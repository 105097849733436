import React from 'react';
import Axios from "axios";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import { CSVDownloader } from "react-papaparse";

import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "../../../_helpers";

toast.configure();
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



export default function AccountAllTable({ FromDates, ToDates, IsIncludeOldClient }) {
  const classes = useRowStyles();
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [IsOldClient,SetIsOldClient] = React.useState(false);
  const [IsManger, SetIsManger] = React.useState(false);
  const [FromDate, SetFromDate] = React.useState();
  const [ToDate, SetToDate] = React.useState();
  const [ClientID,SetClientID] = React.useState("")
  const [UserID,SetUserID] = React.useState("")
  const [Role,SetRole] = React.useState("")
  const [LWMeetingSet,SetLWMeetingSet] = React.useState(0)
  const [TotalMeetingSet,SetTotalMeetingSet] = React.useState(0)
  const [IsLoading,SetIsLoading] =React.useState(true);
  const [ResponderList,SetResponderList] = React.useState([]);
  const [StrategistList,SetStrategistList] = React.useState([]);
  const [ResponderID,SetResponderID] = React.useState(0);
  const [SalesHackerID,SetSalesHackerID] = React.useState(0);
  const [IsResponderID,SetIsResponderID] = React.useState(false);
  const [IsSalesHackerID,SetIsSalesHackerID] = React.useState(false);
  const [ExportData,SetExportData] = React.useState([])
  const [CUserID,SetCUserID] = React.useState(0)

  React.useEffect(() => {
    
    
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDate(FromDates)
    SetToDate(ToDates)
    SetIsOldClient(IsIncludeOldClient)
    ResponderListMethod(Details.ParentUserID,Details.Role)
    StrategistListMethod(Details.ParentUserID,Details.Role)
    AllClientDetails(Details.ParentUserID,Details.Role,FromDates,ToDates,ResponderID,SalesHackerID,IsManger,IsIncludeOldClient)
    CompareKeyMetricsStatisticsByClient(Details.ClientID,Details.ParentUserID,IsIncludeOldClient,Details.Role,FromDates,ToDates)
  }, [FromDates, ToDates, IsIncludeOldClient,Search, Page, RowsPerPage,IsResponderID,IsSalesHackerID,IsManger]);

  const CompareKeyMetricsStatisticsByClient = (CID,UID,IsOldClients,URole,FDate,TDate) =>{
    SetIsLoading(true)
    var InputParameter = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsIncludeOldClient: IsOldClients,
      FromDate:FDate,
      ToDate:TDate
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/CompareKeyMetricsStatisticsByClient",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      
      if(result.data.StatusMessage == "SUCCESS"){
        SetLWMeetingSet(result.data.Data.LWMeetingSet);
        SetTotalMeetingSet(result.data.Data.TotalMeetingSet);
      }
    });
  }
  
  // Responder List
  const ResponderListMethod = (UID,ROLE) => {
    var InputParameter = {
      UserID: UID,
      Role: ROLE
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/ResponderList",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if(result.data.StatusMessage == "SUCCESS"){
        SetResponderList(result.data.Data);
      }else{

      }
    });
  }

    // Strategist List
    const StrategistListMethod = (UID,ROLE) => {
      var InputParameter = {
        UserID: UID,
        Role: ROLE
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/StrategistList",
        method: "POST",
        data: InputParameter,
      });
      rows1.then((result) => {
        if(result.data.StatusMessage == "SUCCESS"){
          SetStrategistList(result.data.Data);
        }else{

        }
      });
    }


    const AllClientDetails = (UID,ROLE,FDate,TDate,RID,SID,IManger,IOld) => {
      SetIsLoading(true)
      var ResponderGetData = {
        
        Role: ROLE,
        FromDate:FDate,
        ToDate:TDate,
        ResponderID:RID,
        SalesHackerID:SID,
        IncludeOldClient:IOld,
        IsManager:IManger,
        ClientIDs:[],
        IsSetByPipestry:false,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        UserID: UID,
        IsResponderID:IsResponderID,
        IsSalesHackerID:IsSalesHackerID
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientGet",
        method: "POST",
        data: ResponderGetData,
      });
      rows1.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
         SetIsLoading(false)
          let Export = [];
          if(Result.data.ExportData.length == 0){
            Export.push({
              "Crush %":"",
              "Client":"",
              "Meetings LW":"",
              "Meetings":"",
              "P Meetings LW":"",
              "Meetings":"",
              "P Meetings":"",
              "Goal":"",
              "Qtr Goal":"",
              "Strategist":"",
              "Responder":"",
              "Sent LW":"",
              "Responses LW":"",
              "Open % LW":"",
              "Open % LM":"",
              "Diffrence(L7-L30)":"",
              "O2R % LW":"",
              "Research Cost":"",
              "Responder":"",
              "Direct Mail Cost":"",
              "Uncontacted":"",
              "Client Status":"",
              "Slack Channel Internal":"",
              "Slack Channel External":""

            })
          }else{
            Result.data.ExportData?.forEach((elem)=>{
              Export.push({
                "Crush %":elem?.CrushRate,
                "Client":elem?.Name,
                "Meetings LW":elem?.MeetingLastWeek,
                "Meetings":elem?.Meetings,
                "P Meetings LW":elem?.PotentialMeetingLastWeek,
                "P Meetings":elem?.PotentialMeeting,
                "Goal":elem?.Goal,
                "Qtr Goal":elem?.QuoterGoal,
                "Strategist":elem?.SalesHackerName,
                "Responder":elem?.ResponderName,
                "Sent LW":elem?.SentEmailAdLastWeek,
                "Responses LW":elem?.ReceivedEmailAddLastWeek,
                "Open % LW":elem?.OpenRateLastWeek?.toFixed(2),
                "Open % LM":elem?.OpenRateLastMonth?.toFixed(2),
                "Diffrence(L7-L30)":elem?.Goal,
                "O2R % LW":elem?.Goal,
                "Research Cost":elem?.Name,
                "Responder":elem?.ResearchCost,
                "Direct Mail Cost":elem?.DirectMailSpend,
                "Uncontacted":elem?.TotalUnContact,
                "Client Status":elem?.ClientBillingMethod,
                "Slack Channel Internal":elem?.SlackChannelInternal,
                "Slack Channel External":elem?.SlackChannelExternal
  
              })
            })
          }
          
          SetExportData(Export)
        }
      });
    };
    const HandleChangePage = (Event, NewPage) => {
      if (NewPage == Page) {
        SetPage(NewPage);
      } else {
        SetPage(NewPage);
        
      }
    };
  
    const ChangeRowSelected = (Event) => {
      SetRowsPerPage(Number(Event.target.value));
      SetPage(1);
      
    };
  
    const RequestSearch = (Event) => {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("search").value;
        if (SearchedVal == Search) {
          SetSearch(SearchedVal);
          SetPage(1);
        } else {
          SetSearch(SearchedVal);
          SetPage(1);
          
        }
      }
    };

    //get sort field data
   const SortData = (Field) => {
    SetIsLoading(true)
    

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameters = {
        Role: Role,
        FromDate:FromDate,
        ToDate:ToDate,
        ResponderID:ResponderID,
        SalesHackerID:SalesHackerID,
        IncludeOldClient:IsOldClient,
        IsManager:IsManger,
        ClientIDs:[],
        IsSetByPipestry:false,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        UserID: UserID,
        IsResponderID:IsResponderID,
        IsSalesHackerID:IsSalesHackerID
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientGet",
        method: "POST",
        data: InputParameters,
      });
      rows1.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

        }

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameters = {
        Role: Role,
        FromDate:FromDate,
        ToDate:ToDate,
        ResponderID:ResponderID,
        SalesHackerID:SalesHackerID,
        IncludeOldClient:IsOldClient,
        IsManager:IsManger,
        ClientIDs:[],
        IsSetByPipestry:false,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        UserID: UserID,
        IsResponderID:IsResponderID,
        IsSalesHackerID:IsSalesHackerID
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientGet",
        method: "POST",
        data: InputParameters,
      });
      rows1.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

        }

      });
    }
    };

    //Advance search 
    const AdvSearch = () => {
        let ResponderId = document.getElementById('ResponderID').value;
        let SalesHackerId = document.getElementById('SalesHackerID').value;
        let IsManagers = document.getElementById('IsManager').checked;
        if(ResponderId != 0){
          SetResponderID(ResponderId);
          SetIsResponderID(true)
        }
        if(SalesHackerId != 0){
          SetSalesHackerID(SalesHackerId);
          SetIsSalesHackerID(true)
        }
        SetIsManger(IsManagers)
    }

    //Reset search

    const Reset = () => {
       document.getElementById('ResponderID').value=0;
      document.getElementById('SalesHackerID').value=0;
       document.getElementById('IsManager').checked=false;
      SetResponderID(0);
      SetSalesHackerID(0);
      SetIsManger(0)
      SetIsResponderID(false)
      SetIsSalesHackerID(false)

    }

    const ExportCsv = () =>{
      toast.success(
        <div>
          Data exported successfully.
        </div>
      )
    }

    const EditPage = (id) => {
      history.push("/editclients", id);
    }
  return (
    <div>
      {IsLoading === true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>:<></>}
      <div className="w-100 whiterow px-1">
        <div className="row">
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title">
                <span class="clr-sky box-main-title">{TotalMeetingSet}</span>
              </div>
              <p class="clr-sky">Meetings Set</p>
            </div>
          </div>
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title">
                <span class="clr-sky box-main-title">{LWMeetingSet}</span>
              </div>
              <p class="clr-sky">Meetings Set L7</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left">All Clients	</h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
              <CSVDownloader data={ExportData} filename={"AllClients-LastWeekResponsesStats"} bom={true}>
                  <a onClick={() => { ExportCsv(); }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row my-5 d-flex">

        <div className="col col-lg-2">
          <div class="form-group mr-10 d-flex">
            <label className="labeltexts mt-2">Responder:</label>   &nbsp;
            <select className="w-100 form-control  m-input" id="ResponderID" name="ResponderID" onChange={(e)=>{SetResponderID(e.target.value)}} value={ResponderID}>
              <option value="0">--Select--</option>
              {
                ResponderList.map((elem)=>(
                  <option value={elem._id}>{elem.ResponderName} {elem.LastName}</option>
                ))
              }
            </select>
          </div>

        </div>
        <div className="col col-lg-2">
          <div class="form-group mr-10 d-flex">
            <label className="labeltexts mt-2"> Strategist:</label>   &nbsp;
            <select className="w-100 form-control  m-input" id="SalesHackerID" name="SalesHackerID" onChange={(e)=>{SetSalesHackerID(e.target.value)}} value={SalesHackerID}>
              <option value="0">--Select--</option>
              {
                StrategistList.map((elem)=>(
                  <option value={elem._id}>{elem.SalesHackerName} {elem.LastName}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="col col-lg-2">
          <div className="boxborders">
            <label class="check_bx">
              <input type="checkbox" id="IsManager"/><span className="checkmark"></span>
              Is Manager
            </label>
          </div>
        </div>
        <div className="col col-lg-4">
          <a href="javascript:void(0);" class="btn btn-primary btnColor" onClick={()=>{AdvSearch()}}>Search</a>
          <a href="javascript:void(0);" class="ml-3 btn btn-secondary" onClick={()=>{Reset()}}><i class="la la-close"></i> Reset</a>
        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="w-100 form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input type="search" id='search' class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} /></label>
        </div>
      </div>
      
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/* <TableCell align="center"><input type="checkbox" /></TableCell> */}

              <TableCell></TableCell>
              <TableCell>Action</TableCell>
              <TableCell><a onClick={() => {
                    SortData("CrushRate");
                  }} >Crush %</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("Name");
                  }} >Client</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("MeetingLastWeek");
                  }} >Meetings L7</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("Meeting");
                  }} >Meetings</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("PotentialMeetingLastWeek");
                  }} >P Meetings L7</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("PotentialMeeting");
                  }} >P Meetings</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("Goal");
                  }} >Goal</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("QuoterGoal");
                  }} >Qtr Goal</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("SalesHackerName");
                  }} >Strategist</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("ResponderName");
                  }} >Responder</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("SentEmailAdLastWeek");
                  }} >Sent L7</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("ReceivedEmailAddLastWeek");
                  }} >Responses LW</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("OpenRateLastWeek");
                  }} >Open % L7</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("OpenRateLastMonth");
                  }} >Open % L30</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("Goal");
                  }} >Difference (L7 - L30)</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("Goal");
                  }} >O2R % L7</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("ResearchCost");
                  }} >Research Cost</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("DirectMailSpend");
                  }} >Direct Mail Cost</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("TotalUnContact");
                  }} >Uncontacted</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("SlackChannelInternal");
                  }} >Slack Channel Internal</a></TableCell>
              <TableCell><a onClick={() => {
                    SortData("SlackChannelExternal");
                  }} >Slack Channel External</a></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Rows.length == 0 ? <p className="text-center">No data in Available</p> : Rows.map((row) => (
            <TableRow key={row.ClientID}>
              <TableCell></TableCell>
              <TableCell><a
                      onClick={() => EditPage(row?.ClientID)}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a></TableCell>
              <TableCell>{row?.CrushRate}%</TableCell>
              <TableCell>{row?.Name}</TableCell>
              <TableCell>{row?.MeetingLastWeek}</TableCell>
              <TableCell>{row?.Meeting}</TableCell>
              <TableCell>{row?.PotentialMeetingLastWeek}</TableCell>
              <TableCell>{row?.PotentialMeeting}</TableCell>
              <TableCell>{row?.Goal}</TableCell>
              <TableCell>{row?.QuoterGoal}</TableCell>
              <TableCell>{`${row?.SalesHackerDetails?.map(item => item.label)?.join(',')}`}</TableCell>
              <TableCell>{`${row?.SalesHackerDetails?.map(item => item.label)?.join(',')}`}</TableCell>
              <TableCell>{row?.SentEmailAdLastWeek}</TableCell>
              <TableCell>{row?.ReceivedEmailAddLastWeek}</TableCell>
              <TableCell>{row?.OpenRateLastWeek?.toFixed(2)}</TableCell>
              <TableCell>{row?.OpenRateLastMonth?.toFixed(2)}</TableCell>
              <TableCell>{row?.Goal}</TableCell>
              <TableCell>{row?.Goal}</TableCell>
              <TableCell>{row?.ResearchCost}</TableCell>
              <TableCell>{row?.DirectMailSpend}</TableCell>
              <TableCell>{row?.TotalUnContact}</TableCell>
              <TableCell>{row?.SlackChannelInternal}</TableCell>
              <TableCell>{row?.SlackChannelExternal}</TableCell>
              </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
    </div>
  );
}
