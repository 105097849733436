import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../images/avatar/1.jpg';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, January, February, March, April, May, June, July, August, September, October, November, December ) {
  return {
    name,
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.January}</TableCell>
        <TableCell>{row.February}</TableCell>
        <TableCell>{row.March}</TableCell>
        <TableCell>{row.April}</TableCell>
        <TableCell>{row.May}</TableCell>
        <TableCell>{row.June}</TableCell>
        <TableCell>{row.July}</TableCell>
        <TableCell>{row.August}</TableCell>
        <TableCell>{row.September}</TableCell>
        <TableCell>{row.October}</TableCell>
        <TableCell>{row.November}</TableCell>
        <TableCell>{row.December}</TableCell>
      </TableRow>
    </React.Fragment>
    
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Referral - Partner', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0),
  createData('Referral - Client', 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0),
  createData('Organic Search', 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0),
  createData('LinkedIn', 0, 0, 2, 0, 0, 1, 0, 0, 0, 2, 0, 1),
  createData('kvr', 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0),
  createData('Inbound - Phone', 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
  createData('Inbound - Email', 0, 0, 2, 0, 0, 1, 0, 1, 0, 0, 1, 0),
  createData('Event', 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0),
  createData('Email', 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0),
  createData('Direct Traffic', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
  createData('Direct Mail - Wine', 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0),
  createData('Direct Mail - Letters', 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
  createData('Direct Mail - Coffee', 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0),
  createData('Cold Calling', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0),
  createData('chat test', 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0),
  createData('Advertising - LinkedIn', 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0),
  createData('Advertising - Google', 0, 0, 0, 3, 0, 0, 0, 0, 1, 0, 0, 0),
  createData('Advertising - Facebook', 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Meeting Source</TableCell>
            <TableCell>January</TableCell> 
            <TableCell>February</TableCell>
            <TableCell>March</TableCell>
            <TableCell>April</TableCell>
            <TableCell>May</TableCell>
            <TableCell>June</TableCell>
            <TableCell>July</TableCell>
            <TableCell>August</TableCell>
            <TableCell>September</TableCell>
            <TableCell>October</TableCell>
            <TableCell>November</TableCell>
            <TableCell>December</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
