import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
const moment = require("moment");
import { GetUserDetails } from "../../../_helpers/Utility";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { history } from "../../../_helpers";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

export default function AccountAllTable({updateFromChild}) {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [Sflag, setSflag] = React.useState(false);
  const [Rlen, setRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, SetfAnchorEl] = React.useState(null);
  const [checkS, setcheckS] = React.useState(false);
  const [orignaldata, setorignaldata] = React.useState([]);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);

  const [cancelarchivepopupval, setcancelarchivepopupval] =
    React.useState(false);
  const [ArchivedPopup, setArchivedPopup] = React.useState(false);
  const [question, setquestion] = React.useState("");
  const [msg, setmsg] = React.useState("");
  const [Type, SetType] = React.useState("");
  const [popupbutton, setpopupbutton] = React.useState("");
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [AID, SetAID] = React.useState("");
  const [Archive, SetArchive] = React.useState(false);
  const [InActive, SetInActive] = React.useState(false);
  const [TemplatePopup, SetTemplatePopup] = React.useState(false)
  const [Name, SetName] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading, SetIsLoading] = React.useState(false);


  useEffect(() => {
    document.title = `All Campaign | SalesHive`;
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
    GetCampaignsList(getaccountcatelist.ClientID, getaccountcatelist.ParentUserID, getaccountcatelist.Role);
  }, [ClientID, Role, search, Page, RowsPerPage, Archive,InActive, AnchorEl]);

  const GetCampaignsList = (CID, UID, URole) => {
    SetIsLoading(true)
    var str_in = {
      page: Page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      Archive: Archive,
      InActive: InActive
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaign/AllClientCampaignGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      console.log(result.data);
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setRlen(result.data.totalcount);
      setflen(result.data.totalcount);
      SetCountPage(result.data.pagecount);
      SetIsLoading(false)
    });
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      var archivechecked = document.getElementById("include_archive").checked;
      var activechecked = document.getElementById("include_inactive").checked;
      if (SearchedVal == search) {
        setSearch(SearchedVal);
        SetPage(1);
        updateFromChild(archivechecked,activechecked,SearchedVal)
      } else {
        setSearch(SearchedVal);
        SetPage(1);
        updateFromChild(archivechecked,activechecked,SearchedVal)
        //document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //push to edit page
  const EditCampaign = async (CampaignID) => {
    
    history.push({ pathname: "/editcampaigns", state: { data: CampaignID } })
  }

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

  const SubmitHandle = async () => {
    
    var archivechecked = document.getElementById("include_archive").checked;
    var activechecked = document.getElementById("include_inactive").checked;
    var SearchedVal = document.getElementById("search").value;
    SetArchive(archivechecked)
    SetInActive(activechecked)
    updateFromChild(archivechecked,activechecked,SearchedVal)
    // console.log(archivechecked + "+"+ activechecked);
  }

  return (
    <div>
    {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row mb-3 pb-4 borderbtnm px-2">
        <div className="col padright pr-3 ">
          <label class="check_bx floatrighter mr-3">
            <input id="include_archive" type="checkbox" />
            <span className="checkmark"></span>
            Include archived campaigns
          </label>
          <label class="check_bx floatrighter mr-3">
            <input id="include_inactive" type="checkbox" />
            <span className="checkmark"></span>
            Include inactive campaigns
          </label>
          <button type="submit" id="submit" class="btn btn-primary btnColor m-btn float-left" onClick={SubmitHandle}>
            <span>
              <i class="la flaticon-search"></i>
              <span>Search</span>
            </span>
          </button>

        </div>
      </div>

      <div className="row px-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} id="search" /></label>
        </div>
      </div>
      
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Total Contacts</TableCell>
              <TableCell>Sent</TableCell>
              <TableCell>Open %</TableCell>
              <TableCell>Open % L1</TableCell>
              <TableCell>Open % L7</TableCell>
              <TableCell>Open % L30</TableCell>
              <TableCell>Reply %</TableCell>
              <TableCell>Bounce %</TableCell>
              <TableCell>Sent L1</TableCell>
              <TableCell>Sent L7</TableCell>
              <TableCell>Meetings</TableCell>
              <TableCell>Uncontacted</TableCell>
              <TableCell>Reply Behavior</TableCell>
              <TableCell>Ignore Blacklisted</TableCell>
              <TableCell>Email Open Tracking</TableCell>
              <TableCell>Send on Weekends</TableCell>
              <TableCell>Track Click</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                rows.map((row) => (
                  <TableRow>
                    <TableCell>{row.ClientData.Name}</TableCell>
                    <TableCell><a href="" onClick={() => { EditCampaign(row._id) }}><u>{row.Name}</u></a></TableCell>
                    <TableCell>{row.TotalCount === undefined ? 0 : row.TotalCount.Count}</TableCell>
                    <TableCell>{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory.TotalSend}</TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpen}</span><br />
                      <span>Tracked: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTracked}</span><br />
                      <span>Open %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTracked === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpen / row.CampaignStepHistory?.TotalTracked) * 100) + "%"}</span>
                    </>} placement="right">
                      <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTracked === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpen / row.CampaignStepHistory?.TotalTracked) * 100) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens:{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpenL1}</span><br />
                      <span>Tracked:{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTrackedL1}</span><br />
                      <span>Open %:{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL1 === 0 ? 0 + " %" : parseInt((row.CampaignStepHistory?.TotalOpenL1 / row.CampaignStepHistory?.TotalTrackedL1) * 100) + " %"}</span>
                    </>} placement="right">
                      <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL1 === 0 ? 0 + " %" : parseInt((row.CampaignStepHistory?.TotalOpenL1 / row.CampaignStepHistory?.TotalTrackedL1) * 100) + " %"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpenL7}</span><br />
                      <span>Tracked: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTrackedL7}</span><br />
                      <span>Open %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL7 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL7 / row.CampaignStepHistory?.TotalTrackedL7) * 100) + "%"}</span>
                    </>} placement="right">
                      <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL7 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL7 / row.CampaignStepHistory?.TotalTrackedL7) * 100) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpenL30}</span><br />
                      <span>Tracked: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTrackedL30}</span><br />
                      <span>Open %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL30 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL30 / row.CampaignStepHistory?.TotalTrackedL30) * 100) + "%"}</span>
                    </>} placement="right">
                      <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL30 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL30 / row.CampaignStepHistory?.TotalTrackedL30) * 100) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Replies: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalReply}</span><br />
                      <span>Sent: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSend}</span><br />
                      <span>Reply %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalReply / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                    </>} placement="right">
                      <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalReply / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Soft Bounce: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSoftBounce}</span><br />
                      <span>Hard Bounce: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalHardBounce}</span><br />
                      <span>Total Bounce: {row.CampaignStepHistory === undefined ? 0 : (row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce)}</span><br />
                      <span>Sent: {row.CampaignStepHistory?.TotalSend}</span><br />
                      <span>Bounce %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : parseInt(((row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce) / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                    </>} placement="right">
                      <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : parseInt(((row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce) / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell>{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSendL1}</TableCell>
                    <TableCell>{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSendL7}</TableCell>
                    <TableCell>{row.MeetingData === undefined? 0 :row.MeetingData.length}</TableCell>
                    <TableCell>{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.unconnected}</TableCell>
                    <TableCell>{row.ReplyBehavior.length > 0 ? row.ReplyBehavior[0].FieldName : ""}</TableCell>
                    <TableCell>{row.IsIncludeBlacklistedContacts == true ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.EmailOpenTracking.length > 0 ? row.EmailOpenTracking[0].FieldName : ""}</TableCell>
                    <TableCell>{row.IsSendonWeekends == true ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.TotalTrackClick}</TableCell>
                    <TableCell>{row.CampaignPriority.length > 0 ? row.CampaignPriority[0].FieldName : ""}</TableCell>
                    <TableCell><a
                      onClick={() => {
                        EditCampaign(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a></TableCell>
                  </TableRow>
                ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}
