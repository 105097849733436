import React, { useEffect } from "react";
const moment = require("moment");
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../../_constants/common.constants";
import { GetUserDetails } from "../../../../_helpers/Utility";
import { history } from "../../../../_helpers";
import loadingicon from "../../../../images/loading.gif";

toast.configure();

export default function AddAccountCategoriesTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("isselected");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AddArray, SetAddArray] = React.useState([]);
  const [CategoryName, SetCategoryName] = React.useState("");
  const [AccountID, SetAccountID] = React.useState("");
  const [SalesReplyAccountCategoryID, SetSalesReplyAccountCategoryID] =
    React.useState("");
  const [IsChecked, SetIsChecked] = React.useState(true);
  const [IsButtonDisabled, SetIsButtonDisabled] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  
  useEffect(() => {
    document.title = `Account | SalesHive`;
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ChildUserID);
      SetPUserID(GetUserData.ParentUserID);
      SetRole(GetUserData.Role);
    }
    SetCategoryName(props.account.Name);
    SetAccountID(props.account.ID);
    SetSalesReplyAccountCategoryID(props.account.SalesReplyAccountCategoryIDs);
    AccountCategoryListGet(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role,
      props.account.SalesReplyAccountCategoryIDs,
      props.account.ID
    );
    
  }, [Search, Page, RowsPerPage]);

  //get account category list
  const AccountCategoryListGet = (
    CID,
    UID,
    URole,
    SalesReplyAccountCategoryIDs,
    AID
  ) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      AccountCategoryID: SalesReplyAccountCategoryIDs,
      AccountID:AID
    };
    const AccountCategoriesList = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_categories/AccountsGetByCategory",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);

      var temArrayA = [];
      const Nearrray = Result.data.PageData.filter(
        (item) => item.AccountCategoryID === props.account.ID
      );
      Nearrray.map((item) => {
        temArrayA.push({ ID: item._id, Value: props.account.ID });
      });
      if(Result.data.PageData.length > 0){
        setSelectAll(Result.data.PageData.every(obj => obj.isselected === true))
      }else{
        setSelectAll(false)
      }
      SetAddArray(temArrayA);
      SetIsLoading(false);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
      SetIsLoading(true);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      SetIsLoading(true);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    SetIsLoading(true);
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        AccountCategoryID: SalesReplyAccountCategoryID,
        AccountID:AccountID
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_categories/AccountsGetByCategory",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        setSelectAll(Result.data.PageData.every(obj => obj.isselected === true))
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        AccountCategoryID: SalesReplyAccountCategoryID,
        AccountID:AccountID

      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_categories/AccountsGetByCategory",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        setSelectAll(Result.data.PageData.every(obj => obj.isselected === true))
      });
    }
  };

  //Back Btn
  const BackBtn = () => {
    history.push("/accountcategory");
  };

  // Check handler function
  const CheckHandler = (e) => {
    setSelectAll(!selectAll);
  
    if (e.target.checked) {
      var temArray = [];
      // Loop through each item in the Rows array
      Rows?.map((val) => {
        document.getElementById(val._id).checked = true;
        updateIsSelectedById(Rows, val._id, e.target.checked);
        // Push an object containing the ID and a value (possibly AccountID) to temArray
        temArray.push({ ID: val._id, Value: AccountID });
        const trElement = document.getElementById(val?._id).closest('tr');
        if(trElement){ 
          trElement.classList.add("highlight");
        }  
      });

      // Set the temArray as the new array using SetAddArray function
      SetAddArray(temArray);
    } else if (!e.target.checked) {
      var temArray = [];

      // Loop through each item in the Rows array
      Rows?.map((val) => {
        document.getElementById(val._id).checked = false;
        updateIsSelectedById(Rows, val._id, e.target.checked);
        // Remove the object from AddArray using splice
        temArray.push({ ID: val._id, Value: null });
        const trElement = document.getElementById(val?._id).closest('tr');
        if(trElement){ 
          trElement.classList.remove("highlight");
        }  
      });
      setSelectedRows(selectAll ? [] : Rows.map((row) => row._id));
      SetAddArray(temArray);
    }
  };

  //Add list contact
  // const CheckBoxAdd = (e, SalesReplyAccountID) => {
  //   
  //   
  //   if (e.target.checked) {
  //     var temArray = AddArray;
  //     let Obj={ID:SalesReplyAccountID,Value:AccountID}
  //     temArray.push(Obj);
  //     SetAddArray(temArray);
  //     SetIsChecked(true)

  //   } else if (!e.target.checked) {
  //     var temArray = AddArray;
  //     let Obj={ID:SalesReplyAccountID,Value:null}
  //     temArray.push(Obj);
  //     SetAddArray(temArray);

  //     SetIsChecked(false)
  //   }
  // };

  // Checkbox handler function for individual rows
  const CheckBoxAdd = (e, SalesReplyAccountID) => {
    // Clone the existing AddArray to a new array
    var temArray = [...AddArray];
    const updatedSelectedRows = [...selectedRows];
    const index = updatedSelectedRows.indexOf(SalesReplyAccountID);

    if (index !== -1) {
      updatedSelectedRows.splice(index, 1);
    } else {
      updatedSelectedRows.push(SalesReplyAccountID);
    }
    updateIsSelectedById(Rows, SalesReplyAccountID, e.target.checked);
    setSelectedRows(updatedSelectedRows);
    
    setSelectAll(Rows.every(obj => obj.isselected === true))
    SetAddArray(temArray);
  };

  // Function to update isselected value
function updateIsSelectedById(array, id, newValue) {
  for (let i = 0; i < array.length; i++) {
      if (array[i]._id === id) {
          array[i].isselected = newValue;
          break; // Stop the loop once the object is found and updated
      }
  }
  
  SetRows(array)
}

// Function to create a new array with id and label properties
function getIdLabelArray(array) {
  return array.map(obj => {
    if (obj.isselected === true) {
        return { ID: obj._id, Value: AccountID };
    } else {
        return { ID: obj._id, Value: null };
    }
});
}

  //Save Btn
  const SaveBtn = (e) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    const newArray = getIdLabelArray(Rows);
    
    
    // SetIsButtonDisabled(true)
    var InputParameter = {
      ClientID: ClientID,
      UserID: UserID,
      SalesReplyAccountIDs: newArray,
      AccountCategoryID: AccountID,
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
    };

    const AccountCategoriesList = Axios({
      url:
        CommonConstants.MOL_APIURL + "/account_categories/AccountsCategoryAdd",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      if (Result.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Sales hive account
            <br />
            Selected sales hive account added successfully.
          </div>
        );
        SetIsLoading(true);
        AccountCategoryListGet(
          ClientID,
          PUserID,
          Role,
          SalesReplyAccountCategoryID,
          AccountID
        );
      } else {
        toast.error(Result.data.Message);
      }
    });
  };
  useEffect(() => {
    if(Rows > 0){
      setSelectAll(Rows.every(obj => obj.isselected === true))
    }else{
      setSelectAll(false)
    }
  }, [selectedRows, Rows])
  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row borderbtnm px-4 d-flex align-items-center pt-2 pb-2">
        <div className="col px-3">
          <h4 className="headertitlenop float-left px-1">
            Accounts {`( ${CategoryName} )`}
          </h4>
        </div>

        <div className="col my-2 float-right">
          <div className="pull-right px-0">
            <a
              id="submit"
              onClick={(e) => {
                SaveBtn(e);
              }}
              // disabled={IsButtonDisabled}
              className="btn btn-primary btn-lightgreen mr-1"
            >
              <i className="la la-save"></i> Save
            </a>
            <a
              id="backtolist"
              onClick={() => {
                BackBtn();
              }}
              className="btn btn-secondary"
            >
              <i className="la la-arrow-circle-left"></i> Back
            </a>
          </div>
        </div>
      </div>
      <div className="row padt-25 px-4">
        <div className="col-sm-12 col-md-6 px-3">
          <label className="textlabelte px-1">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered mx-4">
        <TableContainer component={Paper} className="">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width={20}>
                  <input
                    name="select_all_contacts"
                    type="checkbox"
                    onChange={(e) => CheckHandler(e)}
                    checked={Rows.every(obj => obj.isselected === true)}
                   
                  />
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountName");
                    }}
                  >
                    Account Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountNameLong");
                    }}
                  >
                    Account Name Long
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Email Domains
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow hover key={row._id} selected={row.isselected}>
                      <TableCell>
                        <input
                          type="checkbox"
                          id={row._id}
                          onChange={(e) => CheckBoxAdd(e, row._id)}
                          defaultChecked={row.isselected}
                          
                        />
                      </TableCell>
                    
                    <TableCell>{row.AccountName}</TableCell>
                    <TableCell>{row.AccountNameLong}</TableCell>
                    <TableCell>{row.Domain}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>

      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row px-4">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
