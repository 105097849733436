import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    
  } from '@material-ui/core';
  import moment from 'moment';
  import Pagination from "@material-ui/lab/Pagination";
  import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";


function AccountStatusHistory({UserID,ClientID,Role,AccountID,Domain}) {

    const [Data, setData] = useState([]);
    const [Rows, setRows] = useState([]);
    const [RLen, setRLen] = useState(0);
    const [FLen, setFLen] = useState(0);
    const [CountPage, setCountPage] = useState(0);
    const [Page, setPage] = useState(1);
    const [RowsPerPage, setRowsPerPage] = useState(15);
    const [Search, setSearch] = useState("");
    const [SortField, setSortField] = useState("CreateDate");
    const [SortedBy, setSortedBy] = useState(-1);
    const [Sflag, setSflag] = useState(false);

    useEffect(() => {
        FetchingMethod(Domain,Search, Page, RowsPerPage);
      }, [Search, Page, RowsPerPage, SortField, SortedBy,Domain,AccountID,ClientID]);

      // Lists get all
 const FetchingMethod = (DomainList, Search, Page, RowPage) => {
    if(Domain.trim() != "" && AccountID.trim() != "" && ClientID.trim() != "" && UserID.trim() !== ""){
        const RequestData = {
        Page: parseInt(Page),
        ClientID: ClientID,
        Domain: DomainList,
        RowsPerPage: parseInt(RowPage),
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        SalesReplyAccountID:AccountID
        };
        Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusHistoryGet",
        method: "POST",
        data: RequestData,
        }).then((Response) => {
            setData(Response.data?.PageData);
            setRows(Response.data?.PageData);
            setRLen(Response.data?.TotalCount);
            setFLen(Response.data?.TotalCount);
            setCountPage(Response.data?.PageCount);
        });
    }
  }

  //Sorting Contact list
 const SortingMethod = (FieldName) => {
    setSortField(FieldName)
    setSortedBy(-SortedBy)
  }

  // search for record
 const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("AccountStatusHistorySearch").value;
        setSearch(SearchedVal)
      }
  };

  // change display rows
 const ChangeRowSelected = (Event) => {
    setRowsPerPage(Event.target.value)
    setPage(1)
  };

  // change page
const HandleChangePage = (Event, NewPage) => {
    if (NewPage != Page) {
    setPage(NewPage)
    }
  };

  return (
    <>
        <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Account Status History
                </h3>
              </div>
              <div className="col text-right"></div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelected}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="AccountStatusHistorySearch"
                    onKeyPress={(event) =>
                      RequestSearch(event)
                    }
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortingMethod(
                              "SalesReplyAccountStatusID"
                            );
                          }}
                        >
                          Old Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortingMethod(
                              "OldSalesReplyAccountStatusID"
                            );
                          }}
                        >
                          New Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortingMethod("ChangedDate");
                          }}
                        >
                          Change Date
                        </a>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <TableRow>
                          <TableCell>{row.p2[0]?.Status}</TableCell>
                          <TableCell>{row.p1[0]?.Status}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.ChangedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {Sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {RLen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    RLen
                      ? RLen
                      : Page * RowsPerPage}{" "}
                    of {RLen} entries (filtered from{" "}
                    {FLen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {RLen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    RLen
                      ? RLen
                      : Page * RowsPerPage}{" "}
                    of {RLen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    onChange={HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
    </>
  )
}

export default AccountStatusHistory