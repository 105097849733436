import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import { history } from "../../_helpers";
import { userActions } from "../../_actions";
import { GetUserDetails } from "../../_helpers/Utility";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import GlobalBounceList from "../../_components/user/GlobalBounceList/GlobalBounceListTable";
import { GetClientDetails } from "../../_helpers/Utility";
import $ from "jquery";
import { toast } from "react-toastify";
const Fileupload = require("../../_helpers/fileupload");

import loadingicon from "../../images/loading.gif";

class GlobalBounceListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // FileName: "",
      ExportData: [],
      Files: [],
      Reset: false,
      CsvData: [],
      Dropboxdata: [],
      Filename: "",
      CName:"",
      TotalResult:0,
      TotalDuplicate:0,
      TotalError:0,
      TotalSuccess:0,
      TotalDisplayCount:false,
      UpdateKey:0,
      Email:null
      
    };



  }

  componentDidMount() {
    document.title = "Global Bounce List | SalesHive";
    var Details = GetUserDetails();
    GetClientDetails(Details.ClientID).then((result) => {
      // SetCName(result[0].Name);
      this.setState({CName:result[0].Name})
    });
 
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
    }
    
 }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };


  render() {
    return (
      <>
      <div id="hideloding" className="loding-display" style={{display:"none"}}>
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">


              <div className="row">
                <div className="col">
                  <GlobalBounceList key={this.state.UpdateKey} updateFromChild={this.UpdateFromChild} />
                </div>
              </div> 
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedGlobalBounceListPage = connect(
  mapState,
  actionCreators
)(GlobalBounceListPage);
export { connectedGlobalBounceListPage as GlobalBounceListPage };
