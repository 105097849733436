import React, { useEffect } from 'react';
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
const moment = require('moment');
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import loadingicon from "../../../images/loading.gif";




export default function CallRecordingsTable({ ToDate, FromDate }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [SalesRepList, SetSalesRepList] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsIncludeAllStatus, SetIsIncludeAllStatus] = React.useState(false);
  const [LessThan30Sec, SetLessThan30Sec] = React.useState(false);
  const [ColdCallersID, SetColdCallersID] = React.useState(null);
  const [IsLoading,SetIsLoading]= React.useState(true);
  const [CUserID,SetCUserID] = React.useState(0);

   useEffect(() => {
    document.title = "Call Recordings | SalesHive";
    const details = GetUserDetails();
    if (details != null) {
      SetClientID(details.ClientID);
      SetUserID(details.ParentUserID);
SetCUserID(details.ChildUserID);
      SetRole(details.Role);
      GetColdCallingRecordings(details.ClientID, details.ParentUserID, details.Role,FromDate,ToDate);

    }
  }, [FromDate,ToDate,SortValue, SortField, Search, RowsPerPage, Page, ColdCallersID, LessThan30Sec, IsIncludeAllStatus]);

  useEffect(() => {
    const details = GetUserDetails();
    GetSalesRep(details.ParentUserID);
  }, []);


  const GetSalesRep = (UID) => {
    const InputParameter = {
      UserID: UID
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/cold_caller_recording/SalesDevelopmentRepsGet",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      if (Res.data.StatusMessage = "SUCCESS") {
      SetSalesRepList(Res.data.Data);
      }
    });
  }
  //get Cold calling recording  list
  const GetColdCallingRecordings = (CID, UID, role,FromDate,ToDate) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID == "" ? ClientID : CID,
      UserID: UID == "" ? UserID : UID,
      Role: role == "" ? Role : role,
      IncludeAllStatus: IsIncludeAllStatus,
      LessThan30Sec: LessThan30Sec,
      ToDate: ToDate,
      FromDate: FromDate,
      ColdCallersID: ColdCallersID
    };
    console.log(InputParameter)
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_caller_recording/CallRecordingGet",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      if (Result.data.StatusMessage = "SUCCESS") {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      }else{
        SetIsLoading(false)

      }

    });
  };

  //get sort field data

  const SortData = (Field) => {

    SetSflag(false);
    var Sort = SortValue;
    if (Sort == 1) {
      Sort = -1;
      SetSortValue(-1);
    } else {
      Sort = 1;
      SetSortValue(1);
    }
    SetSortField(Field);
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);

    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //Search Box
  const SearchData = () => {
    let IncludeAllStatus = document.getElementById('includeAllStatus').checked;
    let lessthan30s = document.getElementById('lessthan30s').checked;
    let ColdCallersID = document.getElementById('ColdCallersID').value;
    SetIsIncludeAllStatus(IncludeAllStatus);
    SetLessThan30Sec(lessthan30s);
    SetColdCallersID(ColdCallersID);
  }

  const Reset=()=>{
    SetIsIncludeAllStatus(false);
    SetLessThan30Sec(false);
    SetColdCallersID(null);
    SetPage(1);
    SetSearch("");
    document.getElementById('ColdCallersID').value = "";
  }
  return (
    <>
    {
      IsLoading === true ?
      <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> :<></>
    }
      <div className="row my-4 borderbtnm pb-4 px-3">
        <div className="col col-lg-3">
          <div className="boxborders">
            <label class="check_bx">
              <input type="checkbox" id="includeAllStatus" /><span className="checkmark"></span>
              Include all status
            </label>
          </div>
        </div>
        <div className="col col-lg-3">
          <div className="boxborders">
            <label class="check_bx">
              <input type="checkbox" id="lessthan30s" /><span className="checkmark"></span>
              Include less than 30secs
            </label>
          </div>
        </div>
        <div class="col col-lg-3 d-flex">
          <label class="mt-2 mr-2 nowrap"> Sales Development Reps</label>
          <select class="form-control  m-input" data-val="true" data-val-number="The field ColdCallersID must be a number."
            id="ColdCallersID" name="ColdCallersID">
            <option value="">--Select--</option>
            {SalesRepList.map((row) => (
              <option value={row._id}> {row.ColdCallersName} {row.LastName}</option>

            ))};

          </select>
        </div>
        <div className="col col-lg-3">
          <a href="javascript:void(0);" class="btn btn-primary btnColor" onClick={SearchData}><i class="la la-search"></i>Search</a>
          <a href="javascript:void(0);" class="ml-3 btn btn-secondary" onClick={Reset}><i class="la la-refresh"></i> Reset</a>
        </div>
      </div>
      <div className="row px-3 pt-5">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered mx-3">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell><a onClick={() => { SortData("Name"); }}>Name</a></TableCell>
                <TableCell>
                  <a onClick={() => { SortData("Email"); }}>Email</a></TableCell>
                <TableCell><a onClick={() => { SortData("CompanyPhone"); }}>Company Phone</a></TableCell>
                <TableCell><a onClick={() => { SortData("DirectPhone"); }}>Direct Phone</a></TableCell>
                <TableCell><a onClick={() => { SortData("Mobile"); }}>Mobile Phone</a></TableCell>
                <TableCell><a onClick={() => { SortData("Title"); }}>Title</a></TableCell>
                <TableCell><a onClick={() => { SortData("Company"); }}>Company</a></TableCell>
                <TableCell><a onClick={() => { SortData("Duration"); }}>Duration</a></TableCell>
                <TableCell><a onClick={() => { SortData("Status"); }}>Result</a></TableCell>
                <TableCell><a onClick={() => { SortData("PSTCreatedDate"); }}>Call Date/ Time</a></TableCell>
                <TableCell><a onClick={() => { SortData("ColdCallersName"); }}>Sales Development Reps</a></TableCell>
                <TableCell><a onClick={() => { SortData("PlabackUrl"); }}>Recording</a></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows.map((row) => (
                <TableRow>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.CompanyPhone}</TableCell>
                  <TableCell>{row.DirectPhone}</TableCell>
                  <TableCell>{row.Mobile}</TableCell>
                  <TableCell>{row.Title}</TableCell>
                  <TableCell>{row.Company}</TableCell>
                  <TableCell>{row.Duration}</TableCell>
                  <TableCell>{row.Status}</TableCell>
                  <TableCell>{moment(new Date(row.PSTCreatedDate).toDateString()).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell>{row.ColdCallersName}</TableCell>
                  <TableCell><audio controls style={{ outline: 'none' }} type='audio/mp3'><source src={CommonConstants.CallRecoingURL + row.RecordingFileName + ".mp3"} /></audio></TableCell>

                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row px-3 pb-4 mb-2">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
