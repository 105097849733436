import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import MeetingSlackNotification from '../../_components/user/MeetingSlackNotification/MeetingSlackNotificationTabing';
import CollapsibleTable from '../../_components/usertable';
 

class MeetingSlackNotificationPage extends React.Component {
    
      

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col padd-0 ">
                            <h4 className="headertitlebd">Slack Notification</h4>
                        </div>
                        {/* <div className="col border-bottom px-5">
                            <div className="listing-li float-right padb-15">
                                <ul>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-plus la-icon-font-size-13"></i>
                                        <span>Invite</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <span className="devider"></span>
                    <div className="col padd-0">
                        <MeetingSlackNotification />
                        {/* <FroalaEditor model={this.state.Signature} onModelChange={this.handleModelChange} config={this.config} id="signature"/> */}
                    </div>
                </div>

                    

                    {/* <div className="headerpages">
                        <h4>Users</h4>
                        {/* <div className="listblock">
                            <CollapsibleTable />
                        </div> *
                    </div> */}


                {/* <div className="left-admin headernav">
                    <Sidebar className=""/>
                </div>
                <div className="right-admin">
                    
                </div> */}

                </div>
        <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedMeetingSlackNotificationPage = connect(mapState, actionCreators)(MeetingSlackNotificationPage);
export { connectedMeetingSlackNotificationPage as MeetingSlackNotificationPage };