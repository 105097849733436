import Axios from "axios";
import { CommonConstants } from "../../src/_constants/common.constants";


export function GetUserDetails() {
    let ObjLoginData = JSON.parse(localStorage.getItem('LoginData'));
    if (ObjLoginData && ObjLoginData != null) {
        return ObjLoginData;
    } else {
        return  null;
    }
}

export function UpdateUserDetails(ClientID) {
    
    let ObjLoginData = JSON.parse(localStorage.getItem('LoginData'));
    if (ObjLoginData && ObjLoginData != null) {
        ObjLoginData.ClientID = ClientID;
        localStorage.setItem("LoginData", JSON.stringify(ObjLoginData));
    
        return ObjLoginData;
    } else {
        return  null;
    }
}

export async function GetClientDetails(ClientID) {
    var Str_In = {
        ClientID:ClientID
    } 
    var ClientDetails = await Axios({
        url: CommonConstants.MOL_APIURL + "/client/ClientGetWithID",
        method: "POST",
        data: Str_In,
      });
    
    return ClientDetails.data.Data;
}

export const EmailValidation = (Email) => {
      const EmailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;;
      return EmailRegex.test(Email);
}

export const URLValidation = (Url) => {
        const UrlPatterns = /^(https?|ftp):\/\/[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]+$/;
        return UrlPatterns.test(Url);
}

export const DomainValidation = (Domain) => {
  let DomainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
 return DomainRegex.test(Domain)
}

export const getFirstDayOfQuarter = () => {
    const currentDate = new Date();
    const quarterNumber = Math.floor((currentDate.getMonth() - 1) / 3) + 1;
    const firstDayOfQuarter = new Date(currentDate.getFullYear(), (quarterNumber - 1) * 3, 1);
    return firstDayOfQuarter;
  }

  export const getLastDayOfQuarter = () => {
    const currentDate = new Date();
    const quarterNumber = Math.floor((currentDate.getMonth() - 1) / 3) + 1;
    const firstDayOfQuarter = new Date(currentDate.getFullYear(), (quarterNumber - 1) * 3, 1);
    return firstDayOfQuarter;
  }
  export const getCurrentDayOfQuarter = () =>   
  {
    const currentDate = new Date();
      return currentDate;
  }