import React, { useEffect } from "react";
import Axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import AccountsTable from "../../../_components/user/ClientMailAccounts/AccountsTable";
import DomainsTable from "../../../_components/user/ClientMailAccounts/DomainsTable";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [ExportData, SetExportData] = React.useState([]);
  const [CName, SetCName] = React.useState("");
  const [ClientID, SetClientID] = React.useState("");
  const [UserID, SetUserID] = React.useState("");
  const [Role, SetRole] = React.useState("");
  const [Childupdate, SetChildUpdate] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    ExportCsvData(Details.ParentUserID, Details.Role);
  }, [UserID, ClientID]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ExportCsv = () => {
    toast.success(<div className="toastsize">Data exported successfully.</div>);
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });

  const ExportCsvData = (UID, ROLE) => {
    const InputParameter = {
      UserID: UID,
      Role: ROLE,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/emailaccount/ClientEmailAccountExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        SetExportData(res.data.Data);
      }
    });
  };

  const UpdateChildComponent = (value) => {
    SetChildUpdate(!Childupdate);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h4 className="headertitlebd pl-0"> Master Email Accounts </h4>
        </div>
        <div className="col px-5">
          <div className="listing-li float-right padb-15 pt-1">
            <ul>
              {value === 0 ? (
                <li>
                  <CSVDownloader
                    className="px-0"
                    data={ExportData}
                    filename={CName + "-Master_Email_Accounts_Detail"}
                    bom={true}
                  >
                    <a
                      onClick={ExportCsv}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                      <span>Export</span>
                    </a>
                  </CSVDownloader>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="tabdes">
        <div>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Accounts" {...a11yProps(0)}>
                Overviewasdasd
              </Tab>
              <Tab label="Domains" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
            <div className="row px-2">
              <div className="col">
                <AccountsTable updatechild={UpdateChildComponent} /> 
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} className="tagpaddnone whitebg" index={1}>
            <div className="row px-2">
              <div className="col">
                  <DomainsTable /> 
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
}
