import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import GlobalBounceTabing from '../../_components/user/GlobalBounce/GlobalBounceTabing';
import CollapsibleTable from '../../_components/usertable';



class GlobalBouncePage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlenop px-1 py-4">Global Bounce</h4>
                        </div>
                        <div className="col padright">
                             
                        </div>
                    </div>
                    <span className="devider"></span>
                    <div className="col padd-0">
                        <GlobalBounceTabing />
                    </div>
                </div>

                </div>
                <Footer/>

            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedGlobalBouncePage = connect(mapState, actionCreators)(GlobalBouncePage);
export { connectedGlobalBouncePage as GlobalBouncePage };