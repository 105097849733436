import React from 'react';
import Axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var CryptoJS = require("crypto-js");
import { connect } from 'react-redux';
import Popupone from "reactjs-popup";
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import UsersPerClientTabing from '../../_components/clients/usersperclient/UsersPerClientTabing';
import { history } from '../../_helpers';
const EmailTemplete = require("../../_helpers/email-templete");

import { CommonConstants } from '../../_constants/common.constants';
import{GetUserDetails} from '../../_helpers/Utility'
toast.configure();
import loadingicon from "../../images/loading.gif";

class CUsersPerClientPage extends React.Component {
   constructor(props){
    super(props);

    this.state = {
      PopUpVal: true,
      InvitePopup: false,
      ClientID:null ,
        UserID:null,
        CUserID:null,
        fields: {},
        errors: {},
        IsExist:false,
        IsExist1:false,
        EmailNotNull:false,
        Message:"",
        ListUpdate:false,
        Role:null
    };
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
    this.SendInvite = this.SendInvite.bind(this);
   }

   componentDidMount(){
    var Details = GetUserDetails();
    document.getElementById("hidelod").style.display = "block";
    if(Details != null)
    {
        this.state.ClientID= (Details.ClientID);
        this.state.UserID=(Details.ParentUserID);
        this.state.CUserID=(Details.ChildUserID);
        this.state.Role=(Details.Role);
    }
    document.getElementById("hidelod").style.display = "none";
    }
   OpenInvitePopup() {
    
    this.setState({ InvitePopup: true });
  }
  CloseInvitePopup() {
    
    this.setState({ InvitePopup: false });
    this.setState({Message:""});

  }
  async  CheckAdminInvite(Email){
    let Emails = document.getElementById("EmailInvite").value;
      var InputParameters = {
        ParentUserID: this.state.UserID,
        UserID: this.state.CUserID,
        Email: Emails,
        ClientID:this.state.ClientID
      };
    let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/InviteValidation",
        method: "POST",
        data: InputParameters,
      });
      
        if (resdata.data.StatusMessage == "SUCCESS") {
            if(resdata.data.Flag){
                this.setState({Message:resdata.data.Message});
              return false;
            }else{
              this.setState({Message:""});
              return true;
            }
            
        }
     
  }

     // Whole form validation
     async FormValidation() {
      let formIsValid = true;
      let Email = document.getElementById("EmailInvite").value;
      let IsExists = await this.CheckAdminInvite(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length == 0){
        this.setState({Message:"Please enter email."})
        formIsValid = false;
      }
    
      return formIsValid;
    }
 async SendInvite(e){
  e.preventDefault();
  e.currentTarget.disabled = true;
  var Final_flag =  await this.FormValidation();
  // document.getElementById("hidelod").style.display = "block";
  if(Final_flag==true){
        let Email=document.getElementById('EmailInvite').value;
        let VerificationCodeChar = (Math.random() + 1)
                    .toString(36)
                    .substring(7);
        
          var re =
            /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
          var EmailFlg = re.test(Email);
          if (EmailFlg == true) {
            var FinalEmail = CryptoJS.AES.encrypt(
              JSON.stringify(Email),
              "my-secret-key@123"
            ).toString();
            var UID = CryptoJS.AES.encrypt(
              JSON.stringify(this.state.UserID),
              "my-secret-key@123"
            ).toString();
            var CID = CryptoJS.AES.encrypt(
              JSON.stringify(this.state.ClientID),
              "my-secret-key@123"
            ).toString();
            
            var EmailData = EmailTemplete.userEmailInvite(
              FinalEmail,
              CID,
              UID,
              CommonConstants.usersperclient_invitation
            );
                var str_mail = {
                  Email: Email,
                  html: EmailData,
                  subject: "SalesHive User Account",
                };
                Axios({
                  url: CommonConstants.MOL_APIURL + "/user/profileemail",
                  method: "POST",
                  data: str_mail,
                }).then((res) => {
                  
                    let InputParameters={
                      Email:Email,
                      UserID:this.state.UserID,
                      ClientID:this.state.ClientID,
                      CreatedDate:new Date(),
                      CreatedBy:this.state.CUserID,
                      VerificationCode:VerificationCodeChar,
                      Role:this.state.Role
                  }
                   Axios({
            url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientInvite",
            method: "POST",
            data: InputParameters,
                  }).then((res) => {
                    
                    if (res.data.StatusMessage == "SUCCESS") {
                        toast.success(<div className="toastsize">Users Per Client <br/>
                          Invitation mail sent successfully.</div>)
                        document.getElementById("hidelod").style.display = "none";
                        this.setState({ InvitePopup: false });
                        this.setState({ListUpdate:!this.state.ListUpdate})
                      } else {
                        toast.error(res.data.Message);
                      }
                      this.componentDidMount()
                  })
                  this.setState({ InvitePopup: false });
                  document.getElementById("hidelod").style.display = "none";

                });
          }else{
              this.setState({Message:"Invalid email"})

          }
  } else {
    document.getElementById("m_emailInvite_submit").disabled = false;
    document.getElementById("hidelod").style.display = "none";
  }
            
 }

    render() {
        return (
            <>
                <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup largerPopup">
            <div className="paddingboxTerms">
              <div className="modal-header px-4 py-4">
                <h5>Sent Mail</h5>
                <a className="close-ion text-black-50" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" class="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone p-4">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet px-5">Email</label>
                  <div class="col-lg-8">
                  <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Please enter first name"
                      placeholder="Enter email"
                      id="EmailInvite"
                      name="EmailInvite"
                      type="text"
                      onBlur={this.CheckAdminInvite.bind(this, "Email")}
                    />
                     <span style={{ color: "red" }}>
                        {this.state.Message}
                      </span>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={this.SendInvite}
                  class="btn btn-primary btnColor"
                >
                  Send{" "}
                </button>
              </div>
            </div>
          </div>
                </Popupone>
                <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div>
		
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd py-4 mb-0 pl-0">Users Per Client</h4>
                        </div>
                    <div className="col">
                        <div className="listing-li float-right mt-2">
                            <ul>
                                <li>
                                    <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air" onClick={this.OpenInvitePopup}>
                                        <i class="la la-plus la-icon-font-size-13"></i>
                                        <span>Invite</span>
                                    </a> 
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div> 
                    <div className="col padd-0">
                        <UsersPerClientTabing listupdate={this.state.ListUpdate}/>
                    </div>
                </div>


                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCUsersPerClientPage = connect(mapState, actionCreators)(CUsersPerClientPage);
export { connectedCUsersPerClientPage as CUsersPerClientPage };