import React, { useEffect } from 'react';
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import { CSVReader, CSVDownloader } from "react-papaparse";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Open, SetOpen] = React.useState(false);
  const [ResponderCategoryList, SetResponderCategoryList] = React.useState([]);
  const [Cname, SetCname] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientDetails(GetUserData.ClientID).then((result) => {
      SetCname(result[0]?.Name);
    });
    SalesReplyMessageListGet(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
    SalesResponderCategoryListGet(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
    Exportdetails(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
  }, [Search, Page, RowsPerPage]);

  //get SalesReply Message list
  const SalesReplyMessageListGet = (CID, UID, URole) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const SalesReplyMessageList = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/GetSalesReplyMessagelist",
      method: "POST",
      data: InputParameter,
    });
    SalesReplyMessageList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  //get SalesReply Message list
  const SalesResponderCategoryListGet = (CID, UID, URole) => {
    var InputParameter = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const SalesReplyMessageList = Axios({
      url:
        CommonConstants.MOL_APIURL + "/salesreply_message/GetSalesMessagelist",
      method: "POST",
      data: InputParameter,
    });
    SalesReplyMessageList.then((Result) => {
      SetResponderCategoryList(Result.data?.ResponderCategoryData);
    });
  };

  // Export details
  const Exportdetails = (CID, UID, URole) => {
    let InputParameter = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/SalesReplyMessageExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res?.data);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    document.getElementById("hideloding").style.display = "block";
    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const SalesReplyMessageList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/salesreply_message/GetSalesReplyMessagelist",
        method: "POST",
        data: InputParameter,
      });
      SalesReplyMessageList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const SalesReplyMessageList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/salesreply_message/GetSalesReplyMessagelist",
        method: "POST",
        data: InputParameter,
      });
      SalesReplyMessageList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    }
  };

  //delete new code
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to permanently delete this email from your email account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          _id: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/salesreply_message/SalesReplyMessageDelete",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              toast.success(
                <div className="toastsize">
                  "Response Message" <br /> "Response Message delete
                  successfully."
                </div>
              );
              document.getElementById("hideloding").style.display = "block";
              SalesReplyMessageListGet(ClientID, UserID, Role);
              // history.push("/Campaigns");
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              // props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Update Responder
  const ResponderCategorySelect = (event, id) => {
    var data = {
      _id: id,
      ResponseCategoryID: event.target.value,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/UpdateSalesReplyCategoryDropDown",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        // window.location.reload(true);
        SalesReplyMessageListGet(ClientID, UserID, Role);
        toast.success(
          <div className="toastsize">
            Response Category <br /> Response category updated successfully.
          </div>
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left pb-4 pt-0 pl-0">Responses</h4>
        </div>
        <div className="col border-bottom">
          <div className="listing-li float-right">
            <ul className='mt-0'>
              <CSVDownloader
                data={ExportData}
                filename={Cname + " CampaignResponsesDetail.csv"}
                bom={true}
              >
                <a className="btn btngroup m-btn m-btn--custom">
                  <i class="la la-icon-font-size-13 la-download"> </i>Export
                </a>
              </CSVDownloader>
            </ul>
          </div>
        </div>
      </div>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className='px-2'>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("From");
                    }}
                  >
                    From
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalAccount");
                    }}
                  >
                    Account Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Subject");
                    }}
                  >
                    Subject
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Snippet");
                    }}
                  >
                    Snippet
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MsgDateTime");
                    }}
                  >
                    Responder Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Campaign");
                    }}
                  >
                    Campaign
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("StepNumber");
                    }}
                  >
                    Step Number
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ResponseCategoryID");
                    }}
                  >
                    Responder Category
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            SetOpen((prev) => ({
                              ...prev,
                              [row.SalesReply_MessageID]:
                                !prev[row.SalesReply_MessageID],
                            }))
                          }
                        >
                          {Open[row.SalesReply_MessageID] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{row?.From}</TableCell>
                      <TableCell>{row?.AName}</TableCell>
                      <TableCell>{row?.Subject}</TableCell>
                      <TableCell>
                        {row?.Snippet.slice(0, 30) + " ..."}
                      </TableCell>
                      <TableCell>{row?.MsgDateTime}</TableCell>
                      <TableCell>{row?.Campaign}</TableCell>
                      <TableCell>
                        {row?.StepNumber +
                          "-" +
                          String.fromCharCode(64 + row.OrderBy)}
                      </TableCell>
                      <TableCell className="labeltds" scope="row">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field Status must be a number."
                          id="ResponderCategoryId"
                          name="Status"
                          defaultValue={row?.ResponseCategoryID}
                          onChange={(e) => {
                            ResponderCategorySelect(
                              e,
                              row?.SalesReply_MessageID
                            );
                          }}
                        >
                          {/* <option value="">--Select--</option> */}
                          {ResponderCategoryList?.map((value) => (
                            <option value={value?.ResponseCategoryID}>
                              {value?.Name}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            DeleteBtn(row.SalesReply_MessageID);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={Open[row.SalesReply_MessageID]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[row.SalesReply_MessageID] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>

                                <TableRow>
                                  <TableCell>Response</TableCell>
                                  <TableCell>
                                  <div dangerouslySetInnerHTML={{__html: row?.BodyData[1].BodyDataPlain}}/>
                                  </TableCell>
                                </TableRow>
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </div>
  );
}
