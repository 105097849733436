import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ClientApikeys from '../../_components/user/ClientApiKeys/ClientApikeysTable.js';
//import CollapsibleTable from '../_components/usertable';



class ClientAPIKeysPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left">API Keys</h4>
                            <div className="listing-li float-right padb-15">
                            </div>
                        </div>
                        </div>

                     

                    <div class="paddcols py-4">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label className="textlabelte">Show 
                                <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                </select> 
                                entries</label>
                            </div>
                            <div className="col-sm-12 col-md-6 full-right">
                            <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="table-bordered">
                                    <ClientApikeys />
                                </div>
                            </div>
                        </div>      

                        <div className="row">
                            <div className="col dataTables_info">
                            <p>Showing 1 to 71 of 71 entries</p>
                            </div>
                        </div>
                    </div>

                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientAPIKeysPage = connect(mapState, actionCreators)(ClientAPIKeysPage);
export { connectedClientAPIKeysPage as ClientAPIKeysPage };