import React from "react";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Popupone from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";

const EmailTemplete = require("../../../_helpers/email-templete");
const Fileupload = require("../../../_helpers/fileupload");
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import loadingicon from "../../../images/loading.gif";
import Footer from "../../../_components/user/footer/footer";
import{GetUserDetails} from '../../../_helpers/Utility';

import ProfileImageUpload from '../../../_components/ProfileUpload';
const ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT


const imgstyle = { width: "100px", height: "100px" };

class EditSalesStrategistsPage extends React.Component {
  constructor(props) {
    const Details = GetUserDetails();
    super(props);
    this.childRef = React.createRef();

    this.state = {
      Code: "",
      Profile: [],
      UserProfile: [],
      PopUpVal: true,
      InvitePopup: false,
      FinalImg: "",
      FileImg: "default.png",
      ImgFlag: false,
      UserIDs:null,
      UserID:Details.ParentUserID,
      CUserID:Details.ChildUserID,
      Email:null,
      ProfileImage:null,
      FirstName:null,
      LastName:null,
      Password:null,
      IsEmailExists:false,
      Errors:{},
      Message:"",
      BtnDisabled:false,
      FormData:{
        FirstName: "",
        LastName: "",
        Email: "",
        ProfileImage: "",
        IsManager: "",
        id: "",
        IsTwoStepVerification:"",
        UserID:"",
        SlackAccount:"",
        Password:"",
        ManagerID:""
      },
      errors:{},
      ButtonDisabled:false,
      IsLoading:false,
      MangerList:[]
    };
    this.ResetPassword = this.ResetPassword.bind(this);
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
    this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
    this.BackButton = this.BackButton.bind(this);
    this.HandleEmail = this.HandleEmail.bind(this);
  }
  componentDidMount() {
    document.title="Edit Sales Strategist | SalesHive";

    this.setState({IsLoading:true})
    const data = this.props.location.state;
    this.setState({ Code: data });
    var InputParameters = {
      id: data + "",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/sales_strategists_Profile/SalesStrategistsGetByID",
      method: "POST",
      data: InputParameters,
    }).then((ResSalesData) => {
      
      let EncptPass = ResSalesData.data.Data?.Password;
      let Pass = JSON.parse((CryptoJS.AES.decrypt(((EncptPass).split(" ").join("+")), ENCRYPTION_SALT)).toString(CryptoJS.enc.Utf8))
        this.setState({FinalImg:ResSalesData.data.Data?.ProfileImage});
        let Form = {
          FirstName: ResSalesData.data.Data?.SalesHackerName,
          LastName: ResSalesData.data.Data?.LastName,
          Email: ResSalesData.data.Data?.Email,
          ProfileImage: ResSalesData.data.Data?.ProfileImage,
          IsManager: ResSalesData.data.Data?.IsManager,
          id: this.props.location.state,
          IsTwoStepVerification:ResSalesData.data.Data?.IsTwoStepVerification,
          UserID:ResSalesData.data.Data?.SalesHackerUserID,
          SlackAccount:ResSalesData.data.Data?.SlackAccount,
          Password:Pass,
          ManagerID:ResSalesData.data.Data?.ManagerID,
        }
        
        this.setState({FormData:Form})
        this.setState({IsLoading:false})
    });
   

    this.MangerData(this.state.UserID,this.state.CUserID)

  }


  //getting manger details
  MangerData = (UID,CUID) =>{
    const InputParameters = {
      ChildUserID:UID,
      UserID:CUID,
      ID:this.props.location.state
    }
    Axios({
      url: CommonConstants.MOL_APIURL +"/sales_strategists_Profile/GetSalesHackerManagerList",
      method: "POST",
      data: InputParameters,
    }).then((ResSalesData) => {
      this.setState({MangerList:ResSalesData.data.Data})
     
    });
  }

  //Open invite popup
  OpenInvitePopup() {
    this.setState({ InvitePopup: true });
  }

  //Close invite popup
  CloseInvitePopup() {
    this.setState({ InvitePopup: false });
    this.setState({ Message: "" });
  }

  async  CheckUpdateEmail(Email){
    this.setState({IsLoading:true})

    let Emails = document.getElementById("change_mail").value;
      var InputParameters = {
        ParentUserID: "",
        // UserID: this.state.UserID,
        Email: Emails,
      };
    let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/UpdateEmailValidation",
        method: "POST",
        data: InputParameters,
      });
      
        if (resdata.data.StatusMessage == "SUCCESS") {
            if(resdata.data.Flag){
                this.setState({Message:resdata.data.Message});
        this.setState({IsLoading:false})

              return false;
            }else{
              this.setState({Message:""});
        this.setState({IsLoading:false})

              return true;
            }
            
        }
        this.setState({IsLoading:false})

     
  }

     // Whole form validation
     async FormValidation() {
      let formIsValid = true;
      let Email = document.getElementById("change_mail").value;
      let IsExists = await this.CheckUpdateEmail(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length <=0 ){
        formIsValid=false;
        this.setState({Message:"Please enter email."})
      }
    
      return formIsValid;
    }

 async HandleEmail(){
    let Email = document.getElementById('change_mail').value;
    let IsValid=false
    let errors={}
    if(Email.length <= 0){
      errors['Email'] = "Please enter email"
      IsValid=false
    }else{
      const InputParameters={
        UserID:this.state.UserID,
        Email:Email
      }
     let res = await Axios({
        url: CommonConstants.MOL_APIURL + "/sales_strategists_Profile/SalesHackerEmailExists",
        method: "POST",
        data: InputParameters,
      })
        if(res.data.Data.GetData.length>0){
            this.setState({IsEmailExists:true});
            errors['Email'] = "Email already exists"
            IsValid=false
        }else{
          this.setState({IsEmailExists:false})
          errors['Email'] = null

          IsValid=true
  
        }
      
      this.setState({Errors:errors});
    }
    return IsValid;
  }

  //Update email 
  async SubmitInvitePopup(e) {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag =  await this.FormValidation()
    if(Final_flag==true){
    let Email = document.getElementById('change_mail').value;
    var Tmp_Token =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    var Email_Token = CryptoJS.AES.encrypt(JSON.stringify(Tmp_Token),"my-secret-key@123").toString();

    var uid = this.state.UserIDs;
    var str_in = {
      uID: uid,
      Email_Token: Email_Token,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/changeemail",
      method: "POST",
      data: str_in,
    }).then(async(res) => {
      if (res.data != "") {
        var Username = Email;
        var email = CryptoJS.AES.encrypt(JSON.stringify(Email),"my-secret-key@123").toString();
        var UserUID = CryptoJS.AES.encrypt(JSON.stringify(this.state.UserID), "my-secret-key@123").toString();
        var EmailData = EmailTemplete.salesStrategistsEmailUpdate(
          email,
          this.state.UserIDs,
          this.state.UserIDs,
          UserUID,
          Email_Token
        );
        try {
          // Use await to wait for the SendEmail function to complete
          let IsMailSend = await this.SendEmail(Username, EmailData, "Please Confirm Your Email");
          if (IsMailSend) {
            toast.success(<div className="toastsize">Sales Strategist <br/>Mail sent successfully</div>)
          } else {
              toast.error(<div className="toastsize">Sales Strategists Edit<br/>Something went wrong while sending the update email.</div>);
          }
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error(<div className="toastsize">Sales Strategists Edit<br/>Error sending the update email.</div>);
        }
      }
    });
    this.setState({ InvitePopup: false });
  }else{
    document.getElementById("m_emailInvite_submit").disabled = false;

  }

  }

  
//Update password 
  ResetPassword() {
    var tmp_token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    var forgot_token = CryptoJS.AES.encrypt(JSON.stringify(tmp_token), "my-secret-key@123").toString();
    var InputParameters = {
        "UserName": this.state.FormData.Email,
        "Forgot_Token": forgot_token
    };
    Axios({
        url: CommonConstants.MOL_APIURL + "/login/forgotpassword",
        method: "POST",
        data: InputParameters
    })
    .then(async (resdata) => {
        if (resdata.data.data?.length > 0) {
            var Username = resdata.data.data[0]?.Username;
            var emailData = EmailTemplete.userForgotpassword(forgot_token, Username);
            try {
                // Use await to wait for the SendEmail function to complete
                let IsMailSend = await this.SendEmail(Username, emailData, "Reset Password");
                if (IsMailSend) {
                    toast.success(<div className="toastsize">Sales Strategists Edit<br/>Thank you, Please check your email for a password reset link.</div>);
                } else {
                    toast.error(<div className="toastsize">Sales Strategists Edit<br/>Something went wrong while sending the reset password email.</div>);
                }
            } catch (error) {
                console.error("Error sending email:", error);
                toast.error(<div className="toastsize">Sales Strategists Edit<br/>Error sending the reset password email.</div>);
            }
        }
    });
}

  //back button
  BackButton() {
    history.push("/salesstrategists");
  }


  //Email Send method
  SendEmail = (Username, EmailTemplate, Subject) => {
    return new Promise((resolve, reject) => {
        const InputParameters = {
            email: Username,
            html: EmailTemplate,
            subject: Subject,
        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/user/emailsend",
            method: "POST",
            data: InputParameters,
        })
        .then((res) => { 
            if (res.data.StatusMessage === "SUCCESS") {
                resolve(true); // Resolve the promise with true
            } else {
                resolve(false); // Resolve the promise with false
            }
        })
        .catch((error) => {
            console.error("Error sending email:", error);
            reject(error); // Reject the promise in case of an error
        });
    });
}


  // Function to validate individual form field
  ValidateField(fieldName, value) {
    
    
    // Define your validation rules here
    const newErrors = { ...this.state.errors };
    switch (fieldName) {
      case "FirstName":
        // Check if the FirstName field is empty
        newErrors.FirstName = (value?.trim() === "" || value === null || value === undefined) === "" ? "Please enter sales strategist Name." : "";
        break;
      case "SlackAccount":
        newErrors.SlackAccount = (value?.trim() === "" || value === null || value === undefined) ? "Please enter slack account." : "";
        break;
      
      case "ManagerID":
          // Check if the "IsManager" checkbox is checked
          if (this.state.FormData.IsManager) {
            newErrors.ManagerID = "";
          } else {
            newErrors.ManagerID = (value?.trim() === "" || value === null || value === undefined) ? "Please select manager" : "";
          }
        break;

      default:
        break;
    }

    this.setState({ errors: newErrors });
  }

  //Update OnChange Handler
  HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };

      if (type === "checkbox") {
        // For the checkbox, store the boolean value directly
        updatedFormData[name] = checked;
      } else {
        // For other fields, store their values
        updatedFormData[name] = value;
      }
      // if(name === 'IsManager' && checked === true){
      //   updatedFormData['ManagerID'] = null
      // }
      return {
        FormData: updatedFormData,
      };
    });
  };

  //Update OnBlur Handler
  HandleBlur = (e) => {
    const { name, value } = e.target;

    this.ValidateField(name, value);
  };

  //Update Sales Strategists
  HandleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ ButtonDisabled: true });
    let Image = await this.childRef.current.SubmitData();
    let ImageName = Image.FileName;
    this.setState({ FinalImg: ImageName, ButtonDisabled: true });
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        ProfileImage: ImageName,
      },
    }));
    const { FormData } = this.state;
    const fieldNames = Object.keys(FormData);
    
    let isValid = true;

    fieldNames.forEach((fieldName) => {
      this.ValidateField(fieldName, FormData[fieldName]);
      if (this.state.errors[fieldName]) {
        isValid = false;
      }
    });

    

    if (isValid) {
      Axios({
        url: CommonConstants.MOL_APIURL +"/sales_strategists_Profile/SalesEmailUpdate",
        method: "POST",
        data: FormData,
      }).then((res) => {
        if (res) {
          toast.success(<div className="toastsize" >Sales Strategists Edit<br/>Sales Strategists updated successfully.</div>);
          history.push("/salesstrategists");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });

      // You can submit the form data to your server or perform other actions here
    } else {
      this.setState({ ButtonDisabled: false });
    }
  };



  render() {
  const {FormData,errors} = this.state;
    return (
      <>
      {
        this.state.IsLoading ? <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
        </div> : <></>
      }
          
        <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Email</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      id="change_mail"
                      name="Email"
                      placeholder="Email"
                      type="text"
                      onBlur={this.CheckUpdateEmail.bind(this, "Email")}
                      />
                       <span style={{ color: "red" }}>
                          {this.state.Message}
                        </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={this.SubmitInvitePopup}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </a>
              </div>
            </div>
          </div>
        </Popupone>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">
                    Edit Sales Strategists
                  </h4>
                </div>
              </div>

              <div className="paddcols">
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">First Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="FirstName"
                        name="FirstName"
                        placeholder="First name"
                        type="text"
                        value={FormData.FirstName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.FirstName && (
                        <div className="errorinput">{errors.FirstName}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Last Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        placeholder="Last name"
                        type="text"
                        value={FormData.LastName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        placeholder="Email"
                        type="text"
                        value={FormData.Email}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <a
                    id="lnkUpdateEmail"
                    href="javascript:void(0);"
                    onClick={this.OpenInvitePopup}
                    className="btn btn-primary btngroup btnlightgroup"
                  >
                    Update Email
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Password</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="Password"
                        name="Password"
                        placeholder="Password"
                        type="Password"
                         value={FormData.Password}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <a
                    id="lnkUpdateEmail"
                    href="javascript:void(0);"
                    onClick={this.ResetPassword}
                    className="btn btn-primary btngroup btnlightgroup"
                  >
                    Reset Password
                  </a>
                </div>
              </div> 
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Slack Account</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input" 
                        placeholder="Slack Account"
                        type="text"
                        name="SlackAccount"
                        value={FormData.SlackAccount}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.SlackAccount && (
                        <div className="errorinput">{errors.SlackAccount}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      <input id="IsManager" type="checkbox" name="IsManager"  checked={FormData.IsManager}
                        onChange={this.HandleInputChange}/>
                      <span className="checkmark"></span> Is Manager{" "}
                    </label>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
              </div>
              {
                FormData.IsManager === false? <div className="row">
                 <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Manger</label>
                    <div className="col-lg-8">
                      <select
                        aria-controls="tbl_meeting"
                        className="form-control form-control-sm"
                        id="ManagerID"
                        name="ManagerID"
                        value={FormData.ManagerID}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      >
                        <option value="">--Select--</option>
                        {this.state.MangerList.map((value) => (
                          <option key={value.SalesHackerUserID
                          } value={value.SalesHackerUserID
                          }>
                            {value.SalesHackerName} {value.LastName}
                          </option>
                        ))}
                      </select>
                     
                      {errors.ManagerID && (
                        <div className="errorinput">{errors.ManagerID}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
              </div> : null
              }

              <ProfileImageUpload
                profileImage={this.state.FinalImg}
                ref={this.childRef}
              />       

              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group d-flex">
                  <div className="toggleswich mr-2">
                    <input type="checkbox" className="checktogle"  checked={FormData.Slack}
                        onChange={this.HandleInputChange}
                         id="IsTwoStepVerification" name="IsTwoStepVerification" />
                    <b className="switch"> 
                      <span className="checkion"></span>
                      <span className="uncheckion"></span>
                    </b>
                    <b className="track"></b>
                  </div>
                  <label>Is Two Step Verification</label> 
                </div>
              </div> 
            </div>

              <div className="row mt-4 mb-5">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.ButtonDisabled}
                    onClick={this.HandleSubmit}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackButton}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditSalesStrategistsPage = connect(
  mapState,
  actionCreators
)(EditSalesStrategistsPage);
export { connectedEditSalesStrategistsPage as EditSalesStrategistsPage };
