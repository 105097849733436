import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import KPIsclientsTabing from '../../_components/clients/kpis/KPIsTabing';
const moment=require('moment');
import { CommonConstants } from "../../_constants/common.constants";


class CkpisPage extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        FromDate: "",
        ToDate: "",
      };
      this.SaveBtn = this.SaveBtn.bind(this);
    }
  
    componentDidMount() {
      document.getElementById("from-date").valueAsDate = new Date(
        moment().subtract(1, "months")
      );
      document.getElementById("to-date").valueAsDate = new Date(moment());
      let FromDate = document.getElementById("from-date").value;
      let ToDate = document.getElementById("to-date").value;
      this.setState({
        FromDate: FromDate,
        ToDate: ToDate,
      });
    }
  
    SaveBtn() {
      
      let FromDate = document.getElementById("from-date").value;
      let ToDate = document.getElementById("to-date").value;
      this.setState({ FromDate: FromDate, ToDate: ToDate });
    }
  
    render() {
      const mainTtile = CommonConstants.Title;
      document.title = "KPIs" + mainTtile;
  
      return (
        <>
          <div className="adminmain mheight"> 
            <Sidebar menupage="/kpis" className="" /> 
            <div className="bodyhome">
              <div className="row px-2">
                <div className="col padd-0">
                  <h4 className="headertitlebd">KPIs</h4>
                </div>
                <div className="col padright pr-3">
                  <div className="btn-group">
                    <input id="fromtab" name="fromtab" type="hidden" value="" />
                    <input
                      class="form-control datepicker"
                      id="from-date"
                      name="from-date"
                      type="date"
                      autocomplete="off"
                    />
                    <input
                      class="form-control datepicker"
                      id="to-date"
                      name="to-date"
                      type="date"
                      autocomplete="off"
                    />
                    <button
                    onClick={this.SaveBtn}
                      id="aCompanyState"
                      class="btn btn-primary btnColor m-btn brrb72"
                      autocomplete="off"
                    >
                      <i class="la flaticon-diagram"></i>
                      <span> Analyze</span>
                    </button>
                  </div>
                </div>
              </div> 
              <div className="col padd-0">
                <KPIsclientsTabing FromDates={this.state.FromDate} ToDates={this.state.ToDate} />
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCkpisPage = connect(mapState, actionCreators)(CkpisPage);
export { connectedCkpisPage as CkpisPage };