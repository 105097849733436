import React from 'react';
const moment=require('moment');
import Axios from "axios";

import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ClientResponses from '../../_components/user/ClientResponses/ClientResponses';
//import CollapsibleTable from '../_components/usertable';

import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";
import { CommonConstants } from "../../_constants/common.constants";

class ClientResponsesPage extends React.Component {
    constructor(props) {
        super(props);
            const date13 = new Date(moment().subtract(13, 'day'));
            // Extract the year, month, and day from the Date object
            const year13 = date13.getFullYear();
            const month13 = String(date13.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
            const day13 = String(date13.getDate()).padStart(2, '0');
           const date =  new Date(moment());
           const year = date.getFullYear();
           const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
           const day = String(date.getDate()).padStart(2, '0');
        this.state = {
          FromDate:`${year13}-${month13}-${day13}`,
          ToDate:`${year}-${month}-${day}`,
          ClientID: null,
          UserID: null,
          Role: null,
         
        };
    this.SaveBtn = this.SaveBtn.bind(this);
         
        
      }
    
      componentDidMount() {
        
        
        var Details = GetUserDetails();
        if (Details != null) {
        this.state.ClientID = Details.ClientID;
        this.state.UserID = Details.ChildUserID;
        this.state.Role=Details.Role;
        }
        document.getElementById('from-date').valueAsDate=new Date(moment().subtract(13,'day'))
        document.getElementById('to-date').valueAsDate=new Date(moment())
        let FromDate = document.getElementById('from-date').value;
        let ToDate = document.getElementById('to-date').value;
        
        this.setState({
          FromDate: FromDate,
          ToDate: ToDate
        })
            
        
      }

        //Reload page while delete perform
  UpdateFromChild = (value) => {
    // if(value == true){
    //   this.componentDidMount()
    // }
  }
      SaveBtn(){
        let FromDate=document.getElementById('from-date').value;
        let ToDate=document.getElementById('to-date').value;
        this.setState({FromDate:FromDate,ToDate:ToDate})
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row"> 
                        <div className="col">
                            <h4 className="headertitlepad float-left mb-0">All Responses</h4>
                        </div>
                        <div className="col pt-3">
                            <div className="btn-group">
                                <input id="fromtab" name="fromtab" type="hidden" value="" />
                                <input class="form-control datepicker" type="date"  autocomplete="off" id="from-date" />
                                <input class="form-control datepicker" type="date"  autocomplete="off" id="to-date"/>
                                <button id="aCompanyState" onClick={this.SaveBtn} class="btn btn-primary btnColor btn-radius" autocomplete="off">
                                        <i class="la flaticon-diagram"></i>
                                        <span>
                                            Analyze
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div>

             
                    <div class="paddcols pb-4 pt-1 px-4"> 
                        <div className="row px-2">
                            <div className="col">
                                    <ClientResponses ToDate={this.state.ToDate} FromDate={this.state.FromDate} updateFromChild={this.UpdateFromChild}/> 
                            </div>
                        </div>          
                    </div>
                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientResponsesPage = connect(mapState, actionCreators)(ClientResponsesPage);
export { connectedClientResponsesPage as ClientResponsesPage };