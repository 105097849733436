import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { styled, makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import RestoreIcon from '@material-ui/icons/Restore';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';


import logo from '../../../images/SalesHive.png';
import userdefault from '../../../images/default.png';
import defaultuser from '../../../images/defaultuser.png';
import {Select } from '@material-ui/core';
import { $CombinedState } from 'redux';

import {BrowserRouter as Router, Link,NavLink,useLocation , Route } from 'react-router-dom';



import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import Account from '../../../images/header/Account.svg';
import campiagn from '../../../images/header/campiagn.svg';
import contact from '../../../images/header/contact.svg';
import historyicon from '../../../images/header/history.svg';
import KPI from '../../../images/header/KPI.svg';
import list from '../../../images/header/list.svg';
import meeting from '../../../images/header/meeting.svg';
import settings from '../../../images/header/settings.svg';
import strategy from '../../../images/header/strategy.svg';
import tools from '../../../images/header/tools.svg';
import variable from '../../../images/header/variable.svg';
import admin from '../../../images/header/admin.svg';
import { history } from '../../../_helpers';
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';
import{UpdateUserDetails,GetUserDetails} from '../../../_helpers/Utility'

const drawerWidth = 240;
import ClientID from '../../../_components/user/clientid'


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      //display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const ListItem = withStyles({
  root: {
    "&$selected": {
      //backgroundColor: "red",
      color: "#F8BF58,'!important'",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58"
      }
    },
    "&$selected:hover": {
      //backgroundColor: "purple",
      color: "#F8BF58",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58"
      }
    },
    "&:hover": {
      //backgroundColor: "blue",
      color: "#F8BF58",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58"
      }
    }
  },
  selected: {}
})(MuiListItem);


function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [age, setAge] = React.useState('');
  const [aopen, asetOpen] = React.useState(true);
  const [mopen, msetOpen] = React.useState(true);
  const [copen, csetOpen] = React.useState(true);
  const [vopen, vsetOpen] = React.useState(true);
  const [hopen, hsetOpen] = React.useState(true);
  const [sopen, ssetOpen] = React.useState(true);
  const [topen, tsetOpen] = React.useState(true);
  const [seopen, sesetOpen] = React.useState(true);
  const [accsopen, accssetOpen] = React.useState(true);
  const [pmsopen, pmssetopen] = React.useState(true);
  const [cmpsopen,cmpssetopen]= React.useState(true);
  const [ClientDropdown, setClientDropdown] = React.useState([])
  const [SelectedClientDropdown, setSelectedClientDropdown] = React.useState(true);
  const [cdata, setcdata] = React.useState({data:""})
  const [acllsopen, aclopen] = React.useState(true);
  const [shuopen, shopen] = React.useState(true);
  const [aseopen, asopen] = React.useState(true);
  const [consopen, conopen] = React.useState(true); 
  const [metsopen, metopen] = React.useState(true); 
  const [UserData,SetUserData] = React.useState([]);
  const [LoggedUserDetails,SetLoggedUserDetails] = React.useState([]);
  const [IsClients,SetIsClients] = React.useState(false);
  const [UserRole,SetUserRole]  = React.useState(null)
  const [MenuSelector,SetMenuSelector] = React.useState(null);
  const [SubMenuSelector,SetSubMenuSelector] = React.useState(null);
  useEffect(() => {
    SideBar();
    let LoggedData = JSON.parse(localStorage.getItem("LoginData"))
    if(!LoggedData){
      history.push('/');
    } else{
      GetLoggerUserDetails(LoggedData.ChildUserID,LoggedData.Role);
    } 
  }, [])
  
  const [open, setOpen] = React.useState(true);
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    document.body.classList.toggle('openside'); 
  };

 
 //Sidebar Active routing method
 const SideBar=()=>{ 
  const path = location.pathname; 
  // console.log("path", path);
  let MeetingsPath = ["/cpotentialmeetings","/caddpotentialmeetings","/ceditpotentialmeetings","/cviewpotentialmeetings","/cbookedmeetings","/caddbookedmeetings","/ceditbookedmeetings","/cviewbookedmeetings","/copportunities","/caddopportunities","/ceditopportunities","/cviewopportunities","/crescheduling"];
  let ColdCallingPath = ["/ccoldcallingContact","/CColdCallingReporting","/CCallHistory","/CCallRecordings"];
  let VariableaPath = ["/CVariable","/CCustomVariable","/caddcustomvariable","/ceditcustomvariable","/CCustomVariableSet","/CCallToAction"];
  let HistoryPath = ["/CEmailHistory"];
  let VariablePath = ["/CDocuments","/cadddocuments","/ceditdocuments"];
  //let ToolsPath = ["/campaignsummary","/automator","/despammer","/emailvalidation","/deliverabilitytesting","/deliverabilitytracking","/openratereporting","/clicktrackreporting","/globalbounce","/emailwarmup","/ContactBulkImportRequest"];
  let ObjectionsPath= ["/CUsersPerClient","/objections","/capiKey"];
  let AccountSettingPath = ["/CAccountCategory","/CAccountStatus","/CAccountReasonUnqualified","/CCustomAccountsField"];
  let ContactSettingPath = ["/CContactReasonUnqualified","/CClientPOC","/CContactSource","/CContactsCustomField","/CContactTag"];
  let CampaignSettingPath = ["/Cblacklists","/CEmailAccounts","/CCampaignCategories","/CSendingSchedular","/cemailsignature","/CDefaultCountryBlackList"];
  let MeetingSettingPath = ["/CMeetingowner","/CMeetinggoal","/CMeetingStatus","/CMeetingSource","/CMeetingReminder","/CSlackNotifications","/CCustomMeeting"];
  let PotentialSettingPath = ["/CPotentialMeetingStatus"];
  let AdminPath = ['/clients'];
  let AllClientsPath = ["/clientkpis","/leaderboard","/clientaccount","/clientcontact","/clientcampaigns","/clientresponses","/clientmeetings","/clientrescheduling","/clientpotentialmeetings","/clientlinkedinfollowUp","/clientemailaccounts","/pendinginvites","/variablereporting","/userperclients","/clientactionitems","/clientdirectmail","/clientresearch","/clientlogin","/clientapikeys"];
  let SalesHiveUsersPath = ["/adminuser","/salesstrategists","/responder","/coldcallers","/coldcallerteams"];
  let AdminSettingsPath = ["/clientstatuses","/ResearchTeam","/defaultvariables","/globalbouncelist","/triggerphrases","/smartvariable","/genericemaildomain","/defaultobjections","/defaulttrackingdomain","/salesrep","/globalblacklist","/defaultcountryblacklist","/coldcallerphoneburnerlogin","/DefaultSlackNotification"];
  let ToolsPath = ["/CCampaignSummary", "/CDespammer", "/CEmailvalidation" ];
  
  // path = path.toLowerCase();
  if(MeetingsPath.includes(path)){
    SetMenuSelector(0);
    msetOpen(false);
  }else if(ColdCallingPath.includes(path)){ 
    SetMenuSelector(1);
    csetOpen(false);
  }else if(VariableaPath.includes(path)){
    SetMenuSelector(2);
    vsetOpen(false);
  }else if(HistoryPath.includes(path)){
    SetMenuSelector(3);
    hsetOpen(false)
  }else if(VariablePath.includes(path)){
    SetMenuSelector(4);
    ssetOpen(false)
  }else if(ToolsPath.includes(path)){
    SetMenuSelector(5);
    tsetOpen(false)
  }else if(ObjectionsPath.includes(path)){
    SetMenuSelector(6);
    sesetOpen(false)
  }else if(AccountSettingPath.includes(path)){
    SetMenuSelector(6);
    SetSubMenuSelector(1);
    sesetOpen(false)
    accssetOpen(false)
  }else if(ContactSettingPath.includes(path)){
    SetMenuSelector(6);
    SetSubMenuSelector(2);
    sesetOpen(false)
    conopen(false)
  }else if(CampaignSettingPath.includes(path)){
    SetMenuSelector(6);
    SetSubMenuSelector(3);
    sesetOpen(false)
    cmpssetopen(false)
  }else if(MeetingSettingPath.includes(path)){
    SetMenuSelector(6);
    SetSubMenuSelector(4);
    sesetOpen(false)
    metopen(false)
  }else if(PotentialSettingPath.includes(path)){
    SetMenuSelector(6);
    SetSubMenuSelector(5);
    pmssetopen(false)
    pmssetopen(false)
  }else if(AdminPath.includes(path)){
    SetMenuSelector(7);
    asetOpen(false)
  }else if(AllClientsPath.includes(path)){
    SetMenuSelector(7);
    SetSubMenuSelector(6);
    allcsetopen(false)
    asetOpen(false)
  }else if(SalesHiveUsersPath.includes(path)){
    SetMenuSelector(7);
    SetSubMenuSelector(7);
    shopen(false)
    asetOpen(false)
  }else if(AdminSettingsPath.includes(path)){
    SetMenuSelector(7);
    SetSubMenuSelector(8);
    asopen(false)
    asetOpen(false)
  }else if(ToolsPath.includes(path)){
    SetMenuSelector(8);
    asopen(false)
    asetOpen(false)
  }
}

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  const MeetinghandleClick = () => {
    msetOpen(!mopen);
  };

  const ColdCallinghandleClick = () => {
    csetOpen(!copen);
  };


  const VariableshandleClick = () => {
    vsetOpen(!vopen);
  };

  const HistoryhandleClick = () => {
    hsetOpen(!hopen);
  };

  const StrategyhandleClick = () => {
    ssetOpen(!sopen);
  };

  const ToolshandleClick = () => {
    tsetOpen(!topen);
  };

  const SettingshandleClick = () => {
    sesetOpen(!seopen);
  };

  const AccountSettinghandleClick = () => {
    accssetOpen(!accsopen);
  }
  
  const AdminhandleClick = () => {
    asetOpen(!aopen);
  };

  const meeting_drop_handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const meeting_drop_handleClose = () => {
    setAnchorEl(null);
  };

  const PotentialMeetingSettinghandleClick = () => {
    pmssetopen(!pmsopen);
  }
  const CampaignSettinghandleClick = () => {
    cmpssetopen(!cmpsopen);
  }

  const AllClientshandleClick = () => {
    aclopen(!acllsopen);
  }

  const ShUsershandleClick = () => {
    shopen(!shuopen);
  }

  const AdminSettingshandleClick = () => {
    asopen(!aseopen);
  }

  const ContactSettingshandleClick = () => {
    conopen(!consopen);
  }
  const MeetingSettingshandleClick  = () => {
    metopen(!consopen);
  }
    const addShowClass = () => {
      const element = document.getElementById("id_userbox")
      if(element.classList.contains("show")){
        element.classList.remove("show");
      }
      else{
        element.classList.add("show");
      }
    };

    document.addEventListener('mouseup', function(e) {
      var container = document.getElementById('id_userbox');
      if (container && !container.contains(e.target)) {
        container.classList.remove("show");
      }
      
    });


  const handleChange = (event) => {
    setcdata({data:23});
    setAge(event.target.value);
  };

    // Getting logged user details
    const GetLoggerUserDetails=(CUID,Type)=>{
      const InputParameters = {
        ChildUserID:CUID,
        AccountType:Type
      }
      Axios({
        url:CommonConstants.MOL_APIURL + "/login/GetLoginUserDetails",
        method:"POST",
        data:InputParameters
      }).then((res)=>{
        
        SetLoggedUserDetails(res.data.Data)
      })
      
  }

  const logout=()=>
  {
    localStorage.removeItem("LoginData");
    localStorage.removeItem("id");
    history.push('/');
  }
 


  const drawer = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <Divider />
      <ClientID data={cdata} />
      <List className="py-0">
          <ListItem button >
            <NavLink activeClassName="cust" to="/ckpis">
            <img src={KPI} />
            KPIs
            </NavLink>
          </ListItem>

          <ListItem button>
            <NavLink activeClassName="cust" to="/caccounts">
            <img src={Account} />
            Accounts
            </NavLink>
          </ListItem>

          <ListItem button>
          <NavLink activeClassName="cust" to="/ccontacts">
            <img src={contact} />
            Contacts
             </NavLink>
          </ListItem>

         <ListItem button onClick={MeetinghandleClick} className="menuline" selected={MenuSelector === 0}>
         <img src={meeting} />
          Meetings
          {!mopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!mopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/cpotentialmeetings">Potential Meetings</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
               <NavLink activeClassName="cust" to="/cbookedmeetings">Booked Meetings</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
               <NavLink activeClassName="cust" to="/copportunities">Opportunities</NavLink>
            </ListItem>
            {/* <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/clinkedinfollowup">LinkedIn Follow Ups</NavLink>
            </ListItem> */}
            <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/crescheduling"> Reschedules</NavLink>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
            <NavLink activeClassName="cust" to="/ccampaigns"><img src={campiagn} /> Campaign</NavLink>
        </ListItem>

        <ListItem button onClick={ColdCallinghandleClick} className="menuline" selected={MenuSelector === 1}>
         <img src={meeting} />
          Cold Calling
          {!copen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!copen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>         
            <ListItem button className={classes.nested}>
               <NavLink activeClassName="cust" to="/CColdCallingReporting">Reporting</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
               <NavLink activeClassName="cust" to="/CCallHistory">History</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/CCallRecordings">Recordings</NavLink>
            </ListItem> 
          </List>
        </Collapse>


        <ListItem button>
            <NavLink activeClassName="cust" to="/Clists"><img src={campiagn} />Lists</NavLink>
        </ListItem>

        <ListItem button onClick={VariableshandleClick} className="menuline" selected={MenuSelector === 2}>
         <img src={variable} />
         Variables
          {!vopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!vopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
               <NavLink activeClassName="cust" to="/CVariable">Variable Sets</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/CCustomVariable">Custom Variables</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/CCustomVariableSet">Custom Variable Sets</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/CCallToAction">Call To Action</NavLink>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={HistoryhandleClick} className="menuline" selected={MenuSelector === 3}>
        <img src={historyicon} />
         History
          {!hopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!hopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/CEmailHistory">Email History</NavLink>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={StrategyhandleClick} className="menuline" selected={MenuSelector === 4}>
         <img src={strategy} />
         Strategy
          {!sopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!sopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
           
            <ListItem button className={classes.nested}>
            <NavLink activeClassName="cust" to="/CDocuments">Documents</NavLink>
            </ListItem>
          
          </List>
        </Collapse>


        <ListItem button onClick={SettingshandleClick} className="menuline" selected={MenuSelector === 6}>
         <img src={settings} />
         Settings
          {!seopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!seopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/CUsersPerClient">Users Per Client</NavLink>
            </ListItem>

            <ListItem button className={`${classes.nested} menuline`} onClick={AccountSettinghandleClick} selected={SubMenuSelector === 1}>
              Account Settings
            {!accsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!accsopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem component={NavLink} activeClassName="cust" to="/CAccountCategory" button className={classes.nested}>
                  Account Catagories
                </ListItem>
                <ListItem component={NavLink} activeClassName="cust" to="/CAccountStatus" button className={classes.nested}>
                  Client Status
                </ListItem>

                <ListItem component={NavLink} activeClassName="cust" to="/CAccountReasonUnqualified" button className={classes.nested}>
                Reasons Unqualified
                </ListItem>

                <ListItem component={NavLink} activeClassName="cust" to="/CCustomAccountsField" button className={classes.nested}>
                Custom Fields
                </ListItem>
              </List>
            </Collapse>

            <ListItem button className={`${classes.nested} menuline`} onClick={ContactSettingshandleClick} selected={SubMenuSelector === 2}>
             Contact Settings 
            {!consopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!consopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CContactReasonUnqualified" button className={classes.nested}>
                  Reason Unqualified
                </ListItem>

                <ListItem  component={NavLink} activeClassName="cust" to="/CClientPOC" button className={classes.nested}>
                Client POC
                </ListItem>

                <ListItem  component={NavLink} activeClassName="cust" to="/CContactSource" button className={classes.nested}>
                Contact Source
                </ListItem>

                <ListItem  component={NavLink} activeClassName="cust" to="/CContactsCustomField" button className={classes.nested}>
                Custom Fields
                </ListItem>

                <ListItem  component={NavLink} activeClassName="cust" to="/CContactTag" button className={classes.nested}>
                Tag
                </ListItem>
              </List> 
            </Collapse>



            <ListItem button className={`${classes.nested} menuline`} onClick={CampaignSettinghandleClick} selected={SubMenuSelector === 3}>
            Campaign Settings
            {!cmpsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!cmpsopen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/Cblacklists" button className={classes.nested}>
                Blacklists
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CEmailAccounts" button className={classes.nested}>
                Email Accounts
                </ListItem>
              </List>
              
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CCampaignCategories" button className={classes.nested}>
                Campaign Categories
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CSendingSchedular" button className={classes.nested}>
                Sending Schedules
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/cemailsignature" button className={classes.nested}>
                Email Signature
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CDefaultCountryBlackList" button className={classes.nested}>
                Country BlackList
                </ListItem>
              </List>
            </Collapse>
 

            <ListItem button className={`${classes.nested} menuline`} onClick={MeetingSettingshandleClick} selected={SubMenuSelector === 4}>
            Meeting Settings 
            {!metsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!metsopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CMeetingowner" button className={classes.nested}>
                Meeting Owners
                </ListItem>
              </List> 
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CMeetinggoal" button className={classes.nested}>
                Meeting Goals
                </ListItem>
              </List> 
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CMeetingStatus" button className={classes.nested}>
                Meeting Statuses
                </ListItem>
              </List> 
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CMeetingSource" button className={classes.nested}>
                Meeting Sources
                </ListItem>
              </List> 
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CMeetingReminder" button className={classes.nested}>
                Meeting Reminder
                </ListItem>
              </List> 
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CSlackNotifications" button className={classes.nested}>
                Slack Notifications
                </ListItem>
              </List> 
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CCustomMeeting" button className={classes.nested}>
                Custom Fields
                </ListItem>
              </List> 
            </Collapse>

         <ListItem button className={`${classes.nested} menuline`} onClick={PotentialMeetingSettinghandleClick} selected={SubMenuSelector === 5}>
           Potential Meeting Settings
            {!pmsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!pmsopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem  component={NavLink} activeClassName="cust" to="/CPotentialMeetingStatus" button className={classes.nested}>
                Engagement Status
                </ListItem> 
              </List>
            </Collapse>

            <ListItem component={NavLink} activeClassName="cust" to="/capiKey" button className={classes.nested}>
            API Key
            </ListItem>
          </List>
        </Collapse>


        <ListItem button onClick={ToolshandleClick} className="menuline">
         <img src={tools} />
         Tools
          {!topen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!topen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/CCampaignSummary">Campaigns Summary</NavLink>
            </ListItem> 
            <ListItem button className={classes.nested}>
            <NavLink activeClassName="cust" to="/CDespammer">Despammer</NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
            <NavLink activeClassName="cust" to="/CEmailvalidation">Email Validation</NavLink>
            </ListItem> 
          </List>
        </Collapse>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <div className="header_main">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>

      {/* <div className="col col-lg-2">
        <div className="logodivider">
          <img src={logo} alt="logo"/>
        </div>
      </div> */}

      <div className="col col-lg-5 px-0">
        <div className="logonavbox">
          <div className="navigationbtn">
            <a
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}>
              <MenuIcon />
          </a>   
          </div>
          <div className="logodivider">
            <img src={logo} alt="logo"/>
          </div>
        </div>
      </div>

        {/* 
          <h1>fdsfdsf</h1> */}
      <div className="col col-lg-7">

          <div className="righterblock">
{/* 
          <ul className="menulist mshide">
            <li><NavLink activeClassName="cust" to="/ckpis">KPIs</NavLink></li>
            <li><NavLink activeClassName="cust" to="/caccounts">Accounts</NavLink></li>
            <li><NavLink activeClassName="cust" to="/ccontacts">Contacts</NavLink></li> 
            <li className="dropbtnwhite"> 
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={meeting_drop_handleClick}>
                Meetings <i class="menuarrow la la-angle-down icon-chev"></i>
              </Button>
              <Menu
                className="metingbtns"
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={meeting_drop_handleClose}
              >
                <MenuItem component={Link} to="/cpotentialmeetings" onClick={meeting_drop_handleClose}>Potential Meetings</MenuItem>
                <MenuItem component={Link} to="/cbookedmeetings" onClick={meeting_drop_handleClose}>Booked Meetings</MenuItem>
                <MenuItem component={Link} to="/copportunities" onClick={meeting_drop_handleClose}>Opportunities</MenuItem>
                <MenuItem component={Link} to="/crescheduling" onClick={meeting_drop_handleClose}>Reschedules</MenuItem>
              </Menu>
            </li>  
            <li> <NavLink activeClassName="cust" to="/ccampaigns">Campaigns</NavLink></li>
            <li>
              <a>
                  <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                More <i class="menuarrow la la-angle-down icon-chev"></i>
              </IconButton>  
              </a>
            </li>
          </ul> */}

          {/*<div className="lghiden">
           <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                <i class="la la-navicon"></i>
              </IconButton> 
          </div> */}

        <a href="#" className="m-nav__link m-dropdown__toggle" onClick={addShowClass}>
          <span className="m-topbar__userpic">
             
              <img
                      width="40px"
                      src={
                        LoggedUserDetails?.ProfileImage == ""
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url + LoggedUserDetails?.ProfileImage
                      }
                    ></img>
          </span>
        </a>
           
        <div className="userdropdown" id="id_userbox">
          <div className="bg-themehead">
              <div className="m-card-user m-card-user--skin-dark">
                  <div className="carduser_pic">
                  <img
                      width="40px"
                      src={
                        LoggedUserDetails?.ProfileImage == ""
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url + LoggedUserDetails?.ProfileImage
                      }
                    ></img>
                  </div>
                  <div className="carduser_details">
                      <span className="card-usertitel">{LoggedUserDetails?.FirstName + " "+ LoggedUserDetails?.LastName}</span>
                      <NavLink activeClassName="cust" to="/ceditmyprofile">{LoggedUserDetails?.Username}</NavLink>
                  </div>
              </div>
          </div>
          <div className="bodyuserdop">
              <ul>
                <li>
                  <NavLink activeClassName="cust" to="/ceditmyprofile">
                  <i className="m-nav__link-icon flaticon-profile-1"></i> My Profile
                  </NavLink>
                </li>
                <li>
                  <a className="Logoutbtn btn" onClick={logout}>
                    Logout
                  </a>
                </li>
              </ul>
          </div>
        </div>


        </div>
        
        </div>

        </Toolbar>
      </AppBar>
      
      
      <nav style={{display:"none"}} className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
       
          {/* <Drawer className="sidebarleft"
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'left' : 'left'} 
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>  */}

        {/* <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden> */}
      </nav> 
      </div>

      <Drawer className="sidebarleft" sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      > 
        
        {drawer} 
      </Drawer> 
      <Main 
      open={open}
      ></Main> 
    
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
