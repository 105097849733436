import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";
const moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";

class ViewPotentialMeetingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      Fields1: {},
      Errors1: {},
      StatusAvailable: null,
      Showing: true,
      MeetingSource: "",
      MeetingOwner: "",
      ReminderEmailAccount: "",
      EngagamentStatus: "",
      MeetingSetByName: "",
      AllRes: [],
      SourceMeeting: [],
      StatusMeeting: [],
      Data: [],
      Rows: [],
      Rlen: 0,
      Flen: 0,
      CountPage: 0,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Page: 1,
      RowsPerPage: 10,
      Search: "",
      SortField: "CreatedDate",
      SortedBy: -1,
      Sflag: false,
      MeetingID: null,
      ClientPotential: false,
      ClientStatusL: "",
      ShowNotePopup: false,
      ShowNotePopupUpdate: false,
      TitleAvailable: null,
      OldTitleUpdate: null,
      CName: "",
      Role: "",
      BtnDisabledSave: false,
      BtnDisabledUpdate: false,
      IsLoading: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.EditBtn = this.EditBtn.bind(this);
    this.EditsPage = this.EditsPage.bind(this);
    this.SaveUpdateBtn = this.SaveUpdateBtn.bind(this);
    this.SortData = this.SortData.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.DeleteClientName = this.DeleteClientName.bind(this);
    this.Handlevalidationfornotesedit =
      this.Handlevalidationfornotesedit.bind(this);
    this.Handlevalidationfornotesadd =
      this.Handlevalidationfornotesadd.bind(this);
  }
  componentDidMount() {
    document.title = `Edit Potential Meetings | SalesHive`;
    var Details = GetUserDetails();

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.setState({ IsLoading: true });
    if (this.props.location.state["Pagename"] == "clientPotenial") {
      this.setState({ ClientPotential: true });
    }
    this.EditPotentialMeetingstatus();
    this.NotesGet();
  }

  EditPotentialMeetingstatus() {
    try {
      const id = this.props.location.state.data;
      this.setState({ id: id });

      let stateUpdatesDone = 0;
      // Define the total number of state updates to complete
      const totalStateUpdates = 2; // Adjust this based on your code

      // Function to check if all state updates are done and hide the element
      var str_in = {
        id: id,
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialMeetingByID",
        method: "POST",
        data: str_in,
      }).then((res) => {
        this.setState({ AllRes: res?.data });
        GetClientDetails(res.data[0]?.ClientID)
          .then((result) => {
            this.setState({ CName: result[0]?.Name });
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
          });
        //all default status
        var str_in1 = {
          ClientID: this.state.ClientID,
          MeetingSourceID: res.data[0]?.MeetingSourceID,
          MeetingOwnerID: res.data[0]?.MeetingOwnerID,
          ReminderEmailAccountID: res.data[0]?.ReminderEmailAccountID,
          PotentialMeetingStatusID: res.data[0]?.EngagamentStatusID,
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialAllStatusGetById",
          method: "POST",
          data: str_in1,
        })
          .then((resmeeting) => {
            this.setState({
              MeetingSource: resmeeting.data?.meetingsource[0]?.Name,
            });
            this.setState({
              MeetingOwner: resmeeting.data?.meetingowner[0]?.Name,
            });
            this.setState({
              ReminderEmailAccount:
                resmeeting.data?.accountstatus[0]?.SMTPFromEmail,
            });
            this.setState({
              EngagamentStatus: resmeeting.data?.potentialstatus[0]?.Status,
            });
            this.setState({
              ClientStatus: resmeeting.data?.ClientName[0]?.Name,
            });

            this.setState({ IsLoading: false });
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
          });

        //setbymeeting
        if (res?.data[0]?.ResponderColdCallersID != null) {
          if (res?.data[0]?.ResponderColdCallersType === "R") {
            var str_in8 = {
              ResponderColdCallersID:
                res?.data[0]?.ResponderColdCallersID.substring(1),
              Role: this.state.Role,
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL +
                "/potentialmeeting/PotetialMeetingsetbyResponderGet",
              method: "POST",
              data: str_in8,
            })
              .then((resmeetingsetby) => {
                this.setState({
                  MeetingSetByName:
                    resmeetingsetby?.data && resmeetingsetby.data.length > 0
                      ? resmeetingsetby.data[0]?.ResponderName || ""
                      : "",
                });
                //checkIfAllStateUpdatesDone();
              })
              .catch((error) => {
                toast.error("An error occurred. Please try again.");
                this.setState({ IsLoading: false });
              });
          } else {
            var str_in8 = {
              ResponderColdCallersID:
                res?.data[0]?.ResponderColdCallersID.substring(1),
              Role: this.state.Role,
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL +
                "/potentialmeeting/PotetialMeetingsetbyColdcallerGet",
              method: "POST",
              data: str_in8,
            })
              .then((resmeetingsetby) => {
                this.setState({
                  MeetingSetByName:
                    resmeetingsetby?.data && resmeetingsetby.data[0]
                      ? resmeetingsetby.data[0].ColdCallersName || ""
                      : "",
                });

                //checkIfAllStateUpdatesDone();
              })
              .catch((error) => {
                toast.error("An error occurred. Please try again.");
                this.setState({ IsLoading: false });
              });
          }
        }

        // const checkIfAllStateUpdatesDone = () => {
        //   stateUpdatesDone++
        //   if (stateUpdatesDone === totalStateUpdates) {
        //     // Hide the element with the ID "hideloding"
        //     document.getElementById("hideloding").style.display = "none";
        //   }
        // };
        // notes
        this.setState({ MeetingID: res.data[0]?._id });
      });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  BackBtn() {
    debugger;
    if (this.props.location.state["Pagename"] === "Contact") {
      history.push({
        pathname: "/viewcontacts",
        state: {
          data: this.props.location.state?.pid,
          EmailData: this.props.location.state.EmailData,
        },
      });
    } else if (this.props.location.state["Pagename"] === "clientLinkedin") {
      history.push("/clientlinkedinfollowUp");
    } else if (this.props.location.state.Pagename === "LinkedInFollowUps") {
      history.push("/linkedinfollowup");
    } else if (this.props.location.state["Pagename"] === "clientPotenial") {
      history.push("/clientpotentialmeetings");
    } else if (this.props.location.state["Pagename"] == "AccountContact"){
      history.push({ pathname: "/viewaccount", state: { data: this.props.location.state.AccountData } });
    } else {
      history.push("/potentialmeeting");
    }
  }

  EditBtn = (id) => {
    if (this.props.location.state["Pagename"] == "Contact") {
      history.push({
        pathname: "/editpotentialmeeting",
        state: {
          data: id,
          Pagename: "Contact",
          Dataprospect: this.props.location.state?.pid,
          EmailData: this.props.location.state.EmailData,
          data: this.props.location.state.data,
        },
      });
    } else if (this.props.location.state["Pagename"] == "clientLinkedin") {
      history.push({
        pathname: "/editpotentialmeeting",
        state: { data: id, Pagename: "clientLinkedin" },
      });
    } else if (this.props.location.state["Pagename"] == "clientPotenial") {
      history.push({
        pathname: "/editpotentialmeeting",
        state: { data: id, Pagename: "clientPotenial" },
      });
    } else if (this.props.location.state["Pagename"] == "LinkedInFollowUps") {
      history.push({
        pathname: "/editpotentialmeeting",
        state: { data: id, Pagename: "LinkedInFollowUps" },
      });
    } else {
      history.push({
        pathname: "/editpotentialmeeting",
        state: { data: id, Pagename: "" },
      });
    }
  };


  NotesGet = () => {
    try {
      this.setState({ IsLoading: true });
      var str_in2 = {
        MeetingID: this.props.location.state.data,
        page: this.state.Page,
        rowsPerPage: this.state.RowsPerPage,
        sort: true,
        field: this.state.SortField,
        sortby: this.state.SortedBy,
        Search: this.state.Search,
        type: "User",
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/GetPotentialMeetingNotes",
        method: "POST",
        data: str_in2,
      })
        .then((resnotes) => {
          this.setState({ IsLoading: false });
          if (resnotes === null) {
            this.setState({ Data: null });
            this.setState({ Rows: null });
            this.setState({ Rlen: null });
            this.setState({ Flen: null });
            this.setState({ CountPage: null });
          } else {
            this.setState({ Data: resnotes.data.pagedata });
            this.setState({ Rows: resnotes.data.pagedata });
            this.setState({ Rlen: resnotes.data.totalcount });
            this.setState({ Flen: resnotes.data.totalcount });
            this.setState({ CountPage: resnotes.data.pagecount });
          }
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  // meetings add
  MeetingsConvertAdd = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to convert potential meeting into meeting?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, convert it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var str_in = {
          id: this.props.location.state.data,
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialMeetingByID",
          method: "POST",
          data: str_in,
        })
          .then((res) => {
            history.push({
              pathname: "/addbookedmeeting",
              state: {
                Pagename: "Potential",
                data: res.data[0],
                BackPage: "/bookedmeetings",
              },
            });
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Data is safe :)", "error");
      }
    });
  };

  handleChangePage = (event, newPage) => {
    try {
      this.setState({ Page: newPage });
      var str_in = {
        MeetingID: this.state.MeetingID,
        page: newPage,
        rowsPerPage: this.state.RowsPerPage,
        serchbox: false,
        sort: true,
        field: this.state.SortField,
        sortby: this.state.SortedBy,
        type: "User",
        Role: this.state.Role,
      };
      const Rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/GetPotentialMeetingNotes",
        method: "POST",
        data: str_in,
      });
      Rows1.then((resnotes) => {
        this.setState({ Data: resnotes.data.pagedata });
        this.setState({ Rows: resnotes.data.pagedata });
        this.setState({ Rlen: resnotes.data.totalcount });
        this.setState({ Flen: resnotes.data.totalcount });
        this.setState({ CountPage: resnotes.data.pagecount });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  //Search
  RequestSearch = (Event) => {
    try {
      if (Event.key === "Enter") {
        this.setState({ IsLoading: true });
        var SearchedVal = document.getElementById("Search").value;
        this.setState({ Page: 1 });
        this.setState({ RowsPerPage: 10 });
        var Searchbox;
        if (SearchedVal == "") {
          Searchbox = false;
          this.setState({ Sflag: false });
        } else {
          Searchbox = true;
        }
        var str_in = {
          MeetingID: this.state.MeetingID,
          page: 1,
          rowsPerPage: this.state.RowsPerPage,
          sort: true,
          field: this.state.SortField,
          sortby: this.state.SortedBy,
          Search: SearchedVal,
          type: "User",
          Searchbox: Searchbox,
          Role: this.state.Role,
        };
        const Rows1 = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/GetPotentialMeetingNotes",
          method: "POST",
          data: str_in,
        });
        Rows1.then((resnotes) => {
          this.setState({ Data: resnotes.data.pagedata });
          this.setState({ Rows: resnotes.data.pagedata });
          this.setState({ Rlen: resnotes.data.totalcount });
          this.setState({ Flen: resnotes.data.totalcount });
          this.setState({ CountPage: resnotes.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  //Delete notes details
  DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Potential Notes Meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var Data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: this.state.CUserID,
          Role: this.state.Role,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/DeletePotentialNotesMeeting",
          method: "POST",
          data: Data,
        })
          .then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Delete Potential Notes Meeting successfully.",
                  "success"
                );
                history.push({
                  pathname: "/viewpotentialmeeting",
                  state: { data: this.state.MeetingID, Pagename: "" },
                });
                this.NotesGet();
              } else {
              }
            }
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Data is safe :)", "error");
      }
    });
  };

  chengeRowSelected = (event) => {
    this.setState({ RowsPerPage: event.target.value });
    this.setState({ Page: 1 });
    // startPage("Search", 1, event.target.value);
  };

  //notes

  async fromValidationNotes() {
    try {
      let formIsValid = true;
      let fileds = this.state.Fields;
      let Errors = {};
      var Title22 = document.getElementById("Title22").value.trim();
      var Notes22 = document.getElementById("Notes22").value.trim();
      var IsExist = await this.CheckExistPotentialMeetingTitle(Title22);
      if (Title22 == "") {
        formIsValid = false;
        Errors["Title22"] = "Please Enter Title";
      }
      if (Notes22 == "") {
        formIsValid = false;
        Errors["Notes22"] = "Please Enter Notes";
      }
      if (IsExist == true) {
        formIsValid = false;
      }

      this.setState({ Errors: Errors });
      return formIsValid;
    } catch (error) {
      toast.error("An error occurred.Please try again.");
    }
  }

  handleChangeNotes(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistPotentialMeetingTitle(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.Errors.Title = null;
    }
  }

  async CheckExistPotentialMeetingTitle(Title) {
    try {
      var str_in = {
        MeetingID: this.state.MeetingID,
        ClientID: this.state.ClientID,
        Role: this.state.Role,
      };
      var resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/PotentialMeetingStatusExistsNotes",
        method: "POST",
        data: str_in,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          let Errors = {};
          this.setState({ TitleAvailable: resdata.data.Data[0].Title });
          Errors["Notes22"] = "";
          this.setState({ Errors: Errors });
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      toast.error("An error occurred.Please try again.");
    }
  }

  async SaveBtn(e) {
    try {
      var Title1 = document.getElementById("Title22").value;
      var Notes2 = document.getElementById("Notes22").value;
      // e.preventDefault();
      // e.currentTarget.disabled = true;
      this.setState({ BtnDisabledSave: true });
      var Final_flag = await this.fromValidationNotes();
      if (Final_flag == true) {
        var Data = {
          ClientID: this.state.ClientID,
          MeetingID: this.state.MeetingID,
          Title: Title1,
          Note: Notes2,
          IsDeleted: false,
          CreatedBy: this.state.CUserID,
          CreatedDate: new Date(),
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/AddPotentialNotesMeeting",
          method: "POST",
          data: Data,
        })
          .then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div>
                  Add Note <br />
                  Note added successfully.
                </div>
              );
              this.setState({ ShowNotePopup: false });
              this.NotesGet();
              // history.push({
              //   pathname: "/viewpotentialmeeting",
              //   state: { data: this.state.MeetingID, Pagename: "" },
              // });
              this.setState({ BtnDisabledSave: false });
            } else {
              toast.error(res.data.Message);
              this.setState({ BtnDisabledSave: false });
            }
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
          });
      } else {
        this.setState({ BtnDisabledSave: false });
        //document.getElementById("submitSave").disabled = false;
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  EditsPage(id) {
    try {
      // var mnid = this.state.Rows.PotentialMeetingNoteID
      this.setState({ id: id });
      var str_in = {
        id: id,
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/PotentialMeetingNotesByID",
        method: "POST",
        data: str_in,
      })
        .then((res) => {
          this.setState({ OldTitleUpdate: res.data[0]?.Title });
          document.getElementById("Title1").value = res.data[0]?.Title;
          document.getElementById("Notes").value = res.data[0]?.Note;
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  async fromValidationNote() {
    try {
      let formIsValid = true;

      let Fields = this.state.Fields1;
      let Errors1 = {};

      var Title1 = document.getElementById("Title1").value.trim();
      var Notes = document.getElementById("Notes").value.trim();
      var IsExist = await this.CheckExistBookedMeetingTitle1(Title1);
      if (Title1 == "") {
        formIsValid = false;
        Errors1["Title1"] = "Please Enter Title";
      }
      if (Notes == "") {
        formIsValid = false;
        Errors1["Notes"] = "Please Enter Notes";
      }
      if (IsExist == true) {
        formIsValid = false;
      }
      // if (this.state.nameAvailable != null) {
      //   formIsValid = false;
      // }
      this.setState({ Errors1: Errors1 });

      return formIsValid;
    } catch (error) {
      toast.error("An error occurred.Please try again.");
    }
  }

  handleChangeNote(field, e) {
    let Fields1 = this.state.Fields1;
    Fields1[field] = e.target.value;
    this.setState({ Fields1 });
    if (Fields1.Title != "") {
      this.state.Errors1.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistBookedMeetingTitle1(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.Errors1.Title = null;
    }
  }

  async CheckExistBookedMeetingTitle1(Title) {
    try {
      var str_in = {
        MeetingID: this.state.MeetingID,
        ClientID: this.state.ClientID,
        Role: this.state.Role,
      };
      var resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/PotentialMeetingStatusExistsNotes",
        method: "POST",
        data: str_in,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (this.state.OldTitleUpdate == Title) {
            return false;
          } else {
            let Errors1 = {};
            this.setState({ TitleAvailable: resdata.data.Data[0].Title });
            Errors1["Title1"] = "";
            this.setState({ Errors1: Errors1 });
            return true;
          }
        } else {
          return false;
        }
      }
    } catch (error) {
      toast.error("An error occurred.Please try again.");
    }
  }

  async SaveUpdateBtn(e, closeCallback) {
    try {
      var Title = document.getElementById("Title1").value;
      var Notes = document.getElementById("Notes").value;

      // e.preventDefault();
      // e.currentTarget.disabled = true;
      this.setState({ BtnDisabledUpdate: true });
      var FinalValidation = await this.fromValidationNote();
      if (FinalValidation == true) {
        var Data = {
          _id: this.state.id,
          Title: Title,
          Note: Notes,
          LastUpdatedBy: this.state.CUserID,
          LastUpdatedDate: new Date(),
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotenialNotesMeetingUpdate",
          method: "POST",
          data: Data,
        })
          .then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div>
                  Update Note <br />
                  Note update successfully.
                </div>
              );
              this.NotesGet();
              // history.push({
              //   pathname: "/viewpotentialmeeting",
              //   state: { data: this.state.MeetingID, Pagename: "" },
              // });
              // Call the close callback function
              closeCallback();
              this.setState({ BtnDisabledUpdate: false });
            } else {
              toast.error(res.data.Message);
              this.setState({ BtnDisabledUpdate: false });
            }
          })
          .catch((error) => {
            toast.error("An error occurred while saving. Please try again.");
            this.setState({ IsLoading: false });
            this.setState({ BtnDisabledUpdate: true });
          });
      } else {
        this.setState({ BtnDisabledUpdate: false });
        // document.getElementById("submitupdate").disabled = false;
      }
    } catch (error) {
      toast.error("An error occurred while saving. Please try again.");
      this.setState({ IsLoading: false });
      this.setState({ BtnDisabledUpdate: true });
    }
  }

  //get sort field Data
  SortData(Field) {
    try {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("Search").value;
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        this.setState({ Sflag: false });
        var SortField = Field;
        var SortBy;
        if (this.state.SortedBy == 1) {
          SortBy = -1;
          this.setState({ SortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedBy: 1 });
        }
        this.setState({ SortField: Field });
        var InputParameter = {
          MeetingID: this.state.MeetingID,
          page: this.state.Page,
          rowsPerPage: this.state.RowsPerPage,
          sort: true,
          field: this.state.SortField,
          sortby: this.state.SortedBy,
          Search: this.state.Search,
          type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/GetPotentialMeetingNotes",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((resnotes) => {
          this.setState({ Data: resnotes.data.pagedata });
          this.setState({ Rows: resnotes.data.pagedata });
          this.setState({ Rlen: resnotes.data.totalcount });
          this.setState({ Flen: resnotes.data.totalcount });
          this.setState({ CountPage: resnotes.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred while saving. Please try again.");
          this.setState({ IsLoading: false });
        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (this.state.SortedBy == 1) {
          SortBy = -1;
          this.setState({ SortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedBy: 1 });
        }
        this.setState({ SortField: Field });
        var InputParameter = {
          MeetingID: this.state.MeetingID,
          page: this.state.Page,
          rowsPerPage: this.state.RowsPerPage,
          sort: true,
          field: this.state.SortField,
          sortby: this.state.SortedBy,
          Search: this.state.Search,
          type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/GetPotentialMeetingNotes",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((resnotes) => {
          this.setState({ Data: resnotes.data.pagedata });
          this.setState({ Rows: resnotes.data.pagedata });
          this.setState({ Rlen: resnotes.data.totalcount });
          this.setState({ Flen: resnotes.data.totalcount });
          this.setState({ CountPage: resnotes.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred while saving. Please try again.");
          this.setState({ IsLoading: false });
        });
      }
    } catch (error) {
      toast.error("An error occurred.Please try again.");
    }
  }

  Handlevalidationfornotesedit() {
    let Errors1 = {};
    Errors1["Title1"] = "";
    Errors1["Notes"] = "";
    this.state.TitleAvailable = null;
    this.setState({ Errors1: Errors1 });
  }

  Handlevalidationfornotesadd() {
    this.setState({ ShowNotePopup: false });
    let Errors = {};
    Errors["Title22"] = "";
    Errors["Notes22"] = "";
    this.state.TitleAvailable = null;
    this.setState({ Errors: Errors });
  }

  render() {
    return (
      <>
        {/* <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> */}
        {this.state.IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
        <div className="adminmain"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="px-2">
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h4 className="headertitlebd py-4 mt-0 pl-0">
                    Edit Potential Meeting
                  </h4>
                </div>
                <div className="col-md-8 col-sm-8 pt-3 my-1 pb-3 text-right">
                  <a
                    onClick={() => {
                      this.MeetingsConvertAdd();
                    }}
                    className="btn btngroup m-btn mr-1"
                  >
                    <i className="la la-exchange mr-1"></i>
                    <span className="Mobile_button">Convert</span>
                  </a>
                  {this.state.AllRes.map((value) => (
                    <a
                      className="btn btngroup m-btn mr-1"
                      onClick={() => {
                        this.EditBtn(value._id);
                      }}
                    >
                      <i className="la flaticon-edit-1 mr-1"></i>
                      <span className="Mobile_button">Edit</span>
                    </a>
                  ))}
                  <a
                    className="btn btngroup2 m-btn"
                    onClick={this.BackBtn}
                  >
                    <i className="la la-arrow-circle-left mr-1"></i>
                    <span className="Mobile_button">Back</span>
                  </a>
                </div>
              </div>
              <div className="bg-white px-4">
              {this.state.AllRes.map((value) => (
                <div className="row pt-4">
                  {this.state.ClientPotential == true ? (
                    <>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Client
                          </label>
                          <div className="col-lg-7 View_text">
                            <label>{this.state.CName}</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group"></div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        First Name
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.FirstName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Name
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.LastName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Title
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Title}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Company}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Email
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Email}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        LinkedIn URL
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.LinkedInUrl}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Source
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{this.state.MeetingSource}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Owner
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{this.state.MeetingOwner}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reminder Email Account
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{this.state.ReminderEmailAccount}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Engagement Status
                      </label>

                      <div className="col-lg-7 View_text">
                        <label>{this.state.EngagamentStatus}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhone}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone Ext
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhoneExt}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Phone
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.CompanyPhone}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set By
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{this.state.MeetingSetByName}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-5 view-lable-meet potential_text">
                      Last Email Campaign :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value?.prospectslist?.CampaignDetails?.Name}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Last Email Step
                      </label>
                      <div className="col-lg-7 View_text">
                      <label>{value?.prospectslist?.CampaignDetails?.LastEmailStep}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              </div>
            </div>
          
        
        <div className="mt-4 px-2">
          <div className="paddcols">
            <div className="row pt-2">
              <div className="col d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
              </div>
              <div className="col text-right">
                <button
                  onClick={() => {
                    this.setState({ ShowNotePopup: true });
                  }}
                  className="btn btngroup m-btn "
                >
                  <i class="la la-plus la-icon-font-size-13"></i>
                  <span className="Mobile_button">Add</span>
                </button>
                <Popup open={this.state.ShowNotePopup}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-4">
                          <h5 className="mb-0">Note</h5>
                        </div>
                        <div className="modal-body px-5 py-4">
                          <div class="row mb-3">
                            <label class="col-lg-2 col-form-label text-left">
                              Title
                            </label>
                            <div class="col-lg-10">
                              <input
                                class="form-control m-input"
                                Data-val="true"
                                Data-val-remote="Title already exist."
                                Data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                Data-val-required="Please enter title"
                                id="Title22"
                                name="Title22"
                                placeholder="Enter title"
                                type="text"
                                onBlur={this.handleChangeNotes.bind(
                                  this,
                                  "Title22"
                                )}
                              />
                              <span style={{ color: "red" }}>
                                {this.state.Errors["Title22"]}
                              </span>
                              {this.state.TitleAvailable && (
                                <span style={{ color: "red" }}>
                                  Title already exist.
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="row">
                            <label class="col-lg-2 col-form-label text-left">
                              Note
                            </label>
                            <div class="col-lg-10">
                              <textarea
                                class="form-control m-input minheight390"
                                Data-val="true"
                                Data-val-remote="Title already exist."
                                Data-val-required="Please enter title"
                                id="Notes22"
                                name="Notes22"
                                placeholder="Enter note"
                                type="text"
                                // onChange={this.handleChangeNotes.bind(
                                //   this,
                                //   "Notes22"
                                // )}
                              >
                                {""}
                              </textarea>
                              <span style={{ color: "red" }}>
                                {this.state.Errors["Notes22"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="modal-content bordernone text-right border-top pb-4">
                          <div class="row">
                            <div class="col-lg-12 pull-right">
                              <a
                                id="backtolist"
                                class="btn btn-secondary mr-2"
                                onClick={this.Handlevalidationfornotesadd}
                              >
                                cancel
                              </a>
                              <button
                                id="submitSave"
                                class="btn btn-primary btn-lightgreen mr-1"
                                href="javascript:void(0);"
                                value="Save"
                                onClick={this.SaveBtn}
                                disabled={this.state.BtnDisabledSave}
                              >
                                <i class="la la-save"></i> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.chengeRowSelected}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="Search"
                    id="Search"
                    onKeyPress={(event) => this.RequestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData("Note");
                          }}
                        >
                          Notes
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData("CreatedDate");
                          }}
                        >
                          Created Date
                        </a>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Rows?.length === 0 ? (
                      <p className="text-center">No Data available in table</p>
                    ) : (
                      this.state.Rows?.map((row) => (
                        <TableRow>
                          <TableCell>{row?.Title}</TableCell>
                          <TableCell>{row?.Note}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row?.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                // this.editpotentialmeetingnote(row.PotentialMeetingNoteID);
                                this.EditsPage(row._id);
                              }}
                            >
                              <Popup
                                trigger={
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-3 px-3">
                                          <h4 className="mb-0">Note</h4>
                                        </div>
                                        <div className="modal-body p-5">
                                          <div class="row mb-3">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Title
                                            </label>
                                            <div class="col-lg-10">
                                              <input
                                                class="form-control m-input"
                                                Data-val="true"
                                                Data-val-remote="Title already exist."
                                                Data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                Data-val-required="Please enter title"
                                                id="Title1"
                                                name="Title1"
                                                placeholder="Enter title"
                                                type="text"
                                                onBlur={this.handleChangeNote.bind(
                                                  this,
                                                  "Title1"
                                                )}
                                                // value={
                                                //   this.state.Fields1["Title1"]
                                                // }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.state.Errors1["Title1"]}
                                              </span>
                                              {this.state.TitleAvailable && (
                                                <span style={{ color: "red" }}>
                                                  Title already exist.
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Note
                                            </label>
                                            <div class="col-lg-10">
                                              <textarea
                                                class="form-control m-input minheight390"
                                                Data-val="true"
                                                Data-val-remote="Title already exist."
                                                Data-val-required="Please enter title"
                                                id="Notes"
                                                name="Notes"
                                                placeholder="Enter note"
                                                type="text"
                                                // onChange={this.handleChangeNote.bind(
                                                //   this,
                                                //   "Notes"
                                                // )}
                                                // value={
                                                //   this.state.Fields1["Notes"]
                                                // }
                                              >
                                                {" "}
                                              </textarea>
                                              <span style={{ color: "red" }}>
                                                {this.state.Errors1["Notes"]}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-content bordernone text-right">
                                          <div class="row">
                                            <div class="col-lg-12 pull-right">
                                              <a
                                                id="backtolist"
                                                class="btn btn-secondary mr-2"
                                                onClick={() => {
                                                  close(); this.Handlevalidationfornotesedit();
                                                }}
                                              >
                                                cancel
                                              </a>
                                              <button
                                                id="submitupdate"
                                                class="btn btn-primary btn-lightgreen mr-1"
                                                value="Save"
                                                disabled={this.state.BtnDisabledUpdate}
                                                onClick={(e) => {
                                                  // Pass the close function as a callback
                                                  this.SaveUpdateBtn(e, () => {
                                                    close(); // Call the close function when the update is successful
                                                  });
                                                }}
                                              >
                                                <i class="la la-save"></i> Save
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </a>
                            <a
                              className="btn-eyesicon"
                              onClick={() => {
                                this.DeleteClientName(
                                  row._id
                                );
                              }}
                            >
                              <i class="la flaticon-delete-1 delete-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.Sflag ? (
              <div class="row">
                <div class="col Pageright">
                <Pagination
                  component="div"
                  count={this.state.CountPage}
                  onChange={this.handleChangePage}
                  showFirstButton
                  showLastButton
                />
                </div>
                <div class="col DataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries (filtered from{" "}
                    {this.state.Flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col DataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries
                  </p>
                </div>
                <div class="col Pageright">
                  <Pagination className="pull-right"
                    component="div"
                    count={this.state.CountPage}
                    onChange={this.handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesHiveContactPage = connect(
  mapState,
  actionCreators
)(ViewPotentialMeetingsPage);
export { connectedAddSalesHiveContactPage as ViewPotentialMeetingsPage };
