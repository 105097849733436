import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";

import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

class AddColdCallerTeam extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      IsExist: false,
      UserID: null,
      CUserID: null,
      Role: Details.Role,
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    document.title = "Add Sales Development Reps Teams | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.ColdcallerTeamNameCheckExist(Name)
    if (Name== "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter cold caller team name";
    }
    if(IsExist == true){
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

    // handle change
    HandleChange(field, e) {
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Name != "") {
        this.state.Errors.Name = null;
        this.state.StatusAvailable = null;
        this.ColdcallerTeamNameCheckExist(e.target.value);
      } else {
        this.state.StatusAvailable = null;
        this.state.Errors.Name = null;
      }
    }

  //   cold caller team check exits
  async ColdcallerTeamNameCheckExist(ColdCallerTeamName) {
    var str_in = {
      UserID: this.state.UserID,
      ColdCallerTeamName: ColdCallerTeamName,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/cold_callers_team/ColdCallerTeamNameExists",
      method: "POST",
      data: str_in,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({
            StatusAvailable: resdata.data.Data[0]?.ColdCallerTeamName,
          });
          return true;
        } else {
          return false;
        }
      }
  }

  //   back button
  BackBtn() {
    history.push("/coldcallerteams");
  }

  async SaveBtn(e) {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag =  await this.FromValidation()
    if (Final_flag == true) {
      var ColdCallerTeamName =
        document.getElementById("Name").value;
      var ColdCallerTeamData = {
        UserID: this.state.UserID,
        ColdCallerTeamName: ColdCallerTeamName,
        CreatedBy: this.state.CUserID,
        CreatedDt: new Date().toISOString(),
        IsColdCallerTeamUpdate: false,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/cold_callers_team/ColdCallerTeamAdd",
        method: "POST",
        data: ColdCallerTeamData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div >Sales development teams added successfully.</div>);
          history.push("/coldcallerteams");
        } else {
          toast.error(res.data.Message);
        }
      });
    }else{
      document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlebd pl-0 mb-0">Add Sales Development Reps Teams</h4> 
              </div>
            </div>  
            <div class="bg-white">
              <div class="row">
                  <div class="col-lg-6 ml-3 boxsinput_group">
                  <div class="row d-flex">
                    <label class="col-lg-4  view-lable-meet potential_text">
                      {" "}
                      Name
                    </label>
                    <div class="col-lg-8 mt-2">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Cold Caller Team Name"
                        onBlur={this.HandleChange.bind(
                          this,
                          "Name"
                        )}
                        id="Name"
                        name="Name"
                        type="text"
                        placeholder="Enter sales development reps teams"
                      />
                      <span style={{ color: "red" }}>
                      {this.state.Errors["Name"]}
                    </span>
                      {this.state.StatusAvailable && (
                      <span style={{ color: "red" }}>
                        Cold caller team name already exist.
                      </span>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>  
              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>  
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddColdCallerTeam = connect(
  mapState,
  actionCreators
)(AddColdCallerTeam);
export { connectedAddColdCallerTeam as AddColdCallerTeam };
