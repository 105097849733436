import React, { useState } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
var CryptoJS = require("crypto-js");
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

class EditApiKeyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Apikey: [],
      ClientID: null,
      UserID: null,
      CUserID: null,
      ClientName: "",
      ValueKey: "",
      Copied: false,
      Role:""
    };
    this.inputRef = React.createRef();
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Edit | SalesHive`
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.ApikeyGet();
  }

  //Api get by id
  ApikeyGet() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      ApikeyId: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Apikey/ApikeyByIDGet",
      method: "POST",
      data: str_in,
    }).then((res) => {
      console.log(res);
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          this.setState({ ValueKey: res.data.Data[0]?.ApiKey });
          this.setState({ ClientName: res.data.Data[0]?.Name });
        }
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  //back
  BackBtn() {
    history.push("/apiKey");
  }

  //Update in api key
  SaveBtn(e) {
    e.preventDefault();
    document.getElementById("hideloding").style.display = "block";
    const Characters =
      "/ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=/";

    const GenrateNewApiKey = (length) => {
      let result = "";
      const CharactersLength = Characters.length;
      for (let i = 0; i < length; i++) {
        result += Characters.charAt(
          Math.floor(Math.random() * CharactersLength)
        );
      }
     
    //  document.getElementById("ApiKey").value = result
     this.setState({ValueKey:result})
      return result;
    };
    var data = {
      ApikeyId: this.state?.id,
      ApiKey: GenrateNewApiKey(44),
      LastUpdatedBy: this.state.CUserID,
      LastUpdatedDate: new Date(),
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Apikey/ApiKeyUpdate",
      method: "POST",
      data: data,
    }).then((res) => {
      //console.log(res);
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Edit api key
            <br />
            New api key generated successfully.
          </div>
        );
        // history.push("/apiKey");
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  editItem = () => {
    this.inputRef.current.select();
  };

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.ValueKey);
    this.editItem();
  };

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">Edit</h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row pt-4">
                <div className="col-lg-12 boxsinput_group">
                  <div className="row">
                    <label className="col-lg-2 view-lable-meet full-right">
                      Client Name
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <text id="clientname">{this.state.ClientName}</text>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 boxsinput_group">
                  <div className="row">
                    <label className="col-lg-2 view-lable-meet full-right">
                      API Key
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Api Key"
                        id="ApiKey"
                        value={this.state.ValueKey}
                        name="ApiKey"
                        type="text"
                        readOnly
                        ref={this.inputRef}
                      />
                    </div>
                    <div className="col-lg-2">
                      <div
                        className="btn btngroup"
                        onClick={this.copyToClipboard}
                      >
                        <span>Copy</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
              <div className="row"> 
                <div className="col mt-3">
                  <a
                    id="submit"
                    onClick={this.SaveBtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Generate New API Key
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditApiKeyPage = connect(
  mapState,
  actionCreators
)(EditApiKeyPage);
export { connectedEditApiKeyPage as EditApiKeyPage };
