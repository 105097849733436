import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Popup from "reactjs-popup";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import Swal from "sweetalert2";
import loadingicon from "../../../images/loading.gif";
import { LastPageSharp } from "@material-ui/icons";

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("OrderBy");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    document.title="Client Statuses | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    GetClientStatusList(Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  const GetClientStatusList = (UID, URole) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole,
    };
    const AccountCategoriesList = Axios({
      url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  const DeleteBtn = async (id, CSName) => {
    var InputParameters = {
      ID: id,
      Type: "User",
      ClientID: ClientID,
      Field: SortField,
      SortBy: SortedBy,
    };
    const ClientStatus = await Axios({
      url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusDropDown",
      method: "POST",
      data: InputParameters,
    });
    let Datas = ClientStatus.data.Data.filter(obj => obj._id !== id);
    var ClientStatusDropDown = [];
    Datas.map((val) => {
      ClientStatusDropDown[val._id] = `${val.ClientStatusName}`;
    });

    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a client statuses.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Replace Client Status",
          text: "Which value replace with'" + CSName + "'clientstatuses?",
          confirmButtonColor: "#34bfa3",
          cancelButtonColor: "#34bfa3",
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          reverseButtons: true,
          input: "select",
          inputOptions: ClientStatusDropDown,
          inputPlaceholder: ClientStatusDropDown[0],
          showCancelButton: true,
        }).then((Suecces) => {
          if (Suecces.isConfirmed) {
            var UpdateId = Suecces.value;
            var UpdatingParameters = {
              ClientStatusID: id,
              NewClientStatusID: UpdateId,
              LastUpdatedBy: UserID,
              LastUpdatedDt: new Date(),
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusDelete",
              method: "POST",
              data: UpdatingParameters,
            }).then(async (res) => {
              if (res) {
                if ((res.statusText = "Ok")) {
                  if (res.data.StatusMessage == "SUCCESS") {
                    Swal.fire(
                      "Deleted!",
                      "Client Statuses deleted successfully.",
                      "success"
                    );
                    SetPage(1)
                    SetSearch("")
                    document.getElementById("hideloding").style.display = "block";
                    document.getElementById("search").value = ""
                    GetClientStatusList(UserID, Role);
                    history.push("/clientstatuses")

                  }
                }
              }
            });
          } else if (Suecces.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const EditClientStatus = (id) => {
    history.push("/editclientstatuses", id);
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };

  return (
    <div>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>

      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientStatusName");
                    }}
                  >
                    Client Statuses
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("IsOldClient");
                    }}
                  >
                    Old Client
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("IsRemoveClientReporting");
                    }}
                  >
                    Remove From Client Reporting
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>{row.ClientStatusName}</TableCell>
                    <TableCell>
                      {row.IsOldClient === true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row.IsRemoveClientReporting === true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      <a title="Edit"
                        onClick={() => {
                          EditClientStatus(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a title="Delete"
                        onClick={() => {
                          DeleteBtn(row._id, row.ClientStatusName);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
