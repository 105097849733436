import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Alert from '@mui/material/Alert';
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const moment = require("moment");

class CAddBookedMeetingPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      meetingstatus: [],
      meetingsource: [],
      meetingowner: [],
      meetingtimezone: [],
      meetingaccount: [],
      meetingseniority: [],
      meetingsetbyResponder: [],
      meetingsetbyColdCallers: [],
      checked: true,
      StatusName: "",
      IsExist: false,
      meetingCustomField: [],
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      datevalue: null,
      FileRecording: null,
      FileName: "",
      PotentialMeetingID: "",
      PotentialMeetingIDHideAndShowAlert: "",
      OpenPotentialAlert: false,
      EmailAvailable: null,
      Role: null,
      MeetingSourceData: "",
      MeetingOwnerData: "",
      MeetingSetByData: "",
      MeetingReminderData: "",
      IsButtonDisabled: false,
      ContractVal: 0,
      IsValid: false,
      IsWebsite: true,
      WebsiteNotNull: false,
      IsInValidWebsite:false,
      IsValidEmail:false
    };
    this.pikerdatevalue = this.pikerdatevalue.bind(this);
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.handleChangeSinglePost = this.handleChangeSinglePost.bind(this);
    this.OnChangeUpload = this.OnChangeUpload.bind(this);
    this.CloseAlertOnClick = this.CloseAlertOnClick.bind(this);
    this.ContractValHandle = this.ContractValHandle.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.HandleChangedropdownBlur = this.HandleChangedropdownBlur.bind(this);
    this.HandleChangeotherFieldBlur = this.HandleChangeotherFieldBlur.bind(this);
    this.handleChangeWebsitecheck= this.handleChangeWebsitecheck.bind(this);
  }
  componentDidMount() {
    const element = document.getElementById('myElementScroll')
    element.scrollIntoView();
    document.title = `Add Booked Meetings | SalesHive`;
    if (
      this.props.location.state["Pagename"] == "Potential" ||
      this.props.location.state["Pagename"] == "LinkedInFollowUps"
    ) {
      this.setState({
        PotentialMeetingID: this.props.location.state?.data?.PotentialMeetingID,
      });
      this.setState({
        PotentialMeetingIDHideAndShowAlert:
          this.props.location.state?.data?.PotentialMeetingID,
      });
      this.setState({
        MeetingSourceData: this.props.location.state?.data?.MeetingSourceID,
      });
      this.setState({
        MeetingOwnerData: this.props.location.state?.data?.MeetingOwnerID,
      });
      this.setState({
        MeetingSetByData:
          this.props.location.state?.data?.ResponderColdCallersID,
      });
      this.setState({
        MeetingReminderData:
          this.props.location.state?.data?.ReminderEmailAccountID,
      });

      this.setState({
        ClientID:
          this.props.location.state?.data?.ClientID,
      });

      this.setState({
        UserID:
          this.props.location.state?.data?.UserID,
      });

      // 
      this.setState({ OpenPotentialAlert: true });
      document.getElementById("FirstName").value =
        this.props.location.state?.data?.FirstName;
      document.getElementById("LastName").value =
        this.props.location.state?.data?.LastName;
      document.getElementById("Title").value =
        this.props.location.state?.data?.Title;
      document.getElementById("Company").value =
        this.props.location.state?.data?.Company;
      document.getElementById("Email").value =
        this.props.location.state?.data?.Email;
      document.getElementById("LinkedInURL").value =
        this.props.location.state?.data?.LinkedInUrl;
      document.getElementById("DirectPhone").value =
        this.props.location.state?.data?.DirectPhone;
      document.getElementById("DirectPhoneExt").value =
        this.props.location.state?.data?.DirectPhoneExt;
      document.getElementById("CompanyPhone").value =
        this.props.location.state?.data?.CompanyPhone;
    }

    if (this.props.location.state["Pagename"] == "Meeting") {
      document.getElementById("FirstName").value =
        this.props.location.state.PushData[0]?.FirstName;
      document.getElementById("LastName").value =
        this.props.location.state.PushData[0]?.LastName;
      document.getElementById("Title").value =
        this.props.location.state.PushData[0]?.Title;
      document.getElementById("Company").value =
        this.props.location.state.PushData[0]?.Company;
      document.getElementById("Email").value =
        this.props.location.state.PushData[0]?.Email;
      document.getElementById("LinkedInURL").value =
        this.props.location.state.PushData[0]?.LinkedInURL;
      document.getElementById("DirectPhone").value =
        this.props.location.state.PushData[0]?.DirectPhone;
      document.getElementById("DirectPhoneExt").value =
        this.props.location.state.PushData[0]?.DirectPhoneExt;
      document.getElementById("CompanyPhone").value =
        this.props.location.state.PushData[0]?.CompanyPhone;
      document.getElementById("Website").value =
        this.props.location.state.PushData[0]?.Website;
    }
    this.GetBookedmeeting();
    // this.GetEmailmeeting();
  }

  //get book meeting
  GetBookedmeeting() {
    var Details = GetUserDetails();
    if (
      this.props.location.state["Pagename"] == "Potential" ||
      this.props.location.state["Pagename"] == "LinkedInFollowUps"
    ) {
      var str_in = {
        ClientID: this.props.location.state?.data?.ClientID,
        UserID: this.props.location.state?.data?.UserID,
        Role: Details.Role,
      };
    }else{
      var str_in = {
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Role: Details.Role,
      };
    }
   
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/FindMeetingAllStatus",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      //
      this.setState({
        meetingstatus: result.data?.meetingstatus,
        meetingsource: result.data?.meetingsource,
        meetingowner: result.data?.meetingowner,
        meetingtimezone: result.data?.meetingtimezone,
        meetingaccount: result.data?.meetingaccount,
        meetingseniority: result.data?.meetingseniority,
        meetingsetbyResponder: result.data?.meetingsetby[0]?.ResponderDetails,
        meetingsetbyColdCallers:result.data?.meetingsetby[0]?.ColdCallersDetails,
        meetingCustomField: result.data?.meetingCustomField,
      });
      document.getElementById("hideloding").style.display = "none";
    });
  }

  async FromValidation() {
    let formIsValid = true;
    this.setState({ IsValid: false });
    let fields = this.state.fields;
    var FirstName = document.getElementById("FirstName").value.trim();
    var LastName = document.getElementById("LastName").value.trim();
    var Title = document.getElementById("Title").value.trim();
    var Company = document.getElementById("Company").value.trim();
    var Email = document.getElementById("Email").value.trim();
    var Website = document.getElementById("Website").value.trim();
    var TimeZoneID =
      document.getElementById("TimeZoneID").value;

    var IsExistEmail = await this.CheckExistBookedMeetingName(Email)
    let IsExistWebsite = await this.CheckWebSite(Website)
    let errors = {};
    if ( FirstName == "") {
      formIsValid = false;
      errors["FirstName"] = "Please enter first name";
    }
    if ( LastName == "") {
      formIsValid = false;
      errors["LastName"] = "Please enter last name";
    }
    if (this.state.datevalue === null) {
      formIsValid = false;
      errors["datevalue"] = "Please enter Meeting Start Date";
    }
    if (Title == "") {
      formIsValid = false;
      errors["Title"] = "Please enter Title";
    }
    if ( Company == "") {
      formIsValid = false;
      errors["Company"] = "Please enter Company";
    }

    //website
    if (IsExistWebsite == "InvalidWebsite") {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
    }
    if (IsExistWebsite == "BlankDataWebsite" && Website == "") {
      formIsValid = false;
      errors["Website"] = "Please enter Website";
    }

    //email
    if (IsExistEmail == "InvalidEmail") {
      formIsValid = false;
      errors["Email"] = "invalid email.";
    }
    if (IsExistEmail == "ExistEmail") {
      formIsValid = false;
      errors["Email"] = "Email Already Exist";
    }

    if (IsExistEmail == "BlankData" && Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }
    if (this.props.location.state["Pagename"] != "Potential" && this.props.location.state["Pagename"] != "LinkedInFollowUps" ) {
      var MeetingSourceID = document.getElementById("MeetingSourceID").value;
      var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
      var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
      if (MeetingSourceID == "") {
        formIsValid = false;
        errors["MeetingSourceID"] = "Please select Meeting Source";
      }
      // if (!fields["MeetingSetbyID"]) {
      //   formIsValid = false;
      //   errors["MeetingSetbyID"] = "Please select Meeting Set by";
      // }
      if (ReminderEmailAccount == "") {
        formIsValid = false;
        errors["ClientPOCID"] = "Please select reminder email account";
      }
      if (MeetingOwnerID == "") {
        formIsValid = false;
        errors["MeetingOwnerID"] = "Please select owner";
      }
    }

    if (TimeZoneID == "") {
      formIsValid = false;
      errors["TimeZoneID"] = "Please select Time Zone";
    }

    // if (!fields["SeniorityID"]) {
    //   formIsValid = false;
    //   errors["SeniorityID"] = "Please select Seniority";
    // }
    // if (IsExistEmail == true) {
    //   formIsValid = false;
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Email != "") {
      this.state.errors.Email = null;
      this.state.EmailAvailable = null;
      this.CheckExistBookedMeetingName(e.target.value);
    } else {
      this.state.EmailAvailable = null;
      this.state.errors.Email = null;
    }
  }

  async CheckWebSite(Email) {
    var regexp = new RegExp(
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); 
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidWebsite";
      } else {
          return "AllDoneWebsite";
      }
    } else {
      return "BlankDataWebsite";
    }
  }

  async handleChangeWebsitecheck() {
    let errors = { ...this.state.errors };
    var Website = document.getElementById("Website").value.trim();
    if (Website == "") {
      errors["Website"] = "Please enter Website";
    } else {
      let IsExist = await this.CheckWebSite(Website);
      if (IsExist == "InvalidWebsite") {
        errors["Website"] = "Invalid website!";
      }
      if (IsExist == "AllDoneWebsite") {
        errors["Website"] = null;
      }
    }
    this.setState({ errors: errors });
  }

  async HandleChangedropdownBlur(Name) {
    let errors = { ...this.state.errors };
    var TimeZoneID =
    document.getElementById("TimeZoneID").value;
    if (this.props.location.state["Pagename"] != "Potential" && this.props.location.state["Pagename"] != "LinkedInFollowUps" ) {
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var ReminderEmailAccount = document.getElementById("ClientPOCID").value;

    
    if (Name == "MeetingSource") {
      if (MeetingSourceID == "") {
        errors["MeetingSourceID"] = "Please select Meeting Source";
      } else {
        errors["MeetingSourceID"] = null;
      }
    }
    if (Name == "ReminderEmailAccount") {
      if (ReminderEmailAccount == "") {
        errors["ClientPOCID"] = "Please select reminder email account";
      } else {
        errors["ClientPOCID"] = null;
      }
    }
    if (Name == "MeetingOwner") {
      if (MeetingOwnerID == "") {
        errors["MeetingOwnerID"] = "Please select owner";
      } else {
        errors["MeetingOwnerID"] = null;
      }
    }
    }

    if (Name == "TimeZoneID") {
      if (TimeZoneID == "") {
        errors["TimeZoneID"] = "Please select Time Zone";
      } else {
        errors["TimeZoneID"] = null;
      }
    }
    this.setState({ errors: errors });
  }

  async HandleChangeotherFieldBlur(Name) {
    
    let errors = { ...this.state.errors };
    var FirstName = document.getElementById("FirstName").value.trim();
    var LastName = document.getElementById("LastName").value.trim();
    var Title = document.getElementById("Title").value.trim();
    var Company = document.getElementById("Company").value.trim();

  
    if (Name == "FirstName") {
    if (FirstName == "") {
      
      errors["FirstName"] = "Please enter first name";
    }else {
      errors["FirstName"] = null;
    }
  }
  if (Name == "LastName") {
    if (LastName == "") {
      
      errors["LastName"] = "Please enter last name";
    }else {
      errors["LastName"] = null;
    }
  }
  if (Name == "Title") {
    if (Title == "") {
      
      errors["Title"] = "Please enter Title";
    }else {
      errors["Title"] = null;
    }
  }
  if (Name == "Company") {
    if (Company == "") {
      
      errors["Company"] = "Please enter Company";
    }else {
      errors["Company"] = null;
    }
  }
    this.setState({ errors: errors });
  }

  async HandleChangeBlur() {
    let errors = { ...this.state.errors };
    var Email = document.getElementById("Email").value.trim();
    if (Email == "") {
      errors["Email"] = "Please enter Email";
    } else {
      let IsExist = await this.CheckExistBookedMeetingName(Email);
      if (IsExist == "InvalidEmail") {
        errors["Email"] = "invalid email.";
      }
      if (IsExist == "ExistEmail") {
        errors["Email"] = "Email Already Exist";
      }
      if (IsExist == "AllDone") {
        errors["Email"] = null;
      }
    }

    this.setState({ errors: errors });
  }

  // handleChangeEmailcheck() {
  //   var Website = document.getElementById("Email").value.trim();
  //   if (Website != "") {
  //     let errors = {};
  //     this.state.errors.Website = null;
  //     this.CheckWebSite(Website)
  //     errors["Website"] = "Invalid website!";
  //   } else {
  //     // this.state.statusAvailable = null;
  //     this.state.errors.Website = null;
  //   }
  // }


  // check exit Email
  async CheckExistBookedMeetingName(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidEmail";
      } else {
        // this.setState({ IsExist: false });
        var str_in = {
          ClientID: this.state.ClientID,
          Email: Email,
          Role: this.state.Role,
        };
        var resdata = await Axios({
          url:
            CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingCheckExists",
          method: "POST",
          data: str_in,
        });
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ EmailAvailable: resdata.data.Data[0]?.Email });
            return "ExistEmail";
          } else {
            return "AllDone";
          }
        }
      }
    } else {
      return "BlankData";
    }
  }

  backbtn() {
    if(this.props.location.state["Pagename"] == "Potential"){
      history.push(this.props.location.state["BackPage"])
    }else if(this.props.location.state["Pagename"] == "Meeting"){

        history.push({ pathname: this.props.location.state["BackPage"], state: { data: this.props.location.data, EmailData:this.props.location.EmailData}})

    }else if(this.props.location.state["Pagename"] == "AllClientMeeting"){
      history.push(this.props.location.state["BackPage"])
    }else if(this.props.location.state["Pagename"] == "coppotunities"){
      history.push(this.props.location.state["BackPage"])
    }
    else if(this.props.location.state["Pagename"] == "AccountContact"){
      history.push({
        pathname: "/cviewaccount",
        state: { data: this.props.location.state.data },
      });
    }
    else if(this.props.location.state["Pagename"] == "kpiMeeting"){
      history.push("/ckpis");
    }
    else{
      history.push("/cbookedmeetings");
    }
    //history.push("/bookedmeetings");
  }

  async savebtn(e) {
    this.state.errors.Email = null;
    this.state.EmailAvailable = null;
    this.setState({ IsButtonDisabled: true });

    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    var ContractValue = document.getElementById("ContractValue").value;
    if (Final_flag == true) {
      document.getElementById("hideloding").style.display = "block";
      var UTCMeetingDate = moment(this.state.datevalue)
        .format("MM/DD/YYYY HH:mm A")
        .toString();
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Title = document.getElementById("Title").value;
      var Company = document.getElementById("Company").value;
      var Email = document.getElementById("Email").value;
      var LinkedInURL = document.getElementById("LinkedInURL").value;
      var DirectPhone = document.getElementById("DirectPhone").value;
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
      var CompanyPhone = document.getElementById("CompanyPhone").value;
      var Status = document.getElementById("Status").value;
      var MeetingSourceID = null;
      var MeetingOwnerID = null;
      var ReminderEmailAccount = null;
      var MeetingSetbyID = null;
      if (this.props.location.state["Pagename"] == "Potential") {
        MeetingSourceID = this.state.MeetingSourceData;
        MeetingOwnerID = this.state.MeetingOwnerData;
        ReminderEmailAccount = this.state.MeetingReminderData;
        MeetingSetbyID = this.state.MeetingSetByData;
      } else {
        MeetingSourceID = document.getElementById("MeetingSourceID").value;
        MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
        ReminderEmailAccount = document.getElementById("ClientPOCID").value;
        MeetingSetbyID = document.getElementById("MeetingSetbyID").value;
      }
      var EventCreatedDt = document.getElementById("EventCreatedDt").value;
      var TimeZoneID = document.getElementById("TimeZoneID").value;

      var Industry = document.getElementById("Industry").value;
      var SeniorityID = document.getElementById("SeniorityID").value;
      var ExternalAccountIDs =
        document.getElementById("ExternalAccountID").value;
      var ExternalContactIDs =
        document.getElementById("ExternalContactID").value;
      var Website = document.getElementById("Website").value;
      var ExternalField1 = document.getElementById("ExternalField1").value;
      var ExternalField2 = document.getElementById("ExternalField2").value;
      var ExternalField3 = document.getElementById("ExternalField3").value;

      var TZdata = {
        TimeZoneID: TimeZoneID,
      };
      let TZdetails = await Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetTimezoneMeeting",
        method: "POST",
        data: TZdata,
      });
      var FieldID = null;
      var FieldName = "";

      if (TZdetails.data.length > 0) {
        FieldID = TZdetails.data[0].FieldID;
        FieldName = TZdetails.data[0].FieldName;
      }

      var BookedMeetingCustomFieldValueArray = [];
      this.state.meetingCustomField.map((value) => {
        let customval = document.getElementById(value._id)?.value;
        let customvalid = value._id;
        if (customval.length !== 0) {
          BookedMeetingCustomFieldValueArray.push({
            customval,
            customvalid,
          });
        }
      });

      var data = {
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Title: Title,
        Company: Company,
        Website: Website,
        LinkedInUrl: LinkedInURL,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        Status: this.state.StatusName,
        StatusID: Status,
        MeetingSourceID: MeetingSourceID,
        MeetingOwnerID: MeetingOwnerID,
        TimeZoneID: TimeZoneID,
        ContractValue: this.state.ContractVal,
        Industry: Industry,
        ResponderColdCallersType: MeetingSetbyID?.charAt(0),
        ResponderColdCallersID: MeetingSetbyID,
        IsReminderMailSent: this.state.checked,
        RecordingAudioPath: this.state.FileName,
        ReminderEmailAccountID: ReminderEmailAccount,
        SeniorityID: SeniorityID,
        EventStartDtByTimeZone: UTCMeetingDate,
        EventStartDt: UTCMeetingDate,
        EventCreatedDt: EventCreatedDt,
        Role: this.state.Role,
        ExternalField1: ExternalField1,
        ExternalField2: ExternalField2,
        ExternalField3: ExternalField3,
        IsDeleted: false,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
        CustomMeetingDetails: BookedMeetingCustomFieldValueArray,
        FieldID: FieldID,
        FieldName: FieldName,
        ExternalAccountID: ExternalAccountIDs,
        ExternalContactID: ExternalContactIDs,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          if (
            this.props.location.state["Pagename"] == "Potential" ||
            this.state.PotentialMeetingID != ""
          ) {
            var data = {
              _id: this.state.PotentialMeetingID,
              LastUpdatedDate: new Date(),
              LastUpdatedBy: this.state.UserID,
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL +
                "/potentialmeeting/DeletePotentialMeeting",
              method: "POST",
              data: data,
            });
          }
          toast.success(
            <div>
              Add Meeting
              <br />
              {Title} Meeting added successfully!
            </div>
          );
          if(this.props.location.state["Pagename"] == "Potential"){
            history.push(this.props.location.state["BackPage"])
          }else if(this.props.location.state["Pagename"] == "Meeting"){
      
              history.push({ pathname: this.props.location.state["BackPage"], state: { data: this.props.location.data, EmailData:this.props.location.EmailData}})
      
          }else if(this.props.location.state["Pagename"] == "AllClientMeeting"){
            history.push(this.props.location.state["BackPage"])
          }else if(this.props.location.state["Pagename"] == "coppotunities"){
            history.push(this.props.location.state["BackPage"])
          } else if(this.props.location.state["Pagename"] == "AccountContact"){
            history.push({
              pathname: "/cviewaccount",
              state: { data: this.props.location.state.data },
            });
          }else if(this.props.location.state["Pagename"] == "kpiMeeting"){
            history.push("/ckpis");
          }
          else{
            history.push("/cbookedmeetings");
          }
          //history.push("/bookedmeetings");
          //const formData = new FormData();
          //formData.append('FileRecord',this.state.FileRecording);
          // Axios({
          //   url: CommonConstants.MOL_APIURL + "/bookedmeeting/UploadRecording",
          //   method: "POST",
          //   data: formData,
          // }).then((resupload) => {
          // toast.success(
          //   <div className="toastsize">
          //     Booked Meeting
          //     <br />
          //     Booked meeting added successfully.
          //   </div>
          // );
          // history.push("/bookedmeetings");
          // })
          // .catch((err) => ('Error occurred', err))
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
          document.getElementById("hideloding").style.display = "none";
        }
      });
    } else {
      // document.getElementById("submit").disabled = false;
      this.setState({ IsButtonDisabled: false });
      document.getElementById("hideloding").style.display = "none";
    }
  }

  pikerdatevalue(newvalue) {
    this.setState({ datevalue: newvalue });
  }

  handleChangecheck = (e) => {
    const { checked } = e.target;
    this.setState({
      checked: checked,
    });
  };

  handleChangelist = (e) => {
    var str_in = {
      MeetingStatusID: e.target.value,
      ClientID: this.state.ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingStatusName",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        // 
        this.setState({ StatusName: res.data.data[0]?.Status });
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  OnChangeUpload(e) {
    this.setState({ FileName: e.target.files[0].name });
    this.setState({ FileRecording: e.target.files[0] });
  }

  handleChangeSinglePost(value, id) {}

  CloseAlertOnClick() {
    this.setState({ OpenPotentialAlert: false });
    this.setState({ PotentialMeetingIDHideAndShowAlert: "" });
  }
  //default change the state
  handleDropdownChangeMeetingSource = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingSourceData: selectedValue });
  };

  handleDropdownChangeMeetingOwne = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingOwnerData: selectedValue });
  };

  handleDropdownChangeMeetingSetBy = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingSetByData: selectedValue });
  };

  handleDropdownChangeMeetingReminder = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingReminderData: selectedValue });
  };

  ContractValHandle = (e) => {
    this.setState({ ContractVal: e.target.value });
  };

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain" id="myElementScroll"> 
            <Sidebar className="" />
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad">
                    Add Meeting
                  </h4>
                </div>
              </div>
              {this.state.PotentialMeetingIDHideAndShowAlert != "" ? (
                <div className="py-4">
                  <Alert
                    className="text-success"
                    disabled={this.state.OpenPotentialAlert}
                    variant="outlined"
                    onClose={this.CloseAlertOnClick}
                    icon={false}
                  >
                    <strong color="info">Note: </strong>Potential meeting has
                    been converted into a meeting. Please fill out the remaining
                    detail.
                  </Alert>
                </div>
              ) : (
                ""
              )}

              <div className="px-3 py-3 bg-white">
              <div className="row borderbtnm px-4 py-3">
                <div className="col padd-0">
                  <h3 className=" float-left addpotential_header pl-0">
                    Contact Info
                  </h3>
                </div>
              </div>

              <div className="row pt-1 ">
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("FirstName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["FirstName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("LastName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LastName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("Title")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("Company")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Company"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                        onBlur={this.HandleChangeBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInURL"
                        name="LinkedInURL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhone"
                        name="DirectPhone"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone ext"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhoneExt"
                        name="DirectPhoneExt"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="Website"
                        name="Website"
                        type="text"
                        onBlur={this.handleChangeWebsitecheck}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Website"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
           
              <div className="row borderbtnm bordertop mt-4 px-4 pt-4 pb-3">
                <div className="col padd-0">
                  <h3 className=" float-left addpotential_header">
                    Meeting Info
                  </h3>
                </div>
              </div>

              <div className="row px-2 pt-1">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Status
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field Status must be a number."
                        id="Status"
                        name="Status"
                        onChange={(e) => this.handleChangelist(e)}
                      >
                        {/* <option value="">--Select--</option> */}
                        {this.state.meetingstatus?.map((value) => (
                          <option value={value?._id}>
                            {value?.Status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  {this.props.location.state["Pagename"] == "Potential" ||
                  this.props.location.state["Pagename"] ==
                    "LinkedInFollowUps" ? (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Source
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ContactSourceID must be a number."
                          // id="MeetingSourceID"
                          name="MeetingSourceID"
                          value={this.state.MeetingSourceData}
                          onChange={this.handleDropdownChangeMeetingSource}
                        >
                          {this.state.meetingsource.map((value) => (
                            <option value={value?._id}>
                              {value?.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Source
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ContactSourceID must be a number."
                          id="MeetingSourceID"
                          name="MeetingSourceID"
                          onChange={() =>
                            this.HandleChangedropdownBlur("MeetingSource")
                          }
                        >
                          <option value="">--Select--</option>
                          {this.state.meetingsource.map((value) => (
                            <option value={value?._id}>
                              {value?.Name}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["MeetingSourceID"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Set Date
                    </label>
                    <div class="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="EventCreatedDt"
                        name="EventCreatedDt"
                        type="date"
                        defaultValue={moment(new Date().toDateString()).format(
                          "YYYY-MM-DD"
                        )}
                      />

                      {/* <p id="date" className="errorinput">please select date</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  {this.props.location.state["Pagename"] == "Potential" ||
                  this.props.location.state["Pagename"] ==
                    "LinkedInFollowUps" ? (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set By
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field SalesRepID must be a number."
                          name="MeetingSetbyID"
                          value={this.state.MeetingSetByData}
                          onChange={this.handleDropdownChangeMeetingSetBy}
                        >
                          {/* <option value="">--Select--</option> */}
                          {this.state.meetingsetbyResponder.length > 0
                            ? this.state.meetingsetbyResponder?.map((value) => (
                                <option value={"R" + value?.value}>
                                  {value?.label}
                                </option>
                              ))
                            : ""}
                          {this.state.meetingsetbyColdCallers.length > 0
                            ? this.state.meetingsetbyColdCallers?.map(
                                (value) => (
                                  <option value={"C" + value?.value}>
                                    {value?.label}
                                  </option>
                                )
                              )
                            : ""}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set By
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field SalesRepID must be a number."
                          id="MeetingSetbyID"
                          name="MeetingSetbyID"
                          onChange={this.handleChange.bind(
                            this,
                            "MeetingSetbyID"
                          )}
                        >
                          {/* <option value="">--Select--</option> */}
                          {this.state.meetingsetbyResponder.length > 0
                            ? this.state.meetingsetbyResponder?.map((value) => (
                                <option value={"R" + value?.value}>
                                  {value?.label}
                                </option>
                              ))
                            : ""}
                          {this.state.meetingsetbyColdCallers.length > 0
                            ? this.state.meetingsetbyColdCallers?.map(
                                (value) => (
                                  <option value={"C" + value?.value}>
                                    {value?.label}
                                  </option>
                                )
                              )
                            : ""}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["MeetingSetbyID"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Date
                    </label>
                    <div className="col-lg-7 timedatepikker">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          className="form-control m-input"
                          value={this.state.datevalue}
                          onChange={this.pikerdatevalue}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "red" }}>
                        {this.state.errors["datevalue"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Time Zone
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field SalesRepID must be a number."
                        id="TimeZoneID"
                        name="TimeZoneID"
                        onChange={() =>
                          this.HandleChangedropdownBlur("TimeZoneID")
                        }
                      >
                        <option value="">--Select--</option>
                        {this.state.meetingtimezone.map((value) => (
                          <option value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["TimeZoneID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  {this.props.location.state["Pagename"] == "Potential" ||
                  this.props.location.state["Pagename"] ==
                    "LinkedInFollowUps" ? (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Owner
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field MeetingOwnerID must be a number."
                          name="MeetingOwnerID"
                          value={this.state.MeetingOwnerData}
                          onChange={this.handleDropdownChangeMeetingOwne}
                        >
                          {this.state.meetingowner.map((value) => (
                            <option value={value._id}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Owner
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field MeetingOwnerID must be a number."
                          id="MeetingOwnerID"
                          name="MeetingOwnerID"
                          onChange={() =>
                            this.HandleChangedropdownBlur("MeetingOwner")
                          }
                        >
                          <option value="">--Select--</option>
                          {this.state.meetingowner.map((value) => (
                            <option value={value._id}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["MeetingOwnerID"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 boxsinput_group">
                  {this.props.location.state["Pagename"] == "Potential" ||
                  this.props.location.state["Pagename"] ==
                    "LinkedInFollowUps" ? (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reminder Email Account
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ClientPOCID must be a number."
                          //id="ClientPOCID"
                          name="ClientPOCID"
                          value={this.state.MeetingReminderData}
                          onChange={this.handleDropdownChangeMeetingReminder}
                        >
                          {this.state.meetingaccount.map((value) => (
                            <option value={value._id}>
                              {value.SMTPFromEmail}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reminder Email Account
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ClientPOCID must be a number."
                          id="ClientPOCID"
                          name="ClientPOCID"
                          //defaultValue={this.state.MeetingReminderData}
                          onChange={() =>
                            this.HandleChangedropdownBlur("ReminderEmailAccount")
                          }
                        >
                          <option value="">--Select--</option>
                          {this.state.meetingaccount.map((value) => (
                            <option value={value._id}>
                              {value.SMTPFromEmail}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["ClientPOCID"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contract Value
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter ContractValue name"
                        id="ContractValue"
                        name="ContractValue"
                        type="text"
                        value={this.state.ContractVal}
                        onChange={this.ContractValHandle}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Industry
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Industry "
                        id="Industry"
                        name="Industry"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Account ID
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ExternalAccountID"
                        name="ExternalAccountID"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Contact ID
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalContactID"
                        name="ExternalContactID"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 1
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ExternalField1"
                        name="ExternalField1"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 2
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ExternalField2"
                        name="ExternalField2"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 3
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ExternalField3"
                        name="ExternalField3"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Seniority
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field SalesRepID must be a number."
                        id="SeniorityID"
                        name="SeniorityID"
                        onChange={this.handleChange.bind(this, "SeniorityID")}
                      >
                       <option value="">--Select--</option>
                        {this.state.meetingseniority.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["SeniorityID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="pt-3">
                    <label class="check_bx">
                      <input
                        id="include_source"
                        type="checkbox"
                        onChange={(e) => this.handleChangecheck(e)}
                        defaultChecked={this.state.checked}
                      />
                      <span className="checkmark"></span>
                      Send Reminder
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Call Recording
                    </label>
                    <div className="col-lg-7">
                      <input
                        type="file"
                        id="uploadRecording"
                        accept=".mp3"
                        style={{ outline: "none" }}
                        autocomplete="off"
                        onChange={this.OnChangeUpload}
                      />
                      {/* <label id="recFileName" class="m--hide break-content">
                        File Name
                      </label> */}
                      <input
                        id="RecordingFilePath"
                        name="RecordingFilePath"
                        type="hidden"
                        value=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
           

            <div className="pt-5">
              <div className="row border-bottom">
                <div className="col-md-4 col-sm-4 d-flex align-items-center">
                  <h3 className="float-left addpotential_header pl-2">
                    Custom Meeting Field
                  </h3>
                </div>
              </div>

              <div className="row pt-3">
                {this.state.meetingCustomField.map((value, index) => {
                  return (
                    <div className="col-lg-6 boxsinput_group">
                      <div
                        className="row max-cols d-flex align-items-center px-5"
                        key={index}
                      >
                        <label className="col-lg-4">{value.FieldName}</label>
                        <div className="col-lg-7">
                          <input
                            class="form-control m-input"
                            id={value._id}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="row mt-3 mb-4 borderbtnm"></div>
              <div className="row mb-3 px-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    href="javascript:void(0);"
                    className="btn btn-primary btn-lightgreen mr-1"
                    value="Save"
                    onClick={this.savebtn}
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    href="javascript:void(0);"
                    className="btn btn-secondary"
                    value="Save"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
            </div>

            </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCAddBookedMeetingPage = connect(
  mapState,
  actionCreators
)(CAddBookedMeetingPage);
export { connectedCAddBookedMeetingPage as CAddBookedMeetingPage };
