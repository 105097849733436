import React from "react";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { connect } from "react-redux";

import logo from "../../../images/SalesHive.png";
import logobgs from "../../../images/Loginvector.svg"; 

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
const Uvalidation = require("../../../_helpers/user-validation");
const EmailTemplete = require("../../../_helpers/email-templete");
const Fileupload = require("../../../_helpers/fileupload");
import{GetUserDetails} from '../../../_helpers/Utility'

class CAddUsersPerClientPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      Open: false,
      FileObjects: [],
      FinalImg: "",
      LogoImageName:"",
      ClientID:"",
      UserID:"",
      CUserID:"",
      Errors:{},
      IsExist:false,
      AlreadyExists:false,
      Role:null,
      UserIDby:null
    };
    this.SubmitData = this.SubmitData.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }

  componentDidMount() {

    var Details = GetUserDetails();
    if(Details != null)
    {
      this.setState({ Role: Details.Role });
      this.setState({ UserIDby: Details.ChildUserID });
      // this.setState({ UserID: Details.ParentUserID });
    }

    const QueryParams = new URLSearchParams(window.location.search);
    const Tmp = QueryParams.get("email");
    const TmpCID=QueryParams.get("CID");
    const TmpUID=QueryParams.get("UID");
    var FinalTmp = Tmp.split(" ").join("+");
    var FinalTmp1 = TmpCID.split(" ").join("+");
    var FinalTmp2 = TmpUID.split(" ").join("+");
    var bytes = CryptoJS.AES.decrypt(FinalTmp, "my-secret-key@123");
    var Email = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    var CIDbytes = CryptoJS.AES.decrypt(FinalTmp1, "my-secret-key@123");
    var CID = JSON.parse(CIDbytes.toString(CryptoJS.enc.Utf8));
    var UIDbytes = CryptoJS.AES.decrypt(FinalTmp2, "my-secret-key@123");
    var UID = JSON.parse(UIDbytes.toString(CryptoJS.enc.Utf8));

    this.setState({ Email: Email });
    this.setState({ UserID: UID });
    this.setState({ ClientID: CID });
    const InputParameters = {
      Email:Email
    }
        Axios({
                url: CommonConstants.MOL_APIURL + "/AdminUser/ValidationAddUser",
                method: "POST",
                data: InputParameters,
              }).then((res) => {
                if (res.data.StatusMessage == "SUCCESS") {
                    if(res.data.Data.length > 0){
                        this.setState({AlreadyExists:true})
                    }else{
                      this.setState({AlreadyExists:false})

                    }
                }
              });
 

    FirstName_e.style.display = "none";
    UserName_e.style.display = "none";
    PassWord_e.style.display = "none";
    PassWord_length.style.display = "none";
    UserExists_e.style.display = "none";
    UserAdd_e.style.display = "none";
  }

//upload image
  HandleUpload(field, e) {
    
    if (
      Fileupload.checkMimeType(e.target.files[0]) &&
      Fileupload.checkFileSize(e.target.files[0])
    ) {
      var temp = URL.createObjectURL(e.target.files[0]);
      this.setState({
        FinalImg: e.target.files[0],
      });
      document.getElementById("imagePreview").innerHTML =
        '<img style="width: 145px;height: 145px;position: relative;border-radius: 50%;overflow: hidden;display: flex;justify-content: center;align-items: center;border: no" className="avatar-view container2" src=' +
        temp +
        ' id="avtar-preview" />';
    } else {
    }
  }
 //Check user per client exist
 CheckUserPerClient(Email){
 
  var InputParameters = {
    ClientID: this.state.ClientID,
    UserID: this.state.UserID,
    Email: Email,
    Role:this.state.Role
  };
  Axios({
    url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientExist",
    method: "POST",
    data: InputParameters,
  }).then((resdata) => {
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({ IsExist: true });
        let errors = {};
        errors["Email"] = "Link expire";
        this.setState({ Errors: errors });
      } else {
        this.setState({ IsExist: false });
      }
    }
  });
}
//Add Responder
  SubmitData(e) {
    e.preventDefault();
  e.currentTarget.disabled = true;

      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var UserName = document.getElementById("Email").value;
      var Tmppass = document.getElementById("PassWord").value;   
       var PassWord = CryptoJS.AES.encrypt(Tmppass,"my-secret-key@123").toString();
  
      if (
        FirstName == "" ||
        UserName == "" ||
        Tmppass == "" ||
        Tmppass.length < 6 ||
        Tmppass.length > 20
      ) {
        if (FirstName == "") {
          FirstName_e.style.display = "block";
        }else{
          FirstName_e.style.display = "none";

        }
       
        if (UserName == "") {
          UserName_e.style.display = "block";
        }else{
          UserName_e.style.display = "none";

        }
        if (Tmppass == "") {
          PassWord_e.style.display = "block";
          PassWord_length.style.display = "none";
  
        }else{
          PassWord_e.style.display = "none";
  
          if(Tmppass.length < 6){
            PassWord_length.style.display = "block";
          }else if(Tmppass.length > 20){
            PassWord_length.style.display = "block";
          }else{
            PassWord_length.style.display = "none";
  
          }
        }
      } else {
        FirstName_e.style.display = "none";
        UserName_e.style.display = "none";
        PassWord_e.style.display = "none";
        if(this.state.AlreadyExists == true){
          UserExists_e.style.display = "block";
        }else{
          if(this.state.FinalImg != ""){
            var name = this.state.FinalImg.name;
            var filetype = name.split(".");
            var file_Name;
            if (/\s/g.test(filetype[0])) {
              file_Name =
                filetype[0].replaceAll(/\s/g, "") +
                new Date().getTime() +
                "." +
                filetype[1] +
                "";
            } else {
              file_Name = filetype[0] + new Date().getTime() + "." + filetype[1] + "";
            }
            Fileupload.getBase64(this.state.FinalImg, (result) => {
              var rs = result;
              var splirs = rs.split("base64,");
              var _base64 = splirs[1];
              var myresult = {
                fields: "Coverimg",
                path: "./Content/UploadedFiles/UserProfilePic",
                file: _base64,
                imageName: file_Name,
              };
            
                  Axios({
                    url:CommonConstants.MOL_APIURL +"/responder_profile/ImageSave",
                    method: "POST",
                    data: myresult,
                  }).then((res) => {
                    this.setState({ LogoImageName: file_Name });
                    if (res.statusText == "OK") {
                      var data = {
                        FirstName:FirstName,
                        LastName: LastName,
                        Email: UserName,
                        ProfileImage:this.state.LogoImageName,
                        CreatedDt: new Date(),
                        Password: PassWord,
                        UserID: this.state.UserID,
                        ClientID: this.state.ClientID,
                        Role:this.state.Role,
                        CreatedBy:this.state.UserIDby
                      };
                      Axios({
                        url: CommonConstants.MOL_APIURL +"/users_per_client/UserPerClientAdd",
                        method: "POST",
                        data: data,
                      }).then((res) => {
                        if (res) {
                          UserAdd_e.style.display = "block";
                          localStorage.removeItem("LoginData");
                          localStorage.removeItem("id");
                          history.push('/');
                        }
                      });
                    }
                  });
              
            })
          }else{
            var data = {
              FirstName:FirstName,
              LastName: LastName,
              Email: UserName,
              ProfileImage:"",
              CreatedDt: new Date(),
              Password: PassWord,
              UserID: this.state.UserID,
              ClientID: this.state.ClientID,
              Role:this.state.Role,
              CreatedBy:this.state.UserIDby
            };
            Axios({
              url: CommonConstants.MOL_APIURL +"/users_per_client/UserPerClientAdd",
              method: "POST",
              data: data,
            }).then((res) => {
              if (res) {
                UserAdd_e.style.display = "block";
                localStorage.removeItem("LoginData");
                localStorage.removeItem("id");
                history.push('/');
              }
            });
          }
   
        }
   
  }
}

//back button
  BackButton() {
    history.push("/CUsersPerClient");
  }
  //responder_profile/AddResponder
  render() {
    const { showTemplate, showSaveAlert } = this.state;
    const { file, UserName, PassWord, submitted, radio, SelectedLang } =
      this.state;
    const customStylesLeg = {
      option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        color: state.isSelected ? "#5c0f8b" : "#5c0f8b",
        fontWeight: state.isSelected ? "900" : "400",
      }),
    };
    return (<>
      <div class="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} class="login-vector" />

            <div class="m-login__logo">
              <a href="/">
                <img src={logo} class="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                <div className="heading">
                  <h1 class="lefter_title">Add Users Per Client</h1>
                </div>
                <p id="UserExists_e" className="errorinput">
                      {" "}User already exists{" "}
                    </p>

                    <p id="UserAdd_e" className="font-success">
                      {" "}Users per client added successfully{" "}
                    </p>
                <form name="login" className="form">
                  <div class="input-control">
                    <label for="FirstName" className="input-label" hidden>
                      First Name
                    </label>
                    <input
                      type="text"
                      id="FirstName"
                      name="FirstName"
                      className="input-field"
                      placeholder="First Name"
                      autocomplete="off"
                    />
                    <p id="FirstName_e" className="errorinput">
                      {" "}
                      Please enter First Name{" "}
                    </p>
                  </div>
                  <div class="input-control">
                    <label for="LastName" className="input-label" hidden>
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="LastName"
                      name="LastName"
                      className="input-field"
                      placeholder="Last Name"
                      autocomplete="off"
                    />
                    
                  </div>
                  <div class="input-control">
                    <label for="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                      value={this.state.Email}
                      disabled
                    />
                    <p id="UserName_e" className="errorinput">
                      {" "}
                      Please enter Email{" "}
                    </p>
                  </div>
                  <div class="input-control">
                    <label for="PassWord" className="input-label" hidden>
                      PassWord
                    </label>
                    <input
                      type="PassWord"
                      id="PassWord"
                      name="PassWord"
                      className="input-field"
                      placeholder="Password"
                      autocomplete="off"
                    />
                    <p id="PassWord_e" className="errorinput">
                      {" "}
                      Please enter password.{" "}
                    </p>
                    <p id="PassWord_length" className="errorinput">
                      {" "}Password must be 6 to 20 chars long.{" "}
                    </p>
                  </div>

                  <div class="input-control">
                    <label for="PassWord" className="input-label">
                      Upload
                      <br />
                      Profile
                      <br /> Image
                    </label>
                    <br />
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      name="avatar"
                      onChange={this.HandleUpload.bind(this, "coverimage")}
                      className="{{ $errors->has('Email') ? 'alert alert-danger' : '' }}"
                    />
                    <div className="avatar-preview container2">
                      <div id="imagePreview"></div>
                    </div>
                   
                  </div>

                  <div class="input-control btn-center">
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={this.SubmitData}
                    >
                      <i class="la la-save"></i> Save
                    </a>
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => {
                        this.BackButton();
                      }}
                    >
                      <i class="la la-arrow-circle-left"></i> Back
                    </a>
                    {/* <p id="err_user">
                      {" "}
                      Please enter Correct Email or PassWord{" "}
                    </p> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> 
      </>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};


const connectedCAddUsersPerClientPage = connect(
  mapState,
  actionCreators
)(CAddUsersPerClientPage);
export { connectedCAddUsersPerClientPage as CAddUsersPerClientPage };
