import React from 'react';
import Axios from "axios";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../kpis/lastdaysTabing';

import CollapsibleTable from "./Meetingstable"
import AccountsTable from '../kpis/Accountstable';
import ContactsDetailsTable from '../kpis/Contactsdetailstable';
import ResponseDetailsTable from '../kpis/ResponseDetailsTable';


//chart
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart  from "react-apexcharts";

import Overview from '../../../images/header/overview.svg';
import Twohours from '../../../images/header/24-hours.svg';
import MeetingOrange from '../../../images/header/meeting-orange.svg';
import AccountOrange from '../../../images/header/Account_orange.svg';
import ContactOrange from '../../../images/header/contact_orange.svg';
import EmailOrange from '../../../images/header/email_orange2.svg';
import EmailOrangeBlack from '../../../images/header/email_orange.png';
import lgUserSky from "../../../images/header/lgUserSky.svg";
import lgEmailorange from '../../../images/header/email_orange.svg';
import lgconversationSky from '../../../images/header/conversation_sky.svg';
import lgstopwatchOrange from '../../../images/header/stopwatch_orange.svg';
import lgdollarPurple from '../../../images/header/dollar_purple.svg';
import OpenEmailMessage from '../../../images/header/open-email-message.svg';
import JobInterview from '../../../images/header/job-interview.svg';
import MeetingGreen from '../../../images/header/meeting-green.svg';
import Response from '../../../images/header/response.svg';

import ReactApexchart from "react-apexcharts";
import { useEffect } from "react";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
toast.configure();
import loadingicon from "../../../images/loading.gif";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ FromDates, ToDates }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [ToDateContact, SetToDateContact] = React.useState("");
  const [UserID, SetUserID] = React.useState("");
  const [UserIDby, SetUserIDby] = React.useState("");
  const [Role,SetRole] = React.useState("");
  const [ClientID, SetClientID] = React.useState("");
  const [FromDateContact, SetFromDateContact] = React.useState("");
  const [TotalMeetingCount, SetTotalMeetingCount] = React.useState(0);
  const [CrushRate, SetCrushRate] = React.useState(0);
  const [TotalPipeline, SetTotalPipeline] = React.useState(0);
  const [AvgMeetingPerWeek, SetAvgMeetingPerWeek] = React.useState(0);
  const [TotalMeetingList, SetTotalMeetingList] = React.useState([]);
  const [TotalGoalList, SetTotalGoalList] = React.useState([]);
  const [WeekDayList, SetWeekDayList] = React.useState([]);
  const [EmailSentPerWeek, SetEmailSentPerWeek] = React.useState(0);
  const [AvgAccountsContactedPeWeek, SetAvgAccountsContactedPeWeek] = React.useState(0);
  const [TotalEmailSent, SetTotalEmailSent] = React.useState(0);
  const [TotalOpenPer, SetTotalOpenPer] = React.useState(0);
  const [TotalMeetingL7, SetTotalMeetingL7] = React.useState(0);
  const [TotalPotenationalMeetingL7, SetTotalPotenationalMeetingL7] = React.useState(0);
  const [TotalResponse, SetTotalResponse] = React.useState(0);
  const [TotalSentEmail, SetTotalSentEmail] = React.useState(0);
  const [TotalReceivedEmail, SetTotalReceivedEmail] = React.useState(0);
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ResponsePer, SetResponsePer] = React.useState(0);
  const [ResponsetoMeetingPer, SetResponsetoMeetingPer] = React.useState(0);
  const [TotalSent, SetTotalSent] = React.useState([]);
  const [TotalResponse1, SetTotalResponse1] = React.useState([]);
  const [WeekDay, SetWeekDay] = React.useState([]);
  const [TotalMeetingList1, SetTotalMeetingList1] = React.useState([]);
  const [IsLoading,SetIsLoading] = React.useState(false);

  useEffect(() => {

    var Details = GetUserDetails();
    SetUserID(Details.ParentUserID);
    SetUserIDby(Details.ChildUserID);
    SetClientID(Details.ClientID);
    SetRole(Details.Role);
    SetToDateContact(ToDates);
    SetFromDateContact(FromDates);
    KpiEmailSentPerWeek(FromDates, ToDates, UserID, ClientID,Details.Role)
    MeetingAnalyticStatistics(FromDates, ToDates, UserID, ClientID,Details.Role)
    KpiAvgAccountConnectedPerWeek(FromDates, ToDates, UserID, ClientID,Details.Role)
    ResponsesKeyMatricGet(Details.ClientID, FromDates, ToDates,Details.ParentUserID,Details.Role)

    //KpiLastSevenDayKeyMatric(ClientID)
  }, [FromDates, ToDates]);


  const KpiEmailSentPerWeek = (FromDate, ToDate, CID,role) => {
    SetIsLoading(true);

    var InputParameter = {
      FromDate: FromDate,
      ToDate: ToDate,
      ClientID: CID,
      Role:role
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiEmailSentPerWeek",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          var MailTo = 0
          for (var i = 0; i < res.data.Data.length; i++) {
            var objTotalMailTo = res.data.Data[i].MailTo;
            MailTo = MailTo + objTotalMailTo;
          }
          if(MailTo >0 && res.data.Data.length > 0)
          {
            SetEmailSentPerWeek(Math.round(MailTo / res.data.Data.length))
          }

        }
    SetIsLoading(false);

      } else {
        SetIsLoading(false);

      }
    });
  }

  const KpiAvgAccountConnectedPerWeek = (FromDate, ToDate, CID,role) => {
    SetIsLoading(true);

    var InputParameter = {
      FromDate: FromDate,
      ToDate: ToDate,
      ClientID: CID,
      Role:role

    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiAvgAccountConnectedPerWeek",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          var TotalContactsContacted = 0
          for (var i = 0; i < res.data.Data.length; i++) {
            var objTotalContactsContacted = res.data.Data[i].TotalContactsContacted;
            TotalContactsContacted = TotalContactsContacted + objTotalContactsContacted;
          }
          if(TotalContactsContacted >0 && res.data.Data.length > 0)
          {
            SetAvgAccountsContactedPeWeek(Math.round(TotalContactsContacted / res.data.Data.length))
          }

        }
    SetIsLoading(false);

      } else {
    SetIsLoading(false);

      }
    });
  }

  const MeetingAnalyticStatistics = (FromDate, ToDate, CID,role) => {
    SetIsLoading(true);

    var InputParameter = {
      FromDate: FromDate,
      ToDate: ToDate,
      ClientID: CID,
      Role:role

    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/MeetingAnalyticStatistics",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          var TotalMeeting = 0, TotalGoal = 0, ContractValue = 0;
          var lstTotalGoal=[];
          var lstTotalEvent=[];
          var lstWeekDay=[];
          for (var i = 0; i < res.data.Data.length; i++) {
            var objTotalMeeting = res.data.Data[i].TotalEvent;
            var objTotalGoal = res.data.Data[i].TotalGoal;
            var objContractValue = res.data.Data[i].ContractValue;

            lstTotalGoal.push(objTotalGoal);
            lstTotalEvent.push(objTotalMeeting);
            lstWeekDay.push( res.data.Data[i].WeekDay)
            TotalMeeting = TotalMeeting + objTotalMeeting;
            TotalGoal = TotalGoal + objTotalGoal;
            ContractValue = ContractValue + objContractValue;
          }
          SetWeekDayList(lstWeekDay);
          SetTotalGoalList(lstTotalGoal);
          SetTotalMeetingList(lstTotalEvent);

          SetTotalMeetingCount(TotalMeeting);

          var TotalWeek = res.data.Data.length;
          if (TotalMeeting > 0 && TotalWeek > 0) {
            SetAvgMeetingPerWeek(Math.round(TotalMeeting / TotalWeek))
          }
          if (TotalMeeting > 0 && TotalGoal > 0) {
            SetCrushRate(Math.round(TotalMeeting * 100 / TotalGoal));
          }
          SetTotalPipeline(ContractValue);


        }
    SetIsLoading(false);

      } else {
        SetIsLoading(false);

      }
    });
  }

  const KpiLastSevenDayKeyMatric = () => {
    SetIsLoading(true);

       var InputParameter = {
      ClientID: ClientID,
      Role:Role
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiLastSevenDayKeyMatric",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        
        if (res.data.Data.CampaignList.length > 0) {
          var TotalSent=0,TotalOpen=0,TotalTracked=0,TotalMeeting
          for (var i = 0; i < res.data.Data.CampaignList.length; i++) {

            var objTotalSent=res.data.Data.CampaignList[i].CampaignStepHistory != null ? res.data.Data.CampaignList[i].CampaignStepHistory.TotalSend : 0
            TotalSent=TotalSent + objTotalSent;

            var objTotalOpen=res.data.Data.CampaignList[i].CampaignStepHistory != null ? res.data.Data.CampaignList[i].CampaignStepHistory.TotalOpen : 0
            TotalOpen=TotalOpen + objTotalOpen;

            var objTotalTracked=res.data.Data.CampaignList[i].CampaignStepHistory != null ? res.data.Data.CampaignList[i].CampaignStepHistory.TotalTracked : 0
            TotalTracked=TotalTracked + objTotalTracked;
          }
          SetTotalEmailSent(TotalSent);
          SetTotalOpenPer(Math.round(TotalTracked > 0? (TotalOpen / TotalTracked) * 100 :0))

          if (res.data.Data.MeetingDetails.length > 0) {
            SetTotalMeetingL7(res.data.Data.MeetingDetails.length)
          }
          if (res.data.Data.PotentialMeetingList.length > 0) {
            SetTotalPotenationalMeetingL7(res.data.Data.PotentialMeetingList.length)
          }
          if (res.data.Data.ResponseDetails.length > 0) {
            SetTotalResponse(res.data.Data.ResponseDetails.length)
          }


        }
      } else {

      }
    SetIsLoading(false);

    });
  }

  const ResponsesKeyMatricGet = (CID, FromDates, ToDates,UID,role) => {
    SetIsLoading(true);

    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      UserID:UID,
      Role:role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis_email/ResponseTabGraphCount",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      
  
      if (Res?.data?.StatusMessage == "SUCCESS") {
        if (Res?.data?.Data.length > 0) {
          var lstTotalSent = [];
          var lstTotalResponse = [];
          var lstTotalMeeting = [];
          var lstWeekDay = [];
          var objtotalmeeting = 0;
          var objtotalSentEmail = 0;
          var objtotalReceivedEmail = 0;
          for (var i = 0; i < Res.data.Data.length; i++) {
            var totaemail = Res.data.Data[i].SentEmail;
            objtotalSentEmail = objtotalSentEmail + totaemail;
  
            var totalReceivedEmail = Res.data.Data[i]?.ReceivedEmail;
            objtotalReceivedEmail = objtotalReceivedEmail + totalReceivedEmail;
  
            var totalmeeting = Res.data.Data[i].TotalEvents;
            objtotalmeeting = objtotalmeeting + totalmeeting;
  
            var objWeekDay = Res.data.Data[i].WeekDay;
            lstTotalSent.push(totaemail);
            lstTotalResponse.push(totalReceivedEmail)
            lstTotalMeeting.push(totalmeeting)
            lstWeekDay.push(objWeekDay)
          }
          
          
          
          
          SetTotalSentEmail(objtotalSentEmail)
          SetTotalReceivedEmail(objtotalReceivedEmail)
          SetTotalMeetingList1(objtotalmeeting)
          SetTotalSent(lstTotalSent)
          SetTotalResponse1(lstTotalResponse)
          SetWeekDay(lstWeekDay)
  
          if (objtotalSentEmail > 0 && objtotalReceivedEmail > 0) {
            SetResponsePer(Math.round(objtotalReceivedEmail * 100 / objtotalSentEmail))
          }
  
          if (objtotalReceivedEmail > 0 && objtotalmeeting > 0) {
            SetResponsetoMeetingPer(Math.round(objtotalmeeting * 100 / objtotalReceivedEmail));
        }
    SetIsLoading(false);
        
        } else {
          SetTotalSentEmail(0)
          SetTotalReceivedEmail(0)
          SetTotalMeeting([])
          SetTotalSent([])
          SetTotalResponse1([])
          SetWeekDay([])
    SetIsLoading(false);
          
        }
      }else {
        SetTotalSentEmail(0)
        SetTotalReceivedEmail(0)
        SetTotalMeeting([])
        SetTotalSent([])
        SetTotalResponse1([])
        SetWeekDay([])
    SetIsLoading(false);
        
      }
    SetIsLoading(false);

    });
  };

  charts(FusionCharts);
  const dataSource = { 
    chart: { 
      caption: "Meeting Status",
      theme: "fusion",
      subCaption: " ",
      formatnumberscale: "0",
      showLegend: "1",
      showLabels: "0",
      showValues: "1",
      valuePosition: "inside",
      numbersuffix: "%",
      plothighlighteffect: "false",
      legendcaptionbold: "1",
      legendcaptionfontsize: "15",
      legendCaptionFontColor: "#000",
      showPercentValues: "0",
      showPercentInToolTip: "0",
      enableSlicing: "1",
      legendPosition: "right",
      legendCaption: "Meeting Status: ",
      legendBgColor: "#FFF",
      legendBorderColor: "#ffF",
      legendShadow: "0",
      legendItemFontSize: "14",
    },
    data: [
      {
        label: "Set",
        value: "50",
        color: "#302e49",
        showPercentInTooltip: '0',
        labelPosition: 'inside',
        labelDisplay: 'rotate',
        valuePosition: "inside",
      },
      {
        label: "Qualified",
        value: "15",
        color: "#6f4569",
        showPercentInTooltip: '0',
        labelPosition: 'inside',
        labelDisplay: 'rotate',
      },
      {
        label: "Opportunity",
        value: "5",
        color: "#b35d75",
        showPercentInTooltip: '0',
        labelPosition: 'inside',
        labelDisplay: 'rotate',
      },
      {
        label: "Contract Sent",
        value: "12",
        color: "#e9826e",
        showPercentInTooltip: '0',
        labelPosition: 'inside',
        labelDisplay: 'rotate',
      },
      {
        label: "Closed Won",
        value: "8",
        color: "#ffb963",
        showPercentInTooltip: '0',
        labelPosition: 'inside',
        labelDisplay: 'rotate',
      },
      {
        label: "Rescheduling",
        value: "10",
        color: "#f9f871",
        showPercentInTooltip: '0',
        labelPosition: 'inside',
        labelDisplay: 'rotate',
      },
    ],
  };

  const state = {
    series: [
      {
        name: "Goal",
        color: "#302e49",
        data: TotalGoalList,
      },
      {
        name: "Meetings",
        color: "#e9826e",
        data: TotalMeetingList,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: WeekDayList,
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val;
          },
        },
      },
    },
  };

  const EmailStatus = {
    series: [
      {
        name: "Sent",
        color: "#302e49",
        data: TotalSent,
      },
      {
        name: "Responses",
        color: "#e9826e",
        data: TotalResponse1,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: WeekDay,
      },
      yaxis: {
        title: {
          text: "Count"
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val ;
          },
        },
      },
    },
  };

  const ramp_chart = {
    series: [
      {
        name: "1-3 months",
        color: "#302e49",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "3-5 months",
        color: "#e9826e",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "5-7 months",
        color: "#ffb963",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "7+ months",
        color: "#f9f871",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      title: {
        text: "Ramp Time by ACV",
        align: "left",
        fontSize: "22px",
      },
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      legend: {
        position: "top",
        offsetY: 0,
        offsetX: 0,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const Crushchart = {
    series:[CrushRate],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#eef0f8",
            strokeWidth: "97%",
            opacity: "1",
            margin: 5, // margin is in pixels
            // dropShadow: {
            //   enabled: true,
            //   top: 2,
            //   left: 0,
            //   color: '#ccc',
            //   opacity: 1,
            //   blur: 10
            // }
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },

      fill: {
        type: "colors",
        colors: "#302e49",
        opacity: "1",
        // gradient: {
        //   shade: 'light',
        //   shadeIntensity: 0.4,
        //   inverseColors: false,
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   stops: [48, 46, 73, 1]
        // },

      },
      labels: ["CRUSH %"],
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue==1)
    {
      //Last 7 Day Tab
      KpiLastSevenDayKeyMatric();

    }
  };


  return (
    <div className="tabdes">
      {IsLoading ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></>:<></>}
      <div className='px-2'>
        <AppBar position="static" className='mb-0'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label="Overview"
              icon={<img src={Overview} />}
              {...a11yProps(0)}
            >
              Overviewasdasd
            </Tab>
            <Tab
              label="Last 7 Days"
              icon={<img src={Twohours} />}
              {...a11yProps(1)}

            />
            <Tab
              label="Meetings"
              icon={<img src={MeetingOrange} />}
              {...a11yProps(2)}
            />
            <Tab
              label="Accounts"
              icon={<img src={AccountOrange} />}
              {...a11yProps(3)}
            />
            <Tab
              label="Contacts"
              icon={<img src={ContactOrange} />}
              {...a11yProps(4)}
            />
            <Tab
              label="Emails"
              icon={<img src={EmailOrange} />}
              {...a11yProps(5)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone" index={0}>
          <div className="row mx-n5 px-2">
            <div className="col-md-4">
              <div className='cardbox middleflex'>
              <div id="chart" className="w-100">
                <ReactApexChart
                  options={Crushchart.options}
                  series={Crushchart.series}
                  type="radialBar"
                />
                <lable className="lablecrush">CRUSH %</lable>
              </div>
              </div>
            </div>

            <div className="col-md-4 px-0">
              <div className="cardbox pad-y20">
                <h3 className="smheadtext">Activity KPIs</h3>
                <div className="d-flex pb-3 mb-3 border-bottom">
                  <div className="float-left">
                    <div className="iconsboxsround">
                      <img src={lgUserSky} />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <label>{AvgAccountsContactedPeWeek}</label>
                    <p>Avg. Accounts Contacted Per Week</p>
                  </div>
                </div>

                <div className="d-flex pb-3 mb-3">
                  <div className="float-left">
                    <div className="iconsboxsround">
                      <img src={lgEmailorange} />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <label>{EmailSentPerWeek}</label>
                    <p>Email Sent Per Week</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="cardbox pad-y20">
                <h3 className="smheadtext">Meeting KPIs</h3>
                <div className="d-flex pb-3 mb-3 border-bottom">
                  <div className="float-left">
                    <div className="iconsboxsround">
                      <img src={lgconversationSky} />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <label>{AvgMeetingPerWeek}</label>
                    <p>Avg. Meetings Per Week</p>
                  </div>
                </div>

                <div className="d-flex pb-3 mb-3 border-bottom">
                  <div className="float-left">
                    <div className="iconsboxsround">
                      <img src={lgstopwatchOrange} />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <label>{TotalMeetingCount}</label>
                    <p>Meetings Set</p>
                  </div>
                </div>

                <div className="d-flex pb-3 mb-3">
                  <div className="float-left">
                    <div className="iconsboxsround">
                      <img src={lgdollarPurple} />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <label>${TotalPipeline}</label>
                    <p>Potential Pipeline Value</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 mx-n5 px-2 mx-n4">
            <div className="col-xl-6 pr-2">
            <div className="cardbox px-3 ppeg">
              <h3 className="smheadtext">Weekly Goals</h3>
              <div id="chart">
                <ReactApexchart
                  options={state.options}
                  series={state.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            </div>
            <div className="col-xl-6 pl-2">
              <div className="cardbox px-3 ppeg w-100 h-100"></div>
            </div>
          </div>
 
          <div className="row pb-3 mx-n5 mt-3 px-2">
            <div className="col-xl-6 pr-2">
              <div className='cardbox px-3 ppeg'> 
                <h3 className="smheadtext py-4">Ramp</h3>
                <p>
                  Companies with ACVs greater than $50K report average ramp time
                  of 6 months while those with ACVs below $5K average 4.8. That’s
                  25% longer.
                </p>
                <p>
                  You might think that insignificant, but recall that higher ACV
                  companies are hiring more senior reps. It appears that more
                  experience alone isn’t able to offset the complexities of a
                  larger ACV – with longer sales cycles, more buyers, and assorted
                  other challenges.
                </p>
              </div>
            </div>
            <div className="col-xl-6 pl-2">
              <div className="cardbox p-3 maxchart-630">
                <div id="chart">
                  <ReactApexchart
                    options={ramp_chart.options}
                    series={ramp_chart.series}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="inpt-px-0" index={1}>
          <div className="row colfive mx-n4">
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  <div className="float-left">
                    <div class="bg-white-round">
                      <img src={EmailOrangeBlack} class="kpi-icon" />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalEmailSent}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Emails Sent</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  <div className="float-left">
                    <div class="bg-white-round">
                      <img src={OpenEmailMessage} class="kpi-icon" />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalOpenPer}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Open %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  <div className="float-left">
                    <div class="bg-white-round">
                      <img src={JobInterview} class="kpi-icon" />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalPotenationalMeetingL7}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Potential Meetings Set</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  <div className="float-left">
                    <div class="bg-white-round">
                      <img src={MeetingGreen} class="kpi-icon" />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalMeetingL7}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Meetings Set</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  <div className="float-left">
                    <div class="bg-white-round">
                      <img src={Response} class="kpi-icon" />
                    </div>
                  </div>
                  <div className="contectboxes">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalResponse}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Responses</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className="row mt-3 mx-n3">
            <div className="col px-0">
              <LastdaysTabing />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="row">
            <div className="col px-2">
              <div className="table-bordered mt-0">
                <CollapsibleTable FromDateMeeting={FromDateContact} ToDateMeeting={ToDateContact} />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="row">
            <div className="col px-2">
              <div className="table-bordered mt-0">
                <AccountsTable FromDateAccount={FromDateContact} ToDateAccount={ToDateContact} />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}> 
          <div className="row">
            <div className="col px-2">
              <ContactsDetailsTable FromChildDatesContact={FromDateContact} ToChildDatesContact={ToDateContact} />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="mx-n2" index={5}>
          <div className="row colfive mx-n4">
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalSentEmail}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Sent</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{ResponsePer} %</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Response %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalReceivedEmail}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Responses</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{ResponsetoMeetingPer} %</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Response To Meeting %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalMeeting.length}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Meetings</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row cardbox padtop-50 mt-3 mx-n3">
            <div class="col-xl-1"></div>
            <div class="col-xl-10">
              <div id="chart chart-max450">
                <ReactApexchart
                  options={EmailStatus.options}
                  series={EmailStatus.series}
                  type="bar"
                  height={450}
                />
              </div>
            </div>
            <div class="col-xl-1"></div>
          </div>
 
       
          <div className="row mx-n3">
            <div className="col px-0">
              <ResponseDetailsTable FromChildDatesEmail={FromDateContact} ToChildDatesEmail={ToDateContact} />
            </div>
          </div>

        
        </TabPanel>
      </div>

    </div>
  );
}