import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import { GetUserDetails } from "../../../_helpers/Utility";
import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmailTemplete } from "../../../_helpers/email-templete";
import { history } from "../../../_helpers";
import Footer from "../../../_components/user/footer/footer";

const AccountState = {
  series: [
    {
      name: "Sent",
      color: "#302e49",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 45, 65],
    },
    {
      name: "Responses",
      color: "#e9826e",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 40, 115, 110],
    },
    {
      name: "Meetings",
      color: "#ffb963",
      data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 110],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "04/01 - 04/04",
        "04/05 - 04/11",
        "04/12 - 04/18",
        "04/19 - 04/25",
        "04/26 - 05/02",
        "05/03",
        "05/10",
        "05/17",
        "05/24",
        "06/07",
        "06/14",
        "06/21",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  },
};

class AddOpportunitiesPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fileds: {},
      errors: {},
      statusAvailable: null,
      meetingsource: [],
      meetingseniority: [],
      meetingtimezone: [],
      meetingaccount: [],
      potentialowner: [],
      potentialstatus: [],
      Statusfield:[],
      accountemail: [],
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    this.Getmeetingdetails();
    // this.GetEmailmeeting();
  }

  Getmeetingdetails() {
    var Details = GetUserDetails();
    console.log(Details);
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
    };
    console.log("Potential", str_in);
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/opportunities/FindMeetingAllStatus",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      console.log(result.data);
      this.setState({
        meetingsource: result.data.meetingsource,
        potentialowner: result.data.meetingowner,
        potentialstatus: result.data.meetingstatus,
        meetingseniority: result.data.meetingseniority,
        meetingtimezone: result.data.meetingtimezone,
        meetingaccount: result.data.meetingaccount,
      });

      //  return result.data
    });
  }

  //   GetEmailmeeting() {
  //     var Details = GetUserDetails();
  //     console.log(Details);
  //     var str_in = {
  //       ClientID: Details.ClientID,
  //       ParentUserID: 17,
  //       UserID: Details.ChildUserID,
  //     };
  //     console.log("Potential", str_in);
  //     const rows = Axios({
  //       url: CommonConstants.MOL_APIURL + "/opportunities/GetAccountMeeting",
  //       method: "POST",
  //       data: str_in,
  //     });
  //     rows.then((result) => {
  //       console.log(result.data, "email details");
  //       this.setState({
  //         accountemail: result.data.account,
  //       });

  //       //  return result.data
  //     });
  //   }

  fromValidation() {
    let formIsValid = true;
    let fileds = this.state.fileds;
    let errors = {};
    if (!fileds["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = "Please Enter FirstName";
    }

    if (this.state.statusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fileds;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.FirstName != "") {
      this.state.errors.FirstName = null;
      this.state.statusAvailable = null;
      this.CheckExistOpportynitiesMeetingName(e.target.value);
    } else {
      this.state.statusAvailable = null;
      this.state.errors.FirstName = null;
    }
  }

  CheckExistOpportynitiesMeetingName(FirstName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FirstName: FirstName,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/OpportunitiesMeetingStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  backbtn() {
    history.push("/potentialmeeting");
  }

    handleUpload(field, e) {
      if (
        Fileupload.checkMimeType(e.target.files[0]) &&
        Fileupload.checkFileSize(e.target.files[0])
      ) {
        var temp = URL.createObjectURL(e.target.files[0]);
        this.setState({
          finalimg: e.target.files[0],
        });
      } else {
        console.log("error on size or type");
      }
    }
  savebtn() {
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Title = document.getElementById("Title").value;
    var Company = document.getElementById("Company").value;
    var Email = document.getElementById("Email").value;
    var LinkedInURL = document.getElementById("LinkedInURL").value;
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var Website = document.getElementById("Website").value;
    var MeetingStatusID = document.getElementById("MeetingStatusID").value;
    // var MeetingSetDate = document.getElementById("date1").value;
    // var MeetingDate = document.getElementById("date2").value;
    var MeetingTimeZoneID = document.getElementById("MeetingTimeZoneID").value;
    var EmailAccountID = document.getElementById("EmailAccountID").value;
    var SeniorityMeetingID =
      document.getElementById("SeniorityMeetingID").value;
    var Industry = document.getElementById("Industry").value;
    var ContractValue = document.getElementById("ContractValue").value;
    var data1 = {
       ClientID:this.state.ClientID,
       MeetingStatusID:MeetingStatusID
      };
    Axios({
        url:
          CommonConstants.MOL_APIURL + "/opportunities/findOpportunitiesMeetingStatus",
        method: "POST",
        data: data1,
      }).then((resstatus) => {
        console.log(resstatus.data.BookedStatus[0].Status,"meetingstatus");
        this.setState({Statusfield:resstatus.data.BookedStatus[0].Status})
      

    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var emailflg = re.test(Email);
    if (
      FirstName == undefined ||
      LastName == undefined ||
      Title == undefined ||
      Company == undefined ||
      Email == undefined ||
      LinkedInURL == undefined ||
      MeetingSourceID == undefined ||
      MeetingOwnerID == undefined ||
      DirectPhone == undefined ||
      DirectPhoneExt == undefined ||
      CompanyPhone == undefined ||
      Website == undefined ||
      MeetingStatusID == undefined ||
      MeetingTimeZoneID == undefined ||
      EmailAccountID == undefined ||
      SeniorityMeetingID == undefined ||
      Industry == undefined ||
      ContractValue == undefined 
    ) {
      console.log("Please enter data or check you email");
    } else {
      // var emailData = EmailTemplete.userEmail();
      var data = {
        UserID: this.state.UserID,
        ClientID: this.state.ClientID,
        FirstName: FirstName,
        LastName: LastName,
        Status:this.state.Statusfield,
        Email: Email,
        Title: Title,
        Company: Company,
        LinkedInUrl: LinkedInURL,
        MeetingOwnerID: MeetingOwnerID,
        MeetingSourceID: MeetingSourceID,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        Website: Website,
        MeetingStatusID: MeetingStatusID,
        MeetingTimeZoneID: MeetingTimeZoneID,
        EmailAccountID: EmailAccountID,
        SeniorityMeetingID: SeniorityMeetingID,
        Industry: Industry,
        ContractValue: ContractValue,
        IsDeleted: false,
        CreatedBy: this.state.CUserID,
        CreatedDt: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDt: null,
        // html: emailData,
        // subject: "SalesHive User Account",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/opportunities/AddOpportunitiesMeeting",
        method: "POST",
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "opportunities meeting Status add successfully.",
            "Add opportunities meeting Status"
          );
        //   history.push("/opportunities");
          console.log("successfull opportunities meeting", res.data);
        } else {
          toast.error(res.data.Message);
        }
      });
    }
});
  }

  render() {
    return (
      <>
        <div className="adminmain">
          <Sidebar />
          <div className="bodyhome">
            <div className="paddcols">
              <div className="row borderbtnm ">
                <div className="col padd-0">
                  <h3 className="float-left py-3 addpotential_header px-4">
                  Add Meeting
                  </h3>
                </div>
              </div>
              <div className="row borderbtnm ">
                <div className="col padd-0">
                  <h3 className=" float-left py-3 addpotential_header px-4">
                    Contact Info
                  </h3>
                </div>
              </div>

              <div className="row pt-4 px-2">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInURL"
                        name="LinkedInURL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhone"
                        name="DirectPhone"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone ext"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhoneExt"
                        name="DirectPhoneExt"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="Website"
                        name="Website"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* meeting info */}

              <div className="row borderbtnm px-4">
                <div className="col padd-0">
                  <h3 className=" float-left pb-3 pt-5 addpotential_header">
                    Meeting Info
                  </h3>
                </div>
              </div>

              <div className="row pt-2 px-4">
                <div className="row pt-4">
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Status
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field MeetingStatusID must be a number."
                          id="MeetingStatusID"
                          name="MeetingStatusID"
                        >
                          <option value="">--Select--</option>
                          {this.state.potentialstatus.map((value) => (
                            <option value={value.MeetingStatusID}>
                              {value.Status}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Source
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field MeetingSourceID must be a number."
                          id="MeetingSourceID"
                          name="MeetingSourceID"
                        >
                          <option value="">--Select--</option>
                          {this.state.meetingsource.map((value) => (
                            <option value={value.MeetingSourceID}>
                              {value.Name}
                            </option>
                          ))}
                          
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set Date
                      </label>
                      <div class="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="date1"
                          name="date1"
                          placeholder="Goal"
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set By
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field SalesRepID must be a number."
                          id="SalesRepID"
                          name="SalesRepID"
                        >
                          <option value="">--Select--</option>
                          <option value="2">sales rep 2</option>
                          <option value="1">sales rep 1st</option>
                          <option value="1013">SAI</option>
                          <option value="1012">RANA1</option>
                          <option value="1011">RANA</option>
                          <option value="1007">as</option>
                          <option value="1010">14</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Date
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="date2"
                          name="date2"
                          placeholder="Goal"
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Time Zone
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field SalesRepID must be a number."
                          id="MeetingTimeZoneID"
                          name="MeetingTimeZoneID"
                        >
                          {this.state.meetingtimezone.map((value) => (
                            <option value={value.CommonFieldID}>
                              {value.FieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Owner
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field MeetingOwnerID must be a number."
                          id="MeetingOwnerID"
                          name="MeetingOwnerID"
                        >
                         
                          {this.state.potentialowner.map((value) => (
                            <option value={value.MeetingOwnerID}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reminder Email Account
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ClientPOCID must be a number."
                          id="EmailAccountID"
                          name="EmailAccountID"
                        >
                         
                          {this.state.meetingaccount.map((value) => (
                            <option value={value.AccountID}>
                              {value.Email}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Contract Value
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter ContractValue name"
                          id="ContractValue"
                          name="ContractValue"
                          defaultValue={0}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Industry
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter Industry "
                          id="Industry"
                          name="Industry"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Account ID
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="ExternalAccountID"
                          name="ExternalAccountID"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Contact ID
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="ExternalContactID"
                          name="ExternalContactID"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 1
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="ExternalField1"
                          name="ExternalField1"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 2
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id=" ExternalField2"
                          name=" ExternalField2"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 3
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id=" ExternalField3"
                          name=" ExternalField3"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Seniority
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field Seniority must be a number."
                          id="SeniorityMeetingID"
                          name="SeniorityMeetingID"
                        >
                          {this.state.meetingseniority.map((value) => (
                            <option value={value.CommonFieldID}>
                              {value.FieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <label class="">
                      <input type="checkbox" className="" />
                      <span className="pl-2">Send Reminder</span>
                    </label>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Call Recording
                      </label>
                      <div className="col-lg-7">
                        <input
                          type="file"
                          id="uploadRecording"
                          accept=".mp3"
                          style={{ outline: "none" }}
                            //     onChange={this.handleUpload.bind(this, "coverimage")}
                            // className="{{ $errors->has('email') ? 'alert alert-danger' : '' }}"
                          autocomplete="off"
                        />
                        {/* <label id="recFileName" class="m--hide break-content">
                          File Name
                        </label> */}
                        <input
                          id="RecordingFilePath"
                          name="RecordingFilePath"
                          type="hidden"
                          value=""
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* custom meeting field */}

              {/* <div className="row borderbtnm  px-4">
                <div className="col padd-0">
                  <h3 className=" float-left pb-3 pt-5 addpotential_header">
                    Custom Meeting Field
                  </h3>
                </div>
              </div>

              <div className="row pt-4 px-2">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      cust1
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="cust1"
                        name="cust1"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="row borderbtnm mt-4 mb-4"></div>

              <div className="row mb-3">
                <div className="col-lg-12 pull-left">
                  <a
                    id="submit"
                    href="javascript:void(0);"
                    className="btn btn-primary btn-lightgreen mr-1"
                    value="Save"
                    onClick={this.savebtn}
                  >
                    <i className="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    className="btn btn-secondary"
                    value="Save"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* meeting info */}
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesHiveContactPage = connect(
  mapState,
  actionCreators
)(AddOpportunitiesPage);
export { connectedAddSalesHiveContactPage as AddOpportunitiesPage };
