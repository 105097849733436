import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../_helpers";
const Fileupload = require("../../_helpers/fileupload");
import { userActions } from "../../_actions";
import Emodprofile from "../../_components/user/Emodprofile/Emodprofile";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";

toast.configure();

class EmodProfilePage extends React.Component {

    
  AddEmodField() {
    history.push("/addemodprofile");
  }


  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row pt-2">
              <div className="col">
                <h4 className="headertitle float-left pl-0">eMod Profile</h4>
                <div className="listing-li float-right">
                  <ul> 
                    <li className="px-1">
                      <a
                        onClick={this.AddEmodField}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li> 
                  </ul>
                </div>
              </div>
            </div> 

            
            <div class="paddcols px-4">
              <div className="row">
                <div className="col">
                    <Emodprofile />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedListspage = connect(mapState, actionCreators)(EmodProfilePage);
export { connectedListspage as EmodProfilePage };
