import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";




class CAddCampaignCategoriesPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      UserIDby:Details.ChildUserID,
      Role: Details.Role,
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    this.state.StatusAvailable;
    firstname_e.style.display = "none";
  }

//form validation
FromValidation() {
  let formIsValid = true;
  if (Object.entries(this.state.Fields).length > 0) {
    let Fields = this.state.Fields;
    let Errors = {};
    if (!Fields["Name"]) {
      formIsValid = false;
      Errors["Name"] = "Please enter Name";
    }
    if (this.state.StatusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ Errors: Errors });
  }
  return formIsValid;
}

//change to check error
HandleChange(Field, e) {
  document.getElementById("firstname_e").style.display = "none";
  let Fields = this.state.Fields;
  Fields[Field] = e.target.value;
  this.setState({ Fields });
  if (Fields.Name != "") {
    this.state.Errors.Name = null;
    this.state.StatusAvailable = null;
    this.CheckExistCategoryName(e.target.value);
  } else {
    this.state.StatusAvailable = null;
    this.state.Errors.Name = null;
  }
}

//check exist Status
CheckExistCategoryName(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role:this.state.Role
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/Campaigncategory/CategoryExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ StatusAvailable: resdata.data.Data[0].Name });
          console.log(this.state.StatusAvailable);
          this.setState({ IsExist: true });
        }
      }
    });
  }

//back
  BackBtn() {
    history.push("/CCampaignCategories");
  }

  //add account status
  SaveBtn() {
    var Name = document.getElementById("Name").value;
    if (Name == undefined || Name == "") {
      firstname_e.style.display = "block";
    } else {
      var AddField = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        Name: Name,
        IsDeleted: false,
        CreatedDt: new Date(),
        CreatedBy: this.state.UserIDby,
        LastUpdatedDt: null,
        LastUpdatedBy: null,

      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/Campaigncategory/CategoryAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Campaign Categories<br/>Category added successfully.</div>);
          history.push("/CCampaignCategories");
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Add Category 
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row pt-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">
                      Category Name
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        onChange={this.HandleChange.bind(this, "Name")}
                        value={this.state.StatusAvailable}
                        id="Name"
                        name="Name"
                        type="text"
                      />
                      <label id="firstname_e" className="errorinput">
                        {" "}
                        Please enter Name{" "}
                      </label>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Status already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddCampaignCategoriesPage = connect(
  mapState,
  actionCreators
)(CAddCampaignCategoriesPage);
export { connectedCAddCampaignCategoriesPage as CAddCampaignCategoriesPage };
