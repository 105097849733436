import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../../images/loading.gif";
import { FormatColorResetSharp } from "@material-ui/icons";
toast.configure();

class EditClientStatusPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      OldName: null,
      IsExists: false,
      BtnDisabled:false
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title="Edit Client Statuses | SalesHive";
    
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
    this.EditClientStatus();
  }

  async FromValidation() {
    let formIsValid = true;
    var Status = document.getElementById("ClientStatusName").value.trim();
    let errors = {};

    var IsExist = await this.CheckExistStatus(Status);
    if (Status == "") {
      formIsValid = false;
      errors["ClientStatusName"] = "Please enter Client Status.";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.ClientStatusName != "") {
      this.state.errors.ClientStatusName = null;
      this.state.statusAvailable = null;
      this.CheckExistStatus(e.target.value);
    } else {
      this.state.statusAvailable = null;
      this.state.errors.ClientStatusName = null;
    }
  }

  async CheckExistStatus(Status) {
    var InputParameter = {
      Status: Status,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusExists",
      method: "POST",
      data: InputParameter,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (this.state.OldName == Status) {
            this.setState({ IsExist: false });
            return false;
          } else {
            this.setState({ statusAvailable: resdata.data.Data });
            this.setState({ IsExist: true });
            return true;
          }
        } else {
          this.setState({ IsExist: false });
          return false;
        }
      }
  }

  EditClientStatus() {
    const ID = this.props.location.state;
    var InputParameters = {
      ID: ID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusGetById",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("ClientStatusName").value =
          res.data.Data.ClientStatusName;
        document.getElementById("IsOldClient").checked =
          res.data.Data.IsOldClient;
        document.getElementById("RemoveFromClientReporting").checked =
          res.data.Data.IsRemoveClientReporting;
        this.setState({ OldName: res.data.Data.ClientStatusName });
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data?.Message);
        
      }
    });
  }

  backbtn() {
    history.push("/clientstatuses");
  }
  async savebtn(e) {
    this.setState({BtnDisabled:true})
    this.state.errors.ClientStatusName = null;
    this.state.statusAvailable = null;
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      let ClientStatusName = document.getElementById("ClientStatusName").value;
      let OldClient = document.getElementById("IsOldClient").checked;
      let RemoveFromClientReporting = document.getElementById(
        "RemoveFromClientReporting"
      ).checked;
      var InputParameter = {
        ID: this.props.location.state,
        ClientStatusName: ClientStatusName,
        OldClient: OldClient,
        RemoveFromClientReporting: RemoveFromClientReporting,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDt: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Client Statuses
              <br />
              Client Statuses updated successfully
            </div>
          );
          history.push("/clientstatuses");
        } else {
           this.setState({BtnDisabled:false})
          toast.error(res.data.Message);
        }
      });
    } else {
      this.setState({BtnDisabled:false})
      document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
       <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0 mb-0">
                    Edit Client Statuses
                  </h4>
                </div>
              </div>
            <div class="paddcols">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter status"
                        onBlur={this.handleChange.bind(
                          this,
                          "ClientStatusName"
                        )}
                        id="ClientStatusName"
                        name="ClientStatusName"
                        placeholder="Enter ClientStatusName"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientStatusName"]}
                      </span>
                      {this.state.statusAvailable && (
                        <span style={{ color: "red" }}>
                          Client Status already exist.
                        </span>
                      )}
                    </div>
                    <div class="col-lg-6 boxsinput_group">
                      <label class="check_bx floatrighter mr-3">
                        <input id="IsOldClient" type="checkbox" />
                        <span className="checkmark"></span>
                        Is Old Client
                      </label>
                    </div>
                    <div class="col-lg-6"></div>

                    <div class="col-lg-6 boxsinput_group">
                      <label class="check_bx floatrighter mr-3">
                        <input id="RemoveFromClientReporting" type="checkbox" />
                        <span className="checkmark"></span>
                        Remove From Client Reporting
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
              <div class="row mt-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectededitclientstatus = connect(
  mapState,
  actionCreators
)(EditClientStatusPage);
export { connectededitclientstatus as EditClientStatusPage };
