import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

export default function CamapignCategoryTable() {
  const [Data, SetData] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CampaignINData, SetCampaignINData] = React.useState([]);
  const [CampaignINFlen, SetCampaignINFlen] = React.useState(0);
  const [CampaignINSflag, SetCampaignINSflag] = React.useState(false);
  const [CampaignINRlen, SetCampaignINRlen] = React.useState(0);
  const [CampaignINCountPage, SetCampaignINCountPage] = React.useState(0);
  const [CampaignINRows, SetCampaignINRows] = React.useState([]);
  const [CampaignINPage, SetCampaignINPage] = React.useState(1);
  const [CampaignINRowsPerPage, SetCampaignINRowsPerPage] = React.useState(15);
  const [CampaignINSearch, SetCampaignINSearch] = React.useState("");
  const [CampaignINSortField, SetCampaignINSortField] = React.useState("Name");
  const [CampaignINSortedBy, SetCampaignINSortedBy] = React.useState(1);
  const [CategoryValue, SetCategoryValue] = React.useState(null);
  const [ArrayCheck, SetArrayCheck] = React.useState([]);
  const [ArrayUnCheck, SetArrayUnCheck] = React.useState([]);

  useEffect(() => {
    // var CategoryId =  document.getElementById("CategoryID").value

    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      SetClientID(getaccountcatelist.ClientID);
      SetUserIDby(getaccountcatelist.ChildUserID);
      SetUserID(getaccountcatelist.ParentUserID);
      SetRole(getaccountcatelist.ChildRole);
    }
    CampaignCategorylistGet(
      getaccountcatelist.ClientID,
      getaccountcatelist.ParentUserID,
      getaccountcatelist.ChildRole
    );
    // CampaignInCategorylistGet(getaccountcatelist.ClientID,getaccountcatelist.ChildUserID)
  }, [
    CampaignINPage,
    CampaignINSearch,
    CampaignINRowsPerPage,
    CampaignINSortedBy,
    CategoryValue,
  ]);

  //Get Category List
  const CampaignCategorylistGet = (CID, UID,role) => {
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role:role
    };

    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/Campaigncategory/CategoryListGet2",
      method: "POST",
      data: str_in,
    });
    Rows1.then((result) => {
      SetData(result.data.PageData);
      if (CategoryValue != null) {
        CampaignInCategorylistGet(CID, UID, CategoryValue,role);
      } else {
        CampaignInCategorylistGet(
          CID,
          UID,
          result.data.PageData[0]?._id,
          role
        );
      }
      document.getElementById("hideloding").style.display = "none";
    });
  };

  // CampaignIn category get list
  const CampaignInCategorylistGet = async (CID, UID, CTID,role) => {
    SetCategoryValue(CTID);
    var str_in = {
      Page: CampaignINPage,
      RowsPerPage: CampaignINRowsPerPage,
      Sort: true,
      Field: CampaignINSortField,
      SortBy: CampaignINSortedBy,
      Search: CampaignINSearch,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      CategoryID: CTID,
      Role:role
    };

    const Rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/Campaigncategory/CampaignInCategoryList",
      method: "POST",
      data: str_in,
    });
    ;
    SetCampaignINData(Rows1.data.PageData);
    SetCampaignINRows(Rows1.data.PageData);
    SetCampaignINRlen(Rows1.data.TotalCount);
    SetCampaignINFlen(Rows1.data.TotalCount);
    SetCampaignINCountPage(Rows1.data.PageCount);
    if (Rows1.data.PageData.length !== 0) {
      var tempresult = Rows1.data.PageData.map((item) => {
        if (item.CampaignInCategpryList.length !== 0) {
          document.getElementById(item._id).checked = true;
        } else {
          document.getElementById(item._id).checked = false;
        }
      });
      const results = await Promise.all(tempresult);
    }
  };

  //Search campaign in category search list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var searchedVal = document.getElementById("search").value;
      if (searchedVal == CampaignINSearch) {
        SetCampaignINSearch(searchedVal);
        SetCampaignINPage(1);
      } else {
        SetCampaignINSearch(searchedVal);
        SetCampaignINPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //change display rows
  const ChangeRowSelected = (event) => {
    SetCampaignINRowsPerPage(Number(event.target.value));
    SetCampaignINPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //Handle change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == CampaignINPage) {
      SetCampaignINPage(NewPage);
    } else {
      SetCampaignINPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  // campaign category in handle change
  const HandleDropdownChangeCampaignCategory = (e) => {
    SetCategoryValue(e.target.value);
  };

  // check box handle change
  const CheckBoxAdd = async (e, ID) => {
    var val = document.getElementById(ID).checked;
    var ChArr = ArrayCheck;
    var UnChArr = ArrayUnCheck;
    if (val === true) {
      ChArr.push({CampaignID:ID});
      SetArrayCheck(ChArr);
    } else {
      UnChArr.push({CampaignID:ID});
      SetArrayUnCheck(UnChArr);
    }
  };

  // get sort field data
  const SortData = async (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetCampaignINSflag(false);
      var SortField = Field;
      var SortBy;
      if (CampaignINSortedBy == 1) {
        SortBy = -1;
        SetCampaignINSortedBy(-1);
      } else {
        SortBy = 1;
        SetCampaignINSortedBy(1);
      }
      SetCampaignINSortField(Field);
      var str_in = {
        Page: CampaignINPage,
        RowsPerPage: CampaignINRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: CampaignINSortedBy,
        Search: CampaignINSearch,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CategoryID: CategoryValue,
        Role:Role

      };

      const Rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/Campaigncategory/CampaignInCategoryList",
        method: "POST",
        data: str_in,
      });
      ;
      SetCampaignINData(Rows1.data.PageData);
      SetCampaignINRows(Rows1.data.PageData);
      SetCampaignINRlen(Rows1.data.TotalCount);
      SetCampaignINFlen(Rows1.data.TotalCount);
      SetCampaignINCountPage(Rows1.data.PageCount);
      if (Rows1.data.PageData.length !== 0) {
        var tempresult = Rows1.data.PageData.map((item) => {
          if (item.CampaignInCategpryList.length !== 0) {
            document.getElementById(item._id).checked = true;
          } else {
            document.getElementById(item._id).checked = false;
          }
        });
        const results = await Promise.all(tempresult);
      }
    } else {
      SerchBox = true;
      SetCampaignINSflag(true);
      var SortField = Field;
      var SortBy;
      if (CampaignINSortedBy == 1) {
        SortBy = -1;
        SetCampaignINSortedBy(-1);
      } else {
        SortBy = 1;
        SetCampaignINSortedBy(1);
      }
      SetCampaignINSortField(Field);
      var str_in = {
        Page: CampaignINPage,
        RowsPerPage: CampaignINRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: CampaignINSortedBy,
        Search: CampaignINSearch,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CategoryID: CategoryValue,
        Role:Role

      };

      const Rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/Campaigncategory/CampaignInCategoryList",
        method: "POST",
        data: str_in,
      });
      ;
      SetCampaignINData(Rows1.data.PageData);
      SetCampaignINRows(Rows1.data.PageData);
      SetCampaignINRlen(Rows1.data.TotalCount);
      SetCampaignINFlen(Rows1.data.TotalCount);
      SetCampaignINCountPage(Rows1.data.PageCount);
      if (Rows1.data.PageData.length !== 0) {
        var tempresult = Rows1.data.PageData.map((item) => {
          if (item.CampaignInCategpryList.length !== 0) {
            document.getElementById(item._id).checked = true;
          } else {
            document.getElementById(item._id).checked = false;
          }
        });
        const results = await Promise.all(tempresult);
      }
    }
  };

  // back btn
  const BackBtn = () => {
    history.push("/CCampaignCategories")
  }

  // update total campaigns save btn
  const SaveBtn = () => {
    let InputParameter = {
      ClientID:ClientID,
      UserID:UserID,
      CreatedBy:UserIDby,
      CategoryID:CategoryValue,
      CreatedDt:new Date(),
      ArrayCheck:ArrayCheck,
      ArrayUnCheck:ArrayUnCheck,
      Role:Role

      
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Campaigncategory/CampaignInCategoryUpdate",
      method: "POST",
      data: InputParameter,
    }).then((res)=> {
      if(res.statusText=="OK"){
        Swal.fire({
          title: "Campaign Category",
          text:"Selected Campaign added successfully.",
          icon: "success",
        }
        );
      } else {
      }
    })
  }
  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> 

      <div className="row pt-4">
        <div class="col-lg-6 boxsinput_group">
          <div class="row max-cols">
            <label class="col-lg-4 view-lable-meet">Category Name</label>
            <div className="col-lg-8">
              <select
                className="form-control  m-input"
                name="CategoryID"
                id="CategoryID"
                defaultValue={CategoryValue}
                onChange={(e) => HandleDropdownChangeCampaignCategory(e)}
              >
                {Data.map((value) => (
                  <option value={value._id}>{value.Name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div class="col-lg-6 boxsinput_group">
          <div class="col-lg-12 pull-left">
            <a
              id="submit"
            onClick={SaveBtn}
              class="btn btn-primary btn-lightgreen mr-1"
            >
              <i class="la la-save"></i> Save
            </a>
            <a
              id="backtolist"
              onClick={BackBtn}
              class="btn btn-secondary"
            >
              <i class="la la-arrow-circle-left"></i> Back
            </a>
          </div>
        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className="pr-0"
                  style={{ width: 50, textAlign: "center" }}
                >
                  <input
                    name="select_all"
                    value="1"
                    type="checkbox"
                    autocomplete="off"
                  />
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Name");
                    }}
                  >
                    Name
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CampaignINRows.length === 0 ? <p className="text-center">No data available in table</p> : CampaignINRows.map((row) => (
                <TableRow>
                  <TableCell 
                       className="pr-0"
                  style={{ width: 50, textAlign: "center" }}>
                    {console.log(row)}
                    <input
                      type="checkbox"
                      id={row._id}
                      onChange={(e) => CheckBoxAdd(e, row._id)}
                    />
                  </TableCell>
                  <TableCell>{row.Name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {CampaignINSflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CampaignINCountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing{" "}
              {CampaignINRlen == 0
                ? 0
                : (CampaignINPage - 1) * CampaignINRowsPerPage + 1}{" "}
              to{" "}
              {CampaignINPage * CampaignINRowsPerPage > CampaignINRlen
                ? CampaignINRlen
                : CampaignINPage * CampaignINRowsPerPage}{" "}
              of {CampaignINRlen} entries (filtered from {CampaignINFlen} total
              entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing{" "}
              {CampaignINRlen == 0
                ? 0
                : (CampaignINPage - 1) * CampaignINRowsPerPage + 1}{" "}
              to{" "}
              {CampaignINPage * CampaignINRowsPerPage > CampaignINRlen
                ? CampaignINRlen
                : CampaignINPage * CampaignINRowsPerPage}{" "}
              of {CampaignINRlen} entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CampaignINCountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
