import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddClientStatusPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      IsExists: false,
      BtnDisabled:false
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title="Add Client Statuses | SalesHive";

  }

  ///===== start Validation=============
  async FromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};
    var Status = document.getElementById("status").value.trim();

    var IsExist = await this.CheckExistStatus(Status);
    if (Status == "") {
      formIsValid = false;
      errors["status"] = "Please enter Client Status.";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.status != "") {
      this.state.errors.status = null;
      this.state.statusAvailable = null;
      this.CheckExistStatus(e.target.value);
    } else {
      this.state.statusAvailable = null;
      this.state.errors.status = null;
    }
  }
  ////======End Validation==============

  async CheckExistStatus(Status) {
    var InputParameter = {
      Status: Status,
    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusExists",
      method: "POST",
      data: InputParameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({ statusAvailable: resdata.data.Data });
        return true;
      } else {
        return false;
      }
    }
  }

  backbtn() {
    history.push("/clientstatuses");
  }

  async savebtn(e) {
    this.setState({BtnDisabled:true});
    this.state.errors.Name = null;
    this.state.statusAvailable = null;
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Status = document.getElementById("status").value;
      let OldClient = document.getElementById("IsOldClient").checked;
      let RemoveFromClientReporting = document.getElementById("RemoveFromClientReporting").checked;
      var InputParameter = {
        ClientStatusName: Status.trim(),
        ClientID: this.state.ClientID,
        CreatedDate: new Date(),
        CreatedBy: this.state.CUserID,
        OldClient: OldClient,
        RemoveFromClientReporting: RemoveFromClientReporting,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Client Statuses
              <br />
              Client Statuses added successfully
            </div>
          );
          history.push("/clientstatuses");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false});
        }
      });
    } else {
      document.getElementById("submit").disabled = false;
      this.setState({BtnDisabled:false});
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" />
          <div className="bodyhome"> 
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">
                    Add Client Status
                  </h4>
                </div>
              </div>
            <div class="paddcols mb-3">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        // onChange={(e)=>{this.CheckExistStatus1(e)}}
                        data-val="true"
                        data-val-required="Please enter status"
                        onBlur={this.handleChange.bind(this, "status")}
                        id="status"
                        name="status"
                        placeholder="Enter name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["status"]}
                      </span>
                      {this.state.statusAvailable && (
                        <span style={{ color: "red" }}>
                          Status already exist.
                        </span>
                      )}
                    </div>
                    <div class="col-lg-6 boxsinput_group">
                      <label class="check_bx floatrighter mr-3">
                        <input id="IsOldClient" type="checkbox" />
                        <span className="checkmark"></span>
                        Is Old Client
                      </label>
                    </div>
                    <div class="col-lg-6"></div>

                    <div class="col-lg-6 boxsinput_group">
                      <label class="check_bx floatrighter mr-3">
                        <input id="RemoveFromClientReporting" type="checkbox" />
                        <span className="checkmark"></span>
                        Remove From Client Reporting
                      </label>
                    </div>
                  </div>
                </div>
              </div> 
            </div>

              <div class="row pb-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedclientstatuspage = connect(
  mapState,
  actionCreators
)(AddClientStatusPage);
export { connectedclientstatuspage as AddClientStatusPage };
