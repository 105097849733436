import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import { history } from "../../../_helpers";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Open, setOpen] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [UpdateArray, SetUpdateArray] = React.useState([]);
  const [SendingSchedul, SetSendingSchedul] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SendingSchedulingTimeGet(Details.ClientID, Details.Role);
    EmailWarmUpSender(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  // Email Warm up Sender
  const EmailWarmUpSender = (CID, UID, URole) => {
    SetIsLoading(true);
    var InpurtData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpSenderGet",
      method: "POST",
      data: InpurtData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);

    var SearchedVal = document.getElementById("search").value;
    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        Search: SearchedVal,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };

      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpSenderGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };

      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpSenderGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  // when check box update data
  const CheckBoxClick = (e, row) => {
    if (e.target.checked) {
      var temArray = UpdateArray;
      temArray.push(row);
      SetUpdateArray(temArray);
    } else if (!e.target.checked) {
      UpdateArray.splice(row, 1);
    }
  };

  //Check Box Update
  const IsIncludeWarmUpCheck = async (ID, checkedstatus) => {
    let CheckBoxs = document.getElementById("CheckBoxs").checked;
    if (!checkedstatus) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to Add warm up.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
      }).then((Result) => {
        if (Result.isConfirmed) {
          var InputData = {
            AccountID: ID,
            LastUpdatedBy: CUserID,
            LastUpdatedDate: new Date(),
            IsIncludeWarmUp: !checkedstatus,
            Destination: "Sender",
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/email_warm_up/IsIncludeWarmUpUpdate",
            method: "POST",
            data: InputData,
          }).then((Res) => {
            SetIsLoading(false);

            if (Res) {
              if ((Res.statusText = "Ok")) {
                Swal.fire("Added !", "Add warm up successfully.", "success");
                EmailWarmUpSender(ClientID, UserID, Role);
              } else {
              }
            }
          });
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to Remove warm up.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
      }).then((Result) => {
        if (Result.isConfirmed) {
          var InputData = {
            AccountID: ID,
            LastUpdatedBy: CUserID,
            LastUpdatedDate: new Date(),
            IsIncludeWarmUp: !checkedstatus,
            Destination: "Sender",
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/email_warm_up/IsIncludeWarmUpUpdate",
            method: "POST",
            data: InputData,
          }).then((Res) => {
            SetIsLoading(false);
            if (Res) {
              if ((Res.statusText = "Ok")) {
                Swal.fire("Remove!", "warm up remove successfully.", "success");
                EmailWarmUpSender(ClientID, UserID, Role);
              } else {
              }
            }
          });
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
          SetIsLoading(false);
        }
      });
    }
  };

  const EmailWarmUpHistoryPage = (ID) => {
    history.push("/emailwarmuphistory", { ID: ID });
  };

  const EditPage = (ID) => {
    history.push("/editemailwarmup", ID);
  };

  // sending scheduling time get
  const SendingSchedulingTimeGet = async () => {
    var SendingSchedulTimeData = {
      ClientID: ClientID,
      Role: Role,
    };
    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/SendingschedulingGet",
      method: "POST",
      data: SendingSchedulTimeData,
    });
    // this.setState({
    //   SendingSchedul: rows?.data?.PageData,
    // });
    SetSendingSchedul(rows?.data?.PageData);
  };

  return (
    <div>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("IsIncludeWarmUp");
                    }}
                  >
                    Is Include WarmUp
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >
                    Client
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountName");
                    }}
                  >
                    Account Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("StartingVolume");
                    }}
                  >
                    Starting Volume
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("EndingVolume");
                    }}
                  >
                    Ending Volume
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("RampIncrement");
                    }}
                  >
                    Ramp Percentage
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TodayVolume");
                    }}
                  >
                    Today Volume
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ReplyPercentage");
                    }}
                  >
                    Reply Percentage
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("EmailDelay");
                    }}
                  >
                    Email Delay
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("SpamCount");
                    }}
                  >
                    Spam Count
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ScheduleStartTime");
                    }}
                  >
                    Schedule StartTime
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow key={row._id} row={row}>
                    <TableCell align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => EmailWarmUpHistoryPage(row.AccountID)}
                      >
                        <i
                          class="fa fa-plus-circle plusbl"
                          aria-hidden="true"
                        ></i>
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        id="CheckBoxs"
                        onClick={() =>
                          IsIncludeWarmUpCheck(
                            row.AccountID,
                            row.IsEmailWarmingSending
                          )
                        }
                        onChange={(e) => CheckBoxClick(e, row)}
                        checked={row.IsEmailWarmingSending}
                      />
                      {/* { row.IsIncludeWarmUp ? <input type='checkbox' onClick={()=>IsIncludeWarmUpCheck(row.AccountID)} onChange={(e) => CheckBoxClick(e, row)} id="CheckBoxs" checked/>:<input type='checkbox' id="CheckBoxs" onClick={()=>IsIncludeWarmUpCheck(row.AccountID)} onChange={(e) => CheckBoxClick(e, row)} />} */}
                    </TableCell>
                    <TableCell>{row.ClientName}</TableCell>
                    <TableCell>{row.AccountName}</TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.StartingVolume}</TableCell>
                    <TableCell>{row.EndingVolume}</TableCell>
                    <TableCell>{row.RampIncrement}</TableCell>
                    <TableCell>{row.TodayVolume}</TableCell>
                    <TableCell>{row.ReplyPercentage}</TableCell>
                    <TableCell>{row.EmailDelay}</TableCell>
                    <TableCell>{row.SpamCount}</TableCell>
                    {/* {SendingSchedul?.map((value) => (
                          <TableCell value={value._id}>
                            {value?.ScheduleStartUTCTime!=null?moment(value?.ScheduleStartUTCTime,'HH:mm').format('h:mm A'):("")}
                            {" "}To{" "}
                            {value?.ScheduleEndUTCTime!=null?moment(value?.ScheduleEndUTCTime,'HH:mm').format('h:mm A'):("")}
                          </TableCell>
                        ))} */}

                    {/* {SendingSchedul
  .filter((value) => row.SendingSchedularID === value._id)
  .map((value) => ( */}
                    <TableCell key={row._id}>
                      {row.SS?.ScheduleStartUTCTime != null &&
                      row.SS?.ScheduleStartUTCTime != null ?
                          `${moment(row.SS?.ScheduleStartUTCTime ,'HH:mm').format('h:mm A')} To ${moment(row.SS?.ScheduleEndUTCTime,"HH:mm").format("h:mm A")}`
                        : ""}
                    </TableCell>
                    {/* ))} */}

                    <TableCell>
                      <a
                        onClick={() => EditPage(row._id)}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      {/* <a href="#" className="btn-eyesicon"><i class="la flaticon-Update-1 delete-icon"></i></a>  */}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
