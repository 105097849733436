import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import "react-toastify/dist/ReactToastify.css";
toast.configure();
var DeliverabilityVariable = [
  { name: "FullName" },
  { name: "Title" },
  { name: "Email" },
];
var Vari = {};
if (DeliverabilityVariable.length > 0) {
  for (var i = 0; i < DeliverabilityVariable.length; i++) {
    Vari["{" + DeliverabilityVariable[i].name + "}"] =
      DeliverabilityVariable[i].name;
  }
}

class AddDeliverabilityTestingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      code: "",
      fields: {},
      errors: {},
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      VariableType: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
      signature: {},
      BtnDisabled:false
    };

    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title = "Add | SalesHive";

    Froala.RegisterCommand("Variable", {
      title: "Advanced options",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: Vari,
      callback: function (cmd, val) {
        var editorInstance = this;
        editorInstance.html.insert(val);
      },
    });
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
  }
  FromValidation() {
    let formIsValid = true;
    var Subject = document.getElementById("Title").value.trim();
    // 
    var Message = this.state.signature?.data;

    let errors = {};

    if (Subject.length <= 0) {
      formIsValid = false;
      errors["Subject"] = "Please enter subject";
      // toast.error("Please enter subject");
    }else if (Message == undefined) {
      formIsValid = false;
      toast.error("Please enter message");
      // errors["Message"] = "Please enter Message";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleModelChange = (model) => {
    let datas = {};
    datas.data = model;
    this.setState({
      signature: datas,
    });
    // 
  };

  config = {
    placeholderText: "Edit Your Content Here!",
    charCounterCount: false,
    toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'insertImage', 'html', 'Variable'],
    shortcutsEnabled: ["insertTemplateButton"],
    imageUploadURL: CommonConstants.MOL_APIURL + "/meetingremindersetting/upload_image",
    imageUploadRemoteUrls: false,
    enter: Froala.ENTER_BR
  };

  // handlechange
  handleChange(field, e) {
    document.getElementById("blank_error").style.display = "none";
    // 
    // 
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Name != "") {
      this.state.errors.Name = null;
      this.state.NameAvailable = null;
      this.CheckExistvariableName(e.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.errors.Name = null;
    }
  }

  backbtn() {
    history.push("/deliverabilitytesting");
  }
  savebtn() {
    this.setState({BtnDisabled:true})
    if (this.FromValidation()) {
      var Subject = document.getElementById("Title").value;
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Subject: Subject,
        Message: this.state.signature.data,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/DeliverAbilityTesting/DeliverAbilityTestingAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">Deliverability testing <br/> Deliverability testing added successfully.</div>
          );
          history.push("/deliverabilitytesting");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });
    }else{
    this.setState({BtnDisabled:false})

    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            
              <div className="row pt-4 pb-3">
                <div className="col px-3">
                  <h4 className="headertitlenop float-left px-1">Add</h4>
                </div>
              </div>
            <div class="whiterow">
              <div className="row pt-4 px-4">
                <div class="col-lg-12 boxsinput_group px-3">
                  <div class="row px-1">
                    <label class="col-lg-2 view-lable-meet">Subject</label>
                    <div class="col-lg-10">
                      <input
                        class="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Subject"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pb-3 px-4">
                <div class="col-lg-12 boxsinput_group px-3">
                  <div class="row px-1">
                    <label class="col-lg-2 view-lable-meet">Message</label>
                    <div class="col-lg-10">
                      <FroalaEditor
                        id="signature"
                        config={this.config}
                        onModelChange={this.handleModelChange}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Message"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="row pb-4 pt-4">
                <div class="col-lg-12 pull-left px-3">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDeliverabilityTestingPage = connect(
  mapState,
  actionCreators
)(AddDeliverabilityTestingPage);
export { connectedAddDeliverabilityTestingPage as AddDeliverabilityTestingPage };
