import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Tabing from '../../_components/Tabing';
import CollapsibleTable from '../../_components/usertable';



class HomePage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="col">
                        <h4 className="headertitle width100">Booked Meetings</h4>
                    </div>
                    <span className="devider"></span>
                    <div className="col">
                        <Tabing />
                    </div>
                </div>

                </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
export { connectedHomePage as HomePage };