import React, { useState, useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
var CryptoJS = require("crypto-js");
const moment = require("moment");
import Select from "react-select";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../Kpis/LastdaysTabing";
import Swal from "sweetalert2";
import EditAccountTable from "./EditAccountTable";
import ContactTable from "./ContactTable";
import ResportingTabing from "./ResportingTabing";
import ResponsesTable from "../Campaigns/ResponsesTable";
import AddContactsCampaign from "../EditCampaigns/ContactTable";
import loadingicon from "../../../images/loading.gif";
import ImageUploading from "react-images-uploading";
import Popup from "reactjs-popup";


import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";

import "froala-editor/js/plugins.pkgd.min.js";
import Froala from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
const Fileupload = require("../../../_helpers/fileupload");
import { makeAxiosCall } from "../../../_helpers/emod-ai";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { styled } from "@material-ui/core/styles";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
// import Typography from '@material-ui/core/Typography';
// import Accordion from "../ReactStepWizard/Accordion";

import { Col, Row } from "react-bootstrap";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import ConfigureEmailAccounts from "../EditCampaigns/ConfigureEmailAccounts";
import { Variables } from "../../../_constants/common.constants";
const EmailTemplete = require("../../../_helpers/email-templete");
import parse from "html-react-parser";
import AddListTable from "./ListsTable";
import ContactTableEdit from "./ContactTableEdit";
import $ from "jquery";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import Tooltip from "@material-ui/core/Tooltip";
import CreatableSelect from 'react-select/creatable';
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import { width } from "@mui/system";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#f4f5f8",
  color: "#7b7e8a",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const defaultTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "yellow",
        backgroundColor: "red",
      },
    },
  },
});

export default function SimpleTabs({ campaignID,CPage,email, pid, AccountID,PageName}) {

  const classes = useStyles(); 
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [ClientID, SetClientID] = React.useState(null);
  const [UserID, SetUserID] = React.useState(null);
  const [Role, SetRole] = React.useState(null);
  const [Campaign, SetCampaign] = React.useState("");
  const [CampaignOne, SetCampaignOne] = React.useState("");
  const [OpenTracking, SetOpenTracking] = useState([]);
  const [Priority, SetPriority] = useState([]);
  const [ReplyBehavior, SetReplyBehavior] = useState([]);
  const [Errors, SetErrors] = useState("");
  const [ErrorsStep, SetErrorsStep] = useState({});
  const [ExportCsvErrors, SetExportCsvErrors] = useState({});
  const [ConfigEmailPopup, SetConfigEmailPopup] = useState(false);
  const [ChileValueTwo, SetChileValueTwo] = useState(false);
  const [ProspectFlag, SetProspectFlag] = useState(false);
  const [ConfigPop, SetConfigPop] = useState(false);
  const [CreateManuallyPop, SetCreateManuallyPop] = useState(false);
  const [ContactSourceData, SetContactSourceData] = useState([]);
  const [ClientPOCData, SetClientPOCData] = useState([]);
  const [ReasonUnqualifiedData, SetReasonUnqualifiedData] = useState([]);
  const [ContactTagData, SetContactTagData] = useState([]);
  const [ContactOwnerData, SetContactOwnerData] = useState([]);
  const [ContactSalesDevlopMenData, SetContactSalesDevlopMenData] = useState(
    []
  );
  const [PreviewCampaignDetails, SetPreviewCampaignDetails] = useState([]);
  const [ContactCustomFieldData, SetContactCustomFieldData] = useState([]);
  const [ContactManuallyError, SetContactManuallyError] = useState({});
  const [ContactTagSelectArray, SetContactTagSelectArray] = useState([]);
  const [ContactTagsNamesArray, SetContactTagsNamesArray] = useState([]);
  const [TagArray, SetTagArray] = useState([]);
  const [ProspectID, SetProspectID] = useState("");
  const [ProspectCampaignID, SetProspectCampaignID] = useState("");
  const [SignatureSC, SetSignatureSC] = useState({ data: "" });
  const [SignatureSC2, SetSignatureSC2] = useState({ data: "" });
  const [AddStepPopup, SetAddStepPopup] = useState(false);
  const [AddStepTempletePopup, SetAddStepTempletePopup] = useState(false);
  const [CampaignStepFlag, SetCampaignStepFlag] = useState(false);
  const [CampaignDetails, SetCampaignDetails] = useState([]);

  const [PreviewCampignStepDetails, SetPreviewCampignStepDetails] = useState(
    []
  );
  const [PreviewEmailAccountDetails, SetPreviewEmailAccountDetails] = useState(
    []
  );

  const [PreviewSmartVaribleBiggerThan, SetPreviewSmartVaribleBiggerThan] =
    useState([]);
  const [PreviewSmartVaribleFasterThan, SetPreviewSmartVaribleFasterThan] =
    useState([]);
  const [
    PreviewSmartVaribleMoreExcitingThan,
    SetPreviewSmartVaribleMoreExcitingThan,
  ] = useState([]);
  const [PreviewSmartVaribleMascotCheer, SetPreviewSmartVaribleMascotCheer] =
    useState([]);

  const [PreviewCallToAction, SetPreviewCallToAction] = useState([]);
  const [PreviewAutomatorFeature, SetPreviewAutomatorFeature] = useState([]);

  const [PreviewCustomVaribleSets, SetPreviewCustomVaribleSets] = useState([]);

  const [
    SelectedPreviewCustomVaribleSets,
    SetSelectedPreviewCustomVaribleSets,
  ] = useState({});

  const [SelectedPreviewCallToAction, SetSelectedPreviewCallToAction] =
    useState({});
  const [SelectedPreviewAutomatorFeature, SetSelectedPreviewAutomatorFeature] =
    useState({});

  const [SelectedPreviewCampign, SetSelectedPreviewCampign] = useState({});
  const [SelectedPreviewAccountEmail, SetSelectedPreviewAccountEmail] =
    useState({});
  const [
    SelectedPreviewSmartVaribleBiggerThan,
    SetSelectedPreviewSmartVaribleBiggerThan,
  ] = useState({});
  const [
    SelectedPreviewSmartVaribleFasterThan,
    SetSelectedPreviewSmartVaribleFasterThan,
  ] = useState({});
  const [
    SelectedPreviewSmartVaribleMoreExcitingThan,
    SetSelectedPreviewSmartVaribleMoreExcitingThan,
  ] = useState({});
  const [
    SelectedPreviewSmartVaribleMascotCheer,
    SetSelectedPreviewSmartVaribleMascotCheer,
  ] = useState({});

  const [CampaignStepID, SetCampaignStepID] = useState(null);
  const [DaysTemp, SetDaysTemp] = useState(0);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [expanded, setExpanded] = React.useState("panel1");
  // for imoirt csv
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  // variables dropdown mapping
  const [FirstName, SetFirstName] = React.useState(null);
  const [LastName, SetLastName] = React.useState(null);
  const [Email, SetEmail] = React.useState(null);
  const [Title, SetTitle] = React.useState(null);
  const [Company, SetCompany] = React.useState(null);
  const [CompanyNameLong, SetCompanyNameLong] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [City, SetCity] = React.useState(null);
  const [State, SetState] = React.useState(null);
  const [ContactCategory, SetContactCategory] = React.useState(null);
  const [AccountCategory, SetAccountCategory] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [Address1, SetAddress1] = React.useState(null);
  const [Address2, SetAddress2] = React.useState(null);
  const [LinkedInURL, SetLinkedInURL] = React.useState(null);
  const [Zip, SetZip] = React.useState(null);
  const [Country, SetCountry] = React.useState(null);
  const [ValidationScore, SetValidationScore] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [CompanyRevenue, SetCompanyRevenue] = React.useState(null);
  const [EmployeeCount, setEmployeeCount] = React.useState(null);
  const [MobilePhone, SetMobilePhone] = React.useState(null);
  const [DirectPhone, SetDirectPhone] = React.useState(null);
  const [DirectPhoneExt, SetDirectPhoneExt] = React.useState(null);
  const [OtherPhone1, SetOtherPhone1] = React.useState(null);
  const [OtherPhone2, SetOtherPhone2] = React.useState(null);
  const [ContactSource, SetContactSource] = React.useState(null);
  const [ContactOwner, SetContactOwner] = React.useState(null);
  const [ClientPOC, SetClientPOC] = React.useState(null);
  const [ReasonUnqualified, SetReasonUnqualified] = React.useState(null);
  const [AccountType, SetAccountType] = React.useState(null);
  const [IsDoNotCallContact, SetIsDoNotCallContact] = React.useState(null);
  const [SalesRep, SetSalesRep] = React.useState(null);
  const [ColdCaller, SetColdCaller] = React.useState(null);
  const [Tag, SetTag] = React.useState(null);
  const [CallNotes, SetCallNotes] = React.useState(null);
  const [ContactID, SetContactID] = React.useState(null);
  const [ListName, SetListName] = React.useState(null);
  const [ShowPopupExport1, SetShowPopupExport1] = React.useState(false);
  const [MannuallyDisables, SetMannuallyDisables] = React.useState(false);
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalUpdated, SetTotalUpdated] = React.useState(0);
  const [TotalEmailValiDationScoreD, SetTotalEmailValiDationScoreD] =
    React.useState(0);
  const [TotalEmailValiDationScoreF, SetTotalEmailValiDationScoreF] =
    React.useState(0);
  const [TotalSkipped, SetTotalSkipped] = React.useState(0);
  const [TotalContactBlacklisted, SetTotalContactBlacklisted] =
    React.useState(0);
  const [TotalRemoved, SetTotalRemoved] = React.useState(0);
  const [TotalDomainBlackListed, SetTotalDomainBlackListed] = React.useState(0);
  const [TotalGlobalDomainBlackListed, SetTotalGlobalDomainBlackListed] =
    React.useState(0);
  const [TotalDefaultCountryBlackListed, SetTotalDefaultCountryBlackListed] =
    React.useState(0);
  const [TotalCountryBlackListed, SetTotalCountryBlackListed] =
    React.useState(0);
  const [TotalReplied, SetTotalReplied] = React.useState(0);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [ShowPopupImport, SetShowPopupImport] = React.useState(false);
  const [ShowImportContactCsv, SetShowImportContactCsv] = React.useState(false);
  const [ContactSampleExport, SetContactSampleExport] = React.useState([]);

  const [EditStepTempletePopup, SetEditStepTempletePopup] = useState(false);
  const [CampaignStepTemplatesID, SetCampaignStepTemplatesID] = useState("");
  const [SignatureSC3, SetSignatureSC3] = useState({ data: "" });

  // csv errors table details
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [CSVEPage, SetCSVEPage] = React.useState(1);
  const [CSVERowsPerPage, SetCSVERowsPerPage] = React.useState(15);
  const [Page, SetPage] = React.useState(1);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ContactExitsData, SetContactExitsData] = React.useState(false);
  const [CampaignDataDrop, SetCampaignDataDrop] = React.useState([]);
  const [IsVisible, SetIsVisible] = useState(false);
  const [ListPopup, SetListPopUp] = useState(false);
  // For Deleted data
  const [dataFromChild, setDataFromChild] = useState([]);
  const [DataFromCampaign, setDataFromCampaign] = useState([]);
  const [filenameonlynumber, setfilenameonlynumber] = React.useState("");
  const [CsvFileName, SetCsvFileName] = React.useState("");
  const [ClientEmod, SetClientEmod] = React.useState({});
  const [
    EditStepTempletPopHandleSendTest,
    SetEditStepTempletPopHandleSendTest,
  ] = useState(false);
  const [
    AddStepTempletPopHandleSendTest,
    SetAddStepTempletPopHandleSendTest,
  ] = useState(false);
  const [SendTestAccount, SetSendTestAccount] = useState([]);
  const [SignatureSendTest, SetSignatureSendTest] = React.useState("");

  const [SaveFilterBtnDisabled,SetSaveFilterBtnDisabled] = React.useState(false)

  var Vari = {};
  if (Variables.length > 0) {
    for (var i = 0; i < Variables.length; i++) {
      Vari["{" + Variables[i].name.split(" ").join("") + "}"] =
        Variables[i].name;
    }
  }

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${month}/${day}/${year}`;
  };

  //Filter State Starts from here
  const [OpenFilter, SetOpenFilter] = React.useState(false);
  const [OpenAddFilter, SetOpenAddFilter] = React.useState(false);
  const [OpenEditFilter, SetOpenEditFilter] = React.useState(false);
  const [FieldAttributes, SetFieldAttributes] = React.useState([
    { id: 0, ColumnName: "IsPaused", InputType: "Yes" },
  ]);
  const [FilterParameters, SetFilterParameters] = React.useState([]);
  const [IsApply, SetIsApply] = React.useState(false);
  const [FilterName, SetFilterName] = React.useState([]);
  const [EditFieldAttributes, SetEditFieldAttributes] = React.useState([]);
  const [FilterID, SetFilterID] = React.useState(null);
  const [DeletedFilterProperty, SetDeletedFilterProperty] = React.useState([]);

  const [errors, Seterrors] = React.useState({});
  const [IsInValidWebsite, SetIsInValidWebsite] = React.useState(false);
  const [WebsiteNotNull, SetWebsiteNotNull] = React.useState(false);
  const [IsValidEmail, SetIsValidEmail] = React.useState(false);
  //eMods
  const [eModProfile, SeteModProfile] = useState([]);
  const [eModProfilestaticID, SeteModProfilestaticID] = useState("");
  const [eModProfileDefault, SeteModProfileDefault] = useState("");
  const [eModProfileDefaultEdit, SeteModProfileDefaultEdit] = useState("");
  const [ContactPageFlag,SetContactPageFlag] = useState(false)

  const [TagNameArray, setTagNameArray] = useState([]);
  const [ContactTagsNamesArrayAlready, setContactTagsNamesArrayAlready] = useState([]);
  const [TagArrayAlready, setTagArrayAlready] = useState([]);
  useEffect(() => {
    
    var PageIndex = value;
  
    if(PageName == "ViewContact" && ContactPageFlag === false){
      setValue(2);
      PageIndex = 2;
      SetContactPageFlag(true)
          }
    document.title = "Edit Campaign | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetRole(Details.Role);
      SetUserEmail(Details.Username);
    }

    SetCampaign(campaignID);
    GetCampaignsList(Details.ClientID, Details.ParentUserID);
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/prospect_campaign/ContactCsvImportData",
      method: "GET",
    }).then((res) => {
      SetContactSampleExport(res.data);
    });

    SetOpenTracking([]);
    SetReplyBehavior([]);
    SetPriority([]);
    if (PageIndex === 0) {
      GetAllDroupDown(Details.ClientID, Details.ParentUserID, Details.Role);
      GetCampaignOneDetails(campaignID);
    } else if (PageIndex === 1) {
    } else if (PageIndex === 2) {
      GetContactAllDroupDown(
        Details.ClientID,
        Details.ParentUserID,
        Details.Role
      );
    } else if (PageIndex === 3) {
      GetCampaignStepDetails(
        Details.ClientID,
        Details.ParentUserID,
        Details.Role
      );
    } else if (PageIndex === 4) {
      GetPreviewCampignStepDetails(
        Details.ClientID,
        Details.ParentUserID,
        Details.Role,
        campaignID
      );
    } else {
    }
    Getfilterlist(Details.ClientID, Details.ParentUserID);
    
    // SetAddFilterColumn(Details);
  }, [
    value,
    campaignID,
    ChileValueTwo,
    ConfigEmailPopup,
    Errors,
    ProspectFlag,
    CampaignStepFlag,
    AddStepTempletePopup,
    UserEmail,
  ]);
  // useEffect(() => {
  //         //Set view contact tab
  //         if(PageName == "ViewContact"){
  //           setValue(2);
  //         }else if(PageName == "Campaign")
  //         {
  //           setValue(newValue);
  //         }else{
  //           setValue(newValue);
  //         }
  // },[PageName])
  const handleDataFromCampaign = (data) => {
    setDataFromCampaign(data);
  };

  const handleDataFromChildContactExits = (data) => {
    SetContactExitsData(data);
    SetConfigPop(false);
    SetListPopUp(false);
  };

  //Reload page while delete perform
  const UpdateFromChild = (value) => {
    SetProspectFlag(false);
    SetCampaign(value);
    SetIsVisible(true);
    setTimeout(() => {
      SetIsVisible(false); // Hide the loader
      SetProspectFlag(true);
    }, 3000);
  };
  var Vari = {};
  if (Variables.length > 0) {
    for (var i = 0; i < Variables.length; i++) {
      Vari["{" + Variables[i].name.split(" ").join("") + "}"] =
        Variables[i].name;
    }
  }

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  let config = {
    placeholderText: "Type something!",
    charCounterCount: false,
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "insertLink",
      "insertImage",
      "html",
      "Variable",
    ],
    shortcutsEnabled: ["insertTemplateButton"],
    enter: Froala.ENTER_BR,
    key:"GPD2tA3A2B1A3C2F2sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4G1A3B8C7C6=="
  };

  const handleChangeAccrdion = (id) => {
    if (activeIndexes.indexOf(id) !== -1) {
      setActiveIndexes(activeIndexes.filter((i) => i !== id));
    } else {
      setActiveIndexes([id]);
    }
  };

  const handleeModProfileDefault = (event) => {
    SeteModProfileDefault(event.target.value);
  };

  const handleeModProfileDefaultEdit = (event) => {
    SeteModProfileDefaultEdit(event.target.value);
  };

  const GetCampaignOneDetails = async (CAID) => {
    var StrIn = {
      CampaignID: CAID,
    };
    var Result = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignByID",
      method: "POST",
      data: StrIn,
    });

    if (Result.data.StatusMessage === "SUCCESS") {
      
      SetCampaignOne(Result.data?.Data[0]);
      SeteModProfileDefault(Result?.data?.Data[0]?.EModProfileID);
      document.getElementById("CampaignName").value =
        Result?.data?.Data[0]?.Name;
      document.getElementById("Notes").value = Result?.data?.Data[0]?.Notes;
      document.getElementById("SubjectPromptCheckBox").checked =
        Result?.data?.Data[0]?.useEmodSubject;
      document.getElementById("SendOnWeekends").checked =
        Result.data.Data[0]?.IsSendonWeekends;
      document.getElementById("IgnoreBlacklist").checked =
        Result.data.Data[0]?.IsIncludeBlacklistedContacts;
      document.getElementById("TrackClicks").checked =
        Result.data.Data[0]?.IsTrackClick;
    }
  };

  // Default campaign add first step details get
  const GetAllDroupDown = async (CID, UID, URole) => {
    SetIsVisible(true);
    var StrInOpenTracking = {
      FieldTypeName: "EmailOpenTracking",
    };
    var EmailOpenTracking = await Axios({
      url: CommonConstants.MOL_APIURL + "/commonfiled/GetCommonField",
      method: "POST",
      data: StrInOpenTracking,
    });
    if (EmailOpenTracking.data.StatusMessage == "SUCCESS") {
      SetOpenTracking(
        EmailOpenTracking.data.Data.sort((a, b) => a.OrderBy - b.OrderBy)
      );
    }
    var StrInPriority = {
      FieldTypeName: "CampaignPriority",
    };
    var Priority = await Axios({
      url: CommonConstants.MOL_APIURL + "/commonfiled/GetCommonField",
      method: "POST",
      data: StrInPriority,
    });
    if (Priority.data.StatusMessage == "SUCCESS") {
      SetPriority(Priority.data.Data);
    }
    var StrInReplyBehavior = {
      FieldTypeName: "ReplyBehavior",
    };
    var ReplyBehavior = await Axios({
      url: CommonConstants.MOL_APIURL + "/commonfiled/GetCommonField",
      method: "POST",
      data: StrInReplyBehavior,
    });
    if (ReplyBehavior.data.StatusMessage == "SUCCESS") {
      SetReplyBehavior(ReplyBehavior.data.Data);
    }

    //eMod List
    let eModMenu = {
      UserID: UID,
      Role: Role,
    };
    var eModMenuParams = await Axios({
      url: CommonConstants.MOL_APIURL + "/eMod/eModCampaignGet",
      method: "POST",
      data: eModMenu,
    });

    if (eModMenuParams.data.StatusMessage == "SUCCESS") {
      SeteModProfile(eModMenuParams.data.Data);
    }

    //client List
    let clientMenu = {
      ClientID: CID,
    };
    var clientMenuParams = await Axios({
      url: CommonConstants.MOL_APIURL + "/Clients/ClientGetByID",
      method: "POST",
      data: clientMenu,
    });

    if (clientMenuParams.data.StatusMessage == "SUCCESS") {
      SetClientEmod(clientMenuParams.data.Data);
    }

    SetIsVisible(false);
  };

  //Default campaign add manually contact droupdownlist
  const GetContactAllDroupDown = async (CID, UID, URole) => {
    // ;
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    if (rows != null) {
      let contacttagoptions = rows.data?.ContactTagData.map((val) => {
        return { value: val.Tag, label: val.Tag, cid: val._id };
      });
      SetContactSourceData(rows.data?.ContactSourceData);
      SetClientPOCData(rows.data?.ClientPOCData);
      SetReasonUnqualifiedData(rows.data?.ReasonUnqualifiedData);
      SetContactTagData(contacttagoptions);
      SetContactOwnerData(rows.data?.ContactOwnerData);
      SetContactSalesDevlopMenData(rows.data?.SalesReplyDevlopmentData);
      SetContactCustomFieldData(rows.data?.ContactCustomFieldData);
    }
  };

  //Get Campaign step details
  const GetCampaignStepDetails = async (CID, UID, URole) => {
    SetIsVisible(true);
    var VariableDroupdown = {
      ClientID: ClientID,
      UserID: UserID,
    };
    var result = await Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/GetVariableValueList",
      method: "POST",
      data: VariableDroupdown,
    });
    if (result.data.Data != undefined) {
      //SetVariableDroupDown(result.data.Data)
      Froala.RegisterCommand("Variable", {
        title: "Advanced options",
        type: "dropdown",
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: result.data.Data,
        callback: function (cmd, val) {
          var editorInstance = this;
          editorInstance.html.insert(val);
        },
        // Callback on refresh.
        refresh: function ($btn) {
          //
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
          //
        },
      });
    }

    //Send Test Start
    var VariableDroupdownsendtest = {
      ClientID: ClientID,
      CampaignID: campaignID,
      Role: Role,
    };
    var resultsendtest = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignaccount/CampaignAccountGetSendTest",
      method: "POST",
      data: VariableDroupdownsendtest,
    });

    if (resultsendtest.data.StatusMessage === "SUCCESS") {
      SetSendTestAccount(resultsendtest.data.Data);
    }
    //Send Test End

    var str_inlist = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureList",
      method: "POST",
      data: str_inlist,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          SetSignatureSendTest(resdata.data.Data[0].Signature);
        }
      }
    });

    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: Campaign,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/GetCampaignStep",
      method: "POST",
      data: str_in,
    });
    //
    if (rows.data.StatusMessage == "SUCCESS") {
      var TmpData = rows.data.Data;
      var TotalCount = 1;
      var result = rows.data.Data.map((item) => {
        TotalCount = TotalCount + item.Days;
        item["TotalCount"] = TotalCount;
      });
      const results = await Promise.all(result);

      SetCampaignDetails(TmpData);
      //SetCampaignDetails(rows.data.Data);
    }
    SetIsVisible(false);
  };

  //Get preview campaign step details
  const GetPreviewCampignStepDetails = async (CID, UID, URole, CCid) => {
    SetIsVisible(true);
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CCid,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/PreviewCampignStepGet",
      method: "POST",
      data: str_in,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      SetPreviewCampignStepDetails(rows.data.Data);
      SetPreviewEmailAccountDetails(rows.data.DataEmail);
      SetPreviewSmartVaribleBiggerThan(rows.data.DataSmartVaribleBiggerThan);
      SetPreviewSmartVaribleFasterThan(rows.data.DataSmartVaribleFasterThan);
      SetPreviewSmartVaribleMoreExcitingThan(
        rows.data.DataSmartVaribleMoreExcitingThan
      );
      SetPreviewSmartVaribleMascotCheer(rows.data.DataSmartVaribleMascotCheer);
      SetPreviewCallToAction(rows.data.DataCallToAction);
      SetPreviewAutomatorFeature(rows.data.DataAutomatorFeature);

      await GetPreviewCampaignStepDetailss(
        CID,
        UID,
        URole,
        Object.entries(
          SelectedPreviewCampign === undefined ? [] : SelectedPreviewCampign
        ).length === 0
          ? rows.data.Data[
              Math.floor(Math.random() * (rows.data.Data?.length - 1 + 1))
            ]
          : SelectedPreviewCampign,
        Object.entries(
          SelectedPreviewAccountEmail === undefined
            ? []
            : SelectedPreviewAccountEmail
        ).length === 0
          ? rows.data.DataEmail[
              Math.floor(Math.random() * (rows.data.DataEmail?.length - 1 + 1))
            ]
          : SelectedPreviewAccountEmail,
        Object.entries(
          SelectedPreviewSmartVaribleBiggerThan === undefined
            ? []
            : SelectedPreviewSmartVaribleBiggerThan
        ).length === 0
          ? rows.data.DataSmartVaribleBiggerThan[
              Math.floor(
                Math.random() *
                  (rows.data.DataSmartVaribleBiggerThan?.length - 1 + 1)
              )
            ]
          : SelectedPreviewSmartVaribleBiggerThan,
        Object.entries(
          SelectedPreviewSmartVaribleFasterThan === undefined
            ? []
            : SelectedPreviewSmartVaribleFasterThan
        ).length === 0
          ? rows.data.DataSmartVaribleFasterThan[
              Math.floor(
                Math.random() *
                  (rows.data.DataSmartVaribleFasterThan?.length - 1 + 1)
              )
            ]
          : SelectedPreviewSmartVaribleFasterThan,
        Object.entries(
          SelectedPreviewSmartVaribleMoreExcitingThan === undefined
            ? []
            : SelectedPreviewSmartVaribleMoreExcitingThan
        ).length === 0
          ? rows.data.DataSmartVaribleMoreExcitingThan[
              Math.floor(
                Math.random() *
                  (rows.data.DataSmartVaribleMoreExcitingThan?.length - 1 + 1)
              )
            ]
          : SelectedPreviewSmartVaribleMoreExcitingThan,
        Object.entries(
          SelectedPreviewSmartVaribleMascotCheer === undefined
            ? []
            : SelectedPreviewSmartVaribleMascotCheer
        ).length === 0
          ? rows.data.DataSmartVaribleMascotCheer[
              Math.floor(
                Math.random() *
                  (rows.data.DataSmartVaribleMascotCheer?.length - 1 + 1)
              )
            ]
          : SelectedPreviewSmartVaribleMascotCheer,
        Object.entries(
          SelectedPreviewCallToAction === undefined
            ? []
            : SelectedPreviewCallToAction
        ).length === 0
          ? rows.data.DataCallToAction[
              Math.floor(
                Math.random() * (rows.data.DataCallToAction?.length - 1 + 1)
              )
            ]
          : SelectedPreviewCallToAction,
        Object.entries(
          SelectedPreviewAutomatorFeature === undefined
            ? []
            : SelectedPreviewAutomatorFeature
        ).length === 0
          ? rows.data.DataAutomatorFeature[
              Math.floor(
                Math.random() * (rows.data.DataAutomatorFeature?.length - 1 + 1)
              )
            ]
          : SelectedPreviewAutomatorFeature,
        rows.data.DataCustomVariable
      );
      SetIsVisible(false);
    } else {
      SetIsVisible(false);
    }
  };

  const handleClickrandam = () => {
    const randomIndex = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );
    const randomIndexEmail = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );
    const randomIndexBiggerThan = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );
    const randomIndexFasterThan = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );
    const randomIndexMoreExcitingThan = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );
    const randomIndexMascotCheer = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );
    const randomIndexCallToAction = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );
    const randomIndexAutomatorFeature = Math.floor(
      Math.random() * (PreviewCampignStepDetails.length - 1 + 1)
    );

    const randomData = PreviewCampignStepDetails[randomIndex];
    const randomDataEmail = PreviewEmailAccountDetails[randomIndexEmail];
    const randomDataBiggerThan =
      PreviewSmartVaribleBiggerThan[randomIndexBiggerThan];
    const randomDataFasterThan =
      PreviewSmartVaribleFasterThan[randomIndexFasterThan];
    const randomDataMoreExcitingThan =
      PreviewSmartVaribleMoreExcitingThan[randomIndexMoreExcitingThan];
    const randomDataMascotCheer =
      PreviewSmartVaribleMascotCheer[randomIndexMascotCheer];
    const randomDataCallToAction = PreviewCallToAction[randomIndexCallToAction];
    const randomDataAutomatorFeature =
      PreviewAutomatorFeature[randomIndexAutomatorFeature];

    SetSelectedPreviewSmartVaribleBiggerThan(randomDataBiggerThan);
    SetSelectedPreviewSmartVaribleFasterThan(randomDataFasterThan);
    SetSelectedPreviewSmartVaribleMoreExcitingThan(randomDataMoreExcitingThan);
    SetSelectedPreviewSmartVaribleMascotCheer(randomDataMascotCheer);
    SetSelectedPreviewCampign(randomData);
    SetSelectedPreviewAccountEmail(randomDataEmail);
    SetSelectedPreviewCallToAction(randomDataCallToAction);
    SetSelectedPreviewAutomatorFeature(randomDataAutomatorFeature);
    GetPreviewCampignStepDetails(ClientID, UserID, Role, Campaign);
  };

  const handleChange = (event, newValue) => {
      setValue(newValue);

  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });

  const open = Boolean(AnchorEl);
  const id = open ? "simple-popover" : undefined;

  const BackCampaign = async () => {
    
    
    if(CPage === "ViewContact"){
      history.push({
        pathname: "/viewcontacts",
        state: {
          data: pid,
          EmailData: email,
        },
      });
    }else if(PageName === "AccountContact" && CPage == ""){
     
        history.push({ pathname: "/viewaccount", state: { data: AccountID } });
      
    }else if(CPage === "Campaign"){
      history.push("/campaigns");
    }else{
      history.push("/campaigns");
    }
  
  };

  // get value for text editor addd
  const VariableModelChange = (model) => {
    SetSignatureSC({
      data: model,
    });
  };

  // get value for text editor addd
  const VariableModelChange2 = (model) => {
    SetSignatureSC2({
      data: model,
    });
  };

  // get value for text editor addd
  const VariableModelChange3 = (model) => {
    SetSignatureSC3({
      data: model,
    });
  };

  //Get Preview Campaign step details
  const GetPreviewCampaignStepDetailss = async (
    CID,
    UID,
    URole,
    Arr,
    ArrEmail,
    ArrBiggerThan,
    ArrFasterThan,
    ArrMoreExcitingThan,
    ArrMascotCheer,
    ArrCallToAction,
    ArrAutomatorFeature,
    ArrCustomVar
  ) => {
    //
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: Campaign,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/GetCampaignStep",
      method: "POST",
      data: str_in,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      let CampignData = [];
      var CData = rows.data.Data;
      //CData.map(async (ReplaceRes) => {
      for (let i = 0; i < CData.length; i++) {
        var ReplaceRes = CData[i];
        CampignData.push(ReplaceRes.CStepTemplate[0]?._id);
        var cTData = ReplaceRes.CStepTemplate;
        //cTData.map(async (Val) => {
        for (let j = 0; j < cTData.length; j++) {
          var Val = cTData[j];
          var reg = Val.Body.match(/{(.*?)}/g);
          var regSubject = Val.Subject.match(/{(.*?)}/g);
          if (reg != null) {
            reg.forEach((Regex) => {
              if (Regex === "{Email}") {
                if (Arr.PDetails.Email != "" && Arr.PDetails.Email != null) {
                  let BodyData = Val.Body.replace(
                    "{Email}",
                    Arr.PDetails.Email
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{FirstName}") {
                if (
                  Arr.PDetails.FirstName != "" &&
                  Arr.PDetails.FirstName != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{FirstName}",
                    Arr.PDetails.FirstName
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{LastName}") {
                if (
                  Arr.PDetails.LastName != "" &&
                  Arr.PDetails.LastName != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{LastName}",
                    Arr.PDetails.LastName
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Title}") {
                if (Arr.PDetails.Title != "" && Arr.PDetails.Title != null) {
                  let BodyData = Val.Body.replace(
                    "{Title}",
                    Arr.PDetails.Title
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Company}") {
                if (
                  Arr.PDetails.Company != "" &&
                  Arr.PDetails.Company != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Company}",
                    Arr.PDetails.Company
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{CompanyNameLong}") {
                if (
                  Arr.PDetails.CompanyNameLong != "" &&
                  Arr.PDetails.CompanyNameLong != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{CompanyNameLong}",
                    Arr.PDetails.CompanyNameLong
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Website}") {
                if (
                  Arr.PDetails.Website != "" &&
                  Arr.PDetails.Website != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Website}",
                    Arr.PDetails.Website
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{CompanyPhone}") {
                if (
                  Arr.PDetails.CompanyPhone != "" &&
                  Arr.PDetails.CompanyPhone != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{CompanyPhone}",
                    Arr.PDetails.CompanyPhone
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{LinkedInURL}") {
                if (
                  Arr.PDetails.LinkedInURL != "" &&
                  Arr.PDetails.LinkedInURL != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{LinkedInURL}",
                    Arr.PDetails.LinkedInURL
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Industry}") {
                if (
                  Arr.PDetails.Industry != "" &&
                  Arr.PDetails.Industry != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Industry}",
                    Arr.PDetails.Industry
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Address1}") {
                if (
                  Arr.PDetails.Address1 != "" &&
                  Arr.PDetails.Address1 != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Address1}",
                    Arr.PDetails.Address1
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Address2}") {
                if (
                  Arr.PDetails.Address2 != "" &&
                  Arr.PDetails.Address2 != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Address2}",
                    Arr.PDetails.Address2
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{City}") {
                if (Arr.PDetails.City != "" && Arr.PDetails.City != null) {
                  let BodyData = Val.Body.replace("{City}", Arr.PDetails.City);
                  Val.Body = BodyData;
                }
              } else if (Regex === "{State}") {
                if (Arr.PDetails.State != "" && Arr.PDetails.State != null) {
                  let BodyData = Val.Body.replace(
                    "{State}",
                    Arr.PDetails.State
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Zip}") {
                if (Arr.PDetails.Zip != "" && Arr.PDetails.Zip != null) {
                  let BodyData = Val.Body.replace("{Zip}", Arr.PDetails.Zip);
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Country}") {
                if (
                  Arr.PDetails.Country != "" &&
                  Arr.PDetails.Country != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Country}",
                    Arr.PDetails.Country
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{ContactCategory}") {
                if (
                  Arr.PDetails.ContactCategory != "" &&
                  Arr.PDetails.ContactCategory != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{ContactCategory}",
                    Arr.PDetails.ContactCategory
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AccountCategory}") {
                if (
                  Arr.PDetails.AccountCategory != "" &&
                  Arr.PDetails.AccountCategory != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AccountCategory}",
                    Arr.PDetails.AccountCategory
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AliasTitle}") {
                if (
                  ArrEmail.EAdetails.AliasTitle != "" &&
                  ArrEmail.EAdetails.AliasTitle != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AliasTitle}",
                    ArrEmail.EAdetails.AliasTitle
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AliasName}") {
                if (
                  ArrEmail.EAdetails.AliasName != "" &&
                  ArrEmail.EAdetails.AliasName != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AliasName}",
                    ArrEmail.EAdetails.AliasName
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AliasEmail}") {
                if (
                  ArrEmail.EAdetails.SMTPFromEmail != "" &&
                  ArrEmail.EAdetails.SMTPFromEmail != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AliasEmail}",
                    ArrEmail.EAdetails.SMTPFromEmail
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{SMART.BiggerThan}") {
                if (
                  ArrBiggerThan.SmartV.FeatureValue != "" &&
                  ArrBiggerThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{SMART.BiggerThan}",
                    ArrBiggerThan.SmartV.FeatureValue
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{SMART.FasterThan}") {
                if (
                  ArrFasterThan.SmartV.FeatureValue != "" &&
                  ArrFasterThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{SMART.FasterThan}",
                    ArrFasterThan.SmartV.FeatureValue
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{SMART.MoreExcitingThan}") {
                if (
                  ArrMoreExcitingThan.SmartV.FeatureValue != "" &&
                  ArrMoreExcitingThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{SMART.MoreExcitingThan}",
                    ArrMoreExcitingThan.SmartV.FeatureValue
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Day}") {
                if (ArrCallToAction.CTADayDetails.length != 0) {
                  if (
                    ArrCallToAction.CTADayDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTADayDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Day}",
                      ArrCallToAction.CTADayDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Time}") {
                if (ArrCallToAction.CTATimeDetails.length != 0) {
                  if (
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Time}",
                      ArrCallToAction.CTATimeDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Length}") {
                if (ArrCallToAction.CTALengthDetails.length != 0) {
                  if (
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Length}",
                      ArrCallToAction.CTALengthDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Action}") {
                if (ArrCallToAction.CTAActionDetails.length != 0) {
                  if (
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Action}",
                      ArrCallToAction.CTAActionDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{CTA}") {
                if (ArrAutomatorFeature.CTAActionDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{CTA}",
                      ArrAutomatorFeature.AFCTADetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Closer}") {
                if (ArrAutomatorFeature.AFCloserDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Closer}",
                      ArrAutomatorFeature.AFCloserDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Greeting}") {
                if (ArrAutomatorFeature.AFGreetingDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Greeting}",
                      ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{SenderTitle}") {
                if (ArrAutomatorFeature.AFSenderTitleDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{SenderTitle}",
                      ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Opener}") {
                if (ArrAutomatorFeature.AFOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Opener}",
                      ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{FUOpener}") {
                if (ArrAutomatorFeature.AFFUOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{FUOpener}",
                      ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Subject}") {
                if (ArrAutomatorFeature.AFSubjectDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Subject}",
                      ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{OptOut}") {
                if (ArrAutomatorFeature.AFOptOutDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{OptOut}",
                      ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Problem}") {
                if (ArrAutomatorFeature.AFProblemDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Problem}",
                      ArrAutomatorFeature.AFProblemDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{ValueProp}") {
                if (ArrAutomatorFeature.AFValuePropDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{ValueProp}",
                      ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Story}") {
                if (ArrAutomatorFeature.AFStoryDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Story}",
                      ArrAutomatorFeature.AFStoryDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else {
                if (ArrCustomVar?.length != 0) {
                  for (let i = 0; i < ArrCustomVar.length; i++) {
                    //
                    if (`{${ArrCustomVar[i]?.Name}}` == Regex) {
                      let BodyData = Val.Body.replace(
                        Regex,
                        ArrCustomVar[i].CustomVariableDetails[0].Value
                      );
                      Val.Body = BodyData;
                      var regsub = Val.Body.match(/{(.*?)}/g);
                      //
                      if (regsub != null) {
                        regsub.forEach((Regexsub) => {
                          if (Regexsub === "{Email}") {
                            if (
                              Arr.PDetails.Email != "" &&
                              Arr.PDetails.Email != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Email}",
                                Arr.PDetails.Email
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{FirstName}") {
                            if (
                              Arr.PDetails.FirstName != "" &&
                              Arr.PDetails.FirstName != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{FirstName}",
                                Arr.PDetails.FirstName
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{LastName}") {
                            if (
                              Arr.PDetails.LastName != "" &&
                              Arr.PDetails.LastName != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{LastName}",
                                Arr.PDetails.LastName
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Title}") {
                            if (
                              Arr.PDetails.Title != "" &&
                              Arr.PDetails.Title != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Title}",
                                Arr.PDetails.Title
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Company}") {
                            if (
                              Arr.PDetails.Company != "" &&
                              Arr.PDetails.Company != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Company}",
                                Arr.PDetails.Company
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{CompanyNameLong}") {
                            if (
                              Arr.PDetails.CompanyNameLong != "" &&
                              Arr.PDetails.CompanyNameLong != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{CompanyNameLong}",
                                Arr.PDetails.CompanyNameLong
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Website}") {
                            if (
                              Arr.PDetails.Website != "" &&
                              Arr.PDetails.Website != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Website}",
                                Arr.PDetails.Website
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{CompanyPhone}") {
                            if (
                              Arr.PDetails.CompanyPhone != "" &&
                              Arr.PDetails.CompanyPhone != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{CompanyPhone}",
                                Arr.PDetails.CompanyPhone
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{LinkedInURL}") {
                            if (
                              Arr.PDetails.LinkedInURL != "" &&
                              Arr.PDetails.LinkedInURL != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{LinkedInURL}",
                                Arr.PDetails.LinkedInURL
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Industry}") {
                            if (
                              Arr.PDetails.Industry != "" &&
                              Arr.PDetails.Industry != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Industry}",
                                Arr.PDetails.Industry
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Address1}") {
                            if (
                              Arr.PDetails.Address1 != "" &&
                              Arr.PDetails.Address1 != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Address1}",
                                Arr.PDetails.Address1
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Address2}") {
                            if (
                              Arr.PDetails.Address2 != "" &&
                              Arr.PDetails.Address2 != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Address2}",
                                Arr.PDetails.Address2
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{City}") {
                            if (
                              Arr.PDetails.City != "" &&
                              Arr.PDetails.City != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{City}",
                                Arr.PDetails.City
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{State}") {
                            if (
                              Arr.PDetails.State != "" &&
                              Arr.PDetails.State != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{State}",
                                Arr.PDetails.State
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Zip}") {
                            if (
                              Arr.PDetails.Zip != "" &&
                              Arr.PDetails.Zip != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Zip}",
                                Arr.PDetails.Zip
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Country}") {
                            if (
                              Arr.PDetails.Country != "" &&
                              Arr.PDetails.Country != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Country}",
                                Arr.PDetails.Country
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{ContactCategory}") {
                            if (
                              Arr.PDetails.ContactCategory != "" &&
                              Arr.PDetails.ContactCategory != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{ContactCategory}",
                                Arr.PDetails.ContactCategory
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AccountCategory}") {
                            if (
                              Arr.PDetails.AccountCategory != "" &&
                              Arr.PDetails.AccountCategory != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AccountCategory}",
                                Arr.PDetails.AccountCategory
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AliasTitle}") {
                            if (
                              ArrEmail.EAdetails.AliasTitle != "" &&
                              ArrEmail.EAdetails.AliasTitle != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AliasTitle}",
                                ArrEmail.EAdetails.AliasTitle
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AliasName}") {
                            if (
                              ArrEmail.EAdetails.AliasName != "" &&
                              ArrEmail.EAdetails.AliasName != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AliasName}",
                                ArrEmail.EAdetails.AliasName
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AliasEmail}") {
                            if (
                              ArrEmail.EAdetails.SMTPFromEmail != "" &&
                              ArrEmail.EAdetails.SMTPFromEmail != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AliasEmail}",
                                ArrEmail.EAdetails.SMTPFromEmail
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{SMART.BiggerThan}") {
                            if (
                              ArrBiggerThan.SmartV.FeatureValue != "" &&
                              ArrBiggerThan.SmartV.FeatureValue != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{SMART.BiggerThan}",
                                ArrBiggerThan.SmartV.FeatureValue
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{SMART.FasterThan}") {
                            if (
                              ArrFasterThan.SmartV.FeatureValue != "" &&
                              ArrFasterThan.SmartV.FeatureValue != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{SMART.FasterThan}",
                                ArrFasterThan.SmartV.FeatureValue
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{SMART.MoreExcitingThan}") {
                            if (
                              ArrMoreExcitingThan.SmartV.FeatureValue != "" &&
                              ArrMoreExcitingThan.SmartV.FeatureValue != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{SMART.MoreExcitingThan}",
                                ArrMoreExcitingThan.SmartV.FeatureValue
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Day}") {
                            if (ArrCallToAction.CTADayDetails.length != 0) {
                              if (
                                ArrCallToAction.CTADayDetails[0].FeatureValue !=
                                  "" &&
                                ArrCallToAction.CTADayDetails[0].FeatureValue !=
                                  null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Day}",
                                  ArrCallToAction.CTADayDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Time}") {
                            if (ArrCallToAction.CTATimeDetails.length != 0) {
                              if (
                                ArrCallToAction.CTATimeDetails[0]
                                  .FeatureValue != "" &&
                                ArrCallToAction.CTATimeDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Time}",
                                  ArrCallToAction.CTATimeDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Length}") {
                            if (ArrCallToAction.CTALengthDetails.length != 0) {
                              if (
                                ArrCallToAction.CTALengthDetails[0]
                                  .FeatureValue != "" &&
                                ArrCallToAction.CTALengthDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Length}",
                                  ArrCallToAction.CTALengthDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Action}") {
                            if (ArrCallToAction.CTAActionDetails.length != 0) {
                              if (
                                ArrCallToAction.CTAActionDetails[0]
                                  .FeatureValue != "" &&
                                ArrCallToAction.CTAActionDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Action}",
                                  ArrCallToAction.CTAActionDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{CTA}") {
                            if (
                              ArrAutomatorFeature.CTAActionDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFCTADetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFCTADetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{CTA}",
                                  ArrAutomatorFeature.AFCTADetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Closer}") {
                            if (
                              ArrAutomatorFeature.AFCloserDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFCloserDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFCloserDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Closer}",
                                  ArrAutomatorFeature.AFCloserDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Greeting}") {
                            if (
                              ArrAutomatorFeature.AFGreetingDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFGreetingDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFGreetingDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Greeting}",
                                  ArrAutomatorFeature.AFGreetingDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{SenderTitle}") {
                            if (
                              ArrAutomatorFeature.AFSenderTitleDetails.length !=
                              0
                            ) {
                              if (
                                ArrAutomatorFeature.AFSenderTitleDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFSenderTitleDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{SenderTitle}",
                                  ArrAutomatorFeature.AFSenderTitleDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Opener}") {
                            if (
                              ArrAutomatorFeature.AFOpenerDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFOpenerDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFOpenerDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Opener}",
                                  ArrAutomatorFeature.AFOpenerDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{FUOpener}") {
                            if (
                              ArrAutomatorFeature.AFFUOpenerDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFFUOpenerDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFFUOpenerDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{FUOpener}",
                                  ArrAutomatorFeature.AFFUOpenerDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Subject}") {
                            if (
                              ArrAutomatorFeature.AFSubjectDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFSubjectDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFSubjectDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Subject}",
                                  ArrAutomatorFeature.AFSubjectDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{OptOut}") {
                            if (
                              ArrAutomatorFeature.AFOptOutDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFOptOutDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFOptOutDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{OptOut}",
                                  ArrAutomatorFeature.AFOptOutDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Problem}") {
                            if (
                              ArrAutomatorFeature.AFProblemDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFProblemDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFProblemDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Problem}",
                                  ArrAutomatorFeature.AFProblemDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{ValueProp}") {
                            if (
                              ArrAutomatorFeature.AFValuePropDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFValuePropDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFValuePropDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{ValueProp}",
                                  ArrAutomatorFeature.AFValuePropDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Story}") {
                            if (
                              ArrAutomatorFeature.AFStoryDetails.length != 0
                            ) {
                              if (
                                ArrAutomatorFeature.AFStoryDetails[0]
                                  .FeatureValue != "" &&
                                ArrAutomatorFeature.AFStoryDetails[0]
                                  .FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Story}",
                                  ArrAutomatorFeature.AFStoryDetails[0]
                                    .FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          }
                        });
                      }
                    }
                  }
                }
              }
            });
          }
          if (regSubject != null) {
            regSubject.forEach((Regexs) => {
              if (Regexs === "{Email}") {
                if (Arr.PDetails.Email != "" && Arr.PDetails.Email != null) {
                  let BodyData = Val.Subject.replace(
                    "{Email}",
                    Arr.PDetails.Email
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{FirstName}") {
                if (
                  Arr.PDetails.FirstName != "" &&
                  Arr.PDetails.FirstName != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{FirstName}",
                    Arr.PDetails.FirstName
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{LastName}") {
                if (
                  Arr.PDetails.LastName != "" &&
                  Arr.PDetails.LastName != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{LastName}",
                    Arr.PDetails.LastName
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Title}") {
                if (Arr.PDetails.Title != "" && Arr.PDetails.Title != null) {
                  let BodyData = Val.Subject.replace(
                    "{Title}",
                    Arr.PDetails.Title
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Company}") {
                if (
                  Arr.PDetails.Company != "" &&
                  Arr.PDetails.Company != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Company}",
                    Arr.PDetails.Company
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{CompanyNameLong}") {
                if (
                  Arr.PDetails.CompanyNameLong != "" &&
                  Arr.PDetails.CompanyNameLong != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{CompanyNameLong}",
                    Arr.PDetails.CompanyNameLong
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Website}") {
                if (
                  Arr.PDetails.Website != "" &&
                  Arr.PDetails.Website != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Website}",
                    Arr.PDetails.Website
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{CompanyPhone}") {
                if (
                  Arr.PDetails.CompanyPhone != "" &&
                  Arr.PDetails.CompanyPhone != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{CompanyPhone}",
                    Arr.PDetails.CompanyPhone
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{LinkedInURL}") {
                if (
                  Arr.PDetails.LinkedInURL != "" &&
                  Arr.PDetails.LinkedInURL != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{LinkedInURL}",
                    Arr.PDetails.LinkedInURL
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Industry}") {
                if (
                  Arr.PDetails.Industry != "" &&
                  Arr.PDetails.Industry != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Industry}",
                    Arr.PDetails.Industry
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Address1}") {
                if (
                  Arr.PDetails.Address1 != "" &&
                  Arr.PDetails.Address1 != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Address1}",
                    Arr.PDetails.Address1
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Address2}") {
                if (
                  Arr.PDetails.Address2 != "" &&
                  Arr.PDetails.Address2 != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Address2}",
                    Arr.PDetails.Address2
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{City}") {
                if (Arr.PDetails.City != "" && Arr.PDetails.City != null) {
                  let BodyData = Val.Subject.replace(
                    "{City}",
                    Arr.PDetails.City
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{State}") {
                if (Arr.PDetails.State != "" && Arr.PDetails.State != null) {
                  let BodyData = Val.Subject.replace(
                    "{State}",
                    Arr.PDetails.State
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Zip}") {
                if (Arr.PDetails.Zip != "" && Arr.PDetails.Zip != null) {
                  let BodyData = Val.Subject.replace("{Zip}", Arr.PDetails.Zip);
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Country}") {
                if (
                  Arr.PDetails.Country != "" &&
                  Arr.PDetails.Country != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Country}",
                    Arr.PDetails.Country
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{ContactCategory}") {
                if (
                  Arr.PDetails.ContactCategory != "" &&
                  Arr.PDetails.ContactCategory != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{ContactCategory}",
                    Arr.PDetails.ContactCategory
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AccountCategory}") {
                if (
                  ArrEmail.EAdetails.AccountCategory != "" &&
                  ArrEmail.EAdetails.AccountCategory != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AccountCategory}",
                    ArrEmail.EAdetails.AccountCategory
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AliasTitle}") {
                if (
                  ArrEmail.EAdetails.AliasTitle != "" &&
                  ArrEmail.EAdetails.AliasTitle != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AliasTitle}",
                    ArrEmail.EAdetails.AliasTitle
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AliasName}") {
                if (
                  ArrEmail.EAdetails.AliasName != "" &&
                  ArrEmail.EAdetails.AliasName != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AliasName}",
                    ArrEmail.EAdetails.AliasName
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AliasEmail}") {
                if (
                  ArrEmail.EAdetails.SMTPFromEmail != "" &&
                  ArrEmail.EAdetails.SMTPFromEmail != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AliasEmail}",
                    ArrEmail.EAdetails.SMTPFromEmail
                  );
                  Val.Body = BodyData;
                }
              } else if (Regexs === "{SMART.BiggerThan}") {
                if (
                  ArrBiggerThan.SmartV.FeatureValue != "" &&
                  ArrBiggerThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.BiggerThan}",
                    ArrBiggerThan.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{SMART.FasterThan}") {
                if (
                  ArrFasterThan.SmartV.FeatureValue != "" &&
                  ArrFasterThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.FasterThan}",
                    ArrFasterThan.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{SMART.MoreExcitingThan}") {
                if (
                  ArrMoreExcitingThan.SmartV.FeatureValue != "" &&
                  ArrMoreExcitingThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.MoreExcitingThan}",
                    ArrMoreExcitingThan.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{SMART.MascotCheer}") {
                if (
                  ArrMascotCheer.SmartV.FeatureValue != "" &&
                  ArrMascotCheer.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.MascotCheer}",
                    ArrMascotCheer.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Day}") {
                if (ArrCallToAction.CTADayDetails.length != 0) {
                  if (
                    ArrCallToAction.CTADayDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTADayDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Day}",
                      ArrCallToAction.CTADayDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Time}") {
                if (ArrCallToAction.CTATimeDetails.length != 0) {
                  if (
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Time}",
                      ArrCallToAction.CTATimeDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Length}") {
                if (ArrCallToAction.CTALengthDetails.length != 0) {
                  if (
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Length}",
                      ArrCallToAction.CTALengthDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Action}") {
                if (ArrCallToAction.CTAActionDetails.length != 0) {
                  if (
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Action}",
                      ArrCallToAction.CTAActionDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{CTA}") {
                if (ArrAutomatorFeature.CTAActionDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{CTA}",
                      ArrAutomatorFeature.AFCTADetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Closer}") {
                if (ArrAutomatorFeature.AFCloserDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Closer}",
                      ArrAutomatorFeature.AFCloserDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Greeting}") {
                if (ArrAutomatorFeature.AFGreetingDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Greeting}",
                      ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{SenderTitle}") {
                if (ArrAutomatorFeature.AFSenderTitleDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{SenderTitle}",
                      ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Opener}") {
                if (ArrAutomatorFeature.AFOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Opener}",
                      ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{FUOpener}") {
                if (ArrAutomatorFeature.AFFUOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{FUOpener}",
                      ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Subject}") {
                if (ArrAutomatorFeature.AFSubjectDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Subject}",
                      ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{OptOut}") {
                if (ArrAutomatorFeature.AFOptOutDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{OptOut}",
                      ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Problem}") {
                if (ArrAutomatorFeature.AFProblemDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Problem}",
                      ArrAutomatorFeature.AFProblemDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{ValueProp}") {
                if (ArrAutomatorFeature.AFValuePropDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{ValueProp}",
                      ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Story}") {
                if (ArrAutomatorFeature.AFStoryDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Story}",
                      ArrAutomatorFeature.AFStoryDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              }
            });
          }

          if (ClientEmod.IsAllowEmod === true) {
            //find eMod dynamic ID
            let eModDynamicCampaignID =
              eModProfile.find(
                (profile) =>
                  profile?._id?.toString() ===
                  CampaignOne?.EModProfileID?.toString().toString()
              ) || "";
            let eModDynamicsTeptemplateID =
              eModProfile.find(
                (profile) =>
                  profile?._id?.toString() === Val?.EModProfileID?.toString()
              ) || "";
            //Check eMod tick

            //logic step Templete param
            const ParaStepTempleteeMod = {
              city: Arr?.PDetails?.City,
              company: Arr?.PDetails?.Company,
              country: Arr?.PDetails?.Country,
              emailAddress: Arr?.PDetails?.Email,
              emailDraft: Val?.Body,
              emailSubject: Val?.Subject,
              emailSubjectPrompt: eModDynamicsTeptemplateID.SubjectPrompt,
              emodPrompt: eModDynamicsTeptemplateID.Prompt,
              firstName: Arr?.PDetails?.FirstName,
              lastName: Arr?.PDetails?.LastName,
              linkedinURL: Arr?.PDetails?.LinkedInURL,
              state: Arr?.PDetails?.State,
              title: Arr?.PDetails?.Title,
              useEmodSubject: CampaignOne?.useEmodSubject,
              website: Arr?.PDetails?.Website,
            };

            //logic Campaign
            const ParaCampaigneMod = {
              city: Arr?.PDetails?.City,
              company: Arr?.PDetails?.Company,
              country: Arr?.PDetails?.Country,
              emailAddress: Arr?.PDetails?.Email,
              emailDraft: Val?.Body,
              emailSubject: Val?.Subject,
              emailSubjectPrompt: eModDynamicCampaignID.SubjectPrompt,
              emodPrompt: eModDynamicCampaignID.Prompt,
              firstName: Arr?.PDetails?.FirstName,
              lastName: Arr?.PDetails?.LastName,
              linkedinURL: Arr?.PDetails?.LinkedInURL,
              state: Arr?.PDetails?.State,
              title: Arr?.PDetails?.Title,
              useEmodSubject: CampaignOne?.useEmodSubject,
              website: Arr?.PDetails?.Website,
            };

            if (Val?.EModProfileID?.toString() == "6523d182eff732ba5ceead8c") {
              //eMod off (nothing) templete step
            } else if (
              Val?.EModProfileID?.toString() == "6523d182eff732ba5ceead8d"
            ) {
              //Campaign default check campaign emod id(nothing)
              if (
                CampaignOne.EModProfileID.toString() ==
                "6523d182eff732ba5ceead8c"
              ) {
                //Campaign default id eMod off(nothing)
              } else {
                //Dynamic campaign eModCheck and Call AI api
                const ResultForCampaign = await makeAxiosCall(ParaCampaigneMod);
                //code
                if (ResultForCampaign?.genericEmail === false) {
                  Val.Body = ResultForCampaign?.emailDraft;
                  Val.Subject = ResultForCampaign?.emailSubject;
                } else {
                  Val.Body = ResultForCampaign?.emailDraft;
                }
              }
            } else {
              //Dynamic templete step eModCheck and Call AI api
              // Make API calls
              const ResultForStepTemplate = await makeAxiosCall(
                ParaStepTempleteeMod
              );
              if (ResultForStepTemplate?.genericEmail === false) {
                Val.Body = ResultForStepTemplate?.emailDraft;
                Val.Subject = ResultForStepTemplate?.emailSubject;
              } else {
                Val.Body = ResultForStepTemplate?.emailDraft;
              }
              //code
            }
          }
        }
      }
      setActiveIndexes(CampignData);
      SetPreviewCampaignDetails(rows.data.Data);
    }
  };

  const CampaignExist = async () => {
    // ;
    SetErrors("");
    var GetCampaignName = document.getElementById("CampaignName").value.trim();
    if (GetCampaignName !== CampaignOne.Name) {
      var InputParameter = {
        CampaignName: GetCampaignName,
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      var CampaignStatusExist = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/CampaignStatusExists",
        method: "POST",
        data: InputParameter,
      });
      // ;
      if (CampaignStatusExist.data.Data.length > 0) {
        SetErrors("Campaign name already exists");
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const UpdateCampaignDetails = async () => {
    SetIsVisible(true);
    var IsExist = await CampaignExist();
    if (IsExist === false) {
      // ;
      var GetCampaignName = document
        .getElementById("CampaignName")
        .value.trim();
      var ReplyBehavior = document.getElementById("ReplyBehavior").value;
      var Priority = document.getElementById("Priority").value;
      var OpenTracking = document.getElementById("OpenTracking").value;
      var eModProfileid = document.getElementById("eModProfiles").value;
      var eModCheckedClicks = document.getElementById(
        "SubjectPromptCheckBox"
      ).checked;
      var SendOnWeekends = document.getElementById("SendOnWeekends").checked;
      var IgnoreBlacklist = document.getElementById("IgnoreBlacklist").checked;
      var TrackClicks = document.getElementById("TrackClicks").checked;
      var Notes = document.getElementById("Notes").value.trim();
      var StrInput = {
        CampaignID: CampaignOne._id,
        CampaignName: GetCampaignName,
        ReplyBehavior: ReplyBehavior,
        Priority: Priority,
        OpenTracking: OpenTracking,
        SendOnWeekends: SendOnWeekends,
        IgnoreBlacklist: IgnoreBlacklist,
        TrackClicks: TrackClicks,
        Notes: Notes,
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        eModProfileID: eModProfileid,
        useEmodSubject: eModCheckedClicks,
      };
      var Result = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/CampaignUpdate",
        method: "POST",
        data: StrInput,
      });
      if (Result.data.StatusMessage === "SUCCESS") {
        GetCampaignOneDetails(Result.data.Data._id);
        toast.success(
          <div>
            Edit campaign
            <br />
            Campaign updated successfully.
          </div>
        );
        SetIsVisible(false);
      }
      SetIsVisible(false);
    }
  };

  // config email chile to parent update
  const ConfigEmailAccount = async () => {
    if (ConfigEmailPopup === false) {
      SetConfigEmailPopup(true);
    } else {
      SetConfigEmailPopup(false);
    }
  };

  //Reload page while add perform
  const UpdateFromStepTwo = async (value) => {
    // ;
    if (value == true) {
      ConfigEmailAccount();
      SetChileValueTwo(true);
    }
  };

  //ConfigPop handle open and close
  const ConfigPopHandle = async () => {
    if (ConfigPop === false) {
      SetConfigPop(true);
    } else {
      SetConfigPop(false);
    }
  };

  //ConfigPop handle open and close
  const CreateManuallyPopHandle = async () => {
    if (CreateManuallyPop === false) {
      SetCreateManuallyPop(true);
    } else {
      SetCreateManuallyPop(false);
    }
  };

  // const handleChangeContactOption = async (val) => {
  //   var ContactTagIDArray = [];
  //   var ContactTagNameArray = [];
  //   var TagsArray = [];
  //   val?.map((item) => {
  //     ContactTagIDArray.push(item.cid);
  //   });

  //   SetContactTagSelectArray(ContactTagIDArray);

  //   // contact tag name
  //   val?.map((item) => {
  //     ContactTagNameArray.push(item.label);
  //   });
  //   SetContactTagsNamesArray(ContactTagNameArray);
  //   // tag id and name
  //   val?.map((item) => {
  //     TagsArray.push({
  //       ContactTagID: item.cid,
  //       TagName: item.label,
  //     });
  //   });
  //   SetTagArray(TagsArray);
  //   //
  // };
  const handleChangeContactOption = (val, actionMeta) => {
    const { action } = actionMeta;

    let ContactTagsNamesArray = [];
    let TagArray = [];

    if (action === 'create-option' || action === 'select-option') {
      ContactTagsNamesArray = val.map((item) => item.label);
      TagArray = val.map((item) => ({
        TagName: item.label,
      }));
      setTagNameArray(TagArray);
    }

    if (action === 'select-option') {
      const TagsArrayAlready = val.map((item) => ({
        ContactTagID: item.cid,
        TagName: item.label,
      }));

      setContactTagsNamesArrayAlready(ContactTagsNamesArray);
      setTagArrayAlready(TagsArrayAlready);
    }
  };

  // email validation
  const EmailValidationCheck = async (Email) => {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["Email"] = "invalid email.";
      SetIsValidEmail(true);
      SetErrors(Errors);
      return true;
    } else {
      SetIsValidEmail(false);
      return false;
    }
  };
  // check website name
  const CheckWebSite = async (Email) => {
    let Errors = {};
    var regex = new RegExp(
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    if (!regex.test(Email) && Email != "") {
      Errors["Website"] = "Invalid website";
      Seterrors(Errors);
      SetIsInValidWebsite(true);
      return true;
    } else {
      SetWebsiteNotNull(true);
      return false;
    }
  };

  const FromValidation = async () => {
    let formIsValid = true;
    let errors = {};

    var FirstName = document.getElementById("FirstName").value.trim();
    var Email = document.getElementById("Email").value.trim();
    var Website = document.getElementById("Website").value.trim();
    let IsExistWebsite = await CheckWebSite(Website);
    let IsExistEmail = await EmailValidationCheck(Email);

    if (FirstName == "") {
      formIsValid = false;
      toast.error("Please enter first name");
      SetMannuallyDisables(false);
    }

    if (Website == "" && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter Website");
      SetMannuallyDisables(false);
      SetIsVisible(false);
    }
    if (Email == "" && Website.length > 0 && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter email");
      SetMannuallyDisables(false);
      SetIsVisible(false);
    }
    if (IsExistWebsite == true) {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
      toast.error(
        "Please enter First name, Last name and Company OR First name and Email"
      );
      SetMannuallyDisables(false);
      SetIsVisible(false);
    }
    if (IsExistEmail == true) {
      formIsValid = false;
      toast.error("Invalid email!");
      SetMannuallyDisables(false);
      SetIsVisible(false);
    }
    Seterrors(errors);
    return formIsValid;
  };

  //Add campaign contact manually
  const AddContactManually = async () => {
    SetMannuallyDisables(true);
    var FormValidationFlag = await FromValidation();
    try {
      if (FormValidationFlag === true) {
        SetIsVisible(true);
        var FirstName = document.getElementById("FirstName").value;
        var LastName = document.getElementById("LastName").value;
        var Email = document.getElementById("Email").value.trim();
        var Title = document.getElementById("Title").value;
        var Company = document.getElementById("Company").value;
        var CompanyNameLong = document.getElementById("CompanyNameLong").value;
        var Website = document.getElementById("Website").value;
        var Industry = document.getElementById("Industry").value;
        var Address1 = document.getElementById("Address1").value;
        var Address2 = document.getElementById("Address2").value;
        var City = document.getElementById("City").value;
        var State = document.getElementById("State").value;
        var Zip = document.getElementById("Zip").value;
        var LinkedInURL = document.getElementById("LinkedInURL").value;
        var Country = document.getElementById("Country").value;
        var ContactSourceID = document.getElementById("ContactSourceID").value;
        var ContactOwnerID = document.getElementById("ContactOwnerID").value;
        var ClientPOCID = document.getElementById("ClientPOCID").value;
        var ContactOwnerID = document.getElementById("ContactOwnerID").value;
        var ReasonUnqualifiedID = document.getElementById(
          "ReasonUnqualifiedID"
        ).value;
        var ContactCategory = document.getElementById("ContactCategory").value;
        var AccountCategory = document.getElementById("AccountCategory").value;
        var MobilePhone = document.getElementById("MobilePhone").value;
        var DirectPhone = document.getElementById("DirectPhone").value;
        var CompanyPhone = document.getElementById("CompanyPhone").value;
        var CompanyRevenue = document.getElementById("CompanyRevenue").value;
        var CompanyEmployeeCount = document.getElementById(
          "CompanyEmployeeCount"
        ).value;
        var FollowUpDate = null;
        var ColdCallersID = document.getElementById("ColdCallersID").value;
        var SalesRep = document.getElementById("SalesRep").value;
        var OtherPhone1 = document.getElementById("OtherPhone1").value;
        var OtherPhone2 = document.getElementById("OtherPhone2").value;
        var ExternalAccountID =
          document.getElementById("ExternalAccountID").value;
        var ExternalContactID =
          document.getElementById("ExternalContactID").value;
        var ExternalField1 = document.getElementById("ExternalField1").value;
        var ExternalField2 = document.getElementById("ExternalField2").value;
        var ExternalField3 = document.getElementById("ExternalField3").value;
        var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
        var Domain = Email.substring(Email.lastIndexOf("@") + 1);
        var IsDoNotCallContact =
          document.getElementById("IsDoNotCallContact").checked;
        var ContactCustomFieldValueArray = [];

        var data = {
          ClientID: ClientID,
          UserID: UserID,
          FirstName: FirstName,
          LastName: LastName,
          Email: Email,
          Title: Title,
          Company: Company,
          CompanyNameLong: CompanyNameLong,
          Website: Website,
          LinkedInURL: LinkedInURL,
          Industry: Industry,
          Address1: Address1,
          Address2: Address2,
          City: City,
          State: State,
          Zip: Zip,
          Country: Country,
          ContactSourceID: ContactSourceID,
          ContactOwnerID: ContactOwnerID === "--Select--" ? null : ContactOwnerID,
          ClientPOCID: ClientPOCID,
          ReasonUnqualifiedID: ReasonUnqualifiedID,
          ContactCategory: ContactCategory,
          AccountCategory: AccountCategory,
          MobilePhone: MobilePhone,
          DirectPhone: DirectPhone,
          DirectPhoneExt: DirectPhoneExt,
          CompanyPhone: CompanyPhone,
          CompanyRevenue: CompanyRevenue,
          CompanyEmployeeCount: CompanyEmployeeCount,
          FollowUpDate: FollowUpDate,
          SalesRepID: ColdCallersID,
          ColdCallersID: ColdCallersID,
          SalesRep: SalesRep,
          OtherPhone1: OtherPhone1,
          OtherPhone2: OtherPhone2,
          ExternalAccountID: ExternalAccountID,
          ExternalContactID: ExternalContactID,
          ExternalField1: ExternalField1,
          ExternalField2: ExternalField2,
          ExternalField3: ExternalField3,
          IsDoNotCallContact: IsDoNotCallContact,
          CreatedBy: UserID,
          CreatedDate: new Date(),
          IsDeleted: false,
          EmailDomain: Domain,
          ContactsCustomFieldArray: ContactCustomFieldValueArray,
          AccountTypeID: null,
          TagArrayAlready: TagArrayAlready == [] || null || undefined ? []: TagArrayAlready,
          ContactTags:TagNameArray == [] || null || undefined ? "" : TagNameArray.map((tag) => tag.TagName).join(', ')
        };

        var ProspectDetails = await Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactsAdd",
          method: "POST",
          data: data,
        });

        if (ProspectDetails.data.StatusMessage == "SUCCESS") {
          SetProspectID(ProspectDetails.data.Data.ProspectID);
          toast.success("Contact Added successfully.", "Add Contacts  ");
          var StrInProspectCampaign = {
            CampaignID: Campaign,
            ProspectID: ProspectDetails.data.Data._id,
            UserID: UserID,
            ClientID: ClientID,
            Role: Role,
          };

          var ProspectCampaignQuery = await Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/prospect_campaign/AddProspectCampaign",
            method: "POST",
            data: StrInProspectCampaign,
          });
          SetMannuallyDisables(false);
          if (ProspectCampaignQuery.data.StatusMessage == "SUCCESS") {
            SetProspectCampaignID(ProspectCampaignQuery.data.Data._id);
            SetProspectFlag(true);
            SetMannuallyDisables(false);
            ConfigPopHandle();
            CreateManuallyPopHandle();
            SetProspectFlag(false);
            SetIsVisible(false);
          } else {
            SetIsVisible(false);
            toast.error(ProspectCampaignQuery.data.Message);
            SetMannuallyDisables(false);
          }
        } else {
          SetIsVisible(false);
          toast.error(ProspectDetails.data.Message);
          SetMannuallyDisables(false);
        }
      } else {
        SetIsVisible(false);
        SetMannuallyDisables(false);
      }
    } catch (error) {
      toast.error(error);
      SetIsVisible(false);
      SetMannuallyDisables(false);
    }
    SetIsVisible(false);
  };

  //ConfigPop handle open and close
  const AddStepPopHandle = async () => {
    let Errors = {};
    Errors["Subject"] = "";
    Seterrors(Errors);
    if (AddStepPopup === false) {
      SetAddStepPopup(true);
    } else {
      SetAddStepPopup(false);
    }
  };

  //ConfigPop handle open and close
  const AddStepTempletPopHandle = async (CCampaignStepID) => {
    if (AddStepTempletePopup === false) {
      SetAddStepTempletePopup(true);
      SetCampaignStepID(CCampaignStepID);
    } else {
      SetAddStepTempletePopup(false);
      SetCampaignStepID(CCampaignStepID);
    }
  };

  //Add Campaign stem and templete
  const AddCampaignStep = async (e) => {
    let Errors = {};
    SetIsVisible(true);
    e.preventDefault();
    // e.currentTarget.disabled = true;
    var Days = document.getElementById("Days").value;
    if (CampaignDetails.length > 0 && Days == 0) {
      toast.error(
        <div className="toastsize">
          Add Step
          <br />
          Day should be greater then zero
        </div>
      );
      //document.getElementById("Step_submit").disabled = false;
      SetIsVisible(false);
    } else {
      var eModTmpletestepID = document.getElementById("eModProfileAddID").value;
      var Subject = document.getElementById("AddCampaignSubject").value;
      var Body = SignatureSC.data;
      if (CampaignDetails.length == 0 && (Subject == "" || Body == "")) {
        if (Body == "") {
          toast.error(
            <div className="toastsize">
              Add Step
              <br />
              Body is empty,fill it!
            </div>
          );
        }
        // e.currentTarget.disabled = false;
      } else {
        if (Body == "") {
          toast.error(
            <div className="toastsize">
              Add Step
              <br />
              Body is empty,fill it!
            </div>
          );
        } else {
          var StrInCamapignStep = {
            Role: Role,
            ClientID: ClientID,
            UserID: UserID,
            CampaignID: Campaign,
            Days: Days,
            Subject: Subject,
            Body: Body,
            EModProfileID: eModTmpletestepID,
          };

          var AddCampaignStepDetails = await Axios({
            url: CommonConstants.MOL_APIURL + "/campaignstep/AddCampaignStep",
            method: "POST",
            data: StrInCamapignStep,
          });
          if (AddCampaignStepDetails.data.StatusMessage == "SUCCESS") {
            e.currentTarget.disabled = true;
            AddStepPopHandle();
            SetCampaignStepFlag(true);
            SetSignatureSC({ data: "" });
            //document.getElementById('Step_submit').disabled = false
            SetCampaignStepFlag(false);
            SetIsVisible(false);
          } else {
            //document.getElementById('Step_submit').disabled = false
            SetIsVisible(false);
          }
        }
        SetIsVisible(false);
      }
      SetIsVisible(false);
    }
  };

  // const onDrop = async ({ removedIndex, addedIndex }) => {
  //   var ChangeOrderArr = arrayMoveImmutable(
  //     CampaignDetails,
  //     removedIndex,
  //     addedIndex
  //   );
  //   var StrIn = {
  //     UserID: UserID,
  //     ClientID: ClientID,
  //     Role: Role,
  //     ArrayList: ChangeOrderArr,
  //   };
  //   const rows = await Axios({
  //     url: CommonConstants.MOL_APIURL + "/campaignstep/UpdateCampaignStepOrder",
  //     method: "POST",
  //     data: StrIn,
  //   });
  //   if (rows.data.StatusMessage == "SUCCESS") {
  //     GetCampaignStepDetails(ClientID, UserID, Role);
  //   }
  // };
  const handleBlurWebsite = (event) => {
    let Errors = {};
    const website = event.target.value.trim();
  
    Errors["Website"] = null; // Initializing the property 'Website' in the Errors object
  
    var regex = new RegExp(
      "^(?!(https?://)?www\\.)" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
  
    if (!regex.test(website) && website !== "") {
      Errors["Website"] = "Invalid website";
  
      // Assuming Seterrors is a state-setting function
      Seterrors(Errors);
    } else {
      // Assuming Seterrors is a state-setting function
      Seterrors(Errors);
    }
  };
  const onDrop = async ({ removedIndex, addedIndex }) => {
    // Retrieve the steps being dragged and dropped onto
    const draggedStep = CampaignDetails[removedIndex];
    const targetStep = CampaignDetails[addedIndex];

    // Check if either the dragged step or the target step has Send count > 0
    if (
      draggedStep.CStepTemplate.some(
        (template) => template?.StepCountHistory?.Send > 0
      ) ||
      targetStep.CStepTemplate.some(
        (template) => template?.StepCountHistory?.Send > 0
      )
    ) {
      // If either has Send count > 0, prevent the drop
      //<div className="toastsize">Filter<br/>Property value can not be an empty</div>
      toast.error(
        <div className="toastsize">
          Campaign Step
          <br />
          Campaign step can not move.
        </div>
      );
      return;
    }

    // If both have Send count == 0, proceed with the drop
    const updatedCampaignDetails = arrayMoveImmutable(
      CampaignDetails,
      removedIndex,
      addedIndex
    );

    const StrIn = {
      UserID: UserID,
      ClientID: ClientID,
      Role: Role,
      ArrayList: updatedCampaignDetails,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/UpdateCampaignStepOrder",
      method: "POST",
      data: StrIn,
    });

    if (rows.data.StatusMessage === "SUCCESS") {
      // Update the state or trigger any necessary actions
      toast.success(
        <div className="toastsize">
          Campaign Step
          <br />
          Campaign step order updated successfully.
        </div>
      );
      GetCampaignStepDetails(ClientID, UserID, Role);
    }
  };

  const handleClose = () => {
    SetAnchorEl(null);
  };

  const handleClick = (event, DaysTmplate, StepID) => {
    SetAnchorEl(event.currentTarget);
    SetDaysTemp(DaysTmplate);
    SetCampaignStepID(StepID);
  };
  const AddStepTemplatePopupSubmit = async () => {
    var Body = SignatureSC2.data;
    var Subject = document.getElementById("TemplateSubject").value;
    var eModSecondAddID = document.getElementById(
      "eModProfileAddSecondID"
    ).value;
    if (Body == "") {
      toast.error(
        <div className="toastsize">
          Add Step
          <br />
          Body is empty, fill it!
        </div>
      );
    } else {
      SetIsVisible(true);
      var StrInTemplateStep = {
        ClientID: ClientID,
        UserID: UserID,
        CampaignID: Campaign,
        CampaignStepID: CampaignStepID,
        Body: Body,
        Subject: Subject,
        EModProfileID: eModSecondAddID,
      };
      const rows = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/campaignsteptemplate/AddCampaignStepTemplate",
        method: "POST",
        data: StrInTemplateStep,
      });
      if (rows.data.StatusMessage == "SUCCESS") {
        SetSignatureSC2({ data: "" });
        AddStepTempletPopHandle();
        SetIsVisible(false);
      }else{
        SetIsVisible(false);
      }
      
    }
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    SetCsvFileName(sep[0]);

    if (sep[1] != "csv") {
      SetReset(true);
      ResetCsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
      var csvfilenumber = new Date().getTime() + "." + sep[1];
      setfilename(csvfile);
      setfilenameonlynumber(csvfilenumber);
      setcsvData(data);
      SetDropboxData(data[0].data);
      document.querySelectorAll(".checktogle").forEach((checkbox) => {
        checkbox.disabled = false;
      });
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};
  const ResetCsv = () => {
    SetReset(false);
  };

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "FirstName") {
      SetFirstName(value);
    } else if (Name == "LastName") {
      SetLastName(value);
    } else if (Name == "Email") {
      SetEmail(value);
    } else if (Name == "Title") {
      SetTitle(value);
    } else if (Name == "Company") {
      SetCompany(value);
    } else if (Name == "CompanyNameLong") {
      SetCompanyNameLong(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "DirectPhone") {
      SetDirectPhone(value);
    } else if (Name == "DirectPhoneExt") {
      SetDirectPhoneExt(value);
    } else if (Name == "LinkedInURL") {
      SetLinkedInURL(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "Address1") {
      SetAddress1(value);
    } else if (Name == "Address2") {
      SetAddress2(value);
    } else if (Name == "City") {
      SetCity(value);
    } else if (Name == "State") {
      SetState(value);
    } else if (Name == "Zip") {
      SetZip(value);
    } else if (Name == "Country") {
      SetCountry(value);
    } else if (Name == "ContactCategory") {
      SetContactCategory(value);
    } else if (Name == "AccountCategory") {
      SetAccountCategory(value);
    } else if (Name == "ValidationScore") {
      SetValidationScore(value);
    } else if (Name == "ListName") {
      SetListName(value);
    } else if (Name == "ContactSource") {
      SetContactSource(value);
    } else if (Name == "ContactOwner") {
      SetContactOwner(value);
    } else if (Name == "ClientPOC") {
      SetClientPOC(value);
    } else if (Name == "ReasonUnqualified") {
      SetReasonUnqualified(value);
    } else if (Name == "AccountType") {
      SetAccountType(value);
    } else if (Name == "CompanyRevenue") {
      SetCompanyRevenue(value);
    } else if (Name == "EmployeeCount") {
      setEmployeeCount(value);
    } else if (Name == "IsDoNotCallContact") {
      SetIsDoNotCallContact(value);
    } else if (Name == "SalesRep") {
      SetSalesRep(value);
    } else if (Name == "ColdCaller") {
      SetColdCaller(value);
    } else if (Name == "Tag") {
      SetTag(value);
    } else if (Name == "CallNotes") {
      SetCallNotes(value);
    } else if (Name == "OtherPhone1") {
      SetOtherPhone1(value);
    } else if (Name == "OtherPhone2") {
      SetOtherPhone2(value);
    } else if (Name == "ContactID") {
      SetContactID(value);
    }

    hideCSVHeaderOptions();
  });
  // map csv
  const ContactMapCsv = async () => {
    if (csvData.length == 0) {
      toast.error(
        <div className="toastsize">
          SalesHive Contacts
          <br />
          Please select file
        </div>
      );
    } else {
      document.getElementById("map_attribute").style.display = "block";
      if (csvData.length != 0) {
        ResetCsv();
        DropboxData.map((dddd, index) => {});
      } else {
        ResetCsv();
      }
      await ResetCsv();

      hideCSVHeaderOptions();
    }
  };

  // import black list csv
  const ContactSaveCsv = () => {
    var checkAllContacts = [];
    var FirstName = parseInt(document.getElementById("FirstName").value);
    if (FirstName >= 0) {
      var ObjFirstName = {
        FieldAlias: "First Name",
        Fieldname: "FirstName",
        Customfield: null,
        index: FirstName,
      };
      checkAllContacts.push(ObjFirstName);
    }
    var Email = parseInt(document.getElementById("Email").value);
    if (Email >= 0) {
      var ObjEmail = {
        FieldAlias: "Email",
        Fieldname: "Email",
        Customfield: null,
        index: Email,
      };
      checkAllContacts.push(ObjEmail);
    }

    if (
      !checkAllContacts.some(
        (contact) =>
          contact.Fieldname === "FirstName" &&
          contact.FieldAlias === "First Name"
      )
    ) {
      toast.error(
        <div className="toastsize">
          Campaign
          <br />
          First Name required
        </div>
      );
    } else if (
      !checkAllContacts.some(
        (contact) =>
          contact.Fieldname === "Email" && contact.FieldAlias === "Email"
      )
    ) {
      toast.error(
        <div className="toastsize">
          Campaign
          <br />
          Email required
        </div>
      );
    }
    if (checkAllContacts.length == 2) {
      document.querySelectorAll(".checktogle").forEach((checkbox) => {
        checkbox.disabled = true;
      });

      if (csvData.length >= 100) {
        SetShowPopupImport(true);
        toast.success("Contact file size is more than 100 successfully.");
      } else {
        SetIsVisible(true);
        var saveAllContacts = [];
        var FirstName = parseInt(document.getElementById("FirstName").value);
        if (FirstName >= 0) {
          var ObjFirstName = {
            FieldAlias: "First Name",
            Fieldname: "FirstName",
            Customfield: null,
            index: FirstName,
          };
          saveAllContacts.push(ObjFirstName);
        }
        var LastName = parseInt(document.getElementById("LastName").value);
        if (LastName >= 0) {
          var ObjLastName = {
            FieldAlias: "Last Name",
            Fieldname: "LastName",
            Customfield: null,
            index: LastName,
          };
          saveAllContacts.push(ObjLastName);
        }
        var Email = parseInt(document.getElementById("Email").value);
        if (Email >= 0) {
          var ObjEmail = {
            FieldAlias: "Email",
            Fieldname: "Email",
            Customfield: null,
            index: Email,
          };
          saveAllContacts.push(ObjEmail);
        }
        var Title = parseInt(document.getElementById("Title").value);
        if (Title >= 0) {
          var ObjTitle = {
            FieldAlias: "Title",
            Fieldname: "Title",
            Customfield: null,
            index: Title,
          };
          saveAllContacts.push(ObjTitle);
        }
        var Company = parseInt(document.getElementById("Company").value);
        if (Company >= 0) {
          var ObjCompany = {
            FieldAlias: "Company",
            Fieldname: "Company",
            Customfield: null,
            index: Company,
          };
          saveAllContacts.push(ObjCompany);
        }
        var CompanyNameLong = parseInt(
          document.getElementById("CompanyNameLong").value
        );
        if (CompanyNameLong >= 0) {
          var ObjCompanyNameLong = {
            FieldAlias: "Company Name Long",
            Fieldname: "CompanyNameLong",
            Customfield: null,
            index: Company,
          };
          saveAllContacts.push(ObjCompanyNameLong);
        }
        var Website = parseInt(document.getElementById("Website").value);
        if (Website >= 0) {
          var ObjWebsite = {
            FieldAlias: "Website",
            Fieldname: "Website",
            Customfield: null,
            index: Website,
          };
          saveAllContacts.push(ObjWebsite);
        }
        var CompanyPhone = parseInt(
          document.getElementById("CompanyPhone").value
        );
        if (CompanyPhone >= 0) {
          var ObjCompanyPhone = {
            FieldAlias: "Company Phone",
            Fieldname: "CompanyPhone",
            Customfield: null,
            index: CompanyPhone,
          };
          saveAllContacts.push(ObjCompanyPhone);
        }
        var DirectPhone = parseInt(
          document.getElementById("DirectPhone").value
        );
        if (DirectPhone >= 0) {
          var ObjDirectPhone = {
            FieldAlias: "Direct Phone",
            Fieldname: "DirectPhone",
            Customfield: null,
            index: DirectPhone,
          };
          saveAllContacts.push(ObjDirectPhone);
        }

        var DirectPhoneExt = parseInt(
          document.getElementById("DirectPhoneExt").value
        );
        if (DirectPhoneExt >= 0) {
          var ObjDirectPhoneExt = {
            FieldAlias: "Direct Phone Ext",
            Fieldname: "DirectPhoneExt",
            Customfield: null,
            index: DirectPhoneExt,
          };
          saveAllContacts.push(ObjDirectPhoneExt);
        }
        var LinkedInURL = parseInt(
          document.getElementById("LinkedInURL").value
        );
        if (LinkedInURL >= 0) {
          var ObjLinkedInURL = {
            FieldAlias: "LinkedIn URL",
            Fieldname: "LinkedInURL",
            Customfield: null,
            index: LinkedInURL,
          };
          saveAllContacts.push(ObjLinkedInURL);
        }
        var Industry = parseInt(document.getElementById("Industry").value);
        if (Industry >= 0) {
          var ObjIndustry = {
            FieldAlias: "Industry",
            Fieldname: "Industry",
            Customfield: null,
            index: Industry,
          };
          saveAllContacts.push(ObjIndustry);
        }

        var Address1 = parseInt(document.getElementById("Address1").value);
        if (Address1 >= 0) {
          var ObjAddress1 = {
            FieldAlias: "Address 1",
            Fieldname: "Address1",
            Customfield: null,
            index: Address1,
          };
          saveAllContacts.push(ObjAddress1);
        }

        var Address2 = parseInt(document.getElementById("Address2").value);
        if (Address2 >= 0) {
          var ObjAddress2 = {
            FieldAlias: "Address 2",
            Fieldname: "Address2",
            Customfield: null,
            index: Address2,
          };
          saveAllContacts.push(ObjAddress2);
        }

        var City = parseInt(document.getElementById("City").value);
        if (City >= 0) {
          var ObjCity = {
            FieldAlias: "City",
            Fieldname: "City",
            Customfield: null,
            index: City,
          };

          saveAllContacts.push(ObjCity);
        }
        var State = parseInt(document.getElementById("State").value);
        if (State >= 0) {
          var ObjState = {
            FieldAlias: "State",
            Fieldname: "State",
            Customfield: null,
            index: State,
          };
          saveAllContacts.push(ObjState);
        }

        var Zip = parseInt(document.getElementById("Zip").value);
        if (Zip >= 0) {
          var ObjZip = {
            FieldAlias: "Zip",
            Fieldname: "Zip",
            Customfield: null,
            index: Zip,
          };
          saveAllContacts.push(ObjZip);
        }

        var Country = parseInt(document.getElementById("Country").value);
        if (Country >= 0) {
          var ObjCountry = {
            FieldAlias: "Country",
            Fieldname: "Country",
            Customfield: null,
            index: Country,
          };
          saveAllContacts.push(ObjCountry);
        }

        var ContactCategory = parseInt(
          document.getElementById("ContactCategory").value
        );
        if (ContactCategory >= 0) {
          var ObjContactCategory = {
            FieldAlias: "Contact Category",
            Fieldname: "ContactCategory",
            Customfield: null,
            index: ContactCategory,
          };
          saveAllContacts.push(ObjContactCategory);
        }
        var AccountCategory = parseInt(
          document.getElementById("AccountCategory").value
        );
        if (AccountCategory >= 0) {
          var ObjAccountCategory = {
            FieldAlias: "Account Category",
            Fieldname: "AccountCategory",
            Customfield: null,
            index: AccountCategory,
          };
          saveAllContacts.push(ObjAccountCategory);
        }

        var ValidationScore = parseInt(
          document.getElementById("ValidationScore").value
        );
        if (ValidationScore >= 0) {
          var ObjValidationScore = {
            FieldAlias: "Validation Score",
            Fieldname: "ValidationScore",
            Customfield: null,
            index: ValidationScore,
          };
          saveAllContacts.push(ObjValidationScore);
        }
        var ListName = document.getElementById("ListName").value;
        if (ListName != "") {
          var ObjListName = {
            FieldAlias: "List Name",
            Fieldname: "ListName",
            Customfield: null,
            index: ListName,
          };
          saveAllContacts.push(ObjListName);
        }

        var ContactSource = parseInt(
          document.getElementById("ContactSource").value
        );
        if (ContactSource >= 0) {
          var ObjContactSource = {
            FieldAlias: "Contact Source",
            Fieldname: "ContactSource",
            Customfield: null,
            index: ContactSource,
          };
          saveAllContacts.push(ObjContactSource);
        }
        var ContactOwner = parseInt(
          document.getElementById("ContactOwner").value
        );
        if (ContactOwner >= 0) {
          var ObjContactOwner = {
            FieldAlias: "Contact Owner",
            Fieldname: "ContactOwner",
            Customfield: null,
            index: ContactOwner,
          };
          saveAllContacts.push(ObjContactOwner);
        }
        var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
        if (ClientPOC >= 0) {
          var ObjClientPOC = {
            FieldAlias: "Client POC",
            Fieldname: "ClientPOC",
            Customfield: null,
            index: ClientPOC,
          };
          saveAllContacts.push(ObjClientPOC);
        }
        var ReasonUnqualified = parseInt(
          document.getElementById("ReasonUnqualified").value
        );
        if (ReasonUnqualified >= 0) {
          var ObjReasonUnqualified = {
            FieldAlias: "Reason Unqualified",
            Fieldname: "ReasonUnqualified",
            Customfield: null,
            index: ReasonUnqualified,
          };
          saveAllContacts.push(ObjReasonUnqualified);
        }
        var AccountType = parseInt(
          document.getElementById("AccountType").value
        );
        if (AccountType >= 0) {
          var ObjAccountType = {
            FieldAlias: "Account Type",
            Fieldname: "AccountType",
            Customfield: null,
            index: AccountType,
          };
          saveAllContacts.push(ObjAccountType);
        }
        var CompanyRevenue = parseInt(
          document.getElementById("CompanyRevenue").value
        );
        if (CompanyRevenue >= 0) {
          var ObjCompanyRevenue = {
            FieldAlias: "Company Revenue",
            Fieldname: "CompanyRevenue",
            Customfield: null,
            index: CompanyRevenue,
          };
          saveAllContacts.push(ObjCompanyRevenue);
        }
        var EmployeeCount = parseInt(
          document.getElementById("EmployeeCount").value
        );
        if (EmployeeCount >= 0) {
          var ObjEmployeeCount = {
            FieldAlias: "Employee Count",
            Fieldname: "EmployeeCount",
            Customfield: null,
            index: EmployeeCount,
          };
          saveAllContacts.push(ObjEmployeeCount);
        }

        var MobilePhone = parseInt(
          document.getElementById("MobilePhone").value
        );
        if (MobilePhone >= 0) {
          var ObjMobilePhone = {
            FieldAlias: "Mobile Phone",
            Fieldname: "MobilePhone",
            Customfield: null,
            index: MobilePhone,
          };
          saveAllContacts.push(ObjMobilePhone);
        }

        var IsDoNotCallContact = parseInt(
          document.getElementById("IsDoNotCallContact").value
        );
        if (IsDoNotCallContact >= 0) {
          var ObjIsDoNotCallContact = {
            FieldAlias: "Do not call contact",
            Fieldname: "IsDoNotCallContact",
            Customfield: null,
            index: IsDoNotCallContact,
          };
          saveAllContacts.push(ObjIsDoNotCallContact);
        }

        var SalesRep = parseInt(document.getElementById("SalesRep").value);
        if (SalesRep >= 0) {
          var ObjSalesRep = {
            FieldAlias: "Sales Rep",
            Fieldname: "SalesRep",
            Customfield: null,
            index: SalesRep,
          };
          saveAllContacts.push(ObjSalesRep);
        }

        var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
        if (ColdCaller >= 0) {
          var ObjColdCaller = {
            FieldAlias: "Sales Development Reps",
            Fieldname: "ColdCaller",
            Customfield: null,
            index: ColdCaller,
          };
          saveAllContacts.push(ObjColdCaller);
        }

        var Tag = parseInt(document.getElementById("Tag").value);
        if (Tag >= 0) {
          var ObjTag = {
            FieldAlias: "Tag",
            Fieldname: "Tag",
            Customfield: null,
            index: Tag,
          };
          saveAllContacts.push(ObjTag);
        }

        var CallNotes = parseInt(document.getElementById("CallNotes").value);
        if (CallNotes >= 0) {
          var ObjCallNotes = {
            FieldAlias: "Call Notes",
            Fieldname: "CallNotes",
            Customfield: null,
            index: CallNotes,
          };
          saveAllContacts.push(ObjCallNotes);
        }

        var OtherPhone1 = parseInt(
          document.getElementById("OtherPhone1").value
        );
        if (OtherPhone1 >= 0) {
          var ObjOtherPhone1 = {
            FieldAlias: "Other Phone 1",
            Fieldname: "OtherPhone1",
            Customfield: null,
            index: OtherPhone1,
          };
          saveAllContacts.push(ObjOtherPhone1);
        }
        var OtherPhone2 = parseInt(
          document.getElementById("OtherPhone2").value
        );
        if (OtherPhone2 >= 0) {
          var ObjOtherPhone2 = {
            FieldAlias: "Other Phone 2",
            Fieldname: "OtherPhone2",
            Customfield: null,
            index: OtherPhone2,
          };
          saveAllContacts.push(ObjOtherPhone2);
        }

        var ContactID = parseInt(document.getElementById("ContactID").value);
        if (ContactID >= 0) {
          var ObjContactID = {
            FieldAlias: "ContactID",
            Fieldname: "ContactID",
            Customfield: null,
            index: ContactID,
          };
          saveAllContacts.push(ObjContactID);
        }

        var IsUpdateExistContacts = document.getElementById(
          "IsUpdateExistContacts"
        ).checked;
        // if (IsUpdateExistContacts == false) {
        //   IsUpdateExistContacts = true;
        // } else {
        //   IsUpdateExistContacts = false;
        // }
        var IsInsertNullValues =
          document.getElementById("IsInsertNullValues").checked;
        var IsDoNotupdatecompanyname = document.getElementById(
          "IsDoNotupdatecompanyname"
        ).checked;
        var IsMoveExitsingcurrentcampaign = document.getElementById(
          "IsMoveExitsingcurrentcampaign"
        ).checked;
        var IsInsertContactAlreadyReplaied = document.getElementById(
          "IsInsertContactAlreadyReplaied"
        ).checked;

        var csvdata = csvData;
        var csvdata2 = csvData.length;

        Fileupload.getBase64(files, (result) => {
          var rs = result;
          var splirs = rs.split("base64,");
          var _base64 = splirs[1];

          var ObjData = {
            Fields: "Coverimg",
            Path: "./Content/UploadedFiles/SalesReplyContact",
            File: _base64,
            ImageName: filename,
            FileData: csvdata,
            saveAllContacts: saveAllContacts,
            ClientID: ClientID,
            UserID: UserID,
            CampaignID: campaignID,
            CreatedBy: UserID,
            CreatedDate: new Date(),
            IsUpdateExistContacts:
              IsUpdateExistContacts === false ? true : false,
            IsInsertNullValues: IsInsertNullValues,
            IsDoNotupdatecompanyname: IsDoNotupdatecompanyname,
            IsMoveExistContacts: IsMoveExitsingcurrentcampaign,
            IsAllowAlreadyRepliedContacts: IsInsertContactAlreadyReplaied,
            IsCsvFileName: CsvFileName,
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/prospect_campaign/ProspectCampaignImport",
            method: "POST",
            data: ObjData,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success("Contacts Save Imports");
              SetTotal(res.data.TotalContacts);
              SetTotalSucess(res.data.TotalSuccess);
              SetTotalError(res.data.TotalError);
              SetTotalDuplicate(res.data.TotalDuplicate);
              SetTotalUpdated(res.data.TotalUpdated);
              SetTotalEmailValiDationScoreD(
                res.data.TotalEmailValiDationScoreD
              );
              SetTotalEmailValiDationScoreF(
                res.data.TotalEmailValiDationScoreF
              );
              SetTotalSkipped(res.data.TotalSkipped);
              SetTotalContactBlacklisted(res.data.TotalContactBlacklisted);
              SetTotalRemoved(res.data.TotalRemoved);
              SetTotalDomainBlackListed(res.data.TotalDomainBlackListed);
              SetTotalGlobalDomainBlackListed(
                res.data.TotalGlobalDomainBlackListed
              );
              SetTotalDefaultCountryBlackListed(
                res.data.TotalDefaultCountryBlackListed
              );
              SetTotalCountryBlackListed(res.data.TotalCountryBlackListed);
              SetTotalReplied(res.data.TotalReplied);
              SetData(res.data.Data);
              SetRlen(res.data.Data.length);
              SetFlen(res.data.Data.length);
              const totalPages = Math.ceil(
                res.data.Data.length / CSVERowsPerPage
              );
              SetCountPage(totalPages);
              // SetShowPopupImport(false)
              // SetShowImportContactCsv(false)
              // SetConfigPop(false)
              setcsvData([]);

              document.getElementById("total-div-display").style.display =
                "block";
              document.getElementById("csv_error_table").style.display =
                "block";
              // document.getElementById("map_attribute").style.display = "none";
              // document.getElementById("import_csv").style.display = "none";
              // document.getElementById("hideloding").style.display = "none";
              SetIsVisible(false);
            }
          });
        });
      }
    }
  };
  // import for email 100+csv
  const ContactSaveCsvAddBulk = () => {
    var OriginalFileName = files.name;
    var onlyname = OriginalFileName.substring(
      0,
      OriginalFileName.lastIndexOf(".csv")
    );

    var UserEmail = document.getElementById("UserEmail").value;
    var saveAllContacts = [];
    var FirstName = parseInt(document.getElementById("FirstName").value);
    if (FirstName >= 0) {
      var ObjFirstName = {
        FieldAlias: "First Name",
        Fieldname: "FirstName",
        Customfield: null,
        index: FirstName,
      };
      saveAllContacts.push(ObjFirstName);
    }
    var LastName = parseInt(document.getElementById("LastName").value);
    if (LastName >= 0) {
      var ObjLastName = {
        FieldAlias: "Last Name",
        Fieldname: "LastName",
        Customfield: null,
        index: LastName,
      };
      saveAllContacts.push(ObjLastName);
    }
    var Email = parseInt(document.getElementById("Email").value);
    if (Email >= 0) {
      var ObjEmail = {
        FieldAlias: "Email",
        Fieldname: "Email",
        Customfield: null,
        index: Email,
      };
      saveAllContacts.push(ObjEmail);
    }
    var Title = parseInt(document.getElementById("Title").value);
    if (Title >= 0) {
      var ObjTitle = {
        FieldAlias: "Title",
        Fieldname: "Title",
        Customfield: null,
        index: Title,
      };
      saveAllContacts.push(ObjTitle);
    }
    var Company = parseInt(document.getElementById("Company").value);
    if (Company >= 0) {
      var ObjCompany = {
        FieldAlias: "Company",
        Fieldname: "Company",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompany);
    }
    var CompanyNameLong = parseInt(
      document.getElementById("CompanyNameLong").value
    );
    if (CompanyNameLong >= 0) {
      var ObjCompanyNameLong = {
        FieldAlias: "Company Name Long",
        Fieldname: "CompanyNameLong",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompanyNameLong);
    }
    var Website = parseInt(document.getElementById("Website").value);
    if (Website >= 0) {
      var ObjWebsite = {
        FieldAlias: "Website",
        Fieldname: "Website",
        Customfield: null,
        index: Website,
      };
      saveAllContacts.push(ObjWebsite);
    }
    var CompanyPhone = parseInt(document.getElementById("CompanyPhone").value);
    if (CompanyPhone >= 0) {
      var ObjCompanyPhone = {
        FieldAlias: "Company Phone",
        Fieldname: "CompanyPhone",
        Customfield: null,
        index: CompanyPhone,
      };
      saveAllContacts.push(ObjCompanyPhone);
    }
    var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
    if (DirectPhone >= 0) {
      var ObjDirectPhone = {
        FieldAlias: "Direct Phone",
        Fieldname: "DirectPhone",
        Customfield: null,
        index: DirectPhone,
      };
      saveAllContacts.push(ObjDirectPhone);
    }

    var DirectPhoneExt = parseInt(
      document.getElementById("DirectPhoneExt").value
    );
    if (DirectPhoneExt >= 0) {
      var ObjDirectPhoneExt = {
        FieldAlias: "Direct Phone Ext",
        Fieldname: "DirectPhoneExt",
        Customfield: null,
        index: DirectPhoneExt,
      };
      saveAllContacts.push(ObjDirectPhoneExt);
    }
    var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
    if (LinkedInURL >= 0) {
      var ObjLinkedInURL = {
        FieldAlias: "LinkedIn URL",
        Fieldname: "LinkedInURL",
        Customfield: null,
        index: LinkedInURL,
      };
      saveAllContacts.push(ObjLinkedInURL);
    }
    var Industry = parseInt(document.getElementById("Industry").value);
    if (Industry >= 0) {
      var ObjIndustry = {
        FieldAlias: "Industry",
        Fieldname: "Industry",
        Customfield: null,
        index: Industry,
      };
      saveAllContacts.push(ObjIndustry);
    }

    var Address1 = parseInt(document.getElementById("Address1").value);
    if (Address1 >= 0) {
      var ObjAddress1 = {
        FieldAlias: "Address 1",
        Fieldname: "Address1",
        Customfield: null,
        index: Address1,
      };
      saveAllContacts.push(ObjAddress1);
    }

    var Address2 = parseInt(document.getElementById("Address2").value);
    if (Address2 >= 0) {
      var ObjAddress2 = {
        FieldAlias: "Address 2",
        Fieldname: "Address2",
        Customfield: null,
        index: Address2,
      };
      saveAllContacts.push(ObjAddress2);
    }

    var City = parseInt(document.getElementById("City").value);
    if (City >= 0) {
      var ObjCity = {
        FieldAlias: "City",
        Fieldname: "City",
        Customfield: null,
        index: City,
      };

      saveAllContacts.push(ObjCity);
    }
    var State = parseInt(document.getElementById("State").value);
    if (State >= 0) {
      var ObjState = {
        FieldAlias: "State",
        Fieldname: "State",
        Customfield: null,
        index: State,
      };
      saveAllContacts.push(ObjState);
    }

    var Zip = parseInt(document.getElementById("Zip").value);
    if (Zip >= 0) {
      var ObjZip = {
        FieldAlias: "Zip",
        Fieldname: "Zip",
        Customfield: null,
        index: Zip,
      };
      saveAllContacts.push(ObjZip);
    }

    var Country = parseInt(document.getElementById("Country").value);
    if (Country >= 0) {
      var ObjCountry = {
        FieldAlias: "Country",
        Fieldname: "Country",
        Customfield: null,
        index: Country,
      };
      saveAllContacts.push(ObjCountry);
    }

    var ContactCategory = parseInt(
      document.getElementById("ContactCategory").value
    );
    if (ContactCategory >= 0) {
      var ObjContactCategory = {
        FieldAlias: "Contact Category",
        Fieldname: "ContactCategory",
        Customfield: null,
        index: ContactCategory,
      };
      saveAllContacts.push(ObjContactCategory);
    }
    var AccountCategory = parseInt(
      document.getElementById("AccountCategory").value
    );
    if (AccountCategory >= 0) {
      var ObjAccountCategory = {
        FieldAlias: "Account Category",
        Fieldname: "AccountCategory",
        Customfield: null,
        index: AccountCategory,
      };
      saveAllContacts.push(ObjAccountCategory);
    }

    var ValidationScore = parseInt(
      document.getElementById("ValidationScore").value
    );
    if (ValidationScore >= 0) {
      var ObjValidationScore = {
        FieldAlias: "Validation Score",
        Fieldname: "ValidationScore",
        Customfield: null,
        index: ValidationScore,
      };
      saveAllContacts.push(ObjValidationScore);
    }
    var ListName = document.getElementById("ListName").value;
    if (ListName != "") {
      var ObjListName = {
        FieldAlias: "List Name",
        Fieldname: "ListName",
        Customfield: null,
        index: ListName,
      };
      saveAllContacts.push(ObjListName);
    }

    var ContactSource = parseInt(
      document.getElementById("ContactSource").value
    );
    if (ContactSource >= 0) {
      var ObjContactSource = {
        FieldAlias: "Contact Source",
        Fieldname: "ContactSource",
        Customfield: null,
        index: ContactSource,
      };
      saveAllContacts.push(ObjContactSource);
    }
    var ContactOwner = parseInt(document.getElementById("ContactOwner").value);
    if (ContactOwner >= 0) {
      var ObjContactOwner = {
        FieldAlias: "Contact Owner",
        Fieldname: "ContactOwner",
        Customfield: null,
        index: ContactOwner,
      };
      saveAllContacts.push(ObjContactOwner);
    }
    var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
    if (ClientPOC >= 0) {
      var ObjClientPOC = {
        FieldAlias: "Client POC",
        Fieldname: "ClientPOC",
        Customfield: null,
        index: ClientPOC,
      };
      saveAllContacts.push(ObjClientPOC);
    }
    var ReasonUnqualified = parseInt(
      document.getElementById("ReasonUnqualified").value
    );
    if (ReasonUnqualified >= 0) {
      var ObjReasonUnqualified = {
        FieldAlias: "Reason Unqualified",
        Fieldname: "ReasonUnqualified",
        Customfield: null,
        index: ReasonUnqualified,
      };
      saveAllContacts.push(ObjReasonUnqualified);
    }
    var AccountType = parseInt(document.getElementById("AccountType").value);
    if (AccountType >= 0) {
      var ObjAccountType = {
        FieldAlias: "Account Type",
        Fieldname: "AccountType",
        Customfield: null,
        index: AccountType,
      };
      saveAllContacts.push(ObjAccountType);
    }
    var CompanyRevenue = parseInt(
      document.getElementById("CompanyRevenue").value
    );
    if (CompanyRevenue >= 0) {
      var ObjCompanyRevenue = {
        FieldAlias: "Company Revenue",
        Fieldname: "CompanyRevenue",
        Customfield: null,
        index: CompanyRevenue,
      };
      saveAllContacts.push(ObjCompanyRevenue);
    }
    var EmployeeCount = parseInt(
      document.getElementById("EmployeeCount").value
    );
    if (EmployeeCount >= 0) {
      var ObjEmployeeCount = {
        FieldAlias: "Employee Count",
        Fieldname: "EmployeeCount",
        Customfield: null,
        index: EmployeeCount,
      };
      saveAllContacts.push(ObjEmployeeCount);
    }

    var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
    if (MobilePhone >= 0) {
      var ObjMobilePhone = {
        FieldAlias: "Mobile Phone",
        Fieldname: "MobilePhone",
        Customfield: null,
        index: MobilePhone,
      };
      saveAllContacts.push(ObjMobilePhone);
    }

    var IsDoNotCallContact = parseInt(
      document.getElementById("IsDoNotCallContact").value
    );
    if (IsDoNotCallContact >= 0) {
      var ObjIsDoNotCallContact = {
        FieldAlias: "Do not call contact",
        Fieldname: "IsDoNotCallContact",
        Customfield: null,
        index: IsDoNotCallContact,
      };
      saveAllContacts.push(ObjIsDoNotCallContact);
    }

    var SalesRep = parseInt(document.getElementById("SalesRep").value);
    if (SalesRep >= 0) {
      var ObjSalesRep = {
        FieldAlias: "Sales Rep",
        Fieldname: "SalesRep",
        Customfield: null,
        index: SalesRep,
      };
      saveAllContacts.push(ObjSalesRep);
    }

    var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
    if (ColdCaller >= 0) {
      var ObjColdCaller = {
        FieldAlias: "Sales Development Reps",
        Fieldname: "ColdCaller",
        Customfield: null,
        index: ColdCaller,
      };
      saveAllContacts.push(ObjColdCaller);
    }

    var Tag = parseInt(document.getElementById("Tag").value);
    if (Tag >= 0) {
      var ObjTag = {
        FieldAlias: "Tag",
        Fieldname: "Tag",
        Customfield: null,
        index: Tag,
      };
      saveAllContacts.push(ObjTag);
    }

    var CallNotes = parseInt(document.getElementById("CallNotes").value);
    if (CallNotes >= 0) {
      var ObjCallNotes = {
        FieldAlias: "Call Notes",
        Fieldname: "CallNotes",
        Customfield: null,
        index: CallNotes,
      };
      saveAllContacts.push(ObjCallNotes);
    }

    var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
    if (OtherPhone1 >= 0) {
      var ObjOtherPhone1 = {
        FieldAlias: "Other Phone 1",
        Fieldname: "OtherPhone1",
        Customfield: null,
        index: OtherPhone1,
      };
      saveAllContacts.push(ObjOtherPhone1);
    }
    var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
    if (OtherPhone2 >= 0) {
      var ObjOtherPhone2 = {
        FieldAlias: "Other Phone 2",
        Fieldname: "OtherPhone2",
        Customfield: null,
        index: OtherPhone2,
      };
      saveAllContacts.push(ObjOtherPhone2);
    }

    var ContactID = parseInt(document.getElementById("ContactID").value);
    if (ContactID >= 0) {
      var ObjContactID = {
        FieldAlias: "ContactID",
        Fieldname: "ContactID",
        Customfield: null,
        index: ContactID,
      };
      saveAllContacts.push(ObjContactID);
    }

    var IsUpdateExistContacts = document.getElementById(
      "IsUpdateExistContacts"
    ).checked;
    // if (IsUpdateExistContacts == false) {
    //   IsUpdateExistContacts = true;
    // } else {
    //   IsUpdateExistContacts = false;
    // }
    var IsInsertNullValues =
      document.getElementById("IsInsertNullValues").checked;
    var IsDoNotupdatecompanyname = document.getElementById(
      "IsDoNotupdatecompanyname"
    ).checked;
    var IsMoveExitsingcurrentcampaign = document.getElementById(
      "IsMoveExitsingcurrentcampaign"
    ).checked;
    var IsInsertContactAlreadyReplaied = document.getElementById(
      "IsInsertContactAlreadyReplaied"
    ).checked;

    var csvdata = csvData;
    var csvdata2 = csvData.length;

    var csvdata = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];
      var ObjData = {
        Fields: "Coverimg",
        Filepath: "./Content/UploadedFiles/SalesReplyContact",
        File: _base64,
        ImageName: filenameonlynumber,
        FileData: csvdata,
        MappingFiled: JSON.stringify(saveAllContacts),
        Email: UserEmail,
        ClientID: ClientID,
        UserID: UserID,
        CreatedBy: UserID,
        CreatedDate: new Date(),
        IsUpdateExistContacts: !IsUpdateExistContacts,
        IsInsertNullValues: IsInsertNullValues,
        IsDoNotUpdateCompanyName: IsDoNotupdatecompanyname,
        IsMoveExistContacts: IsMoveExitsingcurrentcampaign,
        IsAllowAlreadyRepliedContacts: IsInsertContactAlreadyReplaied,
        OriginalFileName: OriginalFileName,
        ListName: onlyname,
        CampaignID: campaignID,
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/prospect_campaign/ProspectCampaignBulkUploadImport",
        method: "POST",
        data: ObjData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetShowPopupImport(false);
          SetShowImportContactCsv(false);
          SetConfigPop(false);
          toast.success("Contact You will get file soon on Submitted Email.");
        } else {
          toast.error(res.data.Message);
        }
      });
    });
  };

  const handleAddDuplicateTemplete = async (
    CampaignIDs,
    CampaignStepIDs,
    Bodys,
    Subjects,
    ClientIDs,
    UserIDs,
    OrderBy,
    EModProfileIDDuplicate
  ) => {
    var StrInTemplateStep = {
      ClientID: ClientIDs,
      UserID: UserIDs,
      CampaignID: CampaignIDs,
      CampaignStepID: CampaignStepIDs,
      Body: Bodys,
      Subject: Subjects,
      EModProfileID: EModProfileIDDuplicate,
    };
    const rows = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignsteptemplate/AddCampaignStepTemplate",
      method: "POST",
      data: StrInTemplateStep,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      GetCampaignStepDetails(ClientID, UserID, Role);
    }
  };

  const handleDeleteStepTemplete = (CStepTemplateID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a campaign step.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          CampaignStepTemplateID: CStepTemplateID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/campaignsteptemplate/DeleteCampaignStepTemplate",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Campaign step deleted successfully.",
                "success"
              );
              GetCampaignStepDetails(ClientID, UserID, Role);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const EditStepTempletPopHandle = (id) => {
    if (EditStepTempletePopup === false) {
      SetCampaignStepTemplatesID(id);
      GetStepTempletDataByID(id);
      SetEditStepTempletePopup(true);
    } else {
      SetEditStepTempletePopup(false);
      SetCampaignStepTemplatesID("");
    }
  };

  const GetStepTempletDataByID = async (id) => {
    var StrInTemplateSteps = {
      CampaignStepTemplateID: id,
      ClientID: ClientID,
      UserID: UserID,
    };
    const rows = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignsteptemplate/GetByIDCampaignStepTemplate",
      method: "POST",
      data: StrInTemplateSteps,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      document.getElementById("TemplateSubject3").value =
        rows.data.Data[0]?.Subject;
      SeteModProfileDefaultEdit(rows.data.Data[0]?.EModProfileID);
      SetSignatureSC3({
        data: rows.data.Data[0]?.Body,
      });
    }
  };

  const HandleChangedropdownBlur = () => {
    let Errors = { ...ErrorsStep };
    var Subject = document.getElementById("TemplateSubject3").value;
    if (Subject == "") {
      Errors["TemplateSubject3"] = "Please enter Subject";
    } else {
      Errors["TemplateSubject3"] = null;
    }
    SetErrorsStep(Errors);
  };

  const EditStepTemplatePopupSubmit = async () => {
    var Body = SignatureSC3.data;
    var Subject = document.getElementById("TemplateSubject3").value;
    var EditeModdata = document.getElementById("eModProfileEditID").value;
    if (Body == "") {
      toast.error(
        <div className="toastsize">
          Add Step
          <br />
          Body is empty,fill it!
        </div>
      );
    } else if (Subject == "") {
      let Errors = { ...ErrorsStep };
      Errors["TemplateSubject3"] = "Please enter Subject";
      SetErrorsStep(Errors);
    } else {
      SetIsVisible(true);
      let Errors = { ...ErrorsStep };
      Errors["TemplateSubject3"] = "";
      SetErrorsStep(Errors);
      var StrInTemplateSteps = {
        id: CampaignStepTemplatesID,
        Body: Body,
        Subject: Subject,
        eModProfileID: EditeModdata,
        LastUpdatedBy: UserID,
        LastUpdatedDate: new Date(),
      };
      const rows = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/campaignsteptemplate/UpdateCampaignStepTemplate",
        method: "POST",
        data: StrInTemplateSteps,
      });
      if (rows.data.StatusMessage == "SUCCESS") {
        SetEditStepTempletePopup(false);
        GetCampaignStepDetails(ClientID, UserID, Role);
      }
      SetIsVisible(true);
    }
  };

  const ChangeDays = async () => {
    var Days = document.getElementById("DaysTemplate").value;
    var StrIn = {
      UserID: UserID,
      ClientID: ClientID,
      Role: Role,
      CampaignStepID: CampaignStepID,
      Days: Days,
    };
    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/UpdateCampaignDays",
      method: "POST",
      data: StrIn,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      SetAnchorEl(null);
      GetCampaignStepDetails(ClientID, UserID, Role);
      toast.success(
        <div className="toastsize">
          Edit Step
          <br />
          Campaign step updated successfully.
        </div>
      );
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetCSVERowsPerPage(Number(Event.target.value));
    SetCSVEPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //Update Status is active
  const StepTemplateActive = (ID, Flag) => {
    const InputParameters = {
      CampaignStepTemplateID: ID,
      IsActive: Flag,
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignstep/CampaignStepTemplateIsActive",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage === "SUCCESS") {
        if (res.data.Data.IsActive == true) {
          GetCampaignStepDetails(ClientID, UserID, Role);
          toast.success(
            <div className="toastsize">
              Campaign
              <br />
              Campaign Step Active Successfully.{" "}
            </div>
          );
        } else {
          GetCampaignStepDetails(ClientID, UserID, Role);
          toast.success(
            <div className="toastsize">
              Campaign
              <br />
              Campaign Step Inactive Successfully.{" "}
            </div>
          );
        }
      }
    });
  };

  // Search details

  const handleSearch = (e) => {
    SetSearch(e.target.value);
  };

  let filteredData = Data.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString().toLowerCase().includes(Search.toLowerCase())
    );
  });

  const handleSort = (key) => {
    SetSortField(key);
  };

  if (SortField !== "") {
    filteredData.sort((a, b) => {
      if (a[SortField] < b[SortField]) {
        return -1;
      }
      if (a[SortField] > b[SortField]) {
        return 1;
      }
      return 0;
    });
  }

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = CSVEPage * CSVERowsPerPage;
  const indexOfFirstItem = indexOfLastItem - CSVERowsPerPage;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const DeleteProspectCampaign = () => {
    if (dataFromChild == 0) {
      toast.error("Please select atleast one contact from list.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete Contacts..",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var ProspectCampaignData = {
            dataFromChild: dataFromChild,
            LastUpdatedBy: UserID,
            LastUpdatedDate: new Date(),
            CampaignID: campaignID,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/prospect_campaign/DeleteProspectCampaingArray",
            method: "POST",
            data: ProspectCampaignData,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                SetProspectFlag(true);
                Swal.fire(
                  "Deleted!",
                  "Selected contacts deleted successfully.",
                  "success"
                );
                SetProspectFlag(false);
                document.getElementById("main-check").checked = false;
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  };
  const ProspectCampaignUnPausedContacts = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to UnPause Contacts..",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, UnPause it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var ProspectCampaignData = {
          ClientID: ClientID,
          UserID: UserID,
          CampaignID: campaignID,
          LastUpdatedBy: UserID,
          LastUpdatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/prospect_campaign/ProspectCampaignUnIsPaused",
          method: "POST",
          data: ProspectCampaignData,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              SetProspectFlag(true);
              Swal.fire(
                "Deleted!",
                "Selected contacts UnPaused successfully.",
                "success"
              );
              SetProspectFlag(false);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  // Move campaign
  const ContactMoveToCampaign = () => {
    if (dataFromChild == 0) {
      toast.error("Please select atleast one contact from list.");
    } else {
      document.getElementById("move_campaign").style.display = "block";
      document.getElementById("move_campaign_icon").style.display = "none";
    }
  };

  // Exports csv for contacts
  const ExportsToProspectCampaign = async (e) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await FormValidation();
    if (Final_flag == true) {
      var ExportEmails = document.getElementById("UsernameExport").value;
      var ExportData = {
        UserID: UserID.toString(),
        ClientID: ClientID.toString(),
        Email: ExportEmails,
        AccountType: null,
        CreatedDate: new Date(),
        CreatedBy: UserID,
        AccountTypeID: null,
        ClientIDs: null,
        IsDeleted: false,
        LastUpdatedDate: null,
        LastUpdatedBy: null,
        InProcess: false,
        IsComplete: false,
        InProcessDate: null,
        IsCompleteDate: null,
        Page: null,
        CampaignID: campaignID,
        DynamicQuery: "",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/prospect_campaign/AllProspectCampaignExport",
        method: "POST",
        data: ExportData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Contacts Email Send succefully.",
            "For Exports contacts  "
          );
          SetShowPopupExport1(false);
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("submitcsv").disabled = false;
    }
  };

  // handle change
  const HandleChange = () => {
    var Email = document.getElementById("UsernameExport").value.trim();
    if (Email != "") {
      ExportCsvErrors.UsernameExport = null;
      CheckClientInvite(Email);
    } else {
      ExportCsvErrors.UsernameExport = null;
    }
  };

  // Whole form validation
  const FormValidation = async () => {
    let Errors = {};
    let FormIsValid = true;
    let Email = document.getElementById("UsernameExport").value.trim();
    let IsExist = await CheckClientInvite(Email);

    if (Email == "") {
      FormIsValid = false;
      Errors["UsernameExport"] = "Please enter email";
    }
    if (IsExist == true) {
      FormIsValid = false;
      Errors["UsernameExport"] = "invalid email.";
    }
    SetExportCsvErrors(Errors);
    return FormIsValid;
  };

  // find exits prospect campaign  Email
  const CheckClientInvite = async (Email) => {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["UsernameExport"] = "invalid email.";
      SetExportCsvErrors(Errors);
      return true;
    } else {
      return false;
    }
  };

  // campaign data
  const GetCampaignsList = (CID, UID) => {
    var str_in = {
      ClientID: CID,
      UserID: UID,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/prospect_campaign/CampaignGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      let index = result.data?.DataForMove?.findIndex(
        (obj) => obj._id === campaignID
      );
      if (index !== -1) {
        result.data?.DataForMove?.splice(index, 1);
      }
      SetCampaignDataDrop(
        result.data?.DataForMove?.map((item) => {
          return { value: item._id, label: item.Name, cid: item._id };
        })
      );
    });
  };

  // Open main Filter model
  const OpenFilterModel = () => {
    SetSaveFilterBtnDisabled(false)
    SetOpenFilter(true);
  };

  // close main filter model
  const CloseFilterModel = () => {
    SetOpenFilter(false);
    SetFieldAttributes([{ id: 0, ColumnName: "IsPaused", InputType: "Yes" }]);
  };

  //******* Add Filter methods */

  //Open add filter method
  const OpenAddFilterMethod = () => {
    SetOpenAddFilter(true);
    SetSaveFilterBtnDisabled(false)

  };
  // Close add filter method
  const CloseAddFilterMethod = () => {
    SetOpenAddFilter(false);
    SetFieldAttributes([{ id: 0, ColumnName: "IsPaused", InputType: "Yes" }]);
  };

  //Column set method
  const SetAddFilterColumn = () => {
    let StateArr = [];
    FieldAttributes.forEach((el) => {
      StateArr.push(el);
    });

    let ColumnName = document.getElementById("ColumnName" + 0).value;
    let InputType = document.getElementById("expression" + 0).value;
    let indexed = StateArr.findIndex((v) => v.id === 0);
    if (indexed == -1) {
      StateArr.push({ id: 0, ColumnName: ColumnName, InputType: InputType });
    } else {
      StateArr[indexed] = {
        id: 0,
        ColumnName: ColumnName,
        InputType: InputType,
      };
    }
    SetFieldAttributes(StateArr);
  };

  //Add filter properties
  const AddFilterRow = () => {
    let StateArray = [];
    FieldAttributes.forEach((el) => {
      StateArray.push(el);
    });
    StateArray.push({
      id: StateArray.length + 1,
      ColumnName: "IsPaused",
      InputType: "Yes",
    });

    SetFieldAttributes(StateArray);
  };

  //Remove filter properties
  const RemoveFilterRow = (id) => {
    FieldAttributes.map((element) => {
      if (element.id != id) {
        countArray2.push(element);
      }
    });
    SetFieldAttributes(countArray2);
  };

  //Column Handler for Add Filter
  const ColumnDropdownHandlerAdd = (i) => {
    let StateArr = [];
    FieldAttributes.forEach((el) => {
      StateArr.push(el);
    });
    let ColumnName = document.getElementById("ColumnName" + i).value;
    let InputType = document.getElementById("expression" + i).value;
    if (ColumnName == "IsPaused") {
      document.getElementById("searchvalue" + i).value = "y";
    }
    let indexed = StateArr.findIndex((v) => v.id === i);
    if (indexed == -1) {
      StateArr.push({ id: i, ColumnName: ColumnName, InputType: InputType });
    } else {
      StateArr[indexed] = {
        id: i,
        ColumnName: ColumnName,
        InputType: InputType,
      };
    }

    SetFieldAttributes(StateArr);
  };

  // Expression Handler method
  const AddExpressionHandler = (i) => {
    let StateArr = [];
    FieldAttributes.forEach((el) => {
      StateArr.push(el);
    });
    let ColumnName = document.getElementById("ColumnName" + i).value;
    let InputType = document.getElementById("expression" + i).value;
    if (InputType == "IsBlank" || InputType == "IsNotBlank") {
      document.getElementById("searchvalue" + i).value = "y";
    }
    let indexed = StateArr.findIndex((v) => v.id === i);
    if (indexed == -1) {
      StateArr.push({ id: i, ColumnName: ColumnName, InputType: InputType });
    } else {
      StateArr[indexed] = {
        id: i,
        ColumnName: ColumnName,
        InputType: InputType,
      };
    }
    SetFieldAttributes(StateArr);
  };

  // Filter Validation
  const FilterValidation = () => {
    let ValidationArr = [];

    FieldAttributes.map((newvalue) => {
      if (newvalue.ColumnName == "IsPaused") {
        document.getElementById("searchvalue" + newvalue.id).value = "y";
      }
      var searchedVal1 = document.getElementById(
        "searchvalue" + newvalue.id
      ).value;
      if (searchedVal1.trim() == "") {
        ValidationArr.push(false);
      } else {
        ValidationArr.push(true);
      }
      var expressionval = document.getElementById(
        "expression" + newvalue.id
      ).value;
      if (expressionval == "between") {
        let FilterPropertyValue2 = document.getElementById(
          "EndsWith" + newvalue.id
        ).value;
        if (FilterPropertyValue2.trim() == "") {
          ValidationArr.push(false);
        } else {
          ValidationArr.push(true);
        }
      }
    });

    return ValidationArr.includes(false);
  };

  //Search Filter
  const FilterSearch = () => {
    if (FilterValidation() == false) {
      if (FieldAttributes.length <= 0) {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Please add filter property at least one
          </div>
        );
      } else {
        var FilterDataArray = [];
        FieldAttributes.map((newvalue) => {
          var searchedVal1 = document.getElementById(
            "searchvalue" + newvalue.id
          ).value;
          var expressionval = document.getElementById(
            "expression" + newvalue.id
          ).value;
          var Cloumnval = document.getElementById(
            "ColumnName" + newvalue.id
          ).value;
          if (expressionval == "between") {
            var searchedVal2 = document.getElementById(
              "EndsWith" + newvalue.id
            ).value;
            FilterDataArray.push({
              ColumnName: Cloumnval,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: searchedVal2,
            });
          } else {
            FilterDataArray.push({
              ColumnName: Cloumnval,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: null,
            });
          }
        });

        SetFilterParameters(FilterDataArray);
        SetIsApply(true);
        SetFieldAttributes([
          [{ id: 0, ColumnName: "IsPaused", InputType: "Yes" }],
        ]);
        CloseFilterModel();
        CloseAddFilterMethod();
      }
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };

  //Check filter name while save
  const CheckFilterName = async () => {
    let FilterName = document.getElementById("FilterName").value.trim();
    let flag = false;
    const InputParameters = {
      ClientID: ClientID,
      FilterName: FilterName,
    };
    let res = await Axios({
      url: CommonConstants.MOL_APIURL + "/prospect_campaign/CheckFilterName",
      method: "POST",
      data: InputParameters,
    });
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data <= 0) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      toast.error("Something went wrong in filter name exists api");
    }

    return flag;
  };

  //Save Filter
  const SaveFilter = async () => {
    SetSaveFilterBtnDisabled(true)

    let FilterName = document.getElementById("FilterName").value.trim();
    if (FilterName == "") {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Please enter filter name
        </div>
      );
      SetSaveFilterBtnDisabled(false)
    } else {
      if ((await CheckFilterName()) == false) {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Filter value already exist
          </div>
        );
      SetSaveFilterBtnDisabled(false)

      } else {
        if (FilterValidation() == false) {
          if (FieldAttributes.length <= 0) {
            toast.error(
              <div className="toastsize">
                Filter
                <br />
                Please add filter property at least one
              </div>
            );
      SetSaveFilterBtnDisabled(false)

          } else {
            var FilterDataArray = [];
            FieldAttributes.map((newvalue) => {
              var searchedVal1 = document.getElementById(
                "searchvalue" + newvalue.id
              ).value;
              var expressionval = document.getElementById(
                "expression" + newvalue.id
              ).value;
              var Cloumnval = document.getElementById(
                "ColumnName" + newvalue.id
              ).value;
              if (expressionval == "between") {
                var searchedVal2 = document.getElementById(
                  "EndsWith" + newvalue.id
                ).value;
                FilterDataArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: searchedVal2,
                });
              } else {
                FilterDataArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: null,
                });
              }
            });

            const InputParameters = {
              ClientID: ClientID,
              FilterName: FilterName,
              FilterParameters: FilterDataArray,
              CreatedDate: new Date(),
              UserID: UserID,
            };

            let res = await Axios({
              url: CommonConstants.MOL_APIURL + "/prospect_campaign/SaveFilter",
              method: "POST",
              data: InputParameters,
            });
            if (res.data.StatusMessage == "SUCCESS") {
              SetFilterParameters(FilterDataArray);
              SetIsApply(true);
              SetFieldAttributes([]);
              SetOpenAddFilter(false);
              SetOpenFilter(false);
              SetFieldAttributes([
                { id: 0, ColumnName: "IsPaused", InputType: "Yes" },
              ]);
              Getfilterlist(ClientID, UserID);
              toast.success(<div className="toastsize">Filter <br/>
                Filter Added SuccessFully.</div>)
            } else {
      SetSaveFilterBtnDisabled(false)

              toast.error("Error in save filter");
            }
          }
        } else {
      SetSaveFilterBtnDisabled(false)

          toast.error(
            <div className="toastsize">
              Filter
              <br />
              Property value can not be an empty
            </div>
          );
        }
      }
    }
  };

  //***Select existing filter */

  // Get Filter List
  const Getfilterlist = (CID, UID) => {
    var InputParameters = {
      ClientID: CID,
      UserID: UID,
    };

    const rows3 = Axios({
      url: CommonConstants.MOL_APIURL + "/prospect_campaign/GetFilterName",
      method: "POST",
      data: InputParameters,
    });
    rows3.then((result) => {
      SetFilterName(result.data.Data);
      if (result.data.Data.length > 0) {
        Getfilterpropertylist(result.data.Data[0]._id);
      }
    });
  };

  const handleChangeFilter = () => {
    var filterdropoid = document.getElementById("FilterID").value;
    Getfilterpropertylist(filterdropoid);
  };

  // Get FilteProperty list with dropdown
  const Getfilterpropertylist = (filterid) => {
    if (filterid !== undefined) {
      SetFilterID(filterid);

      var InputParameters = {
        ClientID: ClientID,
        ID: filterid,
        IsDeleted: false,
      };
      const rows4 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/prospect_campaign/GetFilterProperties",
        method: "POST",
        data: InputParameters,
      });
      rows4.then((result) => {
        let FilteredArr = [];
        result.data.Data.forEach((el, i) => {
          el.id = i;
          el.IsSaved = true;
          FilteredArr.push(el);
        });

        SetEditFieldAttributes(FilteredArr);
      });
    }
  };

  //Reset Filter
  const ResetFilter = () => {
    SetIsApply(false);
    SetFilterParameters([]);
  };

  //Edit column name
  const EditColumnName = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    let FilterPropertyName = document.getElementById(
      "EditColumnName" + ID
    ).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyName = FilterPropertyName;
    if (SavedData[indexed].FilterPropertyName == "IsPaused") {
      document.getElementById("Editsearchvalue" + ID).value = "y";
    }
    SetEditFieldAttributes(SavedData);
  };

  //Edit Expression value
  const EditExpressValue = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    let ExpressionName = document.getElementById("Editexpression" + ID).value;
    if (ExpressionName == "IsBlank" || ExpressionName == "IsNotBlank") {
      document.getElementById("Editsearchvalue" + ID).value = "y";
    }
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].ExpressionName = ExpressionName;
    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue1
  const EditFilterPropertyValue1 = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    let FilterPropertyValue1 = document.getElementById(
      "Editsearchvalue" + ID
    ).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue1 = FilterPropertyValue1;
    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue2
  const EditFilterPropertyValue2 = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    let FilterPropertyValue2 = document.getElementById(
      "EditEndsWith" + ID
    ).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue2 = FilterPropertyValue2;
    SetEditFieldAttributes(SavedData);
  };

  //Add new filter property
  const handleeditPropertyClick = () => {
    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    SavedData.push({
      id: SavedData.length + 1,
      FilterPropertyName: "IsPaused",
      ExpressionName: "Yes",
      IsSaved: false,
    });
    SetEditFieldAttributes(SavedData);
  };

  //Remove filter property
  const handleEditRemoveClick = (id) => {
    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    if (SavedData.length <= 1) {
      toast.error(
        <div>
          Filter
          <br />
          Required filter property at least one
        </div>
      );
    } else {
      let DeletedArr = DeletedFilterProperty;
      const FilterPropertyID = SavedData.findIndex((item) => item.id == id);
      if (SavedData[FilterPropertyID].IsSaved == true) {
        DeletedArr.push(SavedData[FilterPropertyID]._id);
        SetDeletedFilterProperty(DeletedArr);
      }
      const FilteredData = SavedData.filter((item) => item.id !== id);
      SetEditFieldAttributes(FilteredData);
    }
  };

  //Update Filter validation
  const UpdateFilterValidation = () => {
    let Flags = [];
    EditFieldAttributes.forEach((el) => {
      if (el.FilterPropertyName == "IsPaused") {
        document.getElementById("Editsearchvalue" + el.id).value = "y";
      }
      var searchedVal1 = document.getElementById(
        "Editsearchvalue" + el.id
      ).value;
      if (searchedVal1.trim() == "") {
        Flags.push(false);
      } else {
        Flags.push(true);
      }
      if (el.ExpressionName == "between") {
        let FilterPropertyValue2 = document.getElementById(
          "EditEndsWith" + el.id
        ).value;
        if (FilterPropertyValue2.trim() == "") {
          Flags.push(false);
        } else {
          Flags.push(true);
        }
      }
    });

    return Flags.includes(false);
  };

  //Update and apply filter
  const editapplyfilterSearch = async () => {
    SetSaveFilterBtnDisabled(true)
    if (UpdateFilterValidation() == false) {
      const InputParameters = {
        ClientID: ClientID,
        FilterParameters: EditFieldAttributes,
        LastUpdatedDate: new Date(),
        UserID: UserID,
        FilterID: FilterID,
        DeletedFilterProperty: DeletedFilterProperty,
      };

      let res = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/prospect_campaign/UpdateFilterProperties",
        method: "POST",
        data: InputParameters,
      });

      if (res.data.StatusMessage == "SUCCESS") {
        var FilterDataArray = [];
        EditFieldAttributes.map((el) => {
          if (el.ExpressionName == "between") {
            FilterDataArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1,
              searchvalue1: el.FilterPropertyValue2,
            });
          } else {
            FilterDataArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1,
              searchvalue1: null,
            });
          }
        });


        SetIsApply(true);
        SetFilterParameters(FilterDataArray);
        SetOpenAddFilter(false);
        SetOpenFilter(false);
        SetOpenEditFilter(false);
        toast.success(
          <div className="toastsize">
            Filter <br />
            Filter Updated SuccessFully.
          </div>
        );
      } else {
        toast.error("Error in save filter");
        SetSaveFilterBtnDisabled(false)
      }
    } else {
      SetSaveFilterBtnDisabled(false)

      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };

  //Apply Edit Filter
  const ApplyEditFilter = () => {
    var FilterDataArray = [];
    EditFieldAttributes.map((el) => {
      if (el.ExpressionName == "between") {
        FilterDataArray.push({
          ColumnName: el.FilterPropertyName,
          expression: el.ExpressionName,
          searchvalue: el.FilterPropertyValue1,
          searchvalue1: el.FilterPropertyValue2,
        });
      } else {
        FilterDataArray.push({
          ColumnName: el.FilterPropertyName,
          expression: el.ExpressionName,
          searchvalue: el.FilterPropertyValue1,
          searchvalue1: null,
        });
      }
    });

    SetFilterParameters(FilterDataArray);
    SetOpenFilter(false);
    SetIsApply(true);
    SetOpenAddFilter(false);
    SetOpenEditFilter(false);
  };

  //Open Edit filter model
  const OpenEditFilterModel = () => {
    SetOpenEditFilter(true);
    SetSaveFilterBtnDisabled(false)
    Getfilterlist(ClientID, UserID);
  };

  //close edit filter
  const CloseEditFilter = () => {
    SetEditFieldAttributes([]);
    SetFieldAttributes([{ id: 0, ColumnName: "IsPaused", InputType: "Yes" }]);
    SetIsApply(false);
    Getfilterlist(ClientID, UserID);
    SetOpenAddFilter(false);
    SetOpenEditFilter(false);
    SetOpenFilter(false)
  };

  const CampaignMove = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to move a contacts to " + e.label,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Move it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var InputParaMeter = {
          ClientID: ClientID,
          UserID: UserID,
          CampaignID: campaignID,
          CampaignMoveID: e.value,
          ArrayData: dataFromChild,
          LastUpdatedBy: UserID,
          LastUpdatedDate: new Date(),
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/prospect_campaign/CampaignMoveAnother",
          method: "POST",
          data: InputParaMeter,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              SetProspectFlag(true);
              Swal.fire("Move!", "Contacts moved successfully.", "success");
              SetProspectFlag(false);
              document.getElementById("main-check").checked = false;
              document.getElementById("move_campaign").style.display = "none";
              document.getElementById("move_campaign_icon").style.display = "block";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  const HandleOkButton = (e) => {
    SetProspectFlag(true);
    ConfigPopHandle();
    SetShowImportContactCsv(false);
    setTimeout(() => {
      SetProspectFlag(false);
    }, 2000);
  };
//edit send test
  const SendEditStepHandleTest = () => {
    let SendTestEmailToIDs =
      document.getElementById("SendTestEmailToID")?.value.trim() || "";
    let SendTestEditSubject =
      document.getElementById("TemplateSubject3")?.value.trim() || "";
    let SendTestIncludeOpenTracking =
      document.getElementById("IsUpdateExistIncludeOpen")?.value.trim() || "";
    var SendTestEditBody = SignatureSC3.data || "";
    let sendTestEmailFromIDs = "";
    let sendTestRefersTokens = "";
    try {
      // Attempt to parse JSON
      let sendTestCommons = JSON.parse(
        document.getElementById("SendTestEmailFromID")?.value
      );
      // Use optional chaining and nullish coalescing to handle undefined or null values
      sendTestEmailFromIDs = sendTestCommons?.SMTPFromEmail || "";
      sendTestRefersTokens = sendTestCommons?.ReferenceToken || "";
    } catch (error) {
      sendTestEmailFromIDs = "";
      sendTestRefersTokens = "";
    }

    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(SendTestEmailToIDs);

    if (SendTestEmailToIDs == "") {
      toast.error("Please enter email!");
    } else if (SignatureSendTest == "") {
      toast.error("Please add email Signature!");
    } else if (sendTestEmailFromIDs == "") {
      toast.error("Please add email account!");
    } else if (Emailflg == false) {
      toast.error("Please enter valid email!");
    } else {
      SetIsVisible(true);
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(SendTestEmailToIDs),
        "my-secret-key@123"
      ).toString();

      var EmailData = EmailTemplete.userEmailInvite(FinalEmail);
      var str_mail = {
        Email: EmailData,
        FromEmail: sendTestEmailFromIDs,
        ToEmail: SendTestEmailToIDs,
        RefresToken: sendTestRefersTokens,
        Html: SendTestEditBody,
        Subject: SendTestEditSubject,
        PageType: "StepTempleteSendTest",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/campaignaccount/CampaignStepEmailSendTest",
        method: "POST",
        data: str_mail,
      })
        .then((res) => {
          SetIsVisible(false);
          toast.success("Email Send Successfully!");
        })
        .catch((err) => {
          SetIsVisible(false);
        });
    }
  };
//add send test
const SendAddStepHandleTest = () => {
  let SendTestEmailToIDs =
    document.getElementById("AddSendTestEmailToID")?.value.trim() || "";
  let SendTestEditSubject =
    document.getElementById("TemplateSubject")?.value.trim() || "";
  let SendTestIncludeOpenTracking =
    document.getElementById("IsUpdateExistIncludeOpenAdd")?.value.trim() || "";
  var SendTestEditBody = SignatureSC2.data || "";
  let sendTestEmailFromIDs = "";
  let sendTestRefersTokens = "";
  try {
    // Attempt to parse JSON
    let sendTestCommons = JSON.parse(
      document.getElementById("AddSendTestEmailFromID")?.value
    );
    // Use optional chaining and nullish coalescing to handle undefined or null values
    sendTestEmailFromIDs = sendTestCommons?.SMTPFromEmail || "";
    sendTestRefersTokens = sendTestCommons?.ReferenceToken || "";
  } catch (error) {
    sendTestEmailFromIDs = "";
    sendTestRefersTokens = "";
  }

  var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  var Emailflg = re.test(SendTestEmailToIDs);

  if (SendTestEmailToIDs == "") {
    toast.error("Please enter email!");
  } else if (SignatureSendTest == "") {
    toast.error("Please add email Signature!");
  } else if (sendTestEmailFromIDs == "") {
    toast.error("Please add email account!");
  } else if (Emailflg == false) {
    toast.error("Please enter valid email!");
  } else {
    SetIsVisible(true);
    var FinalEmail = CryptoJS.AES.encrypt(
      JSON.stringify(SendTestEmailToIDs),
      "my-secret-key@123"
    ).toString();

    var EmailData = EmailTemplete.userEmailInvite(FinalEmail);
    var str_mail = {
      Email: EmailData,
      FromEmail: sendTestEmailFromIDs,
      ToEmail: SendTestEmailToIDs,
      RefresToken: sendTestRefersTokens,
      Html: SendTestEditBody,
      Subject: SendTestEditSubject,
      PageType: "StepTempleteSendTest",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignaccount/CampaignStepEmailSendTest",
      method: "POST",
      data: str_mail,
    })
      .then((res) => {
        SetIsVisible(false);
        toast.success("Email Send Successfully!");
      })
      .catch((err) => {
        SetIsVisible(false);
      });
  }
};
const ContactValidateEmailCheck = async () => {
  
  // document.getElementById("Show_csv_details").style.display = "none"
  if (dataFromChild.length === 0) {
    toast.error("Please select at least one contact from the list");
    return;
  }
  try {
    SetIsVisible(true);
    const InputParaMeter = {
      ContactValidateArray:   dataFromChild,
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
    };

    const response = await Axios.post(
      CommonConstants.MOL_APIURL + "/contacts/ContactValidateCheckCampaign",
      InputParaMeter
    );

    if (response.data.StatusMessage === "SUCCESS") {
      toast.success("Saleshive contact will be validated soon.");
      setDataFromChild([])
      SetProspectFlag(!ProspectFlag);

      SetIsVisible(false);
      
      // document.getElementById("deleteall-btn").style.display = "none"
    } else {
      toast.error("Not validated");
    }
  } catch (error) {
    // Handle errors here
    console.error("Error:", error);
  } finally {
    SetIsVisible(false);
  }
};


  return (
    <div>
      {IsVisible === true ? (
        <div
          id="hideloding23"
          className="loding-display"
          style={{ zIndex: 9999 }}
        >
          <img src={loadingicon} />
        </div>
      ) : (
        ""
      )}
      <MuiThemeProvider theme={defaultTheme}>
        <div className="tabdes">
          <div>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Campaign Setting" {...a11yProps(0)}>
                  Overviewasdasd
                </Tab>
                <Tab label="Email Accounts" {...a11yProps(1)} />
                <Tab label="Contacts" {...a11yProps(2)} />
                <Tab label="Steps" {...a11yProps(3)} />
                <Tab label="Preview" {...a11yProps(4)} />
                <Tab label="Reporting" {...a11yProps(5)} />
              </Tabs>
            </AppBar>
            <TabPanel
              value={value}
              className="tagpaddnone bg-white inpt-0"
              index={0}
            >
              <div className="row border-bottom mb-2">
                <div className="col">
                  <h4 className="headertitlebd pl-0 mb-0">Campaign Setting</h4>
                </div>
              </div>
              <div className="w-100">
                <div className="row pt-2">
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols">
                      <label className="col-lg-4 view-lable-meet">
                        First Name
                      </label>
                      <div className="col-lg-8">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="CampaignName"
                          name="FirstName"
                          placeholder="First name"
                          type="text"
                          onBlur={CampaignExist}
                        />
                        <span style={{ color: "red" }}>{Errors}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols">
                      <label className="col-lg-4 view-lable-meet">
                        Reply Behavior
                      </label>
                      <div className="col-lg-8">
                        <select
                          id="ReplyBehavior"
                          class="form-control m-input valid"
                        >
                          {ReplyBehavior.length === 0 ? (
                            <div></div>
                          ) : (
                            ReplyBehavior.map((value) => (
                              <option
                                value={value?.FieldID}
                                selected={
                                  CampaignOne?.ReplyBehaviorID ===
                                  value?.FieldID
                                    ? true
                                    : false
                                }
                              >
                                {value?.FieldName}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols">
                      <label className="col-lg-4 view-lable-meet">
                        Priority
                      </label>
                      <div className="col-lg-8">
                        <select
                          id="Priority"
                          class="form-control m-input valid"
                        >
                          {Priority.length === 0 ? (
                            <div></div>
                          ) : (
                            Priority.map((value) => (
                              <option
                                value={value?.FieldID}
                                selected={
                                  CampaignOne?.CampaignPriorityID ===
                                  value.FieldID
                                    ? true
                                    : false
                                }
                              >
                                {value.FieldName}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols">
                      <label className="col-lg-4 view-lable-meet">
                        Open Tracking
                      </label>
                      <div className="col-lg-8">
                        <select
                          id="OpenTracking"
                          class="form-control m-input valid"
                        >
                          {OpenTracking.length === 0 ? (
                            <div></div>
                          ) : (
                            OpenTracking?.map((value) => (
                              <option
                                value={value.FieldID}
                                selected={
                                  CampaignOne?.EmailOpenTrackingID ===
                                  value.FieldID
                                    ? true
                                    : false
                                }
                                EmailOpenTrackingID
                              >
                                {value.FieldName}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols">
                      <label className="col-lg-4 view-lable-meet">
                        eMod Profile
                      </label>
                      <div className="col-lg-8">
                        <select
                          id="eModProfiles"
                          value={eModProfileDefault}
                          onChange={(event) => handleeModProfileDefault(event)}
                          class="form-control m-input valid"
                        >
                          <option value="6523d182eff732ba5ceead8c">
                            eMod off
                          </option>
                          {eModProfile === "" ? (
                            <div></div>
                          ) : (
                            eModProfile.map((value) => (
                              <option
                                value={value._id.toString()}
                                selected={
                                  eModProfilestaticID == ""
                                    ? false
                                    : eModProfilestaticID ==
                                      value._id?.toString()
                                    ? true
                                    : false
                                }
                              >
                                {value.Profile}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group"></div>

                  <div className="col-lg-6 boxsinput_group">
                    <label class="check_bx">
                      <input id="SendOnWeekends" type="checkbox" />
                      <span className="checkmark"></span>
                      Send on Weekends
                    </label>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <label class="check_bx">
                      <input id="IgnoreBlacklist" type="checkbox" />
                      <span className="checkmark"></span>
                      Ignore Blacklist
                    </label>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <label class="check_bx">
                      <input id="TrackClicks" type="checkbox" />
                      <span className="checkmark"></span>
                      Track Clicks
                    </label>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <label class="check_bx">
                      <input id="SubjectPromptCheckBox" type="checkbox" />
                      <span className="checkmark"></span>
                      Subject Prompt
                    </label>
                  </div>
                  <div className="col-lg-12 boxsinput_group">
                    <div className="row">
                      <label className="col-lg-12 view-lable-meet">Notes</label>
                      <div className="col-lg-12">
                        <textarea
                          id="Notes"
                          className="form-control m-input"
                          type="text"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-3">
                <a
                  class="btn btn-primary btn-lightgreen"
                  onClick={UpdateCampaignDetails}
                >
                  <i class="la la-save"></i>Save
                </a>
                <a class="btn btn-secondary" onClick={BackCampaign}>
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              className="tagpaddnone bg-white inpt-0"
              index={1}
            >
              <div class="paddcols px-3 pt-0">
                <div className="row borderbtnm">
                  <div className="col pl-0">
                    <h4 className="headertitlepad float-left mb-0">
                      Email Accounts
                    </h4>
                  </div>
                  <div class="col pr-0">
                    <div class="mt-3 listing-li float-right padb-15">
                      <ul className="mt-0">
                        <li onClick={ConfigEmailAccount}>
                          <a className="btn btngroup m-btn">
                            <i class="flaticon-settings"></i>
                            <span>Configure Email Accounts</span>
                          </a>
                          <Popup open={ConfigEmailPopup}>
                            <div>
                              <div className="modal-black"></div>
                              <div className="filterPopup maxpopupfull">
                                <div className="paddingboxTerms">
                                  <div className="modal-header py-4 px-3">
                                    <div className="w-100 d-flex px-3">
                                      <h5 className="mb-0 mt-2 modal-title">
                                        Configure Email Accounts
                                      </h5>
                                      <button
                                        className="close"
                                        onClick={ConfigEmailAccount}
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="la la-remove"
                                        ></span>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="modal-body">
                                    <div className="px-4">
                                      <div className="row">
                                        <div className="col">
                                          <ConfigureEmailAccounts
                                            CCampaignID={Campaign}
                                            CUserID={UserID}
                                            CClientID={ClientID}
                                            updatefromsteptwo={
                                              UpdateFromStepTwo
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popup>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col px-0">
                    <EditAccountTable
                      CCampaignID={Campaign}
                      ListUpdate={ConfigEmailPopup}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              className="tagpaddnone bg-white inpt-0"
              index={2}
            >
              <div class="paddcols px-3 pt-2">
                <div className="row borderbtnm">
                  <div className="col-4 pl-0">
                    <h4 className="headertitlepad float-left py-4 mb-0">
                      Contacts
                    </h4>

                  </div>
                  <div class="col-8 padright pr-0 pt-0">
                    <div class="listing-li float-right padb-15">
                      <ul>
                        <li>
                        <Tooltip title="Configure"> 
                         <a
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                            onClick={ConfigPopHandle}
                          >
                            <i class="flaticon-settings"></i>
                          </a> 
                          </Tooltip>
                          <Popup open={ConfigPop}>
                            <div>
                              <div className="modal-black"></div>
                              <div className="filterPopup largerPopup1000 mt-5">
                                <div className="paddingboxTerms">
                                  <div className="modal-header py-4 px-3">
                                    <div className="w-100 d-flex px-3">
                                      <h5 className="mb-0">
                                        Configure Contacts
                                      </h5>
                                      <button
                                        className="close"
                                        onClick={ConfigPopHandle}
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="la la-remove"
                                        ></span>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="modal-body">
                                    <div className="px-4">
                                      <div className="row">
                                        <div className="col-xl-3 aligncenter">
                                          <div className="iconer mb-3">
                                            <i className="flaticon-file-1 clr-purple"></i>
                                          </div>
                                          <a
                                            class="btn btn-primary btngroup mb-3"
                                            onClick={() =>
                                              SetShowImportContactCsv(true)
                                            }
                                          >
                                            Import from CSV
                                          </a>
                                          <Popup open={ShowImportContactCsv}>
                                            <div>
                                              <div className="modal-black"></div>
                                              <div className="filterPopup largerPopup1000 mt-5">
                                                <div className="paddingboxTerms">
                                                  <div className="modal-header py-4 px-3">
                                                    <div className="w-100 d-flex px-3">
                                                      <h5 className="mb-0">
                                                        Import Contacts{" "}
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        onClick={() =>
                                                          SetShowImportContactCsv(
                                                            false
                                                          )
                                                        }
                                                      >
                                                        <span
                                                          aria-hidden="true"
                                                          class="la la-remove"
                                                        ></span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="modal-body">
                                                    <div className="px-4">
                                                      <div className="row mt-5 mb-3">
                                                        <div className="col">
                                                          <h3 className="float-left addpotential_header">
                                                            Import Contact
                                                            Details
                                                          </h3>
                                                        </div>
                                                      </div>

                                                      <div className="row">
                                                        <div className="col">
                                                          <span className="floatleft">
                                                            <i class="la la-info-circle"></i>{" "}
                                                            A sample file is
                                                            available for
                                                            download,{" "}
                                                            <CSVDownloader
                                                              data={
                                                                ContactSampleExport
                                                              }
                                                              filename={
                                                                "Sample"
                                                              }
                                                              bom={true}
                                                            >
                                                              <a herf="">
                                                                please click
                                                                here{" "}
                                                              </a>
                                                            </CSVDownloader>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="portletbody"
                                                        id="import_csv"
                                                      >
                                                        <div className="row">
                                                          <div className="col-xl-8 offset-xl-2">
                                                            <h3 className="uploadheadingtitle">
                                                              Import Contacts
                                                              Csv
                                                            </h3>
                                                            <div className="uplodfilesbox">
                                                              <CSVReader
                                                                accept={[
                                                                  "text/csv",
                                                                  ".csv",
                                                                  "application/vnd.ms-excel",
                                                                ]}
                                                                onDrop={
                                                                  HandleOnDrop
                                                                }
                                                                onError={
                                                                  HandleOnError
                                                                }
                                                                addRemoveButton
                                                                removeButtonColor="#659cef"
                                                                onRemoveFile={
                                                                  HandleOnRemoveFile
                                                                }
                                                                isReset={Reset}
                                                              >
                                                                <span>
                                                                  Select .CSV
                                                                  file
                                                                </span>
                                                                <p>
                                                                  Add contacts
                                                                  data from csv
                                                                  file to
                                                                  import.
                                                                </p>
                                                              </CSVReader>
                                                            </div>
                                                          </div>
                                                          <div className="col-xl-8 offset-xl-2">
                                                            <div className="alignright">
                                                              <a
                                                                onClick={async () => {
                                                                  await SetReset(
                                                                    true
                                                                  );

                                                                  ResetCsv();
                                                                  ContactMapCsv();
                                                                }}
                                                                className="btn btn-primary btnColor"
                                                              >
                                                                <i className="la la-upload"></i>{" "}
                                                                Upload
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      {/* Mapped div */}
                                                      <div
                                                        className="portletbody portletshadow"
                                                        id="map_attribute"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                      >
                                                        <div className="row col-xs-12">
                                                          <h4 className="headertitle float-left">
                                                            Map attributes
                                                          </h4>
                                                        </div>
                                                        <div
                                                          className="col-xs-12"
                                                          id="divContactSelection"
                                                        >
                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn1"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="FirstName"
                                                                  >
                                                                    First Name
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="FirstName"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    FirstName ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "First Name"
                                                                        )
                                                                      : FirstName
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn2"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="LastName"
                                                                  >
                                                                    Last Name
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="LastName"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    LastName ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Last Name"
                                                                        )
                                                                      : LastName
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn3"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Email"
                                                                  >
                                                                    Email
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Email"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Email ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Email"
                                                                        )
                                                                      : Email
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn4"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Title"
                                                                  >
                                                                    Title
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Title"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Title ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Title"
                                                                        )
                                                                      : Title
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn5"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Company"
                                                                  >
                                                                    Company
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Company"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Company ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Company"
                                                                        )
                                                                      : Company
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>

                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn6"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="CompanyNameLong"
                                                                  >
                                                                    Company Name
                                                                    Long
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="CompanyNameLong"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    CompanyNameLong ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Company Name Long"
                                                                        )
                                                                      : CompanyNameLong
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn11"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Website"
                                                                  >
                                                                    Website
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Website"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Website ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Website"
                                                                        )
                                                                      : Website
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn6"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="CompanyPhone"
                                                                  >
                                                                    Company
                                                                    Phone
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="CompanyPhone"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    CompanyPhone ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Company Phone"
                                                                        )
                                                                      : CompanyPhone
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn22"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="DirectPhone"
                                                                  >
                                                                    Direct Phone
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="DirectPhone"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    DirectPhone ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Direct Phone"
                                                                        )
                                                                      : DirectPhone
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="DirectPhoneExt"
                                                                  >
                                                                    Direct Phone
                                                                    Ext
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="DirectPhoneExt"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    DirectPhoneExt ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Direct Phone Ext"
                                                                        )
                                                                      : DirectPhoneExt
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn14"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="LinkedInURL"
                                                                  >
                                                                    LinkedIn URL
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="LinkedInURL"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    LinkedInURL ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "LinkedIn URL"
                                                                        )
                                                                      : LinkedInURL
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>

                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn18"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Industry"
                                                                  >
                                                                    Industry
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Industry"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Industry ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Industry"
                                                                        )
                                                                      : Industry
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn12"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Address1"
                                                                  >
                                                                    Address 1
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Address1"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Address1 ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Address 1"
                                                                        )
                                                                      : Address1
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn13"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Address2"
                                                                  >
                                                                    Address 2
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Address2"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Address2 ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Address 2"
                                                                        )
                                                                      : Address2
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn7"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="City"
                                                                  >
                                                                    City
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="City"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    City == null
                                                                      ? DropboxData.indexOf(
                                                                          "City"
                                                                        )
                                                                      : City
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn8"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="State"
                                                                  >
                                                                    State
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="State"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    State ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "State"
                                                                        )
                                                                      : State
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn15"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Zip"
                                                                  >
                                                                    Zip
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Zip"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Zip == null
                                                                      ? DropboxData.indexOf(
                                                                          "Zip"
                                                                        )
                                                                      : Zip
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn16"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Country"
                                                                  >
                                                                    Country
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Country"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Country ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Country"
                                                                        )
                                                                      : Country
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn9"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ContactCategory"
                                                                  >
                                                                    Contact
                                                                    Category
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ContactCategory"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ContactCategory ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Contact Category"
                                                                        )
                                                                      : ContactCategory
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn10"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="AccountCategory"
                                                                  >
                                                                    Company
                                                                    Category
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="AccountCategory"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    AccountCategory ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Company Category"
                                                                        )
                                                                      : AccountCategory
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn17"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ValidationScore"
                                                                  >
                                                                    Validation
                                                                    Score
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ValidationScore"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ValidationScore ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Validation Score"
                                                                        )
                                                                      : ValidationScore
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>

                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn17"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ListName"
                                                                  >
                                                                    List Name
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <input
                                                                  type="text"
                                                                  id="ListName"
                                                                  className="form-control  m-input"
                                                                  value={
                                                                    filename
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ContactSource"
                                                                  >
                                                                    Contact
                                                                    Source
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ContactSource"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ContactSource ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Contact Source"
                                                                        )
                                                                      : ContactSource
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn24"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ContactOwner"
                                                                  >
                                                                    Contact
                                                                    Owner
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ContactOwner"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ContactOwner ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Contact Owner"
                                                                        )
                                                                      : ContactOwner
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ClientPOC"
                                                                  >
                                                                    Client POC
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ClientPOC"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ClientPOC ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Client POC"
                                                                        )
                                                                      : ClientPOC
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn24"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ReasonUnqualified"
                                                                  >
                                                                    Reason
                                                                    Unqualified
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ReasonUnqualified"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ReasonUnqualified ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Reason Unqualified"
                                                                        )
                                                                      : ReasonUnqualified
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="AccountType"
                                                                  >
                                                                    Account Type
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="AccountType"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    AccountType ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Account Type"
                                                                        )
                                                                      : AccountType
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn19"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="CompanyRevenue"
                                                                  >
                                                                    Company
                                                                    Revenue
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="CompanyRevenue"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    CompanyRevenue ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Company Revenue"
                                                                        )
                                                                      : CompanyRevenue
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn20"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="EmployeeCount"
                                                                  >
                                                                    Employee
                                                                    Count
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="EmployeeCount"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    EmployeeCount ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Employee Count"
                                                                        )
                                                                      : EmployeeCount
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn24"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="MobilePhone"
                                                                  >
                                                                    Mobile Phone
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="MobilePhone"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    MobilePhone ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Mobile Phone"
                                                                        )
                                                                      : MobilePhone
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="IsDoNotCallContact"
                                                                  >
                                                                    Do not call
                                                                    contact
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="IsDoNotCallContact"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    IsDoNotCallContact ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Do Not Call Contact"
                                                                        )
                                                                      : IsDoNotCallContact
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>

                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn24"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="SalesRep"
                                                                  >
                                                                    Sales Rep
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="SalesRep"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    SalesRep ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Sales Rep"
                                                                        )
                                                                      : SalesRep
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ColdCaller"
                                                                  >
                                                                    Sales
                                                                    Development
                                                                    Reps
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ColdCaller"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ColdCaller ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Sales Development Reps"
                                                                        )
                                                                      : ColdCaller
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>

                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn24"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Tag"
                                                                  >
                                                                    Tag
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="Tag"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    Tag == null
                                                                      ? DropboxData.indexOf(
                                                                          "Tag"
                                                                        )
                                                                      : Tag
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="CallNotes"
                                                                  >
                                                                    Call Notes
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="CallNotes"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    CallNotes ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Call Notes"
                                                                        )
                                                                      : CallNotes
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>

                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn24"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="OtherPhone1"
                                                                  >
                                                                    OtherPhone 1
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="OtherPhone1"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    OtherPhone1 ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Other Phone 1"
                                                                        )
                                                                      : OtherPhone1
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="form-group m-form__group d-flex">
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn24"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="Other Phone 2"
                                                                  >
                                                                    OtherPhone 2
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x"></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="OtherPhone2"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    OtherPhone2 ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "Other Phone 2"
                                                                        )
                                                                      : OtherPhone2
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-2"></div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  className="dbcontent form-control  m-input"
                                                                  id="dbColumn23"
                                                                  disabled="disabled"
                                                                >
                                                                  <option
                                                                    selected=""
                                                                    value="ContactID"
                                                                  >
                                                                    ContactID
                                                                  </option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-1 text-center">
                                                              <span className="fa fa-arrows-h fa-2x "></span>
                                                            </div>
                                                            <div className="col-lg-2 nopadding">
                                                              <div>
                                                                <select
                                                                  id="ContactID"
                                                                  className="csvcontent form-control  m-input"
                                                                  autocomplete="off"
                                                                  value={
                                                                    ContactID ==
                                                                    null
                                                                      ? DropboxData.indexOf(
                                                                          "ContactID"
                                                                        )
                                                                      : ContactID
                                                                  }
                                                                >
                                                                  <option>
                                                                    Not Mapped
                                                                  </option>
                                                                  {DropboxData.map(
                                                                    (
                                                                      dbdata,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          index
                                                                        }
                                                                      >
                                                                        {dbdata}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="row d-flex">
                                                            <div className="col-lg-6">
                                                              <div class="form-group m-form__group row">
                                                                <div class="toggleswich">
                                                                  <input
                                                                    type="checkbox"
                                                                    class="checktogle"
                                                                    id="IsUpdateExistContacts"
                                                                  />
                                                                  <b class="switch">
                                                                    <span class="checkion"></span>
                                                                    <span class="uncheckion"></span>
                                                                  </b>
                                                                  <b class="track"></b>
                                                                </div>
                                                                <label>
                                                                  {" "}
                                                                  Skip updating
                                                                  existing
                                                                  contacts
                                                                </label>
                                                              </div>
                                                              <div class="form-group m-form__group row">
                                                                <div class="toggleswich">
                                                                  <input
                                                                    type="checkbox"
                                                                    class="checktogle"
                                                                    id="IsInsertNullValues"
                                                                  />
                                                                  <b class="switch">
                                                                    <span class="checkion"></span>
                                                                    <span class="uncheckion"></span>
                                                                  </b>
                                                                  <b class="track"></b>
                                                                </div>
                                                                <label>
                                                                  Insert Null
                                                                  Values
                                                                </label>
                                                              </div>
                                                              <div class="form-group m-form__group row">
                                                                <div class="toggleswich">
                                                                  <input
                                                                    type="checkbox"
                                                                    class="checktogle"
                                                                    id="IsDoNotupdatecompanyname"
                                                                  />
                                                                  <b class="switch">
                                                                    <span class="checkion"></span>
                                                                    <span class="uncheckion"></span>
                                                                  </b>
                                                                  <b class="track"></b>
                                                                </div>
                                                                <label>
                                                                  Do Not Update
                                                                  Company Name
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                              <div class="form-group m-form__group row">
                                                                <div class="toggleswich">
                                                                  <input
                                                                    type="checkbox"
                                                                    class="checktogle"
                                                                    id="IsMoveExitsingcurrentcampaign"
                                                                  />
                                                                  <b class="switch">
                                                                    <span class="checkion"></span>
                                                                    <span class="uncheckion"></span>
                                                                  </b>
                                                                  <b class="track"></b>
                                                                </div>
                                                                <label>
                                                                  Move existing
                                                                  contacts to
                                                                  current
                                                                  Campaign
                                                                </label>
                                                              </div>
                                                              <div class="form-group m-form__group row">
                                                                <div class="toggleswich">
                                                                  <input
                                                                    type="checkbox"
                                                                    class="checktogle"
                                                                    id="IsInsertContactAlreadyReplaied"
                                                                  />
                                                                  <b class="switch">
                                                                    <span class="checkion"></span>
                                                                    <span class="uncheckion"></span>
                                                                  </b>
                                                                  <b class="track"></b>
                                                                </div>
                                                                <label>
                                                                  {" "}
                                                                  Insert
                                                                  Contacts That
                                                                  Have Already
                                                                  Replied
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-12 d-flex">
                                                            <button
                                                              onClick={
                                                                ContactSaveCsv
                                                              }
                                                              className="btn btn-primary btn-lightgreen px-0 my-3"
                                                              disabled={
                                                                csvData.length ===
                                                                0
                                                              }
                                                            >
                                                              <i className="la la-save"></i>
                                                              Save
                                                            </button>
                                                          </div>
                                                          {/* <div className="col-lg-12 d-flex">
                                                            <a
                                                              onClick={
                                                                ContactSaveCsv
                                                              }
                                                              className="btn btn-primary btn-lightgreen px-0 my-3"
                                                            >
                                                              <i className="la la-save"></i>
                                                              Save
                                                            </a>
                                                          </div> */}
                                                        </div>
                                                      </div>

                                                      <div class="modal-footer px-0 my-3">
                                                        <button
                                                          type="button"
                                                          class="btn btn-secondary"
                                                          data-dismiss="modal"
                                                          onClick={() =>
                                                            HandleOkButton()
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                        <a
                                                          id="lnkCreateCampaignFromTemplate"
                                                          onClick={() =>
                                                            HandleOkButton()
                                                          }
                                                          class="btn btn-primary btn-lightgreen"
                                                        >
                                                          <i class="la la-save"></i>{" "}
                                                          OK
                                                        </a>
                                                      </div>

                                                      <div
                                                        className="row"
                                                        id="total-div-display"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <div className="row mb-3">
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Total
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {Total}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Success
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalSucess
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Error
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalError
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Skipped
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalSkipped
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Updated
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalUpdated
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Contact
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalContactBlacklisted
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Domain
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalDomainBlackListed
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Removed
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalRemoved
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Global
                                                                        Domain
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalGlobalDomainBlackListed
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Default
                                                                        Country
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalDefaultCountryBlackListed
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Country
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalCountryBlackListed
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Replied
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalReplied
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Email
                                                                        Validation
                                                                        D
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalEmailValiDationScoreD
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Email
                                                                        Validation
                                                                        F
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalEmailValiDationScoreF
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        id="csv_error_table"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                      >
                                                        <div className="row">
                                                          <h4>
                                                            Csv Error Details
                                                          </h4>
                                                        </div>

                                                        {/* table use */}
                                                        <div className="row padt-25">
                                                          <div className="col-sm-12 col-md-6">
                                                            <label className="textlabelte pull-left mt-2">
                                                              Show
                                                              <select
                                                                name="tbl_meeting_length"
                                                                onChange={
                                                                  ChangeRowSelected
                                                                }
                                                                aria-controls="tbl_meeting"
                                                                class="form-control form-control-sm"
                                                              >
                                                                {CommonConstants.show_rows.map(
                                                                  (value) => (
                                                                    <option
                                                                      value={
                                                                        value
                                                                      }
                                                                    >
                                                                      {value}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                              entries
                                                            </label>
                                                          </div>
                                                          <div className="col-sm-12 col-md-6 full-right">
                                                            <label class="pull-left mt-2">
                                                              Search:{" "}
                                                              <input
                                                                type="search"
                                                                id="search"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleSearch(
                                                                    e
                                                                  );
                                                                }}
                                                                class="form-control form-control-sm ml-2"
                                                                placeholder=""
                                                                aria-controls="tbl_meeting"
                                                              />
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div className="table-bordered">
                                                          <TableContainer
                                                            component={Paper}
                                                          >
                                                            <Table
                                                              stickyHeader
                                                              aria-label="sticky table"
                                                            >
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "ContactStatus"
                                                                      );
                                                                    }}
                                                                  >
                                                                    {/* <a
                    onClick={() => {
                      handleSort("ContactStatus");
                    }}
                  > */}
                                                                    Status
                                                                    {/* </a> */}
                                                                  </TableCell>
                                                                  <TableCell
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "FirstName"
                                                                      );
                                                                    }}
                                                                  >
                                                                    {/* <a
                    onClick={() => {
                      handleSort("FirstName");
                    }}
                  > */}
                                                                    FirstName
                                                                    {/* </a> */}
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    <a
                                                                      onClick={() => {
                                                                        handleSort(
                                                                          "LastName"
                                                                        );
                                                                      }}
                                                                    >
                                                                      LastName
                                                                    </a>
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    <a
                                                                      onClick={() => {
                                                                        handleSort(
                                                                          "Email"
                                                                        );
                                                                      }}
                                                                    >
                                                                      Email
                                                                    </a>
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    <a
                                                                      onClick={() => {
                                                                        handleSort(
                                                                          "Company"
                                                                        );
                                                                      }}
                                                                    >
                                                                      Company
                                                                    </a>
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    <a
                                                                      onClick={() => {
                                                                        handleSort(
                                                                          "City"
                                                                        );
                                                                      }}
                                                                    >
                                                                      City
                                                                    </a>
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    <a
                                                                      onClick={() => {
                                                                        handleSort(
                                                                          "City"
                                                                        );
                                                                      }}
                                                                    >
                                                                      State
                                                                    </a>
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    <a
                                                                      onClick={() => {
                                                                        handleSort(
                                                                          "Country"
                                                                        );
                                                                      }}
                                                                    >
                                                                      Country
                                                                    </a>
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    <a
                                                                      onClick={() => {
                                                                        handleSort(
                                                                          "Title"
                                                                        );
                                                                      }}
                                                                    >
                                                                      Title
                                                                    </a>
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody>
                                                                {currentItems?.length ===
                                                                0 ? (
                                                                  <p className="text-center">
                                                                    No data
                                                                    available in
                                                                    table
                                                                  </p>
                                                                ) : (
                                                                  currentItems?.map(
                                                                    (row) => (
                                                                      <TableRow>
                                                                        <TableCell
                                                                          style={{
                                                                            color:
                                                                              "white",
                                                                            letterSpacing:
                                                                              ".6px",
                                                                            padding:
                                                                              "1px 10px",
                                                                            borderRadius:
                                                                              "0.75rem",
                                                                            fontSize:
                                                                              ".7rem",
                                                                            textAlign:
                                                                              "center",
                                                                            backgroundColor:
                                                                              row.ContactStatus ===
                                                                                "SUCCESS" ||
                                                                              row.ContactStatus ===
                                                                                "UPDATED"
                                                                                ? "#3DAF8F"
                                                                                : "#F75666",
                                                                          }}
                                                                        >
                                                                          {
                                                                            row.ContactStatus
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.FirstName
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.LastName
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.Email
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.Company
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.City
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.State
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.Country
                                                                          }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            row.Title
                                                                          }
                                                                        </TableCell>
                                                                      </TableRow>
                                                                    )
                                                                  )
                                                                )}
                                                              </TableBody>

                                                              <div className="row"></div>
                                                            </Table>
                                                          </TableContainer>
                                                        </div>
                                                        {Sflag ? (
                                                          <div class="row">
                                                            <Pagination
                                                              component="div"
                                                              count={CountPage}
                                                              page={CSVEPage}
                                                              onChange={
                                                                handlePagination
                                                              }
                                                              showFirstButton
                                                              showLastButton
                                                            />
                                                            <div class="col dataTables_info">
                                                              <p>
                                                                Showing{" "}
                                                                {Rlen == 0
                                                                  ? 0
                                                                  : (CSVEPage -
                                                                      1) *
                                                                      CSVERowsPerPage +
                                                                    1}{" "}
                                                                to{" "}
                                                                {CSVEPage *
                                                                  CSVERowsPerPage >
                                                                Rlen
                                                                  ? Rlen
                                                                  : CSVEPage *
                                                                    CSVERowsPerPage}{" "}
                                                                of {Rlen}{" "}
                                                                entries
                                                                (filtered from{" "}
                                                                {Flen} total
                                                                entries)
                                                              </p>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div class="row">
                                                            <div class="col dataTables_info">
                                                              <p>
                                                                Showing{" "}
                                                                {Rlen == 0
                                                                  ? 0
                                                                  : (CSVEPage -
                                                                      1) *
                                                                      CSVERowsPerPage +
                                                                    1}{" "}
                                                                to{" "}
                                                                {CSVEPage *
                                                                  CSVERowsPerPage >
                                                                Rlen
                                                                  ? Rlen
                                                                  : CSVEPage *
                                                                    CSVERowsPerPage}{" "}
                                                                of {Rlen}{" "}
                                                                entries
                                                              </p>
                                                            </div>
                                                            <div class="col pageright">
                                                              <Pagination
                                                                component="div"
                                                                count={
                                                                  CountPage
                                                                }
                                                                page={CSVEPage}
                                                                onChange={
                                                                  handlePagination
                                                                }
                                                                showFirstButton
                                                                showLastButton
                                                              />
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Popup>
                                          <p>
                                            Add contacts from CSV file to
                                            contact list via bulk upload.
                                          </p>
                                        </div>

                                        <div className="col-xl-3 aligncenter">
                                          <div className="iconer mb-3">
                                            <i className="flaticon-users clr-purple"></i>
                                          </div>
                                          <a
                                            onClick={() => {
                                              SetContactExitsData(true);
                                            }}
                                            class="btn btn-primary btngroup mb-3"
                                          >
                                            Add from existing
                                          </a>
                                          <Popup open={ContactExitsData}>
                                            <div>
                                              <div className="modal-black"></div>
                                              <div className="filterPopup largerPopup1000 mt-5">
                                                <div className="paddingboxTerms">
                                                  <div className="modal-header py-4 px-3">
                                                    <div className="w-100 d-flex px-3">
                                                      <h5 className="mb-0">
                                                        Add Contacts to campaign{" "}
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        onClick={() => {
                                                          SetContactExitsData(
                                                            false
                                                          );
                                                          SetConfigPop(false);
                                                        }}
                                                      >
                                                        <span
                                                          aria-hidden="true"
                                                          class="la la-remove"
                                                        ></span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="modal-body">
                                                    <div className="px-4">
                                                      <div className="row">
                                                        <div className="col">
                                                          <ContactTableEdit
                                                            CampaignID={
                                                              Campaign
                                                            }
                                                            updateFromChild={
                                                              UpdateFromChild
                                                            }
                                                            onDataFromContact={
                                                              handleDataFromChildContactExits
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Popup>
                                          <p>
                                            Add contacts from the contact list
                                            Only contacts that are not currently
                                            in your other campaigns can be
                                            added.{" "}
                                          </p>
                                        </div>

                                        <div className="col-xl-3 aligncenter">
                                          <div className="iconer mb-3">
                                            <i className="flaticon-users clr-purple"></i>
                                          </div>
                                          <a
                                            onClick={() => {
                                              SetListPopUp(true);
                                            }}
                                            class="btn btn-primary btngroup mb-3"
                                          >
                                            Add from list
                                          </a>

                                          <Popup open={ListPopup}>
                                            <div>
                                              <div className="modal-black"></div>
                                              <div className="filterPopup largerPopup1000">
                                                <div className="paddingboxTerms">
                                                  <div className="modal-header py-4 px-3">
                                                    <div className="w-100 d-flex px-3">
                                                      <h5 className="mb-0">
                                                        Add List to campaign{" "}
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        onClick={() => {
                                                          SetListPopUp(false);
                                                          SetConfigPop(false);
                                                        }}
                                                      >
                                                        <span
                                                          aria-hidden="true"
                                                          class="la la-remove"
                                                        ></span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="modal-body">
                                                    <div className="px-4"> 
                                                      <div className="row">
                                                        <div className="col"> 
                                                            <AddListTable
                                                              handClose={close}
                                                              CampaignId={
                                                                campaignID
                                                              }
                                                              updateFromChild={
                                                                UpdateFromChild
                                                              }
                                                              onDataFromContact={
                                                                handleDataFromChildContactExits
                                                              }
                                                            /> 
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Popup>
                                          <p>
                                            Add contacts from the list Only
                                            contacts that are not currently in
                                            your other campaigns can be added.
                                          </p>
                                        </div>

                                        <div className="col-xl-3 aligncenter">
                                          <div className="iconer mb-3">
                                            <i className="flaticon-user-add clr-purple"></i>
                                          </div>
                                          <a
                                            class="btn btn-primary btngroup mb-3"
                                            onClick={CreateManuallyPopHandle}
                                          >
                                            Create manually
                                          </a>
                                          <Popup open={CreateManuallyPop}>
                                            <div>
                                              <div className="modal-black"></div>
                                              <div className="filterPopup largerPopup1000">
                                                <div className="paddingboxTerms">
                                                  <div className="modal-header py-4 px-3">
                                                    <div className="w-100 d-flex px-3">
                                                      <h5 className="mb-0">
                                                        Add Contacts
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        onClick={
                                                          CreateManuallyPopHandle
                                                        }
                                                      >
                                                        <span
                                                          aria-hidden="true"
                                                          class="la la-remove"
                                                        ></span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="modal-body">
                                                    <div className="px-4">
                                                      <div className="row">
                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              First Name
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="FirstName"
                                                                name="FirstName"
                                                                placeholder="First name"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Last Name
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="LastName"
                                                                name="FirstName"
                                                                placeholder="Last Name"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Email
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Email"
                                                                name="Email"
                                                                placeholder="Email"
                                                                type="email"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Title
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Title"
                                                                name="Title"
                                                                placeholder="Title"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Company
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Company"
                                                                name=""
                                                                placeholder="Company"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Company Name Long
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="CompanyNameLong"
                                                                name="Company Name Long"
                                                                placeholder="Company Name Long"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Website
                                                            </label>
                                                            <div className="col-lg-8">
                                                            <input
  className="form-control m-input"
  id="Website"
  placeholder="Website"
  type="text"
  onBlur={handleBlurWebsite}
/>
<span style={{ color: "red" }}>{errors && errors["Website"]}</span>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Company Phone
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="CompanyPhone"
                                                                placeholder="Company Phone"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Direct Phone
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="DirectPhone"
                                                                placeholder="Direct Phone"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Direct Phone Ext
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="DirectPhoneExt"
                                                                placeholder="Direct Phone Ext"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              LinkedIn URL
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="LinkedInURL"
                                                                placeholder="LinkedIn URL"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Mobile Phone
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="MobilePhone"
                                                                placeholder="Mobile Phone"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Industry
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Industry"
                                                                placeholder="Industry"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Address 1{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Address1"
                                                                placeholder="Address 1"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Address 2
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Address2"
                                                                placeholder="Address 2"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              City{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="City"
                                                                placeholder="City"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              State
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="State"
                                                                placeholder="State"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Zip{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Zip"
                                                                placeholder="Zip"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Country
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="Country"
                                                                placeholder="Country"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Contact Category{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="ContactCategory"
                                                                placeholder="Contact Category"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Account Category
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="AccountCategory"
                                                                placeholder="Account Category"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Contact Source{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <select
                                                                className="form-control  m-input"
                                                                name="ContactSource"
                                                                id="ContactSourceID"

                                                                // onChange={this.handleChange.bind(
                                                                //   this,
                                                                //   "ContactSourceID"
                                                                // )}
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                                </option>
                                                                {ContactSourceData.map(
                                                                  (value) => (
                                                                    <option
                                                                      value={
                                                                        value._id
                                                                      }
                                                                    >
                                                                      {
                                                                        value.Name
                                                                      }
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                              <span
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                {
                                                                  ContactManuallyError[
                                                                    "ContactSourceID"
                                                                  ]
                                                                }
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Contact Owner
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <select
                                                                className="form-control  m-input"
                                                                name="ContactOwner"
                                                                id="ContactOwnerID"
                                                                // onChange={this.handleChange.bind(
                                                                //   this,
                                                                //   "ContactOwnerID"
                                                                // )}
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                                </option>
                                                                {ContactOwnerData.map(
                                                                  (value) => (
                                                                    <option
                                                                      value={
                                                                        value._id
                                                                      }
                                                                    >
                                                                      {
                                                                        value.Name
                                                                      }
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                              <span
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                {
                                                                  ContactManuallyError[
                                                                    "ContactOwnerID"
                                                                  ]
                                                                }
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Client POC{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <select
                                                                className="form-control  m-input"
                                                                name="Client POC"
                                                                id="ClientPOCID"
                                                                //onChange={this.handleChange.bind(this, "ClientPOCID")}
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                                </option>
                                                                {ClientPOCData.map(
                                                                  (value) => (
                                                                    <option
                                                                      value={
                                                                        value._id
                                                                      }
                                                                    >
                                                                      {
                                                                        value.Name
                                                                      }
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                              <span
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                {
                                                                  ContactManuallyError[
                                                                    "ClientPOCID"
                                                                  ]
                                                                }
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Reason Unqualified
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <select
                                                                className="form-control  m-input"
                                                                name="ReasonUnqualified"
                                                                id="ReasonUnqualifiedID"
                                                                // onChange={this.handleChange.bind(
                                                                //   this,
                                                                //   "ReasonUnqualifiedID"
                                                                // )}
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                                </option>
                                                                {ReasonUnqualifiedData.map(
                                                                  (value) => (
                                                                    <option
                                                                      value={
                                                                        value._id
                                                                      }
                                                                    >
                                                                      {
                                                                        value.Reason
                                                                      }
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                              <span
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                {
                                                                  ContactManuallyError[
                                                                    "ReasonUnqualifiedID"
                                                                  ]
                                                                }
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Company Revenue{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="CompanyRevenue"
                                                                placeholder="Company Revenue"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Employee Count
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="CompanyEmployeeCount"
                                                                placeholder="Employee Count"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Sales Development
                                                              Reps{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <select
                                                                className="form-control  m-input"
                                                                name="ColdCallersID"
                                                                id="ColdCallersID"
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                                </option>
                                                                <option value="">
                                                                  --Select--
                                                                </option>
                                                                {ContactSalesDevlopMenData.map(
                                                                  (value) => (
                                                                    <option
                                                                      value={
                                                                        value._id
                                                                      }
                                                                    >
                                                                      {
                                                                        value.ColdCallersName
                                                                      }
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              SalesRep
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                class="form-control m-input"
                                                                id="SalesRep"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Tag{" "}
                                                            </label>
                                                            <div className="col-lg-8">
                                                              {/* <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                hideSelectedOptions={
                                                                  false
                                                                }
                                                                options={
                                                                  ContactTagData
                                                                }
                                                                id="ContactTagID"
                                                                isMulti
                                                                onChange={(
                                                                  val
                                                                ) =>
                                                                  handleChangeContactOption(
                                                                    val
                                                                  )
                                                                }
                                                              /> */}
                                                              <CreatableSelect
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      options={ContactTagData}
      id="ContactTagID"
      isMulti
      onChange={handleChangeContactOption}
    />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Other Phone 1
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="OtherPhone1"
                                                                placeholder="Other Phone 1"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              Other Phone 2
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="OtherPhone2"
                                                                placeholder="Other Phone 2"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              External Account
                                                              ID
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="ExternalAccountID"
                                                                placeholder="External Account ID"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              External Contact
                                                              ID
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="ExternalContactID"
                                                                placeholder="External Contact ID"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              External Field 1
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="ExternalField1"
                                                                placeholder="External Field 1"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              External Field 2
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="ExternalField2"
                                                                placeholder="External Field 2"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6 boxsinput_group">
                                                          <div className="row">
                                                            <label className="col-lg-4 pt-2">
                                                              External Field 3
                                                            </label>
                                                            <div className="col-lg-8">
                                                              <input
                                                                className="form-control m-input"
                                                                id="ExternalField3"
                                                                placeholder="External Field 3"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-lg-12 boxsinput_group">
                                                          <div className="row">
                                                            <label class="check_bx">
                                                              <input
                                                                type="checkbox"
                                                                id="IsDoNotCallContact"
                                                              />
                                                              <span className="checkmark"></span>
                                                              Do Not Call
                                                              Contact
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div class="modal-footer px-0">
                                                        <button
                                                          type="button"
                                                          class="btn btn-secondary"
                                                          data-dismiss="modal"
                                                          onClick={
                                                            CreateManuallyPopHandle
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                        <button
                                                          id="SubmitContactManually"
                                                          onClick={(e) => {
                                                            AddContactManually(
                                                              e
                                                            );
                                                          }}
                                                          disabled={
                                                            MannuallyDisables
                                                          }
                                                          class="btn btn-primary btn-lightgreen"
                                                        >
                                                          <i class="la la-save"></i>{" "}
                                                          Save
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Popup>
                                          <p>
                                            Add contacts to contact list
                                            manually one by one.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Popup open={ShowPopupImport}>
                                    <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-4 px-3">
                                            <div className="w-100 d-flex px-3">
                                              <h5 className="mb-0">Email</h5>
                                              <button
                                                className="close"
                                                onClick={() =>
                                                  SetShowPopupImport(false)
                                                }
                                              >
                                                <span
                                                  aria-hidden="true"
                                                  class="la la-remove"
                                                ></span>
                                              </button>
                                            </div>
                                          </div>
                                          <div className="p-3">
                                            <div className="modal-body">
                                              <div className="col-sm-6 mb-5 px-0">
                                                <div class="row mb-3">
                                                  <label class="col-lg-5 col-form-label text-left">
                                                    Email
                                                  </label>
                                                  <div class="col-lg-7">
                                                    <input
                                                      class="form-control m-input"
                                                      id="UserEmail"
                                                      data-val="true"
                                                      data-val-remote="Title already exist."
                                                      data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                      data-val-required="Please enter Email "
                                                      name="Username"
                                                      defaultValue={UserEmail}
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="border-top modal-content text-right">
                                              <div class="row">
                                                <div class="col-lg-12 pull-right">
                                                  <a
                                                    id="backtolist"
                                                    class="btn btn-secondary mr-2"
                                                    onClick={() =>
                                                      SetShowPopupImport(false)
                                                    }
                                                  >
                                                    cancel
                                                  </a>
                                                  <a
                                                    id="submit"
                                                    onClick={() =>
                                                      ContactSaveCsvAddBulk()
                                                    }
                                                    class="btn btn-primary btn-lightgreen mr-1"
                                                  >
                                                    Save
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Popup>
                                </div>
                              </div>
                            </div>
                          </Popup>
                        </li>
                        <li
                          id="move_campaign"
                          style={{ display: "none",  width: '170px' }}>
                        <div
                          className="col-lg-12 px-0 select-drop"
                        >
                          <Select
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            options={CampaignDataDrop}
                            id="CampaignMove"
                            placeholder={"Campaign"}
                            onChange={(e) => CampaignMove(e)}
                          />
                        </div>
                        </li>
                        <li id="move_campaign_icon">
                        <Tooltip title="Move">

                          <a
                            onClick={ContactMoveToCampaign}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i class="flaticon-paper-plane la-icon-font-size-13"></i>
                          </a>
                          </Tooltip>
                        </li>
                        <li>
                        <Tooltip title="Delete">
                          <a
                            onClick={DeleteProspectCampaign}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                            >
                            <i class="flaticon-delete-1 la-icon-font-size-13"></i>
                          </a>
                          </Tooltip>
                        </li>
                        <li>
                        <Tooltip title="Export">
                          <a
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={() => {
                              SetShowPopupExport1(true);
                            }}
                          >
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                          </Tooltip>
                        </li>
                        <Popup open={ShowPopupExport1}>
                          <div>
                            <div className="modal-black"></div>
                            <div className="filterPopup largerPopup">
                              <div className="paddingboxTerms">
                                <div className="modal-header py-4 px-3">
                                  <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">Email</h5>
                                    <button
                                      className="close"
                                      onClick={() => SetShowPopupExport1(false)}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className="la la-remove"
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <div className="modal-body">
                                    <div className="col-sm-6 mb-5 px-0">
                                      <div className="row mb-3">
                                        <label className="col-lg-5 col-form-label text-left">
                                          Email
                                        </label>
                                        <div className="col-lg-7">
                                          <input
                                            className="form-control m-input"
                                            id="UsernameExport"
                                            data-val="true"
                                            data-val-remote="Title already exist."
                                            data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                            data-val-required="Please enter Email "
                                            name="UsernameExport"
                                            defaultValue={UserEmail}
                                            onBlur={() => {
                                              HandleChange("UsernameExport");
                                            }}
                                            type="text"
                                          />
                                          <span style={{ color: "red" }}>
                                            {ExportCsvErrors["UsernameExport"]}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border-top modal-content text-right">
                                    <div className="row">
                                      <div className="col-lg-12 pull-right">
                                        <a
                                          id="backtolist"
                                          className="btn btn-secondary mr-2"
                                          onClick={() =>
                                            SetShowPopupExport1(false)
                                          }
                                        >
                                          cancel
                                        </a>
                                        <a
                                          id="submitcsv"
                                          onClick={ExportsToProspectCampaign}
                                          className="btn btn-primary btn-lightgreen mr-1"
                                        >
                                          Save
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popup>
                        <li>
                          
                          <Tooltip title="Validate">
                        <a
                           onClick={ContactValidateEmailCheck} 
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="fa la-icon-font-size-13 fa-check-square-o"></i>
                        </a>
                        </Tooltip>
                        </li>
                        <li>
                        <Tooltip title="Unpause all Paused contacts">
                          <a
                            onClick={ProspectCampaignUnPausedContacts}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i class="fa la-icon-font-size-13 fa-pause"></i>
                          </a>
                          </Tooltip>
                        </li>
                        <li>
                        <Tooltip title="Add Filter">

                          <button
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                            onClick={() => {
                              OpenFilterModel();
                            }}
                          >
                            <i class="fa la-icon-font-size-13 fa-filter"></i>
                          </button>
                          </Tooltip>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <Popup open={OpenFilter}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Filter</h5>
                            <button
                              className="close"
                              onClick={() => {
                                CloseFilterModel();
                              }}
                            >
                              <span
                                aria-hidden="true"
                                class="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="">
                            <div className="row">
                              <div className="col-xl-6 allcentertext">
                                <i class="la la-filter iconsfonts"></i>

                                <button
                                  className="btn btn-md btn-primary btnColor"
                                  onClick={() => {
                                    OpenAddFilterMethod();
                                  }}
                                >
                                  <span className="Mobile_button">
                                    Add New Filter
                                  </span>
                                </button>

                                <Popup open={OpenAddFilter}>
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup1000">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-4 px-3">
                                          <div className="w-100 d-flex px-3">
                                            <h5 className="mb-0">New Filter</h5>
                                            <button
                                              className="close"
                                              onClick={() => {
                                                CloseAddFilterMethod();
                                              }}
                                            >
                                              <span
                                                aria-hidden="true"
                                                class="la la-remove"
                                              ></span>
                                            </button>
                                          </div>
                                        </div>
                                        <div className="p-3">
                                          <div className="modal-body">
                                            <div className="col-sm-6 mb-5 px-0">
                                              <div class="row mb-3">
                                                <label class="col-lg-5 col-form-label text-left">
                                                  Filter Name
                                                </label>
                                                <div class="col-lg-7">
                                                  <input
                                                    class="form-control m-input"
                                                    data-val-required="Please enter title"
                                                    id="FilterName"
                                                    name="FilterName"
                                                    placeholder="Filter Name"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="table-bordered">
                                              <TableContainer component={Paper}>
                                                <Table
                                                  id="tab_logic"
                                                  size="small"
                                                  aria-label="a dense table"
                                                >
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell>
                                                        Property{" "}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                        {" "}
                                                      </TableCell>
                                                      <TableCell align="right"></TableCell>
                                                      <TableCell align="right"></TableCell>
                                                      <TableCell align="right"></TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {FieldAttributes.map(
                                                      (itemcount, i) => (
                                                        <TableRow
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              { border: 0 },
                                                          }}
                                                        >
                                                          <TableCell align="right">
                                                            <select
                                                              class="form-control m-input"
                                                              id={
                                                                "ColumnName" +
                                                                itemcount.id
                                                              }
                                                              onChange={() => {
                                                                ColumnDropdownHandlerAdd(
                                                                  itemcount.id
                                                                );
                                                              }}
                                                              autocomplete="off"
                                                            >
                                                              <option value="IsPaused">
                                                                Active
                                                              </option>
                                                              <option value="CampaignContactStatus">
                                                                Campaign Contact
                                                                Status
                                                              </option>
                                                              <option value="Company">
                                                                Company
                                                              </option>
                                                              <option value="TotalSent">
                                                                Deliveries
                                                              </option>
                                                              <option value="Email">
                                                                Email
                                                              </option>
                                                              <option value="TotalHardBounce">
                                                                Hard Bounce
                                                              </option>
                                                              <option value="LastSendDate">
                                                                Last Send Date
                                                              </option>
                                                              <option value="Name">
                                                                Name
                                                              </option>
                                                              <option value="TotalOpen">
                                                                Open
                                                              </option>
                                                              <option value="TotalReply">
                                                                Replies
                                                              </option>
                                                              <option value="TotalSoftBounce">
                                                                Soft Bounce
                                                              </option>
                                                              <option value="Title">
                                                                Title
                                                              </option>
                                                            </select>
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {(() => {
                                                              if (
                                                                FieldAttributes[
                                                                  i
                                                                ].ColumnName ==
                                                                "LastSendDate"
                                                              ) {
                                                                return (
                                                                  <>
                                                                    <select
                                                                      id={
                                                                        "expression" +
                                                                        itemcount.id
                                                                      }
                                                                      class="form-control m-input"
                                                                      onChange={() => {
                                                                        AddExpressionHandler(
                                                                          itemcount.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      <option value="EqualTo">
                                                                        Equals
                                                                      </option>
                                                                      <option value="LessThan">
                                                                        Less
                                                                        Than
                                                                      </option>
                                                                      <option value="LessThanEqualTo">
                                                                        Less
                                                                        Than
                                                                        Equal To
                                                                      </option>
                                                                      <option value="GreaterThan">
                                                                        Greater
                                                                        Than
                                                                      </option>
                                                                      <option value="GreaterThanEqualTo">
                                                                        Greater
                                                                        Than
                                                                        Equal To
                                                                      </option>
                                                                      <option value="between">
                                                                        Between{" "}
                                                                      </option>
                                                                      <option value="IsBlank">
                                                                        Is Blank
                                                                      </option>
                                                                      <option value="IsNotBlank">
                                                                        Is Not
                                                                        Blank
                                                                      </option>
                                                                    </select>
                                                                  </>
                                                                );
                                                              } else if (
                                                                FieldAttributes[
                                                                  i
                                                                ].ColumnName ==
                                                                "IsPaused"
                                                              ) {
                                                                return (
                                                                  <>
                                                                    <select
                                                                      id={
                                                                        "expression" +
                                                                        itemcount.id
                                                                      }
                                                                      onChange={() => {
                                                                        AddExpressionHandler(
                                                                          itemcount.id
                                                                        );
                                                                      }}
                                                                      class="form-control m-input"
                                                                    >
                                                                      <option
                                                                        id="ContainTo"
                                                                        value="True"
                                                                      >
                                                                        Yes
                                                                      </option>
                                                                      <option
                                                                        id="EqualTo"
                                                                        value="False"
                                                                      >
                                                                        No
                                                                      </option>
                                                                    </select>
                                                                  </>
                                                                );
                                                              } else if (
                                                                FieldAttributes[
                                                                  i
                                                                ].ColumnName ==
                                                                  "TotalSent" ||
                                                                FieldAttributes[
                                                                  i
                                                                ].ColumnName ==
                                                                  "TotalHardBounce" ||
                                                                FieldAttributes[
                                                                  i
                                                                ].ColumnName ==
                                                                  "TotalOpen" ||
                                                                FieldAttributes[
                                                                  i
                                                                ].ColumnName ==
                                                                  "TotalReply" ||
                                                                FieldAttributes[
                                                                  i
                                                                ].ColumnName ==
                                                                  "TotalSoftBounce"
                                                              ) {
                                                                return (
                                                                  <>
                                                                    <select
                                                                      id={
                                                                        "expression" +
                                                                        itemcount.id
                                                                      }
                                                                      class="form-control m-input"
                                                                      onChange={() => {
                                                                        AddExpressionHandler(
                                                                          itemcount.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      <option value="EqualTo">
                                                                        Equals
                                                                      </option>
                                                                      <option value="LessThan">
                                                                        Less
                                                                        Than
                                                                      </option>
                                                                      <option value="LessThanEqualTo">
                                                                        Less
                                                                        Than
                                                                        Equal To
                                                                      </option>
                                                                      <option value="GreaterThan">
                                                                        Greater
                                                                        Than
                                                                      </option>
                                                                      <option value="GreaterThanEqualTo">
                                                                        Greater
                                                                        Than
                                                                        Equal To
                                                                      </option>
                                                                      <option value="between">
                                                                        Between{" "}
                                                                      </option>
                                                                    </select>
                                                                  </>
                                                                );
                                                              } else {
                                                                return (
                                                                  <>
                                                                    <select
                                                                      id={
                                                                        "expression" +
                                                                        itemcount.id
                                                                      }
                                                                      class="form-control m-input"
                                                                      onChange={() => {
                                                                        AddExpressionHandler(
                                                                          itemcount.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      <option value="Contains">
                                                                        Contains{" "}
                                                                      </option>
                                                                      <option value="EqualTo">
                                                                        Equals
                                                                      </option>
                                                                    </select>
                                                                  </>
                                                                );
                                                              }
                                                            })()}
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {(() => {
                                                              if (
                                                                itemcount.ColumnName ==
                                                                "LastSendDate"
                                                              ) {
                                                                if (
                                                                  itemcount.InputType ==
                                                                  "between"
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <input
                                                                        type="date"
                                                                        id={
                                                                          "searchvalue" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input required"
                                                                      />
                                                                      <input
                                                                        type="date"
                                                                        id={
                                                                          "EndsWith" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input required"
                                                                      />
                                                                    </>
                                                                  );
                                                                } else {
                                                                  if (
                                                                    itemcount.InputType ==
                                                                      "IsBlank" ||
                                                                    itemcount.InputType ==
                                                                      "IsNotBlank"
                                                                  ) {
                                                                    return (
                                                                      <input
                                                                        type="text"
                                                                        hidden
                                                                        readOnly
                                                                        id={
                                                                          "searchvalue" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input required"
                                                                      />
                                                                    );
                                                                  } else {
                                                                    return (
                                                                      <input
                                                                        type="date"
                                                                        id={
                                                                          "searchvalue" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input required"
                                                                      />
                                                                    );
                                                                  }
                                                                }
                                                              } else if (
                                                                itemcount.ColumnName ==
                                                                "IsPaused"
                                                              ) {
                                                                return (
                                                                  <input
                                                                    type="text"
                                                                    hidden
                                                                    readOnly
                                                                    id={
                                                                      "searchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    class="form-control m-input required"
                                                                  />
                                                                );
                                                              } else if (
                                                                itemcount.ColumnName ==
                                                                  "TotalSent" ||
                                                                itemcount.ColumnName ==
                                                                  "TotalHardBounce" ||
                                                                itemcount.ColumnName ==
                                                                  "TotalOpen" ||
                                                                itemcount.ColumnName ==
                                                                  "TotalReply" ||
                                                                itemcount.ColumnName ==
                                                                  "TotalSoftBounce"
                                                              ) {
                                                                if (
                                                                  itemcount.InputType ==
                                                                  "between"
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <input
                                                                        type="text"
                                                                        id={
                                                                          "searchvalue" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input required"
                                                                      />
                                                                      <input
                                                                        type="text"
                                                                        id={
                                                                          "EndsWith" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input required"
                                                                      />
                                                                    </>
                                                                  );
                                                                } else {
                                                                  return (
                                                                    <input
                                                                      type="text"
                                                                      id={
                                                                        "searchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      class="form-control m-input required"
                                                                    />
                                                                  );
                                                                }
                                                              } else {
                                                                return (
                                                                  <input
                                                                    type="text"
                                                                    id={
                                                                      "searchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    class="form-control m-input required"
                                                                  />
                                                                );
                                                              }
                                                            })()}
                                                          </TableCell>

                                                          <TableCell align="right">
                                                            <button
                                                              type="button"
                                                              id={
                                                                "delete" +
                                                                itemcount.id
                                                              }
                                                              class="btn btngroup"
                                                              onClick={() => {
                                                                RemoveFilterRow(
                                                                  itemcount.id
                                                                );
                                                              }}
                                                            >
                                                              <i class="la flaticon-delete-1"></i>
                                                            </button>
                                                          </TableCell>
                                                          <TableCell align="right"></TableCell>
                                                          <TableCell align="right"></TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </Table>
                                              </TableContainer>
                                            </div>
                                            <div className="row">
                                              <div className="col-sm-12 text-left">
                                                <button
                                                  type="button"
                                                  class="btn btngroup"
                                                  value="ADD Row"
                                                  onClick={() => {
                                                    AddFilterRow();
                                                  }}
                                                >
                                                  <i class="la la-plus la-icon-font-size-13"></i>{" "}
                                                  Add Property{" "}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="border-top modal-content text-right">
                                            <div class="row">
                                              <div class="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  class="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    CloseAddFilterMethod();
                                                  }}
                                                >
                                                  cancel
                                                </a>
                                                <a
                                                  id="submit"
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  onClick={() => {
                                                    FilterSearch();
                                                  }}
                                                  value="Apply"
                                                >
                                                  Apply
                                                </a>
                                                <button
                                                  id="submit"
                                                  disabled={SaveFilterBtnDisabled}
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  onClick={() => {
                                                    SaveFilter();
                                                  }}
                                                  value="Save"
                                                >
                                                  <i class="la la-save"></i>{" "}
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popup>
                              </div>
                              <div className="col-xl-6 allcentertext brdr-left">
                                <i class="la la-filter iconsfonts"></i>

                                <button
                                  className="btn btn-md btn-primary btnColor"
                                  onClick={() => {
                                    OpenEditFilterModel();
                                  }}
                                >
                                  Select from existing Filter
                                </button>

                                <Popup open={OpenEditFilter}>
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup1000">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-4 px-3">
                                          <div className="w-100 d-flex px-3">
                                            <h5 className="mb-0">
                                              Edit Filter
                                            </h5>
                                            <button
                                              className="close"
                                              onClick={CloseEditFilter}
                                            >
                                              <span
                                                aria-hidden="true"
                                                class="la la-remove"
                                              ></span>
                                            </button>
                                          </div>
                                        </div>
                                        <div className="p-3">
                                          <div className="modal-body">
                                            <div className="col-sm-6 mb-5 px-0">
                                              <div class="row mb-3">
                                                <label class="col-lg-5 col-form-label text-left">
                                                  Filter Name
                                                </label>
                                                <div class="col-lg-7">
                                                  <select
                                                    class="form-control m-input m--hide"
                                                    id="FilterID"
                                                    name="FilterID"
                                                    onChange={() => {
                                                      handleChangeFilter();
                                                    }}
                                                  >
                                                    {FilterName &&
                                                      FilterName.map(
                                                        (valuefilter) => (
                                                          <option
                                                            value={
                                                              valuefilter._id
                                                            }
                                                          >
                                                            {
                                                              valuefilter.FilterName
                                                            }
                                                          </option>
                                                        )
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="table-bordered">
                                              <TableContainer component={Paper}>
                                                <Table
                                                  //size="small"
                                                  aria-label="a dense table"
                                                >
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell>
                                                        Property{" "}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                        {" "}
                                                      </TableCell>
                                                      <TableCell align="right"></TableCell> 
                                                      <TableCell align="center"></TableCell> 
                                                    </TableRow>
                                                  </TableHead> 
                                                    <TableBody>
                                                      {EditFieldAttributes.map(
                                                        (itemcount, i) => (
                                                          <TableRow
                                                            sx={{
                                                              "&:last-child td, &:last-child th":
                                                                {
                                                                  border: 0,
                                                                },
                                                            }}
                                                          >
                                                            <TableCell>
                                                              <select
                                                                class="form-control m-input"
                                                                id={
                                                                  "EditColumnName" +
                                                                  itemcount.id
                                                                }
                                                                onChange={() => {
                                                                  EditColumnName(
                                                                    itemcount.id
                                                                  );
                                                                }}
                                                                value={
                                                                  itemcount.FilterPropertyName
                                                                }
                                                                autocomplete="off"
                                                              >
                                                                <option value="IsPaused">
                                                                  Active
                                                                </option>
                                                                <option value="CampaignContactStatus">
                                                                  Campaign
                                                                  Contact Status
                                                                </option>
                                                                <option value="Company">
                                                                  Company
                                                                </option>
                                                                <option value="TotalSent">
                                                                  Deliveries
                                                                </option>
                                                                <option value="Email">
                                                                  Email
                                                                </option>
                                                                <option value="TotalHardBounce">
                                                                  Hard Bounce
                                                                </option>
                                                                <option value="LastSendDate">
                                                                  Last Send Date
                                                                </option>
                                                                <option value="Name">
                                                                  Name
                                                                </option>
                                                                <option value="TotalOpen">
                                                                  Open
                                                                </option>
                                                                <option value="TotalReply">
                                                                  Replies
                                                                </option>
                                                                <option value="TotalSoftBounce">
                                                                  Soft Bounce
                                                                </option>
                                                                <option value="Title">
                                                                  Title
                                                                </option>
                                                              </select>
                                                            </TableCell>
                                                            <TableCell>
                                                              {(() => {
                                                                if (
                                                                  EditFieldAttributes[
                                                                    i
                                                                  ]
                                                                    .FilterPropertyName ==
                                                                  "LastSendDate"
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <select
                                                                        id={
                                                                          "Editexpression" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input"
                                                                        value={
                                                                          itemcount.ExpressionName
                                                                        }
                                                                        onChange={() => {
                                                                          EditExpressValue(
                                                                            itemcount.id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <option value="EqualTo">
                                                                          Equals
                                                                        </option>
                                                                        <option value="LessThan">
                                                                          Less
                                                                          Than
                                                                        </option>
                                                                        <option value="LessThanEqualTo">
                                                                          Less
                                                                          Than
                                                                          Equal
                                                                          To
                                                                        </option>
                                                                        <option value="GreaterThan">
                                                                          Greater
                                                                          Than
                                                                        </option>
                                                                        <option value="GreaterThanEqualTo">
                                                                          Greater
                                                                          Than
                                                                          Equal
                                                                          To
                                                                        </option>
                                                                        <option value="between">
                                                                          Between{" "}
                                                                        </option>
                                                                        <option value="IsBlank">
                                                                          Is
                                                                          Blank
                                                                        </option>
                                                                        <option value="IsNotBlank">
                                                                          Is Not
                                                                          Blank
                                                                        </option>
                                                                      </select>
                                                                    </>
                                                                  );
                                                                } else if (
                                                                  EditFieldAttributes[
                                                                    i
                                                                  ]
                                                                    .FilterPropertyName ==
                                                                  "IsPaused"
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <select
                                                                        id={
                                                                          "Editexpression" +
                                                                          itemcount.id
                                                                        }
                                                                        value={
                                                                          itemcount.ExpressionName
                                                                        }
                                                                        onChange={() => {
                                                                          EditExpressValue(
                                                                            itemcount.id
                                                                          );
                                                                        }}
                                                                        class="form-control m-input"
                                                                      >
                                                                        <option
                                                                          id="ContainTo"
                                                                          value="True"
                                                                        >
                                                                          Yes
                                                                        </option>
                                                                        <option
                                                                          id="EqualTo"
                                                                          value="False"
                                                                        >
                                                                          No
                                                                        </option>
                                                                      </select>
                                                                    </>
                                                                  );
                                                                } else if (
                                                                  EditFieldAttributes[
                                                                    i
                                                                  ]
                                                                    .FilterPropertyName ==
                                                                    "TotalSent" ||
                                                                  EditFieldAttributes[
                                                                    i
                                                                  ]
                                                                    .FilterPropertyName ==
                                                                    "TotalHardBounce" ||
                                                                  EditFieldAttributes[
                                                                    i
                                                                  ]
                                                                    .FilterPropertyName ==
                                                                    "TotalOpen" ||
                                                                  EditFieldAttributes[
                                                                    i
                                                                  ]
                                                                    .FilterPropertyName ==
                                                                    "TotalReply" ||
                                                                  EditFieldAttributes[
                                                                    i
                                                                  ]
                                                                    .FilterPropertyName ==
                                                                    "TotalSoftBounce"
                                                                ) {
                                                                  return (
                                                                    <>
                                                                      <select
                                                                        id={
                                                                          "Editexpression" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input"
                                                                        value={
                                                                          itemcount.ExpressionName
                                                                        }
                                                                        onChange={() => {
                                                                          EditExpressValue(
                                                                            itemcount.id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <option value="EqualTo">
                                                                          Equals
                                                                        </option>
                                                                        <option value="LessThan">
                                                                          Less
                                                                          Than
                                                                        </option>
                                                                        <option value="LessThanEqualTo">
                                                                          Less
                                                                          Than
                                                                          Equal
                                                                          To
                                                                        </option>
                                                                        <option value="GreaterThan">
                                                                          Greater
                                                                          Than
                                                                        </option>
                                                                        <option value="GreaterThanEqualTo">
                                                                          Greater
                                                                          Than
                                                                          Equal
                                                                          To
                                                                        </option>
                                                                        <option value="between">
                                                                          Between{" "}
                                                                        </option>
                                                                      </select>
                                                                    </>
                                                                  );
                                                                } else {
                                                                  return (
                                                                    <>
                                                                      <select
                                                                        id={
                                                                          "Editexpression" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input"
                                                                        value={
                                                                          itemcount.ExpressionName
                                                                        }
                                                                        onChange={() => {
                                                                          EditExpressValue(
                                                                            itemcount.id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <option value="Contains">
                                                                          Contains{" "}
                                                                        </option>
                                                                        <option value="EqualTo">
                                                                          Equals
                                                                        </option>
                                                                      </select>
                                                                    </>
                                                                  );
                                                                }
                                                              })()}
                                                            </TableCell>

                                                            <TableCell>
                                                              {(() => {
                                                                if (
                                                                  itemcount.FilterPropertyName ==
                                                                  "LastSendDate"
                                                                ) {
                                                                  if (
                                                                    itemcount.ExpressionName ==
                                                                    "between"
                                                                  ) {
                                                                    return (
                                                                      <>
                                                                        <input
                                                                          type="date"
                                                                          id={
                                                                            "Editsearchvalue" +
                                                                            itemcount.id
                                                                          }
                                                                          class="form-control m-input required"
                                                                          onChange={() => {
                                                                            EditFilterPropertyValue1(
                                                                              itemcount.id
                                                                            );
                                                                          }}
                                                                          value={
                                                                            itemcount.FilterPropertyValue1
                                                                          }
                                                                        />
                                                                        <input
                                                                          type="date"
                                                                          id={
                                                                            "EditEndsWith" +
                                                                            itemcount.id
                                                                          }
                                                                          class="form-control m-input required"
                                                                          onChange={() => {
                                                                            EditFilterPropertyValue2(
                                                                              itemcount.id
                                                                            );
                                                                          }}
                                                                          value={
                                                                            itemcount.FilterPropertyValue2
                                                                          }
                                                                        />
                                                                      </>
                                                                    );
                                                                  } else {
                                                                    if (
                                                                      itemcount.ExpressionName ==
                                                                        "IsBlank" ||
                                                                      itemcount.ExpressionName ==
                                                                        "IsNotBlank"
                                                                    ) {
                                                                      return (
                                                                        <input
                                                                          type="text"
                                                                          hidden
                                                                          readOnly
                                                                          id={
                                                                            "Editsearchvalue" +
                                                                            itemcount.id
                                                                          }
                                                                          class="form-control m-input required"
                                                                          onChange={() => {
                                                                            EditFilterPropertyValue1(
                                                                              itemcount.id
                                                                            );
                                                                          }}
                                                                          value={
                                                                            itemcount.FilterPropertyValue1
                                                                          }
                                                                        />
                                                                      );
                                                                    } else {
                                                                      return (
                                                                        <input
                                                                          type="date"
                                                                          id={
                                                                            "Editsearchvalue" +
                                                                            itemcount.id
                                                                          }
                                                                          class="form-control m-input required"
                                                                          onChange={() => {
                                                                            EditFilterPropertyValue1(
                                                                              itemcount.id
                                                                            );
                                                                          }}
                                                                          value={
                                                                            itemcount.FilterPropertyValue1
                                                                          }
                                                                        />
                                                                      );
                                                                    }
                                                                  }
                                                                } else if (
                                                                  itemcount.FilterPropertyName ==
                                                                  "IsPaused"
                                                                ) {
                                                                  return (
                                                                    <input
                                                                      type="text"
                                                                      hidden
                                                                      readOnly
                                                                      id={
                                                                        "Editsearchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      class="form-control m-input required"
                                                                    />
                                                                  );
                                                                } else if (
                                                                  itemcount.FilterPropertyName ==
                                                                    "TotalSent" ||
                                                                  itemcount.FilterPropertyName ==
                                                                    "TotalHardBounce" ||
                                                                  itemcount.FilterPropertyName ==
                                                                    "TotalOpen" ||
                                                                  itemcount.FilterPropertyName ==
                                                                    "TotalReply" ||
                                                                  itemcount.FilterPropertyName ==
                                                                    "TotalSoftBounce"
                                                                ) {
                                                                  if (
                                                                    itemcount.ExpressionName ==
                                                                    "between"
                                                                  ) {
                                                                    return (
                                                                      <>
                                                                        <input
                                                                          type="text"
                                                                          id={
                                                                            "Editsearchvalue" +
                                                                            itemcount.id
                                                                          }
                                                                          class="form-control m-input required"
                                                                          onChange={() => {
                                                                            EditFilterPropertyValue1(
                                                                              itemcount.id
                                                                            );
                                                                          }}
                                                                          value={
                                                                            itemcount.FilterPropertyValue1
                                                                          }
                                                                        />
                                                                        <input
                                                                          type="text"
                                                                          id={
                                                                            "EditEndsWith" +
                                                                            itemcount.id
                                                                          }
                                                                          class="form-control m-input required"
                                                                          onChange={() => {
                                                                            EditFilterPropertyValue2(
                                                                              itemcount.id
                                                                            );
                                                                          }}
                                                                          value={
                                                                            itemcount.FilterPropertyValue2
                                                                          }
                                                                        />
                                                                      </>
                                                                    );
                                                                  } else {
                                                                    return (
                                                                      <input
                                                                        type="text"
                                                                        id={
                                                                          "Editsearchvalue" +
                                                                          itemcount.id
                                                                        }
                                                                        class="form-control m-input required"
                                                                        onChange={() => {
                                                                          EditFilterPropertyValue1(
                                                                            itemcount.id
                                                                          );
                                                                        }}
                                                                        value={
                                                                          itemcount.FilterPropertyValue1
                                                                        }
                                                                      />
                                                                    );
                                                                  }
                                                                } else {
                                                                  return (
                                                                    <input
                                                                      type="text"
                                                                      id={
                                                                        "Editsearchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      class="form-control m-input required"
                                                                      onChange={() => {
                                                                        EditFilterPropertyValue1(
                                                                          itemcount.id
                                                                        );
                                                                      }}
                                                                      value={
                                                                        itemcount.FilterPropertyValue1
                                                                      }
                                                                    />
                                                                  );
                                                                }
                                                              })()}
                                                            </TableCell>

                                                            <TableCell align="center">
                                                              <button
                                                                type="button"
                                                                id={
                                                                  "delete" +
                                                                  itemcount.id
                                                                }
                                                                class="btn btngroup"
                                                                onClick={() => {
                                                                  handleEditRemoveClick(
                                                                    itemcount.id
                                                                  );
                                                                }}
                                                              >
                                                                <i class="la flaticon-delete-1"></i>
                                                              </button>
                                                            </TableCell> 
                                                          </TableRow>
                                                        )
                                                      )}
                                                    </TableBody> 
                                                </Table>
                                              </TableContainer>
                                            </div>
                                            <div className="row">
                                              <div className="col-sm-12 text-left">
                                                <button
                                                  type="button"
                                                  class="btn btngroup"
                                                  onClick={() => {
                                                    handleeditPropertyClick();
                                                  }}
                                                >
                                                  <i class="la la-plus la-icon-font-size-13"></i>{" "}
                                                  Add Property{" "}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="border-top modal-content text-right">
                                            <div class="row">
                                              <div class="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  class="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    CloseEditFilter();
                                                  }}
                                                >
                                                  cancel
                                                </a>
                                                <a
                                                  id="submit"
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  onClick={() => {
                                                    ApplyEditFilter();
                                                  }}
                                                >
                                                  Apply
                                                </a>
                                                <button
                                                  id="submit"
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  disabled={SaveFilterBtnDisabled}
                                                  onClick={() => {
                                                    editapplyfilterSearch();
                                                  }}
                                                >
                                                  <i class="la la-save"></i>{" "}
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
                {IsApply == true ? (
                  <a
                    className="text-right"
                    onClick={() => {
                      ResetFilter();
                    }}
                  >
                    Reset
                  </a>
                ) : null}

                <div className="row pt-4">
                  <div className="col px-0">
                    <ContactTable
                      contactFlag={ProspectFlag}
                      CCampaignID={Campaign}
                      sendDataToParent={handleDataFromChild}
                      FilterParameters={FilterParameters}
                      IsApply={FilterParameters.length > 0 ? true : false}
                      onData2={handleDataFromCampaign}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              className="tagpaddnone bg-white inpt-0"
              index={3}
            >
              <div className="timeline-col stepercard">
                <Timeline className="mt-5">
                  <Container
                    dragHandleSelector=".drag-handle"
                    lockAxis="y"
                    onDrop={onDrop}
                  >
                    {CampaignDetails.map((Step) => (
                      <Draggable key={Step._id}>
                        <ListItem>
                          <ListItemIcon className="drag-handle">
                            <TimelineItem>
                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5>
                                  Step {Step.OrderBy} - Day {Step.TotalCount}
                                  <KeyboardArrowDownIcon
                                    aria-describedby={Step._id}
                                    variant="contained"
                                    onClick={(e) => {
                                      handleClick(e, Step.Days, Step._id);
                                    }}
                                  />
                                </h5>
                                <div className="cardstep">
                                  {/* data inner step*/}
                                  {Step.CStepTemplate.map(
                                    (StepTemplate, index) => (
                                      <div className="pb-2">
                                        <div class="row my-2">
                                          <div class="col-lg-3 nopadding">
                                            <Row>
                                              <Col sm={2}>
                                                {String.fromCharCode(
                                                  65 + index
                                                )}
                                              </Col>
                                              <Col sm={10} class="nopadding">
                                                <b>
                                                  {" "}
                                                  {parse(
                                                    StepTemplate.Subject
                                                  )}{" "}
                                                </b>
                                              </Col>
                                            </Row>
                                          </div>
                                          <div class="col-lg-2">
                                            <b>
                                              {StepTemplate?.EModProfileID ===
                                              "6523d182eff732ba5ceead8c"
                                                ? "eMod off"
                                                : StepTemplate?.EModProfileID ===
                                                  "6523d182eff732ba5ceead8d"
                                                ? "Campaign Default"
                                                : eModProfile.find(
                                                    (profile) =>
                                                      profile?._id ===
                                                      StepTemplate?.EModProfileID
                                                  )?.Profile || ""}
                                            </b>
                                          </div>
                                          <div class="col-lg-5">
                                            <p id="bodyContent">
                                              {StepTemplate.Body &&
                                              StepTemplate?.Body?.split(" ")
                                                .length > 5
                                                ? StepTemplate?.Body?.split(" ")
                                                    ?.slice(0, 7)
                                                    ?.join(" ") + " ..."
                                                : StepTemplate?.Body}
                                            </p>
                                          </div>
                                          <div class="col-lg-2 iconssmaller showme d-flex">
                                            <a
                                              onClick={() => {
                                                EditStepTempletPopHandle(
                                                  StepTemplate._id
                                                );
                                              }}
                                            >
                                              <i class="la flaticon-edit-1 edit-icon"></i>
                                            </a>
                                            <a
                                              onClick={() => {
                                                handleAddDuplicateTemplete(
                                                  StepTemplate.CampaignID,
                                                  StepTemplate.CampaignStepID,
                                                  StepTemplate.Body,
                                                  StepTemplate.Subject,
                                                  StepTemplate.ClientID,
                                                  StepTemplate.UserID,
                                                  StepTemplate.OrderBy,
                                                  StepTemplate?.EModProfileID
                                                );
                                              }}
                                            >
                                              <i class="la flaticon-add add-icon edit-icon"></i>
                                            </a>
                                            {Step.CStepTemplate.length == 1 ? (
                                              " "
                                            ) : (
                                              <a
                                                onClick={() => {
                                                  handleDeleteStepTemplete(
                                                    StepTemplate._id
                                                  );
                                                }}
                                              >
                                                <i class="la flaticon-delete-1 delete-icon edit-icon"></i>
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-lg-11">
                                            <b>Total Send :</b>{" "}
                                            {StepTemplate.StepCountHistory ===
                                            undefined
                                              ? 0
                                              : StepTemplate?.StepCountHistory
                                                  ?.Send}
                                            <span>
                                              <b>Open % :</b>{" "}
                                              <Tooltip
                                                title={
                                                  <>
                                                    <span>
                                                      Opens:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.TotalOpen}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Tracked:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Send}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Open %:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                        undefined ||
                                                      StepTemplate
                                                        .StepCountHistory
                                                        .TotalOpen == undefined
                                                        ? 0
                                                        : StepTemplate
                                                            .StepCountHistoryStepTemplate
                                                            ?.StepCountHistory
                                                            ?.TotalOpen == 0
                                                        ? 0
                                                        : (
                                                            (StepTemplate
                                                              ?.StepCountHistory
                                                              ?.TotalOpen /
                                                              StepTemplate
                                                                ?.StepCountHistory
                                                                ?.Send) *
                                                            100
                                                          ).toFixed()}
                                                      %
                                                    </span>
                                                  </>
                                                }
                                                placement="right"
                                              >
                                                <span>
                                                  {" "}
                                                  {StepTemplate.StepCountHistory ===
                                                    undefined ||
                                                  StepTemplate.StepCountHistory
                                                    .TotalOpen == undefined
                                                    ? 0
                                                    : StepTemplate
                                                        ?.StepCountHistory
                                                        ?.TotalOpen == 0
                                                    ? 0
                                                    : (
                                                        (StepTemplate
                                                          ?.StepCountHistory
                                                          ?.TotalOpen /
                                                          StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Send) *
                                                        100
                                                      ).toFixed()}
                                                </span>
                                              </Tooltip>
                                              %
                                            </span>
                                            <span>
                                              <b>Reply % :</b>{" "}
                                              <Tooltip
                                                title={
                                                  <>
                                                    <span>
                                                      Replies:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Reply}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Sent:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Send}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Reply %:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Reply == 0
                                                        ? 0
                                                        : (
                                                            (StepTemplate
                                                              ?.StepCountHistory
                                                              ?.Reply /
                                                              StepTemplate
                                                                ?.StepCountHistory
                                                                ?.Send) *
                                                            100
                                                          ).toFixed()}
                                                      %
                                                    </span>
                                                  </>
                                                }
                                                placement="right"
                                              >
                                                <span>
                                                  {" "}
                                                  {StepTemplate.StepCountHistory ===
                                                  undefined
                                                    ? 0
                                                    : StepTemplate
                                                        ?.StepCountHistory
                                                        ?.Reply == 0
                                                    ? 0
                                                    : (
                                                        (StepTemplate
                                                          ?.StepCountHistory
                                                          ?.Reply /
                                                          StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Send) *
                                                        100
                                                      ).toFixed()}
                                                </span>
                                              </Tooltip>
                                              %
                                            </span>
                                            <span>
                                              <b>Bounce % :</b>{" "}
                                              <Tooltip
                                                title={
                                                  <>
                                                    <span>
                                                      Soft Bounce:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.SoftBounce}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Hard Bounce:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.HardBounce}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Total Bounce:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.SoftBounce +
                                                            StepTemplate.StepCountHistory ===
                                                          undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.HardBounce}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Sent:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Send}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Bounce %:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Send == 0
                                                        ? 0
                                                        : (
                                                            ((StepTemplate
                                                              ?.StepCountHistory
                                                              ?.SoftBounce +
                                                              StepTemplate
                                                                ?.StepCountHistory
                                                                ?.HardBounce) /
                                                              StepTemplate
                                                                ?.StepCountHistory
                                                                ?.Send) *
                                                            100
                                                          ).toFixed()}
                                                      %
                                                    </span>
                                                  </>
                                                }
                                                placement="right"
                                              >
                                                <span>
                                                  {" "}
                                                  {StepTemplate.StepCountHistory ===
                                                  undefined
                                                    ? 0
                                                    : StepTemplate
                                                        ?.StepCountHistory
                                                        ?.Send == 0
                                                    ? 0
                                                    : (
                                                        ((StepTemplate
                                                          ?.StepCountHistory
                                                          ?.SoftBounce +
                                                          StepTemplate
                                                            ?.StepCountHistory
                                                            ?.HardBounce) /
                                                          StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Send) *
                                                        100
                                                      ).toFixed()}
                                                </span>
                                              </Tooltip>
                                              %
                                            </span>
                                            <span>
                                              <b>Interested % :</b>{" "}
                                              <Tooltip
                                                title={
                                                  <>
                                                    <span>
                                                      Interested:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Interested}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Replies:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Reply}
                                                    </span>
                                                    <br />
                                                    <span>
                                                      Open %:{" "}
                                                      {StepTemplate.StepCountHistory ===
                                                      undefined
                                                        ? 0
                                                        : StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Reply == 0
                                                        ? 0
                                                        : (
                                                            (StepTemplate
                                                              ?.StepCountHistory
                                                              ?.Interested /
                                                              StepTemplate
                                                                ?.StepCountHistory
                                                                ?.Reply) *
                                                            100
                                                          ).toFixed()}
                                                      %
                                                    </span>
                                                  </>
                                                }
                                                placement="right"
                                              >
                                                <span>
                                                  {" "}
                                                  {StepTemplate.StepCountHistory ===
                                                  undefined
                                                    ? 0
                                                    : StepTemplate
                                                        ?.StepCountHistory
                                                        ?.Reply == 0
                                                    ? 0
                                                    : (
                                                        (StepTemplate
                                                          ?.StepCountHistory
                                                          ?.Interested /
                                                          StepTemplate
                                                            ?.StepCountHistory
                                                            ?.Reply) *
                                                        100
                                                      ).toFixed()}
                                                </span>
                                              </Tooltip>
                                              %
                                            </span>
                                          </div>
                                          <div class="col-lg-1">
                                            <div class="toggleswich">
                                              <input
                                                type="checkbox"
                                                class="checktogle"
                                                onChange={() => {
                                                  const activeTemplatesCount =
                                                    Step?.CStepTemplate?.filter(
                                                      (template) =>
                                                        template?.IsActive
                                                    )?.length;

                                                  // If more than one active template, allow toggling
                                                  if (
                                                    activeTemplatesCount > 1 ||
                                                    !StepTemplate?.IsActive
                                                  ) {
                                                    StepTemplateActive(
                                                      StepTemplate?._id,
                                                      !StepTemplate?.IsActive
                                                    );
                                                  }
                                                }}
                                                checked={StepTemplate?.IsActive}
                                                disabled={
                                                  Step?.CStepTemplate?.filter(
                                                    (template) =>
                                                      template?.IsActive
                                                  )?.length <= 1 &&
                                                  StepTemplate?.IsActive
                                                }
                                              />
                                              <b class="switch">
                                                <span class="checkion"></span>
                                                <span class="uncheckion"></span>
                                              </b>
                                              <b class="track"></b>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}

                                  <div className="plusicons showme">
                                    <a
                                      onClick={() => {
                                        AddStepTempletPopHandle(Step._id);
                                      }}
                                    >
                                      <i class="la flaticon-add-circular-button add-icon"></i>
                                    </a>
                                  </div>
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          </ListItemIcon>
                        </ListItem>
                      </Draggable>
                    ))}
                  </Container>
                </Timeline>
              </div>
              <Popup open={AddStepTempletePopup}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup1000">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Add Step</h5>
                          <button
                            className="close"
                            onClick={() => AddStepTempletPopHandle}
                          >
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="px-4">
                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Subject
                            </label>
                            <div class="col-lg-9 d-flex">
                              <input
                                class="form-control m-input"
                                type="text"
                                id="TemplateSubject"
                                // onChange={(e) => {
                                //   const inputValue = e.target.value;
                                //   const newErrors = { ...errors };

                                //   if (inputValue.length === 0) {
                                //     newErrors["Subject"] =
                                //       "Please enter Subject";
                                //   } else {
                                //     newErrors["Subject"] = "";
                                //   }

                                //   Seterrors(newErrors);
                                // }}
                              />
                              {/* <span
                                  style={{ color: "red", fontSize: "small" }}
                                >
                                  {errors["Subject"]}
                                </span> */}
                            </div>
                          </div>

                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              eMod Profile
                            </label>
                            <div class="col-lg-9 d-flex">
                              <select
                                id="eModProfileAddSecondID"
                                class="form-control m-input valid"
                              >
                                <option value="6523d182eff732ba5ceead8c">
                                  eMod off
                                </option>
                                {eModProfile?.map((value) => (
                                  <option value={value._id.toString()}>
                                    {value.Profile}
                                  </option>
                                ))}
                                <option value="6523d182eff732ba5ceead8d">
                                  Campaign Default
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Body
                            </label>
                            <div class="col-lg-9">
                              <FroalaEditor
                                id="signature"
                                config={config}
                                onModelChange={VariableModelChange2}
                                model={SignatureSC2.data}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                      <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            SetAddStepTempletPopHandleSendTest(true);
                          }}
                        >
                          Send Test
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={AddStepTempletPopHandle}
                        >
                          Cancel
                        </button>
                        <button
                          id="SubmitStepTemplate"
                          onClick={AddStepTemplatePopupSubmit}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-save mr-2"></i> Save{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            {/* Send Add test start */}
              <Popup open={AddStepTempletPopHandleSendTest}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup1000">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Send Test</h5>
                          <button
                            className="close"
                            onClick={() => {
                              SetAddStepTempletPopHandleSendTest(false);
                            }}
                          >
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="px-4">
                          <div class="row mb-4">
                            <div class="col-lg-6 d-flex justify-content-between align-items-center">
                              <label class=" col-form-label textright-vmb">
                                Email Accounts
                              </label>
                              <div class="">
                                <select
                                  id="AddSendTestEmailFromID"
                                  class="form-control m-input valid"
                                >
                                  {SendTestAccount?.map((value) => (
                                    <option
                                      key={value?._id}
                                      value={JSON.stringify(value)}
                                    >
                                      {value?.SMTPFromEmail}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div class="col-lg-6 d-flex justify-content-between align-items-center">
                              <label class=" col-form-label textright-vmb">
                                To
                              </label>
                              <div class="">
                                <input
                                  id="AddSendTestEmailToID"
                                  class="form-control m-input valid"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row mb-4">
                            <div class="col-lg-6 d-flex justify-content-between align-items-center">
                              <div class=" col-form-label textright-vmb">
                                <div className="toggleswich">
                                  <input
                                    type="checkbox"
                                    className="checktogle"
                                    id="IsUpdateExistIncludeOpenAdd"
                                  />
                                  <b className="switch">
                                    <span className="checkion"></span>
                                    <span className="uncheckion"></span>
                                  </b>
                                  <b className="track"></b>
                                </div>
                              </div>

                              <label>Include Open Tracking</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => {
                            SetAddStepTempletPopHandleSendTest(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btnColor"
                          onClick={SendAddStepHandleTest}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              {/* Send Add test end */}
              <Popover
                className="minipoupdev"
                id={CampaignStepID}
                open={open}
                anchorEl={AnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Row>
                  <Col>Wait time:</Col>
                </Row>
                <Row className="align-item-center">
                  <Col>
                    <input
                      class="form-control m-input"
                      type="number"
                      min="0"
                      id="DaysTemplate"
                      name="Days"
                      defaultValue={DaysTemp}
                    />
                  </Col>
                  <Col className="px-0 py-2">Days</Col>
                  <Col></Col>
                </Row>
                <Row className="mt-4">
                  <Col className="text-right">
                    <a onClick={handleClose} class="btn btn-secondary mr-2">
                      Cancel
                    </a>
                    <a
                      onClick={() => ChangeDays()}
                      class="btn btn-primary btn-lightgreen"
                    >
                      <i class="la la-save"></i> Save
                    </a>
                  </Col>
                </Row>
              </Popover>
              <Popup open={EditStepTempletePopup}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup1000">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Add Step</h5>
                          <button
                            className="close"
                            onClick={() => {
                              SetEditStepTempletePopup(false);
                            }}
                          >
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="px-4">
                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Subject
                            </label>
                            <div class="col-lg-9 d-flex">
                              <input
                                class="form-control m-input"
                                type="text"
                                id="TemplateSubject3"
                                onBlur={() => {
                                  HandleChangedropdownBlur();
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {ErrorsStep["TemplateSubject3"]}
                              </span>
                            </div>
                          </div>

                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              eMod Profile
                            </label>
                            <div class="col-lg-9 d-flex">
                              <select
                                id="eModProfileEditID"
                                value={eModProfileDefaultEdit}
                                onChange={(event) =>
                                  handleeModProfileDefaultEdit(event)
                                }
                                class="form-control m-input valid"
                              >
                                <option value="6523d182eff732ba5ceead8c">
                                  eMod off
                                </option>
                                {eModProfile?.map((value) => (
                                  <option value={value._id.toString()}>
                                    {value.Profile}
                                  </option>
                                ))}
                                <option value="6523d182eff732ba5ceead8d">
                                  Campaign Default
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Body
                            </label>
                            <div class="col-lg-9">
                              <FroalaEditor
                                id="signature3"
                                config={config}
                                onModelChange={VariableModelChange3}
                                model={SignatureSC3.data}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            SetEditStepTempletPopHandleSendTest(true);
                          }}
                        >
                          Send Test
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={EditStepTempletPopHandle}
                        >
                          Cancel
                        </button>
                        <button
                          id="SubmitStepTemplate"
                          onClick={() => {
                            EditStepTemplatePopupSubmit();
                          }}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-save mr-2"></i> Save{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              {/* Send Edit test start */}
              <Popup open={EditStepTempletPopHandleSendTest}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup1000">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Send Test</h5>
                          <button
                            className="close"
                            onClick={() => {
                              SetEditStepTempletPopHandleSendTest(false);
                            }}
                          >
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="px-4">
                          <div class="row mb-4">
                            <div class="col-lg-6 d-flex justify-content-between align-items-center">
                              <label class=" col-form-label textright-vmb">
                                Email Accounts
                              </label>
                              <div class="">
                                <select
                                  id="SendTestEmailFromID"
                                  class="form-control m-input valid"
                                >
                                  {SendTestAccount?.map((value) => (
                                    <option
                                      key={value?._id}
                                      value={JSON.stringify(value)}
                                    >
                                      {value?.SMTPFromEmail}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div class="col-lg-6 d-flex justify-content-between align-items-center">
                              <label class=" col-form-label textright-vmb">
                                To
                              </label>
                              <div class="">
                                <input
                                  id="SendTestEmailToID"
                                  class="form-control m-input valid"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row mb-4">
                            <div class="col-lg-6 d-flex justify-content-between align-items-center">
                              <div class=" col-form-label textright-vmb">
                                <div className="toggleswich">
                                  <input
                                    type="checkbox"
                                    className="checktogle"
                                    id="IsUpdateExistIncludeOpen"
                                  />
                                  <b className="switch">
                                    <span className="checkion"></span>
                                    <span className="uncheckion"></span>
                                  </b>
                                  <b className="track"></b>
                                </div>
                              </div>

                              <label>Include Open Tracking</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => {
                            SetEditStepTempletPopHandleSendTest(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btnColor"
                          onClick={SendEditStepHandleTest}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              {/* Send Edit test end */}
              <div className="row">
                <div className="col w-100">
                  <div className="iconer">
                    <i className="flaticon-file-1 clr-purple"></i>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col w-100 text-center">
                  <a
                    class="btn btn-primary btngroup"
                    onClick={AddStepPopHandle}
                  >
                    <i className="la la-plus-circle la-icon-font-size-13"></i>{" "}
                    Add Campaign Step
                  </a>
                  <Popup open={AddStepPopup}>
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup1000">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-4 px-3">
                            <div className="w-100 d-flex px-3">
                              <h5 className="mb-0">Add Step</h5>
                              <button
                                className="close"
                                onClick={AddStepPopHandle}
                              >
                                <span
                                  aria-hidden="true"
                                  class="la la-remove"
                                ></span>
                              </button>
                            </div>
                          </div>
                          <div className="modal-body">
                            <div className="px-4">
                              <div class="row mb-4">
                                <label class="col-lg-3 col-form-label textright-vmb">
                                  Wait time
                                </label>
                                <div class="col-lg-3 d-flex">
                                  <input
                                    class="form-control m-input"
                                    placeholder=""
                                    id="Days"
                                    type="number"
                                    defaultValue={0}
                                    min={0}
                                  />
                                  <label class="ml-3 col-form-label text-left">
                                    Days
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-4">
                                <label class="col-lg-3 col-form-label textright-vmb">
                                  Subject
                                </label>
                                <div class="col-lg-9 d-flex">
                                  <input
                                    class="form-control m-input"
                                    id="AddCampaignSubject"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="row mb-4">
                                <label class="col-lg-3 col-form-label textright-vmb">
                                  eMod Profile
                                </label>
                                <div class="col-lg-9 d-flex">
                                  <select
                                    id="eModProfileAddID"
                                    // value={eModProfileDefaultEdit}
                                    // onChange={
                                    //   (event) =>
                                    //     handleeModProfileDefaultEdit(
                                    //       event
                                    //     )}
                                    class="form-control m-input valid"
                                  >
                                    <option value="6523d182eff732ba5ceead8c">
                                      eMod off
                                    </option>
                                    {eModProfile?.map((value) => (
                                      <option value={value._id.toString()}>
                                        {value.Profile}
                                      </option>
                                    ))}
                                    <option value="6523d182eff732ba5ceead8d">
                                      Campaign Default
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="row mb-4">
                                <label class="col-lg-3 col-form-label textright-vmb">
                                  Body
                                </label>
                                <div class="col-lg-9">
                                  <FroalaEditor
                                    id="signature"
                                    config={config}
                                    onModelChange={VariableModelChange}
                                    model={SignatureSC.data}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={AddStepPopHandle}
                            >
                              Cancel
                            </button>
                            <button
                              id="Step_submit"
                              href="javascript:void(0);"
                              class="btn btn-primary btnColor"
                              onClick={(e) => {
                                AddCampaignStep(e);
                              }}
                            >
                              <i class="la la-save mr-2"></i> Save{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popup>
                  <div className="timeline-col"></div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              className="tagpaddnone bg-white inpt-0"
              index={4}
            >
              <div className="row ">
                <div className="col padright">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                          onClick={handleClickrandam}
                          className="btn btngroup m-btn"
                        >
                          <i className="flaticon-refresh la-icon-font-size-13">
                            {" "}
                          </i>{" "}
                          Refresh
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="timeline-col">
                <Timeline>
                  {PreviewCampaignDetails.map((Step) => (
                    <TimelineItem key={Step.CampaignStepID}>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <h5>
                          Step {Step.StepNumber} - Day {Step.Days}
                        </h5>

                        <div className="accordioncol">
                          {Step.CStepTemplate.map((StepTemplate, index) => (
                            <Accordion
                              key={StepTemplate._id}
                              expanded={
                                activeIndexes.indexOf(StepTemplate._id) !== -1
                              }
                              onChange={() =>
                                handleChangeAccrdion(StepTemplate._id)
                              }
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography className="d-flex justify-content-between mr-3 w-100">
                                  <div>
                                    <span>
                                      {String.fromCharCode(65 + index)}
                                    </span>
                                    {parse(StepTemplate.Subject)}
                                  </div>
                                  <div>
                                    {StepTemplate?.EModProfileID ===
                                    "6523d182eff732ba5ceead8c"
                                      ? "eMod off"
                                      : StepTemplate?.EModProfileID ===
                                        "6523d182eff732ba5ceead8d"
                                      ? "Campaign Default"
                                      : eModProfile.find(
                                          (profile) =>
                                            profile?._id ===
                                            StepTemplate?.EModProfileID
                                        )?.Profile || ""}
                                  </div>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  {parse(StepTemplate.Body)}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              className="tagpaddnone bg-white inpt-0"
              index={5}
            >
              <div class="paddcols px-4">
                <ResportingTabing Campaignids={Campaign} />
              </div>
            </TabPanel>
          </div>
        </div>
      </MuiThemeProvider>
    </div>
  );
}
