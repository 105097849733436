import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { toast } from "react-toastify";
import ReactApexchart from "react-apexcharts";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import loadingicon from "../../../images/loading.gif";

class AddActionItemsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reset: false,
      fields: {},
      errors: {},
      ClientID: null,
      UserID: null,
      CUserID: null,
      MeetingDate: null,
      WeeklyMeetingStatusId: null,
      DueDate: null,
      Notes: "",
      ToDoItems: "",
      ActionItmesList: [],
      Role: "",
      IsSubmitting:false,
      IsLoading:false
    };

    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title="Add Action Items | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.setState({ Role: Details.Role });
    }
    this.GetActionStatusDropDown();
  }
  // Get Action Status Drop Down
  GetActionStatusDropDown() {
    this.setState({IsLoading:true})
    Axios({
      url: CommonConstants.MOL_APIURL + "/action_items/ActionItemStatusGet",
      method: "GET",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          _id: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ ActionItmesList: option });
        this.setState({ WeeklyMeetingStatusId: option[0]._id });
            this.setState({IsLoading:false})

      } else {
        toast.error(res.data.Message);
            this.setState({IsLoading:false})

      }
    });
  }

  // Form Validation
  async FormValidation() {
    let formIsValid = true;
      let fields = this.state.fields;
      let errors = {};
      var MeetingDate = document.getElementById("MeetingDate").value.trim();
      if (MeetingDate=="") {
        formIsValid = false;
        errors["MeetingDate"] = "Please select meeting date";
      }

      this.setState({ errors: errors });
    return formIsValid;
  }

  OnChangeData = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // handlechange
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.MeetingDate != "" || fields.MeetingDate != null) {
      console.log(fields.MeetingDate)
      this.state.errors.MeetingDate = null;
    } else {
      this.state.errors.MeetingDate = null;
    }
  }

  backbtn() {
    history.push("/actionitems");
  }

  //Save Action Items in Database
  async savebtn(e) {
    this.setState({IsSubmitting:true,IsLoading:true});
    var Final_flag =  await this.FormValidation()
    if (Final_flag == true) {
      var MeetingDates = document.getElementById("MeetingDate").value;
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        MeetingDate: MeetingDates,
        WeeklyMeetingStatusId: this.state.WeeklyMeetingStatusId,
        DueDate: this.state.DueDate,
        Notes: this.state.Notes,
        ToDoItems: this.state.ToDoItems,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/action_items/ActionItemAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Action Item Add
              <br />
              Action item added successfully.
            </div>
          );
          history.push("/actionitems");
        } else {
          toast.error(res.data.Message);
          this.setState({IsSubmitting:false});
    this.setState({IsLoading:false});

        }
      });
    }else{
      this.setState({IsSubmitting:false});
    this.setState({IsLoading:false});

    }
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
        
        <div className="adminmain">
          <Sidebar /> 
          <div className="bodyhome">
           
              <div className="row borderbtnm px-2">
                <div className="col">
                  <h4 className="headertitlepad float-left pt-4">
                    Add Action Items
                  </h4>
                </div>
              </div>

            <div className="bg-white mb-3 px-3">
              <div className="row pb-3 pt-3 px-4 borderbtnm">
                <div className="col-lg-6 boxsinput_group pb-4 px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Action Item Date{" "}
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control datepicker"
                        id="MeetingDate"
                        name="MeetingDate"
                        placeholder=""
                        type="date"
                        autocomplete="off"
                        onChange={this.handleChange.bind(this, "MeetingDate")}
                        value={this.state.fields.MeetingDate}
                      />
                      <span style={{ color: "red" }}>
                    {this.state.errors["MeetingDate"]}
                  </span>
                    </div>
                    
                  </div>
                 
                </div>
                <div className="col-lg-6 boxsinput_group ">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Action Item Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        name="WeeklyMeetingStatusId"
                        value={this.state.WeeklyMeetingStatusId}
                        onChange={this.OnChangeData}
                      >
                        {this.state.ActionItmesList.map((StatusId) => (
                          <option key={StatusId._id} value={StatusId._id}>
                            {StatusId.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Due Date </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control datepicker"
                        id="DueDate"
                        name="DueDate"
                        placeholder=""
                        type="date"
                        autocomplete="off"
                        value={this.state.DueDate}
                        onChange={this.OnChangeData}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row borderbtnm pt-2  px-4 ">
                <div className="col">
                  <h4 className="headertitlepad float-left px-2">Notes</h4>
                </div>
              </div>

              <div className="row borderbtnm px-2">
                <div className="col-lg-12 boxsinput_group">
                  <div className="row  px-4">
                    <div className="col-lg-12 py-3">
                      <textarea
                        className="form-control m-input"
                        placeholder=" "
                        value={this.state.Notes}
                        onChange={this.OnChangeData}
                        id="Notes"
                        name="Notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row borderbtnm pt-2  px-4">
                <div className="col px-4">
                  <h4 className="headertitlepad float-left">To Do Items </h4>
                </div>
              </div>

              <div className="row borderbtnm  px-4 ">
                <div className="col-lg-12 boxsinput_group px-4">
                  <div className="row">
                    <div className="col-lg-12 py-3">
                      <textarea
                        className="form-control m-input"
                        placeholder=" "
                        value={this.state.ToDoItems}
                        onChange={this.OnChangeData}
                        id="ToDoItems"
                        name="ToDoItems"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row mb-5">
                <div className="col-lg-12 pull-left px-3">
                  <button
                    id="submit"
                    disabled={this.state.IsSubmitting}
                    onClick={this.savebtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const  connectedAddActionItemsPage = connect(
  mapState,
  actionCreators
)(AddActionItemsPage);
export { connectedAddActionItemsPage as AddActionItemsPage };
