import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";

class AddGenericEmailDomainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      IsExist: false,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      IsButtonDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    // this.BlurHandler = this.BlurHandler(this);
  }
  componentDidMount() {
    document.title = 'Add Generic Email Domain | SalesHive';
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    document.getElementById("firstname_e").style.display = "none";
  }

  //form validation
  async FromValidation() {
    
    let formIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Domain = document.getElementById("Domain").value.trim();
    var IsExist = await this.CheckExistGenaricEmailDomain(Domain);
    var IsValidate = await this.DomainCheck(Domain)
    if (Domain == "") {
      formIsValid = false;
      Errors["Domain"] = "Please enter domain";
    }else if(IsExist == true){
      formIsValid = false;

    }else if(IsValidate != true){
      formIsValid = false;
      Errors["Domain"] = "Invalid generic email domain"
    }

    this.setState({ Errors: Errors });
    return formIsValid;
  }
  async  DomainCheck(Domain) {
    let regexp = /^[a-zA-Z0-9]+[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]?(?:\.[a-zA-Z]{2,}){1,}$/i;

    if (regexp.test(Domain)) {
        return true;
    } else {
        return false;
    }
}


  async BlurHandler(){
    let formIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Domain = document.getElementById("Domain").value.trim();
    var IsExist = await this.CheckExistGenaricEmailDomain(Domain);
    var IsValidate = await this.DomainCheck(Domain)
    if (Domain == "") {
      formIsValid = false;
      Errors["Domain"] = "Please enter domain";
    }else if(IsExist == true){
      formIsValid = false;

    }else if(IsValidate != true){
      formIsValid = false;
      Errors["Domain"] = "Invalid generic email domain"
    }

    this.setState({ Errors: Errors });
    return formIsValid;
   }
  //change to check error
  HandleChange(Field, e) {
    document.getElementById("firstname_e").style.display = "none";
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Domain != "") {
      this.state.Errors.Domain = null;
      this.state.StatusAvailable = null;
      this.CheckExistGenaricEmailDomain(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Domain = null;
    }
  }

  //check exist name
  async CheckExistGenaricEmailDomain(Domain) {
    
    var str_in = {
      ClientID: this.state.ClientID,
      GenericEmailDomain: Domain,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/genericemaildomain/GenericEmailDomainExists",
      method: "POST",
      data: str_in,
    });
    
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({
          StatusAvailable: resdata.data.Data[0].GenericEmailDomain,
        });
        this.setState({ IsExist: true });
        return true;
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  //back
  BackBtn() {
    history.push("/genericemaildomain");
  }

  //add account category
  async SaveBtn(e) {
    // this.state.Errors.Domain = null;
    // this.state.StatusAvailable = null;
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabled:true});
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Domain = document.getElementById("Domain").value.trim();
      var AddField = {
        GenericEmailDomain: Domain,
        IsDeleted: false,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/genericemaildomain/GenericEmailDomainAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Generic Email Domain Add
              <br />
              Generic email domain added successfully.
            </div>
          );
          history.push("/genericemaildomain");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0">
                    Add Generic Email Domain
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Domain</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        onBlur={()=>{this.BlurHandler()}}
                        id="Domain"
                        name="Domain"
                        placeholder="Domain"
                        type="text"
                      />
                      <label id="firstname_e" className="errorinput">
                        {" "}
                        Please enter Domain{" "}
                      </label>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Domain"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Generic email domains already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row mb-4 pt-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddGenericEmailDomainPage = connect(
  mapState,
  actionCreators
)(AddGenericEmailDomainPage);
export { connectedAddGenericEmailDomainPage as AddGenericEmailDomainPage };
