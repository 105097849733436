import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class AddAccountcustomfield extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      IsExist: false,
      TitleNotNull: false,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      IsButtonDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Add Custom Account Field | SalesHive"
  }

  // form validation
 async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var FieldName = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistAccountClientName(FieldName)
    if (FieldName == "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter field name";
    }
    if(IsExist == true){
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

    // handle change
    HandleChange(field, e) {
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Name != "") {
        this.state.Errors.Name = null;
        this.state.StatusAvailable = null;
        this.CheckExistAccountClientName(e.target.value);
      } else {
        this.state.StatusAvailable = null;
        this.state.Errors.Name = null;
      }
    }

  //check exist Status
  async CheckExistAccountClientName(Name) {
      var str_in = {
        ClientID: this.state.ClientID,
        FieldName: Name,
        Role:this.state.Role
      };
    let resdata = await  Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/customaccounntlist/CustomAccountExists",
        method: "POST",
        data: str_in,
      })
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ StatusAvailable: resdata.data.Data[0]?.FieldName });
            this.setState({ IsExist: true });
            return true;
          } else {
            this.setState({ IsExist: false });
            return false;
          }
        }
      
  }


  BackBtn() {
    history.push("/customaccountfield");
  }

  async SaveBtn(e) {
    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabled:true});
    var Final_flag =  await this.FromValidation()
    if (Final_flag == true) {
      var FieldName = document.getElementById("Name").value.trim();
      var AddField = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        FieldName: FieldName,
        CreatedBy: this.state.CUserID,
        FieldFor: "salesreplyaccount",
        FieldValue: null,
        CreatedDate: new Date(),
        IsDeleted: false,
        IsFieldRequired: false,
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/customaccounntlist/CustomAccountAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Custom Account Field Add<br/>Custom Account field added successfully.</div>);
          history.push("/customaccountfield");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});

        }
      });
    }else{
      // document.getElementById("submit").disabled = false;
      this.setState({IsButtonDisabled:false});

    }
  }
  
  render() {
    const mainTtile = CommonConstants.Title;
    document.title = "Add Custom Account Field" + mainTtile;
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div class=""> 
              <div className="row pt-2">
                <div className="col ml-2">
                  <h4 className="headertitlepad float-left px-0">
                    Add Custom Account Field
                  </h4>
                </div>
              </div>
              <div class="whiterow">
              <div className="row py-3 px-4">
                <div class="col-lg-6 boxsinput_group ml-2">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Field Name</label>
                    <div className="col-lg-7 pl-4">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        // onChange={(e)=>{this.CheckExistAccountClientName1(e)}}
                        id="Name"
                        name="Name"
                        placeholder="Enter field name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Field name already exist.
                        </span>
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <div class="row mb-3 py-4">
                <div class="col-lg-12 pull-left ml-2 px-2">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddAccountcustomfield = connect(
  mapState,
  actionCreators
)(AddAccountcustomfield);
export { connectedAddAccountcustomfield as AddAccountcustomfield };
