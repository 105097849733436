import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ClientKPIsTabing from '../../_components/user/ClientKpis/ClientKpisTabing';
const moment=require('moment');
//import CollapsibleTable from '../../_components/usertable';



class ClientKPIsPage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          FromDate: "",
          ToDate: "",
          IsIncludeOldClient:false

        };
        this.Analyze = this.Analyze.bind(this);
    }
    componentDidMount() {
        document.title = "Client KPIs | SalesHive"
        const currentDate = moment();
const startOfMonth = currentDate.startOf("month").add(1, "days");
document.getElementById("from-date").valueAsDate = startOfMonth.toDate();
        document.getElementById("to-date").valueAsDate = new Date(moment());
        let FromDate = document.getElementById("from-date").value;
        let ToDate = document.getElementById("to-date").value;
        var IsIncludeOldClient=document.getElementById("IsIncludeOldClient").checked;
        this.setState({
          FromDate: FromDate,
          ToDate: ToDate,
          IsIncludeOldClient:IsIncludeOldClient
        });
      }
    
    Analyze() {
       
        var FromDate = document.getElementById("from-date").value;
        var ToDate = document.getElementById("to-date").value;
        var IsIncludeOldClient=document.getElementById("IsIncludeOldClient").checked;
        this.setState({ FromDate: FromDate, ToDate: ToDate,IsIncludeOldClient:IsIncludeOldClient });
      }
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row border-bottom">
                        <div className="col padd-0">
                            <h4 className="headertitlebd">Client KPIs</h4>
                        </div>
                        <div className="col padright">
                             
                        </div>
                    </div>  

                    <div className="row">
                        <div className="col py-3 px-4">  
                            <label class="check_bx">
                                <input type="checkbox" id='IsIncludeOldClient'/><span className="checkmark"></span>
                                Include Old Clients
                            </label> 
                        </div>
                        <div className="col padright">
                            <div className="listing-li float-right padb-15">
                            <div class="btn-group">
                                <input id="fromtab" name="fromtab" type="hidden" value="" />
                                <input class="form-control datepicker" id="from-date" name="from-date" type="date" autocomplete="off"  />
                                <input class="form-control datepicker" id="to-date" name="to-date" type="date" autocomplete="off"  />
                                    <button id="aCompanyState" class="btn btn-primary btnColor m-btn btn-radius" autocomplete="off"  onClick={this.Analyze}>
                                        <i class="la flaticon-diagram"></i>
                                        <span>Compare</span>
                                </button>
                            </div>

                            </div>
                        </div>
                    </div>
                    <span className="devider"></span>

                    <div className="col padd-0">
                        <ClientKPIsTabing FromDates={this.state.FromDate} ToDates={this.state.ToDate} IsIncludeOldClient={this.state.IsIncludeOldClient} />
                    </div>
                </div>

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientKPIsPage = connect(mapState, actionCreators)(ClientKPIsPage);
export { connectedClientKPIsPage as ClientKPIsPage };