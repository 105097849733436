import React, { useEffect } from "react";
const Moment = require("moment");
import Axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import Popup from "reactjs-popup";
import $ from "jquery";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
const Fileupload = require("../../../_helpers/fileupload");

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable({ AccountFieldType }) {
  const [Open, SetOpen] = React.useState(false);
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [SFlag, SetSFlag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [AID, SetAID] = React.useState("");
  const [Checks, SetChecks] = React.useState(false);
  const [OriginalData, SetOriginalData] = React.useState([]);
  const [SortField, SetSortField] = React.useState("AccountName");
  const [SortBy, SetSortBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [TypeList, SetTypeList] = React.useState([]);
  const [HeaderList, SetHeaderList] = React.useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [FileName, SetFileName] = React.useState("");
  const [Csvdata, SetCsvdata] = React.useState([]);
  const [Files, SetFiles] = React.useState([]);
  const [AddArray, SetAddArray] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [AccountName, SetAccountName] = React.useState(null);
  // const [Popup, SetPopup] = React.useState(false);
  const [Cname, SetCname] = React.useState("");
  const [AccountNameLong, SetAccountNameLong] = React.useState(null);
  const [EmailDomains, SetEmailDomains] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [Address1, SetAddress1] = React.useState(null);
  const [Address2, SetAddress2] = React.useState(null);
  const [City, SetCity] = React.useState(null);
  const [State, SetState] = React.useState(null);
  const [Zip, SetZip] = React.useState(null);
  const [Country, SetCountry] = React.useState(null);
  const [AccountCategory, SetAccountCategory] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [AccountOwner, SetAccountOwner] = React.useState(null);
  const [AccountStatus, SetAccountStatus] = React.useState(null);
  const [ReasonUnqualified, SetReasonUnqualified] = React.useState(null);
  const [ClientStatus, SetClientStatus] = React.useState(null);
  const [AccountType, SetAccountType] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [CompanyRevenue, SetCompanyRevenue] = React.useState(null);
  const [EmployeeCount, SetEmployeeCount] = React.useState(null);
  const [AccountNotes, SetAccountNotes] = React.useState(null);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [AccountCount, SetAccountCount] = React.useState([]);
  const [ShowMergePopup, SetShowMergePopup] = React.useState(false);
  const [OwnerMergeDetail, SetOwnerMergeDetail] = React.useState([]);
  const [AccountCategoryMergeDetail, SetAccountCategoryMergeDetail] =
    React.useState([]);
  const [MergeDomainName, SetMergeDomainName] = React.useState("");
  const [AccountCustomMergeDetail, SetAccountCustomMergeDetail] =
    React.useState([]);
  const [AccountCustomMergeValueDetail, SetAccountCustomMergeValueDetail] =
    React.useState([]);

    //Filter States
    const [OpenFilterModel,SetOpenFilterModel] = React.useState(false);
    const [AddFilterModel,SetAddFilterModel] = React.useState(false);
    const [OpenSelectFilterModel,SetOpenSelectFilterModel] = React.useState(false);
    const [CustomColumnData,SetCustomColumnData] = React.useState([]);
    const [FieldAttributes, SetFieldAttributes] = React.useState([{id: Math.floor(Math.random() * 1000000000), ColumnName: 'AccountCategory', InputType: 'Contains',IsCustom:false,IsDate:false,IsBool:false}]);
    const [FilterParameters,SetFilterParameters]=React.useState([]);
    const [IsApply,SetIsApply]=React.useState(false);
    const [FilterName,SetFilterName]=React.useState([]);
    const [EditFieldAttributes,SetEditFieldAttributes]=React.useState([]);
    const [DeletedFilterProperty,SetDeletedFilterProperty]=React.useState([]);
    const [FilterID,SetFilterID]=React.useState(null);
    const [Role,SetRole]=React.useState(null)
    const [ParentUserID,SetParentUserID] = React.useState(null);

  useEffect(() => {
    var GetAccountcateList = GetUserDetails();
    if (GetAccountcateList != null) {
      SetClientID(GetAccountcateList.ClientID);
      SetUserID(GetAccountcateList.ChildUserID);
      SetParentUserID(GetAccountcateList.ParentUserID)
      SetRole(GetAccountcateList.Role);
    }
    GetClientDetails(GetAccountcateList.ClientID).then((result) => {
      SetCname(result[0].Name);
    });
    HeaderListData(GetAccountcateList.ClientID);
    GetAccounlist(GetAccountcateList.ClientID, GetAccountcateList.ParentUserID);
    Exportdetails(GetAccountcateList.ClientID);
    GetCustomFieldsData(GetAccountcateList.ClientID)
    Getfilterlist(GetAccountcateList.ClientID)
  }, [Search, Page, RowsPerPage,FilterParameters,IsApply]);

  const HeaderListData = (CId) => {
    var InputParameters = {
      ClientID: CId,
      Role:Role
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/HeaderListGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {


      SetHeaderList(Result.data.HeaderDetails);
      SetAccountNotes(Result.data.Notesdetails);
    });
  };

  // GetAccounlist
  const GetAccounlist = (CID, UID) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      AccountTypeID: AccountFieldType,
      FilterParameters:FilterParameters,
      IsApply:IsApply,
      Role:Role

    };
    const GetAllAccountList = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
      method: "POST",
      data: InputParameter,
    });
    GetAllAccountList.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  //Search document
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        // document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //Pagination document
  const HandleChangePage = (event, newPage) => {
    if (newPage == Page) {
      SetPage(newPage);
    } else {
      SetPage(newPage);
    }
  };

  // Change Row Selected
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //Delete document meeting
  const DeleteClientName = (ID) => {
    console.log(ID)
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a Account deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText:"No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameter = {
          _id: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role:Role

        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/DeleteAccountList",
          method: "POST",
          data: InputParameter,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Account deleted successfully.", "success");
              document.getElementById("hideloding").style.display = "block";
              GetAccounlist(ClientID, PUserID);
              history.push("/caccounts");
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Export details
  const Exportdetails = (CID) => {
    let InputParameter = {
      ClientID: CID,
      AccountTypeID: AccountFieldType,
      Role:Role

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/ExportAccount",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res?.data);
    });
  };

  const ExportDataTemp = (e) => {
    e.preventDefault();
    toast.success(<div className="toastsize">Data Exported successfully.</div>);
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var FileName = fileInfo.name;
    // this.setState({ Files: fileInfo });
    SetFiles(fileInfo);
    var sep = FileName.split(".");
    if (sep[1] != "csv") {
      // this.setState({ reset: true });
      SetReset(true);
      // this.ResetCsv();
      ResetCsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
      // this.setState({ FileName: csvfile });
      SetFileName(csvfile);

      // this.setState({ Csvdata: data });
      SetCsvdata(data);

      // this.setState({ DropboxData: data[0].data });
      SetDropboxData(data[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  //Hide used option
  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divAccountSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "AccountName") {
      SetAccountName(value);
    } else if (Name == "AccountNameLong") {
      SetAccountNameLong(value);
    } else if (Name == "EmailDomains") {
      SetEmailDomains(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "Address1") {
      SetAddress1(value);
    } else if (Name == "Address2") {
      SetAddress2(value);
    } else if (Name == "City") {
      SetCity(value);
    } else if (Name == "State") {
      SetState(value);
    } else if (Name == "Zip") {
      SetZip(value);
    } else if (Name == "Country") {
      SetCountry(value);
    } else if (Name == "AccountCategory") {
      SetAccountCategory(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "AccountStatus") {
      SetAccountStatus(value);
    } else if (Name == "ClientStatus") {
      SetClientStatus(value);
    } else if (Name == "AccountOwner") {
      SetAccountOwner(value);
    } else if (Name == "ReasonUnqualified") {
      SetReasonUnqualified(value);
    } else if (Name == "AccountType") {
      SetAccountType(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "CompanyRevenue") {
      SetCompanyRevenue(value);
    } else if (Name == "EmployeeCount") {
      SetEmployeeCount(value);
    }
    hideCSVHeaderOptions();
  });

  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };

  // cancel button import
  const CancleAccountBtn = () => {
    ResetCsv();
    SetTotalDisplayCount(false);
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_Account_csv").style.display = "none";
  };

  // map csv
  const AccountMapCsv = () => {
    console.log(Csvdata)

    if (Csvdata.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      console.log('=======')
      ResetCsv();
      DropboxData.map((dddd, index) => {});
    console.log(Csvdata)

    } else {
      ResetCsv();
    console.log(Csvdata)
    console.log('-----')

    }
    hideCSVHeaderOptions();
  };

  // import csv
  const AccountImportCsv = () => {
    document.getElementById("import_Account_csv").style.display = "block";
    document.getElementById("delete_csv").style.display = "none";
  };

  const AccountSaveCsv = () => {
    // if (Csvdata.length >= 100) {
      // toast.success("Account file size is more than 100 successfully.");
    // } else {
      var saveAllAccounts = [];
      var AccountName = parseInt(document.getElementById("AccountName").value);
      if (AccountName >= 0) {
        var ObjAccountName = {
          FieldAlias: "Account Name",
          Fieldname: "AccountName",
          Customfield: null,
          index: AccountName,
        };
        saveAllAccounts.push(ObjAccountName);
      }
      var AccountNameLong = parseInt(
        document.getElementById("AccountNameLong").value
      );
      if (AccountNameLong >= 0) {
        var ObjAccountNameLong = {
          FieldAlias: "Account Name Long",
          Fieldname: "AccountNameLong",
          Customfield: null,
          index: AccountNameLong,
        };
        saveAllAccounts.push(ObjAccountNameLong);
      }
      var EmailDomains = parseInt(
        document.getElementById("EmailDomains").value
      );
      if (EmailDomains >= 0) {
        var ObjEmailDomains = {
          FieldAlias: "Email Domains",
          Fieldname: "EmailDomains",
          Customfield: null,
          index: EmailDomains,
        };
        saveAllAccounts.push(ObjEmailDomains);
      }
      var Website = parseInt(document.getElementById("Website").value);
      if (Website >= 0) {
        var ObjWebsite = {
          FieldAlias: "Website",
          Fieldname: "Website",
          Customfield: null,
          index: Website,
        };
        saveAllAccounts.push(ObjWebsite);
      }
      var Industry = parseInt(document.getElementById("Industry").value);
      if (Industry >= 0) {
        var ObjIndustry = {
          FieldAlias: "Industry",
          Fieldname: "Industry",
          Customfield: null,
          index: Industry,
        };
        saveAllAccounts.push(ObjIndustry);
      }

      var Address1 = parseInt(document.getElementById("Address1").value);
      if (Address1 >= 0) {
        var ObjAddress1 = {
          FieldAlias: "Address 1",
          Fieldname: "Address1",
          Customfield: null,
          index: Address1,
        };
        saveAllAccounts.push(ObjAddress1);
      }

      var Address2 = parseInt(document.getElementById("Address2").value);
      if (Address2 >= 0) {
        var ObjAddress2 = {
          FieldAlias: "Address 2",
          Fieldname: "Address2",
          Customfield: null,
          index: Address2,
        };
        saveAllAccounts.push(ObjAddress2);
      }
      var City = parseInt(document.getElementById("City").value);
      if (City >= 0) {
        var ObjCity = {
          FieldAlias: "City",
          Fieldname: "City",
          Customfield: null,
          index: City,
        };

        saveAllAccounts.push(ObjCity);
      }
      var State = parseInt(document.getElementById("State").value);
      if (State >= 0) {
        var ObjState = {
          FieldAlias: "State",
          Fieldname: "State",
          Customfield: null,
          index: State,
        };
        saveAllAccounts.push(ObjState);
      }

      var Zip = parseInt(document.getElementById("Zip").value);
      if (Zip >= 0) {
        var ObjZip = {
          FieldAlias: "Zip",
          Fieldname: "Zip",
          Customfield: null,
          index: Zip,
        };
        saveAllAccounts.push(ObjZip);
      }

      var Country = parseInt(document.getElementById("Country").value);
      if (Country >= 0) {
        var ObjCountry = {
          FieldAlias: "Country",
          Fieldname: "Country",
          Customfield: null,
          index: Country,
        };
        saveAllAccounts.push(ObjCountry);
      }
      var AccountCategory = parseInt(
        document.getElementById("AccountCategory").value
      );
      if (AccountCategory >= 0) {
        var ObjAccountCategory = {
          FieldAlias: "Account Category",
          Fieldname: "AccountCategory",
          Customfield: null,
          index: AccountCategory,
        };
        saveAllAccounts.push(ObjAccountCategory);
      }
      var AccountOwner = parseInt(
        document.getElementById("AccountOwner").value
      );
      if (AccountOwner >= 0) {
        var ObjAccountOwner = {
          FieldAlias: "Account Owner",
          Fieldname: "AccountOwner",
          Customfield: null,
          index: AccountOwner,
        };
        saveAllAccounts.push(ObjAccountOwner);
      }
      var AccountStatus = parseInt(
        document.getElementById("AccountStatus").value
      );
      if (AccountStatus >= 0) {
        var ObjAccountStatus = {
          FieldAlias: "Account Status",
          Fieldname: "AccountStatus",
          Customfield: null,
          index: AccountStatus,
        };
        saveAllAccounts.push(ObjAccountStatus);
      }
      var ClientStatus = parseInt(
        document.getElementById("ClientStatus").value
      );
      if (ClientStatus >= 0) {
        var ObjClientStatus = {
          FieldAlias: "Client Status",
          Fieldname: "ClientStatus",
          Customfield: null,
          index: ClientStatus,
        };
        saveAllAccounts.push(ObjClientStatus);
      }
      var ReasonUnqualified = parseInt(
        document.getElementById("ReasonUnqualified").value
      );
      if (ReasonUnqualified >= 0) {
        var ObjReasonUnqualified = {
          FieldAlias: "Reason Unqualified",
          Fieldname: "ReasonUnqualified",
          Customfield: null,
          index: ReasonUnqualified,
        };
        saveAllAccounts.push(ObjReasonUnqualified);
      }
      var AccountType = parseInt(document.getElementById("AccountType").value);
      if (AccountType >= 0) {
        var ObjAccountType = {
          FieldAlias: "Account Type",
          Fieldname: "AccountType",
          Customfield: null,
          index: AccountType,
        };
        saveAllAccounts.push(ObjAccountType);
      }
      var CompanyRevenue = parseInt(
        document.getElementById("CompanyRevenue").value
      );
      if (CompanyRevenue >= 0) {
        var ObjCompanyRevenue = {
          FieldAlias: "Company Revenue",
          Fieldname: "CompanyRevenue",
          Customfield: null,
          index: CompanyRevenue,
        };
        saveAllAccounts.push(ObjCompanyRevenue);
      }
      var CompanyPhone = parseInt(
        document.getElementById("CompanyPhone").value
      );
      if (CompanyPhone >= 0) {
        var ObjCompanyPhone = {
          FieldAlias: "Company Phone",
          Fieldname: "CompanyPhone",
          Customfield: null,
          index: CompanyPhone,
        };
        saveAllAccounts.push(ObjCompanyPhone);
      }
      var EmployeeCount = parseInt(
        document.getElementById("EmployeeCount").value
      );
      if (EmployeeCount >= 0) {
        var ObjEmployeeCount = {
          FieldAlias: "Employee Count",
          Fieldname: "EmployeeCount",
          Customfield: null,
          index: EmployeeCount,
        };
        saveAllAccounts.push(ObjEmployeeCount);
      }

      var IsUpdateExistAccounts = document.getElementById(
        "IsUpdateExistAccounts"
      ).checked;
      if (IsUpdateExistAccounts == false) {
        IsUpdateExistAccounts = true;
      } else {
        IsUpdateExistAccounts = false;
      }
      var IsInsertNullValues =
        document.getElementById("IsInsertNullValues").checked;

      var Csvdata = Csvdata;
      var Csvdata2 = Csvdata.length;
      Fileupload.getBase64(Files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ObjData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserContacts",
          File: _base64,
          ImageName: FileName,
          FileData: Csvdata,
          saveAllAccounts: saveAllAccounts,
          ClientID: ClientID,
          UserID: ParentUserID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
          IsUpdateExistAccounts: IsUpdateExistAccounts,
          IsInsertNullValues: IsInsertNullValues,
          Role:Role

        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/ImportCSVAccountData",
          method: "POST",
          data: ObjData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">Account import successfully.</div>
            );
            // var TotalUpdated = res.data.TotalUpdated;
            SetTotalResult(res.data.TotalAccounts);
            SetTotalDuplicate(res.data.TotalDuplicate);
            SetTotalError(res.data.TotalError);
            SetTotalSuccess(res.data.TotalSuccess);
            document.getElementById("map_attribute").style.display = "none";
            document.getElementById("import_Account_csv").style.display =
              "none";
          }
        });
      });
    // }
  };

  // const addEventListener = () => {
  //   // if (PopupVal) {
  //   SetPopup(true);
  //   // }
  // };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val?.SalesReplyAccountID).checked = true;
        } else if (!e.target.checked) {
          document.getElementById(val?.SalesReplyAccountID).checked = false;
        }
      });
    }
  };

  // import Account csv
  // const AccountSaveCsv = () => {
  //   var FirstName = document.getElementById("FirstName").value;

  //   var Csvdata = Csvdata;
  //   Fileupload.getBase64(Files, (result) => {
  //     var rs = result;
  //     var splirs = rs.split("base64,");
  //     var _base64 = splirs[1];

  //     var InputParameter = {
  //       Fields: "Coverimg",
  //       Path: "./Content/UploadedFiles/UserContacts",
  //       File: _base64,
  //       ImageName: FileName,
  //       FileData: Csvdata,
  //       FirstName: FirstName,
  //       ClientID: ClientID,
  //       UserID: UserID,
  //     };
  //     Axios({
  //       url: CommonConstants.MOL_APIURL + "/accounts/ImportCSVAccountData",
  //       method: "POST",
  //       data: InputParameter,
  //     }).then((Res) => {
  //       if (Res.data.StatusMessage == "SUCCESS") {
  //         toast.success(
  //           <div className="toastsize">Account import successfully.</div>
  //         );
  //         // history.push("/accounts");
  //       } else {
  //         toast.error(Res.data.Message);
  //       }
  //     });
  //   });
  // };

  const CancelBulkAccountBtn = () => {
    document.getElementById("delete_csv").style.display = "none";
    // document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute_domain_Accountlist").style.display =
      "none";
  };

  //bulk delete
  const BulkDeleteAccountBtn = () => {
    if (Csvdata.length != 0) {
      document.getElementById(
        "map_attribute_domain_Accountlist"
      ).style.display = "block";

      DropboxData.map((dddd, index) => {});
    } else {
    }
  };

  //open bulkdelete csv
  const AccountBulkDeleteCsv = () => {
    document.getElementById("delete_csv").style.display = "block";
    document.getElementById("import_Account_csv").style.display = "none";
  };

  // Bulk delete Account csv
  const DeleteBulkAccountCsv = () => {
    var DomainName = document.getElementById("DomainName").value;
    Fileupload.getBase64(Files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var BulkDeleteAccountData = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserContacts",
        File: _base64,
        ImageName: FileName,
        FileData: Csvdata,
        DomainName: DomainName,
        Role:Role

      };

      Axios({
        url:
          CommonConstants.MOL_APIURL + "/accounts/ImportCSVAccountBulkDelete",
        method: "POST",
        data: BulkDeleteAccountData,
      }).then((Res) => {
        if (Res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>Account deleted successfully.</div>
          );
          document.getElementById("delete_csv").style.display = "none";
          document.getElementById(
            "map_attribute_domain_Accountlist"
          ).style.display = "none";
          history.push("/caccounts");
        } else {
          toast.error(Res.data.Message);
        }
      });
    });
  };

  //Check box update data
  const CheckBoxClick = (e, row) => {
    if (e.target.checked) {
      var temArray = AddArray;
      temArray.push(row);
      SetAddArray(temArray);
    } else if (!e.target.checked) {
      AddArray.splice(row, 1);
    }
  };

  //Uncheck checkbox

  const Uncheck=()=>{
    AddArray.forEach((el)=>{
      document.getElementById(el._id).checked = false;
    });
    SetAddArray([])
  }

  // Account add to blacklist Domain
  const AccountAddToBlackList = () => {
    if(AddArray.length > 0){
      var AccountParamData = {
        ClientID: ClientID,
        LastUpdatedBy: UserID,
        AccountBlackListArray: AddArray,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AccountToAddToBlackList",
        method: "POST",
        data: AccountParamData,
      }).then((res) => {
        if (
          res.data.StatusMessage == "SUCCESS" &&
          res.data.BlackListlength.length == 0
        ) {
          Swal.fire(
            "Blacklisted",
            "Selected accounts blacklisted successfully.",
            "success"
          );
          Uncheck()
        } else {
          toast.error("Accounts already Blacklisted BlackListed!");
        }
      });
    }else{
      toast.error(<div className="toastsize">Please select atleast one account from list.</div>)
    }
  };

  // View page
  const ViewPage = (ID) => {
    history.push({ pathname: "/cviewaccounts", state: { data: ID } });
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    document.getElementById("hideloding").style.display = "block";
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSFlag(false);
      var SortField = Field;
      var SortedBy;
      if (SortBy == 1) {
        SortedBy = -1;
        SetSortBy(-1);
      } else {
        SortedBy = 1;
        SetSortBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Type: "User",
        ClientID: ClientID,
        UserID: ParentUserID,
        // Role: Role,
        AccountTypeID: AccountFieldType,
        Role:Role

      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    } else {
      SerchBox = true;
      SetSFlag(true);
      var SortField = Field;
      var SortedBy;
      if (SortBy == 1) {
        SortedBy = -1;
        SetSortBy(-1);
      } else {
        SortedBy = 1;
        SetSortBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Type: "User",
        ClientID: ClientID,
        UserID: ParentUserID,
        Role: Role,
        AccountTypeID: AccountFieldType,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    }
  };

  //Merge Data

  const OwnerDetails = (
    OwnerArray,
    AccountCategoryArray,
    SalesReplyIDArray
  ) => {
    var InputParameter = {
      ClientID: ClientID,
      SalesReplyIDArray: SalesReplyIDArray,
      OwnerArray: OwnerArray,
      CategoryAccount: AccountCategoryArray,
      Role:Role

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/OwnerMergeGet",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        SetOwnerMergeDetail(res.data?.DataOwnerAccount);
        SetAccountCategoryMergeDetail(res.data?.DataCateAccount);
        SetAccountCustomMergeDetail(res.data?.DataCustomField);
        SetAccountCustomMergeValueDetail(res.data?.DataCustomFieldValue);
      }
    });
  };

  //Merge domain lists
  const MergeDomain = () => {
    let DomainArray = [];
    AddArray.map((value) => {
      DomainArray.push(value.Salesreply_Accountdetails?.Domain);
    });
    SetMergeDomainName(DomainArray.toString());

    let OwnerArray = [];
    AddArray.map((value) => {
      OwnerArray.push(value?.AccountOwnerID);
    });

    let AccountCategoryArray = [];
    AddArray.map((value) => {
      AccountCategoryArray.push(value?.AccountCategoryID);
    });

    let SalesReplyIDArray = [];
    AddArray.map((value) => {
      SalesReplyIDArray.push(value?._id);
    });

    OwnerDetails(OwnerArray, AccountCategoryArray, SalesReplyIDArray);
    //---------------------------------------------------------------------//
    if (AddArray.length > 1) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to merge an accounts.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, merge it!",
        reverseButtons: true,
      }).then((Result) => {
        if (Result.isConfirmed) {
          SetShowMergePopup(true);
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      toast.error(
        <div className="toastsize">
          Merge Account<br/>Please select atleast two accounts from list.
        </div>
      );
    }
  };

  // Account add to Merge
  const AccountAddToMerge = () => {
    const AccountName = document.getElementById("AccountNameMerge").value;
    const AccountNameLong = document.getElementById(
      "AccountNameLongMerge"
    ).value;
    const Website = document.getElementById("WebsiteMerge").value;
    const Industry = document.getElementById("IndustryMerge").value;
    const AccountCategory = document.getElementById(
      "AccountCategoryMerge"
    ).value;
    const AccountOwner = document.getElementById("AccountOwnerMerge").value;
    const Address1 = document.getElementById("Address1Merge").value;
    const Address2 = document.getElementById("Address2Merge").value;
    const City = document.getElementById("CityMerge").value;
    const State = document.getElementById("StateMerge").value;
    const Zip = document.getElementById("ZipMerge").value;
    const Country = document.getElementById("CountryMerge").value;
    const Domains = document.getElementById("EmailDomainsMerge").value;

    var AccountCustomFieldValueArray = []
   if(AccountCustomMergeValueDetail.length ==0){
    AccountCustomMergeDetail.map((value) => {
      let customval = document.getElementById(value.CustomFieldID+"inp").value;
      let customvalid= value.CustomFieldID;
         if(customval.length !== 0 ){
           AccountCustomFieldValueArray.push({
             customval,
             customvalid
           })
         }
       })
   }else{
    AccountCustomMergeDetail.map((value) => {
      let customval = document.getElementById(value.CustomFieldID+"sel").value;
      let customvalid= value.CustomFieldID;
         if(customval.length !== 0 ){
           AccountCustomFieldValueArray.push({
             customval,
             customvalid
           })
         }
       })
   }

    var AccountParamData = {
      ClientID: ClientID,
      Domain: Domains,
      AccountName: AccountName,
      AccountNameLong: AccountNameLong,
      Website: Website,
      Industry: Industry,
      AccountCategory: AccountCategory,
      AccountOwner: AccountOwner,
      Address1: Address1,
      Address2: Address2,
      City: City,
      State: State,
      Zip: Zip,
      Country: Country,
      LastUpdatedBy: UserID,
      AccountMergeArray: AddArray,
      AccountCustomArr:AccountCustomFieldValueArray,
      Role:Role

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountToAddtoMerge",
      method: "POST",
      data: AccountParamData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success("Account merged successfully.");
        GetAccounlist(ClientID, UserID);
        SetAddArray([]);
        SetShowMergePopup(false);
      } else {
        toast.error("Accounts,Already merged");
      }
    });
  };


  //Filter methods starts from here

  //Close Main filter model box
  const CloseFilterModel = () =>{
    SetOpenFilterModel(false);
    SetAddFilterModel(false);
    SetOpenSelectFilterModel(false);
    SetFieldAttributes([{id: Math.floor(Math.random() * 1000000000), ColumnName: 'AccountCategory', InputType: 'Contains',IsCustom:false,IsDate:false,IsBool:false}])
  }

  //Open Add Filter model
  const OpenAddFilterModel = () =>{
    SetAddFilterModel(true);
  }

  //Close OpenAdd Filter model
  const CloseAddFilterModel = () =>{
    SetOpenFilterModel(false);
    SetAddFilterModel(false);
    SetOpenSelectFilterModel(false);
    SetFieldAttributes([{id: Math.floor(Math.random() * 1000000000), ColumnName: 'AccountCategory', InputType: 'Contains',IsCustom:false,IsDate:false,IsBool:false}])
  }

  //Open select exist filter model
  const OpenSelectExistFilterMode = () =>{
    SetOpenSelectFilterModel(true)
  }

  //Close Select exist filter model
  const CloseSelectExistFilterMode = () =>{
    SetOpenFilterModel(false);
    SetAddFilterModel(false);
    SetOpenSelectFilterModel(false);
  }

    //Get Custom fields
 const GetCustomFieldsData = (CID) =>{
  var InputParameters = {
    ClientID: CID,
    Role:Role

  };
  const rows1 = Axios({
    url: CommonConstants.MOL_APIURL + "/accounts/GetCustomFields",
    method: "POST",
    data: InputParameters,
  });
  rows1.then((Result) => {

    let Arr = [
      {FieldValue:"AccountCategory",ColumnName:"Account Category",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"AccountClientStatus",ColumnName:"Account Client Status",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"AccountName",ColumnName:"Account Name",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"AccountOwner",ColumnName:"Account Owner",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"AccountType",ColumnName:"Account Type",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"Address1",ColumnName:"Address1",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"Address2",ColumnName:"Address2",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"City",ColumnName:"City",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"CompanyPhone",ColumnName:"Company Phone",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"Country",ColumnName:"Country",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"CreatedDate",ColumnName:"Created Date",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"EmailDomains",ColumnName:"Email Domains",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"Industry",ColumnName:"Industry",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"ReasonUnqualified",ColumnName:"Reason Unqualified",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"State",ColumnName:"State",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"Website",ColumnName:"Website",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""},
      {FieldValue:"Zip",ColumnName:"Zip",Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:""}
    ];
    Result.data.Data.forEach((el)=>{
      Arr.push({FieldValue:el.FieldName,ColumnName:el.FieldName,Expression:"Contains",FilterPropertyValue:"",FilterPropertyValue1:"",IsCustom:true})
    })
    let ChangedArr = [];
    Arr.forEach((el,i)=>{
      if(el.FieldValue == "CreatedDate" ){
        ChangedArr.push({id:i,FieldValue:el.FieldValue,ColumnName:el.ColumnName,Expression:"EqualTo",FilterPropertyValue:"",FilterPropertyValue1:"",IsCustom:false,IsDate:true})
      }else{
        if(el.IsCustom == true){
          ChangedArr.push({id:i,FieldValue:el.FieldValue,ColumnName:el.ColumnName,Expression:"Contains",FieldType:"Text",FilterPropertyValue:"",FilterPropertyValue1:"",IsCustom:true,IsDate:false})
        }else{
          ChangedArr.push({id:i,FieldValue:el.FieldValue,ColumnName:el.ColumnName,Expression:"Contains",FieldType:"",FilterPropertyValue:"",FilterPropertyValue1:"",IsCustom:false,IsDate:false})

        }
      }
    });

    let SortedArr = ChangedArr.sort(function (a, b) {return a.ColumnName.toLowerCase().localeCompare(b.ColumnName.toLowerCase());});

    SetCustomColumnData(SortedArr)
  })

}

//Column handled Dropdown Change
const ColumnhandledDropdownChange = (id,Index) => {

    let ColumnName = document.getElementById("ColumnName" +id).value;

    let FieldAttributesDatas = [];
    FieldAttributes.forEach((elem)=>{
      FieldAttributesDatas.push(elem);
    })
    let indexed = CustomColumnData.findIndex(v => v.FieldValue === ColumnName);

    FieldAttributesDatas[Index].ColumnName = ColumnName;

    FieldAttributesDatas[Index].IsCustom = CustomColumnData[indexed].IsCustom;
    FieldAttributesDatas[Index].IsDate = CustomColumnData[indexed].IsDate;


    SetFieldAttributes(FieldAttributesDatas)
}

// Expression Handler drop down change
const ExpressionHandler = (id) =>{
  let StateArr = [];
  FieldAttributes.forEach((elem)=>{
    StateArr.push(elem)
  })
  let ColumnName = document.getElementById("ColumnName" +id).value;
   let InputType = document.getElementById("expression" +id).value;
    let indexed = StateArr.findIndex(v => v.id === id);
    if(indexed == -1){
      StateArr.push({id:id,ColumnName:ColumnName,InputType:InputType})
    }else{
      StateArr[indexed].ColumnName=ColumnName;
      StateArr[indexed].InputType = InputType;
    }

    SetFieldAttributes(StateArr)
}

//Add filter property
const  handleClick = () => {
  let StateArray = [];
  FieldAttributes.forEach((elem)=>{
    StateArray.push(elem)
  })
  StateArray.push({id: Math.floor(Math.random() * 1000000000), ColumnName: '"AccountCategory"', InputType: 'Contains',IsCustom:false,IsDate:false,IsBool:false});
  SetFieldAttributes(StateArray)
};

//remove filter property
const handleRemoveClick = (id) => {
  var countArray2 = [];
 FieldAttributes.map((element) => {
    if (element.id != id) {
      countArray2.push(element);
    }
  });

  SetFieldAttributes(countArray2)
};

 // filter validation
const FilterValidation = () => {
  let ValidationArr=[];

  FieldAttributes.map((newvalue) => {

    if(newvalue.IsBool == false){
      var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value;
      if(searchedVal1.trim() == ""){
        ValidationArr.push(false)
      }else{
        ValidationArr.push(true)
      }
      var expressionval = document.getElementById("expression" + newvalue.id).value;
      if(expressionval== "between"){
        let FilterPropertyValue2 = document.getElementById("EndsWith" +newvalue.id).value;
        if(FilterPropertyValue2.trim() == ""){
          ValidationArr.push(false)
        }else{
          ValidationArr.push(true)
        }
      }
    }

   });

   return ValidationArr.includes(false);


}

const filterSearch = () => {
  if(FilterValidation() == false){
    if(FieldAttributes.length <= 0){
      toast.error(<div className="toastsize">Filter<br/>Please add filter property at least one</div>)
    }else{

    var AccountsFilterSecArray = [];
   FieldAttributes.map((newvalue) => {
      var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value;
      var expressionval = document.getElementById("expression" + newvalue.id).value;
      var ColumnName = document.getElementById("ColumnName" + newvalue.id).value;
      if(expressionval == "between"){
      var searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value;
        AccountsFilterSecArray.push({
          ColumnName: ColumnName,
          expression: expressionval,
          searchvalue: searchedVal1,
          searchvalue1: searchedVal2,
        });
      }else{
        AccountsFilterSecArray.push({
          ColumnName: ColumnName,
          expression: expressionval,
          searchvalue: searchedVal1,
          searchvalue1: null,

        });
      }
     });

    SetFilterParameters(AccountsFilterSecArray);
    SetIsApply(true);
    SetFieldAttributes([])
    SetAddFilterModel(false)
    SetOpenFilterModel(false)
    SetFieldAttributes([{id: Math.floor(Math.random() * 1000000000), ColumnName: "AccountCategory", InputType: 'Contains',IsCustom:false,IsDate:false}]);
    toast.success(<div className="toastsize">Filter<br/>Filter Apply SuccessFully.</div>)
  }

  }else{
    toast.error(<div className="toastsize">Filter<br/>
      Property value can not be an empty</div>)
  }
}

//Reset Filter data
const ResetFilter = () =>{
  SetIsApply(false);
  SetFieldAttributes([{id: Math.floor(Math.random() * 1000000000), ColumnName: '"AccountCategory"', InputType: 'Contains',IsCustom:false,IsDate:false,IsBool:false}]);
}

    //Check filter name exists or not
    const CheckFilterName = async()=>{
      let FilterName = document.getElementById('FilterName').value.trim();
      let flag=false;
      const InputParameters = {
        ClientID:ClientID,
        FilterName:FilterName,
        Role:Role

      }
     let res = await Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/CheckAccountsFilterName",
        method:"POST",
        data:InputParameters
      })
        if(res.data.StatusMessage == "SUCCESS"){
          if(res.data.Data <= 0){
            flag = true
          }else{
            flag = false
          }
        }else{
          toast.error("Something went wrong in filter name exists api")
        }

      return flag;
    }

        // Save Filter Property
     const addfilterAccountfilter = async() =>{
      let FilterName = document.getElementById('FilterName').value.trim();
      if(FilterName == ""){
        toast.error(<div className="toastsize">Filter<br/>Please enter filter name</div>)
      }else{
        if(await CheckFilterName() == false){
          toast.error(<div className="toastsize">Filter<br/>Filter value already exist</div>)
        }else{
          if(FilterValidation() == false){
            if(FieldAttributes.length <= 0){
              toast.error(<div className="toastsize">Filter<br/>Please add filter property at least one</div>)
            }else{
            var AccountFilterSecArray = [];
            FieldAttributes.map((newvalue) => {
              var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value;
              var expressionval = document.getElementById("expression" + newvalue.id).value;
              var Cloumnval = document.getElementById("ColumnName" + newvalue.id).value;
              let indexed = CustomColumnData.findIndex(v=>v.FieldValue == Cloumnval);
              if(expressionval == "between"){
              var searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value;
                AccountFilterSecArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: searchedVal2,
                  FieldType:CustomColumnData[indexed].FieldType
                });
              }else{
                  AccountFilterSecArray.push({
                    ColumnName: Cloumnval,
                    expression: expressionval,
                    searchvalue: searchedVal1,
                    searchvalue1: null,
                    FieldType:CustomColumnData[indexed].FieldType
                  });
              }
             });

             const InputParameters = {
              ClientID:ClientID,
              FilterName:FilterName,
              FilterParameters:AccountFilterSecArray,
              CreatedDate:new Date(),
              UserID:ParentUserID,
              Role:Role

             }

             let res = await Axios({
              url:CommonConstants.MOL_APIURL + "/accounts/SaveAccountsFilter",
              method:"POST",
              data:InputParameters
             });

             if(res.data.StatusMessage == "SUCCESS"){
              SetFilterParameters(AccountFilterSecArray);
              SetIsApply(true);
              SetFieldAttributes([])
              SetAddFilterModel(false)
              SetOpenFilterModel(false)
              SetFieldAttributes([{id: Math.floor(Math.random() * 1000000000), ColumnName: '"AccountCategory"', InputType: 'Contains',IsCustom:false,IsDate:false}]);
              Getfilterlist(ClientID);
              toast.success(<div className="toastsize">Filter<br/>Filter Added SuccessFully.</div>)
             }else{
              toast.error("Error in save filter")
             }
            }
          }else{
            toast.error(<div className="toastsize">Filter<br/>Property value can not be an empty</div>)
          }
        }

      }
    }

     // Get Filter List
 const Getfilterlist = (CID)=> {
  var InputParameters = {
    ClientID: CID,
    Role:Role

  };

  const rows3 = Axios({
    url: CommonConstants.MOL_APIURL + "/accounts/GetAccountsFilterDropDown",
    method: "POST",
    data: InputParameters,
  });
  rows3.then((result) => {

    SetFilterName(result.data.Data)
    if(result.data.Data.length > 0){
      Getfilterpropertylist(result.data.Data[0]._id);
    }
  });
}

//Edit Accounts Filter Change
const handleChangeFilter = () => {
  var filterdropoid = document.getElementById("FilterID").value;
  Getfilterpropertylist(filterdropoid);
}

 // Get FilteProperty list with dropdown
 const Getfilterpropertylist = (filterid)=> {

  SetFilterID(filterid)

  var InputParameters = {
    ClientID: ClientID,
    FilterID: filterid,
    IsDeleted: false,
    Role:Role

  };
  const rows4 = Axios({
    url: CommonConstants.MOL_APIURL + "/accounts/GetAccountsEditFilterProperties",
    method: "POST",
    data: InputParameters,
  });
  rows4.then((result) => {

    let FilteredArr = [];
    result.data.Data.forEach((el,i)=>{
        el.id = i;
        el.IsSaved=true;
        if(el.FilterPropertyName == "CreatedDate"){
          el.IsDate=true
        }else{
          if(el.FieldType == "Text"){
            el.IsCustom=true
          }else{
            el.IsCustom=false
          }
          el.IsDate=false
        }

        FilteredArr.push(el)
    })

    SetEditFieldAttributes(FilteredArr)
  });
}

 //Edit column name
 const EditColumnName = (ID) =>{

  let SavedData = [];
  EditFieldAttributes.forEach((elem)=>{
    SavedData.push(elem)
  })
  //
  let FilterPropertyName = document.getElementById("EditColumnName"+ID).value;

  let indexed = SavedData.findIndex(v => v.id === ID);
  let Customs = CustomColumnData.findIndex(v=>v.FieldValue == FilterPropertyName);

  SavedData[indexed].FilterPropertyName = FilterPropertyName;

  if(SavedData[indexed].FilterPropertyName == "CreatedDate" ){
    SavedData[indexed].IsDate = true;
  }else{
    SavedData[indexed].IsDate = false;

    if(CustomColumnData[Customs].IsCustom == true){
      SavedData[indexed].IsCustom = true;
    }else{
      SavedData[indexed].IsCustom = false;
    }

  }
  //
  SetEditFieldAttributes(SavedData)

}

//Edit Expression value
const EditExpressValue = (ID)=>{
  let SavedData = [];
  EditFieldAttributes.forEach((elem)=>{
    SavedData.push(elem)
  })
  let ExpressionName = document.getElementById("Editexpression" +ID).value;

  let indexed = SavedData.findIndex(v => v.id === ID);
  SavedData[indexed].ExpressionName = ExpressionName;

  SetEditFieldAttributes(SavedData)
}

//Edit FilterPropertyValue1
const EditFilterPropertyValue1 = (ID)=>{
    let SavedData = [];
    EditFieldAttributes.forEach((elem)=>{
      SavedData.push(elem)
    })
    let FilterPropertyValue1 = document.getElementById("Editsearchvalue" +ID).value;
    let indexed = SavedData.findIndex(v => v.id === ID);
    SavedData[indexed].FilterPropertyValue1 = FilterPropertyValue1;

    SetEditFieldAttributes(SavedData)

}

//Edit FilterPropertyValue2
const EditFilterPropertyValue2 = (ID)=>{
    let SavedData = [];
    EditFieldAttributes.forEach((elem)=>{
      SavedData.push(elem)
    })
    let FilterPropertyValue2 = document.getElementById("EditEndsWith" +ID).value;
    let indexed = SavedData.findIndex(v => v.id === ID);
    SavedData[indexed].FilterPropertyValue2 = FilterPropertyValue2;
    SetEditFieldAttributes(SavedData)
}

//Add new filter property
const handleeditPropertyClick = () => {
  let StateArray = [];
  EditFieldAttributes.forEach((elem)=>{
    StateArray.push(elem)
  })
  StateArray.push({id: StateArray.length, FilterPropertyName: 'AccountCategory', ExpressionName: 'Contains',IsCustom:false,IsDate:false,IsBool:false,IsSave:false});
  SetEditFieldAttributes(StateArray)
};

//Remove filter property
const handleEditRemoveClick = (id) => {
  let SavedData = [];
  EditFieldAttributes.forEach((elem)=>{
    SavedData.push(elem)
  })
  if(SavedData.length <=1){
      toast.error(<div>Filter<br/>Required filter property at least one</div>)
  }else{
  let DeletedArr = DeletedFilterProperty;
  const FilterPropertyID = SavedData.findIndex((item)=>item.id == id);

  if(SavedData[FilterPropertyID].IsSaved == true){
    DeletedArr.push(SavedData[FilterPropertyID].FilterPropertyID)
    SetDeletedFilterProperty(DeletedArr)
  }
  const FilteredData = SavedData.filter((item) => item.id !== id);
  SetEditFieldAttributes(FilteredData)
}
};

 //Apply Edit Filter
const ApplyEditFilter = () =>{
  if(UpdateFilterValidation() == false){
      var AccountsFilterSecArray = [];
  EditFieldAttributes.map((el) => {

    if(el.ExpressionName == "between"){
      AccountsFilterSecArray.push({
        ColumnName: el.FilterPropertyName,
        expression: el.ExpressionName,
        searchvalue: el.FilterPropertyValue1,
        searchvalue1: el.FilterPropertyValue2,
      });
    }else{
      AccountsFilterSecArray.push({
        ColumnName: el.FilterPropertyName,
        expression: el.ExpressionName,
        searchvalue: el.FilterPropertyValue1,
        searchvalue1: null,

      });
    }
   });

   SetFilterParameters(AccountsFilterSecArray);
   SetIsApply(true);
   SetOpenFilterModel(false);
   SetAddFilterModel(false);
   SetOpenSelectFilterModel(false);
   toast.success(<div className="toastsize">Filter <br/> Filter Apply SuccessFully.</div>)
}else{
  toast.error(<div className="toastsize">Filter<br/>
    Property value can not be an empty</div>)
}
}

//Update Filter validation
const UpdateFilterValidation = () => {
  let Flags = [];
  EditFieldAttributes.forEach((el)=>{

    var searchedVal1 = document.getElementById("Editsearchvalue" + el.id).value;

      if(searchedVal1.trim() == ""){
        Flags.push(false)
      }else{
        Flags.push(true)
      }
      if(el.ExpressionName == "between"){
        let FilterPropertyValue2 = document.getElementById("EditEndsWith" +el.id).value;
        if(FilterPropertyValue2.trim() == ""){
          Flags.push(false)
        }else{
          Flags.push(true)
        }
      }
  });

     return Flags.includes(false);

}

  //Update and apply filter
const editapplyfilterSearch = async ()=> {
if(UpdateFilterValidation() == false){
    let UpdatingParams = [];
    EditFieldAttributes.forEach((elem)=>{
      if(elem.IsCustom == true){
        elem.FieldType = "Text"
        UpdatingParams.push(elem)
      }else{
        UpdatingParams.push(elem)
      }
    });

  const InputParameters = {
    ClientID:ClientID,
    FilterParameters:UpdatingParams,
    LastUpdatedDate:new Date(),
    UserID:ParentUserID,
    FilterID:FilterID,
    DeletedFilterProperty:DeletedFilterProperty,
    Role:Role

   }


   let res = await Axios({
    url:CommonConstants.MOL_APIURL + "/accounts/UpdateAccountsFilterProperties",
    method:"POST",
    data:InputParameters
   })
    if(res.data.StatusMessage == "SUCCESS"){
      toast.success(<div className="toastsize">Filter <br/>Filter Updated SuccessFully.</div>)
      var AccountsFilterSecArray = [];
      EditFieldAttributes.map((el) => {

        if(el.ExpressionName == "between"){
          AccountsFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: el.FilterPropertyValue2,
          });
        }else{
          AccountsFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: null,

          });
        }
       });
       SetFilterParameters(AccountsFilterSecArray);
       SetIsApply(true);
     }else{
      toast.error("Error in save filter")
     }


     SetOpenFilterModel(false);
     SetAddFilterModel(false);
     SetOpenSelectFilterModel(false);

}else{
  toast.error(<div className="toastsize">Filter<br/>
    Property value can not be an empty</div>)
}
}

  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="px-0">
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left py-1 pl-2">All</h4>
          <div className="listing-li float-right mb-3 iconbox-pad">
            <ul className="mt-0">
              <li>
                <Tooltip title="Add">
                  <a
                    onClick={()=>{history.push("/caddaccounts")}}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i class="la la-icon-font-size-13 la-plus"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Import">
                  <a
                    onClick={AccountImportCsv}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i class="la la-icon-font-size-13 la-upload"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                  <CSVDownloader className="px-0"
                    data={ExportData}
                    filename={Cname + " Account-Csv"}
                    bom={true}
                  >
                    <Tooltip title="Export"><a
                      onClick={ExportDataTemp}
                      className="btn btngroup m-btn m-btn--custom"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                    </a></Tooltip>
                  </CSVDownloader>

              </li>
              <li>
                <Tooltip title="Merge">
                  {/* <a href="" className="btn btngroup m-btn m-btn--custom">
                    <i class="la la-icon-font-size-13 la-arrows-h"></i>
                  </a> */}
                  <button
                    onClick={MergeDomain}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i class="la la-icon-font-size-13 la-arrows-h"></i>
                  </button>
                </Tooltip>
                <Popup open={ShowMergePopup}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup maxpopupfull">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Merge Accounts</h5>
                          <button className="close" onClick={()=>{SetShowMergePopup(false);}}>
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>

                      <div className="paddingboxTerms">
                        <div className="modal-body cardtempls">
                          <div className="row">
                            <label class="col-lg-3 form-label max-w-144">
                              Email Domains
                            </label>
                            <div class="col-lg-9 nopaddingLeft">
                              <input
                                class="form-control m-input"
                                disabled="disabled"
                                id="EmailDomainsMerge"
                                type="text"
                                value={MergeDomainName}
                              />
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div class="col-lg-6 row">
                              <label class="col-lg-5 form-label">
                                Account Name
                              </label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="AccountNameMerge"
                                  type="text"
                                  id="AccountNameMerge"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 row">
                              <label class="col-lg-5 form-label">
                                Account Name Long
                              </label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="AccountNameLongMerge"
                                  type="text"
                                  id="AccountNameLongMerge"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div class="col-lg-6 row">
                              <label class="col-lg-5 form-label">Website</label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="WebsiteMerge"
                                  type="text"
                                  id="WebsiteMerge"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 row">
                              <label class="col-lg-5 form-label">
                                Industry
                              </label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="IndustryMerge"
                                  type="text"
                                  id="IndustryMerge"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div class="col-lg-6 row">
                              <label class="col-lg-5 form-label">
                                Account Category
                              </label>
                              <div class="col-lg-7">
                                <select
                                  autocomplete="off"
                                  class="form-control m-input"
                                  id="AccountCategoryMerge"
                                  name="Website"
                                >
                                  {AccountCategoryMergeDetail.map((val) => (
                                    <option
                                      value={val?._id}
                                    >
                                      {val?.Name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div class="col-lg-6 row">
                              <label class="col-lg-5 form-label">
                                Account Owner
                              </label>
                              <div class="col-lg-7">
                                <select
                                  autocomplete="off"
                                  class="form-control m-input"
                                  id="AccountOwnerMerge"
                                  name="Website"
                                >
                                  {OwnerMergeDetail.map((val) => (
                                    <option value={val?._id}>
                                      {val?.Name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div class="col-lg-6 row ">
                              <label class="col-lg-5 form-label">
                                Address1
                              </label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="Address1Merge"
                                  type="text"
                                  id="Address1Merge"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 row ">
                              <label class="col-lg-5 form-label">
                                Address2
                              </label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="Address2Merge"
                                  type="text"
                                  id="Address2Merge"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div class="col-lg-6 row ">
                              <label class="col-lg-5 form-label">City</label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="CityMerge"
                                  type="text"
                                  id="CityMerge"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 row ">
                              <label class="col-lg-5 form-label">State</label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="StateMerge"
                                  type="text"
                                  id="StateMerge"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div class="col-lg-6 row ">
                              <label class="col-lg-5 form-label">Zip</label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="ZipMerge"
                                  type="text"
                                  id="ZipMerge"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 row ">
                              <label class="col-lg-5 form-label">Country</label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  name="CountryMerge"
                                  type="text"
                                  id="CountryMerge"
                                />
                              </div>
                            </div> 
                          </div>
                            <div className="row mt-4">
                              {AccountCustomMergeDetail.map((mergeval) => {
                                return (
                                  <div class="col-lg-6 row">
                                    <label class="col-lg-5 form-label">
                                      {mergeval?.FieldName}
                                    </label>
                                    {AccountCustomMergeValueDetail.length ==
                                    0 ? (
                                      <div class="col-lg-7">
                                        <input
                                          class="form-control m-input"
                                          name="CustomMerge"
                                          type="text"
                                          id={mergeval.CustomFieldID+"inp"}
                                        />
                                      </div>
                                    ) : (
                                      <div class="col-lg-7">
                                        <select
                                          autocomplete="off"
                                          class="form-control m-input"
                                          name="Website"
                                          id={mergeval.CustomFieldID+"sel"}
                                        >
                                          {AccountCustomMergeValueDetail.map(
                                            (val) => (
                                              <option
                                                value={val?._id}
                                              >
                                                {val?.FieldValue}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                        </div>
                      </div>

                      <div class="modal-footer">
                        <button
                          onClick={() => {
                            SetShowMergePopup(false);
                          }}
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <a
                          onClick={AccountAddToMerge}
                          id="SubmitMerge"
                          href="javascript:void(0);"
                          class="btn btn-primary btn-lightgreen"
                        >
                          <i class="la la-save"></i> Save
                        </a>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>
              <li>
                <Tooltip title="Bulk Delete">
                  <a
                    onClick={AccountBulkDeleteCsv}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i class="la flaticon-delete-1"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Add to BlackList">
                  <a
                    onClick={AccountAddToBlackList}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i class="fa la-icon-font-size-13 fa-ban"></i>
                  </a>
                </Tooltip>
              </li>
              <li><Tooltip title="Add Filter">
                <a
                  onClick={()=>{SetOpenFilterModel(true);}}
                  className="btn btngroup m-btn m-btn--custom"
                >
                  <i class="fa la-icon-font-size-13 fa-filter"></i>
                </a>
                </Tooltip>
              </li>

              <li>
                <Tooltip title="Custom Column">
                  <a
                    onClick={()=>{history.push('/caccountcustomcolumn')}}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la flaticon-interface-8"></i>
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
     </div>

      <div
        className="portletbody"
        id="import_Account_csv"
        style={{ display: "none" }}
      >
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Import Account Csv</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add All data from csv file to import.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a onClick={AccountMapCsv} className="btn btn-primary btnColor mr-1">
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={CancleAccountBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody portletshadow p-3"
        id="map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="row" id="divAccountSelection">
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn1"
                >
                  <option selected="" value="AccountName">
                    Account Name
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="AccountName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountName == null
                      ? DropboxData.indexOf("AccountName")
                      : AccountName
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn2"
                >
                  <option selected="" value="AccountNameLong">
                    Account Name Long
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="AccountNameLong"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountNameLong == null
                      ? DropboxData.indexOf("Account Name Long")
                      : AccountNameLong
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn3"
                >
                  <option selected="" value="EmailDomains">
                    Email Domains
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="AccountName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    EmailDomains == null
                      ? DropboxData.indexOf("Email Domains")
                      : EmailDomains
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn4"
                >
                  <option selected="" value="Website">
                    Website
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="Website"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Website == null ? DropboxData.indexOf("Website") : Website
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn5"
                >
                  <option selected="" value="Address1">
                    Address 1
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="Address1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address1 == null
                      ? DropboxData.indexOf("Address1")
                      : Address1
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn6"
                >
                  <option selected="" value="Address2">
                    Address 2
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="Address2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address2 == null
                      ? DropboxData.indexOf("Address2")
                      : Address2
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn7"
                >
                  <option selected="" value="City">
                    City
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="City"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={City == null ? DropboxData.indexOf("City") : City}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn8"
                >
                  <option selected="" value="State">
                    State
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="State"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={State == null ? DropboxData.indexOf("State") : State}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn9"
                >
                  <option selected="" value="Zip">
                    Zip
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="Zip"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Zip == null ? DropboxData.indexOf("Zip") : Zip}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn10"
                >
                  <option selected="" value="Country">
                    Country
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="Country"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Country == null ? DropboxData.indexOf("Country") : Country
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn11"
                >
                  <option selected="" value="AccountCategory">
                    Account Category
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="AccountCategory"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountCategory == null
                      ? DropboxData.indexOf("Account Category")
                      : AccountCategory
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn12"
                >
                  <option selected="" value="Industry">
                    Industry
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="Industry"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Industry == null
                      ? DropboxData.indexOf("Industry")
                      : Industry
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn13"
                >
                  <option selected="" value="AccountOwner">
                    Account Owner
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="AccountOwner"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountOwner == null
                      ? DropboxData.indexOf("Account Owner")
                      : AccountOwner
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn14"
                >
                  <option selected="" value="AccountStatus">
                    Account Status
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="AccountStatus"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountStatus == null
                      ? DropboxData.indexOf("Account Status")
                      : AccountStatus
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn15"
                >
                  <option selected="" value="ReasonUnqualified">
                    Reason Unqualified
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="ReasonUnqualified"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ReasonUnqualified == null
                      ? DropboxData.indexOf("Reason Unqualified")
                      : ReasonUnqualified
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn16"
                >
                  <option selected="" value="ClientStatus">
                    Client Status
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="ClientStatus"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ClientStatus == null
                      ? DropboxData.indexOf("Client Status")
                      : ClientStatus
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn17"
                >
                  <option selected="" value="AccountType">
                    Account Type
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="AccountType"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountType == null
                      ? DropboxData.indexOf("Account Type")
                      : AccountType
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn18"
                >
                  <option selected="" value="CompanyPhone">
                    Company Phone
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="CompanyPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyPhone == null
                      ? DropboxData.indexOf("Company Phone")
                      : CompanyPhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn19"
                >
                  <option selected="" value="CompanyRevenue">
                    Company Revenue
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="CompanyRevenue"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyRevenue == null
                      ? DropboxData.indexOf("Company Revenue")
                      : CompanyRevenue
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                  id="dbColumn20"
                >
                  <option selected="" value="EmployeeCount">
                    Employee Count
                  </option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="EmployeeCount"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    EmployeeCount == null
                      ? DropboxData.indexOf("Employee Count")
                      : EmployeeCount
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div class="form-group m-form__group row col-lg-6 mx-0 pl-4">
            <div class="toggleswich">
              <input
                type="checkbox"
                class="checktogle"
                id="IsUpdateExistAccounts"
              />
              <b class="switch">
                <span class="checkion"></span>
                <span class="uncheckion"></span>
              </b>
              <b class="track"></b>
            </div>
            <label> Skip updating existing contacts</label>
          </div>
          <div class="form-group m-form__group row col-lg-6 mx-0 pl-4">
            <div class="toggleswich">
              <input
                type="checkbox"
                class="checktogle"
                id="IsInsertNullValues"
              />
              <b class="switch">
                <span class="checkion"></span>
                <span class="uncheckion"></span>
              </b>
              <b class="track"></b>
            </div>
            <label> Insert Null Values</label>
          </div>
          <div className="col-xs-12 border-top pt-4 my-2 px-4">
            <a
              onClick={AccountSaveCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div>
        </div>
      </div>

      {/* Delete for csv */}
      {/* Bulk Csv for */}
      <div className="portletbody" id="delete_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Bulk deleted contact</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add account data from csv file to bulk delete.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a
                onClick={BulkDeleteAccountBtn}
                className="btn btn-primary btnColor mr-1"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={CancelBulkAccountBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {TotalDisplayCount == true ? (
        <div className="portletbody px-4" id=" ">
          <div className="row col-xs-12">
            <h4 className="headertitle float-left">Results</h4>
          </div>

          <div className="col-xs-12">
            <div class="row colfive">
              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-primary">
                          {TotalResult}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-success">
                          {TotalSuccess}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total success</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-danger">
                          {TotalError}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total error</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-warning">
                          {TotalDuplicate}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total Duplicate</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Mapped div */}
      <div
        className="portletbody portletshadow p-4 mt-3 mx-3"
        id="map_attribute_domain_Accountlist"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="row">
          <div className="form-group m-form__group row col-lg-6 mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="Email">
                    Domain
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  id="DomainName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                >
                  <option value="not mapped">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div> 
          </div>
        </div>
          <div className="col-xs-12 border-top pt-4 my-2 px-2">
            <a
              onClick={DeleteBulkAccountCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div>
      </div>
      {
        IsApply == true ? (<a className="float-right" onClick={()=>{ResetFilter()}}>Reset</a>):null
      }
      <div className="px-2">
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 pt-4">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>

        </div>
      </div>
        <Popup open={OpenFilterModel}>

            <div>
              <div className="modal-black"></div>
              <div className="filterPopup">
                <div className="paddingboxTerms">
                  <div className="modal-header py-4">
                    <h5>Filter</h5>
                    <a
                      className="close-ion"
                      onClick={()=>{CloseFilterModel();}}
                    >
                      <span aria-hidden="true" class="la la-remove"></span>
                    </a>

                    {/* <button className="close" onClick={close}></button> */}
                  </div>
                  <div className="modal-content bordernone p-0">
                    <div className="row">
                      <div className="col-xl-6 allcentertext">
                        <i class="la la-filter iconsfonts"></i>
                        <a
                          id="lnkAddNewFilter"
                          onClick={()=>{OpenAddFilterModel();}}
                          class="btn btn-md btn-primary btnColor"
                        >
                          Add New Filter
                        </a>
                      </div>
                      <div className="col-xl-6 allcentertext">
                        <i class="la la-filter iconsfonts"></i>
                        <a
                          id="lnkAddNewFilter"
                          onClick = {()=>{OpenSelectExistFilterMode();}}
                          class="btn btn-md btn-primary btnColor"
                        >
                          Select from existing Filter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </Popup>
        <Popup open={AddFilterModel}>

                                              <div>
                                                <div className="modal-black"></div>
                                                <div className="filterPopup largerPopup1000">
                                                  <div className="paddingboxTerms">
                                                    <div className="modal-header py-4 px-3">
                                                      <div className="w-100 d-flex px-3">
                                                        <h5 className="mb-0">
                                                          New Filter
                                                        </h5>
                                                        <button
                                                          className="close"
                                                          onClick = {()=>{CloseAddFilterModel();}}
                                                        >
                                                          <span
                                                            aria-hidden="true"
                                                            class="la la-remove"
                                                          ></span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                    <div className="p-3">
                                                      <div className="modal-body">
                                                        <div className="col-sm-6 mb-5 px-0">
                                                          <div class="row mb-3">
                                                            <label class="col-lg-5 col-form-label text-left">
                                                              Filter Name
                                                            </label>
                                                            <div class="col-lg-7">
                                                              <input
                                                                class="form-control m-input"
                                                                data-val-required="Please enter title"
                                                                id="FilterName"
                                                                name="FilterName"
                                                                placeholder="Filter Name"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="table-bordered">
                                                          <table
                                                            className="table table-bordered table-hover"
                                                            id="tab_logic"
                                                          >
                                                            <TableContainer
                                                              component={Paper}
                                                            >
                                                              <Table
                                                                size="small"
                                                                aria-label="a dense table"
                                                              >
                                                                <TableHead>
                                                                  <TableRow>
                                                                    <TableCell>
                                                                      Property{" "}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                      {" "}
                                                                    </TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                  </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                  {FieldAttributes.map(
                                                                    (
                                                                      itemcount,i
                                                                    ) => (
                                                                      <TableRow
                                                                        sx={{"&:last-child td, &:last-child th":{border: 0}}}
                                                                      >
                                                                        <TableCell align="right">
                                                                          <select
                                                                            class="form-control m-input"
                                                                            id={"ColumnName" +itemcount.id}
                                                                            onChange={()=>{ColumnhandledDropdownChange(itemcount.id,i)}}
                                                                            // value={this.state.selectedvalue}
                                                                            autocomplete="off"
                                                                          >
                                                                            {CustomColumnData.map((el)=>(

                                                                              <option value={el.FieldValue} > {el.ColumnName} </option>

                                                                              ))}
                                                                          </select>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                          {
                                                                            itemcount.IsCustom == true ? (
                                                                              <select
                                                                              class="form-control m-input"
                                                                              id={"ColumnName" +itemcount.id}
                                                                              // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                                                              // value={selectedvalue}
                                                                              autocomplete="off"
                                                                            >
                                                                                <option id="text" value="Text" > Text</option>
                                                                            </select>
                                                                            ):(null)
                                                                          }
                                                                          </TableCell>

                                                                        <TableCell align="right">
                                                                          {FieldAttributes[i].ColumnName =="CreatedDate" ? (
                                                                            <select id={"expression" +itemcount.id}
                                                                              class="form-control m-input"
                                                                              onChange={()=>{ExpressionHandler(itemcount.id)}}
                                                                            >
                                                                              <option id="Equals" value="EqualTo">Equals</option>
                                                                              <option id="Lessthan" value="LessThan" > Less Than </option>
                                                                              <option id="LessThanEqualTo" value="LessThanEqualTo"> Less Than Equal To </option>
                                                                              <option id="greaterthan" value="GreaterThan"> Greater Than </option>
                                                                              <option id="greaterthanequalto"  value="GreaterThanEqualTo"> Greater Than Eqaul To </option>
                                                                              <option id="between"  value="between"> Between </option>
                                                                            </select>
                                                                          ) : (
                                                                            <select id={ "expression" + itemcount.id } onChange={()=>{ExpressionHandler(itemcount.id)}} class="form-control m-input">
                                                                              <option id="ContainTo" value="Contains">Contains</option>
                                                                              <option id="EqualTo" value="EqualTo">Equals</option>
                                                                            </select>
                                                                          )}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                        {
                                                                            itemcount.ColumnName == "CreatedDate" ?
                                                                                itemcount.InputType == "between" ? (<>
                                                                                    <input type="date"  id={"searchvalue" +itemcount.id} class="form-control m-input required"/>
                                                                                     <input type="date"  id={"EndsWith" +itemcount.id} class="form-control m-input required"/>
                                                                                    </>
                                                                                ) :
                                                                                ((<input type="date"  id={"searchvalue" +itemcount.id} class="form-control m-input required"/> )):
                                                                                (<input type="text"  id={"searchvalue" +itemcount.id} class="form-control m-input required"/> )

                                                                        }

                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                          <button type="button"
                                                                            id={ "delete" + itemcount.id } class="btn btngroup"
                                                                            onClick={() => { handleRemoveClick( itemcount.id ); }}
                                                                          >
                                                                            <i class="la flaticon-delete-1"></i>
                                                                          </button>
                                                                        </TableCell>
                                                                        <TableCell align="right"></TableCell>
                                                                        <TableCell align="right"></TableCell>
                                                                      </TableRow>
                                                                    )
                                                                  )}
                                                                </TableBody>
                                                              </Table>
                                                            </TableContainer>
                                                          </table>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-12 text-left">
                                                            <button
                                                              type="button"
                                                              class="btn btngroup"
                                                              value="ADD Row"
                                                              onClick={() => {
                                                                handleClick();

                                                              }}
                                                            >
                                                              <i class="la la-plus la-icon-font-size-13"></i>{" "}
                                                              Add Property{" "}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="border-top modal-content text-right">
                                                        <div class="row">
                                                          <div class="col-lg-12 pull-right">
                                                            <a
                                                              id="backtolist"
                                                              class="btn btn-secondary mr-2"
                                                              onClick = {()=>{CloseAddFilterModel();}}
                                                            >
                                                              cancel
                                                            </a>
                                                            <a
                                                              id="submit"
                                                              class="btn btn-primary btn-lightgreen mr-1"
                                                              onClick={() => {
                                                                filterSearch();
                                                              }}
                                                              value="Apply"
                                                            >
                                                              Apply
                                                            </a>
                                                            <a
                                                              id="submit"
                                                              class="btn btn-primary btn-lightgreen mr-1"
                                                              onClick={() => {
                                                                addfilterAccountfilter();
                                                              }}
                                                              value="Save"
                                                            >
                                                              <i class="la la-save"></i>{" "}
                                                              Save
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

        </Popup>

        <Popup open={OpenSelectFilterModel}>

                                              <div>
                                                <div className="modal-black"></div>
                                                <div className="filterPopup largerPopup1000">
                                                  <div className="paddingboxTerms">
                                                    <div className="modal-header py-4 px-3">
                                                      <div className="w-100 d-flex px-3">
                                                        <h5 className="mb-0">
                                                          Edit Filter
                                                        </h5>
                                                        <button
                                                          className="close"
                                                          onClick = {()=>{CloseSelectExistFilterMode();}}

                                                        >
                                                          <span
                                                            aria-hidden="true"
                                                            class="la la-remove"
                                                          ></span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                    <div className="p-3">
                                                      <div className="modal-body">
                                                        <div className="col-sm-6 mb-5 px-0">
                                                          <div class="row mb-3">
                                                            <label class="col-lg-5 col-form-label text-left">
                                                              Filter Name
                                                            </label>
                                                            <div class="col-lg-7">
                                                              <select
                                                                class="form-control m-input m--hide"
                                                                id="FilterID"
                                                                name="FilterID"
                                                                onChange={() => {
                                                                  handleChangeFilter();
                                                                }}
                                                              >
                                                                {FilterName.map(
                                                                    (
                                                                      valuefilter
                                                                    ) => (
                                                                      <option
                                                                        value={
                                                                          valuefilter._id
                                                                        }
                                                                      >
                                                                        {
                                                                          valuefilter.FilterName
                                                                        }
                                                                      </option>
                                                                    )
                                                                  )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="table-bordered">
                                                          <TableContainer
                                                            component={Paper}
                                                          >
                                                            <Table
                                                              size="small"
                                                              aria-label="a dense table"
                                                            >
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell>
                                                                    Property{" "}
                                                                  </TableCell>
                                                                  <TableCell align="right">
                                                                    {" "}
                                                                  </TableCell>
                                                                  <TableCell align="right"></TableCell>
                                                                  <TableCell align="right"></TableCell>
                                                                  <TableCell align="right"></TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody>
                                                                <TableBody>
                                                                  {EditFieldAttributes.map(
                                                                    (
                                                                      itemcount2,
                                                                      index
                                                                    ) => (
                                                                      <TableRow
                                                                        sx={{
                                                                          "&:last-child td, &:last-child th":
                                                                          {
                                                                            border: 0,
                                                                          },
                                                                        }}
                                                                      >
                                                                        <TableCell>
                                                                          <select
                                                                            class="form-control m-input"
                                                                            id={"EditColumnName"+itemcount2.id}
                                                                            onChange={()=>{EditColumnName(itemcount2.id)}}
                                                                            value={itemcount2.FilterPropertyName}
                                                                            autocomplete="off"
                                                                          >
                                                                            {CustomColumnData.map((el)=>(

                                                                              <option value={el.FieldValue} > {el.ColumnName} </option>

                                                                              ))}
                                                                          </select>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                          {
                                                                            itemcount2.IsCustom == true ? (
                                                                              <select
                                                                              class="form-control m-input"
                                                                              id={"ColumnName" +itemcount2.id}
                                                                              // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                                                              // value={selectedvalue}
                                                                              autocomplete="off"
                                                                            >
                                                                                <option id="text" value="Text" > Text</option>
                                                                            </select>
                                                                            ):(null)
                                                                          }
                                                                          </TableCell>
                                                                        <TableCell align="right">
                                                                        {itemcount2.FilterPropertyName =="CreatedDate" ? (
                                                                            <select id={"Editexpression" +itemcount2.id}
                                                                              class="form-control m-input"
                                                                              value={itemcount2.ExpressionName}
                                                                              onChange={()=>{EditExpressValue(itemcount2.id)}}
                                                                            >
                                                                              <option id="Equals" value="EqualTo">Equals</option>
                                                                              <option id="Lessthan" value="LessThan" > Less Than </option>
                                                                              <option id="LessThanEqualTo" value="LessThanEqualTo"> Less Than Equal To </option>
                                                                              <option id="greaterthan" value="GreaterThan"> Greater Than </option>
                                                                              <option id="greaterthanequalto"  value="GreaterThanEqualTo"> Greater Than Equal To </option>
                                                                              <option id="between"  value="between"> Between </option>
                                                                            </select>
                                                                          ) : (
                                                                            <select id={"Editexpression" + itemcount2.id }
                                                                            value={itemcount2.ExpressionName}
                                                                            onChange={()=>{EditExpressValue(itemcount2.id)}}
                                                                            class="form-control m-input">
                                                                              <option id="ContainTo" value="Contains">Contains</option>
                                                                              <option id="EqualTo" value="EqualTo">Equals</option>
                                                                            </select>
                                                                          )}
                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                        {
                                                                            itemcount2.FilterPropertyName == "CreatedDate" ?
                                                                                itemcount2.ExpressionName == "between" ? (<>
                                                                                    <input type="date"  id={"Editsearchvalue" +itemcount2.id} onChange={()=>{EditFilterPropertyValue1(itemcount2.id)}} value={itemcount2.FilterPropertyValue1} class="form-control m-input required"/>
                                                                                     <input type="date"  id={"EditEndsWith" +itemcount2.id} onChange={()=>{EditFilterPropertyValue2(itemcount2.id)}} value={itemcount2.FilterPropertyValue2} class="form-control m-input required"/>
                                                                                    </>
                                                                                ) :
                                                                                ((<input type="date"  id={"Editsearchvalue" +itemcount2.id} onChange={()=>{EditFilterPropertyValue1(itemcount2.id)}} value={itemcount2.FilterPropertyValue1} class="form-control m-input required"/> )):
                                                                                (<input type="text"  id={"Editsearchvalue" +itemcount2.id} onChange={()=>{EditFilterPropertyValue1(itemcount2.id)}} value={itemcount2.FilterPropertyValue1} class="form-control m-input required"/> )

                                                                        }
                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                          <button
                                                                            type="button"
                                                                            id={"delete" +itemcount2.id}
                                                                            class="btn btngroup"
                                                                            onClick={() => {handleEditRemoveClick(itemcount2.id);}}
                                                                          >
                                                                            <i class="la flaticon-delete-1"></i>
                                                                          </button>
                                                                        </TableCell>
                                                                        <TableCell align="right"></TableCell>
                                                                        <TableCell align="right"></TableCell>
                                                                      </TableRow>
                                                                    )
                                                                  )}
                                                                </TableBody>
                                                              </TableBody>
                                                            </Table>
                                                          </TableContainer>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-12 text-left">
                                                            <button
                                                              type="button"
                                                              class="btn btngroup"
                                                              onClick={() => {handleeditPropertyClick();}}
                                                            >
                                                              <i class="la la-plus la-icon-font-size-13"></i>{" "}
                                                              Add Property{" "}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="border-top modal-content text-right">
                                                        <div class="row">
                                                          <div class="col-lg-12 pull-right">
                                                            <a
                                                              id="backtolist"
                                                              class="btn btn-secondary mr-2"
                                                              onClick = {()=>{CloseSelectExistFilterMode();}}

                                                            >
                                                              cancel
                                                            </a>
                                                            <a
                                                              id="submit"
                                                              class="btn btn-primary btn-lightgreen mr-1"
                                                                onClick={()=>{ApplyEditFilter()}}
                                                            >
                                                              Apply
                                                            </a>
                                                            <a
                                                              id="submit"
                                                              class="btn btn-primary btn-lightgreen mr-1"
                                                              onClick={() => {editapplyfilterSearch();}}
                                                            >
                                                              <i class="la la-save"></i>{" "}
                                                              Save
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

        </Popup>

        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="px-3">
                    <input type="checkbox" onChange={(e) => CheckHandler(e)} />
                  </TableCell>
                  <TableCell></TableCell>
                  {HeaderList?.map((row) => (
                    <TableCell key={row._id}>
                      <a
                        onClick={() => {
                          SortData(row.ColumnName.replace(/\s+/g, ""));
                        }}
                      >
                        {row.ColumnName}
                      </a>
                    </TableCell>
                  ))}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows?.map((value1) => (
                    <React.Fragment key={value1._id}>
                      <TableRow>
                          <TableCell align="center">
                            <input
                              id={value1._id}
                              type="checkbox"
                              onChange={(e) => CheckBoxClick(e, value1)}
                            />
                          </TableCell>
                        <TableCell>
                          {" "}
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() =>
                              SetOpen((prev) => ({
                                ...prev,
                                [value1._id]:
                                  !prev[value1._id],
                              }))
                            }
                          >
                            {Open[value1._id] ? (
                              <i
                                className="fa fa-minus-circle plusbl"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-plus-circle minusl"
                                aria-hidden="true"
                              ></i>
                            )}
                          </IconButton>
                        </TableCell>
                        {HeaderList.map((row) => {
                          if (row.ColumnName == "Account Name") {
                            return <TableCell>{value1?.AccountName}</TableCell>;
                          } else if (row.ColumnName == "Email Domains") {
                            return (
                              <TableCell>
                                {value1?.Salesreply_Accountdetails?.Domain}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Website") {
                            return <TableCell>{value1?.Website}</TableCell>;
                          } else if (row.ColumnName == "Address 1") {
                            return <TableCell>{value1?.Address1}</TableCell>;
                          } else if (row.ColumnName == "Address 2") {
                            return <TableCell>{value1?.Address2}</TableCell>;
                          } else if (row.ColumnName == "City") {
                            return <TableCell>{value1?.City}</TableCell>;
                          } else if (row.ColumnName == "State") {
                            return <TableCell>{value1?.State}</TableCell>;
                          } else if (row.ColumnName == "Zip") {
                            return <TableCell>{value1?.Zip}</TableCell>;
                          } else if (row.ColumnName == "Country") {
                            return <TableCell>{value1?.Country}</TableCell>;
                          } else if (row.ColumnName == "Account Category") {
                            return (
                              <TableCell>
                                {value1?.SalesAccountCategory}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Industry") {
                            return <TableCell>{value1.Industry}</TableCell>;
                          } else if (row.ColumnName == "Account Owner") {
                            return (
                              <TableCell>{value1?.AccountOwnerName}</TableCell>
                            );
                          } else if (row.ColumnName == "Reason Unqualified") {
                            return (
                              <TableCell>
                                {value1?.ReasonUnqualifiedName}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Company Phone") {
                            return <TableCell>{value1?.CompanyPhone}</TableCell>;
                          } else if (row.ColumnName == "Account Client Status") {
                            return (
                              <TableCell>
                                {value1?.AccountClientStatusName}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "AccountType") {
                            return <TableCell>{value1?.AccountType}</TableCell>;
                          } else if (row.ColumnName == "Created Date") {
                            return (
                              <TableCell>
                                {Moment(
                                  new Date(row?.CreatedDate).toDateString()
                                ).format("MM/DD/YYYY")}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Company Revenue") {
                            return (
                              <TableCell>{value1?.CompanyRevenue}</TableCell>
                            );
                          } else if (row.ColumnName == "Employee Count") {
                            return (
                              <TableCell>
                                {value1?.CompanyEmployeeCount}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Total Call Count") {
                            return <TableCell></TableCell>;
                          } else if (
                            row.CustomFieldID != null &&
                            row.CustomFieldID != ""
                          ) {
                            let valcust = value1.customField.filter(
                              (item) => item.CustomFieldID == row.CustomFieldID
                            );
                            if (valcust.length > 0) {
                              return (
                                <TableCell>{valcust[0].FieldValue}</TableCell>
                              );
                            } else {
                              return <TableCell></TableCell>;
                            }
                          }
                          //----------------------------------------------//
                          else {
                            return <TableCell></TableCell>;
                          }
                        })}

                        <TableCell>
                          <a
                            onClick={() => {
                              ViewPage(value1._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                          <a
                            onClick={() => {
                              DeleteClientName(value1._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={15}
                        >
                          <Collapse
                            in={Open[value1._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {Open[value1._id] && (
                              <Box margin={1} className="innertables">
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Account Status</TableCell>
                                      <TableCell>Last Send Date</TableCell>
                                      <TableCell>Last Response Date</TableCell>
                                      <TableCell>Sent Count</TableCell>
                                      <TableCell>Open Count</TableCell>
                                      <TableCell>Reply Count</TableCell>
                                      <TableCell>Soft Bounce Count</TableCell>
                                      <TableCell>Hard Bounces Count</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {AccountCount?.map((Value) =>
                                    Value._id.toString() ==
                                    value1._id ? (
                                      <TableRow>
                                        <TableCell>
                                          {Value?.LastSendDate}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.LastSendDate}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.LastOpenDate}
                                        </TableCell>
                                        <TableCell>{Value?.TotalSent}</TableCell>
                                        <TableCell>{Value?.TotalOpen}</TableCell>
                                        <TableCell>{Value?.TotalReply}</TableCell>
                                        <TableCell>
                                          {Value?.TotalSoftBounce}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.TotalHardBounce}
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </Table>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
        {SFlag ? (
          <div className="row">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div className="row px-2">
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                page={Page}
                showFirstButton
                showLastButton
              />
            </div>
          </div>

      )}
      </div>
    </>
  );
}