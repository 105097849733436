import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../../user/Kpis/LastdaysTabing';

// import DespammerTable from '../../../_components/user/automator/DespammerTable';
import MasterCompanyNamesTable from "../../../_components/user/Automator/MasterCompanyNamesTable";
import AutomatorTable from "../../../_components/user/Automator/AutomatorTable";

import ImageUploading from "react-images-uploading";
import Popup from "reactjs-popup";
import { CSVReader, CSVDownloader } from "react-papaparse";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  }; 
  const openTermsConditionPopup = () => {
    //console.log("--it's true");
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //console.log("--it's false");
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };


  document.addEventListener('mouseup', function(e) {
      console.log("--popup value--");
      //console.log(popupval);
      if(popupval){
        setpopup(false);
      }
  });
    //const openTermsConditionPopup = false;
  

  return (
    <div className="tabdes"> 
      <Popup open={popupval} modal>
            <div className="modal-black"></div>
        <div className="filterPopup">
            <div className="paddingboxTerms">
                <div className="modal-header">
                    <h5>Filter</h5>
                      <a className="close-ion" onClick={closeTermsConditionModal}><span aria-hidden="true" class="la la-remove"></span></a>
                </div>
                <div className="modal-content bordernone">
                    <div className="row">
                      <div className="col-xl-6 allcentertext">
                        <i class="la la-filter iconsfonts"></i>
                        <a id="lnkAddNewFilter" href="javascript:void(0);" class="btn btn-md btn-primary btnColor">
                            Add New Filter
                        </a>
                      </div>
                      <div className="col-xl-6 allcentertext">
                      <i class="la la-filter iconsfonts"></i>
                        <a id="lnkAddNewFilter" href="javascript:void(0);" class="btn btn-md btn-primary btnColor">
                        Select from existing Filter
                        </a> 
                      </div>
                    </div>
                </div>
            </div>
            {/* <div className="modal-footer">
              <a className="btn" type="submit" onClick={SubmitTermsConditionModal}>Learnedbtn</a>
            </div> */}
        </div> 
        </Popup>


      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"> 
            <Tab label="Automator" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Master Company Names" {...a11yProps(1)} /> 
          </Tabs>
        </AppBar>
        <TabPanel className={classes.root} value={value} index={0}>     
          <AutomatorTable />
        </TabPanel>
        <TabPanel className={classes.root} value={value} index={1}> 
                <MasterCompanyNamesTable />
        </TabPanel> 

      </div> 
    </div>
  );
}