import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Avatar } from "@material-ui/core";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";
import Userionimg from "../../../images/default.png";
import Pagination from "@material-ui/lab/Pagination";
import loadingicon from "../../../images/loading.gif";
import { CSVReader, CSVDownloader } from "react-papaparse";

const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};
export default function AccountAllTable({ FromDates, ToDates, IsIncludeOldClient }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ResponderName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [UserID, SetUserID] = React.useState(0);
  const [ExportData, SetExportData] = React.useState([]);
  const [MeetingSet, SetMeetingSet] = React.useState(0);
  const [MeetingSetLW, SetMeetingSetLW] = React.useState(0);
  const [MeetingSetTW, SetMeetingSetTW] = React.useState(0);
  const [FromDate, SetFromDate] = React.useState("");
  const [ToDate, SetToDate] = React.useState("");
  const [IsIncludeOldClients, SetIsIncludeOldClients] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)

  

  useEffect(() => {
    SetFromDate(FromDates)
    SetToDate(ToDates)
    SetIsIncludeOldClients(IsIncludeOldClient)
    var Details = GetUserDetails();
    if (Details != null) {
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
    }

    FromDates = FromDates.replace(/-/g, '/');
    ToDates = ToDates.replace(/-/g, '/');

    ResponderGet(Details.ParentUserID,FromDates, ToDates, IsIncludeOldClient);
    Exportdetails(Details.ParentUserID, FromDates, ToDates, IsIncludeOldClient);
    // const Inputparameters = {
    //   UserID: Details.ChildUserID,
    //   FromDate:"08-02-2011",
    //   ToDate:"08-03-2023",
    //   IncludeOldClient:false
    // };
    // Axios({
    //   url: CommonConstants.MOL_APIURL + "/allclientkpi/ClientKpiLeaderRespondersExports",
    //   method: "POST",
    //   data: Inputparameters,
    // }).then((Res) => {
    //   SetExportData(Res.data.Data);
    // });
  }, [Search, Page, RowsPerPage]);

  // meeting goal get list
  const ResponderGet = (UID, FromDates, ToDates,IsIncludeOldClient) => {
    var ResponderGetData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      ToDate: ToDates,
      FromDate: FromDates,
      IncludeOldClient:IsIncludeOldClient
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/allclientkpi/AllClientKpiLeaderResponders",
      method: "POST",
      data: ResponderGetData,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      if (Result.data.MeetingSet.length > 0) {
        SetMeetingSet(Result.data.MeetingSet[0].MeetingCount);
      } else {
        SetMeetingSet(0);
      }

      if (Result.data.MeetingSetLW.length > 0) {
        SetMeetingSetLW(Result.data.MeetingSetLW[0].LWMeetingSetCount);
      } else {
        SetMeetingSetLW(0);
      }

      if (Result.data.MeetingSetTW.length > 0) {
        SetMeetingSetTW(Result.data.MeetingSetTW[0].TWMeetingSetCount);
      } else {
        SetMeetingSetTW(0);
      }
      document.getElementById("hideloding").style.display = "none";
    });
  };

  // Export details
  const Exportdetails = (UID, FromDates, ToDates,IsIncludeOldClient) => {
    let InputParameter = {
      UserID: UID,
      ToDate: ToDates,
      FromDate: FromDates,
      IncludeOldClient:IsIncludeOldClient
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/allclientkpi/ClientKpiLeaderRespondersExportsDetails",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res?.data);
    });
  };
  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  const exportcsv = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <div className="row">
        <div className="w-100 whiterow py-2">
          <div className="row">
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{MeetingSet}</span>
                </div>
                <p class="clr-sky">Meetings Set</p>
              </div>
            </div>
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{MeetingSetLW}</span>
                </div>
                <p class="clr-sky">Meeting Set LW</p>
              </div>
            </div>
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{MeetingSetTW}</span>
                </div>
                <p class="clr-sky">Meeting Set TW</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left">Responders</h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <CSVDownloader
                data={ExportData}
                filename={"AllClients-ResponderMeeting"}
                bom={true}
              >
                <li>
                  <a
                    href=""
                    onClick={exportcsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                </li>
              </CSVDownloader>
            </ul>
          </div>
        </div>
      </div>

      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
              onKeyPress={RequestSearch}
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {/* <TableCell align="center"><input type="checkbox" /></TableCell> */}
                <TableCell>Responders</TableCell>
                <TableCell>Meetings</TableCell>
                <TableCell>Meetings All-Time</TableCell>
                <TableCell>Total Clients</TableCell>
                <TableCell>Meetings LW</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? (
                <p className="text-center">No data in Available</p>
              ) : (
                Rows.map((row) => (
                  <TableRow>
                    <TableCell>
                      <img
                        style={imgstyle}
                        src={
                          row?.ProfileImage == ""
                            ? CommonConstants.Image_url + "default.png"
                            : CommonConstants.Image_url + row?.ProfileImage
                        }
                      ></img>
                      {row?.ResponderName}
                    </TableCell>
                    <TableCell>{row?.MeetingCount}</TableCell>
                    <TableCell>{row?.AllMeetingCount}</TableCell>
                    <TableCell>{row?.ClientCount}</TableCell>
                    <TableCell>{row?.LWMeetingCount}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}
