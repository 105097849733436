import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import { toast } from "react-toastify";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function DefualtCountryBlackListTable(props) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [Role, SetRole] = React.useState("");
  const [SortField, SetSortField] = React.useState("Country");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);

  useEffect(() => {
    document.title = "Country Blacklist | SalesHive";
    var GetDefualtBlackList = GetUserDetails();
    if (GetDefualtBlackList != null) {
      SetClientID(GetDefualtBlackList.ClientID);
      SetUserID(GetDefualtBlackList.ChildUserID);
      SetPUserID(GetDefualtBlackList.ParentUserID);
      SetRole(GetDefualtBlackList.Role);
    }
    DefaultCountryBlackListGet(
      GetDefualtBlackList.ClientID,
      GetDefualtBlackList.ParentUserID,
      GetDefualtBlackList.Role
    );
  }, [Search, Page, RowsPerPage]);

  // edit button
  const EditBtn = (id) => {
    history.push({
      pathname: "/editdefaultcountryblacklist",
      state: { data: id },
    });
  };

  // defualt country blacklist delete
  const DeleteBtn = (id,Country) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Country Blacklist.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No,cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
          Country:Country,
          ClientID:ClientID
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultcountryblacklist/DefaultCountryBlacklistDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.data.StatusMessage == "SUCCESS")) {
              toast.success(
                <div className="toastsize">
                  Country blacklist will be deleted soon.
                </div>
              );
              SetPage(1);
              SetSearch("");
              // document.getElementById("hideloding").style.display = "block";
              document.getElementById("search").value = "";
              DefaultCountryBlackListGet(ClientID, PUserID);
              props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        //  document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  // change page
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
    // document.getElementById("hideloding").style.display = "block";
  };

  // handle change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      // document.getElementById("hideloding").style.display = "block";
    }
  };

  // defualt country black list get
  const DefaultCountryBlackListGet = (CID, UID) => {
    SetIsLoading(true);
    var DefualtCoutryBlackList = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/defaultcountryblacklist/DefaultCountryBlackListGet",
      method: "POST",
      data: DefualtCoutryBlackList,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Search: SearchedVal,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultcountryblacklist/DefaultCountryBlackListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    } else {
      SetIsLoading(false)
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultcountryblacklist/DefaultCountryBlackListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Country");
                    }}
                  >
                    Country
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Abbreviation");
                    }}
                  >
                    Abbreviation
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={(CreatedDate) => {
                      SortData("Domain");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow>
                    <TableCell>{row.Country}</TableCell>
                    <TableCell
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.Abbreviation}
                    </TableCell>
                    <TableCell>
                      {moment(new Date(row.CreatedDt).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>

                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id,row.Country);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
