import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import loadingicon from "../../../images/loading.gif";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import Userionimg from '../../../images/default.png';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



export default function AccountAllTable({ToDatess,FromDatess,IsOldClientss}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ResponderName");
  const [SortValue, SetSortValue] = React.useState(1);

  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [FromDates,SetFromDates ] = React.useState("");
  const [ToDates, SetToDates] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    ClientReschedulingGet(Details.ClientID,Details.ParentUserID,Details.Role,ToDatess,FromDatess,IsOldClientss);
  }, [Search, Page, RowsPerPage,ToDatess,FromDatess,IsOldClientss]);

  // Client rescheduling get list
  const ClientReschedulingGet = (CID,UID,URole,ToDate,FromDate,IsOldClientss) => {
    document.getElementById("hideloding").style.display = "block";
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate:ToDate,
      FromDate:FromDate,
      IncludeOldClient:IsOldClientss

    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/leader_board/ResponderGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    document.getElementById("hideloding").style.display = "none";

    });
  };

 //search for record
 const RequestSearch = (Event) => {
  if (Event.key === "Enter") {
    var SearchedVal = document.getElementById("search").value;
    SetSearch(SearchedVal);
    SetPage(1);
  }
};

//change Page
const HandleChangePage = (Event, NewPage) => {
  SetPage(NewPage);
};

//change display rows
const ChangeRowSelected = (Event) => {
  SetRowsPerPage(Event.target.value);
  SetPage(1);
};

//get sort field data
const SortData = (Field) => {
  var SearchedVal = document.getElementById("search").value;
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      ToDate:ToDatess,
      FromDate:FromDatess,
      IncludeOldClient:IsOldClientss
      
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/leader_board/ResponderGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);

    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      ToDate:ToDatess,
      FromDate:FromDatess,
      IncludeOldClient:IsOldClientss
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/leader_board/ResponderGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);

    });
  }
};


  return (
    <>
            <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
    <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      
      <div className="table-bordered">
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {/* <TableCell align="center"><input type="checkbox" /></TableCell> */}   
            <TableCell> <a
                    onClick={() => {
                      SortData("ResponderName");
                    }}
                  >Responders</a></TableCell>
            <TableCell> <a
                    onClick={() => {
                      SortData("MeetingCount");
                    }}
                  >Meetings</a></TableCell>
            <TableCell> <a
                    onClick={() => {
                      SortData("AllMeetingCount");
                    }}
                  >Meetings All-Time</a></TableCell>
            <TableCell> <a
                    onClick={() => {
                      SortData("ClientCount");
                    }}
                  >Total Clients</a></TableCell>
            <TableCell> <a
                    onClick={() => {
                      SortData("LWMeetingCount");
                    }}
                  >Meetings LW</a></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.map((row) => (
            <TableRow key={row.name} row={row}>
              <TableCell>
              <img
                      style={imgstyle}
                      src={
                        row.ProfileImage == ""
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url + row.ProfileImage
                      }
                    ></img>
                {" "+row.ResponderName}</TableCell>
            <TableCell>{row.Meetingcount}</TableCell>
            <TableCell>{row.AllMeetingCount}</TableCell>
            <TableCell>{row.ClientCount}</TableCell>
            <TableCell>{row.LWMeetingCount}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    {Sflag ? (
  <div class="row">
    <Pagination
      component="div"
      count={CountPage}
      onChange={HandleChangePage}
      showFirstButton
      showLastButton
    />
    <div class="col dataTables_info">
      <p>
        Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
        {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
        entries (filtered from {Flen} total entries)
      </p>
    </div>
  </div>
) : (
  <div class="row">
    <div class="col dataTables_info">
      <p>
        Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
        {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
        entries
      </p>
    </div>
    <div class="col pageright">
      <Pagination
        component="div"
        count={CountPage}
        onChange={HandleChangePage}
        showFirstButton
        showLastButton
      />
    </div>
  </div>
)}
    </>

  );
}
