import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import Axios from "axios";
const moment = require("moment");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

class ViewClientPotentialMeetingsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      // Note
      Page: 1,
      RowsPerPage: 10,
      Search: "",
      SortField: "Title",
      SortedBy: 1,
      Sflag: false,
      Data: [],
      Rows: [],
      Rlen: 0,
      Flen: 0,
      CountPage: 0,
      SearchData: [],
      AllRes: [],
      PotentialMeetingID:"",
      NameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
    };
    this.AccountNoteSave = this.AccountNoteSave.bind(this);
    this.AccountNoteEditBtn = this.AccountNoteEditBtn.bind(this);
    this.NoteDelete = this.NoteDelete.bind(this);
    this.EditBtn = this.EditBtn.bind(this);
    this.AccountNoteUpdate = this.AccountNoteUpdate.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
    this.NotesDetails();
    this.EditPotentialMeetingstatus();
  }

  EditPotentialMeetingstatus() {
    const id = this.props.location.state;
    var str_in = {
      PotentialMeetingID: id,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/potentialmeeting/ClientPotentialGetById",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ AllRes: res.data.ViewPotentialData });
    });
  }

  //edit page
  EditBtn = () => {
    history.push("/editclientpotentialmeetings", this.props.location.state);
  };

   //back page
   BackBtn = () => {
    history.push("/clientpotentialmeetings");
  };

  //Notes
  NotesDetails() {
    var Details = GetUserDetails();
    const id = this.props.location.state;
    this.setState({ PotentialMeetingID: id });
    var NoteData = {
      Page: this.state.Page,
      ClientID: Details.ClientID,
      MeetingID: id,
      RowsPerPage: this.state.RowsPerPage,
      Sort: true,
      Field: this.state.SortField,
      SortBy: this.state.SortedBy,
      Search: this.state.Search,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/GetClientPotentialMeetingNotes",
      method: "POST",
      data: NoteData,
    }).then((responsenote) => {
      console.log(responsenote.data?.PageData,"sahil")
      this.setState({ Data: responsenote.data?.PageData });
      this.setState({ Rows: responsenote.data?.PageData });
      this.setState({ Rlen: responsenote.data?.TotalCount });
      this.setState({ Flen: responsenote.data?.TotalCount });
      this.setState({ CountPage: responsenote.data?.PageCount });
    });
  }

  //change display rows
  ChangeRowSelected = (event) => {
    this.setState({ Page: 1 });
    this.setState({ RowsPerPage: event.target.value });
    this.NotesDetails();
  };

  // search for record  note
  RequestSearch(event) {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("searchnote").value;
      this.setState({ Search: SearchedVal });
      this.NotesDetails();
    }
  }

  // change page
  HandleChangePage = (NewPage) => {
    this.setState({ Page: NewPage });
    this.NotesDetails();
  };

  //  handle change  notes
  HandleChangePNotes(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailable = null;
      this.NoteTitleCheckExist(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.Errors.Title = null;
    }
  }

  //delete  note id
  NoteDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a Client potential note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var PNoteDelete = {
          _id: id,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: this.state.CUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/potentialmeeting/DeletePotentialNotesMeeting",
          method: "POST",
          data: PNoteDelete,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Account note deleted successfully.",
                "success"
              );
              history.push("/viewclientpotentialmeetings");
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  // find check exits title
  NoteTitleCheckExist(Title) {
    var ProspectNote = {
      ClientID: this.state.ClientID,
      Title: Title,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialMeetingStatusExistsNotes",
      method: "POST",
      data: ProspectNote,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          this.setState({ TitleAvailable: res.data.Data[0].Title });
        }
      }
    });
  }

  // save  note
  AccountNoteSave() {
    var Title = document.getElementById("TitlePNote").value;
    var Notes = document.getElementById("NotesPNote").value;

    // if(this.FromValidation()){
    var NoteData = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      MeetingID: this.props.location.state,
      Title: Title,
      Note: Notes,
      CreatedBy: this.state.CUserID,
      CreatedDate: new Date(),
      LastUpdatedBy: null,
      LastUpdatedDate: null,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/AddPotentialNotesMeeting",
      method: "POST",
      data: NoteData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success("Account note add successfully.", "Add Note ");
      } else {
        toast.error(res.data.Message);
      }
    });
    // }
  }

  // find id by record  edit
  AccountNoteEditBtn(id) {
    this.setState({ ids: id });
    var AccountNoteEdit = {
      id: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialMeetingNotesByID",
      method: "POST",
      data: AccountNoteEdit,
    }).then((res) => {
      document.getElementById("TitleEditNote").value = res.data[0].Title;
      document.getElementById("NotesEditPNotes").value = res.data[0].Note;
    });
  }

  // note update
  AccountNoteUpdate() {
    var Title = document.getElementById("TitleEditNote").value;
    var Notes = document.getElementById("NotesEditPNotes").value;

    var NoteUpdateData = {
      _id: this.state.ids,
      Title: Title,
      Note: Notes,
      LastUpdatedBy: this.state.CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/PotenialNotesMeetingUpdate",
      method: "POST",
      data: NoteUpdateData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Note Status update successfully.",
          "Update Account Status"
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header">
                    Client Edit Potential Meeting	
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 py-3 text-right">
                  <a className="btn btngroup m-btn m-btn--custom mr-1">
                    <i className="la la-exchange"></i> 
                    <span className="Mobile_button"> Convert</span>
                  </a>
                  {/* {this.state.AllRes.map((value) => ( */}
                  <a
                    className="btn btngroup m-btn m-btn--custom btn-fill mr-1"
                    onClick={() => {
                      this.EditBtn();
                    }}
                  >
                    <i className="la flaticon-edit-1"></i> 
                    <span className="Mobile_button"> Edit</span>
                  </a>
                  {/* ))} */}
                  <a
                    className="btn btngroup2 m-btn m-btn--custom"
                    onClick={this.BackBtn}
                  >
                    <i className="la la-arrow-circle-left"></i> 
                    <span className="Mobile_button"> Back</span>
                  </a>
                </div>
              </div>

            <div class="paddcols">
              {this.state.AllRes?.map((value) => (
                <div className="row pt-4">
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Client
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext"></label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        First Name
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">{value?.FirstName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Name
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">{value?.LastName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Title
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">{value?.Title}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">{value?.Company}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Email
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">{value?.Email}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        LinkedIn Url
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">
                          {value?.LinkedInUrl}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Source
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">
                          {value?.MeetingSourceName}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        {" "}
                        Owner{" "}
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">
                          {value?.MeetingOwnerName}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reminder Email Account
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">
                          {value?.ReminderEmailAccount}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Engagement Status
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">
                          {value?.EngagamentStatus}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">
                          {value?.DirectPhone}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Phone
                      </label>
                      <div className="col-lg-7">
                        <label className="labeltext">
                          {value?.CompanyPhone}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          
        
        {/* Notes */} 
          <div className="paddcols px-4">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
              </div>
              <div className="col text-right">
                <Popup
                  trigger={
                    <button className="btn btngroup m-btn ">
                      <i class="la la-plus la-icon-font-size-13"></i>
                      <span className="Mobile_button">Add</span>
                    </button>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-3 px-3">
                            <h4 className="mb-0">Note</h4>
                          </div>
                          <div className="modal-body p-5">
                            <div class="row mb-3">
                              <label class="col-lg-2 col-form-label text-left">
                                Title
                              </label>
                              <div class="col-lg-10">
                                <input
                                  class="form-control m-input"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter title"
                                  id="TitlePNote"
                                  name="TitleNote"
                                  placeholder="Enter title"
                                  onBlur={this.HandleChangePNotes.bind(
                                    this,
                                    "TitlePNote"
                                  )}
                                  type="text"
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["TitlePNote"]}
                                </span>
                                {this.state.TitleAvailable && (
                                  <span style={{ color: "red" }}>
                                    Title already exist.
                                  </span>
                                )}
                              </div>
                            </div>

                            <div class="row">
                              <label class="col-lg-2 col-form-label text-left">
                                Note
                              </label>
                              <div class="col-lg-10">
                                <textarea
                                  class="form-control m-input minheight390"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-required="Please enter title"
                                  id="NotesPNote"
                                  name="NotesPNote"
                                  placeholder="Enter note"
                                  onBlur={this.HandleChangePNotes.bind(
                                    this,
                                    "NotesPNote"
                                  )}
                                  type="text"
                                >
                                  {" "}
                                </textarea>
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["NotesPNote"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="modal-content bordernone text-right">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={() => {
                                    close();
                                  }}
                                >
                                  cancel
                                </a>
                                <a
                                  id="submit"
                                  class="btn btn-primary btn-lightgreen mr-1"
                                  href="javascript:void(0);"
                                  value="Save"
                                  onClick={this.AccountNoteSave}
                                >
                                  <i class="la la-save"></i> Save
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    onChange={this.ChangeRowSelected}
                    className="form-control form-control-sm"
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchnote"
                    onKeyPress={(event) => this.RequestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Rows?.map((row) =>
                      row.PotentialMeetingID != null ? (
                        <TableRow>
                          <TableCell>{row.Title}</TableCell>
                          <TableCell>{row.Note}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.AccountNoteEditBtn(
                                  row._id
                                );
                              }}
                            >
                              <Popup
                                trigger={
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-3 px-3">
                                          <h4 className="mb-0">Note</h4>
                                        </div>
                                        <div className="modal-body p-5">
                                          <div class="row mb-3">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Title
                                            </label>
                                            <div class="col-lg-10">
                                              <input
                                                class="form-control m-input"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                data-val-required="Please enter title"
                                                id="TitleEditNote"
                                                name="TitleEditNote"
                                                placeholder="Enter title"
                                                type="text"
                                                onChange={this.HandleChangePNotes.bind(
                                                  this,
                                                  "TitleEditNote"
                                                )}
                                                value={
                                                  this.state.Fields[
                                                    "TitleEditNote"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {
                                                  this.state.Errors[
                                                    "TitleEditNote"
                                                  ]
                                                }
                                              </span>
                                              {this.state.TitleAvailable && (
                                                <span style={{ color: "red" }}>
                                                  Title already exist.
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Note
                                            </label>
                                            <div class="col-lg-10">
                                              <textarea
                                                class="form-control m-input minheight390"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-required="Please enter title"
                                                id="NotesEditPNotes"
                                                name="NotesEditPNotes"
                                                placeholder="Enter note"
                                                type="text"
                                                onChange={this.HandleChangePNotes.bind(
                                                  this,
                                                  "NotesEditPNotes"
                                                )}
                                                value={
                                                  this.state.Fields[
                                                    "NotesEditPNotes"
                                                  ]
                                                }
                                              >
                                                {" "}
                                              </textarea>
                                              <span style={{ color: "red" }}>
                                                {
                                                  this.state.Errors[
                                                    "NotesEditPNotes"
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-content bordernone text-right">
                                          <div class="row">
                                            <div class="col-lg-12 pull-right">
                                              <a
                                                id="backtolist"
                                                class="btn btn-secondary mr-2"
                                                onClick={() => {
                                                  close();
                                                }}
                                              >
                                                cancel
                                              </a>
                                              <a
                                                id="submit"
                                                class="btn btn-primary btn-lightgreen mr-1"
                                                href="javascript:void(0);"
                                                value="Save"
                                                onClick={this.AccountNoteUpdate}
                                              >
                                                <i class="la la-save"></i> Save
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </a>
                            <a
                              className="btn-eyesicon"
                              onClick={() => {
                                this.NoteDelete(
                                  row.PotentialMeetingNoteID
                                );
                              }}
                            >
                              <i class="la flaticon-delete-1 delete-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.Sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.CountPage}
                  onChange={this.HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries (filtered from{" "}
                    {this.state.Flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CountPage}
                    onChange={this.HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 
          
        </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedViewClientPotentialMeetingsPage = connect(
  mapState,
  actionCreators
)(ViewClientPotentialMeetingsPage);
export { connectedViewClientPotentialMeetingsPage as ViewClientPotentialMeetingsPage };
