import React, { useEffect, useState } from 'react';
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import Popup from "reactjs-popup";
const moment = require('moment');
import Select from "react-select";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from "@material-ui/core/Collapse";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import loadingicon from "../../../images/loading.gif";

import { history } from "../../../_helpers";

//Edit Filter Component
import EditFilter from './EditFilter';
import { Tooltip } from '@material-ui/core';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});





export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [SalesRepList, SetSalesRepList] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Open, SetOpen] = React.useState(false);
  const [FollowUpNotesOpen, SetFollowUpNotesOpen] = React.useState(false);
  const [ShowExportPopUp,SetShowExportPopUp] = React.useState(false)
  const [AddBurnerModel, SetAddBurnerModel] = useState(false)
  const [checkeddata, setChecked] = React.useState({ "IsMobilePhone": false, "IsCompanyPhone": false, "IsDirectPhone": false, "IsOtherPhone1": false, "IsOtherPhone2": false });
  const [AllColdCallingCondetail, SetAllColdCallingContacts] = useState({})
  const [ColdCallingContactsDetails, SetColdCallingContactsDetails] = useState([]);
  const [LastCallResultDropDown, SetLastCallResultDropDown] = useState([
    { label: 'Never Been Called', value: 'never been called' },
    { label: 'Voicemail 1', value: 'voicemail 1' },
    { label: 'Voicemail 2', value: 'voicemail 2' },
    {
      label: 'Email Only No Voicemail',
      value: 'email only no voicemail'
    },
    { label: 'Not Available', value: 'not available' },
    {
      label: 'Leave No Message / Email',
      value: 'leave no message / email'
    },
    {
      label: 'Asked for Info / Follow Up Later',
      value: 'asked for info / follow up later'
    },
    { label: 'Not Interested', value: 'not interested' },
    { label: 'Not The Right Person', value: 'not the right person' },
    { label: 'DNC', value: 'dnc' },
    { label: 'Na', value: 'na' },
    {
      label: 'Not Longer With Company',
      value: 'not longer with company'
    },
    { label: 'Not In Swimlane', value: 'not in swimlane' },
    { label: 'Nurture', value: 'nurture' },
    { label: 'Referred', value: 'referred' },
    { label: 'No', value: 'no' },
    { label: 'Not Me', value: 'not me' },
    { label: 'Not Now', value: 'not now' },
    { label: 'Activated Lead', value: 'activated lead' },
    { label: 'Meeting Scheduled', value: 'meeting scheduled' },
    { label: 'Connect - Incomplete', value: 'connect - incomplete' }
  ]);
  const [SelectedFilterID, SetSelectedFilterID] = useState(0)
  const [FilterDefaultID,SetFilterDefaultID] = useState(0)
  const [ColdContactFilterList, SetColdContactFilterList] = useState([])
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const [DisabledContactExportBtn,SetDisabledContactExportBtn] = React.useState(false);
  const [Reset,SetReset] = React.useState(true);
  const [SelectAllRecords,SetSelectAllRecords] = React.useState(true)
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  //Filter states starts from here
  const [OpenFilterBox,SetOpenFilterBox] = React.useState(false);
  const [OpenEditFilterBox,SetOpenEditFilterBox] = React.useState(false);
  const [SelectedEditFilter,SetSelectedEditFilter] = React.useState(0);
  const [FieldName,SetFieldName] = React.useState([]); //Fieldname for the filter select box
  const [GetDefaultFilterValues,SetGetDefaultFilterValues] = React.useState([])
  const [OpenAddFilter, SetOpenAddFilter] = React.useState(false);
  const [IsApply, SetIsApply] = React.useState(false);
  const [CustomColumnData, SetCustomColumnData] = React.useState([]);
  const [FieldAttributes, SetFieldAttributes] = React.useState([
    {
      id: Math.floor(Math.random() * 1000000000),
      FieldValue: "AccountCategory",
      ColumnName: "Account Category",
      Expression: "Contains",
      FilterPropertyValue: "",
      FilterPropertyValue1: "",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      NullCondition: false,
    },
  ]);
  const [FilterParameters, SetFilterParameters] = React.useState([]);
  const [FilterName, SetFilterName] = React.useState(null);
  const [FilterID, SetFilterID] = React.useState(null);
  const [EditFieldAttributes, SetEditFieldAttributes] = React.useState([]);
  const [DeletedFilterProperty, SetDeletedFilterProperty] = React.useState([]);
  const [IsFiltered, SetIsFiltered] = React.useState(false);
  const [SaveAsBoxFilterName, SetSaveAsBoxFilterName] = React.useState(false);
  const [SaveAsFilterName, SetSaveAsFilterName] = React.useState(null);
  const [SaveAsFilterData, SetSaveAsFilterData] = React.useState([]);
  const [ErrorMessage, SetErrorMessage] = React.useState([]);
  const [ContactTags, SetContactTags] = React.useState([]);
  const [ContactValidate, SetContactValidate] = React.useState([]);
  const [DeleteTagModel, SetDeleteTagModel] = React.useState(false);
  const [AddTagBtnDisabled, SetAddTagBtnDisabled] = React.useState(false);
  const [ContactDataAll, SetContactDataAll] = React.useState([]);
  const [MainCheckBox, SetMainCheckBox] = React.useState(false);
  const [TableRowLength, SetTableRowLength] = React.useState(null)
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [OpenImportCsv,SetOpenImportCsv] = React.useState(false)
  const [OpenDeleteCsv,SetOpenDeleteCsv] = React.useState(false)
  const [ContactTag, SetContactTag] = React.useState([]);
  const [ContactTagSelectArray, setContactTagSelectArray] = React.useState([]);
  const [ReassignFromSelectedValue, SetReassignFromSelectedValue] = React.useState([]);
  const [ReassignToSelectedValue, SetReassignToSelectedValue] = React.useState([]);
  const [ContactSalesDevlopMenData, setContactSalesDevlopMenData] =React.useState([]);
  const [ContactCustomFieldData, setContactCustomFieldData] = React.useState([]);
  const [ContactReassignData, SetContactReassignData] = React.useState([]);
  const [ContactToReassignData, SetContactToReassignData] = React.useState([]);
  const [CampaignData, SetCampaignData] = React.useState([]);

  //Applied Filter Data
  const [AppliedFilterData,SetAppliedFilterData] = React.useState([]);
  //Edit Save ButtonDisplay
  const [IsSaveButton,SetIsSaveButton] = React.useState(false)
  //Contact tags option
  const [ContactTagsOptions,SetContactTagsOptions] = React.useState([])

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }


    GetColdCallingContact(Details.ClientID, Details.ParentUserID, Details.Role);
    ColdCallingContactFilterGet(Details.ClientID, Details.ParentUserID);
    GetCustomFiledsData(Details.ClientID, Details.ParentUserID, Details.Role);
    ContactInfoGetData(Details.ClientID, Details.ParentUserID, Details.Role)

  }, [Search, Page, RowsPerPage,IsApply]);

  // Getting Filter Dropdown

  const ColdCallingContactFilterGet = async (ClientID, UserID) => {
    const InputParameter = {
      ClientID: ClientID,
      UserID: UserID
    }

    await Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactFilterGetDropDown",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      
      if (Result.data.StatusMessage == "SUCCESS") {
       let Data =  Result.data?.Data?.find(item => item.IsDefault)
       GetDefaultProperties(Data?._id)
       SetSelectedFilterID(Data?._id)
       SetFilterDefaultID(Data?._id)
       SetSelectedEditFilter(Data?._id)
        SetColdContactFilterList(Result.data.Data)
        Getfilterpropertylist(Data?._id)

      }
    })
  }

  //get Cold calling recording  list
  const GetColdCallingContact = (CID, UID, URole) => {

    document.getElementById("hidelod").style.display = "block";
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsApply:IsApply
    };

    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactsGet",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      
      if (Result.data.StatusMessage == "SUCCESS") {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        const allSelected = Result.data.PageData.every((row) => selectedIds.includes(row._id));
        setSelectAll(allSelected);
      }
      document.getElementById("hidelod").style.display = "none";

    });
    //
    //GetNotes(CID,UID)
  };

  //Get Notes
  const GetNotes = (CID, UID) => {
    var InputParameter = {

      ClientID: CID,
      UserID: UID,
    };

    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingNotes",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      
      let Notes = Result.data.Data;
      let UpdatedArr = [];
      let Data = Rows;
      Data.forEach((elem) => {

        let indexed = Notes.findIndex(v => v.ProspectID == elem.ProspectID);
        if (indexed == -1) {
          let Object = elem;
          Object.Notes = "No notes available"
          UpdatedArr.push(Object);
          
        } else {

        }
        
      })

    });
  }

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var Sort = SortValue;
      if (Sort == 1) {
        Sort = -1;
        SetSortValue(-1);
      } else {
        Sort = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      IsApply:IsApply

      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactsGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.totalcount);
        SetFlen(Result.data.totalcount);
        SetCountPage(Result.data.Pagecount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var Sorted;
      if (Sorted == 1) {
        Sorted = -1;
        SetSortValue(-1);
      } else {
        Sorted = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortValue,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        IsApply:IsApply

      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactsGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.Pagedata);
        SetRlen(Result.data.totalcount);
        SetFlen(Result.data.totalcount);
        SetCountPage(Result.data.Pagecount);
      });
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);

    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };


  const EditBtn = (ID,Email) => {
    history.push({ pathname: "/viewcontacts", state: { data: ID, Pagename: "ColdCallingContacts",EmailData:Email} });
  }

  const SelectAllCheckBox = async (e) => {
    Rows?.map(async (val) => {
      if (e.target.checked) {
        document.getElementById(val?._id).checked = true;
        await SetColdCallingContactsDetails((prevItems) => [...prevItems, val]);
      } else if (!e.target.checked) {
        document.getElementById(val?._id).checked = false;
        await SetColdCallingContactsDetails((prevItems) =>
          prevItems.filter((prevItem) => prevItem._id !== val._id) 
        );
      }
    });
  }

  const HandleCall = async (e, data) => {
    if (e.target.checked) {
      await SetColdCallingContactsDetails((prevItems) => [...prevItems, data]);
    }
    else {
      await SetColdCallingContactsDetails((prevItems) =>
        prevItems.filter((prevItem) => prevItem._id !== data._id)
      );
    }
    await SetAllColdCallingContacts({})
  }
  const MultipleCallDial = async () => {
    if (ColdCallingContactsDetails.length > 0) {
      SetAddBurnerModel(true)
    }
    else {
      toast.error(<div className="toastsize">Start Dial Session<br />Please select atleast one cold calling contact from list.</div>)
    }

  }
  const OpenDialPopup = async (e, data) => {
    await SetAllColdCallingContacts(data)
    await SetColdCallingContactsDetails([])
    SetAddBurnerModel(true)
  }
  const CallBtn = async () => {
    try {
      SetAddBurnerModel(false)
      var ColdCallerUserID = "", UserID = "", ClientID = "";
      var Details = GetUserDetails();
      if (Details != null) {
        ColdCallerUserID = Details.ChildUserID;
        UserID = Details.ParentUserID;
        ClientID = Details.ClientID;
      }
      var ColdCallingContacts = [];
      ColdCallingContacts.push(AllColdCallingCondetail)
      var InputParameter = {
        UserID: UserID,
        ClientID: ClientID,
        ColdCallerUserID: ColdCallerUserID,
        AllColdCallingContacts: ColdCallingContactsDetails.length > 0 ? ColdCallingContactsDetails : ColdCallingContacts,
        ObjPhoneNumberdata: checkeddata
      };


      const response = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetPhonebrnerAccessToken",
        method: "POST",
        data: InputParameter,
      });
      response.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          const windowFeatures = 'width=500,height=500,resizable,scrollbars';
          window.open(Result.data.returnUrl, '_blank',windowFeatures);
        }
        else if (Result.data.StatusMessage == "NotExist") {
          toast.error(Result.data.Message);
        }

      });
    } catch (error) {
      
    }
  }

  const handleChange1 = async (event) => {
    await setChecked({ ...checkeddata, ["IsMobilePhone"]: event.target.checked, ["IsCompanyPhone"]: event.target.checked, ["IsDirectPhone"]: event.target.checked, ["IsOtherPhone1"]: event.target.checked, ["IsOtherPhone2"]: event.target.checked });
  };

  const handleChange = async (event, phoneType) => {
    await setChecked({ ...checkeddata, [phoneType]: event.target.checked });
    // 
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'row', ml: 3, flexWrap: 'wrap' }}>
      <FormControlLabel sx={{ fontSize: '10px' }}
        label="Mobile Phone"
        control={<Checkbox checked={checkeddata["IsMobilePhone"]} onChange={(e) => handleChange(e, "IsMobilePhone")} />}
      />
      <FormControlLabel
        label="Company Phone"
        control={<Checkbox checked={checkeddata["IsCompanyPhone"]} onChange={(e) => handleChange(e, "IsCompanyPhone")} />}
      />
      <FormControlLabel
        label="Direct Phone"
        control={<Checkbox checked={checkeddata["IsDirectPhone"]} onChange={(e) => handleChange(e, "IsDirectPhone")} />}
      />
      <FormControlLabel
        label="Other Phone1"
        control={<Checkbox checked={checkeddata["IsOtherPhone1"]} onChange={(e) => handleChange(e, "IsOtherPhone1")} />}
      />
      <FormControlLabel
        label="Other Phone2"
        control={<Checkbox checked={checkeddata["IsOtherPhone2"]} onChange={(e) => handleChange(e, "IsOtherPhone2")} />}
      />
    </Box>
  );

const AddBtn = () =>{
  history.push({
    pathname: "/addcontacts",
    state: {Pagename: "ColdCallingContacts" },
  });
}

const OpenExportPopup = () =>{
  SetDisabledContactExportBtn(false)
  SetShowExportPopUp(true)
  setErrors("")

}
const CloseExportPopup = ()=> {
  SetShowExportPopUp(false)
  setErrors("")
  SetDisabledContactExportBtn(false)


}

 // Function to validate email
 const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

// Function to handle email input onBlur event
const handleEmailBlur = (e) => {
  const { name, value } = e.target;
  const newErrors = { ...errors };

  if (!value) {
    newErrors[name] = 'Please enter email.';
  } else if (!validateEmail(value)) {
    newErrors[name] = 'Invalid email';
  } else {
    newErrors[name] = ''; // No error
  }

  setErrors(newErrors);
};

const SubmitExport = (e) => {
  SetDisabledContactExportBtn(true)

  e.preventDefault();
  if (!errors.email && email != "") {
    let InputParameters = {
      UserID:UserID,
      ClientID:ClientID,
      Email:email,
      IsDeleted:false,
      CreatedDate:new Date(),
      CreatedBy:UserID,
      DynamicQuery:"",
      UserRoleName:Role,
      ColdCallerUserID:UserID
    }
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingSaveExport",
      method: "POST",
      data: InputParameters,
    });
    Row.then((Result) => {
        if(Result.data.StatusMessage == 'SUCCESS'){
          toast.success(<div className='toastsize'>Contact <br/>
          You will get file soon on Submitted Email.</div>)
          CloseExportPopup()
  SetDisabledContactExportBtn(false)

        }else{
  SetDisabledContactExportBtn(false)

        }
    });

  }else{
    const newErrors = { ...errors };
    newErrors['email'] = 'Please enter email.';
    setErrors(newErrors);
  SetDisabledContactExportBtn(false)


  }
};

  // Get All Contact Info Data
const ContactInfoGetData = (CID, UID, Role) => {
    var InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: InputParaMeter,
    });
    rows.then((result) => {
      setContactSalesDevlopMenData(result.data?.ContactSalesDevlopMenData);
      setContactCustomFieldData(result.data?.ContactCustomFieldData);
      SetContactTag(result.data?.ContactTagData);
      const options = result.data?.ContactsReassignData.map((val) => {
        return { value: val._id, label: val.ColdCallersName };
      });
      const options1 = result.data?.ContactTagData.map((val) => {
        return { value: val._id, label: val.Tag };
      });
      SetContactTagsOptions(options1)
      SetContactReassignData(options)
      // SetContactToReassignData(options);   
      SetCampaignData(result.data?.CampaignGetData);

    });
};

let contactoptions = ContactTag.map((val) => {
  return { value: val.Tag, label: val.Tag, cid: val._id };
});

//   // from reassing sale development reps
//   let salesdevlopmentrespoptions = ContactReassignData.map((val) => {
//     return { value: val._id, label: val.ColdCallersName };
//   });

// // to reassign
//   let ReassignToSalesDevlopmentRepsOptions = ContactToReassignData.map((val) => {
//     return { value: val._id, label: val.ColdCallersName };
//   });

// contact tags method 2
let ContactTagsArr = ContactTags.map((val) => {
  return { value: val.ContactTagID, label: val.Tag, cid: val.ContactTagID };
});

//Filter Drop Down Handler
const FilterDropDownHandler = (e) =>{
  if(e.target.value == '--Select--'){
    toast.error(<div className='toastsize'>Please select filter.</div>)
    SetSelectedFilterID(0)
  }else{
    SetSelectedFilterID(e.target.value);
    Getfilterpropertylist(e.target.value);
    SetReset(false)

  }
}

//Reset Filter
// const ResetFilter = () => {
//   SetSelectedFilterID(FilterDefaultID);
//   SetReset(true)
// }


const handleSelectAll = () => {
  setSelectAll(!selectAll);
  if (!selectAll) {
    const allIds = Rows.map((row) => row._id);
    setSelectedIds(allIds);
  } else {
    setSelectedIds([]);
  }
};

const handleRowCheckboxChange = (id) => {
  if (selectedIds.includes(id)) {
    setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
  } else {
    setSelectedIds([...selectedIds, id]);
  }
};


//Select all records 
const SelectAll = () => {
  setSelectAll(!selectAll);
  if (!selectAll) {
    const allIds = Rows.map((row) => row._id);
    setSelectedIds(allIds);
  }
}

//Unselect all records
const UnSelectAll = () => {
  setSelectedIds([]);
}

//Open Filter model
const OpenFilterModel = () => {
  SetOpenFilterBox(true)
}

//Close Fitler model
const CloseFilterModel = () => {
  SetOpenFilterBox(false)
}


//Getting Custom fields
const GetCustomFiledsData = (CID, UID, Role) => {
  const InputParameters = {
    ClientID: CID,
    UserID: UID,
    Role: Role,
  };

  Axios({
    url: CommonConstants.MOL_APIURL + "/contacts/GetCustomFields",
    method: "POST",
    data: InputParameters,
  }).then((res) => {
    let Arr = [
      {
        FieldValue: "AccountName",
        ColumnName: "Account Name",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "AccountCategory",
        ColumnName: "Account Category",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "AccountType",
        ColumnName: "AccountType",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Address1",
        ColumnName: "Address1",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Address2",
        ColumnName: "Address2",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "CallCount",
        ColumnName: "Call Count",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between "},
        ],
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "City",
        ColumnName: "City",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ClientPOC",
        ColumnName: "Client POC",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Company",
        ColumnName: "Company",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "CompanyPhone",
        ColumnName: "Company Phone",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactCategory",
        ColumnName: "Contact Category",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactOwner",
        ColumnName: "Contact Owner",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactSource",
        ColumnName: "Contact Source",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        
        ]
      },
      {
        FieldValue: "ContactStatus",
        ColumnName: "Contact Status",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
        ]
      },
      {
        FieldValue: "Country",
        ColumnName: "Country",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalSent",
        ColumnName: "Deliveries",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "Email",
        ColumnName: "Email",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "FirstName",
        ColumnName: "First Name",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalHardBounce",
        ColumnName: "Hard Bounce",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "Industry",
        ColumnName: "Industry",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "IsBlacklisted",
        ColumnName: "Is Blacklisted",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"False",label:"No"},
          {value:"True",label:"Yes"},
        ],
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:true,
      },
      {
        FieldValue: "LastName",
        ColumnName: "Last Name",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "LastSendDate",
        ColumnName: "Last Send Date",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
  
      },
      {
        FieldValue: "LastValidationDate",
        ColumnName: "Last Validation Date",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:true,
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "LinkedInURL",
        ColumnName: "LinkedIn URL",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ListName",
        ColumnName: "List Name",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "MobilePhone",
        ColumnName: "Mobile Phone",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalOpen",
        ColumnName: "Open",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:false,
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "ReasonUnqualified",
        ColumnName: "Reason Unqualified",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalReply",
        ColumnName: "Replies",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:false,
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "ColdCallersName",
        ColumnName: "Sales Development Reps",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "SalesRep",
        ColumnName: "Sales Rep",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalSoftBounce",
        ColumnName: "Soft Bounce",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:false,
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "State",
        ColumnName: "State",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactTag",
        ColumnName: "Tag",
        Expression: "EqualTo",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"NotEqualTo",label:"Not Equals"},
        ],
        IsDate:false,
        IsNum:false,
        IsSelect:true,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "Title",
        ColumnName: "Title",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ValidationScore",
        ColumnName: "Validation Score",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Website",
        ColumnName: "Website",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Zip",
        ColumnName: "Zip",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalCalls",
        ColumnName: "Total Calls",
        Expression: "Equals",
        IsSelect:false,
        IsNum:true,
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"LessThan",label:"Less Than"},
        ]
      },
      {
          FieldValue: "DaysSinceLastCall",
          ColumnName: "Days Since Last Call",
          Expression: "GreaterThanEqualTo",
          IsSelect:false,
          IsNum:true,
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
          Options:[
            {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          ],
         
      },
      {
        FieldValue: "LastCallResult",
        ColumnName: "Last Call Result",
        Expression: "Equals",
        IsSelect:true,
        IsNum:false,
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
        ],
        IsDate:false,
        IsNum:false,
        IsSelect:true,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
    ];
    res.data.Data.forEach((el) => {
      Arr.push({
        FieldValue: el.FieldName,
        ColumnName: el.FieldName,
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        FieldFor: "Text",
        ContactTagIds: "",
        IsCustom: true,
        IsSelect: false,
        IsBool: false,
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      });
    });
    // let ChangedArr = [];
    // Arr.forEach((el, i) => {
    //   if (
    //     el.FieldValue == "LastSendDate" ||
    //     el.FieldValue == "LastValidationDate"
    //   ) {
    //     ChangedArr.push({
    //       id: i,
    //       FieldValue: el.FieldValue,
    //       ColumnName: el.ColumnName,
    //       Expression: "EqualTo",
    //       FilterPropertyValue: "",
    //       FilterPropertyValue1: "",
    //       FieldFor: "",
    //       ContactTagIds: "",
    //       IsCustom: false,
    //       IsDate: true,
    //       NullCondition: false,
    //       IsNum: false,
    //       IsSelect: false,
    //       IsBool: false,
    //     });
    //   } else {
    //     if (el.IsCustom == true) {
    //       ChangedArr.push({
    //         id: i,
    //         FieldValue: el.FieldValue,
    //         ColumnName: el.ColumnName,
    //         Expression: "Contains",
    //         FilterPropertyValue: "",
    //         FilterPropertyValue1: "",
    //         FieldFor: "Text",
    //         ContactTagIds: "",
    //         IsCustom: true,
    //         IsDate: false,
    //         NullCondition: false,
    //         IsNum: false,
    //         IsSelect: false,
    //         IsBool: false,
    //         Options:[
    //           {value:"Contains",label:"Contains"},
    //           {value:"EqualTo",label:"Equals"},
    //           {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
    //           {value:"IsNotBlank",label:"Is Not Blank"},
    //         ]
    //       });
    //     } else {
    //       if (el.ColumnName == "Tag") {
    //         ChangedArr.push({
    //           id: i,
    //           FieldValue: el.FieldValue,
    //           ColumnName: el.ColumnName,
    //           Expression: "EqualTo",
    //           FilterPropertyValue: "",
    //           FilterPropertyValue1: "",
    //           FieldFor: "",
    //           ContactTagIds: "",
    //           IsCustom: false,
    //           IsDate: false,
    //           NullCondition: false,
    //           IsNum: false,
    //           IsSelect: false,
    //           IsBool: false,
    //         });
    //       } else {
    //         ChangedArr.push({
    //           id: i,
    //           FieldValue: el.FieldValue,
    //           ColumnName: el.ColumnName,
    //           Expression: "Contains",
    //           FilterPropertyValue: "",
    //           FilterPropertyValue1: "",
    //           FieldFor: "",
    //           ContactTagIds: "",
    //           IsCustom: false,
    //           IsDate: false,
    //           NullCondition: false,
    //           IsNum: false,
    //           IsSelect: false,
    //           IsBool: false,
    //         });
    //       }
    //     }
    //   }
    // });
    
    let SortedArr = Arr.sort(function (a, b) {
      return a.ColumnName.toLowerCase().localeCompare(
        b.ColumnName.toLowerCase()
      );
    });
    SetCustomColumnData(SortedArr);
  });
};

//Fieldname handler
const ColumnhandledDropdownChange = (id, Index) => {
  // Get the selected ColumnName from the dropdown
  const dropdown = document.getElementById("ColumnName" + id);
  const ColumnName = dropdown.value;

  // Clone the FieldAttributes array to avoid mutating the original
  const FieldAttributesDatas = [...FieldAttributes];

  // Find the index of the selected ColumnName in CustomColumnData
  const indexed = CustomColumnData.findIndex((v) => v.FieldValue === ColumnName);

  // Update properties based on the selected ColumnName
  FieldAttributesDatas[Index].FieldValue = ColumnName;
  FieldAttributesDatas[Index].IsCustom = CustomColumnData[indexed].IsCustom;
  FieldAttributesDatas[Index].IsDate = CustomColumnData[indexed].IsDate;

  // Check if ColumnName is "IsBlacklisted" and set IsBool accordingly
  FieldAttributesDatas[Index].IsBool = ColumnName === "IsBlacklisted";

  // Check if ColumnName is a numeric field and set IsNum accordingly
  const numericFields = ["CallCount", "TotalSent", "TotalHardBounce", "TotalOpen", "TotalReply", "TotalSoftBounce"];
  FieldAttributesDatas[Index].IsNum = numericFields.includes(ColumnName);

  // Check if ColumnName is "ContactTag" and set IsSelect and Expression accordingly
  if (ColumnName === "ContactTag") {
    FieldAttributesDatas[Index].IsSelect = true;
    FieldAttributesDatas[Index].Expression = "EqualTo";
  } else {
    FieldAttributesDatas[Index].IsSelect = false;
  }

  // Set FieldFor based on IsCustom
  FieldAttributesDatas[Index].FieldFor = CustomColumnData[indexed].IsCustom ? "Text" : "";

  // Update the FieldAttributes array
  SetFieldAttributes(FieldAttributesDatas);
};

//Expression handler
const ExpressionHandler = (id) => {
  // Clone the FieldAttributes array to avoid mutating the original
  const StateArr = [...FieldAttributes];

  // Get the selected ColumnName and ExpressionName from their respective inputs
  const ColumnName = document.getElementById("ColumnName" + id).value;
  const ExpressionName = document.getElementById("expression" + id).value;

  // Find the index of the element in StateArr with the given id
  const indexed = StateArr.findIndex((v) => v.id === id);

  // If no element with the given id is found, push a new object into StateArr
  if (indexed === -1) {
    StateArr.push({
      id: id,
      ColumnName: ColumnName,
      Expression: ExpressionName,
    });
  } else {
    // Otherwise, update the existing element with the new ColumnName and ExpressionName
    StateArr[indexed].ColumnName = ColumnName;
    StateArr[indexed].Expression = ExpressionName;
  }

  // Check if ExpressionName is "IsNotBlank" or "IsBlank" and set NullCondition accordingly
  StateArr[indexed].NullCondition = ExpressionName === "IsNotBlank" || ExpressionName === "IsBlank";

  // Update the FieldAttributes array
  SetFieldAttributes(StateArr);
};

//Change handler for Contact tags and Last call result
const FilterhandleChangeContactOption = (val, id, FieldName) => {
  // Clone the FieldAttributes array to avoid mutating the original
  const Arr = [...FieldAttributes];

  // Initialize the CidArr based on the FieldName condition
  let CidArr;
  if (FieldName !== "ContactTag") {
    CidArr = val.map((elem) => elem.value);
  } else {
    CidArr = val.map((elem) => elem.cid);
  }

  // Find the index of the element in Arr with the given id
  const indexed = Arr.findIndex((v) => v.id === id);

  // Update ContactTagIds and the corresponding searchvalue input field
  if (indexed !== -1) {
    Arr[indexed].ContactTagIds = CidArr.toString();
    document.getElementById("searchvalue" + id).value = CidArr.toString();

    // Update the FieldAttributes array
    SetFieldAttributes(Arr);
  }
};

//Add new Filter properties
const handleClick = () => {
  // Clone the FieldAttributes array to avoid mutating the original
  const StateArray = [...FieldAttributes];

  // Generate a unique id (you can use any method you prefer)
  const uniqueId = Math.floor(Math.random() * 1000000000);

  // Define the new element with default values
  const newElement = {
    id: uniqueId,
    FieldValue: "AccountCategory",
    ColumnName: "Account Category",
    Expression: "Contains",
    FilterPropertyValue: "",
    FilterPropertyValue1: "",
    IsCustom: false,
    IsDate: false,
    IsBool: false,
    NullCondition: false,
  };

  // Push the new element to the StateArray
  StateArray.push(newElement);

  // Update the FieldAttributes array
  SetFieldAttributes(StateArray);
};

//Remove handler properties
const handleRemoveClick = (id) => {
  if (FieldAttributes.length === 1) {
    // Display an error message when trying to remove the last filter property
    toast.error(
      <div className="toastsize">Filter <br /> Required filter property at least one</div>
    );
  } else {
    // Create a new array that excludes the element with the specified id
    const updatedAttributes = FieldAttributes.filter((element) => element.id !== id);

    // Update the FieldAttributes array
    SetFieldAttributes(updatedAttributes);
  }
};

//Filter validations
const FilterValidation = () => {
  // Initialize an array to store validation results
  let validationArr = [];
  
  FieldAttributes.forEach((newvalue) => {
    if (!newvalue.IsBool && !newvalue.IsSelect) {
      const searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value.trim();
      
      // Check if the search value is empty
      if (searchedVal1 === "") {
        validationArr.push(false);
      } else {
        validationArr.push(true);
      }
      
      const expressionVal = document.getElementById("expression" + newvalue.id).value;
      
      // Check if the expression is "between" and validate the second value
      if (expressionVal === "between" && !newvalue.IsSelect) {
        const filterPropertyValue2 = document.getElementById("EndsWith" + newvalue.id).value.trim();
        
        // Check if the second value is empty
        if (filterPropertyValue2 === "") {
          validationArr.push(false);
        } else {
          validationArr.push(true);
        }
      }
    }
  });
  
  // Check if any validation result is false (i.e., there is an invalid filter condition)
  return validationArr.includes(false);
};

//Apply Filter search
const filterSearch = () => {
  // Check if filter validation is successful
  if (!FilterValidation()) {
    // Check if there are no filter properties added
    if (FieldAttributes.length <= 0) {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Please add at least one filter property
        </div>
      );
    } else {
      // Initialize an array to store filter conditions
      const contactFilterSecArray = [];
      
      FieldAttributes.forEach((newvalue) => {
        const searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value.trim();
        const expressionVal = document.getElementById("expression" + newvalue.id).value.trim();
        const ColumnName = document.getElementById("ColumnName" + newvalue.id).value;
        
        if (expressionVal === "between") {
          const searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value;
          contactFilterSecArray.push({
            ColumnName: ColumnName,
            expression: expressionVal,
            searchvalue: searchedVal1,
            searchvalue1: searchedVal2,
            FieldType: newvalue.FieldFor,
            IsCustom: newvalue.IsCustom,
          });
        } else {
          contactFilterSecArray.push({
            ColumnName: ColumnName,
            expression: expressionVal,
            searchvalue: searchedVal1,
            searchvalue1: "", // Set to an empty string if not using "between"
            FieldType: newvalue.FieldFor,
            IsCustom: newvalue.IsCustom,
          });
        }
      });
      
      // Update filter parameters, close the filter box, and set flags
      SetFilterParameters(contactFilterSecArray);
      SetOpenFilterBox(false);
      SetIsApply(true);
      SetIsFiltered(true);
    
      // Reset the filter attributes to default values
      SetFieldAttributes([
        {
          id: Math.floor(Math.random() * 1000000000),
          FieldValue: "AccountCategory",
          ColumnName: "Account Category",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
          IsCustom: false,
          IsDate: false,
          IsBool: false,
          NullCondition: false,
        },
      ]);
      
      SetOpenAddFilter(false);
      toast.success(
        <div className="toastsize">
          Filter <br />
          Filter Applied Successfully.
        </div>
      );
    }
  } else {
    toast.error(
      <div className="toastsize">
        Filter
        <br />
        Property value cannot be empty
      </div>
    );
  }
};

//Getting default filter properties
const GetDefaultProperties = (FilterID) => {
  Axios({
    url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetDefaultFilterDetails",
    method: "POST",
    data: { FilterID },
  })
    .then((res) => {
      if (res.data.StatusMessage === 'SUCCESS') {
        const convertedData = res.data.Data.map((item) => {
          let filterPropertyValue = item.FilterPropertyName === "LastCallResult"
            ? item.FilterPropertyValue1.split(',').map(value => ({ label: value, value }))
            : item.FilterPropertyValue1;

          let options = [];
          if (item.FilterPropertyName === "TotalCalls") {
            options.push({ value: "LessThan", Label: "Less Than" });
          } else if (item.FilterPropertyName === "DaysSinceLastCall") {
            options.push({ value: "GreaterThanEqualTo", Label: "Greater Than Equal To" });
          } else if (item.FilterPropertyName === "LastCallResult") {
            options.push({ value: "EqualTo", Label: "Equals" });
          } else if (item.FilterPropertyName === "IsBlacklisted") {
            options.push({ value: "False", Label: "No" }, { value: "True", Label: "Yes" });
          } else if (item.FilterPropertyName === "ContactTag") {
            options.push({ value: "EqualTo", Label: "Equals" }, { value: "NotEqualTo", Label: "Not Equal" });
          }

          return {
            id: Math.floor(Math.random() * 1000000000),
            FieldValue: item.FilterPropertyName,
            ColumnName: item.FilterPropertyName,
            Expression: item.ExpressionName,
            FilterPropertyValue: filterPropertyValue,
            FilterPropertyValue1: item.FilterPropertyName === "LastCallResult" ? item.FilterPropertyValue2 : null,
            IsCustom: false,
            IsDate: false,
            IsNum: ["TotalCalls", "DaysSinceLastCall"].includes(item.FilterPropertyName),
            IsBool: item.FilterPropertyName === "IsBlacklisted",
            IsSelect: ["ContactTag", "LastCallResult"].includes(item.FilterPropertyName),
            NullCondition: false,
            IsDefault: true,
            Options: options,
          };
        });
        
        SetFieldAttributes(convertedData);
      } else {
        console.error("Failed to retrieve data:", res.data.ErrorMessage);
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

//Reset filter
const ResetFilter = () => {
  SetSelectedFilterID(FilterDefaultID);
  SetReset(true)  
  SetIsApply(false);
  SetFilterParameters([]);
};

//Filter value handler method
const ValueHandler = (ID, FieldAlias, value) => {
  SetFieldAttributes((prevArray) =>
    prevArray.map((obj) => {
      if (obj.id === ID) {
        if (FieldAlias === "searchvalue") {
          return { ...obj, FilterPropertyValue: value };
        } else if (FieldAlias === "EndsWith") {
          return { ...obj, FilterPropertyValue1: value };
        }
      }
      return obj;
    })
  );
};

// Check filter name exist or not
const CheckFilterName = async () => {
  try {
    const FilterName = document.getElementById("FilterName").value.trim();

    if (!FilterName) {
      // Handle empty filter name
      return false;
    }

    const InputParameters = {
      ClientID: ClientID, // Assuming ClientID is defined elsewhere
      FilterName: FilterName,
    };

    const response = await Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/CheckColdCallingContactFilterName",
      method: "POST",
      data: InputParameters,
    });

    if (response.data.StatusMessage === "SUCCESS") {
      // Check if filter name already exists
      return response.data.Data <= 0;
    } else {
      // Handle API error
      toast.error("Something went wrong in filter name exists API");
      return false;
    }
  } catch (error) {
    // Handle unexpected errors
    console.error("An error occurred:", error);
    return false;
  }
};

//save filter properties and filter name
const AddColdCallingContactFilter = async () => {
  try {
    const FilterName = document.getElementById("FilterName").value.trim();
    
    if (FilterName === "") {
      toast.error(<div className="toastsize">Filter<br />Please enter filter name</div>);
      return;
    }
    
    if (!(await CheckFilterName())) {
      toast.error(<div className="toastsize">Filter<br />Filter value already exists</div>);
      return;
    }
    
    if (FilterValidation()) {
      toast.error(<div className="toastsize">Filter<br />Property value cannot be empty</div>);
      return;
    }

    if (FieldAttributes.length <= 0) {
      toast.error(<div className="toastsize">Filter<br />Please add at least one filter property</div>);
      return;
    }

    const ContactFilterSecArray = FieldAttributes.map((newvalue) => {
      const searchedVal1 = document.getElementById(`searchvalue${newvalue.id}`).value;
      const expressionval = document.getElementById(`expression${newvalue.id}`).value;
      const ColumnName = document.getElementById(`ColumnName${newvalue.id}`).value;
      
      if (expressionval === "between") {
        const searchedVal2 = document.getElementById(`EndsWith${newvalue.id}`).value;
        return {
          ColumnName,
          expression: expressionval,
          searchvalue: searchedVal1,
          searchvalue1: searchedVal2,
          FieldType: newvalue.FieldFor,
        };
      } else {
        return {
          ColumnName,
          expression: expressionval,
          searchvalue: searchedVal1,
          searchvalue1: null,
          FieldType: newvalue.FieldFor,
        };
      }
    });

    const InputParameters = {
      ClientID: ClientID, // Assuming ClientID is defined elsewhere
      FilterName: FilterName,
      FilterParameters: ContactFilterSecArray,
      CreatedDate: new Date(),
      UserID: UserID, // Assuming UserID is defined elsewhere
    };

    const res = await Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/SaveColdCallingContactFilter",
      method: "POST",
      data: InputParameters,
    });

    if (res.data.StatusMessage === "SUCCESS") {
      SetFilterParameters(ContactFilterSecArray);
      SetOpenAddFilter(false);
      SetOpenFilterBox(false);
      SetIsApply(true);
      SetIsFiltered(true);
      ColdCallingContactFilterGet(ClientID, UserID);
      toast.success(<div className="toastsize">Filter<br />Filter Added Successfully.</div>);
    } else {
      toast.error("Error in saving the filter");
    }
  } catch (error) {
    console.error("An error occurred:", error);
    toast.error("An error occurred while adding the filter");
  }
};

//Open Edit model
const OpenEditFilterModel = () => {
  SetOpenEditFilterBox(true)
}


       //Update filter select box
       const UpdateFilterSelectBox = (e) => {
        if(e.target.value == FilterDefaultID){
          SetIsSaveButton(false)
        }else{
          SetIsSaveButton(true)
        }
        SetSelectedEditFilter(e.target.value)
        Getfilterpropertylist(e.target.value);

    }

    // Get FilteProperty list with dropdown
    const Getfilterpropertylist = (filterid) => {
      SetFilterID(filterid);
  
      var InputParameters = {
        ClientID: ClientID,
        FilterID: filterid,
        IsDeleted: false,
      };
      const rows4 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetColdCallingContactFilterProperty",
        method: "POST",
        data: InputParameters,
      });
      rows4.then((result) => {
        let FilteredArr = [];
        result?.data?.Data?.forEach((el, i) => {
          el.id = i;
          el.IsSaved = true;
          if (
            el.FilterPropertyName == "LastSendDate" ||
            el.FilterPropertyName == "LastValidationDate"
          ) {
            el.IsDate = true;
          } else {
            if (el.FieldType == "Text") {
              el.IsCustom = true;
            } else {
              el.IsCustom = false;
            }
            el.IsDate = false;
          }
          if (el.FilterPropertyName == "IsBlacklisted") {
            el.IsBool = true;
          } else {
            el.IsBool = false;
          }
          if (
            el.FilterPropertyName == "CallCount" ||
            el.FilterPropertyName == "TotalSent" ||
            el.FilterPropertyName == "TotalHardBounce" ||
            el.FilterPropertyName == "TotalOpen" ||
            el.FilterPropertyName == "TotalReply" ||
            el.FilterPropertyName == "TotalSoftBounce"
          ) {
            el.IsNum = true;
          } else {
            el.IsNum = false;
          }
          if (el.FilterPropertyName == "ContactTag") {
            el.IsSelect = true;
            el.Expression = "EqualTo";
  
            let ContactTagIds = el.FilterPropertyValue1?.split(",");
            let ArrayObj = [];
  
            ContactTagIds.forEach((elem) => {
              let Indexed = ContactTags?.findIndex((v) => v.ContactTagID == elem);
              ArrayObj.push({
                label: ContactTags[Indexed]?.Tag,
                value: ContactTags[Indexed]?.ContactTagID,
                cid: ContactTags[Indexed]?.ContactTagID,
              });
            });
            el.DefaultSelectIDs = ArrayObj;
          } else {
            el.IsSelect = false;
            el.DefaultSelectIDs = [];
          }
          if (
            el.ExpressionName == "IsNotBlank" ||
            el.ExpressionName == "IsBlank"
          ) {
            el.NullCondition = true;
          } else {
            el.NullCondition = false;
          }
          FilteredArr.push(el);
        });
  
        SetEditFieldAttributes(FilteredArr);
      });
    };

    //Applied Filter
    const AppliedFilter = (data) => {
      if(data.length === 0){

      }else{
        ColdCallingContactFilterGet(ClientID, UserID)
        SetAppliedFilterData(data);

      }
    }
    //Close Edit Filter Model 
    const CloseModel = data => {
      SetOpenEditFilterBox(data);
      ColdCallingContactFilterGet(ClientID, UserID)
    }

          //Update filter Drop down
          const UpdateFilterDropDown = (data) => {
            console.log(data)
            if(data){
              ColdCallingContactFilterGet(ClientID, UserID)
            }
          }

  return (
    <>
      <Popup open={ShowExportPopUp}>
        <div>
          <div className="modal-black"></div>
          <div className="filterPopup largerPopup">
            <div className="paddingboxTerms">
              <div className="modal-header py-4 px-3">
                <div className="w-100 d-flex px-3">
                  <h5 className="mb-0">Email</h5>
                  <button
                    className="close"
                    onClick={() => CloseExportPopup(false)}
                  >
                    <span aria-hidden="true" className="la la-remove"></span>
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="modal-body">
                  <div className="col-sm-6 mb-5 px-0">
                    <div className="row mb-3">
                      <label className="col-lg-5 col-form-label text-left">
                        Email
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="UserEmail"
                          data-val="true"
                          name="email"
                          placeholder='Enter email'
                          onBlur={handleEmailBlur}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                        />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top modal-content text-right">
                  <div className="row">
                    <div className="col-lg-12 pull-right">
                      <a
                        id="backtolist"
                        className="btn btn-secondary mr-2"
                        onClick={() => CloseExportPopup()}
                      >
                        cancel
                      </a>
                      <button
                        id="submit"
                        disabled={DisabledContactExportBtn}
                        onClick={(e) => SubmitExport(e)}
                        className="btn btn-primary btn-lightgreen mr-1"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <div id="hidelod" className="loding-display">
        <img src={loadingicon} />
      </div>
      <Popup open={AddBurnerModel}>
        <div className="modal-black"></div>
        <div className="filterPopup largerPopup">
          <div className="paddingboxTerms">
            <div className="modal-header py-4 px-3">
              <div className="w-100 d-flex px-3">
                <h5 className="mb-0">Phone Number</h5>
                <button className="close"
                  onClick={() => SetAddBurnerModel(false)}
                >
                  <span aria-hidden="true" class="la la-remove"></span>
                </button>
              </div>
            </div>
            <div className='p-3'>
              <div className="modal-body">
                <div className='col-sm-12 px-0'>
                  <div class="d-flex mb-3 alllabeset">
                    <FormControlLabel  
                      label="All"
                      control={
                        <Checkbox
                          checked={checkeddata["IsMobilePhone"] && checkeddata["IsCompanyPhone"] && checkeddata["IsDirectPhone"] && checkeddata["IsOtherPhone1"] && checkeddata["IsOtherPhone2"]}
                          onChange={handleChange1}
                        />
                      }
                    />
                    {children}

                  </div>
                </div>
              </div>
              <div className="border-top modal-content text-right">
                <div class="row">
                  <div class="col-lg-12 pull-right">

                    <a id="backtolist" class="btn btn-secondary mr-2"
                      onClick={() => {
                        SetAddBurnerModel(false)
                      }}
                    >
                      cancel
                    </a>
                    <a id="submit"
                      onClick={() => { CallBtn() }}
                      class="btn btn-primary btn-lightgreen mr-1">
                      call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

  
    <div className="row"> 
        <div className="col border-bottom">
          <div className="listing-li float-right padb-15 iconbox-pad">
            <ul>
              <li>
                <Tooltip title="Add">
                <button onClick={AddBtn} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-plus"></i>
                </button>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Export">
                <button onClick={OpenExportPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-download"></i>
                </button>
                </Tooltip>
              </li>
              {Role == "ColdCallers" ?
                <li><Tooltip title="Call">
                  <a onClick={() => { MultipleCallDial() }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                    <i class="la-icon-font-size-13 fa fa-phone-square"></i>
                  </a>
                </Tooltip>
                </li> : null}

            </ul>
          </div>
        </div>
      </div>

      <div className="row mt-2 mb-2">
        <div className="col border-bottom">
          <div className="listing-li float-right padb-15 iconbox-pad">
            <ul>
              <li>
              <select value={SelectedFilterID != 0? SelectedFilterID : ''} className="selectboxdef" onChange={(e)=>FilterDropDownHandler(e)}>
                <option value="--Select--">--Select--</option>
                {ColdContactFilterList.map(item => (
                  <option key={item._id} value={item._id}>
                    {item.FilterName}
                  </option>
                ))}
                </select>
              </li>
              {
               (FilterDefaultID != SelectedFilterID ) &&  (SelectedFilterID != 0)? (
                  <li>
                  <Tooltip title="Delete">
                    <button className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                      <i class="la la-icon-font-size-13 la-trash"></i>
                    </button>
                    </Tooltip>
                  </li>
                ) : (null)
              }

              <li onClick={() => { OpenFilterModel()}}>
                <Tooltip title="Add">
                <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-plus"></i>
                </a>
                </Tooltip>
              </li>

              <li onClick={() => { OpenEditFilterModel()}}>
                <Tooltip title="Edit Filter">
                <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-pencil"></i>
                </a></Tooltip>
              </li>

              <Popup open={OpenFilterBox}>
  <div className="modal-black"></div>
  <div className="filterPopup largerPopup1000">
    <div className="paddingboxTerms">
      <div className="modal-header py-4 px-3">
        <div className="w-100 d-flex px-3">
          <h5 className="mb-0">Filter</h5>
          <button className="close" onClick={() => CloseFilterModel()}>
            <span aria-hidden="true" className="la la-remove"></span>
          </button>
        </div>
      </div>
      <div className="modal-body">
        <div className="row px-4">
          <div className="col-lg-8 row mb-4">
            <label className="col-lg-3 col-form-label pl-0">
              Filter Name
            </label>
            <div className="col-lg-9 d-flex">
              <input
                className="form-control m-input"
                data-val-required="Please enter title"
                id="FilterName"
                name="FilterName"
                placeholder="Filter Name"
                type="text"
              />
            </div>
          </div>
        </div>

        <div className="table-bordered">
          <table className="table  table-hover" id="tab_logic">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Property</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FieldAttributes.map((itemcount, i) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={itemcount.id}
                    >
                      <TableCell align="right">
                        <select
                          className="form-control m-input"
                          id={"ColumnName" + itemcount.id}
                          onChange={() => {
                            ColumnhandledDropdownChange(itemcount.id, i);
                          }}
                          value={itemcount.FieldValue}
                          autoComplete="off"
                          disabled={itemcount.IsDefault ? true : false}
                        >
                          {CustomColumnData.map((el) => (
                            <option key={el.FieldValue} value={el.FieldValue}>
                              {el.ColumnName}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell></TableCell>
                      {itemcount.IsDefault === true ? (
                        <>
                          <TableCell align="right">
                            <select
                              id={"expression" + itemcount.id}
                              onChange={() => {
                                ExpressionHandler(itemcount.id);
                              }}
                              className="form-control m-input"
                            >
                              {itemcount.Options.map((el, index) => (
                                <option key={index} value={el.value}>
                                  {el.Label}
                                </option>
                              ))}
                            </select>
                          </TableCell>
                          <TableCell align="right">
                            {itemcount.IsSelect === true ? (
                              <>
                                {itemcount.FieldValue === "ContactTag" ? (
                                  <Select
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={contactoptions}
                                    defaultValue={itemcount.FilterPropertyValue}
                                    id="ContactTagID"
                                    isMulti
                                    onChange={(val) =>
                                      FilterhandleChangeContactOption(
                                        val,
                                        itemcount.id,
                                        "ContactTag"
                                      )
                                    }
                                  />
                                ) : (
                                  <Select
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={LastCallResultDropDown}
                                    defaultValue={itemcount.FilterPropertyValue}
                                    id="ContactTagID"
                                    isMulti
                                    onChange={(val) =>
                                      FilterhandleChangeContactOption(
                                        val,
                                        itemcount.id,
                                        "LastCallResult"
                                      )
                                    }
                                  />
                                )}

                                <input
                                  type="hidden"
                                  readOnly
                                  id={"searchvalue" + itemcount.id}
                                  className="form-control m-input required"
                                />
                              </>
                            ) : itemcount.IsBool === true ||
                              itemcount.NullCondition === true ? (
                              <input
                                type="hidden"
                                hidden={true}
                                readOnly
                                id={"searchvalue" + itemcount.id}
                                value="y"
                                className="form-control m-input required"
                              />
                            ) : itemcount.IsDate === true ? (
                              itemcount.Expression === "between" ? (
                                <>
                                  <input
                                    type="date"
                                    id={"searchvalue" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "searchvalue",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                    value={itemcount.FilterPropertyValue}
                                  />
                                  <input
                                    type="date"
                                    id={"EndsWith" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "EndsWith",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                    value={itemcount.FilterPropertyValue1}
                                  />
                                </>
                              ) : (
                                <input
                                  type="date"
                                  id={"searchvalue" + itemcount.id}
                                  onChange={(e) =>
                                    ValueHandler(
                                      itemcount.id,
                                      "searchvalue",
                                      e.target.value
                                    )
                                  }
                                  className="form-control m-input required"
                                  value={itemcount.FilterPropertyValue}
                                />
                              )
                            ) : itemcount.IsNum === true ? (
                              itemcount.Expression === "between" ? (
                                <>
                                  <input
                                    type="text"
                                    id={"searchvalue" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "searchvalue",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                    value={itemcount.FilterPropertyValue}
                                  />
                                  <input
                                    type="text"
                                    id={"EndsWith" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "EndsWith",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                    value={itemcount.FilterPropertyValue1}
                                  />
                                </>
                              ) : (
                                <input
                                  type="text"
                                  id={"searchvalue" + itemcount.id}
                                  onChange={(e) =>
                                    ValueHandler(
                                      itemcount.id,
                                      "searchvalue",
                                      e.target.value
                                    )
                                  }
                                  className="form-control m-input required"
                                  value={itemcount.FilterPropertyValue}
                                />
                              )
                            ) : (
                              <input
                                type="text"
                                id={"searchvalue" + itemcount.id}
                                onChange={(e) =>
                                  ValueHandler(
                                    itemcount.id,
                                    "searchvalue",
                                    e.target.value
                                  )
                                }
                                className="form-control m-input required"
                                value={itemcount.FilterPropertyValue}
                              />
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell align="right">
                            {itemcount.IsSelect === true ? (
                              <select
                                id={"expression" + itemcount.id}
                                onChange={() => {
                                  ExpressionHandler(itemcount.id);
                                }}
                                className="form-control m-input"
                              >
                                <option id="EqualTo" value="EqualTo">
                                  Equals
                                </option>
                                <option id="NotEqualTo" value="NotEqualTo">
                                  Not Equal
                                </option>
                              </select>
                            ) : itemcount.IsBool === true ? (
                              <div>
                                <select
                                  id={"expression" + itemcount.id}
                                  onChange={() => {
                                    ExpressionHandler(itemcount.id);
                                  }}
                                  className="form-control m-input"
                                >
                                  <option id="true" value="True">
                                    Yes
                                  </option>
                                  <option id="false" value="False">
                                    No
                                  </option>
                                </select>
                                <input
                                  type="hidden"
                                  readOnly
                                  id={"searchvalue" + itemcount.id}
                                  value="y"
                                  className="form-control m-input required"
                                />
                              </div>
                            ) : itemcount.IsNum === true ? (
                              <select
                                id={"expression" + itemcount.id}
                                className="form-control m-input"
                                onChange={() => {
                                  ExpressionHandler(itemcount.id);
                                }}
                              >
                                <option id="Equals" value="Equals">
                                  Equals
                                </option>
                                <option id="Lessthan" value="LessThan">
                                  Less Than
                                </option>
                                <option
                                  id="LessThanEqualTo"
                                  value="LessThanEqualTo"
                                >
                                  Less Than Equal To
                                </option>
                                <option id="greaterthan" value="GreaterThan">
                                  Greater Than
                                </option>
                                <option
                                  id="greaterthanequalto"
                                  value="GreaterThanEqaulTo"
                                >
                                  Greater Than Equal To
                                </option>
                                <option id="between" value="between">
                                  Between
                                </option>
                              </select>
                            ) : itemcount.IsDate === true ? (
                              <select
                                id={"expression" + itemcount.id}
                                className="form-control m-input"
                                onChange={() => {
                                  ExpressionHandler(itemcount.id);
                                }}
                              >
                                <option id="Equals" value="Equals">
                                  Equals
                                </option>
                                <option id="Lessthan" value="LessThan">
                                  Less Than
                                </option>
                                <option
                                  id="LessThanEqualTo"
                                  value="LessThanEqualTo"
                                >
                                  Less Than Equal To
                                </option>
                                <option id="greaterthan" value="GreaterThan">
                                  Greater Than
                                </option>
                                <option
                                  id="greaterthanequalto"
                                  value="GreaterThanEqaulTo"
                                >
                                  Greater Than Equal To
                                </option>
                                <option id="between" value="between">
                                  Between
                                </option>
                                <option id="IsNotBlank" value="IsNotBlank">
                                  Is Not Blank
                                </option>
                                <option id="IsBlank" value="IsBlank">
                                  Is Blank
                                </option>
                              </select>
                            ) : (
                              <select
                                id={"expression" + itemcount.id}
                                onChange={() => {
                                  ExpressionHandler(itemcount.id);
                                }}
                                className="form-control m-input"
                              >
                                <option id="Contains" value="Contains">
                                  Contains
                                </option>
                                <option id="EqualTo" value="EqualTo">
                                  Equals
                                </option>
                                <option id="IsNotBlank" value="IsNotBlank">
                                  Is Not Blank
                                </option>
                                <option
                                  id="IsBlank"
                                  value="DoesNotEqual/DoesNotContain"
                                >
                                  Does Not Equal / Does Not Contain
                                </option>
                              </select>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {itemcount.IsSelect === true ? (
                              <>
                                <Select
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  options={contactoptions}
                                  id="ContactTagID"
                                  isMulti
                                  onChange={(val) =>
                                    FilterhandleChangeContactOption(
                                      val,
                                      itemcount.id,
                                      "ContactTag"
                                    )
                                  }
                                />
                                <input
                                  type="hidden"
                                  readOnly
                                  id={"searchvalue" + itemcount.id}
                                  className="form-control m-input required"
                                />
                              </>
                            ) : itemcount.IsBool === true ||
                              itemcount.NullCondition === true ? (
                              <input
                                type="hidden"
                                hidden={true}
                                readOnly
                                id={"searchvalue" + itemcount.id}
                                value="y"
                                className="form-control m-input required"
                              />
                            ) : itemcount.IsDate === true ? (
                              itemcount.Expression === "between" ? (
                                <>
                                  <input
                                    type="date"
                                    id={"searchvalue" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "searchvalue",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                  />
                                  <input
                                    type="date"
                                    id={"EndsWith" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "EndsWith",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                  />
                                </>
                              ) : (
                                <input
                                  type="date"
                                  id={"searchvalue" + itemcount.id}
                                  onChange={(e) =>
                                    ValueHandler(
                                      itemcount.id,
                                      "searchvalue",
                                      e.target.value
                                    )
                                  }
                                  className="form-control m-input required"
                                />
                              )
                            ) : itemcount.IsNum === true ? (
                              itemcount.Expression === "between" ? (
                                <>
                                  <input
                                    type="text"
                                    id={"searchvalue" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "searchvalue",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                  />
                                  <input
                                    type="text"
                                    id={"EndsWith" + itemcount.id}
                                    onChange={(e) =>
                                      ValueHandler(
                                        itemcount.id,
                                        "EndsWith",
                                        e.target.value
                                      )
                                    }
                                    className="form-control m-input required"
                                  />
                                </>
                              ) : (
                                <input
                                  type="text"
                                  id={"searchvalue" + itemcount.id}
                                  onChange={(e) =>
                                    ValueHandler(
                                      itemcount.id,
                                      "searchvalue",
                                      e.target.value
                                    )
                                  }
                                  className="form-control m-input required"
                                />
                              )
                            ) : (
                              <input
                                type="text"
                                id={"searchvalue" + itemcount.id}
                                onChange={(e) =>
                                  ValueHandler(
                                    itemcount.id,
                                    "searchvalue",
                                    e.target.value
                                  )
                                }
                                className="form-control m-input required"
                              />
                            )}
                          </TableCell>
                        </>
                      )}

                      <TableCell align="right"></TableCell>
                      <TableCell align="left">
                        <button
                          type="button"
                          title="Delete"
                          id={"delete" + itemcount.id}
                          className="btn btngroup btnhov-delete"
                          onClick={() => {
                            handleRemoveClick(itemcount.id);
                          }}
                        >
                          <i className="la flaticon-delete-1"></i>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </table>
        </div>
        <div className="row">
          <div className="col-sm-12 text-left">
            <button
              type="button"
              className="btn btngroup"
              value="ADD Row"
              onClick={handleClick}
            >
              <i className="la la-plus la-icon-font-size-13"></i> Add Property
            </button>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={() => CloseFilterModel()}
        >
          Cancel
        </button>
        <button
          id="m_emailInvite_submit"
          href="javascript:void(0);"
          className="btn btn-primary btnColor"
          onClick={() => filterSearch()}
        >
          Apply
        </button>
        <button
          id="m_emailInvite_submit"
          href="javascript:void(0);"
          className="btn btn-primary btnColor"
          onClick={() => AddColdCallingContactFilter()}
        >
          <i className="la la-save mr-2"></i> Save
        </button>
      </div>
    </div>
  </div>
              </Popup>



              <Popup open={OpenEditFilterBox}>
              <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup1000">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-4 px-3">
                                            <div className="w-100 d-flex px-3">
                                              <h5 className="mb-0"> Edit Filter</h5>
                                              <button className="close" onClick={ ()=>SetOpenEditFilterBox(false)}>
                                                <span aria-hidden="true"  className="la la-remove" ></span>
                                              </button>
                                            </div>
                                          </div>
                                          <div className="px-3">
                                          <div className="modal-body">
                                            <div class="col-lg-8 row">
                                              <label class="col-lg-3 col-form-label pl-0">
                                                Filter Name
                                              </label>
                                              <div class="col-lg-9 d-flex">
                                              <select value={SelectedEditFilter != 0? SelectedEditFilter : ''} 
                                                className="selectboxdef" 
                                                onChange={(e)=>UpdateFilterSelectBox(e)}>
                                                  {ColdContactFilterList.map(item => (
                                                    <option key={item._id} value={item._id}>
                                                      {item.FilterName}
                                                    </option>
                                                  ))}
                                              </select>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
                                           <EditFilter 
                                            options={CustomColumnData} 
                                            data={EditFieldAttributes} 
                                            AppliedFilter={AppliedFilter}
                                            CloseModel={CloseModel}
                                            IsSaveButton={IsSaveButton}
                                            ContactTags={ContactTagsOptions}
                                            ClientID={ClientID}
                                            UserID={UserID}
                                            FilterID={SelectedEditFilter}
                                            UpdateFilterDropDown = {UpdateFilterDropDown}
                                           />
                                      </div>
                                      </div>
              </div>
              </Popup>

            </ul>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-sm-12 col-md-6"></div>
        <div className="col-sm-12 col-md-12 full-right">
          {
          
          selectedIds.length === 0 ? <a onClick={SelectAll} className='select-all'>Select All</a> : <a onClick={UnSelectAll} className='select-all'>Unselect All</a>

          }
          
        </div>
        {
          (!Reset) && (FilterDefaultID != SelectedFilterID ) || IsApply? <div className="col-sm-12 col-md-12 full-right">
          <a onClick={ResetFilter}  className='select-all'>Reset</a>
        </div> : <></>
        }
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Call Notes</TableCell>
              <TableCell>Follow Up Notes</TableCell>
              <TableCell><input name="select_all" type="checkbox" checked={selectAll}
                  onChange={handleSelectAll} /></TableCell>
              {Role == "ColdCallers" ? <TableCell></TableCell> : null}
              <TableCell style={{ minWidth: '110px' }}><a onClick={() => { SortData("Name"); }}>Name</a></TableCell>
              <TableCell><a onClick={() => { SortData("Title"); }}>Title</a></TableCell>
              <TableCell><a onClick={() => { SortData("Company"); }}>Company</a></TableCell>
              <TableCell style={{ minWidth: '130px'}}><a onClick={() => { SortData("LastCallResult"); }}>Last Call Result</a></TableCell>
              <TableCell><a onClick={() => { SortData("TagsData"); }}>Tag</a></TableCell>
              <TableCell><a onClick={() => { SortData("CallCount"); }}>Call Count</a></TableCell>
              <TableCell><a onClick={() => { SortData("TimeZoneCode"); }}>Time Zone</a></TableCell>
              <TableCell><a onClick={() => { SortData("LastCall"); }}>Last Call Date</a></TableCell>
              <TableCell><a onClick={() => { SortData("Email"); }}>Email</a></TableCell>
              <TableCell><a onClick={() => { SortData("MobilePhone"); }}>Mobile Phone</a></TableCell>
              <TableCell><a onClick={() => { SortData("DirectPhone"); }}>Direct Phone</a></TableCell>
              <TableCell><a onClick={() => { SortData("DirectPhoneExt"); }}>Direct Phone Ext.</a></TableCell>
              <TableCell><a onClick={() => { SortData("CompanyPhones"); }}>Company Phone</a></TableCell>
              <TableCell><a onClick={() => { SortData("OtherPhone1"); }}>Other Phone 1</a></TableCell>
              <TableCell><a onClick={() => { SortData("OtherPhone2"); }}>Other Phone 2</a></TableCell>
              <TableCell><a onClick={() => { SortData("FollowUpDate"); }}>Follow Up Date</a></TableCell>
              <TableCell><a onClick={() => { SortData("CreatedDate"); }}>Created Date</a></TableCell>
              <TableCell><a onClick={() => { SortData("ColdCallersName"); }}>Sales Development Reps</a></TableCell>
              <TableCell><a onClick={() => { SortData("SalesRep"); }}>Sales Rep</a></TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.map((row) => (
              <>
                <TableRow key={row._id} row={row} >
                  <TableCell>
                    <IconButton aria-label="expand row" size="small"
                      onClick={() =>
                        SetOpen((prev) => ({
                          ...prev,
                          [row._id]:
                            !prev[row._id],
                        }))
                      }
                    >


                      {Open[row._id] ? (
                        <i
                          className="fa fa-minus-circle plusbl"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-plus-circle minusl"
                          aria-hidden="true"
                        ></i>
                      )}
                    </IconButton></TableCell>
                  <TableCell> <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      SetFollowUpNotesOpen((prev) => ({
                        ...prev,
                        [row._id]:
                          !prev[row._id],
                      }))
                    }
                  >


                    {FollowUpNotesOpen[row._id] ? (
                      <i
                        className="fa fa-minus-circle plusbl"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-plus-circle minusl"
                        aria-hidden="true"
                      ></i>
                    )}
                  </IconButton></TableCell>
                  {/* <TableCell><input name="select" value={row} id={row._id} type="checkbox" onChange={(e) => HandleCall(e, row)} /></TableCell> */}
                  <TableCell><input name="select" type='checkbox' checked={selectedIds.includes(row._id)} onChange={() => handleRowCheckboxChange(row._id)}/></TableCell>
                  {Role == "ColdCallers" ? <TableCell><IconButton aria-label="expand row" size="small" onClick={(e) => OpenDialPopup(e, row)} ><i class="fa fa-phone-square col-white phoneicons"></i></IconButton></TableCell> : null}
                  <TableCell>{row.FirstName} {row.LastName}</TableCell>
                  <TableCell>{row.Title}</TableCell>
                  <TableCell>{row.Company}</TableCell>
                  <TableCell>{row.LastCallResult != null ? row.LastCallResult : "Never Been Called"}</TableCell>
                  <TableCell>{row.Tagnames}</TableCell>
                  <TableCell>{row.CallCount != null ? row.CallCount : 0}</TableCell>
                  <TableCell>{row.TimeZoneCode}</TableCell>
                  <TableCell>{row.LastCallDate != null ? moment(new Date(row.LastCallDate).toDateString()).format("MM/DD/YYYY"):""}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.MobilePhone}</TableCell>
                  <TableCell>{row.DirectPhone}</TableCell>
                  <TableCell>{row.DirectPhoneExt}</TableCell>
                  <TableCell>{row.CompanyPhone}</TableCell>
                  <TableCell>{row.OtherPhone1}</TableCell>
                  <TableCell>{row.OtherPhone2}</TableCell>
                  <TableCell>{row.FollowUpDate != null ? moment(new Date(row.FollowUpDate).toDateString()).format("MM/DD/YYYY"):""}</TableCell>
                  <TableCell>{row.CreatedDate != null ? moment(new Date(row.CreatedDate).toDateString()).format("MM/DD/YYYY"):""}</TableCell>
                  <TableCell>{row.coldcallers?.ColdCallersName} {row.coldcallers?.LastName}</TableCell>
                  <TableCell>{row.SalesRep}</TableCell>
                  <TableCell> <a
                    onClick={() => {
                      EditBtn(row._id,row.Email);
                    }}
                    className="btn-eyesicon"
                  >
                    <i className="la flaticon-edit-1 edit-icon"></i>
                  </a></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={15}
                  >
                    <Collapse
                      in={Open[row._id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {Open[row._id] && (
                        <Box margin={1} className="innertables">
                          {!row.CallNotes ? "No notes available" : row.CallNotes}
                        </Box>
                      )}
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={15}
                  >
                    <Collapse
                      in={FollowUpNotesOpen[row._id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {FollowUpNotesOpen[row._id] && (
                        <Box margin={1} className="innertables">
                          {!row.FollowUpNotes ? "No notes available" : row.FollowUpNotes}
                        </Box>
                      )}
                    </Collapse>
                  </TableCell>
                </TableRow>


              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
