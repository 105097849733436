import React from 'react';
const moment=require('moment');
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CallHistory from '../../_components/user/ColdCalling/CallHistory';

class CallHistoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          FromDate:new Date(moment().subtract(90, "days")),
          ToDate:new Date()
        };
        
    this.SaveBtn = this.SaveBtn.bind(this);
        
      }
    
      componentDidMount() {
        document.getElementById('from-date').valueAsDate=new Date(moment().subtract(90, "days"));
       document.getElementById('to-date').valueAsDate=new Date();
                let FromDates=document.getElementById('from-date').value;
                let ToDates=document.getElementById('to-date').value;
       this.setState({FromDate:FromDates,ToDate:ToDates})
       
      }

    SaveBtn(){
        let FromDate=document.getElementById('from-date').value;
        let ToDate=document.getElementById('to-date').value;
        console.log("FromDate => ",FromDate)

        this.setState({FromDate:FromDate,ToDate:ToDate})
    }

    render() {
        return (
            <>
                <div className="adminmain mheight">
                <Sidebar className=""/>
                <div className="bodyhome">

                

                <div className="row">
                        <div className="col">
                            <h4 className="headertitlepad float-left py-4 mt-0">Call History</h4>
                        </div>
                        <div className="col pt-3">
                            <div className="btn-group">
                                <input id="fromtab" name="fromtab" type="hidden" value="" />
                                <input class="form-control datepicker" id="from-date" name="from-date" type="date"  autocomplete="off" />
                                <input class="form-control datepicker" id="to-date" name="to-date" type="date"  autocomplete="off" />
                                <button id="aCompanyState" class="btn btn-primary btnColor btn-radius" onClick={this.SaveBtn} autocomplete="off">
                                        <i class="la flaticon-diagram"></i>
                                        <span>
                                            Analyze
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="whiterow">
                        <div className="row px-3 pt-2">
                            <div className="col"> 
                                    <CallHistory ToDate={this.state.ToDate} FromDate={this.state.FromDate} /> 
                            </div> 
                        </div>   
                    </div>   
 

                    </div>
                </div>
  
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCallHistoryPage = connect(mapState, actionCreators)(CallHistoryPage);
export { connectedCallHistoryPage as CallHistoryPage };