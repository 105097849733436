import React, { useEffect } from 'react';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import { CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetClientDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function PotentialMeetingstable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [PotenationalMeetingExportData, SetPotenationalMeetingExportData] = React.useState([]);
  const [PotentialMeetingStatus, setPotentialMeetingStatus] = React.useState([]);
  const [CName, SetCName] = React.useState([]);


  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetUserIDby(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    PotentialMeetingGet(Details.ClientID, Details.Role);
    ExportPotenationalMeeting(Details.ClientID, Details.Role)

  }, [Search, Page, RowsPerPage, SortedBy]);

  const PotentialMeetingGet = (CID, Role) => {
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired: true
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiPotentialMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        SetRows(Res.data.PageData);
        SetRlen(Res.data.TotalCount);
        SetFlen(Res.data.TotalCount);
        SetCountPage(Res.data.PageCount);
        setPotentialMeetingStatus(Res.data.PotentialMeetingStatus)
      }

    });
  };

  //update potential status dropdown
  const PotentialStatusSelect = (event, id) => {
    var data = {
      _id: id,
      EngagamentStatusID: event.target.value,
      LastUpdatedBy: UserIDby,
      LastUpdatedDate: new Date(),
      Role:Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/UpdatePotentialMeetingdropdown",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        window.location.reload(true);
        toast.success(
          "Potential meeting select option Status update successfully.",
          "Update Potential select option  meeting Status"
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  const SortData = (Field) => {
    if (SortedBy == 1) {

       SetSortedBy(-1);
     } else {

       SetSortedBy(1);
     }
     SetSortField(Field);

 };

  const ExportPotenationalMeeting = (CID,Role) => {

    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired:false
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiPotentialMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
     if (Res.data.StatusMessage == "SUCCESS") {
      var ExportData=[]
      if(Res.data.PageData.length > 0)
      {
        for(var i=0;i < Res.data.PageData.length; i++)
        {
          var objCampaign=Res.data.PageData[i];
          var data={
            "Engagement Status":objCampaign.PMS.Status,
            "Company":objCampaign.Company,
            "Contact Name":objCampaign.FirstName +" "+objCampaign.LastName,
            "Email":objCampaign.Email,
            "Domain":objCampaign.Domain,
            "Title":objCampaign.Title,
            "LinkedIn Url":objCampaign.LinkedInUrl,
            "Meeting Source Name":objCampaign.MS.Name,
            "Owner Name":(objCampaign.MO != null && objCampaign.MO != "" ) ? objCampaign.MO.FirstName +" "+objCampaign.MO.LastName :"",
            "Reminder Email Account":objCampaign.A.length > 0 ? objCampaign.A[0].SMTPFromEmail:"",
            "Created Date":objCampaign.CreatedDate
          }
          ExportData.push(data);
        }
        SetPotenationalMeetingExportData(ExportData);
      }else{
        var data={
          "Engagement Status":"",
          "Company":"",
          "Contact Name":"",
          "Email":"",
          "Domain":"",
          "Title":"",
          "LinkedIn Url":"",
          "Meeting Source Name":"",
          "Owner Name":"",
          "Reminder Email Account":"",
          "Created Date":""
        }
        SetPotenationalMeetingExportData([data]);
      }

      }

    });
  };

  const ExportCSV=()=>{
    toast.success(<div className="toastsize">Kpi <br/>Data exported successfully.</div>);
  }

  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };
  return (
    <>
      <div className="row mt-n3">
        <div className="col border-bottom">
          <h4 className="headertitle float-left px-1">Potential Meetings</h4>

          <div className="listing-li float-right padb-15">
            <ul>
              <li>
              <CSVDownloader data={PotenationalMeetingExportData} filename={CName +"-Potential_Meeting"} bom={true}>
                  <a onClick={() => { ExportCSV(); }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-15">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">

          <label className="textlabelte">Search: <input type="search" id="search" onKeyPress={RequestSearch} class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Engagement Status</TableCell>
                <TableCell> <a onClick={() => {SortData("Company");}}>Company</a></TableCell>
                <TableCell><a onClick={() => {SortData("FirstName");}}>Contact Name</a></TableCell>
                <TableCell>Email</TableCell>
                <TableCell><a onClick={() => {SortData("Domain");}}>Domain</a></TableCell>
                <TableCell><a onClick={() => {SortData("Title");}}>Title</a></TableCell>
                <TableCell>LinkedIn Url</TableCell>
                <TableCell>Meeting Source Name</TableCell>
                <TableCell>Owner Name</TableCell>
                <TableCell>Reminder Email Account</TableCell>
                <TableCell>Created Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row._id}>

                      <TableCell className="labeltds" scope="row">
                        <select
                          onChange={(e) => {
                            PotentialStatusSelect(e, row.PotentialMeetingID);
                          }}
                          class="form-control  m-input"
                          id="Potentialstatus"
                          name="Potentialstatus"
                          autoComplete="off"
                          value={row.EngagamentStatusID}
                        >
                          {PotentialMeetingStatus.map((value) => (
                            <option value={value.PotentialMeetingStatusID}>
                              {value.Status}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>{row?.Company}</TableCell>
                      <TableCell> {row?.FirstName +" "+  row?.LastName }</TableCell>
                      <TableCell>{row?.Email}</TableCell>
                      <TableCell>{row?.Domain}</TableCell>
                      <TableCell>{row?.Title}</TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.MS.Name}</TableCell>
                      <TableCell>{row?.MO != null ? row?.MO.FirstName + + row?.MO.LastName : ""}</TableCell>
                      <TableCell>{row?.A.length > 0 ? row?.A[0].SMTPFromEmail : ""}</TableCell>
                      <TableCell>{row?.CreatedDate}</TableCell>
                    </TableRow>


                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
