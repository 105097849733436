import React, { useEffect } from "react";
const moment = require("moment");
import Swal from "sweetalert2";
import Axios from "axios";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);

  useEffect(() => {
    var ResearchPageUserInfo = GetUserDetails();
    if (ResearchPageUserInfo != null) {
      SetClientID(ResearchPageUserInfo.ClientID);
      SetUserID(ResearchPageUserInfo.ParentUserID);
      SetCUserID(ResearchPageUserInfo.ChildUserID);
      SetRole(ResearchPageUserInfo.Role);
    }
    GetResearchList(
      ResearchPageUserInfo.ClientID,
      ResearchPageUserInfo.ParentUserID,
      ResearchPageUserInfo.Role
    );
  }, [Search, Page, RowsPerPage,SortField,SortValue]);

  //get Direct mail list
  const GetResearchList = (CID, UID, Role) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/research/ResearchGet",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    });
  };

  //get sort field data
  const SortData = (Field) => {
    SetSortField(Field)
    SetSortValue(-SortValue)
  }

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //edit page
  const EditBtn = (ID) => {
    history.push("/editresearch", ID);
  };

  //delete Direct Mail
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a research.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/research/ResearchDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Research deleted successfully.",
                "success"
              );
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              GetResearchList(ClientID, UserID, Role);
              history.push("/research");
              props.updateFromChild(true);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ProjectStatus");
                    }}
                  >
                    Project Status
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ProjectName");
                    }}
                  >
                    Research Project Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompletedBy");
                    }}
                  >
                    Needs to be Completed By
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("RequestedDate");
                    }}
                  >
                    Date Requested
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("FinishedDate");
                    }}
                  >
                    Date Finished
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Hours");
                    }}
                  >
                    Hours
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CostPerHour");
                    }}
                  >
                    Cost per hour
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalCost");
                    }}
                  >
                    Total Cost
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ContactsFound");
                    }}
                  >
                    Contacts Found
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CostPerContact");
                    }}
                  >
                    Cost per Contact
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Notes");
                    }}
                  >
                    Notes
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ResearchTeam");
                    }}
                  >
                    Research Team
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.ProjectStatus}</TableCell>
                    <TableCell>{row.ProjectName}</TableCell>
                    <TableCell>
                      {row.CompletedBy != null
                        ? moment(
                            new Date(row.CompletedBy).toDateString()
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {moment(
                        new Date(row.RequestedDate).toDateString()
                      ).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      {row.FinishedDate != null
                        ? moment(
                            new Date(row.FinishedDate).toDateString()
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>{row.Hours}</TableCell>
                    <TableCell>{row.CostPerHour}</TableCell>
                    <TableCell>{row.TotalCost}</TableCell>
                    {row.ContactsFound != null ? (
                      <TableCell>{row.ContactsFound}</TableCell>
                    ) : (
                      <TableCell> </TableCell>
                    )}
                    <TableCell>
                      {Number(row.CostPerContact).toFixed(2)}
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {row.Notes}
                    </TableCell>
                    <TableCell>{row.ResearchTeam}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
     
        <div className="row pb-4">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
     
    </>
  );
}
