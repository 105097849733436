import React from "react";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import MeetingOwner from "../../../_components/user/MeetingOwner/MeetingOwner";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";

class MeetingOwnerPage extends React.Component {
  // add button meeting owner
  AddBtn() {
    history.push("/addmeetingowner");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left pl-0">Meeting Owner</h4>
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.AddBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols px-5">
              <div className="row">
                <div className="col px-1">
                  <MeetingOwner />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedMeetingOwnerPage = connect(
  mapState,
  actionCreators
)(MeetingOwnerPage);
export { connectedMeetingOwnerPage as MeetingOwnerPage };
