import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import EmailAccountsTabing from '../../_components/user/EmailAccounts/EmailAccountsTabing';




class EmailAccountsPage extends React.Component {
    render() {
        return (
            <>
                <div className="adminmain mheight">
                <Sidebar className=""/> 
                <div className="bodyhome px-0">
                    <EmailAccountsTabing /> 
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedEmailAccountsPage = connect(mapState, actionCreators)(EmailAccountsPage);
export { connectedEmailAccountsPage as EmailAccountsPage };