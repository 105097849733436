import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
 
import ReactApexchart  from "react-apexcharts";


const AccountState = {
          
    series: [{
      name: 'Sent',
      color: '#302e49',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 45, 65]
    }, {
      name: 'Responses',
      color: '#e9826e',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 40, 115, 110]
    }, {
      name: 'Meetings',
      color: '#ffb963',
      data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 110]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['04/01 - 04/04', '04/05 - 04/11', '04/12 - 04/18', '04/19 - 04/25', '04/26 - 05/02', '05/03', '05/10', '05/17', '05/24', '06/07', '06/14', '06/21'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  
  
  };

class ColdCallingReportingPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/>   
                <div className="bodyhome">

                <div className="row">
                    <div className="col">
                        <h4 className="headertitlepad float-left py-4">Cold Calling Reporting</h4>
                    </div>
                    <div className="col padright pt-3">
                        <div className="btn-group">
                            <input id="fromtab" name="fromtab" type="hidden" value="" />
                            <input className="form-control datepicker" id="from-date" name="from-date" type="date" value="07-01-2021" autocomplete="off" />
                            <input className="form-control datepicker" id="to-date" name="to-date" type="date" value="07-05-2021" autocomplete="off" />
                            <button id="aCompanyState" className="btn btn-primary btnColor m-btn btn-radius" autocomplete="off">
                                    <i className="la flaticon-diagram"></i>
                                    <span>
                                        Analyze
                                    </span>
                            </button>
                        </div>
                    </div>
                </div>


                <div className="whiterow mb-3 px-3"> 
                    
                   <div className="row pb-4 px-4 pt-4"> 
                         <div className="col col-lg-3 d-flex pr-4"> 
                            <label className="mt-2 mr-2 nowrap px-1"> Tag</label>
                            <select className="form-control  m-input" data-val="true" data-val-number="The field ColdCallersID must be a number." 
                            id="ColdCallersID" name="ColdCallersID"><option value="">--Select--</option>
                                <option value="30"> Chase Demelio</option>
                                <option value="36">Amit Vegad</option>
                                <option value="37">Ankita ankita</option>
                                <option value="41">bhargav Padia</option>
                                <option value="34">chintan padhya</option>
                                <option value="14">coldcaller@test.com test dev</option>
                                <option value="15">coldTest 123</option>
                                <option value="47">Dev _8738Padhya</option>
                                <option value="10">ds </option>
                                <option value="32">h m</option> 
                            </select> 
                        </div>
                        <div className="col col-lg-4 d-flex"> 
                            <label className="mt-2 mr-2 nowrap"> Sales Development Reps</label>
                            <select className="form-control  m-input" data-val="true" data-val-number="The field ColdCallersID must be a number." 
                            id="ColdCallersID" name="ColdCallersID"><option value="">--Select--</option>
                                <option value="30"> Chase Demelio</option>
                                <option value="36">Amit Vegad</option>
                                <option value="37">Ankita ankita</option>
                                <option value="41">bhargav Padia</option>
                                <option value="34">chintan padhya</option>
                                <option value="14">coldcaller@test.com test dev</option>
                                <option value="15">coldTest 123</option>
                                <option value="47">Dev _8738Padhya</option>
                                <option value="10">ds </option>
                                <option value="32">h m</option> 
                            </select> 
                        </div>
                        <div className="col col-lg-5">
                            <a href="javascript:void(0);" className="btn btn-primary btnColor"><i className="la la-search"></i>Search</a>
                            <a href="javascript:void(0);" className="ml-3 btn btn-secondary"><i className="la la-refresh"></i> Reset</a>
                        </div>
                    </div>      
                        

                    <div className="row px-3 pt-5 pb-3 border-bottom"> 
                      <div className="w-100 whiterow px-3">
                        <div className="row">
                            <div className="col">
                                <div className="bg-light-sky boxcardcounter">
                                    <div className="line-title">
                                        <span className="clr-sky box-main-title">0%</span>
                                    </div>
                                    <p className="clr-sky">Connection %</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="bg-light-sky boxcardcounter">
                                    <div className="line-title">
                                        <span className="clr-sky box-main-title">3%</span>
                                    </div>
                                    <p className="clr-sky">Conversion %</p>
                                </div>
                            </div>
                              <div className="col">
                                  <div className="bg-light-sky boxcardcounter">
                                      <div className="line-title">
                                          <span className="clr-sky box-main-title">2</span>
                                      </div>
                                      <p className="clr-sky">Total Meeting </p>
                                  </div>
                              </div>  
                              <div className="col">
                                  <div className="bg-light-sky boxcardcounter">
                                      <div className="line-title">
                                          <span className="clr-sky box-main-title">2</span>
                                      </div>
                                      <p className="clr-sky">Total Dial</p>
                                  </div>
                              </div>  
                          </div>
                        </div> 
                      </div>

                    {/* <div className="row mt-4">
                        <div className="col-sm-12 col-md-6">
                            <label className="textlabelte">Show 
                            <select name="tbl_meeting_length" aria-controls="tbl_meeting" className="form-control form-control-sm">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select> 
                            entries</label> 
                        </div>
                        <div className="col-sm-12 col-md-6 full-right">
                            <label className="textlabelte">Search: <input type="search" className="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                        </div>
                    </div>                   

                    <div className="row">
                        <div className="col">
                            <div className="table-bordered">
                                <CallHistory />
                            </div>
                        </div>
                    </div>   

                    <div className="row">
                        <div className="col dataTables_info">
                        <p>Showing 1 to 71 of 71 entries</p>
                        </div>
                    </div> */}
 
                    <div className="row padtop-50">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">
                            <div id="chart chart-max450">
                            <ReactApexchart options={AccountState.options} series={AccountState.series} type="bar" height={450} />
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                    </div>



                    </div>
                </div>

                    
                <Footer />
                     

                </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedColdCallingReportingPage = connect(mapState, actionCreators)(ColdCallingReportingPage);
export { connectedColdCallingReportingPage as ColdCallingReportingPage };