import React, { useEffect, useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ChatGptimage from "../../../images/chatgpt.svg";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";
//import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

export default function HiveAITable() {
  const [copySuccess, setCopySuccess] = useState(``);
  const [errors, setErrors] = useState({});
  const [Fields, setFields] = useState({});
  const [IsLoading, SetIsLoading] = React.useState(false);

  useEffect(() => {
    document.title = `Hive AI | SalesHive`;
  }, []);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(copySuccess);
    toast.success(
      <div className="toastsize">
        ChatGpt
        <br />
        ChatGpt Response copied.
      </div>
    );
  };

  const ReloadToRefresh = (e) => {
    e.preventDefault();
    SetIsLoading(true);
    setCopySuccess(``);
    document.getElementById("Command").value = ""; // Set the value property to an empty string
    document.getElementById("tone").value = ""; // Set the value property to an empty string
    document.getElementById("emailsummary").value = ""; // Set the value property to an empty string
    SetIsLoading(false);
  };

  const fromValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var commandValue = document.getElementById("Command").value.trim();
    var isExists = await commandNameCheckExist(commandValue);
    if (isExists === "BlankData" && commandValue === "") {
      formIsValid = false;
      errors["Command"] = "Please enter command";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleChangeBlur = async () => {
    let updatedErrors = { ...errors }; // Create a copy of the errors object
    var commandValue = document.getElementById("Command").value.trim();
    var isExists = await commandNameCheckExist(commandValue);
    if (isExists === "BlankData" && commandValue === "") {
      updatedErrors["Command"] = "Please enter command";
    } else {
      updatedErrors["Command"] = null; // Clear the error if validation passes
    }

    setErrors(updatedErrors); // Update the errors state
  };

  // Check exit name
  const commandNameCheckExist = async (Country) => {
    if (Country !== "") {
      return "AllDone";
    } else {
      return "BlankData";
    }
  };

  const saveBtnChatGPT = async (e) => {
    e.preventDefault();
    var commandValue = document.getElementById("Command").value.trim();
    var voiceOfTone = document.getElementById("tone").value || ""; // Default to empty string if not provided
    var emailSummary = document.getElementById("emailsummary").value || ""; // Default to empty string if not provided

    // Create the modified prompt with the selected elements
    var modifiedPrompt = `Command: ${commandValue}\n`;

    if (voiceOfTone !== "") {
      modifiedPrompt += `Tone of Voice: ${voiceOfTone}\n`;
    }

    // if (emailSummary !== "") {
    //   modifiedPrompt += `Use the following information to formulate the response,\n${emailSummary}\n`;
    // }

    if (emailSummary !== "") {
      modifiedPrompt += `More information,\n${emailSummary}\n`;
    }

    // Other code
    var finalFlag = await fromValidation();
    if (finalFlag === true) {
      SetIsLoading(true);
      var inputParameter = {
        prompt: modifiedPrompt, // Use the modified prompt
      };
      const apiChatListList = Axios({
        url: CommonConstants.MOL_APIURL + "/hiveai/GetChatGPTMessageResponse",
        method: "POST",
        data: inputParameter,
      });
      apiChatListList.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          setCopySuccess(Result.data.data);
          toast.success(
            <div className="toastsize">
              ChatGpt
              <br />
              ChatGpt Response successfully.
            </div>
          );
          SetIsLoading(false);
        } else {
          toast.error(
            <div className="toastsize">
              ChatGpt not responding. Please try after some time.
            </div>
          );
          SetIsLoading(false);
        }
      });
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="">
        <div className="row">
          <div className="col">
            <h4 className="headertitlebd pl-1">Hive AI</h4>
          </div>
          <div className="col padchatgptright">
            <a title="ChatGPT"
              onClick={saveBtnChatGPT}
              className="btn btn-primary btn-lightgreen mr-1"
            >
              <img className="gtpchat-icon" src={ChatGptimage} />
            </a>
            <button onClick={ReloadToRefresh} title="Refresh" className="btn btn-secondary">
              <i className="la la-refresh"></i>
            </button>
          </div>
        </div>
      </div>
      <span className="devider"></span>
      <div className="bg-white py-4 pl-3">
        <Row>
          <Col>
            <div className="col-lg-12 row mb-3">
              <label className="col-lg-4 view-lable-meet">Tone of Voice</label>
              <div className="col-lg-12">
                <input
                  className="form-control m-input"
                  placeholder="Enter Tone of Voice"
                  type="text"
                  id="tone"
                />
              </div>
            </div>

            <div className="col-lg-12 row mb-3">
              <label className="col-lg-4 view-lable-meet">Command</label>
              <div className="col-lg-12">
                <textarea
                  className="form-control m-textarea h-100"
                  placeholder="Enter Command"
                  type="text"
                  id="Command"
                  onBlur={handleChangeBlur}
                />
                <span style={{ color: "red" }}>{errors["Command"]}</span>
              </div>
            </div>

            <div className="col-lg-12 row mb-3">
              <label className="col-lg-12 view-lable-meet">
                Use the following information to formulate the response
              </label>
              <div className="col-lg-12">
                <textarea
                  className="form-control m-textarea h-200"
                  placeholder="Enter More Information"
                  type="text"
                  id="emailsummary"
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="col-lg-12 row mb-3 h-90">
              <label className="col-lg-12 view-lable-meet d-flex align-items-center">
                Response:
                <div>
                  <button className="btn-copy" onClick={copyToClipboard}>
                    <FileCopyIcon />{" "}
                  </button>
                </div>
              </label>
              <div className="col-lg-12 hpxminu-37 h-100">
                <form className="h-100 pb-2">
                  <textarea
                    placeholder="Response"
                    className="form-control m-textarea h-100"
                    value={copySuccess}
                  />
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
