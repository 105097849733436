import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";

import { history } from "../../_helpers";
import { userActions } from "../../_actions";
import { CommonConstants } from "../../_constants/common.constants";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import Directmail from "../../_components/user/ActionItems/DirectmailTable";
import { GetUserDetails } from "../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class DirectMailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ExportData: [],
      ClientName:""
    };

    this.ExportCsv = this.ExportCsv.bind(this);
    this.AddPage = this.AddPage.bind(this);
  }

  componentDidMount() {
    document.title="Direct Mail | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
        this.setState({ ClientID: Details.ClientID });
        this.setState({ UserID: Details.ChildUserID });
    }
    this.ExportMethod(Details.ParentUserID, Details.ClientID)

  }

//Export
  ExportMethod(UID, CID) {
    const InputParameters = {
        UserID: UID,
        ClientID: CID,
    }
    Axios({
        url: CommonConstants.MOL_APIURL + "/directmail/DirectMailExport",
        method: "POST",
        data: InputParameters
    }).then((Res) => {
        this.setState({ ExportData: Res.data.Data });
        this.setState({ ClientName: Res.data.ClientName });

    });
}

ExportCsv() {
  toast.success(
    <div>Data exported successfully..</div>
  )
 }

    //Reload page while delete perform
    UpdateFromChild = (value) => {
      if(value == true){
        this.componentDidMount()
      }
    }

//Add page
AddPage() {
  history.push("/adddirectmail");
}

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />  
          <div className="bodyhome"> 
          <div className="row">
            <div className="col">
              <h4 className="headertitle float-left px-0">Direct Mail</h4>
            </div>  
              <div className="col">
              <div className="align-items-center d-flex justify-content-end main">
                <div className="pr-15 listing-li float-right padb-15 pt-1">
                  <ul>
                    <li className="px-0">
                    <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            filename={`${this.state.ClientName}-DirectMail`}
                            bom={true}
                          >
                      <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                 
                            Export
                         
                        </span>
                      </a>
                      </CSVDownloader>
                    </li>
                    <li className="px-1">
                      <a
                        onClick={() => this.AddPage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            </div>

            <div class="whiterow px-4 pt-4">
              <div className="row">
                <div className="col px-4">
                  <Directmail updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedDirectMailPage = connect(
  mapState,
  actionCreators
)(DirectMailPage);
export { connectedDirectMailPage as DirectMailPage };
