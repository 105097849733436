import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ContactBulkImportRequestGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role
    );
  }, [Search, Page, RowsPerPage]);

  // Hard Bounce list
  const ContactBulkImportRequestGet = (CID, UID, URole) => {
    SetIsLoading(true);
    var InpurtData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contact_bulk_import_request/ContactBulkImportRequestGet",
      method: "POST",
      data: InpurtData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);
    var SearchedVal = document.getElementById("search").value;

    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contact_bulk_import_request/ContactBulkImportRequestGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };

      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contact_bulk_import_request/ContactBulkImportRequestGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };
  return (
    <>
      {IsLoading === true ? (
        <div
          id="hideloding"
          className="loding-display"
          style={{ display: "none" }}
        >
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div>
        <div className="row">
          <div className="col padd-0"></div>
        </div>
        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("ClientName");
                      }}
                    >
                      ClientName
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Email");
                      }}
                    >
                      Email
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("OrignalFileName");
                      }}
                    >
                      Csv Name
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("InProgress");
                      }}
                    >
                      In Progress
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("IsCompleted");
                      }}
                    >
                      Compeleted
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("CreatedDate");
                      }}
                    >
                      Created Date
                    </a>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.length == 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows.map((row) => (
                    <TableRow key={row.name} row={row}>
                      <TableCell>{row.ClientName}</TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>{row.OrignalFileName}</TableCell>
                      <TableCell>{row.InProgress ? "Yes" : "No"}</TableCell>
                      <TableCell>{row.IsCompleted ? "Yes" : "No"}</TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
          <div class="row">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
