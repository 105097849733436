import React, { useState, useEffect } from 'react';
import Axios from "axios";
import Popup from "reactjs-popup";
import Select from "react-select";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon23 from "../../../images/loading.gif";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



export default function AccountAllTable({ contactFlag, CCampaignID,onData }) {
  const [Flen, SetFlen] = useState(0);
  const [Sflag, SetSflag] = useState(false);
  const [Rlen, SetRlen] = useState(0);
  const [CountPage, SetCountPage] = useState(0);
  const [Rows, SetRows] = useState([]);
  const [Page, SetPage] = useState(1);
  const [RowsPerPage, SetRowsPerPage] = useState(15);
  const [Search, SetSearch] = useState("");
  const [SortField, SetSortField] = useState("Name");
  const [SortValue, SetSortValue] = useState(1);
  const [ClientID, SetClientID] = useState(0);
  const [UserID, SetUserID] = useState(0);
  const [Role, SetRole] = useState("");
  const [EditPopup,SetEditPopup] = useState(false);
  const [ContactSourceData,SetContactSourceData] = useState([]);
  const [ClientPOCData,SetClientPOCData] = useState([]);
  const [ContactOwnerData,SetContactOwnerData] = useState([]);
  const [ReasonUnqualifiedData,SetReasonUnqualifiedData] = useState([]);
  const [ContactTagData,SetContactTagData] = useState([]);
  const [ContactSalesDevlopMenData,SetContactSalesDevlopMenData] = useState([]);
  const [ContactCustomFieldData,SetContactCustomFieldData] = useState([]);
  const [ContactStatusData,SetContactStatusData] = useState([]);
  const [ContactSourceDefaultSelect,SetContactSourceDefaultSelect] = useState(null);
  const [ContactTagDefaultSelect,SetContactTagDefaultSelect] = useState([]);
  const [ClientPOCDefaultSelect,SetClientPOCDefaultSelect] = useState(null);
  const [ContactOwnerDefaultSelect,SetContactOwnerDefaultSelect] = useState(null);
  const [ReasonDefaultSelect,SetReasonDefaultSelect] = useState(null);
  const [IsDoNotCallContactData,SetIsDoNotCallContactData] = useState(null);
  const [contactoptions,Setcontactoptions] = useState([]);
  const [defaultTagArray,SetdefaultTagArray] = useState([]);
  const [TagArray,SetTagArray] = useState([]);
  const [EditContactTagArray,SetEditContactTagArray] = useState([]);
  const [TagNameArray,SetTagNameArray] = useState([]);
  const [TagIDArray,SetTagIDArray] = useState([]);
  const [errors,Seterrors] = useState({});
  const [Fields,SetFields] = useState({});
  const [Errors,SetErrors] = useState({});
  const [IsInValidWebsite,SetIsInValidWebsite] = useState(false);
  const [IsValidEmail,SetIsValidEmail] = useState(false);
  const [ProspectID,SetProspectID] = useState("")
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    
    //document.getElementById("hideloding23").style.display = "none";
    console.log(CCampaignID,"fdg")
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetCampaignContact(Details.ClientID, Details.ParentUserID, Details.Role);
    ContactGetData(Details.ClientID, Details.ParentUserID)
  }, [Search, Page, RowsPerPage, CCampaignID, contactFlag]);

  const GetCampaignContact = async (CID, UID, URole) => {
    
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CCampaignID
    };
    const GetConfigEmailAccount = await Axios({
      url: CommonConstants.MOL_APIURL + "/prospect_campaign/GetProspectCampaign",
      method: "POST",
      data: InputParameter,
    });
    
    console.log(GetConfigEmailAccount)
    if (GetConfigEmailAccount.data.StatusMessage == "SUCCESS") {
      
      SetRows(GetConfigEmailAccount.data.PageData);
      SetRlen(GetConfigEmailAccount.data.TotalCount);
      SetFlen(GetConfigEmailAccount.data.TotalCount);
      SetCountPage(GetConfigEmailAccount.data.PageCount);
      //document.getElementById("hideloding23").style.display = "none";
    }else{
      //document.getElementById("hideloding23").style.display = "none";
    }
  }

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);

  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        //document.getElementById("hideloding23").style.display = "block";
      }
    }
  };



  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
       //document.getElementById("hideloding23").style.display = "block";
    }
   
  };

      // edit popup
      const EditBtn = (id) => {
        console.log("id", id)
        SetEditPopup(true)
        SetProspectID(id)
        var InputParaMeter = {
          ProspectID:id
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/prospect_campaign/ProspectCampaignGetByID",
          method: "POST",
          data: InputParaMeter,
        }).then((result) => {
          console.log(result.data.Data)
          let defualtoptionsvalue = []
          result.data.Data[0]?.Tagname.map((val) => {
           if(val.TagName == "" || val.ContactTagID == ""){
             return (0)
           }else{
             defualtoptionsvalue.push({value :val.TagName, label: val.TagName, cid:val.ContactTagID})
           }
          
         })
         console.log("defualtoptionsvalue123", defualtoptionsvalue)
        //  this.setState({
        //    defaultTagArray:defualtoptionsvalue
        //  })
         SetdefaultTagArray(defualtoptionsvalue)
  
  
        //  document.getElementById("hideloding").style.display = "none";
  
        //  this.setState({
        //    TagArray: result.data.Data[0]?.Tagname,
        //  });
         SetTagArray(result.data.Data[0]?.Tagname)
          document.getElementById("FirstName").value = result.data.Data[0].FirstName;
          document.getElementById("LastName").value =result.data.Data[0].LastName;
          document.getElementById("Email").value = result.data.Data[0]?.Email;
          document.getElementById("Title").value = result.data.Data[0]?.Title;
          document.getElementById("CompanyNameLong").value =
            result.data.Data[0]?.CompanyNameLong;
          document.getElementById("Company").value = result.data.Data[0]?.Company;
          document.getElementById("Website").value = result.data.Data[0]?.Website;
          document.getElementById("LinkedInURL").value =
            result.data.Data[0]?.LinkedInURL;
          document.getElementById("Industry").value =
            result.data.Data[0]?.Industry;
          document.getElementById("Address1").value =
            result.data.Data[0]?.Address1;
          document.getElementById("Address2").value =
            result.data.Data[0]?.Address2;
          document.getElementById("City").value = result.data.Data[0]?.City;
          document.getElementById("State").value = result.data.Data[0]?.State;
          document.getElementById("Zip").value = result.data.Data[0]?.Zip;
          document.getElementById("Country").value = result.data.Data[0]?.Country;
          document.getElementById("ContactCategory").value =
            result.data.Data[0]?.ContactCategory;
          document.getElementById("AccountCategory").value =
            result.data.Data[0]?.AccountCategory;
          document.getElementById("MobilePhone").value =
            result.data.Data[0]?.MobilePhone;
          document.getElementById("DirectPhone").value =
            result.data.Data[0]?.DirectPhone;
          document.getElementById("DirectPhoneExt").value =
            result.data.Data[0]?.DirectPhoneExt;
          document.getElementById("CompanyPhone").value =
            result.data.Data[0]?.CompanyPhone;
            document.getElementById("OtherPhone1").value =
            result.data.Data[0]?.OtherPhone1;
          document.getElementById("OtherPhone2").value =
            result.data.Data[0]?.OtherPhone2;
          document.getElementById("ExternalAccountID").value =
            result.data.Data[0]?.ExternalAccountID;
          document.getElementById("ExternalContactID").value =
            result.data.Data[0]?.ExternalContactID;
          document.getElementById("ExternalField1").value =
            result.data.Data[0]?.ExternalField1;
          document.getElementById("ExternalField2").value =
            result.data.Data[0]?.ExternalField2;
          document.getElementById("ExternalField3").value =
            result.data.Data[0]?.ExternalField3;
          document.getElementById("IsDoNotCallContact").value =
            result.data.Data[0]?.IsDoNotCallContact;
        });
      };

//get sort field data
const SortData = (Field) => {
  //document.getElementById("hideloding23").style.display = "block";
  var SearchedVal = document.getElementById("search").value;
  // SetPage(1);
  // SetRowsPerPage(10);
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      CampaignID: CCampaignID,
      Search:SearchedVal
    };
    
    const CampaignContactList = Axios({
      url:CommonConstants.MOL_APIURL +"/prospect_campaign/GetProspectCampaign",
      method: "POST",
      data: InputParameter,
    });
    CampaignContactList.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
  //document.getElementById("hideloding23").style.display = "none";

    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      CampaignID: CCampaignID,
      Search:SearchedVal
    };
    
    const CampaignContactList = Axios({
      url:CommonConstants.MOL_APIURL +"/prospect_campaign/GetProspectCampaign",
      method: "POST",
      data: InputParameter,
    });
    CampaignContactList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
  //.getElementById("hideloding23").style.display = "none";

    });
  }
};

  // Contact info update data
  const ContactGetData = (CID,UID) => {
    var str_in = {
      ClientID: CID,
      UserID:UID
    };
    console.log(str_in,"data")
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
              // // multiple contact sele
        let contactoptions = result.data?.ContactTagData.map((val) => {
          return { value: val.Tag, label: val.Tag, cid: val.ContactTagID };
        });

        Setcontactoptions(contactoptions)
        SetContactSourceData(result.data?.ContactSourceData)
        SetClientPOCData(result.data?.ClientPOCData)
        SetReasonUnqualifiedData(result.data?.ReasonUnqualifiedData)
        SetContactTagData(result.data?.ContactTagData)
        SetContactOwnerData(result.data?.ContactOwnerData)
        SetContactSalesDevlopMenData(result.data?.ContactSalesDevlopMenData)
        SetContactCustomFieldData(result.data?.ContactCustomFieldData)
        SetContactStatusData(result.data?.ProspectStatusData)
    });
  }

      // handle dropdown change contact source
      const HandleDropdownChangeContactSource =(e)=> {
        SetContactSourceDefaultSelect(e.target.ContactSourceDefaultSelect)
  
      }
    
   
      // handle dropdown change contact owner
      const  HandleDropdownChangeContactOwner =(e) => {
        SetContactOwnerDefaultSelect(e.target.ContactOwnerDefaultSelect)
  
      }
      // handle dropdown change client
      const  HandleDropdownChangeClientPOC =(e) =>{
        SetClientPOCDefaultSelect(e.target.ClientPOCDefaultSelect)
      }
      // handle dropdown change contac reason
      const HandleDropdownChangeReasonUnqualified =(e) =>{
        SetReasonDefaultSelect(e.target.ReasonDefaultSelect)
      }
      // checkbox
      const HandleChangeCheckIsDoNotCall = (e) => {
        const { checked } = e.target;
        SetIsDoNotCallContactData(checked)
      };
      // handle change
    const  h2Change = (e) => {
        SetdefaultTagArray(e)
      };


      
     const FromValidation = async() => {
      let formIsValid = true;
      // let fields = this.state.fields;
      let errors = {};
  
      var FirstName = document.getElementById("FirstName").value.trim();
      var Email = document.getElementById("Email").value.trim();
      // var Company = document.getElementById("Company").value.trim();
      var Website = document.getElementById("Website").value.trim();
      let IsExistWebsite = await CheckWebSite(Website);
      let IsExistEmail = await EmailValidationCheck(Email);
  
  
      if (FirstName == "") {
        formIsValid = false;
        toast.error("Please enter first name")
      }
      
      if (Website == "" && FirstName != "") {
        formIsValid = false;
        toast.error("Please enter Website")
      }
      if (Email == "" && Website.length < 0 && FirstName != "") {
        formIsValid = false;
        toast.error("Please enter email")
      }
      if (IsExistWebsite == true) {
        formIsValid = false;
        errors["Website"] = "Invalid website!";
        toast.error("Please enter First name, Last name and Company OR First name and Email")
      }
      if (IsExistEmail == true) {
        formIsValid = false;

        toast.error("Invalid email!")
      }
  
      Seterrors(errors)
      // this.setState({ errors: errors });
      return formIsValid;
    }

  const  handleChangeWebsitecheck =()=> {
      var Website = document.getElementById("Website").value.trim();
      if (Website != "") {
        let errors = {};
        errors.Website = null;
        CheckWebSite(Website)
        errors["Website"] = "Invalid website!";
      } else {

        errors.Website = null;
      }
    }

        // check website name
       const  CheckWebSite = async(Email) =>  {
   
          
          let Errors = {};
          var regex = new RegExp(
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
              "((\\d{1,3}\\.){3}\\d{1,3}))" +
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
              "(\\?[;&a-z\\d%_.~+=-]*)?" +
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); 
       
            if (!regex.test(Email) && Email!="") {
     
              Errors["Website"] = "Invalid website";
              Seterrors(Errors)
              SetIsInValidWebsite(true)
  
              return true
            } else {
     
              return false;
            }
        }
      // update data
 const ContactUpdate = async (e) => {
  e.preventDefault();
  e.currentTarget.disabled = true;
  var FormValidationFlag = await FromValidation();
  if(FormValidationFlag === true){
    var FinalTagArray = []
    defaultTagArray.map((item) => {
      FinalTagArray.push({
        ContactTagID:item.cid,
        TagName:item.label
      })
    })
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Email = document.getElementById("Email").value;
    var Title = document.getElementById("Title").value;
    var CompanyNameLong = document.getElementById("CompanyNameLong").value;
    var Company = document.getElementById("Company").value;
    var Website = document.getElementById("Website").value;
    var LinkedInURL = document.getElementById("LinkedInURL").value;
    var Industry = document.getElementById("Industry").value;
    var Address1 = document.getElementById("Address1").value;
    var Address2 = document.getElementById("Address2").value;
    var City = document.getElementById("City").value;
    var State = document.getElementById("State").value;
    var Zip = document.getElementById("Zip").value;
    var Country = document.getElementById("Country").value;
    var ContactCategory = document.getElementById("ContactCategory").value;
    var AccountCategory = document.getElementById("AccountCategory").value;
    var MobilePhone = document.getElementById("MobilePhone").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var CompanyRevenue = document.getElementById("CompanyRevenue").value;
    // var FollowUpDate = document.getElementById("FollowUpDate").value;
    var SalesRep = document.getElementById("SalesRep").value;
    var OtherPhone1 = document.getElementById("OtherPhone1").value;
    var OtherPhone2 = document.getElementById("OtherPhone2").value;
    var ExternalAccountID = document.getElementById("ExternalAccountID").value;
    var ExternalContactID = document.getElementById("ExternalContactID").value;
    var ExternalField1 = document.getElementById("ExternalField1").value;
    var ExternalField2 = document.getElementById("ExternalField2").value;
    var ExternalField3 = document.getElementById("ExternalField3").value;
    var ContactSourceID = document.getElementById("ContactSourceID").value;
    var ReasonUnqualifiedID = document.getElementById(
      "ReasonUnqualifiedID"
    ).value;
    var ClientPOCID = document.getElementById("ClientPOCID").value;
    var ContactOwnerID = document.getElementById("ContactOwnerID").value;
    var IsDoNotCallContact =
      document.getElementById("IsDoNotCallContact").checked;

      var ContactUpdateData = {
        _id: ProspectID,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Title: Title,
        CompanyNameLong: CompanyNameLong,
        Company: Company,
        Website: Website,
        LinkedInURL: LinkedInURL,
        Industry: Industry,
        Address1: Address1,
        Address2: Address2,
        City: City,
        State: State,
        Zip: Zip,
        Country: Country,
        ContactCategory: ContactCategory,
        AccountCategory: AccountCategory,
        MobilePhone: MobilePhone,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        CompanyRevenue: CompanyRevenue,
        SalesRep: SalesRep,
        OtherPhone1: OtherPhone1,
        OtherPhone2: OtherPhone2,
        ExternalAccountID: ExternalAccountID,
        ExternalContactID: ExternalContactID,
        ExternalField1: ExternalField1,
        ExternalField2: ExternalField2,
        ExternalField3: ExternalField3,
        ContactSourceID: ContactSourceID,
        ReasonUnqualifiedID: ReasonUnqualifiedID,
        ClientPOCID: ClientPOCID,
        ContactOwnerID: ContactOwnerID,
        Tagname:FinalTagArray,
        IsDoNotCallContact: IsDoNotCallContact,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        ClientID: ClientID
      };
      console.log("ContactUpdateData1234",ContactUpdateData)
      Axios({
        url: CommonConstants.MOL_APIURL + "/prospect_campaign/ProspectCampaignUpdate",
        method: "POST",
        data: ContactUpdateData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Contact update successfully.", "Update  contact ");
          SetEditPopup(false)
            // history.push({
            //   pathname: "/editcampaigns",
            // });
        } else {
          toast.error(res.data.Message);
        }
      });
  }
  else{
    document.getElementById("submit").disabled = false;
  }


}

  // Handle Change
const  HandleChange =() =>{
  var Website = document.getElementById("Website").value.trim();
    if (Website != "") {
      Errors.Website = null;
    } else {
      Errors.Website = null;
    }
  }

    // cheack email valid
    const EmailValidationCheck =async (Email) =>{
      let regexp =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let Errors = {};
      if (!regexp.test(Email) && Email != "") {
        Errors["Email"] = "invalid email.";
        SetIsValidEmail(true)
        Seterrors(Errors)
        // this.setState({ IsValidEmail: true });
        // this.setState({errors:Errors});
        return true;
      } else {
        // this.setState({ IsValidEmail: false });
        SetIsValidEmail(false)

        return false;
      }
    };

        // when check box update data
        const CheckBoxClick = (e, row) => {
          // ShowAndHideDomain();
          if (e.target.checked) {
            var temArray = DeleteArray;
            temArray.push(row);
            SetDeleteArray(temArray)
            onData(DeleteArray)
          } else if (!e.target.checked) {
            var temArray = DeleteArray;
            DeleteArray.splice(row, 1)
            // SetDeleteArray(temArray)
            onData(DeleteArray)
          }
    
        };

              // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val.ProspectCampaignID).checked = true;
          var temArray = DeleteArray;
          temArray.push(val.ProspectCampaignID);
          SetDeleteArray(temArray);
          onData(DeleteArray)
        } else if (!e.target.checked) {
          document.getElementById(val.ProspectCampaignID).checked = false;
          DeleteArray.splice(val.ProspectCampaignID, 1);
          onData(DeleteArray)
        }
      });
    }
  };

  return (
    <div>
          {/* <div id="hideloding23" className="loding-display">
        <img src={loadingicon23} />
      </div> */}
          <Popup open={EditPopup}>
              
              <div>
                <div className="modal-black"></div>
                <div className="filterPopup largerPopup">
                  <div className="paddingboxTerms">
                    <div className="modal-header py-4 px-3">
                      <div className="w-100 d-flex px-3">
                        <h5 className="mb-0">Edit Contacts</h5>
                        <button className="close" onClick={() => SetEditPopup(false)}>
                          <span
                            aria-hidden="true"
                            className="la la-remove"
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="modal-body">
                      <div className='row'>
                        <div className='col-lg-12'>
                        <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">First Name</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" data-val="true" data-val-required="Please enter first name" id="FirstName" name="FirstName" placeholder="First name" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="FirstName" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Last Name</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="LastName" name="LastName" placeholder="Last name" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="LastName" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        </div>
                        <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Email</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Email" name="Email" placeholder="Email" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Title</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Title" name="Title" placeholder="Title" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Title" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Company</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Company" name="Company" placeholder="Company" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Company" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Company Name Long</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="CompanyNameLong" name="CompanyNameLong" placeholder="Company name long" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="CompanyNameLong" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Website</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input"
                                   value={Fields["Website"]}
                        onChange={HandleChange.bind(this)}
                         data-val="true" data-val-regex="Invalid website" data-val-regex-pattern="^(?!www|https|http	)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&amp;=\?_:;-]+$" data-val-required="Please enter website." id="Website" name="Website" placeholder="Website" type="text" />
                                <span style={{ color: "red" }}>
                        {errors["Website"]}
                      </span>
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Website" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Company Phone</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid company phone" data-val-regex-pattern="^[0-9]*$" id="CompanyPhone" name="CompanyPhone" placeholder="Company Phone" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="CompanyPhone" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Direct Phone</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid direct phone" data-val-regex-pattern="^[0-9]*$" id="DirectPhone" name="DirectPhone" placeholder="Direct Phone" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="DirectPhone" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Direct Phone Ext</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid direct phone ext" data-val-regex-pattern="^[0-9]*$" id="DirectPhoneExt" name="DirectPhoneExt" placeholder="Direct Phone Ext" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="DirectPhoneExt" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">LinkedIn URL</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="LinkedInURL" name="LinkedInURL" placeholder="Linkedin url" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="LinkedInURL" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Mobile Phone</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid mobile phone" data-val-regex-pattern="^[0-9]*$" id="MobilePhone" name="MobilePhone" placeholder="Mobile Phone" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="MobilePhone" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Industry</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Industry" name="Industry" placeholder="Industry" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Industry" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Address 1</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Address1" name="Address1" placeholder="Address 1" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Address1" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Address 2</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Address2" name="Address2" placeholder="Address 2" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Address2" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">City</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="City" name="City" placeholder="City" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="City" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">State</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="State" name="State" placeholder="State" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="State" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Zip</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Zip" name="Zip" placeholder="Zip" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Zip" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Country</label>
                            <div class="col-lg-7">
                                
                                <input class="form-control m-input" data-val="true" data-val-remote="Country Is Global Country Blacklisted." data-val-remote-additionalfields="*.Country,*.UserID,*.CampaignID,*.PageName" data-val-remote-url="/Utility/IsProspectCountryExistsAndBlacklist" id="Country" name="Country" placeholder="Country" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Country" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Contact Category</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ContactCategory" name="ContactCategory" placeholder="Contact category" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="ContactCategory" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Account Category</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="AccountCategory" name="AccountCategory" placeholder="Account category" type="text" />
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="AccountCategory" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Contact Source</label>
                            <div class="col-lg-7">
                                <select class="form-control  m-input" data-val="true" data-val-number="The field ContactSourceID must be a number."      value={ContactSourceDefaultSelect}
                        onChange={(e) =>
                          HandleDropdownChangeContactSource(e)
                        } id="ContactSourceID" name="ContactSourceID">    
                                 {ContactSourceData.map((value) => (
                          <option value={value.ContactSourceID}>
                            {value.Name}
                          </option>
                        ))}
</select>
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="ContactSourceID" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Contact Owner</label>
                            <div class="col-lg-7">
                                <select class="form-control  m-input" data-val="true"    value={ContactOwnerDefaultSelect}
                        onChange={(e) =>
                          
                          HandleDropdownChangeContactOwner(e)
                        } data-val-number="The field ContactOwnerID must be a number." id="ContactOwnerID" name="ContactOwnerID">
                                 {ContactOwnerData.map((value) => (
                          <option value={value.MeetingOwnerID}>
                            {value.Name}
                          </option>
                        ))}

</select>
                                <span class="field-validation-valid errorMessageValidate" data-valmsg-for="ContactOwnerID" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Client POC</label>
                            <div class="col-lg-7">
                                <select class="form-control  m-input" data-val="true" data-val-number="The field ClientPOCID must be a number." id="ClientPOCID" name="ClientPOCID"  value={ClientPOCDefaultSelect}
                        onChange={(e) =>HandleDropdownChangeClientPOC(e)}>
                                {ClientPOCData.map((value) => (
                          <option value={value.ClientPOCID}>
                            {value.Name}
                          </option>
                        ))}

</select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Reason Unqualified</label>
                            <div class="col-lg-7">
                                <select class="form-control  m-input" data-val="true" data-val-number="The field ReasonUnqualifiedID must be a number." id="ReasonUnqualifiedID" name="ReasonUnqualifiedID"      value={ReasonDefaultSelect}
                        onChange={(e) =>
                          HandleDropdownChangeReasonUnqualified(e)
                        }>
                                {ReasonUnqualifiedData.map((value) => (
                          <option value={value.ReasonUnqualifiedID}>
                            {value.Reason}
                          </option>
                        ))}

</select>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Company Revenue</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" data-val="true" data-val-number="The field CompanyRevenue must be a number." id="CompanyRevenue" name="CompanyRevenue" placeholder="Company Revenue" type="number" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Employee Count</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" data-val="true" data-val-number="The field CompanyEmployeeCount must be a number." id="CompanyEmployeeCount" min="0" name="CompanyEmployeeCount" placeholder="Employee Count" type="number"/>
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Sales Development Reps</label>
                            <div class="col-lg-7">
                                <select class="form-control  m-input" data-val="true" data-val-number="The field ColdCallersID must be a number." id="ColdCallersID" name="ColdCallersID"  
                 
                        >
                                {/* {ContactSourceData.map((value) => ( */}
                          <option value="Err">
                                  Saledfs
                          </option>
                        {/* ))} */}

</select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">SalesRep</label>
                            <div class="col-lg-7">
                                    
                                <input class="form-control m-input" id="SalesRep" name="SalesRep" placeholder="Sales Rep" type="text" />
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Tag</label>
                            <div class="col-lg-7">

                                <div className="col-lg-7">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={contactoptions}
                        isMulti
                        onChange={(e) => h2Change(e)}
                        value={defaultTagArray[0]?.ContactTagID === "" ? (null) : defaultTagArray}
                      />
                    </div>

                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Other Phone 1</label>
                            <div class="col-lg-7">
                              <input class="form-control m-input" id="OtherPhone1" name="OtherPhone1" placeholder="Other Phone 1" type="text" />
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Other Phone 2</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="OtherPhone2" name="OtherPhone2" placeholder="Other Phone 2" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">External Account ID</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ExternalAccountID" name="ExternalAccountID" placeholder="External Account ID" type="text" />
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">External Contact ID</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ExternalContactID" name="ExternalContactID" placeholder="External Contact ID" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">External Field 1</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ExternalField1" name="ExternalField1" placeholder="External Field 1" type="text" />
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">External Field 2</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ExternalField2" name="ExternalField2" placeholder="External Field 2" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">External Field 3</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ExternalField3" name="ExternalField3" placeholder="External Field 3" type="text" />
                            </div>
                        </div>

                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <div class="col-lg-12">
                                <div class="m-checkbox-inline">
                                    <label class="m-checkbox ">
                                        <input data-val="true" data-val-required="The IsDoNotCallContact field is required." id="IsDoNotCallContact" name="IsDoNotCallContact" type="checkbox"  onChange={(e) => HandleChangeCheckIsDoNotCall(e)}
                  
                        defaultChecked={IsDoNotCallContactData} /> Do Not Call Contact
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                      </div>
                      </div>
                      <div className="border-top modal-content text-right">
                        <div className="row">
                          <div className="col-lg-12 pull-right">
                            <a
                              id="backtolist"
                              className="btn btn-secondary mr-2"
                              onClick={() => SetEditPopup(false)}
                            >
                              cancel
                            </a>
                            <a
                              id="submit"
                              onClick={(e) => ContactUpdate(e)}
                              className="btn btn-primary btn-lightgreen mr-1"
                            >
                              Save
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
          </Popup>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} /></label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell> <input type="checkbox"  onChange={(e) => CheckHandler(e)}
             /></TableCell>
              <TableCell>  <a
                    onClick={() => {
                      SortData("Name");
                    }}
                  >Name</a></TableCell>
              <TableCell><a
                    onClick={() => {
                      SortData("Title");
                    }}
                  >Title</a></TableCell>
              <TableCell><a
                    onClick={() => {
                      SortData("Company");
                    }}
                  >Company</a></TableCell>
              <TableCell><a
                    onClick={() => {
                      SortData("Industry");
                    }}
                  >Industry</a></TableCell>
              <TableCell><a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >Email</a></TableCell>
              <TableCell><a
                    onClick={() => {
                      SortData("Name");
                    }}
                  >Last Send Date</a></TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Rows?.length == 0 ? (
            <p className="text-center">No data available in table</p>
          ) :( Rows?.map((row) => (
              <TableRow key={row.name} row={row} >
              <TableCell><input type="checkbox"   onChange={(e) => CheckBoxClick(e, row.ProspectCampaignID)}/></TableCell>
                <TableCell>{row.Name}</TableCell>
                <TableCell>{row.Title}</TableCell>
                <TableCell>{row.Company}</TableCell>
                <TableCell>{row.Industry}</TableCell>
                <TableCell>{row.Email}</TableCell>
                <TableCell>{row.LastSendDate}</TableCell>
                <TableCell><a
               onClick={() => {
                         EditBtn(row?.ProspectID)
                         }}
                      className="btn-eyesicon"
                    >
      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a></TableCell>
              </TableRow>
              ))
          )
         }
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}
