import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Axios from "axios";
import { history } from "../../../_helpers";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddClientMeetingsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      code: "",
      fields: {},
      errors: {},
      NameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Typelist: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
    };
    this.GetDocmeeting();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  GetDocmeeting() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/GetMeetingDocuments",
      method: "POST",
    }).then((res) => {
      // console.log(res.data.DocummentsStatus,"type");
      this.setState({ Typelist: res.data.DocummentsStatus });
    });
  }

  FromValidation() {
    let formIsValid = true;
    if (Object.entries(this.state.fields).length > 0) {
      let fields = this.state.fields;
      let errors = {};

      if (!fields["Title"]) {
        formIsValid = false;
        errors["Title"] = "Please enter Name";
      }
      if (this.state.NameAvailable != null) {
        formIsValid = false;
      }
      this.setState({ errors: errors });
    }
    return formIsValid;
  }

  // handlechange
  handleChange(field, e) {
    // document.getElementById("blank_error").style.display = "none";
    // console.log("textbox");
    console.log(e.target.value);
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Title != "") {
      this.state.errors.Title = null;
      this.state.NameAvailable = null;
      this.CheckExistDocumentsTitle(e.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.errors.Title = null;
    }
  }

  // check exit name
  CheckExistDocumentsTitle(Title) {
    var str_in = {
      id: this.state.ClientID,
      Title: Title,
    };
    console.log(str_in);

    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/DocumentsStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      console.log(resdata);
      if (resdata.data.length > 0) {
        this.setState({ NameAvailable: resdata.data[0].Title });
        console.log(this.state.NameAvailable);
        // this.setState({ IsExist: true });
      }
    });
  }

  backbtn() {
    history.push("/usersperclient");
  }
  savebtn() {
    var name = document.getElementById("Title").value;
    var Urllink = document.getElementById("Url").value;
    var TypeID = document.getElementById("TypeID").value;
    // console.log(name);
    // console.log(Urllink);
    // console.log(TypeID);

    if (name == undefined || Urllink == undefined || TypeID == undefined) {
      // document.getElementById("blank_error").style.display = "block";
      console.log("Please enter name");
    } else {
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Title: name,
        Url: Urllink,
        TypeID: TypeID,
        IsDeleted: false,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      console.log(data);
      Axios({
        url: CommonConstants.MOL_APIURL + "/documents/AddDocument",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Add Documents successfully.", "Add Documents Status");
          history.push("/documents");
          console.log("successfull data status", res.data);
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div class="paddcols">

              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlepad float-left">Add Meeting </h4>
                </div>
              </div>

              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlepad float-left">Contact Info</h4>
                </div>
              </div>

              <div className="row pt-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">First Name </label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Enter first name" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Last Name</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Enter first name" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Title</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Title" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Company</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Company" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Email</label>
                    <div class="col-lg-8">
                    <input type="Email" class="form-control" placeholder="Email" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">LinkedIn Url</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="LinkedIn Url" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Direct Phone</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Direct Phone" />
                    </div>
                  </div>
                </div> 

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Direct Phone</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Direct Phone" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Direct Phone Ext</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Direct Phone Ext" />
                    </div>
                  </div>
                </div> 

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Company Phone</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Company Phone" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Website</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Website" />
                    </div>
                  </div>
                </div> 

          </div>
          <div className="row pt-4">

                <div className="col-lg-12 row borderbtnm">
                  <div className="col">
                    <h4 className="headertitlepad float-left">Meeting Info </h4>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Meeting Status</label>
                    <div class="col-lg-8">
                        <select className="form-control " name="ContactSource">
                          <option value="">--Select--</option> 
                        </select>
                    </div>
                  </div>
                </div>  
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Meeting Source</label>
                    <div class="col-lg-8">
                        <select className="form-control " name="ContactSource">
                          <option value="">--Select--</option> 
                        </select>
                    </div>
                  </div>
                </div>  
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Meeting Set Date</label>
                    <div class="col-lg-8">
                    <input type="date" class="form-control" placeholder=" " />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Meeting Set By</label>
                    <div class="col-lg-8">
                        <select className="form-control " name="ContactSource">
                          <option value="">--Select--</option> 
                        </select>
                    </div>
                  </div>
                </div>    
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Meeting Date</label>
                    <div class="col-lg-8">
                    <input type="date" class="form-control" placeholder=" " />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Meeting Time Zone</label>
                    <div class="col-lg-8">
                        <select className="form-control " name="ContactSource">
                          <option value="">--Select--</option> 
                        </select>
                    </div>
                  </div>
                </div>  
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Owner</label>
                    <div class="col-lg-8">
                        <select className="form-control " name="ContactSource">
                          <option value="">--Select--</option> 
                        </select>
                    </div>
                  </div>
                </div>  
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Reminder Email Account</label>
                    <div class="col-lg-8">
                        <select className="form-control " name="ContactSource">
                          <option value="">--Select--</option> 
                        </select>
                    </div>
                  </div>
                </div>    
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Contract Value</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Contract Value" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Industry</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="Industry" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">External Account ID</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="External Account ID" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">External Contact ID</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="External Contact ID" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">External Field 1</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="External Field 1" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">External Field 2</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="External Field 2" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">External Field 3</label>
                    <div class="col-lg-8">
                    <input type="text" class="form-control" placeholder="External Field 3" />
                    </div>
                  </div>
                </div> 
                <div class="col-lg-6"></div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Seniority</label>
                    <div class="col-lg-8">
                        <select className="form-control " name="ContactSource">
                          <option value="">--Select--</option> 
                        </select>
                    </div>
                  </div>
                </div>   

                <div class="col-lg-6 my-3">
                  <div class="m-checkbox-inline">
                    <label class="check_bx">
                        <input type="checkbox" /><span className="checkmark"></span>
                        Send Reminder
                    </label> 
                  </div>
                </div>   
                
                <div class="col-lg-6 boxsinput_group mb-5">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Call Recording</label>
                    <div class="col-lg-8">
                    <input type="file" />
                    </div>
                  </div>
                </div> 
              </div>

              <div class="row py-4">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="usersperclient"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddClientMeetingsPage = connect(
  mapState,
  actionCreators
)(AddClientMeetingsPage);
export { connectedAddClientMeetingsPage as AddClientMeetingsPage };
