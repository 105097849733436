import React from 'react';
import Axios from "axios";
const moment = require("moment");
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import LeaderboardTabing from '../../_components/user/LeaderBoard/LeaderboardTabing';
//import CollapsibleTable from '../../_components/usertable';

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";


class LeaderBoardPage extends React.Component {
    constructor(props) {
        super(props);
        
    
        this.state = {
          FromDate:"",
          ToDate:"",
          IncludeOldClient:false
         
        };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.IsOldClient = this.IsOldClient.bind(this);
         
        
      }
    
      componentDidMount() {
          
        document.getElementById('from-date').valueAsDate=new Date(moment().subtract(1,'months'))
        document.getElementById('to-date').valueAsDate=new Date(moment())
        let FromDate = document.getElementById('from-date').value;
        let ToDate = document.getElementById('to-date').value;
        let IncludeOldClient=document.getElementById('IsOldClient').checked;
        this.setState({IncludeOldClient:IncludeOldClient})
        this.setState({
          FromDate: FromDate,
          ToDate: ToDate
        })
      }

      IsOldClient(){
        let IncludeOldClient=document.getElementById('IsOldClient').checked;
        console.log(IncludeOldClient)
        this.setState({IncludeOldClient:IncludeOldClient})
      }
      SaveBtn(){
        let FromDate=document.getElementById('from-date').value;
        let ToDate=document.getElementById('to-date').value;
        this.setState({FromDate:FromDate,ToDate:ToDate})
    }
    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd pl-0 mb-0">LeaderBoard</h4>
                        </div>
                        <div className="col px-4 padright"> 
                        </div>
                    </div> 

                    

                    <div className="row borderbtnm whiterow mx-0 pb-3">
                        <div className="col px-4"> 
                            <div className="boxborders mt-3">
                                <label class="check_bx">
                                    <input type="checkbox" onClick={this.IsOldClient} id="IsOldClient"/><span className="checkmark"></span>
                                    Include Old Clients
                                </label>
                            </div>
                        </div>
                        <div className="col px-4 mt-3">
                            <div className="listing-li float-right">
                            <div class="btn-group">
                            <input id="fromtab" name="fromtab" type="hidden" value="" />
                                <input class="form-control datepicker" id="from-date" name="from-date" type="date" autocomplete="off"  />
                                <input class="form-control datepicker" id="to-date" name="to-date" type="date" autocomplete="off"  />
                                    <button id="aCompanyState" onClick={this.SaveBtn} class="btn btn-primary btnColor btn-radius" autocomplete="off">
                                        <i class="la flaticon-diagram"></i>
                                        <span>Compare</span>
                                </button>
                            </div>

                            </div>
                        </div>
                    </div> 

                    <div className="col padd-0">
                        <LeaderboardTabing ToDate={this.state.ToDate} FromDate={this.state.FromDate} IsOldClientss={this.state.IncludeOldClient}/>
                    </div>
                </div>

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLeaderBoardPage = connect(mapState, actionCreators)(LeaderBoardPage);
export { connectedLeaderBoardPage as LeaderBoardPage };