import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class CEditClientPOCPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      TitleNotNull: false,
      IsExist: false,
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      UserIDby: null,
      Role: null,
      OldName:null,
      BtnDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
      this.state.UserIDby = Details.ChildUserID;
    }
    this.ClientPocEdit();
  }

   // form validation
   async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.ClientPocNameCheckExist(Name);
    if (Name == "" && Name.indexOf(" ") != "") {
          FormIsValid = false;
          Errors["Name"] = "Please enter name";
    }

    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

    // handle change
    HandleChange() {
      var Name = document.getElementById("Name").value.trim();

      if (Name != "" && Name.indexOf(" ") != "") {
        this.state.Errors.Name = null;
        this.state.StatusAvailable = null;
        this.ClientPocNameCheckExist(Name);
      } else {
        this.state.StatusAvailable = null;
        this.state.Errors.Name = null;
      }
    }

  // Clientpoc check exits
  async ClientPocNameCheckExist(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role:this.state.Role
    };
    var resdata = await  Axios({
      url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocExists",
      method: "POST",
      data: str_in,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if(this.state.OldName == Name){
            this.setState({ IsExist: false });
          }else{
            this.setState({ StatusAvailable: resdata.data.Data[0]?.Name });
            this.setState({ IsExist: true });
            return true;
          }
        } else {
          this.setState({ IsExist: false });
          return false;
        }
      }
  }

  // client poc update 
  ClientPocEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      _id: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
        console.log(res)
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0].Name;
        this.setState({OldName:res.data.Data[0].Name})
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  // back button
  BackBtn() {
    history.push("/CClientPOC");
  }

  // save button
  async SaveBtn() {
    this.setState({BtnDisabled:true})
    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var FinalValidation = await this.FromValidation()

    if (FinalValidation == true) {
      var Name = document.getElementById("Name").value.trim();
      var ClientPocData = {
        _id: this.state.id,
        Name: Name,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDt: new Date(),
        Role:this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocUpdate",
        method: "POST",
        data: ClientPocData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
             Client poc
              <br />
              Client poc updated successfully.
            </div>
          );
          
          history.push("/CClientPOC");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });
    }  else {
      this.setState({BtnDisabled:false})
     // document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left">
                    Edit
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row mb-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="row mb-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.SaveBtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditClientPOCPage = connect(
  mapState,
  actionCreators
)(CEditClientPOCPage);
export { connectedCEditClientPOCPage as CEditClientPOCPage };
