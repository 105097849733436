import React from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
const moment = require("moment");
import Select from "react-select";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

class CEditContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      ClientID: null,
      UserID: null,
      ContactSourceData: [],
      ClientPOCData: [],
      ReasonUnqualifiedData: [],
      ContactTagData: [],
      ContactOwnerData: [],
      ContactSalesDevlopMenData: [],
      ContactCustomFieldData: [],
      ContactStatusData: [],
      ContactSourceDefaultSelect: null,
      ContactTagDefaultSelect: [],
      ContactStatusDefaultSelect: null,
      ContactOwnerDefaultSelect: null,
      ClientPOCDefaultSelect: null,
      ReasonDefaultSelect: null,
      ContactStatusDefaultSelect: null,
      IsDoNotCallContactData: null,
      ProspectIDData: "",
      Email: "",
      OldProspectStatusId: "",
      ProspectCustomFieldByIdArray: [],
      ContactCustomFieldArray: [],
      // CustomValue:"",

      // potentmeeting data
      PotentialMeetingData: [],
      PMRows: [],
      PMRlen: 0,
      PMFlen: 0,
      PMCountPage: 0,
      PMPage: 1,
      PMRowsPerPage: 10,
      PMSearch: "",
      PMSortField: "FirstName",
      PMSortedBy: 1,
      PMSflag: false,

    // lists prosepct
    ListData: [],
    ListRows: [],
    LRlen: 0,
    LFlen: 0,
    LCountPage: 0,
    PageL: 1,
    RowsPerPageL: 15,
    SearchL: "",
    SortFieldL: "Name",
    SortedByL: 1,
    SflagL: false,

      // contact status history
      HistoryData: [],
      HRows: [],
      HRlen: 0,
      HFlen: 0,
      HCountPage: 0,
      HPage: 1,
      HRowsPerPage: 15,
      HSearch: "",
      HSortField: "Status",
      HSortedBy: 1,
      HSflag: false,

      //
      Page: 1,
      RowsPerPage: 10,
      Search: "",

      // potentmeeting data
      PotentialMeetingData: [],
      PMRows: [],
      PMRlen: 0,
      PMFlen: 0,
      PMCountPage: 0,
      PMPage: 1,
      PMRowsPerPage: 15,
      PMSearch: "",
      PMSortField: "FirstName",
      PMSortedBy: 1,
      PMSflag: false,
      PMNotes:[],

      // meeting data
      MeetingData: [],
      MRows: [],
      MRlen: 0,
      MFlen: 0,
      MCountPage: 0,
      MPage: 1,
      MRowsPerPage: 15,
      MSearch: "",
      MSortField: "FirstName",
      MSortedBy: 1,
      MSflag: false,
      MNotes:[],

      // call history data
      CallHistoryData: [],
      CRows: [],
      CRlen: 0,
      CFlen: 0,
      CCountPage: 0,
      CPage: 1,
      CRowsPerPage: 10,
      CSearch: "",
      CSortField: "FirstName",
      CSortedBy: 1,
      CSflag: false,

      // campaign history data
      CampaignHistoryData: [],
      CAMRows: [],
      CAMRlen: 0,
      CAMFlen: 0,
      CAMCountPage: 0,
      CAMPage: 0,
      CAMRowsPerPage: 15,
      CAMSearch: "",
      CAMSortField: "CampaignName",
      CAMSortedBy: 1,
      CAMSflag: false,

      // contact status
      ContactStatusDData: [],
      ContactData: [],
      // ff
      ProspectIdForNote: "",
      ContactTagSelectArray: [],
      selectedChoice: [],

      // Note

        Page: 1,
        RowsPerPage: 15,
        Search: "",
        SortField: "Title",
        SortedBy: 1,
        Sflag: false,
        Data: [],
        Rows: [],
        Rlen: 0,
        Flen: 0,
        CountPage: 0,
        SearchData: [],
        TitleAvailableNotes:null,
        Fields1: {},
        Errors1: {},
        PageType:"",
        Errors:{},
            // for note
            NotePage: 1,
            NoteRowsPerPage: 15,
            NoteSearch: "",
            NoteSortField: "Title",
            NoteSortedBy: 1,
            NoteSflag: false,
            NoteData: [],
            NoteRows: [],
            NoteRlen: 0,
            NoteFlen: 0,
            NoteCountPage: 0,
            OldTitleUpdate:null,
            
      TagArray:[],
      SendEmailPopup:false,
      EditContactTagArray:[],
      defaultTagArray:[],
      contactoptions:[],
      TagNameArray:[],
      TagIDArray:[],

      // 
      errors:{},
      IsInValidWebsite:false,
      IsValidEmail:false,
      NewArrayCustomField:[],
      // 
      ShowNotePopup:false,
      Role:"",
      CUserID:null,
      Open: false,
      BtnDisabled:false
    };
    this.ContactUpdate = this.ContactUpdate.bind(this);
    this.NoteGet();
    this.PotentialMeetingGet();
    this.ListsGet();
    this.MeetingGet();
    this.CallHistoryGet();
    this.CampaignHistoryGet();
    this.ContactStatusGet();
    this.ContactEdit();
    this.ContactStatusHistoryGet();
    // this.ContactNoteGet();

    this.ProspectNoteEditBtn = this.ProspectNoteEditBtn.bind(this);
    this.ProspectNoteSave = this.ProspectNoteSave.bind(this);
    this.ProspectNoteUpdate = this.ProspectNoteUpdate.bind(this);
    this.ViewBtn = this.ViewBtn.bind(this);
    
    this.CustomFieldValueGet();
  }

  componentDidMount() {
    document.title = "Edit Contact | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.ContactInfoGetData(Details.ClientID,Details.ParentUserID,Details.Role);
    this.ContactsGetIdByData();
  }

  // component did update
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.Email !== this.state.Email ||
      // prevState.PMSearch !== this.state.PMSearch ||
      // prevState.MSearch !== this.state.MSearch ||
      prevState.ProspectIdForNote !== this.state.ProspectIdForNote ||
      prevState.CSearch !== this.state.CSearch
    ) {
      // this.PotentialMeetingGet(this.state.Email, this.state.PMSearch);
      // this.MeetingGet(this.state.Email, this.state.MSearch);
      this.CallHistoryGet(this.state.ProspectIdForNote, this.state.CSearch);
      this.ContactStatusGet(this.state.ProspectIdForNote);
    }
  }

  // contact update
  ContactEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ ContactData: res.data.Data });
      this.setState({ ProspectIdForNote: res.data.Data[0]?._id });
    });
  }

  // // custom field display data
  // CustomFieldValueDataGet(){
  //   const id = this.props.location.state;
  //   this.setState({ id: id });
  //   var InputParaMeter = {
  //     ProspectID:id
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/contacts/CustomFieldContactById",
  //     method: "POST",
  //     data: InputParaMeter,
  //   }).then((resmeeting) => {
  //     this.setState({
  //       AccountCustomfieldbyid: resmeeting.data?.AccountcustomfieldByid,
  //     });
  //     {resmeeting.data?.AccountcustomfieldByid.map((val) => {
  //       document.getElementById(val?.CustomFieldID).value=val.FieldValue
  //     })}
  //   });
  // }

  // handle dropdown change contact source
  HandleDropdownChangeContactSource(e) {
    this.setState({
      ContactSourceDefaultSelect: e.target.ContactSourceDefaultSelect,
    });
  }

  // handle dropdown change contact status
  HandleDropdownChangeContactStatus(e) {
    this.setState({
      ContactStatusDefaultSelect: e.target.ContactStatusDefaultSelect,
    });
  }
  // handle dropdown change contact owner
  HandleDropdownChangeContactOwner(e) {
    this.setState({
      ContactOwnerDefaultSelect: e.target.ContactOwnerDefaultSelect,
    });
  }
  // handle dropdown change client
  HandleDropdownChangeClientPOC(e) {
    this.setState({
      ClientPOCDefaultSelect: e.target.ClientPOCDefaultSelect,
    });
  }
  // handle dropdown change contac reason
  HandleDropdownChangeReasonUnqualified(e) {
    this.setState({
      ReasonDefaultSelect: e.target.ReasonDefaultSelect,
    });
  }

  // handle change email sending
  HandleChangeCheckIsDoNotCall = (e) => {
    const { checked } = e.target;
    this.setState({
      IsDoNotCallContactData: checked,
    });
  };

  // Handle Change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.FirstName != "") {
      this.state.Errors.FirstName = null;
    } else {
      this.state.Errors.FirstName = null;
    }
  }

  // change page
  HandleChangePageContactStatusHistory = (Event,NewPage) => {
  
    if(NewPage == this.state.HPage){
      this.setState({ HPage: NewPage });
      }else{
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var ContactStatusHistoryData = {
      Page: this.state.HPage,
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      ProspectID: id,
      RowsPerPage: this.state.HRowsPerPage,
      Sort: true,
      Field: this.state.HSortField,
      SortBy: this.state.HSortedBy,
      Search: SearchStatusHistory,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
      method: "POST",
      data: ContactStatusHistoryData,
    }).then((reshistory) => {
      this.setState({ HistoryData: reshistory.data?.PageData });
      this.setState({ HRows: reshistory.data?.PageData });
      this.setState({ HRlen: reshistory.data?.TotalCount });
      this.setState({ HFlen: reshistory.data?.TotalCount });
      this.setState({ HCountPage: reshistory.data?.PageCount });
    });
  }
  };

  ContactInfoGetData(CID,UID,Role) {
    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      Role:Role
    };
    
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
              // // multiple contact sele
        let contactoptions = result.data?.ContactTagData.map((val) => {
          return { value: val.Tag, label: val.Tag, cid: val._id };
        });
        
        this.setState({
          contactoptions :contactoptions
        })
      this.setState({

        ContactSourceData: result.data?.ContactSourceData,
        ClientPOCData: result.data?.ClientPOCData,
        ReasonUnqualifiedData: result.data?.ReasonUnqualifiedData,
        ContactTagData: result.data?.ContactTagData,
        ContactOwnerData: result.data?.ContactOwnerData,
        ContactSalesDevlopMenData: result.data?.SalesReplyDevlopmentData,
        ContactCustomFieldData: result.data?.ContactCustomFieldData,
        ContactStatusData: result.data?.ProspectStatusData,
      });
    });
  }

  // contacts page edit for id by data
  ContactsGetIdByData() {
    
    const id = this.props.location.state;
    this.setState({ id: id });
    var ContactsData = {
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGetByID",
      method: "POST",
      data: ContactsData,
    }).then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        let defualtoptionsvalue = []
         result.data.Data[0]?.Tagname.map((val) => {
          if(val.TagName == "" || val._id == ""){
            return (0)
          }else{
            defualtoptionsvalue.push({value :val.TagName, label: val.TagName, cid:val.ContactTagID})
          }
         
        })
        
        this.setState({
          defaultTagArray:defualtoptionsvalue
        })


        document.getElementById("hideloding").style.display = "none";

        this.setState({
          TagArray: result.data.Data[0]?.Tagname,
        });
        
        this.setState({
          Email: result.data.Data[0]?.Email,
        });
        this.setState({
          ProspectIDData: result.data.Data[0]?._id,
        });
        this.setState({
          OldProspectStatusId: result.data.Data[0]?.ProspectStatusDetails?.[0]?.ProspectStatusID,
        });
      
        document.getElementById("FirstName").value =
          result.data.Data[0]?.FirstName;
        document.getElementById("LastName").value =
          result.data.Data[0].LastName;
        document.getElementById("Email").value = result.data.Data[0]?.Email;
        document.getElementById("Title").value = result.data.Data[0]?.Title;
        document.getElementById("CompanyNameLong").value =
          result.data.Data[0]?.CompanyNameLong;
        document.getElementById("Company").value = result.data.Data[0]?.Company;
        document.getElementById("Website").value = result.data.Data[0]?.Website;
        document.getElementById("LinkedInURL").value =
          result.data.Data[0]?.LinkedInURL;
        document.getElementById("Industry").value =
          result.data.Data[0]?.Industry;
        document.getElementById("Address1").value =
          result.data.Data[0]?.Address1;
        document.getElementById("Address2").value =
          result.data.Data[0]?.Address2;
        document.getElementById("City").value = result.data.Data[0]?.City;
        document.getElementById("State").value = result.data.Data[0]?.State;
        document.getElementById("Zip").value = result.data.Data[0]?.Zip;
        document.getElementById("Country").value = result.data.Data[0]?.Country;
        document.getElementById("ContactCategory").value =
          result.data.Data[0]?.ContactCategory;
        document.getElementById("AccountCategory").value =
          result.data.Data[0]?.AccountCategory;
        document.getElementById("MobilePhone").value =
          result.data.Data[0]?.MobilePhone;
        document.getElementById("DirectPhone").value =
          result.data.Data[0]?.DirectPhone;
        document.getElementById("DirectPhoneExt").value =
          result.data.Data[0]?.DirectPhoneExt;
        document.getElementById("CompanyPhone").value =
          result.data.Data[0]?.CompanyPhone;
        document.getElementById("CompanyRevenue").value =
          result.data.Data[0].Accountname?.CompanyRevenue == null ? "" : result.data.Data[0].Accountname?.CompanyRevenue;
        document.getElementById("CompanyEmployeeCount").value =
          result.data.Data[0]?.Accountname?.CompanyEmployeeCount == null ? "" : result.data.Data[0].Accountname?.CompanyEmployeeCount;
        document.getElementById("FollowUpDate").value =
          result.data.Data[0]?.FollowUpDate;
        document.getElementById("SalesRep").value =
          result.data.Data[0]?.SalesRep;
        document.getElementById("OtherPhone1").value =
          result.data.Data[0]?.OtherPhone1;
        document.getElementById("OtherPhone2").value =
          result.data.Data[0]?.OtherPhone2;
        document.getElementById("ExternalAccountID").value =
          result.data.Data[0]?.ExternalAccountID;
        document.getElementById("ExternalContactID").value =
          result.data.Data[0]?.ExternalContactID;
        document.getElementById("ExternalField1").value =
          result.data.Data[0]?.ExternalField1;
        document.getElementById("ExternalField2").value =
          result.data.Data[0]?.ExternalField2;
        document.getElementById("ExternalField3").value =
          result.data.Data[0]?.ExternalField3;
        document.getElementById("IsDoNotCallContact").value =
          result.data.Data[0]?.IsDoNotCallContact;
          document.getElementById("ExternalField3").value =
          result.data.Data[0]?.ExternalField3;
          this.setState({
            EditContactTagArray:result.data.Data[0]?.Tagname
          })
        this.setState({
          ContactSourceDefaultSelect: result.data.Data[0]?.ContactSourceID,
        });
        this.setState({
          ReasonDefaultSelect: result.data.Data[0]?.ReasonUnqualifiedID,
        });
        this.setState({
          ClientPOCDefaultSelect: result.data.Data[0]?.ClientPOCID,
        });
        this.setState({
          ContactOwnerDefaultSelect: result.data.Data[0]?.ContactOwnerID,
        });

        this.setState({
          selectedChoice: result.data.Data[0]?._id,
        });
        this.setState({
          ContactStatusDefaultSelect: result.data.Data[0]?.ContactStatusID,
        });
        this.setState({
          IsDoNotCallContactData: result.data.Data[0]?.IsDoNotCallContact,
        });

        // custom field get by id
        var CustomFiedIdData = {
          ProspectID: result.data.Data[0]?._id,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ProspectCustomFieldId",
          method: "POST",
          data: CustomFiedIdData,
        }).then((res) => {
     
          this.setState({
            ProspectCustomFieldByIdArray: res.data?.ProspectCustomFieldById,
          });
        });
      }
    });

    // custom field list
    var Details = GetUserDetails();
    var str_in = {
      ClientID: this.state.ClientID,
      UserID:Details.ParentUserID
    };

    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      this.setState({
        ContactCustomFieldArray: result.data?.ContactCustomFieldData,
      });
    });
  }
  // POtential meeting get
  PotentialMeetingGet() {
    var Details = GetUserDetails();
    const Email = this.props.location.state.EmailData;
    // var Details = GetUserDetails();
    var PotentialMeetingDataStr = {
      Page: this.state.PMPage,
      RowsPerPage: this.state.PMRowsPerPage,
      Sort: true,
      Field: this.state.MSortField,
      SortBy: this.state.PMSortedBy,
      Search: this.state.PMSearch,
      ClientID: Details.ClientID,
      Email: Email,
      Type: "User",
      UserID:Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
      method: "POST",
      data: PotentialMeetingDataStr,
    }).then((result) => {
      this.setState({ PotentialMeetingData: result.data?.PageData });
      this.setState({ PMRows: result.data?.PageData });
      this.setState({ PMRlen: result.data?.TotalCount });
      this.setState({ PMFlen: result.data?.TotalCount });
      this.setState({ PMCountPage: result.data?.PageCount });
      this.setState({ PMNotes: result.data?.PotentialMeetingNoteData });
    });
  }

  // Meeting get
  MeetingGet() {
    // var Details = GetUserDetails();
    var Details = GetUserDetails();
    const Email = this.props.location.state.EmailData;
    var MeetingDataStr = {
      Page: this.state.MPage,
      RowsPerPage: this.state.MRowsPerPage,
      Sort: true,
      Field: this.state.MSortField,
      SortBy: this.state.MSortedBy,
      Search: this.state.MSearch,
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      Email: Email,
      Type: "User",
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
      method: "POST",
      data: MeetingDataStr,
    }).then((result) => {
      this.setState({ MeetingData: result.data?.PageData });
      this.setState({ MRows: result.data?.PageData });
      this.setState({ MRlen: result.data?.TotalCount });
      this.setState({ MFlen: result.data?.TotalCount });
      this.setState({ MCountPage: result.data?.PageCount });
      this.setState({ MNotes: result.data?.MeetingNoteData });

    });
  }

  // Call history get
  CallHistoryGet(ProspectIdForNote, CSearch) {
    var Details = GetUserDetails();
    var CallHistoryrDataStr = {
      Page: this.state.CPage,
      RowsPerPage: this.state.CRowsPerPage,
      Sort: true,
      Field: this.state.CSortField,
      SortBy: this.state.CSortedBy,
      Search: CSearch,
      ClientID: Details.ClientID,
      ProspectID: ProspectIdForNote,
      Type: "User",
      UserID:Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
      method: "POST",
      data: CallHistoryrDataStr,
    }).then((result) => {
      this.setState({ CallHistoryData: result.data?.PageData });
      this.setState({ CRows: result.data?.PageData });
      this.setState({ CRlen: result.data?.TotalCount });
      this.setState({ CFlen: result.data?.TotalCount });
      this.setState({ CCountPage: result.data?.PageCount });
    });
  }

  // Campaign history get
  CampaignHistoryGet(CAMSearch) {
    const id = this.props.location.state.data;
    var Details = GetUserDetails();
    var CampaignHistoryDataStr = {
      Page: this.state.CAMPage,
      ProspectID:id,
      RowsPerPage: this.state.CAMRowsPerPage,
      Sort: true,
      Field: this.state.CAMSortField,
      SortBy: this.state.CAMSortedBy,
      Search: CAMSearch,
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      Type: "User",
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
      method: "POST",
      data: CampaignHistoryDataStr,
    }).then((result) => {
      this.setState({ CampaignHistoryData: result.data?.PageData });
      this.setState({ CAMRows: result.data?.PageData });
      this.setState({ CAMRlen: result.data?.TotalCount });
      this.setState({ CAMFlen: result.data?.TotalCount });
      this.setState({ CAMCountPage: result.data?.PageCount });
    });
  }

  //  campaign history change display rows
  ChangeRowSelectedCampaignHistory = (event) => {
    this.setState({ Page: 1 });
    this.setState({ RowsPerPage: event.target.value });
    this.CampaignHistoryGet();
  };

  //  call history  change page
  HandleChangePageCampaignHistory = (NewPage) => {
    this.setState({ CAMPage: NewPage });
    this.CampaignHistoryGet();
  };

  //  potential meeting change display rows
  ChangeRowSelectedPotentialMeeting = (event) => {
    this.setState({ Page: 1 });
    this.setState({ RowsPerPage: event.target.value });
    this.PotentialMeetingGet();
  };

  //potential meeting search for record contact note
  RequestSearchPotentialMeeting(event) {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("searchpotentialmeet").value;
      this.setState({ PMSearch: SearchedVal });
      this.setState({ PMPage: 1 });
      this.PotentialMeetingGet();
    }
  }

  // meeting search for record contact note
  RequestSearchMeeting(event) {
    if (event.key === "Enter") {
      var Searchedval = document.getElementById("searchMeeting").value;
      this.setState({ MSearch: Searchedval });
      this.setState({ PMPage: 1 });
      this.MeetingGet();
    }
  }
  // call history search for record
  RequestSearchCallHistory(event) {
    if (event.key === "Enter") {
      var Searchedval = document.getElementById("searchCallHistory").value;
      this.setState({ CSearch: Searchedval });
      this.setState({ CPage: 1 });
      this.CallHistoryGet();
    }
  }

  // meeting change page
  HandleChangePageMeeting = (NewPage) => {
    this.setState({ Page: NewPage });
    this.MeetingGet();
  };

  //  call history  change page
  HandleChangePageCallHistory = (NewPage) => {
    this.setState({ Page: NewPage });
    this.CallHistoryGet();
  };

  //   meeting change display rows
  ChangeRowSelectedMeeting = (event) => {
    this.setState({ Page: 1 });
    this.setState({ RowsPerPage: event.target.value });
    this.MeetingGet();
  };

  //   meeting change display rows
  ChangeRowSelectedCallHistory = (event) => {
    this.setState({ CPage: 1 });
    this.setState({ CRowsPerPage: event.target.value });
    this.CallHistoryGet();
  };

  // potential meetingchange page
  HandleChangePagePotentialMeeting = (NewPage) => {
    this.setState({ Page: NewPage });
    this.PotentialMeetingGet();
  };

  // contact btn delete
  DeleteBtnContact = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: this.state.CUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Contact  deleted successfully.",
                "success"
              );
              history.push("/ccontacts");
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // contact add to blacklist domain
  ContactAddToBlackList = (Email) => {
    var ContactData = {
      ClientID: this.state.ClientID,
      UserID:this.state.UserID,
      LastUpdatedBy: this.state.CUserID,
      Email: Email,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactToBlackList",
      method: "POST",
      data: ContactData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        if(res.data.UpdateStatus == true){
          toast.success("Add to blackkist successfully.");
        }else{
          toast.error("Contact already blacklisted.");
        }
      } else {
        toast.error(res.data.Message);
      }
    });
  };
  // custom field data list
  CustomFieldValueGet() {
    const id = this.props.location.state.data;
    this.setState({ id: id });
    // prospect custom field id
    var CustomFiedIdData = {
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectCustomFieldId",
      method: "POST",
      data: CustomFiedIdData,
    }).then((res) => {
 
      // this.setState({
      //   NewArrayCustomField: res.data?.ProspectCustomFieldById,
      // });

      // {res.data?.ProspectCustomFieldById.map((val) => {
      //   // 
      //   document.getElementById(val?.CustomFieldID).value=val?.FieldValue
      // })}

    });
  }
  async  FromValidation() {
    let formIsValid = true;
    // let fields = this.state.fields;
    let errors = {};

    var FirstName = document.getElementById("FirstName").value.trim();
    var Email = document.getElementById("Email").value.trim();
    // var Company = document.getElementById("Company").value.trim();
    var Website = document.getElementById("Website").value.trim();
    let IsExistWebsite = await this.CheckWebSite(Website);
    let IsExistEmail = await this.EmailValidationCheck(Email);


    if (FirstName == "") {
      formIsValid = false;
      toast.error("Please enter first name")
    }
    
    if (Website == "" && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter Website")
      document.getElementById("hideloding").style.display = "none";
    }
    if (Email == "" && Website.length < 0 && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter email")
      document.getElementById("hideloding").style.display = "none";
    }
    if (IsExistWebsite == true) {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
      toast.error("Please enter First name, Last name and Company OR First name and Email")
      document.getElementById("hideloding").style.display = "none";
    }
    if (IsExistEmail == true) {
      formIsValid = false;
      // errors["Website"] = "Invalid email!";
      toast.error("Invalid email!")
      document.getElementById("hideloding").style.display = "none";
    }


    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChangeWebsitecheck() {
    var Website = document.getElementById("Website").value.trim();
    if (Website != "") {
      let errors = {};
      this.state.errors.Website = null;
      this.CheckWebSite(Website)
      errors["Website"] = "Invalid website!";
    } else {
      // this.state.statusAvailable = null;
      this.state.errors.Website = null;
    }
  }

    // check website name
    async CheckWebSite(Email) {
      let Errors = {};
      var regex = new RegExp(
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); 
        // let WebsiteGet = document.getElementById("Website").value;
        if (!regex.test(Email) && Email!="") {
          // this.setState({ IsWebsite: false });
          Errors["Website"] = "Invalid website";
          this.setState({ errors: Errors });
          this.setState({ IsInValidWebsite: true });
          return true
        } else {
          // this.setState({ IsWebsite: true });
          this.setState({ WebsiteNotNull: true });
          return false;
        }
    }

  // cheack email valid
  async EmailValidationCheck (Email) {
      let regexp =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let Errors = {};
      if (!regexp.test(Email) && Email != "") {
        Errors["Email"] = "invalid email.";
        this.setState({ IsValidEmail: true });
        this.setState({errors:Errors});
        return true;
      } else {
        this.setState({ IsValidEmail: false });

        return false;
      }
    };
  // update data
 async ContactUpdate() {
  
          document.getElementById("hideloding").style.display = "block";
  this.setState({BtnDisabled:true})
    var FormValidationFlag = await this.FromValidation();
    if(FormValidationFlag === true){
      var FinalTagArray = []
      this.state.defaultTagArray.map((item) => {
        FinalTagArray.push({
          ContactTagID:item.cid,
          TagName:item.label
        })
      })
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Email = document.getElementById("Email").value;
      var Title = document.getElementById("Title").value;
      var CompanyNameLong = document.getElementById("CompanyNameLong").value;
      var Company = document.getElementById("Company").value;
      var Website = document.getElementById("Website").value;
      var LinkedInURL = document.getElementById("LinkedInURL").value;
      var Industry = document.getElementById("Industry").value;
      var Address1 = document.getElementById("Address1").value;
      var Address2 = document.getElementById("Address2").value;
      var City = document.getElementById("City").value;
      var State = document.getElementById("State").value;
      var Zip = document.getElementById("Zip").value;
      var Country = document.getElementById("Country").value;
      var ContactCategory = document.getElementById("ContactCategory").value;
      var AccountCategory = document.getElementById("AccountCategory").value;
      var MobilePhone = document.getElementById("MobilePhone").value;
      var DirectPhone = document.getElementById("DirectPhone").value;
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
      var CompanyPhone = document.getElementById("CompanyPhone").value;
      var CompanyRevenue = document.getElementById("CompanyRevenue").value;
      var FollowUpDate = document.getElementById("FollowUpDate").value;
      var SalesRep = document.getElementById("SalesRep").value;
      var OtherPhone1 = document.getElementById("OtherPhone1").value;
      var OtherPhone2 = document.getElementById("OtherPhone2").value;
      var ExternalAccountID = document.getElementById("ExternalAccountID").value;
      var ExternalContactID = document.getElementById("ExternalContactID").value;
      var ExternalField1 = document.getElementById("ExternalField1").value;
      var ExternalField2 = document.getElementById("ExternalField2").value;
      var ExternalField3 = document.getElementById("ExternalField3").value;
      var ContactSourceID = document.getElementById("ContactSourceID").value;
      var ReasonUnqualifiedID = document.getElementById(
        "ReasonUnqualifiedID"
      ).value;
      var ClientPOCID = document.getElementById("ClientPOCID").value;
      var ContactOwnerID = document.getElementById("ContactOwnerID").value;
      var ContactStatusID = document.getElementById("ContactStatusID").value;
      var ProspectStatusDetails = [];
      this.state.ContactStatusData.forEach((item) => {
        if(item._id === ContactStatusID){
          ProspectStatusDetails.push({
            ProspectStatusID:item?._id,
            Status:item.Status
          })
        }
      })

     
      var IsDoNotCallContact =
        document.getElementById("IsDoNotCallContact").checked;
  
      var CustomFieldValueUpdateArray = [];
      this.state.ContactCustomFieldArray.map((valuecustfield) => {

        var fcustomfield = this.state.ProspectCustomFieldByIdArray?.find(
          (pcustf) => pcustf?.CustomFieldID === valuecustfield?._id
        )
      
        var prospectcustomfieldid =fcustomfield?._id
        var UpdateValue = document.getElementById(valuecustfield?._id)?.value || "";
        var UpdateCustomFieldId = valuecustfield?._id;
        var userid = this.state.UserID;
        if (UpdateValue.length !== 0) {
          CustomFieldValueUpdateArray.push({
            UpdateValue,
            UpdateCustomFieldId,
            userid,
            prospectcustomfieldid
          });
        }else{
          // Add a default value for blank input fields
          CustomFieldValueUpdateArray.push({
            UpdateValue:"",
            UpdateCustomFieldId,
            userid,
            prospectcustomfieldid
          });  
        }
      });
      
  
        var ContactUpdateData = {
          _id: this.state.id,
          FirstName: FirstName,
          LastName: LastName,
          Email: Email,
          Title: Title,
          CompanyNameLong: CompanyNameLong,
          Company: Company,
          Website: Website,
          LinkedInURL: LinkedInURL,
          Industry: Industry,
          Address1: Address1,
          Address2: Address2,
          City: City,
          State: State,
          Zip: Zip,
          Country: Country,
          ContactCategory: ContactCategory,
          AccountCategory: AccountCategory,
          MobilePhone: MobilePhone,
          DirectPhone: DirectPhone,
          DirectPhoneExt: DirectPhoneExt,
          CompanyPhone: CompanyPhone,
          CompanyRevenue: CompanyRevenue,
          FollowUpDate: FollowUpDate,
          SalesRep: SalesRep,
          OtherPhone1: OtherPhone1,
          OtherPhone2: OtherPhone2,
          ExternalAccountID: ExternalAccountID,
          ExternalContactID: ExternalContactID,
          ExternalField1: ExternalField1,
          ExternalField2: ExternalField2,
          ExternalField3: ExternalField3,
          ContactSourceID: ContactSourceID,
          ReasonUnqualifiedID: ReasonUnqualifiedID,
          ClientPOCID: ClientPOCID,
          ContactOwnerID: ContactOwnerID,
          Tagname:FinalTagArray,
          ContactStatusID: ContactStatusID,
          IsDoNotCallContact: IsDoNotCallContact,
          LastUpdatedBy: this.state.CUserID,
          LastUpdatedDate: new Date(),
          OldProspectStatusId: this.state.OldProspectStatusId,
          ClientID: this.state.ClientID,
          ContactCustomUpdateArray: CustomFieldValueUpdateArray,
          Role:this.state.Role,
          ProspectStatusDetails:ProspectStatusDetails
        };
      
        
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactUpdate",
          method: "POST",
          data: ContactUpdateData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {


            toast.success("Contact update successfully.", "Update  contact ");
            document.getElementById("hideloding").style.display = "none";
            if (this.props.location.state["Pagename"] == "AccountContact") {
              history.push({
                pathname: "/ceditaccount",
                state: { data: this.props.location.state.AccountData },
              });
            }else if(this.props.location.state.PageName == "Potential"){
              history.push("/cpotentialmeeting");

            }else{
              document.getElementById("hideloding").style.display = "none";
              const id = this.props.location.state.data;
              const Email = this.props.location.state.EmailData;
              history.push({
                pathname: "/cviewcontacts",
                state: { data: id, EmailData: Email },
              });
            }
          } else {
            document.getElementById("hideloding").style.display = "none";
            toast.error(res.data.Message);
            this.setState({BtnDisabled:false})
      
          }
        });
    }
    else{
      this.setState({BtnDisabled:false})
    }
  

  }

   ViewBtn  (id, emailid) {
    history.push({
      pathname: "/cviewcontacts",
      state: { data: id, EmailData: emailid },
    });
  };

  // Lists get all
  ListsGet() {
    var Details = GetUserDetails();
    var ListData = {
      Page: this.state.PageL,
      ClientID: Details.ClientID,
      RowsPerPage: this.state.RowsPerPageL,
      Sort: true,
      Field: this.state.SortFieldL,
      SortBy: this.state.SortedByL,
      Search: this.state.SearchL,
      Type: "User",
      UserID:Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
      method: "POST",
      data: ListData,
    }).then((result1) => {
      this.setState({ ListData: result1.data?.PageData });
      this.setState({ ListRows: result1.data?.PageData });
      this.setState({ LRlen: result1.data?.TotalCount });
      this.setState({ LFlen: result1.data?.TotalCount });
      this.setState({ LCountPage: result1.data?.PageCount });
    });
  }

  // search for record
  RequestSearchList(Event) {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchlist").value;
      this.setState({ SearchL: SearchedVal });
      this.ListsGet();
    }
  }

  // change display rows
  ChangeRowSelectedList(Event) {
    this.setState({ RowsPerPageL: Event.target.value });
    this.setState({ PageL: 1 });

    this.ListsGet();
  }

  // change page
  HandleChangePageList(NewPage) {
    this.setState({ PageL: NewPage });
    this.ListsGet();
  }

  // change page
  HandleChangePage = (NewPage) => {
    this.setState({ Page: NewPage });
    this.ContactNoteGet();
  };

  // call note contact change display rows
  ChangeRowSelected = (event) => {
    this.setState({ Page: 1 });
    this.setState({ RowsPerPage: event.target.value });
    this.ContactNoteGet();
  };
  // search for record contact note
  RequestSearch(event) {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("searchnote").value;
      this.setState({ Search: SearchedVal });
      this.ContactNoteGet();
    }
  }


  // change display rows contact status history
  ChangeRowSelectedContactStatusHistory = (event) => {
    this.setState({ HRowsPerPage: event.target.value });
    this.setState({ HPage: 1 });
    this.ContactStatusHistoryGet();
  };

  // request search status history
  RequestSearchContactHistory(event) {  
    if (event.key === "Enter") {
      var Details = GetUserDetails();
      let SearchStatusHistory = document.getElementById("HSearch").value;

    const id = this.props.location.state.data;
    this.setState({ id: id });
    var ContactStatusHistoryData = {
      Page: this.state.HPage,
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      ProspectID: id,
      RowsPerPage: this.state.HRowsPerPage,
      Sort: true,
      Field: this.state.HSortField,
      SortBy: this.state.HSortedBy,
      Search: SearchStatusHistory,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
      method: "POST",
      data: ContactStatusHistoryData,
    }).then((reshistory) => {
      this.setState({ HistoryData: reshistory.data?.PageData });
      this.setState({ HRows: reshistory.data?.PageData });
      this.setState({ HRlen: reshistory.data?.TotalCount });
      this.setState({ HFlen: reshistory.data?.TotalCount });
      this.setState({ HCountPage: reshistory.data?.PageCount });
    });
    }
  }
  // contact status history get
  ContactStatusHistoryGet() {
    var Details = GetUserDetails();
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var ContactStatusHistoryData = {
      Page: this.state.HPage,
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      ProspectID: id,
      RowsPerPage: this.state.HRowsPerPage,
      Sort: true,
      Field: this.state.HSortField,
      SortBy: this.state.HSortedBy,
      Search: this.state.HSearch,
      Type: "User",
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
      method: "POST",
      data: ContactStatusHistoryData,
    }).then((reshistory) => {
      this.setState({ HistoryData: reshistory.data?.PageData });
      this.setState({ HRows: reshistory.data?.PageData });
      this.setState({ HRlen: reshistory.data?.TotalCount });
      this.setState({ HFlen: reshistory.data?.TotalCount });
      this.setState({ HCountPage: reshistory.data?.PageCount });
    });
  }

  // List edit
  ListsAdd() {
    history.push("/caddlist");
  }

    // potential meetings edit
    PotentialMeetingsEdit(id) {
      history.push({pathname:"/cviewpotentialmeeting", state:{data:id, Pagename:"Contact", dataprospect:this.state.ProspectIdForNote}});
    }

  // List edit
  ListsEdit() {
    history.push("/ceditlist");
  }

  // Back button contacts
  ContactBackBtn() {
    if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/ceditaccount",
        state: { data: this.props.location.state.AccountData },
      });
    }else if(this.props.location.state.Pagename == "Potential"){
      history.push("/cpotentialmeeting");
    }else if(this.props.location.state.Pagename == "PotentialLinkedin"){
      history.push("/clinkedinfollowup");
    }else{
      history.push("/ccontacts");
    }
  }

  // contact status get data
  ContactStatusGet(ProspectIdForNote) {
    var Details = GetUserDetails();
    var DataStr = {
      ClientID: Details.ClientID,
      ProspectID: ProspectIdForNote,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactLastEmail",
      method: "POST",
      data: DataStr,
    }).then((result) => {
      var newArray = [];
      newArray.push(result.data?.ContactStatusAllData);
      this.setState({ ContactStatusDData: newArray });
    });
  }

  h2Change = (e) => {
    
    this.setState({ defaultTagArray: e });
  };

  //delete contact note id
  ProspectNoteDelete(id,PageName) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a prospect note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var PNoteDelete = {
          _id: id,
          Type:PageName,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: this.state.CUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteDelete",
          method: "POST",
          data: PNoteDelete,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "prospect note deleted successfully.",
                "success"
              );
              var Details = GetUserDetails();
              const Email = this.props.location.state.EmailData;
              const id = this.props.location.state.data;
              this.setState({ id: id });
              var SearchedVal = document.getElementById("SearchNote").value;
              var ProspectNoteData = {
                Page: this.state.NotePage,
                ClientID: Details.ClientID,
                ProspectID: id,
                Email:Email,
                RowsPerPage: this.state.NoteRowsPerPage,
                Sort: true,
                Field: this.state.NoteSortField,
                SortBy: this.state.NoteSortedBy,
                Search: SearchedVal,
                Type: "User",
                UserID:Details.ParentUserID,
                Role: this.state.Role
              };
              Axios({
                url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
                method: "POST",
                data: ProspectNoteData,
              }).then((responsenote) => {
                this.setState({ NoteData: responsenote.data?.PageData });
                this.setState({ NoteRows: responsenote.data?.PageData });
                this.setState({ NoteRlen: responsenote.data?.TotalCount });
                this.setState({ NoteFlen: responsenote.data?.TotalCount });
                this.setState({ NoteCountPage: responsenote.data?.PageCount });
              });
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  //  contact note update
  ProspectNoteUpdate() {
    var Title = document.getElementById("TitleEditNote").value;
    var Notes = document.getElementById("NotesEditPNotes").value;

    var ProspectNoteUpdateData = {
      _id: this.state.id,
      Title: Title,
      Note: Notes,
      LastUpdatedBy: this.state.CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteUpdate",
      method: "POST",
      data: ProspectNoteUpdateData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Prospect note Status update successfully.",
          "Update Potential meeting Status"
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  //  handle change contact notes
  HandleChangePNotes(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailable = null;
      this.ProspectNoteTitleCheckExist(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.Errors.Title = null;
    }
  }

  // contact note get
  NoteGet() {
    var Details = GetUserDetails();
    const Email = this.props.location.state.EmailData;
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var ProspectNoteData = {
      Page: this.state.NotePage,
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      ProspectID: id,
      Email:Email,
      RowsPerPage: this.state.NoteRowsPerPage,
      Sort: true,
      Field: this.state.NoteSortField,
      SortBy: this.state.NoteSortedBy,
      Search: this.state.NoteSearch,
      Type: "User",
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
      method: "POST",
      data: ProspectNoteData,
    }).then((responsenote) => {
      
      this.setState({ NoteData: responsenote.data?.PageData });
      this.setState({ NoteRows: responsenote.data?.PageData });
      this.setState({ NoteRlen: responsenote.data?.TotalCount });
      this.setState({ NoteFlen: responsenote.data?.TotalCount });
      this.setState({ NoteCountPage: responsenote.data?.PageCount });
    });
  }

  // find id by record contact edit
  ProspectNoteEditBtn(id,PageName) {
    this.setState({ id: id });
    this.setState({PageType:PageName})
    var ProspectNoteEdit = {
      id: id,
      Type:PageName
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteByID",
      method: "POST",
      data: ProspectNoteEdit,
    }).then((res) => {
      
      // if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ OldTitleUpdate: res.data[0]?.Title });
      document.getElementById("TitleEditNote").value = res.data[0].Title;
      document.getElementById("NotesEditPNotes").value = res.data[0].Note;
    });
  }

  //get sort field data
  NoteSortData = (Field) => {
    var Details = GetUserDetails();
    const id = this.props.location.state;
    this.setState({ id: id });
    var searchedVal = document.getElementById("searchnote").value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      // setsflag(false);
      this.setState({ Sflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
        // setsortedBy(-1);
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
        // setsortedBy(1);
      }
      this.setState({ SortField: Field });

      // setsortField(Field);
      var InputParameter = {
        Page: this.state.Page,
        ClientID: Details.ClientID,
        ProspectID: id,
        RowsPerPage: this.state.RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.Search,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const ContactNoteList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteGet",
        method: "POST",
        data: InputParameter,
      });
      ContactNoteList.then((responsenote) => {
        this.setState({ Data: responsenote.data?.PageData });
        this.setState({ Rows: responsenote.data?.PageData });
        this.setState({ Rlen: responsenote.data?.TotalCount });
        this.setState({ Flen: responsenote.data?.TotalCount });
        this.setState({ CountPage: responsenote.data?.PageCount });
      });
    } else {
      SerchBox = true;
      // setsflag(true);
      this.setState({ Sflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
        // setsortedBy(-1);
      } else {
        SortBy = 1;
        // setsortedBy(1);
        this.setState({ SortedBy: 1 });
      }
      // setsortField(Field);
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.Page,
        ClientID: Details.ClientID,
        ProspectID: id,
        RowsPerPage: this.state.RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.Search,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const ContactNoteList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteGet",
        method: "POST",
        data: InputParameter,
      });
      ContactNoteList.then((responsenote) => {
        this.setState({ Data: responsenote.data?.PageData });
        this.setState({ Rows: responsenote.data?.PageData });
        this.setState({ Rlen: responsenote.data?.TotalCount });
        this.setState({ Flen: responsenote.data?.TotalCount });
        this.setState({ CountPage: responsenote.data?.PageCount });
      });
    }
  };

  // contact status history sort get data
  ContactStatusHistorySortData = (Field) => {
    var searchedVal = document.getElementById(
      "HSearch"
    ).value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      // setsflag(false);
      this.setState({ Sflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
        // setsortedBy(-1);
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
        // setsortedBy(1);
      }
      this.setState({ SortField: Field });
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var Details = GetUserDetails();
      var InputParameter = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID:this.state.UserID,
        ProspectID: id,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.HSearch,
        Type: "User",
        Role: this.state.Role
      };

      const ContactStatusHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      ContactStatusHistoryList.then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
      });
    } else {
    var Details = GetUserDetails();

      SerchBox = true;
      // setsflag(true);
      this.setState({ Sflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
        // setsortedBy(-1);
      } else {
        SortBy = 1;
        // setsortedBy(1);
        this.setState({ SortedBy: 1 });
      }
      // setsortField(Field);
      this.setState({ SortField: Field });
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var Details = GetUserDetails();
      var InputParameter = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID:this.state.UserID,
        ProspectID: id,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.HSearch,
        Type: "User",
      };

      const ContactStatusHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      ContactStatusHistoryList.then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
      });
    }
  };

  // lists sort get data
  ListsSortData = (Field) => {
    var Details = GetUserDetails();
    const id = this.props.location.state;
    this.setState({ id: id });
    var searchedVal = document.getElementById("searchlist").value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      // setsflag(false);
      this.setState({ SflagL: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
        // setsortedBy(-1);
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
        // setsortedBy(1);
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PageL,
        ClientID: Details.ClientID,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.SearchL,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const List = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      List.then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
      });
    } else {
      SerchBox = true;
      // setsflag(true);
      this.setState({ SflagL: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
        // setsortedBy(-1);
      } else {
        SortBy = 1;
        // setsortedBy(1);
        this.setState({ SortedBy: 1 });
      }
      // setsortField(Field);
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PageL,
        ClientID: Details.ClientID,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.SearchL,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const List = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      List.then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
      });
    }
  };

  // Potential Meetings sort get data
  PotentialMeetingSortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("searchpotentialmeet").value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      this.setState({ PMSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        ClientID: Details.ClientID,
        Type: "User",
        Email: this.state.Email,
        Search: this.state.PMSearch,
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
      });
    } else {
      SerchBox = true;
      // setsflag(true);
      this.setState({ PMSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.PMSearch,
        ClientID: Details.ClientID,
        Email: this.state.Email,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
      });
    }
  };

  // Call history sort get data
  CallHistorySortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("searchcallhistory").value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      this.setState({ MSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.CSearch,
        ClientID: Details.ClientID,
        ProspectID: this.state.ProspectIdForNote,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };
      const CallHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      CallHistoryList.then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
      });
    } else {
      SerchBox = true;
      this.setState({ MSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.CSearch,
        ClientID:Details.ClientID,
        ProspectID: this.state.ProspectIdForNote,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };
      const CallHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      CallHistoryList.then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
      });
    }
  };
  //  Meetings sort get data
  MeetingSortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("searchMeeting").value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      this.setState({ MSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.MSearch,
        ClientID: Details.ClientID,
        Email: this.state.Email,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };
      const MeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: InputParameter,
      });
      MeetingList.then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
      });
    } else {
      SerchBox = true;
      this.setState({ MSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.MSearch,
        ClientID: Details.ClientID,
        Email: this.state.Email,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const MeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: InputParameter,
      });
      MeetingList.then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
      });
    }
  };
  // handlechage add prospect note
  HandleChangePNotesAdd(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailableNotes = null;
      this.ProspectNoteTitleCheckExist(e.target.value,"Prospect");
    } else {
      this.state.TitleAvailableNotes = null;
      this.state.Errors.Title = null;
    }
  }

    // save contact note
    async ProspectNoteSave(e) {
      e.preventDefault();
      e.currentTarget.disabled = true;
      var Final_flag = await this.fromValidationNotes();
      if (Final_flag == true) {
        var Title = document.getElementById("TitlePNote").value;
        var Notes = document.getElementById("NotesPNote").value;
      var ProspectData = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        ProspectID: this.state.ProspectIdForNote,
        Title: Title,
        Note: Notes,
        CreatedBy: this.state.UserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteAdd",
        method: "POST",
        data: ProspectData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Prospect note add successfully.", "Add Note ");
        this.setState({ShowNotePopup:false})
        document.getElementById("hideloding").style.display = "none";
        //note get data
        var Details = GetUserDetails();
        const Email = this.props.location.state.EmailData;
        const id = this.props.location.state.data;
        this.setState({ id: id });
        var ProspectNoteData = {
          Page: this.state.NotePage,
          ClientID: Details.ClientID,
          UserID:Details.ParentUserID,
          ProspectID: id,
          Email:Email,
          RowsPerPage: this.state.NoteRowsPerPage,
          Sort: true,
          Field: this.state.NoteSortField,
          SortBy: this.state.NoteSortedBy,
          Search: this.state.NoteSearch,
          Type: "User",
          Role:this.state.Role
        };
        Axios({ 
          url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
          method: "POST",
          data: ProspectNoteData,
        }).then((responsenote) => {
          
          this.setState({ NoteData: responsenote.data?.PageData });
          this.setState({ NoteRows: responsenote.data?.PageData });
          this.setState({ NoteRlen: responsenote.data?.TotalCount });
          this.setState({ NoteFlen: responsenote.data?.TotalCount });
          this.setState({ NoteCountPage: responsenote.data?.PageCount });
        });
        } else {
          toast.error(res.data.Message);
        }
      });
      
    }else{
      document.getElementById("submitSave").disabled = false;
    }
  }

    //form validation for add prospect note
    async fromValidationNotes() {
      let formIsValid = true;
      let fileds = this.state.Fields;
      let Errors = {};
      var Title = document.getElementById("TitlePNote").value.trim();
      var Notes = document.getElementById("NotesPNote").value.trim();
      var IsExist = await this.ProspectNoteTitleCheckExist(Title,"Prospect");
      if (Title == "") {
        formIsValid = false;
        Errors["TitlePNote"] = "Please Enter Title";
      }
      if (Notes == "") {
        formIsValid = false;
        Errors["NotesPNote"] = "Please Enter Notes";
      }
      if (IsExist == true) {
        formIsValid = false;
      }
  
      this.setState({ Errors: Errors });
      return formIsValid;
    }

       // find check exits contact prospect title
       async  ProspectNoteTitleCheckExist(Title,PageType) {
        var ProspectNote = {
          ClientID: this.state.ClientID,
          Title: Title,
          Type:PageType
        };
        var res = await Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteCheckExits",
          method: "POST",
          data: ProspectNote,
        })
          if (res.data.StatusMessage == "SUCCESS") {
            if (res.data.Data.length > 0) {
              let Errors = {};
              this.setState({ TitleAvailableNotes: res.data.Data[0].Title });
              Errors["NotesPNote"] = "";
              this.setState({ Errors: Errors });
              return true;
            }else {
              return false;
            }
          }
      
      }

        // search for record contact nFVote
  RequestSearch(event) {
    if (event.key === "Enter") {
      let SearchedNote = document.getElementById("SearchNote").value;
      var Details = GetUserDetails();
      const Email = this.props.location.state.EmailData;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var ProspectNoteData = {
        Page: this.state.NotePage,
        ClientID: Details.ClientID,
        ProspectID: id,
        Email:Email,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: this.state.NoteSortField,
        SortBy: this.state.NoteSortedBy,
        Search: SearchedNote,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: ProspectNoteData,
      }).then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
      });
 
    }
  }

        // handlechage add prospect note
        HandleChangePNotesEdit(Field, e) {
          let Fields = this.state.Fields;
          Fields[Field] = e.target.value;
          this.setState({ Fields });
          if (Fields.Title != "") {
            this.state.Errors1.Title = null;
            this.state.TitleAvailableNotes = null;
            this.ProspectNoteTitleCheckExist1(e.target.value,this.state.PageType);
          } else {
            this.state.TitleAvailableNotes = null;
            this.state.Errors1.Title = null;
          }
        }

          // check exists update notes
    async  ProspectNoteTitleCheckExist1(Title,PageT) {
      var ProspectNote = {
        ClientID: this.state.ClientID,
        Title: Title,
        Type:PageT
      };
      var res = await Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteCheckExits",
        method: "POST",
        data: ProspectNote,
      })
        if (res.data.StatusMessage == "SUCCESS") {
          if (res.data.Data.length > 0) {
            if (this.state.OldTitleUpdate == Title) {
              return false;
            } else {
              let Errors1 = {};
              this.setState({ TitleAvailableNotes: res.data.Data[0].Title });
              Errors1["TitleEditNote"] = "";
              this.setState({ Errors1: Errors1 });
              return true;
            }
          } else {
            return false;
          }
        }
    
    }

      //  contact note update
  async ProspectNoteUpdate(e) {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var FinalValidation = await this.fromValidationNote();
    if (FinalValidation == true) {
      var Title = document.getElementById("TitleEditNote").value;
      var Notes = document.getElementById("NotesEditPNotes").value;
  
      var ProspectNoteUpdateData = {
        _id: this.state.id,
        Title: Title,
        Note: Notes,
        Type:this.state.PageType,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteUpdate",
        method: "POST",
        data: ProspectNoteUpdateData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Prospect note Status update successfully.",
            "Update Potential meeting Status"
          );


          var Details = GetUserDetails();
        const Email = this.props.location.state.EmailData;
        const id = this.props.location.state.data;
        this.setState({ id: id });
        var ProspectNoteData = {
          Page: this.state.NotePage,
          ClientID: Details.ClientID,
          UserID:Details.ParentUserID,
          ProspectID: id,
          Email:Email,
          RowsPerPage: this.state.NoteRowsPerPage,
          Sort: true,
          Field: this.state.NoteSortField,
          SortBy: this.state.NoteSortedBy,
          Search: this.state.NoteSearch,
          Type: "User",
          Role:this.state.Role
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
          method: "POST",
          data: ProspectNoteData,
        }).then((responsenote) => {
          this.setState({ NoteData: responsenote.data?.PageData });
          this.setState({ NoteRows: responsenote.data?.PageData });
          this.setState({ NoteRlen: responsenote.data?.TotalCount });
          this.setState({ NoteFlen: responsenote.data?.TotalCount });
          this.setState({ NoteCountPage: responsenote.data?.PageCount });
        });
   

        } else {
          toast.error(res.data.Message);
        }
      });
    }else{
      document.getElementById("submitupdate").disabled = false;
    }

  }

   // formvalidation note update
   async fromValidationNote() {
    let formIsValid = true;
  
    let Fields = this.state.Fields1;
    let Errors1 = {};
  
    var Title1 = document.getElementById("TitleEditNote").value;
    var Notes1 = document.getElementById("NotesEditPNotes").value;
    var IsExist = await this.ProspectNoteTitleCheckExist(Title1,this.state.PageType);
    if (Title1 == "") {
      formIsValid = false;
      Errors1["TitleEditNote"] = "Please Enter Title";
    }
    if (Notes1 == "") {
      formIsValid = false;
      Errors1["NotesEditPNotes"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    
    this.setState({ Errors1: Errors1 });
  
    return formIsValid;
  }

    // change page
    HandleChangePage = (Event,NewPage) => {
      var Details = GetUserDetails(); 
      this.setState({ NotePage: NewPage });
      var SearchedVal = document.getElementById("SearchNote").value;
  
      if(NewPage == this.state.NotePage){
        this.setState({ NotePage: NewPage });
        }else{
          const Email = this.props.location.state.EmailData;
          const id = this.props.location.state.data;
          this.setState({ id: id });
          var ProspectNoteData = {
            Page: this.state.NotePage,
            ClientID: Details.ClientID,
            ProspectID: id,
            Email:Email,
            RowsPerPage: this.state.NoteRowsPerPage,
            Sort: true,
            Field: this.state.NoteSortField,
            SortBy: this.state.NoteSortedBy,
            Search: SearchedVal,
            Type: "User",
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
            method: "POST",
            data: ProspectNoteData,
          }).then((responsenote) => {
            this.setState({ NoteData: responsenote.data?.PageData });
            this.setState({ NoteRows: responsenote.data?.PageData });
            this.setState({ NoteRlen: responsenote.data?.TotalCount });
            this.setState({ NoteFlen: responsenote.data?.TotalCount });
            this.setState({ NoteCountPage: responsenote.data?.PageCount });
          });
        }
      }

        // potential meetings add
  PotentialMeetingsAdd() {
    var PushData = this.state.ContactData
    PushData["Pagename"] = "Contact"
    history.push("/caddpotentialmeeting", PushData);
  }

    //  potential meeting change display rows
    ChangeRowSelectedPotentialMeeting = (event) => {
      this.setState({ Page: 1 });
      this.setState({ RowsPerPage: event.target.value });
      this.PotentialMeetingGet();
    };

    //potential meeting search for record contact note
    RequestSearchPotentialMeeting(event) {
      var Details = GetUserDetails();
      if (event.key === "Enter") {
        var SearchedVal = document.getElementById("searchpotentialmeet").value;
        this.setState({ PMSearch: SearchedVal });
      const Email = this.props.location.state.EmailData;
  
        var PotentialMeetingDataStr = {
          Page: this.state.PMPage,
          RowsPerPage: this.state.PMRowsPerPage,
          Sort: true,
          Field: this.state.MSortField,
          SortBy: this.state.PMSortedBy,
          Search: SearchedVal,
          ClientID: Details.ClientID,
          Email: Email,
          Type: "User",
        };
        
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
          method: "POST",
          data: PotentialMeetingDataStr,
        }).then((result) => {
          this.setState({ PotentialMeetingData: result.data?.PageData });
          this.setState({ PMRows: result.data?.PageData });
          this.setState({ PMRlen: result.data?.TotalCount });
          this.setState({ PMFlen: result.data?.TotalCount });
          this.setState({ PMCountPage: result.data?.PageCount });
        });
      }
    }

      // Potential Meetings sort get data
  PotentialMeetingSortData = (Field) => {
    var Details = GetUserDetails();
    const Email = this.props.location.state.EmailData;
    var searchedVal = document.getElementById("searchpotentialmeet").value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      this.setState({ PMSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        ClientID: Details.ClientID,
        Type: "User",
        Email: Email,
        Search: this.state.PMSearch,
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
      });
    } else {
      const Email = this.props.location.state.EmailData;
      SerchBox = true;
      // setsflag(true);
      this.setState({ PMSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.PMSearch,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
      });
    }
  };

    // potential meetingchange page
    HandleChangePagePotentialMeeting = (Event,NewPage) => {
      this.setState({ PMPage: NewPage });
      this.PotentialMeetingGet();
    };

      // meetings add
  MeetingsAdd() {
    var PushData = this.state.ContactData
    history.push({pathname:"/caddbookedmeeting", state:{Pagename:"Meeting",PushData, dataprospect:this.state.ProspectIdForNote, BackPage:"/viewcontacts"},data: this.props.location.state.data, EmailData:this.props.location.state.EmailData});
  }
    //   meeting change display rows
    ChangeRowSelectedMeeting = (event) => {
      this.setState({ Page: 1 });
      this.setState({ RowsPerPage: event.target.value });
      this.MeetingGet();
    };

      // meeting search for record contact note
  RequestSearchMeeting(event) {
    if (event.key === "Enter") {
      var Searchedval = document.getElementById("searchMeeting").value;
      var Details = GetUserDetails();
      const Email = this.props.location.state.EmailData;
      var MeetingDataStr = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.MSortedBy,
        Search: Searchedval,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID:Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: MeetingDataStr,
      }).then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
      });
    }
  }

    //  Meetings sort get data
    MeetingSortData = (Field) => {
      var Details = GetUserDetails();
  
      var searchedVal = document.getElementById("searchMeeting").value;
      if (searchedVal == "") {
        SerchBox = false;
        this.setState({ MSflag: false });
        var SortField = Field;
        var SortBy;
        if (this.state.SortedBy == 1) {
          SortBy = -1;
          this.setState({ SortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedBy: 1 });
        }
        this.setState({ SortField: Field });
        const Email = this.props.location.state.EmailData;
        var InputParameter = {
          Page: this.state.MPage,
          RowsPerPage: this.state.MRowsPerPage,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Search: this.state.MSearch,
          ClientID: Details.ClientID,
          Email: Email,
          Type: "User",
          UserID:Details.ParentUserID,
          Role: this.state.Role
        };
        const MeetingList = Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
          method: "POST",
          data: InputParameter,
        });
        MeetingList.then((result) => {
          this.setState({ MeetingData: result.data?.PageData });
          this.setState({ MRows: result.data?.PageData });
          this.setState({ MRlen: result.data?.TotalCount });
          this.setState({ MFlen: result.data?.TotalCount });
          this.setState({ MCountPage: result.data?.PageCount });
        });
      } else {
        SerchBox = true;
        this.setState({ MSflag: true });
  
        var SortField = Field;
        var SortBy;
        if (this.state.SortedBy == 1) {
          SortBy = -1;
          this.setState({ SortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedBy: 1 });
        }
        this.setState({ SortField: Field });
        var InputParameter = {
          Page: this.state.MPage,
          RowsPerPage: this.state.MRowsPerPage,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Search: this.state.MSearch,
          ClientID: Details.ClientID,
          Email: Email,
          Type: "User",
          UserID:Details.ParentUserID,
          Role: this.state.Role
        };
  
        const MeetingList = Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
          method: "POST",
          data: InputParameter,
        });
        MeetingList.then((result) => {
          this.setState({ MeetingData: result.data?.PageData });
          this.setState({ MRows: result.data?.PageData });
          this.setState({ MRlen: result.data?.TotalCount });
          this.setState({ MFlen: result.data?.TotalCount });
          this.setState({ MCountPage: result.data?.PageCount });
        });
      }
    };

      // meeting change page
  HandleChangePageMeeting = (Event,NewPage) => {
    this.setState({ MPage: NewPage });
    this.MeetingGet();
  };

    // Edit campaign history 
    EditCampaignHistory(id){
      history.push({pathname:"/ceditcampaigns", state:{data:id, Pagename:"ViewContact", dataprospect:this.state.ProspectIdForNote}});
    }

      //campaign history search for record campaign history
  RequestSearchCampaignHistory(event) {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("searchcampaigns").value;
      if(SearchedVal== this.state.CAMSearch){
        this.setState({ CAMSearch: SearchedVal });
        this.setState({ CAMPage: 1 });
        this.CampaignHistoryGet();
      }else{
        this.setState({ CAMSearch: SearchedVal });
        this.setState({ CAMPage: 1 });
        this.CampaignHistoryGet();
        // document.getElementById("hideloding").style.display = "block";
      }

    }
  }

  toggleMeeting = (meetingID) => {
    this.setState((prevState) => ({
      Open: {
        ...prevState.Open,
        [meetingID]: !prevState.Open[meetingID],
      },
    }));
  };

  toggleMeeting1 = (eventID) => {
    
    this.setState((prevState) => ({
      Open: {
        ...prevState.Open,
        [eventID]: !prevState.Open[eventID],
      },
    }));
  };
  render() {

    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        {/* EDIT AND NOTES PAGE  */}
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header py-3">
                  Edit Contact
                </h3>
              </div>
              <div className="col-md-8 col-sm-8 pb-4 text-right"></div>
            </div>
            <div className="paddcols">
              <div className="row borderbtnm">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center"></div>
                <div className="col-md-8 col-sm-8 text-right">
                  <div className="listing-li float-right padb-15 iconbox-pad edited">
                    <ul>
                      <li>
                        <a href="" className="btn btngroup m-btn">
                          <i class="flaticon-mail la-icon-font-size-13"></i>
                          <span>Send Email</span>
                        </a>
                      </li>
                      <Popup open={this.state.SendEmailPopup}>
           
           <div>
             <div className="modal-black"></div>
             <div className="filterPopup ">
               <div className="paddingboxTerms">
                 <div className="modal-header py-3 px-3">
                   <h6 className="mb-0">Send Email</h6>
                 </div>
                 <div className="modal-body p-5">
                 
                 <div class="row mb-3">
                     <label class="col-lg-2 col-form-label text-left">
                     Account
                     </label>
                     <div class="col-lg-4">
                     <select
                        className="form-control  m-input"
                        name="AccountID"
                        id="AccountID"
                      >
                      
                          <option value="">
                  
                          </option>
                
                      </select>
                  
                     </div>
                     <div class="col-lg-6 d-flex">
                      <label>Contact</label>
                      {this.state.ContactData?.map((item) => {
                     return(<span class="ml-3">{item.Email}</span>) 
                    })}
                     </div>
                   
                   </div>
                   <div class="row mb-3">
                     <label class="col-lg-2 col-form-label text-left">
                       Subject
                     </label>
                     <div class="col-lg-8">
                       <input
                         class="form-control m-input"
                         data-val="true"
                         data-val-remote="Title already exist."
                         data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                         data-val-required="Please enter title"
                         id="Subject"
                         name="Subject"
                         placeholder="Enter Subject"
                   
                         type="text"
                       />
                  
                     </div>
                   </div>

                   <div class="row">
                     <label class="col-lg-2 col-form-label text-left">
                       Body
                     </label>
                     <div class="col-lg-8">
                       <textarea
                         class="form-control m-input"
                         data-val="true"
                         id="Body"
                         name="Body"
                         placeholder="Enter Body"
                         type="textarea"
                       >
                         {" "}
                       </textarea>
          
                     </div>
                   </div>
                 </div>
                 <div className="modal-content bordernone text-right">
                   <div class="row mb-4">
                     <div class="col-lg-12 pull-right">
                       <a
                         id="backtolist"
                         class="btn btn-secondary mr-2"
                         onClick={() => {this.setState({SendEmailPopup:false})}}
                       >
                         cancel
                       </a>
                       <a
                         id="submit"
                         class="btn btn-primary btn-lightgreen mr-1"
                         href="javascript:void(0);"
                         value="Save"
                       >
                         <i class="la la-save"></i> Save
                       </a>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
    
                      </Popup>
                      <li>
                        <button
                         disabled={this.state.BtnDisabled}
                          onClick={this.ContactUpdate}
                          className="btn btngroup m-btn"
                        >
                          <i class="fa fa-save la-icon-font-size-13"></i>
                          <span>Save</span>
                        </button>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            this.DeleteBtnContact(this.state.ProspectIDData);
                          }}
                          className="btn btngroup m-btn"
                        >
                          <i class="flaticon-delete-1 la-icon-font-size-13"></i>
                          <span>Delete</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            this.ContactAddToBlackList(this.state.Email);
                          }}
                          className="btn btngroup m-btn"
                        >
                          <i class="fa la-icon-font-size-13 fa-ban la-icon-font-size-13"></i>
                          <span>Add to Blacklist</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            this.ContactBackBtn();
                          }}
                          className="btn btngroup m-btn borderbtn"
                        >
                          <i class="la la-arrow-circle-left la-icon-font-size-13"></i>
                          <span>Back</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className="float-left addpotential_header">Contact Info</h3>
              </div>
              </div>
              <div className="row py-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Account Name
                    </label>
                    <label className="col-lg-4 view-lable-meet potential_text blackcol">
                      <a href=""></a>
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                    
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
          
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalFields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                    
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
   
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Name Long :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="CompanyNameLong"
                        name="CompanyNameLong"
                        type="text"

                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        id="Website"
                        name="Website"
                        type="text"
                        value={this.state.Fields["Website"]}
                        onChange={this.HandleChange.bind(this, "Website")}
                      />
                                  <span style={{ color: "red" }}>
                        {this.state.errors["Website"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInURL"
                        name="LinkedInURL"
                        type="text"
                      
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Industry
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Industry"
                        type="text"
                        id="Industry"
                        onChange={this.HandleChange.bind(this, "Industry")}
                        value={this.state.Fields["Industry"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Address 1 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Address1"
                        type="text"
                        id="Address1"
                        value={this.state.Fields["Address1"]}
                        onChange={this.HandleChange.bind(this, "Address1")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Address 2 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Address2"
                        type="text"
                        id="Address2"
                        value={this.state.Fields["Address2"]}
                        onChange={this.HandleChange.bind(this, "Address2")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      City :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="City"
                        id="City"
                        type="text"
                        value={this.state.Fields["City"]}
                        onChange={this.HandleChange.bind(this, "City")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      State :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="State"
                        id="State"
                        type="text"
                        value={this.state.Fields["State"]}
                        onChange={this.HandleChange.bind(this, "State")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Zip :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Zip"
                        id="Zip"
                        type="text"
                        value={this.state.Fields["Zip"]}
                        onChange={this.HandleChange.bind(this, "Zip")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Country :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Country"
                        id="Country"
                        type="text"
                        value={this.state.Fields["Country"]}
                        onChange={this.HandleChange.bind(this, "Country")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Account Category :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="AccountCategory"
                        type="text"
                        id="AccountCategory"
                        value={this.state.Fields["AccountCategory"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "AccountCategory"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Category :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="ContactCategory"
                        type="text"
                        id="ContactCategory"
                        value={this.state.Fields["ContactCategory"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "ContactCategory"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Source :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactSource"
                        id="ContactSourceID"
                        value={this.state.ContactSourceDefaultSelect}
                        onChange={(e) =>
                          this.HandleDropdownChangeContactSource(e)
                        }
                      >
                        {this.state.ContactSourceData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Owner :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactOwnerID"
                        id="ContactOwnerID"
                        value={this.state.ContactOwnerDefaultSelect}
                        onChange={(e) =>
                          this.HandleDropdownChangeContactOwner(e)
                        }
                      >
                        {this.state.ContactOwnerData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Client POC :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ClientPOCID"
                        id="ClientPOCID"
                        value={this.state.ClientPOCDefaultSelect}
                        onChange={(e) => this.HandleDropdownChangeClientPOC(e)}
                      >
                        {this.state.ClientPOCData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Reason Unqualified :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ReasonUnqualifiedID"
                        id="ReasonUnqualifiedID"
                        value={this.state.ReasonDefaultSelect}
                        onChange={(e) =>
                          this.HandleDropdownChangeReasonUnqualified(e)
                        }
                      >
                        {this.state.ReasonUnqualifiedData.map((value) => (
                          <option value={value._id}>
                            {value.Reason}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Mobile Phone :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="MobilePhone"
                        name="MobilePhone"
                        type="text"
                        value={this.state.Fields["MobilePhone"]}
                        onChange={this.HandleChange.bind(this, "MobilePhone")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="DirectPhone"
                        type="text"
                        value={this.state.Fields["DirectPhone"]}
                        onChange={this.HandleChange.bind(this, "DirectPhone")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="DirectPhoneExt"
                        type="text"
                        value={this.state.Fields["DirectPhoneExt"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "DirectPhoneExt"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyPhone"
                        type="text"
                        value={this.state.Fields["CompanyPhone"]}
                        onChange={this.HandleChange.bind(this, "CompanyPhone")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Status :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactSource"
                        id="ContactStatusID"
                        value={this.state.ContactStatusDefaultSelect}
                        onChange={(e) =>
                          this.HandleDropdownChangeContactStatus(e)
                        }
                      >
                        {this.state.ContactStatusData.map((value) => (
                          <option value={value._id}>
                            {value.Status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Revenue :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyRevenue"
                        type="text"
                        value={this.state.Fields["CompanyRevenue"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "CompanyRevenue"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Employee Count :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyEmployeeCount"
                        type="text"
                        value={this.state.Fields["CompanyEmployeeCount"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "CompanyEmployeeCount"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Tag :
                    </label>
                    <div className="col-lg-7">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={this.state.contactoptions}
                        isMulti
                        onChange={(e) => this.h2Change(e)}
                        value={this.state.defaultTagArray && this.state.defaultTagArray[0] ? this.state.defaultTagArray : null}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Follow Up Date :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="FollowUpDate"
                        type="text"
                        value={this.state.Fields["FollowUpDate"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Sales Development Reps :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactSource"
                        id="ContactSourceID"
                        value={this.state.ContactSourceDefaultSelect}
                        onChange={(e) =>
                          this.HandleDropdownChangeContactSource(e)
                        }
                      >
                        {this.state.ContactSalesDevlopMenData.map((value) => (
                          <option value={value._id}>
                            {value.ColdCallersName} {value.LastName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Sales Rep :
                    </label>

                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="SalesRep"
                        type="text"
                        value={this.state.Fields["SalesRep"]}
                        onChange={this.HandleChange.bind(this, "SalesRep")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Other Phone 1 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="OtherPhone1"
                        type="text"
                        value={this.state.Fields["OtherPhone1"]}
                        onChange={this.HandleChange.bind(this, "OtherPhone1")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Other Phone 2 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="OtherPhone2"
                        type="text"
                        value={this.state.Fields["OtherPhone2"]}
                        onChange={this.HandleChange.bind(this, "OtherPhone2")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Account ID :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalAccountID"
                        type="text"
                        value={this.state.Fields["ExternalAccountID"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "ExternalAccountID"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Contact ID :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalContactID"
                        type="text"
                        onChange={this.HandleChange.bind(
                          this,
                          "ExternalContactID"
                        )}
                        value={this.state.Fields["ExternalContactID"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 1 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField1"
                        value={this.state.Fields["ExternalField1"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "ExternalField1"
                        )}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 2 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField2"
                        type="text"
                        value={this.state.Fields["ExternalField2"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "ExternalField2"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 3 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField3"
                        value={this.state.Fields["ExternalField3"]}
                        onChange={this.HandleChange.bind(
                          this,
                          "ExternalField3"
                        )}
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group"></div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Do Not Call Contact :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class=""
                        id="IsDoNotCallContact"
                        onChange={(e) => this.HandleChangeCheckIsDoNotCall(e)}
                        type="checkbox"
                        defaultChecked={this.state.IsDoNotCallContactData}
                      />
                    </div>
                  </div>
                </div>

                {/* custom column field */}
                {this.state.ContactCustomFieldArray?.map((value1, index) => {
                  const customfield = this.state.ProspectCustomFieldByIdArray?.find(
                    (val) => val.CustomFieldID === value1._id
                  )
                  return (
                    <>
                    <div className="col-lg-12 pt-4 padd-0 d-flex align-items-center p-1">
                    <h3 className=" float-left addpotential_header">
                      Custom Fields
                    </h3>
                  </div>
                    <div className="col-lg-6 py-1  ">
                      <div
                        className="row max-cols d-flex align-items-center"
                        key={index}
                      >
                        <label className="col-lg-4">{value1.FieldName}:</label>
                        <div className="col-lg-7">
                                <input
                                  className="form-control m-input"
                                  id={value1?._id}
                                  defaultValue={customfield?.FieldValue}
                                  type="text"
                                />
                              </div>
                      </div>
                    </div>
                    </>
                  );
                })}
              </div>
            </div>
         
 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Contact Status{" "}
                </h3>
              </div>
            </div>
            {this.state.ContactData.map((value) => (
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Created Date :
                    </label>
                    <div className="col-lg-7">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        {moment(
                          new Date(value?.CreatedDate).toDateString()
                        ).format("DD/MM/YYYY")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Validation Date :
                    </label>
                    <div className="col-lg-7">
                      <label className="col-lg-4">
                        {moment(
                          new Date(value?.FollowUpDate).toDateString()
                        ).format("DD/MM/YYYY")}
                      </label>
                    </div>
                  </div>
                </div>
                {this.state?.ContactStatusDData.map((valstats) => (
                  <>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Email Send Date :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats.TotalSentDate?.MailSentDate != null
                              ? moment(
                                  new Date(
                                    valstats.TotalSentDate?.MailSentDate
                                  ).toDateString()
                                ).format("DD/MM/YYYY")
                              : ""}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Open Date :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats.TotalSentDate?.OpenedDateByTimeZone !=
                            null
                              ? moment(
                                  new Date(
                                    valstats.TotalSentDate?.OpenedDateByTimeZone
                                  ).toDateString()
                                ).format("DD/MM/YYYY")
                              : ""}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Response Date :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats.TotalSentDate?.RepliedDateByTimeZone !=
                            null
                              ? moment(
                                  new Date(
                                    valstats.TotalSentDate?.RepliedDateByTimeZone
                                  ).toDateString()
                                ).format("DD/MM/YYYY")
                              : ""}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Direct Mail Date :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats.TotalSentDate?.DateOfSendMail != null
                              ? moment(
                                  new Date(
                                    valstats.TotalSentDate?.DateOfSendMail
                                  ).toDateString()
                                ).format("DD/MM/YYYY")
                              : ""}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Letter Date :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4"></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Status Change :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4"></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Is Opted Out :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {value?.IsOptedOut == true ? "Yes" : "No"}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Is Blacklisted :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {value?.IsContatcBlackListed == true ? "Yes" : "No"}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Blacklist Date :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4"></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          On Global Bounce List :
                        </label>
                        <div className="col-lg-7">
                          {value?.IsGlobalBounce == true ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Total Email Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats?.TrackedEmailCount}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Total Touches :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                          {value.EmailSend?.Delivers == null ? 0 : value.EmailSend?.Delivers}

                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Tracked Email Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats?.TrackedEmailCount}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Open Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {value.EmailSend?.Opens == null ? 0 : value.EmailSend?.Opens}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Reply Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                          {value.EmailSend?.Replies == null ? 0 : value.EmailSend?.Replies}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Touches Before First Reply :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                          {value.EmailSend?.Replies == null ? 0 : value.EmailSend?.Replies}

                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Removed Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">{value?.OptOutsCount == null ? 0 : value?.OptOutsCount}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Soft Bounce Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                          {value.EmailSend?.SoftBounce == null ? 0 : value.EmailSend?.SoftBounce}

                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Hard Bounce Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                          {value.EmailSend?.HardBounce == null ? 0 : value.EmailSend?.HardBounce}

                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Letter Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4"></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Direct Mail Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4"></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Email Campaign :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats.TotalLastEMailData?.MailFrom}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Email Step :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">
                            {valstats.TotalLastEMailData?.StepNumber}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          View Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">0</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Call Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="col-lg-4">{value?.CallCount == null ? 0 : value?.CallCount}</label>
                        </div>
                      </div>
                    </div>
                  </>
                ))}

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Call Result :
                    </label>
                    <div className="col-lg-7">
                      <label className="col-lg-4">
                        {value?.LastCallResult == null ? "" : value?.LastCallResult}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Call Date :
                    </label>
                    <div className="col-lg-7">
                      <label className="col-lg-4">
                        {moment(
                          new Date(value?.FollowUpDate).toDateString()
                        ).format("DD/MM/YYYY")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> 
        {/* Call Notes Table */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Call Notes
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="search"
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Call Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                     <p className="text-center">No data available in table</p> 
                  {/* {
                 this.state.ContactData?.map((row) => {
                  row.CallNotes == null ? ( <p className="text-center">No data available in table</p>):
                        <TableRow>
                          <TableCell><p className="text-center">No data available in table</p></TableCell>
                        </TableRow>
                 })
                  } */}
                    {/* {this.state.ContactData &&
                    this.state.ContactData?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.ContactData?.map((row) => (
                        <TableRow>
                          <TableCell>{row.CallNotes}</TableCell>
                        </TableRow>
                      ))
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.Sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.CountPage}
                  onChange={this.HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries (filtered from{" "}
                    {this.state.Flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CountPage}
                    onChange={this.HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 
        {/* Notes Table */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
              </div>
              <div className="col text-right pr-0">
              <button onClick={() => {this.setState({ShowNotePopup:true})}} className="btn btngroup m-btn ">
                      <i class="la la-plus la-icon-font-size-13"></i>
                      <span className="Mobile_button">Add</span>
                    </button>
                <Popup open={this.state.ShowNotePopup}>
           
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-3 px-3">
                            <h4 className="mb-0">Note</h4>
                          </div>
                          <div className="modal-body p-5">
                            <div class="row mb-3">
                              <label class="col-lg-2 col-form-label text-left">
                                Title
                              </label>
                              <div class="col-lg-10">
                                <input
                                  class="form-control m-input"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter title"
                                  id="TitlePNote"
                                  name="TitleNote"
                                  placeholder="Enter title"
                                  onBlur={this.HandleChangePNotesAdd.bind(
                                    this,
                                    "TitlePNote"
                                  )}
                                  type="text"
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["TitlePNote"]}
                                </span>
                                {this.state.TitleAvailableNotes && (
                                  <span style={{ color: "red" }}>
                                    Title already exist.
                                  </span>
                                )}
                              </div>
                            </div>

                            <div class="row">
                              <label class="col-lg-2 col-form-label text-left">
                                Note
                              </label>
                              <div class="col-lg-10">
                                <textarea
                                  class="form-control m-input minheight390"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-required="Please enter title"
                                  id="NotesPNote"
                                  name="NotesPNote"
                                  placeholder="Enter note"
                                  type="text"
                                >
                                  {" "}
                                </textarea>
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["NotesPNote"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="modal-content bordernone text-right">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={() => {this.setState({ShowNotePopup:false})}}
                                >
                                  cancel
                                </a>
                                <a
                                  id="submitSave"
                                  class="btn btn-primary btn-lightgreen mr-1"
                                  href="javascript:void(0);"
                                  value="Save"
                                  onClick={this.ProspectNoteSave}
                                >
                                  <i class="la la-save"></i> Save
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
             
                </Popup>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    onChange={this.ChangeRowSelected}
                    className="form-control form-control-sm"
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="SearchNote"
                    onKeyPress={(event) => this.RequestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.NoteSortData("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.NoteSortData("Note");
                          }}
                        >
                          Notes
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.NoteSortData("Type");
                          }}
                        >
                          Type
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.NoteSortData("CreatedDate");
                          }}
                        >
                          Created Date
                        </a>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.NoteRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.NoteRows?.map((row) => (
                        <TableRow>
                          <TableCell>{row?.Title}</TableCell>
                          <TableCell>{row?.Note}</TableCell>
                          <TableCell>{row?.PageName}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row?.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.ProspectNoteEditBtn(row._id,row.PageName);
                              }}
                            >
                              <Popup
                                trigger={
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-3 px-3">
                                          <h4 className="mb-0">Note</h4>
                                        </div>
                                        <div className="modal-body p-5">
                                          <div class="row mb-3">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Title
                                            </label>
                                            <div class="col-lg-10">
                                              <input
                                                class="form-control m-input"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                data-val-required="Please enter title"
                                                id="TitleEditNote"
                                                name="TitleEditNote"
                                                placeholder="Enter title"
                                                type="text"
                                                onBlur={this.HandleChangePNotesEdit.bind(
                                                  this,
                                                  "TitleEditNote"
                                                )}
                                              
                                              />
                                              <span style={{ color: "red" }}>
                                                {
                                                  this.state.Errors1[
                                                    "TitleEditNote"
                                                  ]
                                                }
                                              </span>
                                              {this.state.TitleAvailableNotes && (
                                                <span style={{ color: "red" }}>
                                                  Title already exist.
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Note
                                            </label>
                                            <div class="col-lg-10">
                                              <textarea
                                                class="form-control m-input minheight390"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-required="Please enter title"
                                                id="NotesEditPNotes"
                                                name="NotesEditPNotes"
                                                placeholder="Enter note"
                                                type="text"
                                                value={
                                                  this.state.Fields[
                                                    "NotesEditPNotes"
                                                  ]
                                                }
                                              >
                                                {" "}
                                              </textarea>
                                              <span style={{ color: "red" }}>
                                                {
                                                  this.state.Errors1[
                                                    "NotesEditPNotes"
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-content bordernone text-right">
                                          <div class="row">
                                            <div class="col-lg-12 pull-right">
                                              <a
                                                id="backtolist"
                                                class="btn btn-secondary mr-2"
                                                onClick={() => {
                                                  close();
                                                }}
                                              >
                                                cancel
                                              </a>
                                              <a
                                                id="submitupdate"
                                                class="btn btn-primary btn-lightgreen mr-1"
                                                href="javascript:void(0);"
                                                value="Save"
                                                onClick={(e) => { this.ProspectNoteUpdate(e); close(); }}
                                              >
                                                <i class="la la-save"></i> Save
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </a>
                            <a
                              className="btn-eyesicon"
                              onClick={() => {
                                this.ProspectNoteDelete(row._id,row.PageName);
                              }}
                            >
                              <i class="la flaticon-delete-1 delete-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.NoteSflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.NoteCountPage}
                  onChange={this.HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.NoteRlen == 0
                      ? 0
                      : (this.state.NotePage - 1) * this.state.NoteRowsPerPage + 1} to{" "}
                    {this.state.NotePage * this.state.NoteRowsPerPage > this.state.NoteRlen
                      ? this.state.NoteRlen
                      : this.state.NotePage * this.state.NoteRowsPerPage} of {this.state.NoteRlen}{" "} entries (filtered from {this.state.NoteFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.NoteRlen == 0
                      ? 0
                      : (this.state.NotePage - 1) * this.state.NoteRowsPerPage + 1} to{" "}
                    {this.state.NotePage * this.state.NoteRowsPerPage > this.state.NoteRlen
                      ? this.state.NoteRlen
                      : this.state.NotePage * this.state.NoteRowsPerPage}
                    of {this.state.NoteRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.NoteCountPage}
                    onChange={this.HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 
        {/* Potential meeting */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 align-items-center">
                <h3 className="float-left pb-4 addpotential_header">
                  Potential Meetings
                </h3>
              </div>
              <div className="col text-right pr-0">
              <button className="btn btngroup m-btn ">
                <i class="la la-plus la-icon-font-size-13"></i>
                <span
                  className="Mobile_button"
                  onClick={() => {
                    this.PotentialMeetingsAdd();
                  }}
                >
                  Add
                </span>
              </button>
            </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedPotentialMeeting}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchpotentialmeet"
                    onKeyPress={(event) =>
                      this.RequestSearchPotentialMeeting(event)
                    }
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("FirstName");
                          }}
                        >
                          Contact Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("Email");
                          }}
                        >
                          Email
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("Company");
                          }}
                        >
                          Company
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("EngagamentStatusID");
                          }}
                        >
                          Enagement Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("MeetingSourceID");
                          }}
                        >
                          Meeting Sources
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("MeetingOwnerID");
                          }}
                        >
                          Owner
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData(
                              "ResponderEmailAccount"
                            );
                          }}
                        >
                          Remainder Email Account
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.PotentialMeetingSortData("CreatedDate");
                          }}
                        >
                          Created Date
                        </a>
                      </TableCell>
                      <TableCell>
                      Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.PotentialMeetingData &&
                    this.state.PotentialMeetingData?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.PotentialMeetingData &&
                      this.state.PotentialMeetingData.map((row34) => (
                        <>
                        <TableRow>
                        <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                
                                onClick={() => this.toggleMeeting(row34?._id)}
                              >
                              {this.state.Open[row34?._id] ? (
                  <i className="fa fa-minus-circle plusbl" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-plus-circle minusl"></i>
                )}
                              </IconButton>
                            </TableCell>
                          <TableCell>{row34?.ContactName}</TableCell>
                          <TableCell>{row34?.Email}</TableCell>
                          <TableCell>{row34?.Company}</TableCell>
                          <TableCell>{row34?.Title}</TableCell>
                          <TableCell>{row34?.PotentialMeetingStatusName}</TableCell>
                          <TableCell>{row34?.MeetingSourceName}</TableCell>
                          <TableCell>{row34?.MeetingOwnerName}</TableCell>
                          <TableCell>{row34?.ReminderEmailAccount}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row34?.CreatedDatee).toDateString()
                            ).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a  className="btn-eyesicon"
                              onClick={() => {
                                this.PotentialMeetingsEdit(row34.PotentialMeetingID);
                              }}
                            ><i class="la flaticon-edit-1 edit-icon"></i></a>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={12}
                            >
                          <Collapse
      in={this.state.Open[row34?._id]}
      timeout="auto"
      unmountOnExit
    >
      {this.state.Open[row34?._id] && (
        <Box margin={1} className="innertables">
          {this.state.PMNotes.length > 0 ? (
            <Table size="small" aria-label="purchases">
              <TableHead></TableHead>
              {this.state.PMNotes.map((note) =>
                note.PotentialMeetingID === row34._id ? (
                  <TableRow>
                    <TableCell><strong>{note?.Title}</strong></TableCell>
                    <TableCell> <strong>{moment(
                      new Date(note?.CreatedDate).toDateString()
                    ).format("MM/DD/YYYY")}</strong>      </TableCell>
                    <TableCell scope="row">
                      {note.Note}
                    </TableCell>
                    <TableCell
                      className="labeltds"
                      scope="row"
                    ></TableCell>
                    <TableCell
                      className="labeltds"
                      scope="row"
                    ></TableCell>
                  </TableRow>
                ) : null
              )}

              {!this.state.PMNotes.some(
                                  (note) =>
                                    note?.PotentialMeetingID === row34?._id
                                )}
            </Table>
          ) : (
            <p className="text-center">No data available in table</p>
          )}
        </Box>
      )}
    </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.PMSflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.PMCountPage}
                  onChange={this.HandleChangePagePotentialMeeting}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.PMRlen == 0
                      ? 0
                      : (this.state.PMPage - 1) * this.state.PMRowsPerPage +
                        1}{" "}
                    to{" "}
                    {this.state.PMPage * this.state.PMRowsPerPage >
                    this.state.PMRlen
                      ? this.state.PMRlen
                      : this.state.PMPage * this.state.PMRowsPerPage}{" "}
                    of {this.state.PMRlen} entries (filtered from{" "}
                    {this.state.PMFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.PMRlen == 0
                      ? 0
                      : (this.state.PMPage - 1) * this.state.PMRowsPerPage +
                        1}{" "}
                    to{" "}
                    {this.state.Page * this.state.PMRowsPerPage >
                    this.state.PMRlen
                      ? this.state.PMRlen
                      : this.state.PMPage * this.state.PMRowsPerPage}{" "}
                    of {this.state.PMRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.PMCountPage}
                    onChange={this.HandleChangePagePotentialMeeting}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* Meeting Table*/}
 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Meetings
                </h3>
              </div>

              <div className="col text-right px-0">
              <button className="btn btngroup m-btn ">
                <i class="la la-plus la-icon-font-size-13"></i>
                <span
                  className="Mobile_button"
                  onClick={() => {
                    this.MeetingsAdd();
                  }}
                >
                  Add
                </span>
              </button>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedMeeting}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchMeeting"
                    onKeyPress={(event) => this.RequestSearchMeeting(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("FirstName");
                          }}
                        >
                          Contact Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("Email");
                          }}
                        >
                          Email
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("Company");
                          }}
                        >
                          Company
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("Status");
                          }}
                        >
                          Meeting Status
                        </a>{" "}
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("MeetingSourceName");
                          }}
                        >
                          {" "}
                          Meeting Sources
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("MeetingOwnerName");
                          }}
                        >
                          Owner
                        </a>{" "}
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("EventStartDt");
                          }}
                        >
                          Meeitng Date
                        </a>{" "}
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.MeetingSortData("CreatedDt");
                          }}
                        >
                          Meeting Set Date
                        </a>{" "}
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state?.MeetingData &&
                    this.state.MeetingData?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state?.MeetingData &&
                      this.state?.MeetingData.map((row34) => (
                       <>
               
                        <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleMeeting1(row34._id)}
                 
                              >
                                {this.state.Open[row34._id] ? (
                                  <i
                                    className="fa fa-minus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                          <TableCell>{row34?.FirstName}</TableCell>
                          <TableCell>{row34?.Email}</TableCell>
                          <TableCell>{row34?.Company}</TableCell>
                          <TableCell>{row34?.Title}</TableCell>
                          <TableCell>{row34?.Status}</TableCell>
                          <TableCell>{row34?.MeetingSourceName}</TableCell>
                          <TableCell>{row34?.MeetingOwnerName}</TableCell>
                          <TableCell>
                    
                          {row34?.EventStartDt != null
                              ? moment(row34?.EventStartDtByTimeZone)
                          .format("DD/MM/YYYY hh:mm A")
                              : ""}

  
                          </TableCell>
                          <TableCell>
                          {""}
                          </TableCell>
                          <TableCell>
                            <a  className="btn-eyesicon"
                              onClick={() => {
                                this.MeetingsEdit(row34?._id);
                              }}
                            ><i class="la flaticon-edit-1 edit-icon"></i></a>
                          </TableCell>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={12}
                            >
                              <Collapse
                                in={this.state.Open[row34._id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {this.state.Open[row34._id] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {this.state.MNotes.map((note) =>
                                        note?.MeetingID ==
                                        row34._id ? (
                                          <TableRow>
                                            <TableCell><strong>{note?.Title}</strong></TableCell>
                                            <TableCell> <strong>{moment(
                                new Date(note?.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}</strong>      </TableCell>
                                            <TableCell scope="row">
                                           {note.Note}
                                            </TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                          </TableRow>
                                        ) : null
                                      )}
                                      {!this.state.MNotes.some(
                                  (note) =>
                                    note?.MeetingID === row34._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                  
                       </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.MSflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.MCountPage}
                  onChange={this.HandleChangePageMeeting}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.MRlen == 0
                      ? 0
                      : (this.state.MPage - 1) * this.state.MRowsPerPage +
                        1}{" "}
                    to{" "}
                    {this.state.MPage * this.state.MRowsPerPage >
                    this.state.MRlen
                      ? this.state.MRlen
                      : this.state.MPage * this.state.MRowsPerPage}{" "}
                    of {this.state.MRlen} entries (filtered from{" "}
                    {this.state.MFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.MRlen == 0
                      ? 0
                      : (this.state.MPage - 1) * this.state.MRowsPerPage +
                        1}{" "}
                    to{" "}
                    {this.state.Page * this.state.MRowsPerPage >
                    this.state.MRlen
                      ? this.state.MRlen
                      : this.state.MPage * this.state.MRowsPerPage}{" "}
                    of {this.state.MRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.MCountPage}
                    onChange={this.HandleChangePageMeeting}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* Campaign History Table*/} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Campaign History
                </h3>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedCampaignHistory}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchcampaigns"
                    onKeyPress={(event) =>
                      this.RequestSearchCampaignHistory(event)
                    }
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.CampaignHistoryData &&
                    this.state.CampaignHistoryData.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.CampaignHistoryData &&
                      this.state.CampaignHistoryData.map((row34) => (
                        <TableRow>
                          <TableCell>{row34?.CampaignName}</TableCell>
                          <TableCell>
                          {moment(
                              new Date(row34?.CreatedDate).toDateString()
                            ).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a  className="btn-eyesicon"
                              onClick={() => {
                                this.EditCampaignHistory(row34.CampaignID);
                              }}
                            ><i class="la flaticon-edit-1 edit-icon"></i></a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
         
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.CAMSflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.CAMPage}
                  onChange={this.HandleChangePageCampaignHistory}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CAMRlen == 0
                      ? 0
                      : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        0}{" "}
                    to{" "}
                    {this.state.CAMPage * this.state.CAMRowsPerPage >
                    this.state.CAMRlen
                      ? this.state.CAMRlen
                      : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                    of {this.state.CAMRlen} entries (filtered from{" "}
                    {this.state.CAMFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CAMRlen == 0
                      ? 0
                      : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        0}{" "}
                    to{" "}
                    {this.state.CAMPage * this.state.CAMRowsPerPage >
                    this.state.CAMRlen
                      ? this.state.CAMRlen
                      : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                    of {this.state.CAMRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CAMPage}
                    onChange={this.HandleChangePageCampaignHistory}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 
        {/* Lists */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Lists</h3>
              </div> 
              <div className="col text-right pr-0">
                <button className="btn btngroup m-btn ">
                  <i class="la la-plus la-icon-font-size-13"></i>
                  <span
                    className="Mobile_button"
                    onClick={() => {
                      this.ListsAdd();
                    }}
                  >
                    Add
                  </span>
                </button>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedList}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchlist"
                    onKeyPress={(event) => this.RequestSearchList(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.ListsSortData("Name");
                          }}
                        >
                          Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.ListsSortData("Created Date");
                          }}
                        >
                          Created Date
                        </a>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.ListRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.ListRows?.map((row34) => (
                        <TableRow>
                          <TableCell>{row34?.Name}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row34?.CreatedDate).toDateString()
                            ).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.ListsEdit(row?._id);
                              }}
                            ></a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.SflagL ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.LCountPage}
                  onChange={this.HandleChangePageList}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.LRlen == 0
                      ? 0
                      : (this.state.PageL - 1) * this.state.RowsPerPageL +
                        1}{" "}
                    to{" "}
                    {this.state.PageL * this.state.RowsPerPageL >
                    this.state.LRlen
                      ? this.state.LRlen
                      : this.state.PageL * this.state.RowsPerPageL}{" "}
                    of {this.state.LRlen} entries (filtered from{" "}
                    {this.state.LFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.LRlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.LRlen
                      ? this.state.LRlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.LRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.LCountPage}
                    onChange={this.HandleChangePageList}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* Contact status history */} 
          <div className="paddcols p-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Contact Status History
                </h3>
              </div>
              <div className="col text-right"></div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedContactStatusHistory}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="Search"
                    id="HSearch"
                    onKeyPress={(event) =>
                      this.RequestSearchContactHistory(event)
                    }
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.ContactStatusHistorySortData("Status");
                          }}
                        >
                          Old Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.ContactStatusHistorySortData("Status");
                          }}
                        >
                          New Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.ContactStatusHistorySortData("ChangedDate");
                          }}
                        >
                          Change Date
                        </a>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.HRows.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.HRows.map((row) => (
                        <TableRow>
                          <TableCell>{row.p2[0]?.Status}</TableCell>
                          <TableCell>{row.p1[0]?.Status}</TableCell>
                          <TableCell>{
                            row?.ChangedDate == null ? "" :   moment(
                              new Date(row?.ChangedDate).toDateString()
                            ).format("MM/DD/YYYY")
                          }
                          
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.HSflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  page={this.state.HPage}
                  count={this.state.HCountPage}
                  onChange={this.HandleChangePageContactStatusHistory}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.HRlen == 0
                      ? 0
                      : (this.state.HPage - 1) * this.state.HRowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.HPage * this.state.HRowsPerPage > this.state.HRlen
                      ? this.state.HRlen
                      : this.state.HPage * this.state.HRowsPerPage}{" "}
                    of {this.state.HRlen} entries (filtered from{" "}
                    {this.state.HFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.HRlen == 0
                      ? 0
                      : (this.state.HPage - 1) * this.state.HRowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.HPage * this.state.HRowsPerPage > this.state.HRlen
                      ? this.state.HRlen
                      : this.state.HPage * this.state.HRowsPerPage}{" "}
                    of {this.state.HRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                  page={this.state.HPage}
                    count={this.state.HCountPage}
                    onChange={this.HandleChangePageContactStatusHistory}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* Call history Table*/}
 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Call History
                </h3>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedCallHistory}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchcallhistory"
                    onKeyPress={(event) => this.RequestSearchCallHistory(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData("Name");
                          }}
                        >
                          Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData("Company");
                          }}
                        >
                          Company
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData("Duration");
                          }}
                        >
                          Duration
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData("Status");
                          }}
                        >
                          Result
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData(
                              "RecordingFileCreatedDate"
                            );
                          }}
                        >
                          Call Time / Date
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData("RecordingFileName");
                          }}
                        >
                          Recording
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.CallHistorySortData("Note");
                          }}
                        >
                          Note
                        </a>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state?.CallHistoryData &&
                    this.state.CallHistoryData.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state?.CallHistoryData &&
                      this.state?.CallHistoryData.map((row34) => (
                        <TableRow>
                          <TableCell>{row34?.Name}</TableCell>
                          <TableCell>{row34.ProspectDetails?.Title}</TableCell>
                          <TableCell>
                            {row34.ProspectDetails?.Company}
                          </TableCell>
                          <TableCell>{row34?.Duration}</TableCell>
                          <TableCell>{row34?.Status}</TableCell>
                          <TableCell>
                            {row34?.RecordingFileCreatedDate}
                          </TableCell>
                          <TableCell>{row34?.RecordingFileName}</TableCell>
                          <TableCell>{row34?.Note}</TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.MSflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.CCountPage}
                  onChange={this.HandleChangePageCallHistory}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CRlen == 0
                      ? 0
                      : (this.state.CPage - 1) * this.state.CRowsPerPage +
                        1}{" "}
                    to{" "}
                    {this.state.CPage * this.state.CRowsPerPage >
                    this.state.CRlen
                      ? this.state.CRlen
                      : this.state.CPage * this.state.CRowsPerPage}{" "}
                    of {this.state.CRlen} entries (filtered from{" "}
                    {this.state.CFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CRlen == 0
                      ? 0
                      : (this.state.CPage - 1) * this.state.CRowsPerPage +
                        1}{" "}
                    to{" "}
                    {this.state.Page * this.state.CRowsPerPage >
                    this.state.CRlen
                      ? this.state.CRlen
                      : this.state.CPage * this.state.CRowsPerPage}{" "}
                    of {this.state.CRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CCountPage}
                    onChange={this.HandleChangePageCallHistory}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 


         {/* Contact Activity */} 
          <div class="paddcols px-4 pb-4 mt-3">
            <div class="row">
              <div class="col-md-12">
                <h4 className="headertitlebd pl-0">Contact Activity</h4>
              </div>
            </div>
            {this.state?.HRows?.map((item) => {
              const changedDate = item?.ChangedDate ? new Date(item?.ChangedDate).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }) : '';
              return (
                <div class="row">
                  <div class="col-md-1">
                    <span>
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </span>
                  </div>

                  <div class="col-md-8">
                    <b>Prospect Status</b> Update : -{" "}
                    <b>{item.p1[0]?.Status}</b>
                  </div>
                  <div class="col-md-3">{changedDate}</div>
                </div>
              );
            })}
            <div class="row">
              <div class="col-md-1">
                <span>
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </span>
              </div>

              <div class="col-md-8">
                <b>Contact</b> Created via : - <b> List Upload</b>
              </div>
              <div class="col-md-3">08/30/2022 03:58 AM</div>
            </div>
          </div>
        </div>
      </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditContactPage = connect(
  mapState,
  actionCreators
)(CEditContactPage);
export { connectedCEditContactPage as CEditContactPage };
