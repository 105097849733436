const home = process.env.REACT_APP_HOME;
const database = process.env.REACT_APP_DATABASE;

//Google API email Authantication Start
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_KEY = process.env.REACT_APP_CLIENT_KEY
//Google API Ends

//Google Scope Start
const SCOPE=process.env.REACT_APP_SCOPE
//Google Scope End

// redirect url start
const REDIRECT_URL= database+"/emailaccount/AuthCallback"
// redirect url end
export const CommonConstants = {
    MOL_APIURL: database,
    enckey:'secret key 123',
    home:home,
    resetpass: home +'resetpassword',
    Token_timer:3,  //minitues
    RememberMe_timer:3,  //minites
    Title:' | SalesHive', //Title of page
    responder_Invitation:home + 'addresponder',
    coldcallers_Invitation:home + 'addcoldcallers',
    salesstrategists_invitation:home + 'addsalesstrategists',
    usersperclient_invitation:home + 'addusersperclient',
    admin_user:home+'addadmin',
    Image_url:database + '/UserProfilePic/', // image path of server
    change_Email_responder:home + 'updateemailresponder',
    change_Email_coldcallers:home + 'updateemailcoldcallers',
    change_Email_admin:home + 'updateemailadmin',
    change_Email_salesstrategists:home + 'updateemailstrategists',
    change_Email_usersperclient:home + 'updateusersperclient',
    change_Email_timer:3, //minites
    new_admin_url:home + 'addadmin',
    new_responder_url:home + 'addresponder',
    new_coldercaller_url:home + 'addcoldcallers',
    show_rows:[15,25,50,100],  //For rows show in table
    new_sales_strategists_url:home + 'addsalesstrategists',
    CLIENT_ID:CLIENT_ID,
    CLIENT_KEY:CLIENT_KEY,
    SCOPE:SCOPE,
    REDIRECT_URL:REDIRECT_URL,
    CallRecoingURL :database + "/PhoneBurnerCallDownload/",
    CallRecordingMeetingURL : database + "/MeetingAudio/",
	  //CallRecoingURL :"https://node.saleshive.com/PhoneBurnerCallDownload/"

};

// custom variable set for common use
export const Variables = [
    { "name": "FirstName" },
    { "name": "LastName" },
    { "name": "Email" },
    { "name": "Title" },
    { "name": "Company" },
    { "name": "CompanyNameLong" },
    { "name": "Website" },
    { "name": "CompanyPhone" },
    { "name": "LinkedInURL" },
    { "name": "Industry" },
    { "name": "Address1" },
    { "name": "Address2" },
    { "name": "City" },
    { "name": "State" },
    { "name": "Zip" },
    { "name": "Country" },
    { "name": "ContactCategory" },
    { "name": "AccountCategory" },
    { "name": "Client.Company" },
    { "name": "Day" },
    { "name": "Time" },
    { "name": "Day" },
    {"name": "AliasName"},
    {"name": "AliasTitle"},
    {"name": "AliasEmail"},
    { "name": "Length" },
    { "name": "Action" },
    { "name": "SMART.BiggerThan" },
    { "name": "SMART.FasterThan" },
    { "name": "SMART.MoreExcitingThan" },
    { "name": "SMART.MascotCheer" },
    { "name": "DayOfWeek" },
    { "name": "NextLine" },
    { "name": "Source"}
    ];
