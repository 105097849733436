import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from '../../../_components/user/footer/footer';
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import { GetUserDetails } from "../../../_helpers/Utility";
import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {EmailTemplete} from '../../../_helpers/email-templete'
import { history } from "../../../_helpers";
const AccountState = {
  series: [
    {
      name: "Sent",
      color: "#302e49",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 45, 65],
    },
    {
      name: "Responses",
      color: "#e9826e",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 40, 115, 110],
    },
    {
      name: "Meetings",
      color: "#ffb963",
      data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 110],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "04/01 - 04/04",
        "04/05 - 04/11",
        "04/12 - 04/18",
        "04/19 - 04/25",
        "04/26 - 05/02",
        "05/03",
        "05/10",
        "05/17",
        "05/24",
        "06/07",
        "06/14",
        "06/21",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  },
};

class AddColdCallingContactPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fileds: {},
      errors: {},
      statusAvailable: null,
      meetingsource: [],
      potentialowner: [],
      potentialstatus: [],
      accountemail:[],
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    this.GetPotentialmeeting();
    this.GetEmailmeeting();
  }

  GetPotentialmeeting() {
    var Details = GetUserDetails();
    console.log(Details);
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      
    };
    console.log("Potential", str_in);
    const rows = Axios({
      url:
        CommonConstants.MOL_APIURL + "/potentialmeeting/findPotentialAllStatus",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      console.log(result.data);
      this.setState({
        meetingsource: result.data.meetingsource,
        potentialowner: result.data.potentialowner,
        potentialstatus: result.data.potentialstatus,
      });

      //  return result.data
    });
  }

  GetEmailmeeting() {
    var Details = GetUserDetails();
    console.log(Details);
    var str_in = {
      ClientID: Details.ClientID,
      ParentUserID:Details.ParentUserID,
      UserID: Details.ChildUserID,
    };
    console.log("Potential", str_in);
    const rows = Axios({
      url:
        CommonConstants.MOL_APIURL + "/potentialmeeting/GetAccountMeeting",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      console.log(result.data,"email details");
      this.setState({
        accountemail: result.data.Accountemail,
      });

      //  return result.data
    });
  }

  fromValidation() {
    let formIsValid = true;
    let fileds = this.state.fileds;
    let errors = {};
    if (!fileds["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = "Please Enter FirstName";
    }

    if (this.state.statusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fileds;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.FirstName != "") {
      this.state.errors.FirstName = null;
      this.state.statusAvailable = null;
      this.CheckExistPotentialMeetingName(e.target.value);
    } else {
      this.state.statusAvailable = null;
      this.state.errors.FirstName = null;
    }
  }

  CheckExistPotentialMeetingName(FirstName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FirstName: FirstName,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotentialMeetingStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  backbtn() {
    history.push("/potentialmeeting");
  }
  savebtn() {
    
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Title = document.getElementById("Title").value;
    var Company = document.getElementById("Company").value;
    var Email = document.getElementById("Email").value;
    var LinkedInURL = document.getElementById("LinkedInURL").value;
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var EngagementStatusID =document.getElementById("EngagementStatusID").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var emailflg = re.test(Email);
    if (
      FirstName == undefined ||
      LastName == undefined ||
      Title == undefined ||
      Company == undefined ||
      Email == undefined ||
      LinkedInURL == undefined ||
      MeetingSourceID == undefined ||
      MeetingOwnerID == undefined ||
      EngagementStatusID == undefined ||
      DirectPhone == undefined ||
      DirectPhoneExt == undefined ||
      CompanyPhone == undefined ||
      emailflg == false
    ) {
      console.log("Please enter data or check you email");
    } else {
      // var emailData = EmailTemplete.userEmail();

      var data = {
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Title: Title,
        Company: Company,
        LinkedInUrl: LinkedInURL,
        MeetingOwnerID: MeetingOwnerID,
        MeetingSourceID: MeetingSourceID,
        EngagamentStatusID: EngagementStatusID,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        IsDeleted: false,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
        html: emailData,
        subject: "SalesHive User Account"
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/AddPotentialMeeting",
        method: "POST",
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Potential meeting Status add successfully.",
            "Add Potential meeting Status"
          );
          history.push("/potentialmeeting");
          console.log("successfull Potential meeting", res.data);
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
    
          <div className="row mb-0">
            <div className="col">
              <h3 className=" float-left py-4 addpotential_header">
                Add SalesHive Contact 
              </h3>
            </div>
          </div>

          <div className="paddcols mb-3">
            <div className="">
              <div className="row borderbtnm ">
                <div className="col">
                  <h3 className="  float-left py-4 addpotential_header mb-0">
                  Contact Info  
                  </h3>
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                        placeholder="First name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input" 
                        name="Email"
                        type="Email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
                        placeholder="Company"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Company Name Long 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        placeholder="Company Name Long"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Website"
                        name="Website"
                        type="text" 
                        placeholder="Website"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    LinkedIn URL 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInURL"
                        name="LinkedInURL"
                        type="text"
                        placeholder="Linkedin url"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Industry 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Industry"
                        name="Industry"
                        type="text"
                        placeholder="Industry"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Address 1  
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Address1"
                        name="Address 1"
                        type="text"
                        placeholder="Address 1"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Address 2 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Address2"
                        name="Address2"
                        type="text"
                        placeholder="Address 2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    City 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="City"
                        name="City"
                        type="text"
                        placeholder="City"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    State 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="State"
                        name="State"
                        type="text"
                        placeholder="State"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Zip 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Zip"
                        name="Zip"
                        type="text"
                        placeholder="Zip"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Country 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Country"
                        name="Country"
                        type="text"
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Source
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input" 
                        id="ContactSource"
                        name="ContactSource"
                      >
                        <option value="">--Select--</option> 
                        <option value="3465">Patel</option>
                        <option value="3466">Anki</option>
                        <option value="3467">ankita</option>
                        <option value="3447">Purchased List</option>
                        <option value="3448">Manual Research</option>
                        <option value="3449">Advertising</option>
                        <option value="3450">Website</option> 
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Contact Owner 
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input" 
                        id="ContactOwner"
                        name="ContactOwner"
                      >
                        <option value="">--Select--</option>  
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Client POC 
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input" 
                        id="ClientPOC"
                        name="ClientPOC"
                      >
                        <option value="">--Select--</option>  
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Reason Unqualified 
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input" 
                        id="ReasonUnqualified"
                        name="ReasonUnqualified"
                      >
                        <option value="">--Select--</option>  
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Contact Category 
                    </label>
                    <div className="col-lg-7">
                      <input
                          className="form-control m-input"
                          id="AccountCategory "
                          name="AccountCategory "
                          type="text"
                          placeholder="Contact category"
                        />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Account Category  
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="AccountCategory "
                        name="AccountCategory "
                        type="text"
                        placeholder="Account category"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Mobile Phone 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="MobilePhone"
                        name="MobilePhone"
                        type="text"
                        placeholder="Mobile Phone"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Direct Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="DirectPhone"
                        name="DirectPhone"
                        type="text"
                        placeholder="Direct Phone"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="DirectPhoneExt"
                        name="DirectPhoneExt"
                        type="text"
                        placeholder="Direct Phone Ext" 
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Company Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        type="text"
                        placeholder="Company Phone"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Company Revenue
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="CompanyRevenue"
                        name="CompanyRevenue"
                        type="text"
                        placeholder="Company Revenue"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Employee Count
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="EmployeeCount"
                        name="EmployeeCount"
                        type="text"
                        placeholder="Employee Count"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Follow Up Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="FollowUpDate"
                        name="FollowUpDate"
                        type="text"
                        placeholder="Follow Up Date"
                      />
                    </div>
                  </div>
                </div> 

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Sales Development Reps  
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input" 
                        id="ColdCaller "
                        name="ColdCaller"
                      >
                        <option value="">--Select--</option> 
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Sales Rep 
                    </label>
                    <div className="col-lg-7">
                      <input
                          className="form-control m-input"
                          id="FollowUpDate"
                          name="FollowUpDate"
                          type="text"
                          placeholder="Sales Rep"
                        />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Tag
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Tag"
                        name="Tag"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Other Phone 1 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="OtherPhone1"
                        name="OtherPhone1"
                        type="text"
                        placeholder="Other Phone 1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Other Phone 2
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="OtherPhone2"
                        name="OtherPhone2"
                        type="text"
                        placeholder="Other Phone 2"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Follow Up Notes
                    </label>
                    <div className="col-lg-7">
                      <textarea class="form-control m-input" cols="20" id="FollowUpNotes" name="FollowUpNotes" placeholder="Follow Up Notes" rows="2" autocomplete="off"></textarea>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    External Account ID 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalContactID"
                        name="ExternalContactID"
                        type="text"
                        placeholder="External Account ID"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    External Contact ID 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalContactID"
                        name="ExternalContactID"
                        type="text"
                        placeholder="External Contact ID"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    External Field 1 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField1"
                        name="ExternalField1"
                        type="text"
                        placeholder="External Field 1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    External Field 2 
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField2"
                        name="ExternalField2"
                        type="text"
                        placeholder="External Field 2"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    External Field 3
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField3"
                        name="ExternalField3"
                        type="text"
                        placeholder="External Field 3"
                      />
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-6 boxsinput_group"> </div>
                
                <div className="col-lg-6 boxsinput_group"> 
                  <div class="m-checkbox-inline">
                    <label class="check_bx">
                        <input type="checkbox" /><span className="checkmark"></span>
                        Do Not Call Contact
                    </label> 
                  </div>
                </div>

 
              </div>
              </div>
              <div className="rowmt-4"></div> 
            </div>


            <div className="row mb-4">
                <div className="col-lg-12 pull-left">
                  <a
                    id="submit"
                    href="javascript:void(0);"
                    className="btn btn-primary btn-lightgreen mr-1"
                    value="Save"
                    onClick={this.savebtn}
                  >
                    <i className="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    className="btn btn-secondary"
                    value="Save"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

          </div>
        </div> 
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesHiveContactPage = connect(
  mapState,
  actionCreators
)(AddColdCallingContactPage);
export { connectedAddSalesHiveContactPage as AddColdCallingContactPage };
