import React from "react";
import { CommonConstants } from "../_constants/common.constants";
import { connect } from "react-redux";
import i18n from "../i18n";
import logo from "../images/SalesHive.png";
import logobgs from "../images/Loginvector.svg";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { history } from "../_helpers/history";
const Uvalidation = require("../_helpers/user-validation");
const EmailTemplete = require("../_helpers/email-templete");
import { UpdateUserDetails, GetUserDetails } from "../_helpers/Utility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Alert from '@mui/material/Alert';
import loadingicon from "../images/loading.gif";

const style={
  width:"40px",
  height:"60px",
  padding:"10px",
  "textAlign":"center",
  margin:"5px",
  "input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0"
  },
  "input[type=number]": { MozAppearance: "textfield" }
}

class TwoStepVerificationPage extends React.Component {
  constructor(props) {
    if(props.location.state?.OTP === undefined || props.location.state?.OTP === null || props.location.state?.OTP == ""){
      history.push('/login')
    }
    super(props);
    this.state = {
      OTP:this.props.location.state?.OTP,
      StorageObject:this.props.location.state?.StorageObject,
      Redirection:this.props.location.state?.Redirection,
      ErrorMessage:"",
      DisplayError:false,
      value: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: ""
    }; 
  }


  handleChange = (value1, event) => {
    this.setState({ [value1]: event.target.value.trim() });
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  //Verify OTP
  VerifyOTP = (event) => {
    event.preventDefault();
    let {otp1,otp2,otp3,otp4,otp5,otp6} = this.state;
    const mergedOtp = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
     if(mergedOtp.length < 6){
      this.setState({DisplayError:true,ErrorMessage:"Please enter OTP to login"})
     }else if(this.state.OTP !== mergedOtp){
      this.setState({DisplayError:true,ErrorMessage:"OTP is invalid!"})
     }else if(this.state.OTP === mergedOtp.toString()){
      this.setState({DisplayError:false,ErrorMessage:""})
      localStorage.setItem("LoginData", JSON.stringify(this.state.StorageObject));
      history.push(this.state.Redirection);
     }
  }

  //Back to Login
  Back = () =>{
    history.push('/login');
  }
 
  render() {
    document.title = "Two-Factor-Authentication | SalesHive"
    console.log(this.props.location)
    return (
      <div className="login-main">
        <div
          id="hideloding"
          className="loding-display"
          style={{ display: "none" }}
        >
          <img src={loadingicon} />
        </div>
        <div className="row h-100">
          <div className="col-xs-12 col-md-7 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={logo} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-5 d-flex">
            {/* Login Form in sign in page*/}
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
               
                <div className="heading">
                  <h5>Two-Factor-Authentication.</h5>
                </div>
                {
                  this.state.DisplayError ? <div style={{border:"1px solid red",color:"red",padding:"10px"}}><h5>{this.state.ErrorMessage}</h5></div>:null
                }
                <div>
                  <p>Please check your <b>e-mail</b> account for the verification code which we have just sent you and enter that <b>code
                    </b>  on the box below to login.</p>
                </div>
               
                <div id="forgot_message" className="text-success"></div>
            
                <form className="form">
                <label className="input-label" >
                      OTP
                    </label>
                <div className="otpContainer" style={{display:"flex",direction:'row'}}>
                      <input
                        name="otp1"
                        type="text"
                        autoComplete="off"
                        style={style}
                        value={this.state.otp1}
                        onKeyPress={this.keyPressed}
                        onChange={(e) => this.handleChange("otp1", e)}
                        tabIndex="1"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        name="otp2"
                        type="text"
                        autoComplete="off"
                        style={style}
                        value={this.state.otp2}
                        onChange={(e) => this.handleChange("otp2", e)}
                        tabIndex="2"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        name="otp3"
                        type="text"
                        autoComplete="off"
                        style={style}
                        value={this.state.otp3}
                        onChange={(e) => this.handleChange("otp3", e)}
                        tabIndex="3"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        name="otp4"
                        type="text"
                        style={style}
                        autoComplete="off"
                        value={this.state.otp4}
                        onChange={(e) => this.handleChange("otp4", e)}
                        tabIndex="4"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />

                      <input
                        name="otp5"
                        type="text"
                        style={style}
                        autoComplete="off"
                        value={this.state.otp5}
                        onChange={(e) => this.handleChange("otp5", e)}
                        tabIndex="5"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        name="otp6"
                        type="text"
                        style={style}
                        autoComplete="off"
                        value={this.state.otp6}
                        onChange={(e) => this.handleChange("otp6", e)}
                        tabIndex="6"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                 </div>
                 <button className="input-submit" onClick={this.VerifyOTP}>Verify</button>
                 <button className="input-submit-back" onClick={this.Back}>Back</button>

                </form>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedTwoStepVerificationPage = connect(mapState, actionCreators)(TwoStepVerificationPage);
export { connectedTwoStepVerificationPage  as TwoStepVerificationPage };
