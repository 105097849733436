import React, { useEffect } from "react";
import Axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetClientDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function PotentialMeetingstable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [MeetingExportData, SetMeetingExportData] = React.useState([]);
  const [MeetingStatus, setMeetingStatus] = React.useState([]);
  const [CName, SetCName] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    MeetingGet(Details.ClientID, Details.Role);
    ExportMeeting(ClientID, Role);
  }, [Search, Page, RowsPerPage, SortedBy]);

  const MeetingGet = (CID, Role) => {
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired: true,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        SetRows(Res.data.PageData);
        SetRlen(Res.data.TotalCount);
        SetFlen(Res.data.TotalCount);
        SetCountPage(Res.data.PageCount);
        setMeetingStatus(Res.data.MeetingStatus);
      }
    });
  };

  const Bookedstatusselect = (event, id, EventID, UserID, StatusIDs) => {
    // setMeetingstatus(event.target.value);
    let MeetingIDs = null;
    MeetingIDs = event.target.value;
    //find the meeting status name by id
    var data = {
      ClientID: ClientID,
      Role: Role,
      MeetingStatusID: event.target.value,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingStatus",
      method: "POST",
      data: data,
    }).then((res) => {
      var data = {
        // _id: id,
        EventID: EventID,
        ClientID: ClientID,
        UserID: UserID,
        MeetingStatusID: res.data.BookedStatus[0]?.MeetingStatusID,
        Role: Role,
        OldMeetingStatusID: StatusIDs,
        Status: res.data.BookedStatus[0]?.Status,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/UpdateBookedMeetingStatusHistory",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          window.location.reload(true);

          toast.success(
            "Booked meeting select option Status update successfully.",
            "Update Booked select option  meeting Status"
          );
        } else {
          toast.error(res.data.Message);
        }
      });
    });
  };

  const SortData = (Field) => {
    if (SortedBy == 1) {
      SetSortedBy(-1);
    } else {
      SetSortedBy(1);
    }
    SetSortField(Field);
  };

  const ExportMeeting = (CID, Role) => {
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired: false,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        var ExportData = [];
        if (Res.data.PageData.length > 0) {
          for (var i = 0; i < Res.data.PageData.length; i++) {
            var objCampaign = Res.data.PageData[i];
            var data = {};
            data.MeetingStatus = objCampaign.MS[0].Status;
            data.Company = objCampaign.Company;
            data.Title = objCampaign.Title;
            data.ContactName =
              objCampaign.FirstName + " " + objCampaign.LastName;
            data.Email = objCampaign.Email;
            data.Domain = objCampaign.Domain;
            data.MeetingSource = objCampaign.MS.Name;
            data.Owner =
              objCampaign.MO != null && objCampaign.MO != ""
                ? objCampaign.MO.FirstName + " " + objCampaign.MO.LastName
                : "";
            data.MeetingsetDate = objCampaign.EventCreatedDt;
            data.MeetingDate = objCampaign.EventStartDtByTimeZone;
            data.SendReminder =
              objCampaign?.IsSendMeetingReminder == true ? "Yes" : "No";
            data.LinkedInUrl = objCampaign.LinkedInUrl;
            data.ContractValue = objCampaign.MS.ContractValue;
            data.Industry = objCampaign.Industry;
            ExportData.push(data);
          }
          SetMeetingExportData(ExportData);
        } else {
          var data = {};
          data.MeetingStatus = "";
          data.Company = "";
          data.Title = "";
          data.ContactName = "";
          data.Email = "";
          data.Domain = "";
          data.MeetingSource = "";
          data.Owner = "";
          data.MeetingsetDate = "";
          data.MeetingDate = "";
          data.SendReminder = "";
          data.LinkedInUrl = "";
          data.ContractValue = "";
          data.Industry = "";
          ExportData.push(data);
          SetMeetingExportData(ExportData);
        }
      }
    });
  };
  const ExportCSV = () => {
    toast.success(
      <div className="toastsize">
        Kpi <br />
        Data exported successfully.
      </div>
    );
  };
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };
  return (
    <>
      <div className="row mxl-30">
        <div className="col border-bottom">
          <h4 className="headertitle float-left padx-0">Meetings</h4>

          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <CSVDownloader
                  data={MeetingExportData}
                  filename={CName + "-Meeting"}
                  bom={true}
                >
                  <a
                    onClick={() => {
                      ExportCSV();
                    }}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-15 mxl-30 px-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Meeting Status</TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("Company");
                    }}
                  >
                    Company
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Title");
                    }}
                  >
                    Title
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("FirstName");
                    }}
                  >
                    Contact Name
                  </a>
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Domain
                  </a>
                </TableCell>
                <TableCell>Meeting Source</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Meeting Date</TableCell>
                <TableCell>Meeting Set Date</TableCell>
                <TableCell>Send Reminder</TableCell>
                <TableCell>LinkedIn Url</TableCell>
                <TableCell>Contract Value</TableCell>
                <TableCell>Industry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell className="labeltds" scope="row">
                        <select
                          onChange={(e) => {
                            Bookedstatusselect(
                              e,
                              row?._id,
                              row?.EventID,
                              row?.UserID,
                              row?.StatusID
                            );
                          }}
                          class="form-control  m-input"
                          id="Meetingstatus"
                          name="Meetingstatus"
                          autoComplete="off"
                          value={row.MSH.MeetingStatusID}
                        >
                          {MeetingStatus.map((value) => (
                            <option value={value.MeetingStatusID}>
                              {value.Status}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>{row?.Company}</TableCell>
                      <TableCell>{row?.Title}</TableCell>
                      <TableCell>
                        {" "}
                        {row?.FirstName + " " + row?.LastName}
                      </TableCell>
                      <TableCell>{row?.Email}</TableCell>
                      <TableCell>{row?.Domain}</TableCell>
                      <TableCell>{row?.MS.Name}</TableCell>
                      <TableCell>
                        {row?.MO != null
                          ? row?.MO.FirstName + +row?.MO.LastName
                          : ""}
                      </TableCell>
                      <TableCell>{row?.EventCreatedDt}</TableCell>
                      <TableCell>{row?.EventStartDtByTimeZone}</TableCell>
                      <TableCell>
                        {row?.IsSendMeetingReminder == true ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.ContractValue}</TableCell>
                      <TableCell>{row?.Industry}</TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
