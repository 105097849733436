import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
    IconButton,
    Collapse,
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    
  } from '@material-ui/core';
  import moment from 'moment';
  import Pagination from "@material-ui/lab/Pagination";
  import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";


function Meeting({UserID,ClientID,Role,AccountID,Domain}) {

  const [MlistData, setMlistData] = useState([]);
  const [MlistRows, setMlistRows] = useState([]);
  const [MlRlen, setMlRlen] = useState(0);
  const [MlFlen, setMlFlen] = useState(0);
  const [MlCountPage, setMlCountPage] = useState(0);
  const [PageM, setPageM] = useState(1);
  const [RowsPerPageM, setRowsPerPageM] = useState(15);
  const [SearchM, setSearchM] = useState("");
  const [SortFieldM, setSortFieldM] = useState("CreatedDate");
  const [SortedByM, setSortedByM] = useState(-1);
  const [SflagM, setSflagM] = useState(false);
  const [MeetingNotes, setMeetingNotes] = useState([]);
  const [Open, setOpen] = useState(false);


  useEffect(() => {
    MeetingListsGet(Domain,SearchM, PageM, RowsPerPageM);
  }, [SearchM, PageM, RowsPerPageM, SortFieldM, SortedByM,Domain,AccountID,ClientID]);


    // meeting Details
   const MeetingListsGet = (DomainList, Search, Page, RowPage) => {
    if(Domain.trim() != "" && AccountID.trim() != "" && ClientID.trim() != "" && UserID.trim() !== ""){
        var PListData = {
          Page: parseInt(Page),
          ClientID: ClientID,
          Domain: DomainList,
          RowsPerPage: parseInt(RowPage),
          Sort: true,
          Field: SortFieldM,
          SortBy: SortedByM,
          Role: Role,
          Search: Search,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
          method: "POST",
          data: PListData,
        }).then((PListResult) => {
            setMlistData(PListResult.data?.PageData);
            setMlistRows(PListResult.data?.PageData);
            setMlRlen(PListResult.data?.TotalCount);
            setMlFlen(PListResult.data?.TotalCount);
            setMlCountPage(PListResult.data?.PageCount);
            setMeetingNotes(PListResult.data?.MeetingNotes);
        });
      }
      }
    
      //Sorting Contact list
     const BookedMeetingSorting = (Field) => {
        setSortFieldM(Field)
        setSortedByM(-SortedByM)
    
      }
    
      // search for record
     const RequestSearchListM = (Event) => {
        
        if (Event.key === "Enter") {
          var SearchedVal = document.getElementById("MeetingSearch").value;
          setSearchM(SearchedVal);
        
        }
      };
    
      // change display rows
     const ChangeRowSelectedListM = (Event) => {
        setRowsPerPageM(Number(Event.target.value));
        setPageM(1)
        var SearchedVal = document.getElementById("MeetingSearch").value;
        setSearchM(SearchedVal);
        var PListData = {
          Page: 1,
          ClientID: ClientID,
          Domain: Domain,
          RowsPerPage: Number(Event.target.value),
          Sort: true,
          Field: SortFieldM,
          SortBy: SortedByM,
          Role: Role,
          Search: SearchedVal,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
          method: "POST",
          data: PListData,
        }).then((PListResult) => {
            setMlistData(PListResult.data?.PageData);
            setMlistRows(PListResult.data?.PageData);
            setMlRlen(PListResult.data?.TotalCount);
            setMlFlen(PListResult.data?.TotalCount);
            setMlCountPage(PListResult.data?.PageCount);
            setMeetingNotes(PListResult.data?.MeetingNotes);
        });
      };
    
      // change page
     const HandleChangePageListM = (Event, NewPage) => {
        
        if (NewPage == PageM) {
            setPageM(NewPage)
        } else {
          var SearchedVal = document.getElementById("MeetingSearch").value;
          setSearchM(SearchedVal)
          var PListData = {
            Page: NewPage,
            ClientID: ClientID,
            Domain: Domain,
            RowsPerPage: RowsPerPageM,
            Sort: true,
            Field: SortFieldM,
            SortBy: SortedByM,
            Search: SearchedVal,
            Role: Role,
            Type: "User",
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
            method: "POST",
            data: PListData,
          }).then((PListResult) => {
            setMlistData(PListResult.data?.PageData);
      setMlistRows(PListResult.data?.PageData);
      setMlRlen(PListResult.data?.TotalCount);
      setMlFlen(PListResult.data?.TotalCount);
      setMlCountPage(PListResult.data?.PageCount);
      setMeetingNotes(PListResult.data?.MeetingNotes);
          });
        }
      };
    
      // edit Meeting page
    const  MeetingEditPage = (id) => {
        // history.push("/editbookedmeeting", id);
        history.push({
          pathname: "/editbookedmeeting",
          state: {
            data: id,
            Pagename: "AccountContact",
            AccountData: AccountID,
          },
        });
      };
    
      // add Metting page
    const  MeetingAdd = () => {
        // history.push("/addbookedmeeting");
        history.push({
          pathname: "/addbookedmeeting",
          state: { data: AccountID, Pagename: "AccountContact" },
        });
      };
    
      //View Meeting 
      const ViewMeeting = (id) => {
        history.push({
            pathname: "/viewbookedmeeting",
            state: {
                data: id,
                Pagename: 'AccountContact',
                AccountData: AccountID,
              },
          });
      }


  return (
    <>
            <div className="row pt-3"> 

                <div className="col">                
                  <h3 className=" float-left pb-4 addpotential_header">
                    Meeting
                  </h3>
                </div>

                <div className="col text-right">
                  <button
                    onClick={MeetingAdd}
                    className="btn btngroup m-btn m-btn--custom "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div> 
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelectedListM}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="MeetingSearch"
                    onKeyPress={(event) => RequestSearchListM(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("ContactName");
                          }}
                        >
                          Contact Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("Email");
                          }}
                        >
                          Email
                        </a>
                      </TableCell>

                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("Company");
                          }}
                        >
                          Company
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("Status");
                          }}
                        >
                          Meeting Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("MeetingSource");
                          }}
                        >
                          Meeting Source
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("MeetingOwner");
                          }}
                        >
                          Owner
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("CreatedDate");
                          }}
                        >
                          Meeting Date
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            BookedMeetingSorting("EventCreatedDt");
                          }}
                        >
                          Meeting Set Date
                        </a>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {MlistRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      MlistRows?.map((row) => (
                        <>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                    setOpen((prev) => ({
                                        ...prev,
                                        [row._id]: !prev[row._id],
                                      }))
                                }
                              >
                                {Open[row._id] ? (
                                  <i
                                    className="fa fa-minus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell><a onClick={()=>{ViewMeeting(row._id)}}><u>{row?.ContactName}</u></a> </TableCell>
                            <TableCell>{row.Email}</TableCell>
                            <TableCell>{row.Company}</TableCell>
                            <TableCell>{row.Title}</TableCell>
                            <TableCell>{row?.Status}</TableCell>
                            <TableCell>{row?.MeetingSource}</TableCell>
                            <TableCell>{row?.MeetingOwner}</TableCell>
                            <TableCell>
                              {row.EventStartDt
                                ? moment(
                                    new Date(row.EventStartDt).toDateString()
                                  ).format("MM/DD/YYYY HH:mm:ss A")
                                : ""}
                            </TableCell>

                            <TableCell>
                              {row.EventCreatedDt
                                ? moment(
                                    new Date(row.EventCreatedDt).toDateString()
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  MeetingEditPage(row._id);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={Open[row._id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {Open[row._id] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {MeetingNotes.map((note) =>
                                        note.MeetingID.toString() ==
                                        row?._id.toString() ? (
                                          <TableRow>
                                            <TableCell><b>{note?.Title}</b></TableCell>
                                            <TableCell><b> {note.CreatedDate? moment(new Date(note.CreatedDate).toDateString()).format("MM/DD/YYYY"): ""}</b></TableCell>
                                            <TableCell scope="row">
                                              {note.Note}
                                            </TableCell>
                                           
                                          </TableRow>
                                        ) : null
                                      )}

                                      {!MeetingNotes?.some(
                                        (note) =>
                                          note?.MeetingID.toString() ==
                                          row?._id.toString()
                                      ) && (
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            No data available
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {SflagM ? (
              <div className="row">
                <Pagination
                  component="div"
                  count={MlCountPage}
                  onChange={HandleChangePageListM}
                  showFirstButton
                  showLastButton
                />
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {MlRlen == 0
                      ? 0
                      : (PageM - 1) * RowsPerPageM +
                        1}{" "}
                    to{" "}
                    {PageM * RowsPerPageM >
                    MlRlen
                      ? MlRlen
                      : PageM * RowsPerPageM}{" "}
                    of {MlRlen} entries (filtered from{" "}
                    {MlFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {MlRlen == 0
                      ? 0
                      : (PageM - 1) * RowsPerPageM +
                        1}{" "}
                    to{" "}
                    {PageM * RowsPerPageM >
                    MlRlen
                      ? MlRlen
                      : PageM * RowsPerPageM}{" "}
                    of {MlRlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={MlCountPage}
                    onChange={HandleChangePageListM}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
    </>
  )
}

export default Meeting