import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditContactReasonUnqualifiedPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      TitleNotNull: false,
      IsExist: false,
      ClientID: null,
      UserID: null,
      CUserID: null,
      OldName:null,
      IsButtonDisabled:false,
      IsLoading:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    var Details = GetUserDetails();
    document.title = 'Edit | SalesHive';

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
    this.ContactReasonEdit();
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExists = await this.ContactReasonUnqualifiedCheckExist(Name); // Change 'Country' to 'Name'
    
    if (IsExists == "BlankData" && Name === "") { // Change 'Country' to 'Name'
      FormIsValid = false;
      Errors["Name"] = "Please Enter Name"; // Change 'Country' to 'Name'
    }
  
    if (IsExists === "ExistCountry") { // Change 'Country' to 'Name'
      FormIsValid = false;
      Errors["Name"] = "Name already exists."; // Change 'Country' to 'Name'
    }
  
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

    // handle change
    async HandleChange(field, e) {
      let Errors = { ...this.state.Errors };
      const value = e.target.value.trim();
      let IsExists;
    
      if (field === "Country") {
        IsExists = await this.ContactReasonUnqualifiedCheckExist(value);
        if (IsExists === "BlankData" && value === "") {
          Errors.Country = "Please enter reason";
        } else if (IsExists === "ExistCountry") {
          Errors.Country = "Reasion already exists.";
        } else if (IsExists === "AllDone") {
          Errors.Country = null;
        }
      }
    
      this.setState({ Errors });
    }


  // contact reason unqualified reason check exits
  async ContactReasonUnqualifiedCheckExist(Reason) {
    if(Reason !== ""){
      var str_in = {
        ClientID: this.state.ClientID,
        Reason: Reason,
      };
      let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactreasonunqualified/ContactReasonUnqualifiedExists",
        method: "POST",
        data: str_in,
      });Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactreasonunqualified/ContactReasonUnqualifiedExists",
        method: "POST",
        data: str_in,
      })
          if (resdata.data.Data.length > 0) {
            if(this.state.OldName == Reason){
    
            }else{
              this.setState({ StatusAvailable: resdata.data.Data[0]?.Reason });
              return "ExistCountry";
            }
          } else {
            return "AllDone";
          }

    }else{
      return "BlankData"; 
    }

  }
    // contact reason unnqualified data
    ContactReasonEdit() {
      const id = this.props.location.state;
      
      this.setState({ id: id });
      var str_in = {
        id: id,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactreasonunqualified/ContactReasonUnqualifiedGetByID",
        method: "POST",
        data: str_in,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          document.getElementById("Name").value = res.data.Data[0].Reason;
          this.setState({ OldName: res.data.Data[0]?.Reason });

          this.setState({ IsLoading: false});
        } else {
          toast.error(res.data.Message);
          this.setState({ IsLoading: false});
        }
      });
    }

  // back button
  BackBtn() {
    history.push("/contactreasonunqualified");
  }

  // save button
  async SaveBtn() {
    this.setState({IsButtonDisabled:true});

    var Final_flag = await this.FromValidation();
    if (Final_flag == true) { 
      var Reason = document.getElementById("Name").value.trim();
        var data = {
          _id: this.state.id,
          Reason: Reason,
          LastUpdatedBy: this.state.CUserID,
          LastUpdatedDate: new Date(),
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/contactreasonunqualified/ContactReasonUnqualifiedUpdate",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Contact Reason Unqualified
                <br />
                Contact Reason Unqualified updated Successfully.
              </div>
            );
            history.push("/contactreasonunqualified");
          } else {
            toast.error(res.data.Message);
            this.setState({IsButtonDisabled:false});

          }
        });
    }else{
      this.setState({IsButtonDisabled:false});
    }
  }

  render() {
    return (
      <>
      {this.state.IsLoading ?     <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>}
  
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
            <div className="row pt-4 pb-3">
              <div className="col px-3">
                <h4 className="headertitlenop float-left px-1">
                  Edit
                </h4>
              </div>
            </div>

            <div class="whiterow px-3">
              <div className="row">
                <div class="col-lg-6 boxsinput_group px-3">
                  <div class="row max-cols px-1 pt-3 pb-2">
                    <label class="col-lg-3 view-lable-meet">Name</label>
                    <div class="col-lg-7">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-3 pt-4 pb-4">
                <div class="col-lg-12 pull-left pt-2 pb-2 px-3 mx-1">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectededitcontactreasonunqualified = connect(
  mapState,
  actionCreators
)(EditContactReasonUnqualifiedPage);
export { connectededitcontactreasonunqualified as EditContactReasonUnqualifiedPage };
