import React, { useEffect } from "react"; 

export default function footer() {
 
  return ( 
      <footer className="botmfooter"> 
          2023 © <a href="https://saleshive.com/" className="m-link">SalesHive</a>. All Rights Reserved. 
      </footer> 
  );
}
