import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import 'froala-editor/js/plugins.pkgd.min.js';
import Froalaeditor from 'froala-editor'
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import SlackEditor from './SlackEditor';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    
    setImages(imageList);
  }; 
  const openTermsConditionPopup = () => {
    //
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };


  document.addEventListener('mouseup', function(e) {
      
      //
      if(popupval){
        setpopup(false);
      }
  });
    //const openTermsConditionPopup = false;
  

  return (
    <div className="tabdes"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"> 
            <Tab label="Meeting" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Rescheduling" {...a11yProps(1)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
          <SlackEditor
            HeadingName={"Meeting"}
            IsRescheduling={false}
          />
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={1}>
        <SlackEditor
            HeadingName={"Rescheduling"}
            IsRescheduling={true}
          />
        </TabPanel>
 
      </div>
  
    </div>
  );
}