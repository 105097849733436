import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { CSVReader, CSVDownloader } from "react-papaparse";

import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";
import ReactApexchart from "react-apexcharts";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ FromDateMeeting, ToDateMeeting }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Notes, SetNotes] = React.useState([]);
  const [ResponderData, SetResponderData] = React.useState([]);
  const [OldClients, SetOldClients] = React.useState(false);
  const [ResponderID, SetResponderID] = React.useState("");
  const [SourceData, SetSourceData] = React.useState({});
  const [StatusData, SetStatusData] = React.useState({});
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ExportData, SetExportData] = React.useState([]);
  const [TotalGoal, SetTotalGoal] = React.useState([]);
  const [TotalEvent, SetTotalEvent] = React.useState([]);
  const [TotalGoalSum, SetTotalGoalSum] = React.useState(0);
  const [TotalEventSum, SetTotalEventSum] = React.useState(0);
  const [TotalWeekDay, SetTotalWeekDay] = React.useState([]);
  const [TotalCrush, SetTotalCrush] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(false);

  const classes = useRowStyles();

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetUserIDby(Details.ChildUserID);
      SetRole(Details.Role);
    }

    GraphSourceGet(Details.ClientID, FromDateMeeting, ToDateMeeting,Details.Role);
    GraphStatusGet(Details.ClientID, FromDateMeeting, ToDateMeeting,Details.Role);
    MeetingKeyMatricGet(Details.ClientID, FromDateMeeting, ToDateMeeting,Details.Role);
    MeetingExportHandler(Details.ClientID, FromDateMeeting, ToDateMeeting,Details.Role);
    KpiMeetingGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromDateMeeting,
      ToDateMeeting
    );
  }, [Search, Page, RowsPerPage, ResponderID, OldClients,FromDateMeeting, ToDateMeeting]);

  charts(FusionCharts);

  //Source Status Chart
  const GraphSourceGet = (CID, FromDates, ToDates,role) => {
    SetIsLoading(true)

    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      Role:role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/GraphSourceGet",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      // SetTotalMeeting(Res.data.TotalmeetingCount);
      var dataSource = {
        chart: {
          caption: "Meeting Source",
          //subcaption: "For a net-worth of $1M",
          theme: "fusion",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          plothighlighteffect: "false",
          legendcaptionbold: "1",
          legendcaptionfontsize: "15",
          legendCaptionFontColor: "#000",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "right",
          legendCaption: "Meeting Status: ",
          legendBgColor: "#FFF",
          legendBorderColor: "#ffF",
          legendShadow: "0",
          legendItemFontSize: "14",
          legendWidth: "300", 
        },
        data: Res.data.data,
      };
      SetSourceData(dataSource);
      SetIsLoading(false)

    });
  };

  //Status Chart
  const GraphStatusGet = (CID, FromDates, ToDates,role) => {
    SetIsLoading(true)

    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      Role:role,
      FromDate: FromDates,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/GraphStatusGet",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      // this.setState({ GraphData: Res.data.data });
      // this.setState({TotalReschedules:Res.data.TotalmeetingCount})
      var dataStatus = {
        chart: {
          caption: "Meeting Status",
          //subcaption: "For a net-worth of $1M",
          showvalues: "1",
          showpercentintooltip: "0",
          numbersuffix: "%",
          showlegend: "1",
          plothighlighteffect: "fadeout|color=#fff, alpha=60",
          legendcaptionbold: "1",
          legendcaptionfontsize: "16",
          //numberprefix: "$",
          enablemultislicing: "1",
          theme: "fusion",
        },
        data: Res.data.data,
      };
      SetStatusData(dataStatus);
    SetIsLoading(false)

    });
  };

  const MeetingKeyMatricGet = (CID, FromDates, ToDates,role) => {
    SetIsLoading(true)

    const InputParameters = {
      ClientID: CID,
      Role:role,
      ToDate: ToDates,
      FromDate: FromDates,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingKeyMatric",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      if(Res.data.StatusMessage=="SUCCESS")
      {
        if(Res.data.Data.length > 0)
        {
          var lstTotalGoal=[];
          var lstTotalEvent=[];
          var lstTotalWeekDay=[];
          var objTotalGoalSum=0;
          var objTotalEventSum=0;
          for(var i=0;i<Res.data.Data.length;i++)
          {
            var Goal=Res.data.Data[i].TotalGoal;
            var Event=Res.data.Data[i].TotalEvent;
            var WeekDay=Res.data.Data[i].WeekDay;

            lstTotalGoal.push(Goal);
            objTotalGoalSum=objTotalGoalSum + Goal;

            lstTotalEvent.push(Event);
            objTotalEventSum=objTotalEventSum+Event;

            lstTotalWeekDay.push(WeekDay);

          }
          SetTotalGoal(lstTotalGoal)
          SetTotalEvent(lstTotalEvent)
          SetTotalGoalSum(objTotalGoalSum)
          SetTotalEventSum(objTotalEventSum)
          SetTotalWeekDay(lstTotalWeekDay)
          SetTotalCrush (objTotalEventSum >0 && objTotalGoalSum >0 ? Math.round(objTotalEventSum * 100 / objTotalGoalSum) :0);

          SetIsLoading(false)

        }else
        {
          SetTotalGoal([])
          SetTotalEvent([])
          SetTotalGoalSum(0)
          SetTotalEventSum(0)
          SetTotalWeekDay([])
    SetIsLoading(false)

        }
      }
      else{

        SetTotalGoal([])
        SetTotalEvent([])
        SetTotalGoalSum(0)
        SetTotalEventSum(0)
        SetTotalWeekDay([])
    SetIsLoading(false)

      }
    });
  };

  const state = {
    series: [
      {
        name: "Goal",
        color: "#302e49",
        data: TotalGoal,
    // data: [44, 55, 57, 56],
      },
      {
        name: "Meetings",
        color: "#e9826e",
       // data: [76, 85, 101, 98],
        data: TotalEvent,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories:TotalWeekDay
        // ["03/29", "04/05", "04/12", "04/19"],
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val ;
          },
        },
      },
      legend: {
        position: 'right',
        horizontalAlign: 'bottom',
        offsetX: -10
      }
    },
  };

  // kpiMeeting get list
  const KpiMeetingGet = (CID, UID, URole, FromDates, ToDates) => {
    SetIsLoading(true)

    var KipsMeetingData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToDates,
      FromDate: FromDates,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingListGet",
      method: "POST",
      data: KipsMeetingData,
    });
    rows1.then((result) => {
  
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetNotes(result.data.KpisMeetingNotes);
      SetTotalMeeting(result.data.TotalCount);
      SetIsLoading(false)

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //Get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      SetIsLoading(false)

      });
    }
  };

  // //View Meeting
  // const ViewPage = (id) => {
  //   history.push("/viewbookedmeeting", id);
  // };

  const ViewPage = (id) => {
    // history.push("/viewbookedmeeting", id);
    history.push({
      pathname: "/cviewbookedmeetings",
      state: { data: id, Pagename: "kpiMeeting" },
    });
  };

  //Add Meeting
  // const AddPage = () => {
  //   history.push("/addbookedmeeting");
  // };

  const AddPage = () => {
    history.push({pathname:"/caddbookedmeetings", state:{Pagename:"kpiMeeting"}});
  };

  //Export Meeting
  const MeetingExportHandler = (CID, FromDates, ToDates,role) => {
    let InputParameter = {
      ClientID: CID,
      ToDate: ToDates,
      Role:role,
      FromDate: FromDates,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpisMeetingExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res.data.Data);
    });
  };
  const MeetingExport = () =>{
    toast.success(<div className="toastsize">Data exported successfully</div>)
  }

  return (
    <>
    {IsLoading ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></>:<></>}
      <div className="row mx-n4">
        <div className="col-xl-4 px-2">
          <div class="cardbox boxcardcounter">
            <div class="d-flex align-center">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalMeeting}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Total Meetings</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 px-2">
          <div class="cardbox boxcardcounter">
            <div class="d-flex align-center">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalGoalSum}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Total Goals</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 px-2">
          <div class="cardbox boxcardcounter">
            <div class="d-flex align-center">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalCrush} %</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Crush %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row cardbox padtop-50 mt-3">
        <div class="col-xl-1"></div>
        <div class="col-xl-10">
          <div id="chart chart-max450">
            <ReactApexchart
              options={state.options}
              series={state.series}
              type="bar"
              height={450}
            />
          </div>
        </div>
        <div class="col-xl-1"></div>
      </div>
 
        <div className="row mt-3">
          <div className="col minheight cardbox">
            <div className="chartboxsmet">
              <ReactFusioncharts
              key={JSON.stringify(SourceData)}
                type="pie3d"
                width="100%"
                height="100%"
                dataFormat="JSON"
                dataSource={SourceData}
              />
            </div>
          </div>
          <div className="col brdr-left minheight">
            <div className="chartboxsmet">
              <ReactFusioncharts
                key={JSON.stringify(StatusData)}
                type="pie3d"
                width="100%"
                height="100%"
                dataFormat="JSON"
                dataSource={StatusData}
              />
            </div>
          </div>
        </div> 

      <div className="cardbox px-3 mt-3 pb-3 mx-n3">
      <div className="row mt-3">
        <div className="col border-bottom">
          <h4 className="headertitle float-left">Meetings Details</h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
              <CSVDownloader
                    data={ExportData}
                    filename={"KpisMeeting"}
                    bom={true}
                  >
                <a
                  onClick={() => {
                    MeetingExport();
                  }}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-download"></i>Export
                </a>
                  </CSVDownloader>
              </li>
              <li>
                <a
                  onClick={() => {
                    AddPage();
                  }}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-plus"></i>
                  <span>Add</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <TableContainer component={Paper} className="table-bordered">
        <Table aria-label="collapsible table">
        <TableHead>
            <TableRow>
              <TableCell>Notes</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FirstName");
                  }}
                >
                  Account Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Company");
                  }}
                >
                  Company
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("MeetingStatus");
                  }}
                >
                  Meeting Status
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Title");
                  }}
                >
                  Title
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("LastName");
                  }}
                >
                  Contact Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Email");
                  }}
                >
                  Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Domain");
                  }}
                >
                  Domain
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("MeetingSource");
                  }}
                >
                  Meeting Source
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("MeetingOwner");
                  }}
                >
                  Owner
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("EventStartDt");
                  }}
                >
                  Meeting Date
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("EventCreatedDt");
                  }}
                >
                  Meeting Set Date
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IsSendMeetingReminder");
                  }}
                >
                  Send Reminder
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("LinkedInUrl");
                  }}
                >
                  LinkedIn Url
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ContractValue");
                  }}
                >
                  Contract Value
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Industry");
                  }}
                >
                  Industry
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("DirectPhone");
                  }}
                >
                  Direct Phone
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("CompanyPhone");
                  }}
                >
                  Company Phone
                </a>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows.map((row) => (
                <>
                  <TableRow key={row._id}>
                    <TableCell align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          setOpen((prev) => ({
                            //...prev,
                            [row._id]: !prev[row._id],
                          }));
                          // GetNotes(row.EventID);
                        }}
                      >
                        {open[row._id] ? (
                          <i
                            className="fa fa-minus-circle plusbl"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-plus-circle minusl"
                            aria-hidden="true"
                          ></i>
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <a
                        className="btn-eyesicon"
                        onClick={() => {
                          ViewPage(row._id);
                        }}
                      >
                        <i class="la flaticon-eye edit-icon"></i>
                      </a>
                    </TableCell>
                    <TableCell>{row.FirstName}  {row.LastName}</TableCell>
                    <TableCell>{row.Company}</TableCell>
                    <TableCell>{row.MeetingStatus}</TableCell>
                    <TableCell>{row.Title}</TableCell>
                    <TableCell>{row.LastName}</TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.Domain}</TableCell>
                    <TableCell>{row.MeetingSource}</TableCell>
                    <TableCell>{row.MeetingOwner}</TableCell>
                    <TableCell>
                      {" "}
                      {moment(new Date(row.EventStartDt).toDateString()).format(
                        "MM/DD/YYYY"
                      ) != "Invalid date"
                        ? moment(
                            new Date(row.EventStartDt).toDateString()
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {moment(
                        new Date(row.EventCreatedDt).toDateString()
                      ).format("MM/DD/YYYY hh:mm a") != "Invalid date"
                        ? moment(
                            new Date(row.EventCreatedDt).toDateString()
                          ).format("MM/DD/YYYY hh:mm A")
                        : ""}
                    </TableCell>

                    <TableCell>{row?.IsSendMeetingReminder === true ? "yes" : "no"}</TableCell>
                    <TableCell>{row.LinkedInUrl}</TableCell>
                    <TableCell>{row.ContractValue}</TableCell>
                    <TableCell>{row.Industry}</TableCell>
                    <TableCell>{row.DirectPhone}</TableCell>
                    <TableCell>{row.CompanyPhone}</TableCell>
                    <TableCell>
                      <a
                        className="btn-eyesicon"
                        onClick={() => {
                          ViewPage(row._id);
                        }}
                      >
                        <i class="la flaticon-eye edit-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={open[row._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {open[row._id] && (
                          <Box margin={1} className="innertables">
                            <Table size="small" aria-label="purchases">
                              <TableHead></TableHead>
                              
                              {Notes.map((note) =>
                                note.MeetingID?.toString() ==
                                row._id?.toString() ? (
                                  <TableRow key={note.MeetingID}>
                                    <TableCell>{note.Title}</TableCell>
                                    <TableCell className="labeltds"> {moment(
                          new Date(note?.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")}</TableCell>
                                    <TableCell scope="row">
                                      {note.Note}
                                    </TableCell>
                                    <TableCell className="labeltds"></TableCell>
                                    <TableCell className="labeltds"></TableCell>
                                  </TableRow>
                                ) : null
                              )}

                              {!Notes.some(
                                (note) =>
                                  note.MeetingID?.toString() ==
                                  row._id?.toString()
                              ) && (
                                <TableRow>
                                  <TableCell colSpan={4}>
                                    No data available
                                  </TableCell>
                                </TableRow>
                              )}
                            </Table>
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </>
  );
}