import React from 'react';
const moment=require('moment');
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar'; 
import Footer from "../../_components/user/footer/footer";
import VariableReportingTabing from '../../_components/user/VariableReporting/VariableReportingTabing.js';
import Popupone from "reactjs-popup";
import{ GetUserDetails } from '../../_helpers/Utility';

class VariableReportingPage extends React.Component {
   
    constructor(props) {
        super(props);
    
        // reset login status
        //    this.props.logout();

        this.state = { 
            ClientID:null,
            UserID:null,
            popupval : true,
            InvitePopup : false,
            FromDate:"",
            ToDate:"",
            DateSearch:{
                "Start":moment(new Date().setMonth(new Date().getMonth() - 1)).format('YYYY/MM/DD'),
                "End":moment(new Date()).format('YYYY/MM/DD')
            },
        };   
        this.SaveBtn = this.SaveBtn.bind(this);
        this.openInvitePopup = this.openInvitePopup.bind(this);
        this.closeInvitePopup = this.closeInvitePopup.bind(this);
        this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
    } 

    componentDidMount() {
        
        // document.getElementById('from-date').valueAsDate=new Date(moment().subtract(90,'day'))
        // document.getElementById('to-date').valueAsDate=new Date(moment())
        // let FromDate = document.getElementById('from-date').value;
        // let ToDate = document.getElementById('to-date').value;
        
        // this.setState({
        //   FromDate: FromDate,
        //   ToDate: ToDate
        // })

        var Details=GetUserDetails();
        if(Details != null)
        {
            this.state.ClientID= (Details.ClientID);
            this.state.UserID=(Details.ChildUserID);
            
        }
        var d = new Date();
          d.setMonth(d.getMonth() - 1);
          var start = moment(d).format('YYYY-MM-DD');
          var d1 = new Date();
          var end = moment(d1).format('YYYY-MM-DD');
          this.setState({StartDate:start});
          this.setState({EndDate: end});
          document.getElementById("from-date").value = start;
          document.getElementById("to-date").value = end;
          var d = new Date();
          d.setMonth(d.getMonth() - 1);
          var start = moment(d).format('YYYY/MM/DD');
          var d1 = new Date();
          var end = moment(d1).format('YYYY/MM/DD');
          this.setState({DateSearch:{
              "Start":start,
              "End":end
          }})

    } 
    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.closeInvitePopup);
    // }
   
    SaveBtn(){
        let FromDate=document.getElementById('from-date').value;
        let ToDate=document.getElementById('to-date').value;
        this.setState({FromDate:FromDate,ToDate:ToDate})
        this.setState({DateSearch:{
            "Start":FromDate,
            "End":ToDate
        }})
    }
 
 
    openInvitePopup(){
        console.log("--it's true one new"); 
         this.setState({ InvitePopup : true });
         console.log(this.state); 

    } 
    closeInvitePopup(){
        console.log("--it's false new");
        this.setState({ InvitePopup : false });
    }
    
    SubmitInvitePopup() {
        this.setState({ InvitePopup : false });
    } 
    

    render() { 
        return (
            <>
                     
                <Popupone open={this.state.InvitePopup} modal>
                <div className="modal-black"></div>
                    <div className="filterPopup bigpopupcontent">
                        <div className="paddingboxTerms">
                            <div className="modal-header">
                                <h5>Send Mail</h5>
                                <a className="close-ion" onClick={this.closeInvitePopup}><span aria-hidden="true" className="la la-remove"></span></a>
                            </div>
                            <div className="modal-content bordernone"> 
                                <div className="row max-cols">
                                    <label className="col-lg-4 view-lable-meet">First Name</label>
                                    <div className="col-lg-8">
                                        <input className="form-control m-input" data-val="true" data-val-required="Please enter first name" id="FirstName" name="FirstName" placeholder="First name" type="text" value="" />
                                    </div>
                                </div> 
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <a id="m_emailInvite_submit" href="javascript:void(0);" className="btn btn-primary btnColor">Send </a>
                            </div>
                        </div> 
                    </div> 
                </Popupone>


                <div className="adminmain mheight"> 
                    <Sidebar className=""/> 
                    <div className="bodyhome">
                        <div className="row">
                            <div className="col"> 
                                <h4 className="headertitlebd pl-0">Variable Reporting</h4>
                            </div>
                            <div className="col padright">
                                <div className="listing-li float-right padb-15">
                                <div className="btn-group">
                                    <input id="fromtab" name="fromtab" type="hidden" value="" />
                                    <input className="form-control datepicker" id="from-date" name="from-date" type="date" autocomplete="off"  />
                                    <input className="form-control datepicker" id="to-date" name="to-date" type="date" autocomplete="off"  />
                                        <button id="aCompanyState" onClick={this.SaveBtn} className="btn btn-primary btnColor m-btn btn-radius" autocomplete="off">
                                            <i className="la flaticon-diagram"></i>
                                            <span>Analyze</span>
                                    </button>
                                </div>

                                </div>
                            </div>
                        </div> 
                        <div className="col padd-0">
                            
                        </div>

                        <div className="row"> 
                            <div className="col"> 
                                <div className="listblock">
                                    <VariableReportingTabing ToDate={this.state.ToDate} FromDate={this.state.FromDate} daterange={this.state.DateSearch} />
                                </div> 
                            </div> 
                        </div> 
                        
                    </div>
                </div>
                <Footer />
            </>
        );
    }

}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedVariableReportingPage = connect(mapState, actionCreators)(VariableReportingPage);
export { connectedVariableReportingPage as VariableReportingPage };