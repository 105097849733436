const Moment = require("moment");
import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";

import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import loadingicon from "../../../images/loading.gif";

class EditActionItemsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      ClientID: null,
      UserID: null,
      CUserID: null,
      GetActionData: [],
      ActionItmesList: [],
      MeetingDate: null,
      WeeklyMeetingStatusId: null,
      DueDate: null,
      Notes: "",
      ToDoItems: "",
      Role: "",
      IsSubmitting:false,
      IsLoading:false
    };

    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title="Edit Action Items | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.setState({ Role: Details.Role });
    }
    this.ActionItemGet();
    this.GetActionStatusDropDown();
  }

  // Get Action Item status Drop Down
  GetActionStatusDropDown() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/action_items/ActionItemStatusGet",
      method: "GET",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          _id: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ ActionItmesList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // Get Action Item old data
  ActionItemGet() {
    this.setState({IsLoading:true})
    var ID = {
      ID: this.props.location.state,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/action_items/ActionItemGetByID",
      method: "POST",
      data: ID,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
      
        this.setState({ GetActionData: res.data.Data[0] });
        this.setState({
          WeeklyMeetingStatusId: res.data.Data[0].WeeklyMeetingStatusId,
        });
        this.setState({ Notes: res.data.Data[0].Notes });
        this.setState({ ToDoItems: res.data.Data[0].ToDoItems });
        document.getElementById("MeetingDate").value = Moment(
          new Date(res.data.Data[0].MeetingDate).toDateString()
        ).format("YYYY-MM-DD");
        if(res.data.Data[0].DueDate == null){
          document.getElementById("DueDate").value = ""
        }else{
          document.getElementById("DueDate").value = Moment(
            new Date(res.data.Data[0].DueDate).toDateString()
          ).format("YYYY-MM-DD");
        }
        
        this.setState({IsLoading:false})

      } else {
        toast.error(res.data.Message);
        this.setState({IsLoading:false})

      }
    });
  }

  // Form Validation
  async FormValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};
    var MeetingDate = document.getElementById("MeetingDate").value.trim();
    if (MeetingDate == "") {
      formIsValid = false;
      errors["MeetingDate"] = "Please select meeting date";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  OnChangeData = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // handlechange
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ [field]: e.target.value });
    this.setState({ fields });
    if (fields.MeetingDate != "" || fields.MeetingDate != null) {
      this.state.errors.MeetingDate = null;
    } else {
      this.state.errors.MeetingDate = null;
    }
  }

  backbtn() {
    history.push("/actionitems");
  }

  // Update Action Item
  async savebtn(e) {
    this.setState({IsSubmitting:true,IsLoading:true})
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      var MeetingDates = document.getElementById("MeetingDate").value;
      var DueDate = document.getElementById("DueDate").value;

      var data = {
        ID: this.props.location.state,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        MeetingDate: MeetingDates,
        WeeklyMeetingStatusId: this.state.WeeklyMeetingStatusId,
        DueDate: this.state.DueDate,
        Notes: this.state.Notes,
        ToDoItems: this.state.ToDoItems,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/action_items/ActionItemsUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Action Item Edit
              <br />
              Action item updated successfully.
            </div>
          );
          history.push("/actionitems");
        } else {
          toast.error(res.data.Message);
          this.setState({IsSubmitting:false,IsLoading:false});
        }
      });
    } else {
      this.setState({IsSubmitting:false,IsLoading:false});
    }
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>: <></>
      }
        
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pt-4">
                    Edit Action Items
                  </h4>
                </div>
              </div>
            <div class="whiterow mb-3 px-3"> 
              <div className="row pt-4 px-3 mx-0 pb-4 border-bottom">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Action Item Date{" "}
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control datepicker"
                        id="MeetingDate"
                        name="MeetingDate"
                        placeholder=""
                        type="date"
                        autocomplete="off"
                        onChange={this.handleChange.bind(this, "MeetingDate")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingDate"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Action Item Status
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="WeeklyMeetingStatusId"
                        name="WeeklyMeetingStatusId"
                        onChange={this.OnChangeData}
                        value={this.state.WeeklyMeetingStatusId}
                      >
                        {this.state.ActionItmesList.map((StatusId) => (
                          <option value={StatusId._id}>
                            {StatusId.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Due Date </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control datepicker"
                        id="DueDate"
                        name="DueDate"
                        onChange={this.OnChangeData}
                        placeholder=""
                        type="date"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row borderbtnm pt-2 px-3">
                <div className="col px-4">
                  <h4 className="headertitlepad float-left px-2">Notes</h4>
                </div>
              </div>

              <div className="row px-3 pt-3 border-bottom pb-3">
                <div class="col-lg-12 boxsinput_group px-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <textarea
                        className="form-control m-input"
                        placeholder=" "
                        onChange={this.OnChangeData}
                        value={this.state.Notes}
                        id="Notes"
                        name="Notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row borderbtnm pt-3 px-4">
                <div className="col px-4">
                  <h4 className="headertitlepad float-left">To Do Items </h4>
                </div>
              </div>

              <div className="row pb-1 px-3 pt-3">
                <div class="col-lg-12 boxsinput_group px-4">
                  <div class="row pb-3">
                    <div class="col-lg-12">
                      <textarea
                        className="form-control m-input"
                        value={this.state.ToDoItems}
                        placeholder=" "
                        id="ToDoItems"
                        name="ToDoItems"
                        onChange={this.OnChangeData}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="row mb-5">
                <div class="col-lg-12 pull-left px-4">
                  <button
                    id="submit"
                    disabled={this.state.IsSubmitting}
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditActionItemsPage = connect(
  mapState,
  actionCreators
)(EditActionItemsPage);
export { connectedEditActionItemsPage as EditActionItemsPage };
