import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Domain");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetPUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }

    DefaultEmailTrackingDomain(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role
    );
  }, [Search, Page, RowsPerPage,SortedBy,SortField]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  // Default email tracking domain get list
  const DefaultEmailTrackingDomain = (CID, UID, URole) => {
    SetIsLoading(true)
    var MeetingGoalData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search.trim(),
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/default_tracking_domain/DefaultEmailTrackingDomainGet",
      method: "POST",
      data: MeetingGoalData,
    });
    rows1.then((Result) => {
      SetRows(Result.data?.PageData);
      SetRlen(Result.data?.TotalCount);
      SetFlen(Result.data?.TotalCount);
      SetCountPage(Result.data?.PageCount);
      SetIsLoading(false)
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value.trim();
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        SetIsLoading(true)
      }
    }
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      SetIsLoading(true)
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    SetIsLoading(true)
  };

  // Default email tracking domain delete
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a default tracking domains.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: id,
          LastUpdatedBy: UserID,
          LastUpdatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/default_tracking_domain/DefaultEmailTrackingDomainDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Default email tracking domain field deleted successfully.",
                "success"
              );
              SetSearch("")
              SetPage(1)
              SetIsLoading(true)
              document.getElementById("search").value = ""
              DefaultEmailTrackingDomain(ClientID, PUserID, Role);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit btn
  const EditBtn = (id) => {
    history.push("/editdefaulttrackingdomain", id);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    SetSortField(Field)
    SetSortedBy(-SortedBy)
  };
  return (
    <>
    {
      IsLoading == true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : null
    }

      <div>
        <div className="row">
          <div className="col padd-0"></div>
        </div>
        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:
              <input
                type="Search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell width={400}>
                    <a
                      onClick={() => {
                        SortData("Domain");
                      }}
                    >
                      Domain
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("IsWorking");
                      }}
                    >
                      Track Domain Working
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("OpenRateLastWeek");
                      }}
                    >
                      Open % L7
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("OpenRateLastMonth");
                      }}
                    >
                      Open % L30
                    </a>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <a
                      onClick={() => {
                        SortData("CreatedDate");
                      }}
                    >
                      Created Date
                    </a>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows?.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows?.map((row) => (
                    <TableRow key={row.name} row={row}>
                      <TableCell>{row.Domain}</TableCell>
                      <TableCell>
                        {row.IsWorking ? (
                          <span class="btnyes">Yes</span>
                        ) : (
                          <span class="btnno">No</span>
                        )}
                      </TableCell>
                      <TableCell>{row.OpenRateLastWeek} %</TableCell>
                      <TableCell>{row.OpenRateLastMonth} %</TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            EditBtn(row._id);
                          }}
                          className="btn-eyesicon" title="Edit"
                        >
                          <i class="la flaticon-edit-1 edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          className="btn-eyesicon" title="Delete"
                        >
                          <i class="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </>
  );
}
