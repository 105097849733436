import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import parse from "html-react-parser";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicons from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";
import $ from "jquery";
const Fileupload = require("../../../_helpers/fileupload");

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const AutomatorMasterAllTable = (props) => {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SampleExportData, SetSampleExportData] = React.useState([
    { Website: "" },
  ]);
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Cname, SetCname] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [Website, SetWebsite] = React.useState(null);
  const [CompanyName, SetCompanyName] = React.useState(null);
  const [DisabledUpdateMasterAuto, SetDisabledUpdateMasterAuto] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);

  //Show/Hide
  const toggleVisibility = () => {
    setIsVisible(true);
    document.getElementById("delete_csv").style.display = "none";
  };


  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientDetails(GetUserData.ClientID).then((result) => {
      SetCname(result[0]?.Name);
    });
    AutomatorMasterListGet(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
    // Exportdetails(
    //   GetUserData.ClientID,
    //   GetUserData.ParentUserID,
    //   GetUserData.Role
    // );
  }, [Search, Page, RowsPerPage]);

  //get Automater master list
  const AutomatorMasterListGet = (CID, UID, URole) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const AutomatorMasterList = Axios({
      url: CommonConstants.MOL_APIURL + "/CompanyAutomator/GetAutomatorlist",
      method: "POST",
      data: InputParameter,
    });
    AutomatorMasterList.then((Result) => {
      SetRows(Result.data?.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

  // Export details
  const Exportdetails = async(CID, UID, URole) => {
    let InputParameter = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      Page: Page,
      RowsPerPage: RowsPerPage,
    };
    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/CompanyAutomator/AutomatorMasterExportList",
      method: "POST",
      data: InputParameter,
    })
    if(rows1.data.StatusMessage === "SUCCESS"){
      return rows1.data;
    };
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1)
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true)
    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AutomatorMasterList = Axios({
        url: CommonConstants.MOL_APIURL + "/CompanyAutomator/GetAutomatorlist",
        method: "POST",
        data: InputParameter,
      });
      AutomatorMasterList.then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AutomatorMasterList = Axios({
        url: CommonConstants.MOL_APIURL + "/CompanyAutomator/GetAutomatorlist",
        method: "POST",
        data: InputParameter,
      });
      AutomatorMasterList.then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    }
  };

  //delete new code
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to Master Company Automator?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          _id: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/CompanyAutomator/AutomatorMasterDelete",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              toast.success(
                <div className="toastsize">
                  Automator <br /> Automator Message delete successfully.
                </div>
              );
              AutomatorMasterListGet(ClientID, UserID, Role);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              // props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit desing change
  const EditAotumatorBtn = (AotumatorID) => {
    document.getElementById(AotumatorID + "labelbox").style.display = "none";
    document.getElementById(AotumatorID + "text").style.display = "block";
    document.getElementById(AotumatorID + "editicon1").style.display = "none";
    document.getElementById(AotumatorID + "saveicon1").style.display = "block";
    document.getElementById(AotumatorID + "editicon2").style.display = "none";
    document.getElementById(AotumatorID + "saveicon2").style.display = "block";
  };

  const HideEditBtn = (AotumatorID) => {
    document.getElementById(AotumatorID + "labelbox").style.display = "block";
    document.getElementById(AotumatorID + "text").style.display = "none";
    document.getElementById(AotumatorID + "editicon1").style.display = "block";
    document.getElementById(AotumatorID + "saveicon1").style.display = "none";
    document.getElementById(AotumatorID + "editicon2").style.display = "block";
    document.getElementById(AotumatorID + "saveicon2").style.display = "none";
  };

  // Update domain data
  const UpdatePhasesBtn = async (e, id, CompanyID) => {
    SetDisabledUpdateMasterAuto(true)
    var Companys = document.getElementById(id + "text").value;
    var CompanyAutomatorUpdate = {
      ID: CompanyID,
      Company: Companys,
      LastUpdatedBy: CUserID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/CompanyAutomator/AutomatorMasterUpdate",
      method: "POST",
      data: CompanyAutomatorUpdate,
    }).then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Company Automators
            <br />
            Master company updated successfully.
          </div>
        );
        AutomatorMasterListGet(ClientID, UserID, Role);
        HideEditBtn(id);
        SetDisabledUpdateMasterAuto(false)
      } else {
        toast.error(result.data.Message);
      }
    });
  };

  // reset function
  const ResetCsv = () => {
    SetReset(!Reset);
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;

    // this.setState({ files: fileInfo });
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length - 1] != "csv") {
      // this.setState({ reset: true });
      SetReset(true);
      // this.ResetCsv();
      ResetCsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[sep.length - 1];
      // this.setState({ filename: csvfile });
      setfilename(csvfile);

      // Filter out rows with all empty strings
      const filteredData = data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );

      // this.setState({ csvData: data });
      setcsvData(filteredData);
      // this.setState({ DropboxData: data[0].data });
      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  // Bulk delete contact csv
  const DeleteBulkContactCsv = () => {
    var Domains = document.getElementById("DomainMaster").value;
    if (Domains == "notmappedDomain") {
      toast.error(
        <div className="toastsize">
          Company Automators
          <br />
          Bulk delete master domain not mapped!
        </div>
      );
    } else {
      
      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var BulkDeleteAotumasterData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/AutomaterCampany",
          File: _base64,
          ImageName: filename,
          FileData: CsvData,
          Domain: Domains,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/CompanyAutomator/AutoBulkDelete",
          method: "POST",
          data: BulkDeleteAotumasterData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Company Automators
                <br />
                Master website deleted successfully.
              </div>
            );
            document.getElementById("delete_csv").style.display = "none";
            document.getElementById(
              "map_attribute_domain_blacklist"
            ).style.display = "none";
            AutomatorMasterListGet(ClientID, UserID, Role);
          }
        });
      });
    }
  };

  const BulkDeleteContactBtn = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attribute_domain_blacklist").style.display =
        "block";
      ResetCsv();
      DropboxData.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsize">
          Company Automator <br />
          Please select file
        </div>
      );
    }
  };

  const CancelBulkContactBtn = () => {
    ResetCsv();
    document.getElementById("delete_csv").style.display = "none";
    // document.getElementById("map_attributeBlukDeleteSubject").style.display = "none";
    document.getElementById("map_attribute_domain_blacklist").style.display =
      "none";
  };

  const ContactBulkDeleteCsv = () => {
    document.getElementById("delete_csv").style.display = "block";
    document.getElementById("deletesubject_csv").style.display = "none";
    document.getElementById("map_attribute_domain_subjectlist").style.display =
      "none";
    SetTotalDisplayCount(false);
    setcsvData([]);
  };
  // reset function
  // const ResetCsv1 = () => {
  //   SetReset(true);
  // };

  // handle on drop
  const HandleOnDrop1 = (data, fileInfo) => {
    var filename = fileInfo.name;

    // this.setState({ files: fileInfo });
    setfiles(fileInfo);
    var sep = filename.split(".");

    if (sep[sep.length - 1] != "csv") {
      // this.setState({ reset: true });
      SetReset(true);
      // this.ResetCsv();
      ResetCsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[sep.length - 1];
      // this.setState({ filename: csvfile });
      setfilename(csvfile);

      // Filter out rows with all empty strings
      const filteredData = data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );

      // this.setState({ csvData: data });
      setcsvData(filteredData);
      // this.setState({ DropboxData: data[0].data });
      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError1 = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile1 = (data) => {};

  // Bulk delete contact csv
  const SaveBulkContactCsvsubject = () => {
    var Websites = document.getElementById("WebsiteMaster").value;
    var CompanyNa = document.getElementById("CompanyName").value;
    //
    if (Websites == "notmappedsecond") {
      toast.error(
        <div className="toastsize">
          Company Automators
          <br />
          Website is required!
        </div>
      );
    } else if (CompanyNa == "notmappedCaompany") {
      toast.error(
        <div className="toastsize">
          Company Automators
          <br />
          CompanyName is required!
        </div>
      );
    } else {
      SetIsLoading(true)
      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var BulkDeleteAotumasterData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/AutomaterCampany",
          File: _base64,
          ImageName: filename,
          ClientID: ClientID,
          UserID: UserID,
          FileData: CsvData,
          Website: Websites,
          ComapnyName: CompanyNa,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/CompanyAutomator/ImportFileAutomatorCsv",
          method: "POST",
          data: BulkDeleteAotumasterData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Company Automators
                <br />
                Master website deleted successfully.
              </div>
            );
            SetTotalResult(res.data.Total);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetTotalError(res.data.TotalError);
            SetTotalSuccess(res.data.TotalSuccess);
            SetTotalDisplayCount(true);
            // document.getElementById("deletesubject_csv").style.display = "none";
            // document.getElementById("map_attribute_domain_subjectlist").style.display = "none";
            AutomatorMasterListGet(ClientID, UserID, Role);
          }
        });
      });
    }
  };

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "CompanyName") {
      SetCompanyName(value);
    }

    hideCSVHeaderOptions();
  });
  const BulkDeleteContactBtnsubject = () => {
    if (csvData.length != 0) {
      document.getElementById(
        "map_attribute_domain_subjectlist"
      ).style.display = "block";
      ResetCsv();
      DropboxData.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsize">
          Company Automator <br />
          Please select file
        </div>
      );
    }
  };

  const CancelBulkContactBtnsubject = () => {
    ResetCsv();
    SetTotalDisplayCount(false);
    setcsvData([]);
    document.getElementById("deletesubject_csv").style.display = "none";
    // document.getElementById("map_attributeBlukDeleteSubject").style.display = "none";
    document.getElementById("map_attribute_domain_subjectlist").style.display =
      "none";
  };
  const ContactBulkDeleteCsvsubject = () => {
    document.getElementById("deletesubject_csv").style.display = "block";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("map_attribute_domain_blacklist").style.display =
      "none";
    setcsvData([]);
  };

  const convertToCSV = (data) => {
    // Convert array of objects to CSV string
    const header = Object.keys(data[0]).join(",");
    const body = data.map((row) => Object.values(row).join(",")).join("\n");
    return `${header}\n${body}`;
  };


  const exportcsv = async() => {
    try {
     // SetIsloadingEss(true)
     SetIsLoading(true)
    //  document.getElementById("hidelod").style.display = "block";
      const result = await Exportdetails(ClientID,UserID,Role);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          //setexportdta(data);
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download","MasterCompanyAutomator");
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
         // SetIsloadingEss(false)
         SetIsLoading(false)
        // document.getElementById("hidelod").style.display = "none";
        } else {
          toast.error("No data available for export.");
        //  SetIsloadingEss(false)
        SetIsLoading(false)
        //document.getElementById("hidelod").style.display = "none";
        }
      } else {
        toast.error("Failed to export data. Please try again.");
      //  SetIsloadingEss(false)
      //document.getElementById("hidelod").style.display = "none";
      SetIsLoading(false)
      }
    } catch (error) {
      console.error("ExportCsv error:", error);
      toast.error("An error occurred while exporting data.");
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicons} />
        </div>
      ) : (
        <></>
      )}
      <div className="row d-flex align-items-center border-bottom px-2">
        <div className="col">
          <h4 className="headertitlenop float-left">Master Company Names </h4>
        </div>
        <div className="col ">
          <div className="listing-li float-right padb-15">
            <ul>
              <li className="px-0">
                <a
                  // href=""
                  onClick={ContactBulkDeleteCsvsubject}
                  className="btn btngroup m-btn "
                >
                  <i class="la la-icon-font-size-13 la-upload "></i>
                  <span>Import/Update</span>
                </a>
              </li>
              <li>
                <a
                  // href=""
                  onClick={ContactBulkDeleteCsv}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la flaticon-delete-1"></i>
                  <span>Bulk Delete</span>
                </a>
              </li>
              <li className="px-0">
              <a 
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        onClick={exportcsv}
                      >
                        <i className="la la-icon-font-size-13 la-download"></i>
                        <span>
                         
                            Export
                    
                        </span>
                      </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="pt-4" id="deletesubject_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle mb-1">
              Import Company Master Details{" "}
            </h3>
            {/* <span class="m-alert alert-paragraph">
                <i class="la la-info-circle"></i> A sample file is available for
                download, <a href="">please click here</a>
              </span> */}
            <div className="uplodfilesbox pt-4 pb-2">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop1}
                onError={HandleOnError1}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile1}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add company master data from csv file to bulk upload.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2 border-top pt-3">
            <div className="alignright">
              <a
                onClick={BulkDeleteContactBtnsubject}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a
                onClick={CancelBulkContactBtnsubject}
                className="btn btn-secondary"
              >
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody portletshadow"
        id="map_attribute_domain_subjectlist"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="Website">
                    Website
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="WebsiteMaster"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Website == null ? DropboxData.indexOf("Website") : Website
                  }
                >
                  <option value="notmappedsecond">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            {/* ----------------------- */}
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyName">
                    Company Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyName == null
                      ? DropboxData.indexOf("Company Name")
                      : CompanyName
                  }
                >
                  {CompanyName}
                  <option value="notmappedCaompany">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="col-lg-12">
            <a
              onClick={SaveBulkContactCsvsubject}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div>
        </div>
      </div>

      {TotalDisplayCount == true ? (
        <div className="portletbody px-4" id=" ">
          <div className="row col-xs-12">
            <h4 className="headertitle float-left">Results</h4>
          </div>

          <div className="col-xs-12">
            <div class="row colfive">
              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-primary">
                          {TotalResult}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-success">
                          {TotalSuccess}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total success</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-danger">
                          {TotalError}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total error</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-warning">
                          {TotalDuplicate}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total Duplicate</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* <div className="portletbody" id="import_csv">
        {isVisible2 && (
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <h3 className="uploadheadingtitle mb-1">
                Import Master Website Details
              </h3>
              <span class="m-alert alert-paragraph">
                <i class="la la-info-circle"></i> A sample file is available for
                download, <a href="">please click here</a>
              </span>
              <div className="uplodfilesbox mt-3">
                <CSVReader
                  accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                >
                  <span>Select .CSV file</span>
                  <p>Add master website data from csv file to bulk delete.</p>
                </CSVReader>
              </div>
            </div>
            <div className="col-xl-8 offset-xl-2 border-top">
              <div className="alignright">
                <a onClick={BulkDeleteContactBtn} className="btn btn-primary btnColor">
                  <i className="la la-upload"></i> Upload
                </a>
                <a onClick={CancelBulkContactBtn} className="btn btn-secondary">
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          </div>
        )}
      </div> */}
      <div className="pt-4" id="delete_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle mb-1">
              Import Master Website Details
            </h3>
            <span class="m-alert alert-paragraph">
              <i class="la la-info-circle"></i> A sample file is available for
              download,{" "}
              <CSVDownloader
                data={SampleExportData}
                filename={"Sample"}
                bom={true}
              >
                <a>please click here</a>
              </CSVDownloader>
            </span>
            <div className="uplodfilesbox pt-4 pb-2">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add master website data from csv file to bulk delete.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2 border-top pt-3">
            <div className="alignright">
              <a
                onClick={BulkDeleteContactBtn}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={CancelBulkContactBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody portletshadow"
        id="map_attribute_domain_blacklist"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="Domain">
                    Domain
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="DomainMaster"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  // value={0}
                >
                  <option value="notmappedDomain">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="col-lg-12">
            <a
              onClick={DeleteBulkContactCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25 px-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered mx-2">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Website
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Company");
                    }}
                  >
                    Company Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompanyLength");
                    }}
                  >
                    Length
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow>
                    <a
                      href={`http://${row?.Domain}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline', color: 'inherit' }}
                    >
                      <u>{row?.Domain}</u>
                    </a>
                      <TableCell>
                        <label id={row._id + "labelbox"}>{row.Company} </label>{" "}
                        <input
                          type="textbox"
                          id={row._id + "text"}
                          class="form-control m-input"
                          style={{ display: "none" }}
                          defaultValue={row.Company}
                          autocomplete="off"
                        />
                      </TableCell>
                      <TableCell>{row?.CompanyLength}</TableCell>
                      <TableCell className="d-flex save-flex">
                        <a
                          title="Edit"
                          onClick={() => {
                            EditAotumatorBtn(row._id);
                          }}
                          id={row._id + "editicon1"}
                          className="btn-eyesicon"
                        >
                          <i class="la flaticon-edit-1 edit-icon"></i>
                        </a>
                        <a
                          title="Delete"
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          id={row._id + "editicon2"}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                        <button
                          title="Save"
                          onClick={(e) => {
                            UpdatePhasesBtn(
                              e,
                              row._id,
                              row.Automator_CompanyID
                            );
                          }}
                          style={{ display: "none" }}
                          id={row._id + "saveicon1"}
                          className="btn-eyesicon"
                          disabled={DisabledUpdateMasterAuto}
                        >
                          <i className="la la-save"></i>
                        </button>
                        <a
                          title="Cancel"
                          onClick={() => {
                            HideEditBtn(row._id);
                          }}
                          style={{ display: "none" }}
                          id={row._id + "saveicon2"}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-cancel"></i>
                        </a>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row px-2">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default AutomatorMasterAllTable;
