import React from "react";
import Axios from "axios";
import Select from 'react-select';


import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../../images/loading.gif";

class CAddContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [],  
      fields: {},
      errors: {},
      statusAvailable: null,
      data: [],
      rows: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      data1: [],
      rows1: [],
      rlen1: 0,
      flen1: 0,
      countpage1: 0,
      ClientID: null,
      UserID: null,
      ContactSourceData: [],
      ClientPOCData: [],
      ReasonUnqualifiedData: [],
      ContactTagData: [],
      ContactOwnerData: [],
      ContactSalesDevlopMenData: [],
      ContactCustomFieldData: [],
      checked: false,
      fields: {},
      errors: {},
      FirstNameAvailable: null,
      LastNameAvailable: null,
      TitleNameAvailable: null,
      ContactTagSelectArray:[],
      IsWebsite: true,
      WebsiteNotNull: false,
      ContactTagsNamesArray:[],
      TagArray:[],
      IsInValidWebsite:false,
      IsValidEmail:false,
      CUserID:null
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title = "Add SalesHive Contact | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
    }
    this.ContactInfoGetData()
    

    document.getElementById("hideloding").style.display = "none";

  }
  

  handleChangecheck = (e) => {
    const { checked } = e.target;
    this.setState({
      checked: checked,
    });
  };

async  FromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};

    var FirstName = document.getElementById("FirstName").value.trim();
    var Email = document.getElementById("Email").value.trim();
    // var Company = document.getElementById("Company").value.trim();
    var Website = document.getElementById("Website").value.trim();
    let IsExistWebsite = await this.CheckWebSite(Website);
    let IsExistEmail = await this.EmailValidationCheck(Email);


    if (FirstName == "") {
      formIsValid = false;
      toast.error("Please enter first name")
    }
    
    if (Website == "" && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter Website")
    }
    if (Email == "" && Website.length > 0 && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter email")
    }
    if (IsExistWebsite == true) {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
      toast.error("Please enter First name, Last name and Company OR First name and Email")
    }
    if (IsExistEmail == true) {
      formIsValid = false;
      // errors["Website"] = "Invalid email!";
      toast.error("Invalid email!")
    }


    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChangeWebsitecheck() {
    var Website = document.getElementById("Website").value.trim();
    if (Website != "") {
      let errors = {};
      this.state.errors.Website = null;
      this.CheckWebSite(Website)
      errors["Website"] = "Invalid website!";
    } else {
      // this.state.statusAvailable = null;
      this.state.errors.Website = null;
    }
  }
  // handleChange(field, e) {
  //   let fields = this.state.fields;
  //   fields[field] = e.target.value;
  //   this.setState({ fields });
  //   if (fields.FirstName != "") {
  //     this.state.errors.FirstName = null;
  //     this.state.FirstNameAvailable = null;
  //     this.CheckExistContactName(e.target.value);
  //   } else {
  //     this.state.FirstNameAvailable = null;
  //     this.state.errors.FirstName = null;
  //   }

  //   if (fields.LastName != "") {
  //     this.state.errors.LastName = null;
  //     this.state.LastNameAvailable = null;
  //     this.CheckExistContactName(e.target.value);
  //   } else {
  //     this.state.LastNameAvailable = null;
  //     this.state.errors.LastName = null;
  //   }
  // }

  backbtn(){
    if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/cviewcontacts",
        state: { data: this.props.location.state?.data},
      });
    }else{
      history.push("/ccontacts");
    }

  }

  // // for last name
  // handleChangeLastname(field, e) {
  //   let fields = this.state.fields;
  //   fields[field] = e.target.value;
  //   this.setState({ fields });
  //   if (fields.LastName != "") {
  //     this.state.errors.LastName = null;
  //     this.state.LastNameAvailable = null;
  //     this.CheckExistLastContactName(e.target.value);
  //   } else {
  //     this.state.LastNameAvailable = null;
  //     this.state.errors.LastName = null;
  //   }
  // }
  // for title check
  // handleChangeTitlename(field, e) {
  //   let fields = this.state.fields;
  //   fields[field] = e.target.value;
  //   this.setState({ fields });

  //   if (fields.Title != "") {
  //     this.state.errors.Title = null;
  //     this.state.TitleNameAvailable = null;
  //     this.CheckExistTitleContact(e.target.value);
  //   } else {
  //     this.state.TitleNameAvailable = null;
  //     this.state.errors.Title = null;
  //   }
  // }

  // CheckExistContactName(FirstName) {
  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     FirstName: FirstName,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/contacts/ContactExists",
  //     method: "POST",
  //     data: str_in,
  //   }).then((resdata) => {
  //     if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.Data.length > 0) {
  //         this.setState({ FirstNameAvailable: resdata.data.Data[0].FirstName });
  //       }
  //     }
  //   });
  // }
  // // Last Name
  // CheckExistLastContactName(LastName) {
  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     LastName: LastName,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/contacts/ContactLastNameExists",
  //     method: "POST",
  //     data: str_in,
  //   }).then((resdata) => {
  //     if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.Data.length > 0) {
  //         this.setState({ LastNameAvailable: resdata.data.Data[0].LastName });
  //       }
  //     }
  //   });
  // }

  // // Check title name
  // CheckExistTitleContact(Title) {
  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     Title: Title,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/contacts/ContactTitleExists",
  //     method: "POST",
  //     data: str_in,
  //   }).then((resdata) => {
  //     if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.Data.length > 0) {
  //         this.setState({ TitleNameAvailable: resdata.data.Data[0].Title });
  //       }
  //     }
  //   });
  // }

  // check website name
  async CheckWebSite(Email) {
      // let fields = this.state.fields;
      // fields[field] = e.target.value;
      // this.setState({ fields });
      let Errors = {};
      var regex = new RegExp(
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); 
        // let WebsiteGet = document.getElementById("Website").value;
        if (!regex.test(Email) && Email!="") {
          // this.setState({ IsWebsite: false });
          Errors["Website"] = "Invalid website";
          this.setState({ errors: Errors });
          this.setState({ IsInValidWebsite: true });
          return true
        } else {
          // this.setState({ IsWebsite: true });
          this.setState({ WebsiteNotNull: true });
          return false;
        }
    }

  // cheack email valid
  async EmailValidationCheck (Email) {
      let regexp =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let Errors = {};
      if (!regexp.test(Email) && Email != "") {
        Errors["Email"] = "invalid email.";
        this.setState({ IsValidEmail: true });
        this.setState({errors:Errors});
        return true;
      } else {
        this.setState({ IsValidEmail: false });

        return false;
      }
    };

  //     // Regex for Url Validation
  //     WebsitePatternValidation = (Website) => {
  //   var regex = new RegExp(
  //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
  //       "((\\d{1,3}\\.){3}\\d{1,3}))" +
  //       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
  //       "(\\?[;&a-z\\d%_.~+=-]*)?" +
  //       "(\\#[-a-z\\d_]*)?$",
  //     "i"
  //   ); //validation
  //   return regex.test(Website);
  // };
  // check info
  async ContactInfoGetData() {

    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      Role:Details.Role
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    this.setState({
      ContactSourceData: rows.data?.ContactSourceData,
      ClientPOCData: rows.data?.ClientPOCData,
      ReasonUnqualifiedData: rows.data?.ReasonUnqualifiedData,
      ContactTagData: rows.data?.ContactTagData,
      ContactOwnerData: rows.data?.ContactOwnerData,
      ContactSalesDevlopMenData: rows.data?.ContactSalesDevlopMenData,
      ContactCustomFieldData: rows.data?.ContactCustomFieldData,
    });
  }


  handleChangeContactOption = (val) => {
    var ContactTagIDArray = [];
    var ContactTagNameArray = [];
    var TagsArray = [];
    val?.map((item) => {
      ContactTagIDArray.push(item.cid);
    })
    this.setState({
      ContactTagSelectArray:ContactTagIDArray
    })
    // contact tag name
    val?.map((item) => {
      ContactTagNameArray.push(item.label);
    })
    this.setState({
      ContactTagsNamesArray:ContactTagNameArray
    })
    // tag id and name
    val?.map((item) => {
      TagsArray.push({
        ContactTagID:item.cid,
        TagName:item.label
      })
    })
    this.setState({
      TagArray:TagsArray
    })
   

  };
 async savebtn(e) {
  document.getElementById("hideloding").style.display = "block";
    e.preventDefault();
    e.currentTarget.disabled = true;
    var FormValidationFlag = await this.FromValidation();
    if(FormValidationFlag === true){
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Email = document.getElementById("Email").value;
      var Title = document.getElementById("Title").value;
      var Company = document.getElementById("Company").value;
      var CompanyNameLong = document.getElementById("CompanyNameLong").value;
      var Website = document.getElementById("Website").value;
      var Industry = document.getElementById("Industry").value;
      var Address1 = document.getElementById("Address1").value;
      var Address2 = document.getElementById("Address2").value;
      var City = document.getElementById("City").value;
      var State = document.getElementById("State").value;
      var Zip = document.getElementById("Zip").value;
      var LinkedInURL = document.getElementById("LinkedInURL").value;
      var Country = document.getElementById("Country").value;
      var ContactSourceID = document.getElementById("ContactSourceID").value;
      var ContactOwnerID = document.getElementById("ContactOwnerID").value;
      var ClientPOCID = document.getElementById("ClientPOCID").value;
      var ContactOwnerID = document.getElementById("ContactOwnerID").value;
      var ReasonUnqualifiedID = document.getElementById(
        "ReasonUnqualifiedID"
      ).value;
      var ContactCategory = document.getElementById("ContactCategory").value;
      var AccountCategory = document.getElementById("AccountCategory").value;
      var MobilePhone = document.getElementById("MobilePhone").value;
      var DirectPhone = document.getElementById("DirectPhone").value;
      var CompanyPhone = document.getElementById("CompanyPhone").value;
      var CompanyRevenue = document.getElementById("CompanyRevenue").value;
      var CompanyEmployeeCount = document.getElementById(
        "CompanyEmployeeCount"
      ).value;
      var FollowUpDate = document.getElementById("FollowUpDate").value;
      var ColdCallersID = document.getElementById("ColdCallersID").value;
      var SalesRep = document.getElementById("SalesRep").value;
      var OtherPhone1 = document.getElementById("OtherPhone1").value;
      var OtherPhone2 = document.getElementById("OtherPhone2").value;
      var ExternalAccountID = document.getElementById("ExternalAccountID").value;
      var ExternalContactID = document.getElementById("ExternalContactID").value;
      var ExternalField1 = document.getElementById("ExternalField1").value;
      var ExternalField2 = document.getElementById("ExternalField2").value;
      var ExternalField3 = document.getElementById("ExternalField3").value;
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
      // var ContactTagID = document.getElementById("ContactTagID").value;
      var Domain = Email.substring(Email.lastIndexOf("@") + 1);
  
      
      var ContactCustomFieldValueArray = [];
      this.state.ContactCustomFieldData.map((contactvalue) => {
        
        var customval = document.getElementById(contactvalue._id).value;
        var conid = contactvalue._id;
        if (customval.length !== 0) {
          ContactCustomFieldValueArray.push({
            customval,
            conid,
          });
        }
      });
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Title: Title,
        Company: Company,
        CompanyNameLong: CompanyNameLong,
        Website: Website,
        LinkedInURL: LinkedInURL,
        Industry: Industry,
        Address1: Address1,
        Address2: Address2,
        City: City,
        State: State,
        Zip: Zip,
        Country: Country,
        ContactSourceID: ContactSourceID,
        ContactOwnerID: ContactOwnerID,
        ClientPOCID: ClientPOCID,
        ReasonUnqualifiedID: ReasonUnqualifiedID,
        ContactCategory: ContactCategory,
        AccountCategory: AccountCategory,
        MobilePhone: MobilePhone,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        CompanyRevenue: CompanyRevenue,
        CompanyEmployeeCount: CompanyEmployeeCount,
        FollowUpDate: FollowUpDate,
        SalesRepID: ColdCallersID,
        SalesRep: SalesRep,
        ContactTagID: this.state.ContactTagSelectArray,
        ContactTags:this.state.ContactTagsNamesArray,
        Tagname:this.state.TagArray,
        OtherPhone1: OtherPhone1,
        OtherPhone2: OtherPhone2,
        ExternalAccountID: ExternalAccountID,
        ExternalContactID: ExternalContactID,
        ExternalField1: ExternalField1,
        ExternalField2: ExternalField2,
        ExternalField3: ExternalField3,
        IsDoNotCallContact: this.state.checked,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        IsDeleted: false,
        EmailDomain: Domain,
        ContactsCustomFieldArray: ContactCustomFieldValueArray,
        AccountTypeID:this.props.location.state.data
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactsAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {


          toast.success(
            <div>
              Add SalesHive Contacts.<br/>
              Contact added successfully.
            </div>
          );
          document.getElementById("hideloding").style.display = "none";
          if (this.props.location.state["Pagename"] == "AccountContact") {
            history.push({
              pathname: "/cviewcontacts",
              state: { data: this.props.location.state.data },
            });
          }else{
            history.push("/ccontacts");
          }

        } else {
          toast.error(res.data.Message);
          document.getElementById("hideloding").style.display = "none";
        }
      });
    }
    else{
      document.getElementById("submit").disabled = false;
      document.getElementById("hideloding").style.display = "none";
    }
  }

  render() {
  let contactoptions = this.state.ContactTagData.map((val) => {
    return {value :val.Tag, label: val.Tag, cid: val._id}
  })
    return (
      <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" />  
          <div className="bodyhome">
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header py-4">
                    Add SalesHive Contact
                  </h3>
                </div>
              </div> 
            <div className="paddcols">
              <div className="col-lg-12 pl-0 py-4 border-bottom mb-2">
                    <h3 className="addpotential_header mb-0">
                    Contact Info
                    </h3>
                  </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        placeholder="FirstName"
                        type="text"
                        // onChange={this.handleChange.bind(this, "FirstName")}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["FirstName"]}
                      </span> */}
                      {/* {this.state.FirstNameAvailable && (
                        <span style={{ color: "red" }}>
                          First name already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        placeholder="LastName"
                        // onChange={this.handleChangeLastname.bind(
                        //   this,
                        //   "LastName"
                        // )}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["LastName"]}
                      </span>
                      {this.state.LastNameAvailable && (
                        <span style={{ color: "red" }}>
                          Last name already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        placeholder="Email"
                        name="Email"
                        type="text"
                        // onChange={this.handleChange.bind(this, "Email")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        placeholder="Title"
                        type="text"
                        // onChange={this.handleChangeTitlename.bind(
                        //   this,
                        //   "Title"
                        // )}
                      />

                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span> */}
                      {/* {this.state.TitleNameAvailable && (
                        <span style={{ color: "red" }}>
                          Title already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        placeholder="Company"
                        name="Company"
                        type="text"
                        // onChange={this.handleChange.bind(this, "Company")}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Company"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Name Long
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="CompanyNameLong"
                        name="CompanyNameLong"
                        placeholder="Company Name Long"
                        type="text"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "CompanyNameLong"
                        // )}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["CompanyNameLong"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        id="Website"
                        name="Website"
                        placeholder="Website"
                        type="text"
                        onBlur={this.handleChangeWebsitecheck.bind(this, "Website")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Website"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInURL"
                        name="LinkedInURL"
                        placeholder="Linked In Url"
                        type="text"
                        // onChange={this.handleChange.bind(this, "LinkedInURL")}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["LinkedInURL"]}
                      </span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Industry
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Industry"
                        placeholder="Industry"
                        type="text"
                        id="Industry"
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Industry"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Address 1
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Address1"
                        placeholder="Address 1"
                        type="text"
                        id="Address1"
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Address1"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Address 2
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Address2"
                        placeholder="Address 2"
                        type="text"
                        id="Address2"
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Address2"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      City
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        placeholder="City"
                        name="City"
                        id="City"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      State
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="State"
                        id="State"
                        placeholder="State"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Zip
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Zip"
                        id="Zip"
                        type="text"
                        placeholder="Zip"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Country
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Country"
                        name="Country"
                        type="text"
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Source
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactSource"
                        id="ContactSourceID"
                        
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ContactSourceID"
                        // )}
                      >
                        <option value="">--Select--</option>
                        {this.state.ContactSourceData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactSourceID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Owner
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactOwner"
                        id="ContactOwnerID"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ContactOwnerID"
                        // )}
                      >
                        <option value="">--Select--</option>
                        {this.state.ContactOwnerData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactOwnerID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Client POC
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="Client POC"
                        id="ClientPOCID"
                        // onChange={this.handleChange.bind(this, "ClientPOCID")}
                      >
                        <option value="">--Select--</option>
                        {this.state.ClientPOCData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientPOCID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Reason Unqualified
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ReasonUnqualified"
                        id="ReasonUnqualifiedID"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ReasonUnqualifiedID"
                        // )}
                      >
                        <option value="">--Select--</option>
                        {this.state.ReasonUnqualifiedData.map((value) => (
                          <option value={value._id}>
                            {value.Reason}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ReasonUnqualifiedID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Category
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="ContactCategory"
                        placeholder="Contact Category"
                        id="ContactCategory"
                        type="text"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ContactCategory"
                        // )}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactCategory"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Account Category
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="AccountCategory"
                        placeholder="Account Category"
                        id="AccountCategory"
                        type="text"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "AccountCategory"
                        // )}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["AccountCategory"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Mobile Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="MobilePhone"
                        name="MobilePhone"
                        placeholder="Mobile Phone"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="DirectPhone"
                        placeholder="Direct Phone"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="DirectPhoneExt"
                        placeholder="Direct Phone Ext"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyPhone"
                        placeholder="Company Phone"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Revenue
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyRevenue"
                        placeholder="Company Revenue"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Employee Count
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyEmployeeCount"
                        placeholder="Employee Count"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Follow Up Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="FollowUpDate"
                        type="date"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Sales Development Reps
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ColdCallersID"
                        id="ColdCallersID"
                      >
                        <option value="">--Select--</option>
                        {this.state.ContactSalesDevlopMenData.map((value) => (
                          <option value={value._id}>
                            {value.ColdCallersName} {value.LastName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Sales Rep
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="SalesRep"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Tag
                    </label>
                    <div className="col-lg-7">
                    <Select
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      options={contactoptions}
                                      id="ContactTagID"
                                      isMulti
                                      onChange={(val) => this.handleChangeContactOption(val)}
           

                />
                      {/* <select
                        className="form-control  m-input"
                        name="Tag"
                        id="ContactTagID"
                      >
                        <option value="">--Select--</option>
                        {this.state.ContactTagData.map((value) => (
                          <option value={value.ContactTagID}>
                            {value.Tag}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Other Phone 1
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="OtherPhone1"
                        placeholder="Other Phone 1"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Other Phone 2
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="OtherPhone2"
                        placeholder="Other Phone 2"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Account ID
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalAccountID"
                        placeholder="External Account ID"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Contact ID
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalContactID"
                        placeholder="External Contact ID"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 1
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField1"
                        placeholder="External Field 1"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 2
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField2"
                        placeholder="External Field 2"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 3
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField3"
                        placeholder="External Field 3"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      <input
                        class=""
                        onChange={(e) => this.handleChangecheck(e)}
                        defaultChecked={this.state.checked}
                        type="checkbox"
                        id="IsDoNotCallContact"
                      />{" "}
                      Do Not Call Contact
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div> 
              </div>


              
              <div className="col-lg-12 pl-0 py-4 border-top border-bottom mt-4">
                    <h3 className="addpotential_header mb-0">
                    Custom Contact Field
                    </h3>
                  </div>
                  <div className="row mt-4 mb-4 px-2">
              {this.state.ContactCustomFieldData.map((value, index) => {
                    return (
                     
                
                        <div className="col-lg-6 py-1 px-2">
                          <div
                            className="row max-cols d-flex align-items-center"
                            key={index}
                          >
                            <label className="col-lg-4 view-lable-meet potential_text">{value.FieldName}</label>
                            <div className="col-lg-7">
                              <input
                                class="form-control m-input"
                                // value={value._id}
                                type="text"
                                id={value._id}
                              />
                            </div>
                          </div>
                        </div>
                       
                  
                    );
                  })}
              </div>
              

              <div className="row my-3 border-top pt-4 pb-2">
                <div className="col-lg-12 pull-left">
                  <a
                    id="submit"
                    href="javascript:void(0);"
                    className="btn btn-primary btn-lightgreen mr-1"
                    value="Save"
                    onClick={this.savebtn}
                  >
                    <i className="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    className="btn btn-secondary"
                    value="Save"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* EDIT PAGE AND NOTES END */}

        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddContactPage = connect(
  mapState,
  actionCreators
)(CAddContactPage);
export { connectedCAddContactPage as CAddContactPage };
