
import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../../_components/user/footer/footer";
import ClientPOC from '../../../_components/user/ContactSettings/ClientPoc/ClientPoc';
import { CommonConstants } from '../../../_constants/common.constants';
import { history } from '../../../_helpers';




class ClientpocPage extends React.Component {

    addclientpoc(){
       history.push("/addclientpoc")
    }
    setOrderclientpoc(){
        history.push("/setorderclientpoc")
    }

    render() {
        return (
            <>


                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col px-3">
                            <h4 className="headertitlenop px-1 float-left py-3 mb-0 mt-1">Client POC</h4> 
                        </div> 
                        <div className="col px-4">
                            <div className="listing-li float-right padb-15 px-1">
                                <ul>
                                <li>
                                    <a onClick={this.setOrderclientpoc.bind(this)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="la la-icon-font-size-13 flaticon-settings"></i>
                                        <span>Set Order</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a onClick={this.addclientpoc.bind(this)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                        <span>Add</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="whiterow px-0">
                        <div className="row">
                            <div className="col px-5">
                            <ClientPOC />
                            </div>
                        </div>      
                    </div>

                </div> 
                </div>
                <Footer /> 
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientpocPage = connect(mapState, actionCreators)(ClientpocPage);
export { connectedClientpocPage as ClientpocPage };