import React from 'react';
import { connect } from 'react-redux';
import{userActions}from '../../../_actions'
import Sidebar from '../../../_components/user/NavigationSidebar/Sidebar';
import { history } from '../../../_helpers';
import { CommonConstants } from '../../../_constants/common.constants';
import CountryBlacklist from '../../../_components/user/CountryBlackList/CountryBlackList';

class IndexCountryBlacklistPage extends React.Component {

    Addcountryblacklist(){
        history.push('/addcountryblacklist')
    
    }

  
    render() {
        
        return (
            <>
             <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left">Country Blacklist</h4>
                        </div>

                        <div className="col">
                            <div className="listing-li float-right padb-15">
                                <ul>
                                    <li>
                                    <a onClick={this.Addcountryblacklist.bind(this)}className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                        <span>Add</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> 

                    <div class="paddcols"> 
                        <div className="row">
                            <div className="col">
                                    <CountryBlacklist />
                            </div>
                        </div>      
                    </div> 
                </div> 
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedIndexCountryBlacklistPage = connect(mapState, actionCreators)(IndexCountryBlacklistPage);
export { connectedIndexCountryBlacklistPage as IndexCountryBlacklistPage };