import React from "react";
import { CommonConstants } from "../_constants/common.constants";
import { connect } from "react-redux";
import i18n from "../i18n";
import logo from "../images/SalesHive.png";
import logobgs from "../images/Loginvector.svg";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { history } from "../_helpers/history";
const Uvalidation = require("../_helpers/user-validation");
const EmailTemplete = require("../_helpers/email-templete");
import { UpdateUserDetails, GetUserDetails } from "../_helpers/Utility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Alert from '@mui/material/Alert';
import loadingicon from "../images/loading.gif";

const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      UserExist: null,
      AlertIncorrect: false,
      forgotpassOpen: false,
      ForgetUserNotFound: false,
    };

    // this.handleChange = this.handleChange.bind(this);
    // this.submitteddata = this.submitteddata.bind(this);
    this.forgotClick = this.forgotClick.bind(this);
    this.submitforgot = this.submitforgot.bind(this);
    this.cancleforgot = this.cancleforgot.bind(this);
    // this.submitforgot = this.submitforgot.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.rememberme = this.rememberme.bind(this);
    // this.handleforgotpassClose = this.handleforgotpassClose.bind(this);

    this.login = this.login.bind(this);
  }

  ValidateEmail(email) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(email);
  }

  FromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};
    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }

    if (!fields["Password"]) {
      formIsValid = false;
      errors["Password"] = "Please enter password";
    }
    if (this.state.UserExist != null) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Email != "") {
      this.state.errors.Email = null;
      this.state.UserExist = null;
      this.CheckExistUser(fields.Email);
    } else {
      this.state.UserExist = null;
      this.state.errors.Email = null;
    }
    if (fields.Password != "") {
      this.state.errors.Password = null;
    }
  }

  CheckExistUser(UserName) {
    var InputParameters = {
      UserName: UserName,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/login/userexist",
      method: "POST",
      data: InputParameters,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length == 0) {
          this.setState({ UserExist: "User not exist" });
          document.getElementById("hideloding").style.display = "none";
        }
        // else
        // {
        //     forgotpass_u.style.display = "block";
        //     document.getElementById("f_email").value = "";
        // }
      }
    });
  }

  componentDidMount() {
    document.title = "Sign In | SalesHive"
    forgot.style.display = "none";
    f_email_e.style.display = "none";
    e_user.style.display = "none";
    email_user.style.display = "none";
    a_login.style.display = "none";
    forgotpass_u.style.display = "none";
    f_email_inc.style.display = "none";
    this.setState({ ForgetUserNotFound: false });
    //u_login.style.display = "none";
  }
  // handleClose(){
  //     //u_login.style.display = "none";
  //     this.setState({ open: false });
  // }
  // handleforgotpassClose(){
  //     //u_login.style.display = "none";
  //     this.setState({ forgotpassOpen: false });
  // }
  // rememberme(){
  //     localStorage.setItem('remember',new Date());
  // }
  forgotClick() {
    let frogotpass = document.querySelector(".heading");

    frogotpass.parentNode.style.display = "none";
    forgot.style.display = "block";
    f_email_e.style.display = "none";
    const mainTtile = CommonConstants.Title;
    document.title = "Forgot Passwor" + mainTtile;
    forgotpass_u.style.display = "none";
  }
  cancleforgot() {
    forgot.style.display = "none";
    let frogotpass = document.querySelector(".heading");
    frogotpass.parentNode.style.display = "block";
    f_email_e.style.display = "none";
    e_user.style.display = "none";
    document.getElementById("f_email").value = "";
    const mainTtile = CommonConstants.Title;
    document.title = "Sign in" + mainTtile;
  }
  CloseElse() {
    this.setState({ ForgetUserNotFound: true });

    document.getElementById("hideloding").style.display = "none";
  }

  submitforgot() {
    document.getElementById("hideloding").style.display = "block";
    // this.setState({ ForgetUserNotFound: false });

    f_email_e.style.display = "none";
    e_user.style.display = "none";
    forgotpass_u.style.display = "none";
    var email = document.getElementById("f_email").value;

    var tmp_token =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    //    var forgot_token = CryptoJS.MD5(tmp_token).toString();
    var forgot_token = CryptoJS.AES.encrypt(
      JSON.stringify(forgot_token),
      "my-secret-key@123"
    ).toString();
    var EmailData = EmailTemplete.userForgotpassword(forgot_token, email);
    if (email == "") {
      f_email_e.style.display = "block";
      f_email_inc.style.display = "none";

      document.getElementById("hideloding").style.display = "none";
    } else if (!this.ValidateEmail(email)) {
      f_email_inc.style.display = "block";
      f_email_e.style.display = "none";
      document.getElementById("hideloding").style.display = "none";
    } else {
      f_email_e.style.display = "none";
      f_email_inc.style.display = "none";

      var InputParameters = {
        UserName: email,
        Forgot_Token: forgot_token,
        Html: EmailData,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/login/forgotpassword",
        method: "POST",
        data: InputParameters,
      }).then((resdata) => {
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.data.length > 0) {
            forgotpass_u.style.display = "block";
            document.getElementById("f_email").value = "";
            forgot.style.display = "none";
            let frogotpass = document.querySelector(".heading");
            frogotpass.parentNode.style.display = "block";
            f_email_e.style.display = "none";
            e_user.style.display = "none";
            document.getElementById("f_email").value = "";
            const mainTtile = CommonConstants.Title;
            document.title = "Sign in" + mainTtile;
            document.getElementById("hideloding").style.display = "none";
            var InputParameters = {
              email: email,
              html: EmailData,
              subject: "Reset Password",
            };
            Axios({
              url: CommonConstants.MOL_APIURL + "/user/emailsend",
              method: "POST",
              data: InputParameters,
            }).then((res) => {
              document.getElementById("forgot_message").innerText =
                "Thank you, Please check your email for password reset link.";
            });
          } else {
            this.CloseElse();
          }
        } else {
          document.getElementById("hideloding").style.display = "none";
          // this.setState({ forgotpassOpen: true });
        }
      });
    }
  }

  async login(e) {
    e.preventDefault();
    if (this.FromValidation()) {
      var email = document.getElementById("email").value;
      var cipherpassword = document.getElementById("password").value;
      var password = CryptoJS.AES.encrypt(cipherpassword,REACT_APP_ENCRYPTION_SALT).toString();

      var InputParameters = {
        Username: email,
        Password: password,
      };
      let res = await Axios({
        url: CommonConstants.MOL_APIURL + "/login/userlogin",
        method: "POST",
        data: InputParameters,
      });

      console.log(res);
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.data.length > 0) {
          let Is2FA = res.data.data[0]?.IsTwoStepVerification;
          var ClientID = await this.GetClientListForTopDropDown(
            res.data.data[0].ChildUserID,
            res.data.data[0].ParentUserID,
            res.data.data[0].Role
          );
          console.log(res.data.data[0], "res.data.data1232");
          var logindetails = res.data.data[0];
          
          if (logindetails.Role === "Admin") {
            var ObjData = {
              ChildUserID: logindetails.ChildUserID,
              ParentUserID:
                logindetails.ParentUserID == null
                  ? logindetails.ChildUserID
                  : logindetails.ParentUserID,
              ClientID: ClientID,
              Username: logindetails.Username,
              Role: logindetails.Role,
            };
            if (ObjData.Role == "Admin") {
              if (Is2FA) {
                await this.SendTwoStepVerificationEmail(
                  Is2FA,
                  logindetails.ChildUserID,
                  logindetails.Role,
                  ObjData,
                  "/kpis",
                  email
                );
                
              } else {
                localStorage.setItem("LoginData", JSON.stringify(ObjData));
                history.push("/kpis");

              }
            }
          } else if (logindetails.Role == "User") {
            var ObjData = {
              ChildUserID: logindetails.ChildUserID,
              ParentUserID: logindetails.ParentUserID,
              ClientID: ClientID,
              Username: logindetails.Username,
              Role: logindetails.Role,
            };

            if (ObjData.Role == "User") {
              if (Is2FA) {
                this.SendTwoStepVerificationEmail(
                  Is2FA,
                  logindetails.ChildUserID,
                  logindetails.Role,
                  ObjData,
                  "/bookedmeetings",
                  email
                );
                
              } else {
            localStorage.setItem("LoginData", JSON.stringify(ObjData));

                history.push("/bookedmeetings");
              }
            }
          } else if (logindetails.Role == "Client") {
            var ObjData = {
              ChildUserID: logindetails.ChildUserID,
              ParentUserID: logindetails.ParentUserID,
              ClientID: ClientID,
              Username: logindetails.Username,
              Role: logindetails.Role,
            };
            if (ObjData.Role == "Client") {
              console.log(Is2FA)
              if (Is2FA) {
                this.SendTwoStepVerificationEmail(
                  Is2FA,
                  logindetails.ChildUserID,
                  logindetails.Role,
                  ObjData,
                  "/cbookedmeetings",
                  email
                );
                
              } else {
                localStorage.setItem("LoginData", JSON.stringify(ObjData));
                history.push("/cbookedmeetings");

              }
            }
          } else if (logindetails.Role == "Responder") {
            var ObjData = {
              ChildUserID: logindetails.ChildUserID,
              ParentUserID: logindetails.ParentUserID,
              ClientID: ClientID,
              Username: logindetails.Username,
              Role: logindetails.Role,
            };
            if (ObjData.Role == "Responder") {
              if (Is2FA) {
                this.SendTwoStepVerificationEmail(
                  Is2FA,
                  logindetails.ChildUserID,
                  logindetails.Role,
                  ObjData,
                  "/bookedmeetings",
                  email
                  );
                  
                } else {
                localStorage.setItem("LoginData", JSON.stringify(ObjData));
                history.push("/bookedmeetings");
              }
            }
          } else if (logindetails.Role == "ColdCallers") {
            var ObjData = {
              ChildUserID: logindetails.ChildUserID,
              ParentUserID: logindetails.ParentUserID,
              ClientID: ClientID,
              Username: logindetails.Username,
              Role: logindetails.Role,
            };
            if (ObjData.Role == "ColdCallers") {
              if (Is2FA) {
                this.SendTwoStepVerificationEmail(
                  Is2FA,
                  logindetails.ChildUserID,
                  logindetails.Role,
                  ObjData,
                  "/ColdCallingContact",
                  email
                  );
                  
                } else {
                localStorage.setItem("LoginData", JSON.stringify(ObjData));
                history.push("/ColdCallingContact");
              }
            }
          } else {
            var ObjData = {
              ChildUserID: logindetails.ChildUserID,
              ParentUserID: logindetails.ParentUserID,
              ClientID: ClientID,
              Username: logindetails.Username,
              Role: logindetails.Role,
            };
            if (ObjData.Role == "SalesHacker") {
              if (Is2FA) {
                this.SendTwoStepVerificationEmail(
                  Is2FA,
                  logindetails.ChildUserID,
                  logindetails.Role,
                  ObjData,
                  "/bookedmeetings",
                  email
                  );
                  
                } else {
                localStorage.setItem("LoginData", JSON.stringify(ObjData));
                history.push("/bookedmeetings");
              }
            }
          }
        } else {
          this.setState({ AlertIncorrect: true });
        }
      }
    } else {
      document.getElementById("hideloding").style.display = "none";
    }
  }

  async GetClientListForTopDropDown(CUID, PUID, Roles) {
    var ClientID = 0;
    var ObjData = {
      Role: Roles,
      ChildUserID: CUID,
      ParentUserID: PUID,
    };
    const result = await Axios({
      url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
      method: "POST",
      data: ObjData,
    });

    if (result.data.StatusMessage == "SUCCESS") {
      if (result.data.Data.length > 0) {
        UpdateUserDetails(result.data.Data[0].ClientID);
        ClientID = result.data.Data[0].ClientID;
        return ClientID;
      }
      // else{

      // //  toast.error(<div className="toastsize">Client not available, please add client.</div>)
      // }
    }
  }
  CloseForgetUserNotFound() {
    this.setState({ ForgetUserNotFound: !this.state.ForgetUserNotFound });
  }

  //Email Send method
  SendEmail = (Username, EmailTemplate, Subject) => {
    return new Promise((resolve, reject) => {
      const InputParameters = {
        email: Username,
        html: EmailTemplate,
        subject: Subject,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/emailsend",
        method: "POST",
        data: InputParameters,
      })
        .then((res) => {
          if (res.data.StatusMessage === "SUCCESS") {
            resolve(true); // Resolve the promise with true
          } else {
            resolve(false); // Resolve the promise with false
          }
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          reject(error); // Reject the promise in case of an error
        });
    });
  };

  //Genrate 6-Digit OTP
  generateOTP = () => {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  };

  SendTwoStepVerificationEmail = async (Is2FA, CUID, Role,StorageObject,Redirection,Email) => {
    let OTP = this.generateOTP();
    let InputParameters = {
      UserID: CUID,
      UserType: Role,
      OTP: OTP,
      LastUpdatedDate: new Date(),
      LastUpdatedBy: CUID,
    };
    let res = await Axios({
      url: CommonConstants.MOL_APIURL + "/login/SaveOTP",
      method: "POST",
      data: InputParameters,
    });
    if(res.data.StatusMessage =="SUCCESS"){
      var emailData = EmailTemplete.twostepverificationEmail(OTP);
      try {
        // Use await to wait for the SendEmail function to complete
        let IsMailSend = await this.SendEmail(Email, emailData, "Saleshive Login OTP");
        if (IsMailSend) {
          history.push("/twostepverification",{StorageObject,OTP,Redirection});
        }
    } catch (error) {
        console.error("Error sending email:", error);
    }
     
    }
  };

  render() {
    return (
      <div className="login-main">
        <div
          id="hideloding"
          className="loding-display"
          style={{ display: "none" }}
        >
          <img src={loadingicon} />
        </div>
        <div className="row h-100">
          <div className="col-xs-12 col-md-7 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={logo} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-5 d-flex">
            {/* Login Form in sign in page*/}
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                <Alert
                  id="a_login"
                  variant="filled"
                  severity="success"
                  className="erorrheader"
                >
                  login successfull.
                </Alert>
                <Snackbar
                  className="erorrheader"
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}
                >
                  <Alert
                    variant="filled"
                    severity="error"
                    className="erorrheader"
                  >
                    Please enter Correct email or password.
                  </Alert>
                </Snackbar>
                <div className="heading">
                  <h1 className="login_title">Sign In</h1>
                </div>
                {this.state.AlertIncorrect == true ? (
                  <Alert
                    variant="outlined"
                    icon={false}
                    className="text-danger"
                    severity="error"
                  >
                    Invalid email or password!
                  </Alert>
                ) : (
                  ""
                )}
                <div id="forgot_message" className="text-success"></div>
                <form name="login" className="form">
                  <div className="input-control">
                    <label className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      onBlur={this.handleChange.bind(this, "Email")}
                      className="input-field"
                      placeholder="Email"
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Email"]}
                    </span>
                    {this.state.UserExist && (
                      <span style={{ color: "red" }}>
                        User not exist, please sign up!
                      </span>
                    )}
                  </div>
                  <div className="input-control">
                    <label className="input-label" hidden>
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      onBlur={this.handleChange.bind(this, "Password")}
                      className="input-field"
                      placeholder="Password"
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Password"]}
                    </span>
                  </div>
                  <div className="row m-login__form-sub">
                    <div className="col m--align-left">
                      <label className="check_bx">
                        <input type="checkbox" onChange={this.rememberme} />
                        <span className="checkmark"></span>
                        Remember me
                      </label>
                    </div>
                    <div className="col m--align-right">
                      <span
                        onClick={this.forgotClick}
                        id="m_login_forget_password"
                        className="right-texts text text-links"
                      >
                        Forgot Password ?
                      </span>
                    </div>
                  </div>
                  <div className="input-control btn-center">
                    <input
                      type="submit"
                      name="submit"
                      className="input-submit"
                      value="Sign In"
                      onClick={this.login}
                    />
                  </div>
                </form>
              </div>

              {/* Forgot password Form in sign in page*/}
              <div id="forgot" className="centerheigh">
                <Alert
                  id="forgotpass_u"
                  variant="filled"
                  severity="success"
                  className="erorrheader"
                >
                  Email send. Please check your email.
                </Alert>
                <Snackbar
                  className="erorrheader"
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={this.state.forgotpassOpen}
                  autoHideDuration={6000}
                  onClose={this.handleforgotpassClose}
                >
                  <Alert
                    variant="filled"
                    severity="error"
                    className="erorrheader"
                  >
                    No user exists with this email!
                  </Alert>
                </Snackbar>
                <div className="heading">
                  <h1 className="login_title">Forgotten Password ?</h1>
                  <p className="login_pereg">
                    Enter your email to reset your password?
                  </p>
                </div>
                {this.state.ForgetUserNotFound === true ? (
                  <div
                    className="m-alert m-alert--outline alert alert-danger alert-dismissible animated fadeIn"
                    id="ForgetUserNotFound"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close text-white bordered"
                      onClick={() => {
                        this.CloseForgetUserNotFound();
                      }}
                    >
                      x
                    </button>
                    <span>No user exists with this email!</span>
                  </div>
                ) : (
                  <></>
                )}

                <form name="login" className="form">
                  <div className="input-control">
                    <label className="input-label" hidden>
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="f_email"
                      className="input-field"
                      placeholder="Email"
                    />
                    <p id="f_email_e" className="errorinput">
                      {" "}
                      Please enter Email{" "}
                    </p>
                    <p id="f_email_inc" className="errorinput">
                      {" "}
                      Invalid email.{" "}
                    </p>
                    <p id="e_user" className="errorinput">
                      No User found
                    </p>
                    <p id="email_user" className="errorinput">
                      Email send. Please check your email.
                    </p>
                  </div>
                  <div className="input-control btn-center">
                    <input
                      type="button"
                      name="Request"
                      onClick={this.submitforgot}
                      className="input-submit"
                      value="Request"
                    />
                    <input
                      type="button"
                      name="Cancel"
                      onClick={this.cancleforgot}
                      className="input-submit"
                      value="Cancel"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
