import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ClientEmailAccountsTabing from '../../_components/user/ClientMailAccounts/ClientEmailAccountsTabing';

import { CSVReader, CSVDownloader } from "react-papaparse";



class ClientEmailAccountsPage extends React.Component {
 
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                    <Sidebar className=""/> 
                    <div className="bodyhome">  
                        <div className="col padd-0">  
                            <ClientEmailAccountsTabing />  
                        </div>
                    </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientEmailAccountsPage = connect(mapState, actionCreators)(ClientEmailAccountsPage);
export { connectedClientEmailAccountsPage as ClientEmailAccountsPage };