import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import ReactApexchart from "react-apexcharts";




export default function Contactsdetailstable({ CampaignIDforEmail,FromDates,ToDates}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CampaignName");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [FromDate, SetFromDate] = React.useState("");
  const [ToDate, SetToDate] = React.useState("");
  const [CampaignID, SetCampaignID] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [Counts, SetCounts] = React.useState({});
  const [CountGraph, SetCountGraph] = React.useState([]);
  const [SendCountCard, SetSendCountCard] = React.useState(0);
  const [ReplyCountCard, SetReplyCountCard] = React.useState(0);
  const [OpenPerCountCard, SetOpenPerCountCard] = React.useState(0);
  const [ReplyPerCountCard, SetReplyPerCountCard] = React.useState(0);
  const [BouncePerCountCard, SetBouncePerCountCard] = React.useState(0);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ContactReportingGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates);
    ContactReportingExportGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates);
    ReportingContactMatrixGraphGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates);
  }, [Search, Page, RowsPerPage,FromDates,ToDates]);

  const ReportingContactMatrixGraphGet = (CID, UID, URole,Fromdate,Todate) => {
    var InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/ReportingContactMatrixGraphGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetCountGraph(result.data);
    });
  };
  const AccountState = {
    series: [
      {
        name: "Sent",
        color: "#302e49",
        data: CountGraph.map((item) => item.Sent),
      },
      {
        name: "Bounce",
        color: "#e9826e",
        data: CountGraph.map((item) => item.Bounce),
      },
      {
        name: "Reply",
        color: "#e9826e",
        data: CountGraph.map((item) => item.Reply),
      },
      {
        name: "Open",
        color: "#e9826e",
        data: CountGraph.map((item) => item.Open),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: CountGraph.map((item) => item.dateRange),
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val + " $";
          },
        },
      },
    },
  };

  // email reporting list
  const ContactReportingGet = (CID, UID, URole,Fromdate,Todate) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignContactReporting",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetData(result.data?.PageData);
      SetRows(result.data?.PageData);
      SetRlen(result.data?.TotalCount);
      SetFlen(result.data?.TotalCount);
      SetCountPage(result.data?.PageCount);
     // SetCounts(result.data.Counts);
     SetSendCountCard(result.data?.FinalCount?.TotalSentMail ?? 0);
     SetReplyCountCard(result.data?.FinalCount?.TotalReply ?? 0);
     SetOpenPerCountCard(result.data?.FinalCount?.TotalOpenPercentage ?? 0);
     SetReplyPerCountCard(result.data?.FinalCount?.TotalReplyPercentage ?? 0);
     SetBouncePerCountCard(result.data?.FinalCount?.TotalBouncePercentage ?? 0);
     
      SetIsLoading(false);
    });
  };

    // email reporting list
    const ContactReportingExportGet = (CID, UID, URole,Fromdate,Todate) => {
      var InputParameters = {
        ClientID: CID,
        UserID: UID,
        Role: URole,
        CampaignID: CampaignIDforEmail,
        FromDate: Fromdate,
        ToDate: Todate,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/CampaignContactReportingExport",
        method: "POST",
        data: InputParameters,
      });
      rows1.then((result) => {
        SetExportData(result.data);
      });
    };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CampaignID,
        FromDate: FromDate,
        ToDate: ToDate,
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetReportingAccount",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetCounts(result.data.Counts);

        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CampaignID,
        FromDate: FromDate,
        ToDate: ToDate,
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetReportingAccount",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetCounts(Result.data.Counts);

        SetIsLoading(false);
      });
    }
  };
  return (
    <>
        {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
                  <div className="row colfive">
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title">
                          <span class="clr-sky box-main-title">{isNaN(SendCountCard) ? 0 : SendCountCard}</span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Sent</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title">
                          <span class="clr-sky box-main-title">{isNaN(ReplyCountCard) ? 0 : ReplyCountCard}</span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Reply</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title">
                          <span class="clr-sky box-main-title">  {isNaN(OpenPerCountCard) ? 0 : parseFloat(OpenPerCountCard.toFixed(1))} %</span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Open %</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title">
                          <span class="clr-sky box-main-title">{isNaN(ReplyPerCountCard) ? 0 : parseFloat(ReplyPerCountCard.toFixed(1))}%</span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Reply %</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title">
                          <span class="clr-sky box-main-title">{isNaN(BouncePerCountCard) ? 0 : parseFloat(BouncePerCountCard.toFixed(1))}%</span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Bounce </string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row padtop-50">
                <div class="col-xl-1"></div>
                <div class="col-xl-10">
                  <div id="chart chart-max450">
                  <ReactApexchart
              options={AccountState.options}
              series={AccountState.series}
              type="bar"
              height={450}
            />
                  </div>
                </div>
                <div class="col-xl-1"></div>
              </div>

              <div className="row border-bottom">
                <div className="col d-flex align-items-center ">
                  <h4 className="headertitle float-left pl-0">Contact Details</h4>
                </div>
                <div className="col text-right listing-li d-flex align-items-center justify-content-end">
                  <ul className="mt-0">
                    <li>
                      <CSVDownloader
                        className="px-0"
                        data={ExportData}
                        filename={"Email"}
                        bom={true}
                      >
                        <a
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          //onClick={this.exportcsv}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                  </ul>
                </div>
              </div>

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CampaignName");
                    }}
                  >
                    Campaign Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalSentMail");
                    }}
                  >
                    Sent
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalReply");
                    }}
                  >
                    Reply
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalOpen");
                    }}
                  >
                    Open
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.CampaignName}</TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.TotalSentMail}</TableCell>
                    <TableCell>{row.TotalReply}</TableCell>
                    <TableCell>{row.TotalOpen}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
