import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popup from "reactjs-popup";
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [TotalAll, SetTotalAll] = React.useState({});
  const [MaxStep, SetMaxStep] = React.useState([]);
  const [SumAll, SetSumAll] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(false)
  useEffect(() => {
    document.title = 'CampaignResponses | SalesHive';
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }
   
    CampaignSummaryGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

  // Client login
  const CampaignSummaryGet = (CID,UID,URole) => {
    SetIsLoading(true)
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole

    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign_summary/demoSummary",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetTotalAll(Result.data.TotalAll);
      SetMaxStep(Result.data.MaxStep);
      SetSumAll(Result.data.Total);
      SetIsLoading(false)
    });
  };

 

 //search for record
 const RequestSearch = (Event) => {
  if (Event.key === "Enter") {
    var SearchedVal = document.getElementById("search").value;
    SetSearch(SearchedVal);
    SetPage(1);
  }
};

//change Page
const HandleChangePage = (Event, NewPage) => {
  SetPage(NewPage);
};

//change display rows
const ChangeRowSelected = (Event) => {
  SetRowsPerPage(Event.target.value);
  SetPage(1);
};

//get sort field data
const SortData = (Field) => {
  SetIsLoading(true)

  var SearchedVal = document.getElementById("search").value;
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/campaign_summary/demoSummary",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetTotalAll(Result.data.TotalAll);
      SetMaxStep(Result.data.MaxStep);
      SetSumAll(Result.data.Total);
      SetIsLoading(false)

    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/campaign_summary/demoSummary",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetTotalAll(Result.data.TotalAll);
      SetMaxStep(Result.data.MaxStep);
      SetSumAll(Result.data.Total);
      SetIsLoading(false)

    });
  }
};

  return (
    <>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
    <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className='table-bordered'>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow> 
            <TableCell>
            <a
                    onClick={() => {
                      SortData("Name");
                    }}
                  >Campaign</a></TableCell> 
            <TableCell>
            <a
                    onClick={() => {
                      SortData("UnContact");
                    }}
                  >Uncontacted</a></TableCell>
            {MaxStep.map((item)=>{
              return (<TableCell>Step {item}</TableCell>)
            })}
            {/* <TableCell>Step 1</TableCell>
            <TableCell>Step 2</TableCell>   */}
            <TableCell><a
                    onClick={() => {
                      SortData("ReplyCount");
                    }}
                  >Replies</a></TableCell> 
            <TableCell><a
                    onClick={() => {
                      SortData("HardBounceCount");
                    }}
                  >Bounce</a></TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows.map((row) => (
            <TableRow key={row._id} row={row}>
            <TableCell>{row.CampaignName}</TableCell> 
            <TableCell>{row.Uncontacted}</TableCell> 
            {MaxStep.map((item)=>{
              return (<TableCell>{row["Step"+item] !== undefined?row["Step"+item]:0}</TableCell>)
            })}
            <TableCell>{row.Replies}</TableCell> 
            <TableCell>{row.Bounce}</TableCell> 

              </TableRow> 
          )))}
        </TableBody>
        <TableFooter>
        <TableRow> 
            <TableCell>Total</TableCell>
            <TableCell>{TotalAll.Uncontacted}</TableCell>
            {MaxStep.map((item)=>{
              return (<TableCell>{TotalAll["Step"+item] !== undefined?TotalAll["Step"+item]:0}</TableCell>)
            })}
            <TableCell>{TotalAll.Reply}</TableCell>
            <TableCell>{TotalAll.Bounce}</TableCell>
          </TableRow>
          <TableRow> 
            <TableCell>%</TableCell> 
            <TableCell>{SumAll === 0? "0.00%":((TotalAll.Uncontacted*100)/SumAll).toFixed(2)+ "%"}</TableCell>
            {MaxStep.map((item)=>{
              return (<TableCell>{SumAll === 0? "0.00%":((TotalAll["Step"+item]*100)/SumAll).toFixed(2)+ "%"}</TableCell>)
            })}
            <TableCell>{SumAll === 0? "0.00%":((TotalAll.Reply*100)/SumAll).toFixed(2)+ "%"}</TableCell> 
            <TableCell>{SumAll === 0? "0.00%":((TotalAll.Bounce*100)/SumAll).toFixed(2)+ "%"}</TableCell> 
          </TableRow>
          </TableFooter>
      </Table>
    </TableContainer>
    </div>
    {Sflag ? (
  <div class="row">
    <Pagination
      component="div"
      count={CountPage}
      onChange={HandleChangePage}
      showFirstButton
      showLastButton
    />
    <div class="col dataTables_info">
      <p>
        Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
        {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
        entries (filtered from {Flen} total entries)
      </p>
    </div>
  </div>
    ) : (
      <div class="row">
        <div class="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div class="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    )}
    </>
  );
}
