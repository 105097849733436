import React,{useEffect} from 'react';
import { CSVReader, CSVDownloader } from "react-papaparse";
import Axios from "axios";
const moment = require("moment");

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../../user/Kpis/LastdaysTabing';

import RespondersTable from '../../../_components/user/LeaderBoard/RespondersTable';
import StrategistTable from '../../../_components/user/LeaderBoard/StrategistTable';
import ColdCallersTable from '../../../_components/user/LeaderBoard/ColdCallersTable'; 


 
import ImageUploading from 'react-images-uploading';
import Popup from "reactjs-popup";

import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart  from "react-apexcharts";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { toast } from 'react-toastify';

charts(FusionCharts);
const dataSource = {
  chart: {
    caption: "Meeting Status",
    //subcaption: "For a net-worth of $1M",
    subCaption: " ",
    formatnumberscale: "0",
    showLegend: "1",
    showLabels: "0",
    showValues: "1",
    valuePosition: "inside",
    numbersuffix: "%",
    plothighlighteffect: "false",
    legendcaptionbold: "1",
    legendcaptionfontsize: "15",
    legendCaptionFontColor: "#000",
    showPercentValues: "0",
    showPercentInToolTip: "0",
    enableSlicing: "1",
    legendPosition: "right",
    legendCaption: "Meeting Status: ",
    legendBgColor: "#FFF",
    legendBorderColor: "#ffF",
    legendShadow: "0",
    legendItemFontSize: "14",
    legendWidth: "300", 
  },
  data: [
    {
      label: "Set",
      value: "100000",
      "color": "#302e49"
    }, 
    {
      label: "Contract Sent",
      value: "50000",
      "color": "#e9826e"
    }, 
  ]
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({ToDate,FromDate,IsOldClientss}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);
  const [FromDates,SetFromDates ] = React.useState("");
  const [ToDates, SetToDates] = React.useState("");
  const [ExportResponder,SetExportResponder] = React.useState([]);
  const [ExportStrategist,SetExportStrategist] = React.useState([]);
  const [ExportColdCallers,SetExportColdCallers] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ResponderCount, SetResponderCount] = React.useState([]);
  const [SalesDevelopmentRepsCount, SetSalesDevelopmentRepsCount] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDates(FromDate);
    SetToDates(ToDate);
    ResponderCountMethod(Details.ParentUserID,FromDate,ToDate,IsOldClientss);
    ResponderExportMethod(Details.ParentUserID,FromDate,ToDate);

    StrategistExportMethod(Details.ParentUserID,FromDate,ToDate);
    ColdCallersExportMethod(Details.ParentUserID,FromDate,ToDate);
    SalesDevelopmentResCountMethod(Details.ParentUserID,FromDate,ToDate)

  }, [ToDate,FromDate,IsOldClientss]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  }; 
  const openTermsConditionPopup = () => {
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };

  document.addEventListener('mouseup', function(e) {
      if(popupval){
        setpopup(false);
      }
  });
    //const openTermsConditionPopup = false;
  
  //Export Data
  const ResponderExportMethod=(UID,FromDate,ToDate)=>{
    const InputParameters={
      UserID:UID,
      ToDate:ToDate,
      FromDate:FromDate,
      
  }
  Axios({
  url: CommonConstants.MOL_APIURL + "/leader_board/ResponderExport",
  method: "POST",
  data:InputParameters
  }).then((Res) => {
    SetExportResponder( Res.data.Data );
  });
  }
 const ResponderCsv=()=> {
  toast.success(
    <div>
      Data exported successfully.
    </div>
  )
 }

 const StrategistExportMethod=(UID,FromDate,ToDate)=>{
  const InputParameters={
    UserID:UID,
    ToDate:ToDate,
    FromDate:FromDate,
    
}
Axios({
url: CommonConstants.MOL_APIURL + "/leader_board/StrategistExport",
method: "POST",
data:InputParameters
}).then((Res) => {  
  SetExportStrategist( Res.data.Data );
});
}
const StrategistCsv=()=> {}


const ColdCallersExportMethod=(UID,FromDate,ToDate)=>{
  const InputParameters={
    UserID:UID,
    ToDate:ToDate,
    FromDate:FromDate,
    
}
Axios({
url: CommonConstants.MOL_APIURL + "/leader_board/SalesDevelopmentRepsExports",
method: "POST",
data:InputParameters
}).then((Res) => {
  SetExportColdCallers( Res.data.Data );
});
}
const ColdCallersCsv=()=> {

}

const ResponderCountMethod=(UID,ToDate,FromDate,IsOldClient)=>{
  const InputParameters={
    UserID:UID,
    ToDate:ToDate,
    FromDate:FromDate,
    IncludeOldClient:IsOldClient
    
}
console.log(InputParameters,"dvdeere")
Axios({
url: CommonConstants.MOL_APIURL + "/leader_board/ResponderCount",
method: "POST",
data:InputParameters
}).then((Res) => {
  console.log(Res.data)
  SetResponderCount(Res.data.Data)
  console.log(ResponderCount)
  
});
}

const SalesDevelopmentResCountMethod=(UID,FromDate,ToDate)=>{
  const InputParameters={
    UserID:UID,
    ToDate:ToDate,
    FromDate:FromDate,
    
}
Axios({
url: CommonConstants.MOL_APIURL + "/leader_board/SalesDevelopmentRepsCount",
method: "POST",
data:InputParameters
}).then((Res) => {
  console.log(Res.data)
  SetSalesDevelopmentRepsCount(Res.data.Data)
  console.log(ResponderCount)
  
});
}
  return (
    <div className="tabdes mt-3"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Responders" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Strategist" {...a11yProps(1)} />
            <Tab label="Sales Development Reps" {...a11yProps(2)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
        <div className="row">

                <div className="w-100 whiterow px-3">
                  <div className="row">
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{ResponderCount.MeetingCount?ResponderCount.MeetingCount:0}</span>
                              </div>
                              <p class="clr-sky">Total Meetings</p>
                          </div>
                      </div>
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{ResponderCount.LWMeetingCount?ResponderCount.LWMeetingCount:0}</span>
                              </div>
                              <p class="clr-sky">Meeting Set LW</p>
                          </div>
                      </div>
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{ResponderCount.TWMeetingCount?ResponderCount.TWMeetingCount:0}</span>
                              </div>
                              <p class="clr-sky">Meeting Set TW</p>
                          </div>
                      </div>  
                  </div>
              </div>
            
               
            </div>

            <div className="row">
              <div className="col border-bottom">
                  <h4 className="headertitle float-left">Responders</h4>
                  <div className="listing-li float-right padb-15">
                      <ul> 
                      <li>
                    <a
                                        onClick={ResponderCsv}
                                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                    >
                        
                          <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                          <CSVDownloader className='px-0'

                            data={ExportResponder}
                            filename={`LeaderBoard-ResponderMeeting`}
                            bom={true}
                          >
                            Export
                          </CSVDownloader>
                        </span>
                      </a>
                    </li>
                      </ul>
                  </div> 
              </div>
            </div>
             
          <div className="row">
            <div className="col">
                <RespondersTable ToDatess={ToDate} FromDatess={FromDate}  IsOldClientss={IsOldClientss}/> 
            </div>
          </div> 

        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg" index={1}> 
        <div className="row">

                {/* <div className="w-100 whiterow py-5 px-5">
                  <div className="row">
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{SalesDevelopmentRepsCount.MeetingCount?SalesDevelopmentRepsCount.MeetingCount:0}</span>
                              </div>
                              <p class="clr-sky">Total Meetings</p>
                          </div>
                      </div>
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{SalesDevelopmentRepsCount.LWMeetingCount?SalesDevelopmentRepsCount.LWMeetingCount:0}</span>
                              </div>
                              <p class="clr-sky">Meeting Set LW</p>
                          </div>
                      </div>
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{SalesDevelopmentRepsCount.TWMeetingCount?SalesDevelopmentRepsCount.TWMeetingCount:0}</span>
                              </div>
                              <p class="clr-sky">Meeting Set TW</p>
                          </div>
                      </div>  
                  </div>
              </div> */}
            
               
            </div>

            <div className="row">
              <div className="col border-bottom">
                  <h4 className="headertitle float-left mb-0 pl-0">Strategist</h4>
                  <div className="listing-li float-right padb-15">
                      <ul> 
                      <li>
                    <a
                                        onClick={StrategistCsv}
                                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                    >
                        
                          <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                          <CSVDownloader className='px-0'
                            data={ExportStrategist}
                            filename={`LeaderBoard-SalesStrategistCrush`}
                            bom={true}
                          >
                            Export
                          </CSVDownloader>
                        </span>
                      </a>
                    </li>
                      </ul>
                  </div> 
              </div>
            </div>
            
          <div className="row">
            <div className="col">
                <StrategistTable ToDatess={ToDate} FromDatess={FromDate} IsOldClientss={IsOldClientss}/> 
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg" index={2}>  
           <div className="row"> 
            <div className="w-100 whiterow py-5 px-3">
              <div className="row">
                  <div className="col">
                      <div className="bg-light-sky boxcardcounter align-center">
                          <div class="line-title">
                              <span class="clr-sky box-main-title">{SalesDevelopmentRepsCount.MeetingCount?SalesDevelopmentRepsCount.MeetingCount:0}</span>
                          </div>
                          <p class="clr-sky">Total Meetings</p>
                      </div>
                  </div>
                  <div className="col">
                      <div className="bg-light-sky boxcardcounter align-center">
                          <div class="line-title">
                              <span class="clr-sky box-main-title">{SalesDevelopmentRepsCount.LWMeetingCount?SalesDevelopmentRepsCount.LWMeetingCount:0}</span>
                          </div>
                          <p class="clr-sky">Meeting Set LW</p>
                      </div>
                  </div>
                  <div className="col">
                      <div className="bg-light-sky boxcardcounter align-center">
                          <div class="line-title">
                              <span class="clr-sky box-main-title">{SalesDevelopmentRepsCount.TWMeetingCount?SalesDevelopmentRepsCount.TWMeetingCount:0}</span>
                          </div>
                          <p class="clr-sky">Meeting Set TW</p>
                      </div>
                  </div>  
              </div>
            </div>


            </div>

            <div className="row">
            <div className="col border-bottom">
              <h4 className="headertitle float-left pl-0 mb-0">Sales Development Reps</h4>
              <div className="listing-li float-right padb-15">
                  <ul> 
                  <li>
                    <a
                                        onClick={ColdCallersCsv}
                                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                    >
                        
                          <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                          <CSVDownloader className='px-0'
                            data={ExportColdCallers}
                            filename={`LeaderBoard-ColCallersMeeting`}
                            bom={true}
                          >
                            Export
                          </CSVDownloader>
                        </span>
                      </a>
                    </li>
                  </ul>
              </div> 
            </div>
            </div> 

            <div className="row">
              <div className="col">
                    <ColdCallersTable ToDatess={ToDate} FromDatess={FromDate} IsOldClientss={IsOldClientss}/> 
              </div>
            </div> 
        </TabPanel> 

      </div> 
    </div>
  );
}