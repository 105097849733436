import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";



export default function AccountAllTable({ AddUpdate, updateFromChild }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ProspectNoteId, SetProspectNoteId] = React.useState("");
  // const [fields, Setfields] = React.useState({});
  const [errors, Seterrors] = React.useState({});
  const [IsExist, SetIsExist] = React.useState(false);
  const [KeywordAvailable, SetKeywordAvailable] = React.useState(null);
  const [OldKeyword, SetOldKeyword] = React.useState(null);
  const [IsLoading,SetIsLoading]  = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    KeywordsGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage, AddUpdate]);

  const FormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    // var FieldName = document.getElementById("KeywordEdit").value
    var FieldName = document.getElementById("KeywordEdit").value.trim();
    var IsExist = await CheckExistKeyword(FieldName);

    if (FieldName == "") {
      formIsValid = false;
      errors["KeywordEdit"] = "Please enter keyword";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    Seterrors(errors);
    // this.setState({ errors: errors });

    return formIsValid;
  };

  const CheckExistKeyword = async (Keyword) => {
    var InputParameter = {
      Keyword: Keyword,
      Role: Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/DespammerCheck",
      method: "POST",
      data: InputParameter,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data?.length > 0) {
        if (OldKeyword == Keyword) {
          SetIsExist(false);
          return false;
        } else {
          SetKeywordAvailable(resdata.data.Data[0]?.Keyword);
          SetIsExist(true);
          toast.error(
            <div className="toastsize">
              Keyword
              <br />
              Keyword value already exist.
            </div>
          );
          return true;
        }
      } else {
        SetIsExist(false);
        // this.setState({IsExist:false})
        return false;
      }
    }
  };

  const Updatekeywordbtn = async (e) => {
    errors.KeywordEdit = null;
    SetKeywordAvailable(null);
    e.preventDefault();
    e.currentTarget.disabled = true;
    var FinalValidation = await FormValidation();
    if (FinalValidation == true) {
      //  var Keyword = document.getElementById("KeywordEdit").value
      var FieldName = document.getElementById("KeywordEdit").value.trim();

      var Inputparameter = {
        KeywordID: ProspectNoteId,
        Keyword: FieldName,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: CUserID,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/despammer/DespammerUpdate",
        method: "POST",
        data: Inputparameter,
      }).then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          document.getElementById("Keyword_edit_sec").style.display = "none";
          toast.success(
            <div className="toastsize">
              Keyword
              <br />
              Keyword update successfully.
            </div>
          );
        } else {
          toast.error(result.data.Message);
        }
      });
    } else {
      document.getElementById("submit").disabled = false;
    }
  };
  //Get Keyword List
  const KeywordsGet = async (cID, uID, Roles) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: cID,
      UserID: uID,
      Role: Roles,
    };
    const KeywordList = await Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/DespammerGet",
      method: "POST",
      data: InputParameter,
    });
    if (KeywordList.data.StatusMessage == "SUCCESS") {
      SetRows(KeywordList.data.PageData);
      SetRlen(KeywordList.data.TotalCount);
      SetFlen(KeywordList.data.TotalCount);
      SetCountPage(KeywordList.data.PageCount);
    SetIsLoading(false)

    }
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var Sort = SortValue;
      if (Sort == 1) {
        Sort = -1;
        SetSortValue(-1);
      } else {
        Sort = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: Sort,
        Type: "User",
        Role: Role,
      };
      const KeywordList = Axios({
        url: CommonConstants.MOL_APIURL + "/despammer/DespammerGet",
        method: "POST",
        data: InputParameter,
      });
      KeywordList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.totalcount);
        SetFlen(Result.data.totalcount);
        SetCountPage(Result.data.Pagecount);
    SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var Sorted;
      if (Sorted == 1) {
        Sorted = -1;
        SetSortValue(-1);
      } else {
        Sorted = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortValue,
        Type: "User",
        Role: Role,
      };
      const KeywordList = Axios({
        url: CommonConstants.MOL_APIURL + "/despammer/DespammerGet",
        method: "POST",
        data: InputParameter,
      });
      KeywordList.then((Result) => {
        SetRows(Result.data.Pagedata);
        SetRlen(Result.data.totalcount);
        SetFlen(Result.data.totalcount);
        SetCountPage(Result.data.Pagecount);
    SetIsLoading(false)

      });
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //edit page
  const CancelBtn = (ID) => {
    document.getElementById("Keyword_edit_sec").style.display = "none";
  };

  //delete Keyword
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Keyword.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/despammer/DespammerDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Keyword deleted successfully.", "success");

              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              updateFromChild(true);
              //KeywordsGet(ClientID,UserID,Role);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // show add sec
  const EditBtn = (ID) => {
    SetProspectNoteId(ID);
    document.getElementById("Keyword_edit_sec").style.display = "block";
    var InputParameter = {
      KeywordID: ID,
      LastUpdatedDate: new Date(),
      LastUpdatedBy: CUserID,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/DespammerGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("KeywordEdit").value =
          res.data.Data[0]?.Keyword;
        SetOldKeyword(res.data.Data[0]?.Keyword);
        // this.setState({OldName:res.data.Data[0].Name})
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  return (
    <>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> : <></>
    }
      <div
        className="row my-5"
        id="Keyword_edit_sec"
        style={{ display: "none" }}
      >
        <div className="col-lg-12 row">
          <label className="col-lg-4 view-lable-meet">Keyword</label>
          <div className="col-lg-12">
            <input
              className="form-control m-input"
              data-val="true"
              data-val-required="Keyword"
              id="KeywordEdit"
              name="Keyword"
              placeholder="Keyword"
              type="text"
            />
            <span style={{ color: "red" }}>{errors["KeywordEdit"]}</span>
          </div>
        </div>
        <div className="col-lg-12 mt-3 px-4 full-right">
          <button
            className="btn btn-primary btn-lightgreen mr-1"
            id="submit"
            onClick={Updatekeywordbtn}
          >
            <i className="la la-save"></i> Save
          </button>
          <a className="btn btn-secondary" onClick={CancelBtn}>
            <i className="la flaticon-cancel"></i> Cancel
          </a>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>

        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Keyword");
                      }}
                    >
                      Keyword
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("CreatedDate");
                      }}
                    >
                      Created Date
                    </a>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows.map((row) => (
                  <TableRow key={row._id} row={row}>
                    <TableCell>{row.Keyword}</TableCell>
                    <TableCell>
                      {moment(new Date(row.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
          <div className="row">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
