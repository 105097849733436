import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../Kpis/LastdaysTabing";

import CollapsibleTable from "../../usertable";
import AccountsTable from "./Accountstable";
import ResportingContact from "./ResportingContact";
import EmailTable from "./EmailTable";
const moment=require('moment');

//chart
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";


//import ApexCharts from 'apexcharts';
import ReactApexchart from "react-apexcharts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ Campaignids }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(2);
  const [FromDate, setFromDate] = React.useState("");
  const [ToDate, setToDate] = React.useState("");

  useEffect(() => {
    document.getElementById("from-date").valueAsDate = new Date(
      moment().subtract(1, "months")
    );
    document.getElementById("to-date").valueAsDate = new Date(moment());
    let FromDate = document.getElementById("from-date").value;
    let ToDate = document.getElementById("to-date").value;
    setFromDate(FromDate);
    setToDate(ToDate);
  }, []);

  const SaveBtn = () => {
    let updatedFromDate = document.getElementById("from-date").value;
    let updatedToDate = document.getElementById("to-date").value;
    setFromDate(updatedFromDate);
    setToDate(updatedToDate);
  };

  charts(FusionCharts);
  const dataSource = {
    chart: {
      caption: "Meeting Status",
      //subcaption: "For a net-worth of $1M",
      theme: "fusion",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          plothighlighteffect: "false",
          legendcaptionbold: "1",
          legendcaptionfontsize: "15",
          legendCaptionFontColor: "#000",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "right",
          legendCaption: "Meeting Status: ",
          legendBgColor: "#FFF",
          legendBorderColor: "#ffF",
          legendShadow: "0",
          legendItemFontSize: "14",
          legendWidth: "300", 
    },
    data: [
      {
        label: "Set",
        value: "910000",
        color: "#302e49",
      },
      {
        label: "Qualified",
        value: "15000",
        color: "#6f4569",
      },
      {
        label: "Opportunity",
        value: "15000",
        color: "#b35d75",
      },
      {
        label: "Contract Sent",
        value: "50000",
        color: "#e9826e",
      },
      {
        label: "Closed Won",
        value: "15000",
        color: "#ffb963",
      },
      {
        label: "Rescheduling",
        value: "20000",
        color: "#f9f871",
      },
    ],
  };

  const state = {
    series: [
      {
        name: "Goal",
        color: "#302e49",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 45, 65],
      },
      {
        name: "Meetings",
        color: "#e9826e",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 40, 115, 110],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "03/29",
          "04/05",
          "04/12",
          "04/19",
          "04/26",
          "05/03",
          "05/10",
          "05/17",
          "05/24",
          "06/07",
          "06/14",
          "06/21",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };

  const AccountState = {
    series: [
      {
        name: "Sent",
        color: "#302e49",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 45, 65],
      },
      {
        name: "Responses",
        color: "#e9826e",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 40, 115, 110],
      },
      {
        name: "Meetings",
        color: "#ffb963",
        data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 110],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "04/01 - 04/04",
          "04/05 - 04/11",
          "04/12 - 04/18",
          "04/19 - 04/25",
          "04/26 - 05/02",
          "05/03",
          "05/10",
          "05/17",
          "05/24",
          "06/07",
          "06/14",
          "06/21",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };

  const ContactsDetails = {
    series: [
      {
        name: "Sent",
        color: "#302e49",
        data: [44, 55, 57, 56, 61, 8, 63, 60, 66, 56, 45, 65],
      },
      {
        name: "Responses",
        color: "#e9826e",
        data: [76, 85, 19, 98, 87, 105, 11, 114, 94, 40, 115, 110],
      },
      {
        name: "Meetings",
        color: "#ffb963",
        data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 50],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "04/01 - 04/04",
          "04/05 - 04/11",
          "04/12 - 04/18",
          "04/19 - 04/25",
          "04/26 - 05/02",
          "05/03",
          "05/10",
          "05/17",
          "05/24",
          "06/07",
          "06/14",
          "06/21",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };

  const EmailStatus = {
    series: [
      {
        name: "Sent",
        color: "#302e49",
        data: [44, 55, 57, 56, 61, 8, 63, 60, 66, 56, 45, 65],
      },
      {
        name: "Responses",
        color: "#e9826e",
        data: [76, 85, 19, 98, 87, 105, 11, 114, 94, 40, 115, 110],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "04/01 - 04/04",
          "04/05 - 04/11",
          "04/12 - 04/18",
          "04/19 - 04/25",
          "04/26 - 05/02",
          "05/03",
          "05/10",
          "05/17",
          "05/24",
          "06/07",
          "06/14",
          "06/21",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };

  const ramp_chart = {
    series: [
      {
        name: "1-3 months",
        color: "#302e49",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "3-5 months",
        color: "#e9826e",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "5-7 months",
        color: "#ffb963",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "7+ months",
        color: "#f9f871",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const Crushchart = {
    series: [76],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#eef0f8",
            strokeWidth: "97%",
            opacity: "1",
            margin: 5, // margin is in pixels
            // dropShadow: {
            //   enabled: true,
            //   top: 2,
            //   left: 0,
            //   color: '#ccc',
            //   opacity: 1,
            //   blur: 10
            // }
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "colors",
        colors: "#302e49",
        opacity: "1",
        // gradient: {
        //   shade: 'light',
        //   shadeIntensity: 0.4,
        //   inverseColors: false,
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   stops: [48, 46, 73, 1]
        // },
      },
      labels: ["CRUSH %"],
    },
  };

  //charts(FusionCharts);
  // const dataSource = {
  //   chart: {
  //     caption: "Meeting Status",
  //     //subcaption: "For a net-worth of $1M",
  //     showvalues: "1",
  //     showpercentintooltip: "0",
  //     numbersuffix: '%',
  //     "showlegend": "1",
  //     "plothighlighteffect": "fadeout|color=#fff, alpha=60",
  //   "legendcaptionbold": "1",
  //   "legendcaptionfontsize": "16",
  //     //numberprefix: "$",
  //     enablemultislicing: "1",
  //     theme: "fusion"
  //   },
  //   data: [
  //     {
  //       label: "Set",
  //       value: "910000",
  //       "color": "#302e49"
  //     },
  //     {
  //       label: "Qualified",
  //       value: "15000",
  //       "color": "#6f4569"
  //     },
  //     {
  //       label: "Opportunity",
  //       value: "15000",
  //       "color": "#b35d75"
  //     },
  //     {
  //       label: "Contract Sent",
  //       value: "50000",
  //       "color": "#e9826e"
  //     },
  //     {
  //       label: "Closed Won",
  //       value: "15000",
  //       "color": "#ffb963"
  //     },
  //     {
  //       label: "Rescheduling",
  //       value: "20000",
  //       "color": "#f9f871"
  //     }
  //   ]
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h4 className="headertitlepad float-left pl-1">Reporting</h4>
        </div>
        <div className="col padright">
          <div className="btn-group">
            <input id="fromtab" name="fromtab" type="hidden" value="" />
            <input
              className="form-control datepicker"
              id="from-date"
              name="from-date"
              type="date"
              autoComplete="off"
            />
            <input
              className="form-control datepicker"
              id="to-date"
              name="to-date"
              type="date"
              autoComplete="off"
            />
            <button
              onClick={SaveBtn}
              id="aCompanyState"
              className="btn btn-primary btnColor m-btn brrb72"
              autoComplete="off"
            >
              <i className="la flaticon-diagram"></i>
              <span> Analyze</span>
            </button>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="w-100 tabdes">
          <div>
            <AppBar className="px-3" position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Email" {...a11yProps(0)}>
                  Overviewasdasd
                </Tab>
                <Tab label="Contacts" {...a11yProps(1)} />
                <Tab label="Account" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} className="tagpaddnone" index={0}>
              <div className="row">
                <div className="col"> 
                    <EmailTable CampaignIDforEmail={Campaignids} FromDates={FromDate} ToDates={ToDate} /> 
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="row">
                <div className="col"> 
                    <ResportingContact CampaignIDforEmail={Campaignids} FromDates={FromDate} ToDates={ToDate}/> 
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="row w-100">
                <div className="col"> 
                  <AccountsTable CampaignIDforEmail={Campaignids} FromDates={FromDate} ToDates={ToDate}/>
                  </div> 
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
}
