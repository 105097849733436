import React, { useEffect } from "react";
import Axios from "axios";
import { CSVReader, CSVDownloader } from "react-papaparse";
import PropTypes from "prop-types";

import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../../../_components/user/Kpis/LastdaysTabing";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import RemovedTable from "./RemovedTable";
import InterestedTable from "./InterestedTable";
import AutoReplyTable from "./AutoReplyTable";
import AReplyHardBouncesTable from "./AReplyHardBouncesTable";
import HardBouncesTable from "./HardBouncesTable";
import NotInterestedTable from "./NotInterestedTable";
import { GetClientDetails } from "../../../_helpers/Utility";
import { useDebugValue } from "react";
const Fileupload = require("../../../_helpers/fileupload");
import loadingicon from "../../../images/loading.gif";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [ExportRemoved, setExportRemoved] = React.useState([]);
  const [ExportInterseted, setExportInterseted] = React.useState([]);
  const [ExportAutoRplyooo, setExportAutoRplyooo] = React.useState([]);
  const [ExportAutpReplyHard, setExportAutpReplyHard] = React.useState([]);
  const [ExportHardBounce, setExportHardBounce] = React.useState([]);
  const [ExportNotInterseted, setExportNotInterseted] = React.useState([]);
  const [Fields, SetFields] = React.useState({});
  // const [Errors, SetErrors] = React.useState({});
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState(null);
  const [Errors, SetErrors] = React.useState({});
  const [AddDropD, SetAddDropD] = React.useState("");
  const [NameAvaiable, SetNameAvaiable] = React.useState(null);
  const [CName, SetCName] = React.useState("");
  const [IsExist, SetIsExist] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [reset, setReset] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [csvdata, setCsvdata] = React.useState([]);
  const [DropBoxData, SetDropBoxData] = React.useState([]);
  const [opencsv, setOpencsv] = React.useState(false);
  const [MapCsvs, SetMapcsvs] = React.useState(false);

  const [ReloadEffect, SetReloadEffect] = React.useState(false);
  const [HotReload, SetHotReload] = React.useState(false);
  const [ListUpdate, SetListUpdated] = React.useState(false);
  const [BtnDisabled, SetBtnDisabled] = React.useState(false);
  const [ImportDisabled, SetImportDisabled] = React.useState(false);
  const [LoaderActivate, SetLoaderActivate] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setOpencsv(false);
    SetMapcsvs(false);
    SetTotalDisplayCount(false);
    setFilename("");
    SetDropBoxData([]);
    setCsvdata([]);
    SetFields({});
    SetErrors({});
    SetAddDropD("");
    SetBtnDisabled(false);
    SetImportDisabled(false);
  };
  useEffect(() => {
    document.title = "Trigger Phrases | SalesHive";
    var ResearchPageUserInfo = GetUserDetails();
    if (ResearchPageUserInfo != null) {
      SetClientID(ResearchPageUserInfo.ClientID);
      SetUserID(ResearchPageUserInfo.ParentUserID);
      SetCUserID(ResearchPageUserInfo.ChildUserID);
      SetRole(ResearchPageUserInfo.Role);
    }
    GetClientDetails(ResearchPageUserInfo.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    ExportCSVRemoved();
    ExportCSVInterseted();
    ExportCSVAutoReplyooo();
    ExportCSVAutoReplyHard();
    ExportCSVHard();
    ExportCSVNotInterseted();
  }, [ClientID, Role, HotReload, ReloadEffect]);

  //Add dropdown
  const AddDropDown = (Event, Name) => {
    SetAddDropD(Name);
    SetErrors({});
    setOpencsv(false);
    SetMapcsvs(false);
    SetTotalDisplayCount(false);
    SetBtnDisabled(false);
    if (Name == "Removed" && AddDropD == "Removed") {
      document.getElementById("removed").value = "";
      setOpencsv(false);
    } else if (Name == "Interested" && AddDropD == "Interested") {
      document.getElementById("interested").value = "";
      setOpencsv(false);
    } else if (Name == "AutoReplyOOO" && AddDropD == "AutoReplyOOO") {
      document.getElementById("auto-reply-ooo").value = "";
      setOpencsv(false);
    } else if (
      Name == "AutoReplyHardBounces" &&
      AddDropD == "AutoReplyHardBounces"
    ) {
      document.getElementById("auto-reply-hard").value = "";
      setOpencsv(false);
    } else if (Name == "HardBounces" && AddDropD == "HardBounces") {
      document.getElementById("hard-bounce").value = "";
      setOpencsv(false);
    } else if (Name == "NotInterested" && AddDropD == "NotInterested") {
      document.getElementById("not-interseted").value = "";
      setOpencsv(false);
    }
  };

  //Cancle dropdown
  const CancleDropDown = () => {
    SetAddDropD("");
    SetErrors({});
    SetNameAvaiable(null);
  };

  //Common Blur Handler for validation
  const BlurHanlder = (e, tabName) => {
    let Errors = {};
    if (e.target.value.trim() == "") {
      if (tabName == "removed") {
        Errors["removed"] = "Please enter phrase";
      } else if (tabName == "interested") {
        Errors["interested"] = "Please enter phrase";
      } else if (tabName == "auto-reply-ooo") {
        Errors["auto-reply-ooo"] = "Please enter phrase";
      } else if (tabName == "hard-bounce") {
        Errors["hard-bounce"] = "Please enter phrase";
      } else if (tabName == "auto-reply-hard") {
        Errors["auto-reply-hard"] = "Please enter phrase";
      } else if (tabName == "not-interseted") {
        Errors["not-interseted"] = "Please enter phrase";
      }
    } else {
      if (tabName == "removed") {
        Errors["removed"] = "";
      } else if (tabName == "interested") {
        Errors["interested"] = "";
      } else if (tabName == "auto-reply-ooo") {
        Errors["auto-reply-ooo"] = "";
      } else if (tabName == "hard-bounce") {
        Errors["hard-bounce"] = "";
      } else if (tabName == "auto-reply-hard") {
        Errors["auto-reply-hard"] = "";
      } else if (tabName == "not-interseted") {
        Errors["not-interseted"] = "";
      }
    }

    SetErrors(Errors);
  };

  //Reload page while delete perform
  const UpdateFromChild = (value) => {
    if (value == true) {
      SetReloadEffect(!ReloadEffect);
    }
  };

  // For Removed Tab
  const ExportCSVRemoved = () => {
    const InputParameter = {
      PhraseFor: "removed",
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportRemoved(Result.data.Data);
    });
  };
  //save removed
  const SaveRemoved = async (e, FName) => {
    SetLoaderActivate(true);
    SetBtnDisabled(true);
    let Phrase = document.getElementById("removed").value.trim();
    if (Phrase != "") {
      var InputParameter = {
        PhraseFor: FName,
        Phrase: Phrase,
        Role: Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExists",
        method: "POST",
        data: InputParameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          toast.error(
            <div className="toastsize">
              Removed <br /> {Phrase} already exists
            </div>
          );
          SetLoaderActivate(false);
          SetBtnDisabled(false);
        } else {
          var InputParameter = {
            ClientID: ClientID,
            Phrase: Phrase,
            PhraseFor: "removed",
            UserID: UserID,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseAdd",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Removed
                  <br /> Removed added successfully.
                </div>
              );
              document.getElementById("removed").value = "";
              SetAddDropD("");
              SetListUpdated(!ListUpdate);
              SetHotReload(!HotReload);
              CancleDropDown();
              SetLoaderActivate(false);
              // SetBtnDisabled(false);
            } else {
              toast.error(res.data.Message);
              SetLoaderActivate(false);
              SetBtnDisabled(false);
            }
          });
        }
      }
    } else {
      let Errors = {};
      Errors["removed"] = "Please enter phrase";
      SetErrors(Errors);
      SetLoaderActivate(false);
      SetBtnDisabled(false);
    }
    SetLoaderActivate(false);
    //SetBtnDisabled(false);
  };
  //import csv
  const handleOnError = (err) => {};
  //handleremove
  const handleOnRemoveFile = (data) => {};
  //reset
  const resetcsv = () => {
    setReset(!reset);
  };
  //handle
  const handleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    setFiles(fileInfo);
    var sep = filename.split(".");
    if (sep[1] != "csv") {
      setReset(true);
      resetcsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
      setFilename(csvfile);
      // Filter out rows with all empty strings
      const filteredData = data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );

      // Set the filtered data to csvdata
      setCsvdata(filteredData);

      SetDropBoxData(data[0].data);
    }
  };
  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};
  // handle on remove file
  const HandleOnRemoveFile = (data) => {};
  //import
  const importcsv = (e) => {
    e.preventDefault();
    SetAddDropD("");
    SetErrors({});
    setReset(!reset);
    setOpencsv(true);
    SetImportDisabled(false);
    SetMapcsvs(false);
    SetTotalDisplayCount(false);
  };
  // map csv
  const AccountMapCsv = () => {
    SetMapcsvs(false);
    SetTotalDisplayCount(false);
    SetImportDisabled(false);
    if (csvdata.length != 0) {
      DropBoxData.map((dddd, index) => {});
      SetMapcsvs(true);
      resetcsv();
    } else {
      resetcsv();
      setOpencsv(true);

      if (value == 0) {
        toast.error(
          <div className="toastsize">
            removed <br />
            Please select file
          </div>
        );
      } else if (value == 1) {
        toast.error(
          <div className="toastsize">
            interested <br />
            Please select file
          </div>
        );
      } else if (value == 2) {
        toast.error(
          <div className="toastsize">
            autoreply-ooo <br />
            Please select file
          </div>
        );
      } else if (value == 3) {
        toast.error(
          <div className="toastsize">
            autoreply-hardbounces <br />
            Please select file
          </div>
        );
      } else if (value == 4) {
        toast.error(
          <div className="toastsize">
            hardbounces <br />
            Please select file
          </div>
        );
      } else if (value == 5) {
        toast.error(
          <div className="toastsize">
            notinterested
            <br /> Please select file
          </div>
        );
      }
    }
  };
  //Cancle csv
  const canclecsv = () => {
    setOpencsv(false);
    SetMapcsvs(false);
    setCsvdata([]);
    SetImportDisabled(false);
    SetTotalDisplayCount(false);
  };
  //mapcsv
  const Savecsv = (ForPhase) => {
    SetImportDisabled(true);
    SetLoaderActivate(true);

    if (csvdata.length != 0) {
      var PhaseName = document.getElementById("PhaseName").value;
      if (PhaseName != "not mapped") {
        Fileupload.getBase64(files, (result) => {
          var rs = result;
          var splirs = rs.split("base64,");
          var _base64 = splirs[1];
          var myresult = {
            fields: "Coverimg",
            Path: "./Content/UploadedFiles/TriggerPhrase",
            File: _base64,
            ImageName: filename,
            FileData: csvdata,
            ClientID: ClientID,
            UserID: UserID,
            Phrase: PhaseName,
            PhraseFor: ForPhase,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/trigger_phrase/ImportCSVListData",
            method: "POST",
            data: myresult,
          }).then((Res) => {
            if (Res.data.StatusMessage == "SUCCESS") {
              if (ForPhase == "removed") {
                toast.success(
                  <div className="toastsize">
                    Removed <br /> Removed added successfully
                  </div>
                );
              } else if (ForPhase == "interested") {
                toast.success(
                  <div className="toastsize">
                    Interested <br /> Interested added successfully
                  </div>
                );
              } else if (ForPhase == "autoreplypause") {
                toast.success(
                  <div className="toastsize">
                    Auto Reply - OOO <br /> Auto Reply - OOO added successfully
                  </div>
                );
              } else if (ForPhase == "autoreplyblacklist") {
                toast.success(
                  <div className="toastsize">
                    Auto Reply - Hard Bounces <br /> Auto Reply - Hard Bounces
                    added successfully
                  </div>
                );
              } else if (ForPhase == "hardbounces") {
                toast.success(
                  <div className="toastsize">
                    Hard-Bounces <br /> Hard-Bounces added successfully
                  </div>
                );
              } else if (ForPhase == "notinterested") {
                toast.success(
                  <div className="toastsize">
                    Not Interested <br /> Not Interested added successfully
                  </div>
                );
              }
              document.getElementById("PhaseName").disabled = true;
              setCsvdata([]);
              setOpencsv(true);
              SetMapcsvs(true);
              SetTotalResult(Res.data.Total);
              SetTotalDuplicate(Res.data.TotalDuplication);
              SetTotalError(Res.data.TotalError);
              SetTotalSuccess(Res.data.TotalSuccess);
              SetTotalDisplayCount(true);
              SetListUpdated(!ListUpdate);
              SetHotReload(!HotReload);
              CancleDropDown();
              SetLoaderActivate(false);
              SetImportDisabled(true);
            } else {
              toast.error(Res.data.Message);
              SetImportDisabled(false);
              SetLoaderActivate(false);
            }
          });
        });
      } else {
        SetImportDisabled(false);
        SetLoaderActivate(false);

        if (ForPhase == "removed") {
          toast.error(
            <div className="toastsize">
              Removed <br /> Removed not mapped!
            </div>
          );
        } else if (ForPhase == "interested") {
          toast.error(
            <div className="toastsize">
              Interested <br /> Interested not mapped!
            </div>
          );
        } else if (ForPhase == "autoreplypause") {
          toast.error(
            <div className="toastsize">
              Auto Reply - OOO <br /> Auto Reply - OOO not mapped!
            </div>
          );
        } else if (ForPhase == "autoreplyblacklist") {
          toast.error(
            <div className="toastsize">
              Auto Reply - Hard Bounces <br /> Auto Reply - Hard Bounces not
              mapped!
            </div>
          );
        } else if (ForPhase == "hardbounces") {
          toast.error(
            <div className="toastsize">
              Hard-Bounces <br /> Hard-Bounces not mapped!
            </div>
          );
        } else if (ForPhase == "notinterested") {
          toast.error(
            <div className="toastsize">
              Not Interested <br /> Not Interested not mapped!
            </div>
          );
        }
      }
    } else {
      SetImportDisabled(false);
      SetLoaderActivate(false);
    }
  };

  // For Interseted tab
  const ExportCSVInterseted = () => {
    const InputParameter = {
      PhraseFor: "interested",
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportInterseted(Result.data.Data);
    });
  };
  const SaveInterested = async (e) => {
    SetLoaderActivate(true);
    SetBtnDisabled(true);
    let Phrase = document.getElementById("interested").value.trim();
    if (Phrase != "") {
      var InputParameter = {
        PhraseFor: "interested",
        Phrase: Phrase,
        Role: Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExists",
        method: "POST",
        data: InputParameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          toast.error(
            <div className="toastsize">
              Interested <br />
              {Phrase + " already exists"}
            </div>
          );
          SetLoaderActivate(false);
          SetBtnDisabled(false);
        } else {
          let Phrase = document.getElementById("interested").value;
          var InputParameter = {
            ClientID: ClientID,
            Phrase: Phrase,
            PhraseFor: "interested",
            UserID: UserID,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
          };

          let res = await Axios({
            url:
              CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseAdd",
            method: "POST",
            data: InputParameter,
          });
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Interested
                <br /> Interested added successfully.
              </div>
            );
            SetLoaderActivate(false);
            // SetBtnDisabled(false);
            document.getElementById("interested").value = "";
            SetAddDropD("");
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            CancleDropDown();
          } else {
            toast.error(res.data.Message);
            SetLoaderActivate(false);
            SetBtnDisabled(false);
          }
        }
      }
    } else {
      let Errors = {};
      Errors["interested"] = "Please enter phrase";
      SetErrors(Errors);
      SetLoaderActivate(false);
      SetBtnDisabled(false);
    }
  };

  // For Auto Reply -ooo tab
  const ExportCSVAutoReplyooo = () => {
    const InputParameter = {
      PhraseFor: "autoreplypause",
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportAutoRplyooo(Result.data.Data);
    });
  };

  const SaveAutoReplyOOO = async (e) => {
    SetLoaderActivate(true);
    SetBtnDisabled(true);
    let Phrase = document.getElementById("auto-reply-ooo").value.trim();
    if (Phrase != "") {
      var InputParameter = {
        PhraseFor: "autoreplypause",
        Phrase: Phrase,
        Role: Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExists",
        method: "POST",
        data: InputParameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          toast.error(
            <div className="toastsize">
              Auto Reply - OOO
              <br />
              {Phrase + " already exists"}
            </div>
          );
          SetLoaderActivate(false);
          SetBtnDisabled(false);
        } else {
          var InputParameter = {
            ClientID: ClientID,
            Phrase: Phrase,
            PhraseFor: "autoreplypause",
            UserID: UserID,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseAdd",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Auto Reply - OOO
                  <br /> Auto Reply - OOO added successfully.
                </div>
              );
              SetLoaderActivate(false);
              // SetBtnDisabled(false);
              SetListUpdated(!ListUpdate);
              SetHotReload(!HotReload);
              document.getElementById("auto-reply-ooo").value = "";
              CancleDropDown();
            } else {
              toast.error(res.data.Message);
              SetLoaderActivate(false);
              SetBtnDisabled(false);
            }
          });
        }
      }
    } else {
      let Errors = {};
      Errors["auto-reply-ooo"] = "Please enter phrase";
      SetErrors(Errors);
      SetLoaderActivate(false);
      SetBtnDisabled(false);
    }
  };

  // For Auto Reply Hard tab
  const ExportCSVAutoReplyHard = () => {
    const InputParameter = {
      PhraseFor: "autoreplyblacklist",
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportAutpReplyHard(Result.data.Data);
    });
  };

  const SaveAutoReplyHard = async (e) => {
    SetLoaderActivate(true);
    SetBtnDisabled(true);
    let Phrase = document.getElementById("auto-reply-hard").value.trim();
    if (Phrase != "") {
      var InputParameter = {
        PhraseFor: "autoreplyblacklist",
        Phrase: Phrase,
        Role: Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExists",
        method: "POST",
        data: InputParameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          toast.error(
            <div className="toastsize">
              Auto Reply - Hard Bounce
              <br />
              {Phrase + " already exists"}
            </div>
          );
          SetLoaderActivate(false);
          SetBtnDisabled(false);
        } else {
          var InputParameter = {
            ClientID: ClientID,
            Phrase: Phrase,
            PhraseFor: "autoreplyblacklist",
            UserID: UserID,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseAdd",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              SetLoaderActivate(false);
              //  SetBtnDisabled(false);
              toast.success(
                <div className="toastsize">
                  Auto Reply - Hard Bounces
                  <br /> Auto Reply - Hard Bounces added successfully.
                </div>
              );
              document.getElementById("auto-reply-hard").value = "";
              SetListUpdated(!ListUpdate);
              SetHotReload(!HotReload);
              CancleDropDown();
            } else {
              toast.error(res.data.Message);
              SetLoaderActivate(false);
              SetBtnDisabled(false);
            }
          });
        }
      }
    } else {
      let Errors = {};
      Errors["auto-reply-hard"] = "Please enter phrase";
      SetErrors(Errors);
      SetLoaderActivate(false);
      SetBtnDisabled(false);
    }
  };

  //for Hard bounce tab
  const ExportCSVHard = () => {
    const InputParameter = {
      PhraseFor: "hardbounces",
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportHardBounce(Result.data.Data);
    });
  };

  const SaveHardBounce = async (e) => {
    SetLoaderActivate(true);
    SetBtnDisabled(true);
    let Phrase = document.getElementById("hard-bounce").value.trim();
    if (Phrase != "") {
      var InputParameter = {
        PhraseFor: "hardbounces",
        Phrase: Phrase,
        Role: Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExists",
        method: "POST",
        data: InputParameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          toast.error(
            <div className="toastsize">
              {" "}
              Hard Bounce
              <br />
              {Phrase + " already exists"}
            </div>
          );
          SetLoaderActivate(false);
          SetBtnDisabled(false);
        } else {
          var InputParameter = {
            ClientID: ClientID,
            Phrase: Phrase,
            PhraseFor: "hardbounces",
            UserID: UserID,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseAdd",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Hard-Bounces
                  <br /> Hard-Bounces added successfully.
                </div>
              );
              SetLoaderActivate(false);
              //  SetBtnDisabled(false);
              document.getElementById("hard-bounce").value = "";
              SetListUpdated(!ListUpdate);
              SetHotReload(!HotReload);
              CancleDropDown();
            } else {
              toast.error(res.data.Message);
              SetLoaderActivate(false);
              SetBtnDisabled(false);
            }
          });
        }
      }
    } else {
      let Errors = {};
      Errors["hard-bounce"] = "Please enter phrase";
      SetErrors(Errors);
      SetLoaderActivate(false);
      SetBtnDisabled(false);
    }
  };

  //for not interseted tab
  const ExportCSVNotInterseted = () => {
    const InputParameter = {
      PhraseFor: "notinterested",
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportNotInterseted(Result.data.Data);
    });
  };
  const SaveNotInterseted = async (e) => {
    SetLoaderActivate(true);
    SetBtnDisabled(true);
    let Phrase = document.getElementById("not-interseted").value.trim();
    if (Phrase != "") {
      var InputParameter = {
        PhraseFor: "notinterested",
        Phrase: Phrase,
        Role: Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExists",
        method: "POST",
        data: InputParameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          toast.error(
            <div className="toastsize">
              {" "}
              Not Interseted
              <br />
              {Phrase + " already exists"}
            </div>
          );
          SetLoaderActivate(false);
          SetBtnDisabled(false);
        } else {
          var InputParameter = {
            ClientID: ClientID,
            Phrase: Phrase,
            PhraseFor: "notinterested",
            UserID: UserID,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseAdd",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Not Interseted
                  <br /> Not Interseted added successfully.
                </div>
              );
              SetLoaderActivate(false);
              // SetBtnDisabled(false);

              document.getElementById("not-interseted").value = "";
              SetListUpdated(!ListUpdate);
              SetHotReload(!HotReload);
              CancleDropDown();
            } else {
              toast.error(res.data.Message);
              SetLoaderActivate(false);
              SetBtnDisabled(false);
            }
          });
        }
      }
    } else {
      let Errors = {};
      Errors["not-interseted"] = "Please enter phrase";
      SetErrors(Errors);
      SetLoaderActivate(false);
      SetBtnDisabled(false);
    }
  };

  //Export Message
  const ExportMessage = () => {
    if (value == 0) {
      toast.success(
        <div className="toastsize">
          Removed <br />
          Data exported successfully.
        </div>
      );
    } else if (value == 1) {
      toast.success(
        <div className="toastsize">
          Interested <br />
          Data exported successfully.
        </div>
      );
    } else if (value == 2) {
      toast.success(
        <div className="toastsize">
          Auto Reply - OOO <br />
          Data exported successfully.
        </div>
      );
    } else if (value == 3) {
      toast.success(
        <div className="toastsize">
          Auto Reply - Hard Bounces <br />
          Data exported successfully.
        </div>
      );
    } else if (value == 4) {
      toast.success(
        <div className="toastsize">
          Hard-Bounces <br />
          Data exported successfully.
        </div>
      );
    } else if (value == 5) {
      toast.success(
        <div className="toastsize">
          Not Interested
          <br /> Data exported successfully.
        </div>
      );
    }
  };

  return (
    <>
      {LoaderActivate ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="tabdes">
        <div>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Removed" {...a11yProps(0)}>
                Overviewasdasd
              </Tab>
              <Tab label="Interested" {...a11yProps(1)} />
              <Tab label="Auto Reply - OOO" {...a11yProps(2)} />
              <Tab label="Auto Reply - Hard Bounces" {...a11yProps(3)} />
              <Tab label="Hard Bounces" {...a11yProps(4)} />
              <Tab label="Not Interested" {...a11yProps(5)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
            <div className="tabmainerinner pt-0 px-4">
              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlebd">Removed</h4>
                </div>
                <div className="col border-bottom ">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                          onClick={(e) => {
                            AddDropDown(e, "Removed");
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={importcsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-upload"></i>
                          <span>Import</span>
                        </a>
                      </li>
                      <li>
                        <CSVDownloader
                          className="px-0"
                          data={ExportRemoved}
                          filename={CName + " Removed"}
                          bom={true}
                        >
                          <a
                            onClick={() => {
                              ExportMessage();
                            }}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <span>
                              <i class="la la-icon-font-size-13 la-download"></i>
                              Export
                            </span>
                          </a>
                        </CSVDownloader>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* csv */}
              {opencsv ? (
                <div className="portletbody" id="import_csv">
                  <div className="row">
                    <div class="col-xl-8 offset-xl-2 mt-3">
                      <h3 class="uploadheadingtitle">Import Removed</h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={[
                            "text/csv",
                            ".csv",
                            "application/vnd.ms-excel",
                          ]}
                          onDrop={handleOnDrop}
                          onError={handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={handleOnRemoveFile}
                          isReset={reset}
                        >
                          <span>Select .CSV file</span>
                          <p>Add removed data from csv file to import.</p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2 border-top mt-3">
                      <div class="alignright">
                        <a
                          onClick={AccountMapCsv}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-upload"></i> Upload
                        </a>
                        <a onClick={canclecsv} class="btn btn-secondary">
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {MapCsvs ? (
                <div className="portletbody portletshadow p-4 m-1 map_attribute" id="map_attribute">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left pl-0">Map attributes</h4>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group m-form__group row mx-0">
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="ListImports">
                              Phrase
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            id="PhaseName"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top pt-4 mt-2">
                      <button
                        onClick={() => {
                          Savecsv("removed");
                        }}
                        disabled={ImportDisabled}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {TotalDisplayCount ? (
                <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div class="row colfive">
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-primary">
                                  {TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-danger">
                                  {TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* add */}
              {AddDropD === "Removed" ? (
                <div className="px-3 pb-2">
                  <div className="row borderbtnm py-4">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text pl-0">
                          Phrase
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            type="text"
                            id="removed"
                            onBlur={(e) => {
                              BlurHanlder(e, "removed");
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {Errors["removed"]}
                          </span>
                          {NameAvaiable && (
                            <span style={{ color: "red" }}>
                              Status already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3">
                    <div className="col-xl-12">
                      <div class="alignright">
                        <button
                          class="btn btn-primary btnColor"
                          id="submitA"
                          onClick={(e) => {
                            SaveRemoved(e, "removed");
                          }}
                          disabled={BtnDisabled}
                        >
                          <i class="la la-save"></i> Save
                        </button>
                        <a class="btn btn-secondary" onClick={CancleDropDown}>
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <div className="table-bordered border-none">
                    <RemovedTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="bg-white" index={1}>
            <div className="tabmainerinner pt-0 px-4">
              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlebd">Interested</h4>
                </div>
                <div className="col border-bottom ">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                          onClick={(e) => {
                            AddDropDown(e, "Interested");
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={importcsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-upload"></i>
                          <span>Import</span>
                        </a>
                      </li>
                      <li>
                        <CSVDownloader
                          className="px-0"
                          data={ExportInterseted}
                          filename={CName + " Interested"}
                          bom={true}
                        >
                          <a
                            onClick={() => {
                              ExportMessage();
                            }}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i class="la la-icon-font-size-13 la-download"></i>
                            <span>Export</span>
                          </a>
                        </CSVDownloader>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* csv */}
              {opencsv ? (
                <div className="portletbody" id="import_csv">
                  <div className="row">
                    <div class="col-xl-8 offset-xl-2  mt-3">
                      <h3 class="uploadheadingtitle">Import Interested</h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={[
                            "text/csv",
                            ".csv",
                            "application/vnd.ms-excel",
                          ]}
                          onDrop={handleOnDrop}
                          onError={handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={handleOnRemoveFile}
                          isReset={reset}
                        >
                          <span>Select .CSV file</span>
                          <p>Add interested data from csv file to import.</p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2 border-top mt-3">
                      <div class="alignright">
                        <a
                          onClick={AccountMapCsv}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-upload"></i> Upload
                        </a>
                        <a onClick={canclecsv} class="btn btn-secondary">
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {MapCsvs ? (
                <div className="portletbody portletshadow p-4 m-1 map_attribute" id="map_attribute">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left pl-0">Map attributes</h4>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group m-form__group row mx-0">
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="ListImports">
                              Phrase
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            id="PhaseName"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top pt-4 mt-2">
                      <button
                        onClick={() => {
                          Savecsv("interested");
                        }}
                        disabled={ImportDisabled}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {TotalDisplayCount ? (
                <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div class="row colfive">
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-primary">
                                  {TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-danger">
                                  {TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {AddDropD === "Interested" ? (
                <div className="px-3 pb-2">
                  <div className="row borderbtnm py-4">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text pl-0">
                          Phrase
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id="interested"
                            type="text"
                            onBlur={(e) => {
                              BlurHanlder(e, "interested");
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {Errors["interested"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3">
                    <div className="col-xl-12">
                      <div class="alignright">
                        <button
                          class="btn btn-primary btnColor"
                          id="InterestedButton"
                          onClick={(e) => {
                            SaveInterested(e);
                          }}
                          disabled={BtnDisabled}
                        >
                          <i class="la la-save"></i> Save
                        </button>
                        <a class="btn btn-secondary" onClick={CancleDropDown}>
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <div className="table-bordered border-none">
                    <InterestedTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="bg-white" index={2}>
            <div className="tabmainerinner pt-0 px-4">
              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlebd">Auto Reply - OOO </h4>
                </div>
                <div className="col border-bottom ">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                          onClick={(e) => {
                            AddDropDown(e, "AutoReplyOOO");
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={importcsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-upload"></i>
                          <span>Import</span>
                        </a>
                      </li>
                      <li>
                        <CSVDownloader
                          className="px-0"
                          data={ExportAutoRplyooo}
                          filename={CName + " Auto_Reply_-_OOO"}
                          bom={true}
                        >
                          <a
                            onClick={() => {
                              ExportMessage();
                            }}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i class="la la-icon-font-size-13 la-download"></i>
                            <span>Export</span>
                          </a>
                        </CSVDownloader>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* csv */}
              {opencsv ? (
                <div className="portletbody" id="import_csv">
                  <div className="row">
                    <div class="col-xl-8 offset-xl-2 mt-3">
                      <h3 class="uploadheadingtitle">
                        Import Auto Reply - OOO
                      </h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={[
                            "text/csv",
                            ".csv",
                            "application/vnd.ms-excel",
                          ]}
                          onDrop={handleOnDrop}
                          onError={handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={handleOnRemoveFile}
                          isReset={reset}
                        >
                          <span>Select .CSV file</span>
                          <p>
                            Add auto reply - OOO data from csv file to import.
                          </p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2 border-top mt-3">
                      <div class="alignright">
                        <a
                          onClick={AccountMapCsv}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-upload"></i> Upload
                        </a>
                        <a onClick={canclecsv} class="btn btn-secondary">
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {MapCsvs ? (
                <div className="portletbody portletshadow p-4 m-1 map_attribute" id="map_attribute">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left pl-0">Map attributes</h4>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group m-form__group row mx-0">
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="ListImports">
                              Phrase
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            id="PhaseName"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top pt-4 mt-2">
                      <button
                        onClick={() => {
                          Savecsv("autoreplypause");
                        }}
                        disabled={ImportDisabled}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {TotalDisplayCount ? (
                <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div class="row colfive">
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-primary">
                                  {TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-danger">
                                  {TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {AddDropD === "AutoReplyOOO" ? (
                <div className="px-3 pb-2">
                  <div className="row borderbtnm py-4">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text pl-0">
                          Phrase
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id="auto-reply-ooo"
                            type="text"
                            onBlur={(e) => {
                              BlurHanlder(e, "auto-reply-ooo");
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {Errors["auto-reply-ooo"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3">
                    <div className="col-xl-12">
                      <div class="alignright">
                        <button
                          class="btn btn-primary btnColor"
                          id="AutoReplyOOOButton"
                          onClick={(e) => {
                            SaveAutoReplyOOO(e);
                          }}
                          disabled={BtnDisabled}
                        >
                          <i class="la la-save"></i> Save
                        </button>
                        <a class="btn btn-secondary" onClick={CancleDropDown}>
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <div className="table-bordered border-none">
                    <AutoReplyTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="bg-white" index={3}>
            <div className="tabmainerinner pt-0 px-4">
              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlebd">Auto Reply - Hard Bounces</h4>
                </div>
                <div className="col border-bottom ">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                          onClick={(e) => {
                            AddDropDown(e, "AutoReplyHardBounces");
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={importcsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-upload"></i>
                          <span>Import</span>
                        </a>
                      </li>
                      <li>
                        <CSVDownloader
                          className="px-0"
                          data={ExportAutpReplyHard}
                          filename={CName + " Auto_Reply_-_Hard_Bounces"}
                          bom={true}
                        >
                          <a
                            onClick={() => {
                              ExportMessage();
                            }}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i class="la la-icon-font-size-13 la-download"></i>
                            <span>Export</span>
                          </a>
                        </CSVDownloader>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* csv */}
              {opencsv ? (
                <div className="portletbody" id="import_csv">
                  <div className="row">
                    <div class="col-xl-8 offset-xl-2 mt-3">
                      <h3 class="uploadheadingtitle">
                        Import Auto Reply - Hard Bounces
                      </h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={[
                            "text/csv",
                            ".csv",
                            "application/vnd.ms-excel",
                          ]}
                          onDrop={handleOnDrop}
                          onError={handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={handleOnRemoveFile}
                          isReset={reset}
                        >
                          <span>Select .CSV file</span>
                          <p>
                            Add auto reply - hard bounces data from csv file to
                            import.
                          </p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2 border-top mt-3">
                      <div class="alignright">
                        <a
                          onClick={AccountMapCsv}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-upload"></i> Upload
                        </a>
                        <a onClick={canclecsv} class="btn btn-secondary">
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {MapCsvs ? (
                <div className="portletbody portletshadow p-4 m-1 map_attribute" id="map_attribute">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left pl-0">Map attributes</h4>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group m-form__group row mx-0">
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="ListImports">
                              Phrase
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            id="PhaseName"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top pt-4 mt-2">
                      <button
                        onClick={() => {
                          Savecsv("autoreplyblacklist");
                        }}
                        disabled={ImportDisabled}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {TotalDisplayCount ? (
                <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div class="row colfive">
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-primary">
                                  {TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-danger">
                                  {TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {AddDropD === "AutoReplyHardBounces" ? (
                <div className="px-3 pb-2">
                  <div className="row borderbtnm py-4">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text pl-0">
                          Phrase
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id="auto-reply-hard"
                            type="text"
                            onBlur={(e) => {
                              BlurHanlder(e, "auto-reply-hard");
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {Errors["auto-reply-hard"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3">
                    <div className="col-xl-12">
                      <div class="alignright">
                        <button
                          class="btn btn-primary btnColor"
                          id="AutoReplyHardButton"
                          onClick={(e) => {
                            SaveAutoReplyHard(e);
                          }}
                          disabled={BtnDisabled}
                        >
                          <i class="la la-save"></i> Save
                        </button>
                        <a class="btn btn-secondary" onClick={CancleDropDown}>
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <div className="table-bordered border-none">
                    <AReplyHardBouncesTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="bg-white" index={4}>
            <div className="tabmainerinner pt-0 px-4">
              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlebd">Hard Bounces</h4>
                </div>
                <div className="col border-bottom ">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                          onClick={(e) => {
                            AddDropDown(e, "HardBounces");
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={importcsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-upload"></i>
                          <span>Import</span>
                        </a>
                      </li>
                      <li>
                        <CSVDownloader
                          className="px-0"
                          data={ExportHardBounce}
                          filename={CName + " Hard-Bounces"}
                          bom={true}
                        >
                          <a
                            onClick={() => {
                              ExportMessage();
                            }}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i class="la la-icon-font-size-13 la-download"></i>
                            <span>Export</span>
                          </a>
                        </CSVDownloader>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* csv */}
              {opencsv ? (
                <div className="portletbody" id="import_csv">
                  <div className="row">
                    <div class="col-xl-8 offset-xl-2 mt-3">
                      <h3 class="uploadheadingtitle">Import Hard Bounces</h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={[
                            "text/csv",
                            ".csv",
                            "application/vnd.ms-excel",
                          ]}
                          onDrop={handleOnDrop}
                          onError={handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={handleOnRemoveFile}
                          isReset={reset}
                        >
                          <span>Select .CSV file</span>
                          <p>Add hard bounces data from csv file to import.</p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2 border-top mt-3">
                      <div class="alignright">
                        <a
                          onClick={AccountMapCsv}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-upload"></i> Upload
                        </a>
                        <a onClick={canclecsv} class="btn btn-secondary">
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {MapCsvs ? (
                <div className="portletbody portletshadow p-4 m-1 map_attribute" id="map_attribute">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left pl-0">Map attributes</h4>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group m-form__group row mx-0">
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="ListImports">
                              Phrase
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            id="PhaseName"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top pt-4 mt-2">
                      <button
                        onClick={() => {
                          Savecsv("hardbounces");
                        }}
                        disabled={ImportDisabled}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {TotalDisplayCount ? (
                <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div class="row colfive">
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-primary">
                                  {TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-danger">
                                  {TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {AddDropD === "HardBounces" ? (
                <div className="px-3 pb-2">
                  <div className="row borderbtnm py-4">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text pl-0">
                          Phrase
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id="hard-bounce"
                            type="text"
                            onBlur={(e) => {
                              BlurHanlder(e, "hard-bounce");
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {Errors["hard-bounce"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3">
                    <div className="col-xl-12">
                      <div class="alignright">
                        <button
                          class="btn btn-primary btnColor"
                          id="HardBounceButton"
                          onClick={(e) => {
                            SaveHardBounce(e);
                          }}
                          disabled={BtnDisabled}
                        >
                          <i class="la la-save"></i> Save
                        </button>
                        <a class="btn btn-secondary" onClick={CancleDropDown}>
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <div className="table-bordered border-none">
                    <HardBouncesTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="bg-white" index={5}>
            <div className="tabmainerinner pt-0 px-4 px-4">
              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlebd">Not Interested</h4>
                </div>
                <div className="col border-bottom ">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                          onClick={(e) => {
                            AddDropDown(e, "NotInterested");
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={importcsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-upload"></i>
                          <span>Import</span>
                        </a>
                      </li>
                      <li>
                        <CSVDownloader
                          className="px-0"
                          data={ExportNotInterseted}
                          filename={CName + " Not_Interseted"}
                          bom={true}
                        >
                          <a
                            onClick={() => {
                              ExportMessage();
                            }}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i class="la la-icon-font-size-13 la-download"></i>
                            <span>Export</span>
                          </a>
                        </CSVDownloader>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* csv */}
              {opencsv ? (
                <div className="portletbody" id="import_csv">
                  <div className="row">
                    <div class="col-xl-8 offset-xl-2 mt-3">
                      <h3 class="uploadheadingtitle">Import Not Interested</h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={[
                            "text/csv",
                            ".csv",
                            "application/vnd.ms-excel",
                          ]}
                          onDrop={handleOnDrop}
                          onError={handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={handleOnRemoveFile}
                          isReset={reset}
                        >
                          <span>Select .CSV file</span>
                          <p>
                            Add not interested data from csv file to import.
                          </p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2 border-top mt-3">
                      <div class="alignright">
                        <a
                          onClick={AccountMapCsv}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-upload"></i> Upload
                        </a>
                        <a onClick={canclecsv} class="btn btn-secondary">
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {MapCsvs ? (
                <div className="portletbody portletshadow p-4 m-1 map_attribute" id="map_attribute">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left pl-0">Map attributes</h4>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group m-form__group row mx-0">
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="ListImports">
                              Phrase
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            id="PhaseName"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top pt-4 mt-2">
                      <button
                        onClick={() => {
                          Savecsv("notinterested");
                        }}
                        disabled={ImportDisabled}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {TotalDisplayCount ? (
                <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div class="row colfive">
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-primary">
                                  {TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-danger">
                                  {TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2">
                                <span class="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {AddDropD === "NotInterested" ? (
                <div className="px-3 pb-2">
                  <div className="row borderbtnm py-4">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text pl-0">
                          Phrase
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id="not-interseted"
                            type="text"
                            onBlur={(e) => {
                              BlurHanlder(e, "not-interseted");
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {Errors["not-interseted"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3">
                    <div className="col-xl-12">
                      <div class="alignright">
                        <button
                          class="btn btn-primary btnColor"
                          id="NotIntersetedButton"
                          onClick={(e) => {
                            SaveNotInterseted(e);
                          }}
                          disabled={BtnDisabled}
                        >
                          <i class="la la-save"></i> Save
                        </button>
                        <a class="btn btn-secondary" onClick={CancleDropDown}>
                          <i class="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <div className="table-bordered border-none">
                    <NotInterestedTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
}
