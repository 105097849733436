import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddDefaultObjectionsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      StatusAvailable: null,
      IsExist: false,
      IsSubmitting: false,
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Add | SalesHive";
  }

  // form validation
  async FormValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Objection = document.getElementById("Objection").value.trim();
    var IsExist = await this.CheckObjectionExist(Objection);
    if (Objection.length <= 0) {
      FormIsValid = false;
      Errors["Objection"] = "Please enter objection";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Objection != "") {
      this.state.Errors.Objection = null;
      this.state.StatusAvailable = null;
      this.CheckObjectionExist(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  //check exist Status
  async CheckObjectionExist(Name) {
    var Inputparameter = {
      ClientID: this.state.ClientID,
      Objection: Name,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultobjections/DefaultObjectionExist",
      method: "POST",
      data: Inputparameter,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({ StatusAvailable: resdata.data.Data[0]?.Objection });
        this.setState({ IsExist: true });
        return true;
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  BackBtn() {
    history.push("/defaultobjections");
  }

  async SaveBtn(e) {
    this.state.Errors.Objection = null;
    this.state.StatusAvailable = null;
    this.setState({ IsSubmitting: true });
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      var Objection = document.getElementById("Objection").value;
      var Response = document.getElementById("Response").value;
      var InputParameter = {
        ID: this.props.location.state,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Objection: Objection,
        Response: Response,
        ValueFrom:"DefaultObjection",
        CreatedDt:new Date(),
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultobjections/DefaultObjectionsAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Default Objections
              <br />
              Default objection added successfully.
            </div>
          );
          history.push("/defaultobjections");
        } else {
          toast.error(res.data.Message);
        }
        this.setState({ IsSubmitting: false });
      });
    } else {
      // document.getElementById("submit").disabled = false;
      this.setState({ IsSubmitting: false });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0">Add</h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row pt-2 pb-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Objection</label>
                    <div class="col-lg-8">
                      <textarea
                        class="form-control m-input"
                        id="Objection"
                        onBlur={this.HandleChange.bind(this, "Objection")}
                        placeholder="Please enter objection"
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Objection"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Objection already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2 pb-2">
                <div class="col-lg-6 boxsinput_group py-0">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Response</label>
                    <div class="col-lg-8">
                      <textarea
                        class="form-control m-input"
                        id="Response"
                        placeholder="Please enter response"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-4 pt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsSubmitting}
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDefaultObjectionsPage = connect(
  mapState,
  actionCreators
)(AddDefaultObjectionsPage);
export { connectedAddDefaultObjectionsPage as AddDefaultObjectionsPage };
