import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../../_components/user/footer/footer';
import Axios from 'axios';
import { history } from '../../../_helpers';
import { CommonConstants } from '../../../_constants/common.constants';
import { GetUserDetails } from "../../../_helpers/Utility";
//import CallHistory from '../_components/coldcalling/callhistory';

import CEditCampaignsTabing from '../../../_components/clients/EditCampaigns/EditCampaignsTabing';
  
import 'react-toastify/dist/ReactToastify.css'; 
class CEditCampaignsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        pager: {},
        pageOfItems: [],
        files: [],
        list:[],
        columns:[],
        reset:false,
        csvData:[],
        code:"",
        fields: {},
        errors: {},
        NameAvailable: null,
        ClientID:null ,
        UserID:null,
        CUserID:null,
        Role:null,
        Name:"",
        Active:false,
        campaignID:null,
        Page:""
    }; 
} 
async componentDidMount(){
    document.title = "Edit Campaign | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ClientID:Details.ClientID});
      this.setState({UserID:Details.ParentUserID});
      this.setState({CUserID:Details.ChildUserID});
      this.setState({Role:Details.Role});
    }
    
    
    var CampaignID = ""
    if(this.props.location.state.data != undefined){
        CampaignID = this.props.location.state.data;
        this.setState({campaignID:this.props.location.state.data})
    }
    if(this.props.location.state.CampaignID != undefined){
        CampaignID = this.props.location.state.CampaignID;
        this.setState({campaignID:this.props.location.state.CampaignID})
        this.setState({Page:"contact"})
    }
    var StrIn = {
        CampaignID:CampaignID
      }
      var Result = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignByID",
        method: "POST",
        data: StrIn,
      });
      
      if(Result.data.StatusMessage === "SUCCESS"){
        this.setState({Name:Result.data.Data[0].Name})
        this.setState({Active:Result.data.Data[0].IsActive})
      }
}
async CampaignActiveUpdate(CAID){
    
    var Str_in = {
        IsActive:!this.state.Active,
        CampaignID:CAID,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
      };
      const rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL + "/campaign/CampaignActiveUpdate",
        method: "POST",
        data: Str_in,
      });
      if(rows1.data.StatusMessage == "SUCCESS"){
        this.setState({Active:!this.state.Active})
        //GetCampaignsList(ClientID,UserID,Role)
      }else if(rows1.data.StatusMessage == "EmailSignatureError"){
        toast.error(<div>Campaign<br />Email signature is required</div>);
  
      }
      else{
        toast.error(<div>Campaign<br />Please Add Account, Prospect and Step atlest one.</div>);
      }
}

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd pl-0 mb-0">Edit Campaign: {this.state.Name}	</h4>
                        </div>
                        <div className="col padright d-flex justify-content-end">
                        <div class="toggleswich mt-1">
                            <input type="checkbox"  class="checktogle" onChange={()=>{this.CampaignActiveUpdate(this.state.campaignID)}} checked={this.state.Active}/>
                            <b class="switch">
                              <span class="checkion"></span>
                              <span class="uncheckion"></span>
                            </b>
                            <b class="track"></b>
                          </div>
                        </div>
                    </div> 

                    <div className="col padd-0">
                        <CEditCampaignsTabing campaignID={this.props.location.state.data} Page={this.state.Page}/>
                    </div>
                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCEditCampaignsPage = connect(mapState, actionCreators)(CEditCampaignsPage);
export { connectedCEditCampaignsPage as CEditCampaignsPage };