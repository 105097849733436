
import React, { Component } from 'react';
import Axios from 'axios';
import CloseIcon  from "@material-ui/icons/Close";

import { CommonConstants } from '../_constants/common.constants'
const Fileupload = require("../_helpers/fileupload");
const imgstyle = { width: "100px", height: "100px" };

class ProfileImageUpload extends Component {
  
  static defaultProps = {
    NewForm: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      ImgFlag: false,
      FinalImg: null,
      FileImg: '',
      defaultImage:"default.png",
      FileName:this.props.profileImage != "default.png" ? this.props.profileImage : "default.png" ,
      ImageRemove: this.props.NewForm ? true : false
      
    };
   
  }
  handleUpload = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];

    if (
      Fileupload.checkMimeType(selectedFile) &&
      Fileupload.checkFileSize(selectedFile)
    ) {
      const temp = URL.createObjectURL(selectedFile);

      const filetype = selectedFile.name.split(".");
      let file_Name;

      if (/\s/g.test(filetype[0])) {
        file_Name = filetype[0].replaceAll(/\s/g, "") + new Date().getTime() + "." + filetype[1];
      } else {
        file_Name = filetype[0] + new Date().getTime() + "." + filetype[1];
      }

      this.setState({
        FinalImg: selectedFile,
        ImgFlag: true,
        FileImg: temp,
        FileName: file_Name,
        ImageRemove: false,
      });
    } else {
      // Handle invalid file type or size here
    }
  };

  handleRemoveImage = () => {
    this.setState({
      ImageRemove: true,
      FileName: "default.png",
      ImgFlag:false
    });
  };

  SubmitData = () => {
    return new Promise((resolve, reject) => {
      const { FinalImg } = this.state;
      
  
      if (FinalImg) {
        Fileupload.getBase64(FinalImg, (result) => {
          const rs = result;
          const splirs = rs.split("base64,");
          const _base64 = splirs[1];
  
          const myresult = {
            fields: "Coverimg",
            path: "./Content/UploadedFiles/UserProfilePic",
            file: _base64,
            imageName: this.state.FileName, // Use this.state.FileName
          };
  
          Axios({
            url: CommonConstants.MOL_APIURL + "/AdminUser/ImageSave",
            method: "POST",
            data: myresult,
          })
            .then((res) => {
              // Resolve the promise with the response data
              resolve({ ...res, FileName: this.state.FileName !== undefined ? this.state.FileName : "default.png" });
            })
            .catch((error) => {
              // Reject the promise with the error if there's an issue
              reject(error);
            });
        });
      } else {
        
        if((this.props.profileImage !== "" || this.props.profileImage !== "default.png" ) && this.state.ImageRemove === false){
            resolve({FileName:this.props.profileImage})
        }else{
          resolve({FileName:"default.png"});

        }
        // If there's no FinalImg, resolve the promise with a message or some other value
      }
    });
  };
  


  render() {
    
    return (
      <div className="col-lg-6 boxsinput_group py-1">
        <div className="row">
          <label className="col-lg-3 view-lable-meet">Upload Profile Image</label>
          <div className="col-lg-8">
            <input
              type="file"
              id="imageUpload"
              accept=".png, .jpg, .jpeg"
              name="avatar"
              onChange={this.handleUpload.bind(this)}
            />
            {
              this.state.ImageRemove ? <></> : <div id="imagePreview" style={{ position: 'relative',width:'130px',height:'130px',marginTop:"20px" }}>
              <img
                  style={imgstyle}
                  className="avatar-view container2"
                  src={
                  this.state.ImgFlag
                      ? this.state.FileImg
                      : CommonConstants.Image_url + this.props.profileImage
                  }
                  
                  alt="Prpfile Image"
                  id="avtar-preview"
              />
              {
                  this.state.ImgFlag !== 'default.png' ? <button
                  style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  }}
                  onClick={this.handleRemoveImage} // Replace with your remove image function
              >
                 {/* <i className="la la-close" style={{fontWeight:"bolder"}}></i> */}
                 <CloseIcon/>
              </button> : <></>
              }
              
          </div>
            }
            
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileImageUpload;


