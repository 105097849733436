import React, { useState } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

class ViewApiKeyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Apikey: [],
      ClientID: null,
      UserID: null,
      ClientName: "",
      ValueKey: "",
      Copied: false,
      isInputHighlighted: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.title = `View | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID, UserID: Details.ChildUserID });
    }
    this.ApikeyGet();
  }

  // Api get by id
  ApikeyGet() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      ApikeyId: id,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/Apikey/ApikeyByIDGet",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage === "SUCCESS") {
        if (res.data.Data.length > 0) {
          this.setState({ ValueKey: res.data.Data[0]?.ApiKey });
          this.setState({ ClientName: res.data.Data[0]?.Name });
        }
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  // Back
  BackBtn() {
    history.push("/apiKey");
  }

  editItem = () => {
    this.inputRef.current.select();
  };

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.ValueKey);
    this.editItem();
  };

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left mb-0">View</h4>
              </div>
            </div>

            <div className="paddcols">
              <div className="row pt-4">
                <div className="col-lg-12 boxsinput_group">
                  <div className="row">
                    <label className="col-lg-2 view-lable-meet full-right">
                      Client Name
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <text id="clientname">{this.state.ClientName}</text>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 boxsinput_group">
                  <div className="row">
                    <label className="col-lg-2 view-lable-meet full-right">
                      API Key
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Api Key"
                        id="ApiKey"
                        value={this.state.ValueKey}
                        name="valueKey"
                        placeholder="Api Key"
                        type="text"
                        readOnly
                        ref={this.inputRef}
                      />
                    </div>
                    <div className="col-lg-2">
                      <div className="btn btngroup" onClick={this.copyToClipboard}>
                        <span>Copy</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row mb-5 mt-3"> 
                <div className="col-lg-8 pull-left">
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedViewApiKeyPage = connect(
  mapState,
  actionCreators
)(ViewApiKeyPage);
export { connectedViewApiKeyPage as ViewApiKeyPage };
