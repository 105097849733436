import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import EmailWarmupHistory from '../../_components/user/EmailWarmup/EmailWarmupHistory';
//import CollapsibleTable from '../_components/usertable';
import { history } from "../../_helpers";



class EmailWarmUpHistoryPage extends React.Component {
   constructor(props){
    super(props);
    this.state={
        AccountID:this.props.location.state.ID
    }
   }
    componentDidMount(){
        document.title="Account WarmUp Mail History | SalesHive"
      
    }
    BackBtn(){
        history.push('/emailwarmup')
    }
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                    <Sidebar className=""/>
                 
                    <div className="bodyhome">
                            <div className="row">
                                <div className="col">
                                    <h4 className="headertitlenop float-left py-4">Account WarmUp Mail History</h4>
                                </div>
                                <div className="col">
                                    <div className="listing-li float-right padb-15">
                                        <ul>
                                            <li>
                                            <a
                                                id="backtolist"
                                                onClick={this.BackBtn}
                                                class="btn btn-secondary"
                                            >
                                                <i class="la la-arrow-circle-left"></i> Back
                                            </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>  
                            <div className="row">
                                <div className="col"> 
                                        <EmailWarmupHistory AccountID = {this.state.AccountID}/> 
                                    </div> 
                            </div>      
    
                    </div>  
                </div>
                <Footer/>


            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedEmailWarmUpHistoryPage = connect(mapState, actionCreators)(EmailWarmUpHistoryPage);
export { connectedEmailWarmUpHistoryPage as EmailWarmUpHistoryPage };