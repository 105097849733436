import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function MeetingSourceTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading, SetIsLoading] = React.useState(false);
  useEffect(() => {
    document.title = `Meeting Sources | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    MeetingSourceGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

  // get meeting source list
  const MeetingSourceGet = (CID,UID,URole) => {
    SetIsLoading(true);
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingsource/MeetingSourceGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });
  };

  // delete meeting source
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a meeting source.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          MeetingSourceID: ID,
          LastUpdatedBy: CUserID,
          LastUpdatedDt: new Date(),
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/meetingsource/MeetingSourceDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Meeting source deleted successfully.",
                "success"
              );
              //document.getElementById("hideloding").style.display = "block";
              MeetingSourceGet(ClientID,UserID,Role);
              history.push("/CMeetingSource")
              SetPage(1)
              SetSearch("")
              document.getElementById("search").value = ""
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // edit btn
  const EditBtn = (ID) => {
    history.push({ pathname: "/CEditMeetingSource", state: { data: ID } });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
       // document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
      // document.getElementById("hideloding").style.display = "block";
    }
  };

// change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

//get sort field data
const SortData = (Field) => {
  SetIsLoading(true);
  var SearchedVal = document.getElementById("search").value;
  //document.getElementById("hideloding").style.display = "block";
  // SetPage(1);
  // SetRowsPerPage(10);
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/meetingsource/MeetingSourceGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      //document.getElementById("hideloding").style.display = "none";
    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/meetingsource/MeetingSourceGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      //document.getElementById("hideloding").style.display = "none";
    });
  }
};

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
              {/* <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option> */}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                <a
                  onClick={() => {
                    SortData("Name");
                  }}
                  >
                  Name</a></TableCell>
                <TableCell>
                <a
                  onClick={() => {
                    SortData("FieldName");
                  }}
                  >Meeting Channel</a></TableCell>
                <TableCell><a
                  onClick={() => {
                    SortData("MeetingChannelType");
                  }}
                  >Meeting Channel Type</a></TableCell>
                <TableCell>
                <a
                  onClick={() => {
                    SortData("IsSetByPipestry");
                  }}
                  >Count In Reporting?</a></TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{row.FieldName}</TableCell>
                    <TableCell>{row.MeetingChannelType}</TableCell>
                    <TableCell>{row.IsSetByPipestry==true?"Yes":"No" }</TableCell>
                    <TableCell>
                      <a
                        href="#"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        href="#"
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
