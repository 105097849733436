import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import ContactTag from "../../../_components/user/ContactSettings/Tag/ContactTag";

import { CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetClientDetails } from "../../../_helpers/Utility";

toast.configure();

class ContactTagPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientName:"",
      Filename: "",
      ClientID:null,
      UserID:null,
      CUserID:null,
      ExportData: [],
      Role:null
    };
    this.AddBtn = this.AddBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role=Details.Role
    }

    GetClientDetails(Details.ClientID).then((result) => {
      // SetCName(result[0].Name);
      this.setState({ClientName:result[0].Name})
    });
    var str_in = {
      ClientID: this.state.ClientID,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagExport",
      method: "POST",
      data: str_in,
    }).then((res) => {   
      this.setState({ ExportData: res.data.Data });

    });
  }

  // add button
  AddBtn() {
    history.push("/addcontacttag");
  }

 //Reload page while delete perform
 UpdateFromChild = (value) => {
  if(value == true){
    this.componentDidMount()
  }
}

  // export csv button
  ExportCsv() {
    toast.success(<div>Data exported successfully</div>);

  }

  render() {
    return (
      <>
        <div className="adminmain mheight">  
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col px-3">
                <h4 className="headertitlebd float-left px-1">Tag</h4>
                </div>
                <div className="col">
                <div className="listing-li float-right padb-15 pt-2">
                  <ul>
                    <li>
                    <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            filename={`${this.state.ClientName}-ContactTag`}
                            bom={true}
                          >
                      <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
          
                            Export
                         
                        </span>
                      </a>
                      </CSVDownloader>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                  </div>
                </div>
            </div>

            <div class="paddcols px-0 pb-2">
              <div className="row">
                <div className="col px-5">
                  <ContactTag updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContactTag = connect(mapState, actionCreators)(ContactTagPage);
export { connectedContactTag as ContactTagPage };
