import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

class AddCampaignCategoriesPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      IsButtonDisabled: false,
      IsExist: false,
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    document.title = "Add Category | SalesHive";
  }

  

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistCategoryName(name);
    if (name == "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.StatusAvailable = null;
      this.CheckExistCategoryName(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Name = null;
    }
  }


  //check exist name
  async CheckExistCategoryName(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role: this.state.Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/Campaigncategory/CategoryExists",
      method: "POST",
      data: str_in,
    });
 if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({ StatusAvailable: resdata.data.Data[0]?.Name });
        this.setState({ IsExist: true });
        return true;
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  //back
  BackBtn() {
    history.push("/CampaignCategories");
  }

  //add account status
  async SaveBtn(e) {
    e.preventDefault();
    var Name = document.getElementById("Name").value;
    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var AddField = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        Name: Name,
        IsDeleted: false,
        CreatedDt: new Date(),
        CreatedBy: this.state.CUserID,
        LastUpdatedDt: null,
        LastUpdatedBy: null,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/Campaigncategory/CategoryAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Campaign Categories
              <br />
              Category added successfully.
            </div>
          );
          history.push("/CampaignCategories");
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
            <div className="row px-0">
              <div className="col">
                <h4 className="headertitlepad float-left px-0">
                  Add Category
                </h4>
              </div>
            </div>

            <div class="paddcols">
              <div className="row pt-2 px-0 pb-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">
                      Category Name
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        type="text"
                      />

                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                        Name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-3 px-0 mt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedcampaigncategories = connect(
  mapState,
  actionCreators
)(AddCampaignCategoriesPage);
export { connectedcampaigncategories as AddCampaignCategoriesPage };
