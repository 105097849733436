import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Avatar } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";
import Userionimg from "../../../images/default.png";
import loadingicon from "../../../images/loading.gif";
import { CSVReader, CSVDownloader } from "react-papaparse";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({
  ToDate,
  FromDate,
  IsIncludeOldClients,
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ColdCallersName");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [MeetingSet, SetMeetingSet] = React.useState(0);
  const [MeetingSetLW, SetMeetingSetLW] = React.useState(0);
  const [MeetingSetTW, SetMeetingSetTW] = React.useState(0);
  const [CUserID,SetCUserID] = React.useState(0)

  const classes = useRowStyles();
  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    ClientReschedulingGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      ToDate,
      FromDate,
      IsIncludeOldClients
    );
    Exportdetails(Details.ParentUserID, ToDate, FromDate, IsIncludeOldClients);
  }, [Search, Page, RowsPerPage, ToDate, FromDate, IsIncludeOldClients]);

  // Client rescheduling get list
  const ClientReschedulingGet = (
    CID,
    UID,
    URole,
    ToDate,
    FromDate,
    IsOldClientss
  ) => {
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToDate,
      FromDate: FromDate,
      IncludeOldClient: IsOldClientss,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/SalesDevelopmentRepsGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  // Export details
  const Exportdetails = (UID, FromDates, ToDates, IsIncludeOldClient) => {
    let InputParameter = {
      UserID: UID,
      ToDate: ToDates,
      FromDate: FromDates,
      IncludeOldClient: IsIncludeOldClient,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/allclientkpi/SalesDevelopmentRepsExports",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res?.data.Data);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    var SearchedVal = document.getElementById("search").value;
    if (Event.key === "Enter") {
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  const exportcsv = (e) => {
    e.preventDefault();
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };
  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDatess,
        FromDate: FromDatess,
        IncludeOldClient: IsOldClientss,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/allclientkpi/SalesDevelopmentRepsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDatess,
        FromDate: FromDatess,
        IncludeOldClient: IsOldClientss,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/allclientkpi/SalesDevelopmentRepsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };
  return (
    <>
      <div className="row">
        <div className="w-100 whiterow py-2">
          <div className="row">
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{MeetingSet}</span>
                </div>
                <p class="clr-sky">Meetings Set</p>
              </div>
            </div>
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{MeetingSetLW}</span>
                </div>
                <p class="clr-sky">Meeting Set LW</p>
              </div>
            </div>
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{MeetingSetTW}</span>
                </div>
                <p class="clr-sky">Meeting Set TW</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left">Sales Development Reps</h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <CSVDownloader
                data={ExportData}
                filename={"AllClients-ColCallersMeeting"}
                bom={true}
              >
                <li>
                  <a
                    href=""
                    onClick={exportcsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                </li>
              </CSVDownloader>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {/* <TableCell align="center"><input type="checkbox" /></TableCell> */}
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("ColdCallersName");
                    }}
                  >
                    Sales Development Reps
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("MeetingCount");
                    }}
                  >
                    Meetings
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("AllMeetingCount");
                    }}
                  >
                    Meetings All-Time
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("ClientCount");
                    }}
                  >
                    Total Clients
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("LWMeetingCount");
                    }}
                  >
                    Meetings LW
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("TWMeetingCount");
                    }}
                  >
                    Meetings TW
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.map((row) => (
                <TableRow key={row.name} row={row}>
                  <TableCell>
                    <img
                      style={imgstyle}
                      src={
                        row.ProfileImage == ""
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url + row.ProfileImage
                      }
                    ></img>
                    {" " + row.ColdCallersName}
                  </TableCell>
                  <TableCell>
                    {row.MeetingCount ? row.MeetingCount : 0}
                  </TableCell>
                  <TableCell>{row.AllMeetingCount}</TableCell>
                  <TableCell>{row.ClientCount}</TableCell>
                  <TableCell>
                    {row.LWMeetingCount ? row.LWMeetingCount : 0}
                  </TableCell>
                  <TableCell>
                    {row.TWMeetingCount ? row.TWMeetingCount : 0}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
