import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>  
        <TableCell>
          <select className="form-control m-input"> 
            <option>Totale Calls</option> 
          </select>
        </TableCell>
        <TableCell>
          <select className="form-control m-input"> 
            <option>Less Than</option> 
          </select>
        </TableCell>
        <TableCell>
          <input type="text" id="Value_1" class="form-control m-input required" />
        </TableCell> 
        <TableCell align='center'> 
          <button className="btn btn btngroup m-btn"><i class="la flaticon-delete-1 delete-icon"></i></button>
        </TableCell> 
      </TableRow> 

      <TableRow className={classes.root}>  
        <TableCell>
          <select className="form-control m-input"> 
            <option>Days Since Last Call</option> 
          </select>
        </TableCell>
        <TableCell>
          <select className="form-control m-input"> 
            <option>Greater Than Equal To</option> 
          </select>
        </TableCell>
        <TableCell>
          <input type="text" id="Value_1" class="form-control m-input required" />
        </TableCell> 
        <TableCell align='center'> 
          <button className="btn btn btngroup m-btn"><i class="la flaticon-delete-1 delete-icon"></i></button>
        </TableCell> 
      </TableRow> 

      <TableRow className={classes.root}>  
        <TableCell>
          <select className="form-control m-input"> 
            <option>Last Call Result</option> 
          </select>
        </TableCell>
        <TableCell>
          <select className="form-control m-input"> 
            <option>Equals</option> 
          </select>
        </TableCell>
        <TableCell>
          <input type="text" id="Value_1" class="form-control m-input required" />
        </TableCell> 
        <TableCell align='center'> 
          <button className="btn btn btngroup m-btn"><i class="la flaticon-delete-1 delete-icon"></i></button>
        </TableCell> 
      </TableRow> 
    </React.Fragment>
    
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99), 
];

export default function AccountAllTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell></TableCell> 
            <TableCell></TableCell> 
            <TableCell></TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow> 
        <TableCell>
          <select className="form-control m-input"> 
            <option>Totale Calls</option> 
          </select>
        </TableCell>
        <TableCell>
          <select className="form-control m-input"> 
            <option>Less Than</option> 
          </select>
        </TableCell>
        <TableCell>
          <input type="text" id="Value_1" class="form-control m-input required" />
        </TableCell> 
        <TableCell align='center'> 
          <button className="btn btn btngroup m-btn"><i class="la flaticon-delete-1 delete-icon"></i></button>
        </TableCell> 
      </TableRow> 

      {/* <TableRow>  
        <TableCell>
          <select className="form-control m-input"> 
            <option>Days Since Last Call</option> 
          </select>
        </TableCell>
        <TableCell>
          <select className="form-control m-input"> 
            <option>Greater Than Equal To</option> 
          </select>
        </TableCell>
        <TableCell>
          <input type="text" id="Value_1" class="form-control m-input required" />
        </TableCell> 
        <TableCell align='center'> 
          <button className="btn btn btngroup m-btn"><i class="la flaticon-delete-1 delete-icon"></i></button>
        </TableCell> 
      </TableRow>  */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
