import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { arrayMoveImmutable } from "array-move";

import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));
const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items.map((value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={value.Reason}
        />
      ))}
    </ul>
  );
});
class CContactReasonUnqualifiedSetOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      ClientID: null,
      UserID: null,
      Role:""
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }

  componentDidMount() {
    document.title = 'Contact Reason Unqualified Order | SalesHive';
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role=Details.Role
    }
    this.ContactReasonUnqualifiedOrder(Details.ClientID,);
  }

  // contact reason unqualified order
  ContactReasonUnqualifiedOrder(CID) {
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      SortBy: 1,
      Type: "User",
      ClientID: CID,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contactreasonunqualified/ContactReasonUnqualifiedGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      this.setState({ Items: result.data.PageData });
      document.getElementById("hideloding").style.display = "none";
    });
  }

  // on sort end
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

  // save button
  SaveBtn() {
    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveOrderData();
  }

  // save order data
  SaveOrderData() {
    let str_in = {
      ContactReasonunqualifiedList: this.state.Items,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contactreasonunqualified/ContactReasonUnqualifiedSetOrder",
      method: "POST",
      data: str_in,
    });
    rows1.then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Contact reason order updated successfully.",
          "Contact set order order"
        );
        history.push("/CContactReasonUnqualified");
      } else {
        toast.error(res.data.Message);
      }
      this.ContactReasonUnqualifiedOrder();
    });
  }

  // back button
  BackBtn() {
    history.push("/CContactReasonUnqualified");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Contact Reason Order
                  </h4>
                </div>
              </div>

              <div class="paddcols">
                <div className="row pt-4">
                  <div className="col-xl-12 offset-xl-12 p-0">
                    <span className="alertinfo">
                      &nbsp;<b>NOTE</b> : Please drag &amp; drop item to change
                      order of contact reason and press save button.
                    </span>

                    <div class="spacupgroup">
                      <SortableList
                        Items={this.state.Items}
                        onSortEnd={this.OnSortEnd}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
              </div>

              <div class="row my-3">
                <div class="col">
                  <a
                    id="submit"
                    onClick={() => {
                      this.SaveBtn();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save order
                  </a>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCContactReasonUnqualifiedSetOrderPage = connect(
  mapState,
  actionCreators
)(CContactReasonUnqualifiedSetOrderPage);
export { connectedCContactReasonUnqualifiedSetOrderPage as CContactReasonUnqualifiedSetOrderPage };
