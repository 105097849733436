import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVReader, CSVDownloader } from "react-papaparse";

import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import Research from "../../_components/user/ActionItems/ResearchTable";
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";

toast.configure();


class ResearchPage extends React.Component {
  constructor(props) {
 
    super(props);
    this.state = {
      ExportData: [],
      ClientName:""
    };
    this.addpage = this.addpage.bind(this);
  }
  addpage() {
    history.push("/addresearch");
  }
  componentDidMount() {
    document.title = "Research | SalesHive";
    var Details = GetUserDetails();
 
    const Inputparameters={
      ClientID:Details.ClientID,
      UserID:Details.ParentUserID
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ResearchExport",
      method: "POST",
      data:Inputparameters
    }).then((Res) => {
      this.setState({ ExportData: Res.data.Data });
      this.setState({ClientName:Res.data.ClientName})

    });
  }

  ExportCsv() {
    toast.success(<div>Data exported successfully.</div>);

  }
    //Reload page while delete perform
    UpdateFromChild = (value) => {
      if(value == true){
        this.componentDidMount()
      }
    }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left pl-0">Research</h4>
              </div> 
              <div className="col">
              <div className="align-items-center d-flex justify-content-end main">
                <div className="py-1 pr-15 listing-li float-right padb-15">
                  <ul>
                    <li className="p-0">
                    <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            filename={`${this.state.ClientName}-Research`}
                            bom={true}
                          >
                      <a
                        onClick={()=>{this.ExportCsv()}}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                    
                            Export
                       
                        </span>
                      </a>
                      </CSVDownloader>
                    </li>
                    <li className="px-1">
                      <a
                        onClick={() => this.addpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
                </div>
              </div>
            </div>

            <div class="whiterow px-4 pt-4">
              <div className="row">
                <div className="col px-4">
                  <Research updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedResearchPage = connect(mapState, actionCreators)(ResearchPage);
export { connectedResearchPage as ResearchPage };
