import React,{ useEffect } from 'react';
import Axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from "react-toastify";

import Pagination from "@material-ui/lab/Pagination";
import { CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetClientDetails } from "../../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function CollapsibleTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CampaignExportData, SetCampaignExportData] = React.useState([]);
  const [CName, SetCName] = React.useState("");


  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetUserIDby(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ExportCampaign(Details.ClientID,Details.Role);
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    CampaignGet(Details.ClientID,Details.Role);


  }, [Search, Page, RowsPerPage,SortedBy]);

  const CampaignGet = (CID,Role) => {
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired:true
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiCampaignGet",
      method: "POST",
      data: data,
    }).then((Res) => {
      

      if (Res.data.StatusMessage == "SUCCESS") {
        SetRows(Res.data.PageData);
        SetRlen(Res.data.TotalCount);
        SetFlen(Res.data.TotalCount);
        SetCountPage(Res.data.PageCount);
      }

    });
  };

  const ExportCampaign = (CID,Role) => {
    
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired:false
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiCampaignGet",
      method: "POST",
      data: data,
    }).then((Res) => {
     if (Res.data.StatusMessage == "SUCCESS") {
        var ExportData=[]
        if(Res.data.PageData.length > 0)
        {
          for(var i=0;i < Res.data.PageData.length; i++)
          {
            var objCampaign=Res.data.PageData[i];
            
            
            
            var data={
              Name:objCampaign.Name,
              Sent:objCampaign.CampaignStepHistory.TotalSend,
              "Open %":objCampaign.CampaignStepHistory?.TotalTracked >0 ? (objCampaign.CampaignStepHistory?.TotalOpen / objCampaign.CampaignStepHistory?.TotalTracked) * 100 :0,
              "Reply %":objCampaign.CampaignStepHistory?.TotalSend >0 ? (objCampaign.CampaignStepHistory?.TotalReply / objCampaign.CampaignStepHistory?.TotalSend) * 100 :0,
              "Bounce %":objCampaign.CampaignStepHistory?.TotalSend >0 ? (objCampaign.CampaignStepHistory?.TotalHardBounce / objCampaign.CampaignStepHistory?.TotalSend) * 100 :0
            }
            ExportData.push(data);
          }
          SetCampaignExportData(ExportData);
        }else{
          var data={
            Name:"",
            Sent:"",
            "Open %":"",
            "Reply %":"",
            "Bounce %":""
          }
          
          ExportData.push(data);
          SetCampaignExportData(ExportData);
        }



      }

    });
  };

   //change display rows
   const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  const SortData = (Field) => {
     if (SortedBy == 1) {

        SetSortedBy(-1);
      } else {

        SetSortedBy(1);
      }
      SetSortField(Field);

  };

  const ExportCSv= ()=>{
    toast.success(<div className="toastsize">Kpi <br/>Data exported successfully.</div>);
  }
  return (
    <>
    <div className="row">
    <div className="col border-bottom px-0">
        <h4 className="headertitle float-left padx-0">Campaigns</h4>

        <div className="listing-li float-right padb-15">
            <ul>
            <li>
                  <CSVDownloader data={CampaignExportData}  filename={CName +"-Campaign"} bom={true}>
                    <a onClick={() => {ExportCSv(); }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                      <i class="la la-icon-font-size-13 la-download"></i>Export
                    </a>
                    </CSVDownloader>
              </li>
            </ul>
        </div>
    </div>
  </div>


<div className="row padt-15 px-3">
    <div className="col-sm-12 col-md-6 px-0">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
      </div>
  <div className="col-sm-12 col-md-6 full-right px-0">
  <label className="textlabelte">Search: <input type="search" id="search" onKeyPress={RequestSearch} class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
  </div>
</div>

<div className="table-bordered">
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">

        <TableHead>
          <TableRow>
            <TableCell>
            <a onClick={() => {SortData("Name");}}>Campaign Name</a>
              </TableCell>
             <TableCell>Sent</TableCell>
            <TableCell>Open %</TableCell>
            <TableCell>Reply %</TableCell>
            <TableCell>Bounce %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.map((row) => (
            <TableRow key={row._id}>
            <TableCell>{row.Name}</TableCell>
            <TableCell>{row.CampaignStepHistory?.TotalSend}</TableCell>
            <TableCell>{row.CampaignStepHistory?.TotalTracked > 0 ?  (row.CampaignStepHistory?.TotalOpen / row.CampaignStepHistory?.TotalTracked) * 100 :0}</TableCell>
            <TableCell>{row.CampaignStepHistory?.TotalSend > 0 ?  (row.CampaignStepHistory?.TotalReply / row.CampaignStepHistory?.TotalSend) * 100 :0}</TableCell>
            <TableCell>{row.CampaignStepHistory?.TotalSend > 0 ?  (row.CampaignStepHistory?.TotalHardBounce / row.CampaignStepHistory?.TotalSend) * 100 :0} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}