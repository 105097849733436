import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ColdCallerPhoneBurnerLogin from "../../_components/user/ColdCallerPhoneBurnerLogin/ColdCallerPhoneBurnerLoginPage.js";
import Popup from "reactjs-popup";
import { GetUserDetails } from "../../_helpers/Utility";
import Axios from "axios";
import { CommonConstants } from "../../_constants/common.constants";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";

import { toast } from "react-toastify";
toast.configure();
const ColdCallerPhoneBurnerLoginPage = ({ login, logout, loggingIn }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [clientID, setClientID] = useState("");
  const [userID, setUserID] = useState("");
  const [role, setRole] = useState("");
  const [userIDby, setUserIDby] = useState("");
  const [salesDevelopmentresp, setSalesDevelopmentresp] = useState([]);
  const [clientname, setClientsName] = useState([]);
  const [ClientsID, setClientsID] = useState("");
  const [coldcallerIDs, setColdcallerIDs] = useState("");
  const [search, setSearch] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [totalentries, setTotalEntries] = useState(15);
  const [successMessage, setSuccessMessage] = useState("");

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get("message");

    if (message) {
      setSuccessMessage(message);
    }
  }, [location.search]);

  useEffect(() => {
    const Details = GetUserDetails();
    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      setRole(Details.Role);
      setUserIDby(Details.ChildUserID);
    }
    AddnewAuntication();
    setIsLoading(false);
  }, []);

  
  const AddnewAuntication = async () => {
    var Details = GetUserDetails();
    var InputData = {
      UserID: Details.ParentUserID,
    };
    const result = await Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetSalesDevelopmentResp",
      method: "POST",
      data: InputData,
    });
    setSalesDevelopmentresp(result.data);
  };
  const GetclientsName = async (data) => {
    console.log("data", data);
    var InputData = {
      ColdCallerID: data,
    };
    await setColdcallerIDs(data);
    const result = await Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetClientNames",
      method: "POST",
      data: InputData,
    });

    setClientsName(result.data);
  };
  const handleclientidandcoldcallerid = async (data) => {
    await setClientsID(data.split(",")[0]),
      await setColdcallerIDs(data.split(",")[1]);
  };

  const ColdCallerListExists = async (CID,ccIDs) =>{
    debugger
    var InputParameter = {
      ClientsID:CID,
      ColdCallerID:ccIDs,
      Role:role
    }
    var Result  = await Axios({url: CommonConstants.MOL_APIURL + "/login/CheckPhoneBurnerExists",method:"POST",data:InputParameter})
    if(Result.data?.StatusMessage === "SUCCESS"){
      return Result.data?.data?.CheckDataFlage;
    }else{
      return false
    }
  }

  const Authenticatebtn = async () => {
    setIsButtonDisabled(true);
    if (ClientsID != "" && coldcallerIDs != "") {
      debugger
      var IsExists = await ColdCallerListExists(ClientsID,coldcallerIDs);
      debugger
      if(IsExists=== true){

      toast.error(
        <div className="toastsize">
          Sales Development Reps Phone Burner Login
          <br />
          Pair already exist.
        </div>
      );
      setIsButtonDisabled(false);
      }else{
        var Details = GetUserDetails();
        var InputParameter = {
          UserID: Details.ParentUserID,
          ClientID: ClientsID,
          ColdCallerID: coldcallerIDs,
          redirectPage: "coldcallerphoneburnerlogin",
        };
        
        Axios({
          url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerAuthCode",
          method: "POST",
          data: InputParameter,
          withCredentials: true,
        }).then((res) => {
          setIsButtonDisabled(false);
          if (res.data.StatusCode == "Valid") {
            window.location.href = res.data.Url;
          }
        });
      }
    } else if (coldcallerIDs == "") {
      setIsButtonDisabled(false);

      toast.error(
        <div className="toastsize">
          Sales Development Reps Phone Burner Login
          <br />
          Please select sales development reps and client.
        </div>
      );
    } else if (ClientsID == "") {
      setIsButtonDisabled(false);

      toast.error(
        <div className="toastsize">
          Sales Development Reps Phone Burner Login
          <br />
          Please select client.
        </div>
      );
    }
  };
  // search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var searchedVal = document.getElementById("search").value;
      setSearch(searchedVal);
    }
  };
  //   handlemessgae
  const handleclearmessages = async () => {
    ;
    setSuccessMessage("");
    const { pathname, search } = location;
    const urlParams = new URLSearchParams(search);
    urlParams.delete("message");

    history.push({
      pathname: pathname,
      search: urlParams.toString(),
    });
  };

  return (
    <>
      <div className="adminmain mheight">
        <Sidebar className="" />
        <div className="bodyhome">
          <div className="row">
            <div className="col">
              <h4 className="headertitle float-left pl-0">
                Cold Caller Phone Burner Login
              </h4>
              <div className="listing-li float-right padb-15">
                <ul>
                  <li>
                    <Popup
                      trigger={
                        <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                          <i
                            className="la la-icon-font-size-13 la-plus"
                            onClick={AddnewAuntication}
                          ></i>
                        </a>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div>
                          <div className="modal-black"></div>
                          <div className="filterPopup largerPopup900">
                            <div className="paddingboxTerms">
                              <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                  <h5 className="mb-0">Authentication</h5>
                                  <button className="close" onClick={close}>
                                    <span
                                      aria-hidden="true"
                                      className="la la-remove"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                              <div className="modal-body">
                                <div className="px-4">
                                  <div class="row">
                                    <div class="col-lg-8 row">
                                      <label class="col-lg-5 col-form-label m--align-right fs-600">
                                        Sales Development Reps
                                      </label>
                                      <div class="col-lg-7">
                                        <select
                                          class="form-control  m-input"
                                          data-val="true"
                                          data-val-number="The field ColdCallerID must be a number."
                                          id="ColdCallerID"
                                          name="ColdCallerID"
                                          onChange={(e) =>
                                            GetclientsName(e.target.value)
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {salesDevelopmentresp?.map(
                                            (rows, index) => (
                                              <option
                                                key={index}
                                                // value={rows._id+'----'+rows.ColdCallerTeamsID}
                                                value={`${rows._id}`}
                                              >
                                                {rows.ColdCallersName +
                                                  " " +
                                                  rows.LastName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 row">
                                      <label class="col-lg-5 col-form-label m--align-right fs-600">
                                        Client
                                      </label>
                                      <div class="col-lg-7">
                                        <select
                                          id="ClientID"
                                          class="form-control m-input"
                                          disabled=""
                                          onChange={(e) =>
                                            handleclientidandcoldcallerid(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {clientname?.map((rows, index) => (
                                            <option
                                              key={index}
                                              // value={rows._id+'----'+rows.ColdCallerTeamsID}
                                              value={`${rows.ClientID},${rows.ColdCallersID}`}
                                            >
                                              {rows.Client}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-dismiss="modal"
                                  onClick={close}
                                >
                                  Cancel
                                </button>
                                <button
                                  href="javascript:void(0);"
                                  onClick={Authenticatebtn}
                                  disabled={isButtonDisabled}
                                  className="btn btn-primary btnColor"
                                >
                                  <i className="la la-save mr-2"></i>{" "}
                                  Authenticate
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="paddcols py-4">
            {successMessage === "successfully" ? (
              <div
                style={{
                  backgroundColor: "#fff",

                  color: "#34bfa3",
                  fontWeight: 400,
                  padding: "0.85rem 1.5rem",
                  borderRadius: "4px",
                  position: "relative",
                  marginBottom: "1rem",
                  border: "1px solid transparent",
                  borderColor: "#34bfa3",
                }}
              >
                {" "}
                <strong>Success!</strong> Phone Burner Authentication is done.{" "}
                <button
                  type="button"
                  style={{
                    float: "right",
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    lineHeight: 1,
                    color: "#34bfa3",
                    textShadow: "0 1px 0 #fff",
                    opacity: 0.5,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={handleclearmessages}
                >
                  x
                </button>
              </div>
            ) : successMessage === "error" ? (
              <div>Error</div>
            ) : null}

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={(e) => setTotalEntries(e.target.value)}
                  >
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="search"
                    onKeyPress={RequestSearch}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <ColdCallerPhoneBurnerLogin
                  search={search}
                  totalentries={totalentries}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedColdCallerPhoneBurnerLoginPage = connect(
  mapState,
  actionCreators
)(ColdCallerPhoneBurnerLoginPage);
export { connectedColdCallerPhoneBurnerLoginPage as ColdCallerPhoneBurnerLoginPage };
