import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";



class CAddDocumentsPage extends React.Component { 
    //Initialization of States
    constructor(props) {
      super(props);
  
      this.state = {
        IsExist: false,
        TitleNotNull: false,
        UrlNotNull: false,
        Type: false,
        IsUrl: true,
        reset: false,
        fields: {},
        errors: {},
        ClientID: null,
        UserID: null,
        CUserID:null,
        Role: null,
        TypeList: [],
        formData: {
          Title: "",
          Url: "",
          Type: "",
        },
        IsSubmitting:false
      };
  
      this.BackBtn = this.BackBtn.bind(this);
      this.SaveBtn = this.SaveBtn.bind(this);
    }
    //State Life Cycle
    componentDidMount() {
      document.title="Add Document | SalesHive";
      var Details = GetUserDetails();
  
      if (Details != null) {
        this.state.ClientID = Details.ClientID;
        this.state.UserID = Details.ParentUserID;
        this.state.CUserID = Details.ChildUserID;
        this.setState({ Role: Details.Role });
      }
      this.GetTypeDropDown();
    }
    // Get Type Drop Down
    GetTypeDropDown() {
      const InputParameter = {
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/documents/TypeGet",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          const TypeData = res.data.Data;
          const option = TypeData.map((Type) => ({
            FieldID: Type.FieldID,
            FieldName: Type.FieldName,
          }));
          this.setState({ TypeList: option });
          document.getElementById("hideloding").style.display = "none";
        } else {
          toast.error(res.data.Message);
        }
      });
    }
    //check exist Title
    CheckExistDocumentTitle(Title) {
      var InputParameters = {
        ClientID: this.state.ClientID,
        Title: Title,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/documents/DocumentsTitleExists",
        method: "POST",
        data: InputParameters,
      }).then((resdata) => {
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ IsExist: true });
            return true;
          } else {
            this.setState({ IsExist: false });
            return false;
          }
        }
      });
    }
  
    // Regex for Url Validation
    UrlPatternValidation = (URL) => {
      var regex = new RegExp(
        "^(https?:\\/\\/)" +
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); //validation
      return regex.test(URL);
    };
  
    //Back Button
    BackBtn() {
      history.push("/CDocuments");
    }
    //Save Button
    SaveBtn(e) {
      this.setState({IsSubmitting : true})
      if (this.HandleValidation()) {
        var Title = document.getElementById("Title").value.trim();
        var UrlLink = document.getElementById("Url").value.trim();
        var TypeID = document.getElementById("Type").value.trim();
        var Data = {
          ClientID: this.state.ClientID,
          UserID: this.state.UserID,
          Title: Title,
          Url: UrlLink,
          TypeID: TypeID,
          CreatedBy: this.state.CUserID,
          CreatedDate: new Date(),
          Role: this.state.Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/documents/DocumentAdd",
          method: "POST",
          data: Data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div>
                Document Add
                <br />
                Document added successfully.
              </div>
            );
            history.push("/CDocuments");
          } else {
            toast.error(res.data.Message);
            this.setState({IsSubmitting : false})
          }
        });
      } else {
        this.setState({IsSubmitting : false})
  
      }
    }
  
    HandleChange = (event) => {
      const { formData } = this.state;
      formData[event.target.name] = event.target.value;
      this.setState({ formData });
    };
    HandleValidation = () => {
      console.log(this.UrlPatternValidation(this.state.formData.Url))
      let formIsValid = true;
      const errors = {};
      this.CheckExistDocumentTitle(this.state.formData.Title);
      // Validate Title field
      if (!this.state.formData.Title) {
        formIsValid = false;
        errors.Title = "Please enter title";
      } else if (this.state.IsExist) {
        formIsValid = false;
        errors.Title = "Title already exist.";
      } else {
        errors.Title = "";
      }
      // Validate Url field
      if (!this.state.formData.Url) {
        formIsValid = false;
        errors.Url = "Please enter url";
      } else if (!this.UrlPatternValidation(this.state.formData.Url)) {
        formIsValid = false;
        errors.Url = "Please enter valid url";
      } else {
        errors.Spend = "";
      }
      // Validate Type field
      if (!this.state.formData.Type) {
        formIsValid = false;
        errors.Type = "Please select type";
      } else {
        errors.Type = "";
      }
      this.setState({ errors });
  
      return formIsValid;
    };
    HandleBlur = (event) => {
      const { name, value } = event.target;
      const { errors } = this.state;
  
      if (!value) {
        errors[name] = `Please enter ${name.toLowerCase()}`;
      } else {
        if (name === "Title") {
          this.CheckExistDocumentTitle(value);
          if (this.state.IsExist) {
            errors[name] = `${name} already exist.`;
          } else {
            delete errors[name];
          }
        } else if (name == "Url") {
          if (!this.UrlPatternValidation(value)) {
            errors[name] = `Please enter valid url`;
          } else {
            delete errors[name];
          }
        } else {
          delete errors[name];
        }
      }
      this.setState({ errors });
    };
  
    render() {
      return (
        <>
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
          <div className="adminmain mheight"> 
            <Sidebar className="" /> 
            <div className="bodyhome">
              
              <div className="row">
                <div className="col">
                  <h4 className="headertitlenop float-left py-4 mb-0">Add Document </h4>
                </div>
              </div>

              <div class="bg-white">
                <div className="row py-3 px-4">
                  <div class="col-lg-6 boxsinput_group px-4">
                    <div class="row max-cols">
                      <label class="col-lg-3 view-lable-meet">Title</label>
                      <div class="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Title"
                          name="Title"
                          placeholder="Enter title"
                          type="text"
                          value={this.state.formData.Title}
                          onChange={this.HandleChange}
                          onBlur={this.HandleBlur}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Title"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols px-4">
                      <label class="col-lg-3 view-lable-meet">Url</label>
                      <div class="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Url"
                          name="Url"
                          placeholder="Enter url"
                          type="text"
                          value={this.state.formData.Url}
                          onChange={this.HandleChange}
                          onBlur={this.HandleBlur}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Url"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group px-4">
                    <div class="row max-cols ">
                      <label class="col-lg-3 view-lable-meet">Type</label>
                      <div class="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field Status must be a number."
                          id="Type"
                          name="Type"
                          value={this.state.formData.Type}
                          onChange={this.HandleChange}
                          onBlur={this.HandleBlur}
                        >
                          <option>--Select--</option>
                          {this.state.TypeList.map((val) => (
                            <option value={val.FieldID}>{val.FieldName}</option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["Type"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
                <div class="row mb-3 mt-3 pb-4">
                  <div class="col-lg-12 pull-left">
                    <button
                      id="submit"
                      onClick={this.SaveBtn}
                      disabled={this.state.IsSubmitting}
                      class="btn btn-primary btn-lightgreen mr-1"
                    >
                      <i class="la la-save"></i> Save
                    </button>
                    <a
                      id="backtolist"
                      onClick={this.BackBtn}
                      class="btn btn-secondary"
                    >
                      <i class="la la-arrow-circle-left"></i> Back
                    </a>
                  </div>
                </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  }

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddDocumentsPage = connect(
  mapState,
  actionCreators
)(CAddDocumentsPage);
export { connectedCAddDocumentsPage as CAddDocumentsPage };
