import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Avatar } from "@material-ui/core";
import { GetUserDetails } from "../../../_helpers/Utility";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";

import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: "2020-01-05", customerId: "11091700", amount: 3 },
      { date: "2020-01-02", customerId: "Anonymous", amount: 1 },
    ],
  };
}

function Row(props) {
  const { row, SetSearch1, SetPage, PhoneburnerLoginListGet } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const ReAuthenticatebtn = (row) => {
    var Details = GetUserDetails();
    var InputParameter = {
      UserID: Details.ParentUserID,
      ClientID: row.ClientID,
      ColdCallerID: row.ColdCallersID,
      redirectPage: "coldcallerphoneburnerlogin",
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerAuthCode",
      method: "POST",
      data: InputParameter,
      withCredentials: true,
    }).then((res) => {
      if (res.data.StatusCode == "Valid") {
        window.location.href = res.data.Url;
      }
    });
  };
  //Delete record of phone burner
  //delete action item
  const DeleteAuths = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a cold caller phoneburner login.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((Result) => {
      if (Result.isConfirmed) {
        var Details = GetUserDetails();
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: Details.ChildUserID,
          Role: Details.Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/login/DeletePhoneBurner",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.data.StatusMessage = "SUCCESS")) {
              Swal.fire(
                "Deleted!",
                "Cold caller phoneburner login deleted successfully.",
                "success"
              );
              // document.getElementById("hideloding").style.display = "block";
              SetSearch1("");
              SetPage(1);
              var Details = GetUserDetails();
              var InputData = {
                ID: ID,
                LastUpdatedDate: new Date(),
                LastUpdatedBy: Details.ChildUserID,
                Role: Details.Role,
              };
              PhoneburnerLoginListGet();
              // document.getElementById("search").value = ""
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {!row.IsAuthenticated ? (
          <TableCell>
            <a
              className="btn btn-sm btn-primary btnColor  m-btn"
              onClick={() => ReAuthenticatebtn(row)}
              title="Authenticate"
            >
              Authenticate
            </a>
          </TableCell>
        ) : (
          <TableCell>
            <button
              style={{
                backgroundColor: "#3DAF8F",
                color: "white",
                fontSize: "0.9rem",
                border: "1px solid transparent",
                textDecoration: "none",
                padding: "0.25rem 0.5rem",
                lineHeight: "1.5",
                borderRadius: "0.2rem",
                fontWeight: "300",
                WebkitTextFillColor: "white",
              }}
              title="Working"
            >
              Working
            </button>
          </TableCell>
        )}
        <TableCell>{row.ColdCallersName}</TableCell>
        <TableCell>{row.ClientName}</TableCell>
        <TableCell> {row.UserEmail} </TableCell>
        <TableCell>
          <a
            onClick={() => {
              DeleteAuths(row._id);
            }}
            className="btn-eyesicon"
          >
            <i class="la flaticon-delete-1 delete-icon"></i>
          </a>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function AccountAllTable({ search, totalentries }) {
  const [rows, setRows] = React.useState([]);
  const [data, setdata] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [sortField, setsortField] = React.useState("CreatedDate");
  const [sortedBy, setsortedBy] = React.useState(-1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [search1, setSearch1] = React.useState("");
  const [loading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [flen, setflen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  useEffect(() => {
    SetRowsPerPage(totalentries);
  }, [totalentries]);
  useEffect(() => {
    SetIsLoading(true);
    var Details = GetUserDetails();
    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      setRole(Details.Role);
      SetUserEmail(Details.Username);
    }

    var str_in = {
      Page: page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: search,
      ChildUserID: Details.ChildUserID,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetPhoneBurnerlist",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data?.PageData);
      setRows(result.data?.PageData);
      setrlen(result.data?.TotalCount);
      setflen(result.data?.TotalCount);
      setCountPage(result.data?.PageCount);
      SetIsLoading(false);
    });
  }, [search, search1, page, RowsPerPage, totalentries]);
  const PhoneburnerLoginListGet = () => {
    var Details = GetUserDetails();
    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      setRole(Details.Role);
      SetUserEmail(Details.Username);
    }
    SetIsLoading(true);

    var str_in = {
      Page: page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: search,
      ChildUserID: Details.ChildUserID,
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetPhoneBurnerlist",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data?.PageData);
      setRows(result.data?.PageData);
      setrlen(result.data?.TotalCount);
      setflen(result.data?.TotalCount);
      setCountPage(result.data?.PageCount);
      SetIsLoading(false);
    });
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == page) {
      setPage(NewPage);
    } else {
      setPage(NewPage);
      SetIsLoading(true);
    }
  };
  return (
    <>
      {loading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>ColdCalller</TableCell>
                  <TableCell>Sales Development Reps</TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <Row
                      key={row.name}
                      row={row}
                      SetSearch1={setSearch1}
                      SetPage={setPage}
                      PhoneburnerLoginListGet={PhoneburnerLoginListGet}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No data found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            page={page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              page={page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
