import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar'; 
import Footer from "../../_components/user/footer/footer";
import SmartVariableTabing from '../../_components/user/SmartVariable/SmartVariableTabing';
import Popupone from "reactjs-popup";

class SmartVariablePage extends React.Component {
   
    constructor(props) {
        super(props);
    
        // reset login status
        //    this.props.logout();

        this.state = { 
            popupval : true,
            InvitePopup : false
            //PreviousLang: [],
            //_Lang: 'en',
        };   
        this.openInvitePopup = this.openInvitePopup.bind(this);
        this.closeInvitePopup = this.closeInvitePopup.bind(this);
        this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
    } 

    // componentDidMount() {
    //     document.addEventListener('mousedown', this.closeInvitePopup);
    // } 
    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.closeInvitePopup);
    // }
   
 
 
    openInvitePopup(){
        console.log("--it's true one new"); 
         this.setState({ InvitePopup : true });
         console.log(this.state); 

    } 
    closeInvitePopup(){
        console.log("--it's false new");
        this.setState({ InvitePopup : false });
    }
    
    SubmitInvitePopup() {
        this.setState({ InvitePopup : false });
    } 
    

    render() { 
        return (
            <> 
                <div className="adminmain mheight">
                <Sidebar className=""/>
                <div className="bodyhome">
                    <div className="row">
                        <div className="col padd-0"> 
                            <h4 className="headertitlebd">Smart Variables</h4>
                        </div>
                        <div className="col padright">
                            
                        </div>
                    </div> 

                    <div className="row"> 
                         <div className="col">
                            <SmartVariableTabing />
                        </div> 
                    </div> 
                </div>
                </div>
                 <Footer />
            </>
        );
    }

}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedSmartVariablePage = connect(mapState, actionCreators)(SmartVariablePage);
export { connectedSmartVariablePage as SmartVariablePage };