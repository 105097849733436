import React from "react";
import { connect } from "react-redux";

import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientPotentialMeeting from "../../_components/user/ClientPotentialMeeting/ClientPotentialMeeting";

class ClientPotentialMeetingsPage extends React.Component {
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
              <div className="row">
                <div className="col"> 
                    <ClientPotentialMeeting /> 
                </div>
              </div> 
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientPotentialMeetingsPage = connect(
  mapState,
  actionCreators
)(ClientPotentialMeetingsPage);
export { connectedClientPotentialMeetingsPage as ClientPotentialMeetingsPage };
