import React, { useEffect } from "react";
const moment = require("moment");
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

toast.configure();

import Paper from "@material-ui/core/Paper";
import { TrainRounded } from "@material-ui/icons";

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AccountTypeD1, SetAccountTypeD1] = React.useState([]);
  const [HideInactiveAccount, SetHideInactiveAccount] = React.useState(true);
  const [ExportData, SetExportData] = React.useState([]);
  const [ListDData, SetListDData] = React.useState([]);
  const [HeaderDate, SetHeaderDate] = React.useState([]);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    OpenRateReportingClientGet(Details?.ParentUserID);
    var InputData = {
      UserID: Details?.ParentUserID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/open_rate_reporting/OpenRateReportingClientsExports",
      method: "POST",
      data: InputData,
    }).then((res) => {
      SetExportData(res.data.Data);
    });
  }, [Search, Page, RowsPerPage]);


  // Client open rate account get
  const OpenRateReportingClientGet = (Uid) => {
    SetIsLoading(true)
    var InputParameterData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: Uid,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/open_rate_reporting/OpenRateReportingClientsGet",
      method: "POST",
      data: InputParameterData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetHeaderDate(result.data.HeaderDates);
      SetIsLoading(false)
    });
  };
  // search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };
  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // Export csv
  const ExportCsv = () => {
    toast.success(<div>Data exported successfully</div>);
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameterData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: SearchedVal,
        Type: "User",
        UserID: UserID,
      };

      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/open_rate_reporting/OpenRateReportingClientsGet",
        method: "POST",
        data: InputParameterData,
      });
      rows1.then((result) => {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
      SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameterData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: SearchedVal,
        Type: "User",
        UserID: UserID,
      };

      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/open_rate_reporting/OpenRateReportingClientsGet",
        method: "POST",
        data: InputParameterData,
      });
      rows1.then((result) => {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
      SetIsLoading(false)

      });
    }
  };

  return (
    <>
      <div className="row d-flex align-items-center border-bottom ">
        {
          IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
        }
        
        <div className="col">
          <h4 className="headertitlenop float-left">Clients</h4>
        </div>
        <div className="col">
          <div className="listing-li float-right py-3">
            <ul>
              <li>
                <CSVDownloader
                  className="px-0"
                  data={ExportData}
                  filename={"OpenRateReportingClients"}
                  bom={true}
                >
                  <a
                    onClick={ExportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-25 px-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              class="form-control form-control-sm ml-2"
              placeholder=""
              onKeyPress={RequestSearch}
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Name");
                  }}
                >
                  Clients
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("TrackDomain");
                  }}
                >
                  Open Tracking Domain
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("BCCEmail");
                  }}
                >
                  BBC Email
                </a>
              </TableCell>

              {HeaderDate.map((item) => {
                return (
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("");
                      }}
                    >
                      {item}
                    </a>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row.ClientDetails?.Name}</TableCell>
                  <TableCell>{row.ClientDetails?.TrackDomain}</TableCell>
                  <TableCell>{row.ClientDetails?.BCCEmail}</TableCell>
                  <TableCell>{row?.TrackDay1OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay2OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay3OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay4OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay5OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay6OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay7OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay8OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay9OpenRate}%</TableCell>
                  <TableCell>{row?.TrackDay10OpenRate}%</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
