import React, { useEffect } from "react";
import { CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ ToDate, FromDate }) {
  const [Rows, SetRows] = React.useState([]);
  const [Datas, SetData] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [Search, SetSearch] = React.useState("");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [ParentUserID, SetParentUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(true)

  useEffect(() => {
    var ResearchPageUserInfo = GetUserDetails();
    if (ResearchPageUserInfo != null) {
      SetClientID(ResearchPageUserInfo.ClientID);
      SetUserID(ResearchPageUserInfo.ChildUserID);
      SetParentUserID(ResearchPageUserInfo.ParentUserID);
    }
  }, [ClientID, Role, Search, ToDate, FromDate]);

  useEffect(() => {
    GetResponsesofTime(
      ParentUserID, // Use the state value here
      ClientID // Use the state value here
    );
  }, [ClientID, ParentUserID, ToDate, FromDate]);
  //get insight page in Responses of week
  const GetResponsesofTime = (PUID, CID) => {
    SetIsLoading(true)
    var InputParameter = {
      ToDate: ToDate,
      FromDate: FromDate,
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: PUID,
      Role: Role,
    };
    // console.log(InputParameter)
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/insight/ResponsesOverTimeGet",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetRows(Result.data.PageData);
      SetData(Result.data.PageData);
      const newData = Result.data.PageData.map(item => {
        return {
          Week: item._id, // Replace _id with Week
          Response: item.Response // Keep Response as it is
        };
      });

      SetExportData(newData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

    });
  };

  const ExportBtn = () => {
    toast.success(<div>Data exported successfully.</div>);
  };
  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    if (SortValue == 1) {
      Rows.sort(function (a, b) {
        return parseFloat(a.Response) - parseFloat(b.Response);
      });
      SetSortValue(-1);
      SetRows(Rows);
    SetIsLoading(false)

    } else if (SortValue == -1) {
      Rows.sort(function (a, b) {
        return parseFloat(b.Response) - parseFloat(a.Response);
      });
      SetSortValue(1);
      SetRows(Rows);
      SetIsLoading(false)
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search1").value;
      let lowercaseSearchedVal = SearchedVal.toLowerCase(); // Convert the search value to lowercase
  
      // Filter the original data based on the search and reset to the original data if the search input is empty
      var Result = SearchedVal !== ""
        ? Datas.filter(function (el) {
            // Check if 'Response' and '_id' are defined before converting them to lowercase
            const responseStr = el.Response ? el.Response.toString().toLowerCase() : '';
            const idStr = el._id ? el._id.toString().toLowerCase() : '';
            
            return (
              responseStr === lowercaseSearchedVal ||
              idStr === lowercaseSearchedVal
            );
          })
        : Datas; // Use ExportData to reset to the original data
  
      SetRows(Result);
      SetRlen(Result.length);
      SetFlen(Result.length);
     // SetCountPage(Result.data.PageCount);
    }
  };
  
  

  //chnage page
  const HandleChangePage = (event, newPage) => {
    if (newPage == Page) {
      SetPage(newPage);
    } else {
      SetPage(newPage);
    }
  };

  //change selected
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
  };

  return (
    <div>
       {
        IsLoading ?  <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>:<></>
      }
      <div className="row pl-4 d-flex align-items-center justify-content-center pt-3 pb-1 mb-2">
        <div className="col ">
          <h4 className="headertitlenop float-left pt-2 px-2">
            Responses over time{" "}
          </h4>
        </div>
        <div className="col ">
          <div className="listing-li float-right ">
            <ul className="mt-0">
              <li>
                <CSVDownloader
                  data={ExportData}
                  filename={"ResponseOverTime"}
                  bom={true}
                >
                  <a
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    onClick={ExportBtn}
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="px-4">
        <div className="row border-top pt-4">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:
              <input
                type="search"
                id="search1"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Week</TableCell>
                  <TableCell>
                    {/* <a
                      onClick={() => {
                        SortData("Response");
                      }}
                    > */}
                    Response
                    {/* </a> */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {Rows?.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows.map((row) => (
                    <TableRow key={row._id} row={row}>
                      <TableCell>{row._id}</TableCell>
                      <TableCell>{row.Response}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
          <div class="row">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
