import React from "react";
import { connect } from "react-redux";
import Popupone from "reactjs-popup";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
const EmailTemplete = require("../../../_helpers/email-templete");
const Fileupload = require("../../../_helpers/fileupload");
import { history } from "../../../_helpers";
import CompetitorsTable from "../../../_components/user/Contacts/CompetitorsTable";
import loadingicon from "../../../images/loading.gif";
import Footer from "../../../_components/user/footer/footer";
import ProfileImageUpload from '../../../_components/ProfileUpload';
const ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT



toast.configure();

const imgstyle = { width: "100px", height: "100px" };
class EditAdminUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      Code: "",
      Profile: [],
      UserProfile: [],
      PopUpVal: true,
      InvitePopup: false,
      FinalImg: "",
      FileImg: "",
      ImgFlag: false,
      Email: null,
      AdminUserName: null,
      LastName: null,
      Password: null,
      AdminUserUserID: null,
      CountryList: [],
      Message: "",
      CountryID: null,
      FinalImg: "",
      ButtonDisabled: false,
      FormData: {
        Email: "",
        Password: "",
        FirstName: "",
        LastName: "",
        PhoneNo: "",
        ZipCode: "",
        CountryID: "",
      },
      errors: {},
      IsLoading: true,
    };
    this.ResetPassword = this.ResetPassword.bind(this);
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
    this.SubmitInvitePopUp = this.SubmitInvitePopUp.bind(this);
    this.BackButton = this.BackButton.bind(this);
    this.CountryGet();
  }
  componentDidMount() {
    document.title = "Edit Admins | SalesHive";
    const Code = this.props.location.state;
    this.setState({ Code: Code });
    let GetAdminDetails = {
      id: Code,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/AdminUser/GetAdminById",
      method: "POST",
      data: GetAdminDetails,
    }).then((ResAdmin) => {
      console.log(ENCRYPTION_SALT)
      console.log(CryptoJS.AES.decrypt(
        (ResAdmin.data.Data?.Password).split(" ").join("+"),
        ENCRYPTION_SALT
      ).toString(CryptoJS.enc.Utf8))
      let Pass = JSON.parse(
        CryptoJS.AES.decrypt(
          (ResAdmin.data.Data?.Password).split(" ").join("+"),
          ENCRYPTION_SALT
        ).toString(CryptoJS.enc.Utf8)
      );
      console.log(Pass)

      this.setState({ Profile: ResAdmin.data.Data });
      this.setState({ AdminUserUserID: ResAdmin.data.Data.UserID });
      this.setState({ ProfileImage: ResAdmin.data.Data.ProfileImage });
      this.setState({ FinalImg: ResAdmin.data.Data.ProfileImage });
      let Form = {
        Email: ResAdmin.data.Data.Email,
        Password: Pass,
        FirstName: ResAdmin.data.Data?.Firstname,
        LastName: ResAdmin.data.Data?.Lastname,
        PhoneNo: ResAdmin.data.Data?.PhoneNo,
        ZipCode: ResAdmin.data.Data?.ZIPCODE,
        CountryID: ResAdmin.data.Data?.CountryID,
        IsTwoStepVerification: ResAdmin.data.Data?.IsTwoStepVerification,
      };
      this.setState({ FormData: Form, IsLoading: false });
    });
  }
 
  //Get country Drop down
  CountryGet() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/AdminUser/CountryGet",
      method: "POST",
      // data: InputParamters,
    }).then((res) => {
      this.setState({ CountryList: res.data.Data });
    });
  }

  OpenInvitePopup() {
    this.setState({ InvitePopup: true });
  }
  CloseInvitePopup() {
    this.setState({ InvitePopup: false });
    this.setState({ Message: "" });
  }

  async CheckUpdateEmail(Email) {
    let Emails = document.getElementById("change_mail").value;
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var EmailFlg = re.test(Emails);
    if (EmailFlg) {
      var InputParameters = {
        ParentUserID: "",
        // UserID: this.state.UserID,
        Email: Emails,
      };
      let resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/UpdateEmailValidation",
        method: "POST",
        data: InputParameters,
      });

      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Flag) {
          this.setState({ Message: resdata.data.Message });
          return false;
        } else {
          this.setState({ Message: "" });
          return true;
        }
      }
    } else {
      this.setState({ Message: "Invalid mail." });
      return false;
    }
  }

  // Whole form validation
  async FormValidation() {
    let formIsValid = true;
    let Email = document.getElementById("change_mail").value;
    let IsExists = await this.CheckUpdateEmail(Email);

    if (IsExists) {
      formIsValid = true;
    } else {
      formIsValid = false;
    }
    if (Email.trim().length <= 0) {
      formIsValid = false;
      this.setState({ Message: "Please enter email." });
    }

    return formIsValid;
  }

  //Send Update Email mail
  async SubmitInvitePopUp(e) {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      var SendEmail = document.getElementById("change_mail").value;
      var Tmp_Token =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      var Email_Token = CryptoJS.AES.encrypt(
        JSON.stringify(Tmp_Token),
        ENCRYPTION_SALT
      ).toString();

      var InputParamters = {
        uID: this.state.AdminUserUserID,
        Email_Token: Email_Token,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/changeemail",
        method: "POST",
        data: InputParamters,
      }).then(async (res) => {
        if (res.data != "") {
          var Username = SendEmail;
          var email = CryptoJS.AES.encrypt(
            JSON.stringify(SendEmail),
            ENCRYPTION_SALT
          ).toString();
          var emailData = EmailTemplete.userEmailChange(
            Email_Token,
            email,
            CommonConstants.change_Email_admin
          );

          try {
            // Use await to wait for the SendEmail function to complete
            let IsMailSend = await this.SendEmail(
              Username,
              emailData,
              "Please Confirm Your Email"
            );
            if (IsMailSend) {
              toast.success(
                <div className="toastsize">
                  Admin
                  <br />
                  Mail sent successfully
                </div>
              );
            } else {
              toast.error(
                <div className="toastsize">
                  Admin Edit
                  <br />
                  Something went wrong while sending the update email.
                </div>
              );
            }
          } catch (error) {
            console.error("Error sending email:", error);
            toast.error(
              <div className="toastsize">
                Admin Edit
                <br />
                Error sending the update email.
              </div>
            );
          }
        }
      });
      this.setState({ InvitePopup: false });
    } else {
      document.getElementById("m_emailInvite_submit").disabled = false;
    }
  }

  // Send Reset Password email
  ResetPassword() {
    var tmp_token =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    var forgot_token = CryptoJS.AES.encrypt(
      JSON.stringify(tmp_token),
      ENCRYPTION_SALT
    ).toString();

   
    var InputParameters = {
      UserName: this.state.FormData.Email,
      Forgot_Token: forgot_token,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/login/forgotpassword",
      method: "POST",
      data: InputParameters,
    }).then(async (resdata) => {
      if (resdata.data.data?.length > 0) {
        var Username = resdata.data.data[0]?.Username;
        var emailData = EmailTemplete.userForgotpassword(
          forgot_token,
          Username
        );
        try {
          // Use await to wait for the SendEmail function to complete
          let IsMailSend = await this.SendEmail(
            Username,
            emailData,
            "Reset Password"
          );
          if (IsMailSend) {
            toast.success(
              <div className="toastsize">
                Admin Edit
                <br />
                Thank you, Please check your email for password reset link.
              </div>
            );
          } else {
            toast.error(
              <div className="toastsize">
                Admin Edit
                <br />
                Something went wrong while sending the password reset link
              </div>
            );
          }
        } catch (error) {
          console.error("Error sending email:", error);
          toast.error(
            <div className="toastsize">
              Admin Edit
              <br />
              Error sending the password reset link
            </div>
          );
        }
      }
    });
  }

  // Back to Listing Page
  BackButton() {
    history.push("/adminuser");
  }

  //Email Send method
  SendEmail = (Username, EmailTemplate, Subject) => {
    return new Promise((resolve, reject) => {
      const InputParameters = {
        email: Username,
        html: EmailTemplate,
        subject: Subject,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/emailsend",
        method: "POST",
        data: InputParameters,
      })
        .then((res) => {
          if (res.data.StatusMessage === "SUCCESS") {
            resolve(true); // Resolve the promise with true
          } else {
            resolve(false); // Resolve the promise with false
          }
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          reject(error); // Reject the promise in case of an error
        });
    });
  };

  // Function to validate individual form field
  ValidateField(fieldName, value) {
    // Define your validation rules here
    const newErrors = { ...this.state.errors };
    switch (fieldName) {
      case "FirstName":
        // Check if the FirstName field is empty
        newErrors.FirstName =
          value.trim() === "" ? "Please enter First Name" : "";
        break;
      case "ZipCode":
        value = value.trim();
        if (value !== "") {
          this.CheckZipCode(value);
        }
        break;
      default:
        break;
    }

    this.setState({ errors: newErrors });
  }

  //Update OnChange Handler
  HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };

      if (type === "checkbox") {
        // For the checkbox, store the boolean value directly
        updatedFormData[name] = checked;
      } else {
        // For other fields, store their values
        updatedFormData[name] = value;
      }

      return {
        FormData: updatedFormData,
      };
    });
  };

  //Update OnBlur Handler
  HandleBlur = (e) => {
    const { name, value } = e.target;

    this.ValidateField(name, value);
  };

  //Update User Profile Data
  HandleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ ButtonDisabled: true });
    let Image = await this.childRef.current.SubmitData();
    let ImageName = Image.FileName;
    this.setState({ FinalImg: ImageName, ButtonDisabled: true });
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        coverimg: ImageName,
        AdminUserName: prevState.FormData.FirstName,
        ID: this.state.Code,
      },
    }));
    const { FormData } = this.state;
    const fieldNames = Object.keys(FormData);
    let isValid = true;

    fieldNames.forEach((fieldName) => {
      this.ValidateField(fieldName, FormData[fieldName]);
      if (this.state.errors[fieldName]) {
        isValid = false;
      }
    });

    if (isValid) {
      Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/ProfileDataUpdate",
        method: "POST",
        data: FormData,
      }).then((res) => {
        toast.success(
          <div className="toastsize">
            Edit admin
            <br />
            Admins updated successfully.
          </div>
        );
        if (res) {
          //register_u.style.display = "block";
          history.push("/adminuser");
          this.setState({ ButtonDisabled: false });
        }
      });

      // You can submit the form data to your server or perform other actions here
    } else {
      this.setState({ ButtonDisabled: false });
    }
  };

  //Check Zip Code Valid or Not
  async CheckZipCode(ZipCode) {
    try {
      const API_KEY = process.env.REACT_APP_ZIPCODE_KEY; // Replace with your actual API key
      const BASE_URL = process.env.REACT_APP_ZIPCODE_BASE_URL;
      const url = `${BASE_URL}?components=postal_code:${ZipCode}&key=${API_KEY}`;

      let res = await Axios({
        url: url,
        method: "GET",
      });

      let errors = { ...this.state.errors };

      if (res.data.status === "ZERO_RESULTS") {
        errors.ZipCode = "Invalid Zipcode";
      } else {
        errors.ZipCode = "";
      }

      this.setState({ errors });
    } catch (error) {
      console.error("Error during API request:", error);
      // Handle API request error, e.g., set an error message in state
    }
  }

  render() {
    const { FormData, errors } = this.state;
    return (
      <>
        {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}

        <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Email</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      name="Email"
                      placeholder="Email"
                      id="change_mail"
                      type="text"
                      onBlur={this.CheckUpdateEmail.bind(this, "Email")}
                    />
                    <span style={{ color: "red" }}>{this.state.Message}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={this.SubmitInvitePopUp}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </a>
              </div>
            </div>
          </div>
        </Popupone>
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left mb-0">Edit Admin User</h4>
              </div>
            </div>

            <div className="paddcols">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        placeholder="Email"
                        type="text"
                        value={FormData.Email}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <a
                    id="lnkUpdateEmail"
                    href="javascript:void(0);"
                    onClick={this.OpenInvitePopup}
                    className="btn btn-primary btngroup btnlightgroup"
                  >
                    Update Email
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Password</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="Password"
                        name="Password"
                        placeholder="Password"
                        type="Password"
                        value={FormData.Password}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <button
                    id="lnkUpdateEmail"
                    href="javascript:void(0);"
                    onClick={this.ResetPassword}
                    className="btn btn-primary btngroup btnlightgroup"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">First Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="FirstName"
                        name="FirstName"
                        placeholder="First name"
                        type="text"
                        value={FormData.FirstName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.FirstName && (
                        <div className="errorinput">{errors.FirstName}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Last Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        placeholder="Last name"
                        type="text"
                        value={FormData.LastName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Phone No</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        placeholder="Phone No"
                        type="text"
                        id="PhoneNo"
                        name="PhoneNo"
                        value={FormData.PhoneNo}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">ZipCode</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        placeholder="ZipCode"
                        type="text"
                        id="ZipCode"
                        name="ZipCode"
                        value={FormData.ZipCode}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.ZipCode && (
                        <div className="errorinput">{errors.ZipCode}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Country</label>
                    <div className="col-lg-8">
                      <select
                        aria-controls="tbl_meeting"
                        className="form-control form-control-sm"
                        id="CountryID"
                        name="CountryID"
                        value={FormData.CountryID}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      >
                        <option value="0">--Select--</option>
                        {this.state.CountryList.map((value) => (
                          <option key={value.CountryID} value={value.CountryID}>
                            {value.CountryName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <ProfileImageUpload
                profileImage={this.state.FinalImg}
                ref={this.childRef}
              />

              <div className="row pt-2">
                <div className="col-lg-6 boxsinput_group d-flex">
                  <div className="toggleswich mr-2">
                    <input
                      type="checkbox"
                      className="checktogle"
                      id="IsTwoStepVerification"
                      name="IsTwoStepVerification"
                      onChange={this.HandleInputChange}
                      checked={FormData.IsTwoStepVerification}
                    />
                    <b className="switch">
                      <span className="checkion"></span>
                      <span className="uncheckion"></span>
                    </b>
                    <b className="track"></b>
                  </div>
                  <label>Is Two Step Verification</label>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.HandleSubmit}
                  disabled={this.state.ButtonDisabled}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackButton}
                  className="btn btn-secondary"
                >
                  <i className="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditColdCallersPage = connect(
  mapState,
  actionCreators
)(EditAdminUserPage);
export { connectedEditColdCallersPage as EditAdminUserPage };
