import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../../_constants/common.constants";
import { GetUserDetails } from "../../../../_helpers/Utility";
import { history } from "../../../../_helpers";
import loadingicon from "../../../../images/loading.gif";

export default function ContactReasonUnqualified() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("OrderBy");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [DeleteDataDropdown,SetDeleteDataDropdown] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0);

  useEffect(() => {
    var Details = GetUserDetails();
    document.title = 'Contact Reason Unqualified | SalesHive';

    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    ContactReasonUnquailifiedListGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role
    );
  }, [Search, Page, RowsPerPage]);

  // contact reason unqualified get
  const ContactReasonUnquailifiedListGet = (CID, UID, URole) => {
    SetIsLoading(true)
    var ContactReasonUnqualifiedData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contactreasonunqualified/ContactReasonUnqualifiedGet",
      method: "POST",
      data: ContactReasonUnqualifiedData,
    });
    rows1.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetDeleteDataDropdown(result.data.DeleteDropDownData);
      SetIsLoading(false)
    });
  };

  // changeRow selected
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactreasonunqualified/ContactReasonUnqualifiedGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactreasonunqualified/ContactReasonUnqualifiedGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    }
  };

  const DeleteBtn = async (id, CSName) => {
     let Datas = DeleteDataDropdown.filter(obj => obj._id !== id);
    var ContactReasonUnqualifiedData = [];
    Datas.map((source) => {
      ContactReasonUnqualifiedData[source._id] = `${source.Reason} `;
    });

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact reason unqualified.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!", 
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Replace Account Reason Unqualified",
          text: "Which value replace with '" + CSName + "' contact reason unqualified?",
          confirmButtonColor: "#34bfa3",
          cancelButtonColor: "#34bfa3",
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          reverseButtons: true,
          showCancelButton: true,
          input: "select",
          inputOptions: ContactReasonUnqualifiedData,
          inputPlaceholder: ContactReasonUnqualifiedData[0],
          position: 'top',
          showCloseButton: true,
          customClass: { 
            popup: 'replaceaccountpopup', 
            confirmButton: 'btn btn-primary btnColor',
          }
        }).then((Succes) => {
          if (Succes.isConfirmed) {
            var UpdateId = Succes.value;
            var UpdatingParameters = {
              _id: id,
              NewID: UpdateId,
              LastUpdatedBy: CUserID,
              LastUpdatedDate: new Date(),
              Role: Role
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL +"/contactreasonunqualified/ContactReasonUnqualifiedDelete",
              method: "POST",
              data: UpdatingParameters,
            }).then(async (res) => {
              
              if (res) {
                if ((res.statusText = "Ok")) {
                  if (res.data.StatusMessage == "SUCCESS") {
                    Swal.fire(
                      "Deleted!",
                      "Contact reason unqualified deleted successfully.",
                      "success"
                    );
                      ContactReasonUnquailifiedListGet(ClientID, UserID, Role);
                    history.push("/contactreasonunqualified");
                    SetPage(1);
                    SetSearch("");
                    document.getElementById("search").value = "";
                  }
                }
              }
            });
          } else if (Succes.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  // Edit page
  const EditBtn = (id) => {
    
    history.push({
      pathname: "/editcontactreasonunqualified",
      state: { data: id },
    });
  };

  return (
    <div>
      {
        IsLoading === true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Reason");
                    }}>
                    {" "}
                    Name
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell style={{  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}}>{row.Reason}</TableCell>
                    <TableCell>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        title="Delete"
                        onClick={() => {
                          DeleteBtn(row._id,row.Reason);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
