import React,{useEffect} from 'react';
import { CSVReader, CSVDownloader } from "react-papaparse";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { toast } from "react-toastify";
const moment = require('moment');
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loadingicon from "../../../images/loading.gif";
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable({ToDate,FromDate}) {
  // export default function AccountAllTable() {
    const [Flen, SetFlen] = React.useState(0);
    const [Sflag, SetSflag] = React.useState(false);
    const [Rlen, SetRlen] = React.useState(0);
    const [CountPage, SetCountPage] = React.useState(0);
    const [Rows, SetRows] = React.useState([]);
    const [SalesRepList, SetSalesRepList] = React.useState([]);
    const [ExportData, SetExportData] = React.useState([]);
    const [Page, SetPage] = React.useState(1);
    const [RowsPerPage, SetRowsPerPage] = React.useState(15);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("CreatedDt");
    const [SortValue, SetSortValue] = React.useState(1);
    const [ClientID, SetClientID] = React.useState(0);
    const [UserID, SetUserID] = React.useState(0);
    const [UserIDby, SetUserIDby] = React.useState(0);
    const [Role, SetRole] = React.useState("");
    const [ColdCallersID, SetColdCallersID] = React.useState("");
    const [FromDates,SetFromDates]=React.useState("");
    const [ToDates,SetToDates]=React.useState("");
    const [Open, SetOpen] = React.useState(false);
    const [IsLoading,SetIsLoading]= React.useState(true);
    const [ClientName,SetClientName]=React.useState("");
    
  
  
    useEffect(() => {
      document.title = "Call History | SalesHive"
      const Details = GetUserDetails();
      if (Details != null) {
        SetClientID(Details.ClientID);
        SetUserID(Details.ParentUserID);
        SetRole(Details.Role);
        GetColdCallingHistory(Details.ClientID, Details.ParentUserID, Details.Role, FromDate, ToDate);
      GetSalesRep(Details.ParentUserID);
  
      }
    }, [FromDate, ToDate, SortValue, SortField, Search, RowsPerPage, Page, ColdCallersID]);
  
  // Get SalesRep
  const GetSalesRep = (UID) => {
    const InputParameter = {
      UserID: UID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_history/SalesDevelopmentRepsGet",
      method: "POST",
      data: InputParameter
    }).then((Res) => {
      if (Res.data.StatusMessage = "SUCCESS") {
        SetSalesRepList(Res.data.Data);
      }
    });
  }
    //get Cold calling recording  list
    const GetColdCallingHistory = (CID, UID, URole, FromDate, ToDate) => {
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortValue,
        Search: Search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        Role: URole,
        ToDate:ToDates,
        FromDate:FromDates,
        ColdCallersID:ColdCallersID
      };
      
      const Row = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_calling_history/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      Row.then((Result) => {
        
        if (Result.data.StatusMessage = "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        }else{
          SetIsLoading(false)
        }
      });
    };
  
    //Export CSV Data 
    const ExportCsvData=(FromDate,ToDate,CID,role)=>{
      var InputParameter = {
        ClientID: CID,
        ToDate:ToDate,
        FromDate:FromDate,
        Role:role
      };
      // 
      const Row = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_calling_history/CallHistoryCSV",
        method: "POST",
        data: InputParameter,
      });
      Row.then((Result) => {
        if (Result.data.StatusMessage = "SUCCESS") {
        
          var header=Result.data.Header
          var CsvData=Result.data.Data;
          const csvContent = "data:text/csv;charset=utf-8," + header +"\n" + CsvData.map(item => Object.values(item).join(',')).join('\n');
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", Result.data.ClientName.Name +".csv");
          document.body.appendChild(link);
          link.click();
        }
      });
    }
    //Export CSV
    const ExportCsv=()=>{}

   //get sort field data
   const SortData = (Field) => {
    SetSflag(false);
    var Sort = SortValue;
    if (Sort == 1) {
      Sort = -1;
      SetSortValue(-1);
    } else {
      Sort = 1;
      SetSortValue(1);
    }
    SetSortField(Field);
  };
  
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      
    }
  };
  
   //change Page
   const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  
  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  
  //Search Box 
  const SearchData =()=>{
    let ColdCallersID = document.getElementById('ColdCallersID').value;
    SetColdCallersID(ColdCallersID);
   
  }
  
  //Reset ResetFilter 
  const ResetFilter=()=>{
    SetColdCallersID(null);
    document.getElementById('ColdCallersID').value = '';
  }
  
  return (
    <>
    {
      IsLoading === true ?
      <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> :<></>
    }
    <div className="row my-2 borderbtnm pb-4 mb-3"> 
                        <div class="col col-lg-5 d-flex"> 
                            <label class="col-lg-5 mt-2 mr-2 nowrap pl-1"> Sales Development Reps</label>
                            <select class="col-lg-7 form-control  m-input" data-val="true" data-val-number="The field ColdCallersID must be a number." 
                            id="ColdCallersID" name="ColdCallersID">
                              <option value="">--Select--</option>
                               {
                                SalesRepList.map((row)=>(
                                <option value={row._id}> {row.ColdCallersName}</option>

                                ))}
                            </select> 
                        </div>
                        <div className="col col-lg-3">
                            <a href="javascript:void(0);" class="btn btn-primary btnColor" onClick={SearchData}><i class="la la-search"></i>Search</a>
                            <a href="javascript:void(0);" class="ml-3 btn btn-secondary" onClick={ResetFilter}><i class="la la-refresh"></i> Reset</a>
                        </div>
                        <div className="col col-lg-4">
                            <div className="listing-li float-right">
                                 <ul className='mt-0'>
                                    <li>
                                    
                                    <a  onClick={ExportCsv} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-download"></i>
                                        {/* <span>
                                        <CSVDownloader className='px-0'
                                            data={ExportData}
                                            filename={`${ClientName}-Cold_call-History`}
                                            bom={true}
                                        >Export</CSVDownloader></span> */}
                                    </a>
                                    </li> 
                                </ul>
                            </div>
                        </div>
                    </div>  
        <div className="row">
      <div className="col-sm-12 col-md-6">
        <label className="textlabelte">
          Show
          <select
            name="tbl_meeting_length"
            onChange={ChangeRowSelected}
            aria-controls="tbl_meeting"
            className="form-control form-control-sm"
          >
            {CommonConstants.show_rows.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
          entries
        </label>
      </div>
      <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">
          Search:{" "}
          <input
            type="search"
            id="search"
            onKeyPress={RequestSearch}
            className="form-control form-control-sm ml-2"
            placeholder=""
            aria-controls="tbl_meeting"
          />
        </label>
      </div>
    </div>
    <div className="table-bordered">
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
      <TableHead>
              <TableRow>
                <TableCell><a onClick={() => { SortData("Name"); }}>Call Notes</a></TableCell>
                <TableCell><a onClick={() => { SortData("Status"); }}>Result</a></TableCell>
                <TableCell><a onClick={() => { SortData("FirstName"); }}>First Name</a></TableCell>
                <TableCell><a onClick={() => { SortData("LastName"); }}>Last Name</a></TableCell>
                <TableCell><a onClick={() => { SortData("Title"); }}>Title</a></TableCell>
                <TableCell><a onClick={() => { SortData("Company"); }}>Company</a></TableCell>
                <TableCell><a onClick={() => { SortData("Email"); }}>Email</a></TableCell>
                <TableCell><a onClick={() => { SortData("DirectPhone"); }}>Direct Phone</a></TableCell>
                <TableCell><a onClick={() => { SortData("CompanyPhone"); }}>Company Phone</a></TableCell>
                <TableCell><a onClick={() => { SortData("Mobile"); }}>Mobile Phone</a></TableCell>
                <TableCell><a onClick={() => { SortData("ColdCallersName"); }}>Sales Development Reps</a></TableCell>
                <TableCell><a onClick={() => { SortData("PSTCreatedDate"); }}>Call Date</a></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows.map((row) => (
                <>
                  <TableRow key={row.name} row={row} >
                    <TableCell><IconButton aria-label="expand row" size="small" onClick={() => SetOpen((prev) => ({ ...prev, [row._id]: !prev[row._id], }))}>
                      {Open[row._id] ? (<i className="fa fa-minus-circle plusbl" aria-hidden="true"></i>) : (<i className="fa fa-plus-circle minusl" aria-hidden="true"></i>)}</IconButton>
                    </TableCell>

                    <TableCell>{row.Status}</TableCell>
                    <TableCell>{row.FirstName}</TableCell>
                    <TableCell>{row.LastName}</TableCell>
                    <TableCell>{row.Title}</TableCell>
                    <TableCell>{row.Company}</TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.DirectPhone}</TableCell>
                    <TableCell>{row.CompanyPhone}</TableCell>
                    <TableCell>{row.Mobile}</TableCell>
                    <TableCell>{row.ColdCallersName}</TableCell>
                    <TableCell>{row.PSTCreatedDate}</TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={15}
                    >
                      <Collapse
                        in={Open[row._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {Open[row._id] && (
                          <Box margin={1} className="innertables">
                            {!row.CallNotes ? "No notes available" : row.CallNotes}
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              )))}

            </TableBody>
      </Table>
    </TableContainer>
    </div>
    {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
