import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { toast } from "react-toastify";
import { CSVDownloader } from "react-papaparse";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../../user/Kpis/LastdaysTabing";

import DespammerTable from "../../../_components/clients/despammer/DespammerTable";
import KeywordsTable from "../../../_components/clients/despammer/KeywordsTable";

import ImageUploading from "react-images-uploading";
import Popup from "reactjs-popup";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [AddUpdate, SetAddUpdate] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [BtnDisabled,SetBtnDisabled]  = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  //const maxNumber = 69;
  useEffect(() => {
    document.title = 'Despammer | SalesHive'
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    Exportcsv(Details.ClientID, Details.ParentUserID, Details.Role);
  }, []);

  //Export Data
  const Exportcsv = (cID, uID, Roles) => {
    var InputParameter = {
      Type: "User",
      ClientID: cID,
      UserID: uID,
      Role: Roles,
    };
    const KeywordList = Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/ExportDespammer",
      method: "POST",
      data: InputParameter,
    });
    KeywordList.then((Result) => {
      SetExportData(Result.data.Data);
      SetClientName(Result.data.ClientName.Name);
    });
  };

  //Reload page while delete perform
  const UpdateFromChild = (value) => {
    if (value == true) {
      Exportcsv(ClientID, UserID, Role);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //Export CSV
  const ExportCsv = () => {};

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    
    setImages(imageList);
  };
  const openTermsConditionPopup = () => {
    //
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });
  //const openTermsConditionPopup = false;
  // Show div add
  const ShowKeywordsec = () => {
    document.getElementById("Keyword_add_sec").style.display = "block";
    document.getElementById("Keyword_val").style.display = "none";
    SetBtnDisabled(false);
  };

  //  hide div add
  const HideKeywordsec = () => {
    document.getElementById("Keyword_add_sec").style.display = "none";
    document.getElementById("Keyword").value = "";
  };

  // save keywords
  const KeywordAdd = (e) => {
    SetBtnDisabled(true)
    e.preventDefault();
    var Keyword = document.getElementById("Keyword").value;
    if (Keyword == "") {
      document.getElementById("Keyword_val").style.display = "block";
    SetBtnDisabled(false)

    } else {
      var InputParaMeter = {
        Keyword: Keyword,
        CreatedDate: new Date(),
        CreatedBy: CUserID,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/despammer/DespammerAdd",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          document.getElementById("Keyword").value = "";
          SetBtnDisabled(false)

          SetAddUpdate(true);
          toast.success(
            <div className="toastsize">
              Keyword
              <br />
              Keyword added successfully.
            </div>
          );
          SetAddUpdate(false);
          // history.push("/despammer");

          document.getElementById("Keyword_add_sec").style.display = "none";
        } else if (res.data.StatusMessage == "ERROR") {
          toast.error(<div className="toastsize">{res.data.Message}</div>);
          SetBtnDisabled(false)

        } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false)

        }
      });
    }
  };

  return (
    <div className="tabdes">
      <Popup open={popupval} modal>
        <div className="modal-black"></div>
        <div className="filterPopup">
          <div className="paddingboxTerms">
            <div className="modal-header">
              <h5>Filter</h5>
              <a className="close-ion" onClick={closeTermsConditionModal}>
                <span aria-hidden="true" class="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div className="row">
                <div className="col-xl-6 allcentertext">
                  <i class="la la-filter iconsfonts"></i>
                  <a
                    id="lnkAddNewFilter"
                    href="javascript:void(0);"
                    class="btn btn-md btn-primary btnColor"
                  >
                    Add New Filter
                  </a>
                </div>
                <div className="col-xl-6 allcentertext">
                  <i class="la la-filter iconsfonts"></i>
                  <a
                    id="lnkAddNewFilter"
                    href="javascript:void(0);"
                    class="btn btn-md btn-primary btnColor"
                  >
                    Select from existing Filter
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="modal-footer">
              <a className="btn" type="submit" onClick={SubmitTermsConditionModal}>Learnedbtn</a>
            </div> */}
        </div>
      </Popup>

      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Despammer" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Keywords" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone inpt-0" index={0}>
          <div className="row">
            <div className="col px-0 mx-n1">
                <DespammerTable />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone whitebg" index={1}>
          <div className="row px-3">
            <div className="col border-bottom pl-0">
              <h4 className="headertitle float-left pl-0">Keywords</h4>
            </div>
            <div className="col border-bottom  pr-0">
              <div className="listing-li float-right padb-15">
                <ul>
                  <li>
                    <a
                      onClick={ShowKeywordsec}
                      className="btn btngroup m-btn "
                    >
                      <i class="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <CSVDownloader
                      data={ExportData}
                      filename={`${ClientName}-DespammerKeywords`}
                      bom={true}
                    >
                      <a
                        onClick={ExportCsv}
                        className="btn btngroup m-btn "
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>Export</span>
                      </a>
                    </CSVDownloader>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="row my-5"
            id="Keyword_add_sec"
            style={{ display: "none" }}
          >
            <div className="col-lg-12 row">
              <label className="col-lg-4 view-lable-meet">Keyword</label>
              <div className="col-lg-12">
                <input
                  className="form-control m-input"
                  data-val="true"
                  data-val-required="Keyword"
                  id="Keyword"
                  name="Keyword"
                  placeholder="Keyword"
                  type="text"
                />
                <span
                  id="Keyword_val"
                  style={{ display: "none", color: "red" }}
                >
                  Please enter to keyword.
                </span>
              </div>
            </div>
            <div className="col-lg-12 mt-3 px-4 full-right">
              <button
                className="btn btn-primary btn-lightgreen mr-1"
                disabled={BtnDisabled}
                onClick={KeywordAdd}
              >
                <i className="la la-save"></i> Save
              </button>
              <a className="btn btn-secondary" onClick={HideKeywordsec}>
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="table-bordered">
                <KeywordsTable
                  updateFromChild={UpdateFromChild}
                  AddUpdate={AddUpdate}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
