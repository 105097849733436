import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Open, SetOpen] = React.useState(false);
  const [Notes, SetNotes] = React.useState([]);
  const [ResponderData, SetResponderData] = React.useState([]);
  const [ResponderID, SetResponderID] = React.useState("");
  const [Status, SetStatus] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);

  const classes = useRowStyles();

  useEffect(() => {
    document.title = `All LinkedIn Follow Ups | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
   // ResponderDropDown(Details.ParentUserID);
    ClientLinkedInFollowUpGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role
    );
  }, [Search, Page, RowsPerPage, ResponderID]);

  useEffect(() => {
    var Details = GetUserDetails();
    ResponderDropDown(Details.ParentUserID);
  }, []);

  const ClientLinkedInFollowUpGet = (CID, UID, URole) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ResponderID: ResponderID,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/client_linkedin_followup/ClientLinkedInFollowUpGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    });
  };

  // Reponder Drop down list
  const ResponderDropDown = (UId) => {
    var InputParameters = {
      UserID: UId,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/client_reschedules/ClientResponderGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetResponderData(Result.data.Data);
      SetStatus(Result.data.StatusDropDown);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        Search: SearchedVal,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ResponderID: ResponderID,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/client_linkedin_followup/ClientLinkedInFollowUpGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetStatus(Result.data.StatusDropDown);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ResponderID: ResponderID,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/client_linkedin_followup/ClientLinkedInFollowUpGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetStatus(Result.data.StatusDropDown);
        SetIsLoading(false);
      });
    }
  };

  //render on view Page
  const ViewPage = (id) => {
    history.push({
      pathname: "/viewpotentialmeeting",
      state: { data: id, Pagename: "clientLinkedin" },
    });
  };

  //update potential status dropdown
  const PotentialStatusSelect = (event, id) => {
    try {
      var data = {
        _id: id,
        EngagamentStatusID: event.target.value,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        Role: Role,
        LastStatusChangeDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/UpdatePotentialMeetingdropdown",
        method: "POST",
        data: data,
      })
        .then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div>
                Potential Meeting <br />
                Engagement status successfully changed.
              </div>
            );
            //props.updateFromChild(true);
            ClientLinkedInFollowUpGet(ClientID, UserID, Role);
          } else {
            toast.error(res.data.Message);
          }
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a linked in followup.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/client_linkedin_followup/ClientLinkedInFollowUpDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Linked in followup deleted successfully.",
                "success"
              );
              //  document.getElementById("hideloding").style.display = "block";
              ClientLinkedInFollowUpGet(ClientID, UserID, Role);
              history.push("/clientlinkedinfollowUp");
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Get Notes
  const GetNotes = (ID) => {
    var InputParameter = {
      ID: ID,
    };
    const AccountCategoriesList = Axios({
      url: CommonConstants.MOL_APIURL + "/client_linkedin_followup/NotesGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetNotes(Result.data.Data);
    });
  };

  //Get ResponderID
  const GetResponderID = () => {
    let Responder = document.getElementById("ResponderID").value;
    SetResponderID(Responder);
  };

  // meetings add
  const MeetingsConvertAdd = (ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to convert linkedin follow up in to meeting?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, convert it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var str_in = {
          id: ids,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialMeetingByID",
          method: "POST",
          data: str_in,
        }).then((res) => {
          history.push({
            pathname: "/addbookedmeeting",
            state: {
              Pagename: "Potential",
              data: res.data[0],
              BackPage: "/bookedmeetings",
            },
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const EditAccountHandle = (id) => {
    if (id !== undefined && id !== null) {
    history.push({
      pathname: "/viewaccount",
      state: { data: id, Pagename: "ClientLinkedinFollow" },
    });
  }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null) {
    history.push({
      pathname: "/viewcontacts",
      state: { data: id, EmailData: Email, Pagename: "ClientLinkedinFollow" },
    });
  }
  };

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row pt-3 pb-4 border-bottom mb-4">
        <div class="col-lg-2 view-lable-meet">
          <label>Responder</label>
        </div>
        <div class="col-lg-3">
          <select
            class="form-control  m-input"
            id="ResponderID"
            name="ResponderID"
            autocomplete="off"
            onChange={GetResponderID}
          >
            <option value="">--Select--</option>
            {ResponderData.map((value) => (
              <option value={value._id}>{value.Name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Convert</TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >
                    Client Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("EngagamentStatus");
                    }}
                  >
                    Engagement Status
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Company");
                    }}
                  >
                    Company
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountName");
                    }}
                  >
                    Account Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ContactName");
                    }}
                  >
                    Contact Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Title");
                    }}
                  >
                    Title
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("LinkedInUrl");
                    }}
                  >
                    LinkedIn Url
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MeetingSourceName");
                    }}
                  >
                    Meeting Source Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MeetingOwnerName");
                    }}
                  >
                    Owner Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ReminderEmailAccount");
                    }}
                  >
                    Reminder Email Account
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("DirectPhone");
                    }}
                  >
                    Direct Phone
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompanyPhone");
                    }}
                  >
                    Company Phone
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ResponderName");
                    }}
                  >
                    Responder
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CreatedDate");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row.name} row={row}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            SetOpen((prev) => ({
                              //...prev,
                              [row._id]: !prev[row._id],
                            }));
                            GetNotes(row._id);
                          }}
                        >
                          {Open[row._id] ? (
                            <i
                              className="fa fa-minus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            ViewPage(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                      <TableCell scope="row">
                        <a
                          onClick={() => {
                            MeetingsConvertAdd(row._id);
                          }}
                          className="btn-exchangeicon"
                        >
                          <i className="la la-exchange"></i>
                        </a>
                      </TableCell>
                      <TableCell>{row?.ClientName}</TableCell>
                      {/* <TableCell>{row.EngagamentStatus}</TableCell> */}
                      <TableCell className="labeltds" scope="row">
                        <select
                          onChange={(e) => {
                            PotentialStatusSelect(e, row?._id);
                          }}
                          className="form-control m-input"
                          id="Potentialstatus"
                          name="Potentialstatus"
                          autoComplete="off"
                          value={row.EngagamentStatusID}
                        >
                          {/* <option value="">--Select--</option> */}
                          {row?.PMStatus?.map((value) => (
                            <option value={value?._id}>{value?.Status}</option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>{row?.Company}</TableCell>
                      <TableCell className="labeltds" scope="row">
                        <a
                          onClick={() => {
                            EditAccountHandle(row.SA?._id);
                          }}
                        >
                          <u>
                            {row?.AccountName == undefined
                              ? row?.Company
                              : row?.AccountName}
                          </u>
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            EditContactHandle(row.P?._id, row.Email);
                          }}
                        >
                          <u>
                            {row?.ContactName === null
                              ? row?.FirstName + " " + row?.LastName
                              : row?.ContactName}
                          </u>
                        </a>
                      </TableCell>
                      <TableCell>{row?.Email}</TableCell>
                      <TableCell>{row?.Title}</TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.MeetingSourceName}</TableCell>
                      <TableCell>{row?.MeetingOwnerName}</TableCell>
                      <TableCell>{row?.ReminderEmailAccount}</TableCell>
                      <TableCell>{row?.DirectPhone}</TableCell>
                      <TableCell>{row?.CompanyPhone}</TableCell>
                      <TableCell>{row?.ResponderName}</TableCell>
                      <TableCell>
                        {moment(
                          new Date(row?.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            ViewPage(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={Open[row._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[row._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {Notes.map((note) =>
                                  note.PotentialMeetingID === row._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell>{note.Title}</TableCell>
                                      <TableCell className="labeltds">
                                        {moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {note.Note}
                                      </TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) => note.PotentialMeetingID === row._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
