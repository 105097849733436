import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import contactlistloadingicon from "../../../images/loading.gif";
import Swal from "sweetalert2";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function Contactlist({ ListID, handClose}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ProspectID");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [DataInfo, SetDataInfo] = React.useState([]);
  const [UpdateInfo, SetUpdateInfo] = React.useState([]);
  const [SaveList, SetSaveList] = React.useState(false);
  const [IsChecked,SetIsChecked] = React.useState(false);
  const [ContactListData,SetContactListData] = React.useState([]);
  const [UnContactListData,SetUnContactListData] = React.useState([]);


  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetUserIDby(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ContactListGet(GetUserData.ClientID,GetUserData.ParentUserID,ListID);
   // console.log("🚀 ~ file: ContactList.js:31 ~ Contactlist ~ ListID:", ListID)
  }, [Search, Page, RowsPerPage, handClose]);

  //get contact list
  const ContactListGet = (CID,UID,LID) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
      ListID:LID
    };
    const ContactLists = Axios({
      url: CommonConstants.MOL_APIURL + "/list/ContactList",
      method: "POST",
      data: InputParameter,
    });
    ContactLists.then((Result) => {

      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("contactlist-hideloading").style.display = "none";
      var NewArray = []
      Result && Result.data && Result.data.PageData.map((item) => {
        if(item.ContactList[0].ListID != null){
          NewArray.push({ProspectListID:item.ContactList[0].ProspectListID,ProspectID:item._id})
          SetContactListData(NewArray)
        }
      })
      

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchcontact").value;
      SetSearch(SearchedVal);
      SetPage(1);
      document.getElementById("contactlist-hideloading").style.display = "block";
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("contactlist-hideloading").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
    document.getElementById("contactlist-hideloading").style.display = "none";
  };

  // all check handler
  const CheckHandler=(e)=>{
    if (e.target.checked) {
       document.getElementById("AllCheck").checked =true;
    } else if (!e.target.checked) {
      document.getElementById("AllCheck").checked =false;
    }
  }

  //Add list contact
  const CheckBoxAdd = (e,ProspectListID,ID) => {
    if (e.target.checked) {
      ContactListData.push({  ProspectListID: ProspectListID,ProspectID:ID});
      
    } else if (!e.target.checked) {
      var newArray = []
    const array=  ContactListData.filter(function (val){
        return val.ProspectID !== ID
      })
      
      SetContactListData(array)
      // ContactListData.map((item) => {
      //   if(item.ProspectID == ID){
      //     newArray.push(item)
      //   }
      // })

      newArray.push({
        ProspectListID:ProspectListID,
        ProspectID: ID,
      });
      
      SetUnContactListData(newArray);
      // SetSaveList(true);
    }
  };

  const HandelClose = () =>{
    handClose
  }

  //Add and Update list contact with multiple check
  const SaveListBtn = () => {
     if(ContactListData.length > 0){
      var data = {
        CheckedArrayList:ContactListData,
        UnCheckedArrayList: UnContactListData,
        ListID:ListID,
        UserID:UserID,
        Role:Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/list/ContactListUpdate",
        method: "POST",
        data: data,
      }).then((Res) => {
        if (Res.data.StatusMessage == "SUCCESS") {
          handClose
          // history.push("/list");
          // handClose
          Swal.fire(
            "Contact List",
            "Selected Contact added successfully.",
            "success")
          //  handClose
        } else {
          toast.error(Res.data.Message);
        }
      });

    }else{
      toast.error("Please select atleast one contacts from list.")
    }

  };

    //get sort field data
    const SortData = (Field) => {
      document.getElementById("contactlist-hideloading").style.display = "block";
      var SearchedVal = document.getElementById("searchcontact").value;
      // SetPage(1);
      // SetRowsPerPage(10);
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        SetSflag(false);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          ListID:ListID,
          Role: Role,
        };
        
        const ContactListData = Axios({
          url:CommonConstants.MOL_APIURL +"/list/ContactList",
          method: "POST",
          data: InputParameter,
        });
        ContactListData.then((Result) => {
          
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          document.getElementById("contactlist-hideloading").style.display = "none";
        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          search: SearchedVal,
          SerchBox: SerchBox,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          ListID:ListID,
          Role: Role,
        };
        
        const ContactListData = Axios({
          url:CommonConstants.MOL_APIURL +"/list/ContactList",
          method: "POST",
          data: InputParameter,
        });
        ContactListData.then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          document.getElementById("contactlist-hideloading").style.display = "none";
        });
      }
    };

  return (
    <div>
          <div id="contactlist-hideloading" className="loding-display">
        <img src={contactlistloadingicon} />
      </div>
      <div className="row pb-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="searchcontact"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <input name="select_all_contacts" type="checkbox" onChange={(e) =>
                        CheckHandler(e)
                      } />
                </TableCell>
                <TableCell><a    onClick={() => {
                      SortData("FirstName");
                    }}>Name</a></TableCell>
                <TableCell><a onClick={() => {
                      SortData("Title");
                    }}>Title</a></TableCell>
                <TableCell><a onClick={() => {
                      SortData("Company");
                    }}>Company</a></TableCell>
                <TableCell><a onClick={() => {
                      SortData("Email");
                    }}>Email</a></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? <p>No data available in table</p> : Rows.map((row) => (

                <TableRow>
                  <TableCell>
                    <input
                      type="checkbox"
                      id="AllCheck"
                      onChange={(e) =>
                        CheckBoxAdd(e,row.ContactList[0].ProspectListID,row._id)
                      }
                      defaultChecked={row.ContactList[0].ListID == null ? false : true}

                    />
                  </TableCell>
                  <TableCell>{row?.FirstName}</TableCell>
                  <TableCell>{row?.Title}</TableCell>
                  <TableCell>{row?.Company}</TableCell>
                  <TableCell>{row?.Email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}

      <div className="modal-footer px-0 mt-3 pt-3 pb-0">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={handClose}
        >
          Cancel
        </button>
        <button 
          type="button"
          id="popup-close"
          className="btn btn-primary btn-lightgreen"
          onClick={SaveListBtn}
        >
          <i className="la la-save"></i> <span>Save</span>
        </button>
      </div>
    </div>
  );
}