import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const moment = require("moment");

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { Tooltip } from "@mui/material";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

export default function PotentialMeeting(props) {
  const [fdata, setfdata] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [sortField, setsortField] = React.useState("CreatedDate");
  const [sortedBy, setsortedBy] = React.useState(-1);
  const [notes, setNotes] = React.useState([]);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [status, setStatus] = React.useState([]);
  const [Role, setRole] = React.useState("");
  const [matchingNoteFound, setMatchingNoteFound] = React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  useEffect(() => {
    document.title = `Potential Meetings | SalesHive`;
    var getaccountcatelist = GetUserDetails();
  //  document.getElementById("hideloding").style.display = "block";

    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
    if (fdata.length == "") {
      GetPotentialList(
        getaccountcatelist.ClientID,
        getaccountcatelist.ParentUserID,
        getaccountcatelist.Role
      );
    } else {
      // setRows(props.sdata);
      document.getElementById("resetview").style.display = "block";
    }
    // setfdata(props.sdata);
  }, [
    search,
    page,
    RowsPerPage,
    props.FilterParameters,
    props.IsApply,
    props.updateFromChild,
  ]);

  //get potential meeting
  const GetPotentialList = (CID, UID, URole) => {
    SetIsLoading(true)
    var str_in = {
      page: page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      IsApply: props.IsApply,
      PotentialMeetingFilterArray: props.FilterParameters,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/GetPotentialMeeting",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      setStatus(result.data.potentialstatus);
      setNotes(result.data.notesdetails);
      SetIsLoading(false)
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == search) {
        setSearch(SearchedVal);
        setPage(1);
      } else {
        setSearch(SearchedVal);
        setPage(1);
        // document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //delete potential meeting
  const DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a potential meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/DeletePotentialMeeting",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Pontential meeting deleted successfully.",
                "success"
              );
              history.push("/cpotentialmeetings");
              setPage(1);
              setSearch("");
              document.getElementById("search").value = "";
              //document.getElementById("hideloding").style.display = "block";
              GetPotentialList(ClientID, UserID, Role);
              props.updateFromChild(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //change Page
  const HandleChangePage = (event, newPage) => {
    if (newPage == page) {
      setPage(newPage);
    } else {
      setPage(newPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

 //change display rows
 const ChangeRowSelected = (event) => {
  SetRowsPerPage(Number(event.target.value));
  setPage(1);
  //document.getElementById("hideloding").style.display = "block";
};

  //update potential status dropdown
  const PotentialStatusSelect = (event, id) => {
    var data = {
      _id: id,
      EngagamentStatusID: event.target.value,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
      Role: Role,
      LastStatusChangeDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/UpdatePotentialMeetingdropdown",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div>
            Potential Meeting <br />
            Engagement status successfully changed.
          </div>
        );
        document.getElementById("hideloding").style.display = "block";
        GetPotentialList(ClientID, UserID, Role);
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  //render on view page
  const ViewPage = (id) => {
    history.push({pathname:"/cviewpotentialmeetings", state:{data:id,Pagename:""}});
  };

  //Reset view
  const ResetView = () => {
    GetPotentialList(ClientID, UserID);
    document.getElementById("resetview").style.display = "none";
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
   // document.getElementById("hideloding").style.display = "block";
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      setsflag(false);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      setsortField(Field);
      var InputParameter = {
        page: page,
        rowsPerPage: RowsPerPage,
        sort: true,
        field: SortField,
        SerchBox: SerchBox,
        sortby: SortBy,
        search: SearchedVal,
        type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const meetingList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/GetPotentialMeeting",
        method: "POST",
        data: InputParameter,
      });
      meetingList.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
        SetIsLoading(false)
        //document.getElementById("hideloding").style.display = "none";
      });
    } else {
      SerchBox = true;
      setsflag(true);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      setsortField(Field);
      var InputParameter = {
        page: page,
        rowsPerPage: RowsPerPage,
        sort: true,
        field: SortField,
        SerchBox: SerchBox,
        sortby: SortBy,
        search: SearchedVal,
        type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const meetingList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/GetPotentialMeeting",
        method: "POST",
        data: InputParameter,
      });
      meetingList.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
        SetIsLoading(false)
       // document.getElementById("hideloding").style.display = "none";
      });
    }
  };

  // meetings add
  const MeetingsConvertAdd = (ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a potential meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, convert it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var str_in = {
          id: ids,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialMeetingByID",
          method: "POST",
          data: str_in,
        }).then((res) => {
          history.push({pathname:"/caddbookedmeetings", state:{Pagename:"Potential", data:res.data[0], BackPage:"/cbookedmeetings"}});
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const EditAccountHandle = (id) => {
    history.push({
      pathname: "/cviewaccount",
      state: { data: id, Pagename: "Potential" },
    });
  };

  const EditContactHandle = (id, Email) => {
    history.push({
      pathname: "/cviewcontacts",
      state: { data: id, EmailData: Email, Pagename: "Potential" },
    });
  };

  return (
    <div>
    {
    IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
  }
    <div className="row">
      <div className="col padd-0"></div>
    </div>
    <div className="row padt-25">
      <div className="col-sm-12" id="resetview" style={{ display: "none" }}>
        <a
          onClick={() => {
            ResetView();
          }}
        >
          Reset
        </a>
      </div>
      <div className="col-sm-12 col-md-6">
        <label className="textlabelte">
          Show
          <select
            name="tbl_meeting_length"
            onChange={ChangeRowSelected}
            aria-controls="tbl_meeting"
            className="form-control form-control-sm"
          >
            {CommonConstants.show_rows.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
          entries
        </label>
      </div>

      <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">
          Search:{" "}
          <input
            type="search"
            id="search"
            onKeyPress={RequestSearch}
            className="form-control form-control-sm ml-2"
            placeholder=""
            aria-controls="tbl_meeting"
          />
        </label>
      </div>
    </div>
    <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Notes</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Convert</TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FirstName");
                  }}
                >
                  Account Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("EngagamentStatusID");
                  }}
                >
                  Engagement Status
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Company");
                  }}
                >
                  Company
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ContactName");
                  }}
                >
                  Contact Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Email");
                  }}
                >
                  Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Domain");
                  }}
                >
                  Domain
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Title");
                  }}
                >
                  Title
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("LinkedInUrl");
                  }}
                >
                  LinkedIn Url
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("MeetingSourceID");
                  }}
                >
                  Meeting Source Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("MeetingOwnerID");
                  }}
                >
                  Owner Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ReminderEmailAccountID");
                  }}
                >
                  Reminder Email Account
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("DirectPhone");
                  }}
                >
                  Direct Phone
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("DirectPhoneExt");
                  }}
                >
                  Direct Phone Ext
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("CompanyPhone");
                  }}
                >
                  Company Phone
                </a>
              </TableCell>
              <TableCell>Last Status Change Date</TableCell>
              <TableCell>Last Email Campaign</TableCell>
              <TableCell>Last Email Step</TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("CreatedDate");
                  }}
                >
                  Created Date
                </a>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              rows?.map((row) => (
                <>
                  <TableRow key={row._id}>
                    <TableCell align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          setOpen((prev) => ({
                            // ...prev,
                            [row._id]: !prev[row._id],
                          }))
                        }
                      >
                        {open[row._id] ? (
                          <i
                            className="fa fa-minus-circle plusbl"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-plus-circle minusl"
                            aria-hidden="true"
                          ></i>
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          ViewPage(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <Tooltip title="Edit">
                          <i className="la flaticon-eye edit-icon"></i>
                        </Tooltip>
                      </a>

                      <a
                        onClick={() => {
                          DeleteClientName(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <Tooltip title="Delete">
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </Tooltip>
                      </a>
                    </TableCell>
                    <TableCell scope="row">
                      <a
                        onClick={() => {
                          MeetingsConvertAdd(row?._id);
                        }}
                        className="btn-exchangeicon"
                      >
                        <Tooltip title="Convert to Meeting">
                          <i className="la la-exchange"></i>
                        </Tooltip>
                      </a>
                    </TableCell>
                    <TableCell className="labeltds" scope="row">
                      <a
                        onClick={() => {
                          EditAccountHandle(row.SA?._id);
                        }}
                      >
                        <u>{row?.AccountName==undefined?row?.Company:row?.AccountName}</u>
                      </a>
                    </TableCell>
                    <TableCell className="labeltds" scope="row" >
                      <select
                        onChange={(e) => {
                          PotentialStatusSelect(e, row?._id);
                        }}
                        class="form-control  m-input"
                        id="Potentialstatus"
                        name="Potentialstatus"
                        autoComplete="off"
                        value={row.EngagamentStatusID}
                       //style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        {status.map((value) => (
                          <option value={value._id}>{value.Status}</option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell>{row?.Company}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditContactHandle(row.P?._id, row.Email);
                        }}
                      >
                        <u>
                          {row?.ContactName === null
                            ? row?.FirstName + " " + row?.LastName
                            : row?.ContactName}
                        </u>
                      </a>
                    </TableCell>
                    <TableCell>{row?.Email}</TableCell>
                    <TableCell>
                      {row?.Email.substring(row.Email.lastIndexOf("@") + 1)}
                    </TableCell>
                    <TableCell>{row?.Title}</TableCell>
                    <TableCell>{row?.LinkedInUrl}</TableCell>
                    <TableCell>{row?.MeetingSourceName}</TableCell>
                    <TableCell>{row?.MeetingOwnerName}</TableCell>
                    <TableCell>{row?.ReminderEmailAccount}</TableCell>
                    <TableCell>{row?.DirectPhone}</TableCell>
                    <TableCell>{row?.DirectPhoneExt}</TableCell>
                    <TableCell>{row?.CompanyPhone}</TableCell>
                    <TableCell>
                      {moment(
                        new Date(row?.LastStatusChangeDate).toDateString()
                      ).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      {row?.P?.CampaignDetails?.Name}
                    </TableCell>
                    <TableCell>
                      {row?.P?.CampaignDetails?.LastEmailStep}
                    </TableCell>
                    <TableCell>
                      {moment(
                        new Date(row?.CreatedDate).toDateString()
                      ).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          ViewPage(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <Tooltip title="Edit">
                          <i className="la flaticon-eye edit-icon"></i>
                        </Tooltip>
                      </a>
                      <a
                        onClick={() => {
                          DeleteClientName(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <Tooltip title="Delete">
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </Tooltip>
                      </a>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={open[row._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {open[row._id] && (
                          <Box margin={1} className="innertables">
                            <Table size="small" aria-label="purchases">
                              <TableHead></TableHead>

                              {notes.map((note) =>
                                note?.PotentialMeetingID.toString() ===
                                row?._id.toString() ? (
                                  <TableRow key={note._id}>
                                    <TableCell className="font-weight-bold">
                                      {note?.Title}
                                    </TableCell>
                                    <TableCell className="labeltds font-weight-bold">
                                      {moment(
                                        new Date(
                                          note?.CreatedDate
                                        ).toDateString()
                                      ).format("MM/DD/YYYY")}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {note?.Note}
                                    </TableCell>
                                  </TableRow>
                                ) : null
                              )}

                              {!notes.some(
                                (note) =>
                                  note?.PotentialMeetingID.toString() ===
                                  row?._id.toString()
                              ) && (
                                <TableRow>
                                  <TableCell colSpan={4}>
                                    No data available
                                  </TableCell>
                                </TableRow>
                              )}
                            </Table>
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    {sflag ? (
      <div className="row">
        <Pagination
          component="div"
          count={countpage}
          onChange={HandleChangePage}
          showFirstButton
          showLastButton
        />
        <div className="col dataTables_info">
          <p>
            Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
            {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
            entries (filtered from {flen} total entries)
          </p>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
            {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={countpage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    )}
  </div>
  );
}
