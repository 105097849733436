import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../Kpis/LastdaysTabing';

import SenderTable from './SenderTable';
import ReceiverTable from './ReceiverTable';  
 
import ImageUploading from 'react-images-uploading';
import Popup from "reactjs-popup";

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import 'froala-editor/js/plugins.pkgd.min.js';
import Froalaeditor from 'froala-editor'
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  }; 
  const openTermsConditionPopup = () => {
    //console.log("--it's true");
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //console.log("--it's false");
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };


  document.addEventListener('mouseup', function(e) {
      console.log("--popup value--");
      //console.log(popupval);
      if(popupval){
        setpopup(false);
      }
  });
    //const openTermsConditionPopup = false;
  

  return ( 
    <div className="tabdes"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"> 
            <Tab label="Sender" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Receiver" {...a11yProps(1)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}> 
            <div className="row">
            <div className="col px-4"> 
                <SenderTable /> 
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={1}>  
            <div className="row">
            <div className="col px-4"> 
                <ReceiverTable /> 
            </div>
          </div> 
        </TabPanel>
 
      </div> 
    </div> 
  );
}