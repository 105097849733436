import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
const moment = require("moment");
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";

class CEditBookedMeetingPage extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props_meeting", this.props.location.state)
    // console.log("props_meeting1", this.props)
    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      showing: true,
      meetingsource: [],
      Statusfield: [],
      meetingstatus: [],
      meetingsource: [],
      meetingowner: [],
      meetingtimezone: [],
      meetingaccount: [],
      meetingseniority: [],
      meetingsetbyResponder: [],
      meetingsetbyColdCallers: [],
      checked: true,
      meetingseniority1: [],
      defaultselect: [],
      defaultselectstatus: [],
      defaultselectsource: [],
      defaultselecttimezone: [],
      defaultselectseniority: [],
      sourcemeeting: "",
      statusmeeting: [],
      data: [],
      rows: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      data1: [],
      rows1: [],
      rlen1: 0,
      flen1: 0,
      countpage1: 0,
      ClientID: null,
      UserID: null,
      CUserID: null,
      page: 1,
      rowsPerPage: 10,
      search: "",
      sortField: "Name",
      sortedBy: 1,
      sflag: false,
      page1: 1,
      rowsPerPage1: 10,
      search1: "",
      sortField1: "Name",
      sortedBy1: 1,
      sflag1: false,
      meetingid: "",
      fields1: {},
      errors1: {},
      StatusName: "",
      oldstatusid: "",
      meetingCustomField: [],
      meetingCustomfieldbyid: [],
      EmailAvailable: null,
      TitleAvailable: null,
      OldName: null,
      ShowNotePopup: false,
      Role: null,
      IsButtonDisabled: false,
      AccountNamea: "",
      BtnDisabledSave: false,
      BtnDisabledUpdate: false,
      IsValid: false,
      IsWebsite: true,
      WebsiteNotNull: false,
      IsInValidWebsite: false,
      IsValidEmail: false,
    };
    this.backbtn = this.backbtn.bind(this);
    // this.editbtn = this.editbtn.bind(this);
    this.editspage = this.editspage.bind(this);
    this.saveupdatebtn = this.saveupdatebtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.savebtnmain = this.savebtnmain.bind(this);
    this.DeleteClientName = this.DeleteClientName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangesource = this.handleChangesource.bind(this);
    this.handleChangetimezone = this.handleChangetimezone.bind(this);
    this.handleChangeseniority = this.handleChangeseniority.bind(this);
    this.handleChangestatus = this.handleChangestatus.bind(this);
    this.handleChangeowner = this.handleChangeowner.bind(this);
    this.handleChangeSetby = this.handleChangeSetby.bind(this);
    this.handleChangeReminderEmail = this.handleChangeReminderEmail.bind(this);
    this.pikerdatevalue = this.pikerdatevalue.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.HandleChangeotherFieldBlur =
      this.HandleChangeotherFieldBlur.bind(this);
    this.handleChangeWebsitecheck = this.handleChangeWebsitecheck.bind(this);
    this.GetBookedmeeting();
    // this.MeetingHistoryStatus();
    this.NotesGet();
    this.MeetingHistoryGet();
  }
  componentDidMount() {
    var Details = GetUserDetails();

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.EditBookedMeeting();
    document.getElementById("FirstName_error").style.display = "none";
    document.getElementById("LastName_error").style.display = "none";
    document.getElementById("Title_error").style.display = "none";
    document.getElementById("Company_error").style.display = "none";
    document.getElementById("Email_error").style.display = "none";
    //document.getElementById("Website_error").style.display = "none";
  }

  // form validation
  async FromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};

    var FirstName = document.getElementById("FirstName").value.trim();
    var LastName = document.getElementById("LastName").value.trim();
    var Title = document.getElementById("Title").value.trim();
    var Company = document.getElementById("Company").value.trim();
    var Email = document.getElementById("Email").value.trim();
    var Website = document.getElementById("Website").value.trim();

    var IsExistEmail = await this.CheckExistBookedMeetingName(Email);
    let IsExistWebsite = await this.CheckWebSite(Website);

    if (!fields["FirstName"] && FirstName == "") {
      formIsValid = false;
      errors["FirstName"] = "Please enter first name";
    }
    if (!fields["LastName"] && LastName == "") {
      formIsValid = false;
      errors["LastName"] = "Please enter last name";
    }
    if (!fields["Title"] && Title == "") {
      formIsValid = false;
      errors["Title"] = "Please enter Title";
    }
    if (!fields["Company"] && Company == "") {
      formIsValid = false;
      errors["Company"] = "Please enter Company";
    }

    //website
    if (IsExistWebsite == "InvalidWebsite") {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
    }
    if (IsExistWebsite == "BlankDataWebsite" && Website == "") {
      formIsValid = false;
      errors["Website"] = "Please enter Website";
    }

    //email
    if (IsExistEmail == "InvalidEmail") {
      formIsValid = false;
      errors["Email"] = "invalid email.";
    }
    if (IsExistEmail == "ExistEmail") {
      formIsValid = false;
      errors["Email"] = "Email Already Exist";
    }

    if (IsExistEmail == "BlankData" && Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }
    //skip for now
    // if (!fields["MeetingSourceID"]) {
    //   formIsValid = false;
    //   errors["MeetingSourceID"] = "Please select Meeting Source";
    // }
    // if (!fields["MeetingSetbyID"]) {
    //   formIsValid = false;
    //   errors["MeetingSetbyID"] = "Please select Meeting Set by";
    // }
    // if (!fields["TimeZoneID"]) {
    //   formIsValid = false;
    //   errors["TimeZoneID"] = "Please select Time Zone";
    // }
    // if (!fields["ClientPOCID"]) {
    //   formIsValid = false;
    //   errors["ClientPOCID"] = "Please select reminder email account";
    // }
    // if (!fields["MeetingOwnerID"]) {
    //   formIsValid = false;
    //   errors["MeetingOwnerID"] = "Please select owner";
    // }
    // if (!fields["SeniorityID"]) {
    //   formIsValid = false;
    //   errors["SeniorityID"] = "Please select Seniority";
    // }

    // if (this.state.UserExist != null) {
    //     formIsValid = false;
    // }
    this.setState({ errors: errors });

    return formIsValid;
  }

  handleChangeOne(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Email != "") {
      this.state.errors.Email = null;
      this.state.EmailAvailable = null;
      this.CheckExistBookedMeetingName(e.target.value);
    } else {
      this.state.EmailAvailable = null;
      this.state.errors.Email = null;
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Email != "") {
      this.state.errors.Email = null;
      this.state.EmailAvailable = null;
      //this.CheckExistBookedMeetingName(e.target.value);
    } else {
      this.state.EmailAvailable = null;
      this.state.errors.Email = null;
    }
  }

  async CheckWebSite(Email) {
    var regexp = new RegExp(
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidWebsite";
      } else {
        return "AllDoneWebsite";
      }
    } else {
      return "BlankDataWebsite";
    }
  }

  async handleChangeWebsitecheck() {
    let errors = { ...this.state.errors };
    var Website = document.getElementById("Website").value.trim();
    if (Website == "") {
      errors["Website"] = "Please enter Website";
    } else {
      let IsExist = await this.CheckWebSite(Website);
      if (IsExist == "InvalidWebsite") {
        errors["Website"] = "Invalid website!";
      }
      if (IsExist == "AllDoneWebsite") {
        errors["Website"] = null;
      }
    }
    this.setState({ errors: errors });
  }

  async HandleChangeotherFieldBlur(Name) {
    
    let errors = { ...this.state.errors };
    var FirstName = document.getElementById("FirstName").value.trim();
    var LastName = document.getElementById("LastName").value.trim();
    var Title = document.getElementById("Title").value.trim();
    var Company = document.getElementById("Company").value.trim();

    if (Name == "FirstName") {
      if (FirstName == "") {
        errors["FirstName"] = "Please enter first name";
      } else {
        errors["FirstName"] = null;
      }
    }
    if (Name == "LastName") {
      if (LastName == "") {
        errors["LastName"] = "Please enter last name";
      } else {
        errors["LastName"] = null;
      }
    }
    if (Name == "Title") {
      if (Title == "") {
        errors["Title"] = "Please enter Title";
      } else {
        errors["Title"] = null;
      }
    }
    if (Name == "Company") {
      if (Company == "") {
        errors["Company"] = "Please enter Company";
      } else {
        errors["Company"] = null;
      }
    }
    this.setState({ errors: errors });
  }

  async HandleChangeBlur() {
    let errors = { ...this.state.errors };
    var Email = document.getElementById("Email").value.trim();
    if (Email == "") {
      errors["Email"] = "Please enter Email";
    } else {
      let IsExist = await this.CheckExistBookedMeetingName(Email);
      if (IsExist == "InvalidEmail") {
        errors["Email"] = "invalid email.";
      }
      if (IsExist == "ExistEmail") {
        errors["Email"] = "Email Already Exist";
      }
      if (IsExist == "AllDone") {
        errors["Email"] = null;
      }
    }

    this.setState({ errors: errors });
  }

  // handleChangeEmailcheck() {
  //   var Website = document.getElementById("Email").value.trim();
  //   if (Website != "") {
  //     let errors = {};
  //     this.state.errors.Website = null;
  //     this.CheckWebSite(Website)
  //     errors["Website"] = "Invalid website!";
  //   } else {
  //     // this.state.statusAvailable = null;
  //     this.state.errors.Website = null;
  //   }
  // }

  // check exit Email
  async CheckExistBookedMeetingName(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidEmail";
      } else {
        // this.setState({ IsExist: false });
        var str_in = {
          ClientID: this.state.ClientID,
          Email: Email,
          Role: this.state.Role,
        };
        var resdata = await Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/BookedMeetingCheckExists",
          method: "POST",
          data: str_in,
        });
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            if (this.state.OldName == Email) {
              return "AllDone";
            } else {
              this.setState({ EmailAvailable: resdata.data.Data[0]?.Email });
              return "ExistEmail";
            }
          } else {
            return "AllDone";
          }
        }
      }
    } else {
      return "BlankData";
    }
  }

  GetBookedmeeting() {
    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/FindMeetingAllStatus",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      this.setState({
        meetingstatus: result.data?.meetingstatus,
        meetingsource: result.data?.meetingsource,
        meetingowner: result.data?.meetingowner,
        meetingtimezone: result.data?.meetingtimezone,
        meetingaccount: result.data?.meetingaccount,
        meetingseniority: result.data?.meetingseniority,
        meetingsetbyResponder: result.data.meetingsetby[0]?.ResponderDetails,
        meetingsetbyColdCallers:
          result.data.meetingsetby[0]?.ColdCallersDetails,
        meetingCustomField: result.data?.meetingCustomField,
      });
    });
  }

  EditBookedMeeting() {
    const id = this.props.location.state.data;
    this.setState({ EventID: id });
    var str_in = {
      id: id,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ OldName: res.data.data[0]?.Email });
      document.getElementById("FirstName").value =
        res.data.data[0]?.FirstName || "";
      document.getElementById("LastName").value =
        res.data.data[0]?.LastName || "";
      document.getElementById("Title").value = res.data.data[0]?.Title || "";
      document.getElementById("Company").value =
        res.data.data[0]?.Company || "";
      document.getElementById("Email").value = res.data.data[0]?.Email || "";
      document.getElementById("LinkedInUrl").value =
        res.data.data[0]?.LinkedInUrl || "";
      document.getElementById("MeetingSourceID").value =
        res.data.data[0]?.MeetingSourceID || "";
      document.getElementById("MeetingOwnerID").value =
        res.data.data[0]?.MeetingOwnerID || "";
      document.getElementById("DirectPhone").value =
        res.data.data[0]?.DirectPhone || "";
      document.getElementById("DirectPhoneExt").value =
        res.data.data[0]?.DirectPhoneExt || "";
      document.getElementById("CompanyPhone").value =
        res.data.data[0]?.CompanyPhone || "";
      document.getElementById("Website").value =
        res.data.data[0]?.Website || "";
      document.getElementById("MeetingStatusID").value =
        res.data.data[0]?.StatusID || "";
      document.getElementById("MeetingTimeZoneID").value =
        res.data.data[0]?.TimeZoneID || "";
      document.getElementById("SeniorityMeetingID").value =
        res.data.data[0]?.SeniorityID || "";
      document.getElementById("Industry").value =
        res.data.data[0]?.Industry || "";
      document.getElementById("ExternalAccountID").value =
        res.data.data[0]?.ExternalAccountID || "";
      document.getElementById("ExternalContactID").value =
        res.data.data[0]?.ExternalContactID || "";
      document.getElementById("ExternalField1").value =
        res.data.data[0]?.ExternalField1 || "";
      document.getElementById("ExternalField2").value =
        res.data.data[0]?.ExternalField2 || "";
      document.getElementById("ExternalField3").value =
        res.data.data[0]?.ExternalField3 || "";
      document.getElementById("ContractValue").value =
        res.data.data[0]?.ContractValue || "";

      var todayDate = moment(res.data.data[0]?.EventCreatedDt).format(
        "YYYY-MM-DD"
      );
      this.setState({ AccountNamea: res.data.data[0]?.A[0]?.Name });
      // document.getElementById("date2").value = todayDate;
      // document.getElementById("datebookstate").value = todayDate;
      this.setState({ defaultselectsource: res.data.data[0]?.MeetingSourceID });
      this.setState({ defaultselecttimezone: res.data.data[0]?.TimeZoneID });
      this.setState({ defaultselectseniority: res.data.data[0]?.SeniorityID });
      this.setState({ defaultselectstatus: res.data.data[0]?.StatusID });
      this.setState({ oldstatusid: res.data.data[0]?.StatusID });
      this.setState({
        defaultselecttimeowner: res.data.data[0]?.MeetingOwnerID,
      });
      this.setState({
        defaultselectmeetingsetby: res.data.data[0]?.ResponderColdCallersID,
      });
      this.setState({
        defaultselectreminderemail: res.data.data[0]?.ReminderEmailAccountID,
      });
      this.setState({ datevalue: res.data.data[0]?.EventStartDt });
      //status name
      if (res.data.data[0]?.StatusID != null) {
        var str_in = {
          MeetingStatusID: res.data.data[0]?.StatusID,
          ClientID: this.state.ClientID,
          Role: this.state.Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingStatusName",
          method: "POST",
          data: str_in,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            this.setState({ StatusName: res.data.data[0]?.Status });
          } else {
            toast.error(res.data.Message);
          }
        });
      }

      var str_in9 = {
        CustomFieldID: res.data.data[0]?._id,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingCustomFieldId",
        method: "POST",
        data: str_in9,
      }).then((resmeeting) => {
        this.setState({
          meetingCustomfieldbyid: resmeeting.data?.meetingcustomfieldByid,
        });
      });

      // notes
      this.setState({ meetingid: res.data.data[0]?._id });
      document.getElementById("hideloding").style.display = "none";
    });
  }

  MeetingHistoryGet = () => {
    // Meeting status history
    var Details = GetUserDetails();
    var str_in3 = {
      ClientID: Details.ClientID,
      EventID: this.props.location.state.data,
      Page: this.state.page1,
      RowsPerPage: this.state.rowsPerPage1,
      Sort: true,
      Field: this.state.sortField1,
      Sortby: this.state.sortedBy1,
      Search: this.state.search1,
      Type: "User",
      Role: Details.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/bookedmeeting/GetBookedlMeetingStatusHistory",
      method: "POST",
      data: str_in3,
    }).then((reshistory) => {
      if (reshistory === null) {
        this.setState({ data1: null });
        this.setState({ rows1: null });
        this.setState({ rlen1: null });
        this.setState({ flen1: null });
        this.setState({ countpage1: null });
      } else {
        this.setState({ data1: reshistory.data.pagedata });
        this.setState({ rows1: reshistory.data.pagedata });
        this.setState({ rlen1: reshistory.data.totalcount });
        this.setState({ flen1: reshistory.data.totalcount });
        this.setState({ countpage1: reshistory.data.pagecount });
      }
      // this.setState({ statusmeeting: reshistory.data.meetingstatus });
    });
  };

  backbtn() {
    if (this.props.location.state["Pagename"] == "Meeting") {
      history.push({
        pathname: "/cviewcontacts",
        state: { data: this.props.location.state?.dataprospect },
      });
    } else if (this.props.location.state["Pagename"] == "ClientMeeting") {
      history.push("/clientmeetings");
    } else if (this.props.location.state["Pagename"] == "coppotunities") {
      history.push("/copportunities");
    } else if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/ceditaccount",
        state: { data: this.props.location.state.AccountData },
      });
    } else if (this.props.location.state["Pagename"] == "kpiMeeting") {
      history.push("/ckpis");
    } else if (this.props.location.state["Pagename"] == "cRescheduling") {
      history.push("/crescheduling");
    } else {
      history.push("/cbookedmeetings");
      // history.push({pathname:"/bookedmeetings", state:{data:id,Pagename:""}});
    }
  }

  NotesGet = () => {
    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      MeetingID: this.props.location.state.data,
      Page: this.state.page,
      RowsPerPage: this.state.rowsPerPage,
      Sort: true,
      Field: this.state.sortField,
      Sortby: this.state.sortedBy,
      Search: this.state.search,
      Type: "User",
      Role: Details.Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      if (resnotes === null) {
        this.setState({ data: null });
        this.setState({ rows: null });
        this.setState({ rlen: null });
        this.setState({ flen: null });
        this.setState({ countpage: null });
      } else {
        this.setState({ data: resnotes.data.pagedata });
        this.setState({ rows: resnotes.data.pagedata });
        this.setState({ rlen: resnotes.data.totalcount });
        this.setState({ flen: resnotes.data.totalcount });
        this.setState({ countpage: resnotes.data.pagecount });
      }
    });
  };

  //View booked meeting Notes
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    var str_in = {
      ClientID: this.state.ClientID,
      MeetingID: this.state.meetingid,
      Page: this.state.page,
      RowsPerPage: this.state.rowsPerPage,
      Sort: true,
      Field: this.state.sortField,
      Sortby: this.state.sortedBy,
      Search: this.state.search,
      Type: "User",
      Role: this.state.Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      this.setState({ data: resnotes.data?.pagedata });
      this.setState({ rows: resnotes.data?.pagedata });
      this.setState({ rlen: resnotes.data?.totalcount });
      this.setState({ flen: resnotes.data?.totalcount });
      this.setState({ countpage: resnotes.data?.pagecount });
    });
  };

  requestSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent any default form submission behavior
      var searchedVal = document.getElementById("search").value;
      this.setState({ page: 1, rowsPerPage: 10, search: searchedVal }, () => {
        // The setState function can take a callback function that is called after the state has been updated.
        // You can call NotesGet here to ensure it runs after the state is updated.
        this.NotesGet();
      });
    }
  };

  DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: this.state.CUserID,
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/DeleteBookedNotesMeeting",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Delete booked meeting Notes Meeting successfully.",
                "success"
              );
              this.NotesGet();
              //history.push({pathname:"/editbookedmeeting", state:{data:this.state.meetingid,Pagename:""}});
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  chengeRowSelected = (event) => {
    // console.log(event.target.value);

    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 1 });
    var str_in = {
      ClientID: this.state.ClientID,
      MeetingID: this.state.meetingid,
      Page: this.state.page,
      RowsPerPage: this.state.rowsPerPage,
      Sort: true,
      Field: this.state.sortField,
      Sortby: this.state.sortedBy,
      Search: this.state.search,
      Type: "User",
      Role: this.state.Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      this.setState({ data: resnotes.data.pagedata });
      this.setState({ rows: resnotes.data.pagedata });
      this.setState({ rlen: resnotes.data.totalcount });
      this.setState({ flen: resnotes.data.totalcount });
      this.setState({ countpage: resnotes.data.pagecount });
    });
  };

  async fromValidationNotes() {
    let formIsValid = true;
    let fileds = this.state.fields;
    let errors = {};
    var Title22 = document.getElementById("Title22").value.trim();
    var Notes22 = document.getElementById("Notes22").value.trim();
    var IsExist = await this.CheckExistBookedMeetingTitle(Title22);
    if (Title22 == "") {
      formIsValid = false;
      errors["Title22"] = "Please Enter Title";
    }
    if (Notes22 == "") {
      formIsValid = false;
      errors["Notes22"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChangeNotes(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Title != "") {
      this.state.errors.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistBookedMeetingTitle(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.errors.Title = null;
    }
  }

  async CheckExistBookedMeetingTitle(Title) {
    var str_in = {
      ClientID: this.state.ClientID,
      Title: Title,
      Role: this.state.Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/NotesMeetingExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({ TitleAvailable: resdata.data.Data[0].Title });
        return true;
      } else {
        return false;
      }
    }
  }

  async savebtn(e) {
    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    var Title1 = document.getElementById("Title22").value;
    var Notes2 = document.getElementById("Notes22").value;
    this.setState({ BtnDisabledSave: true });
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await this.fromValidationNotes();
    if (Final_flag == true) {
      var data = {
        ClientID: this.state.ClientID,
        Title: Title1,
        Note: Notes2,
        IsDeleted: false,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        MeetingID: this.state.meetingid,
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/AddBookedNotesMeeting",
        method: "POST",
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Booked meeting notes add successfully.",
            "Add Booked meeting Status"
          );

          //history.push({pathname:"/editbookedmeeting", state:{data:this.state.meetingid,Pagename:""}});
          this.setState({ ShowNotePopup: false });
          this.NotesGet();
        } else {
          this.setState({ BtnDisabledSave: false });
          toast.error(res.data.Message);
        }
      });
    } else {
      //document.getElementById("submitSave").disabled = false;
      this.setState({ BtnDisabledSave: false });
    }
  }

  editspage(id) {
    this.setState({ id: id });
    var str_in = {
      id: id,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingNotesById",
      method: "POST",
      data: str_in,
    }).then((res) => {
      // console.log("Meeting details", res.data);
      // if (res.data.StatusMessage == "SUCCESS") {
      this.setState({ OldTitleUpdate: res.data?.Title });
      document.getElementById("Title1").value = res.data?.Title;
      document.getElementById("Notes").value = res.data?.Note;
    });
  }

  async fromValidationNote() {
    let formIsValid = true;

    let fields = this.state.fields1;
    let errors1 = {};

    var Title1 = document.getElementById("Title1").value.trim();
    var Notes = document.getElementById("Notes").value.trim();
    var IsExist = await this.CheckExistBookedMeetingTitle1(Title1);
    if (Title1 == "") {
      formIsValid = false;
      errors1["Title1"] = "Please Enter Title";
    }
    if (Notes == "") {
      formIsValid = false;
      errors1["Notes"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    this.setState({ errors1: errors1 });

    return formIsValid;
  }

  handleChangeNote(field, e) {
    let fields1 = this.state.fields1;
    fields1[field] = e.target.value;
    this.setState({ fields1 });
    if (fields1.Title != "") {
      this.state.errors1.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistBookedMeetingTitle1(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.errors1.Title = null;
    }
  }

  async CheckExistBookedMeetingTitle1(Title) {
    var str_in = {
      ClientID: this.state.ClientID,
      Title: Title,
      Role: this.state.Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/NotesMeetingExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (this.state.OldTitleUpdate == Title) {
          return false;
        } else {
          this.setState({ TitleAvailable: resdata.data.Data[0].Title });
          return true;
        }
      } else {
        return false;
      }
    }
  }

  async saveupdatebtn(e) {
    var Title = document.getElementById("Title1").value.trim();
    var Notes = document.getElementById("Notes").value;
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({ BtnDisabledUpdate: true });
    var FinalValidation = await this.fromValidationNote();
    if (FinalValidation == true) {
      var data = {
        _id: this.state.id,
        ClientID: this.state.ClientID,
        MeetingID: this.state.meetingid,
        MeetingNoteID: this.state.id,
        Title: Title,
        Note: Notes,
        IsDeleted: false,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/BookedNotesMeetingUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        // console.log(res);
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Booked meeting notes updated successfully.",
            "Add Booked meeting Status"
          );
          // history.push({pathname:"/editbookedmeeting", state:{data:this.state.meetingid,Pagename:""}});
          this.NotesGet();
        } else {
          toast.error(res.data.Message);
          this.setState({ BtnDisabledUpdate: false });
        }
      });
    } else {
      this.setState({ BtnDisabledUpdate: false });
      //document.getElementById("submitupdate").disabled = false;
    }
  }

  //get sort field Data
  SortData(Field) {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      this.setState({ sflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.sortedBy == 1) {
        SortBy = -1;
        this.setState({ sortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ sortedBy: 1 });
      }
      this.setState({ sortField: Field });
      var InputParameter = {
        ClientID: this.state.ClientID,
        MeetingID: this.state.meetingid,
        Page: this.state.page,
        RowsPerPage: this.state.rowsPerPage,
        Sort: true,
        Field: this.state.sortField,
        Sortby: this.state.sortedBy,
        Search: this.state.search,
        Type: "User",
        Role: this.state.Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((resnotes) => {
        this.setState({ data: resnotes.data.pagedata });
        this.setState({ rows: resnotes.data.pagedata });
        this.setState({ rlen: resnotes.data.totalcount });
        this.setState({ flen: resnotes.data.totalcount });
        this.setState({ countpage: resnotes.data.pagecount });
      });
    } else {
      SerchBox = true;
      this.setState({ sflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.sortedBy == 1) {
        SortBy = -1;
        this.setState({ sortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ sortedBy: 1 });
      }
      this.setState({ sortField: Field });
      var InputParameter = {
        ClientID: this.state.ClientID,
        MeetingID: this.state.meetingid,
        Page: this.state.page,
        RowsPerPage: this.state.rowsPerPage,
        Sort: true,
        Field: this.state.sortField,
        Sortby: this.state.sortedBy,
        Search: this.state.search,
        Type: "User",
        Role: this.state.Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((resnotes) => {
        this.setState({ data: resnotes.data.pagedata });
        this.setState({ rows: resnotes.data.pagedata });
        this.setState({ rlen: resnotes.data.totalcount });
        this.setState({ flen: resnotes.data.totalcount });
        this.setState({ countpage: resnotes.data.pagecount });
      });
    }
  }

  //View booked meeting Status History
  requestSearch1 = (event) => {
    if (event.key === "Enter") {
      var searchedVal = document.getElementById("search1").value;
      this.setState({ page1: 1 });
      this.setState({ rowsPerPage1: 10 });
      this.setState({ search1: searchedVal });

      var str_in = {
        ClientID: this.state.ClientID,
        EventID: this.state.meetingid,
        Page: this.state.page1,
        RowsPerPage: this.state.rowsPerPage1,
        Sort: true,
        Field: this.state.sortField1,
        Sortby: this.state.sortedBy1,
        Search: this.state.search1,
        Type: "User",
        Role: this.state.Role,
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/GetBookedlMeetingStatusHistory",
        method: "POST",
        data: str_in,
      });
      rows1.then((reshistory) => {
        this.setState({ data1: reshistory.data.pagedata });
        this.setState({ rows1: reshistory.data.pagedata });
        this.setState({ rlen1: reshistory.data.totalcount });
        this.setState({ flen1: reshistory.data.totalcount });
        this.setState({ countpage1: reshistory.data.pagecount });
      });
    }
  };

  handleChangePage1 = (event, newPage) => {
    this.setState({ page1: newPage });
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      Page: this.state.page1,
      RowsPerPage: this.state.rowsPerPage1,
      Sort: true,
      Field: this.state.sortField1,
      Sortby: this.state.sortedBy1,
      Search: this.state.search1,
      Type: "User",
      Role: this.state.Role,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/bookedmeeting/GetBookedlMeetingStatusHistory",
      method: "POST",
      data: str_in,
    });
    rows1.then((reshistory) => {
      this.setState({ data1: reshistory.data.pagedata });
      this.setState({ rows1: reshistory.data.pagedata });
      this.setState({ rlen1: reshistory.data.totalcount });
      this.setState({ flen1: reshistory.data.totalcount });
      this.setState({ countpage1: reshistory.data.pagecount });
    });
  };

  chengeRowSelected1 = (event) => {
    //console.log(event.target.value);

    this.setState({ rowsPerPage1: event.target.value });
    this.setState({ page1: 1 });
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      Page: this.state.page1,
      RowsPerPage: this.state.rowsPerPage1,
      Sort: true,
      Field: this.state.sortField1,
      Sortby: this.state.sortedBy1,
      Search: this.state.search1,
      Type: "User",
      Role: this.state.Role,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/bookedmeeting/GetBookedlMeetingStatusHistory",
      method: "POST",
      data: str_in,
    });
    rows1.then((reshistory) => {
      this.setState({ data1: reshistory.data.pagedata });
      this.setState({ rows1: reshistory.data.pagedata });
      this.setState({ rlen1: reshistory.data.totalcount });
      this.setState({ flen1: reshistory.data.totalcount });
      this.setState({ countpage1: reshistory.data.pagecount });
    });
  };

  //get sort field Data
  SortData1(Field) {
    var SearchedVal = document.getElementById("search1").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      this.setState({ sflag1: false });
      var SortField = Field;
      var SortBy;
      if (this.state.sortedBy1 == 1) {
        SortBy = -1;
        this.setState({ sortedBy1: -1 });
      } else {
        SortBy = 1;
        this.setState({ sortedBy1: 1 });
      }
      this.setState({ sortField1: Field });
      var InputParameter = {
        ClientID: this.state.ClientID,
        EventID: this.state.meetingid,
        Page: this.state.page1,
        RowsPerPage: this.state.rowsPerPage1,
        Sort: true,
        Field: this.state.sortField1,
        Sortby: this.state.sortedBy1,
        Search: this.state.search1,
        Type: "User",
        Role: this.state.Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/GetBookedlMeetingStatusHistory",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((reshistory) => {
        this.setState({ data1: reshistory.data.pagedata });
        this.setState({ rows1: reshistory.data.pagedata });
        this.setState({ rlen1: reshistory.data.totalcount });
        this.setState({ flen1: reshistory.data.totalcount });
        this.setState({ countpage1: reshistory.data.pagecount });
      });
    } else {
      SerchBox = true;
      this.setState({ sflag1: true });
      var SortField = Field;
      var SortBy;
      if (this.state.sortedBy1 == 1) {
        SortBy = -1;
        this.setState({ sortedBy1: -1 });
      } else {
        SortBy = 1;
        this.setState({ sortedBy1: 1 });
      }
      this.setState({ sortField1: Field });
      var InputParameter = {
        ClientID: this.state.ClientID,
        EventID: this.state.meetingid,
        Page: this.state.page1,
        RowsPerPage: this.state.rowsPerPage1,
        Sort: true,
        Field: this.state.sortField1,
        Sortby: this.state.sortedBy1,
        Search: this.state.search1,
        Type: "User",
        Role: this.state.Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/GetBookedlMeetingStatusHistory",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((reshistory) => {
        this.setState({ data1: reshistory.data.pagedata });
        this.setState({ rows1: reshistory.data.pagedata });
        this.setState({ rlen1: reshistory.data.totalcount });
        this.setState({ flen1: reshistory.data.totalcount });
        this.setState({ countpage1: reshistory.data.pagecount });
      });
    }
  }

  pikerdatevalue(newvalue) {
    this.setState({ datevalue: newvalue });
  }

  async savebtnmain(e) {
    this.state.errors.Email = null;
    this.state.EmailAvailable = null;
    this.setState({ IsButtonDisabled: true });
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var FinalValidation = await this.FromValidation();
    if (FinalValidation == true) {
      var UTCMeetingDate = moment(this.state.datevalue)
        .format("MM/DD/YYYY HH:mm A")
        .toString();
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Title = document.getElementById("Title").value;
      var Company = document.getElementById("Company").value;
      var Email = document.getElementById("Email").value;
      var LinkedInURL = document.getElementById("LinkedInUrl").value;
      var DirectPhone = document.getElementById("DirectPhone").value;
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
      var CompanyPhone = document.getElementById("CompanyPhone").value;
      var Status = document.getElementById("MeetingStatusID").value;
      var MeetingSourceID = document.getElementById("MeetingSourceID").value;
      var EventCreatedDt = document.getElementById("date2").value;
      var TimeZoneID = document.getElementById("MeetingTimeZoneID").value;
      var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
      var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
      var ContractValue = document.getElementById("ContractValue").value;
      var Industry = document.getElementById("Industry").value;
      var MeetingSetbyID = document.getElementById("MeetingSetbyID").value;
      var SeniorityID = document.getElementById("SeniorityMeetingID").value;
      var ExternalAccountIDs =
        document.getElementById("ExternalAccountID").value;
      var ExternalContactIDs =
        document.getElementById("ExternalContactID").value;
      var Website = document.getElementById("Website").value;
      var ExternalField1 = document.getElementById("ExternalField1").value;
      var ExternalField2 = document.getElementById("ExternalField2").value;
      var ExternalField3 = document.getElementById("ExternalField3").value;
      //use later
      // var ExternalField1 = document.getElementById("ExternalField1").value;
      // var ExternalField2 = document.getElementById("ExternalField2").value;
      // var ExternalField3 = document.getElementById("ExternalField3").value;
      // console.log(this.state.datevalue.toUTCString())
      // console.log(this.state.EventID)

      var BookedMeetingCustomFieldValueArray = [];
      this.state.meetingCustomField.map((value) => {
        let customval = document.getElementById(value._id)?.value || "";

        let customvalid = value._id;

        if (customval.length !== 0) {
          BookedMeetingCustomFieldValueArray.push({
            customval,
            customvalid,
          });
        } else {
          // Add a default value for blank input fields
          BookedMeetingCustomFieldValueArray.push({
            customval: "",
            customvalid,
          });
        }
      });

      var TZdata = {
        TimeZoneID: TimeZoneID,
        Role: this.state.Role,
      };
      let TZdetails = await Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetTimezoneMeeting",
        method: "POST",
        data: TZdata,
      });
      var FieldID = null;
      var FieldName = "";

      if (TZdetails.data.length > 0) {
        FieldID = TZdetails.data[0]?.FieldID;
        FieldName = TZdetails.data[0]?.FieldName;
      }

      var data = {
        EventID: this.props.location.state.data,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Title: Title,
        Company: Company,
        Website: Website,
        LinkedInUrl: LinkedInURL,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        Status: this.state.StatusName,
        StatusID: Status,
        MeetingSourceID: MeetingSourceID,
        MeetingOwnerID: MeetingOwnerID,
        TimeZoneID: TimeZoneID,
        ContractValue: ContractValue,
        Industry: Industry,
        ResponderColdCallersType: MeetingSetbyID.charAt(0),
        ResponderColdCallersID: MeetingSetbyID,
        IsReminderMailSent: this.state.checked,
        RecordingAudioPath: null,
        ReminderEmailAccountID: ReminderEmailAccount,
        SeniorityID: SeniorityID,
        EventStartDtByTimeZone: UTCMeetingDate,
        EventStartDt: UTCMeetingDate,
        EventCreatedDt: EventCreatedDt,
        ExternalAccountID: ExternalAccountIDs,
        ExternalContactID: ExternalContactIDs,
        ExternalField1: ExternalField1,
        ExternalField2: ExternalField2,
        ExternalField3: ExternalField3,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
        OldStatusId: this.state.oldstatusid,
        CustomMeetingDetails: BookedMeetingCustomFieldValueArray,
        FieldID: FieldID,
        FieldName: FieldName,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Booked Meeting
              <br />
              Booked meeting updated successfully.
            </div>
          );
          if (this.props.location.state["Pagename"] == "Meeting") {
            history.push({
              pathname: "/cviewcontacts",
              state: { data: this.props.location.state?.dataprospect },
            });
          } else if (this.props.location.state["Pagename"] == "ClientMeeting") {
            history.push("/clientmeetings");
          } else if (this.props.location.state["Pagename"] == "coppotunities") {
            history.push("/copportunities");
          } else if (this.props.location.state["Pagename"] == "cRescheduling") {
            history.push("/creschedules");
          } else if (
            this.props.location.state["Pagename"] == "AccountContact"
          ) {
            history.push({
              pathname: "/ceditaccount",
              state: { data: this.props.location.state.AccountData },
            });
          } else if (this.props.location.state["Pagename"] == "kpiMeeting") {
            history.push("/ckpis");
          } else {
            history.push("/cbookedmeetings");
            // history.push({pathname:"/bookedmeetings", state:{data:id,Pagename:""}});
          }
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
  }
  // To set default
  handleChangesource(e) {
    this.setState({ defaultselectsource: e.target.defaultselect });
  }
  handleChangetimezone(e) {
    this.setState({ defaultselecttimezone: e.target.defaultselecttimezone });
  }
  handleChangeseniority(e) {
    this.setState({ defaultselectseniority: e.target.defaultselectseniority });
  }
  handleChangestatus(e) {
    this.setState({ defaultselectstatus: e.target.defaultselectstatus });
    var str_in = {
      MeetingStatusID: e.target.value,
      ClientID: this.state.ClientID,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingStatusName",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        // console.log(res.data.data[0].Status);
        this.setState({ StatusName: res.data.data[0].Status });
      } else {
        toast.error(res.data.Message);
      }
    });
  }
  handleChangeowner(e) {
    this.setState({ defaultselecttimeowner: e.target.defaultselecttimeowner });
  }
  handleChangeSetby(e) {
    this.setState({
      defaultselectmeetingsetby: e.target.defaultselectmeetingsetby,
    });
  }
  handleChangeReminderEmail(e) {
    this.setState({
      defaultselectreminderemail: e.target.defaultselectreminderemail,
    });
  }

  handleChangecheck = (e) => {
    const { checked } = e.target;
    this.setState({
      checked: checked,
    });
  };

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        {/* EDIT AND NOTES PAGE  */}
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="paddcols">
              <div className="row borderbtnm ">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header">
                    Edit Booked Meeting
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 pb-4 text-right">
                  <button
                    className="btn btngroup1 m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air mr-1"
                    onClick={this.savebtnmain}
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i class="la la-save"></i>
                    <span className="Mobile_button">Save</span>
                  </button>

                  <a
                    className="btn btngroup2 m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    onClick={this.backbtn}
                  >
                    <i class="la la-arrow-circle-left"></i>
                    <span className="Mobile_button">Back</span>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Contact Info
                </h3>
              </div>
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("FirstName")
                        }
                        // value={this.state.fields["FirstName"]}
                      />
                      <span
                        id="FirstName_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter first name.
                      </span>
                      <span style={{ color: "red" }}>
                        {this.state.errors["FirstName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("LastName")
                        }
                        // value={this.state.fields["LastName"]}
                      />
                      <span
                        id="LastName_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter last name.
                      </span>
                      <span style={{ color: "red" }}>
                        {this.state.errors["LastName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                        onBlur={() => this.HandleChangeotherFieldBlur("Title")}
                        // value={this.state.fields["Title"]}
                      />
                      <span
                        id="Title_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter title.
                      </span>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("Company")
                        }
                        //value={this.state.fields["Company"]}
                      />
                      <span
                        id="Company_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter company.
                      </span>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Company"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                        onBlur={this.HandleChangeBlur}
                        // value={this.state.fields["Email"]}
                      />
                      <span
                        id="Email_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter email.
                      </span>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInUrl"
                        name="LinkedInUrl"
                        type="text"
                        // onChange={this.handleChange.bind(this, "LinkedInUrl")}
                        // value={this.state.fields["LinkedInUrl"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhone"
                        name="DirectPhone"
                        type="text"
                        // onChange={this.handleChange.bind(this, "DirectPhone")}
                        value={this.state.fields["DirectPhone"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone ext"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhoneExt"
                        name="DirectPhoneExt"
                        type="text"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "DirectPhoneExt"
                        // )}
                        // value={this.state.fields["DirectPhoneExt"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        type="text"
                        // onChange={this.handleChange.bind(this, "CompanyPhone")}
                        // value={this.state.fields["CompanyPhone"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        id="Website"
                        name="Website"
                        type="text"
                        onBlur={this.handleChangeWebsitecheck}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Website"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Account Name
                    </label>
                    <div className="col-lg-7">
                      <label>{this.state.AccountNamea}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Meeting Info
                </h3>
              </div>
              <div className="row  ">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Status:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactSourceID must be a number."
                        id="MeetingStatusID"
                        name="MeetingStatusID"
                        value={this.state.defaultselectstatus}
                        onChange={this.handleChangestatus}
                      >
                        {this.state.meetingstatus.map((value) => (
                          <option value={value?._id}>{value.Status}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Source:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactSourceID must be a number."
                        id="MeetingSourceID"
                        name="MeetingSourceID"
                        value={this.state.defaultselectsource}
                        onChange={this.handleChangesource}
                      >
                        {this.state.meetingsource.map((value) => (
                          <option value={value?._id}>{value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingSourceID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Set Date :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="date2"
                        name="date2"
                        placeholder="Goal"
                        type="date"
                        onChange={this.handleChange.bind(this, "date2")}
                        value={this.state.fields["date2"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Set By:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field MeetingSetbyID must be a number."
                        id="MeetingSetbyID"
                        name="MeetingSetbyID"
                        value={this.state.defaultselectmeetingsetby}
                        onChange={this.handleChangeSetby}
                      >
                        {this.state.meetingsetbyResponder.length > 0
                          ? this.state.meetingsetbyResponder.map((value) => (
                              <option value={"R" + value?.value}>
                                {value?.label}
                              </option>
                            ))
                          : ""}
                        {this.state.meetingsetbyColdCallers.length > 0
                          ? this.state.meetingsetbyColdCallers.map((value) => (
                              <option value={"C" + value?.value}>
                                {value?.label}
                              </option>
                            ))
                          : ""}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingSetbyID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Date:
                    </label>
                    <div className="col-lg-7 timedatepikker">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          className="form-control m-input"
                          value={this.state.datevalue}
                          onChange={this.pikerdatevalue}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Time Zone
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactOwnerID must be a number."
                        id="MeetingTimeZoneID"
                        name="MeetingTimeZoneID"
                        value={this.state.defaultselecttimezone}
                        onChange={this.handleChangetimezone}
                      >
                        {this.state.meetingtimezone.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingTimeZoneID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Owner:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactOwnerID must be a number."
                        id="MeetingOwnerID"
                        name="MeetingOwnerID"
                        value={this.state.defaultselecttimeowner}
                        onChange={this.handleChangeowner}
                      >
                        {this.state.meetingowner.map((value) => (
                          <option value={value._id}>{value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingOwnerID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Reminder Email Account:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ClientPOCID must be a number."
                        id="ClientPOCID"
                        name="ClientPOCID"
                        value={this.state.defaultselectreminderemail}
                        onChange={this.handleChangeReminderEmail}
                      >
                        {this.state.meetingaccount.map((value) => (
                          <option value={value._id}>
                            {value.SMTPFromEmail}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientPOCID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contract Value :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ContractValue"
                        name="ContractValue"
                        type="text"
                        onChange={this.handleChange.bind(this, "ContractValue")}
                        value={this.state.fields["ContractValue"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Industry :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Industry"
                        name="Industry"
                        type="text"
                        onChange={this.handleChange.bind(this, "Industry")}
                        value={this.state.fields["Industry"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Account ID :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalAccountID"
                        name="ExternalAccountID"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalAccountID"
                        )}
                        value={this.state.fields["ExternalAccountID"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Contact ID :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ExternalContactID"
                        name="ExternalContactID"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalContactID"
                        )}
                        value={this.state.fields["ExternalContactID"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 1 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField1"
                        name="ExternalField1"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalField1"
                        )}
                        value={this.state.fields["ExternalField1"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 2 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField2"
                        name="ExternalField2"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalField2"
                        )}
                        value={this.state.fields["ExternalField2"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 3 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField3"
                        name="ExternalField3"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalField3"
                        )}
                        value={this.state.fields["ExternalField3"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center"></div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Seniority :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field SeniorityMeetingID must be a number."
                        id="SeniorityMeetingID"
                        name="SeniorityMeetingID"
                        value={this.state.defaultselectseniority}
                        onChange={this.handleChangeseniority}
                      >
                        {this.state.meetingseniority.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["SeniorityMeetingID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="">
                    <label class="check_bx">
                      <input
                        id="include_source"
                        type="checkbox"
                        onChange={(e) => this.handleChangecheck(e)}
                        defaultChecked={this.state.checked}
                      />
                      <span className="checkmark"></span>
                      Send Reminder
                    </label>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Call Recording
                    </label>
                    <div className="col-lg-7">
                      <input
                        type="file"
                        id="uploadRecording"
                        accept=".mp3"
                        style={{ outline: "none" }}
                        //     onChange={this.handleUpload.bind(this, "coverimage")}
                        // className="{{ $errors->has('email') ? 'alert alert-danger' : '' }}"
                        autocomplete="off"
                      />
                      <label
                        id="recFileName"
                        class="m--hide break-content"
                      ></label>
                      <input
                        id="RecordingFilePath"
                        name="RecordingFilePath"
                        type="hidden"
                        value=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Status Dates
                </h3>
              </div>
              <div className="row ">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Opportunity Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="datebookstate"
                        name="datebookstate"
                        type="date"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "BookedMeetingDate"
                        // )}
                        onChange={this.handleChange.bind(this, "datebookstate")}
                        value={this.state.fields["datebookstate"]}
                        // value={this.state.fields["BookedMeetingDate"]}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Closed Won Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ClosedWonDate"
                        name="ClosedWonDate"
                        type="text"
                        onChange={this.handleChange.bind(this, "ClosedWonDate")}
                        value={this.state.fields["ClosedWonDate"]}
                      />
                    </div>
                  </div>
                </div> */}
              {/* <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Closed Lost Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ClosedLostDate"
                        name="ClosedLostDate"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ClosedLostDate"
                        )}
                        value={this.state.fields["ClosedLostDate"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Unqualified Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="UnqualifiedDate"
                        name="UnqualifiedDate"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "UnqualifiedDate"
                        )}
                        value={this.state.fields["UnqualifiedDate"]}
                      />
                    </div>
                  </div>
                </div> */}
              {/* </div>  */}

              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Custom Meeting Field
                </h3>
              </div>

              <div className="row">
                {this.state.meetingCustomField?.map((value, index) => {
                  const customField = this.state.meetingCustomfieldbyid?.find(
                    (val) => val.CustomFieldID === value._id
                  );

                  return (
                    <div className="col-lg-6 boxsinput_group" key={index}>
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4">{value.FieldName}</label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id={value?._id}
                            defaultValue={customField?.FieldValue}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* notes */}
        <div className="bodyhome1 ">
          <div className="paddcols">
            <div className="row">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
              </div>
              <div className="col text-right">
                <button
                  onClick={() => {
                    this.setState({ ShowNotePopup: true });
                  }}
                  className="btn btngroup m-btn "
                >
                  <i class="la la-plus la-icon-font-size-13"></i>
                  <span className="Mobile_button">Add</span>
                </button>
                <Popup open={this.state.ShowNotePopup}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-3 px-3">
                          <h4 className="mb-0">Note</h4>
                        </div>
                        <div className="modal-body p-5">
                          <div class="row mb-3">
                            <label class="col-lg-2 col-form-label text-left">
                              Title
                            </label>
                            <div class="col-lg-10">
                              <input
                                class="form-control m-input"
                                data-val="true"
                                data-val-remote="Title already exist."
                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                data-val-required="Please enter title"
                                id="Title22"
                                name="Title"
                                placeholder="Enter title"
                                type="text"
                                onBlur={this.handleChangeNotes.bind(
                                  this,
                                  "Title22"
                                )}
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["Title22"]}
                              </span>
                              {this.state.TitleAvailable && (
                                <span style={{ color: "red" }}>
                                  Title already exist.
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="row">
                            <label class="col-lg-2 col-form-label text-left">
                              Note
                            </label>
                            <div class="col-lg-10">
                              <textarea
                                class="form-control m-input minheight390"
                                data-val="true"
                                data-val-remote="Note already exist."
                                data-val-required="Please enter note"
                                id="Notes22"
                                name="Notes"
                                placeholder="Enter note"
                                type="text"
                                // onBlur={this.handleChangeNotes.bind(
                                //   this,
                                //   "Notes22"
                                // )}
                              >
                                {" "}
                              </textarea>
                              <span style={{ color: "red" }}>
                                {this.state.errors["Notes22"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="modal-content bordernone text-right">
                          <div class="row">
                            <div class="col-lg-12 pull-right">
                              <button
                                id="backtolist"
                                class="btn btn-secondary mr-2"
                                onClick={() => {
                                  this.setState({ ShowNotePopup: false });
                                }}
                                disabled={this.state.IsButtonDisabled}
                              >
                                cancel
                              </button>
                              <button
                                id="submitSave"
                                class="btn btn-primary btn-lightgreen mr-1"
                                value="Save"
                                onClick={this.savebtn}
                                disabled={this.state.BtnDisabledSave}
                              >
                                <i class="la la-save"></i> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.chengeRowSelected}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                    {/* <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option> */}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="search"
                    onKeyPress={(event) => this.requestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData("Note");
                          }}
                        >
                          Notes
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData("CreatedDate");
                          }}
                        >
                          Created Date
                        </a>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.rows.map((row) => (
                        // row.MeetingID!=null?(
                        <TableRow>
                          <TableCell>{row.Title}</TableCell>
                          <TableCell>{row.Note}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.editspage(row._id);
                              }}
                            >
                              <Popup
                                trigger={
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-3 px-3">
                                          <h4 className="mb-0">Note</h4>
                                        </div>
                                        <div className="modal-body p-5">
                                          <div class="row mb-3">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Title
                                            </label>
                                            <div class="col-lg-10">
                                              <input
                                                class="form-control m-input"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                data-val-required="Please enter title"
                                                id="Title1"
                                                name="Title1"
                                                placeholder="Enter title"
                                                type="text"
                                                onBlur={this.handleChangeNote.bind(
                                                  this,
                                                  "Title1"
                                                )}
                                                // value={
                                                //   this.state.fields1["Title1"]
                                                // }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.state.errors1["Title1"]}
                                              </span>
                                              {this.state.TitleAvailable && (
                                                <span style={{ color: "red" }}>
                                                  Title already exist.
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Note
                                            </label>
                                            <div class="col-lg-10">
                                              <textarea
                                                class="form-control m-input minheight390"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-required="Please enter title"
                                                id="Notes"
                                                name="Notes"
                                                placeholder="Enter note"
                                                type="text"
                                                // onChange={this.handleChangeNote.bind(
                                                //   this,
                                                //   "Notes"
                                                // )}
                                                // value={
                                                //   this.state.fields1["Notes"]
                                                // }
                                              >
                                                {" "}
                                              </textarea>
                                              <span style={{ color: "red" }}>
                                                {this.state.errors1["Notes"]}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-content bordernone text-right">
                                          <div class="row">
                                            <div class="col-lg-12 pull-right">
                                              <a
                                                id="backtolist"
                                                class="btn btn-secondary mr-2"
                                                onClick={() => {
                                                  console.log("modal closed ");
                                                  close();
                                                }}
                                              >
                                                cancel
                                              </a>
                                              <button
                                                id="submitupdate"
                                                class="btn btn-primary btn-lightgreen mr-1"
                                                href="javascript:void(0);"
                                                value="Save"
                                                disabled={
                                                  this.state.BtnDisabledUpdate
                                                }
                                                onClick={(e) => {
                                                  this.saveupdatebtn(e);
                                                  close();
                                                }}
                                                //onClick={this.saveupdatebtn}
                                              >
                                                <i class="la la-save"></i> Save
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </a>
                            <a
                              // href="#"
                              className="btn-eyesicon"
                              onClick={() => {
                                this.DeleteClientName(row._id);
                              }}
                            >
                              <i class="la flaticon-delete-1 delete-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                        // ):(<></>)
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.countpage}
                  onChange={this.handleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen == 0
                      ? 0
                      : (this.state.page - 1) * this.state.rowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.page * this.state.rowsPerPage > this.state.rlen
                      ? this.state.rlen
                      : this.state.page * this.state.rowsPerPage}{" "}
                    of {this.state.rlen} entries (filtered from{" "}
                    {this.state.flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen == 0
                      ? 0
                      : (this.state.page - 1) * this.state.rowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.page * this.state.rowsPerPage > this.state.rlen
                      ? this.state.rlen
                      : this.state.page * this.state.rowsPerPage}{" "}
                    of {this.state.rlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.countpage}
                    onChange={this.handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* status History*/}
        <div className="bodyhome1 ">
          <div className="paddcols">
            <div className="row">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Meeting Status History
                </h3>
              </div>
              <div className="col text-right"></div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.chengeRowSelected1}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search1"
                    id="search1"
                    onKeyPress={(event) => this.requestSearch1(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData1("MeetingStatusID");
                          }}
                        >
                          Old Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData1("OldMeetingStatusID");
                          }}
                        >
                          New Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            this.SortData1("ChangeDate");
                          }}
                        >
                          Change Date
                        </a>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows1?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.rows1 &&
                      this.state.rows1?.map((row) => (
                        <TableRow>
                          <TableCell>
                            {row.p2.length == 0 ? " " : row.p2[0]?.Status}
                          </TableCell>
                          <TableCell>
                            {row.p1.length == 0 ? " " : row.p1[0]?.Status}
                          </TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.ChangeDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.sflag1 ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.countpage1}
                  onChange={this.handleChangePage1}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen1 == 0
                      ? 0
                      : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                        1}{" "}
                    to{" "}
                    {this.state.page1 * this.state.rowsPerPage1 >
                    this.state.rlen1
                      ? this.state.rlen1
                      : this.state.page1 * this.state.rowsPerPage1}{" "}
                    of {this.state.rlen1} entries (filtered from{" "}
                    {this.state.flen1} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen1 == 0
                      ? 0
                      : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                        1}{" "}
                    to{" "}
                    {this.state.page1 * this.state.rowsPerPage1 >
                    this.state.rlen1
                      ? this.state.rlen1
                      : this.state.page1 * this.state.rowsPerPage1}{" "}
                    of {this.state.rlen1} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.countpage1}
                    onChange={this.handleChangePage1}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <footer className="m-footer">
          <span className="m-footer__copyright">
            2021 ©{" "}
            <a href="https://saleshive.com/" className="m-link">
              SalesHive
            </a>
            . All Rights Reserved.
          </span>
        </footer>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditBookedMeetingPage = connect(
  mapState,
  actionCreators
)(CEditBookedMeetingPage);
export { connectedCEditBookedMeetingPage as CEditBookedMeetingPage };
