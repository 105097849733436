import React, { useEffect } from "react";
const Moment = require("moment");
import Axios from "axios";
import Swal from "sweetalert2";

import { CSVReader, CSVDownloader } from "react-papaparse";
const Fileupload = require("../../../_helpers/fileupload");
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
export default function GenericEmailDomainTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Reset,SetReset] = React.useState(false)
  const [Files,SetFiles] = React.useState([])
  const [ExportData,SetExportData] = React.useState([])
  const [CsvData, SetCsvData] = React.useState([])
  const [Dropboxdata,SetDropboxdata] = React.useState([])
  const [Filename,SetFilename] = React.useState("")
  const [Email,SetEmail] = React.useState(null)
  const [CName,SetCName] = React.useState("")
  const [TotalResult,SetTotalResult] = React.useState(0)
  const [TotalDuplicate,SetTotalDuplicate] = React.useState(0)
  const [TotalError,SetTotalError] = React.useState(0)
  const [TotalSuccess,SetTotalSuccess] = React.useState(0)
  const [TotalDisplayCount,SetTotalDisplayCount] = React.useState(0)
  const [UpdateKey,SetUpdateKey] = React.useState(0)
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(false)
  useEffect( () => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }

    GlobalBounceContactGet(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);
    var InputParameter = {
      UserID: GetUserData.ParentUserID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceExport",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      
        SetExportData(Res.data);

    });
  }, [Search, Page, RowsPerPage]);

  
  //Get Global Bounce Contact list
  const GlobalBounceContactGet = async(CID,UID,URole) => {
    SetIsLoading(true)

    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const GenricEmailDomainList = await Axios({
      url:CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceList",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

  //search for record
  const RequestSearch = (Event) => {

    if (Event.key === "Enter") {
      SetIsLoading(true)
      var SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      SetIsLoading(false)

      }else{
        SetSearch(SearchedVal);
        SetPage(1);
        SetIsLoading(false)
      }
    }
  };
      
        const HandleChangePage = (Event, NewPage) => {
      SetIsLoading(true)

        if(NewPage==Page){
          SetPage(NewPage);
          SetIsLoading(false)

        }else{
          SetPage(NewPage);
           SetIsLoading(false)
        }
      };
      
      const ChangeRowSelected = (Event) => {
      SetIsLoading(true)

        SetRowsPerPage(Number(Event.target.value));
        SetPage(1);
        SetIsLoading(false)
      };

//get sort field data
const SortData = (Field) => {
  SetIsLoading(true)

  var SearchedVal = document.getElementById("search").value;

  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      SerchBox: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    
    const GenricEmailDomainList = Axios({
      url:
        CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceList",
      method: "POST",
      data: InputParameter,
    });
    GenricEmailDomainList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  } else {
    SetIsLoading(true)

    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var SearchedVal = document.getElementById("search").value;
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      SerchBox: SerchBox,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    
    const GenricEmailDomainList = Axios({
      url:
        CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceList",
      method: "POST",
      data: InputParameter,
    });
    GenricEmailDomainList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  }
};

//edit page
  const EditBtn = (ID) => {
    history.push("/editglobalbouncelist", ID);
  };

  //delete new code
  const DeleteBtn = (ID,Email) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Global Bounce Contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          GlobalBounceContactID: ID,
          ClientID:ClientID,
          Email:Email,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          UserID: UserID,
        };
        Axios({
          url:CommonConstants.MOL_APIURL +"/global_bounce/GlobalBounceDelete",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Global Bounce Contact deleted successfully.",
                "success"
              );
              SetSearch("")
              SetPage(1)
              SetIsLoading(true)
              document.getElementById("search").value = ""
              GlobalBounceContactGet(ClientID,UserID,Role);
              history.push("/globalbouncelist")
              props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

    //Add function
    const  AddGlobalBounceList =() =>{
      history.push("/addglobalBounceList");
    }
  
    const hideCSVHeaderOptions = () => {
      var $select = $(".csvcontent");
      $select.find("option").show();
      $select.each(function () {
        var $this = $(this);
        var value = $this.val();
        var $options = $this
          .parents("#divContactSelection")
          .find(".csvcontent")
          .not(this)
          .find("option");
        var $option = $options.filter('[value="' + value + '"]');
        if (value) {
          $option.hide();
        }
      });
    };
  
    $("select").on("change", function () {
        
      var $this = $(this);
      var value = $this.val();
      var Name = $(this).attr("id");
      if (Name == "Email") {
        SetEmail(value);
        
      } 
  
      hideCSVHeaderOptions();
    });
  
    // import csv show div
    const ImportBtn =() =>{
      document.getElementById("import_csv").style.display = "block"
      document.getElementById("map_csv").style.display = "none";
      document.getElementById("display_error_count").style.display = "none"
      SetCsvData([])
      SetReset(!Reset)
    }
  
    //export btn
    const  ExportBtn =()=>{
      toast.success(<div>
        GlobalBounceContact<br/>
        Data exported successfully.
      </div>)
    }
  
    // cancel btn
   const CancelBtn =()=>{
      document.getElementById("import_csv").style.display = "none";
      document.getElementById("map_csv").style.display = "none";
      document.getElementById("display_error_count").style.display = "none"
      SetCsvData([])
      SetReset(true)
      // this.setState({ Reset: true });
  
    }
    // handle on drop
    const HandleOnDrop =(Data, FileInfo)=> {
      
      var Filename = FileInfo.name;
      // this.setState({ Files: FileInfo });
      SetFiles(FileInfo)
      var sep = Filename.split(".");
      if (sep[1] != "csv") {
        SetReset(true)
        // this.setState({ Reset: true });
        Resetcsv();
      } else {
        var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
        SetFilename(csvfile)
        const filteredData = Data.filter(row => !row.data.every(item => item.trim() === ''))
        // this.setState({ Filename: csvfile });
        SetCsvData(filteredData)
        // this.setState({ CsvData: Data });
        SetDropboxdata(filteredData[0].data)
        // SetDropboxdata(Data[0].data)
        // this.setState({ Dropboxdata: Data[0].data });
        document.getElementById('Email').value="Not Mapped";
      }
    }
  
      // map csv
      const MapCsv = ()=> {
        
        if (CsvData.length != 0) {
          document.getElementById("map_csv").style.display = "block";
          document.getElementById("map_csv").style.cursor= "default";
          document.getElementById("Email").disabled = false;
          document.getElementById("SaveCSV").disabled = false;
         Dropboxdata.map((dddd, index) => {});
         SetReset(!Reset);
       
        } else {
          SetReset(!Reset);
          document.getElementById("Email").disabled = false;
          document.getElementById("SaveCSV").disabled = false;
          document.getElementById("map_csv").style.display = "none";
          document.getElementById("display_error_count").style.display = "none"

        }
        hideCSVHeaderOptions();
      }

    // handle remove
    const  HandleOnRemoveFile =(data) =>{}
  
    // handle error
    const  HandleOnError =(err, file, inputElem, reason) => {}
  
    // reset set
    const Resetcsv = ()=> {
        // this.setState({ Reset: true });
        SetReset(true)
      }
  
        // save csv
   const Savecsv =() => {
      SetIsLoading(true)
      var Details = GetUserDetails();
      var Email = document.getElementById("Email").value;
      if(Email == "Not Mapped"){
          toast.error(<div>Global Bounce List <br/>Email not mapped!</div>);
          SetIsLoading(false)
      }else{
        // var CsvData = CsvData;
        
      Fileupload.getBase64(Files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
  
        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/GlobalBounceList",
          File: _base64,
          ImageName: Filename,
          FileData: CsvData,
          CreatedDt:new Date(),
          UserID:UserID,
          CreatedBy:CUserID,
          ClientID:ClientID,
          Email:Email
        };
        
        Axios({
          url: CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceListImport",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if(res.data.StatusMessage == "SUCCESS"){
            SetUpdateKey(UpdateKey+1)
            // this.setState({UpdateKey:UpdateKey+1});
              toast.success(
                  <div className="toastsize">Global Bounce List<br/>
                  email added successfully
                  </div>);
                  SetTotalResult(res.data.Total)
                  SetTotalDuplicate(res.data.TotalDuplication)
                  SetTotalError(res.data.TotalError)
                  SetTotalSuccess(res.data.TotalsSuccess)

                    SetCsvData([])
                  document.getElementById("map_csv").style.cursor= "not-allowed";
                  document.getElementById("Email").disabled = true;
                  document.getElementById("display_error_count").style.display = "block"
                  document.getElementById("SaveCSV").disabled = true;
                  SetIsLoading(false)
                  document.getElementById("map_csv").style.display = "none";
                  document.getElementById("import_csv").style.display = "none";

                  
                  // this.setState({Reset:true});
                  SetReset(!Reset)
                  
          }else{
            toast.error(res.data.Message)
            SetIsLoading(false)
  
          }
   
        });
      });
      }
      
    }

  return (
    <div>
    {
      IsLoading == true ?  <div id="hideloding" className="loding-display">
        <img src={loadingicon} /> 
       </div> : null
    }
       
       <div className="row">
              <div className="col">
                <h4 className="headertitle float-left pl-0">Global Bounce List</h4>
                <div className="listing-li float-right">
                  <ul>
                    <li>
                      <a
                        onClick={AddGlobalBounceList}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={ImportBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li>
                      <CSVDownloader className="px-0"
                        data={ExportData}
                        filename={CName + "Global-Bounce-list"}
                        bom={true}
                      >
                        <a onClick={ExportBtn} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                          <i class="la la-icon-font-size-13 la-download"></i>
                          Export
                        </a>
                      </CSVDownloader>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols">
            {/* Import Csv for Black list*/}
            <div
              className="portletbody"
              id="import_csv"
              style={{ display: "none" }}
            >
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                    Import Global Bounce List
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                   accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>
                        Add Global Bounce List data from csv file to import.
                      </p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2">
                  <div className="alignright">
                    <a onClick={MapCsv} className="btn btn-primary btnColor">
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a onClick={CancelBtn} className="btn btn-secondary">
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Global Bounce div */}

            <div className="portletbody portletshadow p-4 m-1 map_attribute" id="map_csv" style={{display:"none"}}>
              <div className="row col-xs-12">
                <h4 className="headertitle float-left pl-0">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div class="form-group m-form__group row mx-0">
                  <div class="col-lg-2 nopadding">
                    <div>
                      <select
                          id="dbColumn3"
                        class="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Email">
                          Email
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-1 text-center">
                    <span class="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div class="col-lg-2 nopadding">
                    <div>
                      <select
                        id="Email"
                        class="csvcontent form-control  m-input"
                        autocomplete="off"
                        // onChange={handleChange}
                        value={  Email == null ? Dropboxdata.indexOf("Email"): Email
                  }
                      >
                        <option value="Not Mapped">Not Mapped</option>
                        {Dropboxdata.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-xs-12 border-top pt-4 mt-2">
                <button
                id="SaveCSV"
                  onClick={Savecsv}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </button>
              </div>
              </div>
            </div>

            {/* total display error */}
            <div className="portletbody px-4" id="display_error_count" style={{display:"none"}}>
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left">Results</h4>
                </div>

                <div className="col-xs-12">
                  <div class="row colfive">
                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title mb-2">
                              <span class="box-main-title font-primary">
                                {TotalResult}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title mb-2">
                              <span class="box-main-title font-success">
                                {TotalSuccess}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total success</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title mb-2">
                              <span class="box-main-title font-danger">
                                {TotalError}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total error</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title mb-2">
                              <span class="box-main-title font-warning">
                                {TotalDuplicate}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total Duplicate</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              value={RowsPerPage}
              className="form-control form-control-sm">
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"/>
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}>Email</a>
                </TableCell>
                <TableCell>
                <a
                    onClick={() => {
                      SortData("CreatedDt");
                    }}>Created Date</a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? ( <p>No data available in table</p> ) :
                Rows?.map((row) => (
                <TableRow key={row._id}> 
                  <TableCell>{row?.Email}</TableCell>
                  <TableCell>
                    {row.CreatedDt == null ? "" : Moment(new Date(row?.CreatedDt).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        EditBtn(row?._id);
                      }}
                      className="btn-eyesicon"
                      title="Edit"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row?._id,row?.Email);
                      }}
                      className="btn-eyesicon"
                      title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
    </div>
  );
}
