import React,{useEffect} from 'react';
const moment=require('moment');
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CallRecordings from '../../_components/user/ColdCalling/CallRecordings';
import { GetUserDetails } from "../../_helpers/Utility";


class CallRecordingsPage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          FileName: "",
          FromDate:"",
          ToDate:""
        };
    this.SaveBtn = this.SaveBtn.bind(this);
        
      }
    
      componentDidMount() {
        var Details = GetUserDetails();
        if (Details != null) {
          this.state.ClientID = Details.ClientID;
          this.state.UserID = Details.ChildUserID;
        }
        document.getElementById('from-date').valueAsDate=new Date(moment().subtract(14, "days"));
        document.getElementById('to-date').valueAsDate=new Date();
                let FromDates=document.getElementById('from-date').value;
                let ToDates=document.getElementById('to-date').value;
       this.setState({FromDate:FromDates,ToDate:ToDates})
      }

    SaveBtn(){
        let FromDate=document.getElementById('from-date').value;
        let ToDate=document.getElementById('to-date').value;
        this.setState({FromDate:FromDate,ToDate:ToDate})
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome"> 
                <div className="row">
                        <div className="col">
                            <h4 className="headertitlepad float-left py-4 mt-0">Call Recordings	</h4>
                        </div>
                        <div className="col padright pt-3">
                            <div className="btn-group">
                                <input id="fromtab" name="fromtab" type="hidden" value="" />
                                <input className="form-control datepicker" id="from-date" name="from-date" type="date"  autocomplete="off" />
                                <input className="form-control datepicker" id="to-date" name="to-date" type="date" autocomplete="off" />
                                <button id="aCompanyState" className="btn btn-primary btnColor m-btn btn-radius" onClick={this.SaveBtn} autocomplete="off">
                                        <i className="la flaticon-diagram"></i>
                                        <span>
                                            Analyze
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div> 

                    <div className="whiterow">
                        <div className="row px-3">
                            <div className="col pt-2">
                                    <CallRecordings ToDate={this.state.ToDate} FromDate={this.state.FromDate} />
                            </div>
                        </div>    
                    </div>
                </div> 
                </div> 
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCallRecordingsPage = connect(mapState, actionCreators)(CallRecordingsPage);
export { connectedCallRecordingsPage as CallRecordingsPage };