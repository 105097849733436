import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Axios from "axios";
import { history } from "../../../_helpers";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import Footer from "../../../_components/user/footer/footer";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import loadingicon from "../../../images/loading.gif";

class AddObjectionsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      Errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      ObjectionsAvailble: null,
      IsExist: false,
      IsButtonDisabled: false,
      IsLoading:false
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = "Add | SalesHive";
  }
  // form validation
  async FormValidation() {
    let formIsValid = true;
    var Objection = document.getElementById("Objection").value.trim();
    var IsExist = await this.CheckExistsObjection(Objection);
    let Errors = {};
    if (IsExist == "BlankData" && Objection == "") {
      formIsValid = false;
      Errors["Objection"] = "Please enter objection";
    }
    if (IsExist == "ExistObjection") {
      formIsValid = false;
      Errors["Objection"] = "Objection already exist.";
    }
    this.setState({ Errors: Errors });
    return formIsValid;
  }

  backbtn() {
    history.push("/objections");
  }

  // handle change
  HandleChange() {
    var Objection = document.getElementById("Objection").value.trim();
    if (Objection != "" && Objection.indexOf(" ") != "") {
      this.state.Errors.Objection = null;
      this.state.ObjectionsAvailble = null;
      this.CheckExistsObjection(Objection);
    } else {
      this.state.ObjectionsAvailble = null;
      this.state.Errors.Objection = null;
    }
  }

  async HandleChangeBlur(field,e) {
    let Errors = { ...this.state.Errors };
    const value = e.target.value.trim();
    let IsExists;
  
    if (field === "Name") { // Change "Country" to "Name"
      IsExists = await this.CheckExistsObjection(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
  
      if (IsExists === "BlankData" && value === "") {
        Errors.Name = "Please enter objection"; // Change "Country" to "Name"
      } else if (IsExists === "ExistObjection") {
        Errors.Name = "Objection already exist."; // Change "Country" to "Name"
      } else if (IsExists === "AllDone") {
        Errors.Name = null; // Change "Country" to "Name"
      }
    }
  
    this.setState({ Errors });
  }

  // Contact Custom Field Name Check Exits
  async CheckExistsObjection(Objection) {
     this.setState({ IsLoading: true});
    if (Objection != "") {
      var str_in = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Objection: Objection,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/objection/objectionExists",
        method: "POST",
        data: str_in,
      });
      // if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({
            ObjectionsAvailble: resdata.data.Data[0]?.Objection,
          });
           this.setState({ IsLoading: false});;
          return "ExistObjection";
        } else {
           this.setState({ IsLoading: false});;
          return "AllDone";
        }
      // }
    } else {
       this.setState({ IsLoading: false});;
      return "BlankData";
    }
  }

  async savebtn() {
    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      var Objection = document.getElementById("Objection").value.trim();
      var Response = document.getElementById("Response").value.trim();
      var InputDatar = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Objection: Objection,
        Response: Response,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/objection/ObjectionAdd",
        method: "POST",
        data: InputDatar,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Objections <br />
              Objection added successfully.
            </div>
          );
          history.push("/objections");
        } else {
          toast.error(res.data.Message);
           this.setState({ IsLoading: false});;
       
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
       this.setState({ IsLoading: false});;
    }
  }

  render() {
    return (
      <>
          {
        this.state.IsLoading === true ? <>
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        </> : <></>
      }

        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left py-4 mb-0">Add</h4>
              </div>
            </div>

            <div class="paddcols px-3 pt-0">
              <div className="row pt-4 px-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Objection</label>
                    <div class="col-lg-7">
                      <textarea
                        className="form-control m-input"
                        id="Objection"
                        onBlur={this.HandleChangeBlur.bind(this, "Objection")}
                        
                        placeholder="Enter objection"
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Objection"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Response </label>
                    <div class="col-lg-7">
                      <textarea
                        className="form-control m-input"
                        id="Response"
                        placeholder="Enter response"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4 mt-3">
              <div class="col-lg-12 pull-left pt-2">
                <button
                  id="submit"
                  onClick={this.savebtn}
                  class="btn btn-primary btn-lightgreen mr-1"
                  disabled={this.state.IsButtonDisabled}
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.backbtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddObjectionsPage = connect(
  mapState,
  actionCreators
)(AddObjectionsPage);
export { connectedAddObjectionsPage as AddObjectionsPage };
