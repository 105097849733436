import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../../user/Kpis/LastdaysTabing';

// import DomainTable from '../../../_components/clients/slacknotification/DomainTable';
// import ContactTable from '../../../_components/clients/slacknotification/ContactTable';  
 
import ImageUploading from 'react-images-uploading';
import Popup from "reactjs-popup";

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import 'froala-editor/js/plugins.pkgd.min.js';
import Froalaeditor from 'froala-editor'
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  }; 
  const openTermsConditionPopup = () => {
    //console.log("--it's true");
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //console.log("--it's false");
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };


  document.addEventListener('mouseup', function(e) {
      console.log("--popup value--");
      //console.log(popupval);
      if(popupval){
        setpopup(false);
      }
  });
    //const openTermsConditionPopup = false;
  

  return (
    <div className="tabdes"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"> 
            <Tab label="Meeting" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Rescheduling" {...a11yProps(1)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg inpt-0" index={0}>
            <div className="row">
              <div className="col border-bottom">
                  <h4 className="headertitle float-left pl-0">Meeting</h4>   
              </div> 
              <div className="col border-bottom px-5">
                  <div className="listing-li float-right padb-15">
                       
                  </div>
              </div>
            </div>

            <div className="row">
              <div className="col border-bottom"> 
              </div> 
              <div className="col border-bottom px-5">
                  <div className="listing-li float-right padb-15">
                      <ul> 
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-send la-icon-font-size-13"></i>
                            <span>Send Test</span>
                          </a>
                        </li>
                      </ul>
                  </div>
              </div>
            </div>


            <div class="row pt-4 px-4"> 
              <div class="col-lg-12 boxsinput_group pt-4">
                <div class="row">
                  <label class="col-lg-2 view-lable-meet text-right">Channel</label>
                  <div class="col-lg-3">
                    <select class="form-control m-input valid">
                      <option value="">----Select----</option>
                      <option value="1">Slack Channel Internal</option>
                      <option value="2">Slack Channel External</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row">
                  <label class="col-lg-2 view-lable-meet text-right">Subject</label>
                  <div class="col-lg-10">
                    <input class="form-control m-input" id="Name" name="Name" placeholder="Enter category Name" type="text" /> 
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row">
                  <label class="col-lg-2 view-lable-meet text-right">Slack Text</label>
                  <div class="col-lg-10">  
                    <FroalaEditor id="signature"/>                                        
                  </div>
                </div>
              </div>
 
            </div> 

            
            <div class="row mb-5 border-top border-bottom py-4">
                  <div class="col-lg-12 pull-left">
                      <a id="submit" class="btn btn-primary btn-lightgreen mr-1"><i class="la la-save"></i> Save</a>
                  </div>
              </div> 

            <div className='px-4'>
              <div className="row borderbtnm mt-5">
                <div className="col padd-0">
                    <h4 className="headertitlepad float-left">Preview</h4>
                  </div>
              </div>
            </div>
            
        </TabPanel> 
        <TabPanel value={value} className="tagpaddnone whitebg inpt-0" index={1}>
        <div className="row">
              <div className="col border-bottom">
                  <h4 className="headertitle float-left pl-0">Rescheduling</h4>   
              </div> 
              <div className="col border-bottom px-5">
                  <div className="listing-li float-right padb-15">
                       
                  </div>
              </div>
            </div>

            <div className="row">
              <div className="col border-bottom"> 
              </div> 
              <div className="col border-bottom px-5">
                  <div className="listing-li float-right padb-15">
                      <ul> 
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-send la-icon-font-size-13"></i>
                            <span>Send Test</span>
                          </a>
                        </li>
                      </ul>
                  </div>
              </div>
            </div> 

            <div class="row pt-4 px-4">
              <div class="col-lg-12 boxsinput_group pt-4">
                  <div class="row">
                    <label class="col-lg-2 view-lable-meet text-right">Channel</label>
                    <div class="col-lg-3">
                      <select class="form-control m-input valid">
                        <option value="">----Select----</option>
                        <option value="1">Slack Channel Internal</option>
                        <option value="2">Slack Channel External</option>
                      </select>
                    </div>
                  </div>
                </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row">
                  <label class="col-lg-2 view-lable-meet text-right">Subject</label>
                  <div class="col-lg-10">
                    <input class="form-control m-input" id="Name" name="Name" placeholder="Enter category Name" type="text" /> 
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row">
                  <label class="col-lg-2 view-lable-meet text-right">Slack Text</label>
                  <div class="col-lg-10">  
                    <FroalaEditor id="signature"/>                                        
                  </div>
                </div>
              </div> 
              
            </div>

            
            <div class="row mb-5 border-top border-bottom py-4">
                  <div class="col-lg-12 pull-left">
                      <a id="submit" class="btn btn-primary btn-lightgreen mr-1"><i class="la la-save"></i> Save</a>
                  </div>
              </div> 
  
            <div className='px-4'>
                <div className="row borderbtnm mt-5">
                  <div className="col padd-0">
                      <h4 className="headertitlepad float-left">Preview</h4>
                    </div>
                </div>
              </div>
        </TabPanel>
 
      </div>

      <footer class="m-footer">
        <span class="m-footer__copyright">2021 © <a href="https://saleshive.com/" class="m-link">SalesHive</a>. All Rights Reserved.</span>
      </footer>

    </div>
  );
}