import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CEditContactTagpage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      TagNameAvailable: null,
      ClientID: null,
      UserID: null,
      IsExist: false,
      OldName:null,
      UserIDby:null,
      BtnDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title='Edit Tag | SalesHive'
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
      this.state.UserIDby = Details.ChildUserID;
    }
    this.ContactTagEdit();
  }

  // back button
  BackBtn() {
    history.push("/CContactTag");
  }

 // form validation
 async FromValidation() {
  let FormIsValid = true;
  let Errors = {};
  var Name = document.getElementById("Tag").value.trim();
  var IsExists = await this.ContactTagExits(Name);
  if (IsExists == "BlankData" && Name === "") {
        FormIsValid = false;
        Errors["Name"] = "Please enter name";
  }

  if (IsExists === "ExistCountry") {
    FormIsValid = false;
    Errors["Name"] = "Contact Tag already exist."; // Modify the error message
  }
  this.setState({ Errors: Errors });
  return FormIsValid;
}

async HandleChange(field, e) {
  let Errors = { ...this.state.Errors };
  const value = e.target.value.trim();
  let IsExists;


  if (field === "Name") { // Change "Country" to "Name"
    IsExists = await this.ContactTagExits(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"

    if (IsExists === "BlankData" && value === "") {
      Errors.Name = "Please enter name"; // Change "Country" to "Name"
    } else if (IsExists === "ExistCountry") {
      Errors.Name = "Contact Tag already exist."; // Change "Country" to "Name"
    } else if (IsExists === "AllDone") {
      Errors.Name = null; // Change "Country" to "Name"
    }
  }

  this.setState({ Errors });
}

// check contact tag exists
async  ContactTagExits(Name) {
  if(Name !== ""){
    var ContactTagData = {
      ClientID: this.state.ClientID,
      Tag: Name,
      Role:this.state.Role
    };
    var resdata = await    Axios({
      url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagExists",
      method: "POST",
      data: ContactTagData,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if(this.state.OldName == Name){

          }else{
            this.setState({ TagNameAvailable: resdata.data.Data[0].Tag });
            return "ExistCountry";
          }

        }
        else{
          return "AllDone";
        }
      }
  }else{
    return "BlankData";
  }


}


  // contact tag edit
  ContactTagEdit() {
    
    const id = this.props.location.state;
    this.setState({ id: id });
    var ContactTagData = {
      id: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagGetByID",
      method: "POST",
      data: ContactTagData,
    }).then((res) => {
      //console.log(res)
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Tag").value = res.data.Data[0].Tag;
        this.setState({OldName:res.data.Data[0].Tag})
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  // save button for contact update
  async SaveBtn(e) {
   // e.currentTarget.disabled = true;
    this.setState({BtnDisabled:true})
    // document.getElementById("submit").disabled = true;
    // this.state.Errors.Name = null;
    // this.state.TagNameAvailable = null;
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var FinalValidation = await this.FromValidation()
      if (FinalValidation == true) {
        var Name = document.getElementById("Tag").value.trim();
        var ContactTagData = {
          _id: this.state.id,
          Tag: Name,
          LastUpdatedBy: this.state.UserIDby,
          LastUpdatedDate: new Date(),
          Role:this.state.Role
        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagUpdate",
          method: "POST",
          data: ContactTagData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div className="toastsize">Tag Edit<br/>Tag updated successfully</div>);
            // document.getElementById("submit").disabled = false;
            history.push("/CContactTag");
          } else {

            toast.error(res.data.Message);
            this.setState({BtnDisabled:false})
          }
        });
      } else {
        this.setState({BtnDisabled:false})
      }
  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left">Edit Tag</h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row py-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Tag</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  field name"
                        id="Tag"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        //value={this.state.Fields["Name"]}
                        name="Name"
                        placeholder="Enter Tag"
                        type="text"
                      />
                     
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {/* {this.state.TagNameAvailable && (
                        <span style={{ color: "red" }}>
                          Flield already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCEditContactTagpage = connect(
  mapState,
  actionCreators
)(CEditContactTagpage);
export { connectedCEditContactTagpage as CEditContactTagpage };
