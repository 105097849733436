import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Paper from '@material-ui/core/Paper';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactApexChart from "react-apexcharts";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function Contactsdetailstable({
  FromChildDatesContact,
  ToChildDatesContact,
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [TotalSentEmail, SetTotalSentEmail] = React.useState(0);
  const [TotalReceivedEmail, SetTotalReceivedEmail] = React.useState(0);
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ResponsePer, SetResponsePer] = React.useState(0);
  const [ResponsetoMeetingPer, SetResponsetoMeetingPer] = React.useState(0);
  const [TotalSent, SetTotalSent] = React.useState([]);
  const [TotalResponse, SetTotalResponse] = React.useState([]);
  const [WeekDay, SetWeekDay] = React.useState([]);
  const [TotalMeetingList, SetTotalMeetingList] = React.useState([]);
  const [IsLoading,SetIsLoading] = React.useState(false);
  const [FromDate,SetFromDate] = React.useState("");
  const [ToDate,SetToDate] = React.useState("")
  const ContactsDetails = {
    series: [
      {
        name: "Sent",
        color: "#302e49",
        data: [44, 55, 57, 56, 61, 8, 63, 60, 66, 56, 45, 65],
      },
      {
        name: "Responses",
        color: "#e9826e",
        data: [76, 85, 19, 98, 87, 105, 11, 114, 94, 40, 115, 110],
      },
      {
        name: "Meetings",
        color: "#ffb963",
        data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 50],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "04/01 - 04/04",
          "04/05 - 04/11",
          "04/12 - 04/18",
          "04/19 - 04/25",
          "04/26 - 05/02",
          "05/03",
          "05/10",
          "05/17",
          "05/24",
          "06/07",
          "06/14",
          "06/21",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetUserIDby(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDate(FromChildDatesContact)
    SetToDate(ToChildDatesContact)
   ContactExportHandler(Details.ClientID, FromChildDatesContact, ToChildDatesContact, Details.Role)
    KpiContactGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromChildDatesContact,
      ToChildDatesContact
    );
    ContactKeyMatricGet(Details.ClientID, FromChildDatesContact, ToChildDatesContact,Details.ParentUserID,Details.Role)

  }, [Search, Page, RowsPerPage,FromChildDatesContact,ToChildDatesContact]);
  charts(FusionCharts);
  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //Get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        FromDate:FromDate,
        ToDate:ToDate
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiContactListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        FromDate:FromDate,
        ToDate:ToDate
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiContactListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

      });
    }
  };
  // kpiMeeting get list
  const KpiContactGet = (CID, UID, URole,Fdate,TDate) => {
    SetIsLoading(true)

    var KipsContactData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: TDate,
      FromDate: Fdate,
    };


    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiContactListGet",
      method: "POST",
      data: KipsContactData,
    });
    rows1.then((result) => {

      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
    SetIsLoading(false)

    });
  };

    // contact add to blacklist domain
    const ContactAddToBlackList = () => {
      if (DeleteArray.length == 0) {
        toast.error("Please select contact atleast contact from list");
      } else {
        var ContactData = {
          ClientID: ClientID,
          LastUpdatedBy: UserIDby,
          ContactBlackListArray: DeleteArray,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactToAddToBlackList",
          method: "POST",
          data: ContactData,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Add to BlackListed Succesfully.", "success");
            } else {
            }
          }
        });
      }
    };

      // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val.ProspectID).checked = true;
          var temArray = DeleteArray;
          temArray.push(val);
          SetDeleteArray(temArray);
        } else if (!e.target.checked) {
          document.getElementById(val.ProspectID).checked = false;
          DeleteArray.splice(val, 1);
        }
      });
    }
  }
      // when check box update data
  const CheckBoxClick = (e, row) => {
    if (e.target.checked) {
      var temArray = DeleteArray;
      temArray.push(row);
      SetDeleteArray(temArray);
    } else if (!e.target.checked) {
      DeleteArray.splice(row, 1);
    }
  };

   //Export contact file
   const ContactExportHandler = (CID, FromDates, ToDates,role) => {

    let InputParameter = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      Role:role

    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpisContactExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {



      SetExportData(res.data.Data);
    });
  };

// contact key matric get for graph
const ContactKeyMatricGet = (CID, FromDates, ToDates,UID,role) => {
  SetIsLoading(true)

  const InputParameters = {
    ClientID: CID,
    ToDate: ToDates,
    FromDate: FromDates,
    UserID:UID,
    Role:role
  };
  Axios({
    url: CommonConstants.MOL_APIURL + "/kpis/KpiContactKeyMatric",
    method: "POST",
    data: InputParameters,
  }).then((Res) => {

    if (Res.data.StatusMessage == "SUCCESS") {
      if (Res.data.Data.length > 0) {
        var lstTotalSent = [];
        var lstTotalResponse = [];
        var lstTotalMeeting = [];
        var lstWeekDay = [];
        var objtotalmeeting = 0;
        var objtotalSentEmail = 0;
        var objtotalReceivedEmail = 0;
        for (var i = 0; i < Res.data.Data.length; i++) {
          var totaemail = Res.data.Data[i].SentEmail;
          objtotalSentEmail = objtotalSentEmail + totaemail;

          var totalReceivedEmail = Res.data.Data[i]?.ReceivedEmail;
          objtotalReceivedEmail = objtotalReceivedEmail + totalReceivedEmail;

          var totalmeeting = Res.data.Data[i].TotalEvents;
          objtotalmeeting = objtotalmeeting + totalmeeting;

          var objWeekDay = Res.data.Data[i].WeekDay;
          lstTotalSent.push(totaemail);
          lstTotalResponse.push(totalReceivedEmail)
          lstTotalMeeting.push(totalmeeting)
          lstWeekDay.push(objWeekDay)
        }
        SetTotalSentEmail(objtotalSentEmail)
        SetTotalReceivedEmail(objtotalReceivedEmail)
        SetTotalMeetingList(objtotalmeeting)
        SetTotalSent(lstTotalSent)
        SetTotalResponse(lstTotalResponse)
        SetWeekDay(lstWeekDay)

        if (objtotalSentEmail > 0 && objtotalReceivedEmail > 0) {
          SetResponsePer(Math.round(objtotalReceivedEmail * 100 / objtotalSentEmail))
        }

        if (objtotalReceivedEmail > 0 && objtotalmeeting > 0) {
          SetResponsetoMeetingPer(Math.round(objtotalmeeting * 100 / objtotalReceivedEmail));
      }
      SetIsLoading(false)

      } else {
        SetTotalSentEmail(0)
        SetTotalReceivedEmail(0)
        SetTotalMeeting([])
        SetTotalSent([])
        SetTotalResponse([])
        SetWeekDay([])
      SetIsLoading(false)

      }
    }
    else {
      SetTotalSentEmail(0)
      SetTotalReceivedEmail(0)
      SetTotalMeeting([])
      SetTotalSent([])
      SetTotalResponse([])
      SetWeekDay([])
      SetIsLoading(false)

    }
  });
};

const AccountState = {
  series: [
    {
      name: "Sent",
      color: "#302e49",
      data: TotalSent,
    },
    {
      name: "Responses",
      color: "#e9826e",
      data: TotalResponse,
    },
    {
      name: "Meetings",
      color: "#ffb963",
      data: TotalMeetingList,
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: WeekDay
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return  val ;
        },
      },
    },
    legend: {
      position: "right",
    },
  },
};
const ExportPopup = () => {
  toast.success("Data exported successfully.");
};
  return (
    <> 
    {IsLoading ? <><div id="hideloding" className="loding-display">
    <img src={loadingicon} />
  </div></>:<></>}
          <div className="row colfive mx-n4">
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{isNaN(TotalSentEmail)?0:TotalSentEmail}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Sent</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title"> {isNaN(ResponsePer)?0:ResponsePer}%</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Response %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{isNaN(TotalReceivedEmail)?0:TotalReceivedEmail}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Responses</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{isNaN(ResponsetoMeetingPer)?0:ResponsetoMeetingPer}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Response To Meeting %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="cardbox boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{isNaN(TotalMeeting)?0:TotalMeeting}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Meetings</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row cardbox mt-3 padtop-50">
            <div class="col-xl-1"></div>
            <div class="col-xl-10">
              <div id="chart chart-max450">
                <ReactApexChart
                  options={AccountState.options}
                  series={AccountState.series}
                  type="bar"
                  height={450}
                />
              </div>
            </div>
            <div class="col-xl-1"></div>
          </div>

      <div className="cardbox pb-3 mt-3">
        <div className="row">
          <div className="col border-bottom">
            <h4 className="headertitle float-left">Contact Details</h4>
            {Rows.length === 0 ? (
                <></>
              ) : (
                <div className="listing-li float-right padb-15">
              <ul>
                <li>
                  <a
                    onClick={ContactAddToBlackList}
                    className="btn btngroup m-btn activebloack"
                  >
                    <i class="la la-icon-font-size-13 la-ban"></i>
                    <span>Blacklist All</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      ExportPopup();
                    }}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                    <CSVDownloader
                      data={ExportData}
                      FileName={"KpisContact-Csv"}
                      bom={true}
                    ></CSVDownloader>
                  </a>
                </li>
              </ul>
            </div>
              )}

          </div>
        </div>
        <div className="row">
          <div className="col padd-0"></div>
        </div>
        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:
              <input
                type="Search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <input type="checkbox"  onChange={(e) => CheckHandler(e)}/>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("FirstName");
                    }}
                  >
                    Contact Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountName");
                    }}
                  >
                    Account Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Domain
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("DateContacted");
                    }}
                  >
                    Date Contacted
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Date Responded");
                    }}
                  >
                    Date Responded
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Sent");
                    }}
                  >
                    Sent
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Responses");
                    }}
                  >
                    Responses
                  </a>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Meetings");
                  }}
                >
                  Meetings
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow>
                    <TableCell>
                      <input type="checkbox"   id={row.ProspectID}
                        onChange={(e) => CheckBoxClick(e, row)}/>
                    </TableCell>
                    <TableCell>{row?.EmailAdd}</TableCell>
                    <TableCell>{row.P?.FirstName}</TableCell>
                    <TableCell>{row.P?.AccountName}</TableCell>
                    <TableCell>{row?.Domain}</TableCell>
                    <TableCell> {moment(new Date(row?.MailSentDateByTimeZone).toDateString()).format(
                          "MM/DD/YYYY"
                        )}</TableCell>
                        <TableCell> {moment(new Date(row?.LastUpdatedDate).toDateString()).format(
                          "MM/DD/YYYY"
                        )}</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        {Sflag ? (
          <div class="row">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}