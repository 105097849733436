import React, { useEffect } from "react";
import Axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
//import Userimg from '../images/avatar/1.jpg';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function CollapsibleTable() {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [aID, setAID] = React.useState("");
  const [checkS, setcheckS] = React.useState(false);
  const [orignaldata, setorignaldata] = React.useState([]);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");

  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      setRole(getaccountcatelist.Role);
    }
    GetSeniorityList(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID,getaccountcatelist.Role);
  }, [search, page, RowsPerPage]);

  //get seniority meeting
  const GetSeniorityList = (CID,UID,URole) => {
    var str_in = {
      page: page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      Role:URole
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetSeniority",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  const chengeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    setPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //search
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      setSearch(SearchedVal);
      setPage(1);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  const handleChangePage = (event, newPage) => {
    if(newPage==page){
      setPage(newPage);
    }else{
      setPage(newPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  const startpage = (strdata, pages, rowcount) => {
    if (strdata == "normal") {
      var str_in = {
        page: page,
        rowsPerPage: RowsPerPage,
        serchbox: false,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        type: "User",
      };
    } else if (strdata == "search") {
      var str_in = {
        page: pages,
        rowsPerPage: rowcount,
        serchbox: false,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        type: "User",
      };
    }
    console.log(str_in);
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetSeniority",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
    });
  };

  return (
    <div>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={chengeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"/>
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Seniority</TableCell>
              <TableCell>Total Meeting</TableCell>
              <TableCell>Meeting %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.FieldName}</TableCell>

                <TableCell>
                  {row.Statusdetails.length == 0
                    ? 0
                    : row.Statusdetails[0].StatusCount}
                </TableCell>
                <TableCell>
                  {row.Statusdetails.length == 0
                    ? 0
                    : row.Statusdetails[0].Percentage}
                  %
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer></div>
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
