import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Axios from "axios";
const Moment = require("moment");

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import { Tooltip } from "@material-ui/core";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

export default function CollapsibleTable(props) {
  const [bmddata, setfbmddata] = React.useState([]);
  const [Open, SetOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [checkS, setcheckS] = React.useState(false);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [status, setStatus] = React.useState([]);
  const [defaultselect, setDefaultselect] = React.useState([]);
  const [meeting, setMeetingOwner] = React.useState([]);
  const [ownerselected, setownerselect] = React.useState("");
  const [notes, setNotes] = React.useState([]);
  const [HeaderList, SetHeaderlist] = React.useState([]);
  const [CheckOwner, SetCheckOwner] = React.useState(false);
  const [OwnerID, SetOwnerID] = React.useState("");
  const [UserIDby, setUserIDby] = React.useState(0);
  const [Role, setRole] = React.useState("");

  useEffect(() => {
    document.title = `Booked Meetings | SalesHive`;
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      setRole(getaccountcatelist.Role);
      setUserIDby(getaccountcatelist.ChildUserID);
    }
      
    // document.getElementById("hideloding").style.display = "none";
    BookedmeetingListGet(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID,props.IsApply,props.FilterParameters,getaccountcatelist.Role);
    // mapdata();
    OwnerDropDown(getaccountcatelist.ClientID,getaccountcatelist.Role);
  }, [search, page, RowsPerPage, props.FilterParameters,props.IsApply, CheckOwner, OwnerID]);

  //Get booked meeting list
  const BookedmeetingListGet = (CID, UID,IsApply,FilterParams,URole) => {
    var str_in = {
      page: page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      MeetingOwnerID: OwnerID,
      Checker: CheckOwner,
      IsApply:IsApply,
      BookMeetingFilterArray:FilterParams,
      Role:URole
    };
    
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      console.log(result)
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  // Reset Filter
  const ResetFilter = () => {
    if(OwnerID==""&&CheckOwner==false){
      SetOwnerID("");
      SetCheckOwner(false);
    document.getElementById("hideloding").style.display = "none";
    }else{
    document.getElementById("MeetingOwnerID").value = "";
    document.getElementById("include_owner").checked = false;
    SetOwnerID("");
    SetCheckOwner(false);
    document.getElementById("hideloding").style.display = "block";
    }
    // BookedmeetingListGet(ClientID, UserID, false, []);
  };

  //  Advance Filter
  const FilterResponder = () => {
    let OwnerDetails = document.getElementById("MeetingOwnerID").value;
    let ClientCheckBox = document.getElementById("include_owner").checked;
    SetOwnerID(OwnerDetails);
    SetCheckOwner(ClientCheckBox);
    if(OwnerDetails==""&&CheckOwner==false){
      document.getElementById("hideloding").style.display = "none";
    }else{
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //OwnerData Drop down list
  const OwnerDropDown = (CId,URole) => {
    var InputParameters = {
      ClientID: CId,
      Role:URole
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingOwnerGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      
      
      setMeetingOwner(Result.data.Data);
      setStatus(Result.data.meetingstatus);
      setNotes(Result.data.notesdetails);
      SetHeaderlist(Result.data.meetingheader);
      // SetStatusData(Result.data.DataStatusGet);
    });
  };

  // get meeting owner
  // const GetMeetingOwnerlist = () => {
  //   var str_in = {
  //     IsDeleted: false,
  //     type: "User",
  //     ClientID: ClientID,
  //     UserID: UserID,
  //   };
  //   const rows1 = Axios({
  //     url:
  //       CommonConstants.MOL_APIURL + "/bookedmeeting/findPotentialMeetingOwner",
  //     method: "POST",
  //     data: str_in,
  //   });
  //   rows1.then((result) => {
  //     setMeetingOwner(result.data.BookedOwner);
  //   });
  // };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == search) {
        setSearch(SearchedVal);
        setPage(1);
      } else {
        setSearch(SearchedVal);
        setPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //Delete booked meeting
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a Booked meeting deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText:"No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserIDby,
          Role:Role
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL + "/bookedmeeting/DeleteBookedMeeting",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Booked meeting deleted successfully.",
                "success"
              );
              document.getElementById("hideloding").style.display =
                      "block";
              BookedmeetingListGet(ClientID, UserID);
              history.push("/cbookedmeetings");
              setPage(1);
              setSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Pagination
  const HandleChangePage = (event, newPage) => {
    if (newPage == page) {
      setPage(newPage);
    } else {
      setPage(newPage);
     document.getElementById("hideloding").style.display = "block";
    }
  };

  //selection pagination
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    setPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  const resettable = () => {
    document.getElementById("include_owner").checked = false;
    var dropdown = document.getElementById("MeetingOwnerID");
    dropdown.selectedIndex = "";
    setcheckS(false);
    BookedmeetingListGet();
  };

  const mapdata = () => {
    rows.map((value) => setDefaultselect(value.StatusID));
  };

  const handleChangemeeting = (e) => {
    setDefaultselect(e.target.defaultselect);
  };

  const Bookedstatusselect = (event, EventID, UserID, StatusIDs) => {
    // setMeetingstatus(event.target.value);
    let MeetingIDs = null;
    MeetingIDs = event.target.value;
    //find the meeting status name by id
    var data = {
      ClientID: ClientID,
      MeetingStatusID: event.target.value,
      Role:Role
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingStatus",
      method: "POST",
      data: data,
    }).then((res) => {
      var data = {
        // _id: id,
        EventID: EventID,
        ClientID: ClientID,
        UserID: UserID,
        MeetingStatusID: res.data.BookedStatus[0]?._id,
        OldMeetingStatusID: StatusIDs,
        Status: res.data.BookedStatus[0]?.Status,
        Role:Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/UpdateBookedMeetingStatusHistory",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          window.location.reload(true);

          toast.success(
            <div>
              Meeting <br/>
              Status successfully changed.
            </div>
          );
        } else {
          toast.error(res.data.Message);
        }
      });
    });
  };

  const viewpage = (id) => {
    // history.push("/viewbookedmeeting", id);
    history.push({
      pathname: "/cviewbookedmeetings",
      state: { data: id, Pagename: "" },
    });
  };

  const onOwnerSearch = () => {
    var check = document.getElementById("include_owner");
    if (check.checked == true) {
      if (ownerselected == "") {
        setcheckS(true);
        var str_in = {
          page: page,
          rowsPerPage: RowsPerPage,
          sort: true,
          field: sortField,
          sortby: sortedBy,
          search: search,
          type: "User",
          ClientID: ClientID,
          UserID: UserID,
        };
      } else {
        setcheckS(true);
        var str_in = {
          page: 1,
          ClientID: ClientID,
          UserID: UserID,
          rowsPerPage: RowsPerPage,
          sort: true,
          field: sortField,
          sortby: sortedBy,
          searchowner: ownerselected,
          type: "User",
        };
      }
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
      });
    } else {
      if (ownerselected == "") {
        setcheckS(true);
        var str_in = {
          page: page,
          rowsPerPage: RowsPerPage,
          sort: true,
          field: sortField,
          sortby: sortedBy,
          search: search,
          type: "User",
          ClientID: ClientID,
          UserID: UserID,
        };
      } else {
        setcheckS(true);
        var str_in = {
          page: 1,
          ClientID: ClientID,
          UserID: UserID,
          rowsPerPage: RowsPerPage,
          sort: true,
          field: sortField,
          sortby: sortedBy,
          searchowner: ownerselected,
          type: "User",
        };
      }
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
      });
    }
  };

  const ownerselect = (event) => {
    setownerselect(event.target.value);
    // var data = {
    //   ClientID: ClientID,
    //   MeetingOwnerID: event.target.value,
    // };
    // Axios({
    //   url:
    //     CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingOwner",
    //   method: "POST",
    //   data: data,
    // }).then((res) => {

    //   setownerselect(res.data.BookedOwner[0].Name);
    // });
  };

  const resetView = () => {
    BookedmeetingListGet();
    document.getElementById("resetview").style.display = "none";
  };

  const ContactEdit = (CPID) => {
    
  };

  //get sort field data
  const SortData = (Field) => {
    var searchedVal = document.getElementById("search").value;
    var SerchBox;
    if (searchedVal == "") {
      SerchBox = false;
      setsflag(false);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      setsortField(Field);
      var InputParameter = {
        page: page,
        rowsPerPage: RowsPerPage,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        search: search,
        type: "User",
        ClientID: ClientID,
        UserID: UserID,
        MeetingOwnerID: OwnerID,
        Checker: CheckOwner,
        Role:Role
      };
      const UserGetList = Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
        method: "POST",
        data: InputParameter,
      });
      UserGetList.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
      });
    } else {
      SerchBox = true;
      setsflag(true);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      setsortField(Field);
      var InputParameter = {
        page: page,
        rowsPerPage: RowsPerPage,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        search: search,
        type: "User",
        ClientID: ClientID,
        UserID: UserID,
        MeetingOwnerID: OwnerID,
        Checker: CheckOwner,
        Role:Role
      };

      const UserGetList = Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
        method: "POST",
        data: InputParameter,
      });
      UserGetList.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
      });
    }
  };

  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row my-5">
        <div className="col col-lg-1">
          <label class="labeltexts">Owner</label>
        </div>
        <div className="col col-lg-4">
          <select
            // onChange={ownerselect}
            class="form-control  m-input"
            id="MeetingOwnerID"
            name="MeetingOwnerID"
            autocomplete="off"
          >
            <option>--select--</option>
            {meeting.map((value) => (
              <option value={value.MeetingOwnerID}>{value.Name}</option>
            ))}
          </select>
        </div>
        <div className="col col-lg-2">
          <div className="boxborders">
            <label class="check_bx">
              <input id="include_owner" type="checkbox" />
              <span className="checkmark"></span>
              Open Only
            </label>
          </div>
        </div>
        <div className="col col-lg-4">
          <a
            href="javascript:void(0);"
            onClick={FilterResponder}
            class="btn btn-primary btnColor"
          >
            Search
          </a>
          <a
            href="javascript:void(0);"
            onClick={ResetFilter}
            class="ml-3 btn btn-secondary"
          >
            <i class="la la-refresh"></i> Reset
          </a>
        </div>
      </div>
      <div className="borderboxstp"></div>

      <div className="row">
        <div className="col-sm-12" id="resetview" style={{ display: "none" }}>
          <a
            onClick={() => {
              resetView();
            }}
          >
            Reset
          </a>
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                {HeaderList?.map((row) => (
                  <TableCell key={row._id}>
                    <a
                      onClick={() => {
                        SortData(row.ColumnName.replace(/\s+/g, ""));
                      }}
                    >
                      {row.ColumnName}
                    </a>
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                rows?.map((value1) => (
                  <>
                    <TableRow>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            SetOpen((prev) => ({
                              ...prev,
                              [value1._id]: !prev[value1._id],
                            }))
                          }
                        >
                          {Open[value1._id] ? (
                            <i
                              className="fa fa-minus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            viewpage(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                      </TableCell>
                      {HeaderList.map((row) => {
                        if (row.ColumnName == "Title") {
                          return <TableCell>{value1?.Title}</TableCell>;
                        } else if (row.ColumnName == "Meeting Date") {
                          return (
                            <TableCell>
                              {value1?.EventStartDt != null
                                ? value1?.EventStartDt
                                : ""}
                            </TableCell>
                          );
                        } else if (row?.ColumnName == "Domain") {
                          return (
                            <TableCell>
                              {value1?.Email?.substring(
                                value1?.Email?.lastIndexOf("@") + 1
                              )}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Opportunity Date") {
                          return (
                            <TableCell>
                              {value1?.OpportunityDate != null
                                ? Moment(
                                    new Date(value1?.OpportunityDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Set Date") {
                          return (
                            <TableCell>
                              {value1?.EventCreatedDt != null
                                ? value1?.EventCreatedDt
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Created Date") {
                          return (
                            <TableCell>
                              {value1?.CreatedDt != null
                                ? Moment(new Date(value1?.CreatedDt)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "LinkedIn Url") {
                          return <TableCell>{value1?.LinkedInUrl}</TableCell>;
                        } else if (
                          row.ColumnName == "Last Status Change Date"
                        ) {
                          return (
                            <TableCell>
                              {value1?.LastStatusChangeDate != null
                                ? Moment(
                                    new Date(value1?.LastStatusChangeDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Industry") {
                          return <TableCell>{value1?.Industry}</TableCell>;
                        } else if (row.ColumnName == "Direct Phone") {
                          return <TableCell>{value1?.DirectPhone}</TableCell>;
                        } else if (row.ColumnName == "Meeting Set By") {
                          return (
                            <TableCell>
                              {value1?.ResponderColdCallersType == "R"
                                ? value1?.RespName
                                : value1?.ColdName}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Status") {
                          return (
                            <TableCell>
                              {
                                <select
                                  onChange={(e) => {
                                    Bookedstatusselect(
                                      e,
                                      value1?._id,
                                      value1?.UserID,
                                      value1?.StatusID
                                    );
                                  }}
                                  class="form-control  m-input w-auto"
                                  id="MeetingStatusID"
                                  name="MeetingStatusID"
                                  autocomplete="off"
                                  value={value1?.StatusID}
                                >
                                  {status.map((value) => (
                                    <option value={value?._id}>
                                      {value?.Status}
                                    </option>
                                  ))}
                                </select>
                              }
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Company") {
                          return <TableCell>{value1?.Company}</TableCell>;
                        } else if (row.ColumnName == "Contact Name") {
                          return <TableCell>{value1?.ContactName}</TableCell>;
                        } else if (row.ColumnName == "Days In Stage") {
                          return <TableCell>{value1?.DaysInStage}</TableCell>;
                        } else if (row.ColumnName == "Company Phone") {
                          return <TableCell>{value1?.CompanyPhone}</TableCell>;
                        } else if (row.ColumnName == "Closed Won Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedWonDate != null
                                ? Moment(
                                    new Date(value1?.ClosedWonDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Unqualified Date") {
                          return (
                            <TableCell>
                              {value1?.UnqualifiedDate != null
                                ? Moment(
                                    new Date(value1?.UnqualifiedDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Contract Value") {
                          return <TableCell>{value1?.ContractValue}</TableCell>;
                        } else if (row.ColumnName == "Email") {
                          return <TableCell>{value1?.Email}</TableCell>;
                        } else if (row.ColumnName == "Meeting Status Type") {
                          return (
                            <TableCell>{value1?.MeetingStatusType}</TableCell>
                          );
                        } else if (row.ColumnName == "Send Reminder") {
                          return (
                            <TableCell>
                              {value1?.IsSendMeetingReminder === true
                                ? "yes"
                                : "no"}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Seniority") {
                          return <TableCell>{value1?.Seniority}</TableCell>;
                        } else if (row.ColumnName == "Meeting Source") {
                          return <TableCell>{value1?.MeetingSource}</TableCell>;
                        } else if (row.ColumnName == "Account Name") {
                          return <TableCell>{value1?.AccountName}</TableCell>;
                        } else if (row.ColumnName == "Owner") {
                          return <TableCell>{value1?.MeetingOwner}</TableCell>;
                        } else if (row.ColumnName == "Qualified Date") {
                          return (
                            <TableCell>
                              {value1?.QulifiedDate != null
                                ? Moment(new Date(value1?.QulifiedDate)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Closed Lost Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedLostDate != null
                                ? Moment(
                                    new Date(value1?.ClosedLostDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Last Email Step") {
                          return <TableCell>{row?.CampaignDls?.CampaignDetails?.LastEmailStep}</TableCell>;
                        } else if (row.ColumnName == "Last Email Campaign") {
                          return <TableCell>{row?.CampaignDls?.CampaignDetails?.Name}</TableCell>;
                        } else if (row.ColumnName == "Direct Phone Ext") {
                          return (
                            <TableCell>{value1?.DirectPhoneExt}</TableCell>
                          );
                        } else if (row.ColumnName == "Website") {
                          return <TableCell>{value1?.Website}</TableCell>;
                        } else if (row.CustomFieldID != null &&
                          row.CustomFieldID != "") {
                            let valcust = value1.CustomField?.filter(
                              (item) => item.CustomFieldID == row?.CustomFieldID
                            );
                            if (valcust?.length > 0) {
                              return (
                                <TableCell>{valcust[0]?.FieldValue}</TableCell>
                              );
                            } else {
                              return <TableCell></TableCell>;
                            }
                        } else {
                          return <TableCell></TableCell>;
                        }
                      })}

                      <TableCell>
                        <a
                          onClick={() => {
                            viewpage(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>

                        <a
                          onClick={() => {
                            DeleteBtn(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Delete"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={Open[value1?._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[value1?._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {notes.map((note) =>
                                  note?.MeetingID ==
                                  value1?._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {Moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!notes.some(
                                  (note) =>
                                    note?.MeetingID == value1?._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={HandleChangePage}
            page={page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              onChange={HandleChangePage}
              page={page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
