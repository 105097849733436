import React from 'react';
import Popup from "reactjs-popup";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../Kpis/LastdaysTabing';

import AccountAllTable from './ClientAccountAllTable';


// TabPanels
function TabPanel(Props) {
  const { children, value, index, ...other } = Props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// A11 Props
function A11yProps(Index) {
  return {
    id: `simple-tab-${Index}`,
    "aria-controls": `simple-tabpanel-${Index}`,
  };
}

// UseStyles
const UseStyles = makeStyles((Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: Theme.palette.background.paper,
  },
}));


export default function SimpleTabs() {
  const Classes = UseStyles();
  const [Value, SetValue] = React.useState(0);
  const [PopupVal, SetPopup] = React.useState(false);

  // HandleChange for Value
  const HandleChange = (Event, NewValue) => {
    SetValue(NewValue);
  };

  // Close TermsConditional Modal
  const CloseTermsConditionModal = () => {
    //console.log("--it's false");
    SetPopup(false);
  };

  return (
    <div className="tabdes">
      <Popup open={PopupVal} modal>
        <div className="modal-black"></div>
        <div className="filterPopup">
          <div className="paddingboxTerms">
            <div className="modal-header py-4">
              <h5>Filter</h5>
              <a className="close-ion" onClick={CloseTermsConditionModal}>
                <span aria-hidden="true" class="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div className="row">
                <div className="col-xl-6 allcentertext">
                  <i class="la la-filter iconsfonts"></i>
                  <a
                    id="lnkAddNewFilter"
                    href="javascript:void(0);"
                    class="btn btn-md btn-primary btnColor"
                  >
                    Add New Filter
                  </a>
                </div>
                <div className="col-xl-6 allcentertext">
                  <i class="la la-filter iconsfonts"></i>
                  <a
                    id="lnkAddNewFilter"
                    href="javascript:void(0);"
                    class="btn btn-md btn-primary btnColor"
                  >
                    Select from existing Filter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <div>
        <AppBar position="static">
          <Tabs
            value={Value}
            onChange={HandleChange}
            aria-label="simple tabs example"
          >
            <Tab label="All" {...A11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Prospects" {...A11yProps(1)} />
            <Tab label="Current Clients" {...A11yProps(2)} />
            <Tab label="Previous Clients" {...A11yProps(3)} />
            <Tab label="Competitors" {...A11yProps(4)} />
            <Tab label="Investors" {...A11yProps(5)} />
            <Tab label="Partners" {...A11yProps(6)} />
          </Tabs>
        </AppBar>
        <TabPanel value={Value} className="tagpaddnone whitebg" index={0}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={null} AccountHeading="All" />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} className="tagpaddnone whitebg" index={1}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={1} AccountHeading="Prospects" />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} className="tagpaddnone whitebg" index={2}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={2} AccountHeading="Current Clients" />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} className="tagpaddnone whitebg" index={3}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={3} AccountHeading="Previous Clients" />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} className="tagpaddnone whitebg" index={4}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={4} AccountHeading="Competitors" />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} className="tagpaddnone whitebg" index={5}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={5} AccountHeading="Investors" />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} className="tagpaddnone whitebg" index={6}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={6} AccountHeading="Partners" />
            </div>
          </div>
        </TabPanel>
      </div>

    </div>
  );
}