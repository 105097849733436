const moment=require('moment');
import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import EmailHistory from '../../_components/user/EmailHistory/EmailHistory';
import Footer from '../../_components/user/footer/footer';
//import CollapsibleTable from '../_components/usertable';



class EmailHistoryPage extends React.Component {
    constructor(props) {
        super(props);
        
    
        this.state = {
          FromDate:"",
          ToDate:"",
         
        };
    this.SaveBtn = this.SaveBtn.bind(this);
         
        
      }
    
      componentDidMount() {
          
        document.getElementById('from-date').valueAsDate=new Date(moment().subtract(2,'months'))
        document.getElementById('to-date').valueAsDate=new Date(moment())
        let FromDate = document.getElementById('from-date').value;
        let ToDate = document.getElementById('to-date').value;
        this.setState({
          FromDate: FromDate,
          ToDate: ToDate
        })
      }

      SaveBtn(){
        let FromDate=document.getElementById('from-date').value;
        let ToDate=document.getElementById('to-date').value;
        this.setState({FromDate:FromDate,ToDate:ToDate})
    }

    render() {
        return (
            <>
                <div className="adminmain mheight">
                <Sidebar className=""/>
                <div className="bodyhome">
                <div className="row align-items-center ">
                        <div className="col py-4"> 
                            <h4 className="headertitlenop">Email History</h4>
                        </div>
                        <div className="col pt-3">
                            <div className="listing-li float-right padb-15">
                            <div class="btn-group">
                                <input id="fromtab" name="fromtab" type="hidden" value="" />
                                <input class="form-control datepicker" id="from-date" name="from-date" type="date" autocomplete="off"  />
                                <input class="form-control datepicker" id="to-date" name="to-date" type="date" autocomplete="off"  />
                                    <button id="aCompanyState" onClick={this.SaveBtn} class="btn btn-primary btnColor btn-radius" autocomplete="off">
                                        <i class="la flaticon-diagram"></i>
                                        <span>Analyze</span>
                                </button>
                            </div>

                            </div>
                        </div>
                    </div> 

                    <div class="paddcols">  
                        <div className="row">
                            <div className="col"> 
                                <EmailHistory ToDate={this.state.ToDate} FromDate={this.state.FromDate}/> 
                            </div>
                        </div>      

                       
                    </div>

                </div>

                    

                     

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedEmailHistoryPage = connect(mapState, actionCreators)(EmailHistoryPage);
export { connectedEmailHistoryPage as EmailHistoryPage };