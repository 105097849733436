import React from "react";
import { connect } from "react-redux";

import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import BookedMeeting from "../../_components/user/BookedMeeting/BookedmeetingTabing";
//import CollapsibleTable from '../_components/usertable';

class BookedMeetingsPage extends React.Component {
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row px-0">
              <div className="col">
                <h4 className="headertitle float-left pl-2 py-4 mt-0 ">Booked Meetings</h4>
              </div>
            </div>  
              <div className="row">
                <div className="col px-4">
                  <BookedMeeting />
                </div>
              </div> 
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedBookedMeetingsPage = connect(
  mapState,
  actionCreators
)(BookedMeetingsPage);
export { connectedBookedMeetingsPage as BookedMeetingsPage };
