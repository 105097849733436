import React, { useEffect, useState } from "react";
const moment = require("moment");
const Fileupload = require("../../../_helpers/fileupload");
import parse from "html-react-parser";
import Axios from "axios";
import loadingicon from "../../../images/loading.gif";
import Pagination from "@material-ui/lab/Pagination";

import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";

import "froala-editor/js/plugins.pkgd.min.js";

import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";
import { CommonConstants } from "../../../_constants/common.constants";
import { Variables } from "../../../_constants/common.constants";

import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetClientDetails } from "../../../_helpers/Utility";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import $ from "jquery";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ customtabdata,dater}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Value");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  const [SignatureSC2, SetSignatureSC2] = useState({ data: "" });
  const [SignatureSC1, SetSignatureSC1] = useState({ data: "" });
  const [VariableSet, SetVariableSet] = useState("");
  const [AFID, SetAFID] = useState("");
  const [ExportData, SetExportData] = useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [FeatureNameForDrop, SetFeatureNameForDrop] = React.useState(null);
  const [VariableAvailable, SetCustomVariableValue] = React.useState(null);
  const [IsExist, SetIsExist] = React.useState(false);
  const [OldVariableName, SetOldVariableName] = React.useState("");
  const [CName, SetCName] = React.useState("");
  const [CustomVariableID, SetCustomVariableID] = React.useState("");
  const [Value, SetValue] = React.useState(null);
  // total display
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [CUserID, SetCUserID] = React.useState(0);
  const [DateRange,SetDateRange] = React.useState({})
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    Froala.RegisterCommand("Variable", {
      title: "Advanced options",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: Vari,
      callback: function (cmd, val) {
        var editorInstance = this;
        editorInstance.html.insert(val);
      },
      // Callback on refresh.
      refresh: function ($btn) {
        //
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        // 
      },
    });
    SetDateRange(dater)
    SetCustomVariableID(customtabdata._id);
    CustomVariableValuesGet(Details.ClientID, Details.ParentUserID,Details.Role,dater,customtabdata._id);
    const Inputparameters = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CustomVariableID: customtabdata._id,
      DateRange:dater,
      Role:Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/customvariablevalue/CustomVariableValueExport",
      method: "POST",
      data: Inputparameters,
    }).then((Res) => {
      SetExportData(Res.data.Data);
    });
  }, [Search, Page, RowsPerPage,dater]);

  // variables dropdown mapping
  var Vari = {};
  if (Variables.length > 0) {
    for (var i = 0; i < Variables.length; i++) {
      Vari["{" + Variables[i].name + "}"] = Variables[i].name;
    }
  }

  // config for code editor
  let config = {
    placeholderText: "Type something!",
    charCounterCount: false,
    toolbarButtons: ["Variable"],
    shortcutsEnabled: ["insertTemplateButton"],
    enter: Froala.ENTER_BR,
  };
  // get value for text editor addd
  const CustomVariableValuesSc2ModelChange = (model) => {
    SetSignatureSC2({
      data: model,
    });
  };

  // get value for text editor addd
  const CustomVariableValuesSc1ModelChangeForEdit = (model) => {
    SetSignatureSC1({
      data: model,
    });
  };
  // show editor btn
  const AddBtn = () => {
    document.getElementById("text-editor").style.display = "block";
  };

  // Hide editor btn
  const CancelBtn = () => {
    document.getElementById("text-editor").style.display = "none";
    SetSignatureSC2({ data: "" });
  };

  // Hide editor btn for edit
  const CancelBtnEdit = () => {
    document.getElementById("text-editor-edit").style.display = "none";
  };
  // form validation for add
  const FromValidation = async () => {
    
    let FormIsValid = true;
    let Errors = {};
    var IsExist = await CustomVariableValueCheckExits(SignatureSC2?.data.replace(/<[^>]+>/g, ''));

    
    if (SignatureSC2.data == "") {
      FormIsValid = false;
      toast.error("Add Step Body is empty, fill it!");
    }
    if (IsExist == true) {
      FormIsValid = false;
      toast.error("Custom variable value already exist");
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // check variable set exists edit
  const CustomVariableValueCheckExits = async (Value) => {
    
    var VariableData = {
      ClientID: ClientID,
      UserID: UserID,
      CustomVariableID: CustomVariableID,
      Value: Value,
    };
    var resdata = await Axios({
      url:CommonConstants.MOL_APIURL + "/customvariablevalue/CustomVariableValueCheckExits",
      method: "POST",
      data: VariableData,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetCustomVariableValue(resdata.data.Data[0]?.Value);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  // check variable set exists add
  const CustomVariableValueForEdit = async (Value) => {
    var VariableData = {
      ClientID: ClientID,
      UserID: UserID,
      CustomVariableID: CustomVariableID,
      Value: Value,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customvariablevalue/CustomVariableValueCheckExits",
      method: "POST",
      data: VariableData,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (OldVariableName == Value) {
          SetIsExist(true);
          return false;
        } else {
          SetCustomVariableValue(resdata.data.Data[0]?.Value);
          SetIsExist(true);
          return true;
        }
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  // form validation for edit
  const FromValidationForEdit = async () => {
    
    let FormIsValid = true;
    let Errors = {};
    var IsExist = await CustomVariableValueForEdit(SignatureSC1?.data.replace(/<[^>]+>/g, ''));

    
    if (SignatureSC1.data == "") {
      FormIsValid = false;
      toast.error("Add Step Body is empty, fill it!");
    }
    if (IsExist == true) {
      FormIsValid = false;
      toast.error("Custom variable value already exist");
    }
    SetErrors(Errors);
    return FormIsValid;
  };

  // add variable sets
  const CustomVariableValueAdd = async (e) => {
    e.preventDefault();
    
    // e.currentTarget.disabled = true;
    let Final_flag = await FromValidation();
    if (Final_flag == true) {
      let InputParaMeter = {
        ClientID: ClientID,
        UserID: UserID,
        CustomVariableID: CustomVariableID,
        Value: SignatureSC2?.data.replace(/<[^>]+>/g, ""),
        IsActive: true,
        CreatedDate: new Date(),
        CreatedBy: CUserID,
        Role:Role

      };
      
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/CustomVariableValueAdd",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          CancelBtn();
          toast.success("Subject added successfully.");
          CustomVariableValuesGet(ClientID, UserID,Role,DateRange,CustomVariableID);
          document.getElementById("text-editor").style.display = "none";
          SetSignatureSC2({ data: "" });
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("submitAdd").disabled = false;
    }
  };
  //get admin list
  const CustomVariableValuesGet = (CID, UID,Role,DataRanger,CUTID) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      CustomVariableID: CUTID,
      DateRange:DataRanger,
      Role:Role
    };
    const AutomatorFeatureData = Axios({
      url:
        CommonConstants.MOL_APIURL + "/customvariablevalue/GetCustomVariableValue",
      method: "POST",
      data: InputParameter,
    });
    AutomatorFeatureData.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("Search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CustomVariableID: CustomVariableID,
        Role:Role,
        DateRange:DateRange,

      };
      
      const AutomatorFeatureData = Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/GetCustomVariableValue",
        method: "POST",
        data: InputParameter,
      });
      AutomatorFeatureData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CustomVariableID: CustomVariableID,
        Role:Role,
      DateRange:DateRange,

      };
      
      const AutomatorFeatureData = Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/GetCustomVariableValue",
        method: "POST",
        data: InputParameter,
      });
      AutomatorFeatureData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };

  // Delete for auto feature
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Subject.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          CustomVariableValueID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/customvariablevalue/CustomVariableValueDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Subject deleted successfully.", "success");
              CustomVariableValuesGet(ClientID, UserID,Role,DateRange,CustomVariableID);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // automator feature  edit
  const EditBtn = (CustomVariableValueID) => {
    SetAFID(CustomVariableValueID);
    document.getElementById("text-editor-edit").style.display = "block";
    var InputParaMeter = {
      CustomVariableValueID: CustomVariableValueID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customvariablevalue/CustomVariableValueGetIdByData",
      method: "POST",
      data: InputParaMeter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        SetSignatureSC1({ data: res.data.Data[0].Value });
        SetVariableSet(res.data.Data[0].Value);
        SetOldVariableName(res.data.Data[0].Value);
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  };
  // save button for automator update
  const SaveBtnForUpdateAutomator = async (e) => {
    e.preventDefault();
    // e.currentTarget.disabled = true;
    var FinalValidation = await FromValidationForEdit();
    if (FinalValidation == true) {
      var AutomatorFeatureData = {
        CustomVariableValueID: AFID,
        Value: SignatureSC1?.data.replace(/<[^>]+>/g, ""),
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        Role:Role
      };
      
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/customvariablevalue/CustomVariableValueUpdate",
        method: "POST",
        data: AutomatorFeatureData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("custom variable values updated successfully.");
          CancelBtnEdit();
          CustomVariableValuesGet(ClientID, UserID,Role,DateRange,CustomVariableID);
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("submitedit").disabled = false;
    }
  };

  //Update Status is active
  const UpdateStatus = (CustomVariableValueID, Flag) => {
    const InputParameters = {
      CustomVariableValueID: CustomVariableValueID,
      IsActive: Flag,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customvariablevalue/CustomVariableValueIsActive",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res) {
        if ((res.statusText = "Ok")) {
          if (res.data.Data?.IsActive == true) {
            toast.success(
              <div className="toastsize">
               Custom Variable Value
                <br />
                {customtabdata.Name} variable value Active successfully.{" "}
              </div>
            );
          } else {
            toast.success(
              <div className="toastsize">
                      Custom Variable Value <br /> {customtabdata.Name} variable value InActive
                successfully.{" "}
              </div>
            );
          }
          CustomVariableValuesGet(ClientID, UserID,Role,DateRange,CustomVariableID);
        } else {
        }
      }
    });
  };

  // export csv
  const ExportCsv = () => {
    toast.success(<div className="toastsize">Data exported successfully.</div>);
  };

  // import csv
  const CustomVariableValueImportCsv = () => {
    document.getElementById("import_csv").style.display = "block";
  };

  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[1] != "csv") {

      SetReset(true);
 
      ResetCsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

     
      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  // map csv
  const CustomVariableValueImportCsvMapCsv = () => {

    ResetCsv();
    document.getElementById("map_attribute").style.display = "block";
    if (csvData.length != 0) {
      ResetCsv();
      DropboxData.map((dddd, index) => {});
    } else {
      ResetCsv();
    }
    hideCSVHeaderOptions();
  };

  // map csv for delte
  const DeleteForMapCsv = () => {

    ResetCsv();
    document.getElementById("delete_map_attribute").style.display = "block";
    if (csvData.length != 0) {
      ResetCsv();
      DropboxData.map((dddd, index) => {});
    } else {
      ResetCsv();
    }
    hideCSVHeaderOptions();
  };

  // show bulk delete
  const DeleteBtnBulk = () => {
    document.getElementById("delete_csv").style.display = "block";
  };

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    
    
    if (Value == "Value") {
      SetValue(value);
    }
    hideCSVHeaderOptions();
  });

  // cancel button import
  const VariableSetCancelBtn = () => {
    ResetCsv();
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
  };

  const BulkDeleteCancelBtn = () => {
    ResetCsv();
    document.getElementById("delete_map_attribute").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
  };

  // save csv
  const Savecsv = () => {
    var Value = document.getElementById("Value").value;

    var CsvData = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var InputParaMeter = {
        fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserCustomVariableValue",
        File: _base64,
        ImageName: filename,
        FileData: CsvData,
        Value: Value,
        UserID: UserID,
        ClientID: ClientID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        CustomVariableID: CustomVariableID,
      };
      
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/Customvariablevalueimportfile",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">custom variable values added successfully.</div>
          );
          document.getElementById("map_attribute").style.display = "none";
          document.getElementById("import_csv").style.display = "none";
          document.getElementById("total_div_display").style.display = "block";
          SetTotal(res.data.Total);
          SetTotalDuplicate(res.data.TotalDuplication);
          SetTotalSucess(res.data.Totalsuccess);
          SetTotalError(res.data.TotalError);
          CustomVariableValuesGet(ClientID, UserID,Role,DateRange,CustomVariableID);
        } else {
          toast.error(res.data.Message);
        }
      });
    });
  };

  // bulk delete variables set
  const BulkDeleteSave = () => {
    var Value = document.getElementById("Value").value;

    var CsvData = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var InputParaMeter = {
        fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserCustomVariableValue",
        File: _base64,
        ImageName: filename,
        FileData: CsvData,
        Value: Value,
        UserID: UserID,
        ClientID: ClientID,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        CustomVariableID: CustomVariableID,
      };
      
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/CustomVariableValueBulkDelete",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              custom variable values sets deleted successfully.
            </div>
          );
          document.getElementById("delete_map_attribute").style.display =
            "none";
          document.getElementById("delete_csv").style.display = "none";
          document.getElementById("hideloding").style.display = "block";
          CustomVariableValuesGet(ClientID, UserID,Role,DateRange,CustomVariableID);
        } else {
          toast.error(res.data.Message);
        }
      });
    });
  };
  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row borderbtnm">
        <div className="col-md-6">
          <h4 className="headertitlebd pl-0">{customtabdata.Name}</h4>
        </div>
        <div className="col-md-6">
          <div className="listing-li float-right padb-15 iconbox-pad">
            <ul>
              <li>
                <a
                  onClick={AddBtn}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-plus"></i>
                </a>
              </li>
              <li>
                <a
                  onClick={CustomVariableValueImportCsv}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-upload"></i>
                </a>
              </li>
              <li>
                <CSVDownloader
                  data={ExportData}
                  filename={CName + "-CustomVariableSet"}
                  bom={true} className="px-0"
                >
                  <a
                    onClick={ExportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                  </a>
                </CSVDownloader>
              </li>
              <li>
                <a
                  onClick={DeleteBtnBulk}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la flaticon-delete-1"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Import Csv for variable sets*/}
      <div className="portletbody" id="import_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Import {customtabdata.Name}</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add {customtabdata.Name} data from csv file to import.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a
                onClick={CustomVariableValueImportCsvMapCsv}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={VariableSetCancelBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody portletshadow"
        id="map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
        
              <p>Value</p>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Value"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Value == null
                      ? DropboxData.indexOf("Value")
                      : Value
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <a onClick={Savecsv} className="btn btn-primary btn-lightgreen">
              <i className="la la-save"></i>Save
            </a>
          </div>
        </div>
      </div>

      {/* Total Details for csv */}
      <div
        className="portletbody px-4"
        id="total_div_display"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Results</h4>
        </div>

        <div className="col-xs-12">
          <div class="row colfive">
            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-primary">{Total}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-success">
                        {TotalSucess}
                      </span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total success</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-danger">
                        {TotalError}
                      </span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total error</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-warning">
                        {TotalDuplicate}
                      </span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total Duplicate</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bulk delete for variables set */}
      <div className="portletbody" id="delete_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Bulk Delete {customtabdata.Name}</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add {customtabdata.Name} data from csv file to bulk delete.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a onClick={DeleteForMapCsv} className="btn btn-primary btnColor">
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={BulkDeleteCancelBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody"
        id="delete_map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <p>Value</p>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Value"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Value == null
                      ? DropboxData.indexOf("Value")
                      : Value
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <a
              onClick={BulkDeleteSave}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div>
        </div>
      </div>
      {/* Add flora editor code */}
      <div
        class="col-lg-12 boxsinput_group"
        id="text-editor"
        style={{ display: "none" }}
      >
        <div class="row">
          <label class="col-lg-2 view-lable-meet">{customtabdata.Name}</label>
          <div class="col-lg-10">
            <FroalaEditor
              id="signature"
              config={config}
              onModelChange={CustomVariableValuesSc2ModelChange}
              model={SignatureSC2.data}
            />
          </div>
        </div>
        <div class="row mb-3 px-4">
          <div class="col-lg-12 pull-left">
            <button
              id="submitAdd"
              onClick={CustomVariableValueAdd}
              class="btn btn-primary btn-lightgreen mr-1"
            >
              <i class="la la-save"></i> Save
            </button>
            <button
              id="backtolist"
              onClick={CancelBtn}
              class="btn btn-secondary"
            >
              <i class="la la-arrow-circle-left"></i> Cancel
            </button>
          </div>
        </div>
      </div>
      {/* Edit flora editor code */}
      <div
        class="col-lg-12 boxsinput_group"
        id="text-editor-edit"
        style={{ display: "none" }}
      >
        <div class="row">
          <label class="col-lg-2 view-lable-meet">{customtabdata.Name}</label>
          <div class="col-lg-10">
            <FroalaEditor
              id="signature"
              config={config}
              onModelChange={CustomVariableValuesSc1ModelChangeForEdit}
              model={SignatureSC1.data}
            />
          </div>
        </div>
        <div class="row mb-3 px-4">
          <div class="col-lg-12 pull-left">
            <button
              id="submitedit"
              onClick={SaveBtnForUpdateAutomator}
              class="btn btn-primary btn-lightgreen mr-1"
            >
              <i class="la la-save"></i> Save
            </button>
            <button
              id="backtolist"
              onClick={CancelBtnEdit}
              class="btn btn-secondary"
            >
              <i class="la la-arrow-circle-left"></i> Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Active</TableCell>
              <TableCell>
                {" "}
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
               Value
                </a>{" "}
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
                  {" "}
                  Sent{" "}
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
                  {" "}
                  Tracked{" "}
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
                  {" "}
                  Replies{" "}
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
                  {" "}
                  Interested{" "}
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
                  {" "}
                  Open %{" "}
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
                  {" "}
                  Reply %{" "}
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Value");
                  }}
                >
                  Interested %{" "}
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("CreatedDate");
                  }}
                >
                  Created Date
                </a>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.length == 0 ? (
              <p className="text-center">No data in Available</p>
            ) : (
              Rows?.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>
                    <div class="toggleswich">
                      <input
                        type="checkbox"
                        onChange={() => {
                          UpdateStatus(row._id, !row.IsActive);
                        }}
                        class="checktogle"
                        checked={row?.IsActive}
                      />
                      <b class="switch">
                        <span class="checkion"></span>
                        <span class="uncheckion"></span>
                      </b>
                      <b class="track"></b>
                    </div>
                  </TableCell>
                  <TableCell>
                    {parse(row?.Value.replace(/<[^>]+>/g, ""))}
                  </TableCell>
                  <TableCell>{row?.Sent}</TableCell>
                  <TableCell>{row?.Tracked}</TableCell>
                  <TableCell>{row?.Reply}</TableCell>
                  <TableCell>{row?.Intrested}</TableCell>
                  <TableCell><Tooltip title={<>
                <span>Opens: {row?.Open === undefined? 0 : row?.Open}</span><br/>
                <span>Tracked: {row.Tracked === undefined? 0 : row?.Tracked}</span><br/>
                <span>Open %: {row?.Tracked === 0 ? 0+"%" :parseInt((row?.Open === undefined?0:row?.Open/row?.Tracked)*100) +"%"}</span>
                </>} placement="right">
              <span>{row?.Tracked === 0 ? 0+"%" :parseInt((row?.Open === undefined?0:row?.Open/row?.Tracked)*100) +"%"}</span>
            </Tooltip></TableCell>
                  <TableCell><Tooltip title={<>
                <span>Replies: {row.Reply === undefined ? 0: row?.Reply}</span><br/>
                <span>Sent: {row.Sent === undefined ? 0:row?.Sent}</span><br/>
                <span>Reply %: {row?.Sent === 0 ? 0+"%" :parseInt((row?.Reply/row?.Sent)*100) +"%"}</span>
                </>} placement="right">
              <span>{row?.Sent === 0 ? 0+"%" :parseInt((row?.Reply/row?.Sent)*100) +"%"}</span>
            </Tooltip></TableCell>
                  <TableCell><Tooltip title={<>
                <span>Replies: {row.Reply === undefined ? 0: row?.Reply}</span><br/>
                <span>Interested: {row.Intrested === undefined ? 0:row?.Intrested}</span><br/>
                <span>Interested %: {row?.Reply === 0 ? 0+"%" :parseInt((row?.Intrested/row?.Reply)*100) +"%"}</span>
                </>} placement="right">
              <span>{row?.Reply === 0 ? 0+"%" :parseInt((row?.Intrested/row?.Reply)*100) +"%"}</span>
            </Tooltip></TableCell>
                  <TableCell>
                    {" "}
                    {moment(new Date(row?.CreatedDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        EditBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
