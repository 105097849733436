import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
const Moment = require("moment");
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import contactlistloadingicon from "../../../images/loading.gif";



export default function AccountAllTable({handClose,CampaignId}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ProspectID");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ContactListData,SetContactListData] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ListGet(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);

  }, [Search, Page, RowsPerPage, handClose]);

    //get contact list
    const ListGet = (CID,UID,Role) => {
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        Role: Role,
      };
      const listDatas = Axios({
        url: CommonConstants.MOL_APIURL + "/list/ListGet",
        method: "POST",
        data: InputParameter,
      });
      listDatas.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding21").style.display = "none";
      });
    };
  
    //search for record
    const RequestSearch = (Event) => {
      if (Event.key === "Enter") {
          var SearchedVal = document.getElementById("search").value;
          SetSearch(SearchedVal);
          SetPage(1);
          document.getElementById("hideloding21").style.display = "none";
        }
    };
  
    //change Page
    const HandleChangePage = (Event, NewPage) => {
      if (NewPage == Page) {
        SetPage(NewPage);
      } else {
        SetPage(NewPage);
        document.getElementById("hideloding21").style.display = "block";
      }
    };
  
    //change display rows
    const ChangeRowSelected = (Event) => {
      SetRowsPerPage(Number(Event.target.value));
      SetPage(1);
      document.getElementById("hideloding21").style.display = "block";
    };
  
    // all check handler
    const CheckHandler=(e)=>{
      {
        Rows.map((item) => {
          if (e.target.checked) {
            document.getElementById(item.ListID).checked =true;
         } else if (!e.target.checked) {
           document.getElementById(item.ListID).checked =false;
         }
        })
      }
  
    }
  
    //Add list contact
    const CheckBoxAdd = (e,ID) => {
  
      if (e.target.checked) {
        ContactListData.push({  ListID: ID});
        console.log("ContactListData",ContactListData)
      } else if (!e.target.checked) {
        var newArray = []
      const array=  ContactListData.filter(function (val){
          return val.ListID !== ID
        })
        console.log("array",array)
        SetContactListData(array)
        newArray.push({ListID: ID});
        SetContactListData(newArray);
  
      }
    };
  
  
  
    //Add and Update list contact with multiple check
    const SaveListBtn = () => {
      let IsMoveExistToCurrentCampaign =  document.getElementById("IsMoveExistToCurrentCampaign").checked;
       if(ContactListData.length > 0){
        var data = {
          CheckedArrayList:ContactListData,
          CampaignID:CampaignId,
          UserID:UserID,
          ClientID:ClientID,
          CreatedBy:CUserID,
          CreatedDate:new Date(),
          IsMoveExistToCurrentCampaign:IsMoveExistToCurrentCampaign
        };
        console.log("data123",data)
        Axios({
          url: CommonConstants.MOL_APIURL + "/prospect_campaign/CampaginAddToListsFromContact",
          method: "POST",
          data: data,
        }).then((Res) => {
          console.log("Res.data",Res.data)
          if (Res.data.StatusMessage == "SUCCESS") {
            
              toast.success("Added contact succefully")
    
          } else {
            toast.error(Res.data.Message);
          }
        });
        
      }else{
        toast.error("Please select atleast one contacts from list.")
      }
  
    };
  
      //get sort field data
      const SortData = (Field) => {
          var SearchedVal = document.getElementById("search").value;
          // SetPage(1);
          // SetRowsPerPage(10);
          var SerchBox;
          if (SearchedVal == "") {
            SerchBox = false;
            SetSflag(false);
            var SortField = Field;
            var SortBy;
            if (SortedBy == 1) {
              SortBy = -1;
              SetSortedBy(-1);
            } else {
              SortBy = 1;
              SetSortedBy(1);
            }
            SetSortField(Field);
            var InputParameter = {
              Page: Page,
              RowsPerPage: RowsPerPage,
              SerchBox: false,
              Archive: false,
              Sort: true,
              Field: SortField,
              SortBy: SortBy,
              Type: "User",
              ClientID: ClientID,
              UserID: UserID,
              Role: Role,
            };
            console.log(InputParameter);
            const AccountCategoriesList = Axios({
              url:CommonConstants.MOL_APIURL +"/list/ListGet",
              method: "POST",
              data: InputParameter,
            });
            AccountCategoriesList.then((Result) => {
              console.log(Result.data);
              SetRows(Result.data.PageData);
              SetRlen(Result.data.TotalCount);
              SetFlen(Result.data.TotalCount);
              SetCountPage(Result.data.PageCount);
            });
          } else {
            SerchBox = true;
            SetSflag(true);
            var SortField = Field;
            var SortBy;
            if (SortedBy == 1) {
              SortBy = -1;
              SetSortedBy(-1);
            } else {
              SortBy = 1;
              SetSortedBy(1);
            }
            SetSortField(Field);
            var InputParameter = {
              Page: Page,
              RowsPerPage: RowsPerPage,
              search: SearchedVal,
              SerchBox: SerchBox,
              Archive: false,
              Sort: true,
              Field: SortField,
              SortBy: SortBy,
              Type: "User",
              ClientID: ClientID,
              UserID: UserID,
              Role: Role,
            };
            console.log(InputParameter);
            const AccountCategoriesList = Axios({
              url:CommonConstants.MOL_APIURL +"/list/ListGet",
              method: "POST",
              data: InputParameter,
            });
            AccountCategoriesList.then((Result) => {
              SetRows(Result.data.PageData);
              SetRlen(Result.data.TotalCount);
              SetFlen(Result.data.TotalCount);
              SetCountPage(Result.data.PageCount);
            });
          }
        };
  return (
    <div>
              <div className="row">
                                                      <div className="col">
                                                        <label class="d-flex">
                                                          <div class="toggleswich mr-2">
                                                            <input
                                                              type="checkbox"
                                                              class="checktogle"
                                                              id="IsMoveExistToCurrentCampaign"

                                                            />
                                                            <b class="switch">
                                                              <span class="checkion"></span>
                                                              <span class="uncheckion"></span>
                                                            </b>
                                                            <b class="track"></b>
                                                          </div>
                                                          Move existing contacts
                                                          to current Campaign
                                                        </label>
                                                      </div>
                                                    </div>
          <div id="hideloding21" className="loding-display">
        <img src={contactlistloadingicon} />
      </div>
      <div className="row pb-3 mt-1">
        <div className="col-sm-12 col-md-6 d-flex">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <input name="select_all_contacts" type="checkbox" onChange={(e) =>
                        CheckHandler(e)
                      } />
                </TableCell>
                <TableCell>  <a
                    onClick={() => {
                      SortData("Name");
                    }}>Name</a></TableCell>
                <TableCell><a
                    onClick={() => {
                      SortData("TotalCount");
                    }}>Total Contact</a></TableCell>
                <TableCell><a
                    onClick={() => {
                      SortData("CreatedDate");
                    }}>Created Date</a></TableCell>
        
       
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? <p>No data available in table</p> : Rows.map((row) => (
          
                <TableRow>
                  <TableCell>
                    <input
                      type="checkbox"
                      id={row.ListID}
                      onChange={(e) =>
                        CheckBoxAdd(e,row.ListID)
                      }
                  
               
                    />
                  </TableCell>
                  <TableCell>{row?.Name}</TableCell>
                    <TableCell>{row?.TotalProspect}</TableCell>
                    <TableCell>
                      {Moment(new Date(row.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info ">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row pb-5 mb-4">
          <div className="col dataTables_info d-flex ">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}

      <div className="modal-footer px-0">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={handClose}
        >
          Cancel
        </button>
        <button
        
          type="button"
          id="popup-close"
          className="btn btn-primary btn-lightgreen"
          onClick={SaveListBtn}
        >
          <i className="la la-save"></i> Save
        </button>
      </div>
    </div>
  );
}
