import React from 'react';
import { connect } from 'react-redux';
import Axios from "axios";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ClientCampaigns from '../../_components/user/ClientCampaigns/ClientCampaigns';
import { GetUserDetails } from "../../_helpers/Utility";
//import CollapsibleTable from '../_components/usertable';



class ClientCampaignsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          ExportData: [],
          Archive:false,
          InActive:false,
          ClientID:"",
          UserID:"",
          Role:"",
          Search:"" 
        };
      }
      componentDidMount() {
        var Details = GetUserDetails();
        if (Details != null) {
            this.setState({ClientID:Details.ClientID});
            this.setState({UserID:Details.ParentUserID})
            this.setState({Role:Details.Role})
        //   this.state.ClientID = Details.ClientID;
        //   this.state.UserID = Details.ChildUserID;
        //   this.state.Role = Details.Role;
        }
        this.GetExportData(this.state.Archive,this.state.InActive,Details.ParentUserID,this.state.Search)
      }
//Reload page while delete perform
UpdateFromChild = (value1,value2,search) => {
    
    this.GetExportData(value1,value2,this.state.UserID,search)
  }
  GetExportData = async (Archive,InActive,UID,Search) =>{
    
    var InputParameter = {
        UserID:UID,
        Archive:Archive,
        InActive:InActive,
        Search:Search
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/campaign/AllClientCampaignExport",
        method: "POST",
        data: InputParameter,
      }).then((Res) => {
          
        this.setState({ ExportData: Res.data });
      });
  }

  ExportCsv() {}

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left pl-0"> All Campaigns </h4>
                        </div>      
                        <div className="col border-bottom px-4">
                            <div className="listing-li float-right pb-3 px-4">
                                <ul>
                                <li>
                                    <CSVDownloader className='px-0'
                                            data={this.state.ExportData}
                                            filename={"AllClients-Campaign"}
                                            bom={true}
                                        >
                                    <a
                                        onClick={this.ExportCsv}
                                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                    >
                                        <i className="la la-icon-font-size-13 la-download"></i>
                                        <span>
                                    
                                            Export
                                        
                                        </span>
                                    </a>
                                    </CSVDownloader>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
 

                    <div class="paddcols px-4"> 
                        <div className="row">
                            <div className="col">
                                    <ClientCampaigns updateFromChild={this.UpdateFromChild}/> 
                            </div>
                        </div>      
                    </div>

                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientCampaignsPage = connect(mapState, actionCreators)(ClientCampaignsPage);
export { connectedClientCampaignsPage as ClientCampaignsPage };