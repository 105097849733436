import React from "react";
import { connect } from "react-redux";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Axios from "axios";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import DefaultTrackingDomain from "../../_components/user/DefaultTrackingDomain/DefaultTrackingDomainTable";
//import CollapsibleTable from '../_components/usertable';

import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";

class DefaultTrackingDomainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ExportData: [],
    };

    // this.ExportCsv = this.ExportCsv.bind(this);
    this.addpage = this.addpage.bind(this);
  }
  addpage() {
    history.push("/adddefaulttrackingdomain");
  }
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left pl-0">
                  Default Tracking Domain{" "}
                </h4>
                <div className="listing-li float-right padb-15 pr-0">
                  <ul>
                    <li>
                      <a
                        onClick={() => this.addpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols pb-4">
              <div className="row">
                <div className="col">
                  <DefaultTrackingDomain />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedDefaultTrackingDomainPage = connect(
  mapState,
  actionCreators
)(DefaultTrackingDomainPage);
export { connectedDefaultTrackingDomainPage as DefaultTrackingDomainPage };
