import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import DespammerTabing from '../../_components/user/Despammer/DespammerTabing';
import CollapsibleTable from '../../_components/usertable';
import Footer from '../../_components/user/footer/footer';



class DespammerPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row px-4">
                        <div className="col padd-0 py-4">
                            <h4 className="headertitlenop px-1">Despammer</h4>
                        </div>
                        <div className="col padright"> 
                        </div>
                    </div>
                    <div className="col padd-0">
                        <DespammerTabing />
                    </div>
                </div>

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedDespammerPage = connect(mapState, actionCreators)(DespammerPage);
export { connectedDespammerPage as DespammerPage };