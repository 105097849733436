import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditSalesRepPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID: Details.ParentUserID,
      PrevName: null,
      IsExist: false,
      NameAvailable: null,
      BtnDisabled: false,
    };
    this.GetSalesRepsData();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title = "Edit | SalesHive";
  }
  GetSalesRepsData() {
    var InputParameter = {
      ID: this.props.location.state,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data.Name;
        this.setState({ PrevName: res.data.Data.Name });
      }
    });
  }
      // form validation 
  async FormValidation() {
    let FormIsValid = true;
    let errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExists = await this.CheckExistName(Name); // Change 'Country' to 'Name'
    
    if (IsExists == "BlankData" && Name === "") { // Check for an empty string or a space in the name
      FormIsValid = false;
      errors["Name"] = "Please enter name "; // Modify the error message
    }
  
    if (IsExists === "ExistName") {
      FormIsValid = false;
      errors["Name"] = "Name already exists."; // Change 'Country' to 'Name'
    }
  
    this.setState({ errors: errors });
    return FormIsValid;
  }

  // handle change
  async HandleChange(field, e) {
    let errors = { ...this.state.errors };
      const value = e.target.value.trim();
      let IsExists;

    
      if (field === "Name") { // Change "Country" to "Name"
        IsExists = await this.CheckExistName(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
    
        if (IsExists === "BlankData" && value === "") {
          errors.Name = "Please enter name"; // Change "Country" to "Name"
        } else if (IsExists === "ExistName") {
          errors.Name = "Name already exists."; // Change "Country" to "Name"
        } else if (IsExists === "AllDone") {
          errors.Name = null; // Change "Country" to "Name"
        }
      }
    
      this.setState({ errors });
  }

  // check exit Name
  // async CheckExistName(Name) {
  //   // var Name = document.getElementById("Name").value;
  //   if (Name !== "") {
  //     var InputParameter = {
  //       UserID: this.state.PUserID,
  //       Name: Name,
  //     };
  //     var resdata = await Axios({
  //       url: CommonConstants.MOL_APIURL + "/salesrep/CheckNameExist",
  //       method: "POST",
  //       data: InputParameter,
  //     });
  //     // if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.Data?.length > 0) {
  //         this.setState({ NameAvailable: resdata.data.Data[0]?.Name });
  //         this.setState({OldName:res.data.Data[0].Name})
  //         this.setState({ IsExist: true });
  //         return "ExistName";
  //       } else {
  //         return "AllDone";
  //       }
  //     // }
  //   } else {
  //     return "BlankData";
  //   }
  // }

  // check exits name 
  async  CheckExistName(Name) {
    if(Name !== ""){
      var InputParameter = {
        UserID: this.state.PUserID,
        Name: Name,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/CheckNameExist",
        method: "POST",
        data: InputParameter,
      });
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            if(this.state.OldName == Name){

            }else{
              this.setState({ NameAvailable: resdata.data.Data[0].Name });
              return "ExistName";
            }
  
          }
          else{
            return "AllDone";
          }
        }
    }else{
      return "BlankData";
    }


  }

  backbtn() {
    history.push("/salesrep");
  }
  async savebtn(e) {
    // this.state.errors.Name = null;
    // this.state.NameAvailable = null;
    this.setState({ BtnDisabled: true });
    var FinalValidation = await this.FormValidation();
    if (FinalValidation == true) {
      var FieldName = document.getElementById("Name").value.trim();

      var data = {
        ID: this.props.location.state,
        Name: FieldName,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: this.state.UserID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">Sales Reps updated successfully.</div>
          );

          history.push("/salesrep");
        } else {
          toast.error(res.data.Message);
          this.setState({ BtnDisabled: false });
        }
      });
    } else {
      this.setState({ BtnDisabled: false });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0 mb-0">Edit Sales Rep</h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row pt-4 pb-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="Name"
                        placeholder="Enter sales rep name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-4 pt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditSalesRepPage = connect(
  mapState,
  actionCreators
)(EditSalesRepPage);
export { connectedEditSalesRepPage as EditSalesRepPage };
