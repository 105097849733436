import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import ReactApexchart from "react-apexcharts";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

class CAddAccountPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      MeetingOwner: [],
      AccountStatus: [],
      SalesreplyAccountType: [],
      SalesreplyAccountCategory: [],
      SalesreplyAccountStatus: [],
      ReasonUnqualified: [],
      AccountCustomField: [],
      fileds: {},
      errors: {},
      statusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      UserIDby: Details.ChildUserID,
      Role: Details.Role,
      InvalidDomain:false,
      IsButtonDisabled:false
    };
    this.GetAccountStatus();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  GetAccountStatus() {
    var data = {
      ClientID: this.state.ClientID,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAllStatus",
      method: "POST",
      data: data,
    }).then((res) => {
      this.setState({
        MeetingOwner: res.data.MeetingOwner,
        AccountStatus: res.data.AccountStatus,
        SalesreplyAccountType: res.data.SalesreplyAccountType,
        SalesreplyAccountCategory: res.data.SalesreplyAccountCategory,
        SalesreplyAccountStatus: res.data.SalesreplyAccountStatus,
        ReasonUnqualified: res.data.ReasonUnqualified,
        AccountCustomField: res.data.AccountCustomField,
      });
      document.getElementById("hideloding").style.display = "none";
    });
  }

  async FromValidation() {
    this.setState({ InvalidDomain: false });
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};

    var Domain = document.getElementById("Domain").value.trim();
    var IsExist = await this.CheckDomain(Domain)
    if (Domain == "") {
      formIsValid = false;
      errors["Domain"] = "Please enter email domain.";
    }
    if(IsExist == true){
      formIsValid = false;
    }
    if(this.state.InvalidDomain==true){
      formIsValid = false;
      errors["Domain"] = "Invalid email domain";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  //Check domain
  async CheckDomain(Domain){

    let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    let Errors = {};
    if (!regexp.test(Domain) && Domain!="") {
      Errors["Domain"] = "Invalid email domain";
      this.setState({ errors: Errors });
      this.setState({ InvalidDomain: true });
      return true;
    } else {
      return false;
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    var Domain = document.getElementById("Domain").value.trim();
    if (fields.Domain != "") {
      this.state.errors.Domain = null;
      // this.state.statusAvailable = null;
      this.CheckDomain(Domain);
    } else {
      // this.state.statusAvailable = null;
      this.state.errors.Domain = null;
    }
  }

  CheckExistAccountClientName(AccountName) {
    var str_in = {
      ClientID: this.state.ClientID,
      Role:this.state.Role,
      Name: AccountName,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  backbtn() {
    history.push("/caccounts");
  }

  //save email
  async savebtn(e) {
    
    this.setState({IsButtonDisabled:true});
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var FormValidationFlag = await this.FromValidation()
    if (FormValidationFlag === true) {
    var AccountCategoryID = document.getElementById("AccountCategoryID").value;
    var AccountClientStatusID = document.getElementById(
      "AccountClientStatusID"
    ).value;
    var AccountName = document.getElementById("AccountName").value;
    var AccountNameLong = document.getElementById("AccountNameLong").value;
    var AccountOwnerID = document.getElementById("AccountOwnerID").value;
    var AccountStatusID = document.getElementById("AccountStatusID").value;
    var Address1 = document.getElementById("Address1").value;
    var Address2 = document.getElementById("Address2").value;
    var City = document.getElementById("City").value;
    var CompanyEmployeeCount = document.getElementById(
      "CompanyEmployeeCount"
    ).value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var CompanyRevenue = document.getElementById("CompanyRevenue").value;
    var Country = document.getElementById("Country").value;
    var EmailDomins = document.getElementById("Domain").value;
    // var FileName = document.getElementById("FileName").value;
    var Industry = document.getElementById("Industry").value;
    var ReasonUnqualifiedID = document.getElementById(
      "ReasonUnqualifiedID"
    ).value;
    var AccountTypeID = document.getElementById("AccountTypeID").value;
    // var StateteStandardized = document.getElementById(
    //   "StateteStandardized"
    // ).value;
    // var Unqualified = document.getElementById("Unqualified").value;
    var Website = document.getElementById("Website").value;
    var Zip = document.getElementById("Zip").value;
    // var IsAccountdetailsUpdate = document.getElementById(
    //   "IsAccountdetailsUpdate"
    // ).value;
    var State = document.getElementById("State").value;


    var AccountCustomFieldValueArray = []
    this.state.AccountCustomField.map((value) => {
   let customval = document.getElementById(value.CustomFieldID).value;
   let customvalid= value.CustomFieldID;
      if(customval.length !== 0 ){
        AccountCustomFieldValueArray.push({
          customval,
          customvalid
        })
      }
    })


      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        AccountCategoryID: AccountCategoryID,
        AccountClientStatusID: AccountClientStatusID,
        AccountName: AccountName,
        AccountNameLong: AccountNameLong,
        AccountOwnerID: AccountOwnerID,
        AccountStatusID: AccountStatusID,
        AccountTypeID: AccountTypeID,
        Address1: Address1,
        Address2: Address2,
        City: City,
        CompanyEmployeeCount: CompanyEmployeeCount,
        CompanyPhone: CompanyPhone,
        CompanyRevenue: CompanyRevenue,
        Country: Country,
        EmailDomins: EmailDomins,
        // FileName: FileName,
        Industry: Industry,
        IsDeleted: false,
        ReasonUnqualifiedID: ReasonUnqualifiedID,
        // SalesReplyAccountID: SalesReplyAccountID,
        // StateteStandardized: StateteStandardized,
        // TotalCallCount: TotalCallCount,
        // Unqualified: Unqualified,
        Website: Website,
        Zip: Zip,
        IsAccountdetailsUpdate: false,
        SalesReplyAccountID: null,
        State: State,
        CreatedBy: this.state.UserIDby,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        Role:this.state.Role,
        LastUpdatedDate: null,
        AccountCustomDetails:AccountCustomFieldValueArray
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AddAccount",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Add Accounts<br/>
              Account added successfully.
            </div>
          );
          history.push("/accounts");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    }else{
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
         <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad nonhed">Add</h4>
              </div>
            </div>

            <div class="paddcols pb-2">
              <div className="row pt-4 pb-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      {" "}
                      Account Name
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="AccountName"
                        name="AccountName"
                        placeholder="Account Name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Account Name Long
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="AccountNameLong"
                        name="AccountNameLong"
                        placeholder="Account Name Long"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Email Domains
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Domain"
                        name="EmailDomins"
                        placeholder="Email domains"
                        type="text"
                        onBlur={this.handleChange.bind(this, "Domain")}
                      />
                       <span style={{ color: "red" }}>
                        {this.state.errors["Domain"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Website</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Website"
                        name="Website"
                        placeholder="Website "
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-center">
                    <label class="col-lg-4 view-lable-meet">
                      Account Owner
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="AccountOwnerID"
                        name="AccountOwnerID"
                      >
                        <option value="">--select--</option>
                        {this.state.MeetingOwner.map((val) => (
                          <option value={val._id}>{val.Name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-center">
                    <label class="col-lg-4 view-lable-meet">
                      Account Category
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="AccountCategoryID"
                        name="AccountCategoryID"
                      >
                        <option value="">--select--</option>
                        {this.state.SalesreplyAccountCategory.map((val) => (
                          <option value={val._id}>
                            {val.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-center">
                    <label class="col-lg-4 view-lable-meet">
                      Account Status
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="AccountStatusID"
                        name="AccountStatusID"
                      >
                        <option value="">--select--</option>
                        {this.state.AccountStatus.map((val) => (
                          <option value={JSON.stringify(val)}>
                            {val.Status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Industry</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Industry"
                        name="Industry"
                        placeholder="Industry"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Address 1</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Address1"
                        name="Address1"
                        placeholder="Address 1"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Address 2</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Address2"
                        name="Address2"
                        placeholder="Address 2"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">City</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="City"
                        name="EmailDomains"
                        placeholder="City"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">State</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="State"
                        name="State"
                        placeholder="State"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Country</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Country"
                        name="EmailDomains"
                        placeholder="Country"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Zip</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Zip"
                        name="EmailDomains"
                        placeholder="Zip"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-center">
                    <label class="col-lg-4 view-lable-meet">
                      Reason Unqualified
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ReasonUnqualifiedID"
                        name="ReasonUnqualifiedID"
                      >
                        <option value="">--select--</option>
                        {this.state.ReasonUnqualified.map((val) => (
                          <option value={val._id}>
                            {val.Reason}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-center">
                    <label class="col-lg-4 view-lable-meet">Account Type</label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="AccountTypeID"
                        name="AccountTypeID"
                      >
                        <option value="">--select--</option>
                        {this.state.SalesreplyAccountType.map((val) => (
                          <option value={val.FieldID}>
                            {val.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-center">
                    <label class="col-lg-4 view-lable-meet">
                      Account Client Status
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="AccountClientStatusID"
                        name="AccountClientStatusID"
                      >
                        <option value="">--select--</option>
                        {this.state.SalesreplyAccountStatus.map((val) => (
                          <option value={val._id}>{val.Name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Company Phone
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        placeholder="Company Phone"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Company Revenue
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="CompanyRevenue"
                        name="CompanyRevenue"
                        placeholder="Company Revenue"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Employee Count
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="CompanyEmployeeCount"
                        name="CompanyEmployeeCount"
                        placeholder="Employee Count"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                {/* <div class="col-lg-6 boxsinput_group">
                            <div class="row max-cols">
                                <label class="col-lg-4 view-lable-meet">Last Name</label>
                                <div class="col-lg-8">
                                    <input class="form-control m-input" id="LastName" name="LastName" placeholder="Last name" type="text" value="" />
                                </div>
                            </div>
                        </div> */}
              </div>

              {/* <div className="row borderbtnm mt-5">
                <div className="col">
                  <h4 className="headertitlepad nonhed">
                    Custom Contact Field
                  </h4>
                </div>
              </div>
              <div className="row borderbtnm pb-5">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    {this.state.AccountCustomField?.map((value, index) => {
                      return (
                        <div className="col-lg-6 boxsinput_group">
                          <div
                            className="row max-cols d-flex align-items-center"
                            key={index}
                          >
                            <label className="col-lg-4">
                              {value.FieldName}
                            </label>
                            <div className="col-lg-7">
                              <input
                                class="form-control m-input"
                                id={value._id}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div> */}
               <div className="row border-bottom py-4">
                <h3 className="pl-3 float-left addpotential_header mb-0">
                  Custom Meeting Field
                </h3>
              </div>
              <div className="paddcols px-0">
              <div className="row ">
              {this.state.AccountCustomField?.map((value, index) => {
                return (
                  <div className="col-lg-6 boxsinput_group">
                    <div
                      className="row max-cols d-flex align-items-center"
                      key={index}
                    >
                      <label className="col-lg-4">{value.FieldName}</label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id={value._id}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              </div>
 
            </div>

              <div class="row border-top pt-4 my-4">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddAccountPage = connect(
  mapState,
  actionCreators
)(CAddAccountPage);
export { connectedCAddAccountPage as CAddAccountPage };
