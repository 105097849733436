import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var CryptoJS = require("crypto-js");
import { connect } from "react-redux";
import Popupone from "reactjs-popup";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import UsersPerClientTabing from "../../_components/user/UsersPerClient/UsersPerClientTabing";
import { history } from "../../_helpers";
const EmailTemplete = require("../../_helpers/email-templete");

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails,EmailValidation } from "../../_helpers/Utility";
toast.configure();
import loadingicon from "../../images/loading.gif";
const REACT_APP_ENCRYPTION_SALT =  process.env.REACT_APP_ENCRYPTION_SALT
class UsersPerClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PopUpVal: true,
      InvitePopup: false,
      ClientID: null,
      UserID: null,
      fields: {},
      errors: {},
      IsExist: false,
      IsExist1: false,
      EmailNotNull: false,
      Message: "",
      ListUpdate: false,
      Role: null,
      BtnDisabled:false,
      FormData:{
        Email:""
      },
      errors:{},
      IsLoading:false
    };
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
    this.SendInvite = this.SendInvite.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    console.log(Details)
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
    }
  }

  OpenInvitePopup() {
    this.setState({ InvitePopup: true,ButtonDisabled:false });
    this.setState({errors:{},FormData:{Email:""}});
  }
  
  CloseInvitePopup() {
    this.setState({ InvitePopup: false });
    this.setState({errors:{},FormData:{Email:""}});
  }
  
  async CheckAdminInvite(Email) {
    this.setState({IsLoading:true})
    var InputParameters = {
      ParentUserID: this.state.UserID,
      UserID: this.state.UserID,
      Email: Email,
      ClientID: this.state.ClientID,
    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/AdminUser/InviteValidation",
      method: "POST",
      data: InputParameters,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Flag) {
        this.setState({ Message: resdata.data.Message,IsLoading:false });
        return false;
      } else {
        this.setState({ Message: "",IsLoading:false });
        return true;
      }
    }
  }

  // Whole form validation
  async FormValidation() {
    let formIsValid = true;
    let Email = document.getElementById("EmailInvite").value;
    let IsExists = await this.CheckAdminInvite(Email);

    if (IsExists) {
      formIsValid = true;
    } else {
      formIsValid = false;
    }
    if (Email.trim().length == 0) {
      this.setState({ Message: "Please enter email." });
      formIsValid = false;
    }

    return formIsValid;
  }
  async SendInvite(e) {
    this.setState({BtnDisabled:true})
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      let Email = document.getElementById("EmailInvite").value;
      let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);

      var re =
        /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      var EmailFlg = EmailValidation(Email);
      if (EmailFlg == true) {
        var FinalEmail = CryptoJS.AES.encrypt(
          JSON.stringify(Email),
          REACT_APP_ENCRYPTION_SALT
        ).toString();
        var UID = CryptoJS.AES.encrypt(
          JSON.stringify(this.state.UserID),
          REACT_APP_ENCRYPTION_SALT
        ).toString();
        var CID = CryptoJS.AES.encrypt(
          JSON.stringify(this.state.ClientID),
          REACT_APP_ENCRYPTION_SALT
        ).toString();

        var EmailData = EmailTemplete.userEmailInvite(
          FinalEmail,
          CID,
          UID,
          CommonConstants.usersperclient_invitation
        );
        var str_mail = {
          Email: Email,
          html: EmailData,
          subject: "SalesHive User Account",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/user/profileemail",
          method: "POST",
          data: str_mail,
        }).then((res) => {
          let InputParameters = {
            Email: Email,
            UserID: this.state.UserID,
            ClientID: this.state.ClientID,
            CreatedDate: new Date(),
            CreatedBy: this.state.UserID,
            VerificationCode: VerificationCodeChar,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +"/users_per_client/UserPerClientInvite",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Users Per Client <br />
                  Invitation mail sent successfully.
                </div>
              );
              document.getElementById("hidelod").style.display = "none";
              this.setState({ InvitePopup: false });
              this.setState({ ListUpdate: !this.state.ListUpdate });
            } else {
              toast.error(res.data.Message);
              this.setState({BtnDisabled:false})

            }
            this.componentDidMount();
          });
          this.setState({ InvitePopup: false });
          document.getElementById("hidelod").style.display = "none";
          this.setState({BtnDisabled:false})

        });
      } else {
        this.setState({ Message: "Invalid email" });
        this.setState({BtnDisabled:false})
      }
    } else {
      this.setState({BtnDisabled:false})
      document.getElementById("hidelod").style.display = "none";
    }
  }


  
  // Function to validate individual form field
async ValidateField(fieldName, value) {
  const newErrors = { ...this.state.errors };
  
  switch (fieldName) {
    case "Email":
      const trimmedValue = value.trim();
      newErrors.Email = !trimmedValue ? "Please enter email." : EmailValidation(trimmedValue) ? "" : "Invalid email.";
  
      // Check if the email already exists
      if (newErrors.Email === "") {
        const emailExists = await this.CheckAdminInvite(trimmedValue);
        if (!emailExists) {
          newErrors.Email = "Email already exists.";
        }
      }
  
      break;
  
    default:
      break;
  }
  
  this.setState({ errors: newErrors });
  }
  
  //Update OnChange Handler
  HandleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  this.setState((prevState) => {
    let updatedFormData = { ...prevState.FormData };
  
    if (type === "checkbox") {
      // For the checkbox, store the boolean value directly
      updatedFormData[name] = checked;
    } else {
      // For other fields, store their values
      updatedFormData[name] = value;
    }
  
    return {
      FormData: updatedFormData,
    };
  });
  };
  
  //Update OnBlur Handler
  HandleBlur = (e) => {
  const { name, value } = e.target;
  
  this.ValidateField(name, value);
  };
  
  //Send Invite
  HandleSubmit = async (e) => {
  e.preventDefault();
  this.setState({ ButtonDisabled: true,IsLoading:true });
  
  const { FormData } = this.state;
  const fieldNames = Object.keys(FormData);
  let isValid = true;
  
  fieldNames.forEach((fieldName) => {
    this.ValidateField(fieldName, FormData[fieldName]);
    if (this.state.errors[fieldName]) {
      isValid = false;
    }
  });
  
  if (isValid && this.state.FormData.Email?.trim() !== "") {
    let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);
  let Email = this.state.FormData.Email;
  var FinalEmail = CryptoJS.AES.encrypt(JSON.stringify(Email),REACT_APP_ENCRYPTION_SALT).toString();
  var UID = CryptoJS.AES.encrypt(JSON.stringify(this.state.UserID),REACT_APP_ENCRYPTION_SALT).toString();
  var CID = CryptoJS.AES.encrypt(JSON.stringify(this.state.ClientID),REACT_APP_ENCRYPTION_SALT).toString();

  var EmailData = EmailTemplete.userEmailInvite(
    FinalEmail,
    CID,
    UID,
    CommonConstants.usersperclient_invitation
  );
  var str_mail = {
    Email: Email,
    html: EmailData,
    subject: "SalesHive User Account",
  };
  Axios({
    url: CommonConstants.MOL_APIURL + "/user/profileemail",
    method: "POST",
    data: str_mail,
  }).then((res) => {
    let InputParameters = {
      Email: Email,
      UserID: this.state.UserID,
      ClientID: this.state.ClientID,
      CreatedDate: new Date(),
      CreatedBy: this.state.UserID,
      VerificationCode: VerificationCodeChar,
      Role: this.state.Role,
    };
    Axios({
      url:CommonConstants.MOL_APIURL +"/users_per_client/UserPerClientInvite",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Users Per Client <br />
            Invitation mail sent successfully.
          </div>
        );
        this.setState({ InvitePopup: false });
      } else {
        toast.error(res.data.Message);
        this.setState({BtnDisabled:false})
      }
    });
  });
                       
                      
        this.setState({ ButtonDisabled: true,IsLoading:false });
    // You can submit the form data to your server or perform other actions here
  } else {
    this.setState({ ButtonDisabled: false,IsLoading:false });
  }
  };


  render() {
    const { FormData, errors } = this.state;

    return (
      <>
        <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" class="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone p-4">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Email</label>
                  <div class="col-lg-8">
                    <input
                      class="form-control m-input"
                      id="EmailInvite"
                      name="Email"
                      placeholder="Enter Email"
                      type="text"
                      value={FormData.Email}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.Email && (
                        <div className="errorinput">{errors.Email}</div>
                      )}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="m_emailInvite_submit"
                  onClick={this.HandleSubmit}
                  disabled={this.state.ButtonDisabled}
                  class="btn btn-primary btnColor"
                >
                  Send{" "}
                </button>
              </div>
            </div>
          </div>
        </Popupone>
        
        {
          this.state.IsLoading ? <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
        }

        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col padd-0 ">
                <h4 className="headertitlebd">Users Per Client</h4>
              </div>
              <div className="col px-5">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li className="pt-1">
                      <a
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        onClick={this.OpenInvitePopup}
                      >
                        <i class="la la-plus la-icon-font-size-13"></i>
                        <span>Invite</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="devider"></span>
            <div className="col padd-0">
              <UsersPerClientTabing listupdate={this.state.ListUpdate} />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedUsersPerClientPage = connect(
  mapState,
  actionCreators
)(UsersPerClientPage);
export { connectedUsersPerClientPage as UsersPerClientPage };
