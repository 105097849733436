import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../Kpis/LastdaysTabing';

import DomainTable from '../../../_components/user/GlobalBlackList/DomainTable';
import ContactTable from '../../../_components/user/GlobalBlackList/ContactTable';  
 
import ImageUploading from 'react-images-uploading';
import Popup from "reactjs-popup";

import { history } from "../../../_helpers";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);

  useEffect( () => {
    document.title = "Global Blacklist | SalesHive"
    
    if(props?.propdata?.location?.state !== undefined){
      if(props?.propdata?.location?.state?.page == "contact"){
        setValue(1)
      }else{
        setValue(0)
      }
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    
    setImages(imageList);
  }; 
  const openTermsConditionPopup = () => {
    //
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };


  // document.addEventListener('mouseup', function(e) {
  //     
  //     //
  //     if(popupval){
  //       setpopup(false);
  //     }
  // });
    //const openTermsConditionPopup = false;
   const addpage=()=> {
      history.push("/addglobalblacklist");
    }
    const addContactpage=()=> {
      history.push("/addglobalblacklistcontact");
    }

  return (
    <div className="tabdes"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"> 
            <Tab label="Domain" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Contact" {...a11yProps(1)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
            <div className="row">
              <div className="col border-bottom">
                  <h4 className="headertitle float-left">Domain</h4>   
              </div> 
              <div className="col border-bottom">
                  <div className="listing-li float-right">
                      <ul>
                       <li>
                       <a
                        onClick={() => addpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                        </li> 
                      </ul>
                  </div>
              </div>
            </div>


           

            <div className="row">
            <div className="col">
              <div className="table-bordered">
                <DomainTable />
              </div>
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={1}>
            <div className="row">
              <div className="col border-bottom">
                  <h4 className="headertitle float-left">Contact</h4>   
              </div> 
              <div className="col border-bottom">
                  <div className="listing-li float-right padb-15">
                      <ul>
                       <li>
                       <a
                        onClick={() => addContactpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                        </li> 
                      </ul>
                  </div>
              </div>
            </div>



            <div className="row">
            <div className="col">
              <div className="table-bordered">
                <ContactTable />
              </div>
            </div>
          </div> 
        </TabPanel>
 
      </div>  
    </div>
  );
}