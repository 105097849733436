import React, { useEffect } from 'react';
import Axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@material-ui/lab/Pagination";
toast.configure();

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



export default function AccountAllTable({ listupdate, updateFromChild }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsExist, SetIsExist] = React.useState(false);
  const [EditData, SetEditData] = React.useState([]);
  const [OldCloserName, SetOldCloserName] = React.useState("");
  const [Fields, SetFields] = React.useState({});
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsDisabled, SetIsDisabled] = React.useState(false);
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    DefaultVariable(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage, listupdate]);




  // Default variable get list
  const DefaultVariable = (CID, UID, URole) => {
    document.getElementById("hideloding").style.display = "block";

    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      FieldName: "Closer"
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  const SortData = (Field) => {
    document.getElementById("hideloding").style.display = "block";

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search: SearchedVal,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        FieldName: "Closer"
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";

      });
    } else {
      document.getElementById("hideloding").style.display = "block";

      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        FieldName: "Closer"
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";

      });
    }
  };

  // delete
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Closer .",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var InputParameter = {
          ID: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesDelete",
          method: "POST",
          data: InputParameter,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Closer deleted successfully.",
                "success"
              );
              DefaultVariable(ClientID, UserID, Role);
              updateFromChild(true);

            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit desing change
  const EditCloserName = (AutomatorFeatureID) => {
    document.getElementById(
      AutomatorFeatureID + "labelbox"
    ).style.display = "none";
    document.getElementById(
      AutomatorFeatureID + "text"
    ).style.display = "block";
    document.getElementById(
      AutomatorFeatureID + "editicon1"
    ).style.display = "none";
    document.getElementById(
      AutomatorFeatureID + "saveicon1"
    ).style.display = "block";
    document.getElementById(
      AutomatorFeatureID + "editicon2"
    ).style.display = "none";
    document.getElementById(
      AutomatorFeatureID + "saveicon2"
    ).style.display = "block";

    // document.getElementById(id).style.display = "block";

    var str_in = {
      AutomatorFeatureID: AutomatorFeatureID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById(AutomatorFeatureID + "text").value =
          res.data.Data.FeatureValue;
        SetOldCloserName(res.data.Data.FeatureValue);
        SetEditData(res.data.Data);
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  const HideEditCloser = (AutomatorFeatureID) => {
    document.getElementById(
      AutomatorFeatureID + "labelbox"
    ).style.display = "block";
    document.getElementById(
      AutomatorFeatureID + "text"
    ).style.display = "none";
    document.getElementById(
      AutomatorFeatureID + "editicon1"
    ).style.display = "block";
    document.getElementById(
      AutomatorFeatureID + "saveicon1"
    ).style.display = "none";
    document.getElementById(
      AutomatorFeatureID + "editicon2"
    ).style.display = "block";
    document.getElementById(
      AutomatorFeatureID + "saveicon2"
    ).style.display = "none";
  };

  // Update Closer data
  const UpdateCloserBtn = async (e, id) => {
    e.preventDefault();
    e.disabled = true;
    document.getElementById("hideloding").style.display = "block";
    document.getElementById(id + "saveicon1").disabled = true;

    var FeatureValue = document.getElementById(id + "text").value;
    if (FeatureValue.trim() != "") {
      SetIsDisabled(true)
      var Inputparameter = {
        UserID: UserID,
        FeatureValue: FeatureValue,
        FieldName: "Closer"
      };
      let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
        method: "POST",
        data: Inputparameter,
      })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (OldCloserName !== resdata.data.Data[0].FeatureValue) {
            toast.error(<div className="toastsize" >Closer<br />Closer value already exist</div>)
            document.getElementById("hideloding").style.display = "none";
            document.getElementById(id + "saveicon1").disabled = false;
            SetIsDisabled(false)

          } else {
            SetIsDisabled(true)
            var InputParameter = {
              ID: id,
              FeatureValue: FeatureValue,
              LastUpdatedDate: new Date(),
              LastUpdatedBy: CUserID
            };

            Axios({
              url:
                CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesUpdate",
              method: "POST",
              data: InputParameter,
            }).then((result) => {
              if (result.data.StatusMessage == "SUCCESS") {
                toast.success(<div className="toastsize" >Closer<br />Closer updated successfully.</div>);
                HideEditCloser(id);
                DefaultVariable(ClientID, UserID, Role);
                updateFromChild(true);
                document.getElementById(id + "saveicon1").disabled = false;
                SetIsDisabled(false)
              } else {
                toast.error(result.data.Message);
                document.getElementById("hideloding").style.display = "none";
                document.getElementById(id + "saveicon1").disabled = false;
                SetIsDisabled(false)
              }
            });
          }
          document.getElementById("hideloding").style.display = "none";

        } else {
          SetIsDisabled(true)
          var InputParameter = {
            ID: id,
            FeatureValue: FeatureValue,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesUpdate",
            method: "POST",
            data: InputParameter,
          }).then((result) => {
            if (result.data.StatusMessage == "SUCCESS") {
              toast.success(<div className="toastsize" >Closer<br />Closer updated successfully.</div>);
              HideEditCloser(id);
              DefaultVariable(ClientID, UserID, Role);
              updateFromChild(true);
              document.getElementById("hideloding").style.display = "none";
              document.getElementById(id + "saveicon1").disabled = false;
              SetIsDisabled(false)
            } else {
              toast.error(result.data.Message);
              document.getElementById("hideloding").style.display = "none";
              document.getElementById(id + "saveicon1").disabled = false;
              SetIsDisabled(false)
            }
          });
        }
      }

    } else {
      toast.error(<div className="toastsize" >Closer<br />Please enter value</div>)
      document.getElementById("hideloding").style.display = "none";
      document.getElementById(id + "saveicon1").disabled = false;
      SetIsDisabled(false)
    }

    document.getElementById("hideloding").style.display = "none";
    document.getElementById(id + "saveicon1").disabled = false;
    SetIsDisabled(false)
  };

  return (
    <>
      <div id="hideloding" className="loding-display" style={{ display: "none" }}>
        <img src={loadingicon} />
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell><a
                onClick={() => {
                  SortData("FeatureValue");
                }}
              >Closer</a></TableCell>
              <TableCell><a
                onClick={() => {
                  SortData("CreatedDate");
                }}
              >Created Date</a></TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.length == 0 ? (
              <p className="text-center">No data in Available</p>
            ) : (
              Rows?.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>
                    <label
                      id={row._id + "labelbox"}
                    >
                      {row.FeatureValue}{" "}
                    </label>{" "}
                    <input
                      type="textbox"
                      id={row._id + "text"}
                      className="form-control m-input"
                      style={{ display: "none" }}
                      value={Fields["FeatureValue"]}
                      autocomplete="off"
                    />
                  </TableCell>
                  <TableCell>
                    {moment(new Date(row.CreatedDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>
                  <TableCell className='d-flex'>
                    <a title='Edit'
                      onClick={() => {
                        EditCloserName(row._id);
                      }}
                      id={row._id + "editicon1"}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a title='Delete'
                      onClick={() => {
                        DeleteBtn(
                          row._id,
                          row.FeatureValue
                        );
                      }}
                      id={row._id + "editicon2"}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                    <button title='Save'
                      onClick={(e) => {
                        UpdateCloserBtn(
                          e,
                          row._id
                        );
                      }}
                      style={{ display: "none" }}
                      id={row._id + "saveicon1"}
                      className="btn-eyesicon"
                      disabled={IsDisabled}
                    >
                      <i className="la la-save"></i>
                    </button>
                    <a title='Cancel'
                      onClick={() => {
                        HideEditCloser(row._id);
                      }}
                      style={{ display: "none" }}
                      id={row._id + "saveicon2"}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-cancel"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
