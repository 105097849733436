import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
const moment = require("moment");
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";


class CEditAccountsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ID: this.props.location.state?.data,
      Fields: {},
      Errors: {},
      CommonID: this.props.location.state?.data,
      TitleAvailable: null,
      showing: true,
      allres: [],
      data: [],
      rows: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      data1: [],
      rows1: [],
      rlen1: 0,
      flen1: 0,
      countpage1: 0,
      AccountIDs: "",

      ClientID: null,
      UserID: null,
      UserIDby: null,
      page: 1,
      rowsPerPage: 10,
      search: "",
      sortField: "Name",
      sortedBy: 1,
      sflag: false,
      page1: 1,
      rowsPerPage1: 10,
      search1: "",
      sortField1: "Name",
      sortedBy1: 1,
      Role:null,

      sflag1: false,
      MeetingOwner: [],
      AccountStatus: [],
      SalesreplyAccountType: [],
      SalesreplyAccountCategory: [],
      SalesreplyAccountStatus: [],
      ReasonUnqualified: [],
      salesreplyaccountid: "",
      DefaultMeetingOwner: "",
      DefaultAccountStatus: "",
      DefaultSalesreplyAccountType: "",
      DefaultSalesreplyAccountCategory: "",
      DefaultSalesreplyAccountStatus: "",
      DefaultReasonUnqualified: "",
      AccountCustomField: [],
      AccountCustomfieldbyid: [],

      DataSecond: [],
      RowsSecond: [],
      RlenSecond: 0,
      FlenSecond: 0,
      CountPageSecond: 0,

      SflagSecond: false,
      PageSecond: 1,
      RowsPerPageSecond: 10,
      SearchSecond: "",
      SortFieldSecond: "FirstName",
      SortedBySecond: 1,
      ContactDomain: "",

      AccountData:[],

      // ID: "",
      AddBlackListID: "",
      SalesAccountID: "",
      // AccountCustomField: [],
      AccountCustomfieldbyid: [],
      Open: false,
      NotesData: [],


      // Account status history
      Hsflag: false,
      Hpage: 1,
      HistoryData: [],
      HistoryRows: [],
      HRlen: 0,
      HFlen: 0,
      HCountPage: 0,
      PageHistory: 1,
      RowsPerPageHistory: 10,
      SearchHistory: "",
      SortFieldHistory: "Status",
      SortedByHistory: 1,
      SflagHistory: false,
      OldAccountStatusID: "",

      // Note
      Page: 1,
      RowsPerPage: 10,
      Search: "",
      SortField: "Title",
      SortedBy: 1,
      Sflag: false,
      Data: [],
      Rows: [],
      Rlen: 0,
      Flen: 0,
      CountPage: 0,
      SearchData: [],
      ShowNotePopup: false,
      TitleAvailableNotes:null,
      Fields1: {},
      Errors1: {},
      PageType:"",

      //list
      ListData: [],
      ListRows: [],
      LRlen: 0,
      LFlen: 0,
      LCountPage: 0,
      PageL: 1,
      RowsPerPageL: 10,
      SearchL: "",
      SortFieldL: "Name",
      SortedByL: 1,
      SflagL: false,

      //potetial
      PlistData: [],
      PlistRows: [],
      PlRlen: 0,
      PlFlen: 0,
      PlCountPage: 0,
      PageP: 1,
      RowsPerPageP: 10,
      SearchP: "",
      SortFieldP: "FirstName",
      SortedByP: 1,
      SflagP: false,
      PotentialNotes: [],

      //Meeting
      MlistData: [],
      MlistRows: [],
      MlRlen: 0,
      MlFlen: 0,
      MlCountPage: 0,
      PageM: 1,
      RowsPerPageM: 10,
      SearchM: "",
      SortFieldM: "FirstName",
      SortedByM: 1,
      SflagM: false,
      MeetingNotes: [],

      //call history
      CHlistData: [],
      CHlistRows: [],
      CHlRlen: 0,
      CHlFlen: 0,
      CHlCountPage: 0,
      PageCH: 1,
      RowsPerPageCH: 10,
      SearchCH: "",
      SortFieldCH: "FirstName",
      SortedByCH: 1,
      SflagCH: false,

      //campaign
      CAlistData: [],
      CAlistRows: [],
      CAlRlen: 0,
      CAlFlen: 0,
      CAlCountPage: 0,
      PageCA: 1,
      RowsPerPageCA: 10,
      SearchCA: "",
      SortFieldCA: "FirstName",
      SortedByCA: 1,
      SflagCA: false,

      AccountStats: [],
      fields: {},
      errors: {},

             // campaign history data
             CampaignHistoryData: [],
             CAMRows: [],
             CAMRlen: 0,
             CAMFlen: 0,
             CAMCountPage: 0,
             CAMPage: 1,
             CAMRowsPerPage: 15,
             CAMSearch: "",
             CAMSortField: "CampaignName",
             CAMSortedBy: 1,
             CAMSflag: false,
             AccountStatusHistoryData:[],
             AccountStatsDataArray: []
    };

    this.backbtn = this.backbtn.bind(this);
    //  this.editbtn = this.editbtn.bind(this);
    // this.editspage = this.editspage.bind(this);
    // this.saveupdatebtn = this.saveupdatebtn.bind(this);
    this.EditLists = this.EditLists.bind(this);
    this.ListsAdd = this.ListsAdd.bind(this);
    // this.savebtn = this.savebtn.bind(this);
    this.updateData = this.updateData.bind(this);
    this.AccountNoteEditBtn = this.AccountNoteEditBtn.bind(this);
    this.AccountNoteSave = this.AccountNoteSave.bind(this);
    this.AccountNoteUpdate = this.AccountNoteUpdate.bind(this);
    this.HandleChangeOwner = this.HandleChangeOwner.bind(this);
    this.HandleChangeAccountStatus = this.HandleChangeAccountStatus.bind(this);
    this.HandleChangeSalesreplyAccountType =
      this.HandleChangeSalesreplyAccountType.bind(this);
    this.HandleChnageSalesreplyAccountCategory =
      this.HandleChnageSalesreplyAccountCategory.bind(this);
    this.HandleChangeSalesreplyAccountStatus =
      this.HandleChangeSalesreplyAccountStatus.bind(this);
    this.HandleChangeReasonUnqualified =
      this.HandleChangeReasonUnqualified.bind(this);
    this.ContactEdit = this.ContactEdit.bind(this);
    this.ContactAdd = this.ContactAdd.bind(this);

    this.PotenialEditPages = this.PotenialEditPages.bind(this);
    this.PotentialAdd = this.PotentialAdd.bind(this);
    this.EditCampaigns = this.EditCampaigns.bind(this);
    this.CampaignsAdd = this.CampaignsAdd.bind(this);

    this.MeetingEditPage = this.MeetingEditPage.bind(this);
    this.MeetingAdd = this.MeetingAdd.bind(this);
    //this.DeleteClientName = this.DeleteClientName.bind(this);
    var Details = GetUserDetails();
    const id = this.props.location.state.data;

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.GetAccountStatus();
    this.EditAccountstatus();
    this.GetAllStatus();
    this.AccountStatusHistoryGet();
    this.GetAccountCustomfieldbyid();
    // this.CustomFieldStatus();
  }
  componentDidMount() {
    document.title = `Edit Account | SalesHive`;
    this.CampaignHistoryGet(this.state.CAMSearch);
    this.AccountStatusHistoryGetQWE(this.state.ClientID,this.state.UserID)
    this.AccountStatsData(this.state.ClientID,this.state.UserID)
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.ContactDomain !== this.state.ContactDomain ||
      prevState.SearchCH !== this.state.SearchCH ||
      prevState.RowsPerPageCH !== this.state.RowsPerPageCH ||
      prevState.PageCH !== this.state.PageCH ||
      prevState.SearchP !== this.state.SearchP ||
      prevState.RowsPerPageP !== this.state.RowsPerPageP ||
      prevState.PageP !== this.state.PageP ||
      prevState.SearchL !== this.state.SearchL ||
      prevState.RowsPerPageL !== this.state.RowsPerPageL ||
      prevState.PageL !== this.state.PageL ||
      prevState.SearchM !== this.state.SearchM ||
      prevState.RowsPerPageM !== this.state.RowsPerPageM ||
      prevState.PageM !== this.state.PageM ||
      prevState.SearchCA !== this.state.SearchCA ||
      prevState.RowsPerPageCA !== this.state.RowsPerPageCA ||
      prevState.PageCA !== this.state.PageCA ||
      prevState.RowsPerPage !== this.state.RowsPerPage ||
      prevState.Page !== this.state.Page
    ) {
      this.GetContactList(
        this.state.ContactDomain,
        this.state.SearchSecond,
        this.state.PageSecond,
        this.state.RowsPerPageSecond
      );
      this.ListsGet(
        this.state.ContactDomain,
        this.state.SearchL,
        this.state.PageL,
        this.state.RowsPerPageL
      );
      this.PotentialListsGet(
        this.state.ContactDomain,
        this.state.SearchP,
        this.state.PageP,
        this.state.RowsPerPageP
      );
      this.MeetingListsGet(
        this.state.ContactDomain,
        this.state.SearchM,
        this.state.PageM,
        this.state.RowsPerPageM
      );
      this.CallHistroyGet(
        this.state.ContactDomain,
        this.state.SearchCH,
        this.state.PageCH,
        this.state.RowsPerPageCH
      );
      this.CampaignsAccountGet(
        this.state.ContactDomain,
        this.state.SearchCA,
        this.state.PageCA,
        this.state.RowsPerPageCA
      );
      this.NotesDetails(
        this.state.ContactDomain,
        this.state.Search,
        this.state.Page,
        this.state.RowsPerPage
      );
    }
  }
  AccountStatsData(CID,UID) {
    const id = this.props.location.state.data;
    var InputParaMeter ={
      ClientID:CID,
      UserID:UID,
      SalesReplyAccountID:id,
      Role:this.state.Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountStatsDataGet",
      method: "POST",
      data: InputParaMeter,
    });
    rows.then((result) => {
      this.setState({AccountStatsDataArray:result.data?.AccountStatsData})
    });
  }
    //  Account last details Get
    async AccountStatusHistoryGetQWE  (CID,UID){
      const id = this.props.location.state.data;
      var InputParaMeter ={
        ClientID:CID,
        UserID:UID,
        Role:this.state.Role,
        SalesReplyAccountID:id
      };
      const rows = await Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AccountLastStatusHistoryGet",
        method: "POST",
        data: InputParaMeter,
      });
      // rows.then((Result) => {

        var temArray = rows.data?.PageData
        this.setState({ AccountStatusHistoryData: temArray });
      // });
    }
  GetAccountStatus() {
    var data = {
      Role:this.state.Role,
      ClientID: this.state.ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAllStatus",
      method: "POST",
      data: data,
    }).then((res) => {
      this.setState({
        MeetingOwner: res.data.MeetingOwner,
        AccountStatus: res.data.AccountStatus,
        SalesreplyAccountType: res.data.SalesreplyAccountType,
        SalesreplyAccountCategory: res.data.SalesreplyAccountCategory,
        SalesreplyAccountStatus: res.data.SalesreplyAccountStatus,
        ReasonUnqualified: res.data.ReasonUnqualified,
      });
    });
  }

  EditAccountstatus() {
    const id = this.state?.ID;
    var str_in = {
      Role:this.state.Role,
      _id: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ allres: [res.data] });

      this.setState({ DefaultMeetingOwner: res.data[0]?.AccountOwnerID });
      this.setState({ DefaultAccountStatus: res.data[0]?.AccountStatusID });
      this.setState({
        DefaultSalesreplyAccountType: res.data[0]?.AccountCategoryID,
      });
      this.setState({
        DefaultSalesreplyAccountCategory: res.data[0]?.AccountCategoryID,
      });
      this.setState({
        DefaultSalesreplyAccountStatus: res.data[0]?.AccountClientStatusID,
      });
      this.setState({
        DefaultReasonUnqualified: res.data[0]?.ReasonUnqualifiedID,
      });
      this.setState({
        OldAccountStatusID: res.data[0]?.AccountStatusID,
      });
      this.setState({
        ContactDomain: res.data[0]?.Salesreply_Accountdetails?.Domain,
      });
       //notes
       this.setState({ AccountIDs: res.data[0]?._id });


      //Account Stats field
      var str_in9 = {
        ClientID: parseInt(this.state.ClientID),
        Role:this.state.Role,
        SalesReplyAccountID: res.data[0]?._id,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AccountStatsGet",
        method: "POST",
        data: str_in9,
      }).then((Resaccount) => {

        this.setState({
          AccountStats: Resaccount.data?.AccountStatsData,
        });
      });

      //notes
      this.setState({ salesreplyaccountid: res.data[0]._id });
      document.getElementById("hideloding").style.display = "none";
    });
  }

 // Campaign history get
  CampaignHistoryGet(CAMSearch) {
        const id = this.props.location.state.data;
        var Details = GetUserDetails();
        var CampaignHistoryDataStr = {
          Page: this.state.CAMPage,
          SalesReplyAccountID:id,
          RowsPerPage: this.state.CAMRowsPerPage,
          Sort: true,
          Field: this.state.CAMSortField,
          SortBy: this.state.CAMSortedBy,
          Search: CAMSearch,
          ClientID: Details.ClientID,
          Role:this.state.Role,
          UserID:Details.ParentUserID,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/CampaignAccountHistoryGet",
          method: "POST",
          data: CampaignHistoryDataStr,
        }).then((result) => {
          this.setState({ CampaignHistoryData: result.data?.PageData });
          this.setState({ CAMRows: result.data?.PageData });
          this.setState({ CAMRlen: result.data?.TotalCount });
          this.setState({ CAMFlen: result.data?.TotalCount });
          this.setState({ CAMCountPage: result.data?.PageCount });
        });
     }

//   CustomFieldStatus(){
//  //custom field
//  var str_in9 = {
//   SalesReplyAccountID: this.props.location.state?.data,
// };
// Axios({
//   url: CommonConstants.MOL_APIURL + "/accounts/AccountCustomFieldId",
//   method: "POST",
//   data: str_in9,
// }).then((resmeeting) => {
//
//   this.setState({
//     AccountCustomfieldbyid: resmeeting.data?.AccountcustomfieldByid,
//   });

//   {resmeeting.data?.AccountcustomfieldByid.map((val) => {
//     document.getElementById(val?.CustomFieldID).value=val?.FieldValue
//   })}
// });
//   }


  // CheckExistPotentialMeetingTitle(Title) {
  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     Title: Title,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountExists",
  //     method: "POST",
  //     data: str_in,
  //   }).then((resdata) => {
  //     if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.data.length > 0) {
  //         this.setState({ statusAvailable: resdata.data.data });
  //       }
  //     }
  //   });
  // }

  GetAllStatus() {
    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      Role:this.state.Role,
      UserID: Details.ParentUserID,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAllStatus",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      this.setState({
        AccountCustomField: result.data.AccountCustomField,
      });
      // var str_in9 = {
      //   Role:this.state.Role,
      //   SalesReplyAccountID: this.props.location.state?.data,
      // };
      // Axios({
      //   url: CommonConstants.MOL_APIURL + "/accounts/AccountCustomFieldId",
      //   method: "POST",
      //   data: str_in9,
      // }).then((resmeeting) => {
      //   //
      //   this.setState({
      //     AccountCustomfieldbyid: resmeeting.data?.AccountcustomfieldByid,
      //   });

      //   {resmeeting.data?.AccountcustomfieldByid.map((val) => {
      //     document.getElementById(val?.CustomFieldID).value=val?.FieldValue
      //   })}
      // });
    })
  }

  GetAccountCustomfieldbyid() {
    // var Details = GetUserDetails();
    //custom field
    var str_in9 = {
      Role:this.state.Role,
      SalesReplyAccountID: this.props.location.state.data,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountCustomFieldId",
      method: "POST",
      data: str_in9,
    }).then((resmeeting) => {
      this.setState({
        AccountCustomfieldbyid: resmeeting.data?.AccountcustomfieldByid,
      });
    });
  }

  backbtn() {
    if(this.props.location.state["Pagename"] == "Potential"){
      history.push("/cpotentialmeetings");
    }else if(this.props.location.state["Pagename"] == "PotentialLinkedin"){
      history.push("/clinkedinfollowup");
    }else{
      history.push("/caccounts");
    }
  }

  async FromValidation() {
    this.setState({ InvalidDomain: false });
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};

    var Domain = document.getElementById("Domain").value.trim();
    // var IsExist = await this.CheckDomain(Domain)
    if (Domain == "") {
      formIsValid = false;
      errors["Domain"] = "Please enter email domain.";
    }
    // if(IsExist == true){
    //   formIsValid = false;
    // }
    // if(this.state.InvalidDomain==true){
    //   formIsValid = false;
    //   errors["Domain"] = "Invalid email domain";
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }

  //Check domain
  async CheckDomain(Domain){

    let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    let Errors = {};
    if (!regexp.test(Domain) && Domain!="") {
      Errors["Domain"] = "Invalid email domain";
      this.setState({ errors: Errors });
      this.setState({ InvalidDomain: true });
      return true;
    } else {
      return false;
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    var Domain = document.getElementById("Domain").value.trim();
    if (fields.Domain != "") {
      this.state.errors.Domain = null;
      // this.state.statusAvailable = null;
      this.CheckDomain(Domain);
    } else {
      // this.state.statusAvailable = null;
      this.state.errors.Domain = null;
    }
  }

  async updateData(e) {
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabled:true});
    var FormValidationFlag = await this.FromValidation()
    if (FormValidationFlag === true) {
    // e.preventDefault();

    var AccountName = document.getElementById("AccountName").value;
    var EmailDomains = document.getElementById("Domain").value;
    var Website = document.getElementById("Website").value;
    var AccountOwner = document.getElementById("AccountOwner").value;
    var AccountCategory = document.getElementById("AccountCategory").value;
    var AccountStatus = document.getElementById("AccountStatus").value;
    var Industry = document.getElementById("Industry").value;
    var Address1 = document.getElementById("Address1").value;
    var Address2 = document.getElementById("Address2").value;
    var City = document.getElementById("City").value;
    var State = document.getElementById("State").value;
    var Country = document.getElementById("Country").value;
    var Zip = document.getElementById("Zip").value;
    var ReasonUnqualified = document.getElementById("ReasonUnqualified").value;
    var AccountType = document.getElementById("AccountType").value;
    var AccountClientStatus = document.getElementById(
      "AccountClientStatus"
    ).value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var CompanyRevenue = document.getElementById("CompanyRevenue").value;
    var EmployeeCount = document.getElementById("EmployeeCount").value;

    var AccountCustomFieldValueArray = [];

      this.state.AccountCustomField.map((value) => {
        let customval = document.getElementById(value._id).value;
        let customvalid = value._id;
        if (customval.length !== 0) {
          AccountCustomFieldValueArray.push({
            customval,
            customvalid,
          });
        }
      })

    const data = {
      id: this.state?.CommonID,
      ClientID:this.state.ClientID,
      UserID:this.state.UserID,
      AccountName: AccountName,
      EmailDomains: EmailDomains,
      Website: Website,
      AccountOwnerID: AccountOwner,
      AccountCategoryID: AccountCategory,
      AccountStatusID: AccountStatus,
      Industry: Industry,
      Address1: Address1,
      Address2: Address2,
      City: City,
      State: State,
      Country: Country,
      Zip: Zip,
      ReasonUnqualifiedID: ReasonUnqualified,
      AccountTypeID: AccountType,
      AccountClientStatusID: AccountClientStatus,
      CompanyPhone: CompanyPhone,
      CompanyRevenue: CompanyRevenue,
      EmployeeCount: EmployeeCount,
      AccountCustomDetails: AccountCustomFieldValueArray,
      Role:this.state.Role,
      OldAccountStatusID: this.state.OldAccountStatusID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountUpdate",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Update Accounts successfully.",
          "Apdate Accounts Status"
        );
        if(this.props.location.state["Pagename"] == "ClientAccount"){
          history.push({ pathname: "/cviewaccounts", state: { data: this.state?.CommonID,Pagename:"ClientAccount"} });
        }else if(this.props.location.state["Pagename"] == "Potential"){
          history.push({ pathname: "/cviewaccounts", state: { data: this.state?.CommonID,Pagename:"Potential"} });
        }else{
          history.push({ pathname: "/cviewaccounts", state: { data: this.state?.CommonID,Pagename:""  } });
        }
      } else {
        toast.error(res.data.Message);
        this.setState({IsButtonDisabled:false});
      }
    });
  }else{
    document.getElementById("submit").disabled = false;
    this.setState({IsButtonDisabled:false});
  }
  }

  // To set default
  HandleChangeOwner(e) {
    this.setState({ DefaultMeetingOwner: e.target.DefaultMeetingOwner });
  }
  HandleChangeAccountStatus(e) {
    this.setState({
      DefaultAccountStatus: e.target.DefaultAccountStatus,
    });
  }
  HandleChangeSalesreplyAccountType(e) {
    this.setState({
      DefaultSalesreplyAccountType: e.target.DefaultSalesreplyAccountType,
    });
  }
  HandleChnageSalesreplyAccountCategory(e) {
    this.setState({
      DefaultSalesreplyAccountCategory:
        e.target.DefaultSalesreplyAccountCategory,
    });
  }
  HandleChangeSalesreplyAccountStatus(e) {
    this.setState({
      DefaultSalesreplyAccountStatus: e.target.DefaultSalesreplyAccountStatus,
    });
  }
  HandleChangeReasonUnqualified(e) {
    this.setState({
      DefaultReasonUnqualified: e.target.DefaultReasonUnqualified,
    });
  }

  //Contact list
  GetContactList(ContactList, Search, Page, RowPage) {
    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      Domain: ContactList,
      Page: parseInt(Page),
      RowsPerPage: parseInt(RowPage),
      Sort: true,
      Field: this.state.SortFieldSecond,
      Sortby: this.state.SortedBySecond,
      Role:this.state.Role,
      Search: Search,
      Type: "User",
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountContactGet",
      method: "POST",
      data: str_in,
    });
    rows.then((Result) => {
      this.setState({ DataSecond: Result.data?.PageData });
      this.setState({ RowsSecond: Result.data?.PageData });
      this.setState({ RlenSecond: Result.data?.TotalCount });
      this.setState({ FlenSecond: Result.data?.TotalCount });
      this.setState({ CountPageSecond: Result.data?.PageCount });
      this.setState({ NotesData: Result.data?.ProspectNote });
    });
  }

  //Sorting Contact list
  ContactSorting(Field){
    var Details = GetUserDetails();
    var SearchedVal = document.getElementById("searchContact").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      this.setState({ SflagSecond: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBySecond == 1) {
        SortBy = -1;
        this.setState({ SortedBySecond: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBySecond: 1 });
      }
      this.setState({ SortFieldSecond: Field });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: this.state.PageSecond ,
        RowsPerPage: this.state.RowsPerPageSecond,
        Sort: true,
        Field: this.state.SortFieldSecond,
        Sortby: this.state.SortedBySecond,
        Role:this.state.Role,
        Search: this.state.SearchSecond,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/SalesReplyAccountContactGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((Result) => {
        this.setState({ DataSecond: Result.data?.PageData });
        this.setState({ RowsSecond: Result.data?.PageData });
        this.setState({ RlenSecond: Result.data?.TotalCount });
        this.setState({ FlenSecond: Result.data?.TotalCount });
        this.setState({ CountPageSecond: Result.data?.PageCount });
        this.setState({ NotesData: Result.data?.ProspectNote });
      });
    } else {
      SerchBox = true;
      // SetSflag(true);
      this.setState({ SflagSecond: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBySecond == 1) {
        SortBy = -1;
        this.setState({ SortedBySecond: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBySecond: 1 });
      }
      this.setState({ SortFieldSecond: Field });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: this.state.PageSecond ,
        RowsPerPage: this.state.RowsPerPageSecond,
        Sort: true,
        Field: this.state.SortFieldSecond,
        Sortby: this.state.SortedBySecond,
        Role:this.state.Role,
        Search: this.state.SearchSecond,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/SalesReplyAccountContactGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((Result) => {
        this.setState({ DataSecond: Result.data?.PageData });
        this.setState({ RowsSecond: Result.data?.PageData });
        this.setState({ RlenSecond: Result.data?.TotalCount });
        this.setState({ FlenSecond: Result.data?.TotalCount });
        this.setState({ CountPageSecond: Result.data?.PageCount });
        this.setState({ NotesData: Result.data?.ProspectNote });
      });
    }
  }

  //row contact
  ChangeRowSelectedContact = (event) => {
    var Details = GetUserDetails();
    this.setState({ RowsPerPageSecond: Number(event.target.value) });
    this.setState({ PageSecond: 1 });
    var SearchedVal = document.getElementById("searchContact").value;
    var InputParameter = {
      ClientID: Details.ClientID,
      Domain: this.state.ContactDomain,
      Page: 1,
      RowsPerPage: Number(event.target.value),
      Sort: true,
      Field: this.state.SortFieldSecond,
      Sortby: this.state.SortedBySecond,
      Role:this.state.Role,
      Search: SearchedVal,
      Type: "User",
    };
    const AccountContactList = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accounts/SalesReplyAccountContactGet",
      method: "POST",
      data: InputParameter,
    });
    AccountContactList.then((Result) => {
      this.setState({ DataSecond: Result.data?.PageData });
      this.setState({ RowsSecond: Result.data?.PageData });
      this.setState({ RlenSecond: Result.data?.TotalCount });
      this.setState({ FlenSecond: Result.data?.TotalCount });
      this.setState({ CountPageSecond: Result.data?.PageCount });
      this.setState({ NotesData: Result.data?.ProspectNote });
    });
  };

  //Search
  RequestSearchContact = (Event) => {
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchContact").value;
      this.setState({ SearchSecond: SearchedVal });
      this.setState({ PageSecond: 1 });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: this.state.PageSecond ,
        RowsPerPage: this.state.RowsPerPageSecond,
        Sort: true,
        Field: this.state.SortFieldSecond,
        Sortby: this.state.SortedBySecond,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/SalesReplyAccountContactGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((Result) => {
        this.setState({ DataSecond: Result.data?.PageData });
        this.setState({ RowsSecond: Result.data?.PageData });
        this.setState({ RlenSecond: Result.data?.TotalCount });
        this.setState({ FlenSecond: Result.data?.TotalCount });
        this.setState({ CountPageSecond: Result.data?.PageCount });
        this.setState({ NotesData: Result.data?.ProspectNote });
      });
    }
  };

  //contact chnage page
  HandleChangePageContact = (Event, NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.PageSecond) {
      this.setState({ PageSecond: NewPage });
    } else {
      this.setState({ PageSecond: NewPage });
      var SearchedVal = document.getElementById("searchContact").value;
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: NewPage,
        RowsPerPage: this.state.RowsPerPageSecond,
        Sort: true,
        Field: this.state.SortFieldSecond,
        Sortby: this.state.SortedBySecond,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/SalesReplyAccountContactGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((Result) => {
        this.setState({ DataSecond: Result.data?.PageData });
        this.setState({ RowsSecond: Result.data?.PageData });
        this.setState({ RlenSecond: Result.data?.TotalCount });
        this.setState({ FlenSecond: Result.data?.TotalCount });
        this.setState({ CountPageSecond: Result.data?.PageCount });
        this.setState({ NotesData: Result.data?.ProspectNote });
      });
    }
  };

  // edit contact page
  ContactEdit = (id) => {
    // history.push("/editcontacts", id);
    history.push({pathname: "/ceditcontacts",
      state: { data: id,Pagename:"AccountContact",AccountData:this.state.AccountIDs},
    });
  };

  // Add contact page
  ContactAdd = () => {
    // history.push("/addcontacts");
    // var PushData = this.state.AccountData
    // PushData["Pagename"] = "AccountContact"
    // history.push("/addcontacts", PushData);
    history.push({pathname: "/caddcontacts",
      state: { data: this.state.AccountIDs,Pagename:"AccountContact"},
    });
  };

    //delete account
    DeleteClientAccount = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to Delete Account Meeting.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: this.state.AccountIDs,
            LastUpdatedDate: new Date(),
            Role:this.state.Role,
            LastUpdatedBy: this.state.UserIDby,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/accounts/DeleteAccountList",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire("Deleted!", "Delete Account successfully.", "success");
                history.push("/accounts");
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    };

 //black list
 AddToBlankAdd = () => {
  var str_in = {
    DomainName: this.state.ContactDomain,
    ClientID: this.state.ClientID,
    Role:this.state.Role,
    AccountID: this.state.CommonID,
    UserID:this.state.UserID
  };
  // 
  Axios({
    url: CommonConstants.MOL_APIURL + "/accounts/AccountToBlackList",
    method: "POST",
    data: str_in,
  }).then((res) => {
    if (
      res.data.StatusMessage == "SUCCESS" &&
      res.data.BlackListlength.length == 0
    ) {
      toast.success("Add BlackList successfully BlackListed!");
    } else {
      toast.error("Accounts already Blacklisted BlackListed!");
    }
  });
};

  // Account status history get
  AccountStatusHistoryGet() {
    var Details = GetUserDetails();
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var StatusHistoryData = {
      Page: this.state.page,
      ClientID: Details.ClientID,
      SalesReplyAccountID: id,
      RowsPerPage: this.state.RowsPerPageHistory,
      Role:this.state.Role,
      Sort: true,
      Field: this.state.SortFieldHistory,
      SortBy: this.state.SortedByHistory,
      Search: this.state.SearchHistory,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusHistoryGet",
      method: "POST",
      data: StatusHistoryData,
    }).then((reshistory) => {
      this.setState({ HistoryData: reshistory.data?.PageData });
      this.setState({ HistoryRows: reshistory.data?.PageData });
      this.setState({ HRlen: reshistory.data?.TotalCount });
      this.setState({ HFlen: reshistory.data?.TotalCount });
      this.setState({ HCountPage: reshistory.data?.PageCount });
    });
  }

  //Sorting Contact list
  HistorySatusSorting(Field){
      var Details = GetUserDetails();
      var SearchedVal = document.getElementById("searchaccountstatushistory").value;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        this.setState({ SflagHistory: false });
        var SortField = Field;
        var SortBy;
        if (this.state.SortedByHistory == 1) {
          SortBy = -1;
          this.setState({ SortedByHistory: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedByHistory: 1 });
        }
        this.setState({ SortFieldHistory: Field });
        var PListData = {
          Page: this.state.Hpage,
          ClientID: Details.ClientID,
          SalesReplyAccountID: id,
          RowsPerPage: this.state.RowsPerPageHistory,
          Role:this.state.Role,
          Sort: true,
          Field: this.state.SortFieldHistory,
          SortBy: this.state.SortedByHistory,
          Search: SearchedVal,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusHistoryGet",
          method: "POST",
          data: PListData,
        }).then((reshistory) => {
          this.setState({ HistoryData: reshistory.data?.PageData });
          this.setState({ HistoryRows: reshistory.data?.PageData });
          this.setState({ HRlen: reshistory.data?.TotalCount });
          this.setState({ HFlen: reshistory.data?.TotalCount });
          this.setState({ HCountPage: reshistory.data?.PageCount });
        });
      } else {
        SerchBox = true;
        // SetSflag(true);
        this.setState({ SflagHistory: true });
        var SortField = Field;
        var SortBy;
        if (this.state.SortedByHistory == 1) {
          SortBy = -1;
          this.setState({ SortedByHistory: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedByHistory: 1 });
        }
        this.setState({ SortFieldHistory: Field });
        var PListData = {
          Page: this.state.Hpage,
          ClientID: Details.ClientID,
          SalesReplyAccountID: id,
          RowsPerPage: this.state.RowsPerPageHistory,
          Role:this.state.Role,
          Sort: true,
          Field: this.state.SortFieldHistory,
          SortBy: this.state.SortedByHistory,
          Search: SearchedVal,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusHistoryGet",
          method: "POST",
          data: PListData,
        }).then((reshistory) => {
          this.setState({ HistoryData: reshistory.data?.PageData });
      this.setState({ HistoryRows: reshistory.data?.PageData });
      this.setState({ HRlen: reshistory.data?.TotalCount });
      this.setState({ HFlen: reshistory.data?.TotalCount });
      this.setState({ HCountPage: reshistory.data?.PageCount });
        });
      }
  }

  ChangeRowSelectedAccountStatusHistory = (event) => {
    var Details = GetUserDetails();
    this.setState({ RowsPerPageHistory: Number(event.target.value) });
    this.setState({ Hpage: 1 });
    var SearchVal = document.getElementById(
      "searchaccountstatushistory"
    ).value;
    this.setState({ Hpage: NewPage });
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var StatusHistoryData = {
        Page: 1,
        ClientID: Details.ClientID,
        SalesReplyAccountID: id,
        RowsPerPage: Number(event.target.value),
        Sort: true,
        Field: this.state.SortFieldHistory,
        SortBy: this.state.SortedByHistory,
        Role:this.state.Role,
        Search: SearchVal,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusHistoryGet",
        method: "POST",
        data: StatusHistoryData,
      }).then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HistoryRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
      });
  };

  RequestSearchContactHistory(event) {
    if (event.key === "Enter") {
      var SearchVal = document.getElementById(
        "searchaccountstatushistory"
      ).value;
      this.setState({ Page: 1 });
      this.setState({ RowsPerPageHistory: 10 });
      this.setState({ SearchHistory: SearchVal });
      var searchbox;
      // if (SearchVal == "") {
      //   searchbox = false;
      //   this.setState({ SflagHistory: false });
      // } else {
      //   searchbox = true;
      // }
      var Details = GetUserDetails();
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var StatusHistoryData = {
        Page: this.state.page,
        ClientID: Details.ClientID,
        SalesReplyAccountID: id,
        RowsPerPage: this.state.RowsPerPageHistory,
        Sort: true,
        Field: this.state.SortFieldHistory,
        SortBy: this.state.SortedByHistory,
        Search: SearchVal,
        Role:this.state.Role,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusHistoryGet",
        method: "POST",
        data: StatusHistoryData,
      }).then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HistoryRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
      });
    }
  }

  // change page
  HandleChangePageAccountStatusHistory = (Event,NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.Hpage) {
      this.setState({ Hpage: NewPage });
    } else {
      var SearchVal = document.getElementById(
        "searchaccountstatushistory"
      ).value;
      this.setState({ Hpage: NewPage });
        const id = this.props.location.state.data;
        this.setState({ id: id });
        var StatusHistoryData = {
          Page: this.state.page,
          ClientID: Details.ClientID,
          SalesReplyAccountID: id,
          RowsPerPage: this.state.RowsPerPageHistory,
          Sort: true,
          Field: this.state.SortFieldHistory,
          SortBy: this.state.SortedByHistory,
          Search: SearchVal,
          Role:this.state.Role,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusHistoryGet",
          method: "POST",
          data: StatusHistoryData,
        }).then((reshistory) => {
          this.setState({ HistoryData: reshistory.data?.PageData });
          this.setState({ HistoryRows: reshistory.data?.PageData });
          this.setState({ HRlen: reshistory.data?.TotalCount });
          this.setState({ HFlen: reshistory.data?.TotalCount });
          this.setState({ HCountPage: reshistory.data?.PageCount });
        });
    }

  };

  // contact note get
  NotesDetails(ContactList, Search, Page, RowPage) {
    var Details = GetUserDetails();
    var AccountNoteData = {
      Page: parseInt(Page),
      ClientID: Details.ClientID,
      SalesReplyAccountID: this.state.CommonID,
      Domain:ContactList,
      RowsPerPage: parseInt(RowPage),
      Sort: true,
      Field: this.state.SortField,
      SortBy: this.state.SortedBy,
      Search: Search,
      Role:this.state.Role,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
      method: "POST",
      data: AccountNoteData,
    }).then((responsenote) => {
      this.setState({ Data: responsenote.data?.PageData });
      this.setState({ Rows: responsenote.data?.PageData });
      this.setState({ Rlen: responsenote.data?.TotalCount });
      this.setState({ Flen: responsenote.data?.TotalCount });
      this.setState({ CountPage: responsenote.data?.PageCount });
    });
  }

  // call note contact change display rows
  ChangeRowSelected = (event) => {
    var Details = GetUserDetails();
    this.setState({ Page: 1 });
    this.setState({ RowsPerPage: Number(event.target.value) });
    var SearchedVal = document.getElementById("searchnote").value;
    var AccountNoteData = {
      Page: 1,
      ClientID: Details.ClientID,
      SalesReplyAccountID: this.state.CommonID,
      Domain:this.state.ContactDomain,
      RowsPerPage: Number(event.target.value),
      Sort: true,
      Field: this.state.SortField,
      SortBy: this.state.SortedBy,
      Role:this.state.Role,
      Search: SearchedVal,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
      method: "POST",
      data: AccountNoteData,
    }).then((responsenote) => {
      this.setState({ Data: responsenote.data?.PageData });
      this.setState({ Rows: responsenote.data?.PageData });
      this.setState({ Rlen: responsenote.data?.TotalCount });
      this.setState({ Flen: responsenote.data?.TotalCount });
      this.setState({ CountPage: responsenote.data?.PageCount });
    });
  };

  // search for record contact note
  RequestSearch=(event)=>{
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("searchnote").value;
      this.setState({ Search: SearchedVal });
    var AccountNoteData = {
      Page: this.state.Page,
      ClientID: Details.ClientID,
      SalesReplyAccountID: this.state.CommonID,
      Domain:this.state.ContactDomain,
      RowsPerPage: this.state.RowsPerPage,
      Sort: true,
      Field: this.state.SortField,
      SortBy: this.state.SortedBy,
      Role:this.state.Role,
      Search: SearchedVal,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
      method: "POST",
      data: AccountNoteData,
    }).then((responsenote) => {
      this.setState({ Data: responsenote.data?.PageData });
      this.setState({ Rows: responsenote.data?.PageData });
      this.setState({ Rlen: responsenote.data?.TotalCount });
      this.setState({ Flen: responsenote.data?.TotalCount });
      this.setState({ CountPage: responsenote.data?.PageCount });
    });
    }
  }

  // change page
  HandleChangePage = (Event,NewPage) => {
    var Details = GetUserDetails();
    this.setState({ Page: NewPage });
    var SearchedVal = document.getElementById("searchnote").value;
    if (NewPage == this.state.Page) {
      this.setState({ Page: NewPage });
    } else {
      var AccountNoteData = {
        Page: this.state.Page,
        ClientID: Details.ClientID,
        SalesReplyAccountID: this.state.CommonID,
        Domain:this.state.ContactDomain,
        RowsPerPage: this.state.RowsPerPage,
        Sort: true,
        Field: this.state.SortField,
        SortBy: this.state.SortedBy,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
        method: "POST",
        data: AccountNoteData,
      }).then((responsenote) => {
        this.setState({ Data: responsenote.data?.PageData });
        this.setState({ Rows: responsenote.data?.PageData });
        this.setState({ Rlen: responsenote.data?.TotalCount });
        this.setState({ Flen: responsenote.data?.TotalCount });
        this.setState({ CountPage: responsenote.data?.PageCount });
      });
    }

  };

  //form validation
  async fromValidationNotes() {
    let formIsValid = true;
    let fileds = this.state.Fields;
    let Errors = {};
    var Title22 = document.getElementById("Title22").value.trim();
    var Notes22 = document.getElementById("Notes22").value.trim();
    var IsExist = await this.CheckExistAccountTitle(Title22,"Account");
    if (Title22 == "") {
      formIsValid = false;
      Errors["Title22"] = "Please Enter Title";
    }
    if (Notes22 == "") {
      formIsValid = false;
      Errors["Notes22"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    this.setState({ Errors: Errors });
    return formIsValid;
  }

  //  handle change contact notes
  handleChangeNotes(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailableNotes = null;
      this.CheckExistAccountTitle(e.target.value,"Account");
    } else {
      this.state.TitleAvailableNotes = null;
      this.state.Errors.Title = null;
    }
  }

  //delete contact note id
  ProspectNoteDelete(id,PageName) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var PNoteDelete = {
          _id: id,
          Type:PageName,
          LastUpdatedDt: new Date(),
          Role:this.state.Role,
          LastUpdatedBy: this.state.UserIDby,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/DeleteSalesReplyAccount",
          method: "POST",
          data: PNoteDelete,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Note deleted successfully.",
                "success"
              );
              //
              var SearchedVal = document.getElementById("searchnote").value;
              this.setState({ Search: SearchedVal });
            var AccountNoteData = {
              Page: this.state.Page,
              ClientID: this.state.ClientID,
              SalesReplyAccountID: this.state.CommonID,
              Domain:this.state.ContactDomain,
              RowsPerPage: this.state.RowsPerPage,
              Sort: true,
              Field: this.state.SortField,
              SortBy: this.state.SortedBy,
              Search: SearchedVal,
              Role:this.state.Role,
              Type: "User",
            };
            Axios({
              url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
              method: "POST",
              data: AccountNoteData,
            }).then((responsenote) => {
              this.setState({ Data: responsenote.data?.PageData });
              this.setState({ Rows: responsenote.data?.PageData });
              this.setState({ Rlen: responsenote.data?.TotalCount });
              this.setState({ Flen: responsenote.data?.TotalCount });
              this.setState({ CountPage: responsenote.data?.PageCount });
            });
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  async CheckExistAccountTitle(Title,PageType) {
    var str_in = {
      ClientID: this.state.ClientID,
      Title: Title,
      Role:this.state.Role,
      Type:PageType
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        let Errors = {};
        this.setState({ TitleAvailableNotes: resdata.data.Data[0].Title });
        Errors["Notes22"] = "";
        this.setState({ Errors: Errors });
        return true;
      } else {
        return false;
      }
    }
  }

  // find check exits title
  // AccountNoteTitleCheckExist(Title) {
  //   var ProspectNote = {
  //     ClientID: this.state.ClientID,
  //     Title: Title,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountExists",
  //     method: "POST",
  //     data: ProspectNote,
  //   }).then((res) => {
  //     if (res.data.StatusMessage == "SUCCESS") {
  //       if (res.data.Data.length > 0) {
  //         this.setState({ TitleAvailable: res.data.Data[0].Title });
  //       }
  //     }
  //   });
  // }

  // custom field data list
  CustomFieldValueGet() {
    const id = this.props.location.state.data;
    this.setState({ id: id });
    // prospect custom field id
    var CustomFiedIdData = {
      Role:this.state.Role,
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesreplyStatusNotesByID",
      method: "POST",
      data: CustomFiedIdData,
    }).then((res) => {
      this.setState({
        ProspectCustomFieldById: res.data.ProspectCustomFieldById,
      });
    });
  }

  // save contact note
  async AccountNoteSave(e) {
       // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabledNoteSave:true});
    var Final_flag = await this.fromValidationNotes();
    if (Final_flag == true) {
    var Title = document.getElementById("Title22").value;
    var Notes = document.getElementById("Notes22").value;
    var AccountNoteData = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      SalesReplyAccountID: this.state.CommonID,
      Title: Title,
      Note: Notes,
      CreatedBy: this.state.UserIDby,
      CreatedDate: new Date(),
      Role:this.state.Role,
      LastUpdatedBy: null,
      LastUpdatedDate: null,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AddSalesReplyAccountNotes",
      method: "POST",
      data: AccountNoteData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success("Account note add successfully.", "Add Note ");
        this.setState({ShowNotePopup:false})
        //
        var SearchedVal = document.getElementById("searchnote").value;
      this.setState({ Search: SearchedVal });
        var AccountNoteData = {
          Page: this.state.Page,
          ClientID: this.state.ClientID,
          SalesReplyAccountID: this.state.CommonID,
          Domain:this.state.ContactDomain,
          RowsPerPage: this.state.RowsPerPage,
          Sort: true,
          Field: this.state.SortField,
          Role:this.state.Role,
          SortBy: this.state.SortedBy,
          Search: SearchedVal,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
          method: "POST",
          data: AccountNoteData,
        }).then((responsenote) => {
          this.setState({ Data: responsenote.data?.PageData });
          this.setState({ Rows: responsenote.data?.PageData });
          this.setState({ Rlen: responsenote.data?.TotalCount });
          this.setState({ Flen: responsenote.data?.TotalCount });
          this.setState({ CountPage: responsenote.data?.PageCount });
        });
      } else {
        toast.error(res.data.Message);
        this.setState({IsButtonDisabledNoteSave:false});
      }
    });

  }else{
    //document.getElementById("submitSave").disabled = false;
    this.setState({IsButtonDisabledNoteSave:false});
  }
}


async fromValidationNote() {
  let formIsValid = true;

  let Fields = this.state.Fields1;
  let Errors1 = {};

  var Title1 = document.getElementById("Title1").value.trim();
  var Notes = document.getElementById("Notes").value.trim();
  var IsExist = await this.CheckExistAccountTitle1(Title1,this.state.PageType);
  if (Title1 == "") {
    formIsValid = false;
    Errors1["Title1"] = "Please Enter Title";
  }
  if (Notes == "") {
    formIsValid = false;
    Errors1["Notes"] = "Please Enter Notes";
  }
  if (IsExist == true) {
    formIsValid = false;
  }

  this.setState({ Errors1: Errors1 });

  return formIsValid;
}

handleChangeNote(field, e) {
  let Fields1 = this.state.Fields1;
  Fields1[field] = e.target.value;
  this.setState({ Fields1 });
  if (Fields1.Title != "") {
    this.state.Errors1.Title = null;
    this.state.TitleAvailableNotes = null;
    this.CheckExistAccountTitle1(e.target.value,this.state.PageType);
  } else {
    this.state.TitleAvailableNotes = null;
    this.state.Errors1.Title = null;
  }
}

async CheckExistAccountTitle1(Title,PageT) {
  var str_in = {
    ClientID: this.state.ClientID,
    Role:this.state.Role,
    Title: Title,
    Type:PageT
  };
  var resdata = await Axios({
    url:
      CommonConstants.MOL_APIURL +
      "/accounts/SalesReplyAccountExists",
    method: "POST",
    data: str_in,
  });
  if (resdata.data.StatusMessage == "SUCCESS") {
    if (resdata.data.Data.length > 0) {
      if (this.state.OldTitleUpdate == Title) {
        return false;
      } else {
        let Errors1 = {};
        this.setState({ TitleAvailableNotes: resdata.data.Data[0].Title });
        Errors1["Title1"] = "";
        this.setState({ Errors1: Errors1 });
        return true;
      }
    } else {
      return false;
    }
  }
}
  // find id by record contact edit
  AccountNoteEditBtn(id,PageName) {
    this.setState({ ids: id });
    this.setState({PageType:PageName})
    var AccountNoteEdit = {
      Role:this.state.Role,
      _id: id,
      Type:PageName
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesreplyStatusNotesByID",
      method: "POST",
      data: AccountNoteEdit,
    }).then((res) => {
      this.setState({ OldTitleUpdate: res.data[0]?.Title });
      document.getElementById("Title1").value = res.data[0].Title;
      document.getElementById("Notes").value = res.data[0].Note;
    });
  }

  //  contact note update
  async AccountNoteUpdate(e) {
    this.setState({IsButtonDisabledNoteUpdate:true});
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var FinalValidation = await this.fromValidationNote();
    if (FinalValidation == true) {
    var Title = document.getElementById("Title1").value;
    var Notes = document.getElementById("Notes").value;

    var AccountNoteUpdateData = {
      _id: this.state.ids,
      Title: Title,
      Note: Notes,
      Role:this.state.Role,
      Pagetype:this.state.PageType,
      LastUpdatedBy: this.state.UserIDby,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountUpdate",
      method: "POST",
      data: AccountNoteUpdateData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Note Status update successfully.",
          "Update Account Status"
        );
        var SearchedVal = document.getElementById("searchnote").value;
      this.setState({ Search: SearchedVal });
        var AccountNoteData = {
          Page: this.state.Page,
          ClientID: this.state.ClientID,
          SalesReplyAccountID: this.state.CommonID,
          Domain:this.state.ContactDomain,
          RowsPerPage: this.state.RowsPerPage,
          Sort: true,
          Field: this.state.SortField,
          Role:this.state.Role,
          SortBy: this.state.SortedBy,
          Search: SearchedVal,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
          method: "POST",
          data: AccountNoteData,
        }).then((responsenote) => {
          this.setState({ Data: responsenote.data?.PageData });
          this.setState({ Rows: responsenote.data?.PageData });
          this.setState({ Rlen: responsenote.data?.TotalCount });
          this.setState({ Flen: responsenote.data?.TotalCount });
          this.setState({ CountPage: responsenote.data?.PageCount });
        });
      } else {
        toast.error(res.data.Message);
        this.setState({IsButtonDisabledNoteUpdate:false});
      }
    });
  }else{
    this.setState({IsButtonDisabledNoteUpdate:false});
    //document.getElementById("submitupdate").disabled = false;
  }
}

  // Lists get all
  ListsGet(DomainList, Search, Page, RowPage) {
    var Details = GetUserDetails();
    var ListData = {
      Page: parseInt(Page),
      ClientID: Details.ClientID,
      Domain: DomainList,
      RowsPerPage: parseInt(RowPage),
      Sort: true,
      Field: this.state.SortFieldL,
      Role:this.state.Role,
      SortBy: this.state.SortedByL,
      Search: Search,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/ListsGet",
      method: "POST",
      data: ListData,
    }).then((ListResult) => {
      this.setState({ ListData: ListResult.data?.PageData });
      this.setState({ ListRows: ListResult.data?.PageData });
      this.setState({ LRlen: ListResult.data?.TotalCount });
      this.setState({ LFlen: ListResult.data?.TotalCount });
      this.setState({ LCountPage: ListResult.data?.PageCount });
    });
  }

 //Sorting Contact list
 ListSorting(Field){
  var Details = GetUserDetails();
  var SearchedVal = document.getElementById("searchlist").value;
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    this.setState({ SflagL: false });
    var SortField = Field;
    var SortBy;
    if (this.state.SortedByL == 1) {
      SortBy = -1;
      this.setState({ SortedByL: -1 });
    } else {
      SortBy = 1;
      this.setState({ SortedByL: 1 });
    }
    this.setState({ SortFieldL: Field });
    var InputParameter = {
      ClientID: Details.ClientID,
      Domain: this.state.ContactDomain,
      Page: this.state.PageL ,
      RowsPerPage: this.state.RowsPerPageL,
      Role:this.state.Role,
      Sort: true,
      Field: this.state.SortFieldL,
      SortBy: this.state.SortedByL,
      Search: SearchedVal,
      Type: "User",
    };
    const AccountContactList = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accounts/ListsGet",
      method: "POST",
      data: InputParameter,
    });
    AccountContactList.then((ListResult) => {
      this.setState({ ListData: ListResult.data?.PageData });
      this.setState({ ListRows: ListResult.data?.PageData });
      this.setState({ LRlen: ListResult.data?.TotalCount });
      this.setState({ LFlen: ListResult.data?.TotalCount });
      this.setState({ LCountPage: ListResult.data?.PageCount });
    });
  } else {
    SerchBox = true;
    // SetSflag(true);
    this.setState({ SflagL: true });
    var SortField = Field;
    var SortBy;
    if (this.state.SortedByL == 1) {
      SortBy = -1;
      this.setState({ SortedByL: -1 });
    } else {
      SortBy = 1;
      this.setState({ SortedByL: 1 });
    }
    this.setState({ SortFieldL: Field });
    var InputParameter = {
      ClientID: Details.ClientID,
      Domain: this.state.ContactDomain,
      Page: this.state.PageL ,
      RowsPerPage: this.state.RowsPerPageL,
      Role:this.state.Role,
      Sort: true,
      Field: this.state.SortFieldL,
      SortBy: this.state.SortedByL,
      Search: SearchedVal,
      Type: "User",
    };
    const AccountContactList = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accounts/ListsGet",
      method: "POST",
      data: InputParameter,
    });
    AccountContactList.then((ListResult) => {
      this.setState({ ListData: ListResult.data?.PageData });
      this.setState({ ListRows: ListResult.data?.PageData });
      this.setState({ LRlen: ListResult.data?.TotalCount });
      this.setState({ LFlen: ListResult.data?.TotalCount });
      this.setState({ LCountPage: ListResult.data?.PageCount });
    });
  }
}

  // search for record
  RequestSearchList=(Event)=> {
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchlist").value;
      this.setState({ SearchL: SearchedVal });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: this.state.PageL ,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: this.state.SortFieldL,
        SortBy: this.state.SortedByL,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((ListResult) => {
        this.setState({ ListData: ListResult.data?.PageData });
        this.setState({ ListRows: ListResult.data?.PageData });
        this.setState({ LRlen: ListResult.data?.TotalCount });
        this.setState({ LFlen: ListResult.data?.TotalCount });
        this.setState({ LCountPage: ListResult.data?.PageCount });
      });

    }
  }

  // change display rows
  ChangeRowSelectedList=(Event)=>{
    var Details = GetUserDetails();
    this.setState({ RowsPerPageL: Number(Event.target.value) });
    this.setState({ PageL: 1 });
    var SearchedVal = document.getElementById("searchlist").value;
    this.setState({ SearchL: SearchedVal });
    var InputParameter = {
      ClientID: Details.ClientID,
      Domain: this.state.ContactDomain,
      Page: 1 ,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.SortFieldL,
      Role:this.state.Role,
      SortBy: this.state.SortedByL,
      Search: SearchedVal,
      Type: "User",
    };
    const AccountContactList = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accounts/ListsGet",
      method: "POST",
      data: InputParameter,
    });
    AccountContactList.then((ListResult) => {
      this.setState({ ListData: ListResult.data?.PageData });
      this.setState({ ListRows: ListResult.data?.PageData });
      this.setState({ LRlen: ListResult.data?.TotalCount });
      this.setState({ LFlen: ListResult.data?.TotalCount });
      this.setState({ LCountPage: ListResult.data?.PageCount });
    });
  }

  // change page
  HandleChangePageList=(Event,NewPage)=>{
    this.setState({ PageL: NewPage });
    if (NewPage == this.state.PageL) {
      this.setState({ PageL: NewPage });
    } else {
      var SearchedVal = document.getElementById("searchlist").value;
      this.setState({ SearchL: SearchedVal });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: NewPage,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: this.state.SortFieldL,
        Role:this.state.Role,
        SortBy: this.state.SortedByL,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((ListResult) => {
        this.setState({ ListData: ListResult.data?.PageData });
        this.setState({ ListRows: ListResult.data?.PageData });
        this.setState({ LRlen: ListResult.data?.TotalCount });
        this.setState({ LFlen: ListResult.data?.TotalCount });
        this.setState({ LCountPage: ListResult.data?.PageCount });
      });
    }
  }

  // edit contact page
  EditLists = (id) => {
    // history.push("/editlist", id);
    history.push({pathname: "/ceditlists",
      state: { data: id,Pagename:"AccountContact",AccountData:this.state.AccountIDs},
    });

  };

  // add contact page
  ListsAdd = () => {
    // history.push("/addlist");
    history.push({pathname: "/caddlists",
      state: { data: this.state.AccountIDs,Pagename:"AccountContact"},
    });
  };

  //Potenial meeting Details
  PotentialListsGet(DomainPotential, Search, Page, RowPage) {
    var Details = GetUserDetails();
    var PListData = {
      Page: parseInt(Page),
      ClientID: Details.ClientID,
      Domain: DomainPotential,
      RowsPerPage: parseInt(RowPage),
      Sort: true,
      Field: this.state.SortFieldP,
      SortBy: this.state.SortedByP,
      Role:this.state.Role,
      Search: Search,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/PotentialMeetingGet",
      method: "POST",
      data: PListData,
    }).then((PListResult) => {
      this.setState({ PlistData: PListResult.data?.PageData });
      this.setState({ PlistRows: PListResult.data?.PageData });
      this.setState({ PlRlen: PListResult.data?.TotalCount });
      this.setState({ PlFlen: PListResult.data?.TotalCount });
      this.setState({ PlCountPage: PListResult.data?.PageCount });
      this.setState({ PotentialNotes: PListResult.data?.PotentialNotes });
    });
  }

   //Sorting Contact list
  PotentialSorting(Field){
    var Details = GetUserDetails();
    var SearchedVal = document.getElementById("searchPlist").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      this.setState({ SflagP: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedByP == 1) {
        SortBy = -1;
        this.setState({ SortedByP: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedByP: 1 });
      }
      this.setState({ SortFieldP: Field });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: this.state.PageP ,
        RowsPerPage: this.state.RowsPerPageP,
        Sort: true,
        Field: this.state.SortFieldP,
        SortBy: this.state.SortedByP,
        Role:this.state.Role,
        Search: this.state.SearchP,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((PListResult) => {
        this.setState({ PlistData: PListResult.data?.PageData });
        this.setState({ PlistRows: PListResult.data?.PageData });
        this.setState({ PlRlen: PListResult.data?.TotalCount });
        this.setState({ PlFlen: PListResult.data?.TotalCount });
        this.setState({ PlCountPage: PListResult.data?.PageCount });
        this.setState({ PotentialNotes: PListResult.data?.PotentialNotes });
      });
    } else {
      SerchBox = true;
      // SetSflag(true);
      this.setState({ SflagP: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedByP == 1) {
        SortBy = -1;
        this.setState({ SortedByP: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedByP: 1 });
      }
      this.setState({ SortFieldP: Field });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: this.state.PageP ,
        RowsPerPage: this.state.RowsPerPageP,
        Sort: true,
        Field: this.state.SortFieldP,
        SortBy: this.state.SortedByP,
        Role:this.state.Role,
        Search: this.state.SearchP,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((PListResult) => {
        this.setState({ PlistData: PListResult.data?.PageData });
        this.setState({ PlistRows: PListResult.data?.PageData });
        this.setState({ PlRlen: PListResult.data?.TotalCount });
        this.setState({ PlFlen: PListResult.data?.TotalCount });
        this.setState({ PlCountPage: PListResult.data?.PageCount });
        this.setState({ PotentialNotes: PListResult.data?.PotentialNotes });
      });
    }
  }

  // search for record
  RequestSearchListP=(Event)=> {
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchPlist").value;
      this.setState({ SearchP: SearchedVal });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: this.state.PageP ,
        RowsPerPage: this.state.RowsPerPageP,
        Sort: true,
        Field: this.state.SortFieldP,
        SortBy: this.state.SortedByP,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((PListResult) => {
        this.setState({ PlistData: PListResult.data?.PageData });
        this.setState({ PlistRows: PListResult.data?.PageData });
        this.setState({ PlRlen: PListResult.data?.TotalCount });
        this.setState({ PlFlen: PListResult.data?.TotalCount });
        this.setState({ PlCountPage: PListResult.data?.PageCount });
        this.setState({ PotentialNotes: PListResult.data?.PotentialNotes });
      });
    }
  }

  // change display rows
  ChangeRowSelectedListP=(Event)=>{
    var Details = GetUserDetails();
    this.setState({ RowsPerPageP: Number(Event.target.value) });
    this.setState({ PageP: 1 });
    var SearchedVal = document.getElementById("searchPlist").value;
      this.setState({ SearchP: SearchedVal });
      var InputParameter = {
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        Page: 1 ,
        RowsPerPage: Number(Event.target.value),
        Sort: true,
        Field: this.state.SortFieldP,
        SortBy: this.state.SortedByP,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((PListResult) => {
        this.setState({ PlistData: PListResult.data?.PageData });
        this.setState({ PlistRows: PListResult.data?.PageData });
        this.setState({ PlRlen: PListResult.data?.TotalCount });
        this.setState({ PlFlen: PListResult.data?.TotalCount });
        this.setState({ PlCountPage: PListResult.data?.PageCount });
        this.setState({ PotentialNotes: PListResult.data?.PotentialNotes });
      });
  }

  // change page
  HandleChangePageListP=(Event,NewPage)=> {
    var Details = GetUserDetails();
    if (NewPage == this.state.PageP) {
      this.setState({ PageP: NewPage });
    } else {
      this.setState({ PageP: NewPage });
      var SearchedVal = document.getElementById("searchPlist").value;
        var InputParameter = {
          ClientID: Details.ClientID,
          Domain: this.state.ContactDomain,
          Page: NewPage,
          RowsPerPage: this.state.RowsPerPageP,
          Sort: true,
          Field: this.state.SortFieldP,
          SortBy: this.state.SortedByP,
          Role:this.state.Role,
          Search: SearchedVal,
          Type: "User",
        };
        const AccountContactList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/accounts/PotentialMeetingGet",
          method: "POST",
          data: InputParameter,
        });
        AccountContactList.then((PListResult) => {
          this.setState({ PlistData: PListResult.data?.PageData });
          this.setState({ PlistRows: PListResult.data?.PageData });
          this.setState({ PlRlen: PListResult.data?.TotalCount });
          this.setState({ PlFlen: PListResult.data?.TotalCount });
          this.setState({ PlCountPage: PListResult.data?.PageCount });
          this.setState({ PotentialNotes: PListResult.data?.PotentialNotes });
        });
    }
  }

  // edit contact page
  PotenialEditPages = (id) => {
    history.push({pathname: "/ceditbookedmeetings",
      state: { data: id,Pagename:"AccountContact",AccountData:this.state.AccountIDs},
    });
  };

  // add contact page
  PotentialAdd = () => {
    history.push({pathname: "/caddpotentialmeetings",
    state: { data: this.state.AccountIDs,Pagename:"AccountPotential"},
  });
  };

  // meeting Details
  MeetingListsGet(DomainList, Search, Page, RowPage) {
    var Details = GetUserDetails();
    var PListData = {
      Page: parseInt(Page),
      ClientID: Details.ClientID,
      Domain: DomainList,
      RowsPerPage: parseInt(RowPage),
      Sort: true,
      Field: this.state.SortFieldM,
      SortBy: this.state.SortedByM,
      Search: Search,
      Role:this.state.Role,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
      method: "POST",
      data: PListData,
    }).then((PListResult) => {
      this.setState({ MlistData: PListResult.data?.PageData });
      this.setState({ MlistRows: PListResult.data?.PageData });
      this.setState({ MlRlen: PListResult.data?.TotalCount });
      this.setState({ MlFlen: PListResult.data?.TotalCount });
      this.setState({ MlCountPage: PListResult.data?.PageCount });
      this.setState({ MeetingNotes: PListResult.data?.MeetingNotes });
    });
  }

   //Sorting Contact list
  BookedMeetingSorting(Field){
    var Details = GetUserDetails();
    var SearchedVal = document.getElementById("searchPlist").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      this.setState({ SflagM: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedByM == 1) {
        SortBy = -1;
        this.setState({ SortedByM: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedByM: 1 });
      }
      this.setState({ SortFieldM: Field });
      var PListData = {
        Page: this.state.PageM,
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        RowsPerPage: this.state.RowsPerPageM,
        Sort: true,
        Field: this.state.SortFieldM,
        SortBy: this.state.SortedByM,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
        method: "POST",
        data: PListData,
      }).then((PListResult) => {
        this.setState({ MlistData: PListResult.data?.PageData });
        this.setState({ MlistRows: PListResult.data?.PageData });
        this.setState({ MlRlen: PListResult.data?.TotalCount });
        this.setState({ MlFlen: PListResult.data?.TotalCount });
        this.setState({ MlCountPage: PListResult.data?.PageCount });
        this.setState({ MeetingNotes: PListResult.data?.MeetingNotes });
      });
    } else {
      SerchBox = true;
      // SetSflag(true);
      this.setState({ SflagM: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedByM == 1) {
        SortBy = -1;
        this.setState({ SortedByM: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedByM: 1 });
      }
      this.setState({ SortFieldM: Field });
      var PListData = {
        Page: this.state.PageM,
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        RowsPerPage: this.state.RowsPerPageM,
        Sort: true,
        Field: this.state.SortFieldM,
        SortBy: this.state.SortedByM,
        Search: SearchedVal,
        Role:this.state.Role,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
        method: "POST",
        data: PListData,
      }).then((PListResult) => {
        this.setState({ MlistData: PListResult.data?.PageData });
        this.setState({ MlistRows: PListResult.data?.PageData });
        this.setState({ MlRlen: PListResult.data?.TotalCount });
        this.setState({ MlFlen: PListResult.data?.TotalCount });
        this.setState({ MlCountPage: PListResult.data?.PageCount });
        this.setState({ MeetingNotes: PListResult.data?.MeetingNotes });
      });
    }
  }

  // search for record
  RequestSearchListM=(Event)=>{
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchMlist").value;
      this.setState({ SearchM: SearchedVal });
      var PListData = {
        Page: this.state.PageM,
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        RowsPerPage: this.state.RowsPerPageM,
        Sort: true,
        Field: this.state.SortFieldM,
        Role:this.state.Role,
        SortBy: this.state.SortedByM,
        Search: SearchedVal,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
        method: "POST",
        data: PListData,
      }).then((PListResult) => {
        this.setState({ MlistData: PListResult.data?.PageData });
        this.setState({ MlistRows: PListResult.data?.PageData });
        this.setState({ MlRlen: PListResult.data?.TotalCount });
        this.setState({ MlFlen: PListResult.data?.TotalCount });
        this.setState({ MlCountPage: PListResult.data?.PageCount });
        this.setState({ MeetingNotes: PListResult.data?.MeetingNotes });
      });
    }
  }

  // change display rows
  ChangeRowSelectedListM=(Event)=>{
    var Details = GetUserDetails();
    this.setState({ RowsPerPageM: Number(Event.target.value) });
    this.setState({ PageM: 1 });
    var SearchedVal = document.getElementById("searchMlist").value;
    this.setState({ SearchM: SearchedVal });
    var PListData = {
      Page: 1,
      ClientID: Details.ClientID,
      Domain: this.state.ContactDomain,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.SortFieldM,
      Role:this.state.Role,
      SortBy: this.state.SortedByM,
      Search: SearchedVal,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
      method: "POST",
      data: PListData,
    }).then((PListResult) => {
      this.setState({ MlistData: PListResult.data?.PageData });
      this.setState({ MlistRows: PListResult.data?.PageData });
      this.setState({ MlRlen: PListResult.data?.TotalCount });
      this.setState({ MlFlen: PListResult.data?.TotalCount });
      this.setState({ MlCountPage: PListResult.data?.PageCount });
      this.setState({ MeetingNotes: PListResult.data?.MeetingNotes });
    });
  }

  // change page
  HandleChangePageListM=(Event,NewPage)=>{
    var Details = GetUserDetails();
    if (NewPage == this.state.PageM) {
      this.setState({ PageM: NewPage });
    } else {
      var SearchedVal = document.getElementById("searchMlist").value;
      this.setState({ SearchM: SearchedVal });
      var PListData = {
        Page: NewPage,
        ClientID: Details.ClientID,
        Domain: this.state.ContactDomain,
        RowsPerPage: this.state.RowsPerPageM,
        Sort: true,
        Field: this.state.SortFieldM,
        SortBy: this.state.SortedByM,
        Role:this.state.Role,
        Search: SearchedVal,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
        method: "POST",
        data: PListData,
      }).then((PListResult) => {
        this.setState({ MlistData: PListResult.data?.PageData });
        this.setState({ MlistRows: PListResult.data?.PageData });
        this.setState({ MlRlen: PListResult.data?.TotalCount });
        this.setState({ MlFlen: PListResult.data?.TotalCount });
        this.setState({ MlCountPage: PListResult.data?.PageCount });
        this.setState({ MeetingNotes: PListResult.data?.MeetingNotes });
      });
    }

  }

  // edit Meeting page
  MeetingEditPage = (id) => {
    // history.push("/editbookedmeeting", id);
    history.push({pathname: "/ceditbookedmeetings",
    state: { data: id,Pagename:"AccountContact",AccountData:this.state.AccountIDs},
  });
  };

  // add Metting page
  MeetingAdd = () => {
    // history.push("/addbookedmeeting");
    history.push({pathname: "/caddbookedmeetings",
      state: { data: this.state.AccountIDs,Pagename:"AccountContact"},
    });
  };

  // call History Details
  CallHistroyGet(DomainCallHistory, Search, Page, RowPage) {
    var Details = GetUserDetails();
    var CHListData = {
      Page: parseInt(Page),
      ClientID: Details.ClientID,
      RowsPerPage: parseInt(RowPage),
      Domain: DomainCallHistory,
      Sort: true,
      Field: this.state.SortFieldCH,
      Role:this.state.Role,
      SortBy: this.state.SortedByCH,
      Search: Search,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/CallHistoryGet",
      method: "POST",
      data: CHListData,
    }).then((CHListResult) => {
      this.setState({ CHlistData: CHListResult.data?.PageData });
      this.setState({ CHlistRows: CHListResult.data?.PageData });
      this.setState({ CHlRlen: CHListResult.data?.TotalCount });
      this.setState({ CHlFlen: CHListResult.data?.TotalCount });
      this.setState({ CHlCountPage: CHListResult.data?.PageCount });
    });
  }

  // search for record
  RequestSearchListCH(Event) {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchCMlist").value;
      this.setState({ SearchCH: SearchedVal });
      // this.CallHistroyGet();
    }
  }

  // change display rows
  ChangeRowSelectedListCH(Event) {
    this.setState({ RowsPerPageCH: Event.target.value });
    this.setState({ PageCH: 1 });

  }

  // change page
  HandleChangePageListCH(NewPage) {
    this.setState({ PageCH: NewPage });

  }

  // Cmapaign Details
  CampaignsAccountGet(DomainCampaigns, Search, Page, RowPage) {
    var Details = GetUserDetails();
    var CHListData = {
      Page: parseInt(Page),
      ClientID: Details.ClientID,
      RowsPerPage: parseInt(RowPage),
      Domain: DomainCampaigns,
      Sort: true,
      Field: this.state.SortFieldCA,
      Role:this.state.Role,
      SortBy: this.state.SortedByCA,
      Search: Search,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/CampaignsAccountGet",
      method: "POST",
      data: CHListData,
    }).then((CHListResult) => {
      this.setState({ CAlistData: CHListResult.data?.PageData });
      this.setState({ CAlistRows: CHListResult.data?.PageData });
      this.setState({ CAlRlen: CHListResult.data?.TotalCount });
      this.setState({ CAlFlen: CHListResult.data?.TotalCount });
      this.setState({ CAlCountPage: CHListResult.data?.PageCount });
    });
  }

  // search for record
  RequestSearchCampaigns(Event) {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchCAlist").value;
      this.setState({ SearchCA: SearchedVal });
      // this.CampaignsAccountGet();
    }
  }

  // change display rows
  ChangeRowSelectedCampaigns(Event) {
    this.setState({ RowsPerPageCA: Event.target.value });
    this.setState({ PageCA: 1 });
    // this.CampaignsAccountGet();
  }

  // change page
  HandleChangePageCampaginList(NewPage) {
    this.setState({ PageCA: NewPage });
    // this.CampaignsAccountGet();
  }

  // edit Campaigns page
  EditCampaigns = (id) => {
    history.push("/ceditcampaigns", id);
  };

  // add Campaigns page
  CampaignsAdd = () => {
    history.push("/caddcampaigns");
  };

  // edit campaign history
  EditCampaignHistory(id){
    history.push({pathname:"/ceditcampaigns", state:{data:id, Pagename:"ViewContact", dataprospect:this.state.ProspectIdForNote}});
  }

  //  campaign history handle change page
HandleChangePageCampaignHistory = (NewPage) => {
  this.setState({ CAMPage: NewPage });
  this.CampaignHistoryGet();
};

  //campaign history search for record campaign history
  RequestSearchCampaignHistory(event) {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("searchcampaigns").value;
      if(SearchedVal== this.state.CAMSearch){
        this.setState({ CAMSearch: SearchedVal });
        this.setState({ CAMPage: 1 });
        this.CampaignHistoryGet();
      }else{
        this.setState({ CAMSearch: SearchedVal });
        this.setState({ CAMPage: 1 });
        this.CampaignHistoryGet();
        // document.getElementById("hideloding").style.display = "block";
      }

    }
  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        {/* EDIT AND NOTES PAGE  */}
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header">
                    Edit Meeting
                  </h3>
                </div>
            
                <div className="col-md-8 col-sm-8 text-right">
                  <div className="listing-li float-right padb-15 iconbox-pad edited">
                    <ul>
                      {/* <li>
                          <a href="" className="btn btngroup m-btn">
                            <i className="la flaticon-edit-1 la-icon-font-size-13"></i><span>Edit</span>
                          </a>
                        </li> */}
                      <li>
                        <a
                        id="submit"
                          className="btn btngroup m-btn"
                          onClick={this.updateData}
                          disabled={this.state.IsButtonDisabled}
                        >
                          <i className="fa fa-save la-icon-font-size-13"></i>
                          <span>Save</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={this.DeleteClientAccount}
                          className="btn btngroup m-btn"
                        >
                          <i className="flaticon-delete-1 la-icon-font-size-13"></i>
                          <span>Delete</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            this.AddToBlankAdd();
                          }}
                          className="btn btngroup m-btn"
                        >
                          <i className="fa la-icon-font-size-13 fa-ban la-icon-font-size-13"></i>
                          <span>Add to Blacklist</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btngroup m-btn borderbtn"
                          onClick={this.backbtn}
                        >
                          <i className="la la-arrow-circle-left la-icon-font-size-13"></i>
                          <span>Back</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            <div className="bg-white px-4">
              {this.state?.allres[0]?.map((value) => (
                <div className="row py-3">
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Name
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="AccountName"
                          name="AccountName"
                          type="text"
                          placeholder="AccountName"
                          defaultValue={value?.AccountName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Email Domains
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Domain"
                          name="EmailDomains"
                          type="text"
                          placeholder="EmailDomins"
                          defaultValue={value?.Salesreply_Accountdetails?.Domain}
                          // onBlur={this.handleChange.bind(this, "Domain")}
                          disabled
                        />
                         {/* <span style={{ color: "red" }}>
                        {this.state.errors["Domain"]}
                      </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Website
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Website"
                          name="Website"
                          type="text"
                          placeholder="Website"
                          defaultValue={value?.Website}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Owner
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountOwner"
                          name="AccountOwner"
                          placeholder="Account Owner"
                          value={this.state?.DefaultMeetingOwner}
                          onChange={this.HandleChangeOwner}
                        >
                          {this.state.MeetingOwner?.map((val) => (
                            <option value={val?._id}>
                              {val?.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Category
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountCategory"
                          name="AccountCategory"
                          placeholder="Account Category"
                          value={this.state.DefaultSalesreplyAccountType}
                          onChange={this.HandleChnageSalesreplyAccountCategory}
                        >
                          {this.state.SalesreplyAccountCategory?.map((val) => (
                            <option value={val?._id}>
                              {val?.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Status
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountStatus"
                          name="AccountStatus"
                          placeholder="Account Status"
                          value={this.state?.DefaultAccountStatus}
                          onChange={this.HandleChangeAccountStatus}
                        >
                          {this.state.AccountStatus?.map((val) => (
                            <option value={val?._id}>
                              {val?.Status}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Industry
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Industry"
                          name="Industry"
                          type="text"
                          placeholder="Industry"
                          defaultValue={value?.Industry}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Address 1
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Address1"
                          name="Address1"
                          type="text"
                          placeholder="Address1"
                          defaultValue={value?.Address1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Address 2
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Address2"
                          name="Address2"
                          type="text"
                          placeholder="Address1"
                          defaultValue={value?.Address1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        City
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="City"
                          name="City"
                          type="text"
                          placeholder="City"
                          defaultValue={value?.City}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        State
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="State"
                          name="State"
                          type="text"
                          placeholder="State"
                          defaultValue={value?.State}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Country
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Country"
                          name="Country"
                          type="text"
                          placeholder="Country"
                          defaultValue={value?.Country}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Zip
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Zip"
                          name="Zip"
                          type="text"
                          placeholder="Zip"
                          defaultValue={value?.Zip}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reason Unqualified :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="ReasonUnqualified"
                          name="ReasonUnqualified"
                          placeholder="Reason Unqualified"
                          value={this.state?.DefaultReasonUnqualified}
                          onChange={this.HandleChangeReasonUnqualified}
                        >
                          {this.state.ReasonUnqualified?.map((val) => (
                            <option value={val?._id}>
                              {val?.Reason}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Type :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountType"
                          name="AccountType"
                          placeholder="AccountType"
                          value={this.state?.DefaultSalesreplyAccountType}
                          onChange={this.HandleChangeSalesreplyAccountType}
                        >
                          {this.state.SalesreplyAccountType?.map((val) => (
                            <option value={val?.CommonFieldID}>
                              {val?.FieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Client Status :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountClientStatus"
                          name="AccountClientStatus"
                          placeholder="AccountClientStatus"
                          value={this.state?.DefaultSalesreplyAccountStatus}
                          onChange={this.HandleChangeSalesreplyAccountStatus}
                        >
                          {this.state.SalesreplyAccountStatus?.map((val) => (
                            <option value={val?._id}>
                              {val?.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Phone :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="CompanyPhone"
                          name="CompanyPhone"
                          type="text"
                          placeholder="CompanyPhone"
                          defaultValue={value?.CompanyPhone}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Revenue :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="CompanyRevenue"
                          name="CompanyRevenue"
                          type="text"
                          placeholder="CompanyRevenue"
                          defaultValue={value?.CompanyRevenue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Employee Count :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="EmployeeCount"
                          name="EmployeeCount"
                          type="text"
                          placeholder="EmployeeCount"
                          defaultValue={value?.EmailDomins}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-4 pt-4 col-sm-4 d-flex align-items-center">
                <h3 className=" float-left addpotential_header pl-0">
                  Custom Contact Field
                </h3>
              </div>
              <div className="row px-4 mb-2">
                {/* {this.state.AccountCustomField.map((value, index) => {
                  return (
                    <div className="col-lg-6 boxsinput_group">
                      <div
                        className="row max-cols d-flex align-items-center"
                        key={index}
                      >
                        <label className="col-lg-4">{value.FieldName}:</label>
                        <div className="col-lg-7">
                                <input
                                  className="form-control m-input"
                                  id={value.CustomFieldID}
                                  // defaultValue={val.FieldValue}
                                  type="text"
                                />
                              </div> */}
                        {/* {
                          this.state.AccountCustomfieldbyid!=0?(
                            this.state.AccountCustomfieldbyid.map((val) => {
                            // if (value.CustomFieldID === val.CustomFieldID) {
                              return (
                                <div className="col-lg-7">
                                  <input
                                    className="form-control m-input"
                                    id={value.CustomFieldID}
                                    defaultValue={val.FieldValue}
                                    type="text"
                                  />
                                </div>
                              );
                            // }
                          //   else{
                          //     <input
                          //   className="form-control m-input"
                          //   id={value.CustomFieldID}
                          //   type="text"
                          // />
                          //   }
                          })):(
                          <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id={value.CustomFieldID}
                            type="text"
                          />
                        </div>)
                        } */}
                         {this.state.AccountCustomfieldbyid.map((val) => {
                          if (value.CustomFieldID === val.CustomFieldID) {
                            return (
                              <div className="col-lg-7">
                                <input
                                  className="form-control m-input"
                                  id={value.CustomFieldID}
                                  defaultValue={val.FieldValue}
                                  type="text"
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  {/* );
                })} */}
              {/* </div>
            </div> */}
          
        {/* account sate */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Account Stats
                </h3>
              </div>
            </div>
            <div className="row px-3">
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Created Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      01/25/2021
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Validation Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Email Send Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Open Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Response Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Direct Mail Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Letter Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Status Change :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {moment(new Date(this.state.AccountStatusHistoryData[0]?.ChangedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Is Blacklisted :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Total Email Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.TotalSend}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Total Touches :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.TotalSend}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Tracked Email Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.TotalTracked}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Open Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.OpenedCount}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Reply Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.TotalReply}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Touches Before First Reply :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.TotalReply}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Removed Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Soft Bounce Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.TotalSoftBounce}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Hard Bounce Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    {this.state.AccountStatsDataArray[0]?.TotalHardBounce}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Letter Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Direct Mail Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    View Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      {" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> 

        {/* Contact */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Contacts
                </h3>
                <div className="col text-right">
                  <button
                    onClick={() => {
                      this.ContactAdd();
                    }}
                    className="btn btngroup m-btn "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedContact}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchContact"
                    onKeyPress={(event) => this.RequestSearchContact(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell> <a
                          onClick={() => {
                            this.ContactSorting("FirstName");
                          }}
                        >
                          Contact Name
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.ContactSorting("Email");
                          }}
                        >
                          Email
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.ContactSorting("Company");
                          }}
                        >
                          Company
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.ContactSorting("Title");
                          }}
                        >
                          Title
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.ContactSorting("ContactStatus");
                          }}
                        >
                          Contact status
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.ContactSorting("CreatedDate");
                          }}
                        >
                          Last Activity date
                        </a></TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.RowsSecond?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.RowsSecond?.map((row) => (
                        <>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                  this.setState((prev) => ({
                                    Open: {
                                      ...prev,
                                      [row.ProspectID]: !prev[row.ProspectID],
                                    },
                                  }))
                                }
                              >
                                {this.state.Open[row.ProspectID] ? (
                                  <i
                                    className="fa fa-minus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{row?.FirstName}</TableCell>
                            <TableCell>{row?.Email}</TableCell>
                            <TableCell>{row?.Company}</TableCell>
                            <TableCell>{row?.Title}</TableCell>
                            <TableCell>{row?.ContactStatus}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  this.ContactEdit(row.ProspectID);
                                }}
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={this.state.Open[row.ProspectID]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {this.state.Open[row.ProspectID] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {this.state.NotesData.map((note) =>
                                        note.ProspectID == row.ProspectID ? (
                                          <TableRow>
                                            <TableCell>{note.Title}</TableCell>
                                            <TableCell scope="row">
                                              {note.Note}
                                            </TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                          </TableRow>
                                        ) : (
                                          <></>
                                        )
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.SflagSecond ? (
              <div className="row">
                <Pagination
                  component="div"
                  count={this.state.CountPageSecond}
                  onChange={this.HandleChangePageContact}
                  showFirstButton
                  showLastButton
                />
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.RlenSecond == 0
                      ? 0
                      : (this.state.PageSecond - 1) *
                          this.state.RowsPerPageSecond +
                        1}{" "}
                    to{" "}
                    {this.state.PageSecond * this.state.RowsPerPageSecond >
                    this.state.RlenSecond
                      ? this.state.RlenSecond
                      : this.state.PageSecond *
                        this.state.RowsPerPageSecond}{" "}
                    of {this.state.RlenSecond} entries (filtered from{" "}
                    {this.state.FlenSecond} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.RlenSecond == 0
                      ? 0
                      : (this.state.PageSecond - 1) *
                          this.state.RowsPerPageSecond +
                        1}{" "}
                    to{" "}
                    {this.state.PageSecond * this.state.RowsPerPageSecond >
                    this.state.RlenSecond
                      ? this.state.RlenSecond
                      : this.state.PageSecond *
                        this.state.RowsPerPageSecond}{" "}
                    of {this.state.RlenSecond} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CountPageSecond}
                    onChange={this.HandleChangePageContact}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* notes */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
              </div>
              <div className="col text-right">
              <button
                  onClick={() => {
                    this.setState({ ShowNotePopup: true });
                  }}
                  className="btn btngroup m-btn "
                >
                  <i class="la la-plus la-icon-font-size-13"></i>
                  <span className="Mobile_button">Add</span>
                </button>
                <Popup open={this.state.ShowNotePopup}>

                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-3 px-3">
                            <h4 className="mb-0">Note</h4>
                          </div>
                          <div className="modal-body p-5">
                            <div class="row mb-3">
                              <label class="col-lg-2 col-form-label text-left">
                                Title
                              </label>
                              <div class="col-lg-10">
                                <input
                                  class="form-control m-input"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter title"
                                  id="Title22"
                                  name="TitleNote"
                                  placeholder="Enter title"
                                  onBlur={this.handleChangeNotes.bind(
                                    this,
                                    "Title22"
                                  )}
                                  type="text"
                                />
                                <span style={{ color: "red" }}>
                                {this.state.Errors["Title22"]}
                              </span>
                              {this.state.TitleAvailableNotes && (
                                <span style={{ color: "red" }}>
                                  Title already exist.
                                </span>
                              )}
                              </div>
                            </div>

                            <div class="row">
                              <label class="col-lg-2 col-form-label text-left">
                                Note
                              </label>
                              <div class="col-lg-10">
                                <textarea
                                  class="form-control m-input minheight390"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-required="Please enter title"
                                  id="Notes22"
                                  name="NotesPNote"
                                  placeholder="Enter note"

                                  type="text"
                                >
                                  {" "}
                                </textarea>
                                <span style={{ color: "red" }}>
                                {this.state.Errors["Notes22"]}
                              </span>
                              </div>
                            </div>
                          </div>
                          <div className="modal-content bordernone text-right">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={() => {
                                    this.setState({ ShowNotePopup: false });
                                  }}
                                >
                                  cancel
                                </a>
                                <a
                                  id="submitSave"
                                  class="btn btn-primary btn-lightgreen mr-1"
                                  href="javascript:void(0);"
                                  value="Save"
                                  onClick={this.AccountNoteSave}
                                >
                                  <i class="la la-save"></i> Save
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                </Popup>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    onChange={this.ChangeRowSelected}
                    className="form-control form-control-sm"
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchnote"
                    onKeyPress={(event) => this.RequestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.Rows?.map((row) =>

                          <TableRow>
                            <TableCell>{row?.Title}</TableCell>
                            <TableCell>{row?.Note}</TableCell>
                            <TableCell>{row?.PageName}</TableCell>

                            <TableCell>
                              {moment(
                                new Date(row?.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  this.AccountNoteEditBtn(
                                    row._id,row.PageName
                                  );
                                }}
                              >
                                <Popup
                                  trigger={
                                    <i class="la flaticon-edit-1 edit-icon"></i>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-3 px-3">
                                            <h4 className="mb-0">Note</h4>
                                          </div>
                                          <div className="modal-body p-5">
                                            <div class="row mb-3">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Title
                                              </label>
                                              <div class="col-lg-10">
                                                <input
                                                  class="form-control m-input"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                  data-val-required="Please enter title"
                                                  id="Title1"
                                                  name="TitleEditNote"
                                                  placeholder="Enter title"
                                                  type="text"
                                                  onBlur={this.handleChangeNote.bind(
                                                    this,
                                                    "Title1"
                                                  )}

                                                />
                                                <span style={{ color: "red" }}>
                                                    {this.state.Errors["Title22"]}
                                                  </span>
                                                  {this.state.TitleAvailableNotes && (
                                                    <span style={{ color: "red" }}>
                                                      Title already exist.
                                                    </span>
                                                  )}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Note
                                              </label>
                                              <div class="col-lg-10">
                                                <textarea
                                                  class="form-control m-input minheight390"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-required="Please enter title"
                                                  id="Notes"
                                                  name="NotesEditPNotes"
                                                  placeholder="Enter note"
                                                  type="text"
                                                >
                                                  {" "}
                                                </textarea>
                                                <span style={{ color: "red" }}>
                                                {this.state.Errors1["Notes"]}
                                              </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-content bordernone text-right">
                                            <div class="row">
                                              <div class="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  class="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    close();
                                                  }}
                                                >
                                                  cancel
                                                </a>
                                                <a
                                                  id="submitupdate"
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  href="javascript:void(0);"
                                                  value="Save"
                                                  onClick={
                                                    this.AccountNoteUpdate
                                                  }
                                                >
                                                  <i class="la la-save"></i>{" "}
                                                  Save
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </a>
                              <a
                                className="btn-eyesicon"
                                onClick={() => {
                                  this.ProspectNoteDelete(
                                    row._id,row.PageName
                                  );
                                }}
                              >
                                <i class="la flaticon-delete-1 delete-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>

                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.Sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.HCountPage}
                  onChange={this.HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries (filtered from{" "}
                    {this.state.Flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CountPage}
                    onChange={this.HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/*Potential meeting*/} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Potential meeting
                </h3>
                <div className="col text-right">
                  <button
                    onClick={() => {
                      this.PotentialAdd();
                    }}
                    className="btn btngroup m-btn "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedListP}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchPlist"
                    onKeyPress={(event) => this.RequestSearchListP(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell><a
                    onClick={() => {
                      this.PotentialSorting("ContactName");
                    }}>Contact Name</a></TableCell>
                <TableCell><a
                    onClick={() => {
                      this.PotentialSorting("Email");
                    }}>Email</a></TableCell>

                      <TableCell><a
                          onClick={() => {
                            this.PotentialSorting("Company");
                          }}
                        >
                          Company
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.PotentialSorting("Title");
                          }}
                        >
                          Title
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.PotentialSorting("EngagamentStatus");
                          }}
                        >
                          Enagement Status
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.ContactSorting("MeetingSourceName");
                          }}
                        >
                          Meeting Source Name
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.PotentialSorting("MeetingOwnerName");
                          }}
                        >
                          Owner Name
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.PotentialSorting("ReminderEmailAccount");
                          }}
                        >
                          Reminder Email Account
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.PotentialSorting("CreatedDate");
                          }}
                        >
                          Created Date
                        </a></TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.PlistRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.PlistRows?.map((row) => (
                        <>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                  this.setState((prev) => ({
                                    Open: {
                                      ...prev,
                                      [row.PotentialMeetingID]:
                                        !prev[row.PotentialMeetingID],
                                    },
                                  }))
                                }
                              >
                                {this.state.Open[row.PotentialMeetingID] ? (
                                  <i
                                    className="fa fa-minus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell> {row?.ContactName}</TableCell>
                            <TableCell>{row.Email}</TableCell>
                            <TableCell>{row.Company}</TableCell>
                            <TableCell>{row.Title}</TableCell>
                            <TableCell>
                              {row?.EngagamentStatus}
                            </TableCell>
                            <TableCell>
                              {row?.MeetingSourceName}
                            </TableCell>
                            <TableCell>
                              {row?.MeetingOwnerName}
                            </TableCell>
                            <TableCell>{row?.ReminderEmailAccount}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  PotenialEditPages(row.PotentialMeetingID);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={this.state.Open[row.PotentialMeetingID]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {this.state.Open[row.PotentialMeetingID] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {this.state.PotentialNotes.map((note) =>
                                        note.PotentialMeetingID ==
                                        row.PotentialMeetingID ? (
                                          <TableRow>
                                            <TableCell>{note?.Title}</TableCell>
                                            <TableCell scope="row">
                                              {note.Note}
                                            </TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                          </TableRow>
                                        ) : (
                                          <></>
                                        )
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.SflagP ? (
              <div className="row">
                <Pagination
                  component="div"
                  count={this.state.PlCountPage}
                  onChange={this.HandleChangePageListP}
                  showFirstButton
                  showLastButton
                />
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.PlRlen == 0
                      ? 0
                      : (this.state.PageP - 1) * this.state.RowsPerPageP +
                        1}{" "}
                    to{" "}
                    {this.state.PageP * this.state.RowsPerPageP >
                    this.state.PlRlen
                      ? this.state.PlRlen
                      : this.state.PageP * this.state.RowsPerPageP}{" "}
                    of {this.state.PlRlen} entries (filtered from{" "}
                    {this.state.PlFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.PlRlen == 0
                      ? 0
                      : (this.state.PageP - 1) * this.state.RowsPerPageP +
                        1}{" "}
                    to{" "}
                    {this.state.PageP * this.state.RowsPerPageP >
                    this.state.PlRlen
                      ? this.state.PlRlen
                      : this.state.PageP * this.state.RowsPerPageP}{" "}
                    of {this.state.PlRlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.PlCountPage}
                    onChange={this.HandleChangePageListP}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* meeting*/} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Meeting
                </h3>
                <div className="col text-right">
                  <button
                    onClick={() => {
                      this.MeetingAdd();
                    }}
                    className="btn btngroup m-btn "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedListM}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchMlist"
                    onKeyPress={(event) => this.RequestSearchListM(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                  <TableRow>
                      <TableCell></TableCell>
                      <TableCell><a
                    onClick={() => {
                      this.BookedMeetingSorting("ContactName");
                    }}>Contact Name</a></TableCell>
                <TableCell><a
                    onClick={() => {
                      this.BookedMeetingSorting("Email");
                    }}>Email</a></TableCell>

                      <TableCell><a
                          onClick={() => {
                            this.BookedMeetingSorting("Company");
                          }}
                        >
                          Company
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.BookedMeetingSorting("Title");
                          }}
                        >
                          Title
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.BookedMeetingSorting("Status");
                          }}
                        >
                          Meeting Status
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.BookedMeetingSorting("MeetingSource");
                          }}
                        >
                          Meeting Source
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.BookedMeetingSorting("MeetingOwner");
                          }}
                        >
                          Owner
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.BookedMeetingSorting("CreatedDate");
                          }}
                        >
                          Meeting Date
                        </a></TableCell>
                      <TableCell><a
                          onClick={() => {
                            this.BookedMeetingSorting("EventCreatedDt");
                          }}
                        >
                          Meeting Set Date
                        </a></TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    {this.state.MlistRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.MlistRows?.map((row) => (
                        <>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                  this.setState((prev) => ({
                                    Open: {
                                      ...prev,
                                      [row.EventID]: !prev[row.EventID],
                                    },
                                  }))
                                }
                              >
                                {this.state.Open[row.EventID] ? (
                                  <i
                                    className="fa fa-minus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell> {row?.ContactName}</TableCell>
                            <TableCell>{row.Email}</TableCell>
                            <TableCell>{row.Company}</TableCell>
                            <TableCell>{row.Title}</TableCell>
                            <TableCell>
                              {row?.Status}
                            </TableCell>
                            <TableCell>
                              {row?.MeetingSource}
                            </TableCell>
                            <TableCell>
                              {row?.MeetingOwner}
                            </TableCell>
                            <TableCell>{moment(
                                new Date(row?.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row.EventCreatedDt).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  MeetingEditPage(row.EventID);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={this.state.Open[row.EventID]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {this.state.Open[row.EventID] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {this.state.MeetingNotes.map((note) =>
                                        note.MeetingID.toString() ==
                                        row?.EventID ? (
                                          <TableRow>
                                            <TableCell>{note?.Title}</TableCell>
                                            <TableCell scope="row">
                                              {note.Note}
                                            </TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                            <TableCell
                                              className="labeltds"
                                              scope="row"
                                            ></TableCell>
                                          </TableRow>
                                        ) : (
                                          <></>
                                        )
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.SflagM ? (
              <div className="row">
                <Pagination
                  component="div"
                  count={this.state.MlCountPage}
                  onChange={this.HandleChangePageListM}
                  showFirstButton
                  showLastButton
                />
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.MlRlen == 0
                      ? 0
                      : (this.state.PageM - 1) * this.state.RowsPerPageM +
                        1}{" "}
                    to{" "}
                    {this.state.PageM * this.state.RowsPerPageM >
                    this.state.MlRlen
                      ? this.state.MlRlen
                      : this.state.PageM * this.state.RowsPerPageM}{" "}
                    of {this.state.MlRlen} entries (filtered from{" "}
                    {this.state.MlFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.MlRlen == 0
                      ? 0
                      : (this.state.PageM - 1) * this.state.RowsPerPageM +
                        1}{" "}
                    to{" "}
                    {this.state.PageM * this.state.RowsPerPageM >
                    this.state.MlRlen
                      ? this.state.MlRlen
                      : this.state.PageM * this.state.RowsPerPageM}{" "}
                    of {this.state.MlRlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.MlCountPage}
                    onChange={this.HandleChangePageListM}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* Campaigns */} 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Campaigns
                </h3>
              </div>
              <div className="col text-right">
                <button
                  onClick={() => {
                    this.CampaignsAdd();
                  }}
                  className="btn btngroup m-btn "
                >
                  <i className="la la-plus la-icon-font-size-13"></i>
                  <span className="Mobile_button">Add</span>
                </button>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedCampaigns}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchcampaigns"
                    onKeyPress={(event) => this.RequestSearchCampaignHistory(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.CAMRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.CAMRows?.map((row34) => (
                        <TableRow>
                          <TableCell>{row34?.CampaignName}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row34?.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.EditCampaignHistory(row.CampaignID);
                              }}
                            >
                              <i className="la flaticon-edit-1 edit-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.CAMSflag ? (
              <div class="row">
                <Pagination
                  component="div"

                  count={this.state.CAMPage}
                  onChange={this.HandleChangePageCampaignHistory}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CAMRlen == 0
                      ? 0
                      : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        0}{" "}
                    to{" "}
                    {this.state.CAMPage * this.state.CAMRowsPerPage >
                    this.state.CAMRlen
                      ? this.state.CAMRlen
                      : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                    of {this.state.CAMRlen} entries (filtered from{" "}
                    {this.state.CAMFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CAMRlen == 0
                      ? 0
                      : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        0}{" "}
                    to{" "}
                    {this.state.CAMPage * this.state.CAMRowsPerPage >
                    this.state.CAMRlen
                      ? this.state.CAMRlen
                      : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                    of {this.state.CAMRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CAMPage}
                    onChange={this.HandleChangePageCampaignHistory}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* Lists */}
 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Lists</h3>
              </div>
              <div className="col text-right">
              <button
                onClick={() => {
                  this.ListsAdd();
                }}
                className="btn btngroup m-btn "
              >
                <i class="la la-plus la-icon-font-size-13"></i>
                <span className="Mobile_button">Add</span>
              </button>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedList}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchlist"
                    onKeyPress={(event) => this.RequestSearchList(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell><a
                    onClick={() => {
                      this.ListSorting("Name");
                    }}>Name</a></TableCell>
                      <TableCell><a
                    onClick={() => {
                      this.ListSorting("CreatedDate");
                    }}>Created Date</a></TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.ListRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.ListRows?.map((row34) => (
                        <TableRow>
                          <TableCell>{row34.Name}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row34.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.EditLists(row.ListID);
                              }}
                            >
                              <i className="la flaticon-edit-1 edit-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.SflagL ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.LCountPage}
                  onChange={this.HandleChangePageList}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.LRlen == 0
                      ? 0
                      : (this.state.PageL - 1) * this.state.RowsPerPageL +
                        1}{" "}
                    to{" "}
                    {this.state.PageL * this.state.RowsPerPageL >
                    this.state.LRlen
                      ? this.state.LRlen
                      : this.state.PageL * this.state.RowsPerPageL}{" "}
                    of {this.state.LRlen} entries (filtered from{" "}
                    {this.state.LFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.LRlen == 0
                      ? 0
                      : (this.state.PageL - 1) * this.state.RowsPerPageL +
                        1}{" "}
                    to{" "}
                    {this.state.PageL * this.state.RowsPerPageL >
                    this.state.LRlen
                      ? this.state.LRlen
                      : this.state.PageL * this.state.RowsPerPageL}{" "}
                    of {this.state.LRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.LCountPage}
                    onChange={this.HandleChangePageList}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 
        {/* Account status history */}
 
          <div className="paddcols p-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Account Status History
                </h3>
              </div>
              <div className="col text-right"></div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.ChangeRowSelectedAccountStatusHistory}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchaccountstatushistory"
                    onKeyPress={(event) =>
                      this.RequestSearchContactHistory(event)
                    }
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                    <TableCell><a onClick={() => {this.HistorySatusSorting("SalesReplyAccountStatusID");}}>Old Status</a></TableCell>
                      <TableCell><a onClick={() => {this.HistorySatusSorting("OldSalesReplyAccountStatusID");}}>New Status</a></TableCell>
                      <TableCell><a onClick={() => {this.HistorySatusSorting("ChangedDate");}}>Change Date</a></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.HistoryRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.HistoryRows?.map((row) => (
                        <TableRow>
                          <TableCell>{row.p2[0]?.Status}</TableCell>
                          <TableCell>{row.p1[0]?.Status}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.ChangedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.Hsflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.HCountPage}
                  onChange={this.HandleChangePageAccountStatusHistory}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.HRlen == 0
                      ? 0
                      : (this.state.Hpage - 1) * this.state.RowsPerPageHistory +
                        1}{" "}
                    to{" "}
                    {this.state.Hpage * this.state.RowsPerPageHistory >
                    this.state.HRlen
                      ? this.state.HRlen
                      : this.state.Hpage * this.state.RowsPerPageHistory}{" "}
                    of {this.state.HRlen} entries (filtered from{" "}
                    {this.state.HFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.HRlen == 0
                      ? 0
                      : (this.state.Hpage - 1) * this.state.RowsPerPageHistory +
                        1}{" "}
                    to{" "}
                    {this.state.Hpage * this.state.RowsPerPageHistory >
                    this.state.HRlen
                      ? this.state.HRlen
                      : this.state.Hpage * this.state.RowsPerPageHistory}{" "}
                    of {this.state.HRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.HCountPage}
                    onChange={this.HandleChangePageAccountStatusHistory}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* call Histroy */}
 
          <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Call History
                </h3>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={(event) => this.ChangeRowSelectedListCH(event)}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchCMlist"
                    onKeyPress={(event) => this.RequestSearchListCH(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Result</TableCell>
                      <TableCell>Call Time/Date</TableCell>
                      <TableCell>Recording</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.CHlistRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      this.state.CHlistRows?.map((row34) => (
                        <TableRow>
                          <TableCell>{row34.Name}</TableCell>
                          <TableCell>{row34.Title}</TableCell>
                          <TableCell>{row34.Company}</TableCell>
                          <TableCell>{row34.Duration}</TableCell>
                          <TableCell>{row34.Name}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(
                                row34.RecordingFileCreatedDate
                              ).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>{row34.RecordingFileName}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.SflagCH ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.CHlCountPage}
                  onChange={this.HandleChangePageListCH}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CHlRlen == 0
                      ? 0
                      : (this.state.PageCH - 1) * this.state.RowsPerPageCH +
                        1}{" "}
                    to{" "}
                    {this.state.PageCH * this.state.RowsPerPageCH >
                    this.state.CHlRlen
                      ? this.state.CHlRlen
                      : this.state.PageCH * this.state.RowsPerPageCH}{" "}
                    of {this.state.CHlRlen} entries (filtered from{" "}
                    {this.state.CHlFlen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.CHlRlen == 0
                      ? 0
                      : (this.state.PageCH - 1) * this.state.RowsPerPageCH +
                        1}{" "}
                    to{" "}
                    {this.state.PageCH * this.state.RowsPerPageCH >
                    this.state.LRlen
                      ? this.state.CHlRlen
                      : this.state.PageCH * this.state.RowsPerPageCH}{" "}
                    of {this.state.CHlRlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CHlCountPage}
                    onChange={this.HandleChangePageListCH}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div>
        </div> 
      </div> 
      <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditAccountsPage = connect(
  mapState,
  actionCreators
)(CEditAccountsPage);
export { connectedCEditAccountsPage as CEditAccountsPage };
