import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import CollapsibleTable from '../_components/usertable';
import InsightsTabing from '../_components/InsightsTabing';
import Pipeline from '../_components/Pipeline';
import MeetingSource from '../_components/MeetingSource';

//chart
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  charts(FusionCharts);
  const dataSource = {
    chart: {
      caption: "Meeting Status",
      //subcaption: "For a net-worth of $1M",
      theme: "fusion",
      subCaption: " ",
      formatnumberscale: "0",
      showLegend: "1",
      showLabels: "0",
      showValues: "1",
      valuePosition: "inside",
      numbersuffix: "0%",
      plothighlighteffect: "false",
      legendcaptionbold: "1",
      legendcaptionfontsize: "15",
      legendCaptionFontColor: "#000",
      showPercentValues: "0",
      showPercentInToolTip: "0",
      enableSlicing: "1",
      legendPosition: "right",
      legendCaption: "Meeting Status: ",
      legendBgColor: "#FFF",
      legendBorderColor: "#ffF",
      legendShadow: "0",
      legendItemFontSize: "14",
      legendWidth: "300",
    },
    data: [
      {
        label: "Set",
        value: "910000",
        "color": "#302e49"
      },
      {
        label: "Qualified",
        value: "15000",
        "color": "#6f4569"
      },
      {
        label: "Opportunity",
        value: "15000",
        "color": "#b35d75"
      },
      {
        label: "Contract Sent",
        value: "50000",
        "color": "#e9826e"
      },
      {
        label: "Closed Won",
        value: "15000",
        "color": "#ffb963"
      },
      {
        label: "Rescheduling",
        value: "20000",
        "color": "#f9f871"
      }
    ]
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabdes">
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Meetings" {...a11yProps(0)} />
            <Tab label="Meetings Insights" {...a11yProps(1)} />
            <Tab label="Pipeline" {...a11yProps(2)} />
            <Tab label="Meetings By Meeting Source" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone" index={0}>
          
        <div className="col mb-4">
            <div className="row">
                <div className="col">
                    <div className="bg-light-sky boxcardcounter">
                        <div class="line-title">
                            <span class="clr-sky box-main-title">0</span>
                        </div>
                        <div class="box-inner-title">
                            <string class="clr-sky">Total Meetings</string>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="bg-light-sky boxcardcounter">
                        <div class="line-title">
                            <span class="clr-sky box-main-title">0</span>
                        </div>
                        <div class="box-inner-title">
                            <string class="clr-sky">Total Meetings</string>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="bg-light-sky boxcardcounter">
                        <div class="line-title">
                            <span class="clr-sky box-main-title">0</span>
                        </div>
                        <div class="box-inner-title">
                            <string class="clr-sky">Total Closed Lost</string>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="bg-light-sky boxcardcounter">
                        <div class="line-title">
                            <span class="clr-sky box-main-title">0%</span>
                        </div>
                        <div class="box-inner-title">
                            <string class="clr-sky">Closed Won Rate</string>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col border-y">
            <div className="row">
                <div className="col minheight">

               <div className="chartboxsmet">
                  <ReactFusioncharts
                    type="pie3d"
                    width="100%"
                    height="100%"
                    dataFormat="JSON"
                    dataSource={dataSource}
                  />      
                </div>              

                </div>
                <div className="col brdr-left minheight">
                  <div className="chartboxsmet">
                    <ReactFusioncharts
                      type="pie3d"
                      width="100%"
                      height="100%"
                      dataFormat="JSON"
                      dataSource={dataSource}
                    />      
                  </div>   
                </div>
            </div>
        </div>   
      
        <div className="row">
          <div className="col border-bottom">
              <h4 className="headertitle float-left">Booked Meetings</h4>

              <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                      <i class="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li>
                      <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>Export</span>
                      </a>
                    </li>
                    <li>
                      <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                    <li>
                      <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                        <i class="fa la-icon-font-size-13 fa-filter"></i>
                        <span>Add Filter</span>
                      </a>
                    </li>
                    <li>
                      <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                        <i class="fa la-icon-font-size-13 flaticon-interface-8.++ "></i>
                        <span>Custom Column</span>
                      </a>
                    </li>
                  </ul>
              </div>
          </div>
        </div>

        <div className="row my-5">
          <div className="col col-lg-1"><label class="labeltexts">Owner</label></div>
          <div className="col col-lg-4">
              <select class="select-boxs">
                <option value="">--Select--</option>
                <option value="18542">test one</option>
              </select>
          </div>
          <div className="col col-lg-2">
            <div className="boxborders">
              <label class="check_bx">
                  <input type="checkbox" /><span className="checkmark"></span>
                  Remember me
              </label>
            </div>
          </div>
          <div className="col col-lg-4">
            <a href="javascript:void(0);" class="btn btn-primary btnColor">Search</a>
            <a href="javascript:void(0);" class="ml-3 btn btn-secondary"><i class="la la-refresh"></i> Reset</a>
          </div>
        </div>

      <div className="borderboxstp"></div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
            <label className="textlabelte">Show 
              <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              </select> 
              entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="table-bordered">
            <CollapsibleTable />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col dataTables_info">
          <p>Showing 1 to 71 of 71 entries</p>
        </div>
      </div>
        </TabPanel>


        <TabPanel value={value} index={1}>
            <div className="tab">
              <InsightsTabing />        
            </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          
        <div className="row ">
        <div className="col-sm-12 col-md-6">
            <label className="textlabelte">Show 
              <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              </select> 
              entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="table-bordered">
            <Pipeline />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col dataTables_info">
          <p>Showing 1 to 71 of 71 entries</p>
        </div>
      </div>
          
        </TabPanel>
        <TabPanel value={value} index={3}>
  
        <div className="row ">
        <div className="col-sm-12 col-md-6">
            <label className="textlabelte">Show 
              <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              </select> 
              entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="table-bordered">
             <MeetingSource />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col dataTables_info">
          <p>Showing 1 to 71 of 71 entries</p>
        </div>
      </div>



        </TabPanel>
      </div>

      <footer class="m-footer">
        <span class="m-footer__copyright">2021 © <a href="https://saleshive.com/" class="m-link">SalesHive</a>. All Rights Reserved.</span>
      </footer>

    </div>
  );
}