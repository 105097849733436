import React, { useState, useEffect,useMemo } from "react";
import Axios from "axios";
import Select from "react-select";
import Popup from "reactjs-popup";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonConstants } from "../../../_constants/common.constants";

toast.configure();


  const EditFilter = ({ options,data,AppliedFilter,CloseModel,IsSaveButton,ContactTags,ClientID,UserID,FilterID,UpdateFilterDropDown}) => {
    
    const initialFilterProperty = {
      _id:0,
      FilterPropertyName: options[0]?.FieldValue || '',
      ExpressionName: options[0]?.Options[0]?.value || '',
      IsCustom: options[0]?.IsCustom || false,
      IsSelect: options[0]?.IsSelect || false,
      FilterPropertyValue1: '',
      FilterPropertyValue2: '',
      selectValue: null,
    };
  
    const [filterProperties, setFilterProperties] = useState([initialFilterProperty]);
    const [TimeZone] = useState([
      {value:"AT",label:"AT"},
    {value:"CA",label:"CA"},
    {value:"CT",label:"CT"},
    {value:"ET",label:"ET"},
    {value:"MT",label:"MT"},
    {value:"PT",label:"PT"},
    ]);
    const [LastCallResultDropDown] = useState([
      { label: 'Never Been Called', value: 'never been called' },
      {value:"uncontacted",label:"Uncontacted"},
      {value:"active",label:"Active"},
      {value:"opened",label:"Opened"},
      {value:"no response",label:"No Response"},
      {value:"no response - open",label:"No Response - Open"},
      {value:"soft bounce",label:"Soft Bounce"},
      {value:"invalid email",label:"Invalid Email"},
      {value:"hard bounce",label:"Hard Bounce"},
    ]);
    const [ContactTag, setContactTag] = useState(ContactTags);
    const [ClientId,SetClientId] = useState(ClientID);
    const [UserId,SetUserId] = useState(UserID);
    const [FilterId,SetFilterId] = useState(FilterID);
    const [DeletedProperties,SetDeletedProperties] = useState([]);
    const [SaveAsPopup,SetSaveAsPopup] = useState(false)
    const [isFilterNameValid, setIsFilterNameValid] = useState(true);
    const [filterName, setFilterName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
  
   
  
    // Calculate the filtered properties memoized value
    const filteredProperties = useMemo(() => {
      
      return data.map((dataItem) => {
        const IsSelect =
          dataItem.FilterPropertyName === 'LastCallResult' ||
          dataItem.FilterPropertyName === 'TimeZone' ||
          dataItem.FilterPropertyName === 'ContactTag' ||
          dataItem.FilterPropertyName === 'ContactStatus';
  
        let filterPropertyValue1 = dataItem.FilterPropertyValue1;
  
        if (IsSelect) {
          // Convert comma-separated values into an array of objects
          filterPropertyValue1 = filterPropertyValue1
            .split(',')
            .map((value) => ({
              value: value.trim(),
              label: value.trim(), // You can customize the label as needed
            }));
        }
  
        return {
          _id:dataItem._id || 0,
          FilterPropertyName: dataItem.FilterPropertyName,
          ExpressionName: dataItem.ExpressionName,
          IsCustom: dataItem.FieldType === 'Text',
          IsSelect: IsSelect,
          IsCustom:dataItem.IsCustom,
          FilterPropertyValue1: dataItem.FilterPropertyValue1,
          FilterPropertyValue2: dataItem.FilterPropertyValue2,
          selectValue: filterPropertyValue1,
        };
      });
    }, [data]);
  
    // Update filterProperties and ContactTag only when filteredProperties or ContactTags changes
    useEffect(() => {
      setFilterProperties(filteredProperties);
      setContactTag(ContactTags);
      SetFilterId(FilterID)
    }, [filteredProperties, ContactTags]);
  
    const addFilterProperty = () => {
      
      setFilterProperties([...filterProperties, { ...initialFilterProperty }]);
    };
  
    const removeFilterProperty = (index) => {
      if (filterProperties.length === 1) {
        toast.error(
          <div className="toastsize">
            Filter<br />
            Required filter property at least one
          </div>
        );
        return;
      }
      
      const updatedFilterProperties = [...filterProperties];
      const deletedId = updatedFilterProperties[0]._id; // Access the _id of the deleted object
      const deletedFilterProperty = updatedFilterProperties.splice(index, 1); // Get the deleted object
    
      SetDeletedProperties((prevDeletedProperties) => [...prevDeletedProperties, deletedId]);
      setFilterProperties(updatedFilterProperties);
    
      
      
    };
    
  
    const handleFieldChange = (index, selectedFieldValue) => {
      
      const updatedFilterProperties = [...filterProperties];
      updatedFilterProperties[index].FilterPropertyName = selectedFieldValue;
      updatedFilterProperties[index].IsCustom = options.find((option) => option.FieldValue === selectedFieldValue)?.IsCustom || false;
      updatedFilterProperties[index].IsSelect = options.find((option) => option.FieldValue === selectedFieldValue)?.IsSelect || false;
      // Automatically update expression based on the selected field
      updatedFilterProperties[index].ExpressionName = options.find((option) => option.FieldValue === selectedFieldValue)?.Options[0]?.value || '';
  
      setFilterProperties(updatedFilterProperties);
    };
  
    const handleSelectChange = (index, selectedOption) => {
      const updatedFilterProperties = [...filterProperties];
      updatedFilterProperties[index].selectValue = selectedOption;
      // Create a comma-separated string from selected values
      const selectedValues = selectedOption.map((option) => option.value);
      updatedFilterProperties[index].FilterPropertyValue1 = selectedValues.join(',');
      setFilterProperties(updatedFilterProperties);
    };

    //Data model method
    const DataModel = () => {
        // Check if any search value is empty
      const hasEmptySearchValue = filterProperties.some(
        (filterProperty) => filterProperty.FilterPropertyValue1 === ""
      );
  
      if (hasEmptySearchValue) {
        toast.error(<div className="toastsize">Filter <br/> Property value can not be an empty</div>);
        return;
      }
      
      // Create an array of filter objects
      const filters = filterProperties.map((filterProperty) => ({
        _id:filterProperty._id,
        FilterPropertyName: filterProperty.FilterPropertyName,
        ExpressionName: filterProperty.ExpressionName,
        FilterPropertyValue1: filterProperty.FilterPropertyValue1,
        FilterPropertyValue2: filterProperty.FilterPropertyValue2,
        FieldType: "empty", // You can set the FieldType based on your requirements
        IsCustom: filterProperty.IsCustom, // Set IsCustom based on the field's IsCustom value
        IsSelect: filterProperty.IsSelect, // Include IsSelect in the filter object
        // selectValue: filterProperty.selectValue?.map((option) => option.value) // Include selectValue as an array of values
      }));
      return filters;
    }
      const handleSubmit = () => {
        let FilterPropertiesData = DataModel()
        AppliedFilter(FilterPropertiesData)
      };

      //Save Filter
      const SaveFilter = async() => {
        let FilterPropertiesData = DataModel()
        const InputParameter = {
            ClientID:ClientId,
            LastUpdatedBy:UserId,
            FilterID:FilterId,
            FilterParameters: FilterPropertiesData,
            DeletedProperties:DeletedProperties,
            LastUpdatedDate:new Date()
        }
        
        let res = await Axios({
          url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/UpdateColdCallingContactFilter",
          method: "POST",
          data: InputParameter,
        });
  
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Filter <br/> Filter updated successFully.</div>)
          CloseModelBox(false)
          UpdateFilterDropDown(true)
        } else {
          toast.error("Something went wrong in filter name exists api");
        }

      }

      //Save As Filter
      const OpenSaveAsFilter = () => {
        SetSaveAsPopup(true)
        // CloseModelBox(false)
      }

      const handleFilterNameBlur = async () => {
        const trimmedFilterName = filterName.trim();
        
        if (trimmedFilterName === "") {
          setErrorMessage("Please enter filter name");
          setIsFilterNameValid(false);
        } else {
          const isValid = await CheckFilterName(trimmedFilterName);
          setIsFilterNameValid(isValid);
          
          if (!isValid) {
            setErrorMessage("Contact filter already exists.");
          setIsFilterNameValid(false);

          } else {
            setErrorMessage("");
          setIsFilterNameValid(true);
          }
        }
      };

      
      const CheckFilterName = async (filterName) => {
        try {
          const InputParameters = {
            ClientID: ClientID, // Assuming ClientID is defined elsewhere
            FilterName: filterName,
               };
      
          const response = await Axios.post(CommonConstants.MOL_APIURL + "/cold_calling_contacts/CheckColdCallingContactFilterName", InputParameters);
      
          if (response.data.StatusMessage === "SUCCESS") {
            

            return response.data.Data <= 0;
          }
      
          toast.error("Something went wrong in filter name exists API");
        } catch (error) {
          console.error("An error occurred:", error);
        }
      
        return false;
      };

             //Close the edit filter model
             const CloseModelBox = () => {
              CloseModel(false)
            }

      
      //Save As 
      const SaveAsFilter = async () => {
        try {
          const trimmedFilterName = filterName.trim();
        
          if (trimmedFilterName === "") {
            setErrorMessage("Please enter filter name");
            setIsFilterNameValid(false);
          } else {
            const isValid = await CheckFilterName(trimmedFilterName);
            setIsFilterNameValid(isValid);
            
            if (!isValid) {
              setErrorMessage("Contact filter already exists.");
            setIsFilterNameValid(false);
  
            } else {
              setErrorMessage("");
            setIsFilterNameValid(true);
            if ( DataModel() ) {
              const Mappdata = DataModel().map((el) => {
                return {
                  ColumnName: el.FilterPropertyName,
                  expression: el.ExpressionName,
                  searchvalue: el.FilterPropertyValue1,
                  searchvalue1: el.FilterPropertyValue2,
                  FieldType: el.FieldType,
                };
              });
        
              const InputParameters = {
                ClientID: ClientID, // Assuming ClientID is defined elsewhere
                FilterName: filterName,
                FilterParameters: Mappdata,
                CreatedDate: new Date(),
                UserID: UserID, // Assuming UserID is defined elsewhere
              };
        
              try {
                const res = await Axios.post(
                  CommonConstants.MOL_APIURL + "/cold_calling_contacts/SaveColdCallingContactFilter",
                  InputParameters
                );
        
                if (res.data.StatusMessage === "SUCCESS") {
                  SetSaveAsPopup(false);
                  setFilterName("");
                  CloseModel(false);
                  toast.success(
                    <div className="toastsize">Filter<br />Filter Added Successfully.</div>
                  );
                } else {
                  toast.error("Error in saving the filter");
                }
              } catch (error) {
                console.error("An error occurred while saving the filter:", error);
                toast.error("Error in saving the filter");
              }
            }
            }
          }
      
         
        } catch (error) {
          console.error("An error occurred while handling filter name blur:", error);
          toast.error("Error in handling filter name blur");
        }
      };
      


    return (
        <>
                                          <div className="p-3">
                                            <div className="modal-body">
                                             
                                              <div className="table-bordered">
                                                <TableContainer component={Paper}>
                                                  <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell> Property{" "} </TableCell>
                                                        <TableCell align="right"> {" "}</TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {filterProperties.map((filterProperty, index) => (
                                                      <TableRow sx={{"&:last-child td, &:last-child th":{ border: 0 },}} key={index}>
                                                            
                                                            <TableCell align="right">
                                                            <select
                                                                className="form-control m-input" autocomplete="off"
                                                                value={filterProperty.FilterPropertyName}
                                                                onChange={(e) => handleFieldChange(index, e.target.value)}
                                                                >
                                                                {options.map((option) => (
                                                                    <option key={option.FieldName} value={option.FieldValue}>
                                                                    {option.ColumnName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            </TableCell>
                                                            <TableCell>
                                                              {filterProperty.IsCustom == true ? (
                                                                <select className="form-control m-input" autocomplete="off">
                                                                  <option id="text" value="Text"> {" "}Text </option>
                                                                </select>
                                                              ) : null} 
                                                            </TableCell>
                                                            <TableCell align="right">
                                                              
                                                            <select
                                                                className="form-control m-input" autocomplete="off"
                                                                value={filterProperty.ExpressionName}
                                                                onChange={(e) => {
                                                                    const updatedFilterProperties = [...filterProperties];
                                                                    updatedFilterProperties[index].ExpressionName = e.target.value;
                                                                    setFilterProperties(updatedFilterProperties);
                                                                }}
                                                                >
                                                                {options.find((option) =>option.FieldValue === filterProperty.FilterPropertyName )
                                                                    ?.Options.map((expressionOption) => (
                                                                    <option
                                                                        key={expressionOption.value}
                                                                        value={expressionOption.value}
                                                                    >
                                                                        {expressionOption.label}
                                                                    </option>
                                                                    ))}
                                                                </select>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                            {["True", "False", "IsBlank", "IsNotBlank"].includes(filterProperty.ExpressionName) ? (
                                                            <span></span> ) : filterProperty.ExpressionName === "between" ? (
                                                            <>
                                                                {filterProperty.IsSelect ? (
                                                                <Select
                                                                    options={filterProperty.FilterPropertyName === "TimeZone" ? TimeZone : filterProperty.FilterPropertyName === "LastCallResult" ? LastCallResultDropDown :  filterProperty.FilterPropertyName === "ContactTag" ?ContactTag:[]}
                                                                    isMulti
                                                                    value={filterProperty.selectValue}
                                                                    onChange={(selectedOption) =>
                                                                    handleSelectChange(index, selectedOption)
                                                                    }
                                                                />
                                                                ) : (
                                                                <>
                                                                    <input
                                                                      className="form-control m-input"
                                                                      type={filterProperty.FilterPropertyName ==="LastSendDate" ||filterProperty.FilterPropertyName ==="LastValidationDate"
                                                                          ? "date": "text"}
                                                                      value={filterProperty.FilterPropertyValue1}
                                                                      onChange={(e) => {
                                                                          const updatedFilterProperties = [...filterProperties];
                                                                          updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                                          setFilterProperties(updatedFilterProperties);
                                                                      }}
                                                                    />
                                                                   
                                                                    <input
                                                                      className="form-control m-input"
                                                                        type={
                                                                        filterProperty.FilterPropertyName ===
                                                                            "LastSendDate" ||
                                                                        filterProperty.FilterPropertyName ===
                                                                            "LastValidationDate"
                                                                            ? "date"
                                                                            : "text"
                                                                        }
                                                                        value={filterProperty.FilterPropertyValue2}
                                                                        onChange={(e) => {
                                                                        const updatedFilterProperties = [
                                                                            ...filterProperties
                                                                        ];
                                                                        updatedFilterProperties[
                                                                            index
                                                                        ].FilterPropertyValue2 = e.target.value;
                                                                        setFilterProperties(updatedFilterProperties);
                                                                      }}
                                                                    />
                                                                     
                                                                </>
                                                                )}
                                                            </>
                                                            ) : filterProperty.IsSelect ? (
                                                            <Select
                                                            options={filterProperty.FilterPropertyName === "TimeZone" ? TimeZone : filterProperty.FilterPropertyName === "LastCallResult" ? LastCallResultDropDown :  filterProperty.FilterPropertyName === "ContactTag" ?ContactTag:[]}
                                                                isMulti
                                                                value={filterProperty.selectValue}
                                                                onChange={(selectedOption) =>
                                                                handleSelectChange(index, selectedOption)
                                                                }
                                                            />
                                                            ) : (
                                                            <input
                                                            className="form-control m-input"
                                                                type={
                                                                filterProperty.FilterPropertyName === "LastSendDate" ||
                                                                filterProperty.FilterPropertyName === "LastValidationDate"
                                                                    ? "date"
                                                                    : "text"
                                                                }
                                                                value={filterProperty.FilterPropertyValue1}
                                                                onChange={(e) => {
                                                                const updatedFilterProperties = [...filterProperties];
                                                                updatedFilterProperties[index].FilterPropertyValue1 =
                                                                    e.target.value;
                                                                setFilterProperties(updatedFilterProperties);
                                                                }}
                                                            />
                                                            )}
                                                            </TableCell>

                                                            <TableCell align="right">
                                                            <button type="button" className="btn btngroup btnhov-delete" onClick={() => removeFilterProperty(index)}>
                                                                <i className="la flaticon-delete-1"></i>
                                                              </button>
                                                            </TableCell>
                                                            <TableCell align="right"></TableCell>
                                                            <TableCell align="right"></TableCell>
                                                          </TableRow>
                                                         ) 
                                                       )} 
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-12 text-left">
                                                  <button
                                                    type="button"
                                                    className="btn btngroup"
                                                    onClick={addFilterProperty}
                                                  >
                                                    <i className="la la-plus la-icon-font-size-13"></i>{" "}
                                                    Add Property{" "}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="border-top modal-content text-right">
                                              <div className="row">
                                                <div className="col-lg-12 pull-right">
                                                  <button
                                                    id="backtolist"
                                                    className="btn btn-secondary mr-2"
                                                    onClick={CloseModelBox}
                                                  >
                                                    cancel
                                                  </button>
                                                  <button
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                    onClick={handleSubmit}
                                                  >
                                                    Apply
                                                  </button>
                                                  {
                                                    IsSaveButton ? <button
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                    onClick={SaveFilter}
                                                  >
                                                    <i className="la la-save"></i>{" "}
                                                    Save
                                                  </button> : <></>
                                                  }
                                                  
                                                  <button
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                   onClick={OpenSaveAsFilter}
                                                  >
                                                    <i className="la la-save"></i>{" "}
                                                    Save As
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        
                                          <Popup open={SaveAsPopup}>
                                          <div className="modal-black"></div>
                                            <div className="filterPopup largerPopup1000">
                                              <div className="paddingboxTerms">
                                                <div className="modal-header py-4 px-3">
                                                  <div className="w-100 d-flex px-3">
                                                    <h5 className="mb-0">Filter</h5>
                                                    <button className="close" onClick={() => SetSaveAsPopup(false)}>
                                                      <span aria-hidden="true" className="la la-remove"></span>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="row px-4">
                                                    <div className="col-lg-8 row mb-4">
                                                      <label className="col-lg-3 col-form-label pl-0">
                                                        Filter Name
                                                      </label>
                                                      <div className="col-lg-9 d-flex">
                                                      <input
                                                          className={`form-control m-input `}
                                                          data-val-required="Please enter title"
                                                          id="FilterName"
                                                          name="FilterName"
                                                          placeholder="Filter Name"
                                                          type="text"
                                                          value={filterName}
                                                          onChange={(e) => setFilterName(e.target.value)}
                                                          onBlur={handleFilterNameBlur}
                                                        />
                                                      </div>
                                                        {errorMessage && <span style={{color:'red'}}>{errorMessage}</span>}
                                                    </div>
                                                  </div>

                                                
                                                
                                                </div>
                                                <div className="modal-footer">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-dismiss="modal"
                                                    onClick={() => SetSaveAsPopup(false)}
                                                  >
                                                    Cancel
                                                  </button>
                                                
                                                  <button
                                                    id="m_emailInvite_submit"
                                                    href="javascript:void(0);"
                                                    className="btn btn-primary btnColor"
                                                    onClick={() => SaveAsFilter()}
                                                  >
                                                    <i className="la la-save mr-2"></i> Save
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </Popup>
        </>
    );
};

export default EditFilter;