import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import { userActions } from "../../_actions";
import Footer from "../../_components/user/footer/footer";
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';

import Opportunities from '../../_components/clients/opportunities/opportunities';
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";

class COpportunitiesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ExportData: [],
      Role:null,
      ClientID:null,
      UserID:null
    };

    this.addpage = this.addpage.bind(this);
  }
  addpage() {
    history.push({pathname:"/caddbookedmeetings", state:{Pagename:"coppotunities",BackPage:"/copportunities"}});
  }
  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ Role: Details.Role });
    }
  }


  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center">
                <h4 className="headertitle float-left px-0 py-3">Opportunities</h4>
                <div className="listing-li float-right py-3">
                  <ul className="my-0">
                    {/* <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom"
                        onClick={this.ExportMethod}
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                    
                          <CSVDownloader
                            data={this.state.ExportData}
                            filename={"Opportunities"}
                            bom={true}
                          >
                            Export
                          </CSVDownloader>
                       
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom " 
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>Export</span>
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom  "
                        onClick={() => this.addpage()}
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="paddcols px-0"> 
              <div className="row">
                <div className="col px-5"> 
                    <Opportunities /> 
                </div>
              </div> 
            </div>
            
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCOpportunitiesPage = connect(mapState, actionCreators)(COpportunitiesPage);
export { connectedCOpportunitiesPage as COpportunitiesPage };