import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import ClientsLists from '../../_components/clients/lists/lists';
//import CollapsibleTable from '../_components/usertable';
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";
const Fileupload = require("../../_helpers/fileupload");
import loadingicon from "../../images/loading.gif";
toast.configure();

class CListsPage extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        Pager: {},
        PageOfItems: [],
        Files: [],
        List: [],
        Columns: [],
        Reset: false,
        CsvData: [],
        DropBoxData: [],
        TotalDisplayCount:false,
        FileName: "",
        ExportData: [],
        TotalResult:0,
        TotalDuplicate:0,
        TotalError:0,
        TotalSuccess:0,
        ShowPopupForExport:false,
        Useremail:"",
        UserID:0,
        UserIDby:0,
        ClientID:0,
        Role:"",
        Errors:{},
        CName: "",
  
      };
      this.AddListField = this.AddListField.bind(this);
      this.HandleOnDrop = this.HandleOnDrop.bind(this);
      this.HandleOnError = this.HandleOnError.bind(this);
      this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
      this.ImportCsv = this.ImportCsv.bind(this);
      this.MapCsv = this.MapCsv.bind(this);
      this.CancleCsv = this.CancleCsv.bind(this);
      this.SaveCsv = this.SaveCsv.bind(this);
    }
  
    componentDidMount() {
      document.getElementById("hidelod").style.display = "none";
      document.getElementById("import_csv").style.display = "none";
      document.getElementById("map_attribute").style.display = "none";
      var Details = GetUserDetails();
      if (Details != null) {
        this.state.ClientID = Details.ClientID;
        this.state.UserID = Details.ParentUserID;
        this.state.UserIDby = Details.ChildUserID;
        this.state.Useremail = Details.Username;
        this.state.Role = Details.Role;
      }
      GetClientDetails(Details.ClientID).then((result) => {
        this.setState({ CName: result[0].Name });
      });
  
      var InputParameter = {
        ClientID: this.state.ClientID,
        UserID:this.state.UserID,
        Role:this.state.Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/list/ExportCSVList",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        this.setState({ ExportData: res.data });
      });
    }
  
    //reset csv
    ResetCsv() {
      this.setState({ Reset: true });
    }
  
  //change dropdown
    HandleOnDrop(Data, FileInfo) {
      var FileName = FileInfo.name;
      this.setState({ Files: FileInfo });
      var sep = FileName.split(".");
      if (sep[1] != "csv") {
        this.setState({ Reset: true });
        this.ResetCsv();
      } else {
        var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
        this.setState({ FileName: csvfile });
        this.setState({ CsvData: Data });
        this.setState({ DropBoxData: Data[0].data });
      }
    }
  
  //Handle error
    HandleOnError(Err, File, InputElem, Reason) {
    }
  
  //Handle remove file
    HandleOnRemoveFile(Data) {
    }
  
  //import csv
    ImportCsv() {
      document.getElementById("import_csv").style.display = "block";
    }
  
  //map csv
    MapCsv() {
      document.getElementById("hidelod").style.display = "block";
      if (this.state.CsvData.length != 0) {
        document.getElementById("map_attribute").style.display = "block";
        this.state.DropBoxData.map((dddd, index) => {
        });
        this.ResetCsv()
        document.getElementById("hidelod").style.display = "none";
      } else {
        this.ResetCsv()
        document.getElementById("hidelod").style.display = "none";
      }
    }
  
    //Export Button
    ExportBtn(){
      toast.success(<div>
        Data exported successfully.
      </div>)
    }
  
  //cancle Csv
    CancleCsv() {
      this.ResetCsv();
      this.setState({ TotalDisplayCount: false });
      document.getElementById("map_attribute").style.display = "none";
      document.getElementById("import_csv").style.display = "none";
    }
  
  //add List
    SaveCsv() {
      document.getElementById("hidelod").style.display = "block";
      var ListImport = document.getElementById("ListImport").value;
  
      Fileupload.getBase64(this.state.Files, (Result) => {
        var RreglarString = Result;
        var Splirs = RreglarString.split("base64,");
        var Base64 = Splirs[1];
        var InputParameter = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserList",
          File: Base64,
          ImageName: this.state.FileName,
          FileData: this.state.CsvData,
          Name: ListImport,
          ClientID: this.state.ClientID,
          UserID:this.state.UserID,
          Role:this.state.Role
  
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/list/ImportCSVListData",
          method: "POST",
          data: InputParameter,
        }).then((Res) => {
          if (Res.data.StatusMessage == "SUCCESS") {
            toast.success(<div className="toastsize">SalesHive List<br/>SalesHive List added successfully.</div>);
            history.push("/Clists");
            document.getElementById("hidelod").style.display = "none";
            this.setState({ TotalDisplayCount: true });
            this.setState({ TotalResult: Res.data.Total });
            this.setState({ TotalDuplicate: Res.data.TotalDuplication });
            this.setState({ TotalError: Res.data.TotalError});
            this.setState({ TotalSuccess: Res.data.TotalSuccess });
            document.getElementById("map_attribute").style.display = "none";
            document.getElementById("import_csv").style.display = "none";
          } else {
            toast.error(Res.data.Message);
          }
        });
      });
    }
  
    //add function
    AddListField() {
      history.push("/caddlists");
    }
  
    // formvalidation
     FromValidation = async () => {
      let FormIsValid = true;
      let Errors = {};
      var UserEmail = document.getElementById("Username").value.trim()
      let IsExist = await this.EmailValidationCheck(UserEmail)
  ;
      if (UserEmail == "") {
        FormIsValid = false;
        Errors["Username"] = "Please enter email.";
      }
  
      if (IsExist == true) {
        FormIsValid = false;
        Errors["Username"] = "invalid email.";
      }
  
     this.setState({Errors:Errors});
  
      return FormIsValid;
    };
  
      // find exits Email
       EmailValidationCheck = async (UserEmail) => {
         let regexp =
           /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
         let Errors = {};
         if (!regexp.test(UserEmail)
      && UserEmail != "") {
           Errors["Username"] = "invalid email.";
           this.setState({Errors:Errors});
           return true;
         } else {
           return false;
         }
       };
  
         // handle change
     HandleEmailCheck = () => {
      var UserEmail = document.getElementById("Username").value.trim()
      this.setState({Errors:""});
      if (UserEmail != "") {
        var UserEmail = document.getElementById("Username").value.trim()
  
        this.EmailValidationCheck(UserEmail);
      } else {
        Errors.Username = "";
      }
    };
  
    ContactExportCsvBulk = async(e) => {
      e.preventDefault();
      e.currentTarget.disabled = true;
      var Final_flag = await this.FromValidation();
      var UserEmail = document.getElementById("Username").value.trim()
      if(Final_flag == true){
        var InputParaMeter = {
          ClientIDs:"",
          AccountTypeID:null,
          AccountType:null,
          UserID:this.state.UserID,
          CreatedDate:new Date(),
          CreatedBy:this.state.UserIDby,
          LastUpdatedBy:null,
          LastUpdatedDate:null,
          Email:UserEmail,
          InProcess:false,
          IsComplete:false,
          InProcessDate:null,
          IsCompleteDate:null,
          Page:"List",
          ClientID:this.state.ClientID,
          CampaignID:null,
          DynamicQuery:null,
          Role:this.state.Role
  
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/list/exportcontactlist",
          method:"POST",
          data:InputParaMeter
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
              Contact<br/>
              You will get file soon on Submitted Email.
             </div>
            );
            this.setState({ShowPopupForExport:false})
            // history.push("/list");
          } else {
            toast.error(res.data.Message);
          }
        })
      } else {
        document.getElementById("submit").disabled = false;
      }
    }
  
    render() {
      return (
        <>
            <div id="hidelod" className="loding-display">
            <img src={loadingicon} />
          </div>
          <div className="adminmain mheight"> 
            <Sidebar className="" /> 
            <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitle float-left pl-0">Lists</h4>
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                        <a
                           onClick={this.ImportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-upload"></i>
                          <span>Import</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={this.AddListField}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                      <li>
                      <CSVDownloader className="px-0"
                              data={this.state.ExportData}
                              filename={this.state.CName + "ListCsv"}
                              bom={true}
                            >
                        <a
  
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={this.ExportBtn}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span> 
                              Export 
                          </span>
                        </a>
                        </CSVDownloader>
                      </li>
                      <li>
                        <a
                        onClick={() => {
                          this.setState({ShowPopupForExport:true})
                        }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>Export Contact</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Popup open={this.state.ShowPopupForExport}>
  
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Email</h5>
                          <button className="close"        onClick={() => {
                                      this.setState({ShowPopupForExport:false})
                                    }}>
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="modal-body">
                          <div className="col-sm-6 mb-5 px-0">
                            <div class="row mb-3">
                              <label class="col-lg-5 col-form-label text-left">
                                Email
                              </label>
                              <div class="col-lg-7">
                                <input
                                  class="form-control m-input"
                                  id="Username"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter Email "
                                  name="Username"
                                  onBlur={this.HandleEmailCheck}
                                  defaultValue={this.state.Useremail}
                                  type="text"
                                />
                                    <span style={{ color: "red" }}>
                          {this.state.Errors["Username"]}
                        </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-top modal-content text-right">
                          <div class="row">
                            <div class="col-lg-12 pull-right">
                              <a
                                id="backtolist"
                                class="btn btn-secondary mr-2"
                                onClick={() => {
                                  this.setState({ShowPopupForExport:false})
                                    }}
                              >
                                cancel
                              </a>
                              <button
                                id="submit"
                                onClick={this.ContactExportCsvBulk}
                                class="btn btn-primary btn-lightgreen mr-1"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
            </Popup>
            <div className="whitebg pb-4">
                {/* import csv */}
                <div className="portletbody px-4" id="import_csv">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">
                    Import Contact Details
                    </h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                        onDrop={this.HandleOnDrop}
                        onError={this.HandleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.HandleOnRemoveFile}
                        isReset={this.state.Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add List data from csv file to bulk upload.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={this.MapCsv}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a onClick={this.CancleCsv} className="btn btn-secondary">
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="portletbody px-4 portletshadow m-3" id="map_attribute">
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left">Map attributes</h4>
                </div>
                <div className="row col-xs-12">
                  <div className="form-group m-form__group row mx-0 col-lg-6">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="ListImports">
                            Name
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          id="ListImport"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                        >
                          <option value="not mapped">Not Mapped</option>
                          {this.state.DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
  
                  </div>
                  <div className="col-xs-12 border-top pt-4 my-2 px-4">
                    <a
                      onClick={this.SaveCsv}
                      className="btn btn-primary btn-lightgreen"
                    >
                      <i className="la la-save"></i>Save
                    </a>
                  </div>
                </div>
              </div>
            </div>
              {
                this.state.TotalDisplayCount==true?(
                  <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>
  
                  <div className="col-xs-12">
                  <div class="row colfive">
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                                <div class="contectboxes w-100">
                                    <div class="line-title mb-2"><span class="box-main-title font-primary">{this.state.TotalResult}</span></div>
                                    <div class="box-inner-title"><string class="clr-sky">Total</string></div>
                                </div>
                            </div>
                        </div>
                      </div>
  
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                                <div class="contectboxes w-100">
                                    <div class="line-title mb-2"><span class="box-main-title font-success">{this.state.TotalSuccess}</span></div>
                                    <div class="box-inner-title"><string class="clr-sky">Total success</string></div>
                                </div>
                            </div>
                        </div>
                      </div>
  
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                                <div class="contectboxes w-100">
                                    <div class="line-title mb-2"><span class="box-main-title font-danger">{this.state.TotalError}</span></div>
                                    <div class="box-inner-title"><string class="clr-sky">Total error</string></div>
                                </div>
                            </div>
                        </div>
                      </div>
  
                      <div class="col-md-3">
                        <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                                <div class="contectboxes w-100">
                                    <div class="line-title mb-2"><span class="box-main-title font-warning">{this.state.TotalDuplicate}</span></div>
                                    <div class="box-inner-title"><string class="clr-sky">Total Duplicate</string></div>
                                </div>
                            </div>
                        </div>
                      </div> 
                  </div>
  
                  </div>
                </div>
                ):(<></>)
              }
                {/* End Import csv */}
              <div class="paddcols">
                <div className="row">
                  <div className="col">
                      <ClientsLists />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  }
  

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCListsPage = connect(mapState, actionCreators)(CListsPage);
export { connectedCListsPage as CListsPage };