import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { CSVReader, CSVDownloader } from "react-papaparse";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";

import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";
import ReactApexchart from "react-apexcharts";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable({ FromDateAccount, ToDateAccount }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Notes, SetNotes] = React.useState([]);
  const [ResponderData, SetResponderData] = React.useState([]);
  const [OldClients, SetOldClients] = React.useState(false);
  const [ResponderID, SetResponderID] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [AddArray, SetAddArray] = React.useState([]);
  const [TotalSentDomain, SetTotalSentDomain] = React.useState(0);
  const [TotalReceivedDomain, SetTotalReceivedDomain] = React.useState(0);
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ResponsePer, SetResponsePer] = React.useState(0);
  const [ResponsetoMeetingPer, SetResponsetoMeetingPer] = React.useState(0);
  const [TotalSent, SetTotalSent] = React.useState([]);
  const [TotalResponse, SetTotalResponse] = React.useState([]);
  const [WeekDay, SetWeekDay] = React.useState([]);
  const [TotalMeetingList, SetTotalMeetingList] = React.useState([]);
  const [FromDate,SetFromDate] = React.useState("");
  const [ToDate,SetToDate] = React.useState("")
  const [IsLoading,SetIsLoading] = React.useState(false);


  const classes = useRowStyles();

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetUserIDby(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDate(FromDateAccount)
    SetToDate(ToDateAccount)
    AccountExportHandler(Details.ClientID, FromDateAccount, ToDateAccount,Details.Role);
    KpiAccountGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromDateAccount,
      ToDateAccount
    );
    MeetingKeyMatricGet(Details.ClientID, FromDateAccount, ToDateAccount,Details.Role)
  }, [Search, Page, RowsPerPage, ResponderID, OldClients, FromDateAccount, ToDateAccount]);

  charts(FusionCharts);

  const MeetingKeyMatricGet = (CID, FromDates, ToDates,role) => {
    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      Role:role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiAccountKeyMatric",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.Data.length > 0) {
          var lstTotalSent = [];
          var lstTotalResponse = [];
          var lstTotalMeeting = [];
          var lstWeekDay = [];
          var objtotalmeeting = 0;
          var objtotalSentDomain = 0;
          var objtotalReceivedDomain = 0;
          for (var i = 0; i < Res.data.Data.length; i++) {
            var totdaldomain = Res.data.Data[i].SentDomain;
            objtotalSentDomain = objtotalSentDomain + totdaldomain;

            var totalReceivedDomain = Res.data.Data[i].ReceivedDomain;
            objtotalReceivedDomain = objtotalReceivedDomain + totalReceivedDomain;

            var totalmeeting = Res.data.Data[i].TotalEvents;
            objtotalmeeting = objtotalmeeting + totalmeeting;

            var objWeekDay = Res.data.Data[i].WeekDay;
            lstTotalSent.push(totdaldomain);
            lstTotalResponse.push(totalReceivedDomain)
            lstTotalMeeting.push(totalmeeting)
            lstWeekDay.push(objWeekDay)
          }
          SetTotalSentDomain(objtotalSentDomain)
          SetTotalReceivedDomain(objtotalReceivedDomain)
          SetTotalMeetingList(objtotalmeeting)
          SetTotalSent(lstTotalSent)
          SetTotalResponse(lstTotalResponse)
          SetWeekDay(lstWeekDay)

          if (objtotalSentDomain > 0 && objtotalReceivedDomain > 0) {
            SetResponsePer(Math.round(objtotalReceivedDomain * 100 / objtotalSentDomain))
          }

          if (objtotalReceivedDomain > 0 && objtotalmeeting > 0) {
            SetResponsetoMeetingPer(Math.round(objtotalmeeting * 100 / objtotalReceivedDomain));
        }

        } else {
          SetTotalSentDomain(0)
          SetTotalReceivedDomain(0)
          SetTotalMeeting([])
          SetTotalSent([])
          SetTotalResponse([])
          SetWeekDay([])

        }
      }
      else {
        SetTotalSentDomain(0)
        SetTotalReceivedDomain(0)
        SetTotalMeeting([])
        SetTotalSent([])
        SetTotalResponse([])
        SetWeekDay([])

      }
    });
  };
  const AccountState = {
    series: [
      {
        name: "Sent",
        color: "#302e49",
        data: TotalSent,
      },
      {
        name: "Responses",
        color: "#e9826e",
        data: TotalResponse,
      },
      {
        name: "Meetings",
        color: "#ffb963",
        data: TotalMeetingList,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: WeekDay
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val ;
          },
        },
      },
      legend: {
        position: "right",
      },
    },
  };

  // kpiMeeting get list
  const KpiAccountGet = (CID, UID, URole, FromDates, ToDates) => {
    SetIsLoading(true)

    var KipsMeetingData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToDates,
      FromDate: FromDates,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiAccountListGet",
      method: "POST",
      data: KipsMeetingData,
    });
    rows1.then((result) => {
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetTotalMeeting(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false)

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //Get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDate,
      FromDate: FromDate,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiAccountListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDate,
      FromDate: FromDate,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiAccountListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

      });
    }
  };

  //View Meeting
  const ViewPage = (id) => {
    history.push("/cviewbookedmeetings", id);
  };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val?._id).checked = true;
        } else if (!e.target.checked) {
          document.getElementById(val?._id).checked = false;
        }
      });
    }
  };

  //Export Account
  const AccountExportHandler = (CID, FromDates, ToDates,role) => {
    let InputParameter = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      Role:role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpisMeetingExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res.data.Data);
    });
  };


  //Check box update data
  const CheckBoxClick = (e, row) => {
    if (e.target.checked) {
      var temArray = AddArray;
      temArray.push(row);
      SetAddArray(temArray);
    } else if (!e.target.checked) {
      AddArray.splice(row, 1);
    }
  };

  // Account add to blacklist Domain
  const AccountAddToBlackList = () => {
    if(AddArray.length == 0){
      toast.error("Please select atleast one domain from list.");

    }else{
      var AccountParamData = {
        ClientID: ClientID,
        LastUpdatedBy: UserIDby,
        AccountBlackListArray: AddArray,
        Role: Role,
  
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/AccountToAddToBlackList",
        method: "POST",
        data: AccountParamData,
      }).then((res) => {
        if (
          res.data.StatusMessage == "SUCCESS" &&
          res.data.BlackListlength.length != 0
        ) {
          toast.success("Add BlackList successfully.BlackListed!");
        } else {
          toast.error("Accounts already blacklisted.BlackListed!");
        }
      });
    }
    
  };

  //Export Data 
  const ExportDatas = () =>{
    toast.success(<div class="toastsize">Data exported successfully</div>)
  }
  return (
    <>
     {IsLoading ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></>:<></>}
      <div className="row colfive mx-n4">
        <div className="col-xl-2">
          <div class="cardbox boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalSentDomain}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Sent</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="cardbox boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{ResponsePer} %</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Response %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="cardbox boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalReceivedDomain}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Responses</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="cardbox boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{ResponsetoMeetingPer} %</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Response To Meeting %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="cardbox boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalMeeting}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Meetings</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row cardbox mt-3 pb-3 padtop-50">
        <div class="col-xl-1"></div>
        <div class="col-xl-10">
          <div id="chart chart-max450">
            <ReactApexchart
              options={AccountState.options}
              series={AccountState.series}
              type="bar"
              height={450}
            />
          </div>
        </div>
        <div class="col-xl-1"></div>
      </div>
      <div className="cardbox pb-3 mt-4 px-4 mx-n3">
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left pl-0">Company Details</h4>
          {Rows.length === 0 ? (
            <></>
          ) : (
            <div className="listing-li float-right padb-15">
              <ul>
                <li>
                  <a
                    onClick={AccountAddToBlackList}
                    className="btn btngroup m-btn activebloack"
                  >
                    <i class="la la-icon-font-size-13 la-ban"></i>
                    <span>Blocklist All</span>
                  </a>
                </li>
                <li>
                <CSVDownloader
                      data={ExportData}
                      filename={"CompanyDetail"}
                      bom={true}>
                  <a
                    onClick={() => {
                      ExportDatas();
                    }}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                    
                    
                  </a>
                    </CSVDownloader>
                </li>
              </ul>
            </div>
          )}

        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <input type="checkbox"
                  onChange={(e) => CheckHandler(e)}
                />
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                >
                  Domain
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                >
                  Account Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                >
                  Date Contacted
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                >
                  Date Responded
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                >
                  Emails Sent
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                >
                  Contacts Contacted
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                >
                  Meetings
                </a>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows.map((row) => (
                <>
                  <TableRow key={row._id}>
                    <TableCell align="center">
                      <input
                        id={row._id}
                        type="checkbox"
                        onChange={(e) => CheckBoxClick(e, row)}
                      />
                    </TableCell>
                    <TableCell>{row.Salesreply_Accountdetails?.Domain}</TableCell>
                    <TableCell>{row.AccountName}</TableCell>
                    <TableCell>
                      {" "}
                      {moment(new Date(row.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      ) != "Invalid date"
                        ? moment(
                          new Date(row.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {moment(new Date(row.LastUpdatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      ) != "Invalid date"
                        ? moment(
                          new Date(row.LastUpdatedDate).toDateString()
                        ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>23</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>2</TableCell>
                  </TableRow>
                </>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </>
  );
}