import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import SalesHiveAiPage from "../../_components/user/SalesHiveAi/SalesHiveAI";


class HiveAiPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="adminmain">
            <Sidebar className="" />
          <div className="bodyhome">
          <SalesHiveAiPage />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedHiveAiPage = connect(mapState, actionCreators)(HiveAiPage);
export { connectedHiveAiPage as HiveAiPage };
