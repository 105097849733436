import React,{useEffect} from 'react';
import Axios from "axios";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../../clients/kpis/lastdaysTabing';

import DomainTable from '../../../_components/clients/blacklists/DomainTable';
import ContactTable from '../../../_components/clients/blacklists/ContactTable'; 
import RemovedTable from '../../../_components/clients/blacklists/RemovedTable'; 
import WhitelistTable from '../../../_components/clients/blacklists/WhitelistTable'; 

import Popup from "reactjs-popup";
const Fileupload = require("../../../_helpers/fileupload");
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import loadingicon from "../../../images/loading.gif";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role,setRole] = React.useState('');
  const [UserIDby ,setUserIDby ] = React.useState('');
  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  const [DomainNameAvaiable, SetDomainNameAvaiable] = React.useState(null);
  const [Exportdata, SetExportData] = React.useState([]);
  const [ContactDomainExportData, SetContactDomainExport] = React.useState([]);
  const [RemovedExportData, SetRemovedExportData] = React.useState([]);
  const [WhiteListExportData, SetWhiteListExportData] = React.useState([]);

  const [Reset, SetReset] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [CName, SetCName] = React.useState("");
  const [UpdateKey,SetUpdateKey] = React.useState(0)
  // contact
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalInvalid, SetTotalInvalid] = React.useState(0);

  // remove
  const [RemovedTotal, SetRemovedTotal] = React.useState(0);
  const [RemovedTotalSucess, SetRemovedTotalSucess] = React.useState(0);
  const [RemovedTotalError, SetRemovedTotalError] = React.useState(0);
  const [RemovedTotalDuplicate, SetRemovedTotalDuplicate] = React.useState(0);
  const [RemovedTotalUpdated, SetRemovedTotalUpdated] = React.useState(0);

  // whitelist
  const [TotalWhiteList, SetTotalWhiteList] = React.useState(0);
  const [TotalWhiteListSucess, SetTotalWhiteListSucess] = React.useState(0);
  const [TotalWhiteListError, SetTotalWhiteListError] = React.useState(0);
  const [TotalWhiteListDuplicate, SetTotalWhiteListDuplicate] =
    React.useState(0);

  const [DParentChild, SetDParentChild] = React.useState(false);
  const [CParentChild, SetCParentChild] = React.useState(false);
  const [RParentChild, SetRParentChild] = React.useState(false);
  const [WParentChild, SetWParentChild] = React.useState(false);
  const [Flag, SetFlag] = React.useState(false);
  // Domain tab
  const [IsExist, SetIsExist] = React.useState(false);
  const [ShowPopupDomainImport, SetShowPopupDomainImport] =
    React.useState(false);
  const [IsValid, SetIsValid] = React.useState(false);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [Domain, SetDomain] = React.useState(null);
  const [CreatedDate, SetCreatedDate] = React.useState(null);

  // update parent to chaild
  const [DomainChaild, SetDomainChaild] = React.useState(false);
  const [ContactChaild, SetContactChaild] = React.useState(false);
  const [RemovedChaild, SetRemovedChaild] = React.useState(false);
  const [WhiteListChaild, SetWhiteListChaild] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();

    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetUserEmail(Details.Username);
      setUserIDby(Details.ChildUserID);
      setRole(Details.Role);
    }

    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });

    // For export csv file blacklisted domain
    var str_in = {
      ClientID: Details.ClientID,
      Field: "Domain",
      SortBy: 1,
      Role:Role,
      UserID: Details.ParentUserID


    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/blacklisteddomain/BlackListedExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetExportData(res.data);
    });

    // For export csv file blacklisted contact
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Field: "Domain",
      SortBy: 1,
      Role:Role

    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/blacklistedcontact/ContactDomainExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetContactDomainExport(res.data);
    });

    // For export csv file otp outs
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role:Role

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/OptOutsExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetRemovedExportData(res.data);
    });

    // For export csv file whitelist
    Axios({
      url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetWhiteListExportData(res.data);
    });
  }, [DParentChild, CParentChild, RParentChild, WParentChild, Flag, UserEmail,UpdateKey]);

  //***  Domain Table code started ***///

  // Show and hide div add
  const ShowBlackListDomain = () => {
    document.getElementById("addblacklistview").style.display = "block";
    SetShowPopupDomainImport(false);
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("map_attribute_domain_blacklist").style.display =
      "none";
    document.getElementById("delete_csv").style.display = "none";
    SetReset(true);
  };

  // Show import btn in domain
  const ImportBlackListCsv = () => {
    document.getElementById("import_csv").style.display = "block";
    document.getElementById("addblacklistview").style.display = "none";
    document.getElementById("map_attribute_domain_blacklist").style.display =
      "none";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    setcsvData([]);
    SetReset(true);
  };

  // show bluk delete btn in domain
  const BlackListBulkDelete = () => {
    document.getElementById("delete_csv").style.display = "block";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("addblacklistview").style.display = "none";
    setcsvData([]);
    SetReset(true);
  };

  // show  export csv in domain
  const ExportBlackListCsv = () => {
    toast.success(<div>Domain<br/>Data exported successfully.
    </div>)
  };

  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;

    // this.setState({ files: fileInfo });
    setfiles(fileInfo);

    var sep = filename.split(".");

    if (sep[1] != "csv") {
      // this.setState({ reset: true });
      SetReset(true);
      // this.resetcsv();
      resetcsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];

      // this.setState({ filename: csvfile });
      setfilename(csvfile);

      // this.setState({ csvData: data });
      setcsvData(data);
      // this.setState({ DropboxData: data[0].data });
      SetDropboxData(data[0].data);
    }
  };

  const HandleOnError = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFile = (data) => {};

  //Domain maping method
  const BlackListMapCsv = () => {
    
    resetcsv();
    if (csvData.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      document.getElementById("Domain").disabled = false;
      document.getElementById("map_attribute").style.cursor = "default";
      document.getElementById("ImportDomainAddBtn").disabled = false;
      document.getElementById("ImportDomainAddBtn").style.cursor = "default";

      resetcsv();
      DropboxData.map((dddd, index) => {});
    } else {
      resetcsv();
      setcsvData([]);
      document.getElementById("map_attribute").style.display = "none";
    }
    hideCSVHeaderOptions();
  };

  // Csv cancel method for domain
  const CancleBlackListBtn = () => {
    resetcsv();
    setcsvData([]);
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("map_attribute_domain_blacklist").style.display = "none";
  };

  // Domain  csv save method
  const OpenDomainPopupBtn = () => {
    let Domain = document.getElementById("Domain").value;
    if (Domain != "Not Mapped") {
      SetShowPopupDomainImport(true);
    } else {
      toast.error(
        <div className="toastsize">
          Domain
          <br />
          Domain not mapped!
        </div>
      );
    }
  };

  //Domain delete csv uploader method
  const DeleteBlackListBtn = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attribute_domain_blacklist").style.display ="block";

      DropboxData.map((dddd, index) => {});
    } else {
      setcsvData([]);
       document.getElementById("map_attribute_domain_blacklist").style.display ="none";
    }
  };

  // Bulk delete blacklist csv
  const DeleteBulkBlackListCsv = () => {
    SetIsLoading(true);
    var Domain = document.getElementById("Domain1").value;
    if (Domain != "Not Mapped") {
      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var BulkDeleteBlackListData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserBlacklistDomain",
          File: _base64,
          ImageName: filename,
          FileData: CsvData,
          Domain: Domain,
          Role:Role,
          ClientID: ClientID,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/blacklisteddomain/BlackListBulkDelete",
          method: "POST",
          data: BulkDeleteBlackListData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success("Domain deleted successfully");
            SetUpdateKey(UpdateKey+1)
            document.getElementById("map_attribute_domain_blacklist").style.display = "none";
            document.getElementById("delete_csv").style.display = "none";
      setcsvData([]);
    SetIsLoading(false);

            
          }else{
          SetIsLoading(false);
          toast.error(res.data.Message)

          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Domain
          <br />
          Domain not mapped!
        </div>
      );
    SetIsLoading(false);

    }
  };

  //Domain add validation
  const DomainOnBlurValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var Domain = document.getElementById("DomainFor").value.trim();
    let IsExist = await BlacklistDomainNameCheckExist(Domain, false);
    let FLAGISVALID = await DomainCheck(Domain);

    if (Domain == "" && Domain.indexOf(" ") != "") {
      formIsValid = false;
      errors["DomainFor"] = "Please enter Domain";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && Domain.length > 0) {
      formIsValid = false;
      errors["DomainFor"] = "Invalid domain";
    }
    SetErrors(errors);
    return formIsValid;
  };

  // formvalidation for domain tab
  const FormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var Domain = document.getElementById("DomainFor").value.trim();
    let IsExist = await BlacklistDomainNameCheckExist(Domain);
    let FLAGISVALID = await DomainCheck(Domain);

    if (Domain == "" && Domain.indexOf(" ") != "") {
      formIsValid = false;
      errors["DomainFor"] = "Please enter Domain";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && Domain.length > 0) {
      formIsValid = false;
      errors["DomainFor"] = "Invalid domain";
    }
    SetErrors(errors);
    return formIsValid;
  };

  //Domain Check for domain
  const DomainCheck = async (Domain) => {
    var Domain = document.getElementById("DomainFor").value.trim();
    let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;

    if (!regexp.test(Domain)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for domain tab
  const BlacklistDomainNameCheckExist = async (Domain, defaults = true) => {
    var Domain = document.getElementById("DomainFor").value.trim();
    var str_in = {
      ClientID: ClientID,
      Domain: Domain,
      Role:Role

    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/blacklisteddomain/BlackListDomainCheckExits",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Domain <br />
              {Domain} already exists
            </div>
          );
        }
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  // blacklist domain save for domain
  const BlackListDomainAdd = async (e) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await FormValidation();
    var Domain = document.getElementById("DomainFor").value.trim();
    if (Final_flag == true) {
      var BlckListDomainData = {
        ClientID: ClientID,
        Domain: Domain,
        IsDeleted: false,
        CreatedDate: new Date(),
        CreatedBy: UserIDby,
        UserID: UserID,
        Role:Role

      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/blacklisteddomain/BlacklistedDomainAdd",
        method: "POST",
        data: BlckListDomainData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetDomainChaild(true);
          SetUpdateKey(UpdateKey+1)
          toast.success(
            <div className="toastsize">
              Domain
              <br />
              Saleshive blacklisted domain will be added soon..
            </div>
          );
          SetDomainChaild(false);
          document.getElementById("DomainFor").value = "";
          document.getElementById("Dsubmit").disabled = false;
          setcsvData([]);
          DomaintabFor();
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("Dsubmit").disabled = false;
    }
  };

  // import black list csv
  const DomainUploadCsv = () => {
    var Email = document.getElementById("Username").value;
    var filetype = filename.split(".");
    var file_Name;

    var file_Name;
    if (/\s/g.test(filetype[0])) {
      file_Name =
        new Date().getTime() + "." + filetype[1] + "";
    }

    var OriginalFileName = files.name;

    var saveAllContacts = [];
    var Domain = parseInt(document.getElementById("Domain").value);
    if (Domain >= 0) {
      var ObjDomain = {
        FieldAlias: "Domain",
        Fieldname: "Domain",
        Customfield: null,
        index: Domain,
      };
      saveAllContacts.push(ObjDomain);
    }
    var csvdata = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var DomainImportData = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserBlacklistDomain",
        File: _base64,
        ImageName: filename,
        Email: Email,
        MappingFiled: JSON.stringify(saveAllContacts),
        FileData: csvdata,
        ClientID: ClientID,
        OrignalFileName: OriginalFileName,
        UserID: UserID,
        IsCompleted: false,
        InProgress: false,
        CreatedDate: new Date(),
        CreatedBy: UserIDby ,
        LastUpdatedDate: null,
        LastUpdatedBy: null,
        FileName: file_Name,
      Role:Role

      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/blacklisteddomain/BlackListDomainUpload",
        method: "POST",
        data: DomainImportData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>
            Domain <br/>
            You will get file soon on Submitted Email.
          </div>);
          SetUpdateKey(UpdateKey+1)
          // history.push("/blacklists");
          SetReset(!Reset);
          SetShowPopupDomainImport(false);
          document.getElementById("Domain").disabled = true;
          document.getElementById("map_attribute").style.cursor = "not-allowed";
          document.getElementById("ImportDomainAddBtn").disabled = true;
          document.getElementById("ImportDomainAddBtn").style.cursor ="not-allowed";
          setcsvData([]);

        } else {
          toast.error(res.data.Message);
        }
      });
    });
  };

  // hid div add
  const DomaintabFor = () => {
    SetErrors({});
    document.getElementById("DomainFor").value = "";
    document.getElementById("addblacklistview").style.display = "none";
  };

  //** Contact code start from Here **/

  const ContactDomainImportBtn = () => {
    document.getElementById("import_csv_contact").style.display = "block";
    document.getElementById("contactdelete_csv").style.display = "none";
    document.getElementById("addblacklistcontacview").style.display = "none";
    document.getElementById("map_attribute_contact").style.display = "none";
    document.getElementById("map_attributecontact").style.display = "none";
    document.getElementById("Show_csv_details_contact").style.cursor =
      "default";
    document.getElementById("ContactEmail").disabled = false;
    document.getElementById("CSVSaveBtn").disabled = false;
    document.getElementById("ContactEmail").style.display = "default";
    document.getElementById("CSVSaveBtn").style.display = "default";
    document.getElementById("Show_csv_details_contact").style.display = "none";
    setcsvData([]);
    resetcsv();
  };

  // contact domain export
  const ContactDomainExport = () => {
    toast.success(<div>Contact<br/>Data exported successfully.
    </div>)
  };

  const ContactDomainBulkDleteBtn = () => {
    document.getElementById("contactdelete_csv").style.display = "block";
    document.getElementById("import_csv_contact").style.display = "none";
    document.getElementById("addblacklistcontacview").style.display = "none";
    document.getElementById("map_attribute_contact").style.display = "none";
    document.getElementById("Show_csv_details_contact").style.display ="none";
    resetcsv();
    setcsvData([])
  };

  const ContactDomainAddBtn = () => {
    document.getElementById("addblacklistcontacview").style.display = "block";
    document.getElementById("contactdelete_csv").style.display = "none";
    document.getElementById("import_csv_contact").style.display = "none";
    document.getElementById("map_attribute_contact").style.display = "none";
    document.getElementById("map_attributecontact").style.display = "none";
    setcsvData([])
    resetcsv();
  };
  // for contact import csv
  const ContactDomainMapCsvBtn = () => {
    
    if (csvData.length != 0) {
      document.getElementById("ContactEmail").value = "not mapped";
      document.getElementById("map_attribute_contact").style.display = "block";
      document.getElementById("Show_csv_details_contact").style.cursor =
        "default";
      document.getElementById("ContactEmail").disabled = false;
      document.getElementById("CSVSaveBtn").disabled = false;
      document.getElementById("Show_csv_details_contact").style.display =
        "none";
      document.getElementById("hideloding").style.display = "none";
      resetcsv();
      DropboxData.map((dddd, index) => {});
      resetcsv();
    } else {
      document.getElementById("map_attribute_contact").style.display = "none";
      document.getElementById("Show_csv_details_contact").style.display =
        "none";
    }
  };
  const ContactDomainCancleBtn = () => {
    resetcsv();
    document.getElementById("map_attribute_contact").style.display = "none";
    document.getElementById("import_csv_contact").style.display = "none";
    document.getElementById("Show_csv_details_contact").style.display = "none";
  };

  // for contact import csv
  const ContactDomainSaveBtn = () => {
    SetIsLoading(true)

    var ContactEmail = document.getElementById("ContactEmail").value;
    if (ContactEmail != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ContactCsvData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserBlacklistContact",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ContactEmail: ContactEmail,
          ClientID: ClientID,
      Role:Role,
      CreatedDate:new Date()

        };
        
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/blacklistedcontact/ContactDomainImport",
          method: "POST",
          data: ContactCsvData,
        }).then((res) => {
          
          
          
          
          if (res.data.StatusMessage == "SUCCESS") {
            SetUpdateKey(UpdateKey+1)
            document.getElementById("map_attribute_contact").style.cursor =
              "no-drop";
            SetTotal(res.data.Total);
            SetTotalSucess(res.data.TotalsSuccess);
            SetTotalError(res.data.TotalError);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetTotalInvalid(res.data.TotalInvalid);
            document.getElementById("Show_csv_details_contact").style.cursor =
              "no-drop";
            document.getElementById("ContactEmail").disabled = true;
            document.getElementById("CSVSaveBtn").disabled = true;
            document.getElementById("ContactEmail").style.display = "no-drop";
            document.getElementById("CSVSaveBtn").style.display = "no-drop";
            document.getElementById("Show_csv_details_contact").style.display =
              "block";
              SetIsLoading(false)
            setcsvData([]);
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Contact <br /> Contact not mapped!
        </div>
      );
      SetIsLoading(false)
    }
  };

  // for contact tab
  const ContactDomainBulkDeleteBtn = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attributecontact").style.display = "block";

      DropboxData.map((dddd, index) => {});
    } else {
      document.getElementById("map_attributecontact").style.display = "none";
      setcsvData([])
    }
  };

  const ContactDomainBulkCancleBtn = () => {
    resetcsv();
    document.getElementById("map_attributecontact").style.display = "none";
    document.getElementById("contactdelete_csv").style.display = "none";
  };

  // for contact tab
  const ContactDomainBulkDeleteSaveBtn = () => {
    
    var ContactEmail = document.getElementById("ContactEmail1").value;
    if(ContactEmail != "not mapped"){
      SetIsLoading(true)
    var csvdata = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var ContactBulkDeleteData = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserBlacklistContact",
        File: _base64,
        ImageName: filename,
        FileData: csvdata,
        ContactEmail: ContactEmail,
      Role:Role

      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +"/blacklistedcontact/ContactDomainBulkDelete",
          method: "POST",
          data: ContactBulkDeleteData,
      }).then((res) => {
        
        if(res.data.StatusMessage == "SUCCESS"){
          resetcsv();
          document.getElementById("map_attributecontact").style.display = "none";
          document.getElementById("contactdelete_csv").style.display = "none";
          SetUpdateKey(UpdateKey+1)
          toast.success(<div>Contact<br/>Contact deleted successfully.</div>)
             SetIsLoading(false)
             document.getElementById("map_attributecontact").style.display = "none";
        }else{
          toast.error(res.data.msg)
             SetIsLoading(false)
        }
       
      });
    });
  }else{toast.error(<div>Contact<br/>
  Contact not mapped! 
  </div>)}
   
  };

  const ContactTabOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    let IsExist = await ContactEmailCheckExist(ContactEmail, false);
    let FLAGISVALID = await ContactTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Please enter email";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };
  // formvalidation for domain tab
  const ContactTabFormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    let IsExist = await ContactEmailCheckExist(ContactEmail);
    // let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    let FLAGISVALID = await ContactTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Please enter email";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };

  //Email Check for domain
  const ContactTabEmailCheck = async (ContactEmail) => {
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    var regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regexp.test(ContactEmail)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for contact email tab
  const ContactEmailCheckExist = async (ContactEmail, defaults = true) => {
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      ContactEmail: ContactEmail,
      Role:Role

    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/blacklistedcontact/ContactEmailCheckExits",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Contact <br /> {ContactEmail} already exists.
            </div>
          );
        }
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  //  contact email add
  const ContactTabEmailBtn = async (e) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await ContactTabFormValidation();
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    if (Final_flag == true) {
      var ContactDomainData = {
        ClientID: ClientID,
        ContactEmail: ContactEmail,
        IsDeleted: false,
        CreatedDate: new Date(),
        CreatedBy: UserIDby,
        UserID: UserID,
      Role:Role

      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/blacklistedcontact/ContactDomainAdd",
        method: "POST",
        data: ContactDomainData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetContactChaild(true);

          toast.success(
            <div>
              Contact
              <br />
              Contact added successfully.
            </div>
          );
          SetContactChaild(false);
          document.getElementById("ContactEmailAdd").value = "";
          document.getElementById("Csubmit").disabled = false;
          HideContactEmail();
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("Csubmit").disabled = false;
    }
  };

  const HideContactEmail = () => {
    SetErrors({});
    document.getElementById("ContactEmailAdd").value = "";
    document.getElementById("addblacklistcontacview").style.display = "none";
  };



  //** Removed Tab code start's from here *///

  // for removed import map
  const RemovedImportBtn = () => {
    document.getElementById("import_csv_Removed").style.display = "block";
    document.getElementById("delete_csv_removed").style.display = "none";
    document.getElementById("removedview").style.display = "none";
    document.getElementById("map_attribute_removed").style.display = "none";
    document.getElementById("map_attribute_removed").style.cursor = "default";
    document.getElementById("Name").style.cursor = "default";
    document.getElementById("RemovedcsvImport").disabled = false;
    document.getElementById("Name").disabled = false;
    document.getElementById("Show_csv_details_removed").style.display ="none";
    setcsvData([]);
    resetcsv();
  };

  const removedExportBtn = () => {
    toast.success(<div>Removed<br/>Data exported successfully.
    </div>)
  };

  const RemovedDeleteCsvBtn = () => {
    document.getElementById("delete_csv_removed").style.display = "block";
    document.getElementById("import_csv_Removed").style.display = "none";
    document.getElementById("removedview").style.display = "none";
    document.getElementById("map_attribute_removed1").style.display = "none";
    document.getElementById("map_attribute_removed1").style.display = "none";
    document.getElementById("map_attribute_removed1").style.cursor = "default";
    document.getElementById("map_attribute_removed").style.display = "none";
    document.getElementById("Show_csv_details_removed").style.display ="none";
    setcsvData([]);
    resetcsv();
  };

  // Show and hide div add
  const RemovedAddBtn = () => {
    document.getElementById("removedview").style.display = "block";
    document.getElementById("import_csv_Removed").style.display = "none";
    document.getElementById("delete_csv_removed").style.display = "none";
    document.getElementById("map_attribute_removed1").style.display = "none";
    document.getElementById("map_attribute_removed").style.display = "none";
  };

  // for removed import map
  const RemovedImportMapCavBtn = () => {
    if (csvData.length != 0) {
       document.getElementById("Show_csv_details_removed").style.display ="none";
      document.getElementById("map_attribute_removed1").style.display = "block";
      DropboxData.map((dddd, index) => {});
      resetcsv();
    } else {
      document.getElementById("Show_csv_details_removed").style.display ="none";
      document.getElementById("map_attribute_removed1").style.display = "none";
      setcsvData([])
    }
  };

  // for removed cancel csv
  const RemovedCancleCsvBtn = () => {
    resetcsv();
    document.getElementById("map_attribute_removed1").style.display = "none";
    document.getElementById("import_csv_Removed").style.display = "none";
    document.getElementById("Show_csv_details_removed").style.display ="none";
            document.getElementById("map_attribute_removed1").style.cursor = "default";
            document.getElementById("Name").style.cursor = "default";
            document.getElementById("RemovedcsvImport").disabled = false;
            document.getElementById("Name").disabled = false;
  };

  // for import csv removed
  const RemovedSaveCsvBtn = () => {
    SetIsLoading(true);
    var Name = document.getElementById("Name").value;
    if(Name != "not mapped"){
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
  
        var RemovedImportData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/Userremoved",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ClientID: ClientID,
          Name: Name,
      Role:Role

        };
        
        Axios({
          url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/OptoutsImport",
          method: "POST",
          data: RemovedImportData,
        }).then((res) => {
          
          if (res.data.StatusMessage == "SUCCESS") {
            SetRemovedTotal(res.data.Total);
            SetRemovedTotalSucess(res.data.TotalsSuccess);
            SetRemovedTotalError(res.data.TotalError);
            SetRemovedTotalDuplicate(res.data.TotalDuplication);
            SetTotalInvalid(res.data.TotalInvalid);
            document.getElementById("Show_csv_details_removed").style.display ="block";
            document.getElementById("map_attribute_removed1").style.cursor = "not-allowed";
            document.getElementById("Name").style.cursor = "not-allowed";
            document.getElementById("RemovedcsvImport").disabled = true;
            document.getElementById("Name").disabled = true;
            setcsvData([])
            SetIsLoading(false);
          }else{
          SetIsLoading(false);
          }
        });
      });
    }else{
      toast.error(<div className="toastsize">Opt outs <br/>
        Opt outs not mapped!</div>)
        SetIsLoading(false);
    }
   
  };

  // for otpous
  const RemovedBulkDeleteMapCsv = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attribute_removed").style.display = "block";
      DropboxData.map((dddd, index) => {});
    } else {
      document.getElementById("map_attribute_removed").style.display = "none";
      setcsvData([])
    }
  };

  // for removed
  const RemovedBulkDeleteCancelCsv = () => {
    resetcsv();
    document.getElementById("map_attribute_removed").style.display = "none";
    document.getElementById("delete_csv_removed").style.display = "none";
    setcsvData([])
  };

  // for removed bulk delete
  const RemovedBulkDeleteCsvSaveBtn = () => {
    SetIsLoading(true)
    var Name = document.getElementById("Name1").value;
    if(Name != "not mapped"){
      var csvdata = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var myresult = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/Userremoved",
        File: _base64,
        ImageName: filename,
        FileData: csvdata,
        Name: Name,
      Role:Role

      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/OptOutsBulkDelete",
        method: "POST",
        data: myresult,
      }).then((res) => {
        if(res.data.StatusMessage == "SUCCESS"){
          SetIsLoading(false)
          setcsvData([])
        toast.success(<div className="toastsize">Removed <br/>Removed deleted successfully.</div>)
        document.getElementById("map_attribute_removed").style.display = "none";
        document.getElementById("delete_csv_removed").style.display = "none";
        }else{
          toast.error(res.data.message)
          SetIsLoading(false)
        }
      });
    });
    }else{
      toast.error(<div className="toastsize">Opt outs<br/>Opt outs not mapped! </div>)
      SetIsLoading(false)

    }
    
  };

  const RemovedOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    let IsExist = await RemovedEmailCheckExist(RemovedEmail, false);
    let FLAGISVALID = await RemovedTabEmailCheck(RemovedEmail);

    if (RemovedEmail == "" && RemovedEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Please enter email";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && RemovedEmail != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };
  // formvalidation for removed tab
  const RemovedTabFormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    let IsExist = await RemovedEmailCheckExist(RemovedEmail);
    let FLAGISVALID = await RemovedTabEmailCheck(RemovedEmail);

    if (RemovedEmail == "" && RemovedEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Please enter email";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && RemovedEmail != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };

  // cheack exists for  removed email tab
  const RemovedEmailCheckExist = async (RemovedEmail, defaults = true) => {
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      Name: RemovedEmail,
      Role:Role

    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/OptoutsExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Removed
              <br /> {RemovedEmail} already exists.
            </div>
          );
        }
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  //Email Check for removed tab
  const RemovedTabEmailCheck = async (RemovedEmail) => {
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    var regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regexp.test(RemovedEmail)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };
  // remove tab
  const RemovedEmailSaveBtn = async (e) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await RemovedTabFormValidation();
    var Name = document.getElementById("RemovedEmail").value.trim();
    if (Final_flag == true) {
      var data = {
        ClientID: ClientID,
        Name: Name,
        CreatedDate: new Date(),
        CreatedBy: UserIDby,
        UserID: UserID,
      Role:Role

      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/OptOutsAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetRemovedChaild(true);
          toast.success("Added Removed Successfully");
          document.getElementById("RemovedEmail").value = "";
          document.getElementById("Rsubmit").disabled = false;
          RemovedHideBtn();
          SetRemovedChaild(false);
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("Rsubmit").disabled = false;
    }
  };

  const RemovedHideBtn = () => {
    SetErrors({});
    document.getElementById("RemovedEmail").value = "";
    document.getElementById("removedview").style.display = "none";
  };





  
  //*** Whitelist tab code starts from here *///

  const WhiteListImportBtn = () => {
    document.getElementById("import_csv_whitelist").style.display = "block";
    document.getElementById("Show_csv_details_whitelist").style.display ="none";
    document.getElementById("delete_csv_whitelist").style.display ="none"; 
    document.getElementById("map_attribute_whitelist").style.cursor ="default";
    document.getElementById("map_attribute_whitelist").style.display ="none";
    document.getElementById("whitelistcontacview").style.display = "none";
    document.getElementById("WhiteCSVImport").disabled =false;
            document.getElementById("Whitelist").disabled =false;
            setcsvData([]);
            resetcsv();
  };

  const WhiteListExportCsvBtn = () => {
    toast.success(<div>Whitelist<br/>Data exported successfully.
    </div>)
  };

  // for whitelist delete
  const WhiteListDeleteBtn = () => {
    
    document.getElementById("delete_csv_whitelist").style.display = "block";
    document.getElementById("import_csv_whitelist").style.display = "none";
    document.getElementById("Show_csv_details_whitelist").style.display ="none";
    document.getElementById("whitelistcontacview").style.display = "none";

  };

  const WhiteListShowBtn = () => {
    document.getElementById("whitelistcontacview").style.display = "block";
    document.getElementById("delete_csv_whitelist").style.display = "none";
    document.getElementById("import_csv_whitelist").style.display = "none";

  };

  // for white list import csv
  const WhiteListImportMapCsv = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attribute_whitelist").style.display ="block";
      document.getElementById("Show_csv_details_whitelist").style.display ="none";
      document.getElementById("map_attribute_whitelist").style.cursor ="default";
    document.getElementById("WhiteCSVImport").disabled =false;
            document.getElementById("Whitelist").disabled =false;
      DropboxData.map((dddd, index) => {});
      resetcsv();
    } else {
      document.getElementById("map_attribute_whitelist").style.display ="none";
      document.getElementById("Show_csv_details_whitelist").style.display ="none";
      
      resetcsv();
    }
  };

  // for whitelist csv
  const WhiteListCancelCsv = () => {
    resetcsv();
    document.getElementById("map_attribute_whitelist").style.display = "none";
    document.getElementById("import_csv_whitelist").style.display = "none";
    document.getElementById("Show_csv_details_whitelist").style.display ="none";

  };

  // for whitelist csv
  const WhiteListSaveCsvBtn = () => {
    SetIsLoading(true)
    var Whitelist = document.getElementById("Whitelist").value;
    if(Whitelist != "not mapped"){
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
  
        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserWhitelistDomain",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ContactEmail: Whitelist,
          ClientID: ClientID,
          UserID:UserID,
          CreatedBy:UserIDby,
          CreatedDate:new Date(),
          Role:Role

        };
  
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/whitelistedcontact/WhiteListedImportFile",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetTotalWhiteList(res.data.Total);
            SetTotalWhiteListSucess(res.data.TotalsSuccess);
            SetTotalWhiteListError(res.data.TotalError);
            SetTotalWhiteListDuplicate(res.data.TotalDuplication);
            SetTotalInvalid(res.data.TotalInvalid)
            document.getElementById("Show_csv_details_whitelist").style.display ="block";
            document.getElementById("map_attribute_whitelist").style.cursor ="not-allowed";
            document.getElementById("map_attribute_whitelist").style.cursor ="not-allowed";
            document.getElementById("WhiteCSVImport").disabled =true;
            document.getElementById("Whitelist").disabled =true;
            setcsvData([]);
           SetIsLoading(false)
          }else{
            toast.error(res.data.message)
      SetIsLoading(false)

          }
        });
      });
    }else{
      toast.error(<div className="toastsize">Whitelist <br/> Whitelist not mapped!</div>)
      SetIsLoading(false)

    }
   
  };
  // for whitelist tav
  const WhiteListBDeleteMapCsv = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attribute_whitelistdelete").style.display ="block";
      resetcsv();
      DropboxData.map((dddd, index) => {});
    } else {
      document.getElementById("map_attribute_whitelistdelete").style.display ="none";
      resetcsv();
    }
  };

  // for whitelist cancel csv
  const WhiteListBDeleteCancelCsv = () => {
    resetcsv();
    document.getElementById("map_attribute_whitelist").style.display = "none";
    document.getElementById("delete_csv_whitelist").style.display = "none";
    setcsvData([])
  };

  // for whitelist contact csv
  const WhiteListBulkDeleteBtn = () => {
    SetIsLoading(false);
    var ContactEmail = document.getElementById("WhitelistName1").value;
    if(ContactEmail != "not mapped"){
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
  
        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserWhitelistDomain",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ContactEmail: ContactEmail,
          Role:Role,
          UserID:UserID,
          CreatedBy:UserIDby,
          CreatedDate:new Date(),
          Role:Role

        };
  
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/whitelistedcontact/WhitelistBluckDelete",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if(res.data.StatusMessage == "SUCCESS"){
            SetIsLoading(false);
            toast.success(<div className="toastsize">Whitelist <br/> Whitelist deleted successfully.</div>)
          }else{
            toast.error(res.data.Message)
            SetIsLoading(false);
          }
             

        });
      });
    }else{
      toast.error(<div className="toastsize">Whitelist <br/> Whitelist not mapped!</div>)
      SetIsLoading(false);
    }
   
  };

  //Email Check for domain
  const WhiteTabEmailCheck = async (ContactEmail) => {
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    var regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regexp.test(ContactEmail)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for contact email tab
  const WhiteEmailCheckExist = async (ContactEmail, defaults = true) => {
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      ContactEmail: ContactEmail,
      Role:Role

    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/whitelistedcontact/WhiteListedCheackExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Whitelist <br /> {ContactEmail} already exists
            </div>
          );
        }
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  const WhitelistEmailOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    let IsExist = await WhiteEmailCheckExist(ContactEmail, false);
    let FLAGISVALID = await WhiteTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Please enter email";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };

  // formvalidation for white tab
  const WhiteTabFormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    let IsExist = await WhiteEmailCheckExist(ContactEmail);
    let FLAGISVALID = await WhiteTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Please enter email";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };

  // Add Whitelist
  const WhiteListAddBtn = async (e) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await WhiteTabFormValidation();
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    if (Final_flag == true) {
      var WhiteListAddData = {
        ClientID: ClientID,
        ContactEmail: ContactEmail,
        CreatedDate: new Date(),
        CreatedBy: UserIDby,
        UserID: UserID,
        LastUpdatedBy: UserIDby,
      Role:Role

      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedAdd",
        method: "POST",
        data: WhiteListAddData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetWhiteListChaild(true);
          toast.success(
            <div className="toastsize">
              WhiteList
              <br />
              WhiteList added successfully.
            </div>
          );
          document.getElementById("ContactEmailWhitelist").value = "";
          document.getElementById("Wsubmit").disabled = false;
          SetWhiteListChaild(false);
          CloseBtnForWhieBtn();
          // history.push("/blacklists");
          // document.getElementById("whitelistcontacview").style.display = "none";
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("Wsubmit").disabled = false;
    }
  };

  const CloseBtnForWhieBtn = () => {
    SetErrors({});
    document.getElementById("ContactEmailWhitelist").value = "";
    document.getElementById("whitelistcontacview").style.display = "none";
  };

  //** other helper methods **//
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const FromValidation = () => {
    let formIsValid = true;
    if (Object.entries(Fields).length > 0) {
      let Fields = Fields;
      let Errors = {};

      if (!Fields["Domain"]) {
        formIsValid = false;
        Errors["Domain"] = "Please enter Domain";
      }
      if (DomainNameAvaiable != null) {
        formIsValid = false;
      }
      // this.setState({ Errors: Errors });
      SetErrors(Errors);
    }
    return formIsValid;
  };
  const UpdateFromChild = (value, page) => {
    if (value == true) {
      if (page === "Domain") {
        SetDParentChild(true);
      } else if (page === "Contact") {
        SetCParentChild(true);
      } else if (page === "Removed") {
        SetRParentChild(true);
      } else {
        SetWParentChild(true);
      }
    }
  };

  // handle change blacklist domain
  const HandleChangeDomain = (field) => {
    var Domain = document.getElementById("DomainFor").value;
    document.getElementById("Domain").style.display = "none";
    SetFields(Domain);
    if (Fields.Domain != "") {
      Errors.Domain = null;
      var Domain = document.getElementById("Domain").value;

      BlacklistDomainNameCheckExist(Domain);
    } else {
      DomainNameAvaiable = null;
      Errors.Domain = null;
    }
  };

  // For Chekexits name
  const HandleInputContactChange = () => {
    var ContactEmail = document.getElementById("ContactEmail").value;
    document.getElementById("validioncontactdomain").style.display = "none";

    SetFields(ContactEmail);
    if (Fields.ContactEmail != "") {
      Errors.ContactEmail = null;
    } else {
      DomainNameAvaiable = null;
      Errors.ContactEmail = null;
    }
  };

  // for white listed contact
  const HandleInputWhiteListChange = () => {
    var ContactEmailWhitelist = document.getElementById(
      "ContactEmailWhitelist"
    ).value;

    SetFields(ContactEmailWhitelist);
    if (Fields.ContactEmailWhitelist != "") {
      Errors.ContactEmailWhitelist = null;
      // DomainNameAvaiable = null;
      // var Domain = document.getElementById("ContactEmail").value;

      // BlackListDomainCheckExits(Domain)
    } else {
      // DomainNameAvaiable = null;
      // Errors.ContactEmail = null;
    }
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });

  // for white listed
  const hidewhitecontactdomain = () => {
    document.getElementById("whitelistcontacview").style.display = "none";
  };

  // Import csv file

  const resetcsv = () => {
    SetReset(!Reset);
  };

  // for contact tab

  // csv hide header
  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };
  // csv default select
  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "Domain") {
      SetDomain(value);
    } else if (Name == "CreatedDate") {
      SetCreatedDate(value);
    }

    hideCSVHeaderOptions();
  });

  // for remove tab
  const HandleChangeRemoved = (field) => {
    var Name = document.getElementById("Name").value;
    document.getElementById("validotpput").style.display = "none";

    SetFields(Name);
    if (Fields.Name != "") {
      Errors.Name = null;
    } else {
    }
  };

  return (
    <div className="tabdes">
      { IsLoading == true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>: <></>}
      
      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Domain" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Contact" {...a11yProps(1)} />
            <Tab label="Removed" {...a11yProps(2)} />
            <Tab label="Whitelist" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg inpt-0 px-3" index={0}>
          <Popup open={ShowPopupDomainImport}>
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup largerPopup">
                <div className="paddingboxTerms">
                  <div className="modal-header py-4 px-3">
                    <div className="w-100 d-flex px-3">
                      <h5 className="mb-0">Email</h5>
                      <button
                        className="close"
                        onClick={() => {
                          SetShowPopupDomainImport(false);
                        }}
                      >
                        <span aria-hidden="true" class="la la-remove"></span>
                      </button>
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="modal-body">
                      <div className="col-sm-6 mb-5 px-0">
                        <div class="row mb-3">
                          <label class="col-lg-5 col-form-label text-left">
                            Email
                          </label>
                          <div class="col-lg-7">
                            <input
                              class="form-control m-input"
                              id="Username"
                              data-val="true"
                              data-val-remote="Title already exist."
                              data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                              data-val-required="Please enter Email "
                              name="Username"
                              defaultValue={UserEmail}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-top modal-content text-right">
                      <div class="row">
                        <div class="col-lg-12 pull-right">
                          <a
                            id="backtolist"
                            class="btn btn-secondary mr-2"
                            onClick={() => {
                              SetShowPopupDomainImport(false);
                            }}
                          >
                            cancel
                          </a>
                          <button
                            id="submit"
                            onClick={() => DomainUploadCsv()}
                            class="btn btn-primary btn-lightgreen mr-1"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
          <div className="row">
            <div className="col border-bottom pl-0">
              <h4 className="headertitle float-left pl-0">Domain</h4>
            </div>
            <div className="col border-bottom pr-0">
              <div className="listing-li float-right padb-15">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        ShowBlackListDomain();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ImportBlackListCsv}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li class="m-portlet__nav-item">
                    <a
                      onClick={BlackListBulkDelete}
                      class="btn btngroup m-btn"
                    >
                      <span>
                        <i class="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                  <CSVDownloader className="px-0"
                          data={Exportdata}
                          filename={CName + "- SalesReply-Blacklisted-Domain"}
                          bom={true}
                        >
                    <a
                      onClick={ExportBlackListCsv}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                      <span>
                        {" "} Export 
                      </span>
                    </a>
                    </CSVDownloader>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for Black list*/}
          <div
            className="portletbody"
            id="import_csv"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">Import Blacklist Domain</h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add domain data from csv file to import.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={BlackListMapCsv}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a onClick={CancleBlackListBtn} className="btn btn-secondary">
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attribute"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row" id="divContactSelection">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      id="dbColumn1"
                      disabled="disabled"
                    >
                      <option selected="" value="Domain">
                        Domain
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="csvcontent form-control  m-input"
                      id="Domain"
                      autocomplete="off"
                      value={
                        Domain == null ? DropboxData.indexOf("Domain") : Domain
                      }
                    >
                      <option>Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <button
                  id="ImportDomainAddBtn"
                  onClick={() => {
                    OpenDomainPopupBtn();
                  }}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </button>
              </div>
            </div>
          </div>

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          <div
            className="portletbody"
            id="delete_csv"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">
                  Bulk deleted Blacklist Domain
                </h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add domain data from csv file to bulk delete.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={DeleteBlackListBtn}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a onClick={CancleBlackListBtn} className="btn btn-secondary">
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attribute_domain_blacklist"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      <option selected="" value="Domain">
                        Domain
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      id="Domain1"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                    >
                      <option>Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <a
                  onClick={() => {
                    DeleteBulkBlackListCsv();
                  }}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </a>
              </div>
            </div>
          </div>

          <div
            className="addkeyword row my-4"
            id="addblacklistview"
            style={{ display: "none" }}
          >
            <div className="col-sm-12 col-md-6 row">
              <label className="col-lg-3 view-lable-meet">Domain</label>
              <div className="col-lg-9">
                <input
                  className="form-control m-input"
                  data-val="true"
                  data-val-required="Please enter Domain name"
                  id="DomainFor"
                  onBlur={(e) => DomainOnBlurValidation(e, "DomainFor")}
                  name="DomainFor"
                  placeholder="Enter Domain"
                  type="text"
                />
                <span class="texthelp">
                  Enter domain without "@", For e.g. google.com
                </span>
                <br />
                <span style={{ color: "red" }} id="validation_error">
                  {Errors["DomainFor"]}
                </span>
              </div>
            </div>
            <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
              <button
                id="Dsubmit"
                className="btn btn-primary btn-lightgreen mr-1"
                onClick={BlackListDomainAdd}
              >
                <i className="la la-save"></i> Save
              </button>
              <a
                className="btn btn-secondary"
                onClick={() => {
                  DomaintabFor();
                }}
              >
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col px-0"> 
                <DomainTable
                  addDomainlistupdate={DomainChaild}
                  updateFromChild={UpdateFromChild}
                  Key={UpdateKey}
                /> 
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone whitebg inpt-0 px-3" index={1}>
          <div className="row">
            <div className="col border-bottom pl-0">
              <h4 className="headertitle float-left pl-0">Contact</h4>
            </div>
            <div className="col border-bottom pr-0">
              <div className="listing-li float-right padb-15 pr-0">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        ContactDomainAddBtn();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ContactDomainImportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li class="m-portlet__nav-item">
                    <a
                      onClick={ContactDomainBulkDleteBtn}
                      class="btn btngroup m-btn"
                    >
                      <span>
                        <i class="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ContactDomainExport}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                      <span>
                        {" "}
                        <CSVDownloader className="px-0"
                          data={ContactDomainExportData}
                          filename={CName + "- SalesReply-Contact"}
                          bom={true}
                        >
                          Export
                        </CSVDownloader>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          <div
            className="portletbody"
            id="import_csv_contact"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">Import Contact</h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add contact data from csv file to import.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={ContactDomainMapCsvBtn}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a
                    onClick={ContactDomainCancleBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attribute_contact"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      <option selected="" value="ContactEmail">
                        Contact Email
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      id="ContactEmail"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                    >
                      <option value="not mapped">Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <button
                  id="CSVSaveBtn"
                  onClick={ContactDomainSaveBtn}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </button>
              </div>
            </div>
          </div>

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          <div
            className="portletbody"
            id="contactdelete_csv"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">
                  Bulk deleted contact Domain
                </h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add contact data from csv file to bulk delete.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={ContactDomainBulkDeleteBtn}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a
                    onClick={ContactDomainBulkCancleBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attributecontact"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      Contact Email
                      <option selected="" value="ContactEmail">
                        Contact Email
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      id="ContactEmail1"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                    >
                      <option value="not mapped">Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <button
                  id="ContactCSVBtn"
                  onClick={ContactDomainBulkDeleteSaveBtn}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </button>
              </div>
            </div>
          </div>

          {/* Total Details for csv */}
          <div
            className="portletbody px-4"
            id="Show_csv_details_contact"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Results</h4>
            </div>

            <div className="col-xs-12">
              <div class="row colfive">
                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-primary">
                            {Total}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-success">
                            {TotalSucess}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total success</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-danger">
                            {TotalError}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total error</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-warning">
                            {TotalDuplicate}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total Duplicate</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-warning">
                            {TotalInvalid}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total Invalid</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="addkeyword row my-4"
            id="addblacklistcontacview"
            style={{ display: "none" }}
          >
            <div className="col-sm-12 col-md-6 row">
              <label className="col-lg-3 view-lable-meet">Contact</label>
              <div className="col-lg-9">
                <input
                  className="form-control m-input"
                  data-val="true"
                  data-val-required="Please enter Contact name"
                  id="ContactEmailAdd"
                  onBlur={() => ContactTabOnBlur()}
                  name="ContactEmailAdd"
                  placeholder="Enter email"
                  type="text"
                />

                <span style={{ color: "red" }} id="validation_email">
                  {Errors["ContactEmailAdd"]}
                </span>
              </div>
            </div>
            <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
              <button
                id="Csubmit"
                className="btn btn-primary btn-lightgreen mr-1"
                onClick={ContactTabEmailBtn}
              >
                <i className="la la-save"></i> Save
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  HideContactEmail();
                }}
              >
                <i className="la flaticon-cancel"></i> Cancel
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col px-0"> 
                <ContactTable
                  addlistupdate={ContactChaild}
                  updateFromChild={UpdateFromChild}
                  Key={UpdateKey}
                /> 
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone whitebg inpt-0 px-3" index={2}>
          <div className="row">
            <div className="col border-bottom pl-0">
              <h4 className="headertitle float-left pl-0">Removed</h4>
            </div>
            <div className="col border-bottom pr-0">
              <div className="listing-li float-right padb-15 pr-0">
                <ul>
                  <li>
                    <a
                      onClick={RemovedAddBtn}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={RemovedImportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>

                  <li class="m-portlet__nav-item">
                    <a
                      onClick={RemovedDeleteCsvBtn}
                      class="btn btngroup m-btn"
                    >
                      <span>
                        <i class="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={removedExportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                      <span>
                        {" "}
                        <CSVDownloader className="px-0"
                          data={RemovedExportData}
                          filename={CName + " - SalesReply-removed"}
                          bom={true}
                        >
                          Export
                        </CSVDownloader>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          <div
            className="portletbody"
            id="import_csv_Removed"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">Import removed Domain</h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add removed data from csv file to import.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={RemovedImportMapCavBtn}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a
                    onClick={RemovedCancleCsvBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attribute_removed1"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      <option selected="" value="Name">
                        Name
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      id="Name"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                    >
                      <option value="not mapped">Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <button
                id="RemovedcsvImport"
                  onClick={RemovedSaveCsvBtn}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </button>
              </div>
            </div>
          </div>

          {/* Total Details for csv */}
          <div
            className="portletbody px-4"
            id="Show_csv_details_removed"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Results</h4>
            </div>

            <div className="col-xs-12">
              <div class="row colfive">
                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-primary">
                            {RemovedTotal}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-success">
                            {RemovedTotalSucess}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total success</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-danger">
                            {RemovedTotalError}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total error</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-warning">
                            {RemovedTotalDuplicate}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total Duplicate</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-warning">
                            {TotalInvalid}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total Invalid</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          <div
            className="portletbody"
            id="delete_csv_removed"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">Bulk deleted removed</h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add removed data from csv file to bulk delete.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={RemovedBulkDeleteMapCsv}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a
                    onClick={RemovedBulkDeleteCancelCsv}
                    className="btn btn-secondary"
                  >
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attribute_removed"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      Name
                      <option selected="" value="Name">
                        Name
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      id="Name1"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                    >
                      <option value="not mapped">Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <a
                  onClick={RemovedBulkDeleteCsvSaveBtn}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </a>
              </div>
            </div>
          </div>

          <div
            className="addkeyword row my-4"
            id="removedview"
            style={{ display: "none" }}
          >
            <div className="col-sm-12 col-md-6 row">
              <label className="col-lg-3 view-lable-meet">Remove</label>
              <div className="col-lg-9">
                <input
                  className="form-control m-input"
                  data-val="true"
                  data-val-required="Please enter Email "
                  id="RemovedEmail"
                  onBlur={() => RemovedOnBlur()}
                  name="RemovedEmail"
                  placeholder="Enter email"
                  type="text"
                />
                <span style={{ color: "red" }}>{Errors["RemovedEmail"]}</span>
              </div>
            </div>
            <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
              <button
                id="Rsubmit"
                className="btn btn-primary btn-lightgreen mr-1"
                onClick={RemovedEmailSaveBtn}
              >
                <i className="la la-save"></i> Save
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  RemovedHideBtn();
                }}
              >
                <i className="la flaticon-cancel"></i> Cancel
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col px-0"> 
                <RemovedTable
                  addRemovedListed={RemovedChaild}
                  updateFromChild={UpdateFromChild}
                /> 
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone whitebg inpt-0 px-3" index={3}>
          <div className="row">
            <div className="col border-bottom  pl-0">
              <h4 className="headertitle float-left pl-0">Whitelist</h4>
            </div>
            <div className="col border-bottom pr-0">
              <div className="listing-li float-right padb-15 pr-0">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        WhiteListShowBtn();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={WhiteListImportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li class="m-portlet__nav-item">
                    <a
                      onClick={WhiteListDeleteBtn}
                      class="btn btngroup m-btn"
                    >
                      <span>
                        <i class="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={WhiteListExportCsvBtn}
                      className="btn btngroup m-btn"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                      <span>
                        {" "}
                        <CSVDownloader  className="px-0"
                          data={WhiteListExportData}
                          filename={CName + "-SalesReply-Whitelist"}
                          bom={true}
                        >
                          Export
                        </CSVDownloader>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          <div
            className="portletbody"
            id="import_csv_whitelist"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">Import Whitelist</h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add whitelist data from csv file to import.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={WhiteListImportMapCsv}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a onClick={WhiteListCancelCsv} className="btn btn-secondary">
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attribute_whitelist"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      <option selected="" value="Whitelist">
                        Name
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      id="Whitelist"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                    >
                      <option value="not mapped">Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <button
                id="WhiteCSVImport"
                  onClick={WhiteListSaveCsvBtn}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </button>
              </div>
            </div>
          </div>
          {/* Total Details for csv */}
          <div
            className="portletbody px-4"
            id="Show_csv_details_whitelist"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Results</h4>
            </div>

            <div className="col-xs-12">
              <div class="row colfive">
                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-primary">
                            {TotalWhiteList}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-success">
                            {TotalWhiteListSucess}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total success</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-danger">
                            {TotalWhiteListError}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total error</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-warning">
                            {TotalWhiteListDuplicate}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total Duplicate</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="shadowcard boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2">
                          <span class="box-main-title font-warning">
                            {TotalInvalid}
                          </span>
                        </div>
                        <div class="box-inner-title">
                          <string class="clr-sky">Total Invalid</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* Delete for csv */}
          {/* Bulk Csv for */}
          <div
            className="portletbody"
            id="delete_csv_whitelist"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h3 className="uploadheadingtitle">
                  Bulk deleted whitelist Domain
                </h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={HandleOnDrop}
                    onError={HandleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={HandleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add Whitelist data from csv file to bulk delete.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4 border-top pt-2 mt-4 border-top pt-2 mt-4">
                <div className="alignright">
                  <a
                    onClick={WhiteListBDeleteMapCsv}
                    className="btn btn-primary btnColor"
                  >
                    <i className="la la-upload"></i> Upload
                  </a>
                  <a
                    onClick={WhiteListBDeleteCancelCsv}
                    className="btn btn-secondary"
                  >
                    <i className="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Mapped div */}
          <div
            className="portletbody px-4 portletshadow"
            id="map_attribute_whitelistdelete"
            style={{ display: "none" }}
          >
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      <option selected="" value="WhitelistName2">
                        Name
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                      id="WhitelistName1"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                    >
                      <option value="not mapped">Not Mapped</option>
                      {DropboxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="col-xs-12 border-top pt-4 my-2 px-4">
                <a
                  onClick={WhiteListBulkDeleteBtn}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Save
                </a>
              </div>
            </div>
          </div>

          <div
            className="addkeyword row my-4"
            id="whitelistcontacview"
            style={{ display: "none" }}
          >
            <div className="col-sm-12 col-md-6 row">
              <label className="col-lg-3 view-lable-meet">WhiteList</label>
              <div className="col-lg-9">
                <input
                  className="form-control m-input"
                  data-val="true"
                  data-val-required="Please enter Contact name"
                  id="ContactEmailWhitelist"
                  onBlur={() => WhitelistEmailOnBlur()}
                  name="Contact"
                  placeholder="Enter email"
                  type="text"
                />
                <span style={{ color: "red" }} id="validation_email">
                  {Errors["ContactEmailWhitelist"]}
                </span>
              </div>
            </div>
            <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
              <button
                id="Wsubmit"
                className="btn btn-primary btn-lightgreen mr-1"
                onClick={WhiteListAddBtn}
              >
                <i className="la la-save"></i> Save
              </button>
              <a
                className="btn btn-secondary"
                onClick={() => {
                  CloseBtnForWhieBtn();
                }}
              >
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col px-0"> 
                <WhitelistTable
                  addWhiteListed={WhiteListChaild}
                  updateFromChild={UpdateFromChild}
                /> 
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}