import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import Popupone from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
const Fileupload = require("../../../_helpers/fileupload");
const EmailTemplete = require("../../../_helpers/email-templete");
import { GetUserDetails } from "../../../_helpers/Utility";

import ProfileImageUpload from '../../../_components/ProfileUpload';
const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT


const imgstyle = { width: "100px", height: "100px" };

class EditColdCallersPage extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();

    this.state = {
      Code: "",
      Profile: [],
      UserProfile: [],
      PopUpVal: true,
      InvitePopUp: false,
      FinalImg: "",
      FileImg: "",
      ImgFlag: false,
      ProfileImage:null,
      ColdCallersUserID:null,
      Email:null,
      ResponderName:null,
      LastName:null,
      Password:null,
      ColdCallerTeams:[],
      ClientID:null,
      UserID:null,
      Message:"",
      FormData:{
        FirstName: "",
        LastName: "",
        Email: "",
        ProfileImage: "",
        Id: "",
        IsTwoStepVerification:"",
        UserID:"",
        SlackAccount:"",
        Password:"",
        ColdCallerTeamID:"",
        IsTeamLead:"",

      },
      errors:{},
      ButtonDisabled:false,
      IsLoading:false,

    };
    this.ResetPassword = this.ResetPassword.bind(this);
    this.OpenInvitePopUp = this.OpenInvitePopUp.bind(this);
    this.CloseInvitePopUp = this.CloseInvitePopUp.bind(this);
    this.SubmitInvitePopUp = this.SubmitInvitePopUp.bind(this);
    this.SubmitData = this.SubmitData.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }

  componentDidMount() {
    document.title = "Edit Sales Development Reps | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ChildUserID });
    }
    this.ColdCallerTeam(Details.ChildUserID)
    const search = this.props.location.search;
    const Code = this.props.location.state;
    
    this.setState({ Code: Code });
    var InputParameters = {
      ID: Code,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/cold_callers_Profile/ColdCallersGetByID",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      
      let Pass;
      if(Res.data.Data.Password != undefined){
        Pass = JSON.parse((CryptoJS.AES.decrypt(((Res.data.Data?.Password).split(" ").join("+")), REACT_APP_ENCRYPTION_SALT)).toString(CryptoJS.enc.Utf8));
      }else{
        Pass=""
      }
        this.setState({FinalImg:Res.data.Data.ProfileImage});
        this.setState({ColdCallersUserID:Res.data.Data.ColdCallersUserID});
        let Data = {
          FirstName: Res.data.Data.ColdCallersName,
          LastName:Res.data.Data.LastName,
          Email: Res.data.Data.Email,
          ProfileImage: Res.data.Data.ProfileImage,
          Id: Code,
          IsTwoStepVerification:Res.data.Data?.IsTwoStepVerification,
          UserID:Res.data.Data?.UserID,
          SlackAccount:Res.data.Data?.SlackAccount,
          Password:Pass,
          ColdCallerTeamID:Res.data.Data?.ColdCallerTeamsID,
          IsTeamLead:Res.data.Data?.IsTeamLead,
        }
        this.setState({FormData:Data})
        
    });
   
  }
   //Get Cold caller team Dropdown
    ColdCallerTeam(UID){
    var InputParameter = {
      UserID: UID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallersTeamDropDown",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      this.setState({ColdCallerTeams:Result.data.Data})
    
    });
  }

//Open invite poup
  OpenInvitePopUp() {
    this.setState({ InvitePopUp: true });
  }

//Close Invite popup
  CloseInvitePopUp() {
    this.setState({ InvitePopUp: false });
    this.setState({ Message: "" });
  }
  async  CheckUpdateEmail(Email){
    let Emails = document.getElementById("change_mail").value;
      var InputParameters = {
        ParentUserID: "",
        // UserID: this.state.UserID,
        Email: Emails,
      };
    let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/UpdateEmailValidation",
        method: "POST",
        data: InputParameters,
      });
      
        if (resdata.data.StatusMessage == "SUCCESS") {
            if(resdata.data.Flag){
                this.setState({Message:resdata.data.Message});
              return false;
            }else{
              this.setState({Message:""});
              return true;
            }
            
        }
     
  }

     // Whole form validation
     async FormValidation() {
      let formIsValid = true;
      let Email = document.getElementById("change_mail").value;
      let IsExists = await this.CheckUpdateEmail(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length <=0 ){
        formIsValid=false;
        this.setState({Message:"Please enter email."})
      }
    
      return formIsValid;
    }

//Submit invite popup
 async SubmitInvitePopUp(e) {
  e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag =  await this.FormValidation()
    if(Final_flag==true){
    var SendEmail = document.getElementById("change_mail").value;
    var Tmp_Token =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    var Email_Token = CryptoJS.AES.encrypt(JSON.stringify(Tmp_Token),REACT_APP_ENCRYPTION_SALT).toString();
   
    var InputParamters = {
      uID: this.state.ColdCallersUserID,
      Email_Token: Email_Token,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/changeemail",
      method: "POST",
      data: InputParamters,
    }).then(async(res) => {
      
      if (res.data != "") {
        var Username = SendEmail;
        var email = CryptoJS.AES.encrypt(
          JSON.stringify(SendEmail),
          REACT_APP_ENCRYPTION_SALT
        ).toString();
        var emailData = EmailTemplete.userEmailChange(Email_Token, email,CommonConstants.change_Email_coldcallers);

        try {
          // Use await to wait for the SendEmail function to complete
          let IsMailSend = await this.SendEmail(Username, emailData, "Please Confirm Your Email");
          if (IsMailSend) {
            toast.success(<div className="toastsize">Cold Caller <br/>Mail sent successfully</div>)
          } else {
              toast.error(<div className="toastsize">Cold Caller Edit<br/>Something went wrong while sending the update email.</div>);
          }
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error(<div className="toastsize">Cold Caller Edit<br/>Error sending the update email.</div>);
        }
          
        // var InputParameters = {
        //   email: Username,
        //   html: emailData,
        //   subject: "Please Confirm Your Email",
        // };
        // Axios({
        //   url: CommonConstants.MOL_APIURL + "/user/emailsend",
        //   method: "POST",
        //   data: InputParameters,
        // }).then((res) => {
        //   if(res){
        //     toast.success(<div className="toastsize">Cold Caller <br/>Mail sent successfully.</div>)
        //   }
        // });
      }
    });
    this.setState({ InvitePopUp: false });
  }else{
    document.getElementById("m_emailInvite_submit").disabled = false;
  }
  }

//Reset password
  // ResetPassword() {
  //   var Tmp_Token =
  //     Math.random().toString(36).substring(2, 15) +
  //     Math.random().toString(36).substring(2, 15);
  //   var forgot_token = CryptoJS.MD5(Tmp_Token).toString();
  //   var uid = this.state.ColdCallersUserID;
  //   var InputParamters = {
  //     uID: uid,
  //     Forgot_Token: forgot_token,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/user/forgotpassword",
  //     method: "POST",
  //     data: InputParamters,
  //   }).then((res) => {
  //     if (res.data != "") {
  //       var Username = res.data.Username;
  //       var emailData = EmailTemplete.userForgotpassword(forgot_token);
  //       var InputParameters = {
  //         email: Username,
  //         html: emailData,
  //         subject: "Reset Password",
  //       };
  //       Axios({
  //         url: CommonConstants.MOL_APIURL + "/user/emailsend",
  //         method: "POST",
  //         data: InputParameters,
  //       }).then((res) => {
  //         toast.success(<div className="toastsize">Cold Caller Edit<br/>
  //       Thank you, Please check your email for password reset link.</div>);
  //       });
  //     }
  //   });
  // }

  ResetPassword(){
 
    var tmp_token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    var forgot_token = CryptoJS.AES.encrypt(JSON.stringify(tmp_token),REACT_APP_ENCRYPTION_SALT).toString();
   
        var InputParameters = {
            "UserName": this.state.FormData.Email,
            "Forgot_Token":forgot_token
        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/login/forgotpassword",
            method: "POST",
            data: InputParameters
        }).then(async(resdata) => {
          if (resdata.data.data?.length > 0) {
            var Username = resdata.data.data[0]?.Username;
            var emailData = EmailTemplete.userForgotpassword(forgot_token, Username);
            try {
              // Use await to wait for the SendEmail function to complete
              let IsMailSend = await this.SendEmail(Username, emailData, "Reset Password");
              if (IsMailSend) {
                  toast.success(<div className="toastsize">Cold Caller Edit<br/>  Thank you, Please check your email for password reset link.</div>);
              } else {
                  toast.error(<div className="toastsize">Cold Caller Edit<br/>Something went wrong while sending the reset password email.</div>);
              }
          } catch (error) {
              console.error("Error sending email:", error);
              toast.error(<div className="toastsize">Cold Caller Edit<br/>Error sending the reset password email.</div>);
          }
        //     var InputParameters = {
        //       email: Username,
        //       html: emailData,
        //       subject: "Reset Password",
        //     };
        //     Axios({
        //       url: CommonConstants.MOL_APIURL + "/user/emailsend",
        //       method: "POST",
        //       data: InputParameters,
        //     }).then((res) => {
        //       toast.success(<div className="toastsize">Cold Caller Edit<br/>
        // Thank you, Please check your email for password reset link.</div>);
        //     });
          }
        
        });
    
  }

//upload file
  HandleUpload(field, e) {
    e.preventDefault();
    if (
      Fileupload.checkMimeType(e.target.files[0]) &&
      Fileupload.checkFileSize(e.target.files[0])
    ) {
      var temp = URL.createObjectURL(e.target.files[0]);
      this.setState({
        FinalImg: e.target.files[0],
      });
      this.setState({ ImgFlag: true });
      this.setState({ FileImg: temp });
      document.getElementById("FirstName").value = this.state.ColdCallersName;
      document.getElementById("LastName").value = this.state.LastName;
      document.getElementById("Email").value = this.state.Email;
      document.getElementById("Password").value =this.state.Password;
    } else {
    }
  }

//Save Cold Caller
  SubmitData() {
    

        let TeamLead = document.getElementById("IsTeamLead").checked;
       let ColdCallerTeamID = document.getElementById("ColdCallerTeamID").value;
      let Slackaccount= document.getElementById("Slackaccount").value;
      let IsTwoStepVerification =  document.getElementById("IsTwoStepVerification").checked;
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
   
    if (FirstName == "" ||Slackaccount == "") {
      if (FirstName == "") {
        FirstName_e.style.display = "block";
      }
      if (Slackaccount == "") {
        Slackaccount_e.style.display = "block";
      }
    } else {
      if (this.state.ImgFlag == false) {
        file_Name = this.state.ProfileImage;
        var FieldPara = {
            Id:this.state.Code,
            FirstName: FirstName,
            LastName: LastName,
            Email: this.state.Email,
            coverimg: file_Name,
            IsTeamLead: TeamLead,
            SlackAccount:Slackaccount,
            IsTwoStepVerification:IsTwoStepVerification,
            ColdCallerTeamID:ColdCallerTeamID
        };
        Axios({
          url:CommonConstants.MOL_APIURL +"/cold_callers_Profile/ColdCallersProfileUpdate",
          method: "POST",
          data: FieldPara,
        }).then((res) => {
          
          if (res) {
            toast.success(<div className="toastsize">Sales Development Reps Edit<br/>
            Sales Development Reps Update successfully .</div>);
            history.push("/coldcallers");
          } else {
            toast.error(res.data.Message);
          }
        });
      } else {
        var name = this.state.FinalImg.name;
        var filetype = name.split(".");
        var file_Name;
        if (/\s/g.test(filetype[0])) {
          file_Name =
            filetype[0].replaceAll(/\s/g, "") +
            new Date().getTime() +
            "." +
            filetype[1] +
            "";
        } else {
          file_Name =
            filetype[0] + new Date().getTime() + "." + filetype[1] + "";
        }
        Fileupload.getBase64(this.state.FinalImg, (result) => {
          var rs = result;
          var splirs = rs.split("base64,");
          var _base64 = splirs[1];
          var myresult = {
            fields: "Coverimg",
            path: "./Content/UploadedFiles/UserProfilePic",
            file: _base64,
            imageName: file_Name,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/responder_Profile/ImageSave",
            method: "POST",
            data: myresult,
          }).then((res) => {
            
            
            if (this.state.ImgFlag == false) {
              file_Name = this.state.ProfileImage;
            } else {
              file_Name = file_Name;
            }
            var InputParameters = {
              Id:this.state.Code,
              FirstName: FirstName,
              LastName: LastName,
              Email: this.state.Email,
              coverimg: file_Name,
              IsTeamLead: TeamLead,
              SlackAccount:Slackaccount,
              IsTwoStepVerification:IsTwoStepVerification,
              ColdCallerTeamID:ColdCallerTeamID
            };

            Axios({
              url:CommonConstants.MOL_APIURL +"/cold_callers_Profile/ColdCallersProfileUpdate",
              method: "POST",
              data: InputParameters,
            }).then((res) => {
              
              if (res.statusText == "OK") {
                toast.success(<div className="toastsize">Sales Development Reps Edit<br/>
Sales Development Reps Update successfully .</div>);
                history.push("/coldcallers");
              } else {
                toast.error(res.data.Message);
              }
            });
          });
        });
      }
    }
  }

//back
  BackButton() {
    history.push("/coldcallers");
  }

    //Email Send method
    SendEmail = (Username, EmailTemplate, Subject) => {
      return new Promise((resolve, reject) => {
          const InputParameters = {
              email: Username,
              html: EmailTemplate,
              subject: Subject,
          };
          Axios({
              url: CommonConstants.MOL_APIURL + "/user/emailsend",
              method: "POST",
              data: InputParameters,
          })
          .then((res) => { 
              if (res.data.StatusMessage === "SUCCESS") {
                  resolve(true); // Resolve the promise with true
              } else {
                  resolve(false); // Resolve the promise with false
              }
          })
          .catch((error) => {
              console.error("Error sending email:", error);
              reject(error); // Reject the promise in case of an error
          });
      });
    }

     // Function to validate individual form field
     ValidateField(fieldName, value) {
      // Define your validation rules here
      const newErrors = { ...this.state.errors };
      switch (fieldName) {
        case "FirstName":
          // Check if the FirstName field is empty
          newErrors.FirstName = (value?.trim() === "" || value === null || value === undefined) ? "Please enter cold callers name." : "";
          break;
        case "SlackAccount":
          newErrors.SlackAccount = (value?.trim() === "" || value === null || value === undefined) ? "Please slack account." : "";
          break;
        default:
          break;
      }
      
      this.setState({ errors: newErrors });
    }
  
    //Update OnChange Handler
    HandleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
        
      this.setState((prevState) => {
        let updatedFormData = { ...prevState.FormData };
  
        if (type === "checkbox") {
          // For the checkbox, store the boolean value directly
          updatedFormData[name] = checked;
        } else {
          // For other fields, store their values
          updatedFormData[name] = value;
        }
        return {
          FormData: updatedFormData,
        };
      });
    };
  
    //Update OnBlur Handler
    HandleBlur = (e) => {
      const { name, value } = e.target;
      
      
      this.ValidateField(name, value);
    };
  
    //Update Sales Strategists
    HandleSubmit = async (e) => {
      e.preventDefault();
      this.setState({ ButtonDisabled: true });
      let Image = await this.childRef.current.SubmitData();
      let ImageName = Image.FileName;
      this.setState({ FinalImg: ImageName, ButtonDisabled: true });
      this.setState((prevState) => ({
        FormData: {
          ...prevState.FormData,
          coverimg: ImageName,
        },
      }));
      const { FormData } = this.state;
      const fieldNames = Object.keys(FormData);
      
      let isValid = true;
  
      fieldNames.forEach((fieldName) => {
        this.ValidateField(fieldName, FormData[fieldName]);
        if (this.state.errors[fieldName]) {
          isValid = false;
        }
      });
  
      
  
      if (isValid) {
        Axios({
          url:CommonConstants.MOL_APIURL +"/cold_callers_Profile/ColdCallersProfileUpdate",
          method: "POST",
          data: FormData,
        }).then((res) => {
          
          if (res) {
            toast.success(<div className="toastsize">Sales Development Reps Edit<br/>
            Sales Development Reps Update successfully .</div>);
            history.push("/coldcallers");
          } else {
            toast.error(res.data.Message);
          }
        });
  
        // You can submit the form data to your server or perform other actions here
      } else {
        this.setState({ ButtonDisabled: false });
      }
    };

  render() {
  const {FormData,errors} = this.state;

    return (
      <>
        <Popupone open={this.state.InvitePopUp} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopUp}>
                  <span aria-hidden="true" class="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Email</label>
                  <div class="col-lg-8">
                    <input
                      class="form-control m-input"
                      name="Email"
                      placeholder="Email"
                      id="change_mail"
                      type="text"
                      onBlur={this.CheckUpdateEmail.bind(this, "Email")}
                      />
                       <span style={{ color: "red" }}>
                          {this.state.Message}
                        </span>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={this.CloseInvitePopUp}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={this.SubmitInvitePopUp}
                  class="btn btn-primary btnColor"
                >
                  Send{" "}
                </a>
              </div>
            </div>
          </div>
        </Popupone>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">
                    Edit Cold Callers
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row pt-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">First Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="FirstName"
                        name="FirstName"
                        placeholder="First name"
                        type="text"
                        value={FormData.FirstName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.FirstName && (
                        <div className="errorinput">{errors.FirstName}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Last Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="LastName"
                        name="LastName"
                        placeholder="Last name"
                        type="text"
                        value={FormData.LastName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                    />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Email</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        placeholder="Email"
                        type="text"
                        value={FormData.Email}
                   
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <a
                    id="lnkUpdateEmail"
                    href="javascript:void(0);"
                    onClick={this.OpenInvitePopUp}
                    class="btn btn-primary btngroup btnlightgroup"
                  >
                    Update Email
                  </a>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Password</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="Password"
                        name="Title"
                        placeholder="Password"
                        type="Password"
                        value={FormData.Password}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <a
                    id="lnkUpdateEmail"
                    href="javascript:void(0);"
                    onClick={this.ResetPassword}
                    class="btn btn-primary btngroup btnlightgroup"
                  >
                    Reset Password
                  </a>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Sales Development Reps Team</label>
                    <select
                className="form-control  m-input"
                id="ColdCallerTeamID"
                name="ColdCallerTeamID"
                value={FormData.ColdCallerTeamID}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur} 
                autocomplete="off"
              >
                <option value="">--Select--</option>
                {this.state.ColdCallerTeams.map((value) => (
                  <option key={value._id} value={value._id}>
                    {value.ColdCallerTeamName}
                  </option>
                ))}
              </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Slack Account</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="Slackaccount"
                        name="SlackAccount"
                        placeholder="Enter slack account"
                        type="text"
                        value={FormData.SlackAccount}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                    />
                      {errors.SlackAccount && (
                        <div className="errorinput">{errors.SlackAccount}</div>
                      )}   
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      <input id="IsTeamLead" name="IsTeamLead" type="Checkbox" checked={FormData.IsTeamLead}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                    />
                      <span className="Checkmark"></span> Is Team Lead{" "}
                    </label>
                    <div class="col-lg-8"></div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group"></div>
              </div>

              <ProfileImageUpload
                profileImage={this.state.FinalImg}
                ref={this.childRef}
              />  

              {/* <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Upload Profile Image
                    </label>
                    <div class="col-lg-8">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        name="avatar"
                        onChange={this.HandleUpload.bind(this, "coverimage")}
                      />
                    </div>
                    <label class="col-lg-4 view-lable-meet"></label>
                    <div className="col-lg-8">
                      <div>
                        <label>
                          {this.state.ImgFlag == true
                            ? this.state.FinalImg.name
                            : this.state.ProfileImage}
                        </label>
                      </div>
                      <div id="imagePreview">
                        <img
                          style={imgstyle}
                          className="avatar-view container2"
                          src={
                            this.state.ImgFlag == true
                              ? this.state.FileImg
                              : CommonConstants.Image_url +
                                this.state.ProfileImage
                          }
                          id="avtar-preview"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="row pt-4">
                <div class="col-lg-6 boxsinput_group d-flex">
                  <div class="toggleswich mr-2">
                    <input type="checkbox" class="checktogle" id="IsTwoStepVerification" name="IsTwoStepVerification" checked={FormData.IsTwoStepVerification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/>
                    <b class="switch"> 
                      <span class="checkion"></span>
                      <span class="uncheckion"></span>
                    </b>
                    <b class="track"></b>
                  </div>
                  <label>Is Two Step Verification</label> 
                </div>
              </div> 
            </div>

              <div class="row pt-3">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.HandleSubmit}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.BackButton}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditColdCallersPage = connect(
  mapState,
  actionCreators
)(EditColdCallersPage);
export { connectedEditColdCallersPage as EditColdCallersPage };
