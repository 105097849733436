import React from "react";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { connect } from "react-redux";

import logo from "../../../images/SalesHive.png";
import logobgs from "../../../images/Loginvector.svg";

import { history } from "../../../_helpers/history";
import { CommonConstants } from "../../../_constants/common.constants";
const Fileupload = require("../../../_helpers/fileupload");
import { GetUserDetails } from "../../../_helpers/Utility";

import ProfileImageUpload from '../../../_components/ProfileUpload';

const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT

class AddSalesStrategistsPage extends React.Component {
  constructor(props) {
    
    super(props);
    this.childRef = React.createRef();
    this.state = {
      Email: "",
      Open: false,
      FileObjects: [],
      FinalImg: "",
      UserID: null,
      AlreadyExists:false,
      BtnDisabled:false,
      FormData:{
        Email:"",
        FirstName:"",
        LastName:"",
        Password:"",
        SlackAccount:""
      },
      errors:{},
      ButtonDisabled:false,
      IsLoading:false,
      DisplayAlreadyExistsMessage:false,
      DisplayUserAddedMessage:false

    };
    this.BackButton = this.BackButton.bind(this);
  }


  DecryptorMethod = (data) => {
    var FinalTmp = decodeURIComponent(data).split(" ").join("+");
    var bytes = CryptoJS.AES.decrypt(FinalTmp, REACT_APP_ENCRYPTION_SALT);
    var EncryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return EncryptedData;
  }


  componentDidMount() {
    document.title="Add Sales Strategist | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      // this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      // this.state.Role=Details.Role;
    }
    const QueryParams = new URLSearchParams(window.location.search);
    const Tmp = QueryParams.get("email");
    const TmpUID=QueryParams.get("UID");
    const TmpVC=QueryParams.get("vc");

    var Email = this.DecryptorMethod(Tmp);
    var UID = this.DecryptorMethod(TmpUID);
    var VC = this.DecryptorMethod(TmpVC);
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        Email: Email,
        UserID: UID,
        RoleId: 6,
        IsActive: true,
        IsDeleted: false,
        IsArchive:false,
        CreatedBy:UID,
        CreatedDt: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDt: null,
        
      },
    }));      
    this.setState({ Email: Email });
    this.setState({UserID:UID})
    document.getElementById("Email").value = Email;
    const InputParameters = {
      Email:Email
    }
        Axios({
                url: CommonConstants.MOL_APIURL + "/AdminUser/ValidationAddUser",
                method: "POST",
                data: InputParameters,
              }).then((res) => {
                if (res.data.StatusMessage == "SUCCESS") {
                    if(res.data.Data.length > 0){
                        this.setState({AlreadyExists:true})
                    }else{
                      this.setState({AlreadyExists:false})

                    }
                }
              });
  }

  //back salesstrategists
  BackButton() {
    history.push("/salesstrategists");
  }

  // Function to validate individual form field
  ValidateField = (fieldName, value) => {
    var Flag = true;
    this.setState((prevState) => {
      const newErrors = { ...prevState.errors };
  
      if (fieldName === "FirstName") {
        if (value.trim() === "") {
          newErrors.FirstName = "Please enter first name.";
          Flag = false;
        } else {
          newErrors.FirstName = "";
          Flag = true;
        }
      } else if (fieldName === "LastName") {
        if (value.trim() === "") {
          Flag = false;
          newErrors.LastName = "Please enter last name.";
        } else {
          Flag = true;

          newErrors.LastName = "";
        }
      } else if (fieldName === "Password") {
        newErrors.Password = "";
        if (value.trim() === "") {
          Flag = false;

          newErrors.Password = "Please enter password.";
        } else if (value.length < 6 || value.length > 20) {
          Flag = false;

          newErrors.Password = "Password must be 6 to 20 chars long.";
        }else{
          Flag = true;

        }
      }else if(fieldName === "SlackAccount") {
        newErrors.SlackAccount = "";
        if (value.trim() === "") {
          newErrors.SlackAccount = "Please enter slack account.";
        }else{
          Flag = true;

        }
      }
      return { errors: newErrors };
    });
    
    return Flag;
  };
  
  //Update OnChange Handler
  HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };
        updatedFormData[name] = value;
      this.ValidateField(name, value);
      return {
        FormData: updatedFormData,
      };
    });
  };

  //Update OnBlur Handler
  HandleBlur = (e) => {
    const { name, value } = e.target;
    this.ValidateField(name, value);
  };

  //Validation field method
  ValidateMethod = (fieldName,value) => {
    let Flag = true;
    if (fieldName === "FirstName") {
      if (value.trim() === "") {
        Flag = false;
      } else {
        Flag = true;
      }
    } else if (fieldName === "LastName") {
      if (value.trim() === "") {
        Flag = false;
      } else {
        Flag = true;
      }
    } else if (fieldName === "Password") {
      if (value.trim() === "") {
        Flag = false;
      } else if (value.length < 6 || value.length > 20) {
        Flag = false;
      }else{
        Flag = true;

      }
    } else if (fieldName === "SlackAccount") {
      if (value.trim() === "") {
        Flag = false;
      } else {
        Flag = true;
      }
    }
    return Flag;
  }

  //Update User Profile Data
  HandleSubmit = async (e) => {
    e.preventDefault();
  
    this.setState({ButtonDisabled: true });
  
    const { FormData } = this.state;
   
    const fieldNames = Object.keys(FormData);
    let arr = []
    // Iterate through fields and validate
    fieldNames.forEach(async(fieldName) => {
      this.ValidateField(fieldName, FormData[fieldName]);
      arr.push(this.ValidateMethod(fieldName, FormData[fieldName]))
    });
    let isValid = arr.includes(false)
    
    
    // Check if isValid is still true
    if (!isValid && !this.state.AlreadyExists) {
      let Image = await this.childRef.current.SubmitData();
      let ImageName = Image.FileName;
      this.setState({ FinalImg: ImageName});
      FormData['PassWord'] = CryptoJS.AES.encrypt(FormData.Password,REACT_APP_ENCRYPTION_SALT).toString();
      FormData['ProfileImage'] = ImageName;
      this.setState({DisplayAlreadyExistsMessage:false})
      Axios({
        url: CommonConstants.MOL_APIURL + "/sales_strategists_profile/SalesStrategistsAdd",
        method: "POST",
        data: FormData,
      }).then((res) => {
        if (res) {
          this.setState({ ButtonDisabled: false,DisplayUserAddedMessage:true });
          localStorage.removeItem("LoginData");
          localStorage.removeItem("id");
          history.push('/');

        }
      });
    } else if(isValid){
       this.setState({DisplayAlreadyExistsMessage:false})
      this.setState({ ButtonDisabled: false });
    }else{
      this.setState({DisplayAlreadyExistsMessage:true});
      this.setState({ ButtonDisabled: false });
    }
  };

  render() {
    const { showTemplate, showSaveAlert } = this.state;
    const { FormData, errors } = this.state;

    const { file, UserName, PassWord, submitted, radio, SelectedLang } =
      this.state;
    const customStylesLeg = {
      option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        color: state.isSelected ? "#5c0f8b" : "#5c0f8b",
        fontWeight: state.isSelected ? "900" : "400",
      }),
    };
    return (
      <div className="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={logo} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                <div className="heading">
                  <h1 className="lefter_title">Add Sales Strategists</h1>
                </div>
                {
                  this.state.DisplayAlreadyExistsMessage ?  <p id="UserExists_e" className="errorinput">
                  {" "}User already exists{" "}
                </p> : <></>
                }
               
                {
                  this.state.DisplayUserAddedMessage ?  <p id="UserAdd_e" className="font-success">
                  {" "}Sales Strategists added successfully{" "}
                </p> : <></>
                }
                   
                <form name="login" className="form">
                  <div className="input-control">
                    <label htmlFor="FirstName" className="input-label" hidden>
                      First Name
                    </label>
                    <input
                      type="text"
                      id="FirstName"
                      name="FirstName"
                      className="input-field"
                      placeholder="First Name"
                      autoComplete="off"
                      value={FormData.FirstName}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      {errors.FirstName && (
                          <div className="errorinput">{errors.FirstName}</div>
                        )}
                  </div>
                  <div className="input-control">
                    <label htmlFor="LastName" className="input-label" hidden>
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="LastName"
                      name="LastName"
                      className="input-field"
                      placeholder="Last Name"
                      autoComplete="off"
                      value={FormData.LastName}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      
                    
                  </div>
                  <div className="input-control">
                    <label htmlFor="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                      value={FormData.Email}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                      disabled
                    />
                      {errors.Email && (
                          <div className="errorinput">{errors.Email}</div>
                        )}
                    
                   
                  </div>
                  <div className="input-control">
                    <label htmlFor="PassWord" className="input-label" hidden>
                      PassWord
                    </label>
                    <input
                      type="PassWord"
                      id="PassWord"
                      name="Password"
                      className="input-field"
                      placeholder="Password"
                      autoComplete="off"
                      value={FormData.Password}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      {errors.Password && (
                          <div className="errorinput">{errors.Password}</div>
                        )}
                  </div>

                  <div className="input-control">
                    <label htmlFor="SlackAccount" className="input-label" hidden>
                    Slack Account
                    </label>
                    <input
                      type="text"
                      id="SlackAccount"
                      name="SlackAccount"
                      className="input-field"
                      placeholder="Slack Account"
                      autoComplete="off"
                      value={FormData.SlackAccount}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      {errors.SlackAccount && (
                          <div className="errorinput">{errors.SlackAccount}</div>
                        )}
                  </div>
                  <ProfileImageUpload
                    profileImage={this.state.FinalImg}
                    ref={this.childRef}
                    NewForm={true}
                  />
                

                  <div className="input-control btn-center">
                    <button
                      type="button"
                      className="input-submit"
                      id="submit"
                      disabled={this.state.ButtonDisabled}
                      onClick={this.HandleSubmit}
                    >
                      <i className="la la-save"></i> Save
                    </button>
                    <a
                      type="button"
                      className="input-submit"
                      id="backtolist"
                      onClick={() => {
                        this.BackButton();
                      }}
                    >
                      <i className="la la-arrow-circle-left"></i> Back
                    </a>
                    {/* <p id="err_user"> Please enter Correct Email or PassWord </p> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedAddSalesStrategistsPage = connect(
  mapState,
  actionCreators
)(AddSalesStrategistsPage);
export { connectedAddSalesStrategistsPage as AddSalesStrategistsPage };
