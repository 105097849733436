import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "bootstrap";
toast.configure();

class AddSalesRepPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID: Details.ParentUserID,
      IsExist: false,
      NameAvailable: null,
      BtnDisabled: false,
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title = "Add | SalesHive";
  }

  // handle change
  async  HandleChange(field, e) {
    let errors = { ...this.state.errors };
      const value = e.target.value.trim();
      let IsExists;

    
      if (field === "Name") { // Change "Country" to "Name"
        IsExists = await this.CheckExistName(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
    
        if (IsExists === "BlankData" && value === "") {
          errors.Name = "Please enter name"; // Change "Country" to "Name"
        } else if (IsExists === "ExistCountry") {
          errors.Name = "Name already exists."; // Change "Country" to "Name"
        } else if (IsExists === "AllDone") {
          errors.Name = null; // Change "Country" to "Name"
        }
      }
    
      this.setState({ errors });
  }

    // form validation 
  async FormValidation() {
    let FormIsValid = true;
    let errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExists = await this.CheckExistName(Name); // Change 'Country' to 'Name'
    
    if (IsExists == "BlankData" && Name === "") { // Check for an empty string or a space in the name
      FormIsValid = false;
      errors["Name"] = "Please enter name "; // Modify the error message
    }
  
    if (IsExists === "ExistCountry") {
      FormIsValid = false;
      errors["Name"] = "Name already exists."; // Change 'Country' to 'Name'
    }
  
    this.setState({ errors: errors });
    return FormIsValid;
  }

  // check exit Name
  async CheckExistName(Name) {

    // var Name = document.getElementById("Name").value;
    if(Name !== ""){
      var InputParameter = {
        UserID: this.state.PUserID,
        Name: Name,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/CheckNameExist",
        method: "POST",
        data: InputParameter,
      });
      // if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data?.length > 0) {
          this.setState({ NameAvailable: resdata.data.Data[0]?.Name });
          this.setState({ IsExist: true });

          return "ExistCountry";
  
        } else {
          return "AllDone";
        }
      // }
    }else{
      return "BlankData";
    }


  }

  backbtn() {
    history.push("/salesrep");
  }

  async savebtn(e) {
    // this.state.errors.Name = null;
    // this.state.NameAvailable = null;
    this.setState({ BtnDisabled: true });
    e.preventDefault();
    var Final_flag = await this.FormValidation();

    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var Data = {
        UserID: this.state.PUserID,
        Name: Name,
        IsArchive: false,
        CreatedBy: this.state.UserID,
        CreatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsAdd",
        method: "POST",
        data: Data,
      }).then((res) => {
        if (res.data?.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">Sales Reps added successfully.</div>
          );
          history.push("/salesrep");
        } else {
          toast.error(res.data?.Message);
          this.setState({ BtnDisabled: false });
        }
      });
    } else {
      this.setState({ BtnDisabled: false });
    }
  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display" style={{display:"none"}}>
      <img src={loadingicon} />
    </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">Add Sales Rep</h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row pt-4 pb-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        placeholder="Enter sales rep name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Name"]}
                      </span>
                      {/* {this.state.NameAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-4 pt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesRepPage = connect(
  mapState,
  actionCreators
)(AddSalesRepPage);
export { connectedAddSalesRepPage as AddSalesRepPage };
