import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { event } from "jquery";
import Swal from "sweetalert2";

import { history } from "../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../_helpers/Utility";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import { CommonConstants } from "../../_constants/common.constants";
import loadingicon from "../../images/loading.gif";

toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));

const SortableList = SortableContainer(({ newItems }) => {
  return (
    <ul>
      {newItems
        .filter((newfilterarray) => newfilterarray.IsInclude == true)
        .map((value, Index) =>
          value.IsStatic == true ? (
            <SortableItem
              id={Index}
              key={`item-${Index}`}
              index={Index}
              value={value.ColumnName}
            />
          ) : (
            <SortableItem
              id={Index}
              key={`item-${Index}`}
              index={Index}
              value={value.ColumnName}
            />
          )
        )}
    </ul>
  );
});

class CAccountCustomColumnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EditItems: [],
      newItems: [],
      Items: [],
      Page: {},
      IsChecked: true,
      ClientID: null,
      UserID: null,
      UserIDby: null,
      Role: null,
    };
  }

  componentDidMount() {
    document.title = `Account Custom Column | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.Role = Details.Role;
    }

    this.GetAccountCustomColumnList();
  }

  //Get account custom data
  GetAccountCustomColumnList() {
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      TableName: "AccountPage",
      Field: "OrderBy",
      Sortby: 1,
      Type: "User",
      ClientID: this.state.ClientID,
      Role: this.state.Role,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accountcuatomcolumn/AccountCustomColumnList",
      method: "POST",
      data: str_in,
    });
    rows1.then((Result) => {
      var newArray = [];
      this.setState({ Items: Result.data.PageData });
      this.setState({ EditItems: Result.data.PageData });

      {
        Result.data.PageData.map((value, Index) => {
          {
            if (value.IsInclude == true) {
              newArray.push(value);
            }
          }
        });
      }
      this.setState({
        newItems: newArray,
      });
      document.getElementById("hideloding").style.display = "none";
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      newItems: arrayMoveImmutable(this.state.newItems, oldIndex, newIndex),
    });
  };

  SaveOrder() {
    let OrderBy = 0;
    for (let i = 0; i < this.state.newItems.length; i++) {
      this.state.newItems[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "you want to save custom columns.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No,cance!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.SaveData();
      }
    });
  }

  //add item
  SaveData() {
    this.state.newItems.map((changeitem) => {
      this.state.EditItems.map((EditItems) => {
        if (changeitem._id == EditItems._id) {
          EditItems.OrderBy = changeitem.OrderBy;
        }
      });
    });
    let str_in = {
      Role: this.state.Role,
      AccountcustomcolumnList: this.state.EditItems,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accountcuatomcolumn/UpdateOrderAccountCustomcolumn",
      method: "POST",
      data: str_in,
    });
    rows1.then((Result) => {
      if (Result.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Account custom column  order updated successfully.",
          "Account custom column order"
        );
        history.push("/caccounts");
      } else {
        toast.error(Result.data.Message);
      }
      //this.GetAccountCustomColumnList();
    });
  }

  //update custom account
  UpdateAccountCustomList(e, value) {
    if (e.target.checked) {
      // //add to array
      // this.setState({
      //   newItems: this.state.Items.concat([value])
      // })
      var tempArray = this.state.newItems;
      tempArray.push(value);
      this.setState({
        newItems: tempArray,
      });
      this.state.EditItems.map((updateItems) => {
        if (value._id == updateItems._id) {
          updateItems.IsInclude = true;
        }
      });
    } else {
      //remove from array
      this.setState({
        newItems: this.state.Items.filter(function (val) {
          return val !== value;
        }),
      });
      this.state.EditItems.map((updateItems) => {
        if (value._id == updateItems._id) {
          updateItems.IsInclude = false;
        }
      });
    }
  }

  //back
  BackBtn() {
    history.push("/caccounts");
  }

  //check toggle
  toggleChange = () => {
    this.setState({
      IsChecked: !this.state.IsChecked,
    });
  };

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Account Custom Column
                  </h4>
                </div>
                <div class="col d-flex align-items-center justify-content-end mt-1">
                  <a
                    id="submit"
                    onClick={() => {
                      this.SaveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-2"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    class="btn btn-secondary"
                    onClick={() => {
                      this.BackBtn();
                    }}
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

            <div class="paddcols">
              <div className="row">
                <div className="col col-lg-6 padd-0">
                  <div className="cardcolum">
                    <h4 className="headertitlepad float-left">All Column</h4>

                    <div class="boxsinput_group">
                      <div class="m-section">
                        <h3 class="m-section__heading">Fixed Column</h3>
                        <ul class="ddlist">
                          {this.state.Items.filter(
                            (accountcolumn) => accountcolumn.IsStatic == true
                          ).map((value, Index) => (
                            <li>
                              <div class="col row">
                                <label class="">
                                  <input
                                    type="checkbox"
                                    id={Index}
                                    checked
                                    key={`item-${Index}`}
                                    index={Index}
                                  />
                                  {value.ColumnName}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div class="m-section">
                        <h3 class="m-section__heading">Custom Column</h3>
                        <ul class="ddlist">
                          {this.state.Items.slice(4, 100).map((value, Index) =>
                            value.IsInclude == true ? (
                              <li>
                                <div class="col row">
                                  <label class="">
                                    <input
                                      type="checkbox"
                                      id={Index}
                                      defaultChecked={this.state.IsChecked}
                                      // onClick={this.updatedIsIncluded}
                                      onChange={(e) =>
                                        this.UpdateAccountCustomList(e, value)
                                      }
                                      key={`item-${Index}`}
                                      index={Index}
                                    />
                                    {value.ColumnName}
                                  </label>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div class="row col">
                                  <label class="">
                                    <input
                                      type="checkbox"
                                      id={Index}
                                      onChange={(e) =>
                                        this.UpdateAccountCustomList(e, value)
                                      }
                                      key={`item-${Index}`}
                                      index={Index}
                                    />
                                    {value.ColumnName}
                                  </label>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col col-lg-6 padd-0">
                  <div className="cardcolum">
                    <h4 className="headertitlepad float-left">
                      Column Ordering
                    </h4>

                    <div class="boxsinput_group">
                      <SortableList
                        newItems={this.state.newItems}
                        onSortEnd={this.onSortEnd}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAccountCustomColumnPage = connect(
  mapState,
  actionCreators
)(CAccountCustomColumnPage);
export { connectedCAccountCustomColumnPage as CAccountCustomColumnPage };
