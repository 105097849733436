import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import DefaultCountryBlacklistTable from '../../_components/clients/defaultcountryblacklist/DefaultCountryBlacklist';
import CollapsibleTable from '../../_components/usertable';

import { history } from "../../../src/_helpers";

class CDefaultCountryBlackListPage extends React.Component {
    AddBtn() {
        history.push("/cadddefaultcountryblacklist");
      }
    
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                    <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle pl-0 mb-0">Default Country Blacklist</h4> 
                        </div> 
                        <div className="col">
                            <div className="listing-li float-right padb-15">
                                <ul>
                                  
                                    <li>
                                    <a onClick={this.AddBtn.bind(this)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                        <span>Add</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="paddcols pb-4">
                        <div className="row">
                            <div className="col"> 
                                <DefaultCountryBlacklistTable />  
                            </div>
                        </div>      
                    </div>

                </div>

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCDefaultCountryBlackListPage = connect(mapState, actionCreators)(CDefaultCountryBlackListPage);
export { connectedCDefaultCountryBlackListPage as CDefaultCountryBlackListPage };