import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const moment = require("moment");

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

export default function ClientMeeting(props) {
  const [Open, SetOpen] = React.useState(false);
  const [data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [Notes, SetNotes] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [CheckSearch, SetCheckSearch] = React.useState(false);
  const [Role, SetRole] = React.useState(0);
  const [matchingNoteFound, setMatchingNoteFound] = React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0)

  useEffect(() => {
    document.title = `Client Meetings | SalesHive`;
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientMeetingList(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
  }, [Search, Page, RowsPerPage, CheckSearch]);

  //get client meeting
  const GetClientMeetingList = (CID, UID, URole) => {
    SetIsLoading(true)
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CheckedSearch: CheckSearch,
    };
    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingGet",
      method: "POST",
      data: str_in,
    });
    Rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetNotes(result.data.MeetingNotes);
      SetIsLoading(false)

    });
  };

  //Search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //delete Client meeting
  const DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an Event.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Client meeting deleted successfully.",
                "success"
              );
              props.updateFromChild(true);
              GetClientMeetingList(ClientID, UserID, Role);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display Rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //render on view Page
  const ViewPage = (id) => {
    // history.push("/viewbookedmeeting", id);
    history.push({
      pathname: "/viewbookedmeeting",
      state: { data: id, Pagename: "ClientMeeting" },
    });
  };

  //Reset view
  const ResetView = () => {
    SetCheckSearch(true);
    document.getElementById("resetview").style.display = "none";
  };

  //Search Box
  const SearchData = () => {
    let IsCheckedSearch = document.getElementById("includeSourceCheck").checked;
    SetCheckSearch(IsCheckedSearch);
  };

  // Reset filter
  const ResetFilter = () => {
    SetCheckSearch(false);
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true)

    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CheckedSearch: CheckSearch,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CheckedSearch: CheckSearch,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null) {
      history.push({
        pathname: "/viewcontacts",
        state: { data: id, EmailData: Email, Pagename: "ClientBookedMeeting" },
      });
    }
  };

  return (
    <div>
      {
        IsLoading ?  <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
     
      <div className="row py-4 whiterow border-bottom">
        <div className="col col-lg-5">
          <a onClick={SearchData} class="btn text-white btnColor">
            <i class="la la-search"></i>Search
          </a>
          <a onClick={ResetFilter} class="ml-3 btn btn-secondary">
            <i class="la la-close"></i> Reset
          </a>
        </div>
        <div className="col col-lg-3"></div>
        <div className="col col-lg-4">
          <label class="check_bx float-right">
            <input
              id="includeSourceCheck"
              type="checkbox"
              defaultChecked={true}
            />
            <span className="checkmark"></span>
            Include Old Clients
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12" id="resetview" style={{ display: "none" }}>
          <a
            onClick={() => {
              ResetView();
            }}
          >
            Reset
          </a>
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>

        <div className="col-sm-12 col-md-6 full-right border-bttom">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Status");
                    }}
                  >
                    Meeting Status
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >
                    Client Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("LastName");
                    }}
                  >
                    Contact Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Company");
                    }}
                  >
                    Company
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("JobTitle");
                    }}
                  >
                    Title
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Domain
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MeetingSource");
                    }}
                  >
                    Meeting Source
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MeetingSource");
                    }}
                  >
                    Meeting Source Type
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MeetingOwner");
                    }}
                  >
                    Owner
                  </a>
                </TableCell>
                
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ResponderID");
                    }}
                  >
                    Meeting Set By
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("EventStartDt");
                    }}
                  >
                    Meeting Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("EventCreatedDt");
                    }}
                  >
                    Meeting Set Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("IsSendMeetingReminder");
                    }}
                  >
                    Send Reminder
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("LinkedInUrl");
                    }}
                  >
                    LinkedIn Url
                  </a>
                </TableCell>                
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TimeZoneName");
                    }}
                  >
                    Time Zone
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ContractValue");
                    }}
                  >
                    Contract Value
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Industry");
                    }}
                  >
                    Industry
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("DirectPhone");
                    }}
                  >
                    Direct Phone
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompanyPhone");
                    }}
                  >
                    Company Phone
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("DayInStag");
                    }}
                  >
                    Days In Stage
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("LastStatusChangeDate");
                    }}
                  >
                    Last Status Change Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CreatedDt");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("QulifiedDate");
                    }}
                  >
                    Qualified Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("OpportunityDate");
                    }}
                  >
                    Opportunity Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClosedWonDate");
                    }}
                  >
                    Closed Won Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClosedLostDate");
                    }}
                  >
                    Closed Lost Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("UnqualifiedDate");
                    }}
                  >
                    Unqualified Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >
                    Meeting Status Type
                  </a>
                </TableCell>

                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Seniority");
                    }}
                  >
                    Seniority
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            SetOpen((prev) => ({
                              // ...prev,
                              [row._id]: !prev[row._id],
                            }))
                          }
                        >
                          {Open[row._id] ? (
                            <i
                              className="fa fa-minus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            ViewPage(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                      </TableCell>
                      <TableCell>{row.Status}</TableCell>
                      <TableCell>{row.ClientName}</TableCell>
                      <TableCell> <a
                                onClick={() => {
                                  EditContactHandle(
                                    row?.Prospectid,
                                    row.Email
                                  );
                                }}
                              >
                                <u>{row?.ContactName}</u>
                              </a></TableCell>
                      <TableCell>{row.Company}</TableCell>
                      <TableCell>{row.Title}</TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>
                              <a
                                href={`http://${row?.Email?.substring(
                                  row?.Email?.lastIndexOf("@") + 1
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {row?.Email?.substring(
                                    row?.Email?.lastIndexOf("@") + 1
                                  )}
                                </u>
                              </a>
                            </TableCell>
                      <TableCell>{row.MeetingSource}</TableCell>
                      <TableCell>{row.MeetingSourceType}</TableCell>
                      <TableCell>{row.MeetingOwner}</TableCell>
                      <TableCell>
                        {row?.ResponderColdCallersType == "R"
                          ? row?.RespName
                          : row?.ColdName}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(
                          new Date(row.EventStartDt).toDateString()
                        ).format("MM/DD/YYYY") != "Invalid date"
                          ? moment(
                              new Date(row.EventStartDt).toDateString()
                            ).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(
                          new Date(row.EventCreatedDt).toDateString()
                        ).format("MM/DD/YYYY hh:mm a") != "Invalid date"
                          ? moment(
                              new Date(row.EventCreatedDt).toDateString()
                            ).format("MM/DD/YYYY hh:mm A")
                          : ""}
                      </TableCell>

                      <TableCell>{row?.IsSendMeetingReminder == true?"Yes":"No"}</TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.TimeZoneName}</TableCell>
                      <TableCell>{row?.ContractValue}</TableCell>
                      <TableCell>{row?.Industry}</TableCell>
                      <TableCell>{row?.DirectPhone}</TableCell>
                      <TableCell>{row?.CompanyPhone}</TableCell>
                      <TableCell>{row?.DaysInStage}</TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.LastStatusChangeDate).toDateString()
                        ).format("MM/DD/YYYY") != "Invalid date"
                          ? moment(
                              new Date(row.LastStatusChangeDate).toDateString()
                            ).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.CreatedDt != null
                          ? moment(new Date(row?.CreatedDt)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.QulifiedDate).toDateString()
                        ).format("MM/DD/YYYY") != "Invalid date"
                          ? moment(
                              new Date(row.QulifiedDate).toDateString()
                            ).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.OpportunityDate).toDateString()
                        ).format("MM/DD/YYYY") != "Invalid date"
                          ? moment(
                              new Date(row.OpportunityDate).toDateString()
                            ).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.ClosedWonDate).toDateString()
                        ).format("MM/DD/YYYY") != "Invalid date"
                          ? moment(
                              new Date(row.ClosedWonDate).toDateString()
                            ).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.ClosedLostDate).toDateString()
                        ).format("MM/DD/YYYY") != "Invalid date"
                          ? moment(
                              new Date(row.ClosedLostDate).toDateString()
                            ).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.UnqualifiedDate).toDateString()
                        ).format("MM/DD/YYYY") != "Invalid date"
                          ? moment(
                              new Date(row.UnqualifiedDate).toDateString()
                            ).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>{row.MeetingStatusType}</TableCell>

                      <TableCell>{row.Seniority}</TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            ViewPage(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteClientName(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                         <Collapse
                          in={Open[row?._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[row?._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {Notes.map((note) =>
                                  note?.MeetingID ==
                                  row?._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) =>
                                    note?.MeetingID == row?._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                        {/* <Collapse
                          in={open[row._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {open[row._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>

                                {Notes.map((note) =>
                                  note.MeetingID ===
                                  row._id? (
                                    <TableRow key={note._id}>
                                      <TableCell>{note.Title}</TableCell>
                                      <TableCell scope="row">
                                        {note.Note}
                                      </TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) =>
                                  note.MeetingID ===
                                  row._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse> */}
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
