import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  styled,
  makeStyles,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import ListItemButton from "@mui/material/ListItemButton";
import { history } from "../../../_helpers";
import { UpdateUserDetails, GetUserDetails } from "../../../_helpers/Utility";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RestoreIcon from "@material-ui/icons/Restore";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";

import logo from "../../../images/SalesHive.png";
import userdefault from "../../../images/default.png";
import defaultuser from "../../../images/defaultuser.png";
import { Select } from "@material-ui/core";
import { $CombinedState } from "redux";

import {
  BrowserRouter as Router,
  Link,
  NavLink,
  useLocation,
  Route,
} from "react-router-dom";
import { useClickOutside } from "../../../_helpers/useClickOutside";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Account from "../../../images/header/Account.svg";
import campiagn from "../../../images/header/campiagn.svg";
import ColdCalling from "../../../images/header/coldcalling.svg";
import contact from "../../../images/header/contact.svg";
import historyicon from "../../../images/header/history.svg";
import KPI from "../../../images/header/KPI.svg";
import list from "../../../images/header/list.svg";
import meeting from "../../../images/header/meeting.svg";
import settings from "../../../images/header/settings.svg";
import strategy from "../../../images/header/strategy.svg";
import tools from "../../../images/header/tools.svg";
import variable from "../../../images/header/variable.svg";
import admin from "../../../images/header/admin.svg";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonConstants } from "../../../_constants/common.constants";
const drawerWidth = 240;
import Abc from "../SendingSchedular/SendingSchedular.js";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  drawer: {
    maxWidth: 220,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      // display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const ListItem = withStyles({
  root: {
    "&$selected": {
      //backgroundColor: "red",
      color: "#F8BF58,'!important'",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58",
      },
    },
    "&$selected:hover": {
      //backgroundColor: "purple",
      color: "#F8BF58",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58",
      },
    },
    "&:hover": {
      //backgroundColor: "blue",
      color: "#F8BF58",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58",
      },
    },
  },
  selected: {},
})(MuiListItem);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  width: 220,
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [age, setAge] = React.useState("");
  const [aopen, asetOpen] = React.useState(true);
  const [mopen, msetOpen] = React.useState(true);
  const [vopen, vsetOpen] = React.useState(true);
  const [hopen, hsetOpen] = React.useState(true);
  const [sopen, ssetOpen] = React.useState(true);
  const [topen, tsetOpen] = React.useState(true);
  const [seopen, sesetOpen] = React.useState(true);
  const [accsopen, accssetOpen] = React.useState(true);
  const [pmsopen, pmssetopen] = React.useState(true);
  const [cmpsopen, cmpssetopen] = React.useState(true);
  const [cmmsopen, msetsetopen] = React.useState(true);
  const [allcopen, allcsetopen] = React.useState(true);
  const [shuopen, shopen] = React.useState(true);
  const [aseopen, asopen] = React.useState(true);
  const [copen, csetOpen] = React.useState(true);
  const [consopen, conopen] = React.useState(true);
  const [ClientDropdown, setClientDropdown] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  //const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Bird 1', 'Dog 1', 'Sun 1'];
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const dropdownRef = useRef(null);
  const [SelectedClientDropdown, setSelectedClientDropdown] =
    React.useState(false);
  const [ClientID, setClientID] = React.useState({ data: "" });
  const [SelectMenuItem, SetSelectMenuItem] = React.useState("/kpis");

  const [UserData, SetUserData] = React.useState([]);
  const [LoggedUserDetails, SetLoggedUserDetails] = React.useState([]);
  const [IsClients, SetIsClients] = React.useState(false);
  const [UserRole, SetUserRole] = React.useState(null);

  const [MenuSelector, SetMenuSelector] = React.useState(null);
  const [SubMenuSelector, SetSubMenuSelector] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const inputFocus = useRef(null);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    document.body.classList.toggle("openside");
  };

  useEffect(() => {
    //*** Side bar method */
    SideBar();
    //*** Side bar method */

    let LoggedData = JSON.parse(localStorage.getItem("LoginData"));

    if (!LoggedData) {
      history.push("/");
    } else {
      SetUserRole(LoggedData.Role);

      GetClientListForTopDropDown(
        LoggedData.Role,
        LoggedData.ChildUserID,
        LoggedData.ParentUserID
      );
      SetUserData(JSON.parse(localStorage.getItem("LoginData")));
      GetLoggerUserDetails(
        LoggedData.ChildUserID,
        LoggedData.Role,
        LoggedData.ParentUserID
      );
      var SelectedPage = props.menupage;
      if (SelectedPage == undefined) {
        SetSelectMenuItem("/kpis");
      } else {
        SetSelectMenuItem(SelectedPage);
        if (
          SelectedPage == "/potentialmeeting" ||
          SelectedPage == "/bookedmeetings" ||
          SelectedPage == "/opportunities" ||
          SelectedPage == "/linkedinfollowup" ||
          SelectedPage == "/reschedules"
        ) {
          msetOpen(false);
        }
      }
    }

    GetUserDetails();

    //GetClientListForTopDropDown(LoggedData.Role,LoggedData.ChildUserID,LoggedData.ParentUserID)
  }, [SelectMenuItem, searchValue]);

  //Sidebar Active routing method
  const SideBar = () => {
    const path = location.pathname;

    let MeetingsPath = [
      "/potentialmeeting",
      "/bookedmeetings",
      "/opportunities",
      "/linkedinfollowup",
      "/reschedules",
    ];
    let ColdCallingPath = [
      "/ColdCallingContact",
      "/callrecordings",
      "/CallHistory",
      "/coldcallingreporting",
    ];
    let VariableaPath = [
      "/Variable",
      "/customvariable",
      "/customvariableset",
      "/calltoaction",
    ];
    let HistoryPath = ["/emailhistory", "/callhistory"];
    let VariablePath = [
      "/actionitems",
      "/documents",
      "/directmail",
      "/research",
      "/insights",
    ];
    let ToolsPath = [
      "/campaignsummary",
      "/automator",
      "/despammer",
      "/emailvalidation",
      "/HiveAi",
      "/deliverabilitytesting",
      "/deliverabilitytracking",
      "/openratereporting",
      "/clicktrackreporting",
      "/globalbounce",
      "/emailwarmup",
      "/ContactBulkImportRequest",
    ];
    let ObjectionsPath = ["/usersperclient", "/objections", "/viewapiKeys"];
    let AccountSettingPath = [
      "/accountcategory",
      "/accountclientstatus",
      "/AccountReasonUnqualified",
      "/customaccountfield",
    ];
    let ContactSettingPath = [
      "/contactreasonunqualified",
      "/ClientPOC",
      "/contactcustomfield",
      "/ContactSource",
      "/contacttag",
    ];
    let CampaignSettingPath = [
      "/blacklists",
      "/EmailAccounts",
      "/CampaignCategories",
      "/sendingschedular",
      "/emailsignature",
      "/Defaultcountryblacklist",
    ];
    let MeetingSettingPath = [
      "/meetingowner",
      "/meetinggoal",
      "/meetingstatuses",
      "/meetingsource",
      "/meetingreminder",
      "/DefaultSlackNotification",
      "/meetingcustomfield",
    ];
    let PotentialSettingPath = ["/engagementstatus"];
    let AdminPath = ["/clients", "/emodprofile"];
    let AllClientsPath = [
      "/clientkpis",
      "/leaderboard",
      "/clientaccount",
      "/clientcontact",
      "/clientcampaigns",
      "/clientresponses",
      "/clientmeetings",
      "/clientrescheduling",
      "/clientpotentialmeetings",
      "/clientlinkedinfollowUp",
      "/clientemailaccounts",
      "/pendinginvites",
      "/variablereporting",
      "/userperclients",
      "/clientactionitems",
      "/clientdirectmail",
      "/clientresearch",
      "/clientlogin",
      "/clientapikeys",
    ];
    let SalesHiveUsersPath = [
      "/adminuser",
      "/salesstrategists",
      "/responder",
      "/coldcallers",
      "/coldcallerteams",
    ];
    let AdminSettingsPath = [
      "/clientstatuses",
      "/ResearchTeam",
      "/defaultvariables",
      "/globalbouncelist",
      "/triggerphrases",
      "/smartvariable",
      "/genericemaildomain",
      "/defaultobjections",
      "/defaulttrackingdomain",
      "/salesrep",
      "/globalblacklist",
      "/defaultcountryblacklist",
      "/coldcallerphoneburnerlogin",
      "/DefaultSlackNotification",
    ];

    if (MeetingsPath.includes(path)) {
      SetMenuSelector(0);
      msetOpen(false);
    } else if (ColdCallingPath.includes(path)) {
      SetMenuSelector(1);
      csetOpen(false);
    } else if (VariableaPath.includes(path)) {
      vsetOpen(false);
      SetMenuSelector(2);
    } else if (HistoryPath.includes(path)) {
      SetMenuSelector(3);
      hsetOpen(false);
    } else if (VariablePath.includes(path)) {
      SetMenuSelector(4);
      ssetOpen(false);
    } else if (ToolsPath.includes(path)) {
      SetMenuSelector(5);
      tsetOpen(false);
    } else if (ObjectionsPath.includes(path)) {
      SetMenuSelector(6);
      sesetOpen(false);
    } else if (AccountSettingPath.includes(path)) {
      SetMenuSelector(6);
      SetSubMenuSelector(1);
      sesetOpen(false);
      accssetOpen(false);
    } else if (ContactSettingPath.includes(path)) {
      SetMenuSelector(6);
      SetSubMenuSelector(2);
      sesetOpen(false);
      conopen(false);
    } else if (CampaignSettingPath.includes(path)) {
      SetMenuSelector(6);
      SetSubMenuSelector(3);
      sesetOpen(false);
      cmpssetopen(false);
    } else if (MeetingSettingPath.includes(path)) {
      SetMenuSelector(6);
      SetSubMenuSelector(4);
      sesetOpen(false);
      msetsetopen(false);
    } else if (PotentialSettingPath.includes(path)) {
      SetMenuSelector(6);
      SetSubMenuSelector(5);
      sesetOpen(false);
      pmssetopen(false);
    } else if (AdminPath.includes(path)) {
      SetMenuSelector(7);
      asetOpen(false);
    } else if (AllClientsPath.includes(path)) {
      SetMenuSelector(7);
      SetSubMenuSelector(6);
      allcsetopen(false);
      asetOpen(false);
    } else if (SalesHiveUsersPath.includes(path)) {
      SetMenuSelector(7);
      SetSubMenuSelector(7);
      shopen(false);
      asetOpen(false);
    } else if (AdminSettingsPath.includes(path)) {
      SetMenuSelector(7);
      SetSubMenuSelector(8);
      asopen(false);
      asetOpen(false);
    }
  };

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  // Getting logged user details
  const GetLoggerUserDetails = (CUID, Type) => {
    const InputParameters = {
      ChildUserID: CUID,
      AccountType: Type,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetLoginUserDetails",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.Data !== undefined) {
        SetLoggedUserDetails(res.data.Data);
      }
    });
  };

  const GetClientListForTopDropDown = async (Role, CUID, PUID) => {
    var ObjData = {
      Role: Role,
      ChildUserID: CUID,
      ParentUserID: PUID,
    };
    const result = await Axios({
      url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
      method: "POST",
      data: ObjData,
    });
    
    if (result.data.StatusMessage == "SUCCESS") {
      if (result.data.Data.length > 0) {
        setClientDropdown(result.data.Data);
        setFilteredOptions(result.data.Data);
        var Details = await GetUserDetails();

        if (Details.ClientID == undefined) {
          let UserData = JSON.parse(localStorage.getItem("LoginData"));
          if (UserData.ClientID === undefined) {
            await UpdateUserDetails(result.data?.Data[0]?.ClientID);
            setClientID({ data: result.data?.Data[0]?.ClientID });
            setSelectedClientDropdown(result.data?.Data[0]?.ClientID);
            setSearchValue(result.data?.Data[0]?.Name);
            props.updatefromchild(true);
          }
        } else {
          setClientID({ data: Details.ClientID });
          setSelectedClientDropdown(Details.ClientID);
          var searchdata = result.data.Data;
          var filter = searchdata.filter((x) => x.ClientID == Details.ClientID);
          if (filter.length > 0) {
            setSearchValue(filter[0].Name);
          }
        }
        SetIsClients(true);
      } else {
        SetIsClients(false);
        toast.error(
          <div className="toastsize">
            Client not available, please add client.
          </div>
        );
      }
    }
  };

  const logout = () => {
    localStorage.removeItem("LoginData");
    localStorage.removeItem("id");
    history.push("/");
  };

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  const MeetinghandleClick = () => {
    msetOpen(!mopen);
    if (mopen == false) {
      SetMenuSelector(null);
    } else {
      SetMenuSelector(0);
    }
  };

  const VariableshandleClick = () => {
    vsetOpen(!vopen);
    if (vopen == false) {
      SetMenuSelector(null);
    } else {
      SetMenuSelector(2);
      vsetOpen(false);
    }
  };

  const HistoryhandleClick = () => {
    hsetOpen(!hopen);
    if (hopen == true) {
      SetMenuSelector(3);
      hsetOpen(false);
    } else {
      SetMenuSelector(null);
    }
  };

  const StrategyhandleClick = () => {
    ssetOpen(!sopen);
    if (sopen == true) {
      SetMenuSelector(4);
      ssetOpen(false);
    } else {
      SetMenuSelector(null);
    }
  };

  const ToolshandleClick = () => {
    tsetOpen(!topen);
    if (topen == true) {
      SetMenuSelector(5);
      tsetOpen(false);
    } else {
      SetMenuSelector(null);
    }
  };

  const SettingshandleClick = () => {
    sesetOpen(!seopen);
    if (seopen == true) {
      SetMenuSelector(6);
      sesetOpen(false);
    } else {
      SetMenuSelector(null);
    }
  };

  const AccountSettinghandleClick = () => {
    accssetOpen(!accsopen);
    if (accsopen == true) {
      SetMenuSelector(6);
      SetSubMenuSelector(1);
      accssetOpen(false);
    } else {
      SetMenuSelector(6);
      accssetOpen(true);
      SetSubMenuSelector(null);
    }
  };

  const AdminhandleClick = () => {
    asetOpen(!aopen);
    if (aopen == true) {
      SetMenuSelector(7);
      asetOpen(false);
    } else {
      SetMenuSelector(null);
    }
  };

  const meeting_drop_handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const meeting_drop_handleClose = () => {
    setAnchorEl(null);
  };

  const PotentialMeetingSettinghandleClick = () => {
    pmssetopen(!pmsopen);
    if (pmsopen == true) {
      SetMenuSelector(6);
      SetSubMenuSelector(5);
      pmssetopen(false);
    } else {
      SetMenuSelector(6);
      SetSubMenuSelector(null);
      pmssetopen(true);
    }
  };
  const CampaignSettinghandleClick = () => {
    cmpssetopen(!cmpsopen);
    if (cmpsopen == true) {
      SetMenuSelector(6);
      SetSubMenuSelector(3);
      cmpssetopen(false);
    } else {
      SetMenuSelector(6);
      cmpssetopen(true);
      SetSubMenuSelector(null);
    }
  };
  const MeetingSettingshandleClick = () => {
    msetsetopen(!cmmsopen);
    if (cmmsopen == true) {
      SetMenuSelector(6);
      SetSubMenuSelector(4);
      msetsetopen(false);
    } else {
      SetMenuSelector(6);
      msetsetopen(true);
      SetSubMenuSelector(null);
    }
  };
  const AllClientshandleClick = () => {
    allcsetopen(!allcopen);
    if (allcopen == true) {
      SetMenuSelector(7);
      SetSubMenuSelector(6);
      allcsetopen(false);
    } else {
      SetMenuSelector(7);
      allcsetopen(true);
      SetSubMenuSelector(null);
    }
  };
  const ShUsershandleClick = () => {
    shopen(!shuopen);
    if (shuopen == true) {
      SetMenuSelector(7);
      SetSubMenuSelector(7);
      shopen(false);
    } else {
      SetMenuSelector(7);
      shopen(true);
      SetSubMenuSelector(null);
    }
  };
  const AdminSettingshandleClick = () => {
    asopen(!aseopen);
    if (aseopen == true) {
      SetMenuSelector(7);
      SetSubMenuSelector(8);
      asopen(false);
    } else {
      SetMenuSelector(7);
      asopen(true);
      SetSubMenuSelector(null);
    }
  };
  const ColdCallinghandleClick = () => {
    csetOpen(!copen);
    if (copen == false) {
      SetMenuSelector(null);
    } else {
      csetOpen(false);
      SetMenuSelector(1);
    }
  };
  const ContactSettingshandleClick = () => {
    conopen(!consopen);
    if (consopen == true) {
      SetMenuSelector(6);
      SetSubMenuSelector(2);
      conopen(false);
    } else {
      SetMenuSelector(6);
      conopen(true);
      SetSubMenuSelector(null);
    }
  };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  //Open Edit profile and Profile page

  const addShowClass = () => {
    const element = document.getElementById("id_userbox");
    if (element.classList.contains("show")) {
      element.classList.remove("show");
    } else {
      element.classList.add("show");
    }
  };

  const SelectClient = (e) => {
    setClientID({ data: e.target.value });

    setSelectedClientDropdown(e.target.value);
    UpdateUserDetails(e.target.value);
    history.go(0);
  };

  const handleListItemClick = (event, index) => {
    SetSelectMenuItem(index);
  };

  const handleInputChange = (event) => {
    //
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredOptions = ClientDropdown.filter((option) =>
      option.Name.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filteredOptions);
  };

  const handleOptionSelect = async (option, optionName) => {
    //

    setSearchValue(optionName);
    setSearchTerm("");
    setIsOpen(false);
    setFilteredOptions(ClientDropdown);
    setClientID({ data: option });

    setSelectedClientDropdown(option);
    UpdateUserDetails(option);
    history.go(0);
  };

  const drawer = (
    <div>
      {/* <div className={classes.toolbar} />  */}
      <List className="py-0">
        <ListItem button>
          <NavLink
            activeClassName="cust"
            to="/kpis"
            selected={SelectMenuItem === "/kpis"}
            onClick={(event) => handleListItemClick(event, "/kpis")}
          >
            <img src={KPI} />
            KPIs
          </NavLink>
        </ListItem>

        <ListItem button>
          <NavLink to="/accounts" activeClassName="cust">
            <img src={Account} />
            Accounts
          </NavLink>
        </ListItem>

        <ListItem button>
          <NavLink to="/contacts" activeClassName="cust">
            <img src={contact} />
            Contacts
          </NavLink>
        </ListItem>

        <ListItem
          className="menuline"
          button
          onClick={MeetinghandleClick}
          selected={MenuSelector === 0}
        >
          <img src={meeting} />
          Meetings
          {!mopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!mopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem button component={NavLink} activeClassName="cust" to="/potentialmeeting" className={classes.nested} 
            selected={SelectMenuItem === "/potentialmeeting"}
            onClick={(event) => handleListItemClick(event, "/potentialmeeting")} primary="Potential Meetings"> Potential Meetings
            </ListItem> */}
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/potentialmeeting">
                Potential Meetings
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/bookedmeetings">
                Booked Meetings
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/opportunities">
                Opportunities
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/linkedinfollowup">
                LinkedIn Follow Ups
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/reschedules">
                Reschedules
              </NavLink>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
          <NavLink to="/campaigns" activeClassName="cust">
            <img src={campiagn} />
            Campaigns
          </NavLink>
        </ListItem>

        <ListItem
          className="menuline"
          button
          onClick={ColdCallinghandleClick}
          selected={MenuSelector === 1}
        >
          <img src={ColdCalling} />
          Cold Calling
          {!copen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!copen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/ColdCallingContact">
                Contacts
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/callrecordings">
                Recordings
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/CallHistory">
                History
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/coldcallingreporting">
                Reporting
              </NavLink>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
          <NavLink activeClassName="cust" to="/list">
            <img src={list} />
            Lists
          </NavLink>
        </ListItem>

        <ListItem
          className="menuline"
          button
          onClick={VariableshandleClick}
          selected={MenuSelector === 2}
        >
          <img src={variable} />
          Variables
          {!vopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!vopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/Variable">
                Variable Sets
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/customvariable">
                Custom Variables
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/customvariableset">
                Custom Variable Sets
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/calltoaction">
                Call To Action
              </NavLink>
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          className="menuline"
          button
          onClick={HistoryhandleClick}
          selected={MenuSelector === 3}
        >
          <img src={historyicon} />
          History
          {!hopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!hopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/emailhistory">
                Email History
              </NavLink>
            </ListItem>
            {/* <ListItem button className={classes.nested}>
            <NavLink activeClassName="cust" to="/callhistory">Call History</NavLink>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem
          className="menuline"
          button
          onClick={StrategyhandleClick}
          selected={MenuSelector === 4}
        >
          <img src={strategy} />
          Strategy
          {!sopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!sopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/actionitems">
                Action Items
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/documents">
                Documents
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/directmail">
                Direct Mail
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/research">
                Research
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/insights">
                Insights
              </NavLink>
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          className="menuline"
          button
          onClick={ToolshandleClick}
          selected={MenuSelector === 5}
        >
          <img src={tools} />
          Tools
          {!topen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!topen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/campaignsummary">
                Campaigns Summary
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/automator">
                Automator
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/despammer">
                Despammer
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/emailvalidation">
                Email Validation
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/HiveAi">
                Hive AI
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/deliverabilitytesting">
                Deliverability Testing
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/deliverabilitytracking">
                Deliverability Tracking
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/openratereporting">
                Open Rate Reporting
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/clicktrackreporting">
                Click Track Reporting
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/globalbounce">
                Global Bounce
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/emailwarmup">
                Email Warm Up
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/ContactBulkImportRequest">
                Contact Bulk Import Request
              </NavLink>
            </ListItem>
            {/* <ListItem button className={classes.nested}>
            <Link to="/coldcallingContact">Cold Calling Contact</Link>
            </ListItem>
            <ListItem button className={classes.nested}>
            <Link to="/callrecordings">Call Recordings</Link>
            </ListItem>
            <ListItem button className={classes.nested}>
            <Link to="/coldcallingreporting">Cold Calling Reporting</Link>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem
          className="menuline"
          button
          onClick={SettingshandleClick}
          selected={MenuSelector === 6}
        >
          <img src={settings} />
          Settings
          {!seopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={!seopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/objections">
                Objections
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/usersperclient">
                Users Per Client
              </NavLink>
            </ListItem>
            <ListItem
              button
              className={`${classes.nested} menuline`}
              onClick={AccountSettinghandleClick}
              selected={SubMenuSelector === 1}
            >
              Account Settings
              {!accsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!accsopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/accountcategory"
                  button
                  className={classes.nested}
                >
                  Account Catagories
                </ListItem>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/accountclientstatus"
                  button
                  className={classes.nested}
                >
                  Client Status
                </ListItem>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/AccountReasonUnqualified"
                  button
                  className={classes.nested}
                >
                  Reason Unqualified
                </ListItem>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/customaccountfield"
                  button
                  className={classes.nested}
                >
                  Custom Fields
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              className={`${classes.nested} menuline`}
              onClick={ContactSettingshandleClick}
              selected={SubMenuSelector === 2}
            >
              Contact Settings
              {!consopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!consopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/contactreasonunqualified"
                  button
                  className={classes.nested}
                >
                  Reason Unqualified
                </ListItem>

                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/ClientPOC"
                  button
                  className={classes.nested}
                >
                  Client POC
                </ListItem>

                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/contactcustomfield"
                  button
                  className={classes.nested}
                >
                  Custom Fields
                </ListItem>

                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/ContactSource"
                  button
                  className={classes.nested}
                >
                  Contact Source
                </ListItem>

                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/contacttag"
                  button
                  className={classes.nested}
                >
                  Tag
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              className={`${classes.nested} menuline`}
              onClick={CampaignSettinghandleClick}
              selected={SubMenuSelector === 3}
            >
              Campaign Settings
              {!cmpsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!cmpsopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/blacklists"
                  button
                  className={classes.nested}
                >
                  Blacklists
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/EmailAccounts"
                  button
                  className={classes.nested}
                >
                  Email Accounts
                </ListItem>
              </List>

              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/CampaignCategories"
                  button
                  className={classes.nested}
                >
                  Campaign Categories
                </ListItem>
              </List>

              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/sendingschedular"
                  button
                  className={classes.nested}
                >
                  Sending Schedules
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/emailsignature"
                  button
                  className={classes.nested}
                >
                  Email Signature
                </ListItem>
              </List>

              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/countryblacklist"
                  button
                  className={classes.nested}
                >
                  Country Blacklist
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              className={`${classes.nested} menuline`}
              onClick={MeetingSettingshandleClick}
              selected={SubMenuSelector === 4}
            >
              Meeting Settings
              {!cmmsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!cmmsopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/meetingowner"
                  button
                  className={classes.nested}
                >
                  Meeting Owners
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/meetinggoal"
                  button
                  className={classes.nested}
                >
                  Meeting Goals
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/meetingstatuses"
                  button
                  className={classes.nested}
                >
                  Meeting Statuses
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/meetingsource"
                  button
                  className={classes.nested}
                >
                  Meeting Sources
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/meetingreminder"
                  button
                  className={classes.nested}
                >
                  Meeting Reminder
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/meetingslacknotification"
                  button
                  className={classes.nested}
                >
                  Slack Notifications
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/meetingcustomfield"
                  button
                  className={classes.nested}
                >
                  Custom Fields
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              className={`${classes.nested} menuline`}
              onClick={PotentialMeetingSettinghandleClick}
              selected={SubMenuSelector === 5}
            >
              Potential Meeting Settings
              {!pmsopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!pmsopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  activeClassName="cust"
                  to="/engagementstatus"
                  button
                  className={classes.nested}
                >
                  Engagement Status
                </ListItem>
              </List>
            </Collapse>

            <ListItem button className={classes.nested}>
              <NavLink activeClassName="cust" to="/viewapiKeys">
                API Key
              </NavLink>
            </ListItem>
            <ListItem button className={classes.nested}>
              <a
                href="https://zapier.com/apps/saleshive/integrations"
                target="_blank"
              >
                Integrations
              </a>
            </ListItem>
          </List>
        </Collapse>
        {UserRole != "ColdCallers" ? (
          <>
            <ListItem
              className={`${classes.nested} menuline`}
              button
              onClick={AdminhandleClick}
              selected={MenuSelector === 7}
            >
              <img src={admin} />
              Admin
              {!aopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!aopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {UserRole == "Admin" ? (
                  <ListItem button className={classes.nested}>
                    <NavLink activeClassName="cust" to="/clients">
                      Clients
                    </NavLink>
                  </ListItem>
                ) : null}

                <ListItem button className={classes.nested}>
                  <NavLink activeClassName="cust" to="/emodprofile">
                    eMod Admin
                  </NavLink>
                </ListItem>

                <ListItem
                  button
                  className={`${classes.nested} menuline`}
                  onClick={AllClientshandleClick}
                  selected={SubMenuSelector === 6}
                >
                  All Clients
                  {!allcopen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={!allcopen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientkpis"
                      button
                      className={classes.nested}
                    >
                      kpis
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/leaderboard"
                      button
                      className={classes.nested}
                    >
                      LeaderBoard
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientaccount"
                      button
                      className={classes.nested}
                    >
                      Accounts
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientcontact"
                      button
                      className={classes.nested}
                    >
                      Contacts
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientcampaigns"
                      button
                      className={classes.nested}
                    >
                      Campaigns
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientresponses"
                      button
                      className={classes.nested}
                    >
                      Responses
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientmeetings"
                      button
                      className={classes.nested}
                    >
                      Meetings
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientrescheduling"
                      button
                      className={classes.nested}
                    >
                      Rescheduling
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientpotentialmeetings"
                      button
                      className={classes.nested}
                    >
                      Potential Meetings
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientlinkedinfollowUp"
                      button
                      className={classes.nested}
                    >
                      LinkedIn Follow Ups
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientemailaccounts"
                      button
                      className={classes.nested}
                    >
                      Email Accounts
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/pendinginvites"
                      button
                      className={classes.nested}
                    >
                      Pending Invites
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/variablereporting"
                      button
                      className={classes.nested}
                    >
                      Variable Reporting
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/userperclients"
                      button
                      className={classes.nested}
                    >
                      User Per Clients
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientactionitems"
                      button
                      className={classes.nested}
                    >
                      Action Items
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientdirectmail"
                      button
                      className={classes.nested}
                    >
                      Direct Mail
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientresearch"
                      button
                      className={classes.nested}
                    >
                      Research
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientlogin"
                      button
                      className={classes.nested}
                    >
                      Logins
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientapikeys"
                      button
                      className={classes.nested}
                    >
                      API Keys
                    </ListItem>
                  </List>
                </Collapse>

                {/* SalesHive Users */}
                {UserRole != "Responder" ? (
                  <>
                    <ListItem
                      button
                      className={`${classes.nested} menuline`}
                      onClick={ShUsershandleClick}
                      selected={SubMenuSelector === 7}
                    >
                      SalesHive Users
                      {!shuopen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={!shuopen} timeout="auto" unmountOnExit>
                      {UserRole != "SalesHacker" ? (
                        <>
                          <List component="div" disablePadding>
                            <ListItem
                              component={NavLink}
                              activeClassName="cust"
                              to="/adminuser"
                              button
                              className={classes.nested}
                            >
                              Admins
                            </ListItem>
                          </List>
                          <List component="div" disablePadding>
                            <ListItem
                              component={NavLink}
                              activeClassName="cust"
                              to="/salesstrategists"
                              button
                              className={classes.nested}
                            >
                              Sales Strategists
                            </ListItem>
                          </List>
                          <List component="div" disablePadding>
                            <ListItem
                              component={NavLink}
                              activeClassName="cust"
                              to="/responder"
                              button
                              className={classes.nested}
                            >
                              Responders
                            </ListItem>
                          </List>
                        </>
                      ) : null}
                      <List component="div" disablePadding>
                        <ListItem
                          component={NavLink}
                          activeClassName="cust"
                          to="/coldcallers"
                          button
                          className={classes.nested}
                        >
                          Sales Development Reps
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          component={NavLink}
                          activeClassName="cust"
                          to="/coldcallerteams"
                          button
                          className={classes.nested}
                        >
                          Sales Development Teams
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                ) : null}
                {/* Admin Settings */}
                <ListItem
                  button
                  className={`${classes.nested} menuline`}
                  onClick={AdminSettingshandleClick}
                  selected={SubMenuSelector === 8}
                >
                  Admin Settings
                  {!aseopen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={!aseopen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/clientstatuses"
                      button
                      className={classes.nested}
                    >
                      Client Statuses
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/ResearchTeam"
                      button
                      className={classes.nested}
                    >
                      Research Teams
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/defaultvariables"
                      button
                      className={classes.nested}
                    >
                      Default Variables
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/globalbouncelist"
                      button
                      className={classes.nested}
                    >
                      Global Bounce List
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/triggerphrases"
                      button
                      className={classes.nested}
                    >
                      Trigger Phrases
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/smartvariable"
                      button
                      className={classes.nested}
                    >
                      Smart Variables
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/genericemaildomain"
                      button
                      className={classes.nested}
                    >
                      Generic Email Domains
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/defaultobjections"
                      button
                      className={classes.nested}
                    >
                      Default Objections
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/defaulttrackingdomain"
                      button
                      className={classes.nested}
                    >
                      Default Tracking Domains
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/salesrep"
                      button
                      className={classes.nested}
                    >
                      Sales Rep
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/globalblacklist"
                      button
                      className={classes.nested}
                    >
                      Global Blacklist
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      component={NavLink}
                      activeClassName="cust"
                      to="/defaultcountryblacklist"
                      button
                      className={classes.nested}
                    >
                      Default Country Blacklist
                    </ListItem>
                  </List>
                  {UserRole == "Responder" ? null : (
                    <List component="div" disablePadding>
                      <ListItem
                        component={NavLink}
                        activeClassName="cust"
                        to="/coldcallerphoneburnerlogin"
                        button
                        className={classes.nested}
                      >
                        Cold Caller Phone Burner Login
                      </ListItem>
                    </List>
                  )}
                  {UserRole == "Admin" ? (
                    <List component="div" disablePadding>
                      <ListItem
                        component={NavLink}
                        activeClassName="cust"
                        to="/DefaultSlackNotification"
                        button
                        className={classes.nested}
                      >
                        Default Slack Notification
                      </ListItem>
                    </List>
                  ) : null}
                </Collapse>
              </List>
            </Collapse>
          </>
        ) : null}

        {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
      </List>
      {/* <Divider /> */}
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {/* <Abc ClientID={ClientID}></Abc> */}
      <CssBaseline />
      <div className="header_main">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div className="col col-lg-5 px-0">
              <div className="logonavbox">
                <div className="navigationbtn">
                  <a
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </a>
                </div>
                <div className="logodivider">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </div>

            {/* 
          <h1>fdsfdsf</h1> */}
            <div className="col col-lg-7">
              <div className="righterblock">
                {/* 
          <ul className="menulist mshide">
            <li><NavLink activeClassName="cust" to="/kpis" >KPIs</NavLink></li>
            <li><NavLink activeClassName="cust" to="/accounts">Accounts</NavLink></li>
            <li><NavLink activeClassName="cust" to="/contacts">Contacts</NavLink></li> 
            <li className="dropbtnwhite"> 
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={meeting_drop_handleClick}>
                Meetings <i className="menuarrow la la-angle-down icon-chev"></i>
              </Button>
              <Menu
                className="metingbtns"
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={meeting_drop_handleClose}
              >
                <MenuItem onClick={meeting_drop_handleClose}>
                  <NavLink activeClassName="cust" to="/potentialmeeting" >Potential Meetings</NavLink>
                </MenuItem>
                <MenuItem onClick={meeting_drop_handleClose}>
                  <NavLink activeClassName="cust" to="/bookedmeetings">Booked Meetings</NavLink>
                </MenuItem>
                <MenuItem onClick={meeting_drop_handleClose}>
                  <NavLink activeClassName="cust" to="/opportunities">Opportunities</NavLink>
                </MenuItem>
                <MenuItem onClick={meeting_drop_handleClose}>
                  <NavLink activeClassName="cust" to="/linkedinfollowup">LinkedIn Follow Ups</NavLink>
                </MenuItem>
                <MenuItem onClick={meeting_drop_handleClose}>
                  <NavLink activeClassName="cust" to="/reschedules">Reschedules</NavLink>
                </MenuItem>
              </Menu>
            </li>  
            <li><NavLink activeClassName="cust" to="/Campaigns">Campaigns</NavLink></li>
            <li>
              <a>
                  <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                More <i className="menuarrow la la-angle-down icon-chev"></i>
              </IconButton>  
              </a>
            </li>
          </ul> */}

                {/* <div className="lghiden">
          <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                <i className="la la-navicon"></i>
              </IconButton> 
          </div> */}

                {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            Open Menu
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu> */}

                {/* {
            IsClients == true ?<div className="userdrop">
            <FormControl className={classes.formControl}>
              
              <Select  labelId="demo-simple-select-label" value={SelectedClientDropdown} id="clientid"  onChange={SelectClient} >
              {ClientDropdown.map((row) => (
                  <option value={row.ClientID}>{row.Name}</option>
              ))}
                
              </Select>
            </FormControl>
            </div> :(null)
          } */}

                {IsClients == true ? (
                  <div
                    className="dropdown dropselect border-right pr-3 py-3"
                    ref={dropdownRef}
                  >
                    <div
                      className="dropdown-header" 
                      onClick={() => {
                        setIsOpen(!isOpen);
                        if (!isOpen) { 
                          console.log("sadas asd");
                          const timeoutId = setTimeout(() => {
                          inputFocus.current.focus();
                        }, 100); 
                        }
                      }} 
                    >
                      <div className="dropdown-header-title">
                        {searchValue ? searchValue : "Select an option"}
                      </div>
                      <div className="dropdown-header-icon">
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    </div>
                    {isOpen && (
                      <div className="dropdown-options">
                        <input ref={inputFocus} 
                          type="text"
                          className="dropdown-search"
                          value={searchTerm}
                          onChange={handleInputChange}
                          placeholder="Search options"
                        />
                        <ul>
                          {filteredOptions.map((option) => (
                            <li
                              key={option.ClientID}
                              onClick={() =>
                                handleOptionSelect(option.ClientID, option.Name)
                              }
                            >
                              {option.Name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : null}

                <a
                  href="#"
                  className="m-nav__link m-dropdown__toggle py-2"
                  onClick={addShowClass}
                >
                  <span className="m-topbar__userpic">
                    <img
                      width="40px"
                      src={
                        LoggedUserDetails?.length === 0
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url +
                            LoggedUserDetails?.ProfileImage
                      }
                    ></img>
                  </span>
                  <div className="usenav-profile">
                    <span className="card-usertitel">
                      {LoggedUserDetails.FirstName +
                        " " +
                        LoggedUserDetails.LastName}
                    </span>
                    <Link to="/EditMyProfile">
                      {LoggedUserDetails.Username}
                    </Link>
                  </div>
                </a>

                <div className="userdropdown" id="id_userbox">
                  <div className="bg-themehead">
                    <div className="m-card-user m-card-user--skin-dark">
                      <div className="carduser_pic">
                        <img
                          width="40px"
                          src={
                            LoggedUserDetails.ProfileImage == ""
                              ? CommonConstants.Image_url + "default.png"
                              : CommonConstants.Image_url +
                                LoggedUserDetails.ProfileImage
                          }
                        ></img>
                      </div>
                      <div className="carduser_details">
                        <span className="card-usertitel  text-white">
                          {LoggedUserDetails.FirstName +
                            " " +
                            LoggedUserDetails.LastName}
                        </span>
                        <Link to="/EditMyProfile">
                          {LoggedUserDetails.Username}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="bodyuserdop">
                    <ul>
                      <li>
                        <Link to="/EditMyProfile">
                          <i className="m-nav__link-icon flaticon-profile-1"></i>{" "}
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <a className="Logoutbtn btn" onClick={logout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="navtop">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </div> */}
          </Toolbar>
        </AppBar>

        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {/* <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden> */}
        </nav>
      </div>

      <Drawer
        className="sidebarleft"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {drawer}
      </Drawer>
      <Main open={open}></Main>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
