import React from "react";
import { connect } from "react-redux";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Axios from "axios";

import { userActions } from "../../_actions";
const moment = require("moment");
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ResponsesOverTimeTable from "../../_components/user/ActionItems/ResponsesOverTimeTable";
import ResponsesDayOfWeekTable from "../../_components/user/ActionItems/ResponsesDayOfWeekTable";
//import CollapsibleTable from '../_components/usertable';

import { CommonConstants } from "../../_constants/common.constants";

import { GetUserDetails } from "../../_helpers/Utility";

class InsightsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      ExportData: [],
      FromDate: "",
      ToDate: "",
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    document.title = "Insight | SalesHive";
    function getPreviousMonth() {
      const currentDate = new Date();
      currentDate.setDate(1)
      return currentDate;
    }
    let FromDate = (document.getElementById("from-date").valueAsDate = new Date(
      getPreviousMonth()
    ));
    let ToDate = (document.getElementById("to-date").valueAsDate = new Date());
    this.setState({ FromDate: FromDate, ToDate: ToDate });
    this.GetExportData();
  }

  GetExportData() {
    const InputParameter = {
      FromDate: this.state.FromDate,
      ToDate: this.state.ToDate,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/insight/ResponsesDayOfWeekExport",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      this.setState({ ExportData: Res.data.Data });
    });
  }
  ExportCsv() {
    this.GetExportData();
  }

  async SaveBtn() {
    let FromDate = document.getElementById("from-date").value;
    let ToDate = document.getElementById("to-date").value;
    this.setState({ FromDate: FromDate, ToDate: ToDate });
    await this.ExportCsv();
  }
  render() {
    return (
      <>
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row px-1 d-flex align-items-center">
              <div className="col">
                <h4 className="headertitle float-left px-0">Insight</h4>
              </div>
              <div className="col ">
                <div className="listing-li float-right">
                  <div class="btn-group">
                    <input id="fromtab" name="fromtab" type="hidden" value="" />
                    <input
                      class="form-control datepicker"
                      id="from-date"
                      name="from-date"
                      type="date"
                      autocomplete="off"
                    />
                    <input
                      class="form-control datepicker"
                      id="to-date"
                      name="to-date"
                      type="date"
                      autocomplete="off"
                    />
                    <button
                      id="aCompanyState"
                      class="btn btn-primary btnColor m-btn brrb72"
                      autocomplete="off"
                      onClick={this.SaveBtn}
                    >
                      <i class="la flaticon-diagram"></i>
                      <span>Analyze</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="bodymainer">
              <div className="row">
                <div className="col"> 
                    <ResponsesDayOfWeekTable
                      ToDate={this.state.ToDate}
                      FromDate={this.state.FromDate}
                    /> 
                </div>
              </div>
            </div>

            <div className="bodymainer pb-4 mt-3">
              <div className="row">
                <div className="col"> 
                    <ResponsesOverTimeTable
                      ToDate={this.state.ToDate}
                      FromDate={this.state.FromDate}
                    /> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedInsightsPage = connect(mapState, actionCreators)(InsightsPage);
export { connectedInsightsPage as InsightsPage };
