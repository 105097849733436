import React from "react";
const moment = require("moment");

import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";
import TextField from "@material-ui/core/TextField";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";



class CAddSendingSchedularPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      ClientID: null,
      UserID: null,
      UserIDby: null,
      Role:null
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.Role = Details.Role;
  
    }
    document.getElementById("starttime").value = "06:00";
    document.getElementById("endtime").value = "18:00";
  }

//form validation
  FromValidation() {
    let formIsValid = true;
    if (Object.entries(this.state.Fields).length > 0) {
      let Fields = this.state.Fields;
      let Errors = {};
      // var starttime = document.getElementById("starttime").value;
      // var endtime = document.getElementById("endtime").value;

      // if (!Fields["starttime"] || starttime == "" || starttime == undefined) {
      //   formIsValid = false;
      //   Errors["starttime"] = "The SchedularStartTime field is required.";
      // }
      // if (!Fields["endtime"] || endtime == "" || endtime == undefined) {
      //   formIsValid = false;
      //   Errors["endtime"] = "The SchedularEndTime field is required.";
      // }
      if (this.state.statusAvailable != null) {
        formIsValid = false;
      }
      this.setState({ Errors: Errors });
    }
    return formIsValid;
  }

//change handler
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    // if (Fields.starttime != "") {
    //   this.state.Errors.starttime = null;
    // }
    // if (Fields.endtime != "" && Fields.endtime != undefined) {
    //   this.state.Errors.endtime = null;
    // }
  }

//Add Schedular
  SaveBtn(e) {
    e.preventDefault();
    if (this.FromValidation()) {
      var ScheduleStartTime = document.getElementById("starttime").value;
      var SchedularEndTime = document.getElementById("endtime").value;
      let UtcStartTime = moment(ScheduleStartTime,'hh:mm A').format("MM/DD/YYYY HH:mm A");
      let UtcEndTime = moment(SchedularEndTime,'hh:mm A').format("MM/DD/YYYY HH:mm A");

      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        ScheduleStartTime: ScheduleStartTime,
        ScheduleEndTime: SchedularEndTime,
        ScheduleStartUTCTime:UtcStartTime,
        ScheduleEndUTCTime:UtcEndTime,
        CreatedBy: this.state.UserIDby,
        CreatedDate: new Date(),
        IsDeleted:false,
        Role:this.state.Role
      };
      
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
        <div className="toastsize">Add Sending Schedules <br/>Sending Schedules added successfully.</div>
          );
          history.push("/CSendingSchedular");
        } else {
          toast.error(res.data.Message);
        }
      });

    } else {
     
    }
  }

//back
  BackBtn() {
    history.push("/CSendingSchedular");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Add Sending Schedules
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">
                      Schedule Start Time
                    </label>
                    <div class="col-lg-8">
                      <TextField
                        class="form-control m-input timerborder"
                        id="starttime"
                        onChange={this.HandleChange.bind(this, "starttime")}
                        type="time"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["starttime"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">
                      Schedule End Time
                    </label>
                    <div class="col-lg-8">
                      <TextField
                        class="form-control m-input timerborder"
                        onChange={this.HandleChange.bind(this, "endtime")}
                        id="endtime"
                        type="time"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["endtime"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-4">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddSendingSchedularPage = connect(
  mapState,
  actionCreators
)(CAddSendingSchedularPage);
export { connectedCAddSendingSchedularPage as CAddSendingSchedularPage };
