import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import { CSVReader, CSVDownloader } from "react-papaparse";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import Rescheduling from "../../_components/user/Rescheduling/Rescheduling";
//import CollapsibleTable from '../_components/usertable';

import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import loadingicons from "../../images/loading.gif";
import { history } from "../../_helpers";
import { GetClientDetails } from "../../_helpers/Utility";

charts(FusionCharts);

class ReschedulingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: null,
      ClientID: null,
      CUserID: null,
      ExportData: [],
      GraphData: [],
      SourceData: {},
      TotalReschedules: 0,
      Role:null,
      CName:""
    };
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ UserID: Details.Role });
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    this.GraphGet(Details.ClientID);

    this.ExportMethod(Details.ClientID);
  }
  //Export CSV
  ExportMethod(CID) {
    var Details = GetUserDetails();
    const InputParameters = {
      ClientID: CID,
      Role:Details.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/reschedules/MeetingReschedulesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      this.setState({ ExportData: Res.data?.Data });
      this.setState({ TotalReschedules: Res.data?.DataLenght ===undefined?0:Res.data?.DataLenght });
    });
  }
  ExportCsv() {}

  // Graph Get
  GraphGet(CID) {
    const InputParameters = {
      ClientID: CID,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/reschedules/GraphGet",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      this.setState({ GraphData: Res.data?.data });
      //this.setState({ TotalReschedules: Res.data?.TotalmeetingCount ===undefined?0:Res.data?.TotalmeetingCount });
      var dataSource = {
        chart: {
          // caption: "Meeting Source",
          //subcaption: "For a net-worth of $1M",
          theme: "fusion",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          plothighlighteffect: "false",
          legendcaptionbold: "1",
          legendcaptionfontsize: "15",
          legendCaptionFontColor: "#000",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "right",
          //legendCaption: "Meeting Status: ",
          legendBgColor: "#FFF",
          legendBorderColor: "#ffF",
          legendShadow: "0",
          legendItemFontSize: "14",
          legendWidth: "300", 
        },
        data: Res.data?.data,
      };
      this.setState({ SourceData: dataSource });
      document.getElementById("hidelodings").style.display = "none";
    });
  }


    //Reload page while delete perform
    UpdateFromChild = (value) => {
      if (value == true) {
        this.componentDidMount();
      }
    };
  render() {
    return (
      <>
      <div id="hidelodings" className="loding-display">
        <img src={loadingicons} />
      </div>
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlenop px-1 py-4 pl-0">Reschedules</h4>
              </div>

            </div>

            <div className="w-100 whiterow py-4 px-4">
              <div className="row px-3">
                <div className="col px-0">
                  <div className="bg-light-sky boxcardcounter align-center">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">
                        {this.state.TotalReschedules}
                      </span>
                    </div>
                    <p class="clr-sky">Total Reschedules</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="row">
              <h5 className="headertitle float-left pl-3">Meeting Source</h5>
            </div>
            <div className="row">
              <div className="col-xl-6">
              <div className="whitebg">
                <ReactFusioncharts
                key={JSON.stringify(this.state.SourceData)}
                  type="pie3d"
                  width="100%"
                  height="300"
                  dataFormat="JSON"
                  dataSource={this.state.SourceData}
                />
              </div>
              <div className="col-xl-6 px-0"></div> 
              </div>
            </div>

            <div className="row">
              <div className="col"> 
                <h4 className="headertitle float-left">
                  Reschedules
                </h4>
              </div> 
              <div className="col pt-2">
                <div className="listing-li float-right padb-15">
                  <ul className="pr-1">
                    <li>
                    <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            //filename={"Rescheduling"}
                            filename={this.state.CName + "Reschedules"}
                            bom={true}
                          > <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                          
                            Export
                        </span>
                      </a>
                          </CSVDownloader>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols px-4 pt-0">
              <div className="row">
                <div className="col"> 
                    <Rescheduling updateFromChild={this.UpdateFromChild} /> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedReschedulingPage = connect(
  mapState,
  actionCreators
)(ReschedulingPage);
export { connectedReschedulingPage as ReschedulingPage };
