import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
const moment = require("moment");
import { GetUserDetails } from "../../../_helpers/Utility";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { history } from "../../../_helpers";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import Popover from '@mui/material/Popover';

// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });

const defaultTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "yellow",
        backgroundColor: "red"
      }
    }
  }
});

export default function AccountAllTable() {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [Sflag, setSflag] = React.useState(false);
  const [Rlen, setRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, SetfAnchorEl] = React.useState(null);
  const [checkS, setcheckS] = React.useState(false);
  const [orignaldata, setorignaldata] = React.useState([]);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);

  const [cancelarchivepopupval, setcancelarchivepopupval] =
    React.useState(false);
  const [ArchivedPopup, setArchivedPopup] = React.useState(false);
  const [question, setquestion] = React.useState("");
  const [msg, setmsg] = React.useState("");
  const [Type, SetType] = React.useState("");
  const [popupbutton, setpopupbutton] = React.useState("");
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [AID, SetAID] = React.useState("");
  const [Archive, SetArchive] = React.useState(false);
  const [TemplatePopup, SetTemplatePopup] = React.useState(false)
  const [Name, SetName] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  useEffect(() => {
    document.title = "Campaign | SalesHive"
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
    GetCampaignsList(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID,getaccountcatelist.Role);
  }, [ClientID, Role, search, Page, RowsPerPage,Archive,AnchorEl]);

  const GetCampaignsList = (CID,UID,URole) => {
    var str_in = {
      page: Page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      Archive:Archive
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaign/CampaignGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      console.log(result.data);
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setRlen(result.data.totalcount);
      setflen(result.data.totalcount);
      SetCountPage(result.data.pagecount);
    });
  };

  const CampaignActiveUpdate = async (CampaignID, Flag) => {
    
    var Str_in = {
      IsActive:!Flag,
      CampaignID:CampaignID,
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaign/CampaignActiveUpdate",
      method: "POST",
      data: Str_in,
    });
    if(rows1.data.StatusMessage == "SUCCESS"){

      GetCampaignsList(ClientID,UserID,Role)
    }else if(rows1.data.StatusMessage == "EmailSignatureError"){
      toast.error(<div>Campaign<br />Email signature is required</div>);

    }
    else{
      toast.error(<div>Campaign<br />Please Add Account, Prospect and Step atlest one.</div>);
    }
  }

  //push to edit page
  const EditCampaign = async (CampaignID) => {
    history.push({ pathname: "/ceditcampaigns", state: { data: CampaignID } })
  }

  //Handle click
  const HandleClick = (event, id) => {

    SetAID(id);
    SetAnchorEl(event.currentTarget);
  };

   //Handle close one
   const HandleClickOne = (event, id) => {
    SetAID(id);
    SetfAnchorEl(event.currentTarget);
  };
  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
        SetPage(NewPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

   //Handle close
   const HandleClose = () => {
    SetAnchorEl(null);
  };

    //Handle close Second
    const HandleCloseSecond = () => {
      SetfAnchorEl(false);
    };
  //handle change anchrEl
  const HandleCloseOne = () => {
    SetfAnchorEl(null);
  };

    //change display rows
    const ChangeRowSelected = (Event) => {
      SetRowsPerPage(Number(Event.target.value));
      SetPage(1);
      //document.getElementById("hideloding").style.display = "block";
  };

  //Search archive
  const HandleSearch = async() =>{
    var ArchiveGet = document.getElementById("include_archive").checked
    SetArchive(ArchiveGet)
  }
  //campaign archive and unarchive
  const HandleUnArchive = async(CampaignID,Type) => {
    HandleClose();
    HandleCloseOne();
    Swal.fire({
      title: "Are you sure?",
      text: "you want to "+Type+" a campaign.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, "+Type+" it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (Result) => {
      if (Result.isConfirmed) {
        
    var StrIn = {
      CampaignID:AID,
      Type:Type,
      UserID:CUserID
    }
    var Result = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/CampaignArchive",
      method: "POST",
      data: StrIn,
    });
    console.log("Campaign details --->",Result);
    if(Result.data.StatusMessage === "SUCCESS"){
      GetCampaignsList(ClientID,UserID,Role)
      Swal.fire(
        ""+Type+"!",
        "Campaign "+Type+" successfully.",
        "success"
      );
    }
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
    
  }
  
  //campaign delte
  const HandleDelete = async(CampaignID) => {
    HandleClose();
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a campaign.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (Result) => {
    var StrIn = {
      CampaignID:AID,
      UserID:CUserID
    }
    var Result = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/CampaignDelete",
      method: "POST",
      data: StrIn,
    });
    console.log("Campaign details --->",Result);
    if(Result.data.StatusMessage === "SUCCESS"){
      
      //GetCampaignsList(ClientID,UserID,Role)
      Swal.fire(
        "Deleted!",
        "Campaign deleted successfully.",
        "success"
      );
    }
    });
  }

  const  SaveAsGlobal = async(e,Type) => {
    
    SetType(Type);
    HandleClose();
    HandleCloseOne();
    HandleTemplatePopup()
    
    var StrIn = {
      CampaignID: AID,
    };
    var Result = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignByID",
      method: "POST",
      data: StrIn,
    });
    console.log("Campaign details --->", Result);
    if (Result.data.StatusMessage === "SUCCESS") {
        document.getElementById("NameTemplate").value = Result.data.Data[0].Name + " - Template";
        document.getElementById("NotesTemplate").value = Result.data.Data[0].Notes;
    }
    
    // console.log(Type,"global - add")
    // var Str_In;
    // if(Type === "Global"){
    //   Str_In = {
    //     CampaignID:AID,
    //     ClientID:0,
    //     UserID:UserID,
    //     Role:Role
    //   }
    // }else{
    //   Str_In = {
    //     CampaignID:AID,
    //     ClientID:ClientID,
    //     UserID:UserID,
    //     Role:Role
    //   }
    // }
    
    // var AddGlobalTemplate = await Axios({
    //   url:
    //     CommonConstants.MOL_APIURL + "/campaigntemplate/AddCampaignTemplate",
    //   method: "POST",
    //   data: Str_In,
    // });
    // if(AddGlobalTemplate.data.StatusMessage == "SUCCESS"){
    //   GetCampaignsList(ClientID,UserID,Role)
    // }
  }

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == search) {
        setSearch(SearchedVal);
        SetPage(1);
      } else {
        setSearch(SearchedVal);
        SetPage(1);
        //document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  // Deplicate campaign
  const DuplicateCampaign = (Event) => {
    HandleClose();
        HandleCloseOne();
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Duplicate a campaign.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Duplicate it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (Result) => {
      if (Result.isConfirmed) {
        
        var StrIn = {
          CampaignID:AID,
          UserID:CUserID,
          ClientID:ClientID,
          Role:Role
        }
        var Result = await Axios({
          url: CommonConstants.MOL_APIURL + "/campaign/CampaignDuplicateAdd",
          method: "POST",
          data: StrIn,
        });
        if(Result.data.StatusMessage === "SUCCESS"){
          GetCampaignsList(ClientID,UserID,Role)
          Swal.fire(
            "Duplicate!",
            "Campaign duplicate successfully.",
            "success"
          );
        }
        
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  //Import contact
  const ImportContact = (Event) => {
    //history.push({ pathname: "/editcampaigns", state: { CampaignID:  AID} })
  }
  const HandleTemplatePopup = async () =>{
    if(TemplatePopup === false){
      SetTemplatePopup(true)
    }else{
      SetTemplatePopup(false);
    }
  }

  const SaveTeplate = async () => {
    
    console.log(Type,"global - add")
    var Name=document.getElementById("NameTemplate").value
    SetName(Name)
    var Notes=document.getElementById("NotesTemplate").value
    var Str_In;
    if(Type === "Global"){
      Str_In = {
        CampaignID:AID,
        ClientID:ClientID,
        UserID:UserID,
        Role:Role,
        Name:Name,
        Notes:Notes,
        TemplateType: true
      }
    }else{
      Str_In = {
        CampaignID:AID,
        ClientID:ClientID,
        UserID:UserID,
        Role:Role,
        Name:Name,
        Notes:Notes,
        TemplateType: false
      }
    }
    
    var AddGlobalTemplate = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaigntemplate/AddCampaignTemplate",
      method: "POST",
      data: Str_In,
    });
    if(AddGlobalTemplate.data.StatusMessage == "SUCCESS"){
      GetCampaignsList(ClientID,UserID,Role)
      HandleTemplatePopup()
      toast.success(
            <div className="toastsize">
              Campaign Template
              <br />
              Campaign template "{Name}" saved successfully.
            </div>
          );
    }else{

    }
  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
    <div className='px-2'>
    <div className="row">
                <div className="col padd-0"> 
                </div>
                <div className="col padright flexdisplayer pr-3">  
                  <div className="fulleriger">
                    <label class="check_bx floatrighter mr-3 mt-1">
                      <input id="include_archive" type="checkbox" /><span className="checkmark"></span>
                      Include archived campaigns 
                  </label>
                    <button class="btn btn-primary btnColor m-btn m-btn--icon m-btn--air" onClick={HandleSearch}>
                        <span>
                            <i class="la flaticon-search"></i><span>Search</span>
                        </span>
                    </button> 
                  </div>
                </div>
            </div>

            <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} id="search" /></label>
                </div>
            </div> 
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Total Contacts</TableCell>
                <TableCell>Sent</TableCell> 
                <TableCell>Open %</TableCell>
                <TableCell>Open % L1</TableCell>
                <TableCell>Open % L7</TableCell>
                <TableCell>Open % L30</TableCell>
                <TableCell>Reply %</TableCell>
                <TableCell>Bounce %</TableCell>
                <TableCell>Sent L1</TableCell>
                <TableCell>Sent L7</TableCell>
                <TableCell>Active Contacts</TableCell>
                <TableCell>Reply Behavior</TableCell>
                <TableCell>Ignore Blacklisted</TableCell>
                <TableCell>Email Open Tracking</TableCell>
                <TableCell>Send on Weekends</TableCell>
                <TableCell>Priority</TableCell> 
                <TableCell>Track Click</TableCell>
                <TableCell>Total Track Click</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                rows?.map((row) => (
                  <>
                <TableRow>
                  <TableCell><div class="toggleswich">
                                            <input type="checkbox"  class="checktogle" onChange={()=>{CampaignActiveUpdate(row._id,row.IsActive)}} checked={row?.IsActive}/>
                                            <b class="switch">
                                              <span class="checkion"></span>
                                              <span class="uncheckion"></span>
                                            </b>
                                            <b class="track"></b>
                                          </div></TableCell>
                <TableCell><a href="" onClick={()=>{EditCampaign(row._id)}}><u>{row.Name}</u></a></TableCell>
                <TableCell>{row.TotalCount === undefined ? 0:row.TotalCount.Count}</TableCell>
                <TableCell>{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory.TotalSend}</TableCell> 
                <TableCell><Tooltip title={<>
                <span>Opens: {row.CampaignStepHistory === undefined? 0 : row.CampaignStepHistory?.TotalOpen}</span><br/>
                <span>Tracked: {row.CampaignStepHistory === undefined? 0 : row.CampaignStepHistory?.TotalTracked}</span><br/>
                <span>Open %: {row.CampaignStepHistory === undefined ? 0+"%" :row.CampaignStepHistory?.TotalTracked === 0 ? 0+"%" :parseInt((row.CampaignStepHistory?.TotalOpen/row.CampaignStepHistory?.TotalTracked)*100) +"%"}</span>
                </>} placement="right">
              <span>{row.CampaignStepHistory === undefined ? 0+"%" :row.CampaignStepHistory?.TotalTracked === 0 ? 0+"%" :parseInt((row.CampaignStepHistory?.TotalOpen/row.CampaignStepHistory?.TotalTracked)*100) +"%"}</span>
            </Tooltip></TableCell>
                <TableCell><Tooltip title={<>
                <span>Opens:{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpenL1}</span><br/>
                <span>Tracked:{row.CampaignStepHistory === undefined ? 0 :row.CampaignStepHistory?.TotalTrackedL1}</span><br/>
                <span>Open %:{row.CampaignStepHistory === undefined ? 0 +"%" : row.CampaignStepHistory?.TotalTrackedL1 === 0 ? 0+" %" :parseInt((row.CampaignStepHistory?.TotalOpenL1/row.CampaignStepHistory?.TotalTrackedL1)*100) +" %"}</span>
                </>} placement="right">
              <span>{row.CampaignStepHistory === undefined ? 0 +"%" : row.CampaignStepHistory?.TotalTrackedL1 === 0 ? 0+" %" :parseInt((row.CampaignStepHistory?.TotalOpenL1/row.CampaignStepHistory?.TotalTrackedL1)*100) +" %"}</span>
            </Tooltip></TableCell>
                <TableCell><Tooltip title={<>
                <span>Opens: {row.CampaignStepHistory === undefined? 0 :row.CampaignStepHistory?.TotalOpenL7}</span><br/>
                <span>Tracked: {row.CampaignStepHistory === undefined? 0 : row.CampaignStepHistory?.TotalTrackedL7}</span><br/>
                <span>Open %: {row.CampaignStepHistory === undefined? 0+"%" : row.CampaignStepHistory?.TotalTrackedL7 === 0 ? 0+"%" :parseInt((row.CampaignStepHistory?.TotalOpenL7/row.CampaignStepHistory?.TotalTrackedL7)*100) +"%"}</span>
                </>} placement="right">
              <span>{row.CampaignStepHistory === undefined? 0+"%" : row.CampaignStepHistory?.TotalTrackedL7 === 0 ? 0+"%" :parseInt((row.CampaignStepHistory?.TotalOpenL7/row.CampaignStepHistory?.TotalTrackedL7)*100) +"%"}</span>
            </Tooltip></TableCell>
                <TableCell><Tooltip title={<>
                <span>Opens: {row.CampaignStepHistory === undefined? 0: row.CampaignStepHistory?.TotalOpenL30}</span><br/>
                <span>Tracked: {row.CampaignStepHistory === undefined? 0: row.CampaignStepHistory?.TotalTrackedL30}</span><br/>
                <span>Open %: {row.CampaignStepHistory === undefined? 0+"%": row.CampaignStepHistory?.TotalTrackedL30 === 0 ? 0+"%" :parseInt((row.CampaignStepHistory?.TotalOpenL30/row.CampaignStepHistory?.TotalTrackedL30)*100) +"%"}</span>
                </>} placement="right">
              <span>{row.CampaignStepHistory === undefined? 0+"%": row.CampaignStepHistory?.TotalTrackedL30 === 0 ? 0+"%" :parseInt((row.CampaignStepHistory?.TotalOpenL30/row.CampaignStepHistory?.TotalTrackedL30)*100) +"%"}</span>
            </Tooltip></TableCell>
                <TableCell><Tooltip title={<>
                <span>Replies: {row.CampaignStepHistory === undefined ? 0: row.CampaignStepHistory?.TotalReply}</span><br/>
                <span>Sent: {row.CampaignStepHistory === undefined ? 0:row.CampaignStepHistory?.TotalSend}</span><br/>
                <span>Reply %: {row.CampaignStepHistory === undefined ? 0 +"%":row.CampaignStepHistory?.TotalSend === 0 ? 0+"%" :parseInt((row.CampaignStepHistory?.TotalReply/row.CampaignStepHistory?.TotalSend)*100) +"%"}</span>
                </>} placement="right">
              <span>{row.CampaignStepHistory === undefined ? 0 +"%" : row.CampaignStepHistory?.TotalSend === 0 ? 0+"%" : parseInt((row.CampaignStepHistory?.TotalReply/row.CampaignStepHistory?.TotalSend)*100) +"%"}</span>
            </Tooltip></TableCell>
                <TableCell><Tooltip title={<>
                <span>Soft Bounce: {row.CampaignStepHistory === undefined ? 0:row.CampaignStepHistory?.TotalSoftBounce}</span><br/>
                <span>Hard Bounce: {row.CampaignStepHistory === undefined ? 0:row.CampaignStepHistory?.TotalHardBounce}</span><br/>
                <span>Total Bounce: {row.CampaignStepHistory === undefined ? 0:(row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce)}</span><br/>
                <span>Sent: {row.CampaignStepHistory?.TotalSend}</span><br/>
                <span>Bounce %: {row.CampaignStepHistory === undefined ? 0+"%":row.CampaignStepHistory?.TotalSend === 0 ? 0+"%" :parseInt(((row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce)/row.CampaignStepHistory?.TotalSend)*100) +"%"}</span>
                </>} placement="right">
              <span>{row.CampaignStepHistory === undefined ? 0 +"%":row.CampaignStepHistory?.TotalSend === 0 ? 0+"%" : parseInt(((row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce)/row.CampaignStepHistory?.TotalSend)*100) +"%"}</span>
            </Tooltip></TableCell>
                <TableCell>{row.CampaignStepHistory === undefined ? 0:row.CampaignStepHistory?.TotalSendL1}</TableCell>
                <TableCell>{row.CampaignStepHistory === undefined ? 0:row.CampaignStepHistory?.TotalSendL7}</TableCell>
                <TableCell><Tooltip title={<>
                <span>TotalContacts:{row.TotalCount === undefined ? 0:row.TotalCount?.Count}</span><br/>
                <span>Uncontacted:{row.ActiveCounts === undefined ? 0:row.ActiveCounts?.unconnected}</span><br/>
                <span>Delete Contact:{row.ActiveCounts === undefined ? 0:row.ActiveCounts?.DeletedContact}</span><br/>
                <span>Blacklisted:{row.ActiveCounts === undefined ? 0:row.ActiveCounts?.BlackedListed}</span><br/>
                <span>Paused:{row.ActiveCounts === undefined ? 0:row.ActiveCounts?.paused}</span><br/>
                <span>Replies:{row.ActiveCounts === undefined ? 0:row.ActiveCounts?.Replies}</span><br/>
                <span>Bounce:{row.ActiveCounts === undefined ? 0:row.ActiveCounts?.Bounces}</span><br/>
                <span>Active Contact:{row.ActiveCounts === undefined ? 0:row.ActiveCounts?.activecontact}</span>
                </>} placement="right">
              <span><i className="la flaticon-plus delete-icon"></i></span>
            </Tooltip></TableCell>
                <TableCell>{row.ReplyBehavior.length > 0?row.ReplyBehavior[0].FieldName:""}</TableCell>
                <TableCell>{row.IsIncludeBlacklistedContacts == true? "Yes": "No"}</TableCell>
                <TableCell>{row.EmailOpenTracking.length > 0?row.EmailOpenTracking[0].FieldName:""}</TableCell>
                <TableCell>{row.IsSendonWeekends == true? "Yes": "No"}</TableCell>
                <TableCell>{row.CampaignPriority.length > 0?row.CampaignPriority[0].FieldName:""}</TableCell> 
                <TableCell>{row.IsTrackClick == true? "Yes": "No"}</TableCell>
                <TableCell>{row.TotalTrackClick}</TableCell>
                <TableCell>{row?.IsArchive ? (
                      <a className="btn-eyesicon dopdownwe">
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => {
                            HandleClick(e, row?._id);
                          }}
                        >
                          <i className="la la-ellipsis-h add-icon"></i>
                        </Button>
                        <Menu
                          id={row._id}
                          anchorEl={AnchorEl}
                          keepMounted
                          open={Boolean(AnchorEl)}
                          onClose={HandleClose}
                        >
                          <MenuItem
                            onClick={() => {
                              HandleDelete(row._id);
                            }}
                          >
                            <i className="la flaticon-delete-1 mr-3"></i> Delete
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              HandleUnArchive(row._id,"UnArchive");
                            }}
                          >
                            {" "}
                            Unrchive
                          </MenuItem>
                        </Menu>
                      </a>
                    ) : (
                      <div>
                        <a
                          onClick={()=>{EditCampaign(row._id)}}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-edit-1 edit-icon"></i>
                        </a>
                        <a className="btn-eyesicon dopdownwe">
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              HandleClickOne(e, row?._id);
                            }}
                          >
                            <i className="la la-ellipsis-h add-icon"></i>
                          </Button>
                          <Menu
                            id={row._id}
                            anchorEl={AnchorElf}
                            keepMounted
                            open={Boolean(AnchorElf)}
                            onClose={HandleCloseOne}
                          >
                            <MenuItem onClick={(e)=>{ImportContact(e)}}><i className="la la-file mr-3"></i>Import Contacts</MenuItem>
                            <MenuItem onClick={(e)=>{SaveAsGlobal(e,"Client")}}><i className="fa fa-columns mr-3"></i>Save As Template</MenuItem>
                            <MenuItem onClick={(e)=>{SaveAsGlobal(e,"Global")}}><i className="fa fa-columns mr-3"></i>Save As Global Template</MenuItem>
                            <MenuItem onClick={(e)=>{DuplicateCampaign()}}><i className="la flaticon-add mr-3"></i>Duplicate</MenuItem>
                            <MenuItem
                              onClick={() => {
                                HandleUnArchive(row._id,"Archive");
                              }}
                            >
                              <i className="la flaticon-download mr-3"></i> Archive
                            </MenuItem>
                          </Menu>
                        </a>
                      </div>
                    )}</TableCell>
              </TableRow>
              </>
              ))
              )}
              
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Popup open={TemplatePopup}>
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup largerPopup1000">
                <div className="paddingboxTerms">
                  <div className="modal-header py-4 px-3">
                    <div className="w-100 d-flex px-3">
                      <h5 className="mb-0">Save campaign as {Type} template</h5>
                      <button
                        className="close"
                         onClick={HandleTemplatePopup}
                      >
                        <span aria-hidden="true" class="la la-remove"></span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="px-4">
                      <div class="row mb-4">
                        <label class="col-lg-3 col-form-label textright-vmb">
                          Name
                        </label>
                        <div class="col-lg-9 d-flex">
                          <input
                            class="form-control m-input"
                            type="text"
                            id="NameTemplate"
                          />
                        </div>
                      </div>

                      <div class="row mb-4">
                        <label class="col-lg-3 col-form-label textright-vmb">
                          Notes
                        </label>
                        <div class="col-lg-9">
                        <input
                            class="form-control m-input"
                            type="text"
                            id="NotesTemplate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={HandleTemplatePopup}
                    >
                      Cancel
                    </button>
                    <button
                      id="SubmitStepTemplate"
                      onClick={() => {
                        SaveTeplate();
                      }}
                      class="btn btn-primary btnColor"
                    >
                      <i class="la la-save mr-2"></i> Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>         
    </div>
    </MuiThemeProvider>
  );
}
