import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { CSVReader, CSVDownloader } from "react-papaparse";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsOldClient, SetIsOldClient] = React.useState(false);
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [CUserID, SetCUserID] = React.useState(0);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  useEffect(() => {
    document.title = "Clients | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    FetchDetailMethod(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      IsOldClient
    );
    ExportMethod(Details.ParentUserID, Details.ClientID, IsOldClient);
  }, [Search, Page, RowsPerPage, IsOldClient]);

  // Get Clients list
  const FetchDetailMethod = (CID, UID, URole, IsOldClients) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsOldClient: IsOldClients,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientsGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if (result.data.PageData === null) {
        SetData([]);
        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
      } else {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
      }
      SetIsLoading(false);
    });
  };

  //export Client details
  const ExportMethod = (UID, CID, OldClient) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      IsOldClient: OldClient,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientsExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetExportData(Res.data.Data);
      SetClientName(Res.data.ClientName.Name);
    });
  };

  const ExportCsv = () => {
    toast.success(<div>Data exported successfully.</div>);
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal.trim());
      SetPage(1);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };
  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);

    var SearchedVal = document.getElementById("search").value?.trim();
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        IsOldClient: IsOldClient,
        Search: SearchedVal,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clients/ClientsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SetIsLoading(true);

      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        IsOldClient: IsOldClient,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clients/ClientsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  // Advance sorting
  const AdvanceSearch = () => {
    let IsOldClients = document.getElementById("IsOldClients").checked;
    document.getElementById("search").value = "";
    SetSearch("");
    SetIsOldClient(IsOldClients);
  };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val._id).checked = true;
          var temArray = DeleteArray;
          temArray.push(val._id);
          SetDeleteArray(temArray);
        } else if (!e.target.checked) {
          document.getElementById(val._id).checked = false;
          DeleteArray.splice(val, 1);
        }
      });
    }
  };

  //Add list contact
  const CheckBoxAdd = (e, ClientID) => {
    if (e.target.checked) {
      var temArray = DeleteArray;
      temArray.push(ClientID);
      SetDeleteArray(temArray);
    } else if (!e.target.checked) {
      DeleteArray.splice(DeleteArray.indexOf(ClientID), 1);
    }
  };

  //Edit Button
  const EditBtn = (id) => {
    history.push("/editclients", id);
  };

  //AddPage
  const AddPage = () => {
    history.push("/addclients");
  };

  //Bulk Delete
  const BulkDelete = () => {
    if (DeleteArray.length != 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete a selected client.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          SetIsLoading(true);

          const InputParameter = {
            ClientIDs: DeleteArray,
            LastUpdatedDt: new Date(),
            LastUpdatedBy: CUserID,
          };

          Axios({
            url: CommonConstants.MOL_APIURL + "/clients/ClientDelete",
            method: "POST",
            data: InputParameter,
          }).then((Res) => {
            if ((Res.statusText = "Ok")) {
              FetchDetailMethod(ClientID, UserID, Role, IsOldClient);
              Swal.fire("Deleted!", "Client deleted successfully.", "success");
              DeleteArray.forEach((el) => {
                document.getElementById(el).checked = false;
              });
              //history.push('/clients');
              SetDeleteArray([]);
              SetIsLoading(false);
              window.location.reload();
            } else {
              toast.error(Res.data.Message);
              SetIsLoading(false);
            }
            SetIsLoading(false);
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
          SetIsLoading(false);
        }
      });
    } else {
      toast.error(
        <div className="toastsize">
          Please select atleast one client from list.
        </div>
      );
      SetIsLoading(false);
    }
  };

  // Add this function somewhere in your component or scope
  const handleCheckboxChangeEmod = async (ClientId, Flag) => {
    var Str_in = {
      IsAllowEmod: !Flag,
      ClientID: ClientId,
    };
    const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientEmodUpdate",
      method: "POST",
      data: Str_in,
    });
    if (rows1.data.StatusMessage == "SUCCESS") {
      toast.success(
        <div>
          Client
          <br />
          Client eMod activated successfully
        </div>
      );
      FetchDetailMethod(ClientID, UserID, Role, IsOldClient);
    }
  };

  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left pl-0">Clients</h4>
        </div>
        <div className="col border-bottom pl-4">
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <CSVDownloader
                  className="px-0"
                  data={ExportData}
                  filename={`${ClientName}-Client`}
                  bom={true}
                >
                  <a
                    onClick={ExportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i className="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                </CSVDownloader>
              </li>
              <li>
                <a
                  onClick={() => {
                    BulkDelete();
                  }}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i className="la flaticon-delete-1"></i>
                  <span>Delete</span>
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    AddPage();
                  }}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i className="la la-icon-font-size-13 la-plus"></i>
                  <span>Add</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="whiterow w-100 pt-4">
          <div className="full-right px-4 pt-0 pb-3 d-flex right-texts">
            <div className="row">
              <div className="col col-lg-8">
                <div className="boxborders">
                  <label className="check_bx">
                    <input type="checkbox" id="IsOldClients" />
                    <span className="checkmark"></span>
                    Include Old Clients
                  </label>
                </div>
              </div>

              <div className="col col-lg-4">
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    AdvanceSearch();
                  }}
                  className="btn btn-primary btnColor"
                >
                  <i className="la flaticon-search mr-1"></i>Search
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>eMod</TableCell>
                <TableCell>
                  <input
                    name="select_all_contacts"
                    type="checkbox"
                    onChange={(e) => CheckHandler(e)}
                  />
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Name");
                    }}
                  >
                    Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ResponderString");
                    }}
                  >
                    Responder
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("SalesHackerString");
                    }}
                  >
                    Sales Strategist
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ColdCallersString");
                    }}
                  >
                    Cold Caller
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientStatus");
                    }}
                  >
                    Client Status
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientBillingMethod");
                    }}
                  >
                    Client Billing Method
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CreatedDt");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("NumberOfAccounts");
                    }}
                  >
                    No. of Accounts
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientStartDate");
                    }}
                  >
                    Client Start Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientEndDate");
                    }}
                  >
                    Client End Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("BillingContact");
                    }}
                  >
                    Client Billing Contact
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("BillingEmail");
                    }}
                  >
                    Client Billing Email
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("BillingAddress");
                    }}
                  >
                    Client Billing Address
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TargetAccountsperQtr");
                    }}
                  >
                    Target Accounts per Qtr
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MeetingValue");
                    }}
                  >
                    Default Contract Value
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Website");
                    }}
                  >
                    Website
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ReasonLostName");
                    }}
                  >
                    Reason Lost
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("LogoApproval");
                    }}
                  >
                    Logo Approval
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ISAddMeetingsToBlacklist");
                    }}
                  >
                    Add Meetings To Blacklist
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientBillingStatus");
                    }}
                  >
                    Billing Status
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("BillingAmount");
                    }}
                  >
                    Billing Amount
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientBillingPeriod");
                    }}
                  >
                    Billing Period
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AvgMonthlyRevenue");
                    }}
                  >
                    Avg Monthly Revenue
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("PPMEstMonths");
                    }}
                  >
                    PPM Est Months
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("BillingDate");
                    }}
                  >
                    Billing Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("LastLoginDate");
                    }}
                  >
                    Last Login Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TrackDomain");
                    }}
                  >
                    Track Domain
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("BCCEmail");
                    }}
                  >
                    BCC Email
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("IsTrackDomainWorking");
                    }}
                  >
                    Track Domain Working
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("SalesRepName");
                    }}
                  >
                    Closed By
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("SlackChannelInternal");
                    }}
                  >
                    Slack Channel Internal
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("SlackChannelExternal");
                    }}
                  >
                    Slack Channel External
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("IsSentMeetingRescheduleNotification");
                    }}
                  >
                    Is Sent Meeting Reschedule Notification
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("IsSentMeetingCreateNotification");
                    }}
                  >
                    Is Sent Meeting Create Notification
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ServerNumber");
                    }}
                  >
                    Server Number
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id} row={row}>
                    <TableCell>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                    </TableCell>
                    <TableCell>
                      <div className="toggleswich">
                        <input
                          type="checkbox"
                          className="checktogle"
                          id="IsUpdateExistContacts"
                          checked={row.IsAllowEmod || false}
                          onChange={() =>
                            handleCheckboxChangeEmod(row._id, row.IsAllowEmod)
                          }
                        />
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>
                    </TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        id={row._id}
                        onChange={(e) => CheckBoxAdd(e, row._id)}
                      />
                    </TableCell>
                    <TableCell
                      className="labeltds d-flex align-items-center"
                      scope="row"
                    >
                      <div className="carduser_pic">
                        <img
                          style={imgstyle}
                          src={
                            row.ProfileImage == ""
                              ? CommonConstants.Image_url + "default.png"
                              : CommonConstants.Image_url + row.ProfileImage
                          }
                        ></img>
                      </div>
                      {row.Name}
                    </TableCell>
                    <TableCell> {row?.ResponderString}</TableCell>
                    <TableCell>{row?.SalesHackerString}</TableCell>
                    <TableCell>{row?.ColdCallersString}</TableCell>
                    <TableCell>{row.ClientStatus}</TableCell>
                    <TableCell>{row.ClientBillingMethod}</TableCell>
                    <TableCell>
                      {row.CreatedDt != null
                        ? moment(new Date(row.CreatedDt).toDateString()).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </TableCell>
                    <TableCell>{row.NumberOfAccounts}</TableCell>
                    <TableCell>
                      {" "}
                      {row.ClientStartDate != null
                        ? moment(
                            new Date(row.ClientStartDate).toDateString()
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {row.ClientEndDate != null
                        ? moment(
                            new Date(row.ClientEndDate).toDateString()
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>{row.BillingContact}</TableCell>
                    <TableCell>{row.BillingEmail}</TableCell>
                    <TableCell>{row.BillingAddress}</TableCell>
                    <TableCell>{row.TargetAccountsperQtr}</TableCell>
                    <TableCell>{row.MeetingValue}</TableCell>
                    <TableCell>{row.Website}</TableCell>
                    <TableCell>{row.ReasonLostName}</TableCell>
                    <TableCell>{row.LogoApproval}</TableCell>
                    <TableCell>
                      {row.ISAddMeetingsToBlacklist ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{row.ClientBillingStatus}</TableCell>
                    <TableCell>{row.BillingAmount}</TableCell>
                    <TableCell>{row.ClientBillingPeriod}</TableCell>
                    <TableCell>{row.AvgMonthlyRevenue}</TableCell>
                    <TableCell>{row.PPMEstMonths}</TableCell>
                    <TableCell>
                      {row.BillingDate != null
                        ? moment(
                            new Date(row.BillingDate).toDateString()
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {row.LastLoginDate != null
                        ? moment(
                            new Date(row.LastLoginDate).toDateString()
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>{row.TrackDomain}</TableCell>
                    <TableCell>{row.BCCEmail}</TableCell>
                    <TableCell>
                      {row.IsTrackDomainWorking ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{row.SalesRepName}</TableCell>
                    <TableCell>{row.SlackChannelInternal}</TableCell>
                    <TableCell>{row.SlackChannelExternal}</TableCell>
                    <TableCell>
                      {row.IsSentMeetingRescheduleNotification ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row.IsSentMeetingCreateNotification ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{row.ServerNumber}</TableCell>
                    <TableCell>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </>
  );
}
