import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import ContactBulkImportRequest from '../../_components/user/Tools/ContactBulkImportRequest';
//import CollapsibleTable from '../_components/usertable';



class ContactBulkImportRequestPage extends React.Component {
   componentDidMount(){
    document.title = "Contact Bulk Import Request | SalesHive"
   }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlenop float-left px-1 py-4">Contact Bulk Import Request</h4>
                        </div>
                        </div>

                    <div className="px-3 whiterow pt-4">
                        

                        <div className="row px-3 pb-5">
                            <div className="col">
                                    <ContactBulkImportRequest />
                            </div>
                        </div>      

                       
                    </div>

                </div> 
                </div> 
                <Footer/>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedContactBulkImportRequestPage = connect(mapState, actionCreators)(ContactBulkImportRequestPage);
export { connectedContactBulkImportRequestPage as ContactBulkImportRequestPage };