import React,{useEffect} from 'react';
import Axios from "axios";
const moment=require('moment');
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Paper from '@material-ui/core/Paper';
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ReactFusioncharts from "react-fusioncharts";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function Contactsdetailstable({
  FromChildDatesEmail,
  ToChildDatesEmail,
}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ResponseCategory, SetResponseCategory] = React.useState([]);
  const [ClientName,SetClientName]=React.useState("");
  const [ExportData,SetExportData]=React.useState([]);
  const [ResponseCategoryUpdated,SetResponseCategoryUpdated]=React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [BodyDataPlain, setBodyDataPlain] = React.useState([]);
  const [PieChartData,SetPieChartData] = React.useState([]);

  const [FromDate,SetFromDate] = React.useState("");
  const [ToDate,SetToDate] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const classes = useRowStyles();

  useEffect(() => {

    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDate(FromChildDatesEmail)
    SetToDate(ToChildDatesEmail)
    FetchDetailMethod(Details.ClientID,Details.ParentUserID,Details.Role,FromChildDatesEmail,ToChildDatesEmail);
    ExportMethod(Details.ParentUserID,Details.ClientID,FromChildDatesEmail,ToChildDatesEmail,Details.Role)

  }, [Search, Page, RowsPerPage,FromChildDatesEmail,ToChildDatesEmail]);

    // Get Clients list
    const FetchDetailMethod = (CID,UID,URole,FDate,TDate) => {
      SetIsLoading(true)

      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        Role: URole,
        Fromdate:FDate,
        Todate:TDate
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis_email/KpisEmailGet",
        method: "POST",
        data: InputParameter,
      });
      rows1.then((result) => {
        
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetResponseCategory(result.data.ResponseCategory);
        setBodyDataPlain(result.data.ResponseMessage);
        const groupedCounts = result.data.PieChartData.reduce((acc, item) => {
          const { ResponseCategoryID, Status } = item;
          const key = `${ResponseCategoryID}-${Status}`;
          if (acc[key]) {
            acc[key].value++;
          } else {
            acc[key] = { label: Status, value: 1 };
          }
          return acc;
        }, {});
        
        const CountData = Object.values(groupedCounts);
        var CountData1 = {
          chart: {
            caption: "Response Status",
            //subcaption: "For a net-worth of $1M",
            theme: "fusion",
            subCaption: " ",
            formatnumberscale: "0",
            showLegend: "1",
            showLabels: "0",
            showValues: "1",
            valuePosition: "inside",
            numbersuffix: "%",
            plothighlighteffect: "false",
            legendcaptionbold: "1",
            legendcaptionfontsize: "15",
            legendCaptionFontColor: "#000",
            showPercentValues: "0",
            showPercentInToolTip: "0",
            enableSlicing: "1",
            legendPosition: "right",
            legendCaption: "Meeting Status: ",
            legendBgColor: "#FFF",
            legendBorderColor: "#ffF",
            legendShadow: "0",
            legendItemFontSize: "14",
            legendWidth: "300", 
          },
          data: CountData,
        };
        
        SetPieChartData(CountData1);
      SetIsLoading(false)
      });
    };

      // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);

    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);

  };

 // change page
 const HandleChangePage = (Event, NewPage) => {
  SetPage(NewPage);

};
   //get sort field data
const SortData = (Field) => {
  SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        Fromdate:FromDate,
        Todate:ToDate

      };
      const AccountCategoriesList = Axios({
        url:CommonConstants.MOL_APIURL +"/kpis_email/KpisEmailGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetResponseCategory(Result.data.ResponseCategory);
      SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        Fromdate:FromDate,
        Todate:ToDate

      };
      const AccountCategoriesList = Axios({
        url:CommonConstants.MOL_APIURL +"/kpis_email/KpisEmailGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetResponseCategory(Result.data.ResponseCategory);
      SetIsLoading(false)

      });
    }
};

//Update on change option
const ResponseCategoryselect = (event, ID,Domain,EmailOfAccount) => {
  const {options, selectedIndex} = event.target;
  const Arr=ResponseCategoryUpdated;
  const ResponseCategoryChanges ={
    MessageID:ID,
    Domain:Domain,
    EmailAdd:EmailOfAccount,
    StatusVal:event.target.value,
    StatusText:options[selectedIndex].innerHTML,
    UserID:UserID,
    ClientID:ClientID,
    LastUpdatedBy:CUserID,
    LastUpdatedDate:new Date()

  }
  Arr.push(ResponseCategoryChanges)
  SetResponseCategoryUpdated(Arr)


  //
  //

  // //update in Response category Update
  // var InputParameter = {
  //   _id: id,
  //   ResponseCategoryID: event.target.value,
  //   LastUpdatedBy: UserID,
  //   LastUpdatedDate: new Date(),
  // };
  // Axios({
  //   url:
  //     CommonConstants.MOL_APIURL +"/linkedinmeeting/UpdateLinkedinMeetingdropdown",
  //   method: "POST",
  //   data: InputParameter,
  // }).then((res) => {
  //
  //   if (res.data.StatusMessage == "SUCCESS") {
  //     window.location.reload(true);

  //     toast.success(
  //       "Potential meeting select option status update successfully.",
  //       "Update Potential select option  meeting status"
  //     );
  //
  //       "successfull Update Potential meeting select option ",
  //       res.data
  //     );
  //   } else {
  //     toast.error(res.data.Message);
  //   }
  // });
};

const SaveAll = ()=>{
  const InputParameter={
    ValueObject:ResponseCategoryUpdated,
    Role:Role
  }
     Axios({
          url:CommonConstants.MOL_APIURL +"/kpis_email/KpisDelete",
          method: "POST",
          data: InputParameter,
        }).then((res) => {

          if (ResponseCategoryUpdated.length > 0) {
            if (res.data.StatusMessage == 'SUCCESS') {
              toast.success("Response category changed successfully.");
              FetchDetailMethod(ClientID,UserID,FromDate,ToDate)
            } else {
              toast.error(res.data.message);
            }
          }else{
            toast.error("Please change at least one Response category from list.");
          }
        });
}
  //export Client details
  const ExportMethod = (UID, CID,Fromdate,Todate,role) => {
    const InputParameters = {
          UserID: UID,
          ClientID: CID,
          Fromdate:Fromdate,
          Todate:Todate,
          Role:role
      }
      Axios({
          url: CommonConstants.MOL_APIURL + "/kpis_email/KpisExport",
          method: "POST",
          data: InputParameters
      }).then((Res) => {

        SetExportData(Res.data.Data);
        SetClientName(Res.data.ClientName.Name);

      });
  }

 const ExportCsv=() =>{
  if(ExportData.length > 0 ){
    toast.success("Data exported successfully.");
  }else{
    toast.error("No data available!");
  }
  }

  // delete responses details
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to permanently delete this email from your email account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var Inputparameters = {
          MessageID: ID,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: CUserID,
          Role:Role
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +"/kpis_email/KpisDelete",
          method: "POST",
          data: Inputparameters,
        }).then((res) => {

          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Response Message delete successfully.",
                "success"
              );
              FetchDetailMethod(ClientID,UserID,FromChildDatesEmail,ToChildDatesEmail)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Update Attention status
  const UpdateAttention =(MessageID,Status)=>{
    const InputParameter={
      MessageID:MessageID,
      IsAttention:Status,
      LastUpdatedDt:new Date(),
      LastUpdatedBy:CUserID,
      Role:Role
    }
       Axios({
            url:CommonConstants.MOL_APIURL +"/kpis_email/IsAttentionUpdate",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
              if ((res.data.StatusMessage = "SUCCESS")) {
                toast.success(
                  "Message attention status changed successfully.",
                  "Emails"
                );
                FetchDetailMethod(ClientID,UserID,FromDate,ToDate)
              } else {
                toast.error(res.data.Message);
              }
          });
  }

  //Get response message
  const GetResponseMessage=(MessageID)=>{

    const InputParameter={
      MessageID:MessageID,
      Role:Role,

    }
       Axios({
            url:CommonConstants.MOL_APIURL +"/kpis_email/ResponseMessageGet",
            method: "POST",
            data: InputParameter,
          }).then((res) => {

              if ((res.data.StatusMessage = "SUCCESS")) {
                let Arr = BodyDataPlain;
                const Found = Arr.some(el=>el.SalesReply_MessageID === res.data.Data.SalesReply_MessageID )
               if(!Found){
                 Arr.push(res.data.Data)
                 setBodyDataPlain(Arr);
               }


              } else {
                toast.error(res.data.Message);
              }
          });
  }
  const dataSource = {
    chart: {
      caption: "Response Status",
      showvalues: "1",
      showpercentintooltip: "0",
      numbersuffix: "%",
      showlegend: "1",
      plothighlighteffect: "fadeout|color=#fff, alpha=60",
      legendcaptionbold: "1",
      legendcaptionfontsize: "16",
      //numberprefix: "$",
      enablemultislicing: "1",
      theme: "fusion",
    },
    data:PieChartData,
  };
  return (
    <>
    {IsLoading ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></>:<></>}
      <div className="cardbox col border-y mt-3">
            <div className="row"> 
              <div className="col brdr-left minheight">
                <div className="chartboxsmet">
                  <ReactFusioncharts
                    key={JSON.stringify(PieChartData)}
                    type="pie3d"
                    width="100%"
                    height="100%"
                    dataFormat="JSON"
                    dataSource={PieChartData}
                  />
                </div>
              </div>
            </div>
          </div>

<div className='cardbox mt-3 pb-3'>
    <div className="row">
            <div className="col border-bottom">
              <h4 className="headertitle float-left">Response Details</h4>
              <div className="listing-li float-right padb-15">
                <ul>
                  <li>
                    <a onClick={()=>{SaveAll()}} className="btn btngroup btn-lightgreen">
                      <i class="la la-icon-font-size-13 la la-save"></i>
                      <span>Save All Changes</span>
                    </a>
                  </li>
                  <li>
                  <a onClick={()=>ExportCsv()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air" >
                      <CSVDownloader className='px-0'
                      data={ExportData}
                      filename={`${ClientName}-ResponsesDetail`}
                      bom={true}
                    >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                          
                            Export
                         
                        </span>
                        </CSVDownloader>
                      </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>



     <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <a onClick={()=>{SortData("IsAttention")}}><SwapVertIcon/></a>
            </TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("EmailAdd");
                  }}
                  >From</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("Name");
                  }}
                  >Contact Name</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("EmailOfAccount");
                  }}
                  >Email Account</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("AccountName");
                  }}
                  >Account Name</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("CampaignName");
                  }}
                  >Campaign Name</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("StepNumber");
                  }}
                  >Step Number</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("Subject");
                  }}
                  >Subject</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("MsgDateTime");
                  }}
                  >Response date</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("ResponseCategoryID");
                  }}
                  >Response Category</a></TableCell>
            <TableCell><a
                  onClick={() => {
                    SortData("Snippet");
                  }}
                  >Snippet</a></TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.map((row) => (
            <>
             <TableRow key={row.MessageID} row={row}>
             <TableCell align="center">
             <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>{
                          // GetResponseMessage(row.MessageID)
                          setOpen((prev) => ({
                            ...prev,
                            [row.MessageID]: !prev[row.MessageID],
                          }))

                          // if(open[row.MessageID] == true){

                          // }
                        }
                        }
                      >
                        {open[row.MessageID] ? (
                          <i
                            className="fa fa-minus-circle plusbl"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-plus-circle minusl"
                            aria-hidden="true"
                          ></i>
                        )}
                      </IconButton>
             </TableCell>
            <TableCell>
              {
               row.IsAttention ?
                  <a onClick={()=>{UpdateAttention(row.MessageID,false)}}><StarIcon color="secondary" /></a>:
                  <a onClick={()=>{UpdateAttention(row.MessageID,true)}}><StarBorderIcon color="primary" /></a>
              }

              </TableCell>
            <TableCell>{row.EmailAdd}</TableCell>
            <TableCell>{row.Name}</TableCell>
            <TableCell>{row.EmailOfAccount}</TableCell>
            <TableCell>{row.AccountName}</TableCell>
            <TableCell>{row.CampaignName}</TableCell>
            <TableCell>{row.StepNumber}</TableCell>
            <TableCell>{row.Subject}</TableCell>
            <TableCell>{moment(new Date(row.MsgDateTime).toDateString()).format("MM/DD/YYYY")}</TableCell>
            <TableCell className="labeltds" scope="row">
                      <select
                        onChange={(e) => {
                          ResponseCategoryselect(e, row.MessageID,row.Domain,row.EmailOfAccount);
                        }}
                        className="form-control  m-input"
                        id="ResponseCategory"
                        name="ResponseCategory"
                        autoComplete="off"
                        value={row.ResponseCategoryID}
                      >
                        {/* <option value="">--Select--</option> */}
                        {ResponseCategory.map((value) => (

                          <option value={value.ResponseCategoryID}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                    </TableCell>
            <TableCell>{row.Snippet}</TableCell>
            <TableCell> <a onClick={() => {
                          DeleteBtn(row.MessageID);
                        }} className="btn-eyesicon"><i class="la flaticon-delete-1 delete-icon"></i></a></TableCell>
            </TableRow>
            <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={8}
                    >
                      <Collapse in={open[row.MessageID]} timeout="auto" unmountOnExit>
                        {open[row.MessageID] && (
                          <Box margin={1} className="innertables">
                            <Table size="small" aria-label="purchases">
                            <TableHead></TableHead>
                              {BodyDataPlain.map((val) =>val.SalesReply_MessageID == row.MessageID ? (
                                  <TableRow>
                                    {/* <TableCell>{note.Title}</TableCell> */}
                                    <TableCell scope="row">
                                      {val.BodyDataPlain}
                                    </TableCell>

                                  </TableRow>
                                ) : (
                                  <></>
                                )
                              )}



                            </Table>
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
            </>


          ))}

        </TableBody>
      </Table>
    </TableContainer>
    </div>
    {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </>
  );
}

