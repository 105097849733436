import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

class AddResearchPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      fields: {},
      errors: {},
      ProjectStatusList: [],
      ResearchTeamList: [],
      CostPerHours: 0,
      Hours: 0,
      CostPerContact: 0,
      ContactsFound: 0,
      IsButtonDisabled: false,
    };
    this.GetProjectStatusDropDown();
    this.GetResearchTeamGetDropDown();
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Add Research | SalesHive";
  }

  //Get list of Project Status Drop Down
  GetProjectStatusDropDown() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ProjectStatusGet",
      method: "POST",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          _id: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ ProjectStatusList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // Get Research Team drop done
  GetResearchTeamGetDropDown() {
    const InputParameters = {
      // ClientID: this.state.ClientID,
      UserID: this.state.UserID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ResearchTeamGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((value) => ({
          _id: value._id,
          Name: value.Name,
        }));
        this.setState({ ResearchTeamList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  async FormValidation() {
    const { fields, errors } = this.state;
    let formIsValid = true;
    let ProjectStatusID = fields["ProjectStatusID"];
    let updatedErrors = { ...errors };

    if (!fields["ProjectName"]) {
      formIsValid = false;
      updatedErrors["ProjectName"] = "Please enter project name";
    } else {
      updatedErrors["ProjectName"] = ""; // Clear error if valid
    }

    if (!fields["RequestedDate"]) {
      formIsValid = false;
      updatedErrors["RequestedDate"] = "Please select requested date";
    } else {
      updatedErrors["RequestedDate"] = ""; // Clear error if valid
    }

    if (!ProjectStatusID || ProjectStatusID <= 0) {
      formIsValid = false;
      updatedErrors["ProjectStatusID"] = "Please select project status";
    } else {
      updatedErrors["ProjectStatusID"] = ""; // Clear error if valid
    }

    if (!fields["ResearchTeamID"] || this.state.CostPerHours === 0) {
      formIsValid = false;
      updatedErrors["ResearchTeamID"] = "Please select research team";
    } else {
      updatedErrors["ResearchTeamID"] = ""; // Clear error if valid
    }

    this.setState({ errors: updatedErrors });
    return formIsValid;
  }

  updateFieldError(fieldName, errorMessage) {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: errorMessage,
      },
    }));
  }

  async fetchCostAndCalculate(ResearchTeamID, ContactsFound, hours) {
    try {
      const InputParameters = { ResearchTeamID };
      const response = await Axios.post(
        CommonConstants.MOL_APIURL + "/research/CostPerHourResearchTeam",
        InputParameters
      );

      if (response.data.StatusMessage === "SUCCESS") {
        const CostPerHours = response.data.Data?.CostPerHour || 0;
        this.setState({ CostPerHours });

        ContactsFound = Math.max(parseFloat(ContactsFound), 0);
        const parsedHours = parseFloat(hours);
        // (Total Hours * CostPerHours --> fetching from research team) / ContactsFournd
        let CalculationOfCost = (parsedHours * CostPerHours) / ContactsFound;
        CalculationOfCost = isFinite(Number(CalculationOfCost))
          ? CalculationOfCost
          : 0;
        this.setState({ CostPerContact: CalculationOfCost });
      } else {
        toast.error(response.data.Message);
      }
    } catch (error) {
      console.error("Error fetching cost:", error);
    }
  }

  //ResearchTeam set state

  ResearchTeamState(field, e) {
    const { fields } = this.state;
    const newValue = e.target.value;
    fields[field] = newValue;
    this.setState({ fields });

    if (field === "ResearchTeamID") {
      this.updateFieldError("ResearchTeamID", ""); // Clear error on interaction

      if (newValue === "0") {
        this.setState({ CostPerHours: 0 });
      } else if (newValue !== "") {
        this.fetchCostAndCalculate(
          newValue,
          fields.ContactsFound,
          fields.Hours
        );
      }
    }
  }

  // HandleChange
  HandleChange(field, e) {
    const { fields } = this.state;
    const newValue = e.target.value;
    fields[field] = newValue;
    this.setState({ fields });
    this.FormValidation();
    if (field === "ContactsFound" || field === "Hours") {
      this.updateFieldError("ContactsFound", ""); // Clear error on interaction
      this.updateFieldError("Hours", ""); // Clear error on interaction

      var ContactsFound = document.getElementById("ContactsFound").value;

      const parsedContactsFound = parseFloat(ContactsFound);

      const parsedHours = parseFloat(fields["Hours"]);

      const CostPerHour = this.state.CostPerHours;

      let CalculationOfCost = (parsedHours * CostPerHour) / parsedContactsFound;

      // Check if CalculationOfCost is a finite number
      CalculationOfCost = isFinite(Number(CalculationOfCost))
        ? CalculationOfCost
        : 0;

      // Round CalculationOfCost to 2 decimal places
      CalculationOfCost = this.formatNumber(CalculationOfCost);

      this.setState({ CostPerContact: CalculationOfCost });
    }
  }

  formatNumber = (number) => {
    // Check if the number is 0.8
    if (number === 0.8) {
      return number.toFixed(2); // Add trailing zero if the number is 0.8
    } else {
      return parseFloat(number.toFixed(2)); // Round other numbers to 2 decimal places
    }
  };

  BackBtn() {
    history.push("/research");
  }

  ContactFoundHandler(e) {
    let Errors = this.state.errors;
    if (e.target.value < 0) {
      Errors["ContactFound"] =
        "Please enter a value greater than or equal to 1.";
    } else if (e.target.value == 0) {
      Errors["ContactFound"] = null;
    } else {
      Errors["ContactFound"] = null;
    }
    this.setState({ ContactsFound: e.target.value });

    this.setState({ errors: Errors });
  }

  HoursHandler(e) {
    let Errors = this.state.errors;
    this.setState({ Hours: e.target.value });
    if (e.target.value <= 0) {
      Errors["Hours"] = "Please enter a value greater than or equal to 1.";
    } else {
      Errors["Hours"] = null;
    }
    this.setState({ errors: Errors });
  }

  // Save Data
  async SaveBtn(e) {
    e.preventDefault();
    // e.currentTarget.disabled = true;
    
    this.setState({ IsButtonDisabled: true });
    var validation = await this.FormValidation();
    if (validation === true) {
      var ProjectName = document.getElementById("ProjectName").value;
      var CompletedBy = document.getElementById("CompletedBy").value;
      var RequestedDate = document.getElementById("RequestedDate").value;
      var FinishedDate = document.getElementById("FinishedDate").value;
      var Hours = document.getElementById("Hours").value;
      var ProjectStatusID = document.getElementById("ProjectStatusID").value;
      var ContactsFound = document.getElementById("ContactsFound").value;
      var ResearchTeamID = document.getElementById("ResearchTeamID").value;
      var Notes = document.getElementById("Notes").value;
      if (new Date(FinishedDate) < new Date(RequestedDate)) {
        this.setState({ IsButtonDisabled: false });
        toast.error(
          <div className="taostsize">
            Finish date must be equal or greater than requested selected date.
          </div>
        );
      } else {
        var InputParameter = {
          ClientID: this.state.ClientID,
          UserID: this.state.UserID,
          ProjectName: ProjectName,
          CompletedBy: CompletedBy,
          RequestedDate: RequestedDate,
          FinishedDate: FinishedDate,
          Hours: Hours,
          ProjectStatusID: ProjectStatusID,
          ContactsFound: ContactsFound,
          ResearchTeamID: ResearchTeamID,
          IsDeleted: false,
          Notes: Notes,
          CreatedBy: this.state.CUserID,
          CreatedDate: new Date(),
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/research/ResearchAdd",
          method: "POST",
          data: InputParameter,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div>
                Research Add
                <br />
                Research added successfully.
              </div>
            );
            history.push("/research");
          } else {
            this.setState({ IsButtonDisabled: false });
            toast.error(res.data.Message);
          }
        });
      }
    } else {
      this.setState({ IsButtonDisabled: false });
      e.currentTarget.disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left pt-4 mb-0">
                  Add Research
                </h4>
              </div>
            </div>

            <div className="bg-white">
              <div className="row pt-3 px-3">
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">
                      Project Name
                    </label>
                    <div className="col-lg-8 px-4 mx-2">
                      <input
                        className="form-control m-input"
                        id="ProjectName"
                        name="ProjectName"
                        placeholder="Enter project name"
                        type="text"
                        onChange={this.HandleChange.bind(this, "ProjectName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ProjectName"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols px-4">
                    <label className="col-lg-4 view-lable-meet">
                      Needs to be Completed <br />
                      By
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="CompletedBy"
                        name="CompletedBy"
                        placeholder=""
                        type="date"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">
                      Date Requested
                    </label>
                    <div className="col-lg-8 px-4 mx-2">
                      <input
                        className="form-control m-input"
                        id="RequestedDate"
                        name="RequestedDate"
                        placeholder=""
                        type="date"
                        //defaultValue={new Date().toISOString().substr(0, 10)}
                        onChange={this.HandleChange.bind(this, "RequestedDate")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["RequestedDate"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group  px-4">
                  <div className="row max-cols px-4">
                    <label className="col-lg-4 view-lable-meet">
                      Date Finished
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="FinishedDate"
                        name="FinishedDate"
                        placeholder=""
                        type="date"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">
                      Project Status
                    </label>
                    <div className="col-lg-8 px-4 mx-2">
                      <select
                        className="form-control  m-input"
                        id="ProjectStatusID"
                        name="ProjectStatusID"
                        onChange={this.HandleChange.bind(
                          this,
                          "ProjectStatusID"
                        )}
                      >
                        <option value="0">--Select--</option>
                        {this.state.ProjectStatusList.map((Value) => (
                          <option key={Value._id} value={Value._id}>
                            {Value.FieldName}
                          </option>
                        ))}
                      </select>

                      <span style={{ color: "red" }}>
                        {this.state.errors["ProjectStatusID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols px-4">
                    <label className="col-lg-4 view-lable-meet">
                      Research Team
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ResearchTeamID"
                        name="ResearchTeamID"
                        onChange={this.ResearchTeamState.bind(
                          this,
                          "ResearchTeamID"
                        )}
                      >
                        <option value="0">--Select--</option>
                        {this.state.ResearchTeamList.map((Value) => (
                          <option key={Value._id} value={Value._id}>
                            {Value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ResearchTeamID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Hours</label>
                    <div className="col-lg-8 px-4 mx-2">
                      <input
                        className="form-control m-input"
                        id="Hours"
                        name="Hours"
                        placeholder="Enter hours"
                        type="number"
                        min="0"
                        onChange={this.HandleChange.bind(this, "Hours")}
                        onBlur={(e) => {
                          this.HoursHandler(e);
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Hours"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols px-4">
                    <label className="col-lg-4 view-lable-meet">
                      Contacts Found
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="ContactsFound"
                        name="ContactsFound"
                        placeholder="Enter contacts found"
                        type="number"
                        min="0"
                        onChange={this.HandleChange.bind(this, "ContactsFound")}
                        onBlur={(e) => {
                          this.ContactFoundHandler(e);
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactFound"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols align-items-center">
                    <label className="col-lg-3 view-lable-meet">
                      Cost per Contact
                    </label>
                    <div className="col-lg-8 px-4 mx-2  ">
                      {this.state.CostPerContact}
                      {/* <input
                        className="form-control m-input"
                        id="Name"
                        name="Name"
                        placeholder="Enter Quantity"
                        type="text"
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 border-top border-bottom my-3 mt-4">
                  <h4 className="headertitlepad float-left px-2">Notes</h4>
                </div>

                <div className="col-lg-12 boxsinput_group pb-4 px-4">
                  <textarea
                    id="Notes"
                    className="form-control m-input"
                    type="text"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row mb-3 py-4">
              <div className="col-lg-12 pull-left px-4">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  className="btn btn-primary btn-lightgreen mr-1"
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  className="btn btn-secondary"
                >
                  <i className="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDirectMailPage = connect(
  mapState,
  actionCreators
)(AddResearchPage);
export { connectedAddDirectMailPage as AddResearchPage };
