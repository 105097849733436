import React , { useEffect }from 'react';
import PropTypes from 'prop-types';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import KpisAllTable from './ClientKpisAllTable';
import ClientLeaderboardTabing from './ClientLeaderboardTabing';
import { GetUserDetails } from "../../../_helpers/Utility";

import { CommonConstants } from "../../../_constants/common.constants";
import ReactFusioncharts from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";

import loadingicon from "../../../images/loading.gif";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({ FromDates, ToDates, IsIncludeOldClient }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  
  const [UserID, SetUserID] = React.useState(0);
  const [SourceData, SetSourceData] = React.useState();
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [AverageResponseRate, SetAverageResponseRate] = React.useState(0);
  const [AverageResponsetoMeetingRate, SetAverageResponsetoMeetingRate] = React.useState(0);
  const [AverageMeetingRate, SetAverageMeetingRate] = React.useState(0);
  const [ReplyRate, SetReplyRate] = React.useState(0);
  const [ToatlResponsetoMeetingRate, SetToatlResponsetoMeetingRate] = React.useState(0);
  const [FromDate, SetFromDate] = React.useState("");
  const [ToDate, SetToDate] = React.useState("");
  const [IsIncludeOldClients, SetIsIncludeOldClients] = React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)

  
  useEffect(() => {
    SetFromDate(FromDates)
    SetToDate(ToDates)
    SetIsIncludeOldClients(IsIncludeOldClient)
    var Details = GetUserDetails();
    if (Details != null) {
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
    }
    
    
    FromDates = FromDates.replace(/-/g, '/');
    ToDates = ToDates.replace(/-/g, '/');
    GraphSourceGet(Details.ParentUserID, FromDates, ToDates, IsIncludeOldClient);
    OverViewTopKeyMatricsGet(Details.ParentUserID, FromDates, ToDates, IsIncludeOldClient);
  }, [FromDates, ToDates, IsIncludeOldClient]);

  


 
  const GraphSourceGet = (UserID, FromDates, ToDates,IsIncludeOldClient) => {
    
    const InputParameters = {
      UserID: UserID,
      ToDate: ToDates,
      FromDate: FromDates,
      IncludeOldClient:IsIncludeOldClient
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientKpiOverVieGraph",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      if(Res.data.StatusMessage=="SUCCESS")
      {
      
        if(Res.data.Data.length>0)
        {
         
          var dataStatus = {
            chart: {
              caption: "Meeting Status",
              theme: "fusion",
              subCaption: " ",
              formatnumberscale: "0",
              showLegend: "1",
              showLabels: "0",
              showValues: "1",
              valuePosition: "inside",
              numbersuffix: "%",
              plothighlighteffect: "false",
              legendcaptionbold: "1",
              legendcaptionfontsize: "15",
              legendCaptionFontColor: "#000",
              showPercentValues: "0",
              showPercentInToolTip: "0",
              enableSlicing: "1",
              legendPosition: "right",
              legendCaption: "Meeting Status: ",
              legendBgColor: "#FFF",
              legendBorderColor: "#ffF",
              legendShadow: "0",
              legendItemFontSize: "14",
              legendWidth: "300", 
            },
            data: Res.data.Data,
          };
          SetSourceData(dataStatus);
          
        }
      }
      
    });
  };

  const chartConfigs = {
    type: 'pie3d',
    width: '100%',
    height: '300',
    dataFormat: 'JSON',
    dataSource: {
      chart: {
        caption: 'Meeting Source',
        showvalues: '1',
        showpercentintooltip: '0',
        numbersuffix: '%',
        showlegend: '1',
        plothighlighteffect: 'fadeout|color=#fff, alpha=60',
        legendcaptionbold: '1',
        legendcaptionfontsize: '16',
        enablemultislicing: '1',
        theme: 'fusion',
      },
      data: SourceData, // Use the sourceData state here to update the chart data.
    },
  };

  //  var dataSource = {
  //   chart: {
  //     caption: "Meeting Source",
  //     showvalues: "1",
  //     showpercentintooltip: "0",
  //     numbersuffix: '%',
  //     "showlegend": "1",
  //     "plothighlighteffect": "fadeout|color=#fff, alpha=60",
  //     "legendcaptionbold": "1",
  //     "legendcaptionfontsize": "16",
  //     enablemultislicing: "1",
  //     theme: "fusion"
  //   },
  //   data: [
  //     {
  //       label: "Set",
  //       value: "100000",
  //       "color": "#302e49"
  //     }, 
  //     {
  //       label: "Contract Sent",
  //       value: "50000",
  //       "color": "#e9826e"
  //     }, 
  //   ]
  
  // };
  //data:
  const OverViewTopKeyMatricsGet=(UserID, FromDates, ToDates,IsIncludeOldClient)=>{
    SetIsLoading(true)
    const InputParameters = {
      UserID: UserID,
      ToDate: ToDates,
      FromDate: FromDates,
      IncludeOldClient:IsIncludeOldClient
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientKpiOverViewTopKeyMatrics",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      if(Res.data.StatusMessage=="SUCCESS")
      {
        SetTotalMeeting(Res.data.Data.TotalMeeting)
        SetAverageResponseRate(Res.data.Data.AverageResponseRate)
        SetAverageResponsetoMeetingRate(Res.data.Data.AverageResponsetoMeetingRate)
        SetAverageMeetingRate(Res.data.Data.AverageMeetingRate)
        SetReplyRate(Res.data.Data.ReplyRate)
        SetToatlResponsetoMeetingRate(Res.data.Data.ToatlResponsetoMeetingRate)
        SetIsLoading(false)
      }else{
        SetIsLoading(false)

      }
    });
  }
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  charts(FusionCharts);
  
   
  return (
    <div className="tabdes"> 
     <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Overview" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="All Clients" {...a11yProps(1)} />
            <Tab label="Leaderboard" {...a11yProps(2)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
        {
        IsLoading === true ?
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>:<></>
      }
        <div className="row">

                <div className="w-100 whiterow pb-3 px-4">
                  <div className="row">
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{TotalMeeting}</span>
                              </div>
                              <p class="clr-sky">Total Meetings</p>
                          </div>
                      </div>
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{AverageResponseRate}%</span>
                              </div>
                              <p class="clr-sky">Average Response %</p>
                          </div>
                      </div>
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{AverageResponsetoMeetingRate}%</span>
                              </div>
                              <p class="clr-sky">Average Response to Meeting %</p>
                          </div>
                      </div> 
                      <div className="col">
                          <div className="bg-light-sky boxcardcounter align-center">
                              <div class="line-title">
                                  <span class="clr-sky box-main-title">{AverageMeetingRate}%</span>
                              </div>
                              <p class="clr-sky">Average Meeting %</p>
                          </div>
                      </div> 
                  </div>
              </div>

              <div className="w-100 row border-top pt-4 px-5">                              
                        <div className="col-sm-8 px-4">
                          <h3 className="smheadtext">Meeting Source</h3> 
                            <ReactFusioncharts
                              key={JSON.stringify(SourceData)}
                              type="pie3d"
                              width="100%"
                              height="100%"
                              dataFormat="JSON"
                              dataSource={SourceData}
                            />
                        </div>
                        <div className="col-sm-4">
                          <div className="col-lg-11 pt-4">
                           <div className="row">
                              <div className="col">
                                  <div className="bg-light-sky boxcardcounter align-center">
                                      <div class="line-title">
                                          <span class="clr-sky box-main-title">{ReplyRate}%</span>
                                      </div>
                                      <p class="clr-sky">Response %</p>
                                  </div>
                              </div>  
                          </div>
                          <div className="row">
                              <div className="col">
                                  <div className="bg-light-sky boxcardcounter align-center">
                                      <div class="line-title">
                                          <span class="clr-sky box-main-title">{ToatlResponsetoMeetingRate}%</span>
                                      </div>
                                      <p class="clr-sky">Response to Meeting %</p>
                                  </div>
                              </div>  
                          </div>
                          <div className="row">
                              <div className="col">
                                  <div className="bg-light-sky boxcardcounter align-center">
                                      <div class="line-title">
                                          <span class="clr-sky box-main-title">{TotalMeeting}%</span>
                                      </div>
                                      <p class="clr-sky">Meeting %</p>
                                  </div>
                              </div>  
                          </div>
                          </div>
                          
                        </div>

                    </div>
            
               
            </div>
 
 

        </TabPanel>
        <TabPanel value={value} className="tagpaddnone whitebg" index={1}> 
            <div className="row">
            <div className="col">
              <div className="table-bordered">
                <KpisAllTable FromDates={FromDate} ToDates={ToDate} IsIncludeOldClient={IsIncludeOldClients}/>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone whitebg" index={2}>  
            <div className="row">
            <div className="col"> 
                <ClientLeaderboardTabing FromDates={FromDate} ToDates={ToDate} IsIncludeOldClient={IsIncludeOldClients} /> 
            </div>
          </div>
        </TabPanel> 

      </div> 

    </div>
  );
}