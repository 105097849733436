import React, { useEffect } from "react";
import Axios from "axios";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";

import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});




export default function CollapsibleTable() {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [aID, setAID] = React.useState("");
  const [checkS, setcheckS] = React.useState(false);
  const [totalcount, setTotalCount] = React.useState([]);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");

  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      setRole(getaccountcatelist.Role);
    }
    GetMeetingByMonthList(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID,getaccountcatelist.Role);
  }, [search, page, RowsPerPage]);

  //get seniority meeting
  const GetMeetingByMonthList = (CID,UID,URole) => {
    var str_in = {
      page: page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      Role:URole
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetMeetingBySource",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      console.log(result.data);
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      setTotalCount(result.data.totalmonthcount)
      document.getElementById("hideloding").style.display = "none";
    });
  };

    
  const chengeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    setPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //search
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      setSearch(SearchedVal);
      setPage(1);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  const handleChangePage = (event, newPage) => {
    if(newPage==page){
      setPage(newPage);
    }else{
      setPage(newPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  const startpage = (strdata, pages, rowcount) => {
    if (strdata == "normal") {
      console.log(strdata);
      var str_in = {
        page: page,
        rowsPerPage: RowsPerPage,
        serchbox: false,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        type: "User",
      };
    } else if (strdata == "search") {
      console.log("not" + strdata);
      var str_in = {
        page: pages,
        rowsPerPage: rowcount,
        serchbox: false,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        type: "User",
      };
    }
    console.log(str_in);
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetMeetingBySource",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      console.log(result);
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
    });
  };

  return (
    <div>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={chengeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"/>
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
          <TableRow>
            <TableCell>Meeting Source</TableCell>
            <TableCell>January</TableCell> 
            <TableCell>February</TableCell>
            <TableCell>March</TableCell>
            <TableCell>April</TableCell>
            <TableCell>May</TableCell>
            <TableCell>June</TableCell>
            <TableCell>July</TableCell>
            <TableCell>August</TableCell>
            <TableCell>September</TableCell>
            <TableCell>October</TableCell>
            <TableCell>November</TableCell>
            <TableCell>December</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.Name}</TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[0].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[1].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[2].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[3].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[4].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[5].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[6].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[7].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[8].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[9].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[10].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.counts.length == 0
                    ? 0
                    : row.counts[11].count}
                </TableCell>
               
              </TableRow>
            ))}  
          </TableBody>
          <TableFooter>
          {totalcount.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.Name}</TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[0].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[1].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[2].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[3].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[4].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[5].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[6].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[7].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[8].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[9].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[10].count}
                </TableCell>
                <TableCell>
                  {" "}
                  {row.count.length == 0
                    ? 0
                    : row.count[11].count}
                </TableCell>
               
              </TableRow>
            ))}  
          </TableFooter>
        </Table>
      </TableContainer>
      </div>
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
