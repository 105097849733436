import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
 
import ReactApexchart  from "react-apexcharts";


const AccountState = {
          
    series: [{
      name: 'Sent',
      color: '#302e49',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 45, 65]
    }, {
      name: 'Responses',
      color: '#e9826e',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 40, 115, 110]
    }, {
      name: 'Meetings',
      color: '#ffb963',
      data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 110]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['04/01 - 04/04', '04/05 - 04/11', '04/12 - 04/18', '04/19 - 04/25', '04/26 - 05/02', '05/03', '05/10', '05/17', '05/24', '06/07', '06/14', '06/21'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  
  
  };

class AddSalesHiveContactPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">

                

                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlepad float-left">Add SalesHive Contact</h4>
                        </div> 
                    </div>
                <div class="paddcols">
                    <div className="row borderbtnm">
                        <div className="col padd-0">
                            <h4 className="headertitlepad float-left">Contact Info</h4>
                        </div> 
                    </div>
                    
                    {/* <div className="row mt-4 mb-5"> 
                        <div className="col col-lg-2">
                            <div className="boxborders">
                            <label class="check_bx">
                                <input type="checkbox" /><span className="checkmark"></span>
                                Include all status
                            </label>
                            </div>
                        </div>
                        <div className="col col-lg-2">
                            <div className="boxborders">
                            <label class="check_bx">
                                <input type="checkbox" /><span className="checkmark"></span>
                                Include less than 30secs
                            </label>
                            </div>
                        </div>
                        <div className="col col-lg-4">
                            <a href="javascript:void(0);" class="btn btn-primary btnColor"><i class="la la-search"></i>Search</a>
                            <a href="javascript:void(0);" class="ml-3 btn btn-secondary"><i class="la la-refresh"></i> Reset</a>
                        </div>
                    </div>        */}
                        

                    {/* <div className="row mt-4">
                        <div className="col-sm-12 col-md-6">
                            <label className="textlabelte">Show 
                            <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select> 
                            entries</label> 
                        </div>
                        <div className="col-sm-12 col-md-6 full-right">
                            <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                        </div>
                    </div>                   

                    <div className="row">
                        <div className="col">
                            <div className="table-bordered">
                                <CallHistory />
                            </div>
                        </div>
                    </div>   

                    <div className="row">
                        <div className="col dataTables_info">
                        <p>Showing 1 to 71 of 71 entries</p>
                        </div>
                    </div> */}
 
                    {/* <div className="row padtop-50">
                        <div class="col-xl-1"></div>
                        <div class="col-xl-10">
                            <div id="chart chart-max450">
                            <ReactApexchart options={AccountState.options} series={AccountState.series} type="bar" height={450} />
                            </div>
                        </div>
                        <div class="col-xl-1"></div>
                    </div> */} 
                      <div className="row pt-4">  

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">First Name</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-required="Please enter first name" id="FirstName" name="FirstName" placeholder="First name" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Last Name</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="LastName" name="LastName" placeholder="Last name" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Email</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid email." data-val-remote="Email already exist." data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID" data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist" data-val-required="Please enter email." id="Email" name="Email" placeholder="Email" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Title</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="Title" name="Title" placeholder="Title" type="text" value="" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Company</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="Company" name="Company" placeholder="Company" type="text" value="" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Company Name Long</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="CompanyNameLong" name="CompanyNameLong" placeholder="Company name long" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Website</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid Website" data-val-regex-pattern="^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$" id="Website" name="Website" placeholder="Website" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">LinkedIn URL</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="LinkedInURL" name="LinkedInURL" placeholder="Linkedin url" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Industry</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="Industry" name="Industry" placeholder="Industry" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Address 1</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="Address1" name="Address1" placeholder="Address 1" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Address 2</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="Address2" name="Address2" placeholder="Address 2" type="text" value="" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">City</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="City" name="City" placeholder="City" type="text" value="" /> 
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">State</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="State" name="State" placeholder="State" type="text" value="" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Zip</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="Zip" name="Zip" placeholder="Zip" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Country</label>
                                            <div class="col-lg-8">
                                                
                                                <input class="form-control m-input" data-val="true" data-val-remote="Country Is Global Country Blacklisted." data-val-remote-additionalfields="*.Country,*.ClientID,*.UserID,*.CampaignID,*.PageName" data-val-remote-url="/Utility/IsProspectCountryExistsAndBlacklist" id="Country" name="Country" placeholder="Country" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Contact Source</label>
                                            <div class="col-lg-8">
                                                <select class="form-control  m-input" data-val="true" data-val-number="The field ContactSourceID must be a number." id="ContactSourceID" name="ContactSourceID"><option value="">--Select--</option>
                                                <option value="3465">Patel</option>
                                                <option value="3466">Anki</option>
                                                <option value="3467">ankita</option>
                                                <option value="3447">Purchased List</option>
                                                <option value="3448">Manual Research</option>
                                                <option value="3449">Advertising</option>
                                                <option value="3450">Website</option>
                                                <option value="3451">Event</option>
                                                <option value="3452">Referral</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Contact Owner</label>
                                            <div class="col-lg-8">
                                                <select class="form-control  m-input" data-val="true" data-val-number="The field ContactOwnerID must be a number." id="ContactOwnerID" name="ContactOwnerID"><option value="">--Select--</option>
                                                </select>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Client POC</label>
                                            <div class="col-lg-8">
                                                <select class="form-control  m-input" data-val="true" data-val-number="The field ClientPOCID must be a number." id="ClientPOCID" name="ClientPOCID"><option value="">--Select--</option>
                                                <option value="1760">Billing POC</option>
                                                <option value="1761">General POC</option>
                                                <option value="1762">Main POC</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Reason Unqualified</label>
                                            <div class="col-lg-8">
                                                <select class="form-control  m-input" data-val="true" data-val-number="The field ReasonUnqualifiedID must be a number." id="ReasonUnqualifiedID" name="ReasonUnqualifiedID"><option value="">--Select--</option>
                                                <option value="1969">No Longer w/ Company</option>
                                                <option value="1970">Wrong Person</option>
                                                <option value="1971">Not Interested</option>
                                                <option value="1972">Not Decision Maker</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Contact Category</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="ContactCategory" name="ContactCategory" placeholder="Contact category" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Account Category</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" id="AccountCategory" name="AccountCategory" placeholder="Account category" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Mobile Phone</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid mobile phone" data-val-regex-pattern="^[0-9]*$" id="MobilePhone" name="MobilePhone" placeholder="Mobile Phone" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Direct Phone</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid direct phone" data-val-regex-pattern="^[0-9]*$" id="DirectPhone" name="DirectPhone" placeholder="Direct Phone" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Direct Phone Ext</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid direct phone ext" data-val-regex-pattern="^[0-9]*$" id="DirectPhoneExt" name="DirectPhoneExt" placeholder="Direct Phone Ext" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Company Phone</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-regex="Invalid company phone" data-val-regex-pattern="^[0-9]*$" id="CompanyPhone" name="CompanyPhone" placeholder="Company Phone" type="text" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Company Revenue</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-number="The field CompanyRevenue must be a number." id="CompanyRevenue" name="CompanyRevenue" placeholder="Company Revenue" type="number" value="" />
                                            </div>
                                        </div>
                                    </div>
                               

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Employee Count</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input" data-val="true" data-val-number="The field CompanyEmployeeCount must be a number." id="CompanyEmployeeCount" min="0" name="CompanyEmployeeCount" placeholder="Employee Count" type="number" value="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Follow Up Date</label>
                                            <div class="col-lg-8">
                                                <input class="form-control m-input datepicker followupdate" id="strFollowUpDate" name="strFollowUpDate" placeholder="Follow Up Date" type="text" value="" />

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Cold Caller</label>
                                            <div class="col-lg-8">
                                                  <select class="form-control  m-input" data-val="true" data-val-number="The field ColdCallersID must be a number." id="ColdCallersID" name="ColdCallersID"><option value="">--Select--</option>
                                                  <option value="35">@check page profile test</option>
                                                  <option value="29">@cold pic </option>
                                                  <option value="31">@user cold last name</option>
                                                  <option value="41">12Jayesh 12RANA</option>
                                                  <option value="19">cold caller jenish </option>
                                                  <option value="37">cold caller passwordtest test</option>
                                                  <option value="43">cold callers sss mail edit</option>
                                                  <option value="25">cold name </option>
                                                  <option value="14">coldcaller200 </option>
                                                  <option value="36">coldcarrer-new tesr</option>
                                                  <option value="34">hhok ll</option>
                                                  <option value="10">jayeshrana </option>
                                                  <option value="52">jenish modi</option>
                                                  <option value="42">last cold calle</option>
                                                  <option value="23">Rocky Mihir  Don</option>
                                                  <option value="50">test fs</option>
                                                  <option value="38">test@29.com </option>
                                                  </select>                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <label class="col-lg-4 view-lable-meet">Sales Rep</label>
                                            <div class="col-lg-8">
                                                <select class="form-control  m-input" data-val="true" data-val-number="The field SalesRepID must be a number." id="SalesRepID" name="SalesRepID"><option value="">--Select--</option>
                                            <option value="2">sales rep 2</option>
                                            <option value="1">sales rep 1st</option>
                                            <option value="1013">SAI</option>
                                            <option value="1012">RANA1</option>
                                            <option value="1011">RANA</option>
                                            <option value="1007">as</option>
                                            <option value="1010">14</option>
                                            </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 boxsinput_group">
                                        <div class="row max-cols">
                                            <div class="col-lg-12">
                                                <div class="m-checkbox-inline">
                                                    <label class="m-checkbox ">
                                                        <input data-val="true" data-val-required="The IsDoNotCallContact field is required." id="IsDoNotCallContact" name="IsDoNotCallContact" type="checkbox" value="true" />
                                                        <input name="IsDoNotCallContact" type="hidden" value="false" /> Do Not Call Contact
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                      </div>   

                      <div className="row borderbtnm mt-5">
                          <div className="col padd-0">
                              <h4 className="headertitlepad float-left">Contact Info</h4>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-lg-6 boxsinput_group">
                              <div class="row max-cols">
                                  <label class="col-lg-4 view-lable-meet">Ankita test</label>
                                  <div class="col-lg-8">
                                      <input data-val="true" data-val-number="The field CustomFieldID must be a number." id="lstCustomField_0__CustomFieldID" name="lstCustomField[0].CustomFieldID" type="hidden" value="1193" />
                                      <input class="form-control" id="lstCustomField_0__FieldValue" name="lstCustomField[0].FieldValue" type="text" value="" autocomplete="off" />                                                    </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
                      <div class="row py-4">
                            <div class="col-lg-12 pull-left">
                                <a id="submit" href="javascript:void(0);" class="btn btn-primary btn-lightgreen mr-1"><i class="la la-save"></i> Save</a>
                                <a id="backtolist" href="javascript:void(0);" class="btn btn-secondary"><i class="la la-arrow-circle-left"></i> Back</a>
                            </div>
                        </div>



                   

                    

                     

                </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedAddSalesHiveContactPage = connect(mapState, actionCreators)(AddSalesHiveContactPage);
export { connectedAddSalesHiveContactPage as AddSalesHiveContactPage };