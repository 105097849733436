import React, { useState, useEffect }  from 'react';  
import Axios from "axios";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { arrayMoveImmutable } from "array-move";
import { Container, Draggable } from "react-smooth-dnd";
import CampaignsContact from "../campaigns/CampaignsContact";
import CampaignAccount from "../campaigns/CampaignsAccount";
import AddContactsCampaign from "../campaigns/AddContactsCampaign";
import AddListCampaign from "../campaigns/AddListCampaign";
// import Accordion from './Accordion';
import { GetUserDetails } from "../../../_helpers/Utility";
import Campaigns from '../../../_components/clients/campaigns/campaignsTable';
//import Accordion from './accordion';
 
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { styled } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import Popup from "reactjs-popup";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import ConfigureEmailAccounts from "../campaigns/ConfigureEmailAccounts";
import loadingicon from "../../../images/loading.gif";
// Note that Froala Editor has to be required separately
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import Swal from "sweetalert2";
import "froala-editor/js/plugins.pkgd.min.js";
import Froala from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import { CommonConstants } from "../../../_constants/common.constants";
import { normalizeUnits } from "moment";
import { Variables } from "../../../_constants/common.constants";
import parse from "html-react-parser";
import Popover from "@mui/material/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import $ from "jquery";
import { CSVReader } from "react-papaparse";
import { history } from "../../../_helpers";
const Fileupload = require("../../../_helpers/fileupload");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
// https://www.cluemediator.com/how-to-create-step-wizard-in-react 
const fourComponent = () => {
  return <div> 
    <div className="row">
      <div className="col w-100"> 
      <div className="iconer">
          <i className="flaticon-file-1 clr-purple"></i>
        </div>
      </div>
      </div>
    <div className="row">
     <div className='col w-100 text-center'> 
          <Popup trigger={
              <a class="btn btn-primary btngroup">
                <i className="la la-plus-circle la-icon-font-size-13"></i>  Add Campaign Step
              </a>
            } modal
            nested>
              {close => (
                <div>
              <div className="modal-black"></div>
                <div className="filterPopup">
                  <div className="paddingboxTerms">
                    <div className="modal-header py-4 px-3">
                      <div className='w-100 d-flex px-3'>
                          <h5 className="mb-0">Add Step</h5>
                          <button className="close" onClick={close}>
                            <span aria-hidden="true" class="la la-remove"></span>
                          </button>
                      </div> 
                    </div> 
                    <div className="modal-body"> 
                      <div className="px-4">    
                        <div class="row mb-4">
                          <label class="col-lg-3 col-form-label textright-vmb">
                          Wait time 
                          </label>
                          <div class="col-lg-9 d-flex">
                            <input
                              class="form-control m-input" placeholder="Enter title" type="number" value="" /> 
                            <label class="ml-3 col-form-label text-left">
                            Days 
                            </label>
                          </div>
                        </div> 

                        <div class="row mb-4">
                          <label class="col-lg-3 col-form-label textright-vmb">
                          Subject
                          </label>
                          <div class="col-lg-9 d-flex">
                            <input class="form-control m-input" placeholder="Enter title" type="number" value="" /> 
                          </div>
                        </div> 

                        <div class="row mb-4">
                          <label class="col-lg-3 col-form-label textright-vmb">
                          Body
                          </label>
                          <div class="col-lg-9 d-flex">
                            <textarea class="form-control m-input" placeholder="Enter title" type="text"></textarea>
                          </div>
                        </div> 

                      </div> 
                    </div>  
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <a id="m_emailInvite_submit" href="javascript:void(0);" class="btn btn-primary btnColor"><i class="la la-save mr-2"></i> Save </a>
                    </div>
                  </div>
                </div>
              </div>
                )}
          </Popup>
      </div>
    </div>
  </div>
}
const finalComponent = () => {
  return <div>
    <div className="row">
      <div className="col padd-0"> 
          <h4 className="headertitlebd"></h4>
      </div>
      <div className="col padright">
          <div className="listing-li float-right padb-15">
              <ul> 
                  <li>
                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                        <i class="flaticon-refresh"></i> Refresh
                    </a>
                  </li> 
                  <li>
                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                         Select Contact
                    </a>
                  </li>  
              </ul>
          </div>
      </div>
  </div>
    <div className='timeline-col'> 
      <Timeline>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <h5>Step 1 - Day 1</h5>
            <Accordion />
          </TimelineContent>
        </TimelineItem>
        
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <h5>Step 1 - Day 1</h5>
            <Accordion />
          </TimelineContent>
        </TimelineItem> 

      </Timeline>
    </div>

  </div>
}
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#f4f5f8",
  color: "#7b7e8a",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function App({ CreateTemplateCampaign }) {
  // const [config, setconfig] = useState({
  //   placeholderText: 'Edit Your Content Here!',
  //   charCounterCount: false
  // })
  const [signature, setsignature] = useState(null);
  const [steps, setSteps] = useState([
    {
      key: "firstStep",
      label: "1. Add Campaign Details",
      icon: "fa fa-at",
      isDone: true,
    },
    {
      key: "secondStep",
      label: "2. Email Accounts",
      icon: "fa fa-user-circle-o",
      isDone: false,
      
    },
    {
      key: "thirdStep",
      label: "3. Add Contacts",
      icon: "fa fa-user-plus",
      isDone: false,
    },
    {
      key: "fourStep",
      label: "4. Add Step",
      icon: "fa fa-envelope-o",
      isDone: false,
    },
    {
      key: "finalStep",
      label: "5. Preview",
      icon: "fa fa-eye",
      isDone: false,
    },
  ]);
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [CampaignID, SetCampaignID] = useState("");
  const [TempCampaignID, SetTempCampaignID] = useState("");
  const [CampaignName, SetCampaignName] = useState("");
  const [OpenTracking, SetOpenTracking] = useState([]);
  const [Priority, SetPriority] = useState([]);
  const [ReplyBehavior, SetReplyBehavior] = useState([]);
  const [ClientID, SetClientID] = useState("");
  const [UserID, SetUserID] = useState("");
  const [Role, SetRole] = useState("");
  const [ConfigEmailPopup, SetConfigEmailPopup] = useState(false);
  const [Errors, SetErrors] = useState("");
  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const [ChileValueTwo, SetChileValueTwo] = useState(false);
  const [ConfigPop, SetConfigPop] = useState(false);
  const [CreateManuallyPop, SetCreateManuallyPop] = useState(false);
  const [ContactSourceData, SetContactSourceData] = useState([]);
  const [ClientPOCData, SetClientPOCData] = useState([]);
  const [ReasonUnqualifiedData, SetReasonUnqualifiedData] = useState([]);
  const [ContactTagData, SetContactTagData] = useState([]);
  const [ContactOwnerData, SetContactOwnerData] = useState([]);
  const [ContactSalesDevlopMenData, SetContactSalesDevlopMenData] = useState(
    []
  );
  const [ShowPopupExport1, SetShowPopupExport1] = React.useState(false);
  const [ContactCustomFieldData, SetContactCustomFieldData] = useState([]);
  const [ContactManuallyError, SetContactManuallyError] = useState({});
  const [ContactFields, SetContactFields] = useState({});
  const [ContactTagSelectArray, SetContactTagSelectArray] = useState([]);
  const [ContactTagsNamesArray, SetContactTagsNamesArray] = useState([]);
  const [TagArray, SetTagArray] = useState([]);
  const [ProspectID, SetProspectID] = useState("");
  const [ProspectCampaignID, SetProspectCampaignID] = useState("");
  const [ProspectFlag, SetProspectFlag] = useState(false);
  const [SignatureSC, SetSignatureSC] = useState({ data: "" });
  const [SignatureSC2, SetSignatureSC2] = useState({ data: "" });
  const [AddStepPopup, SetAddStepPopup] = useState(false);
  const [AddStepTempletePopup, SetAddStepTempletePopup] = useState(false);
  const [CampaignStepFlag, SetCampaignStepFlag] = useState(false);
  const [CampaignDetails, SetCampaignDetails] = useState([]);
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [CampaignStepID, SetCampaignStepID] = useState(null);
  const [DaysTemp, SetDaysTemp] = useState(0);
  const [PreviewCampaignDetails, SetPreviewCampaignDetails] = useState([]);
  const [PreviewCampignStepDetails, SetPreviewCampignStepDetails] = useState(
    []
  );
  const [PreviewEmailAccountDetails, SetPreviewEmailAccountDetails] = useState(
    []
  );

  const [PreviewSmartVaribleBiggerThan, SetPreviewSmartVaribleBiggerThan] =
    useState([]);
  const [PreviewSmartVaribleFasterThan, SetPreviewSmartVaribleFasterThan] =
    useState([]);
  const [
    PreviewSmartVaribleMoreExcitingThan,
    SetPreviewSmartVaribleMoreExcitingThan,
  ] = useState([]);
  const [PreviewSmartVaribleMascotCheer, SetPreviewSmartVaribleMascotCheer] =
    useState([]);

  const [PreviewCallToAction, SetPreviewCallToAction] = useState([]);
  const [PreviewAutomatorFeature, SetPreviewAutomatorFeature] = useState([]);

  const [SelectedPreviewCallToAction, SetSelectedPreviewCallToAction] =
    useState({});
  const [SelectedPreviewAutomatorFeature, SetSelectedPreviewAutomatorFeature] =
    useState({});

  const [
    SelectedPreviewSmartVaribleBiggerThan,
    SetSelectedPreviewSmartVaribleBiggerThan,
  ] = useState({});
  const [
    SelectedPreviewSmartVaribleFasterThan,
    SetSelectedPreviewSmartVaribleFasterThan,
  ] = useState({});
  const [
    SelectedPreviewSmartVaribleMoreExcitingThan,
    SetSelectedPreviewSmartVaribleMoreExcitingThan,
  ] = useState({});
  const [
    SelectedPreviewSmartVaribleMascotCheer,
    SetSelectedPreviewSmartVaribleMascotCheer,
  ] = useState({});

  const [activeIndexes, setActiveIndexes] = useState([]);
  const [SelectedPreviewCampign, SetSelectedPreviewCampign] = useState({});
  const [ReplyBehaviorID, SetReplyBehaviorID] = useState("");
  const [CampaignPriorityID, SetCampaignPriorityID] = useState("");
  const [EmailOpenTrackingID, SetEmailOpenTrackingID] = useState("");
  //const [StepValue, SetStepValue] = useState
  // for imoirt csv
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [filenameonlynumber, setfilenameonlynumber] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  // variables dropdown mapping
  const [FirstName, SetFirstName] = React.useState(null);
  const [LastName, SetLastName] = React.useState(null);
  const [Email, SetEmail] = React.useState(null);
  const [Title, SetTitle] = React.useState(null);
  const [Company, SetCompany] = React.useState(null);
  const [CompanyNameLong, SetCompanyNameLong] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [City, SetCity] = React.useState(null);
  const [State, SetState] = React.useState(null);
  const [ContactCategory, SetContactCategory] = React.useState(null);
  const [AccountCategory, SetAccountCategory] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [Address1, SetAddress1] = React.useState(null);
  const [Address2, SetAddress2] = React.useState(null);
  const [LinkedInURL, SetLinkedInURL] = React.useState(null);
  const [Zip, SetZip] = React.useState(null);
  const [Country, SetCountry] = React.useState(null);
  const [ValidationScore, SetValidationScore] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [CompanyRevenue, SetCompanyRevenue] = React.useState(null);
  const [EmployeeCount, setEmployeeCount] = React.useState(null);
  const [MobilePhone, SetMobilePhone] = React.useState(null);
  const [DirectPhone, SetDirectPhone] = React.useState(null);
  const [DirectPhoneExt, SetDirectPhoneExt] = React.useState(null);
  const [OtherPhone1, SetOtherPhone1] = React.useState(null);
  const [OtherPhone2, SetOtherPhone2] = React.useState(null);
  const [ContactSource, SetContactSource] = React.useState(null);
  const [ContactOwner, SetContactOwner] = React.useState(null);
  const [ClientPOC, SetClientPOC] = React.useState(null);
  const [ReasonUnqualified, SetReasonUnqualified] = React.useState(null);
  const [AccountType, SetAccountType] = React.useState(null);
  const [IsDoNotCallContact, SetIsDoNotCallContact] = React.useState(null);
  const [SalesRep, SetSalesRep] = React.useState(null);
  const [ColdCaller, SetColdCaller] = React.useState(null);
  const [Tag, SetTag] = React.useState(null);
  const [CallNotes, SetCallNotes] = React.useState(null);
  const [ContactID, SetContactID] = React.useState(null);
  const [ListName, SetListName] = React.useState(null);
  const [CampaignData, SetCampaignData] = React.useState([]);
  const [ShowPopup, SetShowPopup] = React.useState(false);
  const [ShowPopupReassignDev, SetShowPopupReassignDev] = React.useState(false);
  const [ShowPopupExport, SetShowPopupExport] = React.useState(false);
  const [ShowPopupCampaign, SetShowPopupCampaign] = React.useState(false);
  const [ContactReassignData, SetContactReassignData] = React.useState([]);
  const [SearchReassign, SetSearchReassign] = React.useState("");
  const [SortFieldReassign, SetSortFieldReassign] =
    React.useState("ColdCallersName");
  const [SortedByReassign, SetSortedByReassign] = React.useState(1);
  const [CheckState, SetCheckState] = React.useState(false);
  const [ContactTag, SetContactTag] = React.useState([]);

  const [ReassignFromSelectedValue, SetReassignFromSelectedValue] =
    React.useState([]);
  const [ReassignToSelectedValue, SetReassignToSelectedValue] = React.useState(
    []
  );
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalUpdated, SetTotalUpdated] = React.useState(0);
  const [TotalEmailValiDationScoreD, SetTotalEmailValiDationScoreD] =
    React.useState(0);
  const [TotalEmailValiDationScoreF, SetTotalEmailValiDationScoreF] =
    React.useState(0);
  const [TotalSkipped, SetTotalSkipped] = React.useState(0);
  const [TotalContactBlacklisted, SetTotalContactBlacklisted] = React.useState(0);
  const [TotalRemoved, SetTotalRemoved] = React.useState(0);
  const [TotalDomainBlackListed, SetTotalDomainBlackListed] = React.useState(0);
  const [TotalGlobalDomainBlackListed, SetTotalGlobalDomainBlackListed] = React.useState(0);
  const [TotalDefaultCountryBlackListed, SetTotalDefaultCountryBlackListed] = React.useState(0);
  const [TotalCountryBlackListed, SetTotalCountryBlackListed] = React.useState(0);
  const [TotalReplied, SetTotalReplied] = React.useState(0);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [ShowPopupImport, SetShowPopupImport] = React.useState(false);
  const [ShowImportContactCsv, SetShowImportContactCsv] = React.useState(false);
  const [SelectedPreviewAccountEmail, SetSelectedPreviewAccountEmail] =
    useState({});
  const [SignatureSC3, SetSignatureSC3] = useState({ data: "" });
  const [EditStepTempletePopup, SetEditStepTempletePopup] = useState(false);
  const [CampaignStepTemplatesID, SetCampaignStepTemplatesID] = useState("");
  const [VariableDroupDown, SetVariableDroupDown] = useState({});

    // csv errors table details
    const [Data, SetData] = React.useState([]);
    const [Flen, SetFlen] = React.useState(0);
    const [Sflag, SetSflag] = React.useState(false);
    const [Rlen, SetRlen] = React.useState(0);
    const [CountPage, SetCountPage] = React.useState(0);
    const [Rows, SetRows] = React.useState([]);
    const [CSVEPage, SetCSVEPage] = React.useState(1);
    const [CSVERowsPerPage, SetCSVERowsPerPage] = React.useState(15);
    const [Page, SetPage] = React.useState(1);
    const [RowsPerPage, SetRowsPerPage] = React.useState(15);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("");
    const [SortedBy, SetSortedBy] = React.useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [OpenTrackingDefault,SetOpenTrackingDefault] = useState("7")
    const [ExportCsvErrors,SetExportCsvErrors] = useState({});
    const [IsVisible, SetIsVisible] = useState(false);
    const [CsvFileName, SetCsvFileName] = React.useState("");


    // for popup
    const [ContactFromExistingPopup,SetContactFromExistingPopup] = useState(false);
    const [counter, setCounter] = React.useState(0);
    const [ContactExitsData, SetContactExitsData] = React.useState(false);
    const [CampaignDataDrop,SetCampaignDataDrop] = React.useState([]);
    const [CampaignOntherData,SetCampaignOntherData] = React.useState("");
    const [dataFromChild, setDataFromChild] = useState([]);
    const [DataFromCampaign, setDataFromCampaign] = useState([]);
    const [DataUpdate, SetDataUpdate] = useState(false);
    const [CUserID,SetCUserID] = React.useState(0)
  const open = Boolean(AnchorEl);
  // variables dropdown mapping
  var Vari = {};
  if (Variables.length > 0) {
    for (var i = 0; i < Variables.length; i++) {
      Vari["{" + Variables[i].name.split(" ").join("") + "}"] =
        Variables[i].name;
    }
  }
  const handleOpenTrackingDefault = (event)=>{
    SetOpenTrackingDefault(event.target.value);
    
  }
  const handleDataFromCampaign = (data) => {
    setDataFromCampaign(data);
  };
  // config for code editor 
  let config = {
    placeholderText: "Type something!",
    charCounterCount: false,
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "insertLink",
      "insertImage",
      "html",
      "Variable",
    ],
    shortcutsEnabled: ["insertTemplateButton"],
    enter: Froala.ENTER_BR,
  };

  useEffect(() => {
     

    //document.getElementById("hideloding23").style.display = "block";
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
      SetUserEmail(GetUserData.Username);
    }
    if (activeStep.key === "firstStep") {
      ;
      //.getElementById("hideloding23").style.display = "block";
      if (CreateTemplateCampaign != "") {
        GetAllDroupDown(
          GetUserData.ClientID,
          GetUserData.ParentUserID,
          GetUserData.Role
        );
        GetCampaignTeplate(GetUserData.Role);
        document.getElementById(activeStep.key).style.display = "block";
      } else {
        GetAllDroupDown(
          GetUserData.ClientID,
          GetUserData.ParentUserID,
          GetUserData.Role
        );
        document.getElementById(activeStep.key).style.display = "block";
      }
      //document.getElementById("hideloding23").style.display = "none";
    } else if (activeStep.key === "secondStep") {
      //document.getElementById("hideloding23").style.display = "block";
      document.getElementById(activeStep.key).style.display = "block";
      //document.getElementById("hideloding23").style.display = "none";
    } else if (activeStep.key === "thirdStep") {
      
      document.getElementById(activeStep.key).style.display = "block";
      GetContactAllDroupDown(
        GetUserData.ClientID,
        GetUserData.ParentUserID,
        GetUserData.Role
      );
      //document.getElementById(activeStep.key).style.display = "none";
    } else if (activeStep.key === "fourStep") {
      GetCampaignStepDetails(
        GetUserData.ClientID,
        GetUserData.ParentUserID,
        GetUserData.Role
      );
      document.getElementById(activeStep.key).style.display = "block";
    } else {
      //document.getElementById("hideloding23").style.display = "block";
      GetPreviewCampignStepDetails(
        GetUserData.ClientID,
        GetUserData.ParentUserID,
        GetUserData.Role
        // campaignID.data
      );
      document.getElementById(activeStep.key).style.display = "block";
      //document.getElementById("hideloding23").style.display = "none";
    }
    document.getElementById("hideloding23").style.display = "none";
  }, [
    CampaignID,
    ChileValueTwo,
    ConfigEmailPopup,
    Errors,
    activeStep,
    ProspectFlag,
    CampaignStepFlag,
    AddStepTempletePopup,
    UserEmail,
    DataUpdate
  ]);

  console.log(CampaignID,"CampaignID123")
  // for popup close
  const handleDataFromChildContactExits = (data) => {
    SetContactFromExistingPopup(data);
    SetConfigPop(false)
    SetCampaignID()
  };

    //Reload page while delete perform
   const UpdateFromChild = (value) => {
        SetCampaignID(value)
    }
  //Set active step and buttons of next and priovus button
  const ActivePreviousChange = async () => {
    document.getElementById("hideloding23").style.display = "none";
    document.getElementById(activeStep.key).style.display = "none";
    if(activeStep.key==="firstStep"){
      toast.success(
        <div>
        Campaign<br/>
        Campaign added successfully.
        </div>
      )
    }
    if (steps[steps.length - 1].key === activeStep.key) {
      alert("You have completed all steps.");
      return;
    }
    const index = steps.findIndex((x) => x.key === activeStep.key);
    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = true;
        return x;
      })
    );
    setActiveStep(steps[index + 1]);
    document.getElementById(steps[index + 1].key).style.display = "block";
    document.getElementById("hideloding23").style.display = "none";
  };

  // get value for text editor addd
  const VariableModelChange = (model) => {
    SetSignatureSC({
      data: model,
    });
  };

  // get value for text editor addd
  const VariableModelChange2 = (model) => {
    SetSignatureSC2({
      data: model,
    });
  };

  // get value for text editor addd
  const VariableModelChange3 = (model) => {
    SetSignatureSC3({
      data: model,
    });
  };

  const handleModelChange = (model) => {
    setsignature(model);
  };

  //Campaign next button or skip button
  const handleNext = async (PageBtn) => {
    // ;
    if (PageBtn === "Skip") {
      ActivePreviousChange();
    } else if (PageBtn === "Finish") {
      history.push("/ccampaigns");
    } else {
      if (activeStep.key == "firstStep") {
        SetErrors("");
        var CampaignFirstStepDetails = await CampaignFirstStep();
        // ;
        if (CampaignFirstStepDetails.flag == true) {
          // ;
          document.getElementById(activeStep.key).style.display = "none";
          ActivePreviousChange();
        }
      }
      if (activeStep.key == "secondStep") {
        document.getElementById(activeStep.key).style.display = "none";
        ActivePreviousChange();
      }
      if (activeStep.key == "thirdStep") {
        document.getElementById(activeStep.key).style.display = "none";
        ActivePreviousChange();
      }
      if (activeStep.key == "fourStep") {
        document.getElementById(activeStep.key).style.display = "none";
        ActivePreviousChange();
      }
      // if (activeStep.key == "finalStep") {
      //   document.getElementById(activeStep.key).style.display = "none";
      //   // ActivePreviousChange();
      // }
    }
  };

  //Campaign back button
  const handleBack = () => {
    // ;
    document.getElementById(activeStep.key).style.display = "none";
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
    document.getElementById(steps[index - 1].key).style.display = "block";
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };
  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;

    // this.setState({ files: fileInfo });
    setfiles(fileInfo);
    var sep = filename.split(".");
    SetCsvFileName(sep[0])

    if (sep[1] != "csv") {
      // this.setState({ reset: true });
      SetReset(true);
      // this.ResetCsv();
      ResetCsv();
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];

      var csvfilenumber = new Date().getTime() + "." + sep[1];

      // this.setState({ filename: csvfile });
      setfilename(csvfile);
      setfilenameonlynumber(csvfilenumber);

      // this.setState({ csvData: data });
      setcsvData(data);
      // this.setState({ DropboxData: data[0].data });
      SetDropboxData(data[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  const CampaignExist = async () => {
    SetErrors("");
    var GetCampaignName = document.getElementById("CampaignName").value.trim();
    if (GetCampaignName !== CampaignName) {
      var InputParameter = {
        CampaignName: GetCampaignName,
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      var CampaignStatusExist = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/CampaignStatusExists",
        method: "POST",
        data: InputParameter,
      });
      // ;
      if (CampaignStatusExist.data.Data.length > 0) {
        SetErrors("Campaign name already exists");
        return true;
      } else {
        return false;
      }
    }
  };

  // first step add method
  const CampaignFirstStep = async () => {
    // ;
    var FinalStatusValidation = { flag: true, ID: "" };
    var GetCampaignName = document.getElementById("CampaignName").value.trim();
    if (GetCampaignName !== CampaignName) {
      if (CampaignID != "") {
        var ReplyBehavior = document.getElementById("ReplyBehavior").value;
        var Priority = document.getElementById("Priority").value;
        var OpenTracking = document.getElementById("OpenTracking").value;
        var SendOnWeekends = document.getElementById("SendOnWeekends").checked;
        var IgnoreBlacklist =
          document.getElementById("IgnoreBlacklist").checked;
        var TrackClicks = document.getElementById("TrackClicks").checked;
        var Notes = document.getElementById("Notes").value.trim();
        var StrInput = {
          CampaignID: CampaignID,
          CampaignName: GetCampaignName,
          ReplyBehavior: ReplyBehavior,
          Priority: Priority,
          OpenTracking: OpenTracking,
          SendOnWeekends: SendOnWeekends,
          IgnoreBlacklist: IgnoreBlacklist,
          TrackClicks: TrackClicks,
          Notes: Notes,
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
        var Result = await Axios({
          url: CommonConstants.MOL_APIURL + "/campaign/CampaignUpdate",
          method: "POST",
          data: StrInput,
        });
        if (Result.data.StatusMessage === "SUCCESS") {
          FinalStatusValidation = { flag: true, ID: CampaignID };
        } else {
          FinalStatusValidation = { flag: false, ID: "" };
        }
      } else {
        var ReplyBehavior = document.getElementById("ReplyBehavior").value;
        var Priority = document.getElementById("Priority").value;
        var OpenTracking = document.getElementById("OpenTracking").value;
        var SendOnWeekends = document.getElementById("SendOnWeekends").checked;
        var IgnoreBlacklist =
          document.getElementById("IgnoreBlacklist").checked;
        var TrackClicks = document.getElementById("TrackClicks").checked;
        var Notes = document.getElementById("Notes").value.trim();
        var CampaignExistDetails = await CampaignExist();
        if (CampaignExistDetails == false) {
          var StrInput = {
            CampaignTemplateID: CreateTemplateCampaign,
            CampaignName: GetCampaignName,
            ReplyBehavior: ReplyBehavior,
            Priority: Priority,
            OpenTracking: OpenTracking,
            SendOnWeekends: SendOnWeekends,
            IgnoreBlacklist: IgnoreBlacklist,
            TrackClicks: TrackClicks,
            Notes: Notes,
            ClientID: ClientID,
            UserID: UserID,
            Role: Role,
          };
          // ;
          var Result = await Axios({
            url: CommonConstants.MOL_APIURL + "/campaign/CampaignAdd",
            method: "POST",
            data: StrInput,
          });

          if (Result.data.StatusMessage === "SUCCESS") {
            // ;
            SetCampaignID(Result.data.Data._id);
            SetTempCampaignID(Result.data.Data._id);
            SetCampaignName(
              document.getElementById("CampaignName").value.trim()
            );
            FinalStatusValidation = {
              flag: true,
              ID: Result.data.Data.CampaignID,
            };
          } else {
            FinalStatusValidation = { flag: false, ID: "" };
          }
        } else {
          FinalStatusValidation = { flag: false, ID: "" };
        }
      }
    } else {
      FinalStatusValidation = { flag: true, ID: CampaignID };
    }
    return FinalStatusValidation;
  };

  // Default campaign add first step details get
  const GetAllDroupDown = async (CID, UID, URole) => {
    // ;
    // document.getElementById("OpenTracking").value="7"
    var StrInOpenTracking = {
      FieldTypeName: "EmailOpenTracking",
    };
    var EmailOpenTracking = await Axios({
      url: CommonConstants.MOL_APIURL + "/commonfiled/GetCommonField",
      method: "POST",
      data: StrInOpenTracking,
    });
    if (EmailOpenTracking.data.StatusMessage == "SUCCESS") {
      SetOpenTracking(EmailOpenTracking.data.Data.sort((a, b) => b.OrderBy - a.OrderBy));

    }
    var StrInPriority = {
      FieldTypeName: "CampaignPriority",
    };
    var Priority = await Axios({
      url: CommonConstants.MOL_APIURL + "/commonfiled/GetCommonField",
      method: "POST",
      data: StrInPriority,
    });
    if (Priority.data.StatusMessage == "SUCCESS") {
      SetPriority(Priority.data.Data);
    }
    var StrInReplyBehavior = {
      FieldTypeName: "ReplyBehavior",
    };
    var ReplyBehavior = await Axios({
      url: CommonConstants.MOL_APIURL + "/commonfiled/GetCommonField",
      method: "POST",
      data: StrInReplyBehavior,
    });
    if (ReplyBehavior.data.StatusMessage == "SUCCESS") {
      SetReplyBehavior(ReplyBehavior.data.Data);
    }
  };

  //Get Campaign Template details
  const GetCampaignTeplate = async (URole) => {
    ;
    var StrIn = {
      CampaignTemplateID: CreateTemplateCampaign,
      Role: URole,
    };
    var TemplateDetails = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaigntemplate/GetCampaignTemplateByID",
      method: "POST",
      data: StrIn,
    });
    
    if (TemplateDetails.data.StatusMessage == "SUCCESS") {
      document.getElementById("CampaignName").value =
        TemplateDetails.data.Data[0].Name + " - template";
      document.getElementById("Notes").value =
        TemplateDetails.data.Data[0].Notes;
      document.getElementById("SendOnWeekends").checked =
        TemplateDetails.data.Data[0].IsSendOnWeekends;
      document.getElementById("IgnoreBlacklist").checked =
        TemplateDetails.data.Data[0].IsIncludeBlacklistedContacts;
      document.getElementById("TrackClicks").checked =
        TemplateDetails.data.Data[0].IsTrackClick;
      SetReplyBehaviorID(
        TemplateDetails.data.Data[0].ReplyBehaviorID
      );
      SetCampaignPriorityID(
        TemplateDetails.data.Data[0].CampaignPriorityID
      );
      SetEmailOpenTrackingID(
        TemplateDetails.data.Data[0].EmailOpenTrackingID
      );
      //SetOpenTracking(EmailOpenTracking.data.Data);
    }
  };

  //Default campaign add manually contact droupdownlist
  const GetContactAllDroupDown = async (CID, UID, URole) => {
     ;
    document.getElementById("hideloding23").style.display = "block";
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    if (rows != null) {
      let contacttagoptions = rows.data?.ContactTagData.map((val) => {
        return { value: val.Tag, label: val.Tag, cid: val._id };
      });
      document.getElementById("hideloding23").style.display = "none";
      SetContactSourceData(rows.data?.ContactSourceData);
      SetClientPOCData(rows.data?.ClientPOCData);
      SetReasonUnqualifiedData(rows.data?.ReasonUnqualifiedData);
      SetContactTagData(contacttagoptions);
      SetContactOwnerData(rows.data?.ContactOwnerData);
      SetContactSalesDevlopMenData(rows.data?.SalesReplyDevlopmentData);
      SetContactCustomFieldData(rows.data?.ContactCustomFieldData);
      document.getElementById("hideloding23").style.display = "none";
    }else{
      document.getElementById("hideloding23").style.display = "none";
    }
    document.getElementById("hideloding23").style.display = "none";
  };

  //Get Campaign step details
  const GetCampaignStepDetails = async (CID, UID, URole) => {
    //;
    document.getElementById("hideloding23").style.display = "block";
    var VariableDroupdown = {
      ClientID: ClientID,
      UserID: UserID,
    };
    var result = await Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/GetVariableValueList",
      method: "POST",
      data: VariableDroupdown,
    });
    if (result.data.Data != undefined) {
      SetVariableDroupDown(result.data.Data);
      Froala.RegisterCommand("Variable", {
        title: "Advanced options",
        type: "dropdown",
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: result.data.Data,
        callback: function (cmd, val) {
          var editorInstance = this;
          editorInstance.html.insert(val);
        },
        // Callback on refresh.
        refresh: function ($btn) {
          //console.log("do refresh");
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
          // console.log("do refresh when show");
        },
      });
    }

    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignID,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/GetCampaignStep",
      method: "POST",
      data: str_in,
    });
   
    if (rows.data.StatusMessage == "SUCCESS") {
      var TmpData = rows.data.Data;
      var TotalCount = 1;
      var result = rows.data.Data.map((item) => {
        TotalCount = TotalCount + item.Days;
        item["TotalCount"] = TotalCount;
      });
      const results = await Promise.all(result);
      SetCampaignDetails(TmpData);
    }
    document.getElementById("hideloding23").style.display = "none";
  };
  // config email chile to parent update
  const ConfigEmailAccount = async () => {
    if (ConfigEmailPopup === false) {
      SetConfigEmailPopup(true);
    } else {
      SetConfigEmailPopup(false);
    }
  };

  //Reload page while add perform
  const UpdateFromStepTwo = async (value) => {
    // ;
    if (value == true) {
      ConfigEmailAccount();
      SetChileValueTwo(true);
    }
  };

  //ConfigPop handle open and close
  const ConfigPopHandle = async () => {
    if (ConfigPop === false) {
      SetConfigPop(true);
    } else {
      SetConfigPop(false);
    }
  };

  //ConfigPop handle open and close
  const CreateManuallyPopHandle = async () => {
    // ;
    if (CreateManuallyPop === false) {
      SetCreateManuallyPop(true);
    } else {
      SetCreateManuallyPop(false);
    }
  };

  //ConfigPop handle open and close
  const AddStepPopHandle = async () => {
    // ;
    if (AddStepPopup === false) {
      SetAddStepPopup(true);
    } else {
      SetAddStepPopup(false);
    }
  };

  //ConfigPop handle open and close
  const AddStepTempletPopHandle = async (CCampaignStepID) => {
    
    if (AddStepTempletePopup === false) {
      SetAddStepTempletePopup(true);
      SetCampaignStepID(CCampaignStepID);
    } else {
      SetAddStepTempletePopup(false);
      SetCampaignStepID(CCampaignStepID);
    }
  };

  //Contact field handle
  const handleChange = async (field, e) => {
    let fields = ContactFields;
    fields[field] = e.target.value;
    SetContactFields(fields);
    if (ContactFields.FirstName != "") {
      this.state.errors.FirstName = null;
      this.state.FirstNameAvailable = null;
      CheckExistContactName(e.target.value);
    } else {
      this.state.FirstNameAvailable = null;
      this.state.errors.FirstName = null;
    }

    if (fields.LastName != "") {
      this.state.errors.LastName = null;
      this.state.LastNameAvailable = null;
      this.CheckExistContactName(e.target.value);
    } else {
      this.state.LastNameAvailable = null;
      this.state.errors.LastName = null;
    }
  };

  //name is exist or not
  const CheckExistContactName = async (FirstName) => {
    var str_in = {
      ClientID: this.state.ClientID,
      FirstName: FirstName,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ FirstNameAvailable: resdata.data.Data[0].FirstName });
        }
      }
    });
  };

  const handleChangeContactOption = async (val) => {
    
    var ContactTagIDArray = [];
    var ContactTagNameArray = [];
    var TagsArray = [];
    val?.map((item) => {
      ContactTagIDArray.push(item.cid);
    });

    SetContactTagSelectArray(ContactTagIDArray);

    // contact tag name
    val?.map((item) => {
      ContactTagNameArray.push(item.label);
    });
    SetContactTagsNamesArray(ContactTagNameArray);
    // tag id and name
    val?.map((item) => {
      TagsArray.push({
        ContactTagID: item.cid,
        TagName: item.label,
      });
    });
    SetTagArray(TagsArray);
    //console.log("TagArray123",this.state.TagArray)
  };

  //Add campaign contact manually
  const AddContactManually = async (e) => {
    
    document.getElementById("hideloding23").style.display = "block";
    e.preventDefault();
    e.currentTarget.disabled = true;
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Email = document.getElementById("Email").value;
    var Title = document.getElementById("Title").value;
    var Company = document.getElementById("Company").value;
    var CompanyNameLong = document.getElementById("CompanyNameLong").value;
    var Website = document.getElementById("Website").value;
    var Industry = document.getElementById("Industry").value;
    var Address1 = document.getElementById("Address1").value;
    var Address2 = document.getElementById("Address2").value;
    var City = document.getElementById("City").value;
    var State = document.getElementById("State").value;
    var Zip = document.getElementById("Zip").value;
    var LinkedInURL = document.getElementById("LinkedInURL").value;
    var Country = document.getElementById("Country").value;
    var ContactSourceID = document.getElementById("ContactSourceID").value;
    var ContactOwnerID = document.getElementById("ContactOwnerID").value;
    var ClientPOCID = document.getElementById("ClientPOCID").value;
    var ContactOwnerID = document.getElementById("ContactOwnerID").value;
    var ReasonUnqualifiedID = document.getElementById(
      "ReasonUnqualifiedID"
    ).value;
    var ContactCategory = document.getElementById("ContactCategory").value;
    var AccountCategory = document.getElementById("AccountCategory").value;
    var MobilePhone = document.getElementById("MobilePhone").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var CompanyRevenue = document.getElementById("CompanyRevenue").value;
    var CompanyEmployeeCount = document.getElementById(
      "CompanyEmployeeCount"
    ).value;
    var FollowUpDate = null;
    var ColdCallersID = document.getElementById("ColdCallersID").value;
    var SalesRep = document.getElementById("SalesRep").value;
    var OtherPhone1 = document.getElementById("OtherPhone1").value;
    var OtherPhone2 = document.getElementById("OtherPhone2").value;
    var ExternalAccountID = document.getElementById("ExternalAccountID").value;
    var ExternalContactID = document.getElementById("ExternalContactID").value;
    var ExternalField1 = document.getElementById("ExternalField1").value;
    var ExternalField2 = document.getElementById("ExternalField2").value;
    var ExternalField3 = document.getElementById("ExternalField3").value;
    var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
    //var ContactTagID = document.getElementById("ContactTagID").value;
    var Domain = Email.substring(Email.lastIndexOf("@") + 1);
    var IsDoNotCallContact =
      document.getElementById("IsDoNotCallContact").checked;

    //console.log(ContactTagID,"contact123")
    var ContactCustomFieldValueArray = [];
    // ContactCustomFieldData.map((contactvalue) => {
    //   console.log(contactvalue.CustomFieldID)
    //   var customval = document.getElementById(contactvalue.CustomFieldID).value;
    //   var conid = contactvalue.CustomFieldID;
    //   if (customval.length !== 0) {
    //     ContactCustomFieldValueArray.push({
    //       customval,
    //       conid,
    //     });
    //   }
    // });
    var data = {
      ClientID: ClientID,
      UserID: UserID,
      FirstName: FirstName,
      LastName: LastName,
      Email: Email,
      Title: Title,
      Company: Company,
      CompanyNameLong: CompanyNameLong,
      Website: Website,
      LinkedInURL: LinkedInURL,
      Industry: Industry,
      Address1: Address1,
      Address2: Address2,
      City: City,
      State: State,
      Zip: Zip,
      Country: Country,
      ContactSourceID: ContactSourceID,
      ContactOwnerID: ContactOwnerID,
      ClientPOCID: ClientPOCID,
      ReasonUnqualifiedID: ReasonUnqualifiedID,
      ContactCategory: ContactCategory,
      AccountCategory: AccountCategory,
      MobilePhone: MobilePhone,
      DirectPhone: DirectPhone,
      DirectPhoneExt: DirectPhoneExt,
      CompanyPhone: CompanyPhone,
      CompanyRevenue: CompanyRevenue,
      CompanyEmployeeCount: CompanyEmployeeCount,
      FollowUpDate: FollowUpDate,
      SalesRepID: ColdCallersID,
      SalesRep: SalesRep,
      ContactTagID: ContactTagSelectArray,
      ContactTags: ContactTagsNamesArray,
      Tagname: TagArray,
      OtherPhone1: OtherPhone1,
      OtherPhone2: OtherPhone2,
      ExternalAccountID: ExternalAccountID,
      ExternalContactID: ExternalContactID,
      ExternalField1: ExternalField1,
      ExternalField2: ExternalField2,
      ExternalField3: ExternalField3,
      IsDoNotCallContact: IsDoNotCallContact,
      CreatedBy: UserID,
      CreatedDate: new Date(),
      IsDeleted: false,
      EmailDomain: Domain,
      ContactsCustomFieldArray: ContactCustomFieldValueArray,
      AccountTypeID: null,
    };
    var ProspectDetails = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactsAdd",
      method: "POST",
      data: data,
    });

    if (ProspectDetails.data.StatusMessage == "SUCCESS") {
       ;
      document.getElementById("hideloding23").style.display = "block";
      SetProspectID(ProspectDetails.data.Data?._id);
      toast.success("Contact Added successfully.", "Add Contacts  ");
      var StrInProspectCampaign = {
        CampaignID: CampaignID,
        ProspectID: ProspectDetails.data.Data?._id,
        UserID: UserID,
        ClientID: ClientID,
        Role: Role,
      };
      // ;
      var ProspectCampaignQuery = await Axios({
        url:
          CommonConstants.MOL_APIURL + "/prospect_campaign/AddProspectCampaign",
        method: "POST",
        data: StrInProspectCampaign,
      });
      // ;
      if (ProspectCampaignQuery.data.StatusMessage == "SUCCESS") {
        // ;

        SetProspectCampaignID(
          ProspectCampaignQuery.data.Data.ProspectCampaignID
        );
        SetProspectFlag(true);
        document.getElementById("SubmitContactManually").disabled = false;
        ConfigPopHandle();
        CreateManuallyPopHandle();
        SetProspectFlag(false);
        document.getElementById("hideloding23").style.display = "none";
      } else {
        document.getElementById("hideloding23").style.display = "none";
        document.getElementById("SubmitContactManually").disabled = false;
        toast.error(ProspectCampaignQuery.data.Message);
      }
      //history.push("/contacts");
      document.getElementById("hideloding23").style.display = "none";
    } else {
      document.getElementById("SubmitContactManually").disabled = false;
      toast.error(ProspectDetails.data.Message);
      document.getElementById("hideloding23").style.display = "none";
    }
    document.getElementById("hideloding23").style.display = "none";
  };

  //Add Campaign stem and templete
  const AddCampaignStep = async (e) => {
    // ;
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Days = document.getElementById("Days").value;
    if (CampaignDetails.length > 0 && Days == 0) {
      toast.error(
        <div className="toastsize">
          Add Step
          <br />
          Day should be greater then zero
        </div>
      );
      document.getElementById("Step_submit").disabled = false;
    } else {
      var Subject = document.getElementById("AddCampaignSubject").value;
      var Body = SignatureSC.data;
      var StrInCamapignStep = {
        Role: Role,
        ClientID: ClientID,
        UserID: UserID,
        CampaignID: CampaignID,
        Days: Days,
        Subject: Subject,
        Body: Body,
      };
      var AddCampaignStepDetails = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaignstep/AddCampaignStep",
        method: "POST",
        data: StrInCamapignStep,
      });
      if (AddCampaignStepDetails.data.StatusMessage == "SUCCESS") {
  
        AddStepPopHandle();
        SetCampaignStepFlag(true);
        //document.getElementById('Step_submit').disabled = false
        SetCampaignStepFlag(false);
        SetSignatureSC({ data: "" });
      } else {
        //document.getElementById('Step_submit').disabled = false
      }
    }
  };

  const onDrop = async ({ removedIndex, addedIndex }) => {
    var ChangeOrderArr = arrayMoveImmutable(
      CampaignDetails,
      removedIndex,
      addedIndex
    );
    var StrIn = {
      UserID: UserID,
      ClientID: ClientID,
      Role: Role,
      ArrayList: ChangeOrderArr,
    };
    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/UpdateCampaignStepOrder",
      method: "POST",
      data: StrIn,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      //AddStepTempletPopHandle();
      //SetAnchorEl(null);
      GetCampaignStepDetails(ClientID, UserID, Role);
    }
  };

  const handleClose = () => {
    SetAnchorEl(null);
  };

  const handleClick = (event, DaysTmplate, StepID) => {
    // ;
    SetAnchorEl(event.currentTarget);
    SetDaysTemp(DaysTmplate);
    SetCampaignStepID(StepID);
  };

  const AddStepTemplatePopupSubmit = async () => {
    // signature2
    var Body = SignatureSC2.data;
    var Subject = document.getElementById("TemplateSubject").value;
    var StrInTemplateStep = {
      ClientID: ClientID,
      UserID: UserID,
      CampaignID: CampaignID,
      CampaignStepID: CampaignStepID,
      Body: Body,
      Subject: Subject,
    };
    // ;
    const rows = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignsteptemplate/AddCampaignStepTemplate",
      method: "POST",
      data: StrInTemplateStep,
    });
    // ;
    if (rows.data.StatusMessage == "SUCCESS") {
      SetSignatureSC2({ data: "" });
      AddStepTempletPopHandle();
    }
  };

  const handleChangeAccrdion = (id) => {
    if (activeIndexes.indexOf(id) !== -1) {
      setActiveIndexes(activeIndexes.filter((i) => i !== id));
    } else {
      setActiveIndexes([id]);
    }
  };

  //active and deactive template step

  //Get preview campaign step details
  const GetPreviewCampignStepDetails = async (CID, UID, URole, CCid) => {
    document.getElementById("hideloding23").style.display = "block";
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignID,
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/PreviewCampignStepGet",
      method: "POST",
      data: str_in,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      
      SetPreviewCampignStepDetails(rows.data.Data);
      SetPreviewEmailAccountDetails(rows.data.DataEmail);
      SetPreviewSmartVaribleBiggerThan(rows.data.DataSmartVaribleBiggerThan);
      SetPreviewSmartVaribleFasterThan(rows.data.DataSmartVaribleFasterThan);
      SetPreviewSmartVaribleMoreExcitingThan(
        rows.data.DataSmartVaribleMoreExcitingThan
      );
      SetPreviewSmartVaribleMascotCheer(rows.data.DataSmartVaribleMascotCheer);
      SetPreviewCallToAction(rows.data.DataCallToAction);
      SetPreviewAutomatorFeature(rows.data.DataAutomatorFeature);
      // console.log(rows)
      // SetSelectedPreviewCampign(rows.data.Data[0]);
      // if (PreviewCampignStepDetails.length > 0) {
      //   SetSelectedPreviewCampign(rows.data.Data[0]);
      // }
      GetPreviewCampaignStepDetailss(
        CID,
        UID,
        URole,
        Object.entries(SelectedPreviewCampign === undefined ? []:SelectedPreviewCampign).length === 0
          ? rows.data.Data[Math.floor(
            (Math.random() * ((rows.data.Data?.length - 1) + 1)))]
          : SelectedPreviewCampign,
        Object.entries(SelectedPreviewAccountEmail === undefined ? []:SelectedPreviewAccountEmail).length === 0
          ? rows.data.DataEmail[Math.floor(
            (Math.random() * ((rows.data.DataEmail?.length - 1) + 1)))]
          : SelectedPreviewAccountEmail,
        Object.entries(SelectedPreviewSmartVaribleBiggerThan === undefined ? []:SelectedPreviewSmartVaribleBiggerThan).length === 0
          ? rows.data.DataSmartVaribleBiggerThan[Math.floor(
            (Math.random() * ((rows.data.DataSmartVaribleBiggerThan?.length - 1) + 1)))]
          : SelectedPreviewSmartVaribleBiggerThan,
        Object.entries(SelectedPreviewSmartVaribleFasterThan === undefined ? []:SelectedPreviewSmartVaribleFasterThan).length === 0
          ? rows.data.DataSmartVaribleFasterThan[Math.floor(
            (Math.random() * ((rows.data.DataSmartVaribleFasterThan?.length - 1) + 1)))]
          : SelectedPreviewSmartVaribleFasterThan,
        Object.entries(SelectedPreviewSmartVaribleMoreExcitingThan === undefined ? []:SelectedPreviewSmartVaribleMoreExcitingThan).length === 0
          ? rows.data.DataSmartVaribleMoreExcitingThan[Math.floor(
            (Math.random() * ((rows.data.DataSmartVaribleMoreExcitingThan?.length - 1) + 1)))]
          : SelectedPreviewSmartVaribleMoreExcitingThan,
        Object.entries(SelectedPreviewSmartVaribleMascotCheer === undefined ? []:SelectedPreviewSmartVaribleMascotCheer).length === 0
          ? rows.data.DataSmartVaribleMascotCheer[Math.floor(
            (Math.random() * ((rows.data.DataSmartVaribleMascotCheer?.length - 1) + 1)))]
          : SelectedPreviewSmartVaribleMascotCheer,
        Object.entries(SelectedPreviewCallToAction === undefined ? []:SelectedPreviewCallToAction).length === 0
          ? rows.data.DataCallToAction[Math.floor(
            (Math.random() * ((rows.data.DataCallToAction?.length - 1) + 1)))]
          : SelectedPreviewCallToAction,
        Object.entries(SelectedPreviewAutomatorFeature === undefined ? []:SelectedPreviewAutomatorFeature).length === 0
          ? rows.data.DataAutomatorFeature[Math.floor(
            (Math.random() * ((rows.data.DataAutomatorFeature?.length - 1) + 1)))]
          : SelectedPreviewAutomatorFeature,
        rows.data.DataCustomVariable
      );
      document.getElementById("hideloding23").style.display = "none";
    }
    document.getElementById("hideloding23").style.display = "none";
  };

  const handleClickrandam = () => {
    // 
    // if (PreviewCampignStepDetails.length === 0) {
    //   return;
    // }

    // if (PreviewEmailAccountDetails.length === 0) {
    //   return;
    // }

    // if (PreviewSmartVaribleBiggerThan.length === 0) {
    //   return;
    // }

    // if (PreviewSmartVaribleFasterThan.length === 0) {
    //   return;
    // }

    // if (PreviewSmartVaribleMoreExcitingThan.length === 0) {
    //   return;
    // }

    // if (PreviewSmartVaribleMascotCheer.length === 0) {
    //   return;
    // }
    // if (PreviewCallToAction.length === 0) {
    //   return;
    // }

    // if (PreviewAutomatorFeature.length === 0) {
    //   return;
    // }
    // console.log(Math.random()*PreviewCampignStepDetails.length)

    const randomIndex = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );
    const randomIndexEmail = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );
    const randomIndexBiggerThan = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );
    const randomIndexFasterThan = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );
    const randomIndexMoreExcitingThan = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );
    const randomIndexMascotCheer = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );
    const randomIndexCallToAction = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );
    const randomIndexAutomatorFeature = Math.floor(
      (Math.random() * ((PreviewCampignStepDetails.length - 1) + 1))
    );

    const randomData = PreviewCampignStepDetails[randomIndex];
    const randomDataEmail = PreviewEmailAccountDetails[randomIndexEmail];
    const randomDataBiggerThan =
      PreviewSmartVaribleBiggerThan[randomIndexBiggerThan];
    const randomDataFasterThan =
      PreviewSmartVaribleFasterThan[randomIndexFasterThan];
    const randomDataMoreExcitingThan =
      PreviewSmartVaribleMoreExcitingThan[randomIndexMoreExcitingThan];
    const randomDataMascotCheer =
      PreviewSmartVaribleMascotCheer[randomIndexMascotCheer];
    const randomDataCallToAction = PreviewCallToAction[randomIndexCallToAction];
    const randomDataAutomatorFeature =
      PreviewAutomatorFeature[randomIndexAutomatorFeature];

    SetSelectedPreviewSmartVaribleBiggerThan(randomDataBiggerThan);
    SetSelectedPreviewSmartVaribleFasterThan(randomDataFasterThan);
    SetSelectedPreviewSmartVaribleMoreExcitingThan(randomDataMoreExcitingThan);
    SetSelectedPreviewSmartVaribleMascotCheer(randomDataMascotCheer);
    SetSelectedPreviewCampign(randomData);
    SetSelectedPreviewAccountEmail(randomDataEmail);
    SetSelectedPreviewCallToAction(randomDataCallToAction);
    SetSelectedPreviewAutomatorFeature(randomDataAutomatorFeature);

    GetPreviewCampignStepDetails(ClientID, UserID, Role, CampaignID);
  };

  //Get Preview Campaign step details
  const GetPreviewCampaignStepDetailss = async (
    CID,
    UID,
    URole,
    Arr,
    ArrEmail,
    ArrBiggerThan,
    ArrFasterThan,
    ArrMoreExcitingThan,
    ArrMascotCheer,
    ArrCallToAction,
    ArrAutomatorFeature,
    ArrCustomVar
  ) => {
    SetIsVisible(!IsVisible);
    //document.getElementById("hideloding23").style.display = "block";
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignID,
    };
    
    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaignstep/GetCampaignStep",
      method: "POST",
      data: str_in,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      //step details
      // SetPreviewCampignStepDetails(rows.data.Data);
      // SetSelectedPreviewCampign(rows.data.Data[0]);
      // if (PreviewCampignStepDetails.length > 0) {
      //   SetSelectedPreviewCampign(rows.data.Data[0]);
      // }
      //replace
      let CampignData = [];
      rows.data.Data.map((ReplaceRes) => {
        
        CampignData.push(ReplaceRes.CStepTemplate[0]);
        ReplaceRes.CStepTemplate.map((Val) => {
          
          var reg = Val.Body.match(/{(.*?)}/g);
          var regSubject = Val.Subject.match(/{(.*?)}/g);
          if (reg != null) {
            reg.forEach((Regex) => {
              
              if (Regex === "{Email}") {
                if (Arr.PDetails.Email != "" && Arr.PDetails.Email != null) {
                  let BodyData = Val.Body.replace(
                    "{Email}",
                    Arr.PDetails.Email
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{FirstName}") {
                if (
                  Arr.PDetails.FirstName != "" &&
                  Arr.PDetails.FirstName != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{FirstName}",
                    Arr.PDetails.FirstName
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{LastName}") {
                if (
                  Arr.PDetails.LastName != "" &&
                  Arr.PDetails.LastName != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{LastName}",
                    Arr.PDetails.LastName
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Title}") {
                if (Arr.PDetails.Title != "" && Arr.PDetails.Title != null) {
                  let BodyData = Val.Body.replace(
                    "{Title}",
                    Arr.PDetails.Title
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Company}") {
                if (
                  Arr.PDetails.Company != "" &&
                  Arr.PDetails.Company != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Company}",
                    Arr.PDetails.Company
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{CompanyNameLong}") {
                if (
                  Arr.PDetails.CompanyNameLong != "" &&
                  Arr.PDetails.CompanyNameLong != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{CompanyNameLong}",
                    Arr.PDetails.CompanyNameLong
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Website}") {
                if (
                  Arr.PDetails.Website != "" &&
                  Arr.PDetails.Website != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Website}",
                    Arr.PDetails.Website
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{CompanyPhone}") {
                if (
                  Arr.PDetails.CompanyPhone != "" &&
                  Arr.PDetails.CompanyPhone != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{CompanyPhone}",
                    Arr.PDetails.CompanyPhone
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{LinkedInURL}") {
                if (
                  Arr.PDetails.LinkedInURL != "" &&
                  Arr.PDetails.LinkedInURL != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{LinkedInURL}",
                    Arr.PDetails.LinkedInURL
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Industry}") {
                if (
                  Arr.PDetails.Industry != "" &&
                  Arr.PDetails.Industry != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Industry}",
                    Arr.PDetails.Industry
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Address1}") {
                if (
                  Arr.PDetails.Address1 != "" &&
                  Arr.PDetails.Address1 != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Address1}",
                    Arr.PDetails.Address1
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Address2}") {
                if (
                  Arr.PDetails.Address2 != "" &&
                  Arr.PDetails.Address2 != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Address2}",
                    Arr.PDetails.Address2
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{City}") {
                if (Arr.PDetails.City != "" && Arr.PDetails.City != null) {
                  let BodyData = Val.Body.replace("{City}", Arr.PDetails.City);
                  Val.Body = BodyData;
                }
              } else if (Regex === "{State}") {
                if (Arr.PDetails.State != "" && Arr.PDetails.State != null) {
                  let BodyData = Val.Body.replace(
                    "{State}",
                    Arr.PDetails.State
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Zip}") {
                if (Arr.PDetails.Zip != "" && Arr.PDetails.Zip != null) {
                  let BodyData = Val.Body.replace("{Zip}", Arr.PDetails.Zip);
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Country}") {
                if (
                  Arr.PDetails.Country != "" &&
                  Arr.PDetails.Country != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{Country}",
                    Arr.PDetails.Country
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{ContactCategory}") {
                if (
                  Arr.PDetails.ContactCategory != "" &&
                  Arr.PDetails.ContactCategory != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{ContactCategory}",
                    Arr.PDetails.ContactCategory
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AccountCategory}") {
                if (
                  Arr.PDetails.AccountCategory != "" &&
                  Arr.PDetails.AccountCategory != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AccountCategory}",
                    Arr.PDetails.AccountCategory
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AliasTitle}") {
                if (
                  ArrEmail.EAdetails.AliasTitle != "" &&
                  ArrEmail.EAdetails.AliasTitle != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AliasTitle}",
                    ArrEmail.EAdetails.AliasTitle
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AliasName}") {
                if (
                  ArrEmail.EAdetails.AliasName != "" &&
                  ArrEmail.EAdetails.AliasName != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AliasName}",
                    ArrEmail.EAdetails.AliasName
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{AliasEmail}") {
                if (
                  ArrEmail.EAdetails.SMTPFromEmail != "" &&
                  ArrEmail.EAdetails.SMTPFromEmail != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{AliasEmail}",
                    ArrEmail.EAdetails.SMTPFromEmail
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{SMART.BiggerThan}") {
                if (
                  ArrBiggerThan.SmartV.FeatureValue != "" &&
                  ArrBiggerThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{SMART.BiggerThan}",
                    ArrBiggerThan.SmartV.FeatureValue
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{SMART.FasterThan}") {
                if (
                  ArrFasterThan.SmartV.FeatureValue != "" &&
                  ArrFasterThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{SMART.FasterThan}",
                    ArrFasterThan.SmartV.FeatureValue
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{SMART.MoreExcitingThan}") {
                if (
                  ArrMoreExcitingThan.SmartV.FeatureValue != "" &&
                  ArrMoreExcitingThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{SMART.MoreExcitingThan}",
                    ArrMoreExcitingThan.SmartV.FeatureValue
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{SMART.MascotCheer}") {
                if (
                  ArrMascotCheer.SmartV.FeatureValue != "" &&
                  ArrMascotCheer.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Body.replace(
                    "{SMART.MascotCheer}",
                    ArrMascotCheer.SmartV.FeatureValue
                  );
                  Val.Body = BodyData;
                }
              } else if (Regex === "{Day}") {
                if (ArrCallToAction.CTADayDetails.length != 0) {
                  if (
                    ArrCallToAction.CTADayDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTADayDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Day}",
                      ArrCallToAction.CTADayDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Time}") {
                if (ArrCallToAction.CTATimeDetails.length != 0) {
                  if (
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Time}",
                      ArrCallToAction.CTATimeDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Length}") {
                if (ArrCallToAction.CTALengthDetails.length != 0) {
                  if (
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Length}",
                      ArrCallToAction.CTALengthDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Action}") {
                if (ArrCallToAction.CTAActionDetails.length != 0) {
                  if (
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Action}",
                      ArrCallToAction.CTAActionDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{CTA}") {
                if (ArrAutomatorFeature.CTAActionDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{CTA}",
                      ArrAutomatorFeature.AFCTADetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Closer}") {
                if (ArrAutomatorFeature.AFCloserDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Closer}",
                      ArrAutomatorFeature.AFCloserDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Greeting}") {
                if (ArrAutomatorFeature.AFGreetingDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Greeting}",
                      ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{SenderTitle}") {
                if (ArrAutomatorFeature.AFSenderTitleDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{SenderTitle}",
                      ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Opener}") {
                if (ArrAutomatorFeature.AFOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Opener}",
                      ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{FUOpener}") {
                if (ArrAutomatorFeature.AFFUOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{FUOpener}",
                      ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Subject}") {
                if (ArrAutomatorFeature.AFSubjectDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Subject}",
                      ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{OptOut}") {
                if (ArrAutomatorFeature.AFOptOutDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{OptOut}",
                      ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Problem}") {
                if (ArrAutomatorFeature.AFProblemDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Problem}",
                      ArrAutomatorFeature.AFProblemDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{ValueProp}") {
                if (ArrAutomatorFeature.AFValuePropDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{ValueProp}",
                      ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              } else if (Regex === "{Story}") {
                if (ArrAutomatorFeature.AFStoryDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Body.replace(
                      "{Story}",
                      ArrAutomatorFeature.AFStoryDetails[0].FeatureValue
                    );
                    Val.Body = BodyData;
                  }
                }
              }else{
                // console.log(ArrCustomVar,"sahil")
                if(ArrCustomVar?.length!=0){
                  for(let i=0;i<ArrCustomVar.length;i++){
                    // console.log(ArrCustomVar[i].Name,"devana",Regex)
                    if(`{${ArrCustomVar[i].Name}}`==Regex){
                      let BodyData = Val.Body.replace(
                        Regex,
                        ArrCustomVar[i].CustomVariableDetails[0].Value
                      );
                      Val.Body = BodyData;
                      var regsub = Val.Body.match(/{(.*?)}/g);
                      // console.log(regsub,ArrCustomVar[i].CustomVariableDetails[0].Value,"se11")
                      if (regsub != null) {
                        regsub.forEach((Regexsub) => {
                          if (Regexsub === "{Email}") {
                            if (Arr.PDetails.Email != "" && Arr.PDetails.Email != null) {
                              let BodyData = Val.Body.replace(
                                "{Email}",
                                Arr.PDetails.Email
                              );
                              Val.Body = BodyData;
                            }
                          } 
                          else if (Regexsub === "{FirstName}") {
                            if (
                              Arr.PDetails.FirstName != "" &&
                              Arr.PDetails.FirstName != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{FirstName}",
                                Arr.PDetails.FirstName
                              );
                              Val.Body = BodyData;
                            }
                          }
                           else if (Regexsub === "{LastName}") {
                            if (
                              Arr.PDetails.LastName != "" &&
                              Arr.PDetails.LastName != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{LastName}",
                                Arr.PDetails.LastName
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Title}") {
                            if (Arr.PDetails.Title != "" && Arr.PDetails.Title != null) {
                              let BodyData = Val.Body.replace(
                                "{Title}",
                                Arr.PDetails.Title
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Company}") {
                            if (
                              Arr.PDetails.Company != "" &&
                              Arr.PDetails.Company != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Company}",
                                Arr.PDetails.Company
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{CompanyNameLong}") {
                            if (
                              Arr.PDetails.CompanyNameLong != "" &&
                              Arr.PDetails.CompanyNameLong != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{CompanyNameLong}",
                                Arr.PDetails.CompanyNameLong
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Website}") {
                            if (
                              Arr.PDetails.Website != "" &&
                              Arr.PDetails.Website != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Website}",
                                Arr.PDetails.Website
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{CompanyPhone}") {
                            if (
                              Arr.PDetails.CompanyPhone != "" &&
                              Arr.PDetails.CompanyPhone != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{CompanyPhone}",
                                Arr.PDetails.CompanyPhone
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{LinkedInURL}") {
                            if (
                              Arr.PDetails.LinkedInURL != "" &&
                              Arr.PDetails.LinkedInURL != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{LinkedInURL}",
                                Arr.PDetails.LinkedInURL
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Industry}") {
                            if (
                              Arr.PDetails.Industry != "" &&
                              Arr.PDetails.Industry != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Industry}",
                                Arr.PDetails.Industry
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Address1}") {
                            if (
                              Arr.PDetails.Address1 != "" &&
                              Arr.PDetails.Address1 != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Address1}",
                                Arr.PDetails.Address1
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Address2}") {
                            if (
                              Arr.PDetails.Address2 != "" &&
                              Arr.PDetails.Address2 != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Address2}",
                                Arr.PDetails.Address2
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{City}") {
                            if (Arr.PDetails.City != "" && Arr.PDetails.City != null) {
                              let BodyData = Val.Body.replace("{City}", Arr.PDetails.City);
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{State}") {
                            if (Arr.PDetails.State != "" && Arr.PDetails.State != null) {
                              let BodyData = Val.Body.replace(
                                "{State}",
                                Arr.PDetails.State
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Zip}") {
                            if (Arr.PDetails.Zip != "" && Arr.PDetails.Zip != null) {
                              let BodyData = Val.Body.replace("{Zip}", Arr.PDetails.Zip);
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Country}") {
                            if (
                              Arr.PDetails.Country != "" &&
                              Arr.PDetails.Country != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{Country}",
                                Arr.PDetails.Country
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{ContactCategory}") {
                            if (
                              Arr.PDetails.ContactCategory != "" &&
                              Arr.PDetails.ContactCategory != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{ContactCategory}",
                                Arr.PDetails.ContactCategory
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AccountCategory}") {
                            if (
                              Arr.PDetails.AccountCategory != "" &&
                              Arr.PDetails.AccountCategory != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AccountCategory}",
                                Arr.PDetails.AccountCategory
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AliasTitle}") {
                            if (
                              ArrEmail.EAdetails.AliasTitle != "" &&
                              ArrEmail.EAdetails.AliasTitle != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AliasTitle}",
                                ArrEmail.EAdetails.AliasTitle
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AliasName}") {
                            if (
                              ArrEmail.EAdetails.AliasName != "" &&
                              ArrEmail.EAdetails.AliasName != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AliasName}",
                                ArrEmail.EAdetails.AliasName
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{AliasEmail}") {
                            if (
                              ArrEmail.EAdetails.SMTPFromEmail != "" &&
                              ArrEmail.EAdetails.SMTPFromEmail != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{AliasEmail}",
                                ArrEmail.EAdetails.SMTPFromEmail
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{SMART.BiggerThan}") {
                            if (
                              ArrBiggerThan.SmartV.FeatureValue != "" &&
                              ArrBiggerThan.SmartV.FeatureValue != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{SMART.BiggerThan}",
                                ArrBiggerThan.SmartV.FeatureValue
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{SMART.FasterThan}") {
                            if (
                              ArrFasterThan.SmartV.FeatureValue != "" &&
                              ArrFasterThan.SmartV.FeatureValue != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{SMART.FasterThan}",
                                ArrFasterThan.SmartV.FeatureValue
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{SMART.MoreExcitingThan}") {
                            if (
                              ArrMoreExcitingThan.SmartV.FeatureValue != "" &&
                              ArrMoreExcitingThan.SmartV.FeatureValue != null
                            ) {
                              let BodyData = Val.Body.replace(
                                "{SMART.MoreExcitingThan}",
                                ArrMoreExcitingThan.SmartV.FeatureValue
                              );
                              Val.Body = BodyData;
                            }
                          } else if (Regexsub === "{Day}") {
                            if (ArrCallToAction.CTADayDetails.length != 0) {
                              if (
                                ArrCallToAction.CTADayDetails[0].FeatureValue != "" &&
                                ArrCallToAction.CTADayDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Day}",
                                  ArrCallToAction.CTADayDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Time}") {
                            if (ArrCallToAction.CTATimeDetails.length != 0) {
                              if (
                                ArrCallToAction.CTATimeDetails[0].FeatureValue != "" &&
                                ArrCallToAction.CTATimeDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Time}",
                                  ArrCallToAction.CTATimeDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Length}") {
                            if (ArrCallToAction.CTALengthDetails.length != 0) {
                              if (
                                ArrCallToAction.CTALengthDetails[0].FeatureValue != "" &&
                                ArrCallToAction.CTALengthDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Length}",
                                  ArrCallToAction.CTALengthDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Action}") {
                            if (ArrCallToAction.CTAActionDetails.length != 0) {
                              if (
                                ArrCallToAction.CTAActionDetails[0].FeatureValue != "" &&
                                ArrCallToAction.CTAActionDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Action}",
                                  ArrCallToAction.CTAActionDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{CTA}") {
                            if (ArrAutomatorFeature.CTAActionDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFCTADetails[0].FeatureValue != "" &&
                                ArrAutomatorFeature.AFCTADetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{CTA}",
                                  ArrAutomatorFeature.AFCTADetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Closer}") {
                            if (ArrAutomatorFeature.AFCloserDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != "" &&
                                ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Closer}",
                                  ArrAutomatorFeature.AFCloserDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Greeting}") {
                            if (ArrAutomatorFeature.AFGreetingDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                                  "" &&
                                ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                                  null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Greeting}",
                                  ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{SenderTitle}") {
                            if (ArrAutomatorFeature.AFSenderTitleDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                                  "" &&
                                ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                                  null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{SenderTitle}",
                                  ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Opener}") {
                            if (ArrAutomatorFeature.AFOpenerDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != "" &&
                                ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Opener}",
                                  ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{FUOpener}") {
                            if (ArrAutomatorFeature.AFFUOpenerDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                                  "" &&
                                ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                                  null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{FUOpener}",
                                  ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Subject}") {
                            if (ArrAutomatorFeature.AFSubjectDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue !=
                                  "" &&
                                ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Subject}",
                                  ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{OptOut}") {
                            if (ArrAutomatorFeature.AFOptOutDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != "" &&
                                ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{OptOut}",
                                  ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Problem}") {
                            if (ArrAutomatorFeature.AFProblemDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFProblemDetails[0].FeatureValue !=
                                  "" &&
                                ArrAutomatorFeature.AFProblemDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Problem}",
                                  ArrAutomatorFeature.AFProblemDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{ValueProp}") {
                            if (ArrAutomatorFeature.AFValuePropDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                                  "" &&
                                ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                                  null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{ValueProp}",
                                  ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          } else if (Regexsub === "{Story}") {
                            if (ArrAutomatorFeature.AFStoryDetails.length != 0) {
                              if (
                                ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != "" &&
                                ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != null
                              ) {
                                let BodyData = Val.Body.replace(
                                  "{Story}",
                                  ArrAutomatorFeature.AFStoryDetails[0].FeatureValue
                                );
                                Val.Body = BodyData;
                              }
                            }
                          }
                        });
                      }
                    }
                  }
                }
              }
            });
          }
          if (regSubject != null) {
            regSubject.forEach((Regexs) => {
              if (Regexs === "{Email}") {
                if (Arr.PDetails.Email != "" && Arr.PDetails.Email != null) {
                  let BodyData = Val.Subject.replace(
                    "{Email}",
                    Arr.PDetails.Email
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{FirstName}") {
                if (
                  Arr.PDetails.FirstName != "" &&
                  Arr.PDetails.FirstName != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{FirstName}",
                    Arr.PDetails.FirstName
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{LastName}") {
                if (
                  Arr.PDetails.LastName != "" &&
                  Arr.PDetails.LastName != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{LastName}",
                    Arr.PDetails.LastName
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Title}") {
                if (Arr.PDetails.Title != "" && Arr.PDetails.Title != null) {
                  let BodyData = Val.Subject.replace(
                    "{Title}",
                    Arr.PDetails.Title
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Company}") {
                if (
                  Arr.PDetails.Company != "" &&
                  Arr.PDetails.Company != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Company}",
                    Arr.PDetails.Company
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{CompanyNameLong}") {
                if (
                  Arr.PDetails.CompanyNameLong != "" &&
                  Arr.PDetails.CompanyNameLong != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{CompanyNameLong}",
                    Arr.PDetails.CompanyNameLong
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Website}") {
                if (
                  Arr.PDetails.Website != "" &&
                  Arr.PDetails.Website != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Website}",
                    Arr.PDetails.Website
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{CompanyPhone}") {
                if (
                  Arr.PDetails.CompanyPhone != "" &&
                  Arr.PDetails.CompanyPhone != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{CompanyPhone}",
                    Arr.PDetails.CompanyPhone
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{LinkedInURL}") {
                if (
                  Arr.PDetails.LinkedInURL != "" &&
                  Arr.PDetails.LinkedInURL != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{LinkedInURL}",
                    Arr.PDetails.LinkedInURL
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Industry}") {
                if (
                  Arr.PDetails.Industry != "" &&
                  Arr.PDetails.Industry != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Industry}",
                    Arr.PDetails.Industry
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Address1}") {
                if (
                  Arr.PDetails.Address1 != "" &&
                  Arr.PDetails.Address1 != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Address1}",
                    Arr.PDetails.Address1
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Address2}") {
                if (
                  Arr.PDetails.Address2 != "" &&
                  Arr.PDetails.Address2 != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Address2}",
                    Arr.PDetails.Address2
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{City}") {
                if (Arr.PDetails.City != "" && Arr.PDetails.City != null) {
                  let BodyData = Val.Subject.replace(
                    "{City}",
                    Arr.PDetails.City
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{State}") {
                if (Arr.PDetails.State != "" && Arr.PDetails.State != null) {
                  let BodyData = Val.Subject.replace(
                    "{State}",
                    Arr.PDetails.State
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Zip}") {
                if (Arr.PDetails.Zip != "" && Arr.PDetails.Zip != null) {
                  let BodyData = Val.Subject.replace("{Zip}", Arr.PDetails.Zip);
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Country}") {
                if (
                  Arr.PDetails.Country != "" &&
                  Arr.PDetails.Country != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{Country}",
                    Arr.PDetails.Country
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{ContactCategory}") {
                if (
                  Arr.PDetails.ContactCategory != "" &&
                  Arr.PDetails.ContactCategory != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{ContactCategory}",
                    Arr.PDetails.ContactCategory
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AccountCategory}") {
                if (
                  Arr.PDetails.AccountCategory != "" &&
                  Arr.PDetails.AccountCategory != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AccountCategory}",
                    Arr.PDetails.AccountCategory
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AliasTitle}") {
                if (
                  ArrEmail.EAdetails.AliasTitle != "" &&
                  ArrEmail.EAdetails.AliasTitle != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AliasTitle}",
                    ArrEmail.EAdetails.AliasTitle
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AliasName}") {
                if (
                  ArrEmail.EAdetails.AliasName != "" &&
                  ArrEmail.EAdetails.AliasName != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AliasName}",
                    ArrEmail.EAdetails.AliasName
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{AliasEmail}") {
                if (
                  ArrEmail.EAdetails.SMTPFromEmail != "" &&
                  ArrEmail.EAdetails.SMTPFromEmail != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{AliasEmail}",
                    ArrEmail.EAdetails.SMTPFromEmail
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{SMART.BiggerThan}") {
                if (
                  ArrBiggerThan.SmartV.FeatureValue != "" &&
                  ArrBiggerThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.BiggerThan}",
                    ArrBiggerThan.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{SMART.FasterThan}") {
                if (
                  ArrFasterThan.SmartV.FeatureValue != "" &&
                  ArrFasterThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.FasterThan}",
                    ArrFasterThan.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{SMART.MoreExcitingThan}") {
                if (
                  ArrMoreExcitingThan.SmartV.FeatureValue != "" &&
                  ArrMoreExcitingThan.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.MoreExcitingThan}",
                    ArrMoreExcitingThan.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{SMART.MascotCheer}") {
                if (
                  ArrMascotCheer.SmartV.FeatureValue != "" &&
                  ArrMascotCheer.SmartV.FeatureValue != null
                ) {
                  let BodyData = Val.Subject.replace(
                    "{SMART.MascotCheer}",
                    ArrMascotCheer.SmartV.FeatureValue
                  );
                  Val.Subject = BodyData;
                }
              } else if (Regexs === "{Day}") {
                if (ArrCallToAction.CTADayDetails.length != 0) {
                  if (
                    ArrCallToAction.CTADayDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTADayDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Day}",
                      ArrCallToAction.CTADayDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Time}") {
                if (ArrCallToAction.CTATimeDetails.length != 0) {
                  if (
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTATimeDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Time}",
                      ArrCallToAction.CTATimeDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Length}") {
                if (ArrCallToAction.CTALengthDetails.length != 0) {
                  if (
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTALengthDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Length}",
                      ArrCallToAction.CTALengthDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Action}") {
                if (ArrCallToAction.CTAActionDetails.length != 0) {
                  if (
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != "" &&
                    ArrCallToAction.CTAActionDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Action}",
                      ArrCallToAction.CTAActionDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{CTA}") {
                if (ArrAutomatorFeature.CTAActionDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCTADetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{CTA}",
                      ArrAutomatorFeature.AFCTADetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Closer}") {
                if (ArrAutomatorFeature.AFCloserDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFCloserDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Closer}",
                      ArrAutomatorFeature.AFCloserDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Greeting}") {
                if (ArrAutomatorFeature.AFGreetingDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Greeting}",
                      ArrAutomatorFeature.AFGreetingDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{SenderTitle}") {
                if (ArrAutomatorFeature.AFSenderTitleDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{SenderTitle}",
                      ArrAutomatorFeature.AFSenderTitleDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Opener}") {
                if (ArrAutomatorFeature.AFOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Opener}",
                      ArrAutomatorFeature.AFOpenerDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{FUOpener}") {
                if (ArrAutomatorFeature.AFFUOpenerDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{FUOpener}",
                      ArrAutomatorFeature.AFFUOpenerDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Subject}") {
                if (ArrAutomatorFeature.AFSubjectDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Subject}",
                      ArrAutomatorFeature.AFSubjectDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{OptOut}") {
                if (ArrAutomatorFeature.AFOptOutDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{OptOut}",
                      ArrAutomatorFeature.AFOptOutDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Problem}") {
                if (ArrAutomatorFeature.AFProblemDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFProblemDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Problem}",
                      ArrAutomatorFeature.AFProblemDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{ValueProp}") {
                if (ArrAutomatorFeature.AFValuePropDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      "" &&
                    ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue !=
                      null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{ValueProp}",
                      ArrAutomatorFeature.AFValuePropDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              } else if (Regexs === "{Story}") {
                if (ArrAutomatorFeature.AFStoryDetails.length != 0) {
                  if (
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != "" &&
                    ArrAutomatorFeature.AFStoryDetails[0].FeatureValue != null
                  ) {
                    let BodyData = Val.Subject.replace(
                      "{Story}",
                      ArrAutomatorFeature.AFStoryDetails[0].FeatureValue
                    );
                    Val.Subject = BodyData;
                  }
                }
              }
            });
          }
        });
      });
      setActiveIndexes(CampignData);
      SetPreviewCampaignDetails(rows.data.Data);
    }
    document.getElementById("hideloding23").style.display = "none";
    SetIsVisible(!IsVisible);
  };

  // csv

  // import black list csv
  const ContactSaveCsv = () => {
    if (csvData.length >= 100) {
      SetShowPopupImport(true);
      toast.success("Contact file size is more than 100 successfully.");
    } else {
      SetIsVisible(!IsVisible);
      var saveAllContacts = [];
      var FirstName = parseInt(document.getElementById("FirstName").value);
      if (FirstName >= 0) {
        var ObjFirstName = {
          FieldAlias: "First Name",
          Fieldname: "FirstName",
          Customfield: null,
          index: FirstName,
        };
        saveAllContacts.push(ObjFirstName);
      }
      var LastName = parseInt(document.getElementById("LastName").value);
      if (LastName >= 0) {
        var ObjLastName = {
          FieldAlias: "Last Name",
          Fieldname: "LastName",
          Customfield: null,
          index: LastName,
        };
        saveAllContacts.push(ObjLastName);
      }
      var Email = parseInt(document.getElementById("Email").value);
      if (Email >= 0) {
        var ObjEmail = {
          FieldAlias: "Email",
          Fieldname: "Email",
          Customfield: null,
          index: Email,
        };
        saveAllContacts.push(ObjEmail);
      }
      var Title = parseInt(document.getElementById("Title").value);
      if (Title >= 0) {
        var ObjTitle = {
          FieldAlias: "Title",
          Fieldname: "Title",
          Customfield: null,
          index: Title,
        };
        saveAllContacts.push(ObjTitle);
      }
      var Company = parseInt(document.getElementById("Company").value);
      if (Company >= 0) {
        var ObjCompany = {
          FieldAlias: "Company",
          Fieldname: "Company",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompany);
      }
      var CompanyNameLong = parseInt(
        document.getElementById("CompanyNameLong").value
      );
      if (CompanyNameLong >= 0) {
        var ObjCompanyNameLong = {
          FieldAlias: "Company Name Long",
          Fieldname: "CompanyNameLong",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompanyNameLong);
      }
      var Website = parseInt(document.getElementById("Website").value);
      if (Website >= 0) {
        var ObjWebsite = {
          FieldAlias: "Website",
          Fieldname: "Website",
          Customfield: null,
          index: Website,
        };
        saveAllContacts.push(ObjWebsite);
      }
      var CompanyPhone = parseInt(
        document.getElementById("CompanyPhone").value
      );
      if (CompanyPhone >= 0) {
        var ObjCompanyPhone = {
          FieldAlias: "Company Phone",
          Fieldname: "CompanyPhone",
          Customfield: null,
          index: CompanyPhone,
        };
        saveAllContacts.push(ObjCompanyPhone);
      }
      var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
      if (DirectPhone >= 0) {
        var ObjDirectPhone = {
          FieldAlias: "Direct Phone",
          Fieldname: "DirectPhone",
          Customfield: null,
          index: DirectPhone,
        };
        saveAllContacts.push(ObjDirectPhone);
      }

      var DirectPhoneExt = parseInt(
        document.getElementById("DirectPhoneExt").value
      );
      if (DirectPhoneExt >= 0) {
        var ObjDirectPhoneExt = {
          FieldAlias: "Direct Phone Ext",
          Fieldname: "DirectPhoneExt",
          Customfield: null,
          index: DirectPhoneExt,
        };
        saveAllContacts.push(ObjDirectPhoneExt);
      }
      var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
      if (LinkedInURL >= 0) {
        var ObjLinkedInURL = {
          FieldAlias: "LinkedIn URL",
          Fieldname: "LinkedInURL",
          Customfield: null,
          index: LinkedInURL,
        };
        saveAllContacts.push(ObjLinkedInURL);
      }
      var Industry = parseInt(document.getElementById("Industry").value);
      if (Industry >= 0) {
        var ObjIndustry = {
          FieldAlias: "Industry",
          Fieldname: "Industry",
          Customfield: null,
          index: Industry,
        };
        saveAllContacts.push(ObjIndustry);
      }

      var Address1 = parseInt(document.getElementById("Address1").value);
      if (Address1 >= 0) {
        var ObjAddress1 = {
          FieldAlias: "Address 1",
          Fieldname: "Address1",
          Customfield: null,
          index: Address1,
        };
        saveAllContacts.push(ObjAddress1);
      }

      var Address2 = parseInt(document.getElementById("Address2").value);
      if (Address2 >= 0) {
        var ObjAddress2 = {
          FieldAlias: "Address 2",
          Fieldname: "Address2",
          Customfield: null,
          index: Address2,
        };
        saveAllContacts.push(ObjAddress2);
      }

      var City = parseInt(document.getElementById("City").value);
      if (City >= 0) {
        var ObjCity = {
          FieldAlias: "City",
          Fieldname: "City",
          Customfield: null,
          index: City,
        };

        saveAllContacts.push(ObjCity);
      }
      var State = parseInt(document.getElementById("State").value);
      if (State >= 0) {
        var ObjState = {
          FieldAlias: "State",
          Fieldname: "State",
          Customfield: null,
          index: State,
        };
        saveAllContacts.push(ObjState);
      }

      var Zip = parseInt(document.getElementById("Zip").value);
      if (Zip >= 0) {
        var ObjZip = {
          FieldAlias: "Zip",
          Fieldname: "Zip",
          Customfield: null,
          index: Zip,
        };
        saveAllContacts.push(ObjZip);
      }

      var Country = parseInt(document.getElementById("Country").value);
      if (Country >= 0) {
        var ObjCountry = {
          FieldAlias: "Country",
          Fieldname: "Country",
          Customfield: null,
          index: Country,
        };
        saveAllContacts.push(ObjCountry);
      }

      var ContactCategory = parseInt(
        document.getElementById("ContactCategory").value
      );
      if (ContactCategory >= 0) {
        var ObjContactCategory = {
          FieldAlias: "Contact Category",
          Fieldname: "ContactCategory",
          Customfield: null,
          index: ContactCategory,
        };
        saveAllContacts.push(ObjContactCategory);
      }
      var AccountCategory = parseInt(
        document.getElementById("AccountCategory").value
      );
      if (AccountCategory >= 0) {
        var ObjAccountCategory = {
          FieldAlias: "Account Category",
          Fieldname: "AccountCategory",
          Customfield: null,
          index: AccountCategory,
        };
        saveAllContacts.push(ObjAccountCategory);
      }

      var ValidationScore = parseInt(
        document.getElementById("ValidationScore").value
      );
      if (ValidationScore >= 0) {
        var ObjValidationScore = {
          FieldAlias: "Validation Score",
          Fieldname: "ValidationScore",
          Customfield: null,
          index: ValidationScore,
        };
        saveAllContacts.push(ObjValidationScore);
      }
      var ListName = document.getElementById("ListName").value;
      if (ListName != "") {
        var ObjListName = {
          FieldAlias: "List Name",
          Fieldname: "ListName",
          Customfield: null,
          index: ListName,
        };
        saveAllContacts.push(ObjListName);
      }

      var ContactSource = parseInt(
        document.getElementById("ContactSource").value
      );
      if (ContactSource >= 0) {
        var ObjContactSource = {
          FieldAlias: "Contact Source",
          Fieldname: "ContactSource",
          Customfield: null,
          index: ContactSource,
        };
        saveAllContacts.push(ObjContactSource);
      }
      var ContactOwner = parseInt(
        document.getElementById("ContactOwner").value
      );
      if (ContactOwner >= 0) {
        var ObjContactOwner = {
          FieldAlias: "Contact Owner",
          Fieldname: "ContactOwner",
          Customfield: null,
          index: ContactOwner,
        };
        saveAllContacts.push(ObjContactOwner);
      }
      var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
      if (ClientPOC >= 0) {
        var ObjClientPOC = {
          FieldAlias: "Client POC",
          Fieldname: "ClientPOC",
          Customfield: null,
          index: ClientPOC,
        };
        saveAllContacts.push(ObjClientPOC);
      }
      var ReasonUnqualified = parseInt(
        document.getElementById("ReasonUnqualified").value
      );
      if (ReasonUnqualified >= 0) {
        var ObjReasonUnqualified = {
          FieldAlias: "Reason Unqualified",
          Fieldname: "ReasonUnqualified",
          Customfield: null,
          index: ReasonUnqualified,
        };
        saveAllContacts.push(ObjReasonUnqualified);
      }
      var AccountType = parseInt(document.getElementById("AccountType").value);
      if (AccountType >= 0) {
        var ObjAccountType = {
          FieldAlias: "Account Type",
          Fieldname: "AccountType",
          Customfield: null,
          index: AccountType,
        };
        saveAllContacts.push(ObjAccountType);
      }
      var CompanyRevenue = parseInt(
        document.getElementById("CompanyRevenue").value
      );
      if (CompanyRevenue >= 0) {
        var ObjCompanyRevenue = {
          FieldAlias: "Company Revenue",
          Fieldname: "CompanyRevenue",
          Customfield: null,
          index: CompanyRevenue,
        };
        saveAllContacts.push(ObjCompanyRevenue);
      }
      var EmployeeCount = parseInt(
        document.getElementById("EmployeeCount").value
      );
      if (EmployeeCount >= 0) {
        var ObjEmployeeCount = {
          FieldAlias: "Employee Count",
          Fieldname: "EmployeeCount",
          Customfield: null,
          index: EmployeeCount,
        };
        saveAllContacts.push(ObjEmployeeCount);
      }

      var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
      if (MobilePhone >= 0) {
        var ObjMobilePhone = {
          FieldAlias: "Mobile Phone",
          Fieldname: "MobilePhone",
          Customfield: null,
          index: MobilePhone,
        };
        saveAllContacts.push(ObjMobilePhone);
      }

      var IsDoNotCallContact = parseInt(
        document.getElementById("IsDoNotCallContact").value
      );
      if (IsDoNotCallContact >= 0) {
        var ObjIsDoNotCallContact = {
          FieldAlias: "Do not call contact",
          Fieldname: "IsDoNotCallContact",
          Customfield: null,
          index: IsDoNotCallContact,
        };
        saveAllContacts.push(ObjIsDoNotCallContact);
      }

      var SalesRep = parseInt(document.getElementById("SalesRep").value);
      if (SalesRep >= 0) {
        var ObjSalesRep = {
          FieldAlias: "Sales Rep",
          Fieldname: "SalesRep",
          Customfield: null,
          index: SalesRep,
        };
        saveAllContacts.push(ObjSalesRep);
      }

      var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
      if (ColdCaller >= 0) {
        var ObjColdCaller = {
          FieldAlias: "Sales Development Reps",
          Fieldname: "ColdCaller",
          Customfield: null,
          index: ColdCaller,
        };
        saveAllContacts.push(ObjColdCaller);
      }

      var Tag = parseInt(document.getElementById("Tag").value);
      if (Tag >= 0) {
        var ObjTag = {
          FieldAlias: "Tag",
          Fieldname: "Tag",
          Customfield: null,
          index: Tag,
        };
        saveAllContacts.push(ObjTag);
      }

      var CallNotes = parseInt(document.getElementById("CallNotes").value);
      if (CallNotes >= 0) {
        var ObjCallNotes = {
          FieldAlias: "Call Notes",
          Fieldname: "CallNotes",
          Customfield: null,
          index: CallNotes,
        };
        saveAllContacts.push(ObjCallNotes);
      }

      var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
      if (OtherPhone1 >= 0) {
        var ObjOtherPhone1 = {
          FieldAlias: "Other Phone 1",
          Fieldname: "OtherPhone1",
          Customfield: null,
          index: OtherPhone1,
        };
        saveAllContacts.push(ObjOtherPhone1);
      }
      var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
      if (OtherPhone2 >= 0) {
        var ObjOtherPhone2 = {
          FieldAlias: "Other Phone 2",
          Fieldname: "OtherPhone2",
          Customfield: null,
          index: OtherPhone2,
        };
        saveAllContacts.push(ObjOtherPhone2);
      }

      var ContactID = parseInt(document.getElementById("ContactID").value);
      if (ContactID >= 0) {
        var ObjContactID = {
          FieldAlias: "ContactID",
          Fieldname: "ContactID",
          Customfield: null,
          index: ContactID,
        };
        saveAllContacts.push(ObjContactID);
      }

      var IsUpdateExistContacts = document.getElementById(
        "IsUpdateExistContacts"
      ).checked;
      // if (IsUpdateExistContacts == false) {
      //   IsUpdateExistContacts = true;
      // } else {
      //   IsUpdateExistContacts = false;
      // }
      var IsInsertNullValues =
        document.getElementById("IsInsertNullValues").checked;
      var IsDoNotupdatecompanyname = document.getElementById(
        "IsDoNotupdatecompanyname"
      ).checked;
      var IsMoveExitsingcurrentcampaign = document.getElementById(
        "IsMoveExitsingcurrentcampaign"
      ).checked;
      var IsInsertContactAlreadyReplaied = document.getElementById(
        "IsInsertContactAlreadyReplaied"
      ).checked;

      var csvdata = csvData;
      var csvdata2 = csvData.length;

      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ObjData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/SalesReplyContact",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          saveAllContacts: saveAllContacts,
          ClientID: ClientID,
          UserID: UserID,
          CampaignID: CampaignID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
          IsUpdateExistContacts: IsUpdateExistContacts === false ? true : false,
          IsInsertNullValues: IsInsertNullValues,
          IsDoNotupdatecompanyname: IsDoNotupdatecompanyname,
          IsMoveExistContacts: IsMoveExitsingcurrentcampaign,
          IsAllowAlreadyRepliedContacts: IsInsertContactAlreadyReplaied,
          IsCsvFileName:CsvFileName

        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/prospect_campaign/ProspectCampaignImport",
          method: "POST",
          data: ObjData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success("Contacts Save Imports");
            SetTotal(res.data.TotalContacts);
            SetTotalSucess(res.data.TotalSuccess);
            SetTotalError(res.data.TotalError);
            SetTotalDuplicate(res.data.TotalDuplicate);
            SetTotalUpdated(res.data.TotalUpdated);
            SetTotalEmailValiDationScoreD(res.data.TotalEmailValiDationScoreD);
            SetTotalEmailValiDationScoreF(res.data.TotalEmailValiDationScoreF);
            SetTotalSkipped(res.data.TotalSkipped);
            SetTotalContactBlacklisted(res.data.TotalContactBlacklisted);
            SetTotalRemoved(res.data.TotalRemoved);
            SetTotalDomainBlackListed(res.data.TotalDomainBlackListed);
            SetTotalGlobalDomainBlackListed(res.data.TotalGlobalDomainBlackListed);
            SetTotalDefaultCountryBlackListed(res.data.TotalDefaultCountryBlackListed);
            SetTotalCountryBlackListed(res.data.TotalCountryBlackListed);
            SetTotalReplied(res.data.TotalReplied);
            SetData(res.data.Data);
            SetRlen(res.data.Data.length);
            SetFlen(res.data.Data.length);
            const totalPages = Math.ceil(
              res.data.Data.length / CSVERowsPerPage
            );
            SetCountPage(totalPages);
            // SetShowPopupImport(false)
            // SetShowImportContactCsv(false)
            // SetConfigPop(false)

            document.getElementById("total-div-display").style.display =
              "block";
            document.getElementById("csv_error_table").style.display = "block";
            // document.getElementById("map_attribute").style.display = "none";
            // document.getElementById("import_csv").style.display = "none";
            // document.getElementById("hideloding").style.display = "none";
            SetIsVisible(false);
          }
        });
      });
    }
  };
  // import for email 100+csv
  const ContactSaveCsvAddBulk = () => {

    var OriginalFileName = files.name;
    var onlyname = OriginalFileName.substring(
      0,
      OriginalFileName.lastIndexOf(".csv")
    );
   

    var UserEmail = document.getElementById("UserEmail").value;
    var saveAllContacts = [];
    var FirstName = parseInt(document.getElementById("FirstName").value);
    if (FirstName >= 0) {
      var ObjFirstName = {
        FieldAlias: "First Name",
        Fieldname: "FirstName",
        Customfield: null,
        index: FirstName,
      };
      saveAllContacts.push(ObjFirstName);
    }
    var LastName = parseInt(document.getElementById("LastName").value);
    if (LastName >= 0) {
      var ObjLastName = {
        FieldAlias: "Last Name",
        Fieldname: "LastName",
        Customfield: null,
        index: LastName,
      };
      saveAllContacts.push(ObjLastName);
    }
    var Email = parseInt(document.getElementById("Email").value);
    if (Email >= 0) {
      var ObjEmail = {
        FieldAlias: "Email",
        Fieldname: "Email",
        Customfield: null,
        index: Email,
      };
      saveAllContacts.push(ObjEmail);
    }
    var Title = parseInt(document.getElementById("Title").value);
    if (Title >= 0) {
      var ObjTitle = {
        FieldAlias: "Title",
        Fieldname: "Title",
        Customfield: null,
        index: Title,
      };
      saveAllContacts.push(ObjTitle);
    }
    var Company = parseInt(document.getElementById("Company").value);
    if (Company >= 0) {
      var ObjCompany = {
        FieldAlias: "Company",
        Fieldname: "Company",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompany);
    }
    var CompanyNameLong = parseInt(
      document.getElementById("CompanyNameLong").value
    );
    if (CompanyNameLong >= 0) {
      var ObjCompanyNameLong = {
        FieldAlias: "Company Name Long",
        Fieldname: "CompanyNameLong",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompanyNameLong);
    }
    var Website = parseInt(document.getElementById("Website").value);
    if (Website >= 0) {
      var ObjWebsite = {
        FieldAlias: "Website",
        Fieldname: "Website",
        Customfield: null,
        index: Website,
      };
      saveAllContacts.push(ObjWebsite);
    }
    var CompanyPhone = parseInt(document.getElementById("CompanyPhone").value);
    if (CompanyPhone >= 0) {
      var ObjCompanyPhone = {
        FieldAlias: "Company Phone",
        Fieldname: "CompanyPhone",
        Customfield: null,
        index: CompanyPhone,
      };
      saveAllContacts.push(ObjCompanyPhone);
    }
    var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
    if (DirectPhone >= 0) {
      var ObjDirectPhone = {
        FieldAlias: "Direct Phone",
        Fieldname: "DirectPhone",
        Customfield: null,
        index: DirectPhone,
      };
      saveAllContacts.push(ObjDirectPhone);
    }

    var DirectPhoneExt = parseInt(
      document.getElementById("DirectPhoneExt").value
    );
    if (DirectPhoneExt >= 0) {
      var ObjDirectPhoneExt = {
        FieldAlias: "Direct Phone Ext",
        Fieldname: "DirectPhoneExt",
        Customfield: null,
        index: DirectPhoneExt,
      };
      saveAllContacts.push(ObjDirectPhoneExt);
    }
    var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
    if (LinkedInURL >= 0) {
      var ObjLinkedInURL = {
        FieldAlias: "LinkedIn URL",
        Fieldname: "LinkedInURL",
        Customfield: null,
        index: LinkedInURL,
      };
      saveAllContacts.push(ObjLinkedInURL);
    }
    var Industry = parseInt(document.getElementById("Industry").value);
    if (Industry >= 0) {
      var ObjIndustry = {
        FieldAlias: "Industry",
        Fieldname: "Industry",
        Customfield: null,
        index: Industry,
      };
      saveAllContacts.push(ObjIndustry);
    }

    var Address1 = parseInt(document.getElementById("Address1").value);
    if (Address1 >= 0) {
      var ObjAddress1 = {
        FieldAlias: "Address 1",
        Fieldname: "Address1",
        Customfield: null,
        index: Address1,
      };
      saveAllContacts.push(ObjAddress1);
    }

    var Address2 = parseInt(document.getElementById("Address2").value);
    if (Address2 >= 0) {
      var ObjAddress2 = {
        FieldAlias: "Address 2",
        Fieldname: "Address2",
        Customfield: null,
        index: Address2,
      };
      saveAllContacts.push(ObjAddress2);
    }

    var City = parseInt(document.getElementById("City").value);
    if (City >= 0) {
      var ObjCity = {
        FieldAlias: "City",
        Fieldname: "City",
        Customfield: null,
        index: City,
      };

      saveAllContacts.push(ObjCity);
    }
    var State = parseInt(document.getElementById("State").value);
    if (State >= 0) {
      var ObjState = {
        FieldAlias: "State",
        Fieldname: "State",
        Customfield: null,
        index: State,
      };
      saveAllContacts.push(ObjState);
    }

    var Zip = parseInt(document.getElementById("Zip").value);
    if (Zip >= 0) {
      var ObjZip = {
        FieldAlias: "Zip",
        Fieldname: "Zip",
        Customfield: null,
        index: Zip,
      };
      saveAllContacts.push(ObjZip);
    }

    var Country = parseInt(document.getElementById("Country").value);
    if (Country >= 0) {
      var ObjCountry = {
        FieldAlias: "Country",
        Fieldname: "Country",
        Customfield: null,
        index: Country,
      };
      saveAllContacts.push(ObjCountry);
    }

    var ContactCategory = parseInt(
      document.getElementById("ContactCategory").value
    );
    if (ContactCategory >= 0) {
      var ObjContactCategory = {
        FieldAlias: "Contact Category",
        Fieldname: "ContactCategory",
        Customfield: null,
        index: ContactCategory,
      };
      saveAllContacts.push(ObjContactCategory);
    }
    var AccountCategory = parseInt(
      document.getElementById("AccountCategory").value
    );
    if (AccountCategory >= 0) {
      var ObjAccountCategory = {
        FieldAlias: "Account Category",
        Fieldname: "AccountCategory",
        Customfield: null,
        index: AccountCategory,
      };
      saveAllContacts.push(ObjAccountCategory);
    }

    var ValidationScore = parseInt(
      document.getElementById("ValidationScore").value
    );
    if (ValidationScore >= 0) {
      var ObjValidationScore = {
        FieldAlias: "Validation Score",
        Fieldname: "ValidationScore",
        Customfield: null,
        index: ValidationScore,
      };
      saveAllContacts.push(ObjValidationScore);
    }
    var ListName = document.getElementById("ListName").value;
    if (ListName != "") {
      var ObjListName = {
        FieldAlias: "List Name",
        Fieldname: "ListName",
        Customfield: null,
        index: ListName,
      };
      saveAllContacts.push(ObjListName);
    }

    var ContactSource = parseInt(
      document.getElementById("ContactSource").value
    );
    if (ContactSource >= 0) {
      var ObjContactSource = {
        FieldAlias: "Contact Source",
        Fieldname: "ContactSource",
        Customfield: null,
        index: ContactSource,
      };
      saveAllContacts.push(ObjContactSource);
    }
    var ContactOwner = parseInt(document.getElementById("ContactOwner").value);
    if (ContactOwner >= 0) {
      var ObjContactOwner = {
        FieldAlias: "Contact Owner",
        Fieldname: "ContactOwner",
        Customfield: null,
        index: ContactOwner,
      };
      saveAllContacts.push(ObjContactOwner);
    }
    var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
    if (ClientPOC >= 0) {
      var ObjClientPOC = {
        FieldAlias: "Client POC",
        Fieldname: "ClientPOC",
        Customfield: null,
        index: ClientPOC,
      };
      saveAllContacts.push(ObjClientPOC);
    }
    var ReasonUnqualified = parseInt(
      document.getElementById("ReasonUnqualified").value
    );
    if (ReasonUnqualified >= 0) {
      var ObjReasonUnqualified = {
        FieldAlias: "Reason Unqualified",
        Fieldname: "ReasonUnqualified",
        Customfield: null,
        index: ReasonUnqualified,
      };
      saveAllContacts.push(ObjReasonUnqualified);
    }
    var AccountType = parseInt(document.getElementById("AccountType").value);
    if (AccountType >= 0) {
      var ObjAccountType = {
        FieldAlias: "Account Type",
        Fieldname: "AccountType",
        Customfield: null,
        index: AccountType,
      };
      saveAllContacts.push(ObjAccountType);
    }
    var CompanyRevenue = parseInt(
      document.getElementById("CompanyRevenue").value
    );
    if (CompanyRevenue >= 0) {
      var ObjCompanyRevenue = {
        FieldAlias: "Company Revenue",
        Fieldname: "CompanyRevenue",
        Customfield: null,
        index: CompanyRevenue,
      };
      saveAllContacts.push(ObjCompanyRevenue);
    }
    var EmployeeCount = parseInt(
      document.getElementById("EmployeeCount").value
    );
    if (EmployeeCount >= 0) {
      var ObjEmployeeCount = {
        FieldAlias: "Employee Count",
        Fieldname: "EmployeeCount",
        Customfield: null,
        index: EmployeeCount,
      };
      saveAllContacts.push(ObjEmployeeCount);
    }

    var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
    if (MobilePhone >= 0) {
      var ObjMobilePhone = {
        FieldAlias: "Mobile Phone",
        Fieldname: "MobilePhone",
        Customfield: null,
        index: MobilePhone,
      };
      saveAllContacts.push(ObjMobilePhone);
    }

    var IsDoNotCallContact = parseInt(
      document.getElementById("IsDoNotCallContact").value
    );
    if (IsDoNotCallContact >= 0) {
      var ObjIsDoNotCallContact = {
        FieldAlias: "Do not call contact",
        Fieldname: "IsDoNotCallContact",
        Customfield: null,
        index: IsDoNotCallContact,
      };
      saveAllContacts.push(ObjIsDoNotCallContact);
    }

    var SalesRep = parseInt(document.getElementById("SalesRep").value);
    if (SalesRep >= 0) {
      var ObjSalesRep = {
        FieldAlias: "Sales Rep",
        Fieldname: "SalesRep",
        Customfield: null,
        index: SalesRep,
      };
      saveAllContacts.push(ObjSalesRep);
    }

    var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
    if (ColdCaller >= 0) {
      var ObjColdCaller = {
        FieldAlias: "Sales Development Reps",
        Fieldname: "ColdCaller",
        Customfield: null,
        index: ColdCaller,
      };
      saveAllContacts.push(ObjColdCaller);
    }

    var Tag = parseInt(document.getElementById("Tag").value);
    if (Tag >= 0) {
      var ObjTag = {
        FieldAlias: "Tag",
        Fieldname: "Tag",
        Customfield: null,
        index: Tag,
      };
      saveAllContacts.push(ObjTag);
    }

    var CallNotes = parseInt(document.getElementById("CallNotes").value);
    if (CallNotes >= 0) {
      var ObjCallNotes = {
        FieldAlias: "Call Notes",
        Fieldname: "CallNotes",
        Customfield: null,
        index: CallNotes,
      };
      saveAllContacts.push(ObjCallNotes);
    }

    var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
    if (OtherPhone1 >= 0) {
      var ObjOtherPhone1 = {
        FieldAlias: "Other Phone 1",
        Fieldname: "OtherPhone1",
        Customfield: null,
        index: OtherPhone1,
      };
      saveAllContacts.push(ObjOtherPhone1);
    }
    var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
    if (OtherPhone2 >= 0) {
      var ObjOtherPhone2 = {
        FieldAlias: "Other Phone 2",
        Fieldname: "OtherPhone2",
        Customfield: null,
        index: OtherPhone2,
      };
      saveAllContacts.push(ObjOtherPhone2);
    }

    var ContactID = parseInt(document.getElementById("ContactID").value);
    if (ContactID >= 0) {
      var ObjContactID = {
        FieldAlias: "ContactID",
        Fieldname: "ContactID",
        Customfield: null,
        index: ContactID,
      };
      saveAllContacts.push(ObjContactID);
    }

    var IsUpdateExistContacts = document.getElementById(
      "IsUpdateExistContacts"
    ).checked;
    // if (IsUpdateExistContacts == false) {
    //   IsUpdateExistContacts = true;
    // } else {
    //   IsUpdateExistContacts = false;
    // }
    var IsInsertNullValues =
      document.getElementById("IsInsertNullValues").checked;
    var IsDoNotupdatecompanyname = document.getElementById(
      "IsDoNotupdatecompanyname"
    ).checked;
    var IsMoveExitsingcurrentcampaign = document.getElementById(
      "IsMoveExitsingcurrentcampaign"
    ).checked;
    var IsInsertContactAlreadyReplaied = document.getElementById(
      "IsInsertContactAlreadyReplaied"
    ).checked;

    var csvdata = csvData;
    var csvdata2 = csvData.length;

    var csvdata = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];
      var ObjData = {
        Fields: "Coverimg",
        Filepath: "./Content/UploadedFiles/SalesReplyContact",
        File: _base64,
        ImageName: filenameonlynumber,
        FileData: csvdata,
        MappingFiled: JSON.stringify(saveAllContacts),
        Email: UserEmail,
        ClientID: ClientID,
        UserID: UserID,
        CreatedBy: UserID,
        CreatedDate: new Date(),
        IsUpdateExistContacts: !IsUpdateExistContacts,
        IsInsertNullValues: IsInsertNullValues,
        IsDoNotUpdateCompanyName: IsDoNotupdatecompanyname,
        IsMoveExistContacts: IsMoveExitsingcurrentcampaign,
        IsAllowAlreadyRepliedContacts: IsInsertContactAlreadyReplaied,
        OriginalFileName: OriginalFileName,
        ListName: onlyname,
        CampaignID: CampaignID,
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/prospect_campaign/ProspectCampaignBulkUploadImport",
        method: "POST",
        data: ObjData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetShowPopupImport(false);
          SetShowImportContactCsv(false);
          SetConfigPop(false);
          toast.success("Contact You will get file soon on Submitted Email.");
        } else {
          toast.error(res.data.Message);
        }
      });
    });
  };

  //
  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
  
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "FirstName") {
      SetFirstName(value);
    } else if (Name == "LastName") {
      SetLastName(value);
    } else if (Name == "Email") {
      SetEmail(value);
    } else if (Name == "Title") {
      SetTitle(value);
    } else if (Name == "Company") {
      SetCompany(value);
    } else if (Name == "CompanyNameLong") {
      SetCompanyNameLong(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "DirectPhone") {
      SetDirectPhone(value);
    } else if (Name == "DirectPhoneExt") {
      SetDirectPhoneExt(value);
    } else if (Name == "LinkedInURL") {
      SetLinkedInURL(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "Address1") {
      SetAddress1(value);
    } else if (Name == "Address2") {
      SetAddress2(value);
    } else if (Name == "City") {
      SetCity(value);
    } else if (Name == "State") {
      SetState(value);
    } else if (Name == "Zip") {
      SetZip(value);
    } else if (Name == "Country") {
      SetCountry(value);
    } else if (Name == "ContactCategory") {
      SetContactCategory(value);
    } else if (Name == "AccountCategory") {
      SetAccountCategory(value);
    } else if (Name == "ValidationScore") {
      SetValidationScore(value);
    } else if (Name == "ListName") {
      SetListName(value);
    } else if (Name == "ContactSource") {
      SetContactSource(value);
    } else if (Name == "ContactOwner") {
      SetContactOwner(value);
    } else if (Name == "ClientPOC") {
      SetClientPOC(value);
    } else if (Name == "ReasonUnqualified") {
      SetReasonUnqualified(value);
    } else if (Name == "AccountType") {
      SetAccountType(value);
    } else if (Name == "CompanyRevenue") {
      SetCompanyRevenue(value);
    } else if (Name == "EmployeeCount") {
      setEmployeeCount(value);
    } else if (Name == "IsDoNotCallContact") {
      SetIsDoNotCallContact(value);
    } else if (Name == "SalesRep") {
      SetSalesRep(value);
    } else if (Name == "ColdCaller") {
      SetColdCaller(value);
    } else if (Name == "Tag") {
      SetTag(value);
    } else if (Name == "CallNotes") {
      SetCallNotes(value);
    } else if (Name == "OtherPhone1") {
      SetOtherPhone1(value);
    } else if (Name == "OtherPhone2") {
      SetOtherPhone2(value);
    } else if (Name == "ContactID") {
      SetContactID(value);
    }

    hideCSVHeaderOptions();
  });
  // map csv
  const ContactMapCsv = () => {
    // console.log("DropboxData123",DropboxData)
    ResetCsv();
    document.getElementById("map_attribute").style.display = "block";
    if (csvData.length != 0) {
      ResetCsv();
      DropboxData.map((dddd, index) => {});
    } else {
      ResetCsv();
    }
    hideCSVHeaderOptions();
  };

  const handleAddDuplicateTemplete = async (
    CampaignIDs,
    CampaignStepIDs,
    Bodys,
    Subjects,
    ClientIDs,
    UserIDs,
    OrderBy
  ) => {
    var StrInTemplateStep = {
      ClientID: ClientIDs,
      UserID: UserIDs,
      CampaignID: CampaignIDs,
      CampaignStepID: CampaignStepIDs,
      Body: Bodys,
      Subject: Subjects,
    };
    // ;
    const rows = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignsteptemplate/AddCampaignStepTemplate",
      method: "POST",
      data: StrInTemplateStep,
    });
    // ;
    if (rows.data.StatusMessage == "SUCCESS") {
      GetCampaignStepDetails(ClientID, UserID, Role);
    }
  };

  const handleDeleteStepTemplete = (CStepTemplateID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a campaign step.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          CampaignStepTemplateID: CStepTemplateID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/campaignsteptemplate/DeleteCampaignStepTemplate",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Campaign step deleted successfully.",
                "success"
              );
              GetCampaignStepDetails(ClientID, UserID, Role);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const EditStepTempletPopHandle = (id) => {
    if (EditStepTempletePopup === false) {
      SetCampaignStepTemplatesID(id);
      GetStepTempletDataByID(id);
      SetEditStepTempletePopup(true);
    } else {
      SetEditStepTempletePopup(false);
      SetCampaignStepTemplatesID("");
    }
  };

  const GetStepTempletDataByID = async (id) => {
    var StrInTemplateSteps = {
      CampaignStepTemplateID: id,
      ClientID: ClientID,
      UserID: UserID,
    };
    const rows = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignsteptemplate/GetByIDCampaignStepTemplate",
      method: "POST",
      data: StrInTemplateSteps,
    });
    // ;
    if (rows.data.StatusMessage == "SUCCESS") {
      document.getElementById("TemplateSubject3").value =
        rows.data.Data[0]?.Subject;
      SetSignatureSC3({
        data: rows.data.Data[0]?.Body,
      });
    }
  };

  const EditStepTemplatePopupSubmit = async () => {
    var Body = SignatureSC3.data;
    var Subject = document.getElementById("TemplateSubject3").value;
    var StrInTemplateSteps = {
      id: CampaignStepTemplatesID,
      Body: Body,
      Subject: Subject,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    const rows = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignsteptemplate/UpdateCampaignStepTemplate",
      method: "POST",
      data: StrInTemplateSteps,
    });
    if (rows.data.StatusMessage == "SUCCESS") {
      SetEditStepTempletePopup(false);
      GetCampaignStepDetails(ClientID, UserID, Role);
    }
  };

  const ChangeDays = async () => {
    var Days = document.getElementById("DaysTemplate").value;
    if (Days > 0) {
      // console.log(CampaignStepID)
      // console.log(Days)
      var StrIn = {
        UserID: UserID,
        ClientID: ClientID,
        Role: Role,
        CampaignStepID: CampaignStepID,
        Days: Days,
      };
      const rows = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaignstep/UpdateCampaignDays",
        method: "POST",
        data: StrIn,
      });
      if (rows.data.StatusMessage == "SUCCESS") {
        //AddStepTempletPopHandle();
        SetAnchorEl(null);
        GetCampaignStepDetails(ClientID, UserID, Role);
      }
    } else {
      toast.error(
        <div className="toastsize">
          Edit Step
          <br />
          Day should be greater then zero
        </div>
      );
    }
  };

  //Update Status is active
  const StepTemplateActive = (ID, Flag) => {
    const InputParameters = {
      CampaignStepTemplateID: ID,
      IsActive: Flag,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignstep/CampaignStepTemplateIsActive",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage === "SUCCESS") {
        // if (res.data.Data.IsActive == true) {
        GetCampaignStepDetails(ClientID, UserID, Role);
        toast.success(
          <div className="toastsize">
            Campaign
            <br />
            Campaign value Active successfully.{" "}
          </div>
        );
        // }else{
        //   GetCampaignStepDetails(ClientID,UserID,Role);
        //   toast.success(
        //     <div className="toastsize">
        //       Variable
        //       <br />
        //       {FeatureName} variable value Active successfully.{" "}
        //     </div>
        //   );
        // }
      }
    });
  };

  const handleSearch = (e) => {
    SetSearch(e.target.value);
  };

  let filteredData = Data.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString().toLowerCase().includes(Search.toLowerCase())
    );
  });

  const handleSort = (key) => {
    SetSortField(key);
  };

  if (SortField !== "") {
    filteredData.sort((a, b) => {
      if (a[SortField] < b[SortField]) {
        return -1;
      }
      if (a[SortField] > b[SortField]) {
        return 1;
      }
      return 0;
    });
  }

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // change display rows
  const ChangeRowSelected = (Event) => {
    SetCSVERowsPerPage(Number(Event.target.value));
    SetCSVEPage(1);
    //document.getElementById("hideloding23").style.display = "block";
  };

  const indexOfLastItem = CSVEPage * CSVERowsPerPage;
  const indexOfFirstItem = indexOfLastItem - CSVERowsPerPage;
  // const currentItems = filteredData

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / CSVERowsPerPage);

  // handle data from chaild
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  // 
  const DeleteProspectCampaign = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete Contacts..",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var ProspectCampaignData = {
          dataFromChild: dataFromChild,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/prospect_campaign/DeleteProspectCampaingArray",
          method: "POST",
          data: ProspectCampaignData,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Selected contacts deleted successfully.",
                "success"
              );
              // document.getElementById("hideloding").style.display = "block";
            
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

    // Exports csv for contacts
    const ExportsToProspectCampaign = async (e) => {
      e.preventDefault();
      e.currentTarget.disabled = true;
      var Final_flag =  await FormValidation();
      if(Final_flag==true){
        var ExportEmails = document.getElementById("UsernameExport").value;
        var ExportData = {
          UserID: UserID,
          ClientID: ClientID,
          Email: ExportEmails,
          AccountType: null,
          CreatedDate: new Date(),
          CreatedBy: UserID,
          AccountTypeID:null,
          ClientIDs:null,
          IsDeleted:false,
          LastUpdatedDate:null,
          LastUpdatedBy:null,
          InProcess:false,
          IsComplete:false,
          InProcessDate:null,
          IsCompleteDate:null,
          Page:null,
          CampaignID:CampaignID,
          DynamicQuery:""
    
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/prospect_campaign/AllProspectCampaignExport",
          method: "POST",
          data: ExportData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div>
                Contact<br/>
                You will get file soon on Submitted Email.
              </div>
            );
            SetShowPopupExport1(false)   
          } else {
            toast.error(res.data.Message);
          }
        });
      }else{
        document.getElementById("submitcsv").disabled = false;
      }
 
    };

        // handle change
 const HandleChange =() =>{
  
    var Email = document.getElementById("UsernameExport").value.trim();
    if (Email != "") {
      ExportCsvErrors.UsernameExport = null;
      CheckClientInvite(Email);
    } else {
      ExportCsvErrors.UsernameExport = null;
    }
  }

      // Whole form validation
      const FormValidation = async() => {
        let Errors = {};
        let FormIsValid = true;
        let Email = document.getElementById("UsernameExport").value.trim();
        let IsExist = await CheckClientInvite(Email);
    
        if (Email == "") {
          FormIsValid = false;
          Errors["UsernameExport"] = "Please enter email";
        }
        if (IsExist == true) {
          FormIsValid = false;
          Errors["UsernameExport"] = "invalid email.";
        }
    SetExportCsvErrors(Errors)
        return FormIsValid;
      }

        // find exits prospect campaign  Email
      const  CheckClientInvite = async(Email)=> {
          let regexp =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          let Errors = {};
          if (!regexp.test(Email) && Email!="") {
            Errors["UsernameExport"] = "invalid email.";

            SetExportCsvErrors(Errors)
            return true;
          } else {
  
            return false;
          }
        
        }

        // contact exists from popup
        const ContactExistingPopupBtn = () => {
          SetContactFromExistingPopup(true)
        }

        const CampaignMove = (e) => {
          Swal.fire({
            title: "Are you sure?",
            text: "you want to move a contacts to "+ e.label,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34bfa3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Move it!",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              var InputParaMeter = {
                ClientID:ClientID,
                UserID:UserID,
                CampaignID:campaignID,
                CampaignMoveID:e.value,
                ArrayData:DataFromCampaign,
                LastUpdatedBy:CUserID,
                LastUpdatedDate:new Date()
              }

              Axios({
                url: CommonConstants.MOL_APIURL + "/prospect_campaign/CampaignMoveAnother",
                method: "POST",
                data: InputParaMeter,
              }).then((res) => {
                
                if (res) {
                  if ((res.statusText = "Ok")) {
                    Swal.fire(
                      "Move!",
                      "Contacts moved successfully.",
                      "success"
                    );
                    document.getElementById("move_campaign").style.display = "none";
                    document.getElementById("move_campaign_icon").style.display = "block";
                  } else {
                  }
                }
              });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
          });
        }

          // Move campaign
  const ContactMoveToCampaign = () => {
    if(dataFromChild == 0){
      toast.error(<div>Move Contact <br/>Please select atleast one contact from list.</div>)
    }else{
      document.getElementById("move_campaign").style.display = "block";
      document.getElementById("move_campaign_icon").style.display = "none"
    }
  }

  const HandleOkPopup = async () => {
    
    SetProspectFlag(true);
    SetShowImportContactCsv(!ShowImportContactCsv);
    SetConfigPop(!ConfigPop);
    SetDataUpdate(true);
    SetDataUpdate(false);
    SetProspectFlag(false);
  }
  const ContactValidateEmailCheck = async () => {
  debugger
    // document.getElementById("Show_csv_details").style.display = "none"
    if (dataFromChild.length === 0) {
      toast.error("Please select at least one contact from the list");
      return;
    }
    try {
      SetIsVisible(true);
      const InputParaMeter = {
        ContactValidateArray:   dataFromChild,
        LastUpdatedBy: UserID,
        LastUpdatedDate: new Date(),
      };
  
      const response = await Axios.post(
        CommonConstants.MOL_APIURL + "/contacts/ContactValidateCheckCampaign",
        InputParaMeter
      );
  
      if (response.data.StatusMessage === "SUCCESS") {
        toast.success("Saleshive contact will be validated soon.");
        setDataFromChild([])
        SetProspectFlag(!ProspectFlag);

        SetIsVisible(false);
        // document.getElementById("deleteall-btn").style.display = "none"
      } else {
        toast.error("Not validated");
      }
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    } finally {
      SetIsVisible(false);
    }
  };
  
  return (
    <div class="w-100">
      
        <div id="hideloding23" className="loding-display" >
          <img src={loadingicon} />
        </div>
        
      <div className="App w-100">
        <div className="stepwizard-box">
          <div className="steps">
            <ul className="nav">
              {steps.map((step, i) => {
                return (
                  <li
                    key={i}
                    className={`${
                      activeStep.key === step.key ? "active" : ""
                    } ${step.isDone ? "done" : ""}`}
                  >
                    <div>
                      <i className={step.icon}></i>
                    </div>
                    <span>{step.label}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="step-component mt-0 p-0">
            <div className="w-100" style={{ display: "none" }} id="firstStep">
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">
                      Campaign Name
                    </label>
                    <div className="col-lg-7 px-4">
                      <input
                        className="form-control m-input"
                        type="text"
                        onBlur={CampaignExist}
                        id="CampaignName"
                      />
                      <span style={{ color: "red" }}>{Errors}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">
                      Reply Behavior
                    </label>
                    <div className="col-lg-7 px-4">
                      <select
                        id="ReplyBehavior"
                        class="form-control m-input valid"
                      >
                        {ReplyBehavior === "" ? (
                          <div></div>
                        ) : (
                          ReplyBehavior.map((value) => (
                            <option
                              value={value.FieldID}
                              selected={
                                ReplyBehaviorID == ""
                                  ? false
                                  : ReplyBehaviorID == value.FieldID
                                  ? true
                                  : false
                              }
                            >
                              {value.FieldName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Priority</label>
                    <div className="col-lg-7 px-4">
                      <select id="Priority" class="form-control m-input valid">
                        {Priority === "" ? (
                          <div></div>
                        ) : (
                          Priority.map((value) => (
                            <option
                              value={value.FieldID}
                              selected={
                                CampaignPriorityID == ""
                                  ? false
                                  : CampaignPriorityID == value.FieldID
                                  ? true
                                  : false
                              }
                            >
                              {value.FieldName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">
                      Open Tracking
                    </label>
                    <div className="col-lg-7 px-4">
                      <select
                        id="OpenTracking"
                        value={OpenTrackingDefault}
                        
                            onChange={
                              (event) =>
                              handleOpenTrackingDefault(
                                                         event
                                                        )
                            }
                        // defaultValue={7}

                        class="form-control m-input valid"
                      >
                        {OpenTracking === "" ? (
                          <div></div>
                        ) : (
                          OpenTracking.map((value) => (
                            <option
                              value={value.FieldID}
                              selected={
                                EmailOpenTrackingID == ""
                                  ? false
                                  : EmailOpenTrackingID == value.FieldID
                                  ? true
                                  : false
                              }
                            >
                              {value.FieldName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <label class="check_bx">
                    <input id="SendOnWeekends" type="checkbox" />
                    <span className="checkmark"></span>
                    Send on Weekends
                  </label>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <label class="check_bx">
                    <input id="IgnoreBlacklist" type="checkbox" />
                    <span className="checkmark"></span>
                    Ignore Blacklist
                  </label>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <label class="check_bx">
                    <input id="TrackClicks" type="checkbox" checked />
                    <span className="checkmark"></span>
                    Track Clicks
                  </label>
                </div>
                <div className="col-lg-12 boxsinput_group">
                  <div className="row pb-3">
                    <label className="col-lg-12 view-lable-meet">Notes</label>
                    <div className="col-lg-12">
                      <textarea
                        id="Notes"
                        className="form-control m-input"
                        type="text"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "none" }} id="secondStep">
              <div className="row px-3">
                <div className="col borderbtnm pl-0">
                  <h4 className="headertitlebd py-3 pl-0">Email Account Details</h4>
                </div>
                <div className="col pt-0 borderbtnm pr-0">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li onClick={ConfigEmailAccount}>
                        <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                          <i class="flaticon-settings"></i>
                          <span>Configure Email Accounts</span>
                        </a>
                        <Popup open={ConfigEmailPopup}>
                          <div>
                            <div className="modal-black"></div>
                            <div className="filterPopup maxpopupfull">
                              <div className="paddingboxTerms">
                                <div className="modal-header py-4 px-3">
                                  <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0 mt-2 modal-title">
                                      Configure Email Accounts
                                    </h5>
                                    <button
                                      className="close"
                                      onClick={ConfigEmailAccount}
                                    >
                                      <span
                                        aria-hidden="true"
                                        class="la la-remove"
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                                <div className="modal-body">
                                  <div className="px-4">
                                    <div className="row">
                                      <div className="col">
                                        <ConfigureEmailAccounts
                                          CCampaignID={CampaignID}
                                          CUserID={UserID}
                                          CClientID={ClientID}
                                          updatefromsteptwo={UpdateFromStepTwo}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row pb-3">
                <div className="col">
                  <CampaignAccount
                    CCampaignID={CampaignID}
                    ListUpdate={ConfigEmailPopup}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "none" }} id="thirdStep">
              <div className="row px-3">
                <div className="col borderbtnm pl-0">
                  <h4 className="headertitlebd py-3 pl-0">Contact Details</h4>
                </div>
                <div className="col pt-0 borderbtnm pr-0">
                  <div className="listing-li float-right padb-15">
                    <ul>
                      <li>
                      <Tooltip title="Configure">
                        <a
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={ConfigPopHandle}
                        >
                          <i class="flaticon-settings"></i>
                        </a></Tooltip>
                        <Popup open={ConfigPop}>
                          <div>
                            <div className="modal-black"></div>
                            <div className="filterPopup largerPopup1000 mt-5">
                              <div className="paddingboxTerms">
                                <div className="modal-header py-4 px-3">
                                  <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">Configure Contacts</h5>
                                    <button
                                      className="close"
                                      onClick={ConfigPopHandle}
                                    >
                                      <span
                                        aria-hidden="true"
                                        class="la la-remove"
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                                <div className="modal-body">
                                  <div className="px-2">
                                    <div className="row">
                                      <div className="col-xl-3 aligncenter">
                                        <div className="iconer mb-3">
                                          <i className="flaticon-file-1 clr-purple"></i>
                                        </div>
                                        <a
                                          class="btn btn-primary btngroup mb-3"
                                          onClick={() =>
                                            SetShowImportContactCsv(true)
                                          }
                                        >
                                          Import from CSV
                                        </a>
                                        <Popup open={ShowImportContactCsv}>
                                          {/* {(close) => ( */}
                                          <div>
                                            <div className="modal-black"></div>
                                            <div className="filterPopup largerPopup1000">
                                              <div className="paddingboxTerms">
                                                <div className="modal-header py-4 px-3">
                                                  <div className="w-100 d-flex px-2">
                                                    <h5 className="mb-0">
                                                      Import Contacts{" "}
                                                    </h5>
                                                    <button
                                                      className="close"
                                                      onClick={() =>
                                                        SetShowImportContactCsv(
                                                          false
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        aria-hidden="true"
                                                        class="la la-remove"
                                                      ></span>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="modal-body">
                                                  <div>
                                                    <div className="row mt-2 pr-5 mr-5">
                                                      <div className="col mr-5 pr-5">
                                                        <h3 className="pr-5 mr-2">
                                                          Import Contact Details
                                                        </h3>
                                                      </div>
                                                    </div>

                                                    <div className="row pr-5 mr-4">
                                                      <div className="col mr-3 pr-4">
                                                        
                                                          <i class="la la-info-circle"></i>
                                                          A sample file is
                                                          available for
                                                          download,
                                                          <a herf="/">
                                                            please click here
                                                          </a>
                                                       
                                                      </div>
                                                    </div>
                                                    <div
                                                      id="import_csv"
                                                    >
                                                      <div className="row">
                                                        <div className="col-xl-8 offset-xl-2 pl-5">
                                                          <div className="uplodfilesbox">
                                                            <CSVReader
                                                              accept={[
                                                                "text/csv",
                                                                ".csv",
                                                                "application/vnd.ms-excel",
                                                              ]}
                                                              onDrop={
                                                                HandleOnDrop
                                                              }
                                                              onError={
                                                                HandleOnError
                                                              }
                                                              addRemoveButton
                                                              removeButtonColor="#659cef"
                                                              onRemoveFile={
                                                                HandleOnRemoveFile
                                                              }
                                                              isReset={Reset}
                                                            >
                                                              <span>
                                                                Select .CSV file
                                                              </span>
                                                              <p>
                                                                Add contacts
                                                                data from csv
                                                                file to import.
                                                              </p>
                                                            </CSVReader>
                                                          </div>
                                                        </div>
                                                        <div className="col-xl-8 offset-xl-2 pb-5 mb-5 ">
                                                          <div className="alignright">
                                                            <a
                                                              onClick={
                                                                ContactMapCsv
                                                              }
                                                              className="btn btn-primary btnColor text-white"
                                                            >
                                                              <i className="la la-upload"></i>{" "}
                                                              Upload
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* Mapped div */}
                                                    <div
                                                      className="portletbody portletshadow"
                                                      id="map_attribute"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    >
                                                      <div className="row col-xs-12">
                                                        <h4 className="headertitle float-left">
                                                          Map attributes
                                                        </h4>
                                                      </div>
                                                      <div
                                                        className="col-xs-12"
                                                        id="divContactSelection"
                                                      >
                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn1"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="FirstName"
                                                                >
                                                                  First Name
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="FirstName"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  FirstName ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "FirstName"
                                                                      )
                                                                    : FirstName
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn2"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="LastName"
                                                                >
                                                                  Last Name
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="LastName"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  LastName ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "LastName"
                                                                      )
                                                                    : LastName
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn3"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Email"
                                                                >
                                                                  Email
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Email"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Email == null
                                                                    ? DropboxData.indexOf(
                                                                        "Email"
                                                                      )
                                                                    : Email
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn4"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Title"
                                                                >
                                                                  Title
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Title"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Title == null
                                                                    ? DropboxData.indexOf(
                                                                        "Title"
                                                                      )
                                                                    : Title
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn5"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Company"
                                                                >
                                                                  Company
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Company"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Company ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Company"
                                                                      )
                                                                    : Company
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>

                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn6"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="CompanyNameLong"
                                                                >
                                                                  Company Name
                                                                  Long
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="CompanyNameLong"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  CompanyNameLong ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Company Name Long"
                                                                      )
                                                                    : CompanyNameLong
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn11"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Website"
                                                                >
                                                                  Website
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Website"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Website ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Website"
                                                                      )
                                                                    : Website
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn6"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="CompanyPhone"
                                                                >
                                                                  Company Phone
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="CompanyPhone"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  CompanyPhone ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Company Phone"
                                                                      )
                                                                    : CompanyPhone
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn22"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="DirectPhone"
                                                                >
                                                                  Direct Phone
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="DirectPhone"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  DirectPhone ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Direct Phone"
                                                                      )
                                                                    : DirectPhone
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="DirectPhoneExt"
                                                                >
                                                                  Direct Phone
                                                                  Ext
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="DirectPhoneExt"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  DirectPhoneExt ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Direct Phone Ext"
                                                                      )
                                                                    : DirectPhoneExt
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn14"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="LinkedInURL"
                                                                >
                                                                  LinkedIn URL
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="LinkedInURL"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  LinkedInURL ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "LinkedIn URL"
                                                                      )
                                                                    : LinkedInURL
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>

                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn18"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Industry"
                                                                >
                                                                  Industry
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Industry"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Industry ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Industry"
                                                                      )
                                                                    : Industry
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn12"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Address1"
                                                                >
                                                                  Address 1
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Address1"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Address1 ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Address 1"
                                                                      )
                                                                    : Address1
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn13"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Address2"
                                                                >
                                                                  Address 2
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Address2"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Address2 ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Address 2"
                                                                      )
                                                                    : Address2
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn7"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="City"
                                                                >
                                                                  City
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="City"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  City == null
                                                                    ? DropboxData.indexOf(
                                                                        "City"
                                                                      )
                                                                    : City
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn8"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="State"
                                                                >
                                                                  State
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="State"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  State == null
                                                                    ? DropboxData.indexOf(
                                                                        "State"
                                                                      )
                                                                    : State
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn15"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Zip"
                                                                >
                                                                  Zip
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Zip"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Zip == null
                                                                    ? DropboxData.indexOf(
                                                                        "Postal Code"
                                                                      )
                                                                    : Zip
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn16"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Country"
                                                                >
                                                                  Country
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Country"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Country ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Country"
                                                                      )
                                                                    : Country
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn9"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ContactCategory"
                                                                >
                                                                  Contact
                                                                  Category
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ContactCategory"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ContactCategory ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Contact Category"
                                                                      )
                                                                    : ContactCategory
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn10"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="AccountCategory"
                                                                >
                                                                  Company
                                                                  Category
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="AccountCategory"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  AccountCategory ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Company Category"
                                                                      )
                                                                    : AccountCategory
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn17"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ValidationScore"
                                                                >
                                                                  Validation
                                                                  Score
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ValidationScore"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ValidationScore ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Validation Score"
                                                                      )
                                                                    : ValidationScore
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>

                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn17"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ListName"
                                                                >
                                                                  List Name
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <input
                                                                type="text"
                                                                id="ListName"
                                                                className="form-control  m-input"
                                                                value={filename}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ContactSource"
                                                                >
                                                                  Contact Source
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ContactSource"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ContactSource ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Contact Source"
                                                                      )
                                                                    : ContactSource
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn24"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ContactOwner"
                                                                >
                                                                  Contact Owner
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ContactOwner"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ContactOwner ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Contact Owner"
                                                                      )
                                                                    : ContactOwner
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ClientPOC"
                                                                >
                                                                  Client POC
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ClientPOC"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ClientPOC ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Client POC"
                                                                      )
                                                                    : ClientPOC
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn24"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ReasonUnqualified"
                                                                >
                                                                  Reason
                                                                  Unqualified
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ReasonUnqualified"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ReasonUnqualified ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Reason Unqualified"
                                                                      )
                                                                    : ReasonUnqualified
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="AccountType"
                                                                >
                                                                  Account Type
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="AccountType"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  AccountType ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Account Type"
                                                                      )
                                                                    : AccountType
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn19"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="CompanyRevenue"
                                                                >
                                                                  Company
                                                                  Revenue
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="CompanyRevenue"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  CompanyRevenue ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Company Revenue"
                                                                      )
                                                                    : CompanyRevenue
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn20"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="EmployeeCount"
                                                                >
                                                                  Employee Count
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="EmployeeCount"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  EmployeeCount ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Employee Count"
                                                                      )
                                                                    : EmployeeCount
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn24"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="MobilePhone"
                                                                >
                                                                  Mobile Phone
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="MobilePhone"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  MobilePhone ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Mobile Phone"
                                                                      )
                                                                    : MobilePhone
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="IsDoNotCallContact"
                                                                >
                                                                  Do not call
                                                                  contact
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="IsDoNotCallContact"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  IsDoNotCallContact ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Do not call contact"
                                                                      )
                                                                    : IsDoNotCallContact
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>

                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn24"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="SalesRep"
                                                                >
                                                                  Sales Rep
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="SalesRep"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  SalesRep ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Sales Rep"
                                                                      )
                                                                    : SalesRep
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ColdCaller"
                                                                >
                                                                  Sales
                                                                  Development
                                                                  Reps
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ColdCaller"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ColdCaller ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Sales Development Reps"
                                                                      )
                                                                    : ColdCaller
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>

                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn24"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="Tag"
                                                                >
                                                                  Tag
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="Tag"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  Tag == null
                                                                    ? DropboxData.indexOf(
                                                                        "Tag"
                                                                      )
                                                                    : Tag
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="CallNotes"
                                                                >
                                                                  Call Notes
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="CallNotes"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  CallNotes ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "Call Notes"
                                                                      )
                                                                    : CallNotes
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>

                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn24"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="OtherPhone1"
                                                                >
                                                                  OtherPhone 1
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="OtherPhone1"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  OtherPhone1 ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "OtherPhone 1"
                                                                      )
                                                                    : OtherPhone1
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="form-group m-form__group d-flex">
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn24"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="OtherPhone2"
                                                                >
                                                                  OtherPhone 2
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x"></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="OtherPhone2"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  OtherPhone2 ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "OtherPhone 2"
                                                                      )
                                                                    : OtherPhone2
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2"></div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                className="dbcontent form-control  m-input"
                                                                id="dbColumn23"
                                                                disabled="disabled"
                                                              >
                                                                <option
                                                                  selected=""
                                                                  value="ContactID"
                                                                >
                                                                  ContactID
                                                                </option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-1 text-center">
                                                            <span className="fa fa-arrows-h fa-2x "></span>
                                                          </div>
                                                          <div className="col-lg-2 nopadding">
                                                            <div>
                                                              <select
                                                                id="ContactID"
                                                                className="csvcontent form-control  m-input"
                                                                autocomplete="off"
                                                                value={
                                                                  ContactID ==
                                                                  null
                                                                    ? DropboxData.indexOf(
                                                                        "ContactID"
                                                                      )
                                                                    : ContactID
                                                                }
                                                              >
                                                                <option>
                                                                  Not Mapped
                                                                </option>
                                                                {DropboxData.map(
                                                                  (
                                                                    dbdata,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      value={
                                                                        index
                                                                      }
                                                                    >
                                                                      {dbdata}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="row d-flex">
                                                          <div className="col-lg-6">
                                                            <div class="form-group m-form__group row">
                                                              <div class="toggleswich">
                                                                <input
                                                                  type="checkbox"
                                                                  class="checktogle"
                                                                  id="IsUpdateExistContacts"
                                                                />
                                                                <b class="switch">
                                                                  <span class="checkion"></span>
                                                                  <span class="uncheckion"></span>
                                                                </b>
                                                                <b class="track"></b>
                                                              </div>
                                                              <label>
                                                                {" "}
                                                                Skip updating
                                                                existing
                                                                contacts
                                                              </label>
                                                            </div>
                                                            <div class="form-group m-form__group row">
                                                              <div class="toggleswich">
                                                                <input
                                                                  type="checkbox"
                                                                  class="checktogle"
                                                                  id="IsInsertNullValues"
                                                                />
                                                                <b class="switch">
                                                                  <span class="checkion"></span>
                                                                  <span class="uncheckion"></span>
                                                                </b>
                                                                <b class="track"></b>
                                                              </div>
                                                              <label>
                                                                Insert Null
                                                                Values
                                                              </label>
                                                            </div>
                                                            <div class="form-group m-form__group row">
                                                              <div class="toggleswich">
                                                                <input
                                                                  type="checkbox"
                                                                  class="checktogle"
                                                                  id="IsDoNotupdatecompanyname"
                                                                />
                                                                <b class="switch">
                                                                  <span class="checkion"></span>
                                                                  <span class="uncheckion"></span>
                                                                </b>
                                                                <b class="track"></b>
                                                              </div>
                                                              <label>
                                                                Do Not Update
                                                                Company Name
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-6">
                                                            <div class="form-group m-form__group row">
                                                              <div class="toggleswich">
                                                                <input
                                                                  type="checkbox"
                                                                  class="checktogle"
                                                                  id="IsMoveExitsingcurrentcampaign"
                                                                />
                                                                <b class="switch">
                                                                  <span class="checkion"></span>
                                                                  <span class="uncheckion"></span>
                                                                </b>
                                                                <b class="track"></b>
                                                              </div>
                                                              <label>
                                                                Move existing
                                                                contacts to
                                                                current Campaign
                                                              </label>
                                                            </div>
                                                            <div class="form-group m-form__group row">
                                                              <div class="toggleswich">
                                                                <input
                                                                  type="checkbox"
                                                                  class="checktogle"
                                                                  id="IsInsertContactAlreadyReplaied"
                                                                />
                                                                <b class="switch">
                                                                  <span class="checkion"></span>
                                                                  <span class="uncheckion"></span>
                                                                </b>
                                                                <b class="track"></b>
                                                              </div>
                                                              <label>
                                                                {" "}
                                                                Insert Contacts
                                                                That Have
                                                                Already Replied
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-12 d-flex">
                                                          <a
                                                            onClick={
                                                              ContactSaveCsv
                                                            }
                                                            className="btn btn-primary btn-lightgreen px-0 my-3"
                                                          >
                                                            <i className="la la-save"></i>
                                                            Save
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="row"
                                                      id="total-div-display"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    >
                                                      <div className="col">
                                                          <div className="row mb-3">
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Total
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {Total}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Success
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalSucess
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Error
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalError}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Skipped
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalSkipped}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Updated
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalUpdated
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Contact
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalContactBlacklisted}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Domain
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalDomainBlackListed}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Removed
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalRemoved}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Global
                                                                        Domain
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalGlobalDomainBlackListed}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Default
                                                                        Country
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalDefaultCountryBlackListed}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Country
                                                                        Blacklisted
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalCountryBlackListed}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Replied
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {TotalReplied}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Email
                                                                        Validation
                                                                        D
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalEmailValiDationScoreD
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12 col-lg-6 col-xl-3 px-1">
                                                              <div class="bg-light-sky boxcardcounter">
                                                                <div class="d-table w-100">
                                                                  <div class="contectboxes float-left">
                                                                    <div class="line-title pb-2">
                                                                      <span class="clr-sky text-left font-weight-bold">
                                                                        Email
                                                                        Validation
                                                                        F
                                                                      </span>
                                                                    </div>
                                                                    <div class="box-inner-title">
                                                                      <string class="clr-sky font-weight-light">
                                                                        Total
                                                                      </string>
                                                                    </div>
                                                                  </div>
                                                                  <div class="float-right pt-2">
                                                                    <span class="clr-sky box-main-title pt-3">
                                                                      {
                                                                        TotalEmailValiDationScoreF
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                      id="csv_error_table"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    >
                                                      <div className="row">
                                                        <h4>
                                                          Csv Error Details
                                                        </h4>
                                                      </div>

                                                      {/* table use */}
                                                      <div className="row padt-25">
                                                        <div className="col-sm-12 col-md-6">
                                                          <label className="textlabelte">
                                                            Show
                                                            <select
                                                              name="tbl_meeting_length"
                                                              onChange={
                                                                ChangeRowSelected
                                                              }
                                                              aria-controls="tbl_meeting"
                                                              class="form-control form-control-sm"
                                                            >
                                                              {CommonConstants.show_rows.map(
                                                                (value) => (
                                                                  <option
                                                                    value={
                                                                      value
                                                                    }
                                                                  >
                                                                    {value}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            entries
                                                          </label>
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 full-right">
                                                          <label class="textlabelte">
                                                            Search:{" "}
                                                            <input
                                                              type="search"
                                                              id="search"
                                                              onChange={(e) => {
                                                                handleSearch(e);
                                                              }}
                                                              class="form-control form-control-sm ml-2"
                                                              placeholder=""
                                                              aria-controls="tbl_meeting"
                                                            />
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <div className="table-bordered">
                                                        <TableContainer
                                                          component={Paper}
                                                        >
                                                          <Table
                                                            stickyHeader
                                                            aria-label="sticky table"
                                                          >
                                                            <TableHead>
                                                              <TableRow>
                                                                <TableCell
                                                                  onClick={() => {
                                                                    handleSort(
                                                                      "ContactStatus"
                                                                    );
                                                                  }}
                                                                >
                                                                  {/* <a
                    onClick={() => {
                      handleSort("ContactStatus");
                    }}
                  > */}
                                                                  Status
                                                                  {/* </a> */}
                                                                </TableCell>
                                                                <TableCell
                                                                  onClick={() => {
                                                                    handleSort(
                                                                      "FirstName"
                                                                    );
                                                                  }}
                                                                >
                                                                  {/* <a
                    onClick={() => {
                      handleSort("FirstName");
                    }}
                  > */}
                                                                  FirstName
                                                                  {/* </a> */}
                                                                </TableCell>
                                                                <TableCell>
                                                                  <a
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "LastName"
                                                                      );
                                                                    }}
                                                                  >
                                                                    LastName
                                                                  </a>
                                                                </TableCell>
                                                                <TableCell>
                                                                  <a
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "Email"
                                                                      );
                                                                    }}
                                                                  >
                                                                    Email
                                                                  </a>
                                                                </TableCell>
                                                                <TableCell>
                                                                  <a
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "Company"
                                                                      );
                                                                    }}
                                                                  >
                                                                    Company
                                                                  </a>
                                                                </TableCell>
                                                                <TableCell>
                                                                  <a
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "City"
                                                                      );
                                                                    }}
                                                                  >
                                                                    City
                                                                  </a>
                                                                </TableCell>
                                                                <TableCell>
                                                                  <a
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "City"
                                                                      );
                                                                    }}
                                                                  >
                                                                    State
                                                                  </a>
                                                                </TableCell>
                                                                <TableCell>
                                                                  <a
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "Country"
                                                                      );
                                                                    }}
                                                                  >
                                                                    Country
                                                                  </a>
                                                                </TableCell>
                                                                <TableCell>
                                                                  <a
                                                                    onClick={() => {
                                                                      handleSort(
                                                                        "Title"
                                                                      );
                                                                    }}
                                                                  >
                                                                    Title
                                                                  </a>
                                                                </TableCell>
                                                              </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                              {currentItems?.length ===
                                                              0 ? (
                                                                <p className="text-center">
                                                                  No data
                                                                  available in
                                                                  table
                                                                </p>
                                                              ) : (
                                                                currentItems?.map(
                                                                  (row) => (
                                                                    <TableRow>
                                                                      <TableCell>
                                                                        {
                                                                          row.ContactStatus
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.FirstName
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.LastName
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.Email
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.Company
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.City
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.State
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.Country
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          row.Title
                                                                        }
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  )
                                                                )
                                                              )}
                                                            </TableBody>

                                                            <div className="row"></div>
                                                          </Table>
                                                        </TableContainer>
                                                      </div>
                                                      {Sflag ? (
                                                        <div class="row">
                                                          <Pagination
                                                            component="div"
                                                            count={CountPage}
                                                            page={CSVEPage}
                                                            onChange={
                                                              handlePagination
                                                            }
                                                            showFirstButton
                                                            showLastButton
                                                          />
                                                          <div class="col dataTables_info">
                                                            <p>
                                                              Showing{" "}
                                                              {Rlen == 0
                                                                ? 0
                                                                : (CSVEPage -
                                                                    1) *
                                                                    CSVERowsPerPage +
                                                                  1}{" "}
                                                              to{" "}
                                                              {CSVEPage *
                                                                CSVERowsPerPage >
                                                              Rlen
                                                                ? Rlen
                                                                : CSVEPage *
                                                                  CSVERowsPerPage}{" "}
                                                              of {Rlen} entries
                                                              (filtered from{" "}
                                                              {Flen} total
                                                              entries)
                                                            </p>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div class="row">
                                                          <div class="col dataTables_info">
                                                            <p>
                                                              Showing{" "}
                                                              {Rlen == 0
                                                                ? 0
                                                                : (CSVEPage -
                                                                    1) *
                                                                    CSVERowsPerPage +
                                                                  1}{" "}
                                                              to{" "}
                                                              {CSVEPage *
                                                                CSVERowsPerPage >
                                                              Rlen
                                                                ? Rlen
                                                                : CSVEPage *
                                                                  CSVERowsPerPage}{" "}
                                                              of {Rlen} entries
                                                            </p>
                                                          </div>
                                                          <div class="col pageright">
                                                            <Pagination
                                                              component="div"
                                                              count={CountPage}
                                                              page={CSVEPage}
                                                              onChange={
                                                                handlePagination
                                                              }
                                                              showFirstButton
                                                              showLastButton
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div class="modal-footer px-1 mt-5">
                                                      <a
                                                        id="lnkCreateCampaignFromTemplate"
                                                        onClick={HandleOkPopup}
                                                        class="btn btn-primary btn-lightgreen"
                                                      >
                                                        {/* <i class="la la-save"></i>{" "} */}
                                                        OK
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* )} */}
                                        </Popup>
                                        <p>
                                          Add contacts from CSV file to contact
                                          list via bulk upload.
                                        </p>
                                      </div>

                                      <div className="col-xl-3 aligncenter">
                                        <div className="iconer mb-3">
                                          <i className="flaticon-users clr-purple"></i>
                                        </div>
                                        <a class="btn btn-primary btngroup mb-3" onClick={ContactExistingPopupBtn}>
                                              Add from existing
                                            </a>
                                        <Popup open={ContactFromExistingPopup}>
                             
                                            <div>
                                              <div className="modal-black"></div>
                                              <div className="filterPopup largerPopup1000 mt-5">
                                                <div className="paddingboxTerms">
                                                  <div className="modal-header py-4 px-3">
                                                    <div className="w-100 d-flex px-3">
                                                      <h5 className="mb-0">
                                                        Add Contacts to campaign{" "}
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        onClick={() =>
                                                SetContactFromExistingPopup(false)
                                              }
                                                      >
                                                        <span
                                                          aria-hidden="true"
                                                          class="la la-remove"
                                                        ></span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="modal-body">
                                                    <div className="px-4">
                                                      <div className="row">
                                                        <div className="col">
                                                          <AddContactsCampaign
                                                            CampaignID={
                                                              CampaignID
                                                            }
                                                            updateFromChild={UpdateFromChild}
                                                            onDataFromContact={handleDataFromChildContactExits}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                      
                                        </Popup>
                                        <p>
                                          Add contacts from the contact list 
                                          Only contacts that are not currently
                                          in your other campaigns can be added.{" "}
                                        </p>
                                      </div>

                                      <div className="col-xl-3 aligncenter">
                                        <div className="iconer mb-3">
                                          <i className="flaticon-users clr-purple"></i>
                                        </div>
                                        <Popup
                                          trigger={
                                            <a class="btn btn-primary btngroup mb-3">
                                              Add from list
                                            </a>
                                          }
                                          modal
                                          nested
                                        >
                                          {(close) => (
                                            <div>
                                              <div className="modal-black"></div>
                                              <div className="filterPopup largerPopup1000">
                                                <div className="paddingboxTerms">
                                                  <div className="modal-header py-4 px-3">
                                                    <div className="w-100 d-flex px-3">
                                                      <h5 className="mb-0">
                                                        Add List to campaign{" "}
                                                      </h5>
                                                      <button
                                                        className="close"
                                                        onClick={close}
                                                      >
                                                        <span
                                                          aria-hidden="true"
                                                          class="la la-remove"
                                                        ></span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="modal-body">
                                                    <div className="px-4">
                                                      <div className="row">
                                                        <div className="col">
                                                          <div className="table-bordered">
                                                            <AddListCampaign
                                                              handClose={close}
                                                              CampaignId={
                                                                CampaignID
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Popup>
                                        <p>
                                          Add contacts from the list Only
                                          contacts that are not currently in
                                          your other campaigns can be added.
                                        </p>
                                      </div>
                                      <Popup open={ShowPopupImport}>
                                        <div>
                                          <div className="modal-black"></div>
                                          <div className="filterPopup largerPopup">
                                            <div className="paddingboxTerms">
                                              <div className="modal-header py-4 px-3">
                                                <div className="w-100 d-flex px-3">
                                                  <h5 className="mb-0">
                                                    Email
                                                  </h5>
                                                  <button
                                                    className="close"
                                                    onClick={() =>
                                                      SetShowPopupImport(false)
                                                    }
                                                  >
                                                    <span
                                                      aria-hidden="true"
                                                      class="la la-remove"
                                                    ></span>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="p-3">
                                                <div className="modal-body">
                                                  <div className="col-sm-6 mb-5 px-0">
                                                    <div class="row mb-3">
                                                      <label class="col-lg-5 col-form-label text-left">
                                                        Email
                                                      </label>
                                                      <div class="col-lg-7">
                                                        <input
                                                          class="form-control m-input"
                                                          id="UserEmail"
                                                          data-val="true"
                                                          data-val-remote="Title already exist."
                                                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                          data-val-required="Please enter Email "
                                                          name="Username"
                                                          defaultValue={
                                                            UserEmail
                                                          }
                                                          type="text"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="border-top modal-content text-right">
                                                  <div class="row">
                                                    <div class="col-lg-12 pull-right">
                                                      <a
                                                        id="backtolist"
                                                        class="btn btn-secondary mr-2"
                                                        onClick={() =>
                                                          SetShowPopupImport(
                                                            false
                                                          )
                                                        }
                                                      >
                                                        cancel
                                                      </a>
                                                      <a
                                                        id="submit"
                                                        onClick={() =>
                                                          ContactSaveCsvAddBulk()
                                                        }
                                                        class="btn btn-primary btn-lightgreen mr-1"
                                                      >
                                                        Save
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Popup>
                                      <div className="col-xl-3 aligncenter">
                                        <div className="iconer mb-3">
                                          <i className="flaticon-user-add clr-purple"></i>
                                        </div>
                                        <a
                                          class="btn btn-primary btngroup mb-3"
                                          onClick={CreateManuallyPopHandle}
                                        >
                                          Create manually
                                        </a>
                                        <Popup open={CreateManuallyPop}>
                                          <div>
                                            <div className="modal-black"></div>
                                            <div className="filterPopup largerPopup1000">
                                              <div className="paddingboxTerms">
                                                <div className="modal-header py-4 px-3">
                                                  <div className="w-100 d-flex px-3">
                                                    <h5 className="mb-0">
                                                      Add Contacts
                                                    </h5>
                                                    <button
                                                      className="close"
                                                      onClick={
                                                        CreateManuallyPopHandle
                                                      }
                                                    >
                                                      <span
                                                        aria-hidden="true"
                                                        class="la la-remove"
                                                      ></span>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="px-4">
                                                    <div className="row">
                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            First Name
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="FirstName"
                                                              name="FirstName"
                                                              placeholder="First name"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Last Name
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="LastName"
                                                              name="FirstName"
                                                              placeholder="Last Name"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Email
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Email"
                                                              name="Email"
                                                              placeholder="Email"
                                                              type="email"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Title
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Title"
                                                              name="Title"
                                                              placeholder="Title"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Company
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Company"
                                                              name=""
                                                              placeholder="Company"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Company Name Long
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="CompanyNameLong"
                                                              name="Company Name Long"
                                                              placeholder="Company Name Long"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Website
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Website"
                                                              placeholder="Website"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Company Phone
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="CompanyPhone"
                                                              placeholder="Company Phone"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Direct Phone
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="DirectPhone"
                                                              placeholder="Direct Phone"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Direct Phone Ext
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="DirectPhoneExt"
                                                              placeholder="Direct Phone Ext"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            LinkedIn URL
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="LinkedInURL"
                                                              placeholder="LinkedIn URL"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Mobile Phone
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="MobilePhone"
                                                              placeholder="Mobile Phone"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Industry
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Industry"
                                                              placeholder="Industry"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Address 1{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Address1"
                                                              placeholder="Address 1"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Address 2
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Address2"
                                                              placeholder="Address 2"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            City{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="City"
                                                              placeholder="City"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            State
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="State"
                                                              placeholder="State"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Zip{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Zip"
                                                              placeholder="Zip"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Country
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="Country"
                                                              placeholder="Country"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Contact Category{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="ContactCategory"
                                                              placeholder="Contact Category"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Account Category
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="AccountCategory"
                                                              placeholder="Account Category"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Contact Source{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <select
                                                              className="form-control  m-input"
                                                              name="ContactSource"
                                                              id="ContactSourceID"

                                                              // onChange={this.handleChange.bind(
                                                              //   this,
                                                              //   "ContactSourceID"
                                                              // )}
                                                            >
                                                              <option value="">
                                                                --Select--
                                                              </option>
                                                              {ContactSourceData.map(
                                                                (value) => (
                                                                  <option
                                                                    value={
                                                                      value._id
                                                                    }
                                                                  >
                                                                    {value.Name}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            <span
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {
                                                                ContactManuallyError[
                                                                  "ContactSourceID"
                                                                ]
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Contact Owner
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <select
                                                              className="form-control  m-input"
                                                              name="ContactOwner"
                                                              id="ContactOwnerID"
                                                              // onChange={this.handleChange.bind(
                                                              //   this,
                                                              //   "ContactOwnerID"
                                                              // )}
                                                            >
                                                              <option value="">
                                                                --Select--
                                                              </option>
                                                              {ContactOwnerData.map(
                                                                (value) => (
                                                                  <option
                                                                    value={
                                                                      value._id
                                                                    }
                                                                  >
                                                                    {value.Name}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            <span
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {
                                                                ContactManuallyError[
                                                                  "ContactOwnerID"
                                                                ]
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Client POC{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <select
                                                              className="form-control  m-input"
                                                              name="Client POC"
                                                              id="ClientPOCID"
                                                              //onChange={this.handleChange.bind(this, "ClientPOCID")}
                                                            >
                                                              <option value="">
                                                                --Select--
                                                              </option>
                                                              {ClientPOCData.map(
                                                                (value) => (
                                                                  <option
                                                                    value={
                                                                      value._id
                                                                    }
                                                                  >
                                                                    {value.Name}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            <span
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {
                                                                ContactManuallyError[
                                                                  "ClientPOCID"
                                                                ]
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Reason Unqualified
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <select
                                                              className="form-control  m-input"
                                                              name="ReasonUnqualified"
                                                              id="ReasonUnqualifiedID"
                                                              // onChange={this.handleChange.bind(
                                                              //   this,
                                                              //   "ReasonUnqualifiedID"
                                                              // )}
                                                            >
                                                              <option value="">
                                                                --Select--
                                                              </option>
                                                              {ReasonUnqualifiedData.map(
                                                                (value) => (
                                                                  <option
                                                                    value={
                                                                      value._id
                                                                    }
                                                                  >
                                                                    {
                                                                      value.Reason
                                                                    }
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            <span
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {
                                                                ContactManuallyError[
                                                                  "ReasonUnqualifiedID"
                                                                ]
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Company Revenue{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="CompanyRevenue"
                                                              placeholder="Company Revenue"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Employee Count
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="CompanyEmployeeCount"
                                                              placeholder="Employee Count"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Sales Development
                                                            Reps{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <select
                                                              className="form-control  m-input"
                                                              name="ColdCallersID"
                                                              id="ColdCallersID"
                                                            >
                                                              <option value="">
                                                                --Select--
                                                              </option>
                                                           
                                                              {ContactSalesDevlopMenData.map(
                                                                (value) => (
                                                                  <option
                                                                    value={
                                                                      value._id
                                                                    }
                                                                  >
                                                                    {
                                                                      value.ColdCallersName
                                                                    }
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            SalesRep
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              class="form-control m-input"
                                                              id="SalesRep"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Tag{" "}
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <Select
                                                              closeMenuOnSelect={
                                                                false
                                                              }
                                                              hideSelectedOptions={
                                                                false
                                                              }
                                                              options={
                                                                ContactTagData
                                                              }
                                                              id="ContactTagID"
                                                              isMulti
                                                              onChange={(val) =>
                                                                handleChangeContactOption(
                                                                  val
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Other Phone 1
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="OtherPhone1"
                                                              placeholder="Other Phone 1"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            Other Phone 2
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="OtherPhone2"
                                                              placeholder="Other Phone 2"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            External Account ID
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="ExternalAccountID"
                                                              placeholder="External Account ID"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            External Contact ID
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="ExternalContactID"
                                                              placeholder="External Contact ID"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            External Field 1
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="ExternalField1"
                                                              placeholder="External Field 1"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            External Field 2
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="ExternalField2"
                                                              placeholder="External Field 2"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-6 boxsinput_group">
                                                        <div className="row">
                                                          <label className="col-lg-4 pt-2">
                                                            External Field 3
                                                          </label>
                                                          <div className="col-lg-8">
                                                            <input
                                                              className="form-control m-input"
                                                              id="ExternalField3"
                                                              placeholder="External Field 3"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-12 boxsinput_group">
                                                        <div className="row">
                                                          <label class="check_bx">
                                                            <input
                                                              type="checkbox"
                                                              id="IsDoNotCallContact"
                                                            />
                                                            <span className="checkmark"></span>
                                                            Do Not Call Contact
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div class="modal-footer px-0">
                                                      <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        data-dismiss="modal"
                                                        onClick={
                                                          CreateManuallyPopHandle
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                      <button
                                                        id="SubmitContactManually"
                                                        onClick={(e) => {
                                                          AddContactManually(e);
                                                        }}
                                                        class="btn btn-primary btn-lightgreen"
                                                      >
                                                        <i class="la la-save"></i>{" "}
                                                        Save
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Popup>
                                        <p>
                                          Add contacts to contact list manually
                                          one by one.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </li>
                      <div className="col-lg-12" id="move_campaign" style={{display:"none"}}>
                    <Select  options={CampaignDataDrop} id="CampaignMoveID"
                                            onChange={(e) => CampaignMove(e)}
                         classNamePrefix="Campaign" name="color"/>
                    </div>
                      <li>
                      <Tooltip title="Move">
                        <a
                          onClick={ContactMoveToCampaign}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="flaticon-paper-plane"></i>
                        </a>
                        </Tooltip>
                      </li>
                      <li>
                      <Tooltip title="Delete">
                        <a
                       onClick={DeleteProspectCampaign}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="flaticon-delete-1"></i>
                        </a>
                        </Tooltip>
                      </li>
                      <li  onClick={()=>{SetShowPopupExport1(true)}}>
                      <Tooltip title="Export">
                        <a
               
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </a>
                        </Tooltip>
                      </li>
                      <Popup open={ShowPopupExport1}>
              
              <div>
                <div className="modal-black"></div>
                <div className="filterPopup largerPopup">
                  <div className="paddingboxTerms">
                    <div className="modal-header py-4 px-3">
                      <div className="w-100 d-flex px-3">
                        <h5 className="mb-0">Email</h5>
                        <button className="close" onClick={() => SetShowPopupExport1(false)}>
                          <span
                            aria-hidden="true"
                            className="la la-remove"
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="modal-body">
                        <div className="col-sm-6 mb-5 px-0">
                          <div className="row mb-3">
                            <label className="col-lg-5 col-form-label text-left">
                              Email
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id="UsernameExport"
                                data-val="true"
                                data-val-remote="Title already exist."
                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                data-val-required="Please enter Email "
                                name="UsernameExport"
                                defaultValue={UserEmail}
                                onBlur={()=>{HandleChange("UsernameExport")}}
                                type="text"
                              />
                                    <span style={{ color: "red" }}>
                                            {ExportCsvErrors["UsernameExport"]}
                                          </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-top modal-content text-right">
                        <div className="row">
                          <div className="col-lg-12 pull-right">
                            <a
                              id="backtolist"
                              className="btn btn-secondary mr-2"
                              onClick={() => SetShowPopupExport1(false)}
                            >
                              cancel
                            </a>
                            <a
                              id="submitcsv"
                              onClick={ExportsToProspectCampaign}
                              className="btn btn-primary btn-lightgreen mr-1"
                            >
                              Save
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
                      </Popup>
                      <li>
                      <Tooltip title="Validate">
                        <a
                           onClick={ContactValidateEmailCheck}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="fa la-icon-font-size-13 fa-check-square-o"></i>
                        </a>
                        </Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <CampaignsContact
                    contactFlag={ProspectFlag}
                    CCampaignID={CampaignID}
                    onData={handleDataFromChild}
                  onData2={handleDataFromCampaign}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "none" }} id="fourStep">
              <div className="timeline-col stepercard">
                <Timeline>
                  <Container
                    dragHandleSelector=".drag-handle"
                    lockAxis="y"
                    onDrop={onDrop}
                  >
                    {CampaignDetails.map((Step) => (
                      <Draggable key={Step._id}>
                        <ListItem>
                          <ListItemIcon className="drag-handle">
                            <TimelineItem>
                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5>
                                  Step {Step.OrderBy} - Day {Step.TotalCount}
                                  <KeyboardArrowDownIcon
                                    aria-describedby={Step._id}
                                    variant="contained"
                                    onClick={(e) => {
                                      handleClick(
                                        e,
                                        Step.Days,
                                        Step._id
                                      );
                                    }}
                                  />
                                </h5>
                                <div className="cardstep">
                                  {/* data inner step*/}
                                  {Step.CStepTemplate.map(
                                    (StepTemplate, index) => (
                                      <div className="pb-2 border-top">
                                        <div class="row my-2">
                                          <div class="col-lg-4 nopadding">
                                            <Row>
                                              <Col sm={2}>
                                                {String.fromCharCode(
                                                  65 + index
                                                )}
                                              </Col>
                                              <Col sm={10} class="nopadding">
                                                <b>
                                                  {" "}
                                                  {parse(
                                                    StepTemplate.Subject
                                                  )}{" "}
                                                </b>
                                              </Col>
                                            </Row>
                                          </div>
                                          <div class="col-lg-6">
                                            {StepTemplate.Body}
                                          </div>
                                          <div class="col-lg-2 iconssmaller showme d-flex">
                                            <a
                                              onClick={() => {
                                                EditStepTempletPopHandle(
                                                  StepTemplate.CampaignStepTemplateID
                                                );
                                              }}
                                            >
                                              <i class="la flaticon-edit-1 edit-icon"></i>
                                            </a>
                                            <a
                                              onClick={() => {
                                                handleAddDuplicateTemplete(
                                                  StepTemplate.CampaignID,
                                                  StepTemplate.CampaignStepID,
                                                  StepTemplate.Body,
                                                  StepTemplate.Subject,
                                                  StepTemplate.ClientID,
                                                  StepTemplate.UserID,
                                                  StepTemplate.OrderBy
                                                );
                                              }}
                                            >
                                              <i class="la flaticon-add add-icon edit-icon"></i>
                                            </a>
                                            {Step.CStepTemplate.length == 1 ? (
                                              " "
                                            ) : (
                                              <a
                                                onClick={() => {
                                                  handleDeleteStepTemplete(
                                                    StepTemplate._id
                                                  );
                                                }}
                                              >
                                                <i class="la flaticon-delete-1 delete-icon edit-icon"></i>
                                              </a>
                                            )}
                                          </div>
                                        </div>

                                        <div class="row">
                                          <div class="col-lg-11"></div>
                                          <div class="col-lg-1">
                                            <div class="toggleswich">
                                              <input
                                                type="checkbox"
                                                class="checktogle"
                                                onChange={(e) => {
                                                  StepTemplateActive(
                                                    StepTemplate._id,
                                                    !StepTemplate.IsActive
                                                  );
                                                }}
                                                checked={StepTemplate?.IsActive}
                                              />
                                              <b class="switch">
                                                <span class="checkion"></span>
                                                <span class="uncheckion"></span>
                                              </b>
                                              <b class="track"></b>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}

                                  <div className="plusicons showme">
                                    <a
                                      onClick={() => {
                                        AddStepTempletPopHandle(
                                          Step._id
                                        );
                                      }}
                                    >
                                      <i class="la flaticon-add-circular-button add-icon"></i>
                                    </a>
                                  </div>
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          </ListItemIcon>
                        </ListItem>
                      </Draggable>
                    ))}
                  </Container>
                </Timeline>
              </div>
              <Popup open={AddStepTempletePopup}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup1000">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Add Step</h5>
                          <button
                            className="close"
                            onClick={AddStepTempletPopHandle}
                          >
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="px-4">
                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Subject
                            </label>
                            <div class="col-lg-9 d-flex">
                              <input
                                class="form-control m-input"
                                type="text"
                                id="TemplateSubject"
                              />
                            </div>
                          </div>

                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Body
                            </label>
                            <div class="col-lg-9">
                              <FroalaEditor
                                id="signature2"
                                config={config}
                                onModelChange={VariableModelChange2}
                                model={SignatureSC2.data}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={AddStepTempletPopHandle}
                        >
                          Cancel
                        </button>
                        <button
                          id="SubmitStepTemplate"
                          onClick={AddStepTemplatePopupSubmit}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-save mr-2"></i> Save{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              <Popover
                className="minipoupdev"
                id={CampaignStepID}
                open={open}
                anchorEl={AnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Row>
                  <Col>Wait time:</Col>
                </Row>
                <Row className="align-item-center">
                  <Col>
                    <input
                      class="form-control m-input"
                      type="number"
                      min="0"
                      id="DaysTemplate"
                      name="Days"
                      defaultValue={DaysTemp}
                    />
                  </Col>
                  <Col className="px-0 py-2">Days</Col>
                  <Col></Col>
                </Row>
                <Row className="mt-4">
                  <Col className="text-right">
                    <a onClick={handleClose} class="btn btn-secondary mr-2">
                      Cancel
                    </a>
                    <a
                      onClick={() => ChangeDays()}
                      class="btn btn-primary btn-lightgreen"
                    >
                      <i class="la la-save"></i> Save
                    </a>
                  </Col>
                </Row>
              </Popover>
              <Popup open={EditStepTempletePopup}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup1000">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Add Step</h5>
                          <button
                            className="close"
                            // onClick={() => {SetEditStepTempletePopup(false)}}
                          >
                            <span
                              aria-hidden="true"
                              class="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="px-4">
                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Subject
                            </label>
                            <div class="col-lg-9 d-flex">
                              <input
                                class="form-control m-input"
                                type="text"
                                id="TemplateSubject3"
                              />
                            </div>
                          </div>

                          <div class="row mb-4">
                            <label class="col-lg-3 col-form-label textright-vmb">
                              Body
                            </label>
                            <div class="col-lg-9">
                              <FroalaEditor
                                id="signature3"
                                config={config}
                                onModelChange={VariableModelChange3}
                                model={SignatureSC3.data}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={EditStepTempletPopHandle}
                        >
                          Cancel
                        </button>
                        <button
                          id="SubmitStepTemplate"
                          onClick={() => {
                            EditStepTemplatePopupSubmit();
                          }}
                          class="btn btn-primary btnColor"
                        >
                          <i class="la la-save mr-2"></i> Save{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              <div className="row">
                <div className="col w-100">
                  <div className="iconer">
                    <i className="flaticon-file-1 clr-purple"></i>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col w-100 text-center mb-3">
                  <a
                    class="btn btn-primary btngroup"
                    onClick={AddStepPopHandle}
                  >
                    <i className="la la-plus-circle la-icon-font-size-13"></i>{" "}
                    Add Campaign Step
                  </a>
                  <Popup open={AddStepPopup}>
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup1000">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-4 px-3">
                            <div className="w-100 d-flex px-3">
                              <h5 className="mb-0">Add Step</h5>
                              <button
                                className="close"
                                onClick={AddStepPopHandle}
                              >
                                <span
                                  aria-hidden="true"
                                  class="la la-remove"
                                ></span>
                              </button>
                            </div>
                          </div>
                          <div className="modal-body">
                            <div className="">
                              <div class="row mb-4 px-5">
                                <label class="col-lg-2 col-form-label pl-5">
                                  Wait time
                                </label>
                                <div class="col-lg-3 d-flex">
                                  <input
                                    class="form-control m-input"
                                    placeholder=""
                                    id="Days"
                                    type="number"
                                    defaultValue={0}
                                    min={0}
                                  />
                                  <label class="col-form-label text-left">
                                    Days
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-4 px-5">
                                <label class="col-lg-2 col-form-label pl-5 px-0">
                                  Subject
                                </label>
                                <div class="col-lg-9 d-flex">
                                  <input
                                    class="form-control m-input"
                                    id="AddCampaignSubject"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="row mb-4 px-5">
                                <label class="col-lg-2 col-form-label pl-5">
                                  Body
                                </label>
                                <div class="col-lg-9">
                                  <FroalaEditor
                                    id="signature"
                                    config={config}
                                    onModelChange={VariableModelChange}
                                    model={SignatureSC.data}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={AddStepPopHandle}
                            >
                              Cancel
                            </button>
                            <button
                              id="Step_submit"
                              href="javascript:void(0);"
                              class="btn btn-primary btnColor"
                              onClick={(e) => {
                                AddCampaignStep(e);
                              }}
                            >
                              <i class="la la-save mr-2"></i> Save{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popup>
                  <div className="timeline-col"></div>
                </div>
              </div>
            </div>
            <div style={{ display: "none" }} id="finalStep">
              <div className="row">
                <div className="col padd-0">
                  <h4 className="headertitlebd"></h4>
                </div>
                <div className="col padright">
                  <div className="listing-li float-right">
                    <ul>
                      <li className="px-0">
                        <a
                          onClick={handleClickrandam}
                          className="btn btngroup m-btn m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="flaticon-refresh"></i> Refresh
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className="btn btngroup m-btn m-btn--pill m-btn--icon m-btn--air"
                        >
                          Select Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="timeline-col">
                <Timeline>
                  {PreviewCampaignDetails.map((Step) => (
                    <TimelineItem key={Step.CampaignStepID}>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <h5>
                          Step {Step.StepNumber} - Day {Step.Days}
                        </h5>

                        <div className="accordioncol">
                          {Step.CStepTemplate.map((StepTemplate, index) => (
                            <Accordion
                              key={StepTemplate._id}
                              expanded={
                                activeIndexes.indexOf(StepTemplate._id) !== -1
                              }
                              onChange={() =>
                                handleChangeAccrdion(StepTemplate._id)
                              }
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>
                                  <span>{String.fromCharCode(65 + index)}</span>
                                  {parse(StepTemplate.Subject)}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  {parse(StepTemplate.Body)}
                                </Typography>
                                {/* {SelectedPreviewCampign?(<Typography>{SelectedPreviewCampign.ProspectCampaignID}</Typography>):(<></>)} */}
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>
            </div>
          </div>
          <div className="btn-component border-top px-0">
            <button
              className="btn btn-secondary back-button-camp"
              type="button"
              onClick={handleBack}
              disabled={steps[0].key === activeStep.key}
            >
              <i className="la la-arrow-circle-left"></i> Back
            </button>
            <div className="">
              <button
                type="button"
                className="btn btn-primary btn-lightgreen mx-2"
                value="Skip"
                onClick={() => {
                  handleNext("Skip");
                }}
                disabled={steps[0].key === activeStep.key}
              >
                Skip <i className="la la-arrow-circle-right"></i>
              </button>
              {steps[steps.length - 1].key !== activeStep.key ? (
                <button
                  type="button"
                  className="btn btn-primary btn-lightgreen"
                  onClick={() => {
                    handleNext("Next");
                  }}
                >
                  Next
                  <i className="la la-arrow-circle-right"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-lightgreen"
                  onClick={() => {
                    handleNext("Finish");
                  }}
                >
                  Finish
                  <i className="la la-arrow-circle-right"></i>
                </button>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

 
export default App;