import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Axios from "axios";
const moment = require("moment");
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";
const Fileupload = require("../../../_helpers/fileupload");
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CSVReader } from "react-papaparse";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { Book } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
toast.configure();

export default function AccountAllTable({
  AccountTypeForTab,
  AccountTypeName,
}) {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [prospectAllSelectData, setProspectAllSelectData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [sortField, setsortField] = React.useState("CreatedDate");
  const [sortedBy, setsortedBy] = React.useState(-1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [ContactSalesDevlopMenData, setContactSalesDevlopMenData] =
    React.useState([]);
  const [ContactCustomFieldData, setContactCustomFieldData] = React.useState(
    []
  );
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [ImportCsvReset, SetImportCsvReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [filenameonlynumber, setfilenameonlynumber] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [csvData2, setcsvData2] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [ContactHeader, SetContactHeader] = React.useState([]);
  
  const [FirstName, SetFirstName] = React.useState(null);
  const [LastName, SetLastName] = React.useState(null);
  const [Email, SetEmail] = React.useState(null);
  const [Title, SetTitle] = React.useState(null);
  const [Company, SetCompany] = React.useState(null);
  const [CompanyNameLong, SetCompanyNameLong] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [City, SetCity] = React.useState(null);
  const [State, SetState] = React.useState(null);
  const [ContactCategory, SetContactCategory] = React.useState(null);
  const [AccountCategory, SetAccountCategory] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [Address1, SetAddress1] = React.useState(null);
  const [Address2, SetAddress2] = React.useState(null);
  const [LinkedInURL, SetLinkedInURL] = React.useState(null);
  const [Zip, SetZip] = React.useState(null);
  const [Country, SetCountry] = React.useState(null);
  const [ValidationScore, SetValidationScore] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [CompanyRevenue, SetCompanyRevenue] = React.useState(null);
  const [EmployeeCount, setEmployeeCount] = React.useState(null);
  const [MobilePhone, SetMobilePhone] = React.useState(null);
  const [DirectPhone, SetDirectPhone] = React.useState(null);
  const [DirectPhoneExt, SetDirectPhoneExt] = React.useState(null);
  const [OtherPhone1, SetOtherPhone1] = React.useState(null);
  const [OtherPhone2, SetOtherPhone2] = React.useState(null);
  const [ContactSource, SetContactSource] = React.useState(null);
  const [ContactOwner, SetContactOwner] = React.useState(null);
  const [ClientPOC, SetClientPOC] = React.useState(null);
  const [ReasonUnqualified, SetReasonUnqualified] = React.useState(null);
  const [AccountType, SetAccountType] = React.useState(null);
  const [IsDoNotCallContact, SetIsDoNotCallContact] = React.useState(null);
  const [SalesRep, SetSalesRep] = React.useState(null);
  const [ColdCaller, SetColdCaller] = React.useState(null);
  const [Tag, SetTag] = React.useState(null);
  const [CallNotes, SetCallNotes] = React.useState(null);
  const [ContactID, SetContactID] = React.useState(null);
  const [ListName, SetListName] = React.useState(null);
  const [CampaignData, SetCampaignData] = React.useState([]);
  const [ShowPopup, SetShowPopup] = React.useState(false);
  const [ShowPopupReassignDev, SetShowPopupReassignDev] = React.useState(false);
  const [ShowPopupExport, SetShowPopupExport] = React.useState(false);
  const [ShowPopupCampaign, SetShowPopupCampaign] = React.useState(false);
  const [ContactReassignData, SetContactReassignData] = React.useState([]);
  const [ContactToReassignData, SetContactToReassignData] = React.useState([]);

  const [CheckState, SetCheckState] = React.useState(false);
  const [ContactTag, SetContactTag] = React.useState([]);
  const [ContactTagSelectArray, setContactTagSelectArray] = React.useState([]);
  const [ReassignFromSelectedValue, SetReassignFromSelectedValue] =
    React.useState([]);
  const [ReassignToSelectedValue, SetReassignToSelectedValue] = React.useState(
    []
  );

  const [Total, SetTotal] = React.useState(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalUpdated, SetTotalUpdated] = React.useState(0);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [ShowPopupImport, SetShowPopupImport] = React.useState(false);

  const [OpenAddFilter, SetOpenAddFilter] = React.useState(false);
  const [IsApply, SetIsApply] = React.useState(false);
  const [CustomColumnData, SetCustomColumnData] = React.useState([]);
  const [CsvFileName, SetCsvFileName] = React.useState("");
  const [FieldAttributes, SetFieldAttributes] = React.useState([
    {
      id: Math.floor(Math.random() * 1000000000),
      FieldValue: "AccountCategory",
      ColumnName: "Account Category",
      Expression: "Contains",
      FilterPropertyValue: "",
      FilterPropertyValue1: "",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      NullCondition: false,
    },
  ]);
  const [FilterParameters, SetFilterParameters] = React.useState([]);
  const [FilterName, SetFilterName] = React.useState(null);
  const [FilterID, SetFilterID] = React.useState(null);
  const [EditFieldAttributes, SetEditFieldAttributes] = React.useState([]);
  const [DeletedFilterProperty, SetDeletedFilterProperty] = React.useState([]);
  const [IsFiltered, SetIsFiltered] = React.useState(false);
  const [SaveAsBoxFilterName, SetSaveAsBoxFilterName] = React.useState(false);
  const [ErrorMessage, SetErrorMessage] = React.useState([]);
  const [ContactTags, SetContactTags] = React.useState([]);
  const [DeleteTagModel, SetDeleteTagModel] = React.useState(false);
  const [AddTagBtnDisabled, SetAddTagBtnDisabled] = React.useState(false);
  const [TableRowLength, SetTableRowLength] = React.useState(null)
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [OpenImportCsv,SetOpenImportCsv] = React.useState(false)
  const [OpenDeleteCsv,SetOpenDeleteCsv] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0);
  const [DisabledAddFilter,SetDisabledAddFilter] = React.useState(false)
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [IsDisablesExport, setExportsToContacts] = React.useState(false);
  const [TotalCountFlag, SetTotalCountFlag] = React.useState(false);
  const [PageCountForCheck, SetPageCountForCheck] = React.useState([]);
  const [ResetBulkDelete, SetResetBulkDelete] = React.useState(false);
 const [HandleChangeArray,SetHandleChangeArray] = React.useState([])
  
  const location = useLocation();
  const myparam = location;

  useEffect(() => {
    document.title = "Contacts | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      setRole(Details.Role);
      SetUserEmail(Details.Username);
    }
  }, [ UserEmail]);

  useEffect(()=>{
  var Details = GetUserDetails();
        // ContactCustomHeaderDataGet(Details.ClientID, Details.ParentUserID, Details.Role)
      // Use Promise.all to wait for both requests to complete
  Promise.all([
    ContactCustomHeaderDataGet(Details.ClientID, Details.ParentUserID, Details.Role),
    ContactListGet(Details.ClientID, Details.ParentUserID, Details.Role,page),
    ContactListTotal(Details.ClientID, Details.ParentUserID, Details.Role)
  ]).then(() => {
    // Both requests completed, set loader to false
    SetIsLoading(false);
  }).catch((error) => {
    toast.error("An error occurred. Please try again.");
    SetIsLoading(false);
  });
    // ContactListGet(Details.ClientID, Details.ParentUserID, Details.Role);
    ContactInfoGetData(Details.ClientID, Details.ParentUserID, Details.Role);
    GetCustomFiledsData(Details.ClientID, Details.ParentUserID, Details.Role);
    Getfilterlist(Details.ClientID);
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/GetContactTags",
      method: "POST",
      data: { ClientID: Details.ClientID, UserID: Details.ParentUserID },
    }).then((res) => {
      SetContactTags(res.data.Data);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  },[search, page, RowsPerPage, IsApply]);

  // Exports csv for contacts
  const ExportsToContacts = () => {
    var ExportEmails = document.getElementById("Username").value;
    setExportsToContacts(true)
    var ExportData = {
      UserID: UserID,
      ClientID: ClientID,
      Email: ExportEmails,
      AccountType: AccountTypeName,
      CreatedDate: new Date(),
      CreatedBy: CUserID,
      AccountTypeID: null,
      ClientIDs: null,
      IsDeleted: false,
      LastUpdatedDate: null,
      LastUpdatedBy: null,
      InProcess: false,
      IsComplete: false,
      InProcessDate: null,
      IsCompleteDate: null,
      Page: "",
      CampaignID: null,
      DynamicQuery: "",
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/AllClientExportAdd",
      method: "POST",
      data: ExportData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Contact
            <br />
            You will get file soon on Submitted Email.
          </div>
        );
        SetShowPopupExport(false);
        setExportsToContacts(false)
      } else {
        toast.error(res.data.Message);
        setExportsToContacts(false)
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };


  // handle change contact tag delete
  const handleChangeContactOption = (val) => {
    var TagsArray = [];
    val?.map((item) => {
      TagsArray.push({
        ContactTagID: item.cid,
        TagName: item.label,
      });
    });

    setContactTagSelectArray(TagsArray);
  };

// from handle change sales development reps
const HandleChangeFromSaleDevlopMentReps = (e) => {
  const selectedValue = e.value;

  // Remove the selected value from the options in "To" dropdown
  var updatedOptions = ContactReassignData.filter(
    (option) => option.value !== selectedValue
  );

  // Update the options in "To" dropdown
  SetContactToReassignData(updatedOptions);
  if(ReassignFromSelectedValue &&  selectedValue === ReassignToSelectedValue.value){
    SetReassignToSelectedValue(e);
  }
  // Update the selected value in "From" dropdown
  SetReassignFromSelectedValue(e);
  
};
  

  // To handle change sales development reps
  const HandleChangeToSaleDevlopMentReps = (val) => {
    SetReassignToSelectedValue(val);
  };

  // Reassign To SaleDevlopment
  const ReassignToColdCallers = () => {
    if(ReassignFromSelectedValue.value == null){
      toast.error("Please select from sales development reps.")
    }else{
      SetIsLoading(true)
      var ReassignData = {
        ColdCallerFromId: ReassignFromSelectedValue.value,
        ColdCallerToId: ReassignToSelectedValue.value,
      };
  
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ReassignToColdCallers",
        method: "POST",
        data: ReassignData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Contacts
              <br />
              Sales development reps reassign successfully.
            </div>
          );
          ContactReassignDevPopupHideBtn();
          // document.getElementById("hideloding").style.display = "none";
          SetIsLoading(false)
        } else {
          toast.error(res.data.Message);
          // document.getElementById("hideloding").style.display = "none";
          SetIsLoading(false)
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }


  };

  // delete multiple contact tag
  const DeleteSelectContactTag = () => {
    if (ContactTagSelectArray.length == 0) {
      toast.error(<div className="toastsize">Please Select Tag.</div>);
    } else {

      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete the selected tag.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No,cance!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          
          SetIsLoading(true)
          var ContactTagData = {
            ClientID: ClientID,
            ContactArrays: ContactTagSelectArray,
            LastUpdatedBy: CUserID,
            LastUpdatedDate: new Date(),
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/contacts/ContactTagDeleteToPropspect",
            method: "POST",
            data: ContactTagData,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
    
                SetDeleteTagModel(false);
                Swal.fire("Deleted!", "Tag deleted successfully.", "success");
                                    
                SetIsLoading(false)
                setContactTagSelectArray([]);
                ContactListGet(ClientID,UserID,Role,page);
                ContactListTotal(ClientID,UserID,Role);
                setProspectAllSelectData([])
                document.getElementById("main-check").checked = false
              } else {
                SetIsLoading(false)
                setContactTagSelectArray([]);
                ContactListGet(ClientID,UserID,Role,page);
                ContactListTotal(ClientID,UserID,Role);
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
          // document.getElementById("hideloding").style.display = "none";
          SetIsLoading(false)
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }
  };


  // Get All Contact Info Data
  const ContactInfoGetData = (CID, UID, Role) => {
    var InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: InputParaMeter,
    });
    rows.then((result) => {
      setContactSalesDevlopMenData(result.data?.ContactSalesDevlopMenData);
      setContactCustomFieldData(result.data?.ContactCustomFieldData);
      SetContactTag(result.data?.ContactTagData);
      const options = result.data?.ContactsReassignData[0]?.ColdCallersDetails?.map((val) => {
        return { value: val.value, label: val.label
        };
      });
      SetContactReassignData(options)
      // SetContactToReassignData(options);   
      SetCampaignData(result.data?.CampaignGetData);

    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };


  

  // Move to campaign list
  const CampaignListMove = () => {
  document.getElementById("Show_csv_details").style.display = "none"

    if (prospectAllSelectData.length == 0) {
      toast.error(
        <div className="toastsize">
          Move Contact
          <br />
          Please select campaign.
        </div>
      );
    } else {
      SetShowPopupCampaign(true);
    }
  };

  // move to campaign list
  const CampaignToList = () => {
if( document.getElementById("CampaignID").value==""){
toast.error("Move Contact Please Select Campaign")
}else{
  Swal.fire({
    title: "Are you sure?",
    text: "You want to move to campaign.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#34bfa3",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, move it!",
  }).then((result) => {
    if (result.isConfirmed) {
      SetIsLoading(true)
      var Campaign = document.getElementById("CampaignID").value;
      var CampaignData = {
        ClientID: ClientID,
        UserID: UserID,
        CampaignID: Campaign,
        CampaignMoveArray: prospectAllSelectData,
        CreatedDate: new Date(),
        CreatedBy: CUserID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignMove",
        method: "POST",
        data: CampaignData,
      }).then((res) => {
        if (res) {
          if ((res.statusText = "Ok")) {
            Swal.fire(
              "Moved!",
              "Contact move campaign successfully.",
              "success"
            );
            SetShowPopupCampaign(false);
            SetIsLoading(false)
            UnSelectCheckHandler();
          } else {
          }
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Your data is safe :)", "error");
    }
  }).catch((error) => {
    toast.error("An error occurred. Please try again.");
    SetIsLoading(false);
  });

}
  };

  //  multiple delete for function
  const DeleteSelectedAll = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var ContactData = {
          ContactDeleteArray: prospectAllSelectData,
          LastUpdatedBy: CUserID,
        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactDeleteMultiple",
          method: "POST",
          data: ContactData,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Contact  deleted successfully.",
                "success"
              );
              ContactListGet(ClientID,UserID,Role,page);
              ContactListTotal(ClientID,UserID,Role);
              document.getElementById("main-check").checked = false
              SetIsLoading(false)
            } else {
            }
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        SetIsLoading(false)
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  // contact add to blacklist domain
  const ContactAddToBlackList = () => {
  document.getElementById("Show_csv_details").style.display = "none"

    if (prospectAllSelectData.length == 0) {
      toast.error(
        <div className="toastsize">
          Please select atleast one contacts from list.
        </div>
      );
      SetIsLoading(false)
    } else {
      SetIsLoading(true)
      var ContactData = {
        ClientID: ClientID,
        LastUpdatedBy: CUserID,
        ContactBlackListArray: prospectAllSelectData,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactToAddToBlackList",
        method: "POST",
        data: ContactData,
      }).then((res) => {
        if (res) {
          if ((res.data.StatusMessage == "SUCCESS")) {
            Swal.fire(
              "Blacklisted!",
              "Selected contacts blacklisted successfully.",
              "success"
            );
            SetDeleteArray([])
            UnSelectCheckHandler()
            SetIsLoading(false)
          } else if(res.data.StatusMessage == "UNSUCCESS") {
            toast.error(<div dangerouslySetInnerHTML={{ __html: res.data.Message }} />)
            SetDeleteArray([])
            UnSelectCheckHandler()
            SetIsLoading(false)
          }else{
            toast.error(<div dangerouslySetInnerHTML={{ __html: res.data.Message }} />)
            SetDeleteArray([])
            UnSelectCheckHandler()
            SetIsLoading(false)
          }
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }
  };

  // changes row selected
  const ChangeRowSelected = (event) => {
    var Details = GetUserDetails();
    setPage(1);
    SetRowsPerPage(Number(event.target.value));
    SetIsLoading(true)
    var str_in = {
      Page: page,
      RowsPerPage: Number(event.target.value),
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: search,
      Type: "User",
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      AccountType: AccountTypeForTab,
      IsApply: IsApply,
      FilterPropertiesArray: FilterParameters,
      Role: Role,
      IsTotalCount:!TotalCountFlag
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      // setdata(result.data?.PageData);
      // setRows(result.data?.PageData);
      setrlen(result.data?.TotalCount);
      setflen(result.data?.TotalCount);
      setCountPage(result.data?.PageCount);
      // SetTableRowLength(result.data?.PageData?.length)
      SetIsLoading(false)
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  // search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var searchedVal = document.getElementById("search").value;
      setSearch(searchedVal);
      setPage(1); 
    }
  };

  // change page
  const HandleChangePage = async (Event, NewPage) => {
    setPage(NewPage);
    if (NewPage == page) {

      IsLoading(true)
      setPage(NewPage);
      IsLoading(false)

    } else {
      const result = await ContactListGet(ClientID,UserID,Role,NewPage);
      if (result !== null) {
          const PerPageCheckCount = result.data?.PageData?.filter((item) =>
              prospectAllSelectData.some((paitem) => item._id === paitem._id)
          );
          const finalLengthforperpage = result.data?.PageData.length;
          const Pagecheckcount = PerPageCheckCount.length
          if(finalLengthforperpage == Pagecheckcount){
            document.getElementById("main-check").checked = true;
          }else{
            document.getElementById("main-check").checked = false;
          }
      }
    }
  };
  const ContactCustomHeaderDataGet = (CID, UID, Role) => {
    SetIsLoading(true);
    var InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
  
    return Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactCustomHeaderData",
      method: "POST",
      data: InputParaMeter,
    })
      .then((result) => {
        if (result.data?.StatusMessage === "SUCCESS") {
          SetContactHeader(result.data?.Data);
          return result; // Return the result to be used in Promise.all
        } else {
          const errorMessage =
            result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
            toast.error(errorMessage);
        }
      })
      .catch((error) => {
        // Handle the error that occurred during the API request
  
        // Display error message using toast.error
        toast.error(`Error: ${error?.message}`);
  
        // You can also set an error state or show an error message to the user
        // For example: SetErrorState(true);
  
        throw error; // Rethrow the error to be caught by the caller
      });
  };

  // contact get
  const ContactListGet = async (CID, UID, Role,currentPage) => {
    try {
      var str_in = {
        Page: currentPage,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: sortField,
        SortBy: sortedBy,
        Search: search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        AccountType: AccountTypeForTab,
        IsApply: IsApply,
        FilterPropertiesArray: FilterParameters,
        IsTotalCount:TotalCountFlag,
        Role:Role
      };
  
      return Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
        method: "POST",
        data: str_in,
      }).then((result) => {
        if(result.data?.StatusMessage === "SUCCESS"){
          setdata(result.data?.PageData);
          setRows(result.data?.PageData);
          SetTableRowLength(result.data?.PageData?.length)
          return result; // Return the result to be used in Promise.all
        }else{
          const errorMessage =
          result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
          toast.error(errorMessage);
        }
     
      }).catch((error) => {
        // Handle the error that occurred during the API request
  
        // Display error message using toast.error
        toast.error(`Error: ${error?.message}`);
  
        // You can also set an error state or show an error message to the user
        // For example: SetErrorState(true);
  
        throw error; // Rethrow the error to be caught by the caller
      });
    } catch (error) {
      toast.error(`Error: ${error?.message}`);
  
    }
 
  };
  const ContactListTotal = (CID, UID, Role) => {
    var str_in = {
      Page: page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      AccountType: AccountTypeForTab,
      IsApply: IsApply,
      FilterPropertiesArray: FilterParameters,
      Role: Role,
      IsTotalCount:!TotalCountFlag
    };

    return Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
      method: "POST",
      data: str_in,
    }).then((result) => {
      // setdata(result.data?.PageData);
      // setRows(result.data?.PageData);
      if(result.data?.StatusMessage === "SUCCESS"){
        setrlen(result.data?.TotalCount);
        setflen(result.data?.TotalCount);
        setCountPage(result.data?.PageCount);
        // SetTableRowLength(result.data?.PageData?.length)
        return result; // Return the result to be used in Promise.all
      }else{
        const errorMessage =
        result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
        toast.error(errorMessage);
      }
    }).catch((error) => {
      // Handle the error that occurred during the API request

      // Display error message using toast.error
      toast.error(`Error: ${error?.message}`);

      // You can also set an error state or show an error message to the user
      // For example: SetErrorState(true);

      throw error; // Rethrow the error to be caught by the caller
    });
  };

  // contact get after filter apply
    const ContactListGetFilter = (CID, UID, Role,filter,isApply) => {
      SetIsLoading(true)
      var str_in = {
        Page: page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: sortField,
        SortBy: sortedBy,
        Search: search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        AccountType: AccountTypeForTab,
        IsApply: isApply,
        FilterPropertiesArray: filter,
        Role: Role,
        IsTotalCount:TotalCountFlag
      };
  
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {
        setdata(result.data?.PageData);
        setRows(result.data?.PageData);
        SetTableRowLength(result.data?.PageData?.length)
        SetIsLoading(false)
        ContactListGet(CID, UID, Role,page);
        
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    };
  // contact btn
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          CampaignID:null
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Contact  deleted successfully.",
                "success"
              );
              ContactListGet(ClientID,UserID,Role,page);
              ContactListTotal(ClientID, UserID, Role);
              SetIsLoading(false)
            } else {
            }
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        }).finally(() => {
          // Always hide loader, regardless of success or failure
          SetIsLoading(false);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  const ViewBtn = (id, emailid) => {
    history.push({
      pathname: "/viewcontacts",
      state: { data: id, EmailData: emailid },
    });
  };

  const ContactAddTagBtn = () => {
    document.getElementById("Show_csv_details").style.display = "none";
    if (prospectAllSelectData.length == 0) {
      toast.error(
        <div className="toastsize">
          Please select atleast one contacts from list.
        </div>
      );
    } else {
      SetShowPopup(true);
    }
  }
  // import csv
  const ContactImportCsv = (e) => {
    document.getElementById("Show_csv_details").style.display = "none"
    e.preventDefault();
    document.getElementById("import_csv").style.display = "block";
    document.getElementById("delete_csv").style.display = "none"
  };


  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };
  // Import Csv reset
  const ImportResetCsv = () => {
    SetImportCsvReset(true);
  };
  // handle on drop
  const ImprotCsvHandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
   
    setfiles(fileInfo);
  
    var sep = filename.split(".");
    SetCsvFileName(sep[0])
    if (sep[1] != "csv") {
        toast.error( <div className="toastsize">
          Saleshive contacts  <br />
         Invalid file uploaded
        </div>)
      ImportResetCsv()
    } else {
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
      var csvfilenumber = new Date().getTime() + "." + sep[1];
      setfilename(csvfile);
      setfilenameonlynumber(csvfilenumber);
      
      // setcsvData(data);
      setcsvData2(data)
      SetDropboxData(data[0].data);
    }
  };
  const HandleOnDrop = (data, fileInfo) => {
    
    var filename = fileInfo.name;
  

    setfiles(fileInfo);
    var sep = filename.split(".");
    SetCsvFileName(sep[0])
    
    if (sep[1] != "csv") {
      toast.error( <div className="toastsize">
      Saleshive contacts  <br />
     Invalid file uploaded
    </div>)
SetResetBulkDelete(!ResetBulkDelete)

    } else {
      
      var csvfile = sep[0] + new Date().getTime() + "." + sep[1];

      var csvfilenumber = new Date().getTime() + "." + sep[1];
      setfilename(csvfile);
      setfilenameonlynumber(csvfilenumber);
      setcsvData(data);
      SetDropboxData(data[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  // map csv
  const ContactMapCsv = () => {
    if (csvData2.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      DropboxData.map((dddd, index) => {});
      SetImportCsvReset(!ImportCsvReset) 
  } else {
    SetImportCsvReset(!ImportCsvReset) 
  }
  hideCSVHeaderOptions(); 
  };

    // map csv
    const BulkDeleteContactBtn = () => {
      if (csvData.length !== 0) {
        SetResetBulkDelete(!ResetBulkDelete);
        document.getElementById("map_attribute_domain_blacklist").style.display = "block";
      } else {
        SetResetBulkDelete(!ResetBulkDelete);
      }
      hideCSVHeaderOptions();
    };
    
    

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "First Name") {
      SetFirstName(value);
    } else if (Name == "Last Name") {
      SetLastName(value);
    } else if (Name == "Email") {
      SetEmail(value);
    } else if (Name == "Title") {
      SetTitle(value);
    } else if (Name == "Company") {
      SetCompany(value);
    } else if (Name == "CompanyNameLong") {
      SetCompanyNameLong(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "DirectPhone") {
      SetDirectPhone(value);
    } else if (Name == "DirectPhoneExt") {
      SetDirectPhoneExt(value);
    } else if (Name == "LinkedInURL") {
      SetLinkedInURL(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "Address1") {
      SetAddress1(value);
    } else if (Name == "Address2") {
      SetAddress2(value);
    } else if (Name == "City") {
      SetCity(value);
    } else if (Name == "State") {
      SetState(value);
    } else if (Name == "Zip") {
      SetZip(value);
    } else if (Name == "Country") {
      SetCountry(value);
    } else if (Name == "ContactCategory") {
      SetContactCategory(value);
    } else if (Name == "AccountCategory") {
      SetAccountCategory(value);
    } else if (Name == "ValidationScore") {
      SetValidationScore(value);
    } else if (Name == "ListName") {
      SetListName(value);
    } else if (Name == "ContactSource") {
      SetContactSource(value);
    } else if (Name == "ContactOwner") {
      SetContactOwner(value);
    } else if (Name == "ClientPOC") {
      SetClientPOC(value);
    } else if (Name == "ReasonUnqualified") {
      SetReasonUnqualified(value);
    } else if (Name == "AccountType") {
      SetAccountType(value);
    } else if (Name == "CompanyRevenue") {
      SetCompanyRevenue(value);
    } else if (Name == "EmployeeCount") {
      setEmployeeCount(value);
    } else if (Name == "IsDoNotCallContact") {
      SetIsDoNotCallContact(value);
    } else if (Name == "SalesRep") {
      SetSalesRep(value);
    } else if (Name == "ColdCaller") {
      SetColdCaller(value);
    } else if (Name == "Tag") {
      SetTag(value);
    } else if (Name == "CallNotes") {
      SetCallNotes(value);
    } else if (Name == "OtherPhone1") {
      SetOtherPhone1(value);
    } else if (Name == "OtherPhone2") {
      SetOtherPhone2(value);
    } else if (Name == "ContactID") {
      SetContactID(value);
    }

    hideCSVHeaderOptions();
  });

  // cancel button import
  const CancleContactBtn = () => {
    SetImportCsvReset(!ImportCsvReset) 
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none"
  };

  // import for email 100+csv
  const ContactSaveCsvAddBulk = () => {
    setIsSaving(true)
    var OriginalFileName = files.name;
    var onlyname = OriginalFileName.substring(
      0,
      OriginalFileName.lastIndexOf(".csv")
    );

    var UserEmail = document.getElementById("UserEmail").value;
    var saveAllContacts = [];
    var FirstName = parseInt(document.getElementById("First Name").value);
    if (FirstName >= 0) {
      var ObjFirstName = {
        FieldAlias: "First Name",
        Fieldname: "FirstName",
        Customfield: null,
        index: FirstName,
      };
      saveAllContacts.push(ObjFirstName);
    }
    var LastName = parseInt(document.getElementById("Last Name").value);
    if (LastName >= 0) {
      var ObjLastName = {
        FieldAlias: "Last Name",
        Fieldname: "LastName",
        Customfield: null,
        index: LastName,
      };
      saveAllContacts.push(ObjLastName);
    }

    var Email = parseInt(document.getElementById("Email").value);
    if (Email >= 0) {
      var ObjEmail = {
        FieldAlias: "Email",
        Fieldname: "Email",
        Customfield: null,
        index: Email,
      };
      saveAllContacts.push(ObjEmail);
    }
    var Title = parseInt(document.getElementById("Title").value);
    if (Title >= 0) {
      var ObjTitle = {
        FieldAlias: "Title",
        Fieldname: "Title",
        Customfield: null,
        index: Title,
      };
      saveAllContacts.push(ObjTitle);
    }
    var Company = parseInt(document.getElementById("Company").value);
    if (Company >= 0) {
      var ObjCompany = {
        FieldAlias: "Company",
        Fieldname: "Company",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompany);
    }
    var CompanyNameLong = document.getElementById("CompanyNameLong").value;
    if (CompanyNameLong >= 0) {
      var ObjCompanyNameLong = {
        FieldAlias: "Company Name Long",
        Fieldname: "CompanyNameLong",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompanyNameLong);
    }
    var Website = parseInt(document.getElementById("Website").value);
    if (Website >= 0) {
      var ObjWebsite = {
        FieldAlias: "Website",
        Fieldname: "Website",
        Customfield: null,
        index: Website,
      };
      saveAllContacts.push(ObjWebsite);
    }
    var CompanyPhone = parseInt(document.getElementById("CompanyPhone").value);
    if (CompanyPhone >= 0) {
      var ObjCompanyPhone = {
        FieldAlias: "Company Phone",
        Fieldname: "CompanyPhone",
        Customfield: null,
        index: CompanyPhone,
      };
      saveAllContacts.push(ObjCompanyPhone);
    }
    var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
    if (DirectPhone >= 0) {
      var ObjDirectPhone = {
        FieldAlias: "Direct Phone",
        Fieldname: "DirectPhone",
        Customfield: null,
        index: DirectPhone,
      };
      saveAllContacts.push(ObjDirectPhone);
    }

    var DirectPhoneExt = parseInt(
      document.getElementById("DirectPhoneExt").value
    );
    if (DirectPhoneExt >= 0) {
      var ObjDirectPhoneExt = {
        FieldAlias: "Direct Phone Ext",
        Fieldname: "DirectPhoneExt",
        Customfield: null,
        index: DirectPhoneExt,
      };
      saveAllContacts.push(ObjDirectPhoneExt);
    }
    var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
    if (LinkedInURL >= 0) {
      var ObjLinkedInURL = {
        FieldAlias: "LinkedIn URL",
        Fieldname: "LinkedInURL",
        Customfield: null,
        index: LinkedInURL,
      };
      saveAllContacts.push(ObjLinkedInURL);
    }
    var Industry = parseInt(document.getElementById("Industry").value);
    if (Industry >= 0) {
      var ObjIndustry = {
        FieldAlias: "Industry",
        Fieldname: "Industry",
        Customfield: null,
        index: Industry,
      };
      saveAllContacts.push(ObjIndustry);
    }

    var Address1 = parseInt(document.getElementById("Address1").value);
    if (Address1 >= 0) {
      var ObjAddress1 = {
        FieldAlias: "Address 1",
        Fieldname: "Address1",
        Customfield: null,
        index: Address1,
      };
      saveAllContacts.push(ObjAddress1);
    }

    var Address2 = parseInt(document.getElementById("Address2").value);
    if (Address2 >= 0) {
      var ObjAddress2 = {
        FieldAlias: "Address 2",
        Fieldname: "Address2",
        Customfield: null,
        index: Address2,
      };
      saveAllContacts.push(ObjAddress2);
    }

    var City = parseInt(document.getElementById("City").value);
    if (City >= 0) {
      var ObjCity = {
        FieldAlias: "City",
        Fieldname: "City",
        Customfield: null,
        index: City,
      };

      saveAllContacts.push(ObjCity);
    }
    var State = parseInt(document.getElementById("State").value);
    if (State >= 0) {
      var ObjState = {
        FieldAlias: "State",
        Fieldname: "State",
        Customfield: null,
        index: State,
      };
      saveAllContacts.push(ObjState);
    }

    var Zip = parseInt(document.getElementById("Zip").value);
    if (Zip >= 0) {
      var ObjZip = {
        FieldAlias: "Zip",
        Fieldname: "Zip",
        Customfield: null,
        index: Zip,
      };
      saveAllContacts.push(ObjZip);
    }

    var Country = parseInt(document.getElementById("Country").value);
    if (Country >= 0) {
      var ObjCountry = {
        FieldAlias: "Country",
        Fieldname: "Country",
        Customfield: null,
        index: Country,
      };
      saveAllContacts.push(ObjCountry);
    }

    var ContactCategory = parseInt(
      document.getElementById("ContactCategory").value
    );
    if (ContactCategory >= 0) {
      var ObjContactCategory = {
        FieldAlias: "Contact Category",
        Fieldname: "ContactCategory",
        Customfield: null,
        index: ContactCategory,
      };
      saveAllContacts.push(ObjContactCategory);
    }
    var AccountCategory = parseInt(
      document.getElementById("AccountCategory").value
    );
    if (AccountCategory >= 0) {
      var ObjAccountCategory = {
        FieldAlias: "Account Category",
        Fieldname: "AccountCategory",
        Customfield: null,
        index: AccountCategory,
      };
      saveAllContacts.push(ObjAccountCategory);
    }

    var ValidationScore = parseInt(
      document.getElementById("ValidationScore").value
    );
    if (ValidationScore >= 0) {
      var ObjValidationScore = {
        FieldAlias: "Validation Score",
        Fieldname: "ValidationScore",
        Customfield: null,
        index: ValidationScore,
      };
      saveAllContacts.push(ObjValidationScore);
    }
    var ListName = document.getElementById("ListName").value;
    if (ListName != "") {
      var ObjListName = {
        FieldAlias: "List Name",
        Fieldname: "ListName",
        Customfield: null,
        index: ListName,
      };
      saveAllContacts.push(ObjListName);
    }

    var ContactSource = parseInt(
      document.getElementById("ContactSource").value
    );
    if (ContactSource >= 0) {
      var ObjContactSource = {
        FieldAlias: "Contact Source",
        Fieldname: "ContactSource",
        Customfield: null,
        index: ContactSource,
      };
      saveAllContacts.push(ObjContactSource);
    }
    var ContactOwner = parseInt(document.getElementById("ContactOwner").value);
    if (ContactOwner >= 0) {
      var ObjContactOwner = {
        FieldAlias: "Contact Owner",
        Fieldname: "ContactOwner",
        Customfield: null,
        index: ContactOwner,
      };
      saveAllContacts.push(ObjContactOwner);
    }
    var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
    if (ClientPOC >= 0) {
      var ObjClientPOC = {
        FieldAlias: "Client POC",
        Fieldname: "ClientPOC",
        Customfield: null,
        index: ClientPOC,
      };
      saveAllContacts.push(ObjClientPOC);
    }
    var ReasonUnqualified = parseInt(
      document.getElementById("ReasonUnqualified").value
    );
    if (ReasonUnqualified >= 0) {
      var ObjReasonUnqualified = {
        FieldAlias: "Reason Unqualified",
        Fieldname: "ReasonUnqualified",
        Customfield: null,
        index: ReasonUnqualified,
      };
      saveAllContacts.push(ObjReasonUnqualified);
    }
    var AccountType = parseInt(document.getElementById("AccountType").value);
    if (AccountType >= 0) {
      var ObjAccountType = {
        FieldAlias: "Account Type",
        Fieldname: "AccountType",
        Customfield: null,
        index: AccountType,
      };
      saveAllContacts.push(ObjAccountType);
    }
    var CompanyRevenue = parseInt(
      document.getElementById("CompanyRevenue").value
    );
    if (CompanyRevenue >= 0) {
      var ObjCompanyRevenue = {
        FieldAlias: "Company Revenue",
        Fieldname: "CompanyRevenue",
        Customfield: null,
        index: CompanyRevenue,
      };
      saveAllContacts.push(ObjCompanyRevenue);
    }
    var EmployeeCount = parseInt(
      document.getElementById("EmployeeCount").value
    );
    if (EmployeeCount >= 0) {
      var ObjEmployeeCount = {
        FieldAlias: "Employee Count",
        Fieldname: "EmployeeCount",
        Customfield: null,
        index: EmployeeCount,
      };
      saveAllContacts.push(ObjEmployeeCount);
    }

    var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
    if (MobilePhone >= 0) {
      var ObjMobilePhone = {
        FieldAlias: "Mobile Phone",
        Fieldname: "MobilePhone",
        Customfield: null,
        index: MobilePhone,
      };
      saveAllContacts.push(ObjMobilePhone);
    }

    var IsDoNotCallContact = parseInt(
      document.getElementById("IsDoNotCallContact").value
    );
    if (IsDoNotCallContact >= 0) {
      var ObjIsDoNotCallContact = {
        FieldAlias: "Do not call contact",
        Fieldname: "IsDoNotCallContact",
        Customfield: null,
        index: IsDoNotCallContact,
      };
      saveAllContacts.push(ObjIsDoNotCallContact);
    }

    var SalesRep = parseInt(document.getElementById("SalesRep").value);
    if (SalesRep >= 0) {
      var ObjSalesRep = {
        FieldAlias: "Sales Rep",
        Fieldname: "SalesRep",
        Customfield: null,
        index: SalesRep,
      };
      saveAllContacts.push(ObjSalesRep);
    }

    var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
    if (ColdCaller >= 0) {
      var ObjColdCaller = {
        FieldAlias: "Sales Development Reps",
        Fieldname: "ColdCaller",
        Customfield: null,
        index: ColdCaller,
      };
      saveAllContacts.push(ObjColdCaller);
    }

    var Tag = parseInt(document.getElementById("Tag").value);
    if (Tag >= 0) {
      var ObjTag = {
        FieldAlias: "Tag",
        Fieldname: "Tag",
        Customfield: null,
        index: Tag,
      };
      saveAllContacts.push(ObjTag);
    }

    var CallNotes = parseInt(document.getElementById("CallNotes").value);
    if (CallNotes >= 0) {
      var ObjCallNotes = {
        FieldAlias: "Call Notes",
        Fieldname: "CallNotes",
        Customfield: null,
        index: CallNotes,
      };
      saveAllContacts.push(ObjCallNotes);
    }

    var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
    if (OtherPhone1 >= 0) {
      var ObjOtherPhone1 = {
        FieldAlias: "Other Phone 1",
        Fieldname: "OtherPhone1",
        Customfield: null,
        index: OtherPhone1,
      };
      saveAllContacts.push(ObjOtherPhone1);
    }
    var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
    if (OtherPhone2 >= 0) {
      var ObjOtherPhone2 = {
        FieldAlias: "Other Phone 2",
        Fieldname: "OtherPhone2",
        Customfield: null,
        index: OtherPhone2,
      };
      saveAllContacts.push(ObjOtherPhone2);
    }

    var ContactID = parseInt(document.getElementById("ContactID").value);
    if (ContactID >= 0) {
      var ObjContactID = {
        FieldAlias: "ContactID",
        Fieldname: "ContactID",
        Customfield: null,
        index: ContactID,
      };
      saveAllContacts.push(ObjContactID);
    }

    var IsSkipUpdateExistContacts = document.getElementById(
      "IsUpdateExistContacts"
    ).checked;
    // if (IsSkipUpdateExistContacts == false) {
    //   IsSkipUpdateExistContacts = true;
    // } else {
    //   IsSkipUpdateExistContacts = false;
    // }
    var IsUpdateExistContacts = document.getElementById(
      "IsUpdateExistContacts"
    ).checked;
    if (IsUpdateExistContacts == false) {
      IsUpdateExistContacts = true;
    } else {
      IsUpdateExistContacts = false;
    }
    var IsInsertNullValues =
      document.getElementById("IsInsertNullValues").checked;
    var IsDoNotupdatecompanyname = document.getElementById(
      "IsDoNotupdatecompanyname"
    ).checked;

    var csvdata = csvData2;
    var csvdata2 = csvData.length;

    var csvdata = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];
      var ObjData = {
        Fields: "Coverimg",
        Filepath: "./Content/UploadedFiles/SalesReplyContact",
        File: _base64,
        ImageName: filenameonlynumber,
        FileData: csvdata,
        MappingFiled: JSON.stringify(saveAllContacts),
        Email: UserEmail,
        ClientID: ClientID,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        IsSkipUpdateExistContacts: IsSkipUpdateExistContacts,
        IsInsertNullValues: IsInsertNullValues,
        IsUpdateExistContacts: IsUpdateExistContacts,
        IsDoNotupdatecompanyname: IsDoNotupdatecompanyname,
        OriginalFileName: OriginalFileName,
        ListName: onlyname,
        CampaignID: null,

      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactBulkUploadImport",
        method: "POST",
        data: ObjData,
      }).then((res) => {
        document.getElementById("IsInsertNullValues").checked = false;
        document.getElementById("IsUpdateExistContacts").checked=false;
        document.getElementById("IsDoNotupdatecompanyname").checked=false;

        if (res.data.StatusMessage == "SUCCESS") {
          SetShowPopupImport(false);
          toast.success("Contact You will get file soon on Submitted Email.");
        
    document.getElementById("import_csv").style.display = "none"
    setIsSaving(false)
          document.getElementById("map_attribute").style.display = "none";
        } else {
          toast.error(res.data.Message);
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    });
  };

  // import black list csv
  const ContactSaveCsv = () => {
    if (csvData.length >= 100 || csvData2.length>=100) {
      SetShowPopupImport(true);
      toast.success( <div className="toastsize">
     SalesHive Contacts
      <br />
      imported file has more than 100 contacts please enter email for completion notification.
    </div>);
      SetIsLoading(false)
    } else {
      SetIsLoading(true)
      var saveAllContacts = [];
      var FirstName = parseInt(document.getElementById("First Name").value);
      if (FirstName >= 0) {
        var ObjFirstName = {
          FieldAlias: "First Name",
          Fieldname: "FirstName",
          Customfield: null,
          index: FirstName,
        };
        saveAllContacts.push(ObjFirstName);
      }
      var LastName = parseInt(document.getElementById("Last Name").value);
      if (LastName >= 0) {
        var ObjLastName = {
          FieldAlias: "Last Name",
          Fieldname: "LastName",
          Customfield: null,
          index: LastName,
        };
        saveAllContacts.push(ObjLastName);
      }
      var Email = parseInt(document.getElementById("Email").value);
      if (Email >= 0) {
        var ObjEmail = {
          FieldAlias: "Email",
          Fieldname: "Email",
          Customfield: null,
          index: Email,
        };
        saveAllContacts.push(ObjEmail);
      }
      var Title = parseInt(document.getElementById("Title").value);
      if (Title >= 0) {
        var ObjTitle = {
          FieldAlias: "Title",
          Fieldname: "Title",
          Customfield: null,
          index: Title,
        };
        saveAllContacts.push(ObjTitle);
      }
      var Company = parseInt(document.getElementById("Company").value);
      if (Company >= 0) {
        var ObjCompany = {
          FieldAlias: "Company",
          Fieldname: "Company",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompany);
      }
      var CompanyNameLong =document.getElementById("CompanyNameLong").value;
      if (CompanyNameLong >= 0) {
        var ObjCompanyNameLong = {
          FieldAlias: "Company Name Long",
          Fieldname: "CompanyNameLong",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompanyNameLong);
      }
      var Website = parseInt(document.getElementById("Website").value);
      if (Website >= 0) {
        var ObjWebsite = {
          FieldAlias: "Website",
          Fieldname: "Website",
          Customfield: null,
          index: Website,
        };
        saveAllContacts.push(ObjWebsite);
      }
      var CompanyPhone = parseInt(
        document.getElementById("CompanyPhone").value
      );
      if (CompanyPhone >= 0) {
        var ObjCompanyPhone = {
          FieldAlias: "Company Phone",
          Fieldname: "CompanyPhone",
          Customfield: null,
          index: CompanyPhone,
        };
        saveAllContacts.push(ObjCompanyPhone);
      }
      var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
      if (DirectPhone >= 0) {
        var ObjDirectPhone = {
          FieldAlias: "Direct Phone",
          Fieldname: "DirectPhone",
          Customfield: null,
          index: DirectPhone,
        };
        saveAllContacts.push(ObjDirectPhone);
      }

      var DirectPhoneExt = parseInt(
        document.getElementById("DirectPhoneExt").value
      );
      if (DirectPhoneExt >= 0) {
        var ObjDirectPhoneExt = {
          FieldAlias: "Direct Phone Ext",
          Fieldname: "DirectPhoneExt",
          Customfield: null,
          index: DirectPhoneExt,
        };
        saveAllContacts.push(ObjDirectPhoneExt);
      }
      var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
      if (LinkedInURL >= 0) {
        var ObjLinkedInURL = {
          FieldAlias: "LinkedIn URL",
          Fieldname: "LinkedInURL",
          Customfield: null,
          index: LinkedInURL,
        };
        saveAllContacts.push(ObjLinkedInURL);
      }
      var Industry = parseInt(document.getElementById("Industry").value);
      if (Industry >= 0) {
        var ObjIndustry = {
          FieldAlias: "Industry",
          Fieldname: "Industry",
          Customfield: null,
          index: Industry,
        };
        saveAllContacts.push(ObjIndustry);
      }

      var Address1 = parseInt(document.getElementById("Address1").value);
      if (Address1 >= 0) {
        var ObjAddress1 = {
          FieldAlias: "Address 1",
          Fieldname: "Address1",
          Customfield: null,
          index: Address1,
        };
        saveAllContacts.push(ObjAddress1);
      }

      var Address2 = parseInt(document.getElementById("Address2").value);
      if (Address2 >= 0) {
        var ObjAddress2 = {
          FieldAlias: "Address 2",
          Fieldname: "Address2",
          Customfield: null,
          index: Address2,
        };
        saveAllContacts.push(ObjAddress2);
      }

      var City = parseInt(document.getElementById("City").value);
      if (City >= 0) {
        var ObjCity = {
          FieldAlias: "City",
          Fieldname: "City",
          Customfield: null,
          index: City,
        };

        saveAllContacts.push(ObjCity);
      }
      var State = parseInt(document.getElementById("State").value);
      if (State >= 0) {
        var ObjState = {
          FieldAlias: "State",
          Fieldname: "State",
          Customfield: null,
          index: State,
        };
        saveAllContacts.push(ObjState);
      }

      var Zip = parseInt(document.getElementById("Zip").value);
      if (Zip >= 0) {
        var ObjZip = {
          FieldAlias: "Zip",
          Fieldname: "Zip",
          Customfield: null,
          index: Zip,
        };
        saveAllContacts.push(ObjZip);
      }

      var Country = parseInt(document.getElementById("Country").value);
      if (Country >= 0) {
        var ObjCountry = {
          FieldAlias: "Country",
          Fieldname: "Country",
          Customfield: null,
          index: Country,
        };
        saveAllContacts.push(ObjCountry);
      }

      var ContactCategory = parseInt(
        document.getElementById("ContactCategory").value
      );
      if (ContactCategory >= 0) {
        var ObjContactCategory = {
          FieldAlias: "Contact Category",
          Fieldname: "ContactCategory",
          Customfield: null,
          index: ContactCategory,
        };
        saveAllContacts.push(ObjContactCategory);
      }
      var AccountCategory = parseInt(
        document.getElementById("AccountCategory").value
      );
      if (AccountCategory >= 0) {
        var ObjAccountCategory = {
          FieldAlias: "Account Category",
          Fieldname: "AccountCategory",
          Customfield: null,
          index: AccountCategory,
        };
        saveAllContacts.push(ObjAccountCategory);
      }

      var ValidationScore = parseInt(
        document.getElementById("ValidationScore").value
      );
      if (ValidationScore >= 0) {
        var ObjValidationScore = {
          FieldAlias: "Validation Score",
          Fieldname: "ValidationScore",
          Customfield: null,
          index: ValidationScore,
        };
        saveAllContacts.push(ObjValidationScore);
      }
      var ListName = document.getElementById("ListName").value;
      if (ListName != "") {
        var ObjListName = {
          FieldAlias: "List Name",
          Fieldname: "ListName",
          Customfield: null,
          index: ListName,
        };
        saveAllContacts.push(ObjListName);
      }

      var ContactSource = parseInt(
        document.getElementById("ContactSource").value
      );
      if (ContactSource >= 0) {
        var ObjContactSource = {
          FieldAlias: "Contact Source",
          Fieldname: "ContactSource",
          Customfield: null,
          index: ContactSource,
        };
        saveAllContacts.push(ObjContactSource);
      }
      var ContactOwner = parseInt(
        document.getElementById("ContactOwner").value
      );
      if (ContactOwner >= 0) {
        var ObjContactOwner = {
          FieldAlias: "Contact Owner",
          Fieldname: "ContactOwner",
          Customfield: null,
          index: ContactOwner,
        };
        saveAllContacts.push(ObjContactOwner);
      }
      var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
      if (ClientPOC >= 0) {
        var ObjClientPOC = {
          FieldAlias: "Client POC",
          Fieldname: "ClientPOC",
          Customfield: null,
          index: ClientPOC,
        };
        saveAllContacts.push(ObjClientPOC);
      }
      var ReasonUnqualified = parseInt(
        document.getElementById("ReasonUnqualified").value
      );
      if (ReasonUnqualified >= 0) {
        var ObjReasonUnqualified = {
          FieldAlias: "Reason Unqualified",
          Fieldname: "ReasonUnqualified",
          Customfield: null,
          index: ReasonUnqualified,
        };
        saveAllContacts.push(ObjReasonUnqualified);
      }
      var AccountType = parseInt(document.getElementById("AccountType").value);
      if (AccountType >= 0) {
        var ObjAccountType = {
          FieldAlias: "Account Type",
          Fieldname: "AccountType",
          Customfield: null,
          index: AccountType,
        };
        saveAllContacts.push(ObjAccountType);
      }
      var CompanyRevenue = parseInt(
        document.getElementById("CompanyRevenue").value
      );
      if (CompanyRevenue >= 0) {
        var ObjCompanyRevenue = {
          FieldAlias: "Company Revenue",
          Fieldname: "CompanyRevenue",
          Customfield: null,
          index: CompanyRevenue,
        };
        saveAllContacts.push(ObjCompanyRevenue);
      }
      var EmployeeCount = parseInt(
        document.getElementById("EmployeeCount").value
      );
      if (EmployeeCount >= 0) {
        var ObjEmployeeCount = {
          FieldAlias: "Employee Count",
          Fieldname: "EmployeeCount",
          Customfield: null,
          index: EmployeeCount,
        };
        saveAllContacts.push(ObjEmployeeCount);
      }

      var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
      if (MobilePhone >= 0) {
        var ObjMobilePhone = {
          FieldAlias: "Mobile Phone",
          Fieldname: "MobilePhone",
          Customfield: null,
          index: MobilePhone,
        };
        saveAllContacts.push(ObjMobilePhone);
      }

      var IsDoNotCallContact = parseInt(
        document.getElementById("IsDoNotCallContact").value
      );
      if (IsDoNotCallContact >= 0) {
        var ObjIsDoNotCallContact = {
          FieldAlias: "Do not call contact",
          Fieldname: "IsDoNotCallContact",
          Customfield: null,
          index: IsDoNotCallContact,
        };
        saveAllContacts.push(ObjIsDoNotCallContact);
      }

      var SalesRep = parseInt(document.getElementById("SalesRep").value);
      if (SalesRep >= 0) {
        var ObjSalesRep = {
          FieldAlias: "Sales Rep",
          Fieldname: "SalesRep",
          Customfield: null,
          index: SalesRep,
        };
        saveAllContacts.push(ObjSalesRep);
      }

      var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
      if (ColdCaller >= 0) {
        var ObjColdCaller = {
          FieldAlias: "Sales Development Reps",
          Fieldname: "ColdCaller",
          Customfield: null,
          index: ColdCaller,
        };
        saveAllContacts.push(ObjColdCaller);
      }

      var Tag = parseInt(document.getElementById("Tag").value);
      if (Tag >= 0) {
        var ObjTag = {
          FieldAlias: "Tag",
          Fieldname: "Tag",
          Customfield: null,
          index: Tag,
        };
        saveAllContacts.push(ObjTag);
      }

      var CallNotes = parseInt(document.getElementById("CallNotes").value);
      if (CallNotes >= 0) {
        var ObjCallNotes = {
          FieldAlias: "Call Notes",
          Fieldname: "CallNotes",
          Customfield: null,
          index: CallNotes,
        };
        saveAllContacts.push(ObjCallNotes);
      }

      var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
      if (OtherPhone1 >= 0) {
        var ObjOtherPhone1 = {
          FieldAlias: "Other Phone 1",
          Fieldname: "OtherPhone1",
          Customfield: null,
          index: OtherPhone1,
        };
        saveAllContacts.push(ObjOtherPhone1);
      }
      var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
      if (OtherPhone2 >= 0) {
        var ObjOtherPhone2 = {
          FieldAlias: "Other Phone 2",
          Fieldname: "OtherPhone2",
          Customfield: null,
          index: OtherPhone2,
        };
        saveAllContacts.push(ObjOtherPhone2);
      }

      var ContactID = parseInt(document.getElementById("ContactID").value);
      if (ContactID >= 0) {
        var ObjContactID = {
          FieldAlias: "ContactID",
          Fieldname: "ContactID",
          Customfield: null,
          index: ContactID,
        };
        saveAllContacts.push(ObjContactID);
      }

      var IsUpdateExistContacts = document.getElementById(
        "IsUpdateExistContacts"
      ).checked;
      if (IsUpdateExistContacts == false) {
        IsUpdateExistContacts = true;
      } else {
        IsUpdateExistContacts = false;
      }
      var IsInsertNullValues =
        document.getElementById("IsInsertNullValues").checked;
      var IsDoNotupdatecompanyname = document.getElementById(
        "IsDoNotupdatecompanyname"
      ).checked;

      var csvdata = csvData2;
      var csvdata2 = csvData.length;

      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ObjData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/SalesReplyContact",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          saveAllContacts: saveAllContacts,
          ClientID: ClientID,
          UserID: UserID,
          CreatedBy: CUserID,
          CreatedDate: new Date(),
          IsUpdateExistContacts: IsUpdateExistContacts,
          IsInsertNullValues: IsInsertNullValues,
          IsDoNotupdatecompanyname: IsDoNotupdatecompanyname,
          IsCsvFileName:CsvFileName
        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactImport",
          method: "POST",
          data: ObjData,
        }).then((res) => {
          document.getElementById("IsInsertNullValues").checked = false;
          document.getElementById("IsUpdateExistContacts").checked=false;
          document.getElementById("IsDoNotupdatecompanyname").checked=false;
  
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success("Contacts Save Imports");
            SetImportCsvReset(!ImportCsvReset) 
            SetTotal(res.data.TotalContacts);
            SetTotalSucess(res.data.TotalSuccess);
            SetTotalError(res.data.TotalError);
            SetTotalDuplicate(res.data.TotalDuplicate);
            SetTotalUpdated(res.data.TotalUpdated);
            document.getElementById("Show_csv_details").style.display = "block";
            document.getElementById("map_attribute").style.display = "none";
            ContactListGet(ClientID,UserID,Role,page)
            ContactListTotal(ClientID, UserID, Role);
            document.getElementById("import_csv").style.display = "none"
            setcsvData2([])
            // document.getElementById("hideloding").style.display = "none";
            SetIsLoading(false)
          }else{
            SetImportCsvReset(!ImportCsvReset) 
            toast.error("Error")
            // document.getElementById("hideloding").style.display = "none";
            SetIsLoading(false)
          }
        }).catch((error) => {
          SetImportCsvReset(!ImportCsvReset) 
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      });
      SetIsLoading(true)
    }
  };
  // Bulk delete contact csv
  const DeleteBulkContactCsv = () => {
    SetIsLoading(true)
    var Email = document.getElementById("Email").value;


    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var BulkDeleteContactData = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserContacts",
        File: _base64,
        ImageName: filename,
        FileData: csvData,
        Email: Email,
      };
   
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactBulkDelete",
        method: "POST",
        data: BulkDeleteContactData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Contact deleted successfully.");
          SetResetBulkDelete(!ResetBulkDelete) 
          setcsvData([])
          document.getElementById("delete_csv").style.display = "none"
          document.getElementById(
            "map_attribute_domain_blacklist"
          ).style.display = "none";
          ContactListGet(ClientID,UserID,Role,page)
          ContactListTotal(ClientID, UserID, Role);
          SetIsLoading(false)
        }else {
          SetResetBulkDelete(!ResetBulkDelete) 
          setcsvData([])
          toast.error(res.data.Message);
          SetIsLoading(false)
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetResetBulkDelete(!ResetBulkDelete) 
        SetIsLoading(false);
      });
    });
  };


  const CancelBulkContactBtn = () => {
    SetResetBulkDelete(!ResetBulkDelete) 
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("map_attribute_domain_blacklist").style.display = "none";
    document.getElementById("delete_csv").style.display = "none"
    document.getElementById("import_csv").style.display = "none"

  };
  const ContactBulkDeleteCsv = (e) => {
  document.getElementById("Show_csv_details").style.display = "none"

    e.preventDefault()
    document.getElementById("delete_csv").style.display = "block"
    document.getElementById("import_csv").style.display = "none"

  };

  const addcontactpage = () => {
    document.getElementById("Show_csv_details").style.display = "none"
    history.push({
      pathname: "/addcontacts",
      state: { data: AccountTypeForTab, Pagename: "" },
    });
  };

  // When Empty array add display toatst function
  const ContactDeleteTagBtn = () => {
    document.getElementById("Show_csv_details").style.display = "none"
    SetDeleteTagModel(true);
  };

  // When Empty array add display toatst function
  const ContactReassignDevBtn = () => {
    document.getElementById("Show_csv_details").style.display = "none"
    SetShowPopupReassignDev(true);
    SetReassignFromSelectedValue([]);
  };
  const ContactReassignDevPopupHideBtn = () => {
    SetShowPopupReassignDev(false);
  };
  const ContacAddHidePopup = () => {
    SetShowPopup(false);
  };

  // Add contact tag
  const ContactTagAdd = () => {
    // SetAddTagBtnDisabled(true);
    if (prospectAllSelectData.length == 0) {
      toast.error(
        <div className="toastsize">
          Please select atleast one contacts from list.
        </div>
      );
      SetAddTagBtnDisabled(false);
    } else {
      SetShowPopup(true);
      var Tag = document.getElementById("Tag").value;
      if (Tag == "") {
        document.getElementById("tag_err").style.display = "block";
        SetAddTagBtnDisabled(false);
      } else {
        
        SetIsLoading(true)
        var ContactTagData = {
          ClientID: ClientID,
          UserID: UserID,
          Tag: Tag,
          ContactTagAArray: prospectAllSelectData,
        };
  
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactTagAddToProspect",
          method: "POST",
          data: ContactTagData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetShowPopup(false);
            UnSelectCheckHandler();
            toast.success(
              <div>
                Contact<br /> Tag added successfully.
              </div>
            );
            ContactListGet(ClientID,UserID,Role,page)
            ContactListTotal(ClientID, UserID, Role);
            ContactInfoGetData(ClientID,UserID,Role)
            setProspectAllSelectData([])
            document.getElementById("main-check").checked = false
            SetIsLoading(false)
          } else {
            toast.error(res.data.Message);
            SetShowPopup(false);
            SetAddTagBtnDisabled(false);
            // document.getElementById("hideloding").style.display = "none";
            SetIsLoading(false)
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      }
    }
  };

   
    const ProspectCheckHandler = (e, row) => { 
      const trElement = e.target.closest('tr');
      if (e.target.checked) {  
        trElement.classList.add("highlight");
        setProspectAllSelectData(prevData => {
          if (!prevData.find(item => item._id === row._id)) {
            const updatedData = [...prevData, row];
            updateLengthAndDisplay(updatedData);
            return updatedData;
          }
          return prevData;
        });
      } else {
        setProspectAllSelectData(prevData => {
          trElement.classList.remove("highlight"); 
          const updatedData = prevData.filter(item => item._id !== row._id);
          updateLengthAndDisplay(updatedData);
          return updatedData;
        });
      }
    };

    const updateLengthAndDisplay = (updatedData) => {
      const updatedLength = updatedData?.length;
      var selectcount = data?.length;
      
      const PerPageCheckCountS = data?.filter((item) =>
      updatedData.some((paitem) => item._id === paitem._id)
  );

      // Update the display of "deleteall-btn" button based on the length of prospectAllSelectData
      if (updatedLength > 0) {
        document.getElementById("deleteall-btn").style.display = "block";
        // document.getElementById("main-check").checked = true;
      } else {
        document.getElementById("deleteall-btn").style.display = "none";
        // document.getElementById("main-check").checked = false;
      }
      const SPagecheckcount = PerPageCheckCountS.length
      if ( SPagecheckcount === selectcount) {
        document.getElementById("main-check").checked = true;
        document.getElementById("Select-All").style.display = "none";
        document.getElementById("UnSelect").style.display = "block";
      } else {
        
        document.getElementById("main-check").checked = false;
        document.getElementById("Select-All").style.display = "block";
        document.getElementById("UnSelect").style.display = "none";
      }
    };
    

  // all check handler
  const CheckHandler = (e) => { 
    rows?.map((val) => {
      if (e.target.checked) {
        document.getElementById(val?._id).checked = true;  
        const trElement = document.getElementById(val?._id).closest('tr');
        if(trElement){ 
          trElement.classList.add("highlight");
        }  
      } else if (!e.target.checked) {
        document.getElementById(val?._id).checked = false;
        const trElement = document.getElementById(val?._id).closest('tr');
        if(trElement){ 
          trElement.classList.remove("highlight");
        }  
      }
    });

    var countss
    if (e.target.checked) {
      // Select all rows
      const selectedRows = rows.filter((val) => !prospectAllSelectData.some((item) => item._id === val._id));
      const updatedArray = [...prospectAllSelectData, ...selectedRows];
      countss == selectedRows
      SetPageCountForCheck(updatedArray)
      setProspectAllSelectData(updatedArray);
      document.getElementById("deleteall-btn").style.display = "block";
      document.getElementById("Select-All").style.display = "none";
      document.getElementById("UnSelect").style.display = "block";
    } else {
      // Select all rows
      const selectedRows = prospectAllSelectData.filter((val) => !rows.some((item) => item._id === val._id));
      setProspectAllSelectData(selectedRows);
      document.getElementById("deleteall-btn").style.display = "none";
      document.getElementById("Select-All").style.display = "block";
      document.getElementById("UnSelect").style.display = "none";
    }
  };

  
  const SelectCheckHandler = async () => {
    try {
      SetIsLoading(true); // Show the loader
      
      // Your other logic here...
      document.getElementById("main-check").checked = true
      const Data = {
        ClientID: ClientID,
        UserID: UserID,
        AccountType: AccountTypeForTab,
      };
  
      const result = await Axios.post(
        CommonConstants.MOL_APIURL + "/contacts/ContactProspectIDGet",
        Data
      );
  
      setProspectAllSelectData(result.data?.Data);
      // SetContactDataAll(result.data?.Data);
  
      // Update UI elements
      document.getElementById("deleteall-btn").style.display = "block";
      document.getElementById("UnSelect").style.display = "block";
      document.getElementById("Select-All").style.display = "none";
        
      SetIsLoading(false); // Hide the loader
    } catch (error) {
      var Method =
        "Getting Error contact delete delete User log(Repository(ContactDeleteSelect))";
      LogData.LogFileData(error, Method);
      SetIsLoading(false); // Hide the loader in case of an error
    }
  };
  
  // all check handler
  const UnSelectCheckHandler = () => {
    document.getElementById("deleteall-btn").style.display = "none";
    document.getElementById("Select-All").style.display = "block";
    document.getElementById("UnSelect").style.display = "none";
    document.getElementById("main-check").checked = false;
    setProspectAllSelectData([])
  };

  //get sort field data
  const SortData = async (Field) => {
    SetIsLoading(true);
    var searchedVal = document.getElementById("search").value;
    var SerchBox;
  
    try {
      if (searchedVal === "") {
        SerchBox = false;
        setsflag(false);
        var SortField = Field;
        var SortBy;
        if (sortedBy === 1) {
          SortBy = -1;
          setsortedBy(-1);
        } else {
          SortBy = 1;
          setsortedBy(1);
        }
        setsortField(Field);
  
        var InputParameter = {
          Page: page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          Search: search,
          UserID: UserID,
          Role: Role,
          AccountType: AccountTypeForTab,
          IsApply: IsApply,
          FilterPropertiesArray: FilterParameters,
          IsTotalCount: TotalCountFlag,
        };
  
        const ContactGetList = await Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
          method: "POST",
          data: InputParameter,
        });
  
        setRows(ContactGetList.data.PageData);
        // setrlen(ContactGetList.data.TotalCount);
        // setflen(ContactGetList.data.TotalCount);
        // setCountPage(ContactGetList.data.PageCount);
      } else {
        SerchBox = true;
        setsflag(true);
        var SortField = Field;
        var SortBy;
        if (sortedBy === 1) {
          SortBy = -1;
          setsortedBy(-1);
        } else {
          SortBy = 1;
          setsortedBy(1);
        }
        setsortField(Field);
  
        var InputParameter = {
          Page: page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          Search: search,
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          AccountType: AccountTypeForTab,
          IsApply: IsApply,
          FilterPropertiesArray: FilterParameters,
          IsTotalCount: TotalCountFlag,
        };
  
        const ContactGetList = await Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
          method: "POST",
          data: InputParameter,
        });
  
        setRows(ContactGetList.data.PageData);
        // setrlen(ContactGetList.data.TotalCount);
        // setflen(ContactGetList.data.TotalCount);
        // setCountPage(ContactGetList.data.PageCount);
  
        // document.getElementById("hideloding").style.display = "none";
      }
  
      SetIsLoading(false);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
     
    }
  };
  

  let contactoptions = ContactTag?.map((val) => {
    return { value: val?.Tag, label: val?.Tag, cid: val?._id };
  });
  // contact tags method 2
  let ContactTagsArr = ContactTags?.map((val) => {
    return { value: val?.ContactTagID, label: val?.Tag, cid: val?.ContactTagID };
  });



  //Get Custom fields
  const GetCustomFiledsData = (CID, UID, Role) => {
    const InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/GetCustomFields",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      let Arr = [
        {
          FieldValue: "AccountName",
          ColumnName: "Account Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountCategory",
          ColumnName: "Account Category",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountType",
          ColumnName: "AccountType",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Address1",
          ColumnName: "Address1",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Address2",
          ColumnName: "Address2",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "CallCount",
          ColumnName: "Call Count",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "City",
          ColumnName: "City",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ClientPOC",
          ColumnName: "Client POC",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Company",
          ColumnName: "Company",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "CompanyPhone",
          ColumnName: "Company Phone",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactCategory",
          ColumnName: "Contact Category",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactOwner",
          ColumnName: "Contact Owner",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactSource",
          ColumnName: "Contact Source",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactStatus",
          ColumnName: "Contact Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Country",
          ColumnName: "Country",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalSent",
          ColumnName: "Deliveries",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Email",
          ColumnName: "Email",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "FirstName",
          ColumnName: "First Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalHardBounce",
          ColumnName: "Hard Bounce",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Industry",
          ColumnName: "Industry",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "IsBlacklisted",
          ColumnName: "Is Blacklisted",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LastName",
          ColumnName: "Last Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LastSendDate",
          ColumnName: "Last Send Date",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LastValidationDate",
          ColumnName: "Last Validation Date",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LinkedInURL",
          ColumnName: "LinkedIn URL",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ListName",
          ColumnName: "List Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "MobilePhone",
          ColumnName: "Mobile Phone",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalOpen",
          ColumnName: "Open",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ReasonUnqualified",
          ColumnName: "Reason Unqualified",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalReply",
          ColumnName: "Replies",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ColdCallersName",
          ColumnName: "Sales Development Reps",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "SalesRep",
          ColumnName: "Sales Rep",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalSoftBounce",
          ColumnName: "Soft Bounce",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "State",
          ColumnName: "State",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactTag",
          ColumnName: "Tag",
          Expression: "EqualTo",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Title",
          ColumnName: "Title",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ValidationScore",
          ColumnName: "Validation Score",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Website",
          ColumnName: "Website",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Zip",
          ColumnName: "Zip",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
      ];
      res.data.Data.forEach((el) => {
        Arr.push({
          FieldValue: el.FieldName,
          ColumnName: el.FieldName,
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
          FieldFor: "Text",
          ContactTagIds: "",
          IsCustom: true,
          IsSelect: false,
          IsBool: false,
        });
      });
      let ChangedArr = [];
      Arr.forEach((el, i) => {
        if (
          el.FieldValue == "LastSendDate" ||
          el.FieldValue == "LastValidationDate"
        ) {
          ChangedArr.push({
            id: i,
            FieldValue: el.FieldValue,
            ColumnName: el.ColumnName,
            Expression: "EqualTo",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            FieldFor: "",
            ContactTagIds: "",
            IsCustom: false,
            IsDate: true,
            NullCondition: false,
            IsNum: false,
            IsSelect: false,
            IsBool: false,
          });
        } else {
          if (el.IsCustom == true) {
            ChangedArr.push({
              id: i,
              FieldValue: el.FieldValue,
              ColumnName: el.ColumnName,
              Expression: "Contains",
              FilterPropertyValue: "",
              FilterPropertyValue1: "",
              FieldFor: "Text",
              ContactTagIds: "",
              IsCustom: true,
              IsDate: false,
              NullCondition: false,
              IsNum: false,
              IsSelect: false,
              IsBool: false,
            });
          } else {
            if (el.ColumnName == "Tag") {
              ChangedArr.push({
                id: i,
                FieldValue: el.FieldValue,
                ColumnName: el.ColumnName,
                Expression: "EqualTo",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                FieldFor: "",
                ContactTagIds: "",
                IsCustom: false,
                IsDate: false,
                NullCondition: false,
                IsNum: false,
                IsSelect: false,
                IsBool: false,
              });
            } else {
              ChangedArr.push({
                id: i,
                FieldValue: el.FieldValue,
                ColumnName: el.ColumnName,
                Expression: "Contains",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                FieldFor: "",
                ContactTagIds: "",
                IsCustom: false,
                IsDate: false,
                NullCondition: false,
                IsNum: false,
                IsSelect: false,
                IsBool: false,
              });
            }
          }
        }
      });
      
      let SortedArr = ChangedArr.sort(function (a, b) {
        return a.ColumnName.toLowerCase().localeCompare(
          b.ColumnName.toLowerCase()
        );
      });

      SetCustomColumnData(SortedArr);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  //Column handled Dropdown Change
  const ColumnhandledDropdownChange = (id, Index) => {
    let ColumnName = document.getElementById("ColumnName" + id).value;

    let FieldAttributesDatas = [];
    FieldAttributes.forEach((el) => {
      FieldAttributesDatas.push(el);
    });
    let indexed = CustomColumnData.findIndex(
      (v) => v.FieldValue === ColumnName
    );
    FieldAttributesDatas[Index].FieldValue = ColumnName;
    FieldAttributesDatas[Index].IsCustom = CustomColumnData[indexed].IsCustom;
    FieldAttributesDatas[Index].IsDate = CustomColumnData[indexed].IsDate;
    if (ColumnName == "IsBlacklisted") {
      FieldAttributesDatas[Index].IsBool = true;
    } else {
      FieldAttributesDatas[Index].IsBool = false;
    }
    if (
      ColumnName == "CallCount" ||
      ColumnName == "TotalSent" ||
      ColumnName == "TotalHardBounce" ||
      ColumnName == "TotalOpen" ||
      ColumnName == "TotalReply" ||
      ColumnName == "TotalSoftBounce"
    ) {
      FieldAttributesDatas[Index].IsNum = true;
    } else {
      FieldAttributesDatas[Index].IsNum = false;
    }
    if (ColumnName == "ContactTag") {
      FieldAttributesDatas[Index].IsSelect = true;
      FieldAttributesDatas[Index].Expression = "EqualTo";
    } else {
      FieldAttributesDatas[Index].IsSelect = false;
    }
    if (CustomColumnData[indexed].IsCustom == true) {
      FieldAttributesDatas[Index].FieldFor = "Text";
    } else {
      FieldAttributesDatas[Index].FieldFor = "";
    }

    SetFieldAttributes(FieldAttributesDatas);
  };

  // Expression Handler drop down change
  const ExpressionHandler = (id) => {
    let StateArr = [];
    FieldAttributes.forEach((el) => {
      StateArr.push(el);
    });
    let ColumnName = document.getElementById("ColumnName" + id).value;
    let ExpressionName = document.getElementById("expression" + id).value;
    let indexed = StateArr.findIndex((v) => v.id === id);
    if (indexed == -1) {
      StateArr.push({
        id: id,
        ColumnName: ColumnName,
        Expression: ExpressionName,
      });
    } else {
      StateArr[indexed].ColumnName = ColumnName;
      StateArr[indexed].Expression = ExpressionName;
    }
    if (ExpressionName == "IsNotBlank" || ExpressionName == "IsBlank") {
      StateArr[indexed].NullCondition = true;
    } else {
      StateArr[indexed].NullCondition = false;
    }

    SetFieldAttributes(StateArr);
  };

  //Select box handler
  const FilterhandleChangeContactOption = (val, id) => {
    let Arr = [];
    FieldAttributes.forEach((el) => {
      Arr.push(el);
    });
    let CidArr = [];
    val.forEach((elem) => {
      CidArr.push(elem.cid);
    });

    let indexed = Arr.findIndex((v) => v.id === id);
    Arr[indexed].ContactTagIds = CidArr.toString();

    document.getElementById("searchvalue" + id).value = CidArr.toString();
    SetFieldAttributes(Arr);
  };

  //Add filter property
  const handleClick = () => {
    var StateArray = [];
    FieldAttributes.forEach((el) => {
      StateArray.push(el);
    });
    StateArray.push({
      id: Math.floor(Math.random() * 1000000000),
      FieldValue: "AccountCategory",
      ColumnName: "Account Category",
      Expression: "Contains",
      FilterPropertyValue: "",
      FilterPropertyValue1: "",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      NullCondition: false,
    });
    SetFieldAttributes(StateArray);
  };

  //remove filter property
  const handleRemoveClick = (id) => {
    var countArray2 = [];
    FieldAttributes.map((element) => {
      if (element.id != id) {
        countArray2.push(element);
      }
    });
    SetFieldAttributes(countArray2);
  };

  // filter validation
  const FilterValidation = () => {
    let ValidationArr = [];
    FieldAttributes.map((newvalue) => {
      if (newvalue.IsBool == false) {
        var searchedVal1 = document.getElementById(
          "searchvalue" + newvalue.id
        ).value;

        if (searchedVal1.trim() == "") {
          ValidationArr.push(false);
        } else {
          ValidationArr.push(true);
        }
        var expressionval = document.getElementById(
          "expression" + newvalue.id
        ).value;

        if (expressionval == "between") {
          let FilterPropertyValue2 = document.getElementById(
            "EndsWith" + newvalue.id
          ).value;
          if (FilterPropertyValue2.trim() == "") {
            ValidationArr.push(false);
          } else {
            ValidationArr.push(true);
          }
        }
      }
    });

    return ValidationArr.includes(false);
  };

  const filterSearch = async() => {
    
    if (await FilterValidation() == false) {
      if (FieldAttributes.length <= 0) {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Please add filter property at least one
          </div>
        );
      } else {
        var ContactFilterSecArray = [];
        FieldAttributes.map((newvalue) => {
          var searchedVal1 = document
            .getElementById("searchvalue" + newvalue.id)
            .value.trim();
          var expressionval = document
            .getElementById("expression" + newvalue.id)
            .value.trim();
          var ColumnName = document.getElementById(
            "ColumnName" + newvalue.id
          ).value;
          if (expressionval == "between") {
            var searchedVal2 = document.getElementById(
              "EndsWith" + newvalue.id
            ).value;
            ContactFilterSecArray.push({
              ColumnName: ColumnName,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: searchedVal2,
              FieldType: newvalue.FieldFor,
              IsCustom:newvalue.IsCustom
            });
          } else {
            ContactFilterSecArray.push({
              ColumnName: ColumnName,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: searchedVal2,
              FieldType: newvalue.FieldFor,
              IsCustom:newvalue.IsCustom
            });
          }
        });

         SetFilterParameters(ContactFilterSecArray);
        SetIsApply(true);
        SetIsFiltered(true);
        ContactListGetFilter(ClientID, UserID, Role,ContactFilterSecArray,true)
        // SetFieldAttributes([]);
        // SetFieldAttributes([
        //   {
        //     id: Math.floor(Math.random() * 1000000000),
        //     FieldValue: "AccountCategory",
        //     ColumnName: "Account Category",
        //     Expression: "Contains",
        //     FilterPropertyValue: "",
        //     FilterPropertyValue1: "",
        //     IsCustom: false,
        //     IsDate: false,
        //     IsBool: false,
        //     NullCondition: false,
        //   },
        // ]);
        SetOpenAddFilter(false);
        toast.success(
          <div className="toastsize">
            Filter <br />
            Filter Apply SuccessFully.
          </div>
        );
      }
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };

  //Reset Filter
  const ResetFilter = () => {
    SetIsApply(false);
    SetFilterParameters([]);
  };

  //Check filter name exists or not
  const CheckFilterName = async () => {
    let FilterName = document.getElementById("FilterName").value.trim();
    let flag = false;
    const InputParameters = {
      ClientID: ClientID,
      FilterName: FilterName,
    };
    let res = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CheckContactFilterName",
      method: "POST",
      data: InputParameters,
    });
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data <= 0) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      toast.error("Something went wrong in filter name exists api");
    }

    return flag;
  };

  // Save Filter Property
  const addfilterContactfilter = async () => {
    SetDisabledAddFilter(true)
    let FilterName = document.getElementById("FilterName").value.trim();
    if (FilterName == "") {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Please enter filter name
        </div>
      );
    SetDisabledAddFilter(false)

    } else {
      if ((await CheckFilterName()) == false) {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Filter value already exist
          </div>
        );
    SetDisabledAddFilter(false)

      } else {
        if (FilterValidation() == false) {
          if (FieldAttributes.length <= 0) {
            toast.error(
              <div className="toastsize">
                Filter
                <br />
                Please add filter property at least one
              </div>
            );
    SetDisabledAddFilter(false)

          } else {
            var ContactFilterSecArray = [];

            FieldAttributes.map((newvalue) => {
              var searchedVal1 = document.getElementById(
                "searchvalue" + newvalue.id
              ).value;
              var expressionval = document.getElementById(
                "expression" + newvalue.id
              ).value;
              var Cloumnval = document.getElementById(
                "ColumnName" + newvalue.id
              ).value;
              if (expressionval == "between") {
                var searchedVal2 = document.getElementById(
                  "EndsWith" + newvalue.id
                ).value;
                ContactFilterSecArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: searchedVal2,
                  FieldType: newvalue.FieldFor,
                });
              } else {
                ContactFilterSecArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: null,
                  FieldType: newvalue.FieldFor,
                });
              }
            });

            const InputParameters = {
              ClientID: ClientID,
              FilterName: FilterName,
              FilterParameters: ContactFilterSecArray,
              CreatedDate: new Date(),
              UserID: UserID,
            };

            let res = await Axios({
              url: CommonConstants.MOL_APIURL + "/contacts/SaveContactFilter",
              method: "POST",
              data: InputParameters,
            });

            if (res.data.StatusMessage == "SUCCESS") {
              SetFilterParameters(ContactFilterSecArray);

              SetIsApply(true);
              SetFilterParameters([]);
              SetOpenAddFilter(false);
              SetFieldAttributes([
                {
                  id: Math.floor(Math.random() * 1000000000),
                  FieldValue: "AccountCategory",
                  ColumnName: "Account Category",
                  Expression: "Contains",
                  FilterPropertyValue: "",
                  FilterPropertyValue1: "",
                  IsCustom: false,
                  IsDate: false,
                  IsBool: false,
                  NullCondition: false,
                },
              ]);
              Getfilterlist(ClientID);
              toast.success(
                <div className="toastsize">
                  Filter
                  <br />
                  Filter Added SuccessFully.
                </div>
              );

            } else {
              toast.error("Error in save filter");
    SetDisabledAddFilter(false)

            }
          }
        } else {
          toast.error(
            <div className="toastsize">
              Filter
              <br />
              Property value can not be an empty
            </div>
          );
    SetDisabledAddFilter(false)

        }
      }
    }
  };

  // Get Filter List
  const Getfilterlist = (CID) => {
    var InputParameters = {
      ClientID: CID,
    };

    const rows3 = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/GetContactFilterDropDown",
      method: "POST",
      data: InputParameters,
    });
    rows3.then((result) => {
      SetFilterName(result.data.Data);
      if (result.data.Data.length > 0) {
        Getfilterpropertylist(result.data.Data[0]._id);
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };
  const handleExistingfilter=()=>{
        
    if(EditFieldAttributes.length>0){
      
      setIsPopupOpen(true);
    }else{

      toast.error(
        <div className="toastsize">
          Filter
          <br />
          No filter available
        </div>
      );
    }
  }

  const closePopup = () => {
    // Set the state to close the Popup
    setIsPopupOpen(false);
  };
  //Edit Book Meeting Filter Change
  const handleChangeFilter = () => {
    var filterdropoid = document.getElementById("FilterID").value;
    Getfilterpropertylist(filterdropoid);
  };

  // Get FilteProperty list with dropdown
  const Getfilterpropertylist = (filterid) => {
    SetFilterID(filterid);

    var InputParameters = {
      ClientID: ClientID,
      FilterID: filterid,
      IsDeleted: false,
    };
    const rows4 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/contacts/GetContactEditFilterProperties",
      method: "POST",
      data: InputParameters,
    });
    rows4.then((result) => {
      let FilteredArr = [];
      result?.data?.Data?.forEach((el, i) => {
        el.id = i;
        el.IsSaved = true;
        if (
          el.FilterPropertyName == "LastSendDate" ||
          el.FilterPropertyName == "LastValidationDate"
        ) {
          el.IsDate = true;
        } else {
          if (el.FieldType == "Text") {
            el.IsCustom = true;
          } else {
            el.IsCustom = false;
          }
          el.IsDate = false;
        }
        if (el.FilterPropertyName == "IsBlacklisted") {
          el.IsBool = true;
        } else {
          el.IsBool = false;
        }
        if (
          el.FilterPropertyName == "CallCount" ||
          el.FilterPropertyName == "TotalSent" ||
          el.FilterPropertyName == "TotalHardBounce" ||
          el.FilterPropertyName == "TotalOpen" ||
          el.FilterPropertyName == "TotalReply" ||
          el.FilterPropertyName == "TotalSoftBounce"
        ) {
          el.IsNum = true;
        } else {
          el.IsNum = false;
        }
        if (el.FilterPropertyName == "ContactTag") {
          el.IsSelect = true;
          el.Expression = "EqualTo";

          let ContactTagIds = el.FilterPropertyValue1?.split(",");
          let ArrayObj = [];

          ContactTagIds.forEach((elem) => {
            let Indexed = ContactTags?.findIndex((v) => v.ContactTagID == elem);
            ArrayObj.push({
              label: ContactTags[Indexed]?.Tag,
              value: ContactTags[Indexed]?.ContactTagID,
              cid: ContactTags[Indexed]?.ContactTagID,
            });
          });
          el.DefaultSelectIDs = ArrayObj;
        } else {
          el.IsSelect = false;
          el.DefaultSelectIDs = [];
        }
        if (
          el.ExpressionName == "IsNotBlank" ||
          el.ExpressionName == "IsBlank"
        ) {
          el.NullCondition = true;
        } else {
          el.NullCondition = false;
        }
        FilteredArr.push(el);
      });

      SetEditFieldAttributes(FilteredArr);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  //Edit column name
  const EditColumnName = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });

    let FilterPropertyName = document.getElementById(
      "EditColumnName" + ID
    ).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    let Indexed = CustomColumnData.findIndex(
      (v) => v.FieldValue === FilterPropertyName
    );

    SavedData[indexed].FilterPropertyName = FilterPropertyName;

    if (
      SavedData[indexed].FilterPropertyName == "OpportunityDate" ||
      SavedData[indexed].FilterPropertyName == "UnqualifiedDate" ||
      SavedData[indexed].FilterPropertyName == "EventSetDate" ||
      SavedData[indexed].FilterPropertyName == "EventStartDate" ||
      SavedData[indexed].FilterPropertyName == "LastStatusChangeDate" ||
      SavedData[indexed].FilterPropertyName == "CreatedDt" ||
      SavedData[indexed].FilterPropertyName == "ClosedWonDate" ||
      SavedData[indexed].FilterPropertyName == "ClosedLostDate" ||
      SavedData[indexed].FilterPropertyName == "QulifiedDate"
    ) {
      SavedData[indexed].IsDate = true;
    } else {
      SavedData[indexed].IsDate = false;

      if (SavedData[indexed].IsCustom == true) {
        SavedData[indexed].IsCustom = true;
      } else {
        SavedData[indexed].IsCustom = false;
      }
      if (SavedData[indexed].FilterPropertyName == "IsBlacklisted") {
        SavedData[indexed].IsBool = true;
      } else {
        SavedData[indexed].IsBool = false;
      }
      if (
        SavedData[indexed].FilterPropertyName == "CallCount" ||
        SavedData[indexed].FilterPropertyName == "TotalSent" ||
        SavedData[indexed].FilterPropertyName == "TotalHardBounce" ||
        SavedData[indexed].FilterPropertyName == "TotalOpen" ||
        SavedData[indexed].FilterPropertyName == "TotalReply" ||
        SavedData[indexed].FilterPropertyName == "TotalSoftBounce"
      ) {
        SavedData[indexed].IsNum = true;
      } else {
        SavedData[indexed].IsNum = false;
      }
      if (SavedData[indexed].FilterPropertyName == "ContactTag") {
        SavedData[indexed].IsSelect = true;
        SavedData[indexed].Expression = "EqualTo";
      } else {
        SavedData[indexed].IsSelect = false;
      }
      if (
        SavedData[indexed].FilterPropertyName == "LastSendDate" ||
        SavedData[indexed].FilterPropertyName == "LastValidationDate"
      ) {
        SavedData[indexed].IsDate = true;
      } else {
        SavedData[indexed].IsDate = false;
      }
      if (CustomColumnData[Indexed].IsCustom == true) {
        SavedData[indexed].FieldFor = "Text";
      } else {
        SavedData[indexed].FieldFor = "";
      }
    }

    SetEditFieldAttributes(SavedData);
  };

  //Edit Expression value
  const EditExpressValue = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let ExpressionName = document.getElementById("Editexpression" + ID).value;

    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].ExpressionName = ExpressionName;
    if (ExpressionName == "IsNotBlank" || ExpressionName == "IsBlank") {
      SavedData[indexed].NullCondition = true;
    } else {
      SavedData[indexed].NullCondition = false;
    }

    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue1
  const EditFilterPropertyValue1 = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });

    let FilterPropertyValue1 = document
      .getElementById("Editsearchvalue" + ID)
      .value.trim();
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue1 = FilterPropertyValue1;
    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue2
  const EditFilterPropertyValue2 = (ID) => {
    let SavedData = EditFieldAttributes;
    let FilterPropertyValue2 = document
      .getElementById("EditEndsWith" + ID)
      .value.trim();
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue2 = FilterPropertyValue2;

    SetEditFieldAttributes(SavedData);
  };

  //Edit Select contact tags
  const EditFilterhandleChangeContactOption = (val, id) => {
    let Arr = [];

    EditFieldAttributes.forEach((el) => {
      Arr.push(el);
    });
    let CidArr = [];

    val?.forEach((elem) => {
      CidArr.push(elem.cid);
    });

    let indexed = Arr.findIndex((v) => v.id === id);
    Arr[indexed].ContactTagIds = CidArr.toString();
    Arr[indexed].FilterPropertyValue1 = CidArr.toString();

    document.getElementById("Editsearchvalue" + id).value = CidArr.toString();

    SetEditFieldAttributes(Arr);
  };

  //Add new filter property
  const handleeditPropertyClick = () => {
    var StateArray = [];
    EditFieldAttributes.forEach((el) => {
      StateArray.push(el);
    });
    StateArray.push({
      id: Math.floor(Math.random() * 1000000000),
      FilterPropertyName: "AccountCategory",
      ColumnName: "Account Category",
      ExpressionName: "Contains",
      FilterPropertyValue1: "",
      FilterPropertyValue2: "",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      NullCondition: false,
    });

    SetEditFieldAttributes(StateArray);
  };

  // //Remove filter property
  const handleEditRemoveClick = (id) => {

    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    if (SavedData.length <= 1) {
      toast.error(
        <div>
          Filter
          <br />
          Required filter property at least one
        </div>
      );
    } else {
      let DeletedArr = [];
      DeletedFilterProperty.forEach((elem) => {
        DeletedArr.push(elem);
      });

      const FilterPropertyID = SavedData.findIndex((item) => item.id == id);
      if (SavedData[FilterPropertyID].IsSaved == true) {
        DeletedArr.push(SavedData[FilterPropertyID]._id);
        SetDeletedFilterProperty(DeletedArr);
      }
      const FilteredData = SavedData.filter((item) => item.id !== id);
      SetEditFieldAttributes(FilteredData);
    }
  };

  // filter validation
  const EditFilterValidation = () => {
    let ValidationArr = [];

    EditFieldAttributes.map((newvalue) => {
      if (newvalue.IsBool == false) {
        var searchedVal1 = document.getElementById(
          "Editsearchvalue" + newvalue.id
        ).value;

        if (searchedVal1.trim() == "") {
          ValidationArr.push(false);
        } else {
          ValidationArr.push(true);
        }
        var expressionval = document.getElementById(
          "Editexpression" + newvalue.id
        ).value;

        if (expressionval.trim() == "between") {
          let FilterPropertyValue2 = document.getElementById(
            "EditEndsWith" + newvalue.id
          ).value;
          if (FilterPropertyValue2.trim() == "") {
            ValidationArr.push(false);
          } else {
            ValidationArr.push(true);
          }
        }
      }
    });

    return ValidationArr.includes(false);
  };

  //Apply Edit Filter
  const ApplyEditFilter = () => {
    if (EditFilterValidation() == false) {
      var ContactFilterSecArray = [];
      EditFieldAttributes.map((el) => {
        if (el.ExpressionName == "between") {
          ContactFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: el.FilterPropertyValue2,
          });
        } else {
          ContactFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: null,
          });
        }
      });

      SetFilterParameters(ContactFilterSecArray);
      SetIsApply(true);
      SetOpenAddFilter(false);
      toast.success(
        <div className="toastsize">
          Filter <br />
          Filter Apply SuccessFully.
        </div>
      );
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };

  //Update Filter validation
  const UpdateFilterValidation = () => {
    let Flags = [];
    EditFieldAttributes.forEach((el) => {
      var searchedVal1 = document.getElementById(
        "Editsearchvalue" + el.id
      ).value;

      if (searchedVal1.trim() == "") {
        Flags.push(false);
      } else {
        Flags.push(true);
      }
      if (el.ExpressionName == "between") {
        let FilterPropertyValue2 = document.getElementById(
          "EditEndsWith" + el.id
        ).value;
        if (FilterPropertyValue2.trim() == "") {
          Flags.push(false);
        } else {
          Flags.push(true);
        }
      }
    });

    return Flags.includes(false);
  };

  //Update and apply filter
  const editapplyfilterSearch = async () => {
    if (UpdateFilterValidation() == false) {
      const InputParameters = {
        ClientID: ClientID,
        FilterParameters: EditFieldAttributes,
        LastUpdatedDate: new Date(),
        UserID: UserID,
        FilterID: FilterID,
        DeletedFilterProperty: DeletedFilterProperty,
      };

      let res = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contacts/UpdateContactFilterProperties",
        method: "POST",
        data: InputParameters,
      });

      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Filter <br />
            Filter Updated SuccessFully.
          </div>
        );
        var ContactFilterSecArray = [];
        EditFieldAttributes.map((el) => {
          if (el.ExpressionName == "between") {
            ContactFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1,
              searchvalue1: el.FilterPropertyValue2,
              FieldType: el.FieldFor,
            });
          } else {
            ContactFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1,
              searchvalue1: null,
              FieldType: el.FieldFor,
            });
          }
        });
        SetFilterParameters(ContactFilterSecArray);
        SetIsApply(true);
        SetOpenAddFilter(false);
      } else {
        toast.error("Error in save filter");
      }
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };

  //Open save as model box
  const OpenSaveAsModel = () => {
    SetSaveAsBoxFilterName(true);
  };

  //Save As filter data
  const SaveAsFilterMethod = async () => {
    if (UpdateFilterValidation() == false) {
      let SaveAsFilterNames = document.getElementById("SaveAsFilterName").value;
      if (SaveAsFilterNames.trim() == "") {
        SetErrorMessage("Please enter filter name");
      } else {
        if ((await CheckSaveAsFilterName()) == true) {
          var ContactFilterSecArray = [];
          EditFieldAttributes.map((newvalue) => {
            var searchedVal1 = document
              .getElementById("Editsearchvalue" + newvalue.id)
              .value.trim();
            var expressionval = document.getElementById(
              "Editexpression" + newvalue.id
            ).value;
            var Cloumnval = document.getElementById(
              "EditColumnName" + newvalue.id
            ).value;
            if (expressionval == "between") {
              var searchedVal2 = document
                .getElementById("EditEndsWith" + newvalue.id)
                .value.trim();
              ContactFilterSecArray.push({
                ColumnName: Cloumnval,
                expression: expressionval,
                searchvalue: searchedVal1,
                searchvalue1: searchedVal2,
                FieldType: newvalue.FieldFor,
              });
            } else {
              ContactFilterSecArray.push({
                ColumnName: Cloumnval,
                expression: expressionval,
                searchvalue: searchedVal1,
                searchvalue1: null,
                FieldType: newvalue.FieldFor,
              });
            }
          });
          const InputParameters = {
            ClientID: ClientID,
            FilterName: SaveAsFilterNames,
            FilterParameters: ContactFilterSecArray,
            CreatedDate: new Date(),
            UserID: UserID,
          };

          let res = await Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/SaveContactFilter",
            method: "POST",
            data: InputParameters,
          });
          if (res.data.StatusMessage == "SUCCESS") {
            SetFilterParameters(ContactFilterSecArray);

            SetIsApply(true);
            SetFilterParameters([]);
            SetOpenAddFilter(false);
            SetFieldAttributes([
              {
                id: Math.floor(Math.random() * 1000000000),
                FieldValue: "AccountCategory",
                ColumnName: "Account Category",
                Expression: "Contains",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                IsCustom: false,
                IsDate: false,
                IsBool: false,
                NullCondition: false,
              },
            ]);
            Getfilterlist(ClientID);
            toast.success(
              <div className="toastsize">
                Filter
                <br />
                Filter Updated SuccessFully.
              </div>
            );
          } else {
            toast.error("Something went wrong filter save as");
          }
          SetSaveAsBoxFilterName(false);
          SetOpenAddFilter(false);
        } else {
          SetErrorMessage("Contact filter already exist.");
        }
      }
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };

  //close edit filter
  const CloseEditFilter = () => {
    SetEditFieldAttributes([]);
    SetFieldAttributes([]);
    SetFieldAttributes([
      {
        id: Math.floor(Math.random() * 1000000000),
        FieldValue: "AccountCategory",
        ColumnName: "Account Category",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        IsCustom: false,
        IsDate: false,
        IsBool: false,
        NullCondition: false,
      },
    ]);
    SetIsApply(false);
    Getfilterlist(ClientID);
    SetOpenAddFilter(false);
  };

  //Check filter name exists or not
  const CheckSaveAsFilterName = async () => {
    let FilterName = document.getElementById("SaveAsFilterName").value.trim();
    let flag = false;
    if (FilterName == "") {
      SetErrorMessage("Please enter filter name");
      flag = false;
    } else {
      const InputParameters = {
        ClientID: ClientID,
        FilterName: FilterName,
      };

      let res = await Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CheckContactFilterName",
        method: "POST",
        data: InputParameters,
      });

      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data <= 0) {
          flag = true;
          SetErrorMessage(null);
        } else {
          SetErrorMessage("Contact filter already exist.");
          flag = false;
        }
      } else {
        toast.error("Something went wrong in filter name exists api");
      }
    }
    return flag;
  };

const ContactValidateEmailCheck = async () => {
  document.getElementById("Show_csv_details").style.display = "none"
  if (prospectAllSelectData.length === 0) {
    toast.error("Please select at least one contact from the list");
    return;
  }
  try {
    SetIsLoading(true);
    const InputParaMeter = {
      ContactValidateArray: prospectAllSelectData,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };

    const response = await Axios.post(
      CommonConstants.MOL_APIURL + "/contacts/ContactValidateCheck",
      InputParaMeter
    );

    if (response.data.StatusMessage === "SUCCESS") {
      toast.success("Saleshive contact will be validated soon.");
      ContactListGet(ClientID, UserID, Role,page);
      ContactListTotal(ClientID, UserID, Role);
      setProspectAllSelectData([]);
      document.getElementById("deleteall-btn").style.display = "none"
    } else {
      toast.error("Not validated");
    }
  } catch (error) {
    // Handle errors here

  } finally {
    SetIsLoading(false);
  }
};

  return ( 
    <div>
      <Popup open={ShowPopupImport}>
        <div>
          <div className="modal-black"></div>
          <div className="filterPopup largerPopup">
            <div className="paddingboxTerms">
              <div className="modal-header py-4 px-3">
                <div className="w-100 d-flex px-3">
                  <h5 className="mb-0">Email</h5>
                  <button
                    className="close"
                    onClick={() => SetShowPopupImport(false)}
                  >
                    <span aria-hidden="true" className="la la-remove"></span>
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="modal-body">
                  <div className="col-sm-8 mb-5 px-0">
                    <div className="row mb-3">
                      <label className="col-lg-5 col-form-label text-left">
                        Email
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="UserEmail"
                          data-val="true"
                          data-val-remote="Title already exist."
                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                          data-val-required="Please enter Email "
                          name="Username"
                          defaultValue={UserEmail}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top modal-content text-right">
                  <div className="row">
                    <div className="col-lg-12 pull-right">
                      <a
                        id="backtolist"
                        className="btn btn-secondary mr-2"
                        onClick={() => SetShowPopupImport(false)}
                      >
                        cancel
                      </a>
                      <a
                        id="submit"
                        onClick={() => {
                          if (!isSaving) {
                            ContactSaveCsvAddBulk();
                          }
                        }}
                        // onClick={() => ContactSaveCsvAddBulk()}
                        className="btn btn-primary btn-lightgreen mr-1"
                      >
                        Save
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      {
        IsLoading === true ? <>
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        </> : <></>
      }

      <div className="row border-bottom mx-1">
        <div className="col px-0">
          <h4 className="headertitle float-left pl-1 pt-2">{AccountTypeName}</h4>
          <div className="listing-li float-right padb-15 iconbox-pad">
            <ul className="mt-0">
              <li>
                <Tooltip title="Add">
                  <a
                    onClick={() => {
                      addcontactpage(null);
                    }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la la-icon-font-size-13 la-plus"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Import">
                  <a
                    onClick={ContactImportCsv}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la la-icon-font-size-13 la-upload"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Export">
                  <a
     onClick={() => {
    SetShowPopupExport(true);

    // Hide the element with id "Show_csv_details"
    const csvDetailsElement = document.getElementById("Show_csv_details");
    if (csvDetailsElement) {
      csvDetailsElement.style.display = "none";
    }
  }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la la-icon-font-size-13 la-download"></i>
                  </a>
                </Tooltip>
              </li>
              <Popup open={ShowPopupExport}>
       
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Email</h5>
                          <button
                            className="close"
                            onClick={() => SetShowPopupExport(false)}
                          >
                            <span
                              aria-hidden="true"
                              className="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="modal-body">
                          <div className="col-sm-6 mb-5 px-0">
                            <div className="row mb-3">
                              <label className="col-lg-5 col-form-label text-left">
                                Email
                              </label>
                              <div className="col-lg-7">
                                <input
                                  className="form-control m-input"
                                  id="Username"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter Email "
                                  name="Username"
                                  defaultValue={UserEmail}
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-top modal-content text-right">
                          <div className="row">
                            <div className="col-lg-12 pull-right">
                              <a
                                id="backtolist"
                                className="btn btn-secondary mr-2"
                                onClick={() => SetShowPopupExport(false)}
                              >
                                cancel
                              </a>
                              <button
                                disabled={IsDisablesExport}
                                id="submit"
                                onClick={() => ExportsToContacts()}
                                className="btn btn-primary btn-lightgreen mr-1"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              <li>
                <Tooltip title="Validate">
                  <a
                    onClick={ContactValidateEmailCheck}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa-check-square-o"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Bulk Delete">
                  <a
                    onClick={ContactBulkDeleteCsv}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la flaticon-delete-1 la-icon-font-size-13"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Add to Blacklist">
                  <a
                    onClick={ContactAddToBlackList}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa-ban"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Add Filter">
                  <button
                    className="btn btngroup m-btn m-btn--custom"
                    onClick={() => {
                      SetOpenAddFilter(true);
                      SetDisabledAddFilter(false)

                      // SetIsApply(false);
                      SetFieldAttributes([
                        {
                          id: Math.floor(Math.random() * 1000000000),
                          FieldValue: "AccountCategory",
                          ColumnName: "Account Category",
                          Expression: "Contains",
                          FilterPropertyValue: "",
                          FilterPropertyValue1: "",
                          IsCustom: false,
                          IsDate: false,
                          IsBool: false,
                          NullCondition: false,
                        },
                      ]);
                          // Hide the element with id "Show_csv_details"
    const csvDetailsElement = document.getElementById("Show_csv_details");
    if (csvDetailsElement) {
      csvDetailsElement.style.display = "none";
    }
                    }}
                  >
                    <i className="fa la-icon-font-size-13 fa-filter"></i>

                    {/* <span>Add Filter</span> */}
                  </button>
                </Tooltip>
                <Popup open={OpenAddFilter}>
      
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Filter</h5>
                            <button
                              className="close"
                              onClick={() => SetOpenAddFilter(false)}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="">
                            <div className="row">
                              <div className="col-xl-6 allcentertext">
                                <i className="la la-filter iconsfonts"></i>
                                <Popup
                                  trigger={
                                    <button className="btn btn-md btn-primary btnColor">
                                      <span className="Mobile_button">
                                        Add New Filter
                                      </span>
                                    </button>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup1000">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-4 px-3">
                                            <div className="w-100 d-fOpenAddFilterlex px-3 d-flex">
                                              <h5 className="mb-0">
                                                New Filter
                                              </h5>
                                              <button
                                                className="close"
                                                onClick={() => {
                                                  close();
                                                  SetFieldAttributes([]);
                                                  SetOpenAddFilter(false);
                                                  // this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', Expression: 'ContainTo'}]})
                                                }}
                                              >
                                                <span
                                                  aria-hidden="true"
                                                  className="la la-remove"
                                                ></span>
                                              </button>
                                            </div>
                                          </div>
                                          <div className="p-3">
                                            <div className="modal-body">
                                              <div className="col-sm-6 mb-4 px-0">
                                                <div className="row mb-2">
                                                  <label className="col-lg-5 col-form-label text-left">
                                                    Filter Name
                                                  </label>
                                                  <div className="col-lg-7">
                                                    <input
                                                      className="form-control m-input"
                                                      data-val-required="Please enter title"
                                                      id="FilterName"
                                                      name="FilterName"
                                                      placeholder="Filter Name"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="table-bordered">
                                                <table
                                                  className="table  table-hover"
                                                  id="tab_logic"
                                                >
                                                  <TableContainer
                                                    component={Paper}
                                                  >
                                                    <Table
                                                      size="small"
                                                      aria-label="a dense table"
                                                    >
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell>
                                                            Property{" "}
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {" "}
                                                          </TableCell>
                                                          <TableCell align="right"></TableCell>
                                                          <TableCell align="right"></TableCell>
                                                          <TableCell align="right"></TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {FieldAttributes.map(
                                                          (itemcount, i) => (
                                                            <TableRow
                                                              sx={{
                                                                "&:last-child td, &:last-child th":
                                                                  { border: 0 },
                                                              }}
                                                            >
                                                              <TableCell align="right">
                                                                <select
                                                                  className="form-control m-input"
                                                                  id={
                                                                    "ColumnName" +
                                                                    itemcount.id
                                                                  }
                                                                  onChange={() => {
                                                                    ColumnhandledDropdownChange(
                                                                      itemcount.id,
                                                                      i
                                                                    );
                                                                  }}
                                                                  value={
                                                                    itemcount.FieldValue
                                                                  }
                                                                  autocomplete="off"
                                                                >
                                                                  {CustomColumnData.map(
                                                                    (el) => (
                                                                      <option
                                                                        value={
                                                                          el.FieldValue
                                                                        }
                                                                      >
                                                                        {" "}
                                                                        {
                                                                          el.ColumnName
                                                                        }{" "}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                              </TableCell>
                                                              <TableCell>
                                                                {itemcount.IsCustom ==
                                                                true ? (
                                                                  <select
                                                                    className="form-control m-input"
                                                                    id={
                                                                      "ColumnName" +
                                                                      itemcount.id
                                                                    }
                                                                    // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                                                    // value={selectedvalue}
                                                                    autocomplete="off"
                                                                  >
                                                                    <option
                                                                      id="text"
                                                                      value="Text"
                                                                    >
                                                                      {" "}
                                                                      Text
                                                                    </option>
                                                                  </select>
                                                                ) : null}
                                                              </TableCell>
                                                              <TableCell align="right">
                                                                {itemcount.IsSelect ==
                                                                true ? (
                                                                  <select
                                                                    id={
                                                                      "expression" +
                                                                      itemcount.id
                                                                    }
                                                                    // onChange={()=>{this.EditExpressValue(itemcount.id)}}
                                                                    className="form-control m-input"
                                                                  >
                                                                    <option
                                                                      id="EqualTo"
                                                                      value="EqualTo"
                                                                    >
                                                                      Equals
                                                                    </option>
                                                                  </select>
                                                                ) : itemcount.IsBool ==
                                                                  true ? (
                                                                  <div>
                                                                    <select
                                                                      id={
                                                                        "expression" +
                                                                        itemcount.id
                                                                      }
                                                                      onChange={() => {
                                                                        ExpressionHandler(
                                                                          itemcount.id
                                                                        );
                                                                      }}
                                                                      className="form-control m-input"
                                                                    >
                                                                      <option
                                                                        id="true"
                                                                        value="True"
                                                                      >
                                                                        Yes
                                                                      </option>
                                                                      <option
                                                                        id="false"
                                                                        value="False"
                                                                      >
                                                                        No
                                                                      </option>
                                                                    </select>
                                                                    <input
                                                                      type="hidden"
                                                                      readOnly
                                                                      id={
                                                                        "searchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      value="y"
                                                                      className="form-control m-input required"
                                                                    />
                                                                  </div>
                                                                ) : itemcount.IsDate ==
                                                                    true ||
                                                                  itemcount.IsNum ==
                                                                    true ? (
                                                                  <select
                                                                    id={
                                                                      "expression" +
                                                                      itemcount.id
                                                                    }
                                                                    className="form-control m-input"
                                                                    onChange={() => {
                                                                      ExpressionHandler(
                                                                        itemcount.id
                                                                      );
                                                                    }}
                                                                  >
                                                                    <option
                                                                      id="Equals"
                                                                      value="Equals"
                                                                    >
                                                                      Equals
                                                                    </option>
                                                                    <option
                                                                      id="Lessthan"
                                                                      value="LessThan"
                                                                    >
                                                                      {" "}
                                                                      Less Than{" "}
                                                                    </option>
                                                                    <option
                                                                      id="LessThanEqualTo"
                                                                      value="LessThanEqualTo"
                                                                    >
                                                                      {" "}
                                                                      Less Than
                                                                      Equal To{" "}
                                                                    </option>
                                                                    <option
                                                                      id="greaterthan"
                                                                      value="GreaterThan"
                                                                    >
                                                                      {" "}
                                                                      Greater
                                                                      Than{" "}
                                                                    </option>
                                                                    <option
                                                                      id="greaterthanequalto"
                                                                      value="GreaterThanEqaulTo"
                                                                    >
                                                                      {" "}
                                                                      Greater
                                                                      Than Eqaul
                                                                      To{" "}
                                                                    </option>
                                                                    <option
                                                                      id="between"
                                                                      value="between"
                                                                    >
                                                                      {" "}
                                                                      Between{" "}
                                                                    </option>
                                                                    <option
                                                                      id="IsNotBlank"
                                                                      value="IsNotBlank"
                                                                    >
                                                                      Is Not
                                                                      Blank
                                                                    </option>
                                                                    <option
                                                                      id="IsBlank"
                                                                      value="IsBlank"
                                                                    >
                                                                      Is Blank
                                                                    </option>
                                                                  </select>
                                                                ) : (
                                                                  <select
                                                                    id={
                                                                      "expression" +
                                                                      itemcount.id
                                                                    }
                                                                    onChange={() => {
                                                                      ExpressionHandler(
                                                                        itemcount.id
                                                                      );
                                                                    }}
                                                                    className="form-control m-input"
                                                                  >
                                                                    <option
                                                                      id="Contains"
                                                                      value="Contains"
                                                                    >
                                                                      Contains
                                                                    </option>
                                                                    <option
                                                                      id="EqualTo"
                                                                      value="EqualTo"
                                                                    >
                                                                      Equals
                                                                    </option>
                                                                    <option
                                                                      id="IsNotBlank"
                                                                      value="IsNotBlank"
                                                                    >
                                                                      Is Not
                                                                      Blank
                                                                    </option>
                                                                    <option
                                                                      id="IsBlank"
                                                                      value="IsBlank"
                                                                    >
                                                                      Is Blank
                                                                    </option>
                                                                  </select>
                                                                )}
                                                              </TableCell>
                                                              <TableCell align="right">
                                                                {itemcount.IsSelect ==
                                                                true ? (
                                                                  <>
                                                                    <Select
                                                                      closeMenuOnSelect={
                                                                        false
                                                                      }
                                                                      hideSelectedOptions={
                                                                        false
                                                                      }
                                                                      options={
                                                                        contactoptions
                                                                      }
                                                                      id="ContactTagID"
                                                                      isMulti
                                                                      onChange={(
                                                                        val
                                                                      ) =>
                                                                        FilterhandleChangeContactOption(
                                                                          val,
                                                                          itemcount.id
                                                                        )
                                                                      }
                                                                    />
                                                                    <input
                                                                      type="hidden"
                                                                      readOnly
                                                                      id={
                                                                        "searchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      className="form-control m-input required"
                                                                    />
                                                                  </>
                                                                ) : itemcount.IsBool ==
                                                                    true ||
                                                                  itemcount.NullCondition ==
                                                                    true ? (
                                                                  <input
                                                                    type="hidden"
                                                                    hidden={
                                                                      true
                                                                    }
                                                                    readOnly
                                                                    id={
                                                                      "searchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    value="y"
                                                                    className="form-control m-input required"
                                                                  />
                                                                ) : itemcount.IsDate ==
                                                                  true ? (
                                                                  itemcount.Expression ==
                                                                  "between" ? (
                                                                    <>
                                                                      <input
                                                                        type="date"
                                                                        id={
                                                                          "searchvalue" +
                                                                          itemcount.id
                                                                        }
                                                                        className="form-control m-input required"
                                                                      />
                                                                      <input
                                                                        type="date"
                                                                        id={
                                                                          "EndsWith" +
                                                                          itemcount.id
                                                                        }
                                                                        className="form-control m-input required"
                                                                      />
                                                                    </>
                                                                  ) : (
                                                                    <input
                                                                      type="date"
                                                                      id={
                                                                        "searchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      className="form-control m-input required"
                                                                    />
                                                                  )
                                                                ) : itemcount.IsNum ==
                                                                  true ? (
                                                                  itemcount.Expression ==
                                                                  "between" ? (
                                                                    <>
                                                                      <input
                                                                        type="text"
                                                                        id={
                                                                          "searchvalue" +
                                                                          itemcount.id
                                                                        }
                                                                        className="form-control m-input required"
                                                                      />
                                                                      <input
                                                                        type="text"
                                                                        id={
                                                                          "EndsWith" +
                                                                          itemcount.id
                                                                        }
                                                                        className="form-control m-input required"
                                                                      />
                                                                    </>
                                                                  ) : (
                                                                    <input
                                                                      type="text"
                                                                      id={
                                                                        "searchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      className="form-control m-input required"
                                                                    />
                                                                  )
                                                                ) : (
                                                                  <input
                                                                    type="text"
                                                                    id={
                                                                      "searchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    className="form-control m-input required"
                                                                  />
                                                                )}
                                                              </TableCell>

                                                              <TableCell align="right"></TableCell>
                                                              <TableCell align="left">
                                                                <button
                                                                  type="button"
                                                                  title="Delete"
                                                                  id={
                                                                    "delete" +
                                                                    itemcount.id
                                                                  }
                                                                  className="btn btngroup"
                                                                  onClick={() => {
                                                                    handleRemoveClick(
                                                                      itemcount.id
                                                                    );
                                                                  }}
                                                                >
                                                                  <i className="la flaticon-delete-1"></i>
                                                                </button>
                                                              </TableCell>
                                                            </TableRow>
                                                          )
                                                        )}
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                </table>
                                              </div>
                                              <div className="row mb-5 pb-5">
                                                <div className="col-sm-12 text-left mb-5 pb-5">
                                                  <button
                                                    type="button"
                                                    className="btn btngroup"
                                                    value="ADD Row"
                                                    onClick={handleClick}
                                                  >
                                                    <i className="la la-plus la-icon-font-size-13"></i>{" "}
                                                    Add Property
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="border-top modal-content text-right px-4">
                                              <div className="row py-2 pb-4 px-1">
                                                <div className="col-lg-12 pull-right">
                                                  <a
                                                    id="backtolist"
                                                    className="btn btn-secondary mr-2"
                                                    onClick={() => {
                                                      close();
                                                      // this.setState({FieldAttributes:[]});
                                                      // this.setState({OpenAddFilter:false});
                                                      // this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', Expression: 'ContainTo'}]})
                                                    }}
                                                  >
                                                    Cancel
                                                  </a>
                                                  <a
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                    onClick={() => {
                                                      filterSearch();
                                                      // this.closeTermsConditionModal;
                                                    }}
                                                    value="Apply"
                                                  >
                                                    Apply
                                                  </a>
                                                  <button
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                    onClick={() => {
                                                      addfilterContactfilter();
                                                    }}
                                                    disabled={DisabledAddFilter}

                                                    value="Save"
                                                  >
                                                    <i className="la la-save"></i>{" "}
                                                    Save
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </div>
                              <div className="col-xl-6 allcentertext brdr-left">
                                <i className="la la-filter iconsfonts"></i>

                                {isPopupOpen && ( 
                                              <Popup
                                                modal
                                                nested
                                                onClose={closePopup} 
                                              >
                                              </Popup>
                                            )}
                                            <button
                                              className="btn btn-md btn-primary btnColor"
                                              onClick={handleExistingfilter}
                                            >
                                              Select from existing Filter
                                            </button>
                                      <Popup
                                        
                                        modal
                                        nested
                                        open={isPopupOpen} 
                                        closeOnDocumentClick={false} 
                                        onClose={closePopup}
                                      >
                                  {(close) => (
                                    <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup1000">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-4 px-3">
                                            <div className="w-100 d-flex px-3">
                                              <h5 className="mb-0">
                                                Edit Filter
                                              </h5>
                                              <button
                                                className="close"
                                                onClick={close}
                                              >
                                                <span
                                                  aria-hidden="true"
                                                  className="la la-remove"
                                                ></span>
                                              </button>
                                            </div>
                                          </div>
                                          <div className="p-3">
                                            <div className="modal-body">
                                              <div className="col-sm-6 mb-5 px-0">
                                                <div className="row mb-3">
                                                  <label className="col-lg-5 col-form-label text-left">
                                                    Filter Name
                                                  </label>
                                                  <div className="col-lg-7">
                                                    <select
                                                      className="form-control m-input m--hide"
                                                      id="FilterID"
                                                      name="FilterID"
                                                      onChange={() => {
                                                        handleChangeFilter();
                                                      }}
                                                    >
                                                      {FilterName &&
                                                        FilterName.map(
                                                          (valuefilter) => (
                                                            <option
                                                            key={valuefilter._id}
                                                              value={
                                                                valuefilter._id
                                                              }
                                                            >
                                                              {
                                                                valuefilter.FilterName
                                                              }
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="table-bordered">
                                                <TableContainer
                                                  component={Paper}
                                                >
                                                  <Table
                                                    size="small"
                                                    aria-label="a dense table"
                                                  >
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell>
                                                          Property{" "}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                          {" "}
                                                        </TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {EditFieldAttributes.map(
                                                        (itemcount, i) => (
                                                          <TableRow
                                                            sx={{
                                                              "&:last-child td, &:last-child th":
                                                                { border: 0 },
                                                            }}
                                                          >
                                                            <TableCell align="right">
                                                              <select
                                                                className="form-control m-input"
                                                                id={
                                                                  "EditColumnName" +
                                                                  itemcount.id
                                                                }
                                                                onChange={() => {
                                                                  EditColumnName(
                                                                    itemcount.id,
                                                                    i
                                                                  );
                                                                }}
                                                                value={
                                                                  itemcount.FilterPropertyName
                                                                }
                                                                autocomplete="off"
                                                              >
                                                                {CustomColumnData.map(
                                                                  (el) => (
                                                                    <option
                                                                      value={
                                                                        el.FieldValue
                                                                      }
                                                                    >
                                                                      {" "}
                                                                      {
                                                                        el.ColumnName
                                                                      }{" "}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </TableCell>
                                                            <TableCell>
                                                              {itemcount.IsCustom ==
                                                              true ? (
                                                                <select
                                                                  className="form-control m-input"
                                                                  id={
                                                                    "EditColumnName" +
                                                                    itemcount.id
                                                                  }
                                                                  // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                                                  // value={selectedvalue}
                                                                  autocomplete="off"
                                                                >
                                                                  <option
                                                                    id="text"
                                                                    value="Text"
                                                                  >
                                                                    {" "}
                                                                    Text
                                                                  </option>
                                                                </select>
                                                              ) : null}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                              {itemcount.IsSelect ==
                                                              true ? (
                                                                <select
                                                                  id={
                                                                    "Editexpression" +
                                                                    itemcount.id
                                                                  }
                                                                  // onChange={()=>{EditExpressValue(itemcount.id)}}
                                                                  className="form-control m-input"
                                                                >
                                                                  <option
                                                                    id="EqualTo"
                                                                    value="EqualTo"
                                                                  >
                                                                    Equals
                                                                  </option>
                                                                </select>
                                                              ) : itemcount.IsBool ==
                                                                true ? (
                                                                <div>
                                                                  <select
                                                                    id={
                                                                      "Editexpression" +
                                                                      itemcount.id
                                                                    }
                                                                    onChange={() => {
                                                                      EditExpressValue(
                                                                        itemcount.id
                                                                      );
                                                                    }}
                                                                    className="form-control m-input"
                                                                  >
                                                                    <option
                                                                      id="true"
                                                                      value="True"
                                                                    >
                                                                      Yes
                                                                    </option>
                                                                    <option
                                                                      id="false"
                                                                      value="False"
                                                                    >
                                                                      No
                                                                    </option>
                                                                  </select>
                                                                  <input
                                                                    type="hidden"
                                                                    hidden
                                                                    readOnly
                                                                    id={
                                                                      "Editsearchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    value="y"
                                                                    className="form-control m-input required"
                                                                  />
                                                                </div>
                                                              ) : itemcount.IsDate ==
                                                                  true ||
                                                                itemcount.IsNum ==
                                                                  true ? (
                                                                <select
                                                                  id={
                                                                    "Editexpression" +
                                                                    itemcount.id
                                                                  }
                                                                  className="form-control m-input"
                                                                  onChange={() => {
                                                                    EditExpressValue(
                                                                      itemcount.id
                                                                    );
                                                                  }}
                                                                  value={
                                                                    itemcount.ExpressionName
                                                                  }
                                                                >
                                                                  <option
                                                                    id="Equals"
                                                                    value="Equals"
                                                                  >
                                                                    Equals
                                                                  </option>
                                                                  <option
                                                                    id="Lessthan"
                                                                    value="LessThan"
                                                                  >
                                                                    {" "}
                                                                    Less Than{" "}
                                                                  </option>
                                                                  <option
                                                                    id="LessThanEqualTo"
                                                                    value="LessThanEqualTo"
                                                                  >
                                                                    {" "}
                                                                    Less Than
                                                                    Equal To{" "}
                                                                  </option>
                                                                  <option
                                                                    id="greaterthan"
                                                                    value="GreaterThan"
                                                                  >
                                                                    {" "}
                                                                    Greater Than{" "}
                                                                  </option>
                                                                  <option
                                                                    id="greaterthanequalto"
                                                                    value="GreaterThanEqaulTo"
                                                                  >
                                                                    {" "}
                                                                    Greater Than
                                                                    Eqaul To{" "}
                                                                  </option>
                                                                  <option
                                                                    id="between"
                                                                    value="between"
                                                                  >
                                                                    {" "}
                                                                    Between{" "}
                                                                  </option>
                                                                  <option
                                                                    id="IsNotBlank"
                                                                    value="IsNotBlank"
                                                                  >
                                                                    Is Not Blank
                                                                  </option>
                                                                  <option
                                                                    id="IsBlank"
                                                                    value="IsBlank"
                                                                  >
                                                                    Is Blank
                                                                  </option>
                                                                </select>
                                                              ) : (
                                                                <select
                                                                  id={
                                                                    "Editexpression" +
                                                                    itemcount.id
                                                                  }
                                                                  onChange={() => {
                                                                    EditExpressValue(
                                                                      itemcount.id
                                                                    );
                                                                  }}
                                                                  value={
                                                                    itemcount.ExpressionName
                                                                  }
                                                                  className="form-control m-input"
                                                                >
                                                                  <option
                                                                    id="Contains"
                                                                    value="Contains"
                                                                  >
                                                                    Contains
                                                                  </option>
                                                                  <option
                                                                    id="EqualTo"
                                                                    value="EqualTo"
                                                                  >
                                                                    Equals
                                                                  </option>
                                                                  <option
                                                                    id="IsNotBlank"
                                                                    value="IsNotBlank"
                                                                  >
                                                                    Is Not Blank
                                                                  </option>
                                                                  <option
                                                                    id="IsBlank"
                                                                    value="IsBlank"
                                                                  >
                                                                    Is Blank
                                                                  </option>
                                                                </select>
                                                              )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                              {itemcount.IsSelect ==
                                                              true ? (
                                                                <>
                                                                  <Select
                                                                    closeMenuOnSelect={
                                                                      false
                                                                    }
                                                                    hideSelectedOptions={
                                                                      false
                                                                    }
                                                                    options={
                                                                      ContactTagsArr
                                                                    }
                                                                    defaultValue={
                                                                      itemcount.DefaultSelectIDs
                                                                    }
                                                                    id="ContactTagID"
                                                                    isMulti
                                                                    onChange={(
                                                                      val
                                                                    ) =>
                                                                      EditFilterhandleChangeContactOption(
                                                                        val,
                                                                        itemcount.id
                                                                      )
                                                                    }
                                                                  />
                                                                  <input
                                                                    type="hidden"
                                                                    hidden
                                                                    readOnly
                                                                    id={
                                                                      "Editsearchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    className="form-control m-input required"
                                                                    value={
                                                                      itemcount.FilterPropertyValue1
                                                                    }
                                                                  />
                                                                </>
                                                              ) : itemcount.IsBool ==
                                                                  true ||
                                                                itemcount.NullCondition ==
                                                                  true ? (
                                                                <input
                                                                  type="hidden"
                                                                  hidden={true}
                                                                  readOnly
                                                                  id={
                                                                    "Editsearchvalue" +
                                                                    itemcount.id
                                                                  }
                                                                  value="y"
                                                                  className="form-control m-input required"
                                                                />
                                                              ) : itemcount.IsDate ==
                                                                true ? (
                                                                itemcount.ExpressionName ==
                                                                "between" ? (
                                                                  <>
                                                                    <input
                                                                      type="date"
                                                                      id={
                                                                        "Editsearchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      value={
                                                                        itemcount.FilterPropertyValue1
                                                                      }
                                                                      className="form-control m-input required"
                                                                      onChange={() =>
                                                                        EditFilterPropertyValue1(
                                                                          itemcount.id
                                                                        )
                                                                      }
                                                                    />

                                                                    <input
                                                                      type="date"
                                                                      id={
                                                                        "EditEndsWith" +
                                                                        itemcount.id
                                                                      }
                                                                      value={
                                                                        itemcount.FilterPropertyValue2
                                                                      }
                                                                      className="form-control m-input required"
                                                                      onChange={() =>
                                                                        EditFilterPropertyValue2(
                                                                          itemcount.id
                                                                        )
                                                                      }
                                                                    />
                                                                  </>
                                                                ) : (
                                                                  <input
                                                                    type="date"
                                                                    id={
                                                                      "Editsearchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    value={
                                                                      itemcount.FilterPropertyValue1
                                                                    }
                                                                    onChange={() =>
                                                                      EditFilterPropertyValue1(
                                                                        itemcount.id
                                                                      )
                                                                    }
                                                                    className="form-control m-input required"
                                                                  />
                                                                )
                                                              ) : itemcount.IsNum ==
                                                                true ? (
                                                                itemcount.ExpressionName ==
                                                                "between" ? (
                                                                  <>
                                                                    <input
                                                                      type="text"
                                                                      id={
                                                                        "Editsearchvalue" +
                                                                        itemcount.id
                                                                      }
                                                                      value={
                                                                        itemcount.FilterPropertyValue1
                                                                      }
                                                                      onChange={() =>
                                                                        EditFilterPropertyValue1(
                                                                          itemcount.id
                                                                        )
                                                                      }
                                                                      className="form-control m-input required"
                                                                    />

                                                                    <input
                                                                      type="text"
                                                                      id={
                                                                        "EditEndsWith" +
                                                                        itemcount.id
                                                                      }
                                                                      onChange={() =>
                                                                        EditFilterPropertyValue2(
                                                                          itemcount.id
                                                                        )
                                                                      }
                                                                      value={
                                                                        itemcount.FilterPropertyValue2
                                                                      }
                                                                      className="form-control m-input required"
                                                                    />
                                                                  </>
                                                                ) : (
                                                                  <input
                                                                    type="text"
                                                                    id={
                                                                      "Editsearchvalue" +
                                                                      itemcount.id
                                                                    }
                                                                    onChange={() =>
                                                                      EditFilterPropertyValue1(
                                                                        itemcount.id
                                                                      )
                                                                    }
                                                                    value={
                                                                      itemcount.FilterPropertyValue1
                                                                    }
                                                                    className="form-control m-input required"
                                                                  />
                                                                )
                                                              ) : (
                                                                <input
                                                                  type="text"
                                                                  id={
                                                                    "Editsearchvalue" +
                                                                    itemcount.id
                                                                  }
                                                                  onChange={() =>
                                                                    EditFilterPropertyValue1(
                                                                      itemcount.id
                                                                    )
                                                                  }
                                                                  value={
                                                                    itemcount.FilterPropertyValue1
                                                                  }
                                                                  className="form-control m-input required"
                                                                />
                                                              )}
                                                            </TableCell>

                                                            <TableCell align="right">
                                                              <button
                                                                type="button"
                                                                id={
                                                                  "delete" +
                                                                  itemcount.id
                                                                }
                                                                className="btn btngroup"
                                                                onClick={() => {
                                                                  handleEditRemoveClick(
                                                                    itemcount.id
                                                                  );
                                                                }}
                                                              >
                                                                <i className="la flaticon-delete-1"></i>
                                                              </button>
                                                            </TableCell>
                                                            <TableCell align="right"></TableCell>
                                                            <TableCell align="right"></TableCell>
                                                          </TableRow>
                                                        )
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-12 text-left">
                                                  <button
                                                    type="button"
                                                    className="btn btngroup"
                                                    onClick={() => {
                                                      handleeditPropertyClick();
                                                    }}
                                                  >
                                                    <i className="la la-plus la-icon-font-size-13"></i>{" "}
                                                    Add Property{" "}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="border-top modal-content text-right">
                                              <div className="row">
                                                <div className="col-lg-12 pull-right">
                                                  <a
                                                    id="backtolist"
                                                    className="btn btn-secondary mr-2"
                                                    onClick={() => {
                                                      CloseEditFilter();
                                                    }}
                                                  >
                                                    cancel
                                                  </a>
                                                  <button
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                    onClick={() => {
                                                      ApplyEditFilter();
                                                      // this.closeTermsConditionModal;
                                                    }}
                                                  >
                                                    Apply
                                                  </button>
                                                  <a
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                    onClick={() => {
                                                      editapplyfilterSearch();
                                                    }}
                                                  >
                                                    <i className="la la-save"></i>{" "}
                                                    Save
                                                  </a>
                                                  <a
                                                    id="submit"
                                                    className="btn btn-primary btn-lightgreen mr-1"
                                                    onClick={() => {
                                                      OpenSaveAsModel();
                                                    }}
                                                  >
                                                    <i className="la la-save"></i>{" "}
                                                    Save As
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                                <Popup open={SaveAsBoxFilterName}>
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup1000">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-4 px-3">
                                          <div className="w-100 d-flex px-3">
                                            <h5 className="mb-0">Filter</h5>
                                            <button
                                              className="close"
                                              onClick={() => {
                                                SetSaveAsBoxFilterName(false);
                                              }}
                                            >
                                              <span
                                                aria-hidden="true"
                                                className="la la-remove"
                                              ></span>
                                            </button>
                                          </div>
                                        </div>
                                        <div className="p-3">
                                          <div className="modal-body">
                                            <div className="col-sm-6 mb-5 px-0">
                                              <div className="row mb-3">
                                                <label className="col-lg-5 col-form-label text-left">
                                                  Filter
                                                </label>
                                                <div className="col-lg-7">
                                                  <input
                                                    type="text"
                                                    onBlur={() => {
                                                      CheckSaveAsFilterName();
                                                    }}
                                                    placeholder="Enter filter name"
                                                    className="form-control m-input required"
                                                    id="SaveAsFilterName"
                                                  />
                                                  <span className="font-danger">
                                                    {ErrorMessage}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="border-top modal-content text-right">
                                            <div className="row">
                                              <div className="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  className="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    SetSaveAsBoxFilterName(
                                                      false
                                                    );
                                                    SetErrorMessage(null);
                                                  }}
                                                >
                                                  cancel
                                                </a>

                                                <a
                                                  id="submit"
                                                  className="btn btn-primary btn-lightgreen mr-1"
                                                  onClick={() => {
                                                    SaveAsFilterMethod();
                                                  }}
                                                >
                                                  <i className="la la-save"></i>{" "}
                                                  Save
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>
              <li>
                <Tooltip title="Add to Campaign">
                  <a
                    onClick={CampaignListMove}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la flaticon-add la-icon-font-size-13"></i>
                  </a>
                </Tooltip>
              </li>
              <Popup open={ShowPopupCampaign}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup longerPopup">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Move to campaign</h5>
                          <button
                            className="close"
                            onClick={() => SetShowPopupCampaign(false)}
                          >
                            <span
                              aria-hidden="true"
                              className="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="modal-body">
                          <div className="col-sm-6 mb-5 px-0">
                            <div className="row mb-3">
                              <label className="col-lg-5 col-form-label text-left">
                                Campaign
                              </label>
                              <div className="col-lg-7">
                                <select
                                  className="form-control  m-input"
                                  name="CampaignID"
                                  isMulti
                                  options
                                  id="CampaignID"
                                >
                                  {CampaignData.map((value) => (
                                    <option value={value._id}>
                                      {value.Name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-top modal-content text-right">
                          <div className="row">
                            <div className="col-lg-12 pull-right">
                              <a
                                id="backtolist"
                                className="btn btn-secondary mr-2"
                                onClick={() => {
                                  SetShowPopupCampaign(false);
                                }}
                              >
                                Cancel
                              </a>
                              <a
                                id="submit"
                                onClick={CampaignToList}
                                className="btn btn-primary btn-lightgreen mr-1"
                              >
                                <i className="la la-save mr-2"></i>
                                Move
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              <li>
                <Tooltip title="Custom Column">
                  <a
                    href="/contactcustomcolumn"
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la flaticon-interface-8 la-icon-font-size-13"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Add Tag">
                  <a
                    onClick={() => {
                      ContactAddTagBtn();
                    }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa-tag"></i>
                  </a>
                </Tooltip>
                <Popup open={ShowPopup}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Tag</h5>
                            <button
                              className="close"
                              onClick={() => SetShowPopup(false)}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                            <div className="col-sm-6 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-5 col-form-label text-left px-5">
                                  Tag
                                </label>
                                <div className="col-lg-7">
                                  <input
                                    className="form-control m-input"
                                    id="Tag"
                                    data-val="true"
                                    data-val-remote="Title already exist."
                                    data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                    data-val-required="Please enter tag"
                                    name="Tag"
                                    placeholder="Enter tag"
                                    type="text"
                                  />
                                  <p
                                    id="tag_err"
                                    className="errorinput"
                                    style={{ display: "none" }}
                                  >
                                    please enter tag
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right pt-3">
                            <div className="row">
                              <div className="col-lg-12 pull-right">
                                <button
                                  id="backtolist"
                                  className="btn btn-secondary mr-2"
                                  onClick={() => {
                                    SetShowPopup(false);
                                  }}
                                >
                                  cancel
                                </button>
                                <button
                                  disabled={AddTagBtnDisabled}
                                  id="submit"
                                  onClick={ContactTagAdd}
                                  className="btn btn-primary btn-lightgreen mr-1"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>
              <li>
                <Tooltip title="Delete Tag">
                  <a
                    className="btn btngroup m-btn m-btn--custom"
                    onClick={() => {
                    ContactDeleteTagBtn();
                    }}
                  >
                    <i className="fa la-icon-font-size-13 fa-tag text-danger"></i>
                  </a>
                </Tooltip>

                <Popup open={DeleteTagModel}>
             
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Delete Tag</h5>
                            <button
                              className="close"
                              onClick={() => {
                                SetDeleteTagModel(false);
                              }}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                            <div className="col-sm-6 mb-5 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-5 col-form-label text-left">
                                  Tag
                                </label>
                                <div className="col-lg-7">
                                  <Select
                                    placeholder="Select Tag"
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={contactoptions}
                                    id="ContactTagID"
                                    isMulti
                                    onChange={(val) =>
                                      handleChangeContactOption(val)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right pt-3">
                            <div className="row">
                              <div className="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  className="btn btn-secondary mr-2"
                                  onClick={() => {
                                    SetDeleteTagModel(false);
                                  }}
                                >
                                  Cancel
                                </a>
                                <a
                                  id="submit"
                                  onClick={DeleteSelectContactTag}
                                  className="btn btn-primary btn-lightgreen mr-1"
                                >
                                  <i className="la la-save mr-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>

              <li>
                <Tooltip title="Reassign Sales Development Reps">
                  <a
                    onClick={() => {
                      ContactReassignDevBtn();
                    }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa fa-exchange"></i>
                  </a>
                </Tooltip>
                <Popup open={ShowPopupReassignDev}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup longerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Reassign</h5>
                            <button
                              className="close"
                              onClick={() => {
                                ContactReassignDevPopupHideBtn();
                              }}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                            <div className="col-sm-10 mb-4 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-4 text-left reassign-form">
                                  From
                                </label>
                                <div className="col-lg-8">
                                  <Select
                                    // closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={ContactReassignData}
                                    value={ReassignFromSelectedValue}
                                    id="FromRe"
                                    placeholder={
                                      "Select sales development reps"
                                    }
                                    onChange={(e) =>
                                      HandleChangeFromSaleDevlopMentReps(e)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-10 mb-3 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-4 reassign-form text-left">
                                  To
                                </label>
                                <div className="col-lg-8">
                                  <Select
                                    // closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    placeholder={
                                      "Select sales development reps"
                                    }
                                    options={ContactToReassignData}
                                    onChange={(val) =>
                                      HandleChangeToSaleDevlopMentReps(val)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right pt-3">
                            <div className="row">
                              <div className="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  className="btn btn-secondary mr-2"
                                  onClick={() => {
                                    ContactReassignDevPopupHideBtn();
                                  }}
                                >
                                  Cancel
                                </a>
                                <a
                                  id="submit"
                                  onClick={() => ReassignToColdCallers()}
                                  className="btn btn-primary btn-lightgreen mr-1"
                                >
                                  <i className="la la-save mr-1"></i>
                                  Reassign
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Import Csv for Black list*/}

          <div className="portletbody" id="import_csv" style={{display:"none"}}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2 pb-3">
            <h3 className="uploadheadingtitle">Import Contact Details</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={ImprotCsvHandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={ImportCsvReset}
              >
                <span>Select .CSV file</span>
                <p>Add Contact data from csv file to bulk upload.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2 border-top pt-3">
            <div className="alignright">
              <a onClick={ContactMapCsv} className="btn btn-primary btnColor">
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={CancleContactBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>



      {/* Mapped div */}
      <div
        className="portletbody portletshadow p-4 m-1 map_attribute"
        id="map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left pl-0">Map attributes</h4>
        </div>
        <div className="row">
        <div className="col-12" id="divContactSelection">
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn1"
                  disabled="disabled"
                >
                  <option selected="" value="First Name">
                    First Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="First Name"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={ FirstName == null ? DropboxData.indexOf("First Name"): FirstName}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn2"
                  disabled="disabled"
                >
                  <option selected="" value="Last Name">
                    Last Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Last Name"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={LastName == null ? DropboxData.indexOf("Last Name") : LastName}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn3"
                  disabled="disabled"
                >
                  <option selected="" value="Email">
                    Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Email"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Email == null ? DropboxData.indexOf("Email") : Email}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn4"
                  disabled="disabled"
                >
                  <option selected="" value="Title">
                    Title
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Title"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Title == null ? DropboxData.indexOf("Title") : Title}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn5"
                  disabled="disabled"
                >
                  <option selected="" value="Company">
                    Company
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Company"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Company == null ? DropboxData.indexOf("Company") : Company
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn6"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyNameLong">
                    Company Name Long
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyNameLong"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyNameLong == null
                      ? DropboxData.indexOf("Company Name Long")
                      : CompanyNameLong
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn11"
                  disabled="disabled"
                >
                  <option selected="" value="Website">
                    Website
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Website"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Website == null ? DropboxData.indexOf("Website") : Website
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn6"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyPhone">
                    Company Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyPhone == null
                      ? DropboxData.indexOf("Company Phone")
                      : CompanyPhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="DirectPhone">
                    Direct Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="DirectPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    DirectPhone == null
                      ? DropboxData.indexOf("Direct Phone")
                      : DirectPhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="DirectPhoneExt">
                    Direct Phone Ext
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="DirectPhoneExt"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    DirectPhoneExt == null
                      ? DropboxData.indexOf("Direct Phone Ext")
                      : DirectPhoneExt
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn14"
                  disabled="disabled"
                >
                  <option selected="" value="LinkedInURL">
                    LinkedIn URL
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="LinkedInURL"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    LinkedInURL == null
                      ? DropboxData.indexOf("LinkedIn URL")
                      : LinkedInURL
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn18"
                  disabled="disabled"
                >
                  <option selected="" value="Industry">
                    Industry
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Industry"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Industry == null
                      ? DropboxData.indexOf("Industry")
                      : Industry
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn12"
                  disabled="disabled"
                >
                  <option selected="" value="Address1">
                    Address 1
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Address1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address1 == null
                      ? DropboxData.indexOf("Address 1")
                      : Address1
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn13"
                  disabled="disabled"
                >
                  <option selected="" value="Address2">
                    Address 2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Address2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address2 == null
                      ? DropboxData.indexOf("Address 2")
                      : Address2
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn7"
                  disabled="disabled"
                >
                  <option selected="" value="City">
                    City
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="City"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={City == null ? DropboxData.indexOf("City") : City}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn8"
                  disabled="disabled"
                >
                  <option selected="" value="State">
                    State
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="State"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={State == null ? DropboxData.indexOf("State") : State}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn15"
                  disabled="disabled"
                >
                  <option selected="" value="Zip">
                    Zip
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Zip"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Zip == null ? DropboxData.indexOf("Postal Code") : Zip}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn16"
                  disabled="disabled"
                >
                  <option selected="" value="Country">
                    Country
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Country"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Country == null ? DropboxData.indexOf("Country") : Country
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn9"
                  disabled="disabled"
                >
                  <option selected="" value="ContactCategory">
                    Contact Category
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactCategory"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactCategory == null
                      ? DropboxData.indexOf("Contact Category")
                      : ContactCategory
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn10"
                  disabled="disabled"
                >
                  <option selected="" value="AccountCategory">
                    Company Category
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="AccountCategory"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountCategory == null
                      ? DropboxData.indexOf("Company Category")
                      : AccountCategory
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn17"
                  disabled="disabled"
                >
                  <option selected="" value="ValidationScore">
                    Validation Score
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ValidationScore"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ValidationScore == null
                      ? DropboxData.indexOf("Validation Score")
                      : ValidationScore
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn17"
                  disabled="disabled"
                >
                  <option selected="" value="ListName">
                    List Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <input
                  type="text"
                  id="ListName"
                  className="form-control  m-input"
                  value={filename}
                />
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ContactSource">
                    Contact Source
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactSource"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactSource == null
                      ? DropboxData.indexOf("Contact Source")
                      : ContactSource
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="ContactOwner">
                    Contact Owner
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactOwner"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactOwner == null
                      ? DropboxData.indexOf("Contact Owner")
                      : ContactOwner
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ClientPOC">
                    Client POC
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ClientPOC"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ClientPOC == null
                      ? DropboxData.indexOf("Client POC")
                      : ClientPOC
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="ReasonUnqualified">
                    Reason Unqualified
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ReasonUnqualified"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ReasonUnqualified == null
                      ? DropboxData.indexOf("Reason Unqualified")
                      : ReasonUnqualified
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="AccountType">
                    Account Type
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="AccountType"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountType == null
                      ? DropboxData.indexOf("Account Type")
                      : AccountType
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn19"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyRevenue">
                    Company Revenue
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyRevenue"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyRevenue == null
                      ? DropboxData.indexOf("Company Revenue")
                      : CompanyRevenue
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn20"
                  disabled="disabled"
                >
                  <option selected="" value="EmployeeCount">
                    Employee Count
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="EmployeeCount"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    EmployeeCount == null
                      ? DropboxData.indexOf("Employee Count")
                      : EmployeeCount
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="MobilePhone">
                    Mobile Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="MobilePhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    MobilePhone == null
                      ? DropboxData.indexOf("Mobile Phone")
                      : MobilePhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="IsDoNotCallContact">
                    Do not call contact
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="IsDoNotCallContact"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    IsDoNotCallContact == null
                      ? DropboxData.indexOf("Do not call contact")
                      : IsDoNotCallContact
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="SalesRep">
                    Sales Rep
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="SalesRep"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SalesRep == null
                      ? DropboxData.indexOf("Sales Rep")
                      : SalesRep
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ColdCaller">
                    Sales Development Reps
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ColdCaller"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ColdCaller == null
                      ? DropboxData.indexOf("Sales Development Reps")
                      : ColdCaller
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="Tag">
                    Tag
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Tag"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Tag == null ? DropboxData.indexOf("Tag") : Tag}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="CallNotes">
                    Call Notes
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CallNotes"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CallNotes == null
                      ? DropboxData.indexOf("Call Notes")
                      : CallNotes
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="OtherPhone1">
                    OtherPhone 1
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="OtherPhone1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    OtherPhone1 == null
                      ? DropboxData.indexOf("OtherPhone 1")
                      : OtherPhone1
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="OtherPhone2">
                    OtherPhone 2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="OtherPhone2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    OtherPhone2 == null
                      ? DropboxData.indexOf("OtherPhone 2")
                      : OtherPhone2
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ContactID">
                    ContactID
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactID"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactID == null
                      ? DropboxData.indexOf("ContactID")
                      : ContactID
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row px-4">
            <div className="toggleswich mr-2">
              <input
                type="checkbox"
                className="checktogle"
                id="IsUpdateExistContacts"
              />
              <b className="switch">
                <span className="checkion"></span>
                <span className="uncheckion"></span>
              </b>
              <b className="track"></b>
            </div>
            <label> Skip updating existing contacts</label>
          </div>
          <div className="form-group m-form__group row px-4">
            <div className="toggleswich mr-2">
              <input
                type="checkbox"
                className="checktogle"
                id="IsInsertNullValues"
              />
              <b className="switch">
                <span className="checkion"></span>
                <span className="uncheckion"></span>
              </b>
              <b className="track"></b>
            </div>
            <label> Insert Null Values</label>
          </div>
          <div className="form-group m-form__group row px-4">
            <div className="toggleswich mr-2">
              <input
                type="checkbox"
                className="checktogle"
                id="IsDoNotupdatecompanyname"
              />
              <b className="switch">
                <span className="checkion"></span>
                <span className="uncheckion"></span>
              </b>
              <b className="track"></b>
            </div>
            <label>Do Not Update Company Name</label>
          </div>
         
          <div className="form-group m-form__group border-top py-4 px-2">
            <a
              onClick={ContactSaveCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div> 
        </div>
        </div>

      </div>

      {/* Total Details for csv */}
      <div
        className="portletbody px-4"
        id="Show_csv_details"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Results</h4>
        </div>

        <div className="col-xs-12">
          <div className="row colfive">
            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-primary">
                        {Total}
                      </span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-success">
                        {TotalSucess}
                      </span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total success</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-danger">
                        {TotalError}
                      </span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total error</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-warning">
                        {TotalDuplicate}
                      </span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total Duplicate</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-warning">
                        {TotalUpdated}
                      </span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total Updated</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bulk Csv for */}

          <div className="portletbody" id="delete_csv" style={{display:"none"}} >
        <div className="row">
          <div className="col-xl-8 offset-xl-2 pb-3">
            <h3 className="uploadheadingtitle">Bulk Delete Contact</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={ResetBulkDelete}
              >
                <span>Select .CSV file</span>
                <p>Add contact data from csv file to bulk delete.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2 border-top pt-3">
            <div className="alignright">
              <a
                onClick={BulkDeleteContactBtn}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={CancelBulkContactBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>


      {/* Mapped div */}
      <div
        className="portletbody portletshadow p-4 m-1 map_attribute"
        id="map_attribute_domain_blacklist"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left pl-0">Map attributes</h4>
        </div>
        <div className="mx-m-1"> 
        <div className="col-xs-12">
          <div className="form-group m-form__group row mx-0">
          <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn3"
                  disabled="disabled"
                >
                  <option selected="" value="Email">
                    Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Email"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Email == null ? DropboxData.indexOf("Email") : Email}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="col-xs-12 border-top pt-4 mt-2">
            <a
              onClick={DeleteBulkContactCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div>
        </div>
        </div> 
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6"></div>
        <div className="col-sm-12 col-md-6">
          <div className="listing-text listing-li float-right padb-15">
            <ul className="mt-2">
              <li>
                <a
                  id="UnSelect"
                  defaultValue={CheckState}
                  onClick={(e) => UnSelectCheckHandler(e)}
                  style={{ display: "none" }}
                  className="mt-2 px-2"
                >
                  Unselect All
                </a>
              </li>
              {IsApply == true ? (
                <li>
                  <a
                    onClick={() => {
                      ResetFilter();
                    }}
                  >
                    {" "}
                    Reset
                  </a>
                </li>
              ) : null}

              <li>
                <a
                  id="Select-All"
                  className="mr-5"
                  defaultValue={CheckState}
                  onClick={(e) => SelectCheckHandler(e)}
                >
                  Select All
                </a>
              </li>
              <li>
                <a
                  onClick={DeleteSelectedAll}
                  className="btn btn-danger m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air text-white"
                  id="deleteall-btn"
                  style={{ display: "none" }}
                >
                  <i className="la flaticon-delete-1  text-white"></i>
                  <span> Delete All</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row px-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="px-2">
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="p-1" align="center">
                    <input
                      type="checkbox"
                      id="main-check"
                      onChange={(e) => CheckHandler(e)}  
                    />
                  </TableCell>
                  {ContactHeader?.map((row) => (
                    <TableCell key={row._id}>
                      <a
                   
                        onClick={() => {
                          SortData(row.ColumnName.replace(/\s+/g, ""));
                        }}
                      >
                        {row.ColumnName} 
                      </a>
                    </TableCell>
                  ))}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == 0 ? (
                  <p>No data available in table</p>
                ) : (
                  rows?.map((value1) => (
                    <>
                      <TableRow key={value1._id}>
                        <TableCell>
                          <a
                            onClick={() => {
                              ViewBtn(value1?._id, value1.Email);
                            }}
                            className="btn-eyesicon"
                            title="View"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          <input
                      type="checkbox"
                      id={value1?._id}
                      onChange={(e) => ProspectCheckHandler(e, value1)}
                    
                      checked={prospectAllSelectData.some(item => item._id === value1?._id)}
                          />
                        </TableCell>

                    {ContactHeader.map((row) => {
                      if (row.ColumnName == "Contact Name") {
                        return <TableCell><a
                   onClick={() => {
                          ViewBtn(value1?._id, value1.Email);
                        }}
                      ><u>{value1?.FirstName}</u></a></TableCell>;
                      } else if (row.ColumnName == "Account Name") {
                        return <TableCell>             <a
                      onClick={() => {
                              ViewBtn(value1?._id, value1.Email);
                            }}
                            className="btn-eyesicon"
                            title="View"
                          > <u>{value1.Accountname?.SalesReplyAccountName}</u></a></TableCell>;
                      } else if (row.ColumnName == "Email") {
                        return <TableCell>{value1?.Email}</TableCell>;
                      } else if (row.ColumnName == "Title") {
                        return <TableCell>{value1?.Title}</TableCell>;
                      } else if (row.ColumnName == "Validation Score") {
                        return <TableCell>{value1?.ValidationScore}</TableCell>;
                      } else if (row.ColumnName == "Last Validation Date") {
                        return (
                          <TableCell>
                          {
                            value1?.LastValidationDate == null ? "" : moment(
                              new Date(value1?.LastValidationDate).toDateString()
                            ).format("MM/DD/YYYY")
                          }
    
                          </TableCell>
                        );
                      } else if (row.ColumnName == "Company") {
                        return <TableCell>{value1?.Company}</TableCell>;
                      } else if (row.ColumnName == "Website") {
                        return <TableCell><a    href={`http://${value1?.Website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}><u>{value1?.Website}</u></a></TableCell>;
                      } else if (row.ColumnName == "LinkedIn URL") {
                        return <TableCell>{value1?.LinkedInURL}</TableCell>;
                      } else if (row.ColumnName == "Industry") {
                        return <TableCell>{value1?.Industry}</TableCell>;
                      } else if (row.ColumnName == "Address 1") {
                        return <TableCell>{value1?.Address1}</TableCell>;
                      } else if (row.ColumnName == "Address 2") {
                        return <TableCell>{value1?.Address2}</TableCell>;
                      } else if (row.ColumnName == "City") {
                        return <TableCell>{value1?.City}</TableCell>;
                      } else if (row.ColumnName == "State") {
                        return <TableCell>{value1?.State}</TableCell>;
                      } else if (row.ColumnName == "Company Phone") {
                        return <TableCell>{value1?.CompanyPhone}</TableCell>;
                      } else if (row.ColumnName == "Zip") {
                        return <TableCell>{value1?.Zip}</TableCell>;
                      } else if (row.ColumnName == "Country") {
                        return <TableCell>{value1?.Country}</TableCell>;
                      } else if (row.ColumnName == "Contact Category") {
                        return <TableCell>{value1?.ContactCategory}</TableCell>;
                      } else if (row.ColumnName == "Account Category") {
                        return <TableCell>{value1?.AccountCategory}</TableCell>;
                      } else if (row.ColumnName == "List Name") {
                        return <TableCell>{value1?.ListName}</TableCell>;
                      } else if (row.ColumnName == "Deliveries") {
                        return <TableCell>{value1.EmailSend?.Delivers == null || undefined || "" ? 0 : value1.EmailSend?.Delivers}</TableCell>;
                      } else if (row.ColumnName == "Opens") {
                        return <TableCell>{value1.EmailSend?.Opens == null || undefined || "" ? 0 : value1.EmailSend?.Opens}</TableCell>;
                      } else if (row.ColumnName == "Replies") {
                        return <TableCell>{value1.EmailSend?.Replies == null || undefined || "" ? 0 : value1.EmailSend?.Replies}</TableCell>;
                      } else if (row.ColumnName == "Soft Bounces") {
                        return <TableCell>{value1.EmailSend?.SoftBounce == null || undefined || "" ? 0 : value1.EmailSend?.SoftBounce}</TableCell>;
                      } else if (row.ColumnName == "Hard Bounces") {
                        return <TableCell>{value1.EmailSend?.HardBounce == null || undefined || "" ? 0 : value1.EmailSend?.HardBounce}</TableCell>;
                      } else if (row.ColumnName == "Contact Status") {
                        const status = value1?.ProspectStatusDetails?.Status || "";
                        return <TableCell>{status}</TableCell>;
                      } else if (row.ColumnName == "Contact Source") {
                        return <TableCell>{value1?.contactSources[0]?.Name}</TableCell>;
                      } else if (row.ColumnName == "Contact Owner") {
                        return <TableCell>{value1?.contactOwners[0]?.Name}</TableCell>;
                      } else if (row.ColumnName == "Client POC") {
                        return <TableCell>{value1?.clientPOCs[0]?.Name}</TableCell>;
                      } else if (row.ColumnName == "Reason Unqualified") {
                        return <TableCell>{value1?.reasonUnqualifieds[0]?.Reason}</TableCell>;
                      } else if (row.ColumnName == "Account Type") {
                        return <TableCell>{value1?.commonFieldAccounts[0]?.FieldName}</TableCell>;
                      } else if (row.ColumnName == "Direct Phone") {
                        return <TableCell>{value1?.DirectPhone}</TableCell>;
                      } else if (row.ColumnName == "Company Phone") {
                        return <TableCell>{value1?.CompanyPhone}</TableCell>;
                      } else if (row.ColumnName == "Company Revenue") {
                        return <TableCell>{value1?.CompanyRevenue == null || undefined || "" ? 0 : value1?.CompanyRevenue}</TableCell>;
                      } else if (row.ColumnName == "Employee Count") {
                        return (
                          <TableCell>{value1.Accountname?.CompanyEmployeeCount}</TableCell>
                        );
                      } else if (row.ColumnName == "Mobile Phone") {
                        return <TableCell>{value1?.MobilePhone}</TableCell>;
                      } else if (row.ColumnName == "Ankita test") {
                        return <TableCell>{value1?.Ankitatest}</TableCell>;
                      } else if (row.ColumnName == "Call Count") {
                        return <TableCell>{value1?.CallCount == null || undefined || "" ? 0 : value1?.CallCount}</TableCell>;
                      } else if (row.ColumnName == "Last Call Result") {
                        return <TableCell>{value1?.LastCallResult}</TableCell>;
                      } else if (row.ColumnName == "Last Call Date") {
                        return (
                          <TableCell>
                          {value1?.LastValidationDate == null ? "" : moment(
                              new Date(value1?.LastValidationDate).toDateString()
                            ).format("MM/DD/YYYY") 
                          }

                          </TableCell>
                        );
                      } else if (row.ColumnName == "Call Notes") {
                        return <TableCell>{value1?.CallNotes}</TableCell>;
                      } else if (row.ColumnName == "Contacted Date") {
                        return (
                          <TableCell>
                            {value1?.CreatedDate == null ? "" : moment(
                              new Date(value1?.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY") 
                          }
                          </TableCell>
                        );
                      } else if (row.ColumnName == "Do Not Call Contact") {
                        return (
                          <TableCell>
                            {value1?.IsDoNotCallContact == true ? "Yes" : "No"}
                          </TableCell>
                        );
                      } else if (row.ColumnName == "Last Email Campaign") {
                        return <TableCell>{value1?.LastEmailCampaign}</TableCell>;
                      } else if (row.ColumnName == "Last Email Step") {
                        return <TableCell>{value1?.LastEmailStep}</TableCell>;
                      } else if (row.ColumnName == "Sales Rep") {
                        return <TableCell>{value1?.SalesRep}</TableCell>;
                      } else if (row.ColumnName == "Direct Phone Ext") {
                        return <TableCell>{value1?.DirectPhoneExt}</TableCell>;
                      } else if (row.ColumnName == "Tag") { 
                        return (
                          <TableCell>{value1?.ContactTags}</TableCell>
                        );
                      } else if (row.ColumnName == "Sales Development Reps") {
                        return (
                          <TableCell>{value1?.coldCallers[0]?.ColdCallersName}</TableCell>
                        );
                      } else if (row.ColumnName == "Other Phone 2") {
                        return <TableCell>{value1?.OtherPhone2}</TableCell>;
                      } else if (row.ColumnName == "Other Phone 1") {
                        return <TableCell>{value1?.OtherPhone1}</TableCell>;
                      } else if (
                        row.CustomFieldID != null &&
                        row.CustomFieldID != ""
                      ) {
                        let valueCustomField = Array.isArray(value1.CustomFieldData) ? value1.CustomFieldData.filter((item) => item?.CustomFieldID === row?.CustomFieldID) : [];
         
                        if(valueCustomField?.length > 0){
         
                          return <TableCell>{valueCustomField[0]?.FieldValue}</TableCell>;
                          
                        }else{
                          return <TableCell></TableCell>;
                        }
                    
                      }  else{
                        return <TableCell></TableCell>;
                      }
                    })}

                        <TableCell>
                          <a
                            onClick={() => {
                              ViewBtn(value1?._id);
                            }}
                            className="btn-eyesicon"
                            title="View"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                          <a
                            onClick={() => {
                              DeleteBtn(value1?._id);
                            }}
                            className="btn-eyesicon"
                            title="Delete"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {sflag ? (
          <div className="row">
            <Pagination
              component="div"
              count={countpage}
              page={page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of{" "}
                {rlen} entries (filtered from {flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col dataTables_info">
              <p>
                Showing  {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of{" "}
                {rlen} entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={countpage}
                page={page}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
