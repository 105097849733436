import React from "react";
const moment=require('moment');

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import KPIsTabing from "../../_components/user/Kpis/KPIsTabing";
import { CommonConstants } from "../../_constants/common.constants";

import { getFirstDayOfQuarter,getCurrentDayOfQuarter } from "../../_helpers/Utility";

class KPIsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FromDate: "",
      ToDate: "",
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.UpdateFromKPIPage = this.UpdateFromKPIPage.bind(this);
  }

  componentDidMount() {
    var fromdate=getFirstDayOfQuarter() 
    fromdate.setDate(fromdate.getDate() + 1)
    document.getElementById("from-date").valueAsDate =fromdate;
    document.getElementById("to-date").valueAsDate = getCurrentDayOfQuarter();

    let FromDate = document.getElementById("from-date").value;
    let ToDate = document.getElementById("to-date").value;
    this.setState({FromDate: FromDate,ToDate: ToDate});
  }

  SaveBtn() {
    
    let FromDate = document.getElementById("from-date").value;
    let ToDate = document.getElementById("to-date").value;
    this.setState({ FromDate: FromDate, ToDate: ToDate });
  }
  UpdateFromKPIPage(value){
    if(value == true){
      window.location="/kpis"
    }
  }

  render() {
    const mainTtile = CommonConstants.Title;
    document.title = "KPIs" + mainTtile;
    
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar menupage="/kpis" updatefromchild = {this.UpdateFromKPIPage} className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col padd-0">
                <h4 className="headertitlebd pl-3 py-4 mt-0">KPIs</h4>
              </div>
              <div className="col padright">
                <div className="btn-group">
                  <input id="fromtab" name="fromtab" type="hidden" value="" />
                  <input
                    class="form-control datepicker"
                    id="from-date"
                    name="from-date"
                    type="date"
                    // value="07-01-2021"
                    autocomplete="off"
                  />
                  <input
                    class="form-control datepicker"
                    id="to-date"
                    name="to-date"
                    type="date"
                    // value="07-05-2021"
                    autocomplete="off"
                  />
                  <button
                  onClick={this.SaveBtn}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i class="la flaticon-diagram"></i>
                    <span> Analyze</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col padd-0">
              <KPIsTabing FromDates={this.state.FromDate} ToDates={this.state.ToDate} />
            </div>
          </div>
        </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default KPIsPage;
