import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'; 
import Footer from '../../_components/user/footer/footer'; 
import ClientVariableTabing from '../../_components/clients/variable/VariableTabing.js';
import Popupone from "reactjs-popup";
const moment = require("moment");
import { GetUserDetails } from "../../_helpers/Utility";
class CVariablePage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            ClientID:null,
            UserID:null,
            CUserID:null,
            StartDate:null,
            EndDate:null,
            exportdata: {
                "Start":moment(new Date().setMonth(new Date().getMonth() - 1)).format('YYYY/MM/DD'),
                "End":moment(new Date()).format('YYYY/MM/DD')
            },
            pageData:null
        };
        this.DateAnalize = this.DateAnalize.bind(this);
      }
      componentDidMount() {
        document.title = "Variable Sets | SalesHive";
        // document.getElementById("hidelod").style.display = "none";
        //this.loadPage();
        var Details=GetUserDetails();
        if(Details != null)
        {
            this.state.ClientID= (Details.ClientID);
            this.state.UserID=(Details.ParentUserID);
            this.state.CUserID=(Details.ChildUserID);
            
        }
        var d = new Date();
          d.setMonth(d.getMonth() - 1);
          var start = moment(d).format('YYYY-MM-DD');
          var d1 = new Date();
          var end = moment(d1).format('YYYY-MM-DD');
          this.setState({StartDate:start});
          this.setState({EndDate: end});
          document.getElementById("from-date").value = start;
          document.getElementById("to-date").value = end;
          var d = new Date();
          d.setMonth(d.getMonth() - 1);
          var start = moment(d).format('YYYY/MM/DD');
          var d1 = new Date();
          var end = moment(d1).format('YYYY/MM/DD');
          this.setState({exportdata:{
              "Start":start,
              "End":end
          }})


      }
      DateAnalize(){
        var fromdate = document.getElementById("from-date").value;
        var todate = document.getElementById("to-date").value;
        this.setState({exportdata:{
            "Start":fromdate,
            "End":todate
        }})
      }
    render() { 
        return (
            <>
                     
                     <Popupone open={this.state.InvitePopup} modal>
                <div className="modal-black"></div>
                    <div className="filterPopup bigpopupcontent">
                        <div className="paddingboxTerms">
                            <div className="modal-header">
                                <h5>Send Mail</h5>
                                <a className="close-ion" onClick={this.closeInvitePopup}><span aria-hidden="true" class="la la-remove"></span></a>
                            </div>
                            <div className="modal-content bordernone"> 
                                <div class="row max-cols">
                                    <label class="col-lg-4 view-lable-meet">First Name</label>
                                    <div class="col-lg-8">
                                        <input class="form-control m-input" data-val="true" data-val-required="Please enter first name" id="FirstName" name="FirstName" placeholder="First name" type="text" value="" />
                                    </div>
                                </div> 
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <a id="m_emailInvite_submit" href="javascript:void(0);" class="btn btn-primary btnColor">Send </a>
                            </div>
                        </div> 
                    </div> 
                </Popupone>


                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col"> 
                            <h4 className="headertitlebd pl-2">Variable Sets</h4>
                        </div>
                        <div className="col">
                            <div className="listing-li float-right pt-3 pr-2">
                                <div class="btn-group">
                                    <input id="fromtab" name="fromtab" type="hidden" value="" />
                                    <input class="form-control datepicker" id="from-date" name="from-date" type="date" autocomplete="off" />
                                    <input class="form-control datepicker" id="to-date" name="to-date" type="date" autocomplete="off" />
                                        <button onClick={this.DateAnalize} id="aCompanyState" class="btn btn-primary btnColor btn-radius" autocomplete="off">
                                            <i class="la flaticon-diagram"></i>
                                            <span>Analyze</span>
                                    </button>
                                </div> 
                            </div>
                        </div>
                    </div>
                   
                        <div className="row"> 
                            <div className="col">
                                <ClientVariableTabing  daterange={this.state.exportdata}/>
                            </div> 
                        </div> 

                </div> 
                </div>
                <Footer />
            </>
        );
    }

}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCVariablePage = connect(mapState, actionCreators)(CVariablePage);
export { connectedCVariablePage as CVariablePage };