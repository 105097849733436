import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";

class AddGlobalBounceListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      EmailAvailable: null,
      IsExist: false,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      IsButtonDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = "Add Global Bounce List | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
  }

  //form validation
  async FromValidation() {
    let formIsValid = true;
    let errors = {};
    var Email = document.getElementById("Email").value.trim();
    var IsExist = await this.CheckGlobalBounceExits(Email);
    // var IsValid = await this.CheckEmailValidation();
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (IsExist == "InvalidEmail") {
      formIsValid = false;
      errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      formIsValid = false;
      errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }

    this.setState({ Errors: errors });
    return formIsValid;
  }

  // form validation
  async HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var Email = document.getElementById("Email").value.trim();

    let IsExist = await this.CheckGlobalBounceExits(Email);
    if (IsExist == "InvalidEmail") {
      Errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      Errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && Email == "") {
      Errors["Email"] = "Please enter email";
    }

    if (IsExist == "AllDone") {
      Errors["Email"] = null;
    }

    this.setState({ Errors: Errors });
  }

  //change to check error
  async HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Email != "") {
      this.state.Errors.Email = null;
      this.state.EmailAvailable = null;
      await this.CheckGlobalBounceExits(e.target.value);
      await this.CheckEmailValidation();
    } else {
      this.state.EmailAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  //check exist name
  async CheckGlobalBounceExits(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidEmail";
      } else {
        var InputParameter = {
          UserID: this.state.UserID,
          Email: Email,
        };
        var resdata = await Axios({
          url: CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceExists",
          method: "POST",
          data: InputParameter,
        });

        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data?.length > 0) {
            this.setState({ EmailAvailable: resdata.data.Data[0]?.Email });
            return "ExistEmail";
          } else {
            return "AllDone";
          }
        }
      }
    } else {
      return "BlankData";
    }
  }

  //back
  BackBtn() {
    history.push("/globalbouncelist");
  }

  //add global bounce
  async SaveBtn(e) {
    // document.getElementById("hideloding").style.display = "block";
    // this.state.Errors.Email = null;
    // this.state.EmailAvailable = null;
    // document.getElementById("submit").disabled = true;

    e.preventDefault();
    this.setState({ IsButtonDisabled: true });
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();

    if (Final_flag == true) {
      var Email = document.getElementById("Email").value.trim();
      var InputParameter = {
        Email: Email,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        CreatedBy: this.state.CUserID,
        CreatedDt: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Global Bounce Contact added successfully.
            </div>
          );
          // document.getElementById("submit").disabled = false;
          // document.getElementById("hideloding").style.display = "none";
          history.push("/globalbouncelist");
        } else {
          toast.error(res.data?.Message);
          this.setState({ IsButtonDisabled: false });
          //document.getElementById("hideloding").style.display = "none";
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
    //document.getElementById("hideloding").style.display = "none";
  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display" style={{display:"none"}}>
        <img src={loadingicon} />
      </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left">
                    Add Global Bounce List
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row pt-4 pb-2">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter email"
                        onBlur={this.HandleChangeBlur}
                        id="Email"
                        name="Email"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Email"]}
                      </span>

                      {/* {this.state.EmailAvailable && (
                        <span style={{ color: "red" }}>
                          Email already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12 pull-left pt-4 px-2">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  className="btn btn-primary btn-lightgreen mr-1"
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  className="btn btn-secondary"
                >
                  <i className="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddGlobalBounceListPage = connect(
  mapState,
  actionCreators
)(AddGlobalBounceListPage);
export { connectedAddGlobalBounceListPage as AddGlobalBounceListPage };
