import React from "react";
import Axios from "axios";
import DatePicker from "react-datepicker";
const moment = require("moment");

import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditMeetingGoalPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      SelectDate: "",
      Fields: {},
      Errors: {},
      OldName: "",
      ClientID: null,
      UserID: null,
      UserIDby: null,
      Role:null,
      IsButtonDisabled:false,
      WeekDate:"",
      Goal:"",
      GoalStatus:true,
      WeekStatus:true,
      oldDate:""
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.SelectedDate = this.SelectedDate.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Edit Meeting Goal | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
      this.state.UserIDby = Details.ChildUserID;
    }
    this.MeetingGoalEdit(this.props.location.state);
  }

    // find id by record meeting goal
    MeetingGoalEdit(ID) {
      const id = this.props.location.state;
      this.setState({ id: id });
      var MeetingGoalData = {
        id: ID,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalGetId",
        method: "POST",
        data: MeetingGoalData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          console.log(moment(new Date(res.data.Data[0]?.Week)).format("MM/DD/YYYY"))
          this.setState({WeekDate:res.data.Data[0]?.Week,Goal:res.data.Data[0]?.Goal})
          // document.getElementById("Goal").value = res.data.Data[0]?.Goal;
          // this.setState({ OldName: moment(new Date(res.data.Data[0]?.Week)).format("MM/DD/YYYY") });
          const selectedDate = moment.utc(res.data.Data[0]?.Week).toDate()
          this.setState({SelectDate: selectedDate})

          document.getElementById("Week").value = selectedDate;
          
          this.setState({oldDate:  moment.utc(res.data.Data[0]?.Week).toDate()})
        } else {
          toast.error(res.data.Message);
        }
      });
    }

  // back button
  BackBtn() {
    history.push("/meetinggoal");
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Goal = document.getElementById("Goal").value.trim();
    var Week = document.getElementById("Week").value.trim();
    var IsExist = await this.CheckExistGoalName(Goal);
    if (Goal == "") {
      FormIsValid = false;
      Errors["Goal"] = "Please Enter Goal";
    }
    if (Goal >100000000) {
      FormIsValid = false;
      Errors["Goal"] = "Please enter valid input";
    }
    if (Week == "") {
      FormIsValid = false;
    }
    if (IsExist == true) {
      FormIsValid = false;
    }

    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  handleDateBlur = async () => {
    let Errors = { ...this.state.Errors };
    if (this.state.SelectDate === "") {
      Errors['Week'] = "Please select a week";
      this.setState({ WeekStatus: false });
    } else {
      var IsExist = await this.CheckExistGoalName(this.state.SelectDate);
      if (IsExist) {
        Errors['Week'] = "Week already exists"; // Set a message for week existence
        this.setState({ WeekStatus: false });
      } else {
        this.setState({ WeekStatus: true });
        Errors['Week'] = ""; // Clear the error message
      }
    }
    this.setState({ Errors: Errors });
  };
  handleGoalChange = (event) => {
  this.setState({ Goal: event.target.value });
}
  HandleChangeBlur = () => {
    let Goal = document.getElementById("Goal").value.trim();
    let Errors = { ...this.state.Errors };
    if (Goal === "") {
      Errors["Goal"] = "Please enter a goal";
      this.setState({ GoalStatus: false });
    } else {
      if (isNaN(Goal) || parseInt(Goal) <= 0) {
        Errors["Goal"] = "Please enter a valid positive number";
        this.setState({ GoalStatus: false });
      } else {
        this.setState({ GoalStatus: true });
        Errors["Goal"] = "";
      }
    }
    this.setState({ Errors: Errors });
  };



  //check exist name
  async CheckExistGoalName(WeekData) {
    var str_in = {
      ClientID: this.state.ClientID,
      Week: WeekData,
      Role: this.state.Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/meetinggoal/CheckExistWeek",
      method: "POST",
      data: str_in,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        let WeekDates=moment(new Date(WeekData)).format("MM/DD/YYYY");
       
        if (this.state.OldName == WeekDates) {
          return false;
        } else {
          const dateFromWeekDates = moment(WeekDates);
          const formattedWeekDates = dateFromWeekDates.format('YYYY-MM-DD');
          
          const dateFromState = moment(this.state.oldDate);
          const formattedStateDate = dateFromState.format('YYYY-MM-DD');
          
          if (formattedWeekDates === formattedStateDate) {
            return false;}
            else{

              this.setState({ SelectDate:this.state.oldDate})
              // this.setState({ SelectDate: moment(new Date(this.state.OldName)).format("MM/DD/YYYY") });
              toast.error(<div className="toastsize">Edit Meeting Goal<br/>Goal already exist in selected week,select other week.</div>);
              
              return true;
            }
        }
      } else {
        return false;
      }
    }
  }

  // update meeting goal
  async SaveBtn(e) {
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabled:true});
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Goal = document.getElementById("Goal").value.trim();
      var MeetingGoalUpdate = {
        _id: this.state.id,
        Goal: Goal,
        Week: this.state.SelectDate,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDt: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalUpdate",
        method: "POST",
        data: MeetingGoalUpdate,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Meeting goal updated successfully.",
            "Edit source status"
          );
          history.push("/meetinggoal");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  // select data
  SelectedDate=async(date)=> {
    await this.setState({ SelectDate: date });
    
    await this.CheckExistGoalName(date)
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col px-4">
                  <h4 className="headertitlepad float-left mb-0">
                    Edit Meeting Goal
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row py-4 px-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Week</label>
                    <div class="col-lg-8">
                    <DatePicker
                        selected={
                          (this.state.SelectDate == null || this.state.SelectDate == "") ? "" : this.state.SelectDate
                        }
                        onChange={(date) => this.SelectedDate(date)}
                        dateFormat="dd/MM/yyyy"
                        name="startDate"
                        id="Week"
                        filterDate={(date) => date.getDay() === 1}
                        onBlur={this.handleDateBlur}
                        className="form-control m-input w-100"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Week"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Goal</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="Goal"
                        // name="LastName"
                        placeholder="Goal"
                        type="number"
                        onBlur={this.HandleChangeBlur}
                        value={this.state.Goal}
                        onChange={this.handleGoalChange}
                      />
                     <span style={{ color: "red" }}>
                        {this.state.Errors["Goal"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditMeetingGoalPage = connect(
  mapState,
  actionCreators
)(EditMeetingGoalPage);
export { connectedEditMeetingGoalPage as EditMeetingGoalPage };
